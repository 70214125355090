import { saveSequence } from "../../services/api";
import { createNewEmptySequence, Sequence } from "../../types/sequence";
import { useBoard } from "../useBoard";
import { useSequenceUpdater } from "./useSequenceUpdater";

export function useNewSequence() {
    const sequenceUpdater = useSequenceUpdater();
    const board = useBoard();

    const createEmptySequence = (newSequenceName: string, callback: () => void) => {
        saveSequence(createNewEmptySequence(newSequenceName)).then(({ status, sequence }) => {
            const id = sequence.id;
            sequenceUpdater.updateSequences((sequences: Sequence[]) => {
                if (sequences.find(s => s.id === id)) {
                    board.addSequence(id);
                }
            });
            callback();
        });
    };

    return {
        createEmptySequence,
    }
}