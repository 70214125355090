import { VFlex } from "../containers/VFlex";
import { ProgressBar } from "baseui/progress-bar";
import { LabelXSmall } from "baseui/typography";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { getPasswordValidation } from "../../utils/password";

export function PasswordStrengthBar({ password }: { password: string }) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const passwordValid = getPasswordValidation(password);

    let feedback = "";
    if (passwordValid.valid) {
        feedback = strings.Signup.PasswordStrongPassword;
    } else if (!passwordValid.noWhitespace) {
        feedback = strings.Signup.PasswordNoWhitespace;
    } else if (!passwordValid.has8digit) {
        feedback = strings.Signup.Password8Chars;
    } else if (!passwordValid.hasLow) {
        feedback = strings.Signup.PasswordLowerCase;
    } else if (!passwordValid.hasCap) {
        feedback = strings.Signup.PasswordUpperCase;
    } else if (!passwordValid.hasNumber) {
        feedback = strings.Signup.PasswordNumber;
    }

    return (
        <VFlex>
            <ProgressBar overrides={{
                BarContainer: {
                    style: {
                        marginLeft: 0,
                        marginRight: 0
                    }
                },
                BarProgress: {
                    style: {
                        backgroundColor: passwordValid?.valid ? "green" : "orange"
                    }
                }
            }} value={passwordValid.strength} maxValue={passwordValid.maxStrength}/>
            <LabelXSmall color={theme.colors.contentSecondary} hidden={passwordValid.length === 0}>
                {feedback}
            </LabelXSmall>
        </VFlex>
    );
}