import { useRecoilValue } from 'recoil';
import { StyledTable } from "baseui/table";
import { ZoneRow } from "./ZoneRow";
import { zonesSortDirectionState, zonesSortState, zonesState } from "../../states/zoneState";
import { EmptyState } from "../ui/EmptyState";
import { useStrings } from "../../hooks/useStrings";
import { ZoneIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { useZoneSelection } from "../../hooks/zones/useZoneSelection";
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';
import { default as AutoSizer } from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import { ZoneHeaderRow } from './ZoneHeaderRow';
import { useRef } from 'react';
import { ListRef } from '../../types/list';
import { useStyleOverrides } from "../../hooks/useStyleOverrides";

export function ZoneGrid() {
    const zones = useRecoilValue(zonesState);
    const { strings } = useStrings();
    const { theme } = useTheme();
    const { clearSelection } = useZoneSelection();
    const ref = useRef() as ListRef;
    const zonesSort = useRecoilValue(zonesSortState);
    const zonesSortDirection = useRecoilValue(zonesSortDirectionState);
    const { tableStyle } = useStyleOverrides();

    useEffectOnce(() => { return () => clearSelection()});
    useUpdateEffect(() => {
        if (!ref?.current) { return }

        ref.current.resetAfterIndex(0, false);
    }, [zonesSort, zonesSortDirection]);

    const getItemSize = (index: number) => {
        const zone = zones[index];
        if (zone.assignedMembers.length && zone.assignedTeams.length) {
            return 100;
        } else if (zone.assignedMembers.length || zone.assignedTeams.length) {
            return 80;
        }
        return 60;
    }

    if (zones.length === 0) {
        return (
            <EmptyState title={strings.EmptyStates.ZonesEmptyTitle}
                        subtitle={strings.EmptyStates.ZonesEmptyDescription}>
                <ZoneIcon size={64} color={theme.colors.contentPrimary}/>
            </EmptyState>
        );
    }

    return (
        <div style={{
            flex: 1,
            marginBottom: theme.sizing.scale800,
        }}>
            <StyledTable role="grid" style={tableStyle}>
                <ZoneHeaderRow/>
                <div style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ height, width }) => {
                            return (
                                <List height={height} itemCount={zones.length} itemSize={getItemSize} width={width}
                                    itemData={zones} ref={ref}>
                                    {ZoneRow}
                                </List>
                            )
                        }}
                    </AutoSizer>
                </div>
            </StyledTable>
        </div>
    );
}
