import { atomWithIndexedPersistence, atomWithPersistence, idbStorageEffect } from "../utils/persistence";
import { FormContent, FormContentIds, FormItem, FormSubmitTemplate, FormTemplate } from "../types/form";
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { companyState } from "./accountState";
import { getMatchingConditional } from "../utils/formUtils";
import { Timestamp, Uid } from "../types/core";

export const formTemplatesState = atomWithIndexedPersistence("formtemplates", [] as FormTemplate[]);

export const formTemplatesSortedState = selector({
    key: "formtemplates.sorted",
    get: ({ get }) => {
        const templates = get(formTemplatesState);
        return templates
            .filter(t => true)
            .sort((t1, t2) => {
                return t1.content.title.localeCompare(t2.content.title);
            });
    }
});

export const recentSubmissionFormsState = atomWithIndexedPersistence("formsubmissions.recent", [] as FormTemplate[]);
export const recentSubmissionFormUidsState = atomWithPersistence<{uid: Uid, timestamp: Timestamp}[]>("formsubmissionUids.recent", [])

export const recentSubmissionFormsSortedState = selector({
    key: "formsubmissions.recent.sorted",
    get: ({ get }) => {
        const forms = get(recentSubmissionFormsState);
        return forms
            .filter(t => true)
            .sort((f1, f2) => {
                return f2.content.timestamp - f1.content.timestamp
            });
    }
});

export const selectedFormState = atom<FormTemplate | undefined>({ key: "form.template.selected", default: undefined });

export const selectedFormSubmitState = atom<FormTemplate | undefined>({ key: "form.submit.selected", default: undefined });

export const formSubmitState = atomFamily<FormSubmitTemplate | undefined, string>({
    key: "form.submit",
    default: undefined,
    effects: [idbStorageEffect],
});

export const formPreviewState = atomFamily<FormSubmitTemplate | undefined, string>({
    key: "form.preview",
    default: undefined,
});

export const formSubmitIsValidState = selectorFamily<boolean, string>({
    key: "form.submit.isValid",
    get: (uid) => ({ get }) => {
        const form = get(formSubmitState(uid));
        if (!form) return false;

        const formContent = form.content;
        // FINDS ANY ITEM WITH INVALID VALUE OR WITH ANY INVALID VALUE IN THEIR CONDITIONALS IF APPLY
        const invalidItemIndex = formContent.content.findIndex((item) => {
            const matchingConditional = getMatchingConditional(item);

            if (!matchingConditional && item.required && !item.value) {
                return true;
            } else if (matchingConditional) {
                const invalidConditionalItemIndex = matchingConditional.content.findIndex((conditionalItem) => {
                    if (conditionalItem.required && !conditionalItem.value) {
                        return true;
                    }
                    return false;
                });
                return invalidConditionalItemIndex >= 0;
            }
            return false;
        });

        return invalidItemIndex === -1;
    }
});

export const formQuestionsState = atom<FormTemplate | undefined>({ key: "form.template.questions", default: undefined });

export const selectedFormSubmissionState = atom<FormTemplate | undefined>({
    key: "form.submission.selected",
    default: undefined
});

export const formSubmissionsState = atom({
    key: "formsubmissions",
    default: [] as FormTemplate[],
});

export const formSubmissionsByTemplateState = selectorFamily<FormTemplate[], FormContentIds>({
    key: "formsubmissions.bytemplate",
    get: (ids) => ({ get }) => get(formSubmissionsState)
        .filter(f => f.id && (f.id === ids.templateId || f.id === ids.contentId)),
});

export const formSubmissionsForJobState = selectorFamily<FormTemplate[], {submissionId: string, templateId: string}[]>({
    key: "formsubmissions.forjob",
    get: (submittedForms) => ({ get }) => {
        const formSubmissions = get(formSubmissionsState);
        const jobFormSubmissions = submittedForms
            .map((submittedForm) => {
                return formSubmissions.find(f => f.id === submittedForm.templateId && f.submissionUid === submittedForm.submissionId)
            })
            .filter((submission) => submission !== undefined) as FormTemplate[];
        return jobFormSubmissions.sort((f1, f2) => f2.createdTs - f1.createdTs);
    },
});

export const standardFormContentState = selector<FormContent>({
    key: "form.standard",
    get: ({ get }) => {
        const company = get(companyState);
        return company?.standard_form || { content: [] as FormItem[] } as FormContent;
    }
});

export const editStandardFormContentState = atom<FormContent>({
    key: "edit.form.standard",
    default: standardFormContentState,
});
