import { useStrings } from "../../../hooks/useStrings";
import { useRecoilState, useRecoilValue } from "recoil";
import { clustersState } from "../../../states/clusteringState";
import React, { useState } from "react";
import { selectedJobIdsState } from "../../../states/jobsState";
import { distributeJobs } from "../../../services/api";
import { Slider } from "baseui/slider";
import { Spin } from "antd";
import { HFlex } from "../../containers/HFlex";
import { SpacerExpand } from "../../containers/Spacer";
import { LabelXSmall } from "baseui/typography";
import { Button } from "baseui/button";

export function ClusteringConfig() {
    const { format, strings } = useStrings();
    const [clusters, setClusters] = useRecoilState(clustersState);

    const [regions, setRegions] = useState(3);
    const jobs = useRecoilValue(selectedJobIdsState);
    const [loading, setLoading] = useState(false);

    const onFinalChange = () => {
        setLoading(true);
        distributeJobs({ regions, jobs, method: "distance" }).then(response => {
            if (response.status) {
                setClusters(response.clusters);
            }
        }).finally(() => setLoading(false));
    }

    return (
        <>
            <HFlex alignCenter spacing style={{ height: "64px" }}>
                <SpacerExpand/>
                {loading && <Spin/>}
                {loading && <LabelXSmall>{format(strings.Clustering.CreatingXClusters, regions)}</LabelXSmall>}
                <SpacerExpand/>
            </HFlex>
            <Slider value={[regions]}
                    disabled={loading}
                    onChange={value => setRegions(value.value[0])}
                    onFinalChange={onFinalChange}
                    valueToLabel={value => value !== 1
                        ? format(strings.Clustering.XClusters, value)
                        : strings.Clustering.OneCluster}
                    persistentThumb={!loading}
                    min={1}
                    max={20}
                    step={1}
            />
            {clusters.length === 0 && <Button onClick={onFinalChange}>{strings.General.Start}</Button>}
        </>
    );

    //const [mode, setMode] = useRecoilState(clusterModeState);
    //const [max, setMax] = useRecoilState(clusterMaxState);
    //useEffect(() => onFinalChange(), [mode, max])
    // <HFlex spacing alignCenter>
    //     <SpacerExpand/>
    //     Mode:
    //     <ButtonGroup size={"mini"} kind={"secondary"} selected={mode === "zones" ? 1 : 0}>
    //         <Button onClick={() => {
    //             setMode("distance")
    //         }}>
    //             Cluster by Distance
    //         </Button>
    //         <Button onClick={() => {
    //             setMode("zones")
    //         }}>
    //             Cluster by Zones
    //         </Button>
    //     </ButtonGroup>
    //     <SpacerExpand/>
    // </HFlex>
    // <Input type={"number"} max={100} min={1} value={max} onChange={val => setMax(parseInt(val.target.value) || 1)}/>
}