import { Language, SOURCE_INDIVIDUAL, TYPE_PERSON } from '../../types/core';
import {
    editMemberErrorState,
    editMemberHasModifiedState,
    editMemberModifiedState,
    editMemberState
} from '../../states/editMemberState';
import { useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useModal } from '../useModal';
import { editingMemberIdState } from '../../states/appState';
import { memberState } from '../../states/membersState';
import { Member } from '../../types/member';
import { deleteAccount, editAccount } from '../../services/api';
import useAccount from '../useAccount';
import useDirector from '../useDirector';

export function useMemberForms() {
    const editingMember = useRecoilValue(editingMemberIdState);
    const [member, setMember] = useRecoilState(memberState(editingMember));
    const resetMember = useResetRecoilState(memberState(editingMember));
    const memberFields = useRecoilValue(editMemberState(editingMember));
    const [isLoading, setIsLoading] = useState(false);
    const { closeModal } = useModal();
    const memberHasAnyError = useRecoilValue(editMemberErrorState(editingMember));
    const profileModifiedFields = useRecoilValue(editMemberModifiedState);
    const profileHasModifiedFields = useRecoilValue(editMemberHasModifiedState);
    const canSubmit = !memberHasAnyError;
    const { isSelfAccount } = useAccount();
    const { setCurrentLanguage } = useDirector();
    const { performPoll } = useDirector();

    const onSubmit = async () => {
        setIsLoading(true);

        let data: { [key: string]: any } = {};

        if (profileHasModifiedFields) {
            data = { ...profileModifiedFields };
            if ('working_hours' in data) {
                data['working_hours'] = memberFields.working_hours.source === SOURCE_INDIVIDUAL ? memberFields.working_hours : {};
            }

            const { status, account: updatedMember } = await editAccount({ uid: member?.id, ...data });
            if (status) {
                setMember(updatedMember as Member);
                if (updatedMember && "language" in data && isSelfAccount(updatedMember.uid)) {
                    setCurrentLanguage(updatedMember.language as Language)
                }
                if (updatedMember && isSelfAccount(updatedMember?.uid)) {
                    performPoll();
                }
            }
        }

        setIsLoading(false);
        closeModal();
    };

    const onDelete = async () => {
        if (member?.type !== TYPE_PERSON) {
            console.warn("trying to delete company/place");
            return;
        }

        if (isSelfAccount(member.id)) {
            console.warn("trying to delete self account");
            return;
        }
        const { status } = await deleteAccount(member.uid);
        if (status) {
            console.info('member deleted correctly:', member.uid);
            resetMember();
        }
        closeModal();
    }

    return {
        canSubmit,
        isLoading,
        onSubmit,
        onDelete,
        hasChanges: profileHasModifiedFields,
    };
}
