import { FlexGridLayout } from "../containers/FlexGridLayout";
import { AssistantFulfillmentCard } from "./AssistantFulfillmentCard";
import { useRecoilState } from "recoil";
import { assistantFulfillmentsState } from "../../states/assistantState";

export function AssistantFulfillments() {
    const [fulfillments, setFulfillments] = useRecoilState(assistantFulfillmentsState);

    if (fulfillments.length === 0) {
        return null;
    }

    return (
        <FlexGridLayout baseWidth={300}>
            {fulfillments.map((fulfillment, index) =>
                <AssistantFulfillmentCard
                    key={index}
                    fulfillment={fulfillment}
                    onRemove={() => setFulfillments(prev => [...prev].filter(f => f !== fulfillment))}/>)
            }
        </FlexGridLayout>
    );
}