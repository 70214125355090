import { HFlex } from "../containers/HFlex";
import { useStrings } from "../../hooks/useStrings";
import { SpacerExpand } from "../containers/Spacer";
import { Button } from "baseui/button";
import { Plus } from "baseui/icon";
import React from "react";
import { FormContent, FormItem, FormTemplate } from "../../types/form";
import { useSetRecoilState } from "recoil";
import { selectedFormState } from "../../states/formsState";
import useAccount from "../../hooks/useAccount";
import { Timestamp } from "../../types/core";

export function FormsTitleBar() {
    const { strings } = useStrings();
    const setSelectedForm = useSetRecoilState(selectedFormState);
    const account = useAccount();

    const onNewClick = () => setSelectedForm({
        id: "",
        creatorUid: account.uid,
        createdTs: Date.now() as Timestamp,
        status: 0,
        content: {
            id: "",
            title: strings.Forms.FormsUntitledForm,
            description: "",
            timestamp: 0,
            content: [] as FormItem[],
        } as FormContent
    } as FormTemplate);

    return (
        <HFlex alignCenter>
            {strings.Forms.Forms}
            <SpacerExpand/>
            <Button size={"mini"} kind={"primary"} onClick={onNewClick}>
                <Plus/> {strings.Forms.FormNew}
            </Button>
        </HFlex>
    );
}