import React, { memo } from "react";
import { StatefulTooltip } from "baseui/tooltip";
import { Button, KIND } from "baseui/button";

type Props = {
    tooltip: string,
    onClick?: Function,
    children: React.ReactNode,
    kind?: keyof typeof KIND;
    size?: "mini" | "compact" | "default";
}

export const HoverButton = memo(({
                                tooltip,
                                onClick = () => {},
                                kind = KIND.secondary,
                                children,
                                size = "mini",
                            }: Props) => {

    const overrides = {
        Root: {
            style: {
                height: "28px",
                width: "28px"
            }
        }
    };

    return (
        <StatefulTooltip content={() => (tooltip)} showArrow placement={"top"}>
            <Button size={size}
                    shape="round"
                    kind={kind}
                    onClick={(event) => {
                        event.stopPropagation();
                        onClick();
                    }}
                    overrides={overrides}
            >
                {children}
            </Button>
        </StatefulTooltip>
    );
});