import { useRecoilState } from "recoil";
import { boardMemberUidsState, boardVisibleSequencesState } from "../states/boardState";
import { Member } from "../types/member";
import { Uid } from "../types/core";

export function useBoard() {
    const [memberUids, setMemberUids] = useRecoilState(boardMemberUidsState);
    const [sequences, setSequences] = useRecoilState(boardVisibleSequencesState);

    const add = (memberUid: Uid | Uid[]) => {
        if (Array.isArray(memberUid)) {
            setMemberUids(old => Array.from(new Set([...(memberUid as Uid[]), ...old])));
            return;
        }
        if (memberUids.includes(memberUid)) {
            return;
        }
        setMemberUids([memberUid, ...memberUids]);
    };

    const remove = (uidToRemove: string) => setMemberUids(memberUids.filter((uid) => uid !== uidToRemove));
    const removeMember = (member: Member) => setMemberUids(memberUids.filter((uid) => uid !== member.uid));

    const addSequence = (id: string) => {
        if (sequences.includes(id)) {
            return;
        }
        setSequences([id, ...sequences]);
    };
    const removeSequence = (id: string) => setSequences(sequences.filter((seqId) => id !== seqId));

    return {
        add,
        remove,
        removeMember,
        setMemberUids,
        memberUids,
        sequences,
        addSequence,
        removeSequence,
        setSequences,
    };
}
