import { ChartPanel } from "./ChartPanel";
import { JobStats } from "./stats/JobsStats";
import { FlexGridLayout } from "../containers/FlexGridLayout";
import React from "react";
import { MemberStatDay } from "../../types/stats";
import { MemberStats } from "./stats/MemberStats";
import { useStrings } from "../../hooks/useStrings";


type Props = {
    stats: MemberStatDay[],
    fetching: boolean;
    width: number;
    showJobs?: boolean;
    showMembers?: boolean;
}

export function JobOverviewCharts({
                                      stats,
                                      fetching,
                                      width,
                                      showJobs = true,
                                      showMembers = true
                                  }: Props) {
    const { strings } = useStrings();
    const jobStatsHeight = 55 * 8;

    return (
        <FlexGridLayout baseWidth={width} rowGap={48}>
            { showJobs && <ChartPanel title={strings.Stats.JobStats}>
                <JobStats stats={stats} fetching={fetching} width={jobStatsHeight} height={jobStatsHeight}/>
            </ChartPanel>}
            { showMembers && <ChartPanel title={strings.Stats.MemberStats}>
                <MemberStats stats={stats} fetching={fetching} width={jobStatsHeight} height={jobStatsHeight}/>
            </ChartPanel> }
        </FlexGridLayout>
    );
}