import { LocationTable } from "./LocationTable";
import { useRecoilValue } from "recoil";
import { memberTracksLayerState } from "../../states/geojsonState";
import { retrieveTrackEntriesOrStopsFromGeojson } from "../../utils/geojsonUtils";
import { VFlex } from "../containers/VFlex";
import { Uid } from "../../types/core";

export function LocationTableForMember({ uid }: { uid: Uid }) {
    const tracksLayer = useRecoilValue(memberTracksLayerState({ uid }));
    const entries = retrieveTrackEntriesOrStopsFromGeojson(tracksLayer);
    return (
        <VFlex spacing>
            <div style={{ overflowX: "auto", width: "100%", height: "100%" }}>
                <LocationTable entries={entries}/>
            </div>
        </VFlex>
    )
}