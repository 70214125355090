import { TrackEntryAndData } from "./track";
import { Latitude, Longitude, Timestamp, Uid } from "./core";

export interface TimelineEntry {
    latEnd: Latitude;
    distance: number;
    jobTitle: string;
    placeUidStart: Uid;
    memberName: string;
    lngStart: Longitude;
    tsEnd: Timestamp;
    type: string;
    placeNameStart: string;
    locationStart: string;
    locationEnd: string;
    track1Encoded: string;
    duration: number;
    jobId: string;
    placeUidEnd: string;
    tsStart: Timestamp;
    latStart: Latitude;
    memberUid: Uid;
    track1Id: number;
    placeNameEnd: string;
    track2Id: number;
    lngEnd: Longitude;
    info: string;
}

export enum TimelineType {
    LOG_IN,
    LOG_OUT,
    CLOCK_IN,
    CLOCK_OUT,
    START_WORKING_HOURS,
    END_WORKING_HOURS,
    TRACK,
    PAUSE,
    CHECK_IN,
    CHECK_OUT,
    JOB_CHECK_IN,
    JOB_CHECK_OUT,
    JOB_ISSUE,
    JOB_COMPLETED,
}

export interface TimelineStop {
    start: number;
    end: number;
}

export type TimelineItem = {
    start: number;
    track?: TrackEntryAndData;
    stop?: TimelineStop;
    events: TimelineEntry[];
}