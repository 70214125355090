import { ImportType } from './../types/imports';
import { CompanyTabKeys, JOB_TABS, MEMBER_TABS } from '../constants/tabs';
import { atom, atomFamily, selector } from 'recoil';
import { PAGE } from '../constants/pages';
import { atomWithPersistence } from '../utils/persistence';
import { dayOfMinus, dayOfToday } from "../utils/time";
import { accountBlockedState } from "./accountState";
import { Day, EMPTY_UID, PageType, Uid, UidDay } from "../types/core";
import { actionsIsOpenState } from "./skeletonState";
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_WIDTH } from "../constants/app";
import { memberUidsState } from "./membersState";
import { MessageInstance } from "antd/es/message/interface";
import { NotificationInstance } from "antd/lib/notification/interface";
import { drawModeState, isLocationDrawMode } from "./drawModeState";
import { MemberField } from "../types/fields";
import { Activity } from "../types/notification";
import { Member } from "../types/member";
import { Constraint } from "../constants/routing";

export const pageState = atomWithPersistence<PageType>('app.page.current', PAGE.LIVE);

export const memberActiveTabState = atom({
    key: 'member.tab.active',
    default: MEMBER_TABS.profile
});

export const selectedTimeFrameState = atom({
    key: 'app.time.selection',
    default: [0, 60 * 24],
});

export const selectedDayState = atomWithPersistence(
    'app.day.selection',
    dayOfToday()
);

export const selectedDayFrameState = atom<[Day, Day]>({
    key: "app.day.frame.selection",
    default: [dayOfMinus(6), dayOfToday()]
});

export const dayState = selector({
    key: 'app.day',
    get: ({ get }) => {
        return get(selectedDayState);
    }
});

export const editingMemberIdState = atom({
    key: 'editing.member.id',
    default: EMPTY_UID
});

export const memberRoutingOnlyState = atom({
    key: 'editing.member.routing',
    default: false,
});

export const editingJobIdState = atom<string | undefined>({
    key: 'editing.job.id',
    default: undefined,
});

export const editingCompanyIdState = atom({
    key: 'editing.company.id',
    default: '',
});

export const editingPlaceIdState = atom<Uid | undefined>({
    key: 'editing.place.id',
    default: undefined,
});

export const editingTeamIdState = atom<number>({
    key: 'editing.team.id',
    default: -1,
});

export const editingZoneIdState = atom<string | undefined>({
    key: 'editing.zone.id',
    default: undefined,
});

export const jobActiveTabState = atom({
    key: 'job.tab.active',
    default: JOB_TABS.jobInfo
});

export const companyTabFilterState = atom({
    key: 'company.tab.filter',
    default: "" as CompanyTabKeys | "",
});

export const modalIsOpenState = selector({
    key: 'app.modal.open',
    get: ({ get }) => {
        const editingMember = get(editingMemberIdState);
        const editingCompany = get(editingCompanyIdState);
        const accountBlocked = get(accountBlockedState);
        const editingPlace = get(editingPlaceIdState);
        const editingJob = get(editingJobIdState);
        const editingZone = get(editingZoneIdState);
        return editingMember || editingCompany || accountBlocked || editingPlace !== undefined || editingJob || editingJob === "" || editingZone;
    }
});

export const hiddenModalState = selector({
    key: "app.modal.hidden",
    get: ({ get }) => {
        const drawMode = get(drawModeState);
        return isLocationDrawMode(drawMode);
    }
});

export const modalContentState = selector({
    key: 'app.modal.content',
    get: ({ get }) => {
        const editingMember = get(editingMemberIdState);
        const editingJob = get(editingJobIdState);
        const editingCompany = get(editingCompanyIdState);
        const accountBlocked = get(accountBlockedState);
        const editingPlace = get(editingPlaceIdState);
        const editingZone = get(editingZoneIdState);

        if (editingPlace !== undefined) {
            return 'place';
        }
        if (editingJob !== undefined) {
            return 'job';
        }
        if (editingMember) {
            return 'member';
        }
        if (editingCompany || accountBlocked) {
            return 'company';
        }
        if (editingZone !== undefined) {
            return 'zone';
        }

        return '';
    }
});

export const prefAutoZoomState = atomWithPersistence("pref.autozoom", true);

export const addingTeamState = atom({
    key: 'team.editing',
    default: false,
});

export const draggingState = atom({
    key: 'dragging',
    default: false,
});

export const editingJobTypesState = atom({
    key: 'jobTypes.editing',
    default: false,
});

export const importTypeState = atom<ImportType | undefined>({
    key: 'files.import',
    default: undefined,
});

export const sidebarBrokenState = atom({
    key: "sidebar.broken",
    default: false,
});

export const sidebarCollapsedState = atom({
    key: "sidebar.collapsed",
    default: false,
});

export const accountMenuIsOpenState = atom({
    key: "accountmenu.isopen",
    default: false,
});

export const sidebarPinnedState = atomWithPersistence("sidebar.pinned", false);

export const sidebarWidthState = selector({
    key: "sidebar.width", get: ({ get }) => {
        const actionIsOpen = get(actionsIsOpenState);
        const broken = get(sidebarBrokenState);
        const collapsed = get(sidebarCollapsedState);
        return !actionIsOpen && !broken ? collapsed ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_WIDTH : 0;
    }
});

export const sidebarLocationOpenState = atomWithPersistence("sidebar.location.open", true);
export const sidebarManageOpenState = atomWithPersistence("sidebar.manage.open", true);
export const sidebarAnalyzeOpenState = atomWithPersistence("sidebar.analyze.open", false);
export const sidebarMoreOpenState = atomWithPersistence("sidebar.more.open", false);

export const showBeaconState = atom({
    key: "help.beacon.visible",
    default: 0,
});

export const loadedState = selector<boolean>({
    key: "app.loaded", get: ({ get }) => {
        const memberUids = get(memberUidsState);
        return memberUids.length > 0;
    }
});

export const messageApiState = atom<MessageInstance | undefined>({ key: "message.api", default: undefined });

export const notificationApiState = atom<NotificationInstance | undefined>({
    key: "notification.api",
    default: undefined
});

export const routeSimulationRunningForState = atomFamily<boolean, string>({
    key: "app.route.simulation.running",
    default: false,
});

export const snoozeNotificationsState = atomWithPersistence("app.snoozenotifications", false);

export const ignoreSettingState = atomFamily<boolean, MemberField>({
    key: 'app.setting.ignore',
    default: false,
});

export const routingUndoOptimizeState = atomFamily<string[], UidDay>({
    key: 'app.routing.optimize.originalorder',
    default: [],
});

export const routingRecentlyUnassignedState = atomFamily<string[], UidDay>({
    key: 'app.routing.optimize.unassigned',
    default: [],
});

export const activitiesState = atom({
    key: "app.activities",
    default: [] as Activity[]
});

export const selectedMultiMemberState = atom<Member[]>({
    key: "app.member.selection.multi",
    default: []
});

export const hideAllPanelsState = atom<boolean>({
    key: "app.panels.hide",
    default: false
});

export const constraintsState = atomWithPersistence("app.routing.constraints", [
    Constraint.WORKING_HOURS,
    Constraint.TIME_WINDOWS,
    Constraint.SKILLS,
    Constraint.CAPACITY,
    Constraint.EVEN_DISTRIBUTION,
]);

export const blurState = atomWithPersistence("app.blur", true);