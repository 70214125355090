import { LngLat, useMap } from "react-map-gl";
import { useEffect, useState } from "react";
import { Point } from 'mapbox-gl';
import { useRecoilValue, useResetRecoilState } from "recoil";
import { jobsForSelectedMemberState, jobsForUnassignedState } from "../../states/jobsState";
import { useAllJobsSelection } from "../../hooks/jobs/useAllJobsSelection";
import { membersFilteredDangerousState } from "../../states/membersState";
import { useBoard } from "../../hooks/useBoard";
import { useEffectOnce, useUpdateEffect } from "usehooks-ts";
import { Job } from "../../types/job";
import { dispatchTreeActiveCardState } from "../../states/viewState";
import { mapToolState } from "../../states/mapState";
import { allJobsState } from "../../states/allJobsState";
import { dayState } from "../../states/appState";

export function BoxSelection() {
    const { map } = useMap();
    const day = useRecoilValue(dayState);
    const jobsForSelectedMember = useRecoilValue(jobsForSelectedMemberState({day}));
    const allJobs = useRecoilValue(allJobsState);
    const unassignedJobs = useRecoilValue(jobsForUnassignedState);
    const membersFiltered = useRecoilValue(membersFilteredDangerousState);
    const activeCard = useRecoilValue(dispatchTreeActiveCardState);
    const jobSelection = useAllJobsSelection();
    const board = useBoard();
    const [points, setPoints] = useState<LngLat[]>();
    const tool = useRecoilValue(mapToolState);
    const resetTool = useResetRecoilState(mapToolState);
    const [event, setEvent] = useState<MouseEvent>();

    useEffect(() => {
        if (points?.length === 2) {
            const p1 = points[0];
            const p2 = points[1];
            const minLat = Math.min(p1.lat, p2.lat);
            const minLng = Math.min(p1.lng, p2.lng);
            const maxLat = Math.max(p1.lat, p2.lat);
            const maxLng = Math.max(p1.lng, p2.lng);
            const jobs: Job[] = [...jobsForSelectedMember];
            if (activeCard === "unassigned") {
                jobs.push(...unassignedJobs);
            }
            if (activeCard === "jobs") {
                jobs.push(...allJobs);
            }
            const matchingJobs = jobs
                .filter(j => j.destinationLat > minLat
                    && j.destinationLng > minLng
                    && j.destinationLat < maxLat
                    && j.destinationLng < maxLng
                );
            const matchingMembers = matchingJobs.length === 0
                ? membersFiltered.filter(m => m.location.lat > minLat
                    && m.location.lng > minLng
                    && m.location.lat < maxLat
                    && m.location.lng < maxLng) : [];
            if (matchingJobs.length > 0) {
                jobSelection.select(Array.from(new Set(matchingJobs.map(j => j.id))));
            }
            if (matchingMembers.length > 0) {
                board.setMemberUids(matchingMembers.map(m => m.uid));
            }
        }
    }, [points]);

    useEffectOnce(() => {
        if (!map) {
            return;
        }
        map.getMap().boxZoom.disable();
        const canvas = map.getCanvasContainer();

        function mouseDown(e: MouseEvent) {
            setEvent(e);
        }

        canvas.addEventListener('mousedown', mouseDown, true /* disables dragging */);
    });

    useUpdateEffect(() => {
        if (!map || !event || !((event.shiftKey || tool === "selection") && event.button === 0)) {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('keydown', onKeyDown);
            return;
        }
        map.getMap().dragPan.disable();
        const canvas = map.getCanvasContainer();
        const start: Point = mousePos(event);
        let current: Point;
        let box: HTMLDivElement | undefined;

        function mousePos(e: MouseEvent) {
            const rect = canvas.getBoundingClientRect();
            return new Point(
                e.clientX - rect.left - canvas.clientLeft,
                e.clientY - rect.top - canvas.clientTop
            );
        }

        function onMouseMove(e: MouseEvent) {
            current = mousePos(e);
            if (!box) {
                box = document.createElement('div');
                box.style.border = "2px solid #3887be";
                box.style.position = "absolute";
                canvas.appendChild(box);
            }
            const minX = Math.min(start.x, current.x);
            const maxX = Math.max(start.x, current.x);
            const minY = Math.min(start.y, current.y);
            const maxY = Math.max(start.y, current.y)
            const pos = 'translate(' + minX + 'px,' + minY + 'px)';
            box.style.transform = pos;
            box.style.width = maxX - minX + 'px';
            box.style.height = maxY - minY + 'px';
        }

        function onMouseUp(e: MouseEvent) {
            finish([start, mousePos(e)]);
        }

        function onKeyDown(e: KeyboardEvent) {
            if (e.keyCode === 27) {
                finish(undefined);
            }
        }

        function finish(bbox: [Point, Point] | undefined) {
            if (!map) {
                return;
            }
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('mouseup', onMouseUp);
            if (box) {
                box.parentNode?.removeChild(box);
                box = undefined;
            }
            if (bbox) {
                const p1 = map.unproject(bbox[0]);
                const p2 = map.unproject(bbox[1]);
                setPoints([p1, p2]);
            }
            map.getMap().dragPan.enable();
            resetTool();
        }

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
        document.addEventListener('keydown', onKeyDown);
    }, [event])

    return null;
}