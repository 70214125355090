import { useRecoilState, useRecoilValue } from "recoil";
import { editingJobIdState } from "../../../states/appState";
import useAccount from "../../../hooks/useAccount";
import { editJobState } from "../../../states/editJobState";
import { useForms } from "../../../hooks/useForms";
import { getInputFormConfig } from "../../../types/job";
import { Button } from "baseui/button";
import { SectionedWrapper } from "../../containers/SectionedWrapper";
import { Section } from "../../ui/Section";
import { ParagraphSmall } from "baseui/typography";
import { Centered } from "../../containers/Centered";
import { Radio, RadioGroup } from "baseui/radio";
import { Spacer, SpacerExpand } from "../../containers/Spacer";
import { VFlex } from "../../containers/VFlex";
import { HFlex } from "../../containers/HFlex";
import * as React from "react";
import { useState } from "react";
import { StandardFormModal } from "../../forms/StandardFormModal";
import { EditIcon } from "../../ui/svg";
import { useTheme } from "../../../hooks/useTheme";
import { useStrings } from "../../../hooks/useStrings";
import { ConfirmModal } from "../../ui/ConfirmModal";
import { FormPreviewElement } from "../../forms/FormPreviewElement";
import { DeviceFrame } from "../../device/DeviceFrame";
import { IndividualFormModal } from "../../forms/IndividualFormModal";

export function JobInputFormTab() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const jobId = useRecoilValue(editingJobIdState);
    const { company } = useAccount();
    const [fields, setFields] = useRecoilState(editJobState(jobId || ''));
    const { newIndividualQuickForm } = useForms();
    const usingStandard = !fields?.inputFormConfig;
    const usingIndividual = !usingStandard;
    const form = usingIndividual ? getInputFormConfig(fields) : company?.standard_form;
    const [showStandardForm, setShowStandardForm] = useState(false);
    const [showIndividualForm, setShowIndividualForm] = useState(false);
    const [showSwitchToStandard, setShowSwitchToStandard] = useState(false);

    if (!fields) {
        return null;
    }

    const onRadioChange = (useIndividual: boolean) => {
        if (useIndividual) {
            setFields({
                ...fields,
                inputFormConfig: JSON.stringify(newIndividualQuickForm)
            });
        } else {
            setShowSwitchToStandard(true);
        }
    };

    return (
        <SectionedWrapper>
            <Section title={strings.ShortForm.ShortFormTitle} noMarginTop>
                <ParagraphSmall marginTop={0}>{strings.ShortForm.ShortFormDesc}</ParagraphSmall>
                <Centered>
                    {showSwitchToStandard && <ConfirmModal
                        isOpen
                        description={strings.ShortForm.SwitchBackStandardFormDesc}
                        confirmText={strings.ShortForm.UseStandardForm}
                        cancelText={strings.ShortForm.UseIndividualForm}
                        onCancel={() => setShowSwitchToStandard(false)}
                        onConfirm={() => {
                            setShowSwitchToStandard(false);
                            setFields({
                                ...fields,
                                inputFormConfig: "",
                            });
                        }}
                    />}
                    <RadioGroup
                        value={fields.inputFormConfig ? "individual" : "standard"}
                        onChange={e => onRadioChange(e.target.value === "individual")}
                        name="number"
                        align={"horizontal"}
                    >
                        <Radio value="standard">
                            {strings.ShortForm.UseStandardForm}
                        </Radio>
                        <Radio value="individual">
                            {strings.ShortForm.UseIndividualForm}
                        </Radio>
                    </RadioGroup>
                </Centered>
            </Section>
            {showStandardForm && <StandardFormModal onClose={(saved) => setShowStandardForm(false)}/>}
            {showIndividualForm && <IndividualFormModal onClose={(saved) => setShowIndividualForm(false)}/>}
            {form &&
                <Section title={usingStandard
                    ? strings.ShortForm.StandardForm
                    : strings.ShortForm.ConfigurationOfIndividualForm}>
                    <VFlex>
                        <Button size={"compact"} kind={"secondary"} onClick={() => usingStandard ? setShowStandardForm(true) : setShowIndividualForm(true)}>
                            <EditIcon size={14} color={theme.colors.contentTertiary}/>
                            <Spacer/> {strings.General.Customize}
                        </Button>
                        <HFlex>
                            <SpacerExpand/>
                            <DeviceFrame height={"auto"} width={"320px"} minHeight="400px"
                                         backgroundColor={theme.colors.backgroundSecondary}>
                                {form.content.map((item, index) =>
                                    <div key={index}>
                                        <Spacer/>
                                        <FormPreviewElement index={index} formItem={item}/>
                                    </div>
                                )}
                                <Spacer/>
                            </DeviceFrame>
                            <SpacerExpand/>
                        </HFlex>
                    </VFlex>
                </Section>
            }
            <Spacer height="48px"/>
        </SectionedWrapper>
    );
}