import { HFlex } from "../containers/HFlex";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Input } from "baseui/input";
import { placesSearchState, placesTeamFilterState, } from "../../states/placeState";
import { teamsState } from "../../states/teamsState";
import { useStrings } from "../../hooks/useStrings";
import { TeamSelect } from "../pickers/TeamSelect";
import { usePlaceSelection } from "../../hooks/places/usePlaceSelection";
import { Filter } from "baseui/icon";

export function PlacesFilterRow() {
    const { strings } = useStrings();
    const [teamFilter, setTeamFilter] = useRecoilState(placesTeamFilterState);
    const setPlacesSearch = useSetRecoilState(placesSearchState);
    const placesSearch = useRecoilValue(placesSearchState);
    const teams = useRecoilValue(teamsState);
    const selectedTeam = teams.find(team => team.id === teamFilter);
    const { clearSelection } = usePlaceSelection();

    return (
        <HFlex alignCenter spacing>
            <Input
                value={placesSearch}
                size={"compact"}
                placeholder={strings.General.SearchForPlaceAddress}
                onChange={({ target: { value } }) => setPlacesSearch(value)}
                overrides={{
                    Root: {
                        style: {
                            flex: 1,
                            paddingLeft: "0px",
                            paddingRight: "0px",
                        }
                    },
                    Input: {
                        style: {
                            paddingLeft: "0px",
                            paddingRight: "0px",
                        }
                    }
                }}
                startEnhancer={<Filter/>}
            />
            <div style={{ width: "150px" }}>
                <TeamSelect showFilterIcon selectedTeam={selectedTeam} onSelect={(team) => {
                    clearSelection();
                    setTeamFilter(team?.id || 0);
                }}/>
            </div>
        </HFlex>
    );
}