import { FlexGridLayout } from "../containers/FlexGridLayout";
import { ALL_REPORTS, Form } from "../../types/reports";
import { Card } from "baseui/card";
import { Button } from "baseui/button";
import { useReports } from "../../hooks/useReports";
import { useSetRecoilState } from "recoil";
import {
    selectedReportConfigState,
    selectedReportPayloadState,
    setupRecurringReportState
} from "../../states/reportState";
import { useStrings } from "../../hooks/useStrings";
import { VFlex } from "../containers/VFlex";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { FormReportIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { LinkedFormsSelect } from "../pickers/LinkedFormsSelect";
import { useState } from "react";
import { LinkedForm } from "../../types/linked";
import { Input } from "baseui/input";

export function ReportSelection() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const { titleOf, descriptionOf, iconOf } = useReports();
    const setSelectedReport = useSetRecoilState(selectedReportConfigState);
    const setRecurringReport = useSetRecoilState(setupRecurringReportState);
    const setSelectedReportPayload = useSetRecoilState(selectedReportPayloadState);
    const [selectedForm, setSelectedForm] = useState<LinkedForm[]>([]);
    const [query, setQuery] = useState("");

    const queryLowerCase = query.toLowerCase();
    const reports = [...ALL_REPORTS]
        .filter(r => query.length === 0 || titleOf(r).toLowerCase().includes(queryLowerCase));
    const showFormReport = query.length === 0
        || `${strings.Reports.ExportFormSubmissions}`.toLowerCase().includes(queryLowerCase);

    return (
        <VFlex spacing>
            <Input value={query}
                   type={"search"}
                   placeholder={strings.General.Filter}
                   onChange={(val) => setQuery(val.target.value)}
                   size="compact"
                   overrides={{
                       Root: {
                           style: {
                               flexShrink: 0
                           }
                       }
                   }}
            />
            <div style={{ overflow: "auto" }}>
                <FlexGridLayout baseWidth={260} rowGap={8} columnGap={8}>
                    {reports.map((report, index) =>
                        <Card key={index}
                              title={<HFlex alignCenter>{titleOf(report)}<SpacerExpand/>{iconOf(report)}</HFlex>}
                              action={
                                  <HFlex spacing>
                                      <Button size={"compact"}
                                              onClick={() => {
                                                  setRecurringReport(false);
                                                  setSelectedReport(report);
                                              }}>
                                          {strings.General.Start}
                                      </Button>
                                      <Button size={"compact"}
                                              kind={"secondary"}
                                              onClick={() => {
                                                  setRecurringReport(true);
                                                  setSelectedReport(report);
                                              }}>
                                          {strings.Reports.SetupRecurringReport}
                                      </Button>
                                  </HFlex>
                              }>
                            {descriptionOf(report)}
                        </Card>
                    )}

                    {showFormReport &&
                        <Card
                            title={strings.Reports.ExportFormSubmissions}
                            action={
                                <HFlex spacing>
                                    <LinkedFormsSelect
                                        linkedForms={selectedForm}
                                        onChangeLinkedForms={(linkedForms) => setSelectedForm(linkedForms as LinkedForm[])}
                                        multi={false}
                                        style={{ maxWidth: "170px" }}
                                    />
                                    <Button size={"compact"}
                                            disabled={selectedForm.length !== 1}
                                            onClick={() => {
                                                setRecurringReport(false);
                                                setSelectedReportPayload(selectedForm[0].id);
                                                setSelectedReport(Form);
                                            }}>
                                        {strings.General.Start}
                                    </Button>
                                </HFlex>
                            }>
                            <FormReportIcon size={64} color={theme.colors.contentPrimary}/>
                            {strings.Reports.ExportFormSubmissionsDesc}
                        </Card>
                    }
                </FlexGridLayout>
                <Spacer height={"48px"}/>
            </div>
        </VFlex>
    );
}