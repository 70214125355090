import { RouteInfo } from "../../../types/job";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    CloseIcon,
    NavigateIcon,
    OptimizeIcon,
    PuzzleIcon,
    SimulateIcon,
    SingleCheckIcon,
    ViewIcon
} from "../../ui/svg";
import { LabelSmall, LabelXSmall } from "baseui/typography";
import { Spacer, SpacerExpand } from "../../containers/Spacer";
import { Button } from "baseui/button";
import { useRouting } from "../../../hooks/useRouting";
import {
    constraintsState,
    routeSimulationRunningForState,
    routingRecentlyUnassignedState,
    routingUndoOptimizeState,
    selectedDayState
} from "../../../states/appState";
import { useRouteInfo } from "../../../hooks/useRouteInfo";
import { Popconfirm, Tag, Tooltip } from "antd";
import { useTheme } from "../../../hooks/useTheme";
import { startRouteSimulation, stopRouteSimulation } from "../../../services/api";
import { useToast } from "../../../hooks/useToast";
import { useCamera } from "../../../hooks/useCamera";
import { jobsByMemberOrUnassignedState } from "../../../states/jobsState";
import { HFlex } from "../../containers/HFlex";
import { LonLat, Uid } from "../../../types/core";
import { isOffDuty } from "../../../types/member";
import { memberState } from "../../../states/membersState";
import React, { memo } from "react";
import { RoutingSettingsButton } from "./RoutingSettingsButton";
import { useStrings } from "../../../hooks/useStrings";
import { Section } from "../../ui/Section";
import { VFlex } from "../../containers/VFlex";
import { JobBadge } from "../../badges/JobBadge";
import { useJobAssignment } from "../../../hooks/jobs/useJobAssignment";
import { useDayRouteUpdater } from "../../../hooks/useDayRouteUpdater";
import { routingStatusState } from "../../../states/viewState";
import { EllipsedText } from "../../ui/EllipsedText";
import { createConstraints } from "../../../utils/routing";
import { ConstraintsTiles } from "../routing/ConstraintsTiles";

type Props = {
    routeInfoObj: RouteInfo | undefined;
    memberUid: Uid;
};

function RouteInfoBanner({ routeInfoObj, memberUid }: Props) {
    const routeInfo = useRouteInfo();
    const { strings } = useStrings();
    const { theme } = useTheme();
    const day = useRecoilValue(selectedDayState);
    const toast = useToast();
    const isSimulationRunning = useRecoilValue(routeSimulationRunningForState(memberUid));
    const camera = useCamera();
    const routing = useRouting();
    const jobs = useRecoilValue(jobsByMemberOrUnassignedState({ uid: memberUid, day }));
    const member = useRecoilValue(memberState(memberUid));
    const undoOptimize = useRecoilValue(routingUndoOptimizeState({ uid: memberUid, day }));
    const [recentlyUnassigned, setRecentlyUnassigned] = useRecoilState(routingRecentlyUnassignedState({
        uid: memberUid,
        day
    }));
    const assign = useJobAssignment();
    const dayRouteUpdate = useDayRouteUpdater();
    const routingStatus = useRecoilValue(routingStatusState({ uid: memberUid, day: day }));
    const constraints = useRecoilValue(constraintsState);

    if (!member) {
        return null;
    }

    const onStartRouteSimulation = async () => {
        if (isOffDuty(member)) {
            toast.showWarning(strings.Routing.SimulationOffDutyDesc);
            return;
        }
        camera.fitToJobs(jobs);
        const { status } = await startRouteSimulation({ uid: memberUid, day: day });
        if (status) {
            toast.showSuccess(strings.General.Started);
        }
    };

    const onStopRouteSimulation = async () => {
        await stopRouteSimulation({ uid: memberUid, day: day });
    };

    const onViewClick = () => {
        if (routeInfoObj) {
            camera.fitToLngLats([...jobs.map(j => { return [j.destinationLng, j.destinationLat] as LonLat }),
                [routeInfoObj.startLocation.lng, routeInfoObj.startLocation.lat],
                [routeInfoObj.endLocation.lng, routeInfoObj.endLocation.lat]]);
        } else {
            camera.fitToJobs(jobs);
        }
    }

    const summary = routeInfoObj && routeInfo.summary(routeInfoObj);

    const title = routeInfoObj && routeInfoObj.optimized
        ? strings.Routing.OptimizedRoute + ": " + strings.General.Total
        : routeInfoObj
            ? strings.General.Total
            : routingStatus
                ? strings.Routing.Route
                : strings.Routing.RouteCalculationIssue;

    const buttonOverrides: any = {
        BaseButton: {
            style: {
                fontSize: '12px',
                paddingTop: '2px',
                paddingBottom: '2px',
                paddingLeft: '8px',
                paddingRight: '8px',
            },
        },
    };

    const onAcceptUnassignedClick = (jobId: string) => {
        setRecentlyUnassigned(old => old.filter(id => id !== jobId));
    };

    const onAcceptAllUnassignedClick = () => {
        setRecentlyUnassigned([]);
    };

    const onReassignUnassignedClick = async (jobId: string) => {
        setRecentlyUnassigned(old => old.filter(id => id !== jobId));
        await assign.bulkEdit([jobId], undefined, { assigneeUid: memberUid });
        await dayRouteUpdate.update(memberUid, day);
    };

    const onSolveClick = () => {
        routing.optimize(memberUid, true, createConstraints(constraints), (response) => {
            if (response.abort_reason) {
                if (response.abort_reason === "no_region") {
                    toast.showWarning(strings.Routing.NoRegionWarning);
                } else if (response.abort_reason == "impossible_route") {
                    toast.showWarning(strings.Routing.ImpossibleRouteWarning);
                } else {
                    toast.showWarning(strings.General.SomethingWentWrong + ": " + response.abort_reason);
                }
            }
        });
    };

    const onOptimizeClick = () => {
        if (jobs.length <= 150) {
            routing.optimize(memberUid);
        } else {
            toast.showWarning(strings.Routing.RouteOptimizationTooManyJobs);
        }
    };

    return (
        <Section defaultExpanded title={(
            <HFlex alignCenter>
                {title + (summary ? (" " + summary.totalDuration) : "")}
                <SpacerExpand/>
                <Tooltip title={strings.Routing.SeeFullRoute}>
                    <Button size={"mini"} kind={"tertiary"} onClick={(event) => {
                        event.stopPropagation();
                        onViewClick();
                    }} overrides={buttonOverrides}>
                        <ViewIcon color={theme.colors.contentPrimary} size={14}/>
                    </Button>
                </Tooltip>
                <Tooltip title={strings.Routing.CalculatedRoute}>
                    <Button size={"mini"} kind={"tertiary"} onClick={() => routing.calculate(memberUid)}
                            overrides={buttonOverrides}>
                        <NavigateIcon size={14} color={theme.colors.contentPrimary}/>
                    </Button>
                </Tooltip>
                <RoutingSettingsButton memberUid={memberUid} buttonOverrides={buttonOverrides}/>
                {isSimulationRunning ?
                    <Button size={"mini"} kind={"tertiary"} onClick={onStopRouteSimulation}
                            overrides={buttonOverrides}>
                        <SimulateIcon size={14} color={theme.customColors.accent}/>
                        <Spacer/>
                        {strings.Routing.StopSimulation}
                    </Button> :
                    <Popconfirm
                        title={strings.Routing.StartSimulation}
                        description={strings.Routing.StartSimulationDesc}
                        onConfirm={onStartRouteSimulation}
                        okText={strings.General.Start}
                    >
                        <Tooltip title={strings.Routing.StartSimulation}>
                            <Button size={"mini"} kind={"tertiary"} overrides={buttonOverrides}>
                                <SimulateIcon size={14} color={theme.colors.contentPrimary}/>
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                }
            </HFlex>
        )} expandable persistenceKey="routeinfobanner">
            {!routingStatus &&
                <LabelXSmall style={{
                    fontSize: "11px",
                    lineHeight: "13px",
                    paddingBottom: "8px",
                }}>
                    <EllipsedText>{strings.Routing.RouteCalculationFailed}</EllipsedText>
                </LabelXSmall>}
            {summary && <>
                <HFlex style={{ paddingBottom: "4px" }}>
                    <HFlex spacing alignCenter>
                        <Tag>
                            {strings.Routing.Travelling + ": " + summary.travelDuration + " | " + summary.totalDistance}
                        </Tag>
                        <Tag>
                            {strings.Routing.OnSiteTime + ": " + summary.onSiteDuration}
                        </Tag>
                        {summary.waitingDuration && <Tag>
                            {strings.Routing.WaitingTime + ": " + summary.waitingDuration}
                        </Tag>}
                    </HFlex>
                </HFlex>
                <Spacer/>
                {recentlyUnassigned.length > 0 && (
                    <VFlex spacing={"4px"}>
                        <HFlex spacing alignCenter>
                            <LabelXSmall>{strings.Routing.UnableToAssign}:</LabelXSmall>
                            {recentlyUnassigned.length >= 3 && (
                                <Button size={"mini"} kind={"secondary"} onClick={onAcceptAllUnassignedClick}>
                                    {strings.General.AcceptAll}
                                </Button>
                            )}
                        </HFlex>
                        {recentlyUnassigned.map(id => (
                            <HFlex alignCenter key={id}>
                                <JobBadge id={id} closable={false} maxChars={26}/>
                                <Tooltip title={strings.General.Accept}>
                                    <Button size={"mini"}
                                            kind={"tertiary"}
                                            overrides={buttonOverrides}
                                            onClick={() => onAcceptUnassignedClick(id)}
                                    >
                                        <SingleCheckIcon size={12} color={theme.customColors.accent}/>
                                    </Button>
                                </Tooltip>
                                <Tooltip title={strings.General.Reassign}>
                                    <Button size={"mini"}
                                            kind={"tertiary"}
                                            overrides={buttonOverrides}
                                            onClick={() => onReassignUnassignedClick(id)}
                                    >
                                        <CloseIcon size={12} color={theme.colors.contentSecondary}/>
                                    </Button>
                                </Tooltip>
                            </HFlex>
                        ))}
                        <LabelXSmall color={theme.colors.contentTertiary}>
                            {strings.Routing.UnableToAssignCaption}
                        </LabelXSmall>
                        <Spacer/>
                    </VFlex>
                )}
            </>}

            <HFlex spacing style={{ alignItems: "start" }}>
                {routeInfoObj?.optimized && undoOptimize.length > 0 &&
                    <Button size={"mini"} kind={"tertiary"} onClick={() => routing.undo(memberUid)}
                            overrides={buttonOverrides}>
                        <OptimizeIcon size={18} color={theme.colors.contentPrimary}/>
                        <Spacer/>
                        {strings.Routing.UndoOptimize}
                    </Button>}
                {!routeInfoObj?.optimized && <Tooltip
                    title={strings.Routing.OptimizeDistanceTooltip}>
                    <Popconfirm
                        title={strings.Routing.OptimizeRoute}
                        description={
                            <VFlex spacing={"4px"}>
                                <LabelSmall color={theme.colors.contentSecondary}>
                                    {strings.Routing.OptimizeRouteDesc}
                                </LabelSmall>
                                <LabelSmall color={theme.colors.contentSecondary}>
                                    {strings.Routing.OptimizeDistanceTooltip}
                                </LabelSmall>
                            </VFlex>
                        }
                        onConfirm={onOptimizeClick}
                        okText={strings.General.Start}
                    >
                        <Button size={"mini"}
                                kind={"tertiary"}
                                overrides={buttonOverrides}
                        >
                            <OptimizeIcon size={18} color={theme.colors.contentPrimary}/>
                            <Spacer width={"4px"}/>
                            {strings.Routing.OptimizeRoute}
                        </Button>
                    </Popconfirm>
                </Tooltip>}
                <Tooltip
                    title={strings.Routing.SolveRouteTooltip}>
                    <Popconfirm
                        title={(
                            <HFlex spacing alignCenter>
                                {strings.Routing.SolveRoute}
                                <Tag color={"gold"}>Beta</Tag>
                            </HFlex>
                        )}
                        description={
                            <VFlex spacing={"4px"} style={{ maxWidth: "500px" }}>
                                <LabelSmall color={theme.colors.contentSecondary}>
                                    {strings.Routing.OptimizeRouteDesc}
                                </LabelSmall>
                                <LabelSmall color={theme.colors.contentSecondary}>
                                    {strings.Routing.SolveRouteTooltip}
                                </LabelSmall>
                                <Spacer/>
                                <ConstraintsTiles/>
                            </VFlex>
                        }
                        onConfirm={onSolveClick}
                        okText={strings.General.Start}
                    >
                        <Button size={"mini"}
                                kind={"tertiary"}
                                overrides={buttonOverrides}>
                            <PuzzleIcon size={18} color={theme.colors.contentPrimary}/>
                            <Spacer width={"4px"}/>
                            {strings.Routing.SolveRoute}
                        </Button>
                    </Popconfirm>
                </Tooltip>
            </HFlex>
        </Section>
    );
}

export default memo(RouteInfoBanner);