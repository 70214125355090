import { DEVICE_MODE_BACKGROUNDED, DEVICE_MODE_FOREGROUNDED, DEVICE_MODE_SUSPENDED, Member } from "../../types/member";
import { useTime } from "../useTime";
import { useStrings } from "../useStrings";
import { ANDROID_BUILD, IOS_BUILD } from "../../constants/app";

export function useMemberStatusInfo(member: Member | undefined) {
    const { strings } = useStrings();
    const time = useTime();

    let trackingStatusText = member?.states.tracking_status ? strings.General.ActiveSwitchOn : strings.General.ServiceSwithOff;
    if (member && member.timestamps.tracking > 0) {
        trackingStatusText += " (" + time.formatDateTime(member.timestamps.tracking) + ")";
    }

    let loginStatusText = member?.states.login_status ? strings.General.LoggedIn : strings.General.LoggedOut;
    if (member && member.timestamps.login > 0) {
        loginStatusText += " (" + time.formatDateTime(member.timestamps.login) + ")";
    }

    const isAppleDevice = member?.device.manufacturer === "Apple";
    const isAndroidDevice = !isAppleDevice;
    const buildNumber = member?.device?.app_version;
    const isUpdateAvailable = buildNumber ? (isAppleDevice ? buildNumber < IOS_BUILD : buildNumber < ANDROID_BUILD) : false;

    let modeText = "";
    if (isAppleDevice) {
        if (member.device.mode === DEVICE_MODE_BACKGROUNDED) {
            modeText = strings.ProfileModal.AppRunningInBackground;
        } else if (member.device.mode === DEVICE_MODE_FOREGROUNDED) {
            modeText = strings.ProfileModal.AppRunningInForeground;
        } else if (member.device.mode === DEVICE_MODE_SUSPENDED) {
            modeText = strings.ProfileModal.AppSuspended;
        }
    }

    const isLoggedInAndActive = member?.states.login_status && member.states.tracking_status;

    const permissionText = member?.states.location_permission ? strings.General.LocationPermissionGranted : strings.General.LocationPermissionDenied;

    return {
        loginStatusText,
        trackingStatusText,
        isAppleDevice,
        isAndroidDevice,
        modeText,
        isLoggedInAndActive,
        permissionText,
        isUpdateAvailable,
    };
}