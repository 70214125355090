import { Button } from 'baseui/button';
import { useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useEffectOnce } from 'usehooks-ts';
import { useStyleOverrides } from '../../../hooks/useStyleOverrides';
import { stringsState } from '../../../states/localeState';
import {
    pickerMembersConfigState,
    pickerMembersState,
    pickerSelectedMembersState
} from '../../../states/membersPickerState';
import { Member } from '../../../types/member';
import { SpacerExpand } from '../../containers/Spacer';
import { MemberListPicker } from '../../pickers/MemberListPicker';
import { StyledModalContent } from '../../ui/StyledModalContent';

type Props = {
    closeModal: () => void,
    onAccept: (members: Member[]) => Promise<void>,
    multi?: boolean;
    title?: React.ReactNode;
    extraActions?: React.ReactNode;
    helper?: React.ReactNode;
}

export function MembersPickerModalContent({ closeModal, onAccept, title, multi = true, extraActions, helper }: Props) {
    const strings = useRecoilValue(stringsState);
    const selectedMembers = useRecoilValue(pickerSelectedMembersState);
    const resetPickerMembers = useResetRecoilState(pickerMembersState);
    const resetPickerMemberConfig = useResetRecoilState(pickerMembersConfigState);
    const [isLoading, setIsLoading] = useState(false);
    const { cancelButtonStyles } = useStyleOverrides();

    if (!title) {
        title = strings.Members.SelectMembers;
    }

    useEffectOnce(() => {
        return () => {
            resetPickerMembers();
            resetPickerMemberConfig();
        }
    });

    const handleAccept = async () => {
        setIsLoading(true);
        await onAccept([...selectedMembers]);
        setIsLoading(false);
        closeModal();
    }

    return (
        <StyledModalContent
            header={title}
            body={
                <>
                    <MemberListPicker multi={multi}/>
                    {helper}
                </>
            }
            footer={
                <>
                    {extraActions}
                    <SpacerExpand/>
                    <Button
                        onClick={closeModal}
                        size='compact'
                        kind='tertiary'
                        overrides={cancelButtonStyles}
                    >
                        {strings.General.Cancel}
                    </Button>
                    <Button
                        onClick={handleAccept}
                        size='compact'
                        isLoading={isLoading}
                    >
                        {strings.General.Accept}
                    </Button>
                </>
            }
        />
    );
}