import { PlacesIcon, StopWatchIcon } from "../ui/svg";
import { getColorFromProgress, numberOrLetter } from "../../utils/jobUtils";
import { VFlex } from "../containers/VFlex";
import { HFlex } from "../containers/HFlex";
import { LabelXSmall } from "baseui/typography";
import { Badge } from "antd";
import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { TrackOrStopEntry } from "../../types/track";
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { jobState } from "../../states/jobsState";
import { placeState } from "../../states/placeState";
import FixedJob from "../map/markers/FixedJob";

export function LocationCell({ entry }: { entry: TrackOrStopEntry }) {
    const { theme } = useTheme();
    const job = entry.job && getRecoil(jobState(entry.job));
    const place = entry.place ? getRecoil(placeState(entry.place)) : undefined;
    const isStop = entry.kind === "stop";
    const isTrack = entry.kind === "track";

    const ellipsisStyle: any = {
        fontSize: "10px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        lineHeight: "13px",
        textOverflow: "ellipsis"
    };

    const iconSize = 16;

    return (
        <HFlex spacing alignCenter style={{ flexShrink: 0 }}>
            {isTrack &&
                <VFlex>
                    <HFlex spacing alignCenter style={{ flexShrink: 0 }}>
                        <Badge size={"small"} color={"black"} style={{ color: "white" }} count={entry.startLetter}/>
                        <LabelXSmall style={ellipsisStyle}>
                            {entry.startText}
                        </LabelXSmall>
                    </HFlex>
                    <div style={{ marginLeft: "7px", width: "1px", height: "4px", backgroundColor: "black" }}/>
                    <HFlex spacing alignCenter style={{ flexShrink: 0 }}>
                        <Badge size={"small"} color={"black"} style={{ color: "white" }} count={entry.endLetter}/>
                        <LabelXSmall style={ellipsisStyle}>
                            {entry.endText}
                        </LabelXSmall>
                    </HFlex>
                </VFlex>
            }
            <VFlex>
                <HFlex alignCenter spacing>
                    {isStop && <>
                        {isStop && entry.place &&
                            <PlacesIcon size={iconSize}
                                        color={place?.color || theme.colors.contentTertiary}
                                        style={{ flexShrink: 0 }}/>}
                        {isStop && job &&
                            <FixedJob size={iconSize}
                                      color={getColorFromProgress(job)}
                                      style={{ flexShrink: 0 }}
                                      text={numberOrLetter(job)}
                            />}
                        {isStop && entry.at === "between_tracks" &&
                            <StopWatchIcon size={iconSize}
                                           color={theme.colors.contentPrimary}
                                           style={{ flexShrink: 0 }}
                            />}
                    </>}
                    {entry.title &&
                        <LabelXSmall style={ellipsisStyle}>
                            {entry.title}
                        </LabelXSmall>
                    }
                </HFlex>
            </VFlex>
        </HFlex>
    );
}