import { HFlex } from "../containers/HFlex";
import { LabelXSmall } from "baseui/typography";
import { TimelineEntry, TimelineType } from "../../types/timeline";
import { useTime } from "../../hooks/useTime";
import { useTheme } from "../../hooks/useTheme";
import { useHover } from "usehooks-ts";
import { useRef } from "react";
import { useStrings } from "../../hooks/useStrings";
import { useCamera } from "../../hooks/useCamera";
import { EllipsedText } from "../ui/EllipsedText";
import {
    ClockOutlineIcon,
    DoubleCheckIcon,
    FlagDiagonalIcon,
    SingleCheckIcon,
    ThumbDownIcon,
    ThumbUpIcon
} from "../ui/svg";
import { PROGRESS_STATUS_ISSUE, PROGRESS_STATUS_SUCCESS } from "../../types/job";
import { getProgressColor } from "../../utils/jobUtils";
import { Clickable } from "../containers/Clickable";
import { useSetRecoilState } from "recoil";
import { editingJobIdState, editingPlaceIdState } from "../../states/appState";
import { SpacerExpand } from "../containers/Spacer";
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { jobState } from "../../states/jobsState";
import { COLOR_MARINE } from "../../constants/colors";

type Props = {
    entry: TimelineEntry;
}

export function Event({ entry }: Props) {
    const { strings, format } = useStrings();
    const { theme } = useTheme();
    const time = useTime();
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const camera = useCamera();
    const setEditingJob = useSetRecoilState(editingJobIdState);
    const setEditingPlace = useSetRecoilState(editingPlaceIdState);

    let label = "";
    let icon = <FlagDiagonalIcon size={10} color={theme.colors.contentPrimary}/>;

    const job = entry.jobId && getRecoil(jobState(entry.jobId));
    const number = job && job.number > 0 ? " #" + job?.number : "";
    const jobTitle = (entry.jobTitle || strings.General.NoName) + number;

    if (entry.type === TimelineType[TimelineType.LOG_IN].toLowerCase()) {
        label = strings.Alerts.AlertLoggedIn;
    } else if (entry.type === TimelineType[TimelineType.LOG_OUT].toLowerCase()) {
        label = strings.Alerts.AlertLoggedOut;
    } else if (entry.type === TimelineType[TimelineType.START_WORKING_HOURS].toLowerCase()) {
        label = strings.Alerts.StartOfWorkingHours;
        icon = <ClockOutlineIcon size={10} color={theme.colors.contentPrimary}/>
    } else if (entry.type === TimelineType[TimelineType.END_WORKING_HOURS].toLowerCase()) {
        label = strings.Alerts.EndOfWorkingHours;
        icon = <ClockOutlineIcon size={10} color={theme.colors.contentPrimary}/>
    } else if (entry.type === TimelineType[TimelineType.CLOCK_IN].toLowerCase()) {
        label = strings.Alerts.ClockInSwitch;
    } else if (entry.type === TimelineType[TimelineType.CLOCK_OUT].toLowerCase()) {
        label = strings.Alerts.ClockOutService;
    } else if (entry.type === TimelineType[TimelineType.CHECK_IN].toLowerCase()) {
        label = format(entry.info === "manual" ? strings.Alerts.ManualCheckInAtX : strings.Alerts.CheckInAtX,
            entry.placeNameStart || strings.General.NoName).toString();
        icon = <SingleCheckIcon size={10} color={theme.colors.contentPrimary}/>
    } else if (entry.type === TimelineType[TimelineType.CHECK_OUT].toLowerCase()) {
        label = format(entry.info === "manual" ? strings.Alerts.ManualCheckOutAtX : strings.Alerts.CheckOutAtX,
            entry.placeNameStart || strings.General.NoName).toString();
        icon = <DoubleCheckIcon size={10} color={theme.colors.contentPrimary}/>
    } else if (entry.type === TimelineType[TimelineType.JOB_CHECK_IN].toLowerCase()) {
        label = format(strings.Alerts.ArrivedAtJobX, jobTitle).toString();
        icon = <SingleCheckIcon size={10} color={COLOR_MARINE}/>
    } else if (entry.type === TimelineType[TimelineType.JOB_CHECK_OUT].toLowerCase()) {
        label = format(strings.Alerts.LeftJobX, jobTitle).toString();
        icon = <DoubleCheckIcon size={10} color={theme.customColors.accent}/>
    } else if (entry.type === TimelineType[TimelineType.JOB_ISSUE].toLowerCase()) {
        label = format(strings.Alerts.IssueMarkedJobX, jobTitle).toString();
        icon = <ThumbDownIcon size={10} color={getProgressColor(PROGRESS_STATUS_ISSUE)}/>
    } else if (entry.type === TimelineType[TimelineType.JOB_COMPLETED].toLowerCase()) {
        label = format(strings.Alerts.CompletedMarkedJobX, jobTitle).toString();
        icon = <ThumbUpIcon size={10} color={getProgressColor(PROGRESS_STATUS_SUCCESS)}/>
    } else {
        label = entry.type;
    }

    const hasLocation = entry.latStart && entry.lngStart;

    const onClick = () => {
        if (hasLocation) {
            camera.zoomWithTempMarker({ lat: entry.latStart, lng: entry.lngStart }, label);
        }
    };

    const color = isHover ? theme.colors.contentPrimary : theme.colors.contentSecondary;
    const style = hasLocation ? { cursor: "pointer" } : {};

    return (
        <div ref={hoverRef} style={style} onClick={onClick}>
            <HFlex style={{ gap: theme.sizing.scale400, minHeight: "40px", alignItems: "center" }}>
                <div style={{flexShrink: 0}}>
                    {icon}
                </div>
                <LabelXSmall color={color}
                             overrides={{ Block: { style: { minWidth: '54px' } } }}
                >
                    {time.formatTimeOnly(entry.tsStart)}
                </LabelXSmall>
                <LabelXSmall color={color}>
                    <EllipsedText maxLines={2}>{label}</EllipsedText>
                </LabelXSmall>
                <SpacerExpand/>
                {(entry.jobId || entry.placeUidStart) &&
                    <Clickable onClick={() => {
                        if (entry.jobId) {
                            setEditingJob(entry.jobId);
                        } else {
                            setEditingPlace(entry.placeUidStart);
                        }
                    }}>
                        <LabelXSmall>{strings.General.Open}</LabelXSmall>
                    </Clickable>
                }
            </HFlex>
        </div>
    );
}