import { Layer, Source } from "react-map-gl";
import { usePlacesLayerStyle } from "../mapStyle";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { placesLayerState } from "../../../states/geojsonState";
import { PAGE } from "../../../constants/pages";
import { mapInteractiveLayersState, showPlacesLayerState } from "../../../states/mapState";
import { pageState } from "../../../states/appState";
import { useEffect } from "react";

export function PlacesLayer() {
    const ID = "places";
    const placesLayer = useRecoilValue(placesLayerState);
    const { placesLayerStyle, placeNamesLayerStyle } = usePlacesLayerStyle();
    const showPlacesLayer = useRecoilValue(showPlacesLayerState);
    const page = useRecoilValue(pageState);
    const setInteractiveLayers = useSetRecoilState(mapInteractiveLayersState);
    const render = (showPlacesLayer || page === PAGE.PLACES) && page !== PAGE.HEATMAP && placesLayer;

    useEffect(() => {
        setInteractiveLayers((old) => render
            ? [...[...old].filter(id => id !== ID), ID]
            : [...old].filter(id => id !== ID)
        );
    }, [render]);

    if (!render) {
        return null;
    }

    return (
        <Source cluster={false} id={ID} type="geojson" data={placesLayer}>
            <Layer {...placesLayerStyle} />
            <Layer minzoom={14} {...placeNamesLayerStyle} />
        </Source>
    );
}