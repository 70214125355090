import { Input } from "baseui/input";
import { StatefulPopover } from "baseui/popover";
import { Color, TwitterPicker } from "react-color";
import {
    COLOR_BLACK,
    COLOR_BLUE,
    COLOR_GRAY,
    COLOR_GREEN,
    COLOR_ORANGE,
    COLOR_PURPLE,
    COLOR_RED,
    COLOR_YELLOW
} from "../../constants/colors";
import { useStrings } from "../../hooks/useStrings";
import { HFlex } from "../containers/HFlex";

type Props = {
    selectedColor?: Color;
    onChange: (color: string) => void;
}

export function ColorPicker({ selectedColor, onChange }: Props) {
    const { strings } = useStrings();
    const colors = [
        COLOR_GREEN,
        COLOR_YELLOW,
        COLOR_ORANGE,
        COLOR_RED,
        COLOR_PURPLE,
        COLOR_BLUE,
        COLOR_GRAY,
        COLOR_BLACK,
    ];

    return (
        <HFlex spacing alignCenter>
            {selectedColor && <div style={{
                backgroundColor: selectedColor.toString(),
                width: "18px",
                height: "18px",
                borderRadius: "15px",
                flexShrink: 0,
            }}/>}
            <StatefulPopover
                content={({ close }) => (
                    <TwitterPicker
                        color={selectedColor}
                        colors={colors}
                        triangle="hide"
                        onChange={(c) => onChange(c.hex)}
                    />
                )}
                triggerType="click"
                placement="bottomLeft"
            >
                <span>
                    <Input placeholder={strings.General.Color}
                           value={selectedColor?.toString()}
                           size="compact"
                           overrides={{
                               Root: {
                                   style: {
                                       maxWidth: "100px"
                                   }
                               }
                           }}
                           readOnly/>
                </span>
            </StatefulPopover>
        </HFlex>
    )
}