import { Radio, RadioGroup } from "baseui/radio";
import { Button } from "baseui/button";
import { Delete, Plus } from "baseui/icon";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { StyledSelect } from "../ui/StyledSelect";
import { arrayMove, arrayRemove, List } from "baseui/dnd-list";
import { LabelXSmall } from "baseui/typography";
import { Checkbox } from "baseui/checkbox";
import { Input } from "baseui/input";
import { Textarea } from "baseui/textarea";
import { FileIcon, ImageIcon, SignatureIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";
import { HFlex } from "../containers/HFlex";
import { useEffect, useState } from "react";
import { FormItem } from "../../types/form";

type FormItemElementProps = {
    formItem: FormItem;
    onSetOptions: (options: string[]) => void;
    canEdit?: boolean;
}

export function FormItemElement(props: FormItemElementProps) {
    const {
        formItem,
        onSetOptions,
        canEdit = true
    } = props;
    const { theme } = useTheme();
    const { strings } = useStrings();
    const [newOption, setNewOption] = useState("");

    const item = formItem;

    useEffect(() => {
        if (newOption.includes("\n") || newOption.includes("\t")) {
            const newOptions: string[] = newOption
                .split(/\n|\t/)
                .map((option) => option.trim())
                .filter((option) => option.length > 0);

            onSetOptions([...item.options, ...newOptions]);
            setNewOption("");
        }
    }, [newOption]);

    const onRemoveOption = (option: string) => {
        onSetOptions(item.options.filter(o => o !== option));
    };

    const onAddOption = () => {
        setNewOption("");
        onSetOptions([...item.options, newOption]);
    }

    const addOptionRow = (item.type === "dropdown" || item.type === "radio") &&
        <HFlex>
            <Textarea
                placeholder={strings.Forms.FormsAddOption}
                overrides={{
                    Root: {
                        style: {
                            width: "120px"
                        }
                    }
                }}
                size={"mini"}
                rows={1}
                value={newOption}
                onChange={value => setNewOption(value.target.value)}
            />
            <Spacer/>
            {newOption.length > 0 &&
                <Button size={"mini"} kind={"secondary"} onClick={onAddOption}>
                    <Plus/> {strings.Forms.FormsAddOption}
                </Button>
            }
            <SpacerExpand/>
        </HFlex>;

    return (
        <>
            {item.type === "radio" && (
                <>
                    <RadioGroup
                        name="number"
                        align={"vertical"}
                    >
                        {item.options.map((o, index) =>
                            <Radio key={index}>
                                {o}
                                {canEdit &&
                                    <Button size={"mini"} kind={"tertiary"} onClick={() => onRemoveOption(o)}>
                                        <Delete color={theme.colors.contentTertiary}/>
                                    </Button>}
                            </Radio>)}
                    </RadioGroup>
                    <Spacer/>
                    {addOptionRow}
                </>
            )}
            {item.type === "dropdown" && (
                <>
                    <StyledSelect placeholder={strings.Forms.FormsDropdown} size={"mini"} disabled/>
                    <List
                        overrides={{
                            Item: {
                                style: {
                                    backgroundColor: "transparent"
                                }
                            }
                        }}
                        removable
                        items={item.options}
                        onChange={({ oldIndex, newIndex }) => {
                            const items = item.options;
                            const newItems = newIndex === -1
                                ? arrayRemove(items, oldIndex)
                                : arrayMove(items, oldIndex, newIndex);
                            onSetOptions(newItems);
                        }}
                    />
                    {addOptionRow}
                </>
            )}
            {item.type === "section" && (
                <LabelXSmall>{strings.Forms.FormNewSection}</LabelXSmall>
            )}
            {item.type === "label" && (
                <LabelXSmall>{strings.Forms.FormsLabel}</LabelXSmall>
            )}
            {item.type === "checkbox" && (
                <Checkbox disabled>{strings.Forms.FormsCheckbox}</Checkbox>
            )}
            {item.type === "text" && (
                <Input size={"mini"} value={`--- ${strings.Forms.FormsSingleLineInput} ---`}/>
            )}
            {item.type === "paragraph" && (
                <Textarea size={"mini"} value={`${strings.Forms.FormsMultilineInput}\n---\n---`}/>
            )}
            {item.type === "number" && (
                <Input size={"mini"} value={"### (" + strings.Forms.FormsNumber + ")"}/>
            )}
            {item.type === "decimal" && (
                <Input size={"mini"} value={"###.## (" + strings.Forms.FormsDecimal + ")"}/>
            )}
            {item.type === "image" && (
                <ImageIcon color={theme.colors.contentTertiary} size={64}/>
            )}
            {item.type === "signature" && (
                <SignatureIcon color={theme.colors.contentTertiary} size={64}/>
            )}
            {item.type === "file" && (
                <FileIcon color={theme.colors.contentTertiary} size={64}/>
            )}
        </>
    );
}