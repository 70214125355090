import { memo } from "react";
import { Member } from "../../types/member";
import { UnorderedList } from "../containers/UnorderedList";
import MemberItem from "../member/MemberItem";
import { HoverButton } from "../buttons/HoverButton";
import { RemoveIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";

type Props = {
    members: Member[];
    canEdit?: boolean;
    onRemoveMember?: (member: Member) => void;
}

function TeamMembers({ members, canEdit = false, onRemoveMember }: Props) {
    const { theme } = useTheme();
    const { strings } = useStrings();

    return (
        <UnorderedList>
            {members.map((member, index) =>
                <MemberItem
                    key={index}
                    uid={member.uid}
                    extraIcon={canEdit &&
                        <HoverButton size={"mini"}
                                     tooltip={strings.TeamManagement.RemoveFromTeam}
                                     onClick={() => onRemoveMember && onRemoveMember(member)}>
                            <RemoveIcon size={12} color={theme.colors.contentTertiary}/>
                        </HoverButton>}
                />)}
        </UnorderedList>
    );
}

export default memo(TeamMembers)