import { useTheme } from "./useTheme";
import { fixedColumnStyle } from "../constants/ui";
import { FormControlOverrides } from "baseui/form-control";

export function useStyleOverrides() {
    const { theme, css } = useTheme();

    const readOnlyInputStyles = {
        InputContainer: {
            style: {
                backgroundColor: theme.colors.backgroundPrimary,
            }
        }
    };

    const cancelButtonStyles = {
        BaseButton: {
            style: {
                marginRight: theme.sizing.scale400
            }
        }
    };

    const linkStyles = css({
        marginBottom: theme.sizing.scale550,
        cursor: "pointer",
    });

    const checkboxStyles = {
        Label: {
            style: {
                fontSize: theme.sizing.scale550,
            },
        },
    };

    const labelEllipsisStyles = {
        Block: {
            style: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        }
    }

    const gridStyle = (isSelected: boolean): any => {
        return {
            borderBottomWidth: "1px",
            borderTopWidth: "0px",
            borderLeftWidth: "0px",
            borderRightWidth: "0px",
            borderStyle:  "solid",
            borderColor: "#00000019",
            backgroundColor: isSelected ? theme.customColors.accent + "20" : undefined,
        }
    };

    const tableStyle = {
        height: "100%",
        width: "calc(100% - 2px)",
        overflow: "hidden",
        backgroundColor: "unset",
    };

    const tableHeaderLabelStyle = {
        color: theme.colors.contentTertiary,
        fontSize: theme.typography.LabelXSmall.fontSize,
        fontWeight: theme.typography.LabelXSmall.fontWeight,
    }

    const zonesColumnStyle = [
        fixedColumnStyle(50), // select
        fixedColumnStyle(80), // color
        fixedColumnStyle(250, true), // name
        fixedColumnStyle(160), // created date
    ];

    const placesColumnStyle = [
        fixedColumnStyle(50), // select
        fixedColumnStyle(80), // color
        fixedColumnStyle(250, true), // name
        fixedColumnStyle(100), // radius 
        fixedColumnStyle(160), // created date
    ];

    const statsFontSize = {};
    const statsWidth = 100;

    const statsColumnStyle = [
        fixedColumnStyle(200, true, statsFontSize), // name
        fixedColumnStyle(statsWidth, false, statsFontSize), // totalDist
        fixedColumnStyle(statsWidth, false, statsFontSize), // avg
        fixedColumnStyle(statsWidth, false, statsFontSize), // max
        fixedColumnStyle(statsWidth, false, statsFontSize), // totalTracks
        fixedColumnStyle(statsWidth, false, statsFontSize), // totalJobs
        fixedColumnStyle(statsWidth, false, statsFontSize), // ontimeCount
        fixedColumnStyle(statsWidth, false, statsFontSize), // delaysCount
        fixedColumnStyle(statsWidth, false, statsFontSize), // successCount
        fixedColumnStyle(statsWidth, false, statsFontSize), // issueCount
        fixedColumnStyle(statsWidth, false, statsFontSize), // engaged
        fixedColumnStyle(statsWidth, false, statsFontSize), // total job onsite
        fixedColumnStyle(statsWidth, false, statsFontSize), // avg job onsite
        fixedColumnStyle(statsWidth, false, statsFontSize), // total place onsite
        fixedColumnStyle(statsWidth, false, statsFontSize), // avg place onsite
        fixedColumnStyle(statsWidth, false, statsFontSize), // total pause
        fixedColumnStyle(statsWidth, false, statsFontSize), // avg pause
        fixedColumnStyle(statsWidth, false, statsFontSize), // totalTravelTime
        fixedColumnStyle(statsWidth, false, statsFontSize), // avgTravelTime
        fixedColumnStyle(statsWidth, false, statsFontSize), // totalTimeBetweenJobs
        fixedColumnStyle(statsWidth, false, statsFontSize), // avgTimeBetweenJobs
    ];

    const activityLogColumnStyle = [
        fixedColumnStyle(200), // name
        fixedColumnStyle(170), // date and time
        fixedColumnStyle(200, true), // activity
    ];

    const formControlSpacing: FormControlOverrides = {
        ControlContainer: {
            style: {
                marginTop: "0px",
                marginBottom: "0px",
            }
        },
        LabelContainer: {
            style: {
                marginTop: "8px",
                marginBottom: "0px",
                paddingTop: "0px",
                paddingBottom: "4px",
            }
        },
        Label: {
            style: {
                marginTop: "0px",
                marginBottom: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
            }
        },
        Caption: {
            style: {
                marginTop: "0px",
                marginBottom: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
            }
        },
    }

    return {
        readOnlyInputStyles,
        cancelButtonStyles,
        linkStyles,
        checkboxStyles,
        labelEllipsisStyles,
        gridStyle,
        tableStyle,
        tableHeaderLabelStyle,
        zonesColumnStyle,
        placesColumnStyle,
        statsColumnStyle,
        activityLogColumnStyle,
        formControlSpacing,
        fixedColumnStyle,
    }
}