import React, { memo, ReactNode, useState } from "react";
import { useTheme } from "../../../hooks/useTheme";
import { useStrings } from '../../../hooks/useStrings';
import { ColumnCard } from "../../board/ColumnCard";
import { COLOR_ACCENT } from "../../../constants/colors";
import JobItem from "../JobItem";
import { useRecoilState, useRecoilValue } from "recoil";
import { clustersState } from "../../../states/clusteringState";
import { Spacer, SpacerExpand } from "../../containers/Spacer";
import { LabelMedium, LabelXSmall } from "baseui/typography";
import { HFlex } from "../../containers/HFlex";
import { Button } from "baseui/button";
import { CheckCircledIcon, EditIcon, ViewIcon } from "../../ui/svg";
import { Input } from "baseui/input";
import { Cluster, getClusterColor } from "../../../types/cluster";
import { FlexGridLayout } from "../../containers/FlexGridLayout";
import { VFlex } from "../../containers/VFlex";
import { jobState, selectedJobIdsState } from "../../../states/jobsState";
import { ClusteringConfig } from "./ClusteringConfig";
import { NumberedStep, ProgressSteps } from "baseui/progress-steps";
import { AllJobsGrid } from "../../alljobs/AllJobsGrid";
import { ArrowLeft, ArrowRight } from "baseui/icon";
import { Alert } from "antd";
import { useCamera } from "../../../hooks/useCamera";
import { getRecoil } from "../../../providers/RecoilAccessProvider";
import { Job } from "../../../types/job";
import { Member } from "../../../types/member";
import { MemberSelect } from "../../pickers/MemberSelect";
import { useJobAssignment } from "../../../hooks/jobs/useJobAssignment";
import { dayState } from "../../../states/appState";
import { UidDay } from "../../../types/core";
import { UnassignedAllOrSelected } from "../UnassignedAllOrSelected";
import { JobSearch } from "../JobSearch";

export const ClusterColumn = memo(() => {
    const { format, strings } = useStrings();
    const { theme } = useTheme();
    const [clusters, setClusters] = useRecoilState(clustersState);
    const [isEditingCluster, setEditingCluster] = useState<Cluster>();
    const [name, setName] = useState("");
    const jobs = useRecoilValue(selectedJobIdsState);
    const [current, setCurrent] = useState(0);
    const camera = useCamera();
    const jobAssignment = useJobAssignment();
    const day = useRecoilValue(dayState);

    const children: ReactNode[] = [];
    const maxTotal = 250;

    const onViewClick = (cluster: Cluster) => {
        const jobs = cluster.jobs.map(id => getRecoil(jobState(id)) as Job).filter(job => !!job);
        camera.fitToJobs(jobs);
    };

    const updateClusters = (newCluster: Cluster, clusterIdx: number) => {
        setClusters([...clusters.slice(0, clusterIdx), newCluster, ...clusters.slice(clusterIdx + 1)]);
    }

    const onApplyClick = async (cluster: Cluster, clusterIdx: number) => {
        const uidDays = cluster.jobs.map(j => { return { uid: cluster.assignee?.uid, day } as UidDay });
        await jobAssignment.bulkEdit(cluster.jobs, uidDays, { day, assigneeUid: cluster.assignee?.uid });
        const newCluster = {
            ...cluster,
            assigned: true,
        }
        updateClusters(newCluster, clusterIdx);
    }

    for (let clusterIdx = 0; clusterIdx < clusters.length; clusterIdx++) {
        const cluster = clusters[clusterIdx];
        const color = getClusterColor(cluster)
        const isEditingName = cluster === isEditingCluster;
        children.push(
            <HFlex key={"cluster-header-" + clusterIdx} alignCenter style={{ marginTop: "40px" }}>
                <SpacerExpand/>
                {!isEditingName &&
                    <>
                        <LabelMedium color={theme.colors.contentSecondary}>{cluster.name}</LabelMedium>
                        <Spacer width={"4px"}/>
                        <LabelXSmall color={theme.colors.contentTertiary}>({cluster.jobs.length})</LabelXSmall>
                        <Spacer width={"16px"}/>
                        {!cluster.assigned &&
                            <Button size={"mini"} kind={"tertiary"} onClick={() => {
                                setEditingCluster(cluster);
                                setName(cluster.name);
                            }}>
                                <EditIcon size={9} color={theme.colors.contentSecondary}/>
                            </Button>
                        }
                        <Button size={"mini"} kind={"tertiary"} onClick={() => onViewClick(cluster)}>
                            <ViewIcon size={9} color={theme.colors.contentSecondary}/>
                        </Button>
                        {!cluster.assigned &&
                            <HFlex style={{ width: "160px" }}>
                                <MemberSelect
                                    onMemberSelect={(member: Member) => {
                                        const copy = {
                                            ...cluster,
                                            assignee: member
                                        } as Cluster;
                                        updateClusters(copy, clusterIdx);
                                    }}
                                    placeholder={strings.General.SelectAssignee}
                                    selectedMember={cluster.assignee}
                                    avatar={false}
                                    hideUnassigned
                                />
                            </HFlex>
                        }
                        {cluster.assignee && !cluster.assigned && <Spacer/>}
                        {cluster.assignee && !cluster.assigned &&
                            <Button size={"compact"} onClick={() => onApplyClick(cluster, clusterIdx)}>
                                {strings.General.Apply}
                            </Button>
                        }
                    </>
                }
                {isEditingName &&
                    <Input size={"mini"} value={name} onChange={val => setName(val.target.value)}></Input>}
                {isEditingName &&
                    <Button size={"mini"} kind={"tertiary"} onClick={() => {
                        setEditingCluster(undefined);
                        const newCluster = { ...cluster, name: name } as Cluster;
                        updateClusters(newCluster, clusterIdx);
                    }}>
                        {strings.General.OK}
                    </Button>
                }
                <SpacerExpand/>
            </HFlex>
        );
        if (!cluster.assigned) {
            const layout = (
                <FlexGridLayout key={"cluster-body-" + clusterIdx} baseWidth={220}>
                    {cluster.jobs.map(id => <JobItem key={id}
                                                     id={id}
                                                     color={color}
                                                     showCheckmark={false} showHandle={false}/>)}
                </FlexGridLayout>
            );
            children.push(layout);
        } else {
            children.push(
                <HFlex key={"cluster-body-" + clusterIdx} spacing alignCenter style={{ marginTop: "8px" }}>
                    <SpacerExpand/>
                    <CheckCircledIcon size={24} color={getClusterColor(cluster)}/>
                    <LabelXSmall>
                        {strings.General.AssignedTo} {cluster.assignee?.name || cluster.assignee?.username}
                    </LabelXSmall>
                    <SpacerExpand/>
                </HFlex>
            );
        }
    }

    const selectedJobsString = jobs.length === 1
        ? strings.Job.OneSelectedJob
        : format(strings.Job.XSelectedJobs, jobs.length);

    return (
        <ColumnCard
            color={COLOR_ACCENT}
            title={strings.Clustering.Clustering}
        >
            <Alert showIcon type={"info"} style={{ marginTop: "8px", marginBottom: "8px" }} description={
                <LabelXSmall>{strings.Clustering.Info}</LabelXSmall>
            }
                   closable/>
            <ProgressSteps current={current}>
                <NumberedStep
                    title={current === 0
                        ? format(strings.Clustering.SelectUpToXJobsForClustering, maxTotal) + (jobs.length > 0 ? " (" + selectedJobsString + ")" : "")
                        : selectedJobsString}>
                    <VFlex spacing style={{ height: "400px" }}>
                        <HFlex alignCenter spacing>
                            <JobSearch/>
                            <SpacerExpand/>
                            <UnassignedAllOrSelected/>
                        </HFlex>
                        <AllJobsGrid unassigned/>
                        <HFlex>
                            <Button
                                size="compact"
                                onClick={() => setCurrent(1)}
                                shape={"pill"}
                                kind={"secondary"}
                                disabled={jobs.length <= 1 || jobs.length > maxTotal}
                            >
                                {strings.General.Next}
                                <Spacer/>
                                <ArrowRight size={16} color={theme.colors.contentPrimary}/>
                            </Button>
                        </HFlex>
                    </VFlex>
                </NumberedStep>
                <NumberedStep title={format(strings.Clustering.ClusteringXSelectedJobs, jobs.length)}>
                    <Button
                        size="compact"
                        onClick={() => setCurrent(0)}
                        kind={"secondary"}
                        shape={"pill"}
                    >
                        <ArrowLeft size={16} color={theme.colors.contentPrimary}/>
                        <Spacer/>
                        {strings.General.Back}
                    </Button>
                    <ClusteringConfig/>
                    {children}
                </NumberedStep>
            </ProgressSteps>
        </ColumnCard>
    );
});
