import { tinyTag } from "../../constants/ui";
import { UserPositionOutlineIcon } from "../ui/svg";
import { COLOR_MOVING } from "../../constants/colors";
import { Tag, Tooltip } from "antd";
import { useTime } from "../../hooks/useTime";
import { useRecoilValue } from "recoil";
import { memberState } from "../../states/membersState";
import { useStrings } from "../../hooks/useStrings";
import { DAY } from "../../utils/time";
import { memo } from "react";
import { Uid } from "../../types/core";

export const LastLocationBadge = memo(({ memberUid, tiny }: { memberUid: Uid, tiny?: boolean }) => {
    const time = useTime();
    const { strings } = useStrings();
    const member = useRecoilValue(memberState(memberUid));

    if (!member?.location?.ts) {
        return null;
    }

    const dateText = time.formatDateOnly(member.location.ts) ;
    const startText = time.formatTimeOnly(member.location.ts);
    const timeText = member.location.ts < Date.now() - DAY * 30 ? dateText : dateText + " " + startText;

    return (
        <Tooltip title={strings.General.LastLocation + " " + time.formatExactTime(member.location.ts)}>
            <Tag style={tiny ? tinyTag : undefined} color={"blue"} icon={
                <UserPositionOutlineIcon size={9} color={COLOR_MOVING}/>}>
                {" " + timeText}
            </Tag>
        </Tooltip>
    );
});