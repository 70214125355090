import { ChatFillIcon, HelpFillIcon, SettingsIcon } from "../ui/svg";
import { HFlex } from "../containers/HFlex";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ignoreSettingState, showBeaconState } from "../../states/appState";
import { CreateMenu } from "../menu/CreateMenu";
import { useTheme } from "../../hooks/useTheme";
import { ActionButton } from "../buttons/ActionButton";
import { useProSidebar } from "react-pro-sidebar";
import { GlobalSearch } from "./GlobalSearch";
import { useStrings } from "../../hooks/useStrings";
import { newMessagesCountState } from "../../states/messagesState";
import { PAGE } from "../../constants/pages";
import { Badge } from "antd";
import { useNavigator } from "../../hooks/useNavigator";
import { AssistantButton } from "../assistant/AssistantButton";
import useAccount from "../../hooks/useAccount";
import { isLocationOnlyPlan } from "../../utils/account";
import { useHover } from "usehooks-ts";
import { useRef } from "react";
import { hoveringMediumCardState } from "../../states/viewState";

export function NavbarTools() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const { broken, toggled } = useProSidebar();
    const setShowBeacon = useSetRecoilState(showBeaconState);
    const newMessagesCount = useRecoilValue(newMessagesCountState);
    const { navigateToPage } = useNavigator();
    const {
        languageMatchesBrowser,
        timezoneMatchesBrowser,
        timeFormatMatchesBrowser,
        distanceFormatMatchesBrowser,
        company,
    } = useAccount();
    const ignoreTimezone = useRecoilValue(ignoreSettingState("timezone"));
    const ignoreDistance = useRecoilValue(ignoreSettingState("unit_distance"));
    const ignoreTime = useRecoilValue(ignoreSettingState("unit_time"));
    const ignoreLanguage = useRecoilValue(ignoreSettingState("language"));
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const isMediumCardHover = useRecoilValue(hoveringMediumCardState);

    const browserMismatchCount = [
        !timezoneMatchesBrowser && !ignoreTimezone,
        !timeFormatMatchesBrowser && !ignoreTime,
        !distanceFormatMatchesBrowser && !ignoreDistance,
        !languageMatchesBrowser && !ignoreLanguage,
    ].filter(b => b).length;

    if (broken && toggled) {
        return null;
    }

    const isDispatchPlan = !isLocationOnlyPlan(company?.stripe_plan);
    const opacity = !broken && isMediumCardHover ? 0.2 : 1;

    return (
        <HFlex ref={hoverRef}
               alignCenter
               spacing
               style={{
                   pointerEvents: "auto",
                   width: 'auto',
               }}
        >
            <GlobalSearch isHover={isHover} opacity={opacity}/>
            {browserMismatchCount > 0 && (
                <Badge count={browserMismatchCount}>
                    <ActionButton tooltip={strings.Settings.SettingsAttention}
                                  kind={"secondary"}
                                  size={"compact"}
                                  onClick={() => navigateToPage(PAGE.SETTINGS)}
                                  opacity={opacity}
                    >

                        <SettingsIcon size={12} color={theme.colors.contentPrimary}/>
                    </ActionButton>
                </Badge>
            )}
            {isDispatchPlan && <AssistantButton opacity={opacity}/>}
            {newMessagesCount > 0 && <>
                <Badge count={newMessagesCount}>
                    <ActionButton tooltip={strings.Messages.NewMessage}
                                  kind={"secondary"}
                                  size={"compact"}
                                  onClick={() => navigateToPage(PAGE.MESSAGES)}
                                  opacity={opacity}
                    >

                        <ChatFillIcon size={12} color={theme.colors.contentPrimary}/>
                    </ActionButton>
                </Badge>
            </>}
            <ActionButton tooltip={strings.Help.Help}
                          kind={"secondary"}
                          size={"compact"}
                          onClick={() => setShowBeacon((prev) => prev + 1)}
                          opacity={opacity}
            >
                <HelpFillIcon size={12} color={theme.colors.contentPrimary}/>
            </ActionButton>
            <CreateMenu/>
        </HFlex>
    );
}