import { sequenceState } from "../../states/sequencesState";
import { createNewEmptySequence, ReadableSequence, SavableSequence, Sequence } from "../../types/sequence";
import { editSequenceJob, saveSequence } from "../../services/api";
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { useSequenceUpdater } from "./useSequenceUpdater";

export function useSequenceSaver() {
    const updater = useSequenceUpdater();

    const save = (currentSequence: Sequence, newOrder: string[]) => {
        const newSequence = { ...currentSequence, jobs: newOrder } as SavableSequence;
        saveSequence(newSequence).then(({ status, sequence }) => {
            if (status) {
                updater.updateSequences();
            }
        });
    }

    const updateSequence = async (sequence: SavableSequence, callback: (sequence: ReadableSequence) => void) => {
        const { status, sequence: newSequence } = await saveSequence(sequence);
        if (status) {
            callback(newSequence);
        }
    }

    const saveSequenceAs = (name: string, jobIds: string[]) => {
        const newSequence = createNewEmptySequence(name);
        save(newSequence, jobIds);
    };

    const updateJobInSequence = async (sequenceId: string, jobId: string, fields: {}) => {
        const { status } = await editSequenceJob(sequenceId, jobId, fields);
        if (status) {
            updater.updateSequences();
        }
    };

    const updateJobsInSequence = (sequenceId: string, jobId: string, index: number) => {
        const currentSequence = getRecoil(sequenceState(sequenceId));
        if (!currentSequence) {
            return;
        }
        const theJob = currentSequence.jobs.find(j => j.id === jobId);
        if (!theJob) {
            return;
        }
        const newOrder = currentSequence.jobs.filter(j => j.id !== jobId).map(j => j.id);
        newOrder.splice(index, 0, theJob.id);
        save(currentSequence, newOrder);
    }

    const addJobToSequence = (sequenceId: string, jobId: string, index: number) => {
        const currentSequence = getRecoil(sequenceState(sequenceId));
        if (!currentSequence) {
            return false;
        }
        const array = currentSequence.jobs.map(j => j.id);
        array.splice(index, 0, jobId)
        save(currentSequence, array);
        return true;
    }

    const removeJobFromSequence = (sequenceId: string, ids: string[]) => {
        const currentSequence = getRecoil(sequenceState(sequenceId));
        if (!currentSequence) {
            return false;
        }
        const filteredIds = currentSequence.jobs
            .filter(j => !ids.includes(j.id))
            .map(j => j.id);
        save(currentSequence, filteredIds);
        return true;
    }

    return {
        updateJobsInSequence,
        updateJobInSequence,
        saveSequenceAs,
        updateSequence,
        addJobToSequence,
        removeJobFromSequence,
    };
}