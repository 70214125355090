import { useRecoilState, useRecoilValue } from "recoil";
import {
    accessState,
    accountBlockedState,
    accountKeyState,
    billingState,
    businessState,
    companyKeyState,
    companyState,
    companyUsernameState,
    distanceFormatState,
    hardLoginTsState,
    iconState,
    inviteCodesState,
    languageState,
    loginTsState,
    nameState,
    passwordState,
    roleState,
    slackTeamIdState,
    timeFormatState,
    timestampsState,
    timezoneState,
    tokenState,
    uidState,
    usernameState
} from "../states/accountState";
import { isAdmin, isOperator, isWorker } from "../types/member";
import {
    getBrowserDistanceFormat,
    getBrowserLanguage,
    getBrowserTimeFormat,
    getBrowserTimezone,
    getLanguageFromLocale
} from "../utils/browser";

export default function useAccount() {
    const [hardLoginTs, setHardLoginTs] = useRecoilState(hardLoginTsState);
    const [username, setUsername] = useRecoilState(usernameState);
    const [password, setPassword] = useRecoilState(passwordState);
    const [name, setName] = useRecoilState(nameState);
    const [role, setRole] = useRecoilState(roleState);
    const [uid, setUid] = useRecoilState(uidState);
    const [token, setToken] = useRecoilState(tokenState);
    const [accountKey, setAccountKey] = useRecoilState(accountKeyState);
    const [companyKey, setCompanyKey] = useRecoilState(companyKeyState);
    const [companyUsername, setCompanyUsername] = useRecoilState(companyUsernameState);
    const [access, setAccess] = useRecoilState(accessState);
    const [icon, setIcon] = useRecoilState(iconState);
    const [loginTs, setLoginTs] = useRecoilState(loginTsState);
    const [timeFormat, setTimeFormat] = useRecoilState(timeFormatState);
    const [distanceFormat, setDistanceFormat] = useRecoilState(distanceFormatState);
    const [language, setLanguage] = useRecoilState(languageState);
    const [timezone, setTimezone] = useRecoilState(timezoneState);
    const [company, setCompany] = useRecoilState(companyState);
    const [inviteCodes, setInviteCodes] = useRecoilState(inviteCodesState);
    const [billing, setBilling] = useRecoilState(billingState);
    const [slackTeamId, setSlackTeamId] = useRecoilState(slackTeamIdState);
    const isBlocked = useRecoilValue(accountBlockedState);
    const [timestamps, setTimestamps] = useRecoilState(timestampsState);
    const [isBusiness, setBusiness] = useRecoilState(businessState);

    const isAmPm = () => timeFormat === '12';
    const isImperial = () => distanceFormat !== 'SI';
    const isSelfAccount = (id: string) => uid === id;
    const isSelfCompany = uid === company?.uid;
    const isSelfWorker = !isSelfCompany && isWorker(role);
    const isSelfOperator = !isSelfCompany && isOperator(role);
    const isSelfAdmin = !isSelfCompany && isAdmin(role);
    const isSelfAdminOrCompany = isSelfAdmin || isSelfCompany;
    const hasCompany = company && company.uid;
    const timezoneMatchesBrowser = timezone === getBrowserTimezone();
    const timeFormatMatchesBrowser = timeFormat === getBrowserTimeFormat();
    const distanceFormatMatchesBrowser = distanceFormat === getBrowserDistanceFormat();
    const languageMatchesBrowser = getLanguageFromLocale(language) === getLanguageFromLocale(getBrowserLanguage());


    return {
        username,
        setUsername,
        password,
        setPassword,
        name,
        setName,
        role,
        setRole,
        uid,
        setUid,
        token,
        setToken,
        access,
        setAccess,
        icon,
        setIcon,
        loginTs,
        setLoginTs,
        timeFormat,
        setTimeFormat,
        isAmPm,
        distanceFormat,
        setDistanceFormat,
        isImperial,
        timezone,
        setTimezone,
        language,
        setLanguage,
        company,
        setCompany,
        inviteCodes,
        setInviteCodes,
        isSelfAccount,
        billing,
        setBilling,
        isSelfCompany,
        isSelfAdmin,
        isSelfWorker,
        isSelfOperator,
        isSelfAdminOrCompany,
        isBlocked,
        accountKey,
        companyKey,
        companyUsername,
        setAccountKey,
        setCompanyKey,
        setCompanyUsername,
        slackTeamId,
        setSlackTeamId,
        hasCompany,
        hardLoginTs,
        setHardLoginTs,
        timezoneMatchesBrowser,
        timeFormatMatchesBrowser,
        distanceFormatMatchesBrowser,
        languageMatchesBrowser,
        isBusiness,
        setBusiness,
        timestamps,
        setTimestamps,
    }
}