import { DatePicker } from "baseui/datepicker";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useTime } from "../../hooks/useTime";
import { dateToDayBrowserTimezone, dayToDateBrowserTimezone } from "../../utils/time";
import { useState } from "react";
import { selectedDayFrameState } from "../../states/appState";

export function GlobalDayFramePicker() {
    const [dates, setDates] = useRecoilState(selectedDayFrameState);
    const [internalDates, setInternalDates] = useState<Date[]>([dayToDateBrowserTimezone(dates[0]), dayToDateBrowserTimezone(dates[1])]);
    const resetDates = useResetRecoilState(selectedDayFrameState);
    const { dateFormat } = useTime();

    return (
        <DatePicker
            value={internalDates}
            onClose={() => {
                if (!dates[0] || !dates[1]) {
                    resetDates();
                }
            }}
            onChange={({ date }) => {
                const newDates = date;
                if (Array.isArray(newDates) && newDates.length === 1) {
                    setInternalDates(newDates as Date[]);
                } else if (Array.isArray(newDates) && newDates.length === 2) {
                    const fromDay = dateToDayBrowserTimezone(newDates[0] as Date);
                    const untilDay = dateToDayBrowserTimezone(newDates[1] as Date);
                    setDates([fromDay, untilDay]);
                    setInternalDates(newDates as Date[]);
                }
            }}
            formatString={dateFormat}
            placeholder={dateFormat}
            range={true}
            size="compact"
            overrides={{
                Input: {
                    props: {
                        overrides: {
                            InputContainer: {
                                style: {
                                    boxSizing: "content-box",
                                },
                            },
                            Input: {
                                style: {
                                    textAlign: "center",
                                },
                            },
                        },
                    },
                },
                InputWrapper: {
                    style: {
                        width: "150px",
                        textAlign: "center",
                    },
                },
            }}
        />
    );
}
