import { FloatButton } from "antd";
import React from "react";
import { useCamera } from "../../hooks/useCamera";
import { ArrowUp, CheckIndeterminate, Plus } from "baseui/icon";
import { useRecoilValue } from "recoil";
import { mapBearingState } from "../../states/mapState";

export function ZoomButtons() {
    const camera = useCamera();
    const mapBearing = useRecoilValue(mapBearingState);

    const heading = mapBearing < 0 ? 360 + mapBearing : mapBearing;

    return (
        <FloatButton.Group shape="square" style={{
            bottom: "24px",
            right: "10px",
            width: "40px",
            zIndex: "auto",
        }}>
            <FloatButton icon={<Plus/>} onClick={() => camera.zoomIn()}/>
            <FloatButton icon={<CheckIndeterminate/>} onClick={() => camera.zoomOut()}/>
            <FloatButton icon={<ArrowUp style={{
                transform: "rotate(" + heading + "deg)",
            }}/>} onClick={() => camera.resetNorth()}/>
        </FloatButton.Group>
    );
}