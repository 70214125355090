import { getDiffFields } from './../utils/objects';
import { atomFamily, selector, selectorFamily } from 'recoil';
import { Place } from '../types/place';
import { placeState } from './placeState';
import { editingPlaceIdState } from './appState';
import { emailValidator } from '../constants/validators';

const editPlaceInitializer = selectorFamily<Place, string>({
    key: 'edit.place.initializer',
    get: (uid) => ({ get }) => {
        const place = get(placeState(uid));
        return {
            ...place,
        } as Place;
    },
});

export const editPlaceState = atomFamily<Place, string>({
    key: 'edit.place',
    default: editPlaceInitializer,
});

export const editPlaceValidState = selectorFamily<{ [key: string]: boolean }, string>({
    key: 'edit.place.valid',
    get: (uid) => ({ get }) => {
        const fields = get(editPlaceState(uid));
        const validFields = Object.fromEntries(Object.keys(fields).map((key) => [key, true]));
        if (!fields.name) {
            validFields['name'] = false;
        }

        if (fields.email) {
            validFields['email'] = emailValidator.test(fields.email);
        } else {
            validFields['email'] = true;
        }
        return validFields;
    },
});

export const editPlaceErrorState = selectorFamily<boolean, string>({
    key: 'edit.place.error',
    get: (uid) => ({ get }) => {
        const validFields = get(editPlaceValidState(uid));

        return Object.values(validFields).some((valid) => !valid);
    },
});

export const editPlaceModifiedState = selector({
    key: 'edit.place.modified',
    get: ({ get }) => {
        const placeId = get(editingPlaceIdState);
        const place = get(placeState(placeId || ''));
        const placeFields = get(editPlaceState(placeId || ''));
        return getDiffFields(place || {}, placeFields);
    },
});

export const editPlaceHasModifiedState = selector({
    key: 'edit.place.modified.count',
    get: ({ get }) => {
        return !!Object.keys(get(editPlaceModifiedState)).length;
    },
});