import { Switch, Tooltip } from "antd";
import { StyledTimePicker } from "./StyledTimePicker";
import { dateToTime, timeToDate } from "../../utils/time";
import { HFlex } from "../containers/HFlex";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { Spacer } from "../containers/Spacer";

type Props = {
    start: number;
    end: number;
    onTimeChange: (value: number, target: "start" | "end") => void;
}

export function AllDayTimePicker({ start, end, onTimeChange }: Props) {
    const { strings } = useStrings();
    const { theme } = useTheme();

    const allDay = start === 0 && end === 2400;
    const handleAllDay = (checked: boolean) => {
        if (checked) {
            onTimeChange(0, "start");
            onTimeChange(2400, "end");
        } else {
            onTimeChange(800, "start");
            onTimeChange(1800, "end");
        }
    };

    return (
        <HFlex alignCenter>
            <Tooltip title={strings.General.AllDay}>
                <Switch
                    checkedChildren="24H"
                    unCheckedChildren="24H"
                    checked={allDay}
                    onChange={handleAllDay}
                    style={{
                        maxWidth: theme.sizing.scale1400,
                        minWidth: "54px",
                    }}
                />
            </Tooltip>
            {!allDay &&
                <>
                    <Spacer/>
                    <StyledTimePicker
                        value={start}
                        onChange={(date) => {
                            if (date) {
                                onTimeChange(dateToTime(date), "start")
                            }
                        }}
                    />
                    {"—"}
                    <StyledTimePicker
                        value={timeToDate(end)}
                        onChange={(date) => {
                            if (date) {
                                onTimeChange(dateToTime(date), "end")
                            }
                        }}
                    />
                </>
            }
        </HFlex>
    );
}