import { Modal, ROLE } from 'baseui/modal';
import { useTheme } from "../../hooks/useTheme";

type Props = {
    children: React.ReactNode;
    isOpen?: boolean;
    onClose: () => void;
    defaultSize?: boolean;
    customSize?: number;
    closeable?: boolean;
    animate?: boolean;
}

export function StyledModal({
                                children,
                                isOpen = true,
                                onClose,
                                defaultSize = false,
                                customSize,
                                closeable = true,
                                animate = true
                            }: Props) {
    const { theme } = useTheme();
    const defaultWidth = defaultSize ? '500px' : '760px';

    return (
        <Modal
            closeable={closeable}
            isOpen={isOpen}
            role={ROLE.dialog}
            onClose={onClose}
            animate={animate}
            overrides={{
                Dialog: {
                    style: {
                        width: '100%',
                        [theme.mediaQuery.medium]: {
                            width: customSize ? customSize + "px" : defaultWidth,
                        },
                        backgroundColor: theme.colors.primaryB,
                        border: 'solid 1px ' + theme.colors.contentTertiary,
                    }
                },
                DialogContainer: {
                    style: {
                        position: 'fixed',
                    },
                },
            }}
        >
            {children}
        </Modal>
    );
}
