import { Block } from "baseui/block";
import { TriangleLeft, TriangleRight } from "baseui/icon";
import { useRecoilState, useRecoilValue } from 'recoil';
import { pageState, selectedDayState } from '../../states/appState';
import { stringsState } from "../../states/localeState";
import { datePlusDays, dateToDayBrowserTimezone, dayOfToday, dayToDateBrowserTimezone } from "../../utils/time";
import { ActionButton } from "../buttons/ActionButton";
import { HFlex } from "../containers/HFlex";
import { useEffect, useState } from "react";
import { cardSizesState, windowIsMobileState } from "../../states/skeletonState";
import { useTime } from "../../hooks/useTime";
import { globalDatePickerHighlightedState } from "../../states/viewState";
import { useTheme } from "../../hooks/useTheme";
import { isToday } from "date-fns";
import { DatePicker } from 'baseui/datepicker';
import { SpacerExpand } from "../containers/Spacer";
import { Button } from "baseui/button";
import { CheckCircledIcon, ResetIcon } from "../ui/svg";
import { useToast } from "../../hooks/useToast";
import { useWindowSize } from "usehooks-ts";

export function GlobalDatePicker({ expanded = false }: { expanded?: boolean; }) {
    const { theme } = useTheme();
    const strings = useRecoilValue(stringsState);
    const [selectedDay, setSelectedDay] = useRecoilState(selectedDayState);
    const initialDate = [dayToDateBrowserTimezone(selectedDay)];
    const [value, setValue] = useState(initialDate);
    const isMobile = useRecoilValue(windowIsMobileState);
    const { dateFormat } = useTime();
    const [highlighted, setHighlighted] = useRecoilState(globalDatePickerHighlightedState);
    const toast = useToast();
    const time = useTime();
    const page = useRecoilValue(pageState);
    const { smallCardSize } = useRecoilValue(cardSizesState(page));
    const window = useWindowSize();

    useEffect(() => {
        setValue([dayToDateBrowserTimezone(selectedDay)]);
        toast.showInfo(time.formatDay(selectedDay), undefined);
    }, [selectedDay])

    const modifyDate = (days: number) => {
        const newDate = datePlusDays(value[0], days);
        setSelectedDay(dateToDayBrowserTimezone(newDate))
    }

    const smallCardPixel = smallCardSize * window.width / 100;
    const smallCard = isMobile || (!expanded && smallCardPixel < 420);
    const superSmallCard = (!expanded && smallCardPixel < 220)
    const isTodaySelected = isToday(value[0]);
    const width = isTodaySelected || superSmallCard
        ? (smallCard ? "80px" : "160px")
        : (smallCard ? "100px" : "190px");


    return (
        <HFlex style={{
            justifyContent: "center",
            width: width,
            flexShrink: 0,
            borderRadius: "8px",
            borderColor: highlighted ? theme.customColors.accent : theme.colors.contentTertiary,
            borderLeftStyle: "solid",
            borderRightStyle: "solid",
            borderTopStyle: "solid",
            borderBottomStyle: "solid",
            backgroundColor: "transparent",
            transition: "background-color 0.5s ease",
            alignItems: "center"
        }}>
            {!isTodaySelected && !superSmallCard &&
                <ActionButton kind={"tertiary"}
                              size={"mini"}
                              tooltip={strings.General.Today}
                              onClick={() => setSelectedDay(dayOfToday())}
                >
                    <ResetIcon size={10} color={theme.colors.contentPrimary}/>
                </ActionButton>
            }
            {!smallCard &&
                <ActionButton size={"mini"}
                              kind={"tertiary"}
                              tooltip={strings.Dispatch.PreviousDay}
                              onClick={() => modifyDate(-1)}
                >
                    <TriangleLeft/>
                </ActionButton>
            }
            <Block>
                <DatePicker
                    value={value}
                    onChange={({ date }) => {
                        if (date instanceof Date) {
                            let day = dateToDayBrowserTimezone(date as Date);
                            setSelectedDay(day);
                            setValue([date as Date]);
                        }
                    }}
                    onOpen={() => setHighlighted(false)}
                    formatString={dateFormat}
                    placeholder={dateFormat}
                    range={false}
                    monthsShown={1}
                    size="compact"
                    quickSelect={true}
                    quickSelectOptions={[]}
                    overrides={{
                        QuickSelectFormControl: {
                            props: {
                                overrides: {
                                    Label: () => (
                                        <HFlex>
                                            <SpacerExpand/>
                                            <Button kind={isTodaySelected ? "tertiary" : "primary"}
                                                    size={"compact"}
                                                    endEnhancer={isTodaySelected &&
                                                        <CheckCircledIcon size={16} color={theme.customColors.accent}/>}
                                                    onClick={() => {
                                                        let todayDate = new Date();
                                                        let day = dateToDayBrowserTimezone(todayDate);
                                                        setSelectedDay(day);
                                                        setValue([todayDate])
                                                    }}>{strings.General.Today}</Button>
                                            <SpacerExpand/>
                                        </HFlex>
                                    ),
                                    ControlContainer: () => <div></div>,
                                },
                            },
                        },
                        Input: {
                            props: {
                                overrides: {
                                    Root: {
                                        style: () => ({
                                            borderTopWidth: "0px",
                                            borderBottomWidth: "0px",
                                            borderLeftWidth: "0px",
                                            borderRightWidth: "0px",
                                            backgroundColor: "transparent",
                                        }),
                                    },
                                    Input: {
                                        style: () => ({
                                            fontWeight: isTodaySelected ? "bold" : "normal",
                                            backgroundColor: "transparent",
                                        }),
                                    },
                                    InputContainer: {
                                        style: {
                                            backgroundColor: "transparent",
                                        }
                                    }
                                }
                            }
                        },
                    }}
                />
            </Block>
            {!smallCard &&
                <ActionButton size={"mini"}
                              kind={"tertiary"}
                              tooltip={strings.Dispatch.NextDay}
                              onClick={() => modifyDate(+1)}
                >
                    <TriangleRight/>
                </ActionButton>
            }
        </HFlex>
    );
}
