import { VFlex } from "../containers/VFlex";
import { LabelMedium } from "baseui/typography";
import React, { useState } from "react";
import { useTime } from "../../hooks/useTime";
import { HoverButton } from "../buttons/HoverButton";
import { useTheme } from "../../hooks/useTheme";
import { FormTemplate } from "../../types/form";
import { useRecoilState, useRecoilValue } from "recoil";
import { formSubmissionsByTemplateState, selectedFormState } from "../../states/formsState";
import { ArrowRight } from "baseui/icon";
import { ClickableListItem } from "../ui/ClickableListItem";
import { Tag } from "antd";
import { useStrings } from "../../hooks/useStrings";
import { RemoveIcon } from "../ui/svg";

export function FormTemplateItem({ formTemplate }: { formTemplate: FormTemplate }) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const time = useTime();
    const [isHover, setHover] = useState(false);
    const [selectedForm, setSelectedForm] = useRecoilState(selectedFormState);
    const submissions = useRecoilValue(formSubmissionsByTemplateState({templateId: formTemplate.id, contentId: formTemplate.content.id}));

    const isSelected = selectedForm?.id === formTemplate.id;

    const onEditClick = () => setSelectedForm(isSelected ? undefined : formTemplate);

    const hoverButtonsFunction = () =>
        <HoverButton
            tooltip={isSelected ? strings.General.Clear : strings.General.Open}
            onClick={onEditClick}
            size={"compact"}
        >
            {isSelected
                ? <RemoveIcon size={16} color={theme.colors.contentTertiary} style={{ flexShrink: 0 }}/>
                : <ArrowRight size={16} color={theme.colors.contentTertiary} style={{ flexShrink: 0 }}/>
            }
        </HoverButton>

    return (
        <ClickableListItem
            isSelected={isSelected}
            onClick={onEditClick}
            onHover={hoverButtonsFunction}
            onHoverChange={setHover}
        >
            <VFlex>
                <LabelMedium color={theme.colors.contentPrimary}>
                    {formTemplate.content.title}
                </LabelMedium>
                <div>
                    <Tag color={isSelected ? "green" : "default"} style={{ fontSize: "10px", lineHeight: "12px"}}>
                        {strings.General.Created} {time.formatDateOnly(formTemplate.createdTs)}
                    </Tag>
                </div>
            </VFlex>
            {!isHover && submissions.length > 0 &&
                <Tag>{submissions.length}</Tag>
            }
        </ClickableListItem>
    );
}