import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { Button } from "baseui/button";
import { Plus } from "baseui/icon";
import * as React from "react";
import { useRef } from "react";
import { useHover } from "usehooks-ts";
import { StatefulPopover } from "baseui/popover";
import { FormItemOption } from "../../hooks/useForms";
import { StyledMenu } from "../ui/StyledMenu";

export function FormBetweenItems({
                                     items,
                                     onPlusClick
                                 }: { items: FormItemOption[], onPlusClick: (option: FormItemOption) => void }) {
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);

    return (
        <div ref={hoverRef}>
            <HFlex style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <SpacerExpand/>
                <StatefulPopover
                    returnFocus
                    content={({ close }) => (
                        <StyledMenu
                            items={items}
                            onItemSelect={(opt) => {
                                onPlusClick(opt.item);
                                close();
                            }}
                        />
                    )}
                >
                    <Button
                        kind={"primary"}
                        shape={"round"}
                        size={"mini"}
                        overrides={{
                            Root: {
                                style: {
                                    "visibility": isHover ? "visible" : "hidden"
                                }
                            }
                        }}
                    >
                        <Plus size={12}/>
                    </Button>
                </StatefulPopover>
                <SpacerExpand/>
            </HFlex>
        </div>
    );
}