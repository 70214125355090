import { Draggable } from "react-beautiful-dnd";
import { PINNED_JOB_ID_PREFIX } from "../../../constants/dnd";
import { PinnedJobItem } from "./PinnedJobItem";
import { memo } from "react";

type Props = {
    data: string[];
    index: number;
    style: React.CSSProperties;
};

export const PinnedJob = memo(({ data, index, style }: Props) => {
    const jobId = data[index];

    if (!jobId) {
        return null;
    }

    const draggableId = PINNED_JOB_ID_PREFIX + jobId;

    return (
        <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={false}>
            {(provided) => (
                <PinnedJobItem jobId={jobId} style={style} provided={provided}/>
            )}
        </Draggable>
    );
});
