import { ChevronRight } from 'baseui/icon';
import { memo, useRef } from 'react';
import { MenuItem } from 'react-pro-sidebar';
import { useHover } from 'usehooks-ts';
import { useTheme } from "../../hooks/useTheme";

type Props = {
    title: string;
    onClick: () => void;
    isOpen: boolean;
    children: React.ReactNode;
    itemsCount: number;
};

function SubmenuItem({ title, onClick, isOpen, children, itemsCount }: Props) {
    const ref = useRef(null);
    const isHover = useHover(ref);
    const { theme } = useTheme();

    return (
        <>
            <MenuItem
                ref={ref}
                title={title}
                icon={
                    <ChevronRight
                        style={{
                            transform: isOpen ? 'rotate(90deg)' : undefined,
                            transition: 'transform 0.3s ease',
                        }}
                        $size={"22px"}
                    />
                }
                onClick={onClick}
                style={{
                    color: isHover ? theme.colors.contentPrimary : "#bbbfc6"
                }}
            >
                {title}
            </MenuItem>
            <div style={{
                overflow: "hidden",
                height: (isOpen ? itemsCount * 46 : 0) + "px",
                transition: "height 0.3s ease",
                flexShrink: 0,
            }}>
                {children}
            </div>
        </>
    );
}

export default memo(SubmenuItem);
