import { FormControl } from 'baseui/form-control';
import { useRecoilValue } from 'recoil';
import { COUNTRY_LIST } from '../../constants/countries';
import { stringsState } from '../../states/localeState';
import { FlexGridLayout } from '../containers/FlexGridLayout';
import { FormInput } from '../ui/FormInput';
import { AnyField } from "../../types/fields";
import { StyledSelect } from '../ui/StyledSelect';

type Props = {
    addressValue: string;
    addressEnhancer?: React.ReactNode;
    cityValue: string;
    postalCodeValue: string;
    stateValue: string;
    countryValue?: string;
    onFieldChange: (key: AnyField, value: string | number) => void;
    disabled?: boolean;
    readonly?: boolean;
};

export function AddressFields({
                                  addressValue,
                                  addressEnhancer,
                                  cityValue,
                                  postalCodeValue,
                                  stateValue,
                                  countryValue,
                                  onFieldChange,
                                  disabled = false,
                                  readonly = false,
                              }: Props) {
    const strings = useRecoilValue(stringsState);

    return (
        <>
            <FormInput
                label={strings.General.Address}
                value={addressValue}
                field="address"
                onChange={onFieldChange}
                disabled={disabled}
                readonly={readonly}
                endEnhancer={addressEnhancer}
            />
            <FlexGridLayout>
                <FormControl label={strings.General.Country}>
                    <StyledSelect
                        clearable
                        closeOnSelect
                        size="compact"
                        options={COUNTRY_LIST}
                        value={[{ countryCode: countryValue }]}
                        labelKey="countryName"
                        valueKey="countryCode"
                        openOnClick
                        searchable
                        type="select"
                        onChange={params => onFieldChange('country_code', params.option?.countryCode || '')}
                        disabled={disabled || readonly}
                    />
                </FormControl>
                <FormInput
                    label={strings.General.City}
                    value={cityValue}
                    field="city"
                    onChange={onFieldChange}
                    disabled={disabled}
                    readonly={readonly}
                />
                <FormInput
                    label={strings.General.State}
                    value={stateValue}
                    field="state"
                    onChange={onFieldChange}
                    disabled={disabled}
                    readonly={readonly}
                />
                <FormInput
                    label={strings.General.PostalCode}
                    value={postalCodeValue}
                    type="number"
                    field="postalcode"
                    onChange={onFieldChange}
                    disabled={disabled}
                    readonly={readonly}
                />
            </FlexGridLayout>
        </>
    );
}
