import { Switch, Tooltip } from "antd";
import { StatefulTooltip } from "baseui/tooltip";
import { ParagraphSmall } from "baseui/typography";
import { DAY_STRING_KEYS } from "../../constants/days";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { WorkingHours as WorkingHoursT } from "../../types/core";
import { DayKey } from "../../types/days";
import { dateToTime } from "../../utils/time";
import { HFlex } from "../containers/HFlex";
import { ResponsiveFlex } from "../containers/ResponsiveFlex";
import { StyledTimePicker } from "../pickers/StyledTimePicker";
import { OvernightIcon } from "../ui/svg";

type Props = {
    workingHours: WorkingHoursT;
    editable: boolean;
    day: DayKey;
    onTimeChange: (value: number, day: string, target: "start" | "end") => void;
};

export function WorkingDayTime({ workingHours, day, editable, onTimeChange }: Props) {
    const { strings } = useStrings();
    const { theme } = useTheme();

    const allDay = workingHours[day].start === 0 && workingHours[day].end === 2400;

    const handleAllDay = (checked: boolean) => {
        if (checked) {
            onTimeChange(0, day, "start");
            onTimeChange(2400, day, "end");
        } else {
            onTimeChange(800, day, "start");
            onTimeChange(1800, day, "end");
        }
    };

    return (
        <ResponsiveFlex
            spacing={theme.sizing.scale300}
            style={{
                columnGap: theme.sizing.scale600,
                rowGap: theme.sizing.scale300,
                minHeight: theme.sizing.scale950,
            }}
        >
            <HFlex
                style={{
                    width: "auto",
                    flex: 2,
                    maxWidth: "220px",
                    columnGap: theme.sizing.scale300,
                    justifyContent: "flex-start",
                    [theme.mediaQuery.medium]: {
                        justifyContent: "space-between",
                    },
                }}
                alignCenter
            >
                <ParagraphSmall
                    margin={0}
                    overrides={{
                        Block: {
                            style: {
                                display: "flex",
                                columnGap: theme.sizing.scale200,
                            },
                        },
                    }}
                >
                    {strings.getString(DAY_STRING_KEYS[day as DayKey].full)}
                    {workingHours[day as DayKey].start > workingHours[day as DayKey].end && (
                        <StatefulTooltip content={strings.General.Overnight} showArrow placement="top">
                            <span>
                                <OvernightIcon size={14} color={theme.colors.contentPrimary}/>
                            </span>
                        </StatefulTooltip>
                    )}
                </ParagraphSmall>
                <Tooltip title={strings.General.AllDay}>
                    <Switch
                        checkedChildren="24H"
                        unCheckedChildren="24H"
                        checked={allDay}
                        onChange={handleAllDay}
                        disabled={!editable}
                        style={{
                            maxWidth: theme.sizing.scale1400,
                        }}
                    />
                </Tooltip>
            </HFlex>
            {!allDay && (
                <HFlex spacing={theme.sizing.scale600} alignCenter style={{ width: "auto", flex: 3 }}>
                    <StyledTimePicker
                        value={workingHours[day].start}
                        onChange={(date) => onTimeChange(date ? dateToTime(date) : 0, day, "start")}
                        disabled={!editable}
                    />
                    <ParagraphSmall marginTop={0} marginBottom={0}>
                        {strings.General.To.toLowerCase()}
                    </ParagraphSmall>
                    <StyledTimePicker
                        value={workingHours[day].end}
                        onChange={(date) => onTimeChange(date ? dateToTime(date) : 0, day, "end")}
                        disabled={!editable}
                    />
                </HFlex>
            )}
        </ResponsiveFlex>
    );
}
