import { MapProvider } from 'react-map-gl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import LayersCard from '../components/skeleton/layers/LayersCard';
import LayersOpenButton from '../components/skeleton/layers/LayersOpenButton';
import SkeletonActionsWrapper from '../components/skeleton/SkeletonActionsWrapper';
import SkeletonBody from '../components/skeleton/SkeletonBody';
import SkeletonContentWrapper from '../components/skeleton/SkeletonContentWrapper';
import SkeletonFloatingPanel from '../components/skeleton/SkeletonFloatingPanel';
import SkeletonMainContainer from '../components/skeleton/SkeletonMainContainer';
import { StyledModal } from '../components/ui/StyledModal';
import { useModal } from '../hooks/useModal';
import { AppStateHandler } from '../providers/AppStateHandler';
import WebsocketConnector from '../providers/WebsocketConnector';
import {
    hiddenModalState,
    importTypeState,
    loadedState,
    messageApiState,
    modalIsOpenState,
    notificationApiState
} from '../states/appState';
import { floatingPanelIsOpenState } from '../states/skeletonState';
import { ModalContentView } from './ModalContentView';
import { ConfirmModal } from '../components/ui/ConfirmModal';
import React, { useEffect, useState } from 'react';
import { stringsState } from '../states/localeState';
import { ToasterContainer } from "baseui/toast";
import { ErrorScopeProvider } from "../providers/ErrorScopeProvider";
import { Navbar } from "../components/navbar/Navbar";
import { ImportModal } from '../components/shared/ImportModal';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { HFlex } from "../components/containers/HFlex";
import { StateUpdateProvider } from "../providers/StateUpdateProvider";
import { ReactivationModal } from "../components/ui/ReactivationModal";
import { BeaconProvider } from "../components/help/BeaconProvider";
import { ConfigProvider, message, notification, theme } from "antd";
import { useTheme } from "../hooks/useTheme";
import { OnboardProvider } from '../components/onboard/OnboardContext';
import { OnboardSteps } from '../components/onboard/OnboardSteps';
import { onboardVisibleState } from '../states/onboardState';
import { SkeletonHandler } from "../providers/SkeletonHandler";
import { CompanySetupModal } from '../components/onboard/modal/CompanySetupModal';
import useAccount from "../hooks/useAccount";
import { ZoomButtons } from "../components/skeleton/ZoomButtons";
import { RecoilCacheProvider } from "../providers/RecoilCacheProvider";
import { AssistantModal } from "../components/assistant/AssistantModal";
import { LogoutProvider } from '../providers/LogoutProvider';
import { useDrag } from '../hooks/useDrag';
import { ToolButtons } from '../components/skeleton/ToolButtons';
import { showPaymentMethodState } from "../states/paymentState";
import { PlacesLayerUpdateProvider } from "../providers/PlacesLayerUpdateProvider";
import { useRemoveChildInterceptor } from "../hooks/useRemoveChildInterceptor";
import { showPaymentMethodReminderState } from "../states/accountState";
import { EnterPaymentMethodReminder } from "../components/billing/EnterPaymentMethodReminder";
import { DragDropContext } from "react-beautiful-dnd";

function MainView() {
    const strings = useRecoilValue(stringsState);
    const modalIsOpen = useRecoilValue(modalIsOpenState);
    const importType = useRecoilValue(importTypeState);
    const floatingPanelIsOpen = useRecoilValue(floatingPanelIsOpenState);
    const { closeModal, getCloseIsSafely } = useModal();
    const [showConfirm, setShowConfirm] = useState(false);
    const { onDragStart, onDragEnd, onBeforeDragStart, onBeforeCapture, onDragUpdate } = useDrag();
    const hiddenModal = useRecoilValue(hiddenModalState);
    const { isDarkTheme } = useTheme();
    const [messageApi, messageContext] = message.useMessage();
    const [notificationApi, notificationContext] = notification.useNotification();
    const loaded = useRecoilValue(loadedState);
    const loadingKey = "loading";
    const setMessageApi = useSetRecoilState(messageApiState);
    const setNotificationApi = useSetRecoilState(notificationApiState);
    const onboardVisible = useRecoilValue(onboardVisibleState);
    const { hasCompany } = useAccount();
    const showPaymentMethod = useRecoilValue(showPaymentMethodState);
    const { isBlocked } = useAccount();
    const showPaymentMethodReminder = useRecoilValue(showPaymentMethodReminderState);

    useRemoveChildInterceptor(); // this is to prevent beautifuldnd to throw removeChild errors

    useEffect(() => setMessageApi(messageApi), [messageApi])
    useEffect(() => setNotificationApi(notificationApi), [notificationApi])

    useEffect(() => {
        if (!loaded) {
            messageApi.open({
                key: loadingKey,
                type: 'loading',
                content: strings.General.Loading,
                duration: 0,
            });
        } else {
            messageApi.open({
                key: loadingKey,
                type: 'success',
                content: strings.General.Ready,
                duration: 2,
            });
        }
    }, [loaded])

    const handleCloseModal = () => {
        const closeIsSafely = getCloseIsSafely();
        if (closeIsSafely) {
            closeModal();
        } else {
            setShowConfirm(true);
        }
    }

    const handleCloseConfirm = () => {
        setShowConfirm(false);
        closeModal();
    }

    return (
        <ErrorScopeProvider>
            <ConfigProvider
                theme={{
                    algorithm: isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
                }}
            >
                <ProSidebarProvider>
                    <WebsocketConnector/>
                    <StateUpdateProvider/>
                    <BeaconProvider/>
                    <LogoutProvider/>
                    <PlacesLayerUpdateProvider/>
                    <ToasterContainer autoHideDuration={6000} placement={"topRight"}/>
                    {messageContext}
                    {notificationContext}
                    <OnboardProvider>
                        <HFlex style={{ height: "100%" }}>
                            <DragDropContext onDragStart={onDragStart}
                                             onDragEnd={onDragEnd}
                                             onBeforeDragStart={onBeforeDragStart}
                                             onBeforeCapture={onBeforeCapture}
                                             onDragUpdate={onDragUpdate}>
                                <MapProvider>
                                    <SkeletonHandler/>
                                    <AppStateHandler/>
                                    <RecoilCacheProvider/>
                                    <AssistantModal/>
                                    <SkeletonBody>
                                        <SkeletonMainContainer/>
                                        <LayersOpenButton/>
                                        {onboardVisible && <OnboardSteps/>}
                                        <ToolButtons/>
                                        <ZoomButtons/>
                                        <SkeletonContentWrapper/>
                                        <LayersCard/>
                                        <Navbar/>
                                        <SkeletonActionsWrapper/>
                                    </SkeletonBody>
                                    {!hasCompany && <CompanySetupModal/>}
                                    {floatingPanelIsOpen && <SkeletonFloatingPanel/>}
                                    {showPaymentMethodReminder && <EnterPaymentMethodReminder/>}
                                    {modalIsOpen && (
                                        <>
                                            <StyledModal
                                                closeable={!showPaymentMethod && !isBlocked}
                                                onClose={handleCloseModal}
                                                isOpen={!hiddenModal}
                                            >
                                                <ModalContentView/>
                                            </StyledModal>
                                            <ConfirmModal
                                                isOpen={showConfirm}
                                                description={strings.General.ReallyCloseUnsaved}
                                                onCancel={() => setShowConfirm(false)}
                                                onConfirm={handleCloseConfirm}
                                                confirmText={strings.General.CloseWithoutSaving}
                                                cancelText={strings.General.Cancel}
                                            />
                                        </>
                                    )}
                                    {importType && (
                                        <ImportModal/>
                                    )}
                                    <ReactivationModal/>
                                </MapProvider>
                            </DragDropContext>
                        </HFlex>
                    </OnboardProvider>
                </ProSidebarProvider>
            </ConfigProvider>
        </ErrorScopeProvider>
    );
}

export default React.memo(MainView);
