import { Button } from "baseui/button";
import { useEffect, useState } from "react";
import { SetterOrUpdater, useRecoilValue } from "recoil";
import { DAYS } from "../../constants/days";
import { useTheme } from "../../hooks/useTheme";
import { stringsState } from "../../states/localeState";
import { WorkingHours as WorkingHoursT } from "../../types/core";
import { DayKey } from "../../types/days";
import { VFlex } from "../containers/VFlex";
import { DaysPicker } from "../pickers/DaysPicker";
import { ClipboardIcon } from "../ui/svg";
import { WorkingDayTime } from "./WorkingDayTime";

type Props = {
    workingHours: WorkingHoursT;
    setWorkingHours: SetterOrUpdater<any>;
    editable?: boolean;
};

export function WorkingHours({ workingHours, setWorkingHours, editable = true }: Props) {
    const { theme } = useTheme();
    const strings = useRecoilValue(stringsState);

    useEffect(() => setDays(getDays()), [workingHours.source]);

    const getDayIsAvailable = (day: string) => {
        return workingHours[day as DayKey].start !== 0 || workingHours[day as DayKey].end !== 0;
    };

    const getDays = () => {
        return {
            monday: getDayIsAvailable(DAYS[DAYS.monday]),
            tuesday: getDayIsAvailable(DAYS[DAYS.tuesday]),
            wednesday: getDayIsAvailable(DAYS[DAYS.wednesday]),
            thursday: getDayIsAvailable(DAYS[DAYS.thursday]),
            friday: getDayIsAvailable(DAYS[DAYS.friday]),
            saturday: getDayIsAvailable(DAYS[DAYS.saturday]),
            sunday: getDayIsAvailable(DAYS[DAYS.sunday]),
        };
    };

    const [days, setDays] = useState(getDays());

    const onCheckedDay = (day: string, checked: boolean) => {
        setWorkingHours((prev: any) => ({
            ...prev,
            working_hours: {
                ...prev.working_hours,
                [day]: {
                    start: !checked ? 0 : 800,
                    end: !checked ? 0 : 1800,
                },
            },
        }));
    };

    const onTimeChange = (value: number, day: string, target: "start" | "end") => {
        setWorkingHours((prev: any) => ({
            ...prev,
            working_hours: {
                ...prev.working_hours,
                [day]: {
                    ...prev.working_hours[day as DayKey],
                    [target]: value,
                },
            },
        }));
    };

    const copyToAll = (days: string[], start: number, end: number) => {
        days.forEach((day) => {
            setWorkingHours((prev: any) => ({
                ...prev,
                working_hours: {
                    ...prev.working_hours,
                    [day]: {
                        start,
                        end,
                    },
                },
            }));
        });
    };

    const handleCheckedDay = (day: DayKey) => {
        setDays((prev) => {
            const available = !prev[day];
            onCheckedDay(day, available);
            return {
                ...prev,
                [day]: available,
            };
        });
    };

    const handleCopyToAll = () => {
        const firstKey = availableDays[0][0];
        const start = workingHours[firstKey as DayKey].start;
        const end = workingHours[firstKey as DayKey].end;
        copyToAll(
            availableDays.map(([day]) => day),
            start,
            end
        );
    };

    const daysArray = Object.entries(days);
    const availableDays = daysArray.filter(([, available]) => available);
    const availableDaysCount = availableDays.length;

    return (
        <>
            <DaysPicker days={days} onCheck={handleCheckedDay} editable={editable}/>
            {!!availableDaysCount && (
                <>
                    {availableDaysCount > 1 && editable && (
                        <Button
                            onClick={handleCopyToAll}
                            startEnhancer={<ClipboardIcon size={13} color={theme.colors.contentPrimary}/>}
                            kind="secondary"
                            size="mini"
                        >
                            {strings.WorkingHours.CopyTime}
                        </Button>
                    )}
                    <VFlex
                        style={{
                            rowGap: theme.sizing.scale600,
                            marginBottom: theme.sizing.scale300,
                            height: "auto",
                            marginTop: availableDaysCount > 1 ? theme.sizing.scale600 : 0,
                        }}
                    >
                        {availableDays.map(([day], index) => (
                            <WorkingDayTime
                                key={index}
                                day={day as DayKey}
                                workingHours={workingHours}
                                editable={editable}
                                onTimeChange={onTimeChange}
                            />
                        ))}
                    </VFlex>
                </>
            )}
        </>
    );
}
