import { useRecoilValue } from "recoil";
import { zonesState } from "../../states/zoneState";
import { Zone } from "../../types/zone";
import { polygon } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { LonLat } from "../../types/core";

export function useZones() {
    const allZones = useRecoilValue(zonesState);

    const getZonesFor = (lat?: number, lng?: number, limit = 10) => {
        const zones: Zone[] = [];
        if (lat && lng) {
            for (let zone of allZones) {
                if (zone.points.length >= 3) {
                    const coordinates: LonLat[] = [...zone.points, zone.points[0]];
                    const isInside = booleanPointInPolygon([lng, lat], polygon([coordinates]));
                    if (isInside) {
                        zones.push(zone);
                    }
                    if (zones.length >= limit) {
                        break;
                    }
                }
            }
        }
        return zones;
    };

    return { allZones, getZonesFor };
}