import { atom } from "recoil";

export const showPaymentMethodState = atom({
    key: "payment.show",
    default: false,
});

export const paymentClientSecretState = atom({
    key: "payment.clientsecret",
    default: "",
});