import { useComponentStyles } from "../../hooks/useComponentStyles";

type Props = {
    children: string;
    maxLines?: number;
    breakAll?: boolean;
    style?: React.CSSProperties;
    inline?: boolean;
};

export function EllipsedText({ children, maxLines = 3, style, breakAll = false, inline = false }: Props) {
    const { classnameEllipseText } = useComponentStyles();
    if (inline) {
        maxLines = 1;
    }
    return (
        <span
            className={classnameEllipseText({ maxLines, breakAll })}
            style={style}
        >
            {children}
        </span>
    );
}
