export type MinMax = [number, number];

export function clamp(value: number, bounds: MinMax): number {
    return Math.min(bounds[1], Math.max(bounds[0], value));
}

export function sanitizeCoordinate(value: number, min: number, max: number): number {
    if (value < min || value > max) {
        return 0;
    } else {
        return value;
    }
}