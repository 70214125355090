import { useUnit } from "./useUnit";
import { useTime } from "./useTime";
import { Job, RouteInfo } from "../types/job";

export function useRouteInfo() {
    const { distanceToString } = useUnit();
    const { formatDuration, formatSecondsToTime } = useTime();

    const calcEtaEtdDist = (routeInfo: RouteInfo, numberInRoute: number) => {
        let startTime = routeInfo.metaStartTime
        let driveTime = 0;
        let placeTime = 0;
        let routeDistKM = 0;
        let waitTime = 0;
        for (let i = 0; i < numberInRoute && i < routeInfo.legDistancesInKM.length && i < routeInfo.legTimesInSec.length; i++) {
            routeDistKM += routeInfo.legDistancesInKM[i]
            driveTime += routeInfo.legTimesInSec[i]
            waitTime += routeInfo.legWaitInSec?.[i] ?? 0;
            if (i - 1 >= 0 && i - 1 < routeInfo.metaOnSiteTimesInSec.length) {
                placeTime += routeInfo.metaOnSiteTimesInSec[i - 1]
            }
        }
        let eta = startTime + driveTime + placeTime + waitTime;
        let etd = eta;
        if (routeInfo.metaOnSiteTimesInSec.length > numberInRoute - 1) {
            etd += routeInfo.metaOnSiteTimesInSec[numberInRoute - 1]
        }
        let dist = routeDistKM * 1000;
        return { "eta": eta, "etd": etd, "dist": dist };
    }

    const length = (route: RouteInfo) => route.legDistancesInKM.length;

    const travelInfo = (routeInfo: RouteInfo, idx: number) => {
        if (idx < routeInfo.legTimesInSec.length && idx < routeInfo.legDistancesInKM.length) {
            let durationMillis = 1000 * routeInfo.legTimesInSec[idx];
            let durationText = formatDuration(durationMillis);
            let distanceInMeter = Math.floor(routeInfo.legDistancesInKM[idx] * 1000);
            let distanceText = distanceToString(distanceInMeter);
            return {
                valid: true,
                durationText: durationText,
                distanceText: distanceText,
                durationMillis: durationMillis,
                distanceInMeter: distanceInMeter,
            };
        } else {
            return {
                valid: false
            };
        }
    }

    const legInfo = (route: RouteInfo, job: Job) => {
        const etaEtdDist = calcEtaEtdDist(route, job.number);
        return {
            etaText: formatSecondsToTime(etaEtdDist.eta),
            etdText: formatSecondsToTime(etaEtdDist.etd),
            travelInfo: travelInfo(route, job.number),
        }
    }

    const summary = (route: RouteInfo) => {
        const end = calcEtaEtdDist(route, length(route));
        const distanceMeter = end.dist;
        const totalDist = distanceToString(distanceMeter);
        const totalSeconds = end.etd - route.metaStartTime;
        const totalTime = formatDuration(totalSeconds * 1000);
        const travelTime = formatDuration(route.routeTimeInSec * 1000);
        const waitingSeconds = Array.isArray(route.legWaitInSec)
            ? route.legWaitInSec.reduce((acc, cur) => acc + cur, 0) : 0;
        const onSiteTime = formatDuration((totalSeconds - route.routeTimeInSec - waitingSeconds) * 1000);
        const waitingTime = formatDuration(waitingSeconds * 1000);
        return {
            totalMeter: distanceMeter,
            totalDistance: totalDist,
            totalSeconds: totalSeconds,
            totalDuration: totalTime,
            travelDuration: travelTime,
            onSiteDuration: onSiteTime,
            waitingDuration: waitingTime,
        }
    }

    const isValid = (routeInfoObject: RouteInfo | undefined, jobs: Job[]) => {
        return routeInfoObject && routeInfoObject.metaOnSiteTimesInSec.length === jobs.length;
    }

    return { calcEtaEtdDist, summary, legInfo, travelInfo, isValid };
}