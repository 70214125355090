import { SpacerExpand } from "../containers/Spacer";
import { HFlex } from "../containers/HFlex";
import React, { useEffect, useState } from "react";
import { useAudioRecorder } from "../../hooks/useAudioRecorder";
import { LabelXSmall } from "baseui/typography";
import { useTheme } from "../../hooks/useTheme";
import { AudioStopRecordingButton } from "./AudioStopRecordingButton";
import { AudioStartRecordingButton } from "./AudioStartRecordingButton";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { audioRecorderBlobState } from "../../states/audioState";
import { useAudioTranscription } from "../../hooks/useAudioTranscription";
import { Spin } from "antd";
import { useStrings } from "../../hooks/useStrings";

export const MAX_SECONDS = 30;

export function AudioRecording() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const { startRecording, stopRecording, ready, isRecording } = useAudioRecorder(MAX_SECONDS);
    const audioBlob = useRecoilValue(audioRecorderBlobState);
    const resetAudioBlob = useResetRecoilState(audioRecorderBlobState);
    const { fetchTranscriptions } = useAudioTranscription();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (audioBlob?.size > 0) {
            setLoading(true);
            fetchTranscriptions(audioBlob)
                .finally(() => {
                    setLoading(false);
                    resetAudioBlob();
                });
        }
    }, [audioBlob]);

    useEffect(() => {
        if (ready && !isRecording) {
            startRecording();
        }
    }, [ready])

    return (
        <HFlex style={{ overflow: "hidden" }}>
            <SpacerExpand/>
            {loading && <Spin/>}
            {!loading && !ready &&
                <LabelXSmall color={theme.customColors.accent}>{strings.Audio.GrantMicPermission}</LabelXSmall>}
            {!loading && ready && isRecording && <AudioStopRecordingButton onClick={stopRecording}/>}
            {!loading && ready && !isRecording && <AudioStartRecordingButton onClick={startRecording}/>}
            <SpacerExpand/>
        </HFlex>
    );
}