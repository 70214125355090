import { useRecoilValue } from "recoil";
import React from "react";
import { jobState } from "../../states/jobsState";
import { getColorFromProgress } from "../../utils/jobUtils";
import { getTitle, STATUS_DELETED } from "../../types/job";
import { Tag } from "antd";
import { useSingleJobSelection } from "../../hooks/jobs/useSingleJobSelection";
import { JobTooltip } from "../job/JobTooltip";

type Props = {
    id: string;
    closable?: boolean;
    maxChars?: number;
}

export function JobBadge({ id, maxChars = 5, closable = true }: Props) {
    const job = useRecoilValue(jobState(id));
    const { toggleSelected } = useSingleJobSelection(id);

    if (!job || job.status === STATUS_DELETED) {
        return null;
    }

    return (
        <JobTooltip job={job}>
            <Tag key={id} color={getColorFromProgress(job)} closable={closable} onClose={() => toggleSelected()}>
                {getTitle(job, maxChars)}
            </Tag>
        </JobTooltip>
    );
}