import { allPlacesState } from "../states/placeState";
import { Member } from "../types/member";
import { Place } from "../types/place";
import { sortMembers } from "../utils/memberUtils";
import { sortPlaces } from "../utils/placeUtils";
import { membersDangerousState } from "./../states/membersState";
import { useStrings } from "./useStrings";
import { getRecoil } from "../providers/RecoilAccessProvider";

export function useText() {
    const { strings } = useStrings();
    const getSplittedText = (text: string) => text.split(",");
    const getJoinedText = (strings: string[]) => strings.join(", ");
    const mapMembers = (member: Member) => member.name;
    const mapPlaces = (place: Place) => place.name;
    const moreText = (count: number) => `${count} ${strings.General.More.toLowerCase()}...`;

    const getMembersText = (memberUids: string, defaultText: string = "", maxItems = 2) => {
        if (!memberUids) {
            return defaultText;
        }
        const members = getRecoil(membersDangerousState);
        const uids = getSplittedText(memberUids);
        const filteredMembers = members.filter((member) => uids.includes(member.uid));
        if (!filteredMembers.length) {
            return defaultText;
        }
        if (filteredMembers.length > maxItems) {
            const excludedCount = filteredMembers.length - maxItems;
            return getJoinedText([
                ...filteredMembers.slice(0, maxItems).sort(sortMembers).map(mapMembers),
                moreText(excludedCount),
            ]);
        } else {
            return getJoinedText(filteredMembers.map(mapMembers));
        }
    };

    const getPlacesText = (placeUids: string, defaultText: string = "", maxItems = 2) => {
        if (!placeUids) {
            return defaultText;
        }
        const places = getRecoil(allPlacesState);
        const uids = getSplittedText(placeUids);
        const filteredPlaces = places.filter((place) => uids.includes(place.uid));
        if (!filteredPlaces.length) {
            return defaultText;
        }
        if (filteredPlaces.length > maxItems) {
            const excludedCount = filteredPlaces.length - maxItems;
            return getJoinedText([
                ...filteredPlaces.slice(0, maxItems).sort(sortPlaces).map(mapPlaces),
                moreText(excludedCount),
            ]);
        } else {
            return getJoinedText(filteredPlaces.map(mapPlaces));
        }
    };

    const getMembersTextFromArray = (membersArr: Member[], defaultText: string = "", maxItems = 2) => {
        if (!membersArr.length) {
            return defaultText;
        }
        if (membersArr.length > maxItems) {
            const excludedCount = membersArr.length - maxItems;
            return getJoinedText([
                ...membersArr.slice(0, maxItems).sort(sortMembers).map(mapMembers),
                moreText(excludedCount),
            ]);
        } else {
            return getJoinedText(membersArr.map(mapMembers));
        }
    };

    const getPlacesTextFromArray = (placesArr: Place[], defaultText: string = "", maxItems = 2) => {
        if (!placesArr.length) {
            return defaultText;
        }
        if (placesArr.length > maxItems) {
            const excludedCount = placesArr.length - maxItems;
            return getJoinedText([
                ...placesArr.slice(0, maxItems).sort(sortPlaces).map(mapPlaces),
                moreText(excludedCount),
            ]);
        } else {
            return getJoinedText(placesArr.map(mapPlaces));
        }
    };

    return {
        getMembersText,
        getPlacesText,
        getMembersTextFromArray,
        getPlacesTextFromArray,
    };
}
