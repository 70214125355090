import { LightTheme } from "baseui";
import { EmptyState } from "../components/ui/EmptyState";
import { Centered } from "../components/containers/Centered";
import { Spin } from "antd";
import { HFlex } from "../components/containers/HFlex";
import { useStrings } from "../hooks/useStrings";
import { getCookie } from "../utils/cookies";
import useAccount from "../hooks/useAccount";
import useDirector from "../hooks/useDirector";
import { useNavigator } from "../hooks/useNavigator";
import { PAGE } from "../constants/pages";
import { useEffect, useState } from "react";
import { Button } from "baseui/button";
import { useRecoilValue } from "recoil";
import { loggedInState } from "../states/accountState";
import { Uid } from "../types/core";

export function SwitchView() {
    const account = useAccount();
    const { strings } = useStrings();
    const { performLogin, logout } = useDirector();
    const { navigateToPage } = useNavigator();
    const [showLoginButton, setShowLoginButton] = useState(false);
    const isLoggedIn = useRecoilValue(loggedInState);
    const [autoLoggedIn, setAutoLoggedIn] = useState(false);

    useEffect(() => {
        if (isLoggedIn) {
            navigateToPage(PAGE.LIVE);
            return;
        }
        const userUid = getCookie("useruid") as Uid;
        const loginToken = getCookie("logintoken");
        const autoLogin = async () => {
            if (userUid && loginToken && !autoLoggedIn) {
                account.setUsername(userUid);
                account.setUid(userUid);
                account.setToken(loginToken);
                const result = await performLogin(true);
                if (!result.error) {
                    navigateToPage(PAGE.LIVE);
                    return;
                }
                setAutoLoggedIn(true);
            }
            setShowLoginButton(true);
        }

        autoLogin();
    }, [isLoggedIn]);

    const loginButton = (
        <Button onClick={() => {
            navigateToPage("login");
            logout();
        }}>
            {strings.General.Login}
        </Button>
    );

    return (
        <HFlex
            style={{
                display: "flex",
                background: LightTheme.colors.backgroundPrimary,
                overflowY: "auto",
                justifyContent: "center",
                height: "100%",
            }}
        >
            <Centered>
                {showLoginButton
                    ? loginButton
                    : <EmptyState title={strings.General.Loading}>
                        <Spin/>
                    </EmptyState>
                }
            </Centered>
        </HFlex>
    );
}
