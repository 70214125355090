import { isDropoff, isPickup, Job } from "../../../types/job";
import { JobBadge } from "../../badges/JobBadge";
import { HFlex } from "../../containers/HFlex";
import { Button } from "baseui/button";
import { SelectJobModal } from "../../dispatch/SelectJobModal";
import { Section } from "../../ui/Section";
import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { editJobState } from "../../../states/editJobState";
import { editingJobIdState } from "../../../states/appState";
import { jobCompactVisualizationState } from "../../../states/optionsState";
import { useStrings } from "../../../hooks/useStrings";
import { jobState } from "../../../states/jobsState";
import { VFlex } from "../../containers/VFlex";
import { Banner } from "baseui/banner";

export function AssociatedJobSection() {
    const jobId = useRecoilValue(editingJobIdState);
    const [fields, setFields] = useRecoilState(editJobState(jobId || ''));
    const associatedJob = useRecoilValue(jobState(fields?.associatedJobId))
    const compact = useRecoilValue(jobCompactVisualizationState);
    const [showAssociatedJobModal, setShowAssociatedJobModal] = useState(false);
    const { strings } = useStrings();

    if (!fields) {
        return null;
    }

    const onSelectAssociatedJobClick = () => {
        setShowAssociatedJobModal(true);
    };

    const onChangeInput = (key: string, value: string[] | string | number) => {
        setFields((prev) => ({ ...prev, [key]: value } as Job));
    };

    return (
        <Section title={isPickup(fields) ? "Delivery" : "Pickup"}
                 expandable={compact}
                 collapsedNode={fields.associatedJobId &&
                     <JobBadge maxChars={23} id={fields.associatedJobId} closable={false}/>}
        >
            <VFlex>
                <HFlex alignCenter spacing>
                    <Button kind={"tertiary"} size={"mini"} onClick={onSelectAssociatedJobClick}>
                        {strings.General.Select}
                    </Button>
                    {fields.associatedJobId &&
                        <JobBadge maxChars={43} id={fields.associatedJobId} closable={false}/>}
                    {showAssociatedJobModal && (
                        <SelectJobModal uid={fields.receiverUid}
                                        day={fields.day}
                                        onJobSelected={(job) => onChangeInput("associatedJobId", job.id)}
                                        onClose={() => setShowAssociatedJobModal(false)}
                        />
                    )}
                </HFlex>
                {associatedJob
                    && isPickup(fields)
                    && !isDropoff(associatedJob)
                    && <Banner kind={"warning"}>
                        <VFlex>
                            Selected job is not marked as dropoff.
                            <div><Button kind={"primary"} size={"mini"}>Set selected job as Drop-off</Button></div>
                        </VFlex>
                    </Banner>}
                {associatedJob
                    && isDropoff(fields)
                    && !isPickup(associatedJob)
                    && <Banner kind={"warning"}>
                        <VFlex>
                            Selected job is not marked as pick-up.
                            <div><Button kind={"primary"} size={"mini"}>Set selected job as Pick-up</Button></div>
                        </VFlex>
                    </Banner>}
                {associatedJob
                    && fields.day !== associatedJob.day
                    && <Banner kind={"warning"}>
                        <VFlex>
                            Selected job is assigned to a different day.
                        </VFlex>
                    </Banner>}
                {associatedJob
                    && fields.receiverUid !== associatedJob.receiverUid
                    && <Banner kind={"warning"}>
                        <VFlex>
                            Selected job is assigned to a different assignee.
                        </VFlex>
                    </Banner>}
            </VFlex>
        </Section>
    )
}