import { Column } from "jspreadsheet-ce";
import { getColumnConfig } from "../../utils/spreadsheets";
import { PlacesImportAdvancedFields, PlacesImportDefaultFields } from "../../constants/places";

export function usePlacesImportConfig() {
    const name = PlacesImportDefaultFields[PlacesImportDefaultFields.Name];
    const address = PlacesImportDefaultFields[PlacesImportDefaultFields.Address];
    const city = PlacesImportDefaultFields[PlacesImportDefaultFields.City];
    const zipCode = PlacesImportDefaultFields[PlacesImportDefaultFields["Zip Code"]];
    const state = PlacesImportDefaultFields[PlacesImportDefaultFields.State];
    const country = PlacesImportDefaultFields[PlacesImportDefaultFields.Country];
    const email = PlacesImportDefaultFields[PlacesImportDefaultFields["E-mail"]];
    const phone = PlacesImportDefaultFields[PlacesImportDefaultFields.Phone];
    // Advanced fields
    const latitude = PlacesImportAdvancedFields[PlacesImportAdvancedFields.Latitude];
    const longitude = PlacesImportAdvancedFields[PlacesImportAdvancedFields.Longitude];
    const radius = PlacesImportAdvancedFields[PlacesImportAdvancedFields.Radius];

    const defaultColumnsConfig = {
        [name]: getColumnConfig(name, { width: 220 }),
        [address]: getColumnConfig(address),
        [city]: getColumnConfig(city),
        [zipCode]: getColumnConfig(zipCode),
        [state]: getColumnConfig(state),
        [country]: getColumnConfig(country),
        [email]: getColumnConfig(email),
        [phone]: getColumnConfig(phone),
    };

    const advancedColumnsConfig = {
        [latitude]: getColumnConfig(latitude),
        [longitude]: getColumnConfig(longitude),
        [radius]: getColumnConfig(radius),
    };

    const defaultColumns = Object.values(defaultColumnsConfig);

    const getAdvancedColumnsOptions = (columns: Column[]) => {
        const columnFields = columns.map((column) => column.title);
        const advancedFields = Object.keys(advancedColumnsConfig);
        return advancedFields.filter((field) => !columnFields.includes(field));
    };

    return {
        defaultColumnsConfig,
        defaultColumns,
        getConfiguration: getColumnConfig,
        getAdvancedColumnsOptions,
        advancedColumnsConfig,
    };
}
