import { atomWithIndexedPersistence } from "../utils/persistence";
import { atom, selector, selectorFamily } from "recoil";
import { Zone } from "../types/zone";
import { zonesLayerState } from "./geojsonState";
import { SORT_DIRECTION, SortDirection } from "baseui/table";
import { Uid } from "../types/core";

export enum ZoneSort {
    NAME,
    CREATED,
    COLOR,
}

export const zonesSortState = atom({
    key: "zones.sort",
    default: ZoneSort.NAME,
});

export const zonesSortDirectionState = atom<SortDirection>({
    key: "zones.sort.direction",
    default: SORT_DIRECTION.ASC,
});

export const zonesSearchState = atom({
    key: "zones.search",
    default: "",
});

export const allZonesState = selector({
    key: "zones.all",
    get: ({ get }) => {
        const zonesLayer = get(zonesLayerState);
        if (!zonesLayer) {
            return [] as Zone[];
        }
        const obj = zonesLayer as unknown as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
        if (!obj.features) {
            return [] as Zone[];
        }
        const sortedZones = obj.features
            .map((feat) => {
                const points = feat.geometry as any;
                return {
                    ...feat.properties,
                    points: points.coordinates[0],
                } as Zone;
            })
            .sort((z1, z2) => {
                return z1.name.localeCompare(z2.name);
            });
        return sortedZones;
    },
});

export const zonesState = selector({
    key: "zones",
    get: ({ get }) => {
        const allZones = get(allZonesState);
        if (!allZones.length) return [];
        const sort = get(zonesSortState);
        const search = get(zonesSearchState);
        const sortDirection = get(zonesSortDirectionState);
        const sortedZones = allZones
            .filter((z) => search === "" || z.name.toLowerCase().includes(search.toLowerCase()))
            .sort((z1, z2) => {
                if (sort === ZoneSort.CREATED) {
                    return z2.created - z1.created;
                } else if (sort === ZoneSort.COLOR) {
                    return z1.color.localeCompare(z2.color);
                } else {
                    return z1.name.localeCompare(z2.name);
                }
            });
        return sortDirection === SORT_DIRECTION.DESC ? sortedZones.reverse() : sortedZones;
    },
});

export const zoneState = selectorFamily<Zone | undefined, string>({
    key: 'zone',
    get: (uid) => ({ get }) => {
        const zones = get(zonesState);
        return zones.find(z => z.uid === uid);
    }
});

export const zonesSelectedUidsState = atomWithIndexedPersistence("zones.selected.uids", [] as Uid[]);

export const zonesSelectedState = selector({
    key: "zones.selected",
    get: ({ get }) => get(zonesSelectedUidsState)
        .map(uid => get(zoneState(uid)))
        .filter(zone => !!zone) as Zone[],
});

export const editZoneState = atom<Zone>({ key: "zone.edit", default: undefined });

