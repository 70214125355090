import { MediumCard, SmallCard, VisibleCards } from '../components/skeleton/skeletonTypes';

export const HALF = 'half';
export const COLLAPSED = 'collapsed';

export const INITIAL_MEDIUM_STATE: MediumCard = {
    isOpened: true,
    knob: {
        isVisible: true,
        shouldOpen: false,
    },
};

export const INITIAL_SMALL_STATE: SmallCard = {
    isOpened: true,
    knob: {
        isVisible: true,
        shouldOpen: false,
    },
};

export const NO_CARDS: VisibleCards = {
    smallIsVisible: false,
    mediumIsVisible: false,
}

export const SMALL_CARD_ONLY: VisibleCards = {
    smallIsVisible: true,
    mediumIsVisible: false,
};

export const MEDIUM_CARD_ONLY: VisibleCards = {
    smallIsVisible: false,
    mediumIsVisible: true,
};

export const ALL_CARDS: VisibleCards = {
    smallIsVisible: true,
    mediumIsVisible: true,
};

export const CARD_WIDTH_SMALL = 500;
export const CARD_WIDTH_MEDIUM = 700;
export const CARD_WIDTH_LARGE = 1100;