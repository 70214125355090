import { Checkbox } from 'baseui/checkbox';
import { FormControl } from 'baseui/form-control';
import { Radio, RadioGroup } from 'baseui/radio';
import { useRecoilState } from 'recoil';
import { CHECK_IN_DELAY_MINUTES, CHECK_IN_RADIUS, DELETE_DAYS, } from '../../../constants/company';
import { useOnboardRefs } from '../../../hooks/onboard/useOnboardRefs';
import { editCompanyState } from '../../../states/editCompanyState';
import { SectionedWrapper } from '../../containers/SectionedWrapper';
import { AccountSettingsFields } from '../../shared/AccountSettingsFields';
import { CreatableTagsInput } from '../../ui/CreatableTagsInput';
import { Section } from '../../ui/Section';
import useAccount from "../../../hooks/useAccount";
import { useStyleOverrides } from '../../../hooks/useStyleOverrides';
import { Spacer } from "../../containers/Spacer";
import { LinkedZonesSelect } from "../../pickers/LinkedZonesSelect";
import { getLinkedZones, getLinkedZonesString } from "../../../types/linked";
import { useStrings } from "../../../hooks/useStrings";

export function CompanySettingsTab() {
    const { strings } = useStrings();
    const [companyFields, setCompanyFields] = useRecoilState(editCompanyState);
    const { isSelfAdminOrCompany } = useAccount();
    const { formControlSpacing } = useStyleOverrides();
    const {
        delayRef,
        checkinNotificationsRef,
        autoCheckinRef,
        deleteTracksRef,
        checkinAreaRef,
    } = useOnboardRefs();

    const onChangeInput = (key: string, value: string | number) => {
        setCompanyFields((prev) => ({ ...prev, [key]: value }));
    };

    const onChangeToggle = (key: string, value: boolean) => {
        setCompanyFields((prev) => ({ ...prev, [key]: value }));
    };

    const statusLabels = companyFields?.predefined_status_labels.length > 0
        ? companyFields.predefined_status_labels.split(',')
        : [];

    return (
        <SectionedWrapper>
            <Section title={strings.General.General} noMarginTop>
                <AccountSettingsFields
                    languageValue={companyFields.language}
                    timezoneValue={companyFields.timezone}
                    unitDistanceValue={companyFields.unit_distance}
                    unitTimeValue={companyFields.unit_time}
                    onFieldChange={onChangeInput}
                />
            </Section>
            <Section title={strings.Company.TrackingAndLocation}>
                <FormControl label={strings.Company.RestrictLocationLabel}
                             caption={strings.Company.RestrictLocationDesc}
                             overrides={formControlSpacing}
                >
                    <LinkedZonesSelect
                        linkedZones={getLinkedZones(companyFields.restricted_zones)}
                        onChangeLinkedZones={(linkedZones) => {
                            onChangeInput("restricted_zones", getLinkedZonesString(linkedZones));
                        }}
                        placeholder={strings.Company.SelectZones}/>
                </FormControl>
                <div ref={deleteTracksRef}>
                    <FormControl label={strings.Company.DeleteTracksOlderThan} overrides={formControlSpacing}>
                        <RadioGroup
                            value={`${companyFields.delete_tracking_data_days}`}
                            onChange={(event) => onChangeInput('delete_tracking_data_days', Number(event.target.value))}
                            align="horizontal"
                            disabled={!isSelfAdminOrCompany}
                        >
                            <Radio value={DELETE_DAYS.never}>{strings.General.Never}</Radio>
                            <Radio value={DELETE_DAYS.oneDay}>{strings.General.OneDay}</Radio>
                            <Radio value={DELETE_DAYS.oneMonth}>{strings.General.OneMonth}</Radio>
                            <Radio value={DELETE_DAYS.threeMonths}>
                                {strings.formatString(strings.General.XMonths, 3)}
                            </Radio>
                        </RadioGroup>
                    </FormControl>
                </div>
            </Section>
            <Section title={strings.Company.CheckIns}>
                <div ref={checkinNotificationsRef}>
                    <div ref={autoCheckinRef}>
                        <FormControl label={" "} overrides={formControlSpacing}>
                            <Checkbox
                                checked={companyFields.enable_auto_checkins}
                                onChange={(event) => onChangeToggle('enable_auto_checkins', event.target.checked)}
                                disabled={!isSelfAdminOrCompany}
                            >
                                {strings.Company.EnableAutoCheckin}
                            </Checkbox>
                        </FormControl>
                    </div>
                    <div ref={delayRef}>
                        <FormControl label={strings.Company.CheckInDelay}
                                     caption={strings.Company.CheckInDelayMessage}
                                     overrides={formControlSpacing}
                        >
                            <RadioGroup
                                value={`${companyFields.checkin_loitering_delay / 60}`}
                                onChange={(event) => onChangeInput('checkin_loitering_delay', 60 * Number(event.target.value))}
                                align="horizontal"
                                disabled={!isSelfAdminOrCompany}
                            >
                                <Radio value={CHECK_IN_DELAY_MINUTES.zero}>
                                    {strings.formatString(strings.General.Xmin, CHECK_IN_DELAY_MINUTES.zero)}
                                </Radio>
                                <Radio value={CHECK_IN_DELAY_MINUTES.one}>
                                    {strings.formatString(strings.General.Xmin, CHECK_IN_DELAY_MINUTES.one)}
                                </Radio>
                                <Radio value={CHECK_IN_DELAY_MINUTES.five}>
                                    {strings.formatString(strings.General.Xmin, CHECK_IN_DELAY_MINUTES.five)}
                                </Radio>
                                <Radio value={CHECK_IN_DELAY_MINUTES.ten}>
                                    {strings.formatString(strings.General.Xmin, CHECK_IN_DELAY_MINUTES.ten)}
                                </Radio>
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div ref={checkinAreaRef}>
                        <FormControl label={strings.Company.CheckInArea}
                                     overrides={formControlSpacing}
                        >
                            <RadioGroup
                                value={`${companyFields.checkin_default_radius}`}
                                onChange={(event) => onChangeInput('checkin_default_radius', Number(event.target.value))}
                                align="horizontal"
                                disabled={!isSelfAdminOrCompany}
                            >
                                <Radio value={CHECK_IN_RADIUS.short}>{strings.Company.CheckInShortRadius}</Radio>
                                <Radio value={CHECK_IN_RADIUS.big}>{strings.Company.CheckInBigRadius}</Radio>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            </Section>
            <Section title={strings.Company.ZoneCheckIns}>
                <FormControl label={" "} overrides={formControlSpacing}>
                    <Checkbox
                        checked={companyFields.enable_zone_checkins}
                        onChange={(event) => onChangeToggle('enable_zone_checkins', event.target.checked)}
                        disabled={!isSelfAdminOrCompany}
                    >
                        {strings.Company.EnableZoneCheckin}
                    </Checkbox>
                </FormControl>
            </Section>
            <Section title={strings.Company.AdvancedMobileSettings}>
                <FormControl label={" "} overrides={formControlSpacing}>
                    <CreatableTagsInput
                        label={strings.Company.StatusLabelList}
                        caption={strings.Company.StatusLabelSection}
                        tags={statusLabels}
                        onTagsChange={(tags) =>
                            setCompanyFields((prev) => ({ ...prev, predefined_status_labels: tags.join(',') }))
                        }
                    />
                </FormControl>
                <FormControl label={" "} overrides={formControlSpacing}>
                    <CreatableTagsInput
                        label={strings.TripLists.TripPurposeList}
                        caption={strings.TripLists.TripPurposeListCaption}
                        tags={companyFields?.track_purpose_list || []}
                        onTagsChange={(tags) =>
                            setCompanyFields((prev) => ({ ...prev, track_purpose_list: tags }))
                        }
                    />
                </FormControl>
                <FormControl label={" "} overrides={formControlSpacing}>
                    <CreatableTagsInput
                        label={strings.TripLists.TripCommentList}
                        caption={strings.TripLists.TripCommentListCaption}
                        tags={companyFields?.track_comment_list || []}
                        onTagsChange={(tags) =>
                            setCompanyFields((prev) => ({ ...prev, track_comment_list: tags }))
                        }
                    />
                </FormControl>
            </Section>
            <Section title={strings.Company.Notifications}>
                <Checkbox
                    checked={companyFields.email_on_message}
                    onChange={(event) => onChangeToggle('email_on_message', event.target.checked)}
                    disabled={!isSelfAdminOrCompany}
                >
                    {strings.Company.SendEmailOnNewMessage}
                </Checkbox>
            </Section>
            <Spacer height={"48px"}/>
        </SectionedWrapper>
    );
}