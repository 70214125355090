import { useRecoilState, useRecoilValue } from "recoil";
import { jobState, selectedJobIdsState } from "../../states/jobsState";

export function useSingleJobSelection(id: string) {
    const job = useRecoilValue(jobState(id));
    const [selectedIds, setSelectedJobIds] = useRecoilState(selectedJobIdsState);

    const isSelected = selectedIds.includes(id);

    const toggleSelected = () => {
        if (!job) {
            return;
        }
        if (isSelected) {
            setSelectedJobIds((prev) => [...prev.filter(id => id !== job.id)]);
        } else {
            setSelectedJobIds((prev) => [...prev.filter(id => id !== job.id), job.id]);
        }
    }

    return { isSelected, toggleSelected };
}