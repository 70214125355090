import { atomFamily } from "recoil";
import { MileageCalendarHeatmapEntry } from "../types/charts";

export const speedHistogramDataState = atomFamily<number[], string>({
    key: "data.speed.histogram",
    default: [] as number[],
});

export const mileageCalendarHeatmapDataState = atomFamily<MileageCalendarHeatmapEntry[], string>({
    key: "data.mileage.calendarheatmap",
    default: [] as MileageCalendarHeatmapEntry[],
});