import { useRecoilValue } from "recoil";
import { VFlex } from "../containers/VFlex";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { Button } from "baseui/button";
import { Plus } from "baseui/icon";
import { UnorderedList } from "../containers/UnorderedList";
import React, { useState } from "react";
import { attachedLocationState, messagesInboxState } from "../../states/messagesState";
import { InboxMessage } from "./InboxMessage";
import { MembersPickerModalContent } from "../member/modal/MembersPickerModalContent";
import { StyledModal } from "../ui/StyledModal";
import { Member } from "../../types/member";
import { Textarea } from "baseui/textarea";
import { useStrings } from "../../hooks/useStrings";
import { MemberBadges } from "../badges/MemberBadges";
import { LabelSmall } from "baseui/typography";
import { sendMessage } from "../../services/api";
import { useToast } from "../../hooks/useToast";

export function InboxList() {
    const { strings } = useStrings();
    const inbox = useRecoilValue(messagesInboxState);
    const [pickingMembers, setPickingMembers] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
    const [messageText, setMessageText] = useState("");
    const [sending, setSending] = useState(false);
    const toast = useToast();
    const attachedLocation = useRecoilValue(attachedLocationState);

    const onNewClick = () => setPickingMembers(true);
    const closeMemberPickerModal = () => setPickingMembers(false);
    const onAcceptMembers = async (members: Member[]) => setSelectedMembers(members);
    const onSend = async () => {
        setSending(true);
        sendMessage({
            receivers: selectedMembers.map(m => m.uid),
            msg: messageText,
            lat: attachedLocation?.lat,
            lng: attachedLocation?.lng,
        }).then(({ status }) => {
            setSending(false);
            setSelectedMembers([]);
            setMessageText("");
            if (status) {
                toast.showSuccess(strings.Messages.MessageSent);
            } else {
                toast.showWarning(strings.General.SomethingWentWrong);
            }
        });
    };

    return (
        <VFlex spacing>
            <StyledModal isOpen={pickingMembers} onClose={closeMemberPickerModal}>
                <MembersPickerModalContent
                    closeModal={closeMemberPickerModal}
                    onAccept={onAcceptMembers}
                    title={`${strings.Messages.SendMessageTo}...`}
                />
            </StyledModal>
            <StyledModal isOpen={selectedMembers.length > 0} onClose={() => setSelectedMembers([])}>
                <VFlex spacing padding>
                    <HFlex spacing alignCenter>
                        <LabelSmall style={{ whiteSpace: "nowrap" }}>{strings.Messages.SendingMessageTo}</LabelSmall>
                        <MemberBadges members={selectedMembers.map(m => m.uid)}/>
                    </HFlex>
                    <Textarea
                        autoFocus
                        value={messageText}
                        onChange={(ev) => setMessageText(ev.target.value)}
                        placeholder={`${strings.Messages.EnterMessage}...`}
                    />
                    <Button size={"compact"} onClick={onSend}>{strings.General.Send}</Button>
                </VFlex>
            </StyledModal>
            <HFlex>
                <SpacerExpand/>
                <Button size={"mini"} kind={"primary"} onClick={onNewClick} isLoading={sending}>
                    <Plus/> {strings.Messages.NewMessage}
                </Button>
            </HFlex>
            <UnorderedList>
                {inbox.map((m, index) => <InboxMessage key={index} message={m}/>)}
            </UnorderedList>
        </VFlex>
    );
}