import { ArrowLeft } from "baseui/icon";
import { HeadingLarge } from "baseui/typography";
import { useRecoilValue } from "recoil";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { signupStepState } from "../../states/signupState";
import { Centered } from "../containers/Centered";
import { VFlex } from "../containers/VFlex";
import { StylishBackButton } from "./ui/StylishBackButton";
import { StylishButton } from "./ui/StylishButton";
import { NoWrapText } from "../containers/NoWrapText";

type Props = {
    children?: React.ReactNode;
    title?: string;
    actionText: string;
    action: () => void;
    actionDisabled: boolean;
    isLoading?: boolean;
    back?: () => void;
    skipAll?: () => void;
    footer?: React.ReactNode;
    step: number;
    content?: React.ReactNode;
};

export function AccountStep({
                                children,
                                title,
                                actionText,
                                action,
                                back,
                                skipAll,
                                footer,
                                step,
                                actionDisabled,
                                content,
                                isLoading = false,
                            }: Props) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const currentStep = useRecoilValue(signupStepState);

    if (step !== currentStep) {
        return null;
    }

    return (
        <div
            style={{
                height: "100%",
                minWidth: "100%",
                textAlign: "center",
                transition: "transform 300ms ease-in-out",
            }}
        >
            <Centered style={{ width: "70%", margin: "0 auto" }}>
                {title && (
                    <HeadingLarge marginTop={0} marginBottom={theme.sizing.scale600}>
                        {title}
                    </HeadingLarge>
                )}
                {content && (
                    <VFlex spacing style={{ height: "auto", marginBottom: theme.sizing.scale800 }}>
                        {content}
                    </VFlex>
                )}
                <VFlex spacing={theme.sizing.scale900} style={{ width: "370px", height: "auto" }}>
                    {children}
                    <VFlex spacing style={{ height: "auto" }}>
                        <StylishButton onClick={action} shape="pill" disabled={actionDisabled} isLoading={isLoading}>
                            {actionText}
                        </StylishButton>
                        {skipAll && (<StylishBackButton
                                shape="pill"
                                kind="tertiary"
                                onClick={skipAll}
                            >
                                <NoWrapText>{strings.Signup.SkipAll}</NoWrapText>
                            </StylishBackButton>
                        )}
                        {back && (
                            <StylishBackButton
                                shape="pill"
                                onClick={back}
                                kind="tertiary"
                                disabled={isLoading}
                                startEnhancer={<ArrowLeft size={24}/>}
                            >
                                {strings.Signup.Back}
                            </StylishBackButton>
                        )}
                    </VFlex>
                </VFlex>
                {footer}
            </Centered>
        </div>
    );
}
