import { useState } from "react";
import { useRecoilValue } from "recoil";
import { useUpdateEffect } from "usehooks-ts";
import { stringsState } from "../../../states/localeState";
import { ConfirmModal } from "../../ui/ConfirmModal";
import { FormInput } from "../../ui/FormInput";

type Props = {
    isOpen: boolean;
    onCancel: () => void;
    onAccept: (name: string) => void | Promise<void>;
    isNewSequence?: boolean;
    title?: string;
    name?: string;
}

export function SequenceNameModal({ isOpen, onAccept, onCancel, title, name, isNewSequence = false }: Props) {
    const strings = useRecoilValue(stringsState);
    const [sequenceName, setSequenceName] = useState(name || '');

    if (!title) {
        title = isNewSequence ? strings.Sequences.NewEmptySequence : strings.Sequences.SaveSequenceAs;
    }

    useUpdateEffect(() => {
        setSequenceName(name || '');
    }, [isOpen]);

    return (
        <ConfirmModal
            title={title}
            description={
                <FormInput
                    label={strings.Sequences.SequenceName}
                    field="name"
                    value={sequenceName}
                    onChange={(_, value) => setSequenceName(value as string)}
                />
            }
            isOpen={isOpen}
            onConfirm={() => onAccept(sequenceName)}
            onCancel={onCancel}
            cancelText={strings.General.Cancel}
            confirmText={strings.General.Save}
            canConfirm={!!sequenceName}
        />
    )
}