import React from "react";
import { Checkbox, Input, InputNumber, Radio, Select, Typography } from "antd";
import { useStrings } from "../../hooks/useStrings";
import { FormItem } from "../../types/form";
import { VFlex } from "../containers/VFlex";

const { Text, Title } = Typography;
const { TextArea } = Input;

type Props = {
    index: number;
    formItem: FormItem;
    onChange?: (value: string | number) => void;
    onTouched?: () => void;
    size?: "small" | "middle" | "large",
    width?: number | string,
};

export function FormPreviewElement({ index, formItem, onChange, onTouched, size = "small", width = 180 }: Props) {
    const { strings } = useStrings();

    const item = formItem;
    const options = item.options.map((o) => {
        return {
            value: o,
            label: o,
        };
    });

    const style = { width };
    const itemName = item.name + (item.required ? " *" : "");

    return (
        <div key={index}>
            {item.type === "radio" && (
                <VFlex>
                    <Text>{itemName}</Text>
                    <Radio.Group
                        buttonStyle={"outline"}
                        size={size}
                        value={item.value}
                        onChange={(e) => onChange && onChange(e.target.value)}
                        onBlur={onTouched}
                    >
                        {options.map((o, index) => (
                            <Radio.Button key={index} value={o.value}>
                                {o.label}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </VFlex>
            )}
            {item.type === "dropdown" && (
                <VFlex>
                    <Text>{itemName}</Text>
                    <Select
                        showSearch
                        size={size}
                        style={style}
                        options={options}
                        value={item.value}
                        onChange={(value) => onChange && onChange(value)}
                        onBlur={onTouched}
                        allowClear
                    />
                </VFlex>
            )}
            {item.type === "section" && <Title level={4}>{itemName}</Title>}
            {item.type === "label" && <Text>{strings.Forms.FormsLabel}</Text>}
            {item.type === "checkbox" && (
                <Checkbox
                    checked={item.value === "true"}
                    onChange={(e) => onChange && onChange(e.target.checked ? "true" : "false")}
                >
                    {itemName}
                </Checkbox>
            )}
            {item.type === "text" && (
                <VFlex>
                    <Text>{itemName}</Text>
                    <Input
                        size={size}
                        style={style}
                        placeholder={itemName}
                        value={item.value}
                        onChange={(e) => onChange && onChange(e.target.value)}
                        onBlur={onTouched}
                    />
                </VFlex>
            )}
            {item.type === "paragraph" && (
                <VFlex>
                    <Text>{itemName}</Text>
                    <TextArea
                        size={size}
                        style={style}
                        placeholder={itemName}
                        value={item.value}
                        onChange={(e) => onChange && onChange(e.target.value)}
                        onBlur={onTouched}
                    />
                </VFlex>
            )}
            {item.type === "number" && (
                <VFlex>
                    <Text>{itemName}</Text>
                    <InputNumber
                        size={size}
                        style={style}
                        placeholder={itemName}
                        value={item.value}
                        onChange={(value) => onChange && onChange(value ?? "")}
                        onBlur={onTouched}
                    />
                </VFlex>
            )}
            {item.type === "decimal" && (
                <VFlex>
                    <Text>{itemName}</Text>
                    <InputNumber<string>
                        size={size}
                        stringMode
                        style={style}
                        step="0.1"
                        precision={0.1}
                        placeholder={itemName}
                        onChange={(value) => onChange && onChange(value ?? "")}
                        onBlur={onTouched}
                    />
                </VFlex>
            )}
        </div>
    );
}