import { GlobalDatePicker } from "../pickers/GlobalDatePicker";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { HFlex } from "../containers/HFlex";
import { useRecoilValue } from "recoil";
import { pageState } from "../../states/appState";
import { PAGE } from "../../constants/pages";
import { GlobalDayFramePicker } from "../pickers/GlobalDayFramePicker";
import { GlobalTimeSlider } from "../pickers/GlobalTimeSlider";
import { SwapPage } from "./SwapPage";
import useAccount from "../../hooks/useAccount";
import { isLocationOnlyPlan } from "../../utils/account";
import { useElementWidth } from "../../hooks/useElementWidth";
import { MutableRefObject, useRef } from "react";

type Props = {
    title?: string;
    withoutDate?: boolean;
    withTimeSlider?: boolean;
    expandedDate?: boolean;
    withClose?: boolean;
}

export function CardTitleWithDate({
                                      title,
                                      withoutDate = false,
                                      withTimeSlider = false,
                                      expandedDate = false,
                                      withClose = false,
                                  }: Props) {
    const page = useRecoilValue(pageState);
    const { company } = useAccount();
    const isDispatchPlan = !isLocationOnlyPlan(company?.stripe_plan);
    const ref = useRef() as MutableRefObject<HTMLDivElement>;
    const { elementWidth } = useElementWidth(ref);

    return (
        <HFlex ref={ref} alignCenter style={{ height: "64px" }}>
            {elementWidth > 280 ?
                (isDispatchPlan && (page === PAGE.LIVE || page === PAGE.DISPATCH) ? <SwapPage/> : title)
                : undefined}
            <SpacerExpand/>
            {withTimeSlider && <>
                <Spacer width={"16px"}/>
                <GlobalTimeSlider/>
                <Spacer/>
            </>}
            {!withoutDate && page !== PAGE.HEATMAP && <GlobalDatePicker expanded={expandedDate}/>}
            {!withoutDate && page === PAGE.HEATMAP && <GlobalDayFramePicker/>}
        </HFlex>
    );
}