import { saveTimeSheetEntry } from "../services/api";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { timesheetEventState } from "../states/viewState";
import { TimesheetEntry } from "../types/timesheet";
import { dayState } from "../states/appState";
import { dateToTime, dayOfToday, dayToDateBrowserTimezone, timeToDate } from "../utils/time";
import { EMPTY_TS, Timestamp, Uid } from "../types/core";

export function useTimesheet() {
    const day = useRecoilValue(dayState);
    const setTimesheetEvent = useSetRecoilState(timesheetEventState);

    const removeEntry = async (entry: TimesheetEntry) => {
        await saveEntry(entry.uid, entry.clockIn?.id || "", null, entry.clockOut?.id || "", null);
    };

    const editEntry = async (entry: TimesheetEntry, dateIn: Date | null, dateOut: Date | null) => {
        await saveEntry(entry.uid, entry.clockIn?.id || "", dateIn, entry.clockOut?.id || "", dateOut);
    }

    const newEntry = async (uid: Uid, dateIn: Date | null, dateOut: Date | null) => {
        await saveEntry(uid, "", dateIn, "", dateOut);
        setTimesheetEvent(Date.now());
    }

    const saveEntry = async (uid: Uid, idIn: string, dateIn: Date | null, idOut: string, dateOut: Date | null) => {
        const dateOfDay = dayToDateBrowserTimezone(day);
        const dateOfToday = dayToDateBrowserTimezone(dayOfToday());
        const diffMillis = dateOfDay.getTime() - dateOfToday.getTime();
        const timeIn: Timestamp = (dateIn && (timeToDate(dateToTime(dateIn)).getTime() + diffMillis)) as Timestamp;
        const timeOut: Timestamp = (dateOut && (timeToDate(dateToTime(dateOut)).getTime() + diffMillis)) as Timestamp;
        const data = {
            memberUid: uid,
            clockInId: idIn,
            clockInTs: timeIn || EMPTY_TS,
            clockOutId: idOut,
            clockOutTs: timeOut || EMPTY_TS,
        }
        await saveTimeSheetEntry(data);
        setTimesheetEvent(Date.now());
    }

    return { removeEntry, editEntry, newEntry }
}