import { Button } from 'baseui/button';
import { arrayMove, arrayRemove, List } from 'baseui/dnd-list';
import { Plus } from 'baseui/icon';
import { Input } from 'baseui/input';
import { ParagraphSmall, ParagraphXSmall } from 'baseui/typography';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useStyleOverrides } from '../../hooks/useStyleOverrides';
import { stringsState } from '../../states/localeState';
import { CustomField } from '../../types/core';
import { HFlex } from '../containers/HFlex';
import { Spacer, SpacerExpand } from '../containers/Spacer';
import { Tooltip } from "antd";
import { RemoveIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { VFlex } from '../containers/VFlex';

type Props = {
    fields: CustomField[];
    onSaveAsDefault?: () => void,
    onUpdateFields: (fields: CustomField[], sorted?: boolean) => void;
    target: "places" | "jobs";
};

export function CustomFields({ fields, onSaveAsDefault, onUpdateFields, target }: Props) {
    const { theme } = useTheme();
    const [isAddingField, setIsAddingField] = useState(false);
    const strings = useRecoilValue(stringsState);
    const [newField, setNewField] = useState('');
    const [newFieldValue, setNewFieldValue] = useState('');
    const { readOnlyInputStyles } = useStyleOverrides();
    const newFieldIsValid = fields.findIndex((field) => field.key === newField.trim()) === -1

    const onAddField = () => {
        const newFields = [...fields];
        newFields.push({ val: newFieldValue, key: newField });
        onUpdateFields(newFields);
        setNewField('');
        setNewFieldValue('');
    }

    const onEditValue = (key: string, value: string, index: number) => {
        const editedCustomField = {
            val: value,
            key,
        }
        const newFields = [...fields];
        newFields[index] = editedCustomField;
        onUpdateFields(newFields);
    };

    const items = fields.map((field, index) => {
        return (
            <HFlex key={index} spacing>
                <Input value={field.key} readOnly size="compact" overrides={readOnlyInputStyles}/>
                <Input
                    value={field.val}
                    onChange={(event) => onEditValue(field.key, event.target.value, index)}
                    size="compact"
                    placeholder={strings.CustomFields.EnterValue}
                    type="text"
                />
            </HFlex>
        );
    });

    return (
        <>
            <ParagraphSmall marginTop={theme.sizing.scale300}>{strings.CustomFields.CustomFieldsDesc}</ParagraphSmall>
            {!!items.length && (
                <List
                    items={items}
                    removable
                    onChange={({ oldIndex, newIndex }) => {
                        const newFields =
                            newIndex === -1 ? arrayRemove(fields, oldIndex) : arrayMove(fields, oldIndex, newIndex);
                        onUpdateFields(newFields);
                    }}
                    overrides={{
                        List: {
                            style: {
                                marginTop: 0,
                                marginBottom: 0,
                            },
                        },
                        Item: {
                            style: {
                                paddingTop: "4px",
                                paddingBottom: "4px"
                            }
                        }
                    }}
                />
            )}
            {!isAddingField && (
                <HFlex spacing alignCenter>
                    <SpacerExpand/>
                    <Button size="compact" onClick={() => setIsAddingField(true)} kind="tertiary">
                        <Plus/>
                        <Spacer/>
                        {strings.CustomFields.AddCustomField}
                    </Button>
                    {onSaveAsDefault &&
                        <Tooltip
                            title={target === "places"
                                ? strings.General.SaveCustomFieldsForPlacesDesc
                                : strings.General.SaveCustomFieldsForJobsDesc}>
                            <Button size="compact" onClick={onSaveAsDefault} kind="tertiary">
                                {strings.General.SaveTemplate}
                            </Button>
                        </Tooltip>
                    }
                </HFlex>
            )}
            {isAddingField && (
                <VFlex>
                    <HFlex spacing alignCenter>
                        <Button size={"mini"}
                                kind={"tertiary"}
                                onClick={() => setIsAddingField(false)}
                                style={{ width: "42px", flexShrink: 0, marginLeft: "8px" }}
                        >
                            <RemoveIcon size={24} color={theme.colors.contentPrimary}/>
                        </Button>
                        <Input
                            value={newField}
                            onChange={(event) => {
                                setNewField(event.target.value);
                            }}
                            size="compact"
                            type="text"
                            placeholder={strings.CustomFields.FieldName}
                            autoFocus
                        />
                        <Input
                            value={newFieldValue}
                            onChange={(event) => setNewFieldValue(event.target.value)}
                            size="compact"
                            type="text"
                            placeholder={strings.CustomFields.EnterValue}
                        />
                        <Button onClick={onAddField} disabled={!newField || !newField.trim() || !newFieldIsValid} size="compact">
                            {strings.General.Add}
                        </Button>
                    </HFlex>
                    {!newFieldIsValid && (
                        <ParagraphXSmall marginTop={theme.sizing.scale300} marginBottom={theme.sizing.scale300} marginLeft={"58px"} color={theme.colors.negative}>
                            {strings.CustomFields.DuplicatedFieldError}
                        </ParagraphXSmall>
                    )}
                </VFlex>
            )}
        </>
    );
}
