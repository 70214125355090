import { API_ENDPOINT, STATUS_OK } from "./api";
import { Md5 } from "ts-md5";
import { Job } from "../types/job";
import { Uid } from "../types/core";

export const retrieveSingleJob = async (companyUid: Uid, jobId: string) => {
    const hash = Md5.hashStr(`8410${companyUid}${jobId}`);
    const url = API_ENDPOINT + `/singlejob/${companyUid}/${jobId}/${hash}`;
    const result = await fetch(url);
    const json = await result.json();
    if (json.status === STATUS_OK) {
        return { status: true, job: json.job as Job, companyName: json.companyName as string };
    } else {
        return { status: false };
    }
}