import { ColumnCard } from "../../board/ColumnCard";
import { useTheme } from "../../../hooks/useTheme";
import { PinFilledIcon, RemoveIcon } from "../../ui/svg";
import { HFlex } from "../../containers/HFlex";
import { LabelMedium } from "baseui/typography";
import PinnedJobList from "./PinnedJobList";
import { Droppable } from "react-beautiful-dnd";
import { DROPPABLE_PINNED, DROPPABLE_TYPE_JOB } from "../../../constants/dnd";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { jobIdsPinnedState, pinnedJobsState } from "../../../states/jobsState";
import { PinnedJobItem } from "./PinnedJobItem";
import { useStrings } from "../../../hooks/useStrings";
import { SpacerExpand } from "../../containers/Spacer";
import { Button } from "baseui/button";
import { EmptyState } from "../../ui/EmptyState";
import React, { memo } from "react";
import { HoverButton } from "../../buttons/HoverButton";
import { dispatchTreeActiveCardState } from "../../../states/viewState";

export const PinnedJobsColumn = memo(() => {
    const { strings } = useStrings();
    const { theme, isDarkTheme } = useTheme();
    const pinnedJobs = useRecoilValue(pinnedJobsState);
    const reset = useResetRecoilState(jobIdsPinnedState);
    const hoverColor = isDarkTheme ? theme.colors.positive700 : theme.colors.positive50;
    const close = useResetRecoilState(dispatchTreeActiveCardState);

    const onClearClicked = () => {
        reset();
    };

    return (
        <ColumnCard width={260} color={"transparent"} title={
            <HFlex spacing>
                <PinFilledIcon size={16} color={theme.colors.contentPrimary}/>
                <LabelMedium>{strings.Dispatch.PinnedJobs}</LabelMedium>
                <SpacerExpand/>
                <Button kind={"secondary"} size={"mini"} onClick={onClearClicked}>{strings.General.Clear}</Button>
                <HoverButton tooltip={strings.General.RemoveFromBoard}
                             kind={"tertiary"}
                             onClick={() => { close() }}
                >
                    <RemoveIcon color={theme.colors.contentPrimary} size={14}/>
                </HoverButton>
            </HFlex>}
        >
            <Droppable
                droppableId={DROPPABLE_PINNED}
                type={DROPPABLE_TYPE_JOB}
                isDropDisabled={false}
                mode="virtual"
                renderClone={(provided, snapshot, rubric) => (
                    <PinnedJobItem provided={provided}
                                   isDragging={snapshot.isDragging}
                                   jobId={pinnedJobs[rubric.source.index].id}/>
                )}
            >
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            backgroundColor: snapshot.isDraggingOver ? hoverColor : "inherit",
                            height: pinnedJobs.length ? "100%" : "auto",
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            overflow: "hidden",
                        }}
                    >
                        <PinnedJobList isUsingPlaceholder={snapshot.isUsingPlaceholder}/>
                    </div>
                )}
            </Droppable>
            {pinnedJobs.length === 0 && <EmptyState title={strings.General.NothingPinnedYet}/>}
        </ColumnCard>
    );
});