import { useRecoilValue, useSetRecoilState } from "recoil";
import { memberState } from "../../states/membersState";
import React from "react";
import { Tag } from "antd";
import useAccount from "../../hooks/useAccount";
import { COLOR_COMPANY } from "../../constants/colors";
import { getTruncatedMemberName } from "../../types/member";
import { Clickable } from "../containers/Clickable";
import { EditUserIcon } from "../ui/svg";
import { HFlex } from "../containers/HFlex";
import { editingMemberIdState } from "../../states/appState";
import { useTheme } from "../../hooks/useTheme";
import { Uid } from "../../types/core";

type Props = {
    uid: Uid;
    onClose?: () => void,
    ellipsed?: boolean;
    shortName?: boolean;
    edit?: boolean;
    inverse?: boolean;
    fallbackName?: string;
}

export function MemberBadge({ uid, onClose, ellipsed = false, shortName = false, edit = false, inverse = false, fallbackName }: Props) {
    const member = useRecoilValue(memberState(uid));
    const company = useAccount();
    const setEditingMember = useSetRecoilState(editingMemberIdState);
    const { theme } = useTheme();

    const isCompany = uid === company.uid;
    const name = isCompany ? company.name : member?.name || fallbackName || "";

    if (!name) {
        return null;
    }

    const color = isCompany ? COLOR_COMPANY : member?.color || "#000000";

    const ellipsedStyles = ellipsed ? {
        overflow: "hidden",
        textOverflow: "ellipsis",
    } : {};

    const tagStyle: any = {
        backgroundColor: inverse ? color : color + "22",
        borderColor: color,
        borderStyle: 'solid',
        marginRight: "0px",
        color: inverse ? "#FFFFFF" : undefined,
        display: "flex",
        whiteSpace: "nowrap",
        ...ellipsedStyles,
    };

    return (
        <Tag key={uid} closable={onClose !== undefined}
             onClose={(e) => {
                 e.preventDefault();
                 if (onClose) {
                     onClose();
                 }
             }}
             style={tagStyle}
        >
            <HFlex alignCenter spacing>
                {shortName && !isCompany && member ? getTruncatedMemberName(member) : name}

                {edit && (
                    <Clickable onClick={() => member && setEditingMember(member?.uid)}>
                        <EditUserIcon size={8} color={theme.colors.contentPrimary}/>
                    </Clickable>
                )}
            </HFlex>
        </Tag>
    );
}