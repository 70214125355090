import React from "react";

type IconProps = {
    color?: string;
    size?: number;
    text?: string;
    animated?: boolean;
    repeatCount?: number | "indefinite";
    duration?: string;
    style?: any;
    onClick?: () => void;
};

export function LogoIcon({ color, size }: IconProps) {
    return (
        <svg width={size}
             height={size} id="Layer_1" className="navbar__logo" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 31.35 31.35">
            <defs>
                <style>{`.cls-1{fill:none;stroke:${color};strokeMiterlimit:22.93;strokeWidth:1.04px;}.cls-1,.cls-2{fillRule:evenodd;}.cls-2{fill:${color};}`}</style>
            </defs>
            <path fill={color} className="cls-1"
                  d="M17.5,32.66A15.16,15.16,0,1,1,32.66,17.5,15.16,15.16,0,0,1,17.5,32.66Z"
                  transform="translate(-1.82 -1.82)"/>
            <path fill={color} className="cls-2"
                  d="M26.7,18.92a9,9,0,0,1-3.09,5.66,9.07,9.07,0,0,1-6.08,2.19,9.07,9.07,0,0,1-9.28-9.29A9.06,9.06,0,0,1,17.52,8.2a9.13,9.13,0,0,1,4.29,1.05v2.54a6.88,6.88,0,0,0-4.28-1.43,7,7,0,0,0-7.12,7.13,7,7,0,0,0,7.13,7.15,7,7,0,0,0,7.1-6.43h0V7.68a11.84,11.84,0,0,0-7.15-2.33A11.91,11.91,0,0,0,5.4,17.49,11.89,11.89,0,0,0,17.53,29.65,11.92,11.92,0,0,0,29.6,18.92ZM17.51,13.2a4.19,4.19,0,0,1,4.3,4.28,4.14,4.14,0,0,1-1.22,3.07,4.19,4.19,0,0,1-3.08,1.21,4.19,4.19,0,0,1-4.29-4.28,4.19,4.19,0,0,1,4.29-4.28Z"
                  transform="translate(-1.82 -1.82)"/>
        </svg>
    );
}

export function LocateIcon({ size, color }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 32 32"
        >
            <g fill={color}>
                <path
                    fill={color}
                    d="M16,30C8.28,30,2,23.72,2,16S8.28,2,16,2s14,6.28,14,14S23.72,30,16,30z M16,4C9.383,4,4,9.383,4,16 s5.383,12,12,12s12-5.383,12-12S22.617,4,16,4z"
                ></path>
                <rect x="15" fill={color} width="2" height="7"></rect>
                <rect x="25" y="15" fill={color} width="7" height="2"></rect>
                <rect x="15" y="25" fill={color} width="2" height="7"></rect>
                <rect y="15" fill={color} width="7" height="2"></rect>
                <circle cx="16" cy="16" r="4"></circle>
            </g>
        </svg>
    );
}

export function DispatchIcon({ size, color }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 32 32"
        >
            <g
                strokeLinecap="square"
                strokeLinejoin="miter"
                strokeWidth="2"
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
            >
                <path d="M13,30 c0,0-11-11.134-11-17.952C2,5.057,7.684,1,13,1s11,4.057,11,11.048"></path>
                <circle cx="13" cy="12" r="3"></circle>
                <circle cx="24" cy="24" r="7" stroke={color}></circle>
                <polyline points=" 24,21 24,24 27,24 " stroke={color}></polyline>
            </g>
        </svg>
    );
}

export function PinSmall({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32">
            <g transform="translate(0, 0)">
                <path
                    fill={color}
                    d="M16,0C10.1,0,4,4.5,4,12c0,7.1,10.8,18.2,11.3,18.7c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3C17.2,30.2,28,19.2,28,12C28,4.5,21.9,0,16,0z M16,16c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S18.2,16,16,16z"
                ></path>
            </g>
        </svg>
    );
}

export function PinLarge({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48">
            <g transform="translate(0, 0)">
                <path
                    fill={color}
                    d="M24,1C15.644,1,7,7.359,7,18c0,11.285,15.627,27.042,16.293,27.707C23.48,45.895,23.735,46,24,46s0.52-0.105,0.707-0.293C25.373,45.042,41,29.285,41,18C41,7.359,32.356,1,24,1z M24,24c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S27.309,24,24,24z"
                ></path>
            </g>
        </svg>
    );
}

export function CloseIcon({ size = 16, color = '#444444', style }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={size + "px"}
            height={size + "px"}
            style={style}
            viewBox="0 0 16 16"
        >
            <g transform="translate(0, 0)">
                <line
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="13.5"
                    y1="2.5"
                    x2="2.5"
                    y2="13.5"
                    data-cap="butt"
                ></line>
                <line
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="2.5"
                    y1="2.5"
                    x2="13.5"
                    y2="13.5"
                    data-cap="butt"
                ></line>
            </g>
        </svg>
    );
}

export function TrafficIcon({ color = '#444444', size }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={size}
            height={size}
            viewBox="0 0 32 32"
        >
            <g transform="translate(0, 0)">
                <polygon
                    data-color="color-2"
                    fill={color}
                    points="5,10 3,10 0,4 5,4 "
                ></polygon>
                <polygon
                    data-color="color-2"
                    fill={color}
                    points="5,19 3,19 0,13 5,13 "
                ></polygon>
                <polygon
                    data-color="color-2"
                    fill={color}
                    points="5,28 3,28 0,22 5,22 "
                ></polygon>
                <polygon
                    data-color="color-2"
                    fill={color}
                    points="27,10 29,10 32,4 27,4 "
                ></polygon>
                <polygon
                    data-color="color-2"
                    fill={color}
                    points="27,19 29,19 32,13 27,13 "
                ></polygon>
                <polygon
                    data-color="color-2"
                    fill={color}
                    points="27,28 29,28 32,22 27,22 "
                ></polygon>
                <path
                    fill={color}
                    d="M24,0H8C7.447,0,7,0.448,7,1v30c0,0.552,0.447,1,1,1h16c0.553,0,1-0.448,1-1V1C25,0.448,24.553,0,24,0zM16,28c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S17.654,28,16,28z M16,19c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S17.654,19,16,19z M16,10c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S17.654,10,16,10z"
                ></path>
            </g>
        </svg>
    );
}

export function JobsLayerIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect stroke={color} fill="transparent" strokeWidth="3" x="3.51515" y="3.88497" width="25"
                      height="15.10753" rx="8"/>
                <line y2="19.8658" x2="16.15152" y1="29.29437" x1="16.15152" strokeWidth="3" stroke={color}
                      fill="none"/>
                <text transform="matrix(0.636764 0 0 0.45559 3.88553 13.7578)" stroke={color} fontStyle="normal"
                      fontWeight="bold" textAnchor="start" fontFamily="sans-serif" fontSize="24"
                      y="3.15943" x="11.8867" strokeWidth="0" fill={color}>
                    1
                </text>
            </g>
        </svg>
    );
}

export function RoutesLayerIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10">
                <line fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2" strokeDasharray="4" x1="25" x2="7" y1="26" y2="26"/>
                <line fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2" strokeDasharray="4" x1="17.8" x2="26.2" y1="9" y2="23"/>
                <line fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2" strokeDasharray="4" x1="5.8" x2="14.2" y1="23" y2="9"/>
                <circle cx="28" cy="26" r="3" fill={color} stroke={color} strokeWidth="2"/>
                <circle cx="16" cy="6" r="3" fill={color} stroke={color} strokeWidth="2"/>
                <circle cx="4" cy="26" r="3" fill={color} stroke={color} strokeWidth="2"/>
            </g>
        </svg>
    );
}

export function WaypointsLayerIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path d="M21,3h-3c-0.553,0-1-0.448-1-1s0.447-1,1-1h3c0.553,0,1,0.448,1,1S21.553,3,21,3z" fill={color}/>
                <path d="M37,1H27c-0.553,0-1,0.448-1,1s0.447,1,1,1h10c4.411,0,8,3.589,8,8s-3.589,8-8,8h-4v2h4 c5.514,0,10-4.486,10-10S42.514,1,37,1z" fill={color}/>
                <path d="M27.65,37.241l-7-6c-0.296-0.253-0.712-0.311-1.068-0.149C19.228,31.255,19,31.609,19,32v5h-8 c-4.411,0-8-3.589-8-8s3.589-8,8-8h4v-2h-4C5.486,19,1,23.486,1,29s4.486,10,10,10h8v5c0,0.391,0.228,0.745,0.582,0.909 C19.716,44.97,19.858,45,20,45c0.234,0,0.466-0.083,0.65-0.241l7-6C27.872,38.569,28,38.292,28,38S27.872,37.431,27.65,37.241z" fill={color}/>
                <circle cx="24" cy="20" r="7"/>
            </g>
        </svg>
    );
}

export function ZonesIcon({ color = '#444444', size }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={size}
            height={size}
            viewBox="0 0 32 32"
        >
            <g transform="translate(0, 0)">
                <path
                    fill={color}
                    d="M31,22h-2.78l-2.4-12H27c0.552,0,1-0.448,1-1V3c0-0.552-0.448-1-1-1h-6c-0.552,0-1,0.448-1,1v2h-8V3c0-0.552-0.448-1-1-1H5C4.448,2,4,2.448,4,3v6c0,0.552,0.448,1,1,1h1.18l-2.4,12H1c-0.552,0-1,0.448-1,1v6c0,0.552,0.448,1,1,1h6c0.552,0,1-0.448,1-1v-2h16v2c0,0.552,0.448,1,1,1h6c0.552,0,1-0.448,1-1v-6C32,22.448,31.552,22,31,22z M8,25v-2c0-0.552-0.448-1-1-1H5.82l2.4-12H11c0.552,0,1-0.448,1-1V7h8v2c0,0.552,0.448,1,1,1h2.78l2.4,12H25c-0.552,0-1,0.448-1,1v2H8z"
                ></path>
            </g>
        </svg>
    );
}

export function PlacesIcon({ color = '#444444', size, style }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={size}
            height={size}
            style={style}
            viewBox="0 0 32 32"
        >
            <g transform="translate(0, 0)">
                <path
                    data-color="color-2"
                    fill={color}
                    d="M19,22.093v1.998c6.606,0.413,10,2.123,10,2.909c0,0.897-4.423,3-13,3S3,27.897,3,27c0-0.786,3.394-2.496,10-2.909v-1.998C6.778,22.471,1,24.039,1,27c0,3.436,7.775,5,15,5s15-1.564,15-5	C31,24.039,25.222,22.471,19,22.093z"
                ></path>
                <path
                    fill={color}
                    d="M25,9c0-4.962-4.037-9-9-9S7,4.038,7,9c0,4.624,3.507,8.442,8,8.941V27h2v-9.059	C21.493,17.442,25,13.624,25,9z"
                ></path>
            </g>
        </svg>
    );
}

export function ContractIcon({ color = '#444444' }: IconProps) {
    return (
        <svg
            width="20px"
            height="20px"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            transform="translate(0, 0)"
        >
            <g fill={color} transform="translate(4, 4)">
                <path d="m13.3 10.7v-10.7l10.7 10.7z"/>
                <path d="m10.7 13.3h-10.7l10.7 10.7z"/>
            </g>
        </svg>
    );
}

export function ExpandIcon({ color = '#444444' }: IconProps) {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            transform="translate(0, 0)"
        >
            <g fill={color} transform="translate(4, 4)">
                <path d="m24 0v13.5l-13.5-13.5z"/>
                <path d="m0 24h13.5l-13.5-13.5z"/>
            </g>
        </svg>
    );
}

export function JobIcon({ color, size, text, style }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 32 32"
            style={style}
        >
            <g>
                <path
                    strokeWidth={0.5}
                    stroke={"#DDDDDD"}
                    fill={color}
                    d="M16,31.1c-0.2,0-0.3-0.1-0.5-0.2c-0.4-0.5-10.9-11-10.9-18.6C4.5,6.1,9.5,1,15.8,0.9c0.1,0,0.1,0,0.2,0c6.2-0.1,11.3,4.9,11.4,11.2c0,0.1,0,0.1,0,0.2c0,7.6-10.5,18.1-10.9,18.6C16.3,31,16.2,31.1,16,31.1z"
                />
            </g>
            <text
                textAnchor="middle"
                x="50%"
                y="40%"
                dy=".35em"
                fontFamily="sans-serif"
                fontSize="11px"
                fill="rgb(255,255,255)"
            >
                {text}
            </text>
        </svg>
    );
}

export function OptimizeIcon({ color, size }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 32 32"
        >
            <g
                strokeLinecap="square"
                strokeLinejoin="miter"
                strokeWidth="2"
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
            >
                <polygon points="2 26 6 30 23.974 11.974 20.026 7.974 2 26"></polygon>
                <line
                    x1="16.026"
                    y1="11.974"
                    x2="20.026"
                    y2="15.974"
                    strokeLinecap="butt"
                ></line>
                <line x1="30.121" y1="1.879" x2="25.879" y2="6.121" stroke={color}></line>
                <line x1="25.879" y1="1.879" x2="30.121" y2="6.121" stroke={color}></line>
                <line
                    x1="30.121"
                    y1="19.879"
                    x2="25.879"
                    y2="24.121"
                    stroke={color}
                ></line>
                <line
                    x1="25.879"
                    y1="19.879"
                    x2="30.121"
                    y2="24.121"
                    stroke={color}
                ></line>
                <line x1="12.121" y1="1.879" x2="7.879" y2="6.121" stroke={color}></line>
                <line x1="7.879" y1="1.879" x2="12.121" y2="6.121" stroke={color}></line>
            </g>
        </svg>
    );
}

export function VDotsIcon({ color, size }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 32 32"
        >
            <g fill={color}>
                <path fill={color} d="M19 4a3 3 0 1 0-6 0 3 3 0 1 0 6 0z"></path>
                <path fill={color} d="M19 16a3 3 0 1 0-6 0 3 3 0 1 0 6 0z"></path>
                <path fill={color} d="M19 28a3 3 0 1 0-6 0 3 3 0 1 0 6 0z"></path>
            </g>
        </svg>
    );
}

export function CheckboxUncheckedIcon({ color, size, onClick }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            onClick={onClick}
            viewBox="0 0 32 32"
            style={{ cursor: onClick ? "pointer" : undefined }}
        >
            <g fill={color}>
                <path
                    d="M27,31H5a4,4,0,0,1-4-4V5A4,4,0,0,1,5,1H27a4,4,0,0,1,4,4V27A4,4,0,0,1,27,31ZM5,3A2,2,0,0,0,3,5V27a2,2,0,0,0,2,2H27a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2Z"
                    fill={color}
                ></path>
            </g>
        </svg>
    );
}

export function CheckboxCheckedIcon({ color, size, onClick }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            onClick={onClick}
            viewBox="0 0 32 32"
            style={{ cursor: onClick ? "pointer" : undefined }}
        >
            <g fill={color}>
                <path
                    d="M27,1H5A4,4,0,0,0,1,5V27a4,4,0,0,0,4,4H27a4,4,0,0,0,4-4V5A4,4,0,0,0,27,1Zm-1.793,9.707-12,12a1,1,0,0,1-1.414,0l-5-5a1,1,0,1,1,1.414-1.414L12.5,20.586,23.793,9.293a1,1,0,0,1,1.414,1.414Z"
                    fill={color}
                ></path>
            </g>
        </svg>
    );
}

export function DeselectAllIcon({ color, size }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 32 32"
        >
            <g fill={color}>
                <path
                    d="M29,1H3A2,2,0,0,0,1,3V29a2,2,0,0,0,2,2H29a2,2,0,0,0,2-2V3A2,2,0,0,0,29,1ZM24,17H8V15H24Z"
                    fill={color}
                ></path>
            </g>
        </svg>
    );
}

export function NavigateIcon({ color, size }: IconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 32 32"
        >
            <g fill={color}>
                <path
                    d="M30.614,10.21a1.037,1.037,0,0,1,0,1.58l-9,7A1,1,0,0,1,20,18V12H3V29a1,1,0,0,1-2,0V11a1,1,0,0,1,1-1H20V4a1,1,0,0,1,1.614-.79Z"
                    fill={color}
                ></path>
            </g>
        </svg>
    );
}

export function TriangleLeftIcon({ color, size }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path
                    d="M24.5,29.9A1.05,1.05,0,0,0,25,29V3a1.05,1.05,0,0,0-.5-.9.92.92,0,0,0-1,.1l-18,13A1.02,1.02,0,0,0,5,16a1.234,1.234,0,0,0,.4.8l18,13a1.421,1.421,0,0,0,.6.2A.9.9,0,0,0,24.5,29.9Z"
                    fill={color}></path>
            </g>
        </svg>
    );
}

export function TriangleRightIcon({ color, size }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path
                    d="M27,16a1.234,1.234,0,0,0-.4-.8l-18-13a1.073,1.073,0,0,0-1.1-.1A1.05,1.05,0,0,0,7,3V29a1.05,1.05,0,0,0,.5.9A.9.9,0,0,0,8,30a1.421,1.421,0,0,0,.6-.2l18-13A1.234,1.234,0,0,0,27,16Z"
                    fill={color}></path>
            </g>
        </svg>
    );
}

export function CrossRoadIcon({ color, size }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 64 64">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <polyline points="32 18 12 18 6 24 12 30 32 30"></polyline>
                <polyline points="37 8 52 8 58 14 52 20 37 20" stroke={color}></polyline>
                <polyline points="37 28 52 28 58 34 52 40 37 40" stroke={color}></polyline>
                <line x1="40" y1="62" x2="24" y2="62"></line>
                <line x1="32" y1="62" x2="32" y2="2"></line>
            </g>
        </svg>
    );
}

export function AllJobsIcon({ color, size }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 64 64">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <path
                    d="M30.854,5.202 C34.178,3.177,38.223,2,42,2c9.665,0,20,7.01,20,19.091C62,32.874,42,54,42,54s-2.617-2.706-6.244-7.202"
                    strokeLinecap="butt" stroke={color}></path>
                <path d="M46,26 c0,12.689-22,34-22,34S2,38.689,2,26C2,12.99,13.369,4,24,4S46,12.99,46,26z"></path>
                <circle cx="24" cy="26" r="8"></circle>
            </g>
        </svg>
    );
}

export function HighPriorityIcon({ size }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
            <g>
                <path
                    d="M24,46.536a4.963,4.963,0,0,1-3.535-1.465h0L2.928,27.534a5.006,5.006,0,0,1,0-7.071L20.465,2.926a5.005,5.005,0,0,1,7.07,0L45.072,20.463a5.006,5.006,0,0,1,0,7.071L27.535,45.071A4.961,4.961,0,0,1,24,46.536Z"
                    fill="#d55240"></path>
                <path
                    d="M33,23a1,1,0,0,1-.707-.293L24,14.414l-8.293,8.293a1,1,0,0,1-1.414-1.414l9-9a1,1,0,0,1,1.414,0l9,9A1,1,0,0,1,33,23Z"
                    fill="#fff"></path>
                <path
                    d="M33,31a1,1,0,0,1-.707-.293L24,22.414l-8.293,8.293a1,1,0,0,1-1.414-1.414l9-9a1,1,0,0,1,1.414,0l9,9A1,1,0,0,1,33,31Z"
                    fill="#fff"></path>
            </g>
        </svg>
    );
}

export function NormalPriorityIcon({ size }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
            <g>
                <path
                    d="M27.535,2.929,45.072,20.466a5.006,5.006,0,0,1,0,7.071L27.535,45.074a5.005,5.005,0,0,1-7.07,0L2.928,27.537a5.006,5.006,0,0,1,0-7.071L20.465,2.929h0a5,5,0,0,1,7.07,0Z"
                    fill="#ffd764"></path>
                <path d="M33,21H15a1,1,0,0,1,0-2H33a1,1,0,0,1,0,2Z" fill="#fff"></path>
                <path d="M33,29H15a1,1,0,0,1,0-2H33a1,1,0,0,1,0,2Z" fill="#fff"></path>
            </g>
        </svg>
    );
}

export function LowPriorityIcon({ size }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
            <g>
                <path
                    d="M27.535,2.929,45.072,20.466a5.006,5.006,0,0,1,0,7.071L27.535,45.074a5.005,5.005,0,0,1-7.07,0L2.928,27.537a5.006,5.006,0,0,1,0-7.071L20.465,2.929h0a5,5,0,0,1,7.07,0Z"
                    fill="#4da34d"></path>
                <path
                    d="M33,25a1,1,0,0,0-.707.293L24,33.586l-8.293-8.293a1,1,0,0,0-1.414,1.414l9,9a1,1,0,0,0,1.414,0l9-9A1,1,0,0,0,33,25Z"
                    fill="#fff"></path>
                <path
                    d="M33,17a1,1,0,0,0-.707.293L24,25.586l-8.293-8.293a1,1,0,0,0-1.414,1.414l9,9a1,1,0,0,0,1.414,0l9-9A1,1,0,0,0,33,17Z"
                    fill="#fff"></path>
            </g>
        </svg>
    );
}

export function SingleCheckIcon({ size, color, style }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} style={style} viewBox="0 0 32 32">
            <g fill={color}>
                <path
                    d="M11,29a1,1,0,0,1-.707-.293L.586,19,2,17.586l8.9,8.9L29.809,1.6,31.4,2.809l-19.6,25.8a1,1,0,0,1-.728.393Z"
                    fill={color}></path>
            </g>
        </svg>
    );
}

export function DoubleCheckIcon({ size, color, style }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} style={style} viewBox="0 0 32 32">
            <g fill={color}>
                <path
                    d="M29.864,5.593,15.928,22.514l-1.545-1.545L13.106,22.52l2.187,2.187A1,1,0,0,0,16,25h.048a1,1,0,0,0,.724-.363L31.408,6.864Z"></path>
                <path
                    d="M8,25a1,1,0,0,1-.707-.293L.586,18,2,16.586l5.928,5.928L21.864,5.593l1.544,1.271L8.772,24.636A1,1,0,0,1,8.048,25Z"
                    fill={color}></path>
            </g>
        </svg>);
}

export function BoldCheckIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <polygon points="11.714 27.786 1 17.071 5.286 12.786 11.714 19.214 26.714 4.214 31 8.5 11.714 27.786"
                         fill={color}></polygon>
            </g>
        </svg>
    );
}

export function VerifiedIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path
                    d="M28.225,2.821l-12-2.769a1.035,1.035,0,0,0-.45,0l-12,2.769A1,1,0,0,0,3,3.8V18c0,8.976,10.553,13.144,12.668,13.889a1,1,0,0,0,.332.056.988.988,0,0,0,.331-.056C18.447,31.147,29,26.993,29,18V3.8A1,1,0,0,0,28.225,2.821ZM14.057,21.471,8.586,16,10,14.586l3.943,3.943,7.949-9.272,1.518,1.3Z"
                    fill={color}></path>
            </g>
        </svg>
    );
}

export function PhoneIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <path
                    d="M20.693,19.293l-2.282,2.853a24.077,24.077,0,0,1-8.557-8.557l2.853-2.282a1.939,1.939,0,0,0,.56-2.3l-2.6-5.855A1.939,1.939,0,0,0,8.407,2.062L3.457,3.346A1.95,1.95,0,0,0,2.018,5.5,28.761,28.761,0,0,0,26.5,29.982a1.95,1.95,0,0,0,2.153-1.439l1.284-4.95a1.939,1.939,0,0,0-1.089-2.258l-5.855-2.6A1.939,1.939,0,0,0,20.693,19.293Z"></path>
            </g>
        </svg>
    );
}

export function EmailIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <path
                    d="M23.764,29.589c-3.427,1.31-13.883,3.45-19.555-3.076-5.494-6.322-3.11-15.891,1-20.675C8.931,1.515,16.616-.484,23.35,2.25s7.857,9.056,7.621,12.7-1.359,7.575-5.022,7.86-4.254-3.133-4.49-4.784.65-8.714.65-8.714c-8.035-2.449-10.87,1.823-11.757,4.443s-1.182,6.721,2.127,8.486,7.68-.513,8.98-4.215"
                    strokeLinecap="butt"></path>
            </g>
        </svg>
    );
}

export function MobilePhoneIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path
                    d="M23,0H9A4,4,0,0,0,5,4V28a4,4,0,0,0,4,4H23a4,4,0,0,0,4-4V4A4,4,0,0,0,23,0Zm2,25a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V5A1,1,0,0,1,8,4H24a1,1,0,0,1,1,1Z"
                    fill={color}></path>
                <path d="M18.5,7h-5a.5.5,0,0,1,0-1h5a.5.5,0,0,1,0,1Z"></path>
            </g>
        </svg>
    );
}

export function TeamIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path
                    d="M30.549,20.586l-4.375-1.251A2,2,0,0,1,24.762,17.8l-.269-1.35A6,6,0,0,0,28,11V8.252A6.166,6.166,0,0,0,22.185,2a6,6,0,0,0-5.652,3.532A8.349,8.349,0,0,1,18,10.252V13a7.918,7.918,0,0,1-.309,2.15,6.02,6.02,0,0,0,1.816,1.3l-.269,1.35a2,2,0,0,1-1.412,1.533l-1.687.482,2.96.846A4.015,4.015,0,0,1,22,24.509V28a2.966,2.966,0,0,1-.184,1H31a1,1,0,0,0,1-1V22.509A2,2,0,0,0,30.549,20.586Z"></path>
                <path
                    d="M18.549,22.586l-4.375-1.251A2,2,0,0,1,12.762,19.8l-.269-1.351A6,6,0,0,0,16,13V10.252A6.166,6.166,0,0,0,10.185,4,6,6,0,0,0,4,10v3a6,6,0,0,0,3.507,5.452L7.238,19.8a2,2,0,0,1-1.412,1.532L1.451,22.586A2,2,0,0,0,0,24.509V28a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V24.509A2,2,0,0,0,18.549,22.586Z"
                    fill={color}></path>
            </g>
        </svg>
    );
}

export function UserPositionIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path fill={color}
                      d="M26,26H6v-5.284c0-1.129,0.628-2.162,1.634-2.673C9.364,17.165,12.348,16,16,16s6.636,1.165,8.366,2.043 C25.372,18.554,26,19.586,26,20.716V26z"></path>
                <circle fill={color} cx="16" cy="7" r="6"></circle>
                <path
                    d="M28,21.924v2.192c1.355,0.675,2,1.375,2,1.885c0,1.631-5.454,4-14,4S2,27.631,2,26 c0-0.51,0.645-1.21,2-1.885v-2.192C1.387,22.988,0,24.378,0,26c0,3.939,8.049,6,16,6s16-2.061,16-6 C32,24.378,30.613,22.989,28,21.924z"></path>
            </g>
        </svg>
    );
}

export function UserPositionOutlineIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <path
                    d="M6,22.273 C2.931,23.189,1,24.519,1,26c0,2.761,6.716,5,15,5s15-2.239,15-5c0-1.48-1.93-2.811-4.998-3.726"
                    strokeLinecap="butt" stroke={color}></path>
                <path
                    d="M26,26H6v-4.284c0-1.129,0.628-2.162,1.634-2.673 C9.364,18.165,12.348,17,16,17s6.636,1.165,8.366,2.043C25.372,19.554,26,20.586,26,21.716V26z"></path>
                <circle cx="16" cy="7" r="6"></circle>
            </g>
        </svg>
    );
}

export function UserIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path
                    d="M27.711,21.887C25.332,21.026,21.368,20,16,20s-9.332,1.026-11.711,1.887 C2.322,22.598,1,24.486,1,26.587V31c0,0.552,0.448,1,1,1h28c0.552,0,1-0.448,1-1v-4.413C31,24.486,29.678,22.598,27.711,21.887z"></path>
                <path fill={color}
                      d="M16,18c4.411,0,8-3.589,8-8V8c0-4.411-3.589-8-8-8S8,3.589,8,8v2C8,14.411,11.589,18,16,18z"></path>
            </g>
        </svg>
    );
}

export function AdminIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path fill={color}
                      d="M20.553,19.597C18.729,19.245,16.565,19,14,19c-5.647,0-9.442,1.158-11.632,2.129 C0.93,21.767,0,23.201,0,24.783V30c0,0.552,0.448,1,1,1h17.298C17.48,29.694,17,28.155,17,26.5 C17,23.653,18.405,21.14,20.553,19.597z"></path>
                <path fill={color}
                      d="M14,17c4.579,0,8-4.751,8-9c0-4.411-3.589-8-8-8S6,3.589,6,8C6,12.249,9.421,17,14,17z"></path>
                <polygon
                    points="27.509,23.706 25.5,19.636 23.491,23.706 19,24.359 22.25,27.527 21.483,32 25.5,29.888 29.517,32 28.75,27.527 32,24.359 "></polygon>
            </g>
        </svg>
    );
}

export function OperatorIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path fill={color}
                      d="M14,17c4.579,0,8-4.751,8-9c0-4.411-3.589-8-8-8S6,3.589,6,8C6,12.249,9.421,17,14,17z"></path>
                <path fill={color}
                      d="M16,25c0-2.2,0.791-4.213,2.102-5.776C16.859,19.086,15.499,19,14,19c-5.647,0-9.442,1.158-11.632,2.129 C0.93,21.767,0,23.201,0,24.783V30c0,0.552,0.448,1,1,1h17.305C16.876,29.407,16,27.308,16,25z"></path>
                <path
                    d="M25,18c-3.86,0-7,3.14-7,7s3.14,7,7,7s7-3.14,7-7S28.86,18,25,18z M24.707,27.707 C24.512,27.902,24.256,28,24,28s-0.512-0.098-0.707-0.293L20.586,25L22,23.586l2,2l4-4L29.414,23L24.707,27.707z"></path>
            </g>
        </svg>
    );
}

export function DeliveryTimeIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path fill={color}
                      d="M31.196,17.02l-4.455-0.891l-2.805-7.48C23.79,8.259,23.417,8,23,8h-7.059 c-0.128,1.154-0.53,2.449-1.215,3.599C13.153,14.236,10.282,16,7,16c-2.829,0-5.35-1.308-7-3.349V28h14h0.101 c0.465,2.279,2.484,4,4.899,4s4.434-1.721,4.899-4H31c0.553,0,1-0.447,1-1v-9C32,17.523,31.663,17.113,31.196,17.02z M19,30 c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S20.654,30,19,30z"></path>
                <path d="M7,0C3.14,0,0,3.14,0,7s3.14,7,7,7s7-3.14,7-7S10.86,0,7,0z M11,8H6V3h2v3h3V8z"></path>
            </g>
        </svg>
    );
}

export function ClockIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path d="M16,0A16,16,0,1,0,32,16,16.019,16.019,0,0,0,16,0ZM26,17H15V6h2v9h9Z" fill={color}></path>
            </g>
        </svg>
    );
}

export function ClockOutlineIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="4" fill="none" stroke={color}
               strokeMiterlimit="10">
                <circle cx="24" cy="24" r="22"></circle>
                <polyline points="24 10 24 24 38 24" stroke={color}></polyline>
            </g>
        </svg>
    );
}

export function BatteryLowIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path d="M31,11H29V21h2a1,1,0,0,0,1-1V12A1,1,0,0,0,31,11Z"></path>
                <path
                    d="M23,6H4a4,4,0,0,0-4,4V22a4,4,0,0,0,4,4H23a4,4,0,0,0,4-4V10A4,4,0,0,0,23,6ZM9,22H5a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H9Z"
                    fill={color}></path>
            </g>
        </svg>
    );
}

export function BatteryStatusIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path d="M31,11H29V21h2a1,1,0,0,0,1-1V12A1,1,0,0,0,31,11Z"></path>
                <path
                    d="M23,6H4a4,4,0,0,0-4,4V22a4,4,0,0,0,4,4H23a4,4,0,0,0,4-4V10A4,4,0,0,0,23,6ZM5,22a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1h7l5,12Z"
                    fill={color}></path>
            </g>
        </svg>
    );
}

export function BatteryChargingIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path d="M31,11H29V21h2a1,1,0,0,0,1-1V12A1,1,0,0,0,31,11Z"></path>
                <path
                    d="M23,6H4a4,4,0,0,0-4,4V22a4,4,0,0,0,4,4H23a4,4,0,0,0,4-4V10A4,4,0,0,0,23,6ZM15,16v5L5,15l7,1V11l10,6Z"
                    fill={color}></path>
            </g>
        </svg>
    );
}

export function BatteryPowerIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path d="M31,11H29V21h2a1,1,0,0,0,1-1V12A1,1,0,0,0,31,11Z"></path>
                <rect y="6" width="27" height="20" rx="4" fill={color}></rect>
            </g>
        </svg>
    );
}

export function StopWatchIcon({ size, color, style }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} style={style} viewBox="0 0 32 32">
            <g fill={color}>
                <path d="M16,6A13,13,0,1,0,29,19,13.015,13.015,0,0,0,16,6Zm7,14H15V12h2v6h6Z" fill={color}></path>
                <path d="M12,2h3V4h2V2h3a1,1,0,0,0,0-2H12a1,1,0,0,0,0,2Z"></path>
                <rect x="26" y="5.586" width="2" height="4.828" transform="translate(2.251 21.435) rotate(-45)"></rect>
            </g>
        </svg>
    );
}

export function StopWatchOutlineIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <polyline points="16 13 16 19 22 19" stroke={color}></polyline>
                <circle cx="16" cy="19" r="12"></circle>
                <line x1="12" y1="1" x2="20" y2="1" stroke={color}></line>
                <line x1="16" y1="1" x2="16" y2="4" strokeLinecap="butt" stroke={color}></line>
                <line x1="26" y1="7" x2="28" y2="9" stroke={color}></line>
            </g>
        </svg>
    );
}

export function HourGlassOutlineIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <line x1="5" y1="2" x2="27" y2="2"></line>
                <line x1="5" y1="30" x2="27" y2="30"></line>
                <path d="M25,2V6.279a6,6,0,0,1-.912,3.18L20,16l4.088,6.541A6,6,0,0,1,25,25.721V30"
                      strokeLinecap="butt"></path>
                <path d="M7,30V25.721a6,6,0,0,1,.912-3.18L12,16,7.912,9.459A6,6,0,0,1,7,6.279V2"
                      strokeLinecap="butt"></path>
                <path d="M11,26v-.279a2,2,0,0,1,.3-1.059L15.152,18.5a1,1,0,0,1,1.7,0L20.7,24.661a2,2,0,0,1,.3,1.06V26Z"
                      stroke="none" fill={color}></path>
            </g>
        </svg>
    );
}

export function JourneyIcon({ size, color, style }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} style={style} viewBox="0 0 48 48">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="3" fill="none" stroke={color}
               strokeMiterlimit="10">
                <path d="M8,30V11a8,8,0,0,1,8-8h0a8,8,0,0,1,8,8V37a8,8,0,0,0,8,8h0a8,8,0,0,0,8-8V18"
                      stroke={color}></path>
                <circle cx="40" cy="8" r="5"></circle>
                <circle cx="8" cy="40" r="5"></circle>
            </g>
        </svg>
    );
}

export function EditUserIcon({ size, color, style }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" style={style} width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <path
                    d="M18,20.209 C16.798,20.078,15.466,20,14,20c-5.57,0-9.247,1.165-11.227,2.043C1.69,22.524,1,23.598,1,24.783V30h12"></path>
                <path d="M7,8c0-3.866,3.134-7,7-7 s7,3.134,7,7s-3.134,8-7,8S7,11.866,7,8z"></path>
                <polygon points=" 21,31 18,31 18,28 27,19 30,22 " stroke={color}></polygon>
            </g>
        </svg>
    );
}

export function ClipboardIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <polyline points="12 27 2 27 2 2 23 2 23 10"></polyline>
                <rect x="16" y="14" width="14" height="16" stroke={color}></rect>
            </g>
        </svg>
    );
}

export function TrashIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <path d="M27,10V28a3,3,0,0,1-3,3H8a3,3,0,0,1-3-3V10"></path>
                <line x1="16" y1="15" x2="16" y2="25"></line>
                <line x1="11" y1="15" x2="11" y2="25"></line>
                <line x1="21" y1="15" x2="21" y2="25"></line>
                <polyline points="11 6 11 1 21 1 21 6" strokeLinecap="butt" stroke={color}></polyline>
                <line x1="30" y1="6" x2="2" y2="6" stroke={color}></line>
            </g>
        </svg>
    );
}

export function EditIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <line x1="20" y1="5" x2="27" y2="12" strokeLinecap="butt" stroke={color}></line>
                <line x1="10" y1="22" x2="23.5" y2="8.5" stroke={color}></line>
                <line x1="4" y1="21" x2="11" y2="28" strokeLinecap="butt" stroke={color}></line>
                <path d="M11,28,2,30l2-9L22.414,2.586a2,2,0,0,1,2.828,0l4.172,4.172a2,2,0,0,1,0,2.828Z"></path>
            </g>
        </svg>
    );
}

export function ExportIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <line x1="16" y1="14" x2="16" y2="30" strokeLinecap="butt"></line>
                <polyline points="21,25 16,30 11,25 "></polyline>
                <path
                    d="M20,21h6 c2.8-0.3,5-2.6,5-5.5c0-2.9-2.2-5.2-5-5.5C25.5,5,21.2,1,16,1c-4.5,0-8.3,3-9.6,7.1C3.4,8.6,1,11.3,1,14.5c0,3.4,2.6,6.2,6,6.5h5"
                    stroke={color}></path>
            </g>
        </svg>
    );
}

export function PlaceOutlineIcon({ size, color, style }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" style={style}>
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <circle cx="16" cy="9" r="8"></circle>
                <line x1="16" y1="17" x2="16" y2="26"></line>
                <path
                    d=" M12.001,23.166C6.217,23.657,2,25.188,2,27c0,2.209,6.268,4,14,4s14-1.791,14-4c0-1.812-4.217-3.343-10.001-3.834"
                    stroke={color}></path>
            </g>
        </svg>
    );
}

export function PlaceFillIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path
                    d="M19,22.093v1.998c6.606,0.413,10,2.123,10,2.909c0,0.897-4.423,3-13,3S3,27.897,3,27 c0-0.786,3.394-2.496,10-2.909v-1.998C6.778,22.471,1,24.039,1,27c0,3.436,7.775,5,15,5s15-1.564,15-5 C31,24.039,25.222,22.471,19,22.093z"></path>
                <path fill={color}
                      d="M25,9c0-4.962-4.037-9-9-9S7,4.038,7,9c0,4.624,3.507,8.442,8,8.941V27h2v-9.059 C21.493,17.442,25,13.624,25,9z"></path>
            </g>
        </svg>
    );
}

export function ZoneIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path fill={color}
                      d="M31,22h-2.78l-2.4-12H27c0.552,0,1-0.448,1-1V3c0-0.552-0.448-1-1-1h-6c-0.552,0-1,0.448-1,1v2h-8V3 c0-0.552-0.448-1-1-1H5C4.448,2,4,2.448,4,3v6c0,0.552,0.448,1,1,1h1.18l-2.4,12H1c-0.552,0-1,0.448-1,1v6c0,0.552,0.448,1,1,1h6 c0.552,0,1-0.448,1-1v-2h16v2c0,0.552,0.448,1,1,1h6c0.552,0,1-0.448,1-1v-6C32,22.448,31.552,22,31,22z M8,25v-2 c0-0.552-0.448-1-1-1H5.82l2.4-12H11c0.552,0,1-0.448,1-1V7h8v2c0,0.552,0.448,1,1,1h2.78l2.4,12H25c-0.552,0-1,0.448-1,1v2H8z"></path>
            </g>
        </svg>
    );
}

export function ZoneOutlineIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <line x1="4.75" y1="23" x2="8.25" y2="9" strokeLinecap="butt" stroke={color}></line>
                <line x1="25" y1="26" x2="7" y2="26" strokeLinecap="butt" stroke={color}></line>
                <line x1="23.751" y1="9.005" x2="27.25" y2="23.001" strokeLinecap="butt" stroke={color}></line>
                <line x1="12" y1="6" x2="20" y2="6" strokeLinecap="butt" stroke={color}></line>
                <rect x="1" y="23" width="6" height="6"></rect>
                <rect x="6" y="3" width="6" height="6"></rect>
                <rect x="20" y="3" width="6" height="6"></rect>
                <rect x="25" y="23" width="6" height="6"></rect>
            </g>
        </svg>
    );
}

export function RemoveIcon({ size, color, style }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} style={style} viewBox="0 0 32 32">
            <g fill={color}>
                <path fill={color}
                      d="M9.3,22.7C9.5,22.9,9.7,23,10,23s0.5-0.1,0.7-0.3l5.3-5.3l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L17.4,16l5.3-5.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L16,14.6l-5.3-5.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4 l5.3,5.3l-5.3,5.3C8.9,21.7,8.9,22.3,9.3,22.7z"></path>
            </g>
        </svg>
    );
}

export function UsersOutlineIcon({ size, color, style }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" style={style}>
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <path
                    d="M19.549,23.586l-4.925-1.408a1,1,0,0,1-.716-.82l-.415-2.905A6,6,0,0,0,17,13V10.252A6.166,6.166,0,0,0,11.185,4,6,6,0,0,0,5,10v3a6,6,0,0,0,3.507,5.453l-.415,2.905a1,1,0,0,1-.716.82L2.451,23.586A2,2,0,0,0,1,25.509V29H21V25.509A2,2,0,0,0,19.549,23.586Z"></path>
                <path
                    d="M25,29h6V23.562a2,2,0,0,0-1.515-1.941l-5.833-1.458a1,1,0,0,1-.747-.829l-.412-2.881A6,6,0,0,0,26,11V8.252A6.166,6.166,0,0,0,20.185,2,5.977,5.977,0,0,0,17,2.8"
                    stroke={color}></path>
            </g>
        </svg>
    );
}

export function AllJobsOutlineIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <path d="M15.51,2.548 C17.172,1.535,19.112,1,21,1c4.832,0,10,3.505,10,9.545C31,16.437,21,27,21,27"
                      strokeLinecap="butt" stroke={color}></path>
                <path d="M23,13c0,6.345-11,17-11,17 S1,19.345,1,13C1,6.495,6.684,2,12,2S23,6.495,23,13z"></path>
                <circle cx="12" cy="13" r="3"></circle>
            </g>
        </svg>
    );
}

export function WorldIcon({ size, color, style }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} style={style} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <polyline
                    points="6.977 4.016 9.238 6.654 11.105 8.989 10.917 12.197 7.182 12.888 4.55 16 5.777 18.547 6.962 20.67 9.756 22.833 7.926 25.61 5.471 26.683"
                    strokeLinecap="butt" stroke={color}></polyline>
                <polyline
                    points="28.059 7.078 25.836 8.49 22.991 8.461 20.827 9.323 18.912 8.002 18.267 5.574 16.484 3.7 14.609 2.364 14.569 1.067"
                    strokeLinecap="butt" stroke={color}></polyline>
                <polygon
                    points="25.688 17.23 25.381 19.929 23.471 21.873 21.778 23.787 19.759 25.31 17.128 25.858 15.722 22.977 17.018 20.075 16.193 17.199 17.378 14.085 20.793 13.45 23.68 14.672 25.688 17.23"
                    stroke={color}></polygon>
                <circle cx="16" cy="16" r="15"></circle>
            </g>
        </svg>
    );
}

export function WorldPinIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M12.536,42.39 c1.262-0.933,2.23-3.304,2.23-3.304s1.09-0.757,1.431-1.723c0.341-0.966,0.019-2.278,0.019-2.278s0.966-2.836,0.511-3.631 c-0.455-0.795-2.386-1.307-2.386-1.307s-0.795-1.477-2.443-1.761c-1.08-0.114-2.159-0.852-2.159-2.386s1.534-4.034,1.534-4.034 s1.591-0.778,1.818-1.346s0.305-1.892,0.305-1.892s1.513-2.67,0.661-4.205c-0.272-0.489-1.534-0.852-1.534-0.852l-1.77-2.655"
                    fill="none" stroke={color} strokeLinecap="butt"/>
                <path
                    d="M39.784,38.284 c-1.93-0.925-5.306-1.698-5.306-1.698s-2.467-2.746-4.086-2.519c-1.619,0.227-3.267,2.045-3.267,2.045s0.114,3.352-0.398,3.523 c-0.511,0.17-1.875-0.739-2.727-0.057c-0.852,0.682-0.361,2.604-0.361,2.604s-1.855,0.578-2.082,1.203s0.675,2.017,0.847,2.551"
                    fill="none" stroke={color} strokeLinecap="butt"/>
                <path
                    d="M26.307,6.137 C25.548,6.05,24.782,6,24,6C12.954,6,4,14.954,4,26s8.954,20,20,20s20-8.954,20-20c0-1.354-0.135-2.677-0.391-3.955"
                    fill="none" stroke={color} strokeLinecap="butt"/>
                <path d="M43,10.8 C43,17,34,25,34,25s-9-8-9-14.2C25,5.231,29.651,2,34,2S43,5.231,43,10.8z" fill="none"/>
                <circle cx="34" cy="11" fill="none" r="3"/>
            </g>
        </svg>
    );
}

export function SettingsFillIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path
                    d="M30.735,20.7,27.5,18.318a11.756,11.756,0,0,0,0-4.638L30.735,11.3A1,1,0,0,0,31.009,10L28.7,6a1,1,0,0,0-1.268-.417L23.747,7.2a11.694,11.694,0,0,0-4-2.317L19.3.89A1,1,0,0,0,18.305,0H13.7A1,1,0,0,0,12.7.89l-.444,4a11.694,11.694,0,0,0-4,2.317L4.564,5.586A1,1,0,0,0,3.3,6L.991,10A1,1,0,0,0,1.265,11.3L4.5,13.682a11.766,11.766,0,0,0,0,4.64L1.266,20.7A1,1,0,0,0,.991,22L3.3,26a1,1,0,0,0,1.267.416L8.253,24.8a11.694,11.694,0,0,0,4,2.317l.444,4A1,1,0,0,0,13.7,32h4.61a1,1,0,0,0,.994-.89l.444-4a11.676,11.676,0,0,0,4-2.316l3.689,1.618A1,1,0,0,0,28.7,26l2.3-4A1,1,0,0,0,30.735,20.7ZM16,21a5,5,0,1,1,5-5A5,5,0,0,1,16,21Z"
                    fill={color}></path>
            </g>
        </svg>
    );
}

export function HelpFillIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path
                    d="M17,23.5H13v-2c0-4.281,2.847-6.54,5.134-8.355C20.284,11.438,21.5,10.5,21.5,8.5c0-3-2.991-4-5.5-4A16.63,16.63,0,0,0,7.75,7.22L5.776,3.753A19.7,19.7,0,0,1,16,.5c5.771,0,9.5,3.5,9.5,8,0,4-2.706,6.053-4.879,7.778C18.376,18.06,17,19.265,17,21.5Z"
                    fill={color}></path>
                <path d="M15,31.5A2.5,2.5,0,1,1,17.5,29,2.5,2.5,0,0,1,15,31.5Z"></path>
            </g>
        </svg>
    );
}

export function ChatFillIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path
                    d="M29,8H28v9a5.006,5.006,0,0,1-5,5H15.267l-3.3,3h7.857l7.364,5.891A.5.5,0,0,0,28,30.5V25h1a3,3,0,0,0,3-3V11A3,3,0,0,0,29,8Z"></path>
                <path
                    d="M23,1H3A3,3,0,0,0,0,4V17a3,3,0,0,0,3,3H5v7.5a.5.5,0,0,0,.836.37L14.494,20H23a3,3,0,0,0,3-3V4A3,3,0,0,0,23,1Z"
                    fill={color}></path>
            </g>
        </svg>
    );
}

export function BellFillIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill={color}>
                <path
                    d="M16,28c-1.414,0-2.738-.05-3.986-.139,0,.047-.014.091-.014.139a4,4,0,0,0,8,0c0-.048-.012-.092-.014-.139C18.738,27.95,17.414,28,16,28Z"></path>
                <path
                    d="M26.294,14.054V9.938A10.386,10.386,0,0,0,16,0,10.473,10.473,0,0,0,5.706,10.039v4.015C5.706,18.571,2,19.375,2,21.884,2,24.193,7.456,26,16,26s14-1.807,14-4.116C30,19.375,26.294,18.571,26.294,14.054Z"
                    fill={color}></path>
            </g>
        </svg>
    );
}

export function CheckCircledIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <polyline points=" 9,17 13,21 23,11 " stroke={color}></polyline>
                <circle cx="16" cy="16" r="15"></circle>
            </g>
        </svg>
    );
}

export function InfoCircledIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <circle cx="16" cy="16" r="15"></circle>
                <line x1="16" y1="7" x2="16" y2="19" stroke={color}></line>
                <circle cx="16" cy="24.5" r="1.5" stroke="none" fill={color}></circle>
            </g>
        </svg>
    );
}

export function LoginIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <line x1="1" y1="16" x2="22" y2="16" strokeLinecap="butt" stroke={color}></line>
                <polyline points="15 9 22 16 15 23" stroke={color}></polyline>
                <path d="M15,1H27a3,3,0,0,1,3,3V28a3,3,0,0,1-3,3H15"></path>
            </g>
        </svg>
    );
}

export function BatteryIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <polygon points="5 11 12 11 17 21 5 21 5 11" stroke={color}></polygon>
                <line x1="31" y1="12" x2="31" y2="20" stroke={color}></line>
                <rect x="1" y="7" width="26" height="18" rx="3" ry="3"></rect>
            </g>
        </svg>
    );
}

export function AppleIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <path
                    d="M28,23.697 c-0.656,1.427-0.969,2.064-1.815,3.325c-1.177,1.761-2.839,3.954-4.897,3.973c-1.829,0.017-2.299-1.168-4.781-1.155 s-3,1.176-4.829,1.159c-2.058-0.018-3.631-1.999-4.81-3.761c-3.295-4.925-3.639-10.704-1.607-13.776 c1.444-2.184,3.723-3.461,5.865-3.461c2.181,0,3.552,1.174,5.356,1.174c1.75,0,2.815-1.176,5.338-1.176 c1.906,0,3.926,1.019,5.365,2.78C22.47,15.318,23.236,21.929,28,23.697L28,23.697z"></path>
                <path
                    d="M20.56,5.722 C21.515,4.497,22.239,2.768,21.976,1c-1.56,0.107-3.383,1.099-4.448,2.392c-0.966,1.173-1.765,2.914-1.455,4.606 C17.775,8.051,19.537,7.035,20.56,5.722L20.56,5.722z"
                    stroke={color}></path>
            </g>
        </svg>
    );
}

export function AndroidIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <path d="M31,23A15.154,15.154,0,0,0,1,23Z"></path>
                <line x1="6" y1="6" x2="8.88" y2="11.76"></line>
                <circle cx="8.5" cy="18.5" r="1.5" stroke="none" fill={color}></circle>
                <line x1="26" y1="6" x2="23.12" y2="11.76"></line>
                <circle cx="23.5" cy="18.5" r="1.5" stroke="none" fill={color}></circle>
            </g>
        </svg>
    );
}

export function PlusIcon({ size, color }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2" fill="none" stroke={color}
               strokeMiterlimit="10">
                <line x1="16" y1="9" x2="16" y2="23" stroke={color}></line>
                <line x1="23" y1="16" x2="9" y2="16" stroke={color}></line>
                <circle cx="16" cy="16" r="15"></circle>
            </g>
        </svg>
    );
}

export function DropIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" strokeLinecap="butt" x1="32" x2="32" y1="48" y2="16"/>
                <line fill="none" stroke={color} x1="25" x2="39" y1="59" y2="59"/>
                <line fill="none" stroke={color} x1="25" x2="39" y1="5" y2="5"/>
                <line fill="none" stroke={color} x1="59" x2="59" y1="39" y2="25"/>
                <line fill="none" stroke={color} x1="5" x2="5" y1="39" y2="25"/>
                <polyline fill="none" points="20 36 32 48 44 36"/>
                <path d="M5,47v7a5,5,0,0,0,5,5h7" fill="none" stroke={color}/>
                <path d="M47,59h7a5,5,0,0,0,5-5V47" fill="none" stroke={color}/>
                <path d="M59,17V10a5,5,0,0,0-5-5H47" fill="none" stroke={color}/>
                <path d="M17,5H10a5,5,0,0,0-5,5v7" fill="none" stroke={color}/>
            </g>
        </svg>
    );
}

export function MenuIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" x1="2" x2="30" y1="16" y2="16"/>
                <line fill="none" stroke={color} x1="2" x2="30" y1="7" y2="7"/>
                <line fill="none" stroke={color} x1="2" x2="30" y1="25" y2="25"/>
            </g>
        </svg>
    );
}

export function DragHandleIcon({ size, color, style }: IconProps) {
    return (
        <svg style={{ flexShrink: 0, ...style }} width={size} height={size} viewBox="0 0 48 48"
             xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path d="M40 18H8v4h32v-4zM8 30h32v-4H8v4z"/>
            </g>
        </svg>
    );
}

export function DropPersonIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M50,51H14v-4.483 c0-3.55,2.328-6.669,5.733-7.677C22.92,37.896,27.208,37,32,37c4.713,0,9.027,0.911,12.25,1.864c3.411,1.008,5.75,4.125,5.75,7.682 V51z"
                    fill="none" stroke={color}/>
                <path d="M22,19 c0-5.523,4.477-10,10-10s10,4.477,10,10s-4.477,12-10,12S22,24.523,22,19z" fill="none"
                      stroke={color}/>
                <polyline fill="none" points=" 2,14 2,2 14,2 "/>
                <polyline fill="none" points=" 50,2 62,2 62,14 "/>
                <polyline fill="none" points=" 2,50 2,62 14,62 "/>
                <polyline fill="none" points=" 50,62 62,62 62,50 "/>
            </g>
        </svg>
    );
}

export function DropUnassignIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M29,61S7,39,7,24.1C7,10.113,18.369,2,29,2s22,8.113,22,22.1A23.117,23.117,0,0,1,49.372,32"
                      fill="none" stroke={color}/>
                <circle cx="29" cy="24" fill="none" r="8" stroke={color}/>
                <circle cx="45" cy="50" fill="none" r="12"/>
                <line fill="none" x1="41" x2="49" y1="50" y2="50"/>
            </g>
        </svg>
    );
}

export function OvernightIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M26.2,20 C18.4,20,12,13.6,12,5.8c0-1.3,0.2-2.6,0.5-3.8C6.5,3.7,2,9.2,2,15.7C2,23.6,8.4,30,16.3,30c6.6,0,12.1-4.5,13.7-10.5 C28.8,19.8,27.5,20,26.2,20z"
                    fill="none" stroke={color}/>
            </g>
        </svg>
    );
}

export function InfoIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <circle cx="16" cy="16" fill="none" r="15" stroke={color}/>
                <circle cx="16" cy="7.5" r="1.5" stroke="none"/>
                <line fill="none" stroke="inherit" x1="16" x2="16" y1="13" y2="24"/>
            </g>
        </svg>
    );
}

export function ConfigurationToolsIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <rect height="28" width="28" fill="none" stroke={color} x="2" y="2"/>
                <line fill="none" x1="14" x2="26" y1="9" y2="9"/>
                <line fill="none" x1="6" x2="10" y1="9" y2="9"/>
                <line fill="none" x1="10" x2="10" y1="12" y2="6"/>
                <line fill="none" x1="14" x2="26" y1="23" y2="23"/>
                <line fill="none" x1="6" x2="10" y1="23" y2="23"/>
                <line fill="none" x1="10" x2="10" y1="26" y2="20"/>
                <line fill="none" x1="6" x2="18" y1="16" y2="16"/>
                <line fill="none" x1="22" x2="26" y1="16" y2="16"/>
                <line fill="none" x1="22" x2="22" y1="13" y2="19"/>
            </g>
        </svg>
    );
}

export function SequenceIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points="24 14 24 24 32 32"/>
                <polyline fill="none" points="5.932 2.26 4.474 13.854 15.858 10.682" stroke={color}/>
                <path d="M4.474,13.854A22,22,0,1,1,2,24" fill="none" stroke={color} strokeLinecap="butt"/>
            </g>
        </svg>
    );
}

export function JobTemplateIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M16,26.687 c-1.703,2-3,3.313-3,3.313S2,18.866,2,12.048C2,5.057,7.684,1,13,1c5.044,0,10.42,3.653,10.956,9.999"
                    fill="none" stroke={color}/>
                <circle cx="13" cy="12" fill="none" r="3" stroke={color}/>
                <polygon fill="none"
                         points=" 23.5,16.636 25.509,20.706 30,21.359 26.75,24.527 27.517,29 23.5,26.888 19.483,29 20.25,24.527 17,21.359 21.491,20.706 "/>
            </g>
        </svg>
    );
}

export function BookmarkIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M28,30,16,23,4,30V4A3,3,0,0,1,7,1H25a3,3,0,0,1,3,3Z" fill="none" stroke={color}/>
            </g>
        </svg>
    );
}

export function BookmarkFillIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"
             style={{ flexShrink: 0 }}>
            <g fill={color}>
                <path
                    d="M25,0H7A4,4,0,0,0,3,4V31.5a.5.5,0,0,0,.77.421L16,24.094l12.23,7.827A.5.5,0,0,0,28.5,32a.5.5,0,0,0,.5-.5V4A4,4,0,0,0,25,0Z"
                    fill={color}/>
            </g>
        </svg>
    );
}

export function UnassignIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect stroke={color} fill="transparent" strokeWidth="2" x="1.01552" y="1.56686" width="29.66598"
                      height="20.27344" rx="10"/>
                <line stroke={color} y2="22.57721" x2="16.15152" y1="32.06703" x1="16.15152"
                      strokeWidth="2" fill="none"/>
                <text fontWeight="normal" transform="matrix(1.79834 0 0 1.68198 -55.9263 -2.57989)" stroke={color}
                      textAnchor="start" fontFamily="sans-serif" fontSize="10" y="11.9373" x="37.14902"
                      strokeWidth="0.5" fill={color}>
                    ?
                </text>
            </g>
        </svg>
    );
}

export function FilterIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polygon fill="none" points="30 5 19 16 19 26 13 30 13 16 2 5 2 1 30 1 30 5" stroke={color}/>
            </g>
        </svg>
    );
}

export function SortIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" x1="5" x2="27" y1="12" y2="12"/>
                <line fill="none" stroke={color} x1="1" x2="31" y1="4" y2="4"/>
                <line fill="none" stroke={color} x1="9" x2="23" y1="20" y2="20"/>
                <line fill="none" x1="13" x2="19" y1="28" y2="28"/>
            </g>
        </svg>
    );
}

export function ImageIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <circle cx="24.5" cy="23.5" fill="none" r="4.5"/>
                <circle cx="24.5" cy="23.5" fill="none" r="4.5"/>
                <polyline fill="none" points="25 40 40 25 55 40" strokeLinecap="butt"/>
                <polyline fill="none" points="9 44 19 34 29 44" strokeLinecap="butt"/>
                <rect height="48" width="58" fill="none" stroke={color} x="3" y="8"/>
                <rect height="36" width="46" fill="none" stroke={color} x="9" y="14"/>
            </g>
        </svg>
    );
}

export function SignatureIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points="34.585,7.585 14,17 7,53 44,47 52.952,25.952 " stroke={color}
                          strokeLinecap="butt"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="7" x2="26.464" y1="53" y2="34.536"/>
                <line fill="none" stroke={color} x1="58" x2="30" y1="31" y2="3"/>
                <line fill="none" x1="2" x2="62" y1="62" y2="62"/>
                <ellipse cx="30" cy="31" fill="none" rx="5" ry="5" stroke={color}
                         transform="matrix(0.7071 -0.7071 0.7071 0.7071 -13.1335 30.2929)"/>
            </g>
        </svg>
    );
}

export function FileIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M48,16v28 c0,8.8-7.2,16-16,16h0c-8.8,0-16-7.2-16-16V15c0-6.1,4.9-11,11-11h0c6.1,0,11,4.9,11,11v27c0,3.3-2.7,6-6,6h0c-3.3,0-6-2.7-6-6V16"
                    fill="none" stroke={color}/>
            </g>
        </svg>
    );
}

export function AlertsReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M4.548,50.527,28.266,7.233a4.239,4.239,0,0,1,7.468,0L59.452,50.527A4.352,4.352,0,0,1,55.718,57H8.282A4.352,4.352,0,0,1,4.548,50.527Z"
                    fill="none" stroke={color}/>
                <line fill="none" x1="32" x2="32" y1="20" y2="42"/>
                <circle cx="32" cy="49" fill="none" r="1"/>
            </g>
        </svg>
    );
}

export function WorksheetReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <path d="M14,39v2c0,4.5-5,5-5,5 s29.657,0,30,0c2.761,0,5-2.239,5-5v-2H14z" fill="none" stroke={color}/>
                <path d="M38,34V2H4v39 c0,2.761,2.239,5,5,5" fill="none" stroke={color}/>
                <line fill="none" x1="12" x2="30" y1="12" y2="12"/>
                <line fill="none" x1="12" x2="30" y1="20" y2="20"/>
                <line fill="none" x1="12" x2="30" y1="28" y2="28"/>
            </g>
        </svg>
    );
}

export function StatisticsReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10">
                <rect height="13" width="8" fill="none" stroke={color} strokeWidth="2" x="4" y="44"/>
                <rect height="14" width="8" fill="none" stroke={color} strokeWidth="2" x="36" y="43"/>
                <rect height="30" width="8" fill="none" stroke={color} strokeWidth="2" x="20" y="27"/>
                <rect height="30" width="8" fill="none" stroke={color} strokeWidth="2" x="52" y="27"/>
                <circle cx="8" cy="27" fill="none" r="4" stroke={color} strokeLinecap="butt" strokeWidth="2"/>
                <circle cx="40" cy="27" fill="none" r="4" stroke={color} strokeLinecap="butt" strokeWidth="2"/>
                <circle cx="24" cy="11" fill="none" r="4" stroke={color} strokeLinecap="butt" strokeWidth="2"/>
                <circle cx="56" cy="11" fill="none" r="4" stroke={color} strokeLinecap="butt" strokeWidth="2"/>
                <line fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2" x1="10.828" x2="21.172"
                      y1="24.172" y2="13.828"/>
                <line fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2" x1="37.172" x2="26.828"
                      y1="24.172" y2="13.828"/>
                <line fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2" x1="42.828" x2="53.172"
                      y1="24.172" y2="13.828"/>
            </g>
        </svg>
    );
}

export function TimelineReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10">
                <line fill="none" stroke={color} strokeWidth="2" x1="32" x2="32" y1="2" y2="62"/>
                <line fill="none" stroke={color} strokeWidth="2" x1="38" x2="32" y1="14" y2="14"/>
                <line fill="none" stroke={color} strokeWidth="2" x1="38" x2="32" y1="50" y2="50"/>
                <line fill="none" stroke={color} strokeWidth="2" x1="26" x2="32" y1="32" y2="32"/>
                <rect height="16" width="16" fill="none" stroke={color} strokeWidth="2" x="44" y="6"/>
                <rect height="16" width="16" fill="none" stroke={color} strokeWidth="2" x="44" y="42"/>
                <rect height="16" width="16" fill="none" stroke={color} strokeWidth="2" transform="translate(24 64) rotate(-180)" x="4" y="24"/>
            </g>
        </svg>
    );
}

export function FileLogIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M19.4,47v-9.3h2v7.7h3.8V47H19.4z" stroke="none" strokeLinejoin="miter"/>
                <path
                    d="M35.4,42.3c0,1.5-0.4,2.7-1.1,3.5c-0.8,0.8-1.9,1.2-3.3,1.2s-2.5-0.4-3.3-1.2 c-0.8-0.8-1.1-2-1.1-3.6c0-1.5,0.4-2.7,1.1-3.5c0.8-0.8,1.9-1.2,3.3-1.2s2.5,0.4,3.3,1.2C35,39.6,35.4,40.8,35.4,42.3z M28.6,42.3 c0,1,0.2,1.8,0.6,2.3s1,0.8,1.8,0.8c1.6,0,2.4-1,2.4-3.1c0-2.1-0.8-3.1-2.3-3.1c-0.8,0-1.4,0.3-1.8,0.8 C28.8,40.5,28.6,41.3,28.6,42.3z"
                    stroke="none" strokeLinejoin="miter"/>
                <path
                    d="M41.1,41.8h3.7v4.8c-0.6,0.2-1.2,0.3-1.7,0.4c-0.5,0.1-1.1,0.1-1.6,0.1 c-1.4,0-2.5-0.4-3.2-1.2c-0.7-0.8-1.1-2-1.1-3.5c0-1.5,0.4-2.7,1.3-3.5c0.9-0.8,2-1.3,3.6-1.3c1,0,1.9,0.2,2.8,0.6l-0.7,1.6 c-0.7-0.3-1.4-0.5-2.1-0.5c-0.9,0-1.5,0.3-2,0.9s-0.8,1.3-0.8,2.3c0,1,0.2,1.8,0.6,2.3c0.4,0.5,1,0.8,1.8,0.8c0.4,0,0.8,0,1.3-0.1 v-1.9h-1.8V41.8z"
                    stroke="none" strokeLinejoin="miter"/>
                <polyline fill="none" points="40,2 40,18 56,18 " stroke={color} strokeLinecap="butt"/>
                <polyline fill="none" points="56,32 56,18 40,2 8,2 8,32 " stroke={color}/>
                <polyline fill="none" points="8,53 8,62 56,62 56,53 " stroke={color}/>
                <polyline fill="none" points="8,27 3,32 3,53 61,53 61,32 56,27 " stroke={color} strokeLinecap="butt"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="61" x2="3" y1="32" y2="32"/>
            </g>
        </svg>
    );
}

export function CheckinsReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points="3 35 16 48 45 13" stroke={color}/>
                <polyline fill="none" points="28 44 32 48 61 13"/>
            </g>
        </svg>
    );
}

export function DayRouteReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points="32 18 12 18 6 24 12 30 32 30" stroke={color}/>
                <polyline fill="none" points="37 8 52 8 58 14 52 20 37 20"/>
                <polyline fill="none" points="37 28 52 28 58 34 52 40 37 40"/>
                <line fill="none" stroke={color} x1="40" x2="24" y1="62" y2="62"/>
                <line fill="none" stroke={color} x1="32" x2="32" y1="62" y2="2"/>
            </g>
        </svg>
    );
}

export function JobsReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M34,55.561C31.1,58.9,29,61,29,61S7,39,7,24.1C7,10.113,18.369,2,29,2s22,8.113,22,22.1c0,5.608-3.114,12.221-7,18.288"
                    fill="none" stroke={color}/>
                <circle cx="29" cy="24" fill="none" r="8" stroke={color}/>
                <polyline fill="none" points="38 48 44 54 58 40"/>
            </g>
        </svg>
    );
}


export function MembersReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M39.486,45.994,28.1,41.438a2,2,0,0,1-1.241-1.609l-.468-3.744A11,11,0,0,0,33,26V16.725a6,6,0,0,0-6-6L25.447,7.618a1.159,1.159,0,0,0-1.387-.557L16.654,9.705A9.154,9.154,0,0,0,11,17.728V26a11,11,0,0,0,6.614,10.085l-.468,3.744a2,2,0,0,1-1.241,1.609L4.514,45.994A4,4,0,0,0,2,49.708V57H42V49.708A4,4,0,0,0,39.486,45.994Z"
                    fill="none" stroke={color}/>
                <path
                    d="M48,57H62V47.708a4,4,0,0,0-2.514-3.714L48.1,39.438a2,2,0,0,1-1.241-1.609l-.468-3.744A11,11,0,0,0,53,24V14.725a6,6,0,0,0-6-6L45.447,5.618a1.159,1.159,0,0,0-1.387-.557L36.654,7.705a6.612,6.612,0,0,0-.7.295"
                    fill="none"/>
            </g>
        </svg>
    );
}

export function MileageReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" x1="9" x2="13" y1="39" y2="39"/>
                <line fill="none" x1="32" x2="32" y1="16" y2="20"/>
                <line fill="none" x1="48.263" x2="45.435" y1="22.737" y2="25.565"/>
                <line fill="none" x1="55" x2="51" y1="39" y2="39"/>
                <line fill="none" x1="28.464" x2="16" y1="35.464" y2="23"/>
                <circle cx="31.999" cy="39" fill="none" r="5"/>
                <path d="M57.372,55A30,30,0,1,0,6.628,55Z" fill="none" stroke={color}/>
            </g>
        </svg>
    );
}

export function OnSiteReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" stroke={color} x1="24" x2="40" y1="2" y2="2"/>
                <line fill="none" stroke={color} x1="32" x2="32" y1="2" y2="5"/>
                <line fill="none" x1="32" x2="32" y1="16" y2="20"/>
                <line fill="none" x1="52" x2="48" y1="36" y2="36"/>
                <line fill="none" x1="32" x2="32" y1="56" y2="52"/>
                <line fill="none" x1="12" x2="16" y1="36" y2="36"/>
                <line fill="none" stroke={color} x1="50" x2="59" y1="9" y2="18"/>
                <circle cx="32" cy="36" fill="none" r="26" stroke={color}/>
                <path
                    d="M28.716,38.284c-1.261-1.814-5.281-14.6-5.281-14.6S34.022,31.9,35.283,33.717a4,4,0,1,1-6.567,4.567Z"
                    fill="none"/>
            </g>
        </svg>
    );
}

export function PlacesReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M22,46.525 C11.475,47.675,4,50.588,4,54c0,4.418,12.536,8,28,8s28-3.582,28-8c0-3.412-7.475-6.325-18-7.475"
                    fill="none"/>
                <path d="M29,29.678V52c0,1.105,1.343,2,3,2 s3-0.895,3-2V29.678" fill="none" stroke={color}
                      strokeLinecap="butt"/>
                <circle cx="32" cy="16" fill="none" r="14" stroke={color}/>
            </g>
        </svg>
    );
}

export function StopsReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" stroke={color} x1="9" x2="55" y1="2" y2="2"/>
                <line fill="none" stroke={color} x1="9" x2="55" y1="62" y2="62"/>
                <path d="M14,62V51.106a10,10,0,0,1,1.76-5.665L25,32,15.76,18.559A10,10,0,0,1,14,12.894V2" fill="none"
                      stroke={color} strokeLinecap="butt"/>
                <path d="M50,2V12.894a10,10,0,0,1-1.76,5.665L39,32l9.24,13.441A10,10,0,0,1,50,51.106V62" fill="none"
                      stroke={color} strokeLinecap="butt"/>
                <path d="M22,54V51.727a4,4,0,0,1,.7-2.266L32,35.939l9.3,13.522a4,4,0,0,1,.7,2.267V54Z" fill="none"/>
            </g>
        </svg>
    );
}

export function FormReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points=" 25,35 30,40 40,30 " stroke="inherit"/>
                <polyline fill="none" points="49,11 57,11 57,62 7,62 7,11 15,11 " stroke={color}/>
                <polyline fill="none" points=" 51,17 51,56 13,56 13,17 " stroke="inherit"/>
                <path d="M39,9c0-3.866-3.134-7-7-7 s-7,3.134-7,7h-5v8h24V9H39z" fill="none" stroke={color}/>
                <circle cx="32" cy="9" fill={color} r="2" stroke="none" strokeLinejoin="miter"/>
            </g>
        </svg>
    );
}

export function TimesheetReportIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points="16 16 32 32 43 21"/>
                <circle cx="32" cy="32" fill="none" r="29" stroke={color}/>
                <line fill="none" x1="32" x2="32" y1="9" y2="14"/>
                <line fill="none" x1="55" x2="50" y1="32" y2="32"/>
                <line fill="none" x1="32" x2="32" y1="55" y2="50"/>
                <line fill="none" x1="9" x2="14" y1="32" y2="32"/>
            </g>
        </svg>
    );
}

export function TimelineIcon({ size, color, style }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" x1="16" x2="16" y1="1" y2="31"/>
                <line fill="none" x1="19" x2="16" y1="7" y2="7"/>
                <line fill="none" x1="19" x2="16" y1="25" y2="25"/>
                <line fill="none" x1="13" x2="16" y1="16" y2="16"/>
                <rect height="8" width="8" fill="none" stroke={color} x="23" y="3"/>
                <rect height="8" width="8" fill="none" stroke={color} x="23" y="21"/>
                <rect height="8" width="8" fill="none" stroke={color} transform="translate(10 32) rotate(-180)" x="1"
                      y="12"/>
            </g>
        </svg>
    );
}

export function DashboardIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <rect height="17" width="11" fill="none" stroke={color} x="3" y="1"/>
                <rect height="9" width="11" fill="none" x="3" y="22"/>
                <rect height="9" width="11" fill="none" x="18" y="1"/>
                <rect height="17" width="11" fill="none" stroke={color} x="18" y="14"/>
            </g>
        </svg>
    );
}

export function StatisticsIcon({ size, color, style }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" stroke={color} x1="3.999" x2="4" y1="29" y2="22"/>
                <line fill="none" stroke={color} x1="11.999" x2="11.999" y1="13" y2="29"/>
                <line fill="none" stroke={color} x1="20.018" x2="20" y1="29" y2="22"/>
                <line fill="none" stroke={color} x1="27.999" x2="27.999" y1="13" y2="29"/>
                <polyline fill="none" points="3 13 12 4 20 12 29 3"/>
            </g>
        </svg>
    );
}

export function FormIcon({ size, color, style }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points=" 12,19 15,22 21,16 "/>
                <polyline fill="none" points="25,5 28,5 28,31 4,31 4,5 7,5 " stroke={color} strokeLinecap="butt"/>
                <path d="M19,4c0-1.657-1.343-3-3-3 s-3,1.343-3,3h-3v4h12V4H19z" fill="none" stroke={color}/>
            </g>
        </svg>
    );
}


export function WarningSignIcon({ size, color, style }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M1.248,27,14.2,3.071a2.051,2.051,0,0,1,3.6,0L30.752,27a2.036,2.036,0,0,1-1.8,3H3.05A2.036,2.036,0,0,1,1.248,27Z"
                    fill="none" stroke={color}/>
                <line fill="none" stroke="inherit" x1="16" x2="16" y1="10" y2="20"/>
                <circle cx="16" cy="24.5" r="1.5" stroke="none"/>
            </g>
        </svg>
    );
}

export function HelpIcon({ size, color, style }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <circle cx="16" cy="16" fill="none" r="15" stroke={color}/>
                <circle cx="16" cy="24.5" r="1.5" stroke="none"/>
                <path d="M12.908,7.79C15.7,6.555,19.32,6.7,20.555,8.7s.382,4.324-1.735,6.118S16,17.588,16,19"
                      fill="none" stroke="inherit"/>
            </g>
        </svg>
    );
}

export function ReportIcon({ size, color, style }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <rect height="30" width="26" fill="none" stroke={color} x="3" y="1"/>
                <line fill="none" x1="16" x2="16" y1="11" y2="21"/>
                <line fill="none" x1="11" x2="11" y1="18" y2="21"/>
                <line fill="none" x1="21" x2="21" y1="15" y2="21"/>
            </g>
        </svg>
    );
}

export function TimesheetIcon({ size, color, style }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <circle cx="16" cy="18" fill="none" r="12" stroke={color}/>
                <line fill="none" x1="11" x2="21" y1="1" y2="1"/>
                <line fill="none" x1="16" x2="16" y1="1" y2="3"/>
                <line fill="none" x1="16" x2="24" y1="18" y2="18"/>
                <polyline fill="none" points="28 18 28 30 4 30 4 18" stroke={color} strokeLinecap="butt"/>
            </g>
        </svg>
    );
}

export function MobileChatIcon({ size, color, style }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M29,15H17l-5,3V3a2,2,0,0,1,2-2H29a2,2,0,0,1,2,2V13A2,2,0,0,1,29,15Z" fill="none"/>
                <path d="M8,3H6A3,3,0,0,0,3,6V28a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V19" fill="none" stroke={color}/>
                <line fill="none" x1="17" x2="21" y1="10" y2="10"/>
                <line fill="none" x1="17" x2="26" y1="6" y2="6"/>
            </g>
        </svg>
    );
}

export function IntegrationsIcon({ size, color, style }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" stroke={color} x1="2" x2="6.001" y1="30" y2="25.999"/>
                <line fill="none" stroke={color} x1="14" x2="11" y1="14" y2="17"/>
                <line fill="none" stroke={color} x1="18" x2="15" y1="18" y2="21"/>
                <line fill="none" x1="30" x2="25.999" y1="2" y2="6.001"/>
                <path d="M8.169,14.169,6,16.336A6.833,6.833,0,0,0,15.664,26l2.167-2.168Z" fill="none" stroke={color}/>
                <path d="M23.831,17.831,26,15.664A6.833,6.833,0,0,0,16.336,6L14.169,8.169Z" fill="none"/>
            </g>
        </svg>
    );
}

export function StopsIcon({ size, color }: IconProps) {
    return (
        <svg height={size} width={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" stroke={color} x1="5" x2="27" y1="2" y2="2"/>
                <line fill="none" stroke={color} x1="5" x2="27" y1="30" y2="30"/>
                <path d="M25,2V6.279a6,6,0,0,1-.912,3.18L20,16l4.088,6.541A6,6,0,0,1,25,25.721V30" fill="none"
                      stroke={color} strokeLinecap="butt"/>
                <path d="M7,30V25.721a6,6,0,0,1,.912-3.18L12,16,7.912,9.459A6,6,0,0,1,7,6.279V2" fill="none"
                      stroke={color} strokeLinecap="butt"/>
                <path d="M11,26v-.279a2,2,0,0,1,.3-1.059L15.152,18.5a1,1,0,0,1,1.7,0L20.7,24.661a2,2,0,0,1,.3,1.06V26Z"
                      stroke="none"/>
            </g>
        </svg>
    );
}

export function SlackIcon({ size }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
            <g>
                <path d="M18.133,2a4.4,4.4,0,1,0,0,8.8h4.4V6.4a4.4,4.4,0,0,0-4.4-4.4" fill="#36c5f0"></path>
                <path d="M18.133,13.733H6.4a4.4,4.4,0,1,0,0,8.8H18.134a4.4,4.4,0,0,0,0-8.8" fill="#36c5f0"></path>
                <path d="M46,18.134a4.4,4.4,0,1,0-8.8,0v4.4h4.4a4.4,4.4,0,0,0,4.4-4.4" fill="#2eb67d"></path>
                <path d="M34.267,18.134V6.4a4.4,4.4,0,1,0-8.8,0V18.133a4.4,4.4,0,1,0,8.8,0" fill="#2eb67d"></path>
                <path d="M29.866,46a4.4,4.4,0,1,0,0-8.8h-4.4v4.4a4.4,4.4,0,0,0,4.4,4.4" fill="#ecb22e"></path>
                <path d="M29.866,34.267H41.6a4.4,4.4,0,1,0,0-8.8H29.866a4.4,4.4,0,0,0,0,8.8h0" fill="#ecb22e"></path>
                <path d="M2,29.866a4.4,4.4,0,0,0,4.4,4.4h0a4.4,4.4,0,0,0,4.4-4.4v-4.4H6.4a4.4,4.4,0,0,0-4.4,4.4"
                      fill="#e01e5a"></path>
                <path d="M13.733,29.866V41.6a4.4,4.4,0,1,0,8.8,0V29.866a4.4,4.4,0,0,0-4.4-4.4h0a4.4,4.4,0,0,0-4.4,4.4"
                      fill="#e01e5a"></path>
            </g>
        </svg>
    );
}

export function GoogleCalendarIcon({ size }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={size} height={size}>
            <path fill="#c7c7c7" fillRule="evenodd"
                  d="M38,5c-6.302,0-21.698,0-28,0C8.895,5,8,5.895,8,7 c0,3.047,0,3,0,3h32c0,0,0,0.047,0-3C40,5.895,39.105,5,38,5z M14,8c-0.552,0-1-0.448-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1 C15,7.552,14.552,8,14,8z M34,8c-0.552,0-1-0.448-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1C35,7.552,34.552,8,34,8z"
                  clipRule="evenodd"/>
            <path fill="#1976d2" fillRule="evenodd"
                  d="M44,11c0.103-0.582-1.409-2-2-2C34.889,9,13.111,9,6,9 c-1,0-2.103,1.418-2,2c0.823,4.664,3,15,3,15h34C41,26,43.177,15.664,44,11z"
                  clipRule="evenodd"/>
            <path fill="#1e88e5" fillRule="evenodd"
                  d="M41,26H7c0,0-2.177,10.336-3,15c0,1.146,0.792,2,2,2 c7.111,0,28.889,0,36,0c0.591,0,2-0.5,2-2C43.177,36.336,41,26,41,26z"
                  clipRule="evenodd"/>
            <path fill="#fafafa" fillRule="evenodd"
                  d="M20.534 26c.984.325 1.687.85 2.105 1.557.433.732.65 1.55.65 2.457 0 1.582-.519 2.826-1.556 3.733-1.037.906-2.363 1.36-3.977 1.36-1.582 0-2.892-.427-3.93-1.282-1.038-.855-1.536-2.014-1.497-3.476l.036-.072h2.242c0 .914.28 1.642.841 2.182.56.541 1.33.811 2.308.811.994 0 1.773-.27 2.337-.811.564-.541.847-1.34.847-2.397 0-1.073-.25-1.864-.751-2.373-.501-.509-1.292-.763-2.373-.763h-2.051V26H20.534zM31.637 26H33.986000000000004V34.856H31.637z"
                  clipRule="evenodd"/>
            <path fill="#e0e0e0" fillRule="evenodd"
                  d="M14.727 22.036h-2.254l-.024-.072c-.04-1.312.435-2.427 1.425-3.345.99-.918 2.284-1.377 3.882-1.377 1.606 0 2.886.427 3.84 1.282.954.855 1.431 2.073 1.431 3.655 0 .716-.217 1.429-.65 2.141-.433.712-1.083 1.254-1.95 1.628L20.534 26h-4.77v-.911h2.051c1.042 0 1.779-.26 2.212-.781.433-.521.65-1.246.65-2.176 0-.994-.246-1.749-.739-2.266-.493-.517-1.22-.775-2.182-.775-.914 0-1.648.268-2.2.805C15.022 20.414 14.746 21.098 14.727 22.036zM33.986 26L31.637 26 31.637 19.782 28.083 19.83 28.083 18.136 33.986 17.492z"
                  clipRule="evenodd"/>
            <path fill="#1976d2" fillRule="evenodd"
                  d="M6 9c-1.438 0-2.103 1.418-2 2 .823 4.664 3 15 3 15M41 26c0 0 2.177-10.336 3-15 0-1.625-1.409-2-2-2"
                  clipRule="evenodd"/>
        </svg>
    );
}

export function XlsxIcon({ size }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
            <g>
                <polygon fill="#0E512B" points="44,27 4,27 1,23 5,19 43,19 47,23 "></polygon>
                <path fill="#E6E6E6"
                      d="M41,47H7c-1.105,0-2-0.895-2-2V3c0-1.105,0.895-2,2-2l24,0l12,12v32C43,46.105,42.105,47,41,47z"></path>
                <path fill="#B3B3B3" d="M31,1v10c0,1.105,0.895,2,2,2h10L31,1z"></path>
                <path fill="#1B7343" d="M45,41H3c-1.105,0-2-0.895-2-2V23h46v16C47,40.105,46.105,41,45,41z"></path>
                <path fill="#FFFFFF"
                      d="M17.592,36h-2.455l-1.531-2.455L12.092,36H9.691l2.605-4.018l-2.449-3.835H12.2l1.418,2.428l1.364-2.428 h2.422l-2.487,4.001L17.592,36z"></path>
                <path fill="#FFFFFF" d="M18.473,36v-7.853h2.122v6.14h3.024V36H18.473z"></path>
                <path fill="#FFFFFF"
                      d="M30.01,33.615c0,0.487-0.124,0.919-0.371,1.297s-0.604,0.672-1.069,0.881 c-0.465,0.21-1.012,0.314-1.638,0.314c-0.523,0-0.961-0.037-1.316-0.11s-0.723-0.201-1.106-0.384v-1.891 c0.405,0.208,0.825,0.37,1.262,0.486s0.838,0.174,1.203,0.174c0.315,0,0.546-0.054,0.693-0.163s0.22-0.25,0.22-0.422 c0-0.107-0.03-0.201-0.088-0.282c-0.06-0.08-0.154-0.162-0.285-0.244c-0.13-0.082-0.479-0.251-1.044-0.505 c-0.512-0.232-0.896-0.458-1.152-0.677c-0.256-0.219-0.446-0.469-0.569-0.752c-0.124-0.283-0.186-0.617-0.186-1.004 c0-0.724,0.263-1.288,0.79-1.692s1.25-0.606,2.17-0.606c0.813,0,1.642,0.188,2.487,0.563l-0.65,1.638 c-0.734-0.336-1.368-0.504-1.901-0.504c-0.276,0-0.476,0.048-0.602,0.145s-0.188,0.217-0.188,0.359c0,0.154,0.08,0.292,0.239,0.414 c0.159,0.122,0.591,0.344,1.297,0.666c0.677,0.305,1.146,0.631,1.41,0.98C29.878,32.646,30.01,33.085,30.01,33.615z"></path>
                <path fill="#FFFFFF"
                      d="M38.298,36h-2.455l-1.531-2.455L32.798,36h-2.401l2.605-4.018l-2.449-3.835h2.353l1.418,2.428l1.364-2.428 h2.422l-2.487,4.001L38.298,36z"></path>
            </g>
        </svg>
    );
}

export function MailIcon({ size }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
            <g>
                <path fill="#E6E6E6"
                      d="M45,43H3c-1.10457,0-2-0.89543-2-2V7c0-1.10457,0.89543-2,2-2h42c1.10457,0,2,0.89543,2,2v34 C47,42.10457,46.10457,43,45,43z"></path>
                <path fill="#B3B3B3"
                      d="M24,29c-0.25586,0-0.51172-0.09766-0.70703-0.29297l-14-14c-0.39062-0.39062-0.39062-1.02344,0-1.41406 s1.02344-0.39062,1.41406,0L24,26.58594l13.29297-13.29297c0.39062-0.39062,1.02344-0.39062,1.41406,0s0.39062,1.02344,0,1.41406 l-14,14C24.51172,28.90234,24.25586,29,24,29z"></path>
                <path fill="#B3B3B3"
                      d="M10,35c-0.25586,0-0.51172-0.09766-0.70703-0.29297c-0.39062-0.39062-0.39062-1.02344,0-1.41406l4-4 c0.39062-0.39062,1.02344-0.39062,1.41406,0s0.39062,1.02344,0,1.41406l-4,4C10.51172,34.90234,10.25586,35,10,35z"></path>
                <path fill="#B3B3B3"
                      d="M38,35c-0.25586,0-0.51172-0.09766-0.70703-0.29297l-4-4c-0.39062-0.39062-0.39062-1.02344,0-1.41406 s1.02344-0.39062,1.41406,0l4,4c0.39062,0.39062,0.39062,1.02344,0,1.41406C38.51172,34.90234,38.25586,35,38,35z"></path>
            </g>
        </svg>
    );
}

export function ApiIcon({ size }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
            <g>
                <path fill="#335262"
                      d="M12,34.828l-9.414-9.414c-0.781-0.781-0.781-2.047,0-2.828L12,13.172L14.829,16l-8,8l8,8L12,34.828z"></path>
                <path fill="#335262"
                      d="M36,34.828L33.171,32l8-8l-8-8L36,13.172l9.414,9.414c0.781,0.781,0.781,2.047,0,2.828L36,34.828z"></path>
                <rect x="5.507" y="22" transform="matrix(0.2427 -0.9701 0.9701 0.2427 -5.1069 41.4583)" fill="#EA9860"
                      width="36.985" height="4"></rect>
            </g>
        </svg>
    );
}

export function FilterFilledIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path
                    d="M30,1H2A1,1,0,0,0,1,2V6a1,1,0,0,0,.324.737L13,17.439V31a1,1,0,0,0,1.6.8l4-3A1,1,0,0,0,19,28V17.439l11.676-10.7A1,1,0,0,0,31,6V2A1,1,0,0,0,30,1Z"
                    fill={color}/>
            </g>
        </svg>
    );
}

export function CompanyIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points=" 23,11 23,4 9,4 9,15 "/>
                <line fill="none" stroke={color} x1="14" x2="17.938" y1="31" y2="31"/>
                <line fill="none" x1="16" x2="16" y1="1" y2="4"/>
                <line fill="none" stroke={color} x1="6" x2="9" y1="23" y2="23"/>
                <line fill="none" stroke={color} x1="23" x2="26" y1="23" y2="23"/>
                <line fill="none" stroke={color} x1="23" x2="26" y1="19" y2="19"/>
                <line fill="none" stroke={color} x1="6" x2="9" y1="27" y2="27"/>
                <line fill="none" stroke={color} x1="23" x2="26" y1="27" y2="27"/>
                <rect height="12" width="13" fill="none" stroke={color} x="1" y="19"/>
                <rect height="16" width="13" fill="none" stroke={color} x="18" y="15"/>
            </g>
        </svg>
    );
}

export function SettingsIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <circle cx="16" cy="16" fill="none" r="4"/>
                <path
                    d="M27,16a11.057,11.057,0,0,0-.217-2.175L30.49,11.1l-3-5.2L23.263,7.754a10.955,10.955,0,0,0-3.754-2.172L19,1H13l-.509,4.582A10.955,10.955,0,0,0,8.737,7.754L4.51,5.9l-3,5.2,3.707,2.727a11.009,11.009,0,0,0,0,4.35L1.51,20.9l3,5.2,4.227-1.852a10.955,10.955,0,0,0,3.754,2.172L13,31h6l.509-4.582a10.955,10.955,0,0,0,3.754-2.172L27.49,26.1l3-5.2-3.707-2.727A11.057,11.057,0,0,0,27,16Z"
                    fill="none" stroke={color}/>
            </g>
        </svg>
    );
}

export function PinSearchIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M15.856,28 C14.749,29.242,14,30,14,30S3,18.866,3,12.048C3,5.057,8.684,1,14,1s11,4.057,11,11.048"
                    fill="none" stroke={color}/>
                <circle cx="14" cy="12" fill="none" r="3" stroke={color}/>
                <circle cx="23" cy="22" fill="none" r="5"/>
                <line fill="none" strokeLinecap="butt" x1="31" x2="26.535" y1="30" y2="25.535"/>
            </g>
        </svg>
    );
}


export function ArchiveIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points="2 19 11 19 11 23 21 23 21 19 30 19" strokeLinecap="butt"/>
                <rect height="28" width="28" fill="none" rx="3" ry="3" stroke={color} x="2" y="2"/>
            </g>
        </svg>
    );
}

export function ArchivedFillIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path
                    d="M27,1H5A4,4,0,0,0,1,5V27a4,4,0,0,0,4,4H27a4,4,0,0,0,4-4V5A4,4,0,0,0,27,1ZM5,3H27a2,2,0,0,1,2,2V19H21v4H11V19H3V5A2,2,0,0,1,5,3Z"
                    fill={color}/>
                <path d="M14,16a1,1,0,0,1-.707-.293L9.586,12,11,10.586l3,3,7-7L22.414,8l-7.707,7.707A1,1,0,0,1,14,16Z"/>
            </g>
        </svg>
    );
}

export function CloneJobIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M15.51,2.548 C17.172,1.535,19.112,1,21,1c4.832,0,10,3.505,10,9.545C31,16.437,21,27,21,27"
                      fill="none" strokeLinecap="butt"/>
                <path d="M23,13c0,6.345-11,17-11,17 S1,19.345,1,13C1,6.495,6.684,2,12,2S23,6.495,23,13z" fill="none"
                      stroke={color}/>
                <circle cx="12" cy="13" fill="none" r="3" stroke={color}/>
            </g>
        </svg>
    );
}

export function HeatmapIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <circle cx="2" cy="11" r="1" stroke="none"/>
                <circle cx="11" cy="2" r="1" stroke="none"/>
                <circle cx="21" cy="2" r="1" stroke="none"/>
                <circle cx="11" cy="30" r="1" stroke="none"/>
                <circle cx="21" cy="30" r="1" stroke="none"/>
                <circle cx="2" cy="21" r="1" stroke="none"/>
                <circle cx="30" cy="11" r="1" stroke="none"/>
                <circle cx="30" cy="21" r="1" stroke="none"/>
                <circle cx="11" cy="11" fill="none" r="3" stroke={color}/>
                <circle cx="21" cy="11" fill="none" r="3" stroke={color}/>
                <circle cx="11" cy="21" fill="none" r="3" stroke={color}/>
                <circle cx="21" cy="21" fill="none" r="3" stroke={color}/>
            </g>
        </svg>
    );
}

export function ArrowRightIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" strokeLinecap="butt" x1="2" x2="30" y1="16" y2="16"/>
                <polyline fill="none" points="21,7 30,16 21,25 " stroke={color}/>
            </g>
        </svg>
    );
}

export function UploadsIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" strokeLinecap="butt" x1="6" x2="6" y1="7" y2="29"/>
                <path d="M4,29H28a3,3,0,0,0,3-3V10a3,3,0,0,0-3-3H25L22,2H14L11,7H4a3,3,0,0,0-3,3V26A3,3,0,0,0,4,29Z"
                      fill="none" stroke={color}/>
                <circle cx="18" cy="18" fill="none" r="7"/>
            </g>
        </svg>
    );
}

export function SignalIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M24,10 c0-2.8-2.2-5-5-5" fill="none"/>
                <path d="M28,10 c0-5-4-9-9-9" fill="none"/>
                <path d="M18,31H6c-1.1,0-2-0.9-2-2 V11c0-1.1,0.9-2,2-2h12c1.1,0,2,0.9,2,2v18C20,30.1,19.1,31,18,31z"
                      fill="none" stroke={color}/>
                <rect height="13" width="8" fill="none" stroke={color} x="8" y="13"/>
            </g>
        </svg>
    );
}

export function DirectionsIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M30,11H5a3,3,0,0,0-3,3V29" fill="none" stroke={color} strokeLinecap="butt"/>
                <polyline fill="none" points="22 19 30 11 22 3" stroke={color}/>
            </g>
        </svg>
    );
}

export function AddJobIcon({ size, color }: IconProps) {
    return <PlusIcon size={size} color={color}/>
}

export function SimulateIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <rect height="26" width="30" fill="none" stroke={color} x="1" y="3"/>
                <polygon fill="none" points="13 12 20 16 13 20 13 12"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="1" x2="31" y1="8" y2="8"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="6" x2="6" y1="3" y2="8"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="11" x2="11" y1="3" y2="8"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="16" x2="16" y1="3" y2="8"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="21" x2="21" y1="3" y2="8"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="26" x2="26" y1="3" y2="8"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="31" x2="1" y1="24" y2="24"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="6" x2="6" y1="24" y2="29"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="11" x2="11" y1="24" y2="29"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="16" x2="16" y1="24" y2="29"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="21" x2="21" y1="24" y2="29"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="26" x2="26" y1="24" y2="29"/>
            </g>
        </svg>
    );
}

export function ViewIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M1.409,17.182a1.936,1.936,0,0,1-.008-2.37C3.422,12.162,8.886,6,16,6c7.02,0,12.536,6.158,14.585,8.81a1.937,1.937,0,0,1,0,2.38C28.536,19.842,23.02,26,16,26S3.453,19.828,1.409,17.182Z"
                    fill="none" stroke={color}/>
                <circle cx="16" cy="16" fill="none" r="6"/>
            </g>
        </svg>
    );
}

export function HideIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M8.373,23.627a27.659,27.659,0,0,1-6.958-6.445,1.938,1.938,0,0,1-.008-2.37C3.428,12.162,8.892,6,16.006,6a14.545,14.545,0,0,1,7.626,2.368"
                    fill="none" stroke={color} strokeLinecap="butt"/>
                <path
                    d="M27,10.923a30.256,30.256,0,0,1,3.591,3.887,1.937,1.937,0,0,1,0,2.38C28.542,19.842,23.026,26,16.006,26A12.843,12.843,0,0,1,12,25.341"
                    fill="none" stroke={color}/>
                <path d="M11.764,20.243a6,6,0,0,1,8.482-8.489" fill="none" strokeLinecap="butt"/>
                <path d="M21.923,15a6.005,6.005,0,0,1-5.917,7A6.061,6.061,0,0,1,15,21.916" fill="none"/>
                <line fill="none" x1="2" x2="30" y1="30" y2="2"/>
            </g>
        </svg>
    );
}

export function HomeIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points="1 14 16 2 31 14" strokeLinecap="butt"/>
                <polyline fill="none" points="13 30 13 22 19 22 19 30" stroke={color} strokeLinecap="butt"/>
                <path d="M5,16v11c0,1.657,1.343,3,3,3H24c1.657,0,3-1.343,3-3v-11" fill="none" stroke={color}/>
            </g>
        </svg>
    );
}

export function LayersIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path
                    d="M45.5,32.132l-4.34-2.48L25.488,38.6a2.994,2.994,0,0,1-2.976,0L6.844,29.652,2.5,32.132a1,1,0,0,0,0,1.736l21,12a1,1,0,0,0,.992,0l21-12a1,1,0,0,0,0-1.736Z"/>
                <path
                    d="M45.5,23.132l-4.34-2.48L25.488,29.6a2.994,2.994,0,0,1-2.976,0L6.844,20.652,2.5,23.132a1,1,0,0,0,0,1.736l21,12a1,1,0,0,0,.992,0l21-12a1,1,0,0,0,0-1.736Z"/>
                <path
                    d="M45.5,14.132l-21-12a1,1,0,0,0-.992,0l-21,12a1,1,0,0,0,0,1.736l21,12a1,1,0,0,0,.992,0l21-12a1,1,0,0,0,0-1.736Z"
                    fill={color}/>
            </g>
        </svg>
    );
}

export function AlarmDisableIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M13,28a3,3,0,0,0,6,0" fill="none" stroke={color} strokeLinecap="butt"/>
                <path d="M25.117,6.885A10,10,0,0,0,6,11v5c0,4-3,9-3,9H7" fill="none" stroke={color}
                      strokeLinecap="butt"/>
                <path d="M12,25H29s-3-5-3-9V11" fill="none" stroke={color}/>
                <line fill="none" x1="2" x2="30" y1="30" y2="2"/>
            </g>
        </svg>
    );
}

export function PrinterIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M7,24H1V11A3,3,0,0,1,4,8H28a3,3,0,0,1,3,3V24H25" fill="none" stroke={color}/>
                <line fill="none" x1="12" x2="20" y1="21" y2="21"/>
                <line fill="none" x1="12" x2="20" y1="26" y2="26"/>
                <polyline fill="none" points="7 4 7 1 25 1 25 4"/>
                <rect height="15" width="18" fill="none" x="7" y="16"/>
            </g>
        </svg>
    );
}

export function GpxReportIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M23.3,41.8H27v4.8c-0.6,0.2-1.2,0.3-1.7,0.4c-0.5,0.1-1.1,0.1-1.6,0.1 c-1.4,0-2.5-0.4-3.2-1.2c-0.7-0.8-1.1-2-1.1-3.5c0-1.5,0.4-2.7,1.3-3.5c0.9-0.8,2-1.3,3.6-1.3c1,0,1.9,0.2,2.8,0.6l-0.7,1.6 c-0.7-0.3-1.4-0.5-2.1-0.5c-0.9,0-1.5,0.3-2,0.9c-0.5,0.6-0.8,1.3-0.8,2.3c0,1,0.2,1.8,0.6,2.3c0.4,0.5,1,0.8,1.8,0.8 c0.4,0,0.8,0,1.3-0.1v-1.9h-1.8V41.8z"
                    stroke="none" strokeLinejoin="miter"/>
                <path
                    d="M35.9,40.6c0,1-0.3,1.8-0.9,2.3c-0.6,0.5-1.5,0.8-2.7,0.8h-0.8V47h-2v-9.3h3 c1.1,0,2,0.2,2.6,0.7C35.6,38.9,35.9,39.7,35.9,40.6z M31.4,42.1h0.6c0.6,0,1.1-0.1,1.4-0.4c0.3-0.2,0.5-0.6,0.5-1 c0-0.5-0.1-0.8-0.4-1s-0.6-0.3-1.2-0.3h-0.9V42.1z"
                    stroke="none" strokeLinejoin="miter"/>
                <path d="M45.4,47h-2.2L41,43.5L38.8,47h-2.1l3.1-4.8l-2.9-4.5h2.2l2,3.3l2-3.3h2.1 l-2.9,4.6L45.4,47z"
                      stroke="none" strokeLinejoin="miter"/>
                <polyline fill="none" points="40,2 40,18 56,18 " stroke={color} strokeLinecap="butt"/>
                <polyline fill="none" points="56,32 56,18 40,2 8,2 8,32 " stroke={color}/>
                <polyline fill="none" points="8,53 8,62 56,62 56,53 " stroke={color}/>
                <polyline fill="none" points="8,27 3,32 3,53 61,53 61,32 56,27 " stroke={color} strokeLinecap="butt"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="61" x2="3" y1="32" y2="32"/>
            </g>
        </svg>
    );
}

export function ClockMiniIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} style={{ marginRight: "4px" }} viewBox="0 0 16 16"
             xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeWidth="1">
                <circle cx="8" cy="8" fill="none" r="7.5" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
                <polyline fill="none" points="8 3 8 8 13 8" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
    );
}

export function CalendarMiniIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} style={{ marginRight: "4px" }} viewBox="0 0 16 16"
             xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeWidth="1">
                <line fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x1="0.5" x2="15.5"
                      y1="5.5" y2="5.5"/>
                <line fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x1="4.5" x2="4.5"
                      y1="0.5" y2="2.5"/>
                <line fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" x1="11.5" x2="11.5"
                      y1="0.5" y2="2.5"/>
                <path
                    d="M14,2.5H2A1.5,1.5,0,0,0,.5,4v9A1.5,1.5,0,0,0,2,14.5H14A1.5,1.5,0,0,0,15.5,13V4A1.5,1.5,0,0,0,14,2.5Z"
                    fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
    );
}

export function CommandIcon({ size, color, animated = false, repeatCount = "indefinite", duration = "2s" }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <rect height="10" width="10" fill="none" stroke={color} x="11" y="11"/>
                <path d="M3,7c0-2.2,1.8-4,4-4 s4,1.8,4,4c0,0.7,0,4,0,4s-2.5,0-4,0C4.8,11,3,9.2,3,7z" fill="none"
                      stroke={color}/>
                <path d="M29,7c0-2.2-1.8-4-4-4 s-4,1.8-4,4c0,0.7,0,4,0,4s2.5,0,4,0C27.2,11,29,9.2,29,7z" fill="none"
                      stroke={color}/>
                <path d="M29,25c0,2.2-1.8,4-4,4 s-4-1.8-4-4c0-0.7,0-4,0-4s2.5,0,4,0C27.2,21,29,22.8,29,25z" fill="none"
                      stroke={color}/>
                <path d="M3,25c0,2.2,1.8,4,4,4 s4-1.8,4-4c0-0.7,0-4,0-4s-2.5,0-4,0C4.8,21,3,22.8,3,25z" fill="none"
                      stroke={color}/>
            </g>
            {animated && <animateTransform
                attributeName="transform"
                dur={duration}
                type="rotate"
                from="0 0 0"
                to="360 0 0"
                repeatCount={repeatCount}/>
            }
        </svg>
    );
}

export function OpenAiIcon({ size, color, animated = false, repeatCount = "indefinite", duration = "2s" }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M24,30.159l-13.209-7.627V11.309c0-4.864,3.943-8.806,8.807-8.806h0c3.253,0,6.094,1.764,7.619,4.388"
                    fill="none" stroke={color} strokeLinecap="butt"/>
                <path
                    d="M18.666,27.079V11.826s9.72-5.611,9.72-5.611c4.212-2.432,9.598-.989,12.03,3.223h0c1.627,2.817,1.52,6.16,.01,8.792"
                    fill="none" stroke={color} strokeLinecap="butt"/>
                <path
                    d="M18.666,20.921l13.209-7.627,9.72,5.612c4.212,2.432,5.655,7.818,3.223,12.03h0c-1.627,2.817-4.575,4.396-7.61,4.405"
                    fill="none" stroke={color} strokeLinecap="butt"/>
                <path
                    d="M24,17.841l13.209,7.627v11.223c0,4.864-3.943,8.806-8.807,8.806h0c-3.253,0-6.094-1.764-7.619-4.388"
                    fill="none" stroke={color} strokeLinecap="butt"/>
                <path
                    d="M29.334,20.921v15.253s-9.72,5.611-9.72,5.611c-4.212,2.432-9.598,.989-12.03-3.223h0c-1.627-2.817-1.52-6.16-.01-8.792"
                    fill="none" stroke={color} strokeLinecap="butt"/>
                <path
                    d="M29.334,27.079l-13.209,7.627-9.72-5.612c-4.212-2.432-5.655-7.818-3.223-12.03h0c1.627-2.817,4.575-4.396,7.61-4.405"
                    fill="none" stroke={color} strokeLinecap="butt"/>
            </g>
            {animated && <animateTransform
                attributeName="transform"
                dur={duration}
                type="rotate"
                from="0 0 0"
                to="360 0 0"
                repeatCount={repeatCount}/>
            }
        </svg>
    );
}

export function SendIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" strokeLinecap="butt" x1="30" x2="14" y1="2" y2="18"/>
                <polygon fill="none" points="30 2 21 30 14 18 2 11 30 2" stroke={color}/>
            </g>
        </svg>
    );
}

export function SunIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" x1="2" x2="6" y1="24" y2="24"/>
                <line fill="none" x1="8.444" x2="11.272" y1="8.444" y2="11.272"/>
                <line fill="none" x1="24" x2="24" y1="2" y2="6"/>
                <line fill="none" x1="39.556" x2="36.728" y1="8.444" y2="11.272"/>
                <line fill="none" x1="46" x2="42" y1="24" y2="24"/>
                <line fill="none" x1="39.556" x2="36.728" y1="39.556" y2="36.728"/>
                <line fill="none" x1="24" x2="24" y1="46" y2="42"/>
                <line fill="none" x1="8.444" x2="11.272" y1="39.556" y2="36.728"/>
                <circle cx="24" cy="24" fill="none" r="12" stroke={color}/>
            </g>
        </svg>
    );
}

export function LightningIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polygon fill="none" points="26,5 6,28 24,28 22,43 42,20 24,20 " stroke={color}/>
            </g>
        </svg>
    );
}

export function TimeLoadingAnimation({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             enableBackground="new 0 0 100 100">
            <circle fill="none" stroke={color} strokeWidth="4" strokeMiterlimit="10" cx="50" cy="50" r="48"/>
            <line fill="none" strokeLinecap="round" stroke={color} strokeWidth="4" strokeMiterlimit="10" x1="50"
                  y1="50" x2="85" y2="50.5">
                <animateTransform
                    attributeName="transform"
                    dur="2s"
                    type="rotate"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"/>
            </line>
            <line fill="none" strokeLinecap="round" stroke={color} strokeWidth="4" strokeMiterlimit="10" x1="50"
                  y1="50" x2="49.5" y2="74">
                <animateTransform
                    attributeName="transform"
                    dur="15s"
                    type="rotate"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"/>
            </line>
        </svg>
    );
}

export function LockIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M22,10V7a6.018,6.018,0,0,0-6-6h0a6.018,6.018,0,0,0-6,6v3" fill="none"/>
                <rect height="17" width="24" fill="none" rx="3" stroke={color} x="4" y="14"/>
                <line fill="none" x1="16" x2="16" y1="24" y2="27"/>
                <circle cx="16" cy="21" fill="none" r="3"/>
            </g>
        </svg>
    );
}

export function UnlockedIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path d="M21.19,4c-1.039-1.789-2.973-2.993-5.19-3h0c-3.31,.01-5.99,2.69-6,6v3" fill="none"/>
                <rect height="17" width="24" fill="none" rx="3" ry="3" stroke={color} x="4" y="14"/>
                <line fill="none" x1="16" x2="16" y1="24" y2="27"/>
                <circle cx="16" cy="21" fill="none" r="3"/>
            </g>
        </svg>
    );
}

export function MicrophoneIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path d="M16,22c4.411,0,8-3.589,8-8V8c0-4.411-3.589-8-8-8S8,3.589,8,8v6C8,18.411,11.589,22,16,22z"
                      fill={color}/>
                <path
                    d="M24,30h-7v-4.051C23.149,25.438,28,20.28,28,14c0-0.553-0.448-1-1-1s-1,0.447-1,1 c0,5.514-4.486,10-10,10S6,19.514,6,14c0-0.553-0.448-1-1-1s-1,0.447-1,1c0,6.28,4.851,11.438,11,11.949V30H8c-0.552,0-1,0.447-1,1 s0.448,1,1,1h16c0.552,0,1-0.447,1-1S24.552,30,24,30z"/>
            </g>
        </svg>
    );
}

export function TracksIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <circle cx="23" cy="18" fill="none" r="3" stroke={color}/>
                <circle cx="7" cy="7" fill="none" r="2" stroke={color}/>
                <path d="M31,18c0,4.418-8,12-8,12s-8-7.582-8-12a8,8,0,0,1,16,0Z" fill="none" stroke={color}/>
                <path d="M15,28a8,8,0,0,1-8-8" fill="none"/>
                <path d="M13,7c0,3.314-6,9-6,9S1,10.314,1,7A6,6,0,0,1,13,7Z" fill="none" stroke={color}/>
            </g>
        </svg>
    );
}

export function BookmarkAddIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" x1="16" x2="16" y1="8" y2="16"/>
                <line fill="none" x1="12" x2="20" y1="12" y2="12"/>
                <path d="M28,30,16,23,4,30V4A3,3,0,0,1,7,1H25a3,3,0,0,1,3,3Z" fill="none" stroke={color}/>
            </g>
        </svg>
    );
}

export function ThumbUpIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points="9 30 3 30 3 15 9 15" strokeLinecap="butt"/>
                <path d="M9,15,13,1h1a3,3,0,0,1,3,3V14h9a3,3,0,0,1,3,3v1l-1.774,9.547A3,3,0,0,1,24.28,30H9Z" fill="none"
                      stroke={color}/>
            </g>
        </svg>
    );
}

export function ThumbUpFilledIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path d="M7,14H3a1,1,0,0,0-1,1V30a1,1,0,0,0,1,1H7Z" fill={color}/>
                <path d="M9,31H24.28a4,4,0,0,0,3.929-3.27l1.774-9.547A1.021,1.021,0,0,0,30,18V17a4,4,0,0,0-4-4H17V4a4,4,0,0,0-4-4h0a1,1,0,0,0-.978.791L9,14Z" fill={color}/>
            </g>
        </svg>
    );
}

export function ThumbDownIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points="9 2 3 2 3 17 9 17" strokeLinecap="butt"/>
                <path d="M9,17l4,14h1a3,3,0,0,0,3-3V18h9a3,3,0,0,0,3-3V14L27.227,4.453A3,3,0,0,0,24.28,2H9Z" fill="none"
                      stroke={color}/>
            </g>
        </svg>
    );
}

export function ThumbDownFilledIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path d="M7,18H3a1,1,0,0,1-1-1V2A1,1,0,0,1,3,1H7Z" fill={color}/>
                <path d="M9,1H24.28a4,4,0,0,1,3.929,3.27l1.774,9.547A1.021,1.021,0,0,1,30,14v1a4,4,0,0,1-4,4H17v9a4,4,0,0,1-4,4h0a1,1,0,0,1-.979-.791L9,18Z" fill={color}/>
            </g>
        </svg>
    );
}

export function FlagColoredIcon({ size }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="M8.00018,30.00001V5.99999c0-0.70747,0.71453-1.19122,1.37139-0.92848l29.99982,12.00001 c0.83815,0.33526,0.83815,1.52169,0,1.85695L9.37158,30.92849C8.71471,31.19124,8.00018,30.70748,8.00018,30.00001z"
                    fill="#EFD358"/>
                <path
                    d="M8,47c-0.55225,0-1-0.44775-1-1V2c0-0.55225,0.44775-1,1-1s1,0.44775,1,1v44C9,46.55225,8.55225,47,8,47z"
                    fill="#444444"/>
            </g>
        </svg>
    );
}

export function FullScreenIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <rect height="18" width="22" fill="none" rx="2" ry="2" stroke={color} x="1" y="3"/>
                <polyline fill="none" points="5 13 5 17 9 17"/>
                <polyline fill="none" points="15 7 19 7 19 11"/>
            </g>
        </svg>
    );
}

export function ResizeXIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeWidth="1">
                <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="0.5" x2="0.5" y1="0.5" y2="15.5"/>
                <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="15.5" x2="15.5" y1="0.5" y2="15.5"/>
                <polyline fill="none" points="6 5.5 3.5 8 6 10.5" stroke={color} strokeLinecap="round"
                          strokeLinejoin="round"/>
                <polyline fill="none" points="10 5.5 12.5 8 10 10.5" stroke={color} strokeLinecap="round"
                          strokeLinejoin="round"/>
            </g>
        </svg>
    );
}

export function PinIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" stroke={color} x1="21" x2="3" y1="29" y2="11"/>
                <line fill="none" stroke={color} x1="20" x2="30" y1="2" y2="12"/>
                <line fill="none" x1="9.5" x2="2" y1="22.5" y2="30"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="28" x2="18" y1="10" y2="26"/>
                <line fill="none" stroke={color} strokeLinecap="butt" x1="6" x2="22" y1="14" y2="4"/>
            </g>
        </svg>
    );
}

export function PinFilledIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path
                    d="M9.172,21.414l-7.879,7.879c-0.391,0.391-0.391,1.023,0,1.414C1.488,30.902,1.744,31,2,31 s0.512-0.098,0.707-0.293l7.879-7.879L9.172,21.414z"/>
                <path
                    d="M30.707,11.293l-10-10c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l1.111,1.111l-14.26,8.912 l-2.438-2.438c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l18,18C20.488,29.902,20.744,30,21,30s0.512-0.098,0.707-0.293 c0.391-0.391,0.391-1.023,0-1.414l-2.438-2.438l8.912-14.26l1.111,1.111C29.488,12.902,29.744,13,30,13s0.512-0.098,0.707-0.293 C31.098,12.316,31.098,11.684,30.707,11.293z"
                    fill={color}/>
            </g>
        </svg>
    );
}

export function EnterIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeWidth="1">
                <line fill="none" strokeLinecap="round" strokeLinejoin="round" x1="11.5" x2="0.5" y1="4.5" y2="15.5"/>
                <polyline fill="none" points="5.5 4.5 11.5 4.5 11.5 10.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6.5,15.5H14A1.5,1.5,0,0,0,15.5,14V2A1.5,1.5,0,0,0,14,.5H4A1.5,1.5,0,0,0,2.5,2V7.5" fill="none"
                      stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
    );
}


export function PointToolIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polygon fill="none" points="2,2 7,16 10,10 16,7 " stroke={color}/>
                <line fill="none" x1="22" x2="22" y1="18" y2="26"/>
                <line fill="none" x1="18" x2="26" y1="22" y2="22"/>
                <circle cx="22" cy="22" fill="none" r="9"/>
            </g>
        </svg>
    );
}


export function RouteToolIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <path
                    d="M5,19V6.5 C5,3.462,7.462,1,10.5,1h0C13.538,1,16,3.462,16,6.5v19c0,3.038,2.462,5.5,5.5,5.5h0c3.038,0,5.5-2.462,5.5-5.5V13"
                    fill="none"/>
                <circle cx="27" cy="5" fill="none" r="4" stroke={color}/>
                <circle cx="5" cy="27" fill="none" r="4" stroke={color}/>
            </g>
        </svg>
    );
}

export function SelectionToolIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points=" 1,4 1,1 4,1 "/>
                <polyline fill="none" points=" 1,25 1,28 4,28 "/>
                <polyline fill="none" points=" 28,4 28,1 25,1 "/>
                <line fill="none" x1="9" x2="12" y1="1" y2="1"/>
                <line fill="none" x1="17" x2="20" y1="1" y2="1"/>
                <line fill="none" x1="1" x2="1" y1="9" y2="12"/>
                <line fill="none" x1="1" x2="1" y1="17" y2="20"/>
                <line fill="none" x1="9" x2="12" y1="28" y2="28"/>
                <line fill="none" x1="28" x2="28" y1="9" y2="12"/>
                <polygon fill="none" points="28,18 14,14 18,28 21,24 27,30 30,27 24,21 " stroke={color}/>
            </g>
        </svg>
    );
}

export function CreditCardIcon({ size }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="M44,41H4c-1.657,0-3-1.343-3-3V10c0-1.657,1.343-3,3-3h40c1.657,0,3,1.343,3,3v28C47,39.657,45.657,41,44,41 z"
                    fill="#BFDEE0"/>
                <rect height="8" width="46" fill="#223E49" x="1" y="13"/>
                <rect height="4" width="22" fill="#FFFFFF" x="7" y="27"/>
            </g>
        </svg>
    );
}

export function PauseIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <line fill="none" x1="9" x2="9" y1="16" y2="8"/>
                <line fill="none" x1="15" x2="15" y1="16" y2="8"/>
                <circle cx="12" cy="12" fill="none" r="11" stroke={color}/>
            </g>
        </svg>
    );
}

export function ActivityLogIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" style={style} xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <polygon fill="none" points="19 3 4 19 15 19 13 29 28 13 17 13 19 3" stroke={color}/>
            </g>
        </svg>
    );
}

export function JobsIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <path d="M15.51,2.548 C17.172,1.535,19.112,1,21,1c4.832,0,10,3.505,10,9.545C31,16.437,21,27,21,27" fill="none" strokeLinecap="butt"/>
                <path d="M23,13c0,6.345-11,17-11,17 S1,19.345,1,13C1,6.495,6.684,2,12,2S23,6.495,23,13z" fill="none" stroke={color}/>
                <circle cx="12" cy="13" fill="none" r="3" stroke={color}/>
            </g>
        </svg>
    );
}

export function EnlargeVerticalIcon({ size, color }: IconProps) {
    return (
        <svg width={size} height={size}  viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <line fill="none" strokeLinecap="butt" x1="16" x2="16" y1="2" y2="30"/>
                <polyline fill="none" points="23,23 16,30 9,23 " stroke={color}/>
                <polyline fill="none" points="9,9 16,2 23,9 " stroke={color}/>
            </g>
        </svg>
    );
}

export function ClusteringIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <path d="M22,19.727 C22,23.262,16,30,16,30s-6-6.738-6-10.273C10,16.103,13.101,14,16,14S22,16.103,22,19.727z" fill="none" stroke="inherit"/>
                <path d="M31,5.773 C31,8.718,26,14,26,14s-5-5.282-5-8.227C21,2.752,23.584,1,26,1S31,2.752,31,5.773z" fill="none" stroke={color}/>
                <path d="M11,5.773 C11,8.718,6,14,6,14S1,8.718,1,5.773C1,2.752,3.584,1,6,1S11,2.752,11,5.773z" fill="none" stroke={color}/>
                <circle cx="6" cy="6" fill={color} r="1" stroke="none" strokeLinejoin="miter"/>
                <circle cx="16" cy="20" r="1" stroke="none" strokeLinejoin="miter"/>
                <circle cx="26" cy="6" fill={color} r="1" stroke="none" strokeLinejoin="miter"/>
            </g>
        </svg>
    );
}

export function DocumentCopy({ size, color }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <rect height="26" width="22" fill="none" stroke={color} x="3" y="5"/>
                <line fill="none" stroke={color} x1="8" x2="20" y1="12" y2="12"/>
                <line fill="none" stroke={color} x1="8" x2="20" y1="18" y2="18"/>
                <line fill="none" stroke={color} x1="8" x2="12" y1="24" y2="24"/>
                <polyline fill="none" points=" 6,1 29,1 29,28 "/>
            </g>
        </svg>
    );
}

export function VolumeMuteIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polyline fill="none" points="26,15 26,30 16.487,24.146 " stroke={color}/>
                <polyline fill="none" points="13,22 2,22 2,10 13,10 26,2 26,9 " stroke={color} strokeLinecap="butt"/>
                <line fill="none" x1="30" x2="6" y1="5" y2="29"/>
            </g>
        </svg>
    );
};

export function VolumeIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10"
               strokeWidth="2">
                <polygon fill="none" points="20,29 9,21 1,21 1,11 9,11 20,3 " stroke={color}/>
                <line fill="none" x1="27" x2="31" y1="16" y2="16"/>
                <line fill="none" x1="25.3" x2="28.7" y1="9.5" y2="7.5"/>
                <line fill="none" x1="25.3" x2="28.7" y1="22.5" y2="24.5"/>
            </g>
        </svg>
    );
}

export function PlayIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <circle cx="16" cy="16" fill="none" r="15" stroke={color}/>
                <polygon fill="none" points=" 12,23 12,9 23,16 "/>
            </g>
        </svg>
    );
}

export function SendToPhoneIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <line fill="none" strokeLinecap="butt" x1="1" x2="16" y1="16" y2="16"/>
                <polyline fill="none" points="12 12 16 16 12 20"/>
                <path d="M6,20v8a3,3,0,0,0,3,3H23a3,3,0,0,0,3-3V4a3,3,0,0,0-3-3H9A3,3,0,0,0,6,4v8" fill="none" stroke={color}/>
            </g>
        </svg>
    );
}

export function EyeAnimatedOpenCloseIcon({ size, color, style }: IconProps) {
    const css = `
        @keyframes eyeOpenClose {
            0%, 100% {
                transform: scale(1);
                opacity: 1;
            }
            50% {
                transform: scale(0);
                opacity: 0;
            }
        }

        .nc-int-eye-open-close {
            --transition-duration: .3s;
            perspective: 100px;
        }

        .nc-int-eye-open-close :nth-child(1) {
            transform-origin: 16px 22px;
            animation: eyeOpenClose 2s linear infinite;
        }

        .nc-int-eye-open-close :nth-child(3),
        .nc-int-eye-open-close :nth-child(4),
        .nc-int-eye-open-close :nth-child(5),
        .nc-int-eye-open-close :nth-child(6),
        .nc-int-eye-open-close :nth-child(7) {
            transform-origin: 50% 50%;
            animation: eyeOpenClose 2s linear infinite;
        }
    `;

    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2">
                <g className="js-nc-int-icon nc-int-eye-open-close">
                    <circle cx="16" cy="16" r="5" stroke="none"/>
                    <path d="M2,16s6,8,14,8,14-8,14-8" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2,16S8,8,16,8s14,8,14,8" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
                    <line fill="none" opacity="0" stroke={color} strokeLinecap="butt" x1="12.768" x2="11.563" y1="8.427" y2="4.583"/>
                    <line fill="none" opacity="0" stroke={color} strokeLinecap="butt" x1="19.232" x2="20.437" y1="8.427" y2="4.583"/>
                    <line fill="none" opacity="0" stroke={color} strokeLinecap="butt" x1="6.563" x2="4.141" y1="11.563" y2="8.482"/>
                    <line fill="none" opacity="0" stroke={color} strokeLinecap="butt" x1="25.437" x2="27.859" y1="11.563" y2="8.482"/>
                </g>
                <style>{css}</style>
            </g>
        </svg>
    );
}


export function FlagDiagonalIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} stroke={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <line fill="none" x1="2.3" x2="15.1" y1="8.2" y2="30.5"/>
                <path d="M28.7,13.3 c-5.2,4.7-11.6-1.1-15.4,5.8L7.1,8.5c3.9-6.8,10.3-1,15.4-5.8L28.7,13.3z" fill="none" stroke={color}/>
            </g>
        </svg>
    );
}

export function SplitTaskIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" >
            <g fill={color} strokeWidth="2">
                <path d="M10 2l0 28" fill="none" stroke={color} strokeMiterlimit="10"/>
                <path d="M22 24l0-10.34a3 3 0 0 0-1.41-2.54l-6.59-4.12" fill="none" stroke={color} strokeMiterlimit="10"/>
                <path d="M15 25l-5 5-5-5" fill="none" stroke={color} strokeLinecap="square" strokeMiterlimit="10"/>
                <path d="M27 19l-5 5-5-5" fill="none" stroke={color} strokeLinecap="square" strokeMiterlimit="10"/>
            </g>
        </svg>
    );
}


export function AppointmentIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect stroke={color} fill="transparent" strokeWidth="2" x="1.01552" y="1.56686" width="29.66598"
                      height="20.27344" rx="10"/>
                <line stroke={color} y2="22.57721" x2="16.15152" y1="32.06703" x1="16.15152" strokeWidth="2"
                      fill="none"/>
                <line y2="5.1265" x2="15.75003" y1="12.3755" x1="15.62505" strokeWidth="2" stroke={color} fill="none"/>
                <line stroke={color} y2="12.50048" x2="14.62519" y1="12.62547" x1="23.12402" strokeWidth="2"
                      fill="none"/>
            </g>
        </svg>
    );
}

export function FindIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10">
                <line fill="none" stroke={color} strokeWidth="2" x1="29" x2="20.777" y1="29" y2="20.777"/>
                <circle cx="13" cy="13" fill="none" r="11" stroke={color} strokeWidth="2"/>
            </g>
        </svg>
    );
}

export function PuzzleIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10">
                <path d="M27,14V5h-9 c0-2.209-1.791-4-4-4s-4,1.791-4,4H1v8c2.761,0,5,2.239,5,5s-2.239,5-5,5v8h8c0-2.761,2.239-5,5-5s5,2.239,5,5h8v-9 c2.209,0,4-1.791,4-4C31,15.791,29.209,14,27,14z" fill="none" stroke={color} strokeWidth="2" transform="translate(0, 0)"/>
            </g>
        </svg>
    );
}

export function MultiRouteIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10">
                <line fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2" x1="2" x2="30" y1="16" y2="16"/>
                <line fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2" x1="2" x2="16" y1="7" y2="7"/>
                <line fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2" x1="2" x2="21" y1="25" y2="25"/>
                <polyline fill="none" points="25,11 30,16 25,21 " stroke={color} strokeWidth="2"/>
                <polyline fill="none" points=" 11,2 16,7 11,12 " stroke={color} strokeWidth="2"/>
                <polyline fill="none" points=" 16,20 21,25 16,30 " stroke={color} strokeWidth="2"/>
            </g>
        </svg>
    );
}

export function TeamsIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10">
                <path d="M10.189,12.95c-.548-.584-1.326-.95-2.189-.95H4c-1.657,0-3,1.343-3,3v5H3v7H8" fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2"/>
                <path d="M21.811,12.95c.548-.584,1.326-.95,2.189-.95h4c1.657,0,3,1.343,3,3v5h-2s0,7,0,7h-5" fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2"/>
                <path d="M19,31h-6v-8h-3V14c0-1.657,1.343-3,3-3h6c1.657,0,3,1.343,3,3v9h-3v8Z" fill="none" stroke={color} strokeWidth="2"/>
                <circle cx="6" cy="6" fill="none" r="3" stroke={color} strokeWidth="2"/>
                <circle cx="26" cy="6" fill="none" r="3" stroke={color} strokeWidth="2"/>
                <circle cx="16" cy="4.5" fill="none" r="3.5" stroke={color} strokeWidth="2"/>
            </g>
        </svg>
    );
}

export function ResetIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10">
                <path d="M2,22h18.5 c5.247,0,9.5-4.253,9.5-9.5v0C30,7.253,25.747,3,20.5,3H11" fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2"/>
                <polyline fill="none" points="10,14 2,22 10,30 " stroke={color} strokeWidth="2"/>
            </g>
        </svg>
    );
}

export function LabelIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <path d="M29.828,15.414,15.707,1.293A1,1,0,0,0,15,1H2A1,1,0,0,0,1,2V15a1,1,0,0,0,.293.707L15.414,29.828a4,4,0,0,0,5.657,0l8.757-8.757a4,4,0,0,0,0-5.657ZM10,13a3,3,0,1,1,3-3A3,3,0,0,1,10,13Z" fill={color}/>
            </g>
        </svg>
    );
}

export function TableIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10">
                <line fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2" x1="2" x2="30" y1="11" y2="11"/>
                <line fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2" x1="2" x2="30" y1="20" y2="20"/>
                <line fill="none" stroke={color} strokeLinecap="butt" strokeWidth="2" x1="10" x2="10" y1="2" y2="30"/>
                <path d="M27,30H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H27a3,3,0,0,1,3,3V27A3,3,0,0,1,27,30Z" fill="none" stroke={color} strokeWidth="2"/>
            </g>
        </svg>
    );
}

export function BlurBackgroundIcon({ size, color, style }: IconProps) {
    return (
        <svg width={size} height={size} style={style} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <g fill={color}>
                <polygon fill={color} points="31 15.586 15.586 31 18.414 31 31 18.414 31 15.586"/>
                <polygon fill={color} points="23.586 31 26.414 31 31 26.414 31 23.586 23.586 31"/>
                <polygon fill={color} points="7.586 31 10.414 31 31 10.414 31 7.586 7.586 31"/>
                <polygon fill={color} points="1 24.414 24.414 1 21.586 1 1 21.586 1 24.414"/>
                <polygon fill={color} points="31 1 29.586 1 1 29.586 1 31 2.414 31 31 2.414 31 1"/>
                <polygon fill={color} points="16.414 1 13.586 1 1 13.586 1 16.414 16.414 1"/>
                <polygon fill={color} points="8.414 1 5.586 1 1 5.586 1 8.414 8.414 1"/>
            </g>
        </svg>
    );
}