import { useEffect, useState } from "react";
import { useMap } from "react-map-gl";
// @ts-ignore
import MapboxCircle from 'mapbox-gl-circle';
import { useDrawMode } from "../../../hooks/useDrawMode";
import { useGeocode } from "../../../hooks/useGeocode";
import { RADIUS_DEFAULT, RADIUS_MAX, RADIUS_MIN } from "../../../constants/places";
import { COLOR_PLACE_DEFAULT, COLOR_PLACE_STROKE } from "../../../constants/colors";
import { Latitude, Longitude } from "../../../types/core";

export function NewPlaceCircle() {
    const { map } = useMap();
    const { geolocate } = useGeocode();
    const [circle, setCircle] = useState<any>();
    const { drawMode, updateLocation, updateRadius } = useDrawMode();

    const lat = drawMode?.location?.lat;
    const lng = drawMode?.location?.lng;
    const radius = drawMode?.radius;
    const type = drawMode?.type;

    useEffect(() => {
        if (type !== "place" || !lat || !lng) {
            return;
        }
        const center = { lat, lng };
        setCircle(new MapboxCircle(center, radius || RADIUS_DEFAULT, {
            editable: true,
            fillColor: COLOR_PLACE_DEFAULT,
            fillOpacity: 0.5,
            strokeColor: COLOR_PLACE_STROKE,
            strokeOpacity: 1,
            strokeWeight: 3,
            refineStroke: true,
        }));
    }, [lat, lng, radius]);

    useEffect(() => {
        if (!circle || !map) {
            return;
        }
        const onCenterChanged = async (circle: MapboxCircle) => {
            const lat: Latitude = circle.center[1];
            const lng: Longitude = circle.center[0];
            const newLocation = await geolocate(lat, lng);
            updateLocation(newLocation);
        };

        const onRadiusChanged = (circle: MapboxCircle) => {
            circle.radius = Math.min(RADIUS_MAX, circle.radius);
            circle.radius = Math.max(RADIUS_MIN, circle.radius);
            updateRadius(circle.radius);
        };

        circle.addTo(map.getMap());
        circle.on('centerchanged', onCenterChanged);
        circle.on('radiuschanged', onRadiusChanged);

        return () => {
            circle.off("centerchanged", onCenterChanged);
            circle.off("radiuschanged", onRadiusChanged);
            circle.remove();
        }
    }, [circle, map])

    return null;
}