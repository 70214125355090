import { useStyletron } from "baseui";
import { LabelSmall } from "baseui/typography";
import { ReactNode } from "react";
import { useStrings } from "../../hooks/useStrings";
import { HFlex } from "../containers/HFlex";
import { PlaceBadge } from "./PlaceBadge";
import { Uid } from "../../types/core";

export function PlacesBadges({
                                 uids,
                                 max = 10,
                                 children = null,
                                 onClose,
                             }: { uids: Uid[], max?: number, children?: ReactNode, onClose?: (uid: Uid) => void, }) {
    const { strings } = useStrings();
    const maxSelectedVisible = max;
    const [, theme] = useStyletron();
    const selectedCount = uids.length;

    return (
        <HFlex alignCenter spacing={theme.sizing.scale200} style={{
            flexWrap: 'wrap',
            minHeight: theme.sizing.scale850,
        }}>
            {uids.slice(0, maxSelectedVisible).map((uid, index) => (
                <PlaceBadge uid={uid} key={index} onClose={onClose ? () => onClose(uid) : undefined}/>
            ))}
            {selectedCount > maxSelectedVisible && (
                <LabelSmall alignSelf="center" color={theme.colors.contentTertiary}>
                    {`${selectedCount - maxSelectedVisible} ${strings.General.More.toLowerCase()}...`}
                </LabelSmall>
            )}
            {children}
        </HFlex>
    );
}