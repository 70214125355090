import { HFlex } from "../containers/HFlex";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Input } from "baseui/input";
import { zonesSearchState } from "../../states/zoneState";
import { useStrings } from "../../hooks/useStrings";
import { Filter } from "baseui/icon";

export function ZonesFilterRow() {
    const { strings } = useStrings();
    const setZonesSearch = useSetRecoilState(zonesSearchState);
    const zonesSearch = useRecoilValue(zonesSearchState);

    return (
        <HFlex alignCenter>
            <Input
                size={"compact"}
                placeholder={strings.General.SearchForZone}
                value={zonesSearch}
                onChange={({ target: { value } }) => setZonesSearch(value)}
                startEnhancer={<Filter/>}
                overrides={{
                    Root: {
                        style: {
                            paddingLeft: "0px",
                            paddingRight: "0px",
                        }
                    },
                    Input: {
                        style: {
                            paddingLeft: "0px",
                            paddingRight: "0px",
                        }
                    }
                }}
            />
        </HFlex>
    );
}