import { setCompanyActive } from "../../services/privateApi";
import useDirector from "../useDirector";

export function useCompanyActivation() {
    const director = useDirector();

    const activate = async () => {
        const { status } = await setCompanyActive(true);
        await director.performPoll();
        await director.performBilling();
        return status;
    }

    const deactivate = async (reason: string, text: string, comment: string) => {
        const { status } = await setCompanyActive(false, {
            reason: reason,
            text: text,
            comment: comment,
        } as any);
        await director.performPoll();
        await director.performBilling();
        return status;
    }

    return { activate, deactivate }
}