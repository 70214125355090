import { DatePicker } from "baseui/datepicker";
import { dateMinusDays, datePlusDays } from "../../utils/time";
import { ResponsiveFieldWrapper } from "../containers/ResponsiveFieldWrapper";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedReportConfigState, selectedReportDatesState } from "../../states/reportState";
import { useState } from "react";
import { useTime } from "../../hooks/useTime";
import { useStrings } from "../../hooks/useStrings";
import { windowIsMobileState } from "../../states/skeletonState";


export function ReportDatesStep() {
    const reportConfig = useRecoilValue(selectedReportConfigState);
    const [dates, setDates] = useRecoilState(selectedReportDatesState);
    const [minDate, setMinDate] = useState<Date>();
    const [maxDate, setMaxDate] = useState<Date>();
    const { longFormDateFormat } = useTime();
    const { strings } = useStrings();
    const isMobile = useRecoilValue(windowIsMobileState);

    if (!reportConfig) {
        return null;
    }

    return (
        <ResponsiveFieldWrapper maxWidth={250}>
            <DatePicker
                value={dates}
                range={reportConfig.allowMultipleDates}
                size="compact"
                startDateLabel={strings.General.From}
                endDateLabel={strings.General.Until}
                placeholder={longFormDateFormat}
                formatString={longFormDateFormat}
                monthsShown={isMobile ? 1 : 2}
                onClose={() => {
                    setDates((prev) => [...prev]);
                }}
                onOpen={() => {
                    setMinDate(undefined);
                    setMaxDate(undefined);
                }}
                minDate={minDate}
                maxDate={maxDate}
                onChange={({ date }) => {
                    if (Array.isArray(date)) {
                        setDates(date);
                        if (!date[1] && date[0]) {
                            setMinDate(dateMinusDays(date[0], reportConfig.maxDays))
                            setMaxDate(datePlusDays(date[0], reportConfig.maxDays));
                        }
                    } else if (date instanceof Date) {
                        setDates([date]);
                    }
                }}
                overrides={{
                    Popover: {
                        props: {
                            placement: "bottomRight",
                        }
                    }
                }}
            />
        </ResponsiveFieldWrapper>
    );
}