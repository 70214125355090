import { useRecoilState, useRecoilValue } from "recoil";
import {
    selectedReportConfigState,
    selectedReportDayWeekState,
    selectedReportStartDayState,
    selectedReportWeekdaysState
} from "../../states/reportState";
import { DaysPicker } from "../pickers/DaysPicker";
import { DayKey } from "../../types/days";
import { SELECTED_DAYS_NONE } from "../../constants/days";
import { DatePicker } from "baseui/datepicker";
import { dateToDayBrowserTimezone, dayToDateBrowserTimezone } from "../../utils/time";
import { ResponsiveFieldWrapper } from "../containers/ResponsiveFieldWrapper";
import { useTime } from "../../hooks/useTime";
import { useState } from "react";

export function ReportWeekdaysStep() {
    const reportConfig = useRecoilValue(selectedReportConfigState);
    const dayWeek = useRecoilValue(selectedReportDayWeekState);
    const [weekdays, setWeekdays] = useRecoilState(selectedReportWeekdaysState);
    const { longFormDateFormat } = useTime();
    const [selectedDay, setSelectedDay] = useRecoilState(selectedReportStartDayState);
    const initialDate = [dayToDateBrowserTimezone(selectedDay)];
    const [value, setValue] = useState(initialDate);

    if (!reportConfig) {
        return null;
    }

    if (dayWeek === "twoweeks") {
        return (
            <ResponsiveFieldWrapper maxWidth={250}>
                <DatePicker
                    value={value}
                    size="compact"
                    placeholder={longFormDateFormat}
                    formatString={longFormDateFormat}
                    onChange={({ date }) => {
                        if (date instanceof Date) {
                            const day = dateToDayBrowserTimezone(date as Date);
                            setSelectedDay(day);
                            setValue([date as Date]);
                        }
                    }}
                    overrides={{
                        Popover: {
                            props: {
                                placement: "bottomRight",
                            }
                        }
                    }}
                />
            </ResponsiveFieldWrapper>
        );
    }

    const onCheckChange = (day: DayKey) => {
        setWeekdays((prev) => {
            if (dayWeek === "day") {
                const toggled = !prev[day];
                return {
                    ...prev,
                    [day]: toggled,
                };
            } else {
                return {
                    ...SELECTED_DAYS_NONE,
                    [day]: true,
                };
            }
        });
    };

    return (
        <DaysPicker editable days={weekdays} onCheck={onCheckChange}/>
    );
}