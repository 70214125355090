import * as React from 'react';
import { Button } from 'baseui/button';
import { ChevronDown, Plus } from 'baseui/icon';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { importTypeState, pageState } from "../../states/appState";
import { LabelSmall } from "baseui/typography";
import { useNewJob } from '../../hooks/jobs/useNewJob';
import { useNewPlace } from '../../hooks/places/useNewPlace';
import { PAGE } from "../../constants/pages";
import { useProSidebar } from "react-pro-sidebar";
import { useStrings } from '../../hooks/useStrings';
import { Divider } from "./Divider";
import { MenuButton } from "./MenuButton";
import { MenuContainer } from "./MenuContainer";
import {
    AddJobIcon,
    AllJobsOutlineIcon,
    PlaceOutlineIcon,
    ReportIcon,
    UsersOutlineIcon,
    ZoneOutlineIcon
} from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { useNavigator } from '../../hooks/useNavigator';
import { newMemberEventState } from "../../states/viewState";
import { useDrawMode } from "../../hooks/useDrawMode";
import useAccount from "../../hooks/useAccount";
import { isLocationOnlyPlan } from "../../utils/account";

export function CreateMenu() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const { initNewJob } = useNewJob();
    const { initNewPlace } = useNewPlace();
    const { navigateToPage } = useNavigator();
    const page = useRecoilValue(pageState);
    const setImportType = useSetRecoilState(importTypeState);
    const { broken } = useProSidebar();
    const setNewMemberEvent = useSetRecoilState(newMemberEventState);
    const { enterDrawMode } = useDrawMode();
    const { company, isSelfAdminOrCompany } = useAccount();

    const isDispatchPlan = !isLocationOnlyPlan(company?.stripe_plan);

    return (
        <StatefulPopover
            focusLock
            placement={PLACEMENT.bottomLeft}
            content={({ close }) => (
                <MenuContainer>
                    {isDispatchPlan && <>
                        <MenuButton
                            icon={<AddJobIcon color={theme.colors.contentPrimary} size={16}/>}
                            title={strings.Job.NewJob}
                            onClick={() => {
                                initNewJob();
                                close();
                            }}/>
                        <MenuButton
                            icon={<AllJobsOutlineIcon color={theme.colors.contentPrimary} size={16}/>}
                            title={strings.Routing.NewRoute}
                            onClick={() => {
                                enterDrawMode({
                                    type: "route",
                                    action: "new",
                                });
                                close();
                            }}/>
                    </>}
                    <MenuButton
                        icon={<PlaceOutlineIcon color={theme.colors.contentPrimary} size={16}/>}
                        title={strings.Places.NewPlace}
                        onClick={() => {
                            initNewPlace();
                            close();
                        }}/>
                    <MenuButton
                        icon={<ZoneOutlineIcon color={theme.colors.contentPrimary} size={16}/>}
                        title={strings.Zones.NewZone}
                        onClick={() => {
                            enterDrawMode({
                                type: "zone",
                                action: "new",
                            });
                            close();
                        }}/>
                    <Divider/>
                    {isSelfAdminOrCompany && <MenuButton
                        icon={<UsersOutlineIcon color={theme.colors.contentPrimary} size={16}/>}
                        title={strings.Members.NewMember}
                        onClick={() => {
                            if (page === PAGE.TEAM) {
                                setImportType('members');
                            } else {
                                setNewMemberEvent(Date.now);
                            }
                            navigateToPage(PAGE.TEAM);
                            close();
                        }}/>}
                    <MenuButton
                        icon={<ReportIcon color={theme.colors.contentPrimary} size={16}/>}
                        title={strings.Reports.NewReport}
                        onClick={() => {
                            navigateToPage(PAGE.REPORTS);
                            close();
                        }}/>
                </MenuContainer>
            )}
        >
            <Button startEnhancer={broken ? undefined : <Plus/>} shape={"pill"} kind={"secondary"}
                    endEnhancer={() => <ChevronDown size={24}/>} size={"mini"}>
                <LabelSmall>{strings.General.Create}</LabelSmall>
            </Button>
        </StatefulPopover>
    );
}