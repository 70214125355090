import { useStrings } from "../hooks/useStrings";

export function HelpView() {
    const { strings } = useStrings();

    return (
        <iframe
            src={strings.Help.Url}
            title={strings.Help.Title}
            height="100%"
            width="100%"
            style={{ border: "none", borderRadius: "8px", marginBottom: "16px" }}
        />
    );
}