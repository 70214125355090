import { useRecoilValue } from "recoil";
import { stringsState } from "../../states/localeState";
import ImgCrop from "antd-img-crop";
import { message, Spin, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { Plus } from "baseui/icon";
import { useTheme } from "../../hooks/useTheme";
import Dragger from "antd/es/upload/Dragger";
import { StyleObject } from "styletron-react";

type Props = {
    onValid: (file: File) => void;
    isLoading?: boolean;
    isAvatar?: boolean;
    styles?: StyleObject;
};

export function ImageUploader({ onValid, isLoading = false, isAvatar = false, styles = {} }: Props) {
    const strings = useRecoilValue(stringsState);
    const { css } = useTheme();

    const validateImage = (file: RcFile) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
        if (!isJpgOrPng) {
            message.error(strings.General.InvalidImageError);
        }
        return isJpgOrPng;
    };

    const uploaderProps: React.ComponentProps<typeof Upload|typeof Dragger> = {
        showUploadList: false,
        customRequest: (options: UploadRequestOption) => {
            onValid(options.file as File);
        }
    }

    const avatarProps: React.ComponentProps<typeof Upload> = {
        ...uploaderProps,
        listType: "picture-circle",
        className: css({
            textAlign: "center",
            ...styles,
        }),
    }

    const draggerProps: React.ComponentProps<typeof Dragger> = {
        ...uploaderProps,
        className: css({
            display: "block",
            height: "100px",
            ...styles,
        }),
    }

    const uploadContent = (
        <div>
            {isLoading ? <Spin />  : <Plus size={16}/> }
            <div>{strings.General.ImageUpload}</div>
        </div>
    );

    if (isAvatar) {
        return (
            <ImgCrop rotationSlider cropShape="round" showReset beforeCrop={validateImage} quality={0.6}>
                <Upload {...avatarProps}>
                    {uploadContent}
                </Upload>
            </ImgCrop>
        )
    }

    return (
        <ImgCrop rotationSlider aspectSlider showReset beforeCrop={validateImage} quality={1}>
            <Dragger {...draggerProps}>
                {uploadContent}
            </Dragger>
        </ImgCrop>
    );
}