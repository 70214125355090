import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { PAGE } from "../constants/pages";
import { loggedInState } from "../states/accountState";
import { isPageType } from "../utils/app";
import { pageState } from "./../states/appState";
import { signupStepState } from "./../states/signupState";
import { useNavigator } from "./useNavigator";
import useAccount from "./useAccount";

export function useRedirect() {
    const { navigateToPage } = useNavigator();
    const isLoggedIn = useRecoilValue(loggedInState);
    const location = useLocation();
    const [page, setPage] = useRecoilState(pageState);
    const signupStep = useRecoilValue(signupStepState);
    const { isSelfWorker } = useAccount();

    useEffect(() => {
        const path = location.pathname.substring(1);
        if ((!isLoggedIn && path === "signup") || (isLoggedIn && signupStep > 1)) {
            return;
        }

        if (isLoggedIn && isSelfWorker) {
            navigateToPage(PAGE.FORMSUBMISSION, true)
        } else if ((path === "signup" || path === "login") && isLoggedIn) {
            navigateToPage(PAGE.LIVE, true);
        } else if (isPageType(path) && !isLoggedIn) {
            navigateToPage("login", true);
        } else if (isPageType(path) && isLoggedIn) {
            setPage(path);
        } else if (isLoggedIn) {
            navigateToPage(page, true);
        } else if (path !== "login" && path !== "signup") {
            navigateToPage("login", true);
        }
    }, [isLoggedIn, location.pathname]);

    return null;
}
