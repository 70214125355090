import { atom } from "recoil";
import { Job } from "../types/job";
import { Member } from "../types/member";

export const updateJobsState = atom({
    key: "update.jobs",
    default: [] as Job[],
});
export const newMemberState = atom({
    key: "update.member.new",
    default: {} as Member | undefined,
});
export const updateAccountState = atom({
    key: "update.account",
    default: {} as Member,
});
export const updateMembersState = atom({
    key: "update.members",
    default: [] as Member[],
});
