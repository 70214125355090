import { sortTeams } from './../utils/teamUtils';
import { Team } from './../types/core';
import { companyState } from './accountState';
import { atomFamily, selector, selectorFamily } from 'recoil';


export const teamsState = selector({
    key: 'teams.all',
    get: ({ get }) => {
        const company = get(companyState);
        if (!company?.teams_config) {
            return [];
        }

        return [...company.teams_config].sort(sortTeams);
    }
});

export const teamState = selectorFamily<Team | undefined, number>({
    key: 'team',
    get: (id) => ({ get }) => {
        const teams = get(teamsState);
        const team = teams.find((team) => team.id === id);
        return team;
    }
});

export const teamsExpandedState = atomFamily<boolean, number>({
    key: "team.expanded",
    default: (team) => true,
});