const cleanString = (s: string) => s.toLowerCase().replaceAll(/[´'‘’`"]/g, "");

export function containsSubstring(str: string | undefined, substr: string | undefined): boolean {
    if (!str) {
        return false;
    }
    if (!substr) {
        return true;
    }
    return cleanString(str).includes(cleanString(substr));
}