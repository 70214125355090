import { useRecoilValue } from "recoil";
import { memberNamesState } from "../../states/membersState";
import { allPlacesState } from "../../states/placeState";
import { TYPE } from "baseui/select";

import * as React from 'react';
import { memo, useEffect, useMemo } from 'react';
import { useDebounce } from "usehooks-ts";
import { MemberItemSimplified } from "../member/MemberItemSimplified";
import { HFlex } from "../containers/HFlex";
import { LabelXSmall } from "baseui/typography";
import { PinSearchIcon, PlaceOutlineIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { Place } from "../../types/place";
import { jobsState } from "../../states/jobsState";
import { Job } from "../../types/job";
import { getColorFromProgress } from "../../utils/jobUtils";
import { useTime } from "../../hooks/useTime";
import { DetailedLocation, Uid } from "../../types/core";
import { VFlex } from "../containers/VFlex";
import { StyledSelect } from "../ui/StyledSelect";
import { uuid } from "../../utils/uuid";
import { usePlacesAutocomplete } from "../../hooks/useAutocomplete";
import { convert } from 'geo-coordinates-parser'
import FixedJob from "../map/markers/FixedJob";

export type Option = {
    label: string;
    id: string | undefined,
    memberUid?: Uid;
    place?: Place;
    job?: Job;
    location?: DetailedLocation;
    suggestion?: any;
}

type Props = {
    placeholder: string;
    onOptionSelected: (option: Option) => void;
    width: string;
    address?: boolean
    place?: boolean;
    member?: boolean;
    job?: boolean;
    growOnFocus?: boolean;
    autoFocus?: boolean;
    centeredOptions?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    opacity?: number;
}

function UniversalPickerInternal({
                                     placeholder,
                                     onOptionSelected,
                                     width,
                                     address = true,
                                     place = true,
                                     member = true,
                                     job = true,
                                     growOnFocus = false,
                                     autoFocus = false,
                                     centeredOptions = true,
                                     onOpen,
                                     onClose,
                                     opacity = 1
                                 }: Props) {
    const { theme } = useTheme();
    const memberNames = useRecoilValue(memberNamesState);
    const places = useRecoilValue(allPlacesState);
    const jobs = useRecoilValue(jobsState);
    const time = useTime();
    const placesAutocomplete = usePlacesAutocomplete();

    const memberOptions = useMemo(() => member ? memberNames.map(({ uid, name }) => {
        return {
            id: uid,
            label: name,
            value: name,
            memberUid: uid,
        } as Option
    }) : [], [memberNames]);
    const placeOptions = place ? places.map(p => {
        return {
            id: p.uid,
            label: p.name,
            value: p.name,
            place: p
        } as Option
    }) : [];

    const [query, setQuery] = React.useState<string>("");
    const debouncedQuery = useDebounce<string>(query, 350);
    const [options, setOptions] = React.useState<Option[]>(member ? memberOptions : placeOptions);
    const [isLoading, setIsLoading] = React.useState(false);

    const createCoordOption = () => {
        let converted: any;
        try {
            converted = convert(query, 5);
        } catch {
            converted = undefined;
        }
        const coordOption = converted ? {
            id: uuid(),
            label: query,
            value: query,
            location: {
                lat: converted.decimalLatitude as number,
                lng: converted.decimalLongitude as number,
                name: query,
                address: "",
            } as DetailedLocation
        } as Option : undefined;
        return coordOption;
    }

    useEffect(() => {
        if (debouncedQuery.length >= 3) {
            setIsLoading(true);

            const jobOptions = job ? jobs.map(j => {
                return {
                    id: j.id,
                    label: j.destinationName,
                    value: j.destinationName,
                    job: j
                } as Option
            }) : [];

            setOptions([...memberOptions, ...jobOptions, ...placeOptions]);

            if (address) {
                placesAutocomplete.retrievePredictions(debouncedQuery).then(predictions => {
                    const remoteOptions = predictions.map(sugg => {
                        const option = {
                            id: uuid(),
                            label: sugg.description,
                            value: query,
                            location: {
                                name: sugg.description,
                                address: "",
                            } as DetailedLocation, suggestion: sugg
                        } as Option;
                        return option
                    });
                    const allOptions = [...memberOptions, ...jobOptions, ...placeOptions, ...remoteOptions];
                    const coordOption = createCoordOption();
                    setOptions(coordOption ? [coordOption, ...allOptions] : allOptions);
                })
                    .finally(() => setIsLoading(false));
            } else {
                setIsLoading(false)
            }
        }
    }, [debouncedQuery]);

    const getLabel = ({ option }: any) => {
        if (option.memberUid) {
            return <MemberItemSimplified uid={option.memberUid}/>;
        }
        if (option.job) {
            const job = option.job as Job;
            return <HFlex alignCenter spacing>
                <FixedJob color={getColorFromProgress(job)} size={16}/>
                <LabelXSmall>{option.label}</LabelXSmall>
                <LabelXSmall
                    color={theme.customColors.accent}>{time.formatDay(job.day)}</LabelXSmall>
            </HFlex>;
        }
        if (option.location) {
            const location = option.location as DetailedLocation;
            return (
                <HFlex alignCenter spacing>
                    <PinSearchIcon size={16} color={theme.colors.contentPrimary}/>
                    <VFlex>
                        <LabelXSmall>{location.name ? location.name : location.address}</LabelXSmall>
                        {location.name && <LabelXSmall color={theme.customColors.accent}>{location.address}</LabelXSmall>}
                    </VFlex>
                </HFlex>
            );
        }

        return (
            <HFlex alignCenter spacing>
                {option.place && <PlaceOutlineIcon size={16} color={option.place.color}/>}
                <LabelXSmall>{option.label}</LabelXSmall>
            </HFlex>
        );
    };

    return (
        <StyledSelect
            placeholder={placeholder}
            size={"compact"}
            options={options}
            isLoading={isLoading}
            autoFocus
            getOptionLabel={getLabel}
            labelKey={"value"}
            type={TYPE.search}
            onOpen={onOpen}
            onClose={onClose}
            onChange={async ({ value }) => {
                if (value && value[0]) {
                    const selected = value[0] as Option;
                    if (selected.location && selected.suggestion) {
                        setIsLoading(true);
                        const result = await placesAutocomplete.retrieveDetails(selected.suggestion.place_id);
                        const lat = result.geometry?.location?.lat();
                        const lng = result.geometry?.location?.lng();
                        const location = {
                            ...selected.location,
                            lat,
                            lng,
                        }
                        const option = { ...selected, location } as Option;
                        onOptionSelected(option);
                        setIsLoading(false);
                    } else {
                        onOptionSelected(value[0] as Option);
                    }
                }
            }}
            onInputChange={(event: React.SyntheticEvent) => {
                const element = event.target as HTMLInputElement;
                const query = element.value;
                setQuery(query);
                setOptions(member ? memberOptions : placeOptions);
            }}
            overrides={{
                Root: {
                    style: {
                        width: "auto",
                        flexShrink: 0,
                        opacity,
                    }
                },
                ControlContainer: {
                    style: ({ $isFocused }) => ({
                        width: growOnFocus && $isFocused ? `calc(${width} * 2)` : width,
                        borderBottomLeftRadius: "36px",
                        borderBottomRightRadius: "36px",
                        borderTopLeftRadius: "36px",
                        borderTopRightRadius: "36px",
                        transitionProperty: "width",
                        transitionDuration: "300ms",
                        transitionTimingFunction: "ease",
                    }),
                },
                Input: {
                    props: {
                        autoComplete: "off",
                    }
                },
                DropdownContainer: {
                    style: {
                        width: "300px",
                    },
                },
                Popover: {
                    props: {
                        placement: !centeredOptions ? "bottomLeft" : undefined,
                    }
                }
            }}
        />
    );
}

export const UniversalPicker = memo(UniversalPickerInternal);