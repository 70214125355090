import { HFlex } from "../containers/HFlex";
import { hasDeviceAnalysisFeature, hasValidDevice, Member } from "../../types/member";
import { Button } from "baseui/button";
import { HTTPS_DOMAIN, sendMessage } from "../../services/api";
import { Alert, Divider } from "antd";
import { Spacer } from "../containers/Spacer";
import { TrackingDiagnosticsSection } from "./TrackingDiagnosticsSection";
import React from "react";
import { useToast } from "../../hooks/useToast";
import { useMembersUpdater } from "../../hooks/members/useMembersUpdater";
import { Section } from "../ui/Section";
import { ParagraphXSmall } from "baseui/typography";
import { useStrings } from "../../hooks/useStrings";

export function DeviceDiagnosticsSection({ member }: { member: Member | undefined }) {
    const toast = useToast();
    const { strings } = useStrings();
    const { updateSingleMember } = useMembersUpdater();

    if (!member) {
        return null;
    }

    const onTrigger = () => {
        sendMessage({
            msg: strings.DeviceDiagnostics.DeviceDiagnosticsRequest + " 📊⬆️",
            receivers: [member.uid],
        }).then(() => {
            toast.showSuccess(strings.DeviceDiagnostics.Requested);
            for (let i = 0; i < 5; i++) {
                setTimeout(() => updateSingleMember(member.uid), 4_000 * i);
            }
        })
    }

    return (
        <Section title={strings.DeviceDiagnostics.DeviceDiagnostics}>
            <ParagraphXSmall>
                {strings.DeviceDiagnostics.Description}
            </ParagraphXSmall>
            <HFlex spacing alignCenter>
                <Button size={"mini"} kind={"primary"} onClick={onTrigger} disabled={!hasDeviceAnalysisFeature(member)}>
                    {strings.DeviceDiagnostics.Trigger}
                </Button>
                {!hasDeviceAnalysisFeature(member) &&
                    <Alert message={hasValidDevice(member)
                        ? strings.DeviceDiagnostics.UnavailableNeedsUpdate
                        : strings.DeviceDiagnostics.UnavailableNotLoggedIn}/>}
            </HFlex>
            <Divider/>
            <Spacer/>
            {member.device?.diagnostics_path &&
                <TrackingDiagnosticsSection dataUrl={HTTPS_DOMAIN + member.device.diagnostics_path}/>
            }
        </Section>
    )
}