import CalendarHeatmap from "react-calendar-heatmap";
import React, { useEffect, useState } from "react";
import { VFlex } from "../containers/VFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { useCharts } from "../../hooks/useCharts";
import { dateMinusDays, DAY, dayOfMinus, dayOfToday, dayToMillisBrowserTimezone, isoDateToDay } from "../../utils/time";
import { MileageCalendarHeatmapEntry } from "../../types/charts";
import { useTime } from "../../hooks/useTime";
import '../timeline/calendarheatmap.css';
import { useStrings } from "../../hooks/useStrings";
import { HFlex } from "../containers/HFlex";
import { useUnit } from "../../hooks/useUnit";
import { useRecoilState, useSetRecoilState } from "recoil";
import { selectedDayState } from "../../states/appState";
import { mileageCalendarHeatmapDataState } from "../../states/dataState";
import { Button } from "baseui/button";
import { LabelXSmall } from "baseui/typography";
import { useTheme } from "../../hooks/useTheme";
import { Alert } from "antd";
import { Day, Uid } from "../../types/core";

type Props = {
    uid: Uid;
    showInfo?: boolean;
    onDaySelected?: (day: Day) => void;
    style?: any;
};

export function MileageHistory({ uid, style, showInfo = true, onDaySelected }: Props) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const time = useTime();
    const unit = useUnit();
    const { fetchMileageCalendarHeatmap } = useCharts();
    const [data, setData] = useRecoilState(mileageCalendarHeatmapDataState(uid));
    const [numDays, setNumDays] = useState(31);
    const setSelectedDay = useSetRecoilState(selectedDayState);
    const [nowDate,] = useState(new Date());
    const fromDay = dayOfMinus(numDays);
    const untilDay = dayOfToday();
    const startDate = time.formatIsoDate(dateMinusDays(new Date(), numDays));
    const endDate = time.formatIsoDate(nowDate);

    useEffect(() => {
        fetchMileageCalendarHeatmap(uid, fromDay, untilDay)
            .then(result => {
                const data = result.data;
                const dates = data.map(d => d.date);
                let millis = dayToMillisBrowserTimezone(fromDay);
                while (millis <= Date.now()) {
                    const isoDate = time.formatIsoDate(new Date(millis));
                    if (!dates.includes(isoDate)) {
                        data.push({ date: isoDate, count: 0 });
                    }
                    millis += DAY;
                }
                setData(data);
            })
    }, [fromDay, untilDay]);

    return (
        <VFlex>
            {showInfo && <Alert showIcon closable message={strings.General.MileageHistoryDesc}/>}
            <Spacer/>
            <HFlex>
                <SpacerExpand/>
                <Button size={"mini"} kind={"tertiary"} onClick={() => setNumDays(numDays + 30)}>
                    <LabelXSmall color={theme.colors.contentTertiary}>
                        {strings.General.More}
                    </LabelXSmall>
                </Button>
                <SpacerExpand/>
            </HFlex>
            <HFlex>
                <SpacerExpand/>
                <div style={{ width: "200px", marginLeft: "40px", ...style }}>
                    <CalendarHeatmap
                        values={data}
                        horizontal={false}
                        gutterSize={2}
                        startDate={startDate}
                        endDate={endDate}
                        showMonthLabels
                        showWeekdayLabels
                        onClick={(value) => {
                            const day = isoDateToDay(value?.date);
                            if (value?.date) {
                                if (onDaySelected) {
                                    onDaySelected(day);
                                } else {
                                    setSelectedDay(day);
                                }
                            }
                        }}
                        tooltipDataAttrs={(value: MileageCalendarHeatmapEntry) => {
                            return value?.date && {
                                'data-tooltip': `${time.formatDay(isoDateToDay(value.date))}${value.count > 0
                                    ? "\n" + unit.distanceToString(value.count) : ""}`
                            }
                        }}
                        classForValue={(value) => {
                            if (!value || value.count === 0) {
                                return 'color-mileage-0';
                            }
                            if (value.count < 10_000) {
                                return `color-mileage-1`;
                            }
                            if (value.count < 25_000) {
                                return `color-mileage-2`;
                            }
                            if (value.count < 50_000) {
                                return `color-mileage-3`;
                            }
                            if (value.count < 125_000) {
                                return `color-mileage-4`;
                            }
                            if (value.count < 180_000) {
                                return `color-mileage-5`;
                            }
                            if (value.count < 250_000) {
                                return `color-mileage-6`;
                            }
                            return `color-mileage-7`;
                        }}
                        monthLabels={[
                            strings.General.MonthJan,
                            strings.General.MonthFeb,
                            strings.General.MonthMar,
                            strings.General.MonthApr,
                            strings.General.MonthMay,
                            strings.General.MonthJun,
                            strings.General.MonthJul,
                            strings.General.MonthAug,
                            strings.General.MonthSep,
                            strings.General.MonthOct,
                            strings.General.MonthNov,
                            strings.General.MonthDec,
                        ]}
                        weekdayLabels={[
                            strings.General.WeekdaySu,
                            strings.General.WeekdayMo,
                            strings.General.WeekdayTu,
                            strings.General.WeekdayWe,
                            strings.General.WeekdayTh,
                            strings.General.WeekdayFr,
                            strings.General.WeekdaySa,
                        ]}
                    />
                </div>
            </HFlex>
            <Spacer/>
        </VFlex>
    );
}