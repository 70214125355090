import { Button } from "baseui/button";
import { StyledDivider } from "baseui/divider";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { StyledLink } from "baseui/link";
import { Modal, ModalBody, ROLE, SIZE } from "baseui/modal";
import { HeadingXLarge, HeadingXSmall, ParagraphMedium } from "baseui/typography";
import { ChangeEvent, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { VFlex } from "../components/containers/VFlex";
import useAccount from "../hooks/useAccount";
import { useStrings } from "../hooks/useStrings";
import { useTheme } from "../hooks/useTheme";
import { loggedInState, usernameState } from "../states/accountState";
import { Alert } from "antd";
import { COLOR_LOGIN_BACKGROUND } from "../constants/colors";
import { useNavigator } from "../hooks/useNavigator";
import { checkUserAvailability } from "../services/privateApi";

export default function SsoView() {
    const { strings } = useStrings();
    const account = useAccount();
    const [username, setUsername] = useRecoilState(usernameState);
    const isLoggedIn = useRecoilValue(loggedInState);
    const { theme, css } = useTheme();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const { navigateToPage } = useNavigator();

    useEffect(() => setUsername(account.username), [account.username]);

    const onUsernameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUsername(value);
    };

    const onLoginClick = async () => {
        setLoading(true);
        setErrorMessage("");

        const { status, authUrl, available } = await checkUserAvailability({
            username: account.username,
        });
        setLoading(false);

        if (status && authUrl) {
            window.location.replace(authUrl);
        } else if (available) {
            setErrorMessage(strings.Login.UserDoesNotExist);
        } else {
            setErrorMessage(strings.Login.UserNotSetUpForSSO);
        }
    };

    const onBackToLogin = () => {
        navigateToPage("login", true);
    };


    return (
        <Modal
            isOpen={!isLoggedIn}
            closeable={false}
            animate
            autoFocus
            size={SIZE.default}
            role={ROLE.dialog}
            overrides={{
                Dialog: {
                    style: {
                        backgroundColor: theme.colors.backgroundTertiary + "F0",
                        padding: theme.sizing.scale1400,
                    },
                },
                DialogContainer: {
                    style: {
                        backgroundColor: COLOR_LOGIN_BACKGROUND,
                        backgroundImage: "url(/images/login-background.svg)",
                        backgroundPosition: "50%",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    },
                },
            }}
        >
            <ModalBody $style={{
                marginTop: 0,
                marginRight: 0,
                marginBottom: 0,
                marginLeft: 0,
            }}>
                <VFlex alignCenter style={{ marginBottom: theme.sizing.scale1000 }}>
                    <HeadingXLarge margin={0}>{"Hellotracks"}</HeadingXLarge>
                    <HeadingXSmall margin={0}>{strings.General.SingleSignOn}</HeadingXSmall>
                </VFlex>

                <FormControl label={strings.General.UsernameOrEmail}>
                    <Input
                        name="username"
                        placeholder="user@company.com"
                        value={username}
                        type="email"
                        onChange={(e) => onUsernameChange(e)}
                        clearOnEscape
                    />
                </FormControl>
                {errorMessage && (
                    <Alert showIcon type={"warning"} description={errorMessage}/>
                )}
                <VFlex style={{ marginTop: theme.sizing.scale1000 }}>
                    <Button
                        onClick={onLoginClick}
                        isLoading={loading}
                    >
                        {strings.General.Login}
                    </Button>
                </VFlex>
                <StyledDivider
                    $size="cell"
                    className={css({
                        marginTop: theme.sizing.scale900,
                        marginBottom: theme.sizing.scale800,
                    })}
                />
                <VFlex alignCenter spacing>
                    <ParagraphMedium margin={0}>
                        {strings.General.BackTo}{" "}
                        <StyledLink
                            className={css({ cursor: "pointer" })}
                            onClick={onBackToLogin}
                        >
                            {strings.General.Login}
                        </StyledLink>
                    </ParagraphMedium>
                    <ParagraphMedium margin={0}>
                        {strings.General.DontHaveAnAccountYet}{" "}
                        <StyledLink
                            className={css({ cursor: "pointer" })}
                            href="/signup"
                            onClick={(event: any) => {
                                event.preventDefault();
                                navigateToPage("signup", true);
                            }}
                        >
                            {strings.General.Signup}
                        </StyledLink>
                    </ParagraphMedium>
                </VFlex>
            </ModalBody>
        </Modal>
    );
}
