import { LabelXSmall } from "baseui/typography";
import { Card } from "antd/lib";
import { useTheme } from "../../hooks/useTheme";
import { ReactNode } from "react";

export function MiniCard({ onClick, children }: { onClick?: () => void, children: ReactNode }) {
    const { theme } = useTheme();

    const style = onClick && { cursor: "pointer" };
    return (
        <Card style={{ backgroundColor: theme.colors.backgroundSecondary, ...style }} onClick={onClick}>
            <LabelXSmall>{children}</LabelXSmall>
        </Card>
    )
}