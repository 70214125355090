import { CSV_EXTENSION, EXCEL_EXTENSION, JSON_EXTENSION, KML_EXTENSION } from './app';

export enum PlacesImportDefaultFields {
    "Name",
    "Address",
    "City",
    "Zip Code",
    "State",
    "Country",
    "E-mail",
    "Phone",
}

export enum PlacesImportAdvancedFields {
    "Latitude",
    "Longitude",
    "Radius"
}

export const IMPORT_PLACE_FILE_FORMATS = [CSV_EXTENSION, EXCEL_EXTENSION, KML_EXTENSION, JSON_EXTENSION];

export const RADIUS_DEFAULT = 100;
export const RADIUS_MIN = 25;
export const RADIUS_MAX = 300;