import { ReactNode, useEffect, useState } from "react";

export function AssistantChip({ chip }: { chip: ReactNode }) {
    const [opacity, setOpacity] = useState(0);
    const [translateX, setTranslateX] = useState<string | undefined>("-100%");
    useEffect(() => {
        setTimeout(() => {
            setOpacity(1);
            setTranslateX("0")
        }, 10);
    }, [])

    return (
        <div style={{
            flexBasis: chip === "" ? "100%" : undefined,
            height: chip === "" ? "8px" : undefined,
            opacity,
            transition: "opacity 0.3s ease-out",
            overflow: "hidden",
        }}>
            <div style={{
                transition: "transform 0.3s ease-out",
                transform: `translateX(${translateX})`,
                overflow: "hidden",
            }}>
                {chip}
            </div>
        </div>)
}