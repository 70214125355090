import { ColumnConfiguration, ReportFormat, ReportType } from "../types/reports";
import { downloadFile } from "../utils/files";
import { API_ENDPOINT, Endpoint, prepareRequest } from "./api";
import { TimesheetEntry } from "../types/timesheet";
import { Uid } from "../types/core";


export const downloadReport = async (endpoint: Endpoint, dataObj: CreateReportRequest) => {
    const url = API_ENDPOINT + '/' + endpoint;
    const result = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(prepareRequest(dataObj)),
    });

    const blob = await result.blob();
    downloadFile(dataObj.filename + "." + dataObj.format, blob);
}

export interface CreateReportRequest {
    format: ReportFormat;
    reporttype: ReportType;
    filename: string;
    accounts?: string[];
    day?: number;
    from?: number;
    until?: number;
    payload?: string,
    startDay?: number,
    columns?: ColumnConfiguration[],
}

export const createReport = async (req: CreateReportRequest) => {
    await downloadReport(Endpoint.createreport, req);
}

export const downloadTracksAsGpx = async (memberUid: Uid, entries: TimesheetEntry[]) => {
    const url = API_ENDPOINT + '/' + Endpoint.downloadtracks;
    const result = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(prepareRequest({ memberUid, trackIds: entries.map(e => e.uid) })),
    });
    const blob = await result.blob();
    downloadFile("gpx-" + Date.now() + ".zip", blob);
}