import { StyledHead, StyledHeadCell } from "baseui/table";
import { tableHeaderStyle } from "../../../constants/ui";
import { useStyleOverrides } from "../../../hooks/useStyleOverrides";
import { useMembersColumns } from "../../../hooks/members/useMembersColumns";
import { useTables } from "../../../hooks/useTables";
import { useTheme } from "../../../hooks/useTheme";
import { MEMBERS_NAME_COLUMN, MEMBERS_OPTIONS_COLUMN } from "../../../constants/members";
import { Fragment } from "react";

export function MembersTableHeaderRow() {
    const { isDarkTheme } = useTheme();
    const { fixedColumnStyle } = useStyleOverrides();
    const { columnsConfiguration, tableWidth, columnsWidth, enabledColumns } = useMembersColumns();
    const { getHeadCell } = useTables(columnsConfiguration);

    return (
        <StyledHead role="row" $width={`${tableWidth}px`} style={{
            minWidth: "100%",
            backgroundColor: isDarkTheme ? "#00000088" : "#FFFFFF88"
        }}>
            {getHeadCell(MEMBERS_NAME_COLUMN)}
            {enabledColumns.map((column, index) => (
                <Fragment key={column+index}>
                    {getHeadCell(column)}
                </Fragment>
            ))}
            <StyledHeadCell
                $style={{ ...fixedColumnStyle(columnsWidth[MEMBERS_OPTIONS_COLUMN]), ...tableHeaderStyle }}/>
        </StyledHead>
    );
}