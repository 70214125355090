import { useTheme } from "./useTheme";

export function useListItem() {
    const { theme, isDarkTheme } = useTheme();

    const draggingOverColor = isDarkTheme ? theme.colors.positive700 : theme.colors.positive50;
    const hoverColor = theme.customColors.accent + "20";
    const listItemHeight = 34;
    const listItemHeightPx = listItemHeight + "px";

    const getSelectedStyles = (isSelected: boolean, isDraggingOver: boolean) => {
        return isSelected
            ? {
                border: "solid 2px " + theme.colors.contentPrimary,
                backgroundColor: isDraggingOver
                    ? draggingOverColor
                    : hoverColor,
            }
            : {
                border: "solid 2px transparent",
                backgroundColor: isDraggingOver
                    ? draggingOverColor
                    : undefined,
                [":hover"]: {
                    backgroundColor: isDraggingOver ? draggingOverColor : hoverColor
                }
            }
    };

    const listItemRootStyle = ({
                                   isSelected = false,
                                   isDraggingOver = false
                               }: { isSelected?: boolean, isDraggingOver?: boolean }) => {
        return {
            cursor: "pointer",
            boxSizing: "border-box",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            backgroundImage: "none",
            ...getSelectedStyles(isSelected, isDraggingOver),
        };
    }

    const listItemContentStyle = ({
                                      isSelected = false,
                                      isHovering = false,
                                      small = false
                                  }: { isSelected?: boolean, isHovering?: boolean, small?: boolean }) => {
        return {
            overflow: 'hidden',
            minHeight: small ? listItemHeightPx : "50px",
            maxHeight: small ? listItemHeightPx : undefined,
            borderBottomColor: dividerColor(isSelected, isHovering),
        }
    }

    const dividerColor = (isSelected: boolean, isHovering: boolean) => {
        return isSelected || isHovering ? "transparent" : theme.colors.contentPrimary + "15"
    }

    return {
        draggingOverColor,
        listItemRootStyle,
        listItemContentStyle,
        listItemHeight,
        listItemHeightPx,
        dividerColor,
    }
}