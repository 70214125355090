import { atom, atomFamily } from "recoil";
import { LonLat } from "../types/core";
import { QuickRouteItemData } from "../types/quickRoute";

export const quickRouteCoordinatesState = atom({
    key: "quickroute.coordinates",
    default: [] as LonLat[],
});

export const quickRouteDataState = atomFamily<QuickRouteItemData, LonLat>({
    key: "quickroute.data",
    default: (lonLat) => {
        return { lonLat: lonLat, title: "" } as QuickRouteItemData
    },
});