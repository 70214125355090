import { useRecoilState, useRecoilValue } from "recoil";
import { ReportCreation } from "../components/reports/ReportCreation";
import { ReportSelection } from "../components/reports/ReportSelection";
import {
    selectedRecurringReportState,
    selectedReportConfigState,
    setupRecurringReportState
} from "../states/reportState";
import { SetupRecurringReport } from "../components/reports/SetupRecurringReport";
import { Tab, Tabs } from "baseui/tabs-motion";
import { RecentReports } from "../components/reports/RecentReports";
import { tabOverrides, tabsOverrides } from "../constants/ui";
import { RecurringReports } from "../components/reports/RecurringReports";
import { useStrings } from "../hooks/useStrings";
import { reportsViewActiveTab } from "../states/viewState";
import { RecurringReport } from "../components/reports/RecurringReport";

type Keys = "new" | "recurring" | "recent";

export function ReportsView() {
    const { strings } = useStrings();
    const selectedReport = useRecoilValue(selectedReportConfigState);
    const recurring = useRecoilValue(setupRecurringReportState);
    const selectedRecurringReport = useRecoilValue(selectedRecurringReportState);
    const [activeKey, setActiveKey] = useRecoilState(reportsViewActiveTab);

    if (!recurring && selectedReport) {
        return <ReportCreation/>
    }

    if (recurring && selectedReport && !selectedRecurringReport) {
        return <SetupRecurringReport/>
    }

    if (recurring && selectedReport && selectedRecurringReport) {
        return <RecurringReport/>
    }

    return (
        <Tabs
            activeKey={activeKey}
            onChange={({ activeKey }) => {
                setActiveKey(activeKey as Keys);
            }}
            overrides={tabsOverrides}
        >
            <Tab key={"new"} title={strings.Reports.CreateNewReports} overrides={tabOverrides}>
                <ReportSelection/>
            </Tab>
            <Tab key={"recurring"} title={strings.Reports.RecurringReports} overrides={tabOverrides}>
                <RecurringReports/>
            </Tab>
            <Tab key={"recent"} title={strings.Reports.RecentReports} overrides={tabOverrides}>
                <RecentReports/>
            </Tab>
        </Tabs>
    );
}