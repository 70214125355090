import { Badge } from "antd";
import { DraggableProvided } from "react-beautiful-dnd";
import { useAllJobsSelection } from "../../hooks/jobs/useAllJobsSelection";
import { useSingleJobSelection } from "../../hooks/jobs/useSingleJobSelection";
import TableJobItem from "./TableJobItem";

type Props = {
    provided: DraggableProvided;
    jobId: string;
    isDragging?: boolean;
};

export function TableJob({ provided, jobId, isDragging = false }: Props) {
    const { isSelected } = useSingleJobSelection(jobId || "");
    const { selectionCount } = useAllJobsSelection();

    return (
        <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={{
                ...provided.draggableProps.style,
                transform: !isDragging ? "none" : provided.draggableProps.style?.transform,
            }}
        >
            {isDragging && isSelected && selectionCount > 1 ? (
                <Badge count={selectionCount}>
                    <TableJobItem id={jobId} />
                </Badge>
            ) : (
                <TableJobItem id={jobId} />
            )}
        </div>
    );
}
