import { MapProvider } from "react-map-gl";
import { ErrorScopeProvider } from "../providers/ErrorScopeProvider";
import { SharedTrack } from "../components/timeline/SharedTrack";

export function PublishedTrackView() {

    return (
        <ErrorScopeProvider>
            <MapProvider>
                <SharedTrack/>
            </MapProvider>
        </ErrorScopeProvider>
    );
}