import { Button } from 'baseui/button';
import { Input } from 'baseui/input';
import { ListItem, ListItemLabel } from 'baseui/list';
import { LabelSmall } from 'baseui/typography';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTheme } from '../../hooks/useTheme';
import { editJobTypesState } from '../../states/editJobTypesState';
import { stringsState } from '../../states/localeState';
import { ResponsiveFlex } from '../containers/ResponsiveFlex';
import { UnorderedList } from '../containers/UnorderedList';
import { TrashIcon } from '../ui/svg';

export function JobTypesManager() {
    const strings = useRecoilValue(stringsState);
    const { theme, css } = useTheme();
    const [jobTypes, setJobTypes] = useRecoilState(editJobTypesState);
    const [newJobType, setNewJobType] = useState('');

    const handleNewJobType = () => {
        setJobTypes((prev) => [...prev, { id: -1, name: newJobType }]);
        setNewJobType('');
    };

    const handleRemoveJobType = ({ id, index }: { id?: number; index: number }) => {
        setJobTypes((prev) => {
            if (id) {
                return prev.filter((jobType) => jobType.id !== id);
            } else {
                const newJobTypes = [...prev];
                newJobTypes.splice(index, 1);
                return newJobTypes;
            }
        });
    };

    return (
        <>
            <ResponsiveFlex
                spacing={theme.sizing.scale600}
                style={{ marginTop: theme.sizing.scale400, marginBottom: theme.sizing.scale400 }}
            >
                <Input
                    value={newJobType}
                    onChange={(event) => setNewJobType(event.target.value)}
                    size="compact"
                    placeholder={strings.Job.JobType}
                    autoFocus
                />
                <Button onClick={handleNewJobType} disabled={!newJobType} size="compact">
                    {strings.General.Add}
                </Button>
            </ResponsiveFlex>
            <div className={css({
                height: '40vh',
                overflow: 'auto'
            })}>
                <UnorderedList>
                    {jobTypes.map((jobType, index) => (
                        <ListItem
                            key={index}
                            endEnhancer={() => (
                                <Button
                                    size="compact"
                                    kind="secondary"
                                    onClick={() =>
                                        handleRemoveJobType({ id: jobType.id !== -1 ? jobType.id : undefined, index })
                                    }
                                >
                                    <TrashIcon size={14} color={theme.colors.contentPrimary}/>
                                </Button>
                            )}
                        >
                            <ListItemLabel>{jobType.name}</ListItemLabel>
                        </ListItem>
                    ))}
                </UnorderedList>
            </div>
            <LabelSmall color={theme.colors.contentSecondary} marginTop={theme.sizing.scale400}>
                {strings.Job.JobTypeDeleteDesc}
            </LabelSmall>
        </>
    );
}
