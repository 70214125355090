import React from 'react';
import { extractYouTubeID } from "../../utils/app";

export const YouTubeEmbed = function({ url } : { url: string}) {
    const videoId = extractYouTubeID(url);
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    if (!embedUrl) {
        return null;
    }

    return (
        <iframe
            width="560"
            height="315"
            src={embedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    );
}