import { VFlex } from "../containers/VFlex";
import { Radio, RadioGroup } from "baseui/radio";
import { ResponsiveFieldWrapper } from "../containers/ResponsiveFieldWrapper";
import { LabelSmall } from "baseui/typography";
import { Button } from "baseui/button";
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";
import { ChangeEvent, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { pickerMembersState } from "../../states/membersPickerState";
import { placesPickerState } from "../../states/placesPickerState";
import { useText } from "../../hooks/useText";
import { createPortal } from "react-dom";
import { StyledModal } from "../ui/StyledModal";
import { PlacesPickerModalContent } from "../places/modal/PlacesPickerModalContent";
import { MembersPickerModalContent } from "../member/modal/MembersPickerModalContent";
import {
    selectedReportConfigState,
    selectedReportMembersState,
    selectedReportPlacesState
} from "../../states/reportState";

type Props = {
    onNext: () => void;
};

export function ReportFilterStep({ onNext }: Props) {
    const reportConfig = useRecoilValue(selectedReportConfigState);
    const [members, setMembers] = useRecoilState(selectedReportMembersState);
    const [places, setPlaces] = useRecoilState(selectedReportPlacesState);
    const { theme } = useTheme();
    const { strings } = useStrings();
    const filteringBy = members.length ? "members" : places.length ? "places" : undefined;
    const defaultFilter = reportConfig?.canSelectMembers ? "members" : reportConfig?.canSelectPlaces ? "places" : undefined;
    const [filterBy, setFilterBy] = useState<"places" | "members" | undefined>(
        filteringBy ? filteringBy : defaultFilter
    );
    const setSelectedMembers = useSetRecoilState(pickerMembersState);
    const setSelectedPlaces = useSetRecoilState(placesPickerState);
    const [pickingPlaces, setPickingPlaces] = useState(false);
    const [pickingMembers, setPickingMembers] = useState(false);
    const { getMembersTextFromArray, getPlacesTextFromArray } = useText();

    const handleOpenMembersPicker = () => {
        setSelectedMembers((prev) =>
            prev.map((member) => ({ ...member, selected: !!members.find((m) => m.id === member.id) }))
        );
        setPickingMembers(true);
    };

    const handleOpenPlacesPicker = () => {
        setSelectedPlaces((prev) =>
            prev.map((place) => ({ ...place, selected: !!places.find((p) => p.uid === place.uid) }))
        );
        setPickingPlaces(true);
    };

    const closePlacesPickerModal = () => {
        setPickingPlaces(false);
    };

    const closeMembersPickerModal = () => {
        setPickingMembers(false);
    };

    const handleFilterBy = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
        const newFilter = value as typeof filterBy;
        setFilterBy(newFilter);
        if (newFilter === "members") {
            setPlaces([]);
        }
        if (newFilter === "places") {
            setMembers([]);
        }
    };

    if (!reportConfig || !filterBy) {
        return null;
    }

    return (
        <VFlex style={{ rowGap: theme.sizing.scale300, height: "auto" }}>
            {reportConfig.canSelectMembers && reportConfig.canSelectPlaces && (
                <RadioGroup value={filterBy} onChange={handleFilterBy} name="filter" align="horizontal">
                    <Radio value="members">{strings.General.ByMembers}</Radio>
                    <Radio value="places">{strings.General.ByPlaces}</Radio>
                </RadioGroup>
            )}
            {filterBy === "members" && (
                <ResponsiveFieldWrapper>
                    <LabelSmall>{strings.General.Members}</LabelSmall>
                    <Button
                        size={"mini"}
                        shape={"pill"}
                        kind={members.length === 0 ? "primary" : "secondary"}
                        onClick={handleOpenMembersPicker}
                    >
                        {getMembersTextFromArray(members, strings.Members.SelectMembers)}
                    </Button>
                </ResponsiveFieldWrapper>
            )}
            {filterBy === "places" && (
                <ResponsiveFieldWrapper>
                    <LabelSmall>{strings.General.Places}</LabelSmall>
                    <Button
                        size={"mini"}
                        shape={"pill"}
                        kind={places.length === 0 ? "primary" : "secondary"}
                        onClick={handleOpenPlacesPicker}
                    >
                        {getPlacesTextFromArray(places, strings.Places.SelectPlaces)}
                    </Button>
                </ResponsiveFieldWrapper>
            )}
            {createPortal(
                <StyledModal onClose={closePlacesPickerModal} isOpen={pickingPlaces}>
                    <PlacesPickerModalContent
                        closeModal={closePlacesPickerModal}
                        onAccept={async (places) => {
                            setPlaces(places);
                            onNext();
                        }}
                        multi={reportConfig.allowMultipleAccounts}
                    />
                </StyledModal>,
                document.body
            )}
            {createPortal(
                <StyledModal onClose={closeMembersPickerModal} isOpen={pickingMembers}>
                    <MembersPickerModalContent
                        closeModal={closeMembersPickerModal}
                        onAccept={async (members) => {
                            setMembers(members);
                            onNext();
                        }}
                        multi={reportConfig.allowMultipleAccounts}
                    />
                </StyledModal>,
                document.body
            )}
        </VFlex>
    );
}