import { useSetRecoilState } from "recoil";
import { editPlaceState } from "../../states/editPlaceState";
import { editingPlaceIdState } from "../../states/appState";
import { RADIUS_DEFAULT } from "../../constants/places";
import { usePlaceSelection } from "./usePlaceSelection";
import { Place } from "../../types/place";
import { CustomField, DetailedLocation, EMPTY_UID } from "../../types/core";
import useAccount from "../useAccount";
import { useDrawMode } from "../useDrawMode";

export function useNewPlace() {
    const setEditingPlace = useSetRecoilState(editingPlaceIdState);
    const setEditPlace = useSetRecoilState(editPlaceState(""));
    const selection = usePlaceSelection();
    const { company } = useAccount();
    const { enterDrawMode } = useDrawMode();

    const initNewPlace = () => {
        enterDrawMode({
            type: "place",
            action: "new",
        });
    }

    const createNewPlace = (locationPicker: DetailedLocation, radius: number = RADIUS_DEFAULT) => {
        selection.clearSelection();
        setEditingPlace(EMPTY_UID);
        const defaultCustomFields = company?.place_custom_fields
            .map(f => {
                return { key: f, val: "" } as CustomField
            }) || [] as CustomField[];
        setEditPlace({
            radius,
            name: locationPicker?.address || "",
            address: locationPicker?.address || "",
            city: locationPicker?.city || "",
            postalcode: locationPicker?.postalcode || "",
            state: locationPicker?.state || "",
            country_code: locationPicker?.country_code || "",
            latitude: locationPicker?.lat || 0,
            longitude: locationPicker?.lng || 0,
            location: {
                lat: locationPicker?.lat || 0,
                lng: locationPicker?.lng || 0,
            },
            extended_data: defaultCustomFields,
            linkedForms: company?.place_linked_forms_template,
        } as Place);
    };

    return {
        createNewPlace,
        initNewPlace,
    };
}