import { useStyletron } from 'baseui';
import { ChangeEvent } from 'react';
import { useRecoilState } from 'recoil';
import { mapIconSizeState } from '../../../states/mapState';
import { HFlex } from '../../containers/HFlex';
import { PinLarge, PinSmall } from '../../ui/svg';

const min = 10;
const max = 60;

export function IconSizeSlider() {
    const [css, theme] = useStyletron();
    const [iconSize, setIconSize] = useRecoilState(mapIconSizeState);

    const onSliderChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setIconSize(Number(value) || iconSize);
    };

    const sliderPercentage = (iconSize - min) * (100 / (max - min));

    const sliderStyles = css({
        MozAppearance: 'none',
        appearance: 'none',
        width: '80%',
        height: '2px',
        background: `linear-gradient(
            to right,
            ${theme.colors.contentPrimary} 0%,
            ${theme.colors.contentPrimary} ${sliderPercentage}%,
            ${theme.colors.borderTransparent} ${sliderPercentage}%,
            ${theme.colors.borderTransparent} 100%
        )`,
        outline: 'none',
        transition: 'background 0.2s',
        cursor: 'pointer',
        '::-webkit-slider-thumb': {
            appearance: 'none',
            MozAppearance: 'none',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            background: theme.colors.backgroundInversePrimary,
            cursor: 'pointer',
        },
    });

    return (
        <HFlex alignCenter>
            <PinSmall size={24} color={theme.colors.contentPrimary}/>
            <input
                type="range"
                min={min}
                max={max}
                value={iconSize}
                onChange={onSliderChange}
                className={sliderStyles}
                id="slider-iconsize"
            />
            <PinLarge size={48} color={theme.colors.contentPrimary}/>
        </HFlex>
    );
}
