import { useRecoilValue, useSetRecoilState } from "recoil";
import { activeTeamTabState } from "../states/viewState";
import React from "react";
import { VFlex } from "../components/containers/VFlex";
import { TeamManagement } from "../components/team/TeamManagement";
import { MembersTable } from "../components/team/table/MembersTable";
import { MemberSearch } from "../components/member/MemberSearch";
import { HFlex } from "../components/containers/HFlex";
import { Plus } from "baseui/icon";
import { Button } from "antd";
import { addingTeamState } from "../states/appState";
import { useTheme } from "../hooks/useTheme";
import { useStrings } from "../hooks/useStrings";
import useAccount from "../hooks/useAccount";

export function TeamView() {
    const activeKey = useRecoilValue(activeTeamTabState);
    const setAddingTeam = useSetRecoilState(addingTeamState);
    const { theme } = useTheme();
    const { strings } = useStrings();
    const { isSelfAdminOrCompany } = useAccount();

    return (
        <VFlex spacing style={{ paddingLeft: "8px", paddingRight: "8px", paddingBlock: "8px" }}>
            <HFlex alignCenter spacing>
                <MemberSearch/>
                {activeKey === "team" && <Button disabled={!isSelfAdminOrCompany} onClick={() => setAddingTeam(true)}>
                    <HFlex alignCenter spacing>
                        <Plus size={16} color={theme.colors.contentPrimary}/>
                        {strings.TeamManagement.NewTeam}
                    </HFlex>
                </Button>}
            </HFlex>
            {activeKey === "members" ? <MembersTable/> : <TeamManagement/>}
        </VFlex>
    );
}