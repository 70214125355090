import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { activeStepState, onboardStepState, onboardVisibleState } from "../../states/onboardState";
import { OnboardStepType } from "../../types/onboard";

export function useOnboardStep(stepType: OnboardStepType) {
    const [activeState, setActiveState] = useRecoilState(activeStepState);
    const [step, setStep] = useRecoilState(onboardStepState(stepType));
    const resetStep = useResetRecoilState(onboardStepState(stepType));
    const onboardVisible = useRecoilValue(onboardVisibleState);

    const prevStep = () => {
        queueMicrotask(() => {
            setStep((prev) => prev - 1);
        });
    };

    const nextStep = () => {
        queueMicrotask(() => {
            setStep((prev) => prev + 1);
        });
    };

    const closeStep = () => {
        setActiveState(undefined);
        resetStep();
    };

    const openStep = () => {
        queueMicrotask(() => {
            setActiveState(stepType);
        });
    };

    const isOpen = stepType === activeState;
    const canTrigger = onboardVisible && activeState;

    return {
        step,
        setStep,
        prevStep,
        nextStep,
        closeStep,
        openStep,
        isOpen,
        canTrigger,
    };
}
