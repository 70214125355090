import React from "react";
import { calcTotalsAndAveragesByMember, MemberStatDay, retrieveMemberUids } from "../../types/stats";
import { Datum, StackedBars } from "../../components/statistics/visx/StackedBars";
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { memberState } from "../../states/membersState";
import { DAY } from "../../utils/time";
import { asNumber } from "../../utils/objects";
import { useStrings } from "../useStrings";
import { ChartPanel } from "../../components/statistics/ChartPanel";

export function useJobTimesCharts({
                                   stats,
                                   fetching,
                                   width
                               }: { stats: MemberStatDay[], fetching: boolean, width: number }) {
    const { strings } = useStrings();
    const memberUids = retrieveMemberUids(stats);

    const [totalTimeBetweenJobs, avgTimeBetweenJobs] = calcTotalsAndAveragesByMember(stats, memberUids, 'totalTimeBetweenJobs', 'avgTimeBetweenJobs');
    const [totalJobOnsiteTime, avgJobOnsiteTime] = calcTotalsAndAveragesByMember(stats, memberUids, 'totalJobOnsiteTime', 'avgJobOnsiteTime');

    const dataByMember: Datum[] = memberUids.map((uid, index) => ({
        bucketField: getRecoil(memberState(uid))?.name || uid,
        totalTimeBetweenJobs: totalTimeBetweenJobs[index],
        avgTimeBetweenJobs: avgTimeBetweenJobs[index],
        totalJobOnsiteTime: totalJobOnsiteTime[index],
        avgJobOnsiteTime: avgJobOnsiteTime[index],
    } as Datum));

    const dataByMemberFiltered = dataByMember
        .filter(d => asNumber(d, 'totalTimeBetweenJobs') < DAY)
        .filter(d => asNumber(d, 'totalTimeBetweenJobs') > 0);

    const dataByMemberSortedByTotal = [...dataByMemberFiltered]
        .sort((d1: Datum, d2: Datum) => {
            return asNumber(d1, 'totalTimeBetweenJobs') - asNumber(d2, 'totalTimeBetweenJobs');
        });

    const dataByMemberSortedByAvg = [...dataByMemberFiltered]
        .sort((d1: Datum, d2: Datum) => {
            return asNumber(d1, 'avgTimeBetweenJobs') - asNumber(d2, 'avgTimeBetweenJobs');
        });

    const rowHeight = 24;
    const rowPad = 100;

    return [
        <ChartPanel title={strings.Stats.AccumulatedTimeAtAndBetweenJobs} key={strings.Stats.AccumulatedTimeAtAndBetweenJobs}>
            <StackedBars width={width}
                         height={dataByMemberSortedByTotal.length * rowHeight + rowPad}
                         data={dataByMemberSortedByTotal}
                         fetching={fetching}
                         categories={["totalTimeBetweenJobs", "totalJobOnsiteTime"]}
                         labels={[strings.Stats.AccumulatedTimeBetweenJobs, strings.Stats.AccumulatedTimeAtJobSites]}
            />
        </ChartPanel>,
        <ChartPanel title={strings.Stats.AvgTimeAtAndBetweenJobs} key={strings.Stats.AvgTimeAtAndBetweenJobs}>
            <StackedBars width={width}
                         height={dataByMemberSortedByAvg.length * rowHeight + rowPad}
                         data={dataByMemberSortedByAvg}
                         fetching={fetching}
                         categories={["avgTimeBetweenJobs", "avgJobOnsiteTime"]}
                         labels={[strings.Stats.AvgTimeBetweenJobs, strings.Stats.AvgTimeAtJobSites]}
            />
        </ChartPanel>
    ];
}