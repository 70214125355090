import { atom, selector } from "recoil";
import { jobsSearchState, jobState } from "./jobsState";
import { searchMatch, sortJobsByHeader } from "../utils/jobUtils";
import { ALL_JOBS_DEFAULT_COLUMNS, ALL_JOBS_DEFAULT_COLUMNS_WIDTH, DEFAULT_SORT_CONFIG } from "../constants/jobs";
import { ColumnsWidthType, Day, Filter } from "../types/core";
import { Job, STATUS_ARCHIVED } from "../types/job";
import { allJobsActiveTabState } from "./viewState";
import { SORT_DIRECTION, SortDirection } from "baseui/table";
import { DAY, dayOfMinus, dayOfToday, dayToMillisBrowserTimezone } from "../utils/time";
import { atomWithPersistence, localStorageEffect } from "../utils/persistence";
import { companyState } from "./accountState";

export const allJobsRequestRoundState = atom({
    key: "jobs.table.requestround",
    default: 0,
});
export const allJobsIdsState = atom({
    key: "jobs.table.ids",
    default: [] as string[],
});
export const allJobsState = selector<Job[]>({
    key: "jobs.table",
    get: ({ get }) => {
        const ids = get(allJobsIdsState);
        const search = get(jobsSearchState);
        const filters = get(allJobsFiltersState);
        const activeTab = get(allJobsActiveTabState);
        const jobs = ids.map(id => get(jobState(id)))
            .filter(job => {
                if (!job) {
                    return false;
                }
                return activeTab === "archived"
                    ? job.status === STATUS_ARCHIVED
                    : job.status !== STATUS_ARCHIVED;
            }) as Job[];
        const sort = get(allJobsSortState);
        const sortConfig = get(allJobsSortConfigState);
        const direction = get(allJobsSortDirectionState);
        const customSortField = get(allJobsCustomSortFieldState);

        const sortedJobs = jobs
            .filter(job => searchMatch(job, search))
            .filter(job => (job.tsCreated > 0 && job.tsCreated > sortConfig.from && job.tsCreated < sortConfig.until)
                || (job.tsSaved > 0 && job.tsSaved > sortConfig.from && job.tsSaved < sortConfig.until)
                || (job.day > 0 && dayToMillisBrowserTimezone(job.day) > sortConfig.from && dayToMillisBrowserTimezone(job.day) < sortConfig.until)
            )
            .filter(job => filters.every(f => f.matches(job)))
            .sort((j1, j2) => sortJobsByHeader(j1, j2, sort, customSortField));
        return direction === SORT_DIRECTION.DESC ? sortedJobs.reverse() : sortedJobs;
    }
});
export const allJobsFiltersState = atom({
    key: "jobs.table.filters",
    default: [] as Filter[],
});
export const allJobsSortConfigState = selector({
    key: "jobs.table.sortconfig",
    get: ({ get }) => {
        const days: [Day, Day] = get(allJobsFromUntilDaysState);
        const activeTab = get(allJobsActiveTabState);
        return {
            ...DEFAULT_SORT_CONFIG,
            status: activeTab === "archived" ? 1 : 0,
            from: dayToMillisBrowserTimezone(days[0]),
            until: dayToMillisBrowserTimezone(days[1]) + DAY,
        }
    }
});

export const LAST_30_DAYS = () => dayOfMinus(30);
export const LAST_6_MONTS = () => dayOfMinus(30 * 6);
export const LAST_30_DAYS_KEY = "30days";
export const LAST_6_MONTS_KEY = "6months";
export const CUSTOM_DATES_KEY = "custom";

export const allJobsFromUntilRangeState = atomWithPersistence<typeof LAST_30_DAYS_KEY | typeof LAST_6_MONTS_KEY | typeof CUSTOM_DATES_KEY>(
    "jobs.table.fromuntil.range",
    LAST_30_DAYS_KEY,
);

export const allJobsFromUntilDaysState = atom<[Day, Day]>({
    key: "jobs.table.fromuntil.day",
    default: selector({
        key: "jobs.table.fromuntil.day.default",
        get: ({ get }) => {
            const selectedRange = get(allJobsFromUntilRangeState);
            if (selectedRange === LAST_30_DAYS_KEY) {
                return [LAST_30_DAYS(), dayOfToday()];
            } else if (selectedRange === LAST_6_MONTS_KEY) {
                return[LAST_6_MONTS(), dayOfToday()];
            } else {
                return[LAST_30_DAYS(), dayOfToday()];
            }
        }
    })
});

export enum JobSort {
    TITLE = 1,
    ASSIGNEE,
    PROGRESS_STATUS,
    ASSIGNED_DATE,
    PRIORITY,
    ADDRESS,
    TEAM,
    CREATED,
    SAVED,
    ORDER,
    NOTES,
    TIME_WINDOW,
    ZONES,
    WORK_TYPE,
    CARRY_OVER,
    ONSITE,
    PICKUP,
    DROPOFF,
    LABELS,
    CUSTOM,
}

export const allJobsSortState = atom({
    key: "jobs.table.sort",
    default: JobSort.TITLE,
});

export const allJobsSortDirectionState = atom<SortDirection>({
    key: "jobs.table.sort.direction",
    default: SORT_DIRECTION.ASC,
});

export const allJobsColumnsState = atomWithPersistence<string[]>("jobs.table.columns", ALL_JOBS_DEFAULT_COLUMNS);

export const allJobsCustomSortFieldState = atom<string | undefined>({
    key: "jobs.table.sort.custom",
    default: undefined,
});

export const allJobsColumsWidthState = atom<ColumnsWidthType>({
    key: "jobs.table.columns.width",
    default: selector({
        key: "jobs.table.columns.width.initializer",
        get: ({ get }) => {
            const customFields = get(companyState)?.job_custom_fields ?? [];
            const customFieldEntries = customFields.map((customField) => [customField, 100]);
            const customFieldsWidth = Object.fromEntries(customFieldEntries); 
            return {
                ...ALL_JOBS_DEFAULT_COLUMNS_WIDTH,
                ...customFieldsWidth,
            }
        }
    }),
    effects: [localStorageEffect]
});

