import { Button } from "baseui/button";
import { NumberedStep, ProgressSteps } from "baseui/progress-steps";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffectOnce } from "usehooks-ts";
import { useReports } from "../../hooks/useReports";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import {
    selectedReportConfigState,
    selectedReportDayWeekState,
    selectedReportFormatState,
    selectedReportMembersState,
    selectedReportPlacesState
} from "../../states/reportState";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { CheckCircledIcon } from "../ui/svg";
import { ReportColumnsStep } from "./ReportColumnsStep";
import { ReportDayWeekStep } from "./ReportDayWeekStep";
import { ReportFilterStep } from "./ReportFilterStep";
import { ReportFormatStep } from "./ReportFormatStep";
import { ReportNameStep } from "./ReportNameStep";
import { ReportNotifyStep } from "./ReportNotifyStep";
import { ReportWeekdaysStep } from "./ReportWeekdaysStep";

export function SetupRecurringReport() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const { getFilterTitle } = useReports();
    const [reportConfig, setReportConfig] = useRecoilState(selectedReportConfigState);
    const hasFilterStep = reportConfig?.canSelectMembers || reportConfig?.canSelectPlaces;
    const [current, setCurrent] = useState(hasFilterStep ? 0 : 1);
    const format = useRecoilValue(selectedReportFormatState);
    const members = useRecoilValue(selectedReportMembersState);
    const places = useRecoilValue(selectedReportPlacesState);
    const [isLoading, setIsLoading] = useState(false);
    const reports = useReports();
    const hasAvailableColumns = !!reportConfig?.availableColumns?.length;
    const dayWeek = useRecoilValue(selectedReportDayWeekState);

    useEffectOnce(() => {
        return reports.cleanup;
    });

    const handleNext = () => {
        setCurrent((prev) => prev + 1);
    }

    const handleCancel = () => {
        setReportConfig(undefined);
    };

    const handleBack = () => {
        if (current === 0 || (current === 1 && !hasFilterStep)) {
            setReportConfig(undefined);
        } else {
            setCurrent((prev) => prev - 1);
        }
    };

    const canContinue = () => {
        if (current === 0) {
            if (reportConfig?.canSelectMembers || reportConfig?.canSelectPlaces) {
                return !!places.length || !!members.length;
            }
            return false;
        }
        return true;
    };

    if (!reportConfig) {
        return null;
    }

    const saveRecurringReport = async () => {
        if (!format) {
            return;
        }
        setIsLoading(true);
        await reports.saveNewRecurringConfig();
        setIsLoading(false);
        setReportConfig(undefined);
    };

    const buttons = <HFlex>
        <Button kind="secondary" size="compact" onClick={handleBack}>
            {strings.General.Back}
        </Button>
        {current !== (hasAvailableColumns ? 6 : 5) && (
            <Button
                kind="primary"
                size="compact"
                onClick={handleNext}
                disabled={!canContinue()}
            >
                {strings.General.Next}
            </Button>
        )}
        {current === (hasAvailableColumns ? 6 : 5) && (
            <Button
                kind="primary"
                size="compact"
                onClick={saveRecurringReport}
                disabled={!format}
                isLoading={isLoading}
            >
                <CheckCircledIcon color={theme.colors.contentInversePrimary} size={16}/>
                <Spacer/> {strings.General.Save}
            </Button>
        )}
    </HFlex>;

    const filterStep = (
        <NumberedStep key="filter" title={getFilterTitle()}>
            <ReportFilterStep onNext={() => handleNext()}/>
            <Spacer/>
            {buttons}
        </NumberedStep>
    );

    const dayWeekStep = (
        <NumberedStep key="dayWeek" title={strings.Reports.SelectTimeFrame}>
            <ReportDayWeekStep/>
            <Spacer/>
            {buttons}
        </NumberedStep>
    );

    const weekdaysStep = (
        <NumberedStep key="weekdays"
                      title={dayWeek === "twoweeks"
                          ? strings.Reports.SelectDayToCreateFirstReport
                          : strings.Reports.SelectDayToCreate}>
            <ReportWeekdaysStep/>
            <Spacer/>
            {buttons}
        </NumberedStep>
    );

    const columnsStep = (
        <NumberedStep key="columns" title={strings.Reports.Columns}>
            <ReportColumnsStep/>
            <Spacer/>
            {buttons}
        </NumberedStep>
    );

    const formatStep = (
        <NumberedStep key="format" title={strings.General.Format}>
            <ReportFormatStep/>
            <Spacer/>
            {buttons}
        </NumberedStep>
    );

    const notifyStep = (
        <NumberedStep key="notify" title={strings.Reports.NotifyPeopleOnCreation}>
            <ReportNotifyStep/>
            <Spacer/>
            {buttons}
        </NumberedStep>
    );

    const nameStep = (
        <NumberedStep key="name" title={strings.General.Title}>
            <ReportNameStep/>
            <Spacer height={"48px"}/>
            {buttons}
        </NumberedStep>
    );

    const steps = hasAvailableColumns ? [
        filterStep,
        dayWeekStep,
        weekdaysStep,
        columnsStep,
        formatStep,
        notifyStep,
        nameStep,
    ] : [
        filterStep,
        dayWeekStep,
        weekdaysStep,
        formatStep,
        notifyStep,
        nameStep,
    ];

    return (
        <VFlex>
            <ProgressSteps
                current={current}
                overrides={{
                    Root: {
                        style: {
                            height: "100%",
                            overflowY: "auto",
                        },
                    },
                }}
            >
                {steps}
            </ProgressSteps>
            <HFlex spacing alignCenter style={{
                marginRight: "8px",
                marginBottom: "8px"
            }}>
                <Button kind="tertiary" size="compact" onClick={handleCancel}>
                    {strings.General.Cancel}
                </Button>
                <SpacerExpand/>
            </HFlex>
        </VFlex>
    );
}
