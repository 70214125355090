import MapboxDraw from '@mapbox/mapbox-gl-draw';
import type { MapRef } from 'react-map-gl';
import { useControl } from 'react-map-gl';
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { editZoneState } from "../../states/zoneState";
import { zonesLayerState } from "../../states/geojsonState";
import { EMPTY_UID, LonLat, Uid } from "../../types/core";
import * as GeoJSON from 'geojson'
import { useDrawMode } from "../../hooks/useDrawMode";
import { DrawMode } from "../../states/drawModeState";

export default function DrawControl({ drawMode }: { drawMode: DrawMode }) {
    const [drawObj] = useState<MapboxDraw>(new MapboxDraw({
        displayControlsDefault: false,
        controls: {}
    }));
    const editZone = useRecoilValue(editZoneState);
    const [showingZone, setShowingZone] = useState<{ id: string, uid: Uid }>({ id: "", uid: EMPTY_UID });
    const zonesLayer = useRecoilValue(zonesLayerState);
    const [ready, setReady] = useState(false);
    const { updateCoordinates, enterDrawMode } = useDrawMode();

    const onUpdateCoordinates = (feat: GeoJSON.Feature | GeoJSON.Feature[]) => {
        const feature = Array.isArray(feat) ? feat[0] : feat;
        if (feature && feature.geometry?.type === "Polygon") {
            const polygon = feature.geometry as GeoJSON.Polygon;
            const coordinates = polygon.coordinates[0];
            updateCoordinates(coordinates as LonLat[]);
        } else if (feature && feature.geometry?.type === "LineString") {
            const linestring = feature.geometry as GeoJSON.LineString;
            const coordinates = linestring.coordinates;
            updateCoordinates(coordinates as LonLat[])
        }
    };
    const onUpdate = (ev: any) => {
        onUpdateCoordinates(ev.features as GeoJSON.Feature[]);
    }
    const onCreate = (ev: any) => {
        onUpdateCoordinates(ev.features[0] as GeoJSON.Feature);
    }
    const onDelete = () => {};
    const onSelectionChange = () => {
        drawObj?.changeMode("simple_select")
    }

    useEffect(() => {
        if (!ready) {
            return;
        }
        if (editZone && editZone.uid !== showingZone.uid) {
            if (showingZone.id) {
                drawObj.delete([showingZone.id]);
            }
            const feat = zonesLayer?.features.find(feat => feat.properties?.uid === editZone.uid);
            if (feat) {
                const ids = drawObj.add(feat);
                setShowingZone({ id: ids[0], uid: editZone.uid });
                drawObj.changeMode("direct_select", { featureId: ids[0] });
            }
        } else if (!editZone && showingZone.id) {
            drawObj.delete([showingZone.id]);
            setShowingZone({ id: "", uid: EMPTY_UID });
        }
    }, [editZone, ready]);

    // FIXME minor issue: DrawControl somehow breaks / disables boxzoom

    useEffect(() => {
        if (!ready) {
            return;
        }
        if (!drawMode) {
            drawObj.deleteAll();
        } else if (drawMode.type === "zone" && drawMode.action === "new") {
            drawObj.changeMode("draw_polygon")
        } else if (drawMode.type === "route" && drawMode.action === "new") {
            drawObj.changeMode("draw_line_string");
        }
    }, [drawMode, ready]);

    useEffect(() => {
        return () => {
            // cleanup
            if (ready) {
                drawObj.deleteAll();
            }
        }
    }, []);

    useControl<MapboxDraw>(
        () => drawObj,
        ({ map }: { map: MapRef }) => {
            map.on('draw.create', onCreate);
            map.on('draw.update', onUpdate);
            map.on('draw.delete', onDelete);
            map.on('draw.selectionchange', onSelectionChange);
            setReady(true);
        },
        ({ map }: { map: MapRef }) => {
            map.off('draw.create', onCreate);
            map.off('draw.update', onUpdate);
            map.off('draw.delete', onDelete);
            setReady(false);
        },
        {}
    );

    return null;
}
