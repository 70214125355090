import { atomFamily, selector, selectorFamily } from 'recoil';
import { Team } from '../types/core';
import { teamsState, teamState } from './teamsState';

const fieldsForTeamEditSelector = selectorFamily<Team, number>({
    key: 'teams.edition.default',
    get: (id) => ({ get }) => {
        const team = get(teamState(id));
        return team ? {
            ...team
        } : {} as Team;
    }
});

export const fieldsForTeamEditState = atomFamily<Team, number>({
    key: 'teams.edition',
    default: fieldsForTeamEditSelector,
});

export const validTeamFieldsState = selectorFamily<{ [key: string]: boolean }, number>({
    key: 'teams.edition.validation',
    get: (id) => ({ get }) => {
        const fields = get(fieldsForTeamEditState(id));
        const validFields = Object.fromEntries(Object.keys(fields).map((key) => [key, true]));

        if (!fields.name) {
            validFields['name'] = false;
        }

        return validFields;
    }
});

export const teamFieldsHasAnyErrorState = selectorFamily<boolean, number>({
    key: 'member.edition.error',
    get: (id) => ({ get }) => {
        const validFields = get(validTeamFieldsState(id));

        return Object.values(validFields).some((valid) => !valid)
    }
});

export const availableTeamIdState = selector({
    key: 'team.available',
    get: ({ get }) => {
        const teamIds = get(teamsState).map((team) => team.id);
        for (let id of teamIds) {
            const newId = id + 1;
            if (!teamIds.includes(newId)) {
                return newId;
            }
        }
        return 1;
    }
});