import { Button } from 'baseui/button';
import { ParagraphXSmall } from 'baseui/typography';
import { useRecoilState, useRecoilValue } from 'recoil';
import { stringsState } from '../../../states/localeState';
import { mapTypeState } from '../../../states/mapState';
import { HFlex } from '../../containers/HFlex';
import { VFlex } from '../../containers/VFlex';
import { MAP_TYPES } from "../../../constants/mapConstants";
import { useTheme } from "../../../hooks/useTheme";

export function MapTypePicker() {
    const {css, theme} = useTheme();
    const [mapType, setMapType] = useRecoilState(mapTypeState);
    const strings = useRecoilValue(stringsState);

    const changeMapType = (newMapType: string) => {
        setMapType(newMapType);
    };

    const mapImageStyles = css({
        width: '56px',
        height: '56px',
        borderRadius: '5px',
    });

    const buttonOverrides = {
        Root: {
            style: {
                paddingTop: '5px',
                paddingRight: '5px',
                paddingBottom: '5px',
                paddingLeft: '5px',
            },
        },
    };

    return (
        <HFlex
            style={{
                justifyContent: 'space-between',
            }}
        >
            {MAP_TYPES.map((map) => (
                <VFlex
                    key={map.mapType}
                    alignCenter
                >
                    <Button
                        onClick={() => changeMapType(map.mapType)}
                        overrides={buttonOverrides}
                        colors={{
                            color: '',
                            backgroundColor:
                                mapType === map.mapType
                                    ? theme.colors.backgroundInversePrimary
                                    : theme.colors.backgroundTertiary,
                        }}
                    >
                        <img
                            src={map.img}
                            loading="lazy"
                            alt=""
                            className={mapImageStyles}
                        />
                    </Button>
                    <ParagraphXSmall marginTop="scale200" marginBottom={0}>
                        {strings.getString(map.labelKey)}
                    </ParagraphXSmall>
                </VFlex>
            ))}
        </HFlex>
    );
}
