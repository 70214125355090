import { Card, Image } from "antd";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { jobsByDayState } from "../../states/jobsState";
import { dayState, editingJobIdState } from "../../states/appState";
import { Job } from "../../types/job";
import { imagesFromJob } from "../../utils/jobUtils";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { Button } from "baseui/button";
import { LabelXSmall } from "baseui/typography";
import { HFlex } from "../containers/HFlex";
import { useTheme } from "../../hooks/useTheme";
import { CSSProperties } from "react";
import { EmptyState } from "../ui/EmptyState";
import { ImageIcon } from "../ui/svg";
import { useStrings } from "../../hooks/useStrings";
import { MemberBadge } from "../badges/MemberBadge";

type ImageInfo = {
    url: string;
    job: Job;
}

export function Uploads() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const day = useRecoilValue(dayState);
    const jobs = useRecoilValue(jobsByDayState(day));
    const setEditingJob = useSetRecoilState(editingJobIdState);
    const images: ImageInfo[] = jobs.flatMap(job => {
        return imagesFromJob(job).map(url => {
            return {
                url: url,
                job: job
            } as ImageInfo
        });
    });
    const gridStyle: CSSProperties = {
        width: '25%',
        textAlign: 'center',
    };

    return (
        <div style={{ overflow: "auto" }}>
            <Card>
                {images.map((image, index) =>
                    <Card.Grid key={index} style={gridStyle}>
                        <Image src={image.url}/>
                        <Spacer/>
                        <Button size={"mini"} kind={"tertiary"} onClick={() => {setEditingJob(image.job.id)}}>
                            {image.job.destinationName}
                        </Button>
                        {image.job.receiverUid && <HFlex>
                            <SpacerExpand/>
                            <LabelXSmall color={theme.colors.contentTertiary}>
                                <MemberBadge uid={image.job.receiverUid}/>
                            </LabelXSmall>
                        </HFlex>}
                    </Card.Grid>
                )}
                {images.length === 0 && (
                    <EmptyState title={strings.General.NoUploadedImages}
                                subtitle={strings.General.NoUploadedImagesDesc}>
                        <ImageIcon size={64} color={theme.colors.contentTertiary}/>
                    </EmptyState>
                )}
            </Card>
        </div>
    );
}