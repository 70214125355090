import { useRecoilValue } from "recoil";
import { stringsState } from "../../states/localeState";
import { PRIO_HIGH, PRIO_LOW, PRIO_NORMAL } from "../../types/job";
import { StyledSelect } from "../ui/StyledSelect";

type Props = {
    priority?: number;
    onPriorityChange: (priority: number) => void;
}

export function JobPrioritySelect({ priority, onPriorityChange }: Props) {
    const strings = useRecoilValue(stringsState);

    const options = [
        { id: PRIO_LOW, label: strings.Job.Low },
        { id: PRIO_NORMAL, label: strings.Job.Normal },
        { id: PRIO_HIGH, label: strings.Job.High },
    ]

    const value = priority ? [{ id: priority }] : [];

    return (
        <StyledSelect
            closeOnSelect
            size="compact"
            options={options}
            value={value}
            openOnClick
            type="select"
            onChange={(params) => onPriorityChange(params.option?.id as number)}
            placeholder={strings.Job.Priority}
            clearable={false}
        />
    );
}