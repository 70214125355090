import { useRecoilValue } from "recoil";
import { useStrings } from "../../../hooks/useStrings";
import { SequenceNameModal } from "./SequenceNameModal";
import { useState } from "react";
import { saveSequence } from "../../../services/api";
import { SavableSequence, Sequence } from "../../../types/sequence";
import { useSequenceUpdater } from "../../../hooks/jobs/useSequenceUpdater";
import { ConfirmModal } from "../../ui/ConfirmModal";
import { useNewSequence } from "../../../hooks/jobs/useNewSequence";
import { Alert } from "antd";
import { useBoard } from "../../../hooks/useBoard";
import { useTheme } from "../../../hooks/useTheme";
import { default as AutoSizer } from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import SequenceItemVirtualized from "./SequenceItemVirtualized";
import { UnorderedList } from "../../containers/UnorderedList";
import { sequenceFilterState, sequencesState } from "../../../states/sequencesState";
import { useListItem } from "../../../hooks/useListItem";

export function SequenceList() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const sequences = useRecoilValue(sequencesState);
    const filter = useRecoilValue(sequenceFilterState);
    const [selectedSequence, setSelectedSequence] = useState<Sequence>();
    const [sequenceToDelete, setSequenceToDelete] = useState<Sequence>();
    const [showNewSequence, setShowNewSequence] = useState(false);
    const { createEmptySequence } = useNewSequence();
    const sequenceUpdater = useSequenceUpdater();
    const { removeSequence } = useBoard();
    const { listItemHeight } = useListItem();

    const onEditSequence = (sequence: Sequence) => {
        setSelectedSequence(sequence);
    }

    const closeEditSequence = () => {
        setSelectedSequence(undefined);
    }

    const onDeleteSequence = (sequence: Sequence) => {
        setSequenceToDelete(sequence);
    }

    const closeDeleteSequence = () => {
        setSequenceToDelete(undefined);
    }

    const editSequenceName = (newSequenceName: string) => {
        if (!selectedSequence) {
            return;
        }
        const savable = { ...selectedSequence, name: newSequenceName } as SavableSequence;
        delete savable.jobs; // avoid writing jobs (also, it's a string array)
        saveSequence(savable)
            .then(({ status, sequence }) => {
                sequenceUpdater.updateSequences();
                closeEditSequence();
            });
    };

    const onDeleteConfirmed = () => {
        if (!sequenceToDelete) {
            return;
        }
        saveSequence({ ...sequenceToDelete, status: 1 } as SavableSequence).then(({ status }) => {
            sequenceUpdater.updateSequences();
            removeSequence(sequenceToDelete.id);
            closeDeleteSequence();
        });
    };

    const closeNewSequence = () => {
        setShowNewSequence(false);
    };

    const filteredSequences = filter
        ? sequences.filter(seq => !filter || seq?.name?.toLowerCase().includes(filter))
        : sequences;
    const sequenceIds = filteredSequences.map(s => s.id);

    return (
        <>
            {sequenceIds.length > 0 && (
                <UnorderedList
                    style={{
                        marginBottom: theme.sizing.scale600,
                        overflowY: "hidden",
                        minHeight: "250px",
                        height: "100%",
                    }}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                height={height}
                                itemCount={sequenceIds.length}
                                itemSize={() => listItemHeight}
                                width={width}
                                itemData={{
                                    ids: sequenceIds,
                                    onEdit: onEditSequence,
                                    onDelete: onDeleteSequence,
                                }}
                            >
                                {SequenceItemVirtualized}
                            </List>
                        )}
                    </AutoSizer>
                </UnorderedList>
            )}
            {sequenceIds.length === 0 && <Alert description={strings.Sequences.SequencesDesc} closable showIcon/>}
            <SequenceNameModal
                isOpen={!!selectedSequence}
                onCancel={closeEditSequence}
                onAccept={editSequenceName}
                name={selectedSequence?.name}
                title={strings.General.Edit}
            />
            <ConfirmModal
                isOpen={!!sequenceToDelete}
                description={sequenceToDelete?.name}
                onCancel={closeDeleteSequence}
                onConfirm={onDeleteConfirmed}
                title={strings.Sequences.DeleteSequenceQuestion}
                confirmText={strings.General.Delete}
                cancelText={strings.General.Cancel}
                danger
            />
            <SequenceNameModal
                isOpen={showNewSequence}
                onCancel={closeNewSequence}
                onAccept={(name) => createEmptySequence(name, closeNewSequence)}
                isNewSequence
            />
        </>
    );
}