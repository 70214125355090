import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { windowSizeState } from "../states/skeletonState";
import { getWindowSize } from "../utils/window";

export function WindowHandler() {
    const setWindowSize = useSetRecoilState(windowSizeState)

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return null;
}