import { useRecoilCallback, useRecoilValue } from "recoil";
import { memberState, memberUidsState } from "../states/membersState";
import { jobIdsState, jobState } from "../states/jobsState";
import { newMemberState, updateAccountState, updateJobsState, updateMembersState } from "../states/updaterState";
import { useState } from "react";
import { STATUS_DELETED } from "../types/job";
import { useUpdateEffect } from "usehooks-ts";
import { allJobsIdsState } from "../states/allJobsState";
import { editingJobIdState } from "../states/appState";
import { editJobHasModifiedState, editJobModifiedState, } from "../states/editJobState";
import { useResetUneditedFields } from "../hooks/jobs/useResetUneditedFields";
import { getRecoil } from "./RecoilAccessProvider";

export function StateUpdateProvider() {
    const memberUids = useRecoilValue(memberUidsState);
    const jobIds = useRecoilValue(jobIdsState);
    const newMember = useRecoilValue(newMemberState);
    const jobsResponse = useRecoilValue(updateJobsState);
    const account = useRecoilValue(updateAccountState);
    const membersResponse = useRecoilValue(updateMembersState);
    const [jobsUpdateCounter, setJobsUpdateCounter] = useState(0);
    const editingJobId = useRecoilValue(editingJobIdState);
    const editJobHasModified = useRecoilValue(editJobHasModifiedState);
    const resetUneditedFields = useResetUneditedFields(editingJobId ?? "");

    const newMemberRecoilCallback = useRecoilCallback(({ set }) => () => {
        if (!newMember) {
            return;
        }
        set(memberState(newMember.uid), newMember);
        set(memberUidsState, [...memberUids, newMember.uid]);
    }, [newMember]);

    const accountRecoilCallback = useRecoilCallback(({ set }) => () => {
        set(memberState(account.uid), account);
    }, [account]);

    const membersRecoilCallback = useRecoilCallback(({ set }) => () => {
        if (membersResponse && membersResponse.length > 0) {
            membersResponse.forEach(a => set(memberState(a.uid), a));
            set(memberUidsState, membersResponse.map(a => a.uid));
        }
    }, [membersResponse]);

    const jobsRecoilCallback = useRecoilCallback(({ set }) => () => {
        if (jobsResponse && jobsResponse.length > 0) {
            const deletedJobIds: string[] = [];
            jobsResponse.forEach(job => {
                set(jobState(job.id), job);
                if (job.id === editingJobId && editJobHasModified) {
                    const excludedFields = Object.keys(getRecoil(editJobModifiedState));
                    resetUneditedFields(job, excludedFields);
                }
                if (job.status === STATUS_DELETED) {
                    deletedJobIds.push(job.id);
                }
            });
            const newIds = jobsResponse.filter(j => !j.isTemplate && !j.isPartOfSequence && j.status !== STATUS_DELETED).map(j => j.id);
            const updatedJobIds = deletedJobIds.length ? jobIds.filter((id) => !deletedJobIds.includes(id)) : jobIds;
            const syncedIds = Array.from(new Set([...updatedJobIds, ...newIds]));
            set(jobIdsState, syncedIds);

            setJobsUpdateCounter(jobsUpdateCounter + 1);

            set(allJobsIdsState, syncedIds);
        }
    }, [jobsResponse]);

    useUpdateEffect(() => newMemberRecoilCallback(), [newMember]);
    useUpdateEffect(() => membersRecoilCallback(), [membersResponse]);
    useUpdateEffect(() => jobsRecoilCallback(), [jobsResponse]);
    useUpdateEffect(() => accountRecoilCallback(), [account]);

    return null;
}