import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { LightningIcon, SunIcon } from "../ui/svg";
import { MiniCard } from "../ui/MiniCard";
import { useTheme } from "../../hooks/useTheme";
import { useRecoilValue } from "recoil";
import { memberNamesState } from "../../states/membersState";
import useAccount from "../../hooks/useAccount";
import { allPlacesState } from "../../states/placeState";
import { useState } from "react";

export function AssistantSamples() {
    const { theme } = useTheme();
    const account = useAccount();
    const members = useRecoilValue(memberNamesState);
    const [memberIdx] = useState(Math.floor(Math.random() * members.length));
    const places = useRecoilValue(allPlacesState);
    const [placeIdx] = useState(Math.floor(Math.random() * places.length));
    const memberName = members[memberIdx]?.name || "John";
    const placeName = places[placeIdx]?.name || account.company?.name || "HQ";
    const address = account.company?.address || places[placeIdx]?.address || "123 Main St"

    return (
        <HFlex spacing={"16px"}>
            <SpacerExpand/>
            <VFlex spacing={"16px"} alignCenter style={{ maxWidth: "260px" }}>
                <SunIcon size={24} color={theme.colors.contentPrimary}/>
                Examples
                <MiniCard>
                    {`Urgent electrical repair needed at #${placeName}, assign to @${memberName}`}
                </MiniCard>
                <MiniCard>
                    {`Pickup at #${placeName} asap, assign @${memberName}`}
                </MiniCard>
                <MiniCard>
                    {`New installation job at ${address}, starting tomorrow at 9am until 12pm`}
                </MiniCard>
                <MiniCard>
                    {`Battery swap, assign to @${memberName} for tomorrow, low prio`}
                </MiniCard>
            </VFlex>
            <VFlex spacing={"16px"} alignCenter style={{ maxWidth: "260px" }}>
                <LightningIcon size={24} color={theme.colors.contentPrimary}/>
                Capabilities
                <MiniCard>
                    Assigning to specific users - e.g. "Assign to Robert"
                </MiniCard>
                <MiniCard>
                    Scheduling for specific times - e.g. "Scheduled 5pm"
                </MiniCard>
                <MiniCard>
                    Indicating priority - e.g. "Urgent, max until 2pm"
                </MiniCard>
                <MiniCard>
                    Identifying locations - e.g. "At 3255 Laguna St"
                </MiniCard>
            </VFlex>
            <SpacerExpand/>
        </HFlex>
    );
}