import { Button } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import React from "react";
import { useRecoilState } from "recoil";
import { boardShowAllUnassignedState } from "../../states/boardState";
import { useStrings } from "../../hooks/useStrings";
import { NoWrapText } from "../containers/NoWrapText";

export function UnassignedAllOrSelected() {
    const { strings } = useStrings();
    const [unassignedShowAll, setUnassignedShowAll] = useRecoilState(boardShowAllUnassignedState);

    return (
        <ButtonGroup size={"mini"} kind={"secondary"} selected={unassignedShowAll ? 1 : 0}>
            <Button onClick={() => {
                setUnassignedShowAll(false);
            }}>
                <NoWrapText>{strings.General.SelectedDay}</NoWrapText>
            </Button>
            <Button onClick={() => {
                setUnassignedShowAll(true);
            }}>
                {strings.Dispatch.AllUnassigned}
            </Button>
        </ButtonGroup>
    );
}