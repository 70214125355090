import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { Button } from "baseui/button";
import React, { ReactNode } from "react";

export function MenuButton({ title, icon, onClick }: { title: string, icon?: ReactNode, onClick: () => void }) {

    return (
        <Button size={"compact"} kind={"tertiary"} onClick={onClick}>
            <HFlex alignCenter style={{ textAlign: "left" }}>
                {icon}
                {icon && <Spacer/>}
                {title}
                <SpacerExpand/>
            </HFlex>
        </Button>
    );
}