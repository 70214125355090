import { useTheme } from "../../hooks/useTheme";

type Props = {
    children: React.ReactNode;
}

export function NoWrapText({ children }: Props) {
    const { css } = useTheme();

    return (
        <div
            className={css({
                whiteSpace: 'nowrap',
                flexGrow: 1,
                overflow: 'hidden',
            })}
        >
            {children}
        </div>
    );
}