import { atom } from 'recoil';
import { LOADING, LOGGED_OUT, READY } from '../constants/directorStatuses';
import { getRecoil } from '../providers/RecoilAccessProvider';
import { updateZonesLayer } from "../services/geojson";
import { loggedInState } from './accountState';

const DIRECTOR_KEY = 'Director';

const directorState = atom({
    key: DIRECTOR_KEY,
    default: '',
    effects: [
        ({ onSet, setSelf }) => {
            onSet((status) => {
                switch (status) {
                    case LOADING:
                        updateZonesLayer();
                        setSelf(READY);
                        break;
                    case READY:
                        break;
                    case LOGGED_OUT:
                        break;
                    default:
                        break;
                }
            });
        },
        // initializing director state
        ({ trigger, setSelf }) => {
            const isLoggedIn = getRecoil(loggedInState);
            if (trigger === 'get' && isLoggedIn) {
                // posting to queue so it can trigger
                queueMicrotask(() => setSelf(LOADING));
            }
        },
    ],
});

export { directorState };
