import { HFlex } from "../containers/HFlex";
import ShowSequenceMenu from "./sequences/ShowSequenceMenu";
import { Spacer } from "../containers/Spacer";
import { JobSearch } from "./JobSearch";
import useAccount from "../../hooks/useAccount";
import { isLocationOnlyPlan } from "../../utils/account";
import { ClosePageButton } from "../skeleton/ClosePageButton";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { dispatchTreeActiveCardState, dispatchTreeCollapsedState } from "../../states/viewState";
import { ChevronRight } from "baseui/icon";
import { cardSizesState, visibleCardsState } from "../../states/skeletonState";
import { PAGE } from "../../constants/pages";
import { CloseIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { useBoard } from "../../hooks/useBoard";

export function DefaultActionRow() {
    const { theme } = useTheme();
    const { company } = useAccount();
    const [collapsed, setCollapsed] = useRecoilState(dispatchTreeCollapsedState);
    const resetCardSizes = useResetRecoilState(cardSizesState(PAGE.DISPATCH));
    const board = useBoard();
    const closeDispatchTree = useResetRecoilState(dispatchTreeActiveCardState);
    const resetDispatchTreeCollapsed = useResetRecoilState(dispatchTreeCollapsedState);
    const { smallIsVisible } = useRecoilValue(visibleCardsState);

    if (isLocationOnlyPlan(company?.stripe_plan)) {
        return null;
    }

    return (
        <HFlex alignCenter style={{ height: "64px" }}>
            <ClosePageButton icon={collapsed ? <ChevronRight/> : undefined} onClose={() => {
                resetCardSizes();
                setCollapsed(old => !old);
            }}/>
            <JobSearch/>
            <Spacer/>
            <ShowSequenceMenu/>
            {smallIsVisible && <ClosePageButton icon={<CloseIcon color={theme.colors.contentPrimary}/>} onClose={() => {
                board.setMemberUids([]);
                board.setSequences([]);
                closeDispatchTree();
                resetDispatchTreeCollapsed();
            }}/>}
        </HFlex>
    );
}