import { sidebarWidthState } from './../states/appState';
import { useRecoilValue } from 'recoil';
import { useRef, useState } from "react";
import { DraggableData, DraggableEvent } from "react-draggable";

export function useDraggable() {
    const [disabled, setDisabled] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null);
    const sidebarWidth = useRecoilValue(sidebarWidthState);

    const dragStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x + sidebarWidth,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    return {
        bounds,
        dragStart,
        draggleRef,
        disabled,
        setDisabled,
    };
}
