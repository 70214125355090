import { Button, KIND, SHAPE, SIZE } from 'baseui/button';
import { useSetRecoilState } from 'recoil';
import { floatingPanelIsOpenState } from '../../states/skeletonState';
import { CloseIcon } from '../ui/svg';
import { StyledCard } from '../ui/StyledCard';
import { useDraggable } from '../../hooks/useDraggable';
import { LabelSmall } from 'baseui/typography';
import { FloatingPanelContent } from "../../views/FloatingPanelContent";
import { useTheme } from "../../hooks/useTheme";
import { useCardTitles } from "./hooks/useCardTitles";
import Draggable from 'react-draggable';

export default function SkeletonFloatingPanel() {
    const { css, theme } = useTheme();
    const setFloatingPanelIsOpen = useSetRecoilState(floatingPanelIsOpenState);
    const { dragStart, bounds, disabled, draggleRef, setDisabled } = useDraggable();
    const { floatingPanelTitle } = useCardTitles();

    const onFloatingPanelClose = () => {
        setFloatingPanelIsOpen(false);
    };

    const floatingPanelStyles = css({
        position: 'absolute',
        textAlign: 'center',
        overflow: 'hidden',
    });

    return (
        <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => dragStart(event, uiData)}
        >
            <div ref={draggleRef} className={floatingPanelStyles}>
                <StyledCard
                    title={
                        <LabelSmall
                            className={css({
                                cursor: "move",
                                userSelect: "none",
                            })}
                            onMouseOver={() => {
                                if (disabled) {
                                    setDisabled(false);
                                }
                            }}
                            onMouseOut={() => {
                                setDisabled(true);
                            }}
                        >
                            {floatingPanelTitle}
                        </LabelSmall>
                    }
                    actions={
                        <Button
                            size={SIZE.mini}
                            shape={SHAPE.circle}
                            onClick={onFloatingPanelClose}
                            kind={KIND.tertiary}
                        >
                            <CloseIcon color={theme.colors.backgroundInversePrimary}/>
                        </Button>
                    }
                >
                    <FloatingPanelContent/>
                </StyledCard>
            </div>
        </Draggable>
    );
}
