import { Checkbox } from 'baseui/checkbox';
import { Search } from 'baseui/icon';
import { Input } from 'baseui/input';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { ChangeEvent, useState } from 'react';
import { useRecoilState } from 'recoil';
import { pickerMembersState } from '../../states/membersPickerState';
import { MemberListItem, Role } from '../../types/member';
import { VFlex } from '../containers/VFlex';
import { MemberItemSimplified } from '../member/MemberItemSimplified';
import { MemberBadges } from "../badges/MemberBadges";
import { TeamsSelect } from "./TeamsSelect";
import { HFlex } from "../containers/HFlex";
import { Team, Uid } from "../../types/core";
import { containsSubstring } from "../../utils/filter";
import { LabelSmall } from "baseui/typography";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { Spacer } from "../containers/Spacer";

type Props = {
    multi?: boolean;
}

export function MemberListPicker({ multi = true }: Props) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const [roles, setRoles] = useState<Role[]>(["worker", "operator", "admin"]);
    const [memberList, setMemberList] = useRecoilState(pickerMembersState);
    const [memberSearch, setMemberSearch] = useState('');
    const hasAny = Boolean(memberList.length);
    const hasAll = hasAny && memberList.every((member) => member.selected);
    const hasSome = hasAny && memberList.some((member) => member.selected);
    const [teamFilter, setTeamFilter] = useState<Team[]>([]);
    const filteredMembers = memberList
        .filter((member) => roles.includes(member.role))
        .filter((member) => containsSubstring(member.name, memberSearch))
        .filter((member) => teamFilter.length === 0 || member.teams.some(t => teamFilter.map(t => t.id).includes(t)));
    const filteredHasAll = filteredMembers.every((member) => member.selected);
    const showingAllMembers = filteredMembers.length === memberList.length;
    const selectedUids = memberList.filter(m => m.selected).map(m => m.uid);

    const toggleAll = ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
        setMemberList((prev) =>
            prev.map((member) => {
                if (!showingAllMembers && filteredMembers.findIndex((m) => m.id === member.id) !== -1) {
                    return {
                        ...member,
                        selected: !filteredHasAll,
                    };
                }
                if (showingAllMembers) {
                    return { ...member, selected: !hasAll };
                }
                return {
                    ...member,
                };
            })
        );
    };

    const toggle = (uid: Uid, checked: boolean) => {
        const updatedList = memberList.map(member => {
            return {
                ...member,
                selected: member.uid === uid ? checked : multi ? member.selected : false,
            }
        });
        setMemberList(updatedList);
    };

    const checkedStyle = {
        backgroundColor: theme.customColors.accent,
        color: "white",
    };

    const uncheckedStyle = {
        color: theme.colors.contentPrimary,
    };

    return (
        <VFlex spacing>
            <HFlex spacing alignCenter>
                <Input
                    value={memberSearch}
                    onChange={(e) => setMemberSearch(e.target.value)}
                    placeholder={strings.General.Filter}
                    clearable
                    size="mini"
                    startEnhancer={<Search/>}
                />
                <TeamsSelect
                    selectedTeams={teamFilter}
                    placeholder={strings.General.FilterByTeam}
                    onSelect={(teams) => {setTeamFilter(teams as Team[])}}
                />
            </HFlex>
            <HFlex alignCenter>
                <LabelSmall style={{ marginRight: "4px" }}>{strings.General.Role + ": "}</LabelSmall>
                <CheckableTag
                    style={roles.length === 3 ? checkedStyle : uncheckedStyle}
                    checked={roles.length === 3}
                    onChange={checked => setRoles(checked ? ["worker", "operator", "admin"] : [])}
                >
                    {strings.General.ShowAll}
                </CheckableTag>
                <Spacer/>
                <CheckableTag
                    style={roles.includes("worker") ? checkedStyle : uncheckedStyle}
                    checked={roles.includes("worker")}
                    onChange={checked => setRoles(old => checked
                        ? [...old, "worker"]
                        : [...old.filter(r => r !== "worker")])}
                >
                    {strings.ProfileModal.Worker}
                </CheckableTag>
                <CheckableTag
                    style={roles.includes("operator") ? checkedStyle : uncheckedStyle}
                    checked={roles.includes("operator")}
                    onChange={checked => setRoles(old => checked
                        ? [...old, "operator"]
                        : [...old.filter(r => r !== "operator")])}
                >
                    {strings.General.Operator}
                </CheckableTag>
                <CheckableTag
                    style={roles.includes("admin") ? checkedStyle : uncheckedStyle}
                    checked={roles.includes("admin")}
                    onChange={checked => setRoles(old => checked
                        ? [...old, "admin"]
                        : [...old.filter(r => r !== "admin")])}
                >
                    {strings.General.Admin}
                </CheckableTag>
            </HFlex>
            {selectedUids.length > 0 && (
                <MemberBadges members={selectedUids}
                              onClose={(uid) => toggle(uid, false)}
                />
            )}
            <TableBuilder
                data={filteredMembers}
                overrides={{
                    Root: {
                        style: {
                            height: '50vh',
                            overflowY: 'auto',
                        },
                    },
                }}
            >
                <TableBuilderColumn
                    overrides={{
                        TableHeadCell: { style: { width: '1%' } },
                        TableBodyCell: { style: { width: '1%' } },
                    }}
                    header={multi &&
                        <Checkbox checked={hasAll} isIndeterminate={!hasAll && hasSome} onChange={toggleAll}/>}
                >
                    {(member: MemberListItem) => (
                        <Checkbox name={member.id} checked={member.selected} onChange={({ target }) => {
                            toggle(target.name as Uid, target.checked);
                        }}/>
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn header={strings.General.Member}>
                    {(member: MemberListItem) => <MemberItemSimplified uid={member.uid} role={true}/>}
                </TableBuilderColumn>
            </TableBuilder>
        </VFlex>
    );
}
