import { HFlex } from "../containers/HFlex";
import { Spacer } from "../containers/Spacer";
import { GlobalDayFramePicker } from "../pickers/GlobalDayFramePicker";
import { useRecoilState } from "recoil";
import { selectedMultiMemberState } from "../../states/appState";
import { MembersPickerModalContent } from "../member/modal/MembersPickerModalContent";
import { StyledModal } from "../ui/StyledModal";
import React, { useState } from "react";
import { Button } from "baseui/button";
import { FilterIcon, RemoveIcon } from "../ui/svg";
import { MemberBadges } from "../badges/MemberBadges";
import { useTheme } from "../../hooks/useTheme";

export function StatisticsTitleBar() {
    const { theme } = useTheme();
    const [members, setMembers] = useRecoilState(selectedMultiMemberState);
    const [pickingMembers, setPickingMembers] = useState(false);

    const memberUids = members.map(m => m.uid);
    const onCloseModal = () => setPickingMembers(false);

    return (
        <HFlex alignCenter spacing>
            <GlobalDayFramePicker/>
            <Button kind={"secondary"} size={"mini"} onClick={() => setPickingMembers(true)}>
                <FilterIcon size={16} color={theme.colors.contentPrimary}/>
                <Spacer/>
                {memberUids.length > 0 && (
                    <MemberBadges members={memberUids}/>
                )}
            </Button>
            {members.length > 0 &&
                <Button kind={"tertiary"} size={"mini"} onClick={() => setMembers([])}>
                    <RemoveIcon size={16} color={theme.colors.contentPrimary}/>
                </Button>
            }
            <StyledModal onClose={onCloseModal} isOpen={pickingMembers}>
                <MembersPickerModalContent
                    closeModal={onCloseModal}
                    onAccept={async (members) => {
                        setMembers(members);
                    }}
                    multi
                />
            </StyledModal>
        </HFlex>
    );
}