import { LINE_WIDTH } from "../../constants/timeline";
import { useTheme } from "../../hooks/useTheme";
import { COLOR_MOVING, COLOR_STEELGRAY } from "../../constants/colors";

export function Line({ primary, hovering, height }: { primary?: boolean, hovering?: boolean, height?: string }) {
    const { theme, isDarkTheme } = useTheme();

    const color = isDarkTheme ? COLOR_STEELGRAY : theme.colors.backgroundAlwaysDark;

    const background = hovering ? {
        background: `linear-gradient(to bottom, ${color}, ${COLOR_MOVING}, ${color})`,
    } : {
        backgroundColor: primary ? color : color + "55",
    };

    return (
        <div style={{
            ...background,
            width: (LINE_WIDTH) + 'px',
            flexGrow: height ? 0 : 1,
            height,
        }}/>
    );
}