import { Button } from "baseui/button";
import { LabelIcon } from "../ui/svg";
import { Tooltip } from "antd";
import { Label } from "../../types/core";

export function JobLabelBadge({ label }: { label: Label }) {

    return (
        <Tooltip title={label.text}>
            <Button size={"mini"} kind={"tertiary"} overrides={
                {
                    BaseButton: {
                        style: {
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                        },
                    },
                }
            }>
                <LabelIcon size={12} color={label.color}/>
            </Button>
        </Tooltip>
    );
}