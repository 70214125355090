import { Button } from "baseui/button";
import { CheckboxCheckedIcon, CheckboxUncheckedIcon, PlaceFillIcon } from "../ui/svg";
import { Centered } from "../containers/Centered";
import { VFlex } from "../containers/VFlex";
import { LabelSmall, ParagraphXSmall } from "baseui/typography";
import React from "react";
import { useCamera } from "../../hooks/useCamera";
import { useUnit } from "../../hooks/useUnit";
import { useTheme } from "../../hooks/useTheme";
import { usePlaceSelection } from "../../hooks/places/usePlaceSelection";
import { Place } from "../../types/place";
import { useSetRecoilState } from "recoil";
import { editingPlaceIdState } from "../../states/appState";
import { TeamsBadges } from "../badges/TeamsBadges";
import { StyledCell, StyledRow } from "baseui/table";
import { EllipsedText } from "../ui/EllipsedText";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { TimeBadge } from "../badges/TimeBadge";

type Props = {
    data: Place[];
    index: number;
    style: React.CSSProperties;
};

export function PlaceRow({ data, index, style }: Props) {
    const place = data[index];
    const { theme } = useTheme();
    const setEditingPlace = useSetRecoilState(editingPlaceIdState);
    const camera = useCamera();
    const unit = useUnit();
    const { toggleSelected, isSelected, tableMultiSelect } = usePlaceSelection();
    const { gridStyle, placesColumnStyle } = useStyleOverrides();

    const handlePlaceClick = () => {
        camera.zoomTo({ lat: place.latitude, lng: place.longitude });
        setEditingPlace(place.uid);
    }

    if (!place) {
        return null;
    }

    return (
        <StyledRow role="row" style={
            {
                ...style,
                ...gridStyle(isSelected(place.uid))
            }
        }>
            <StyledCell $style={{ ...placesColumnStyle[0], justifyContent: "center" }}>
                <span
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        if (event.shiftKey && tableMultiSelect(place, data)) {
                            return;
                        }
                        toggleSelected(place.uid);
                    }}
                >
                    <Button
                        size="mini"
                        kind="tertiary"
                    >
                        {isSelected(place.uid)
                            ? <CheckboxCheckedIcon size={18} color={theme.colors.contentPrimary}/>
                            : <CheckboxUncheckedIcon size={18} color={theme.colors.contentPrimary}/>
                        }
                    </Button>
                </span>
            </StyledCell>

            <StyledCell $style={placesColumnStyle[1]}>
                <Centered>
                    <PlaceFillIcon size={18} color={place.color}/>
                </Centered>
            </StyledCell>

            <StyledCell $style={placesColumnStyle[2]}>
                <Button size={"mini"} kind={"tertiary"} onClick={handlePlaceClick} style={{ overflow: "hidden" }}>
                    <VFlex style={{ overflow: "hidden" }}>
                        <LabelSmall style={{ textAlign: "start" }}>
                            <EllipsedText inline breakAll>{place.name}</EllipsedText>
                        </LabelSmall>
                        <ParagraphXSmall marginTop={0} marginBottom={0} style={{ textAlign: "start" }}>
                            <EllipsedText maxLines={place.teams.length ? 1 : 2}>{place.address}</EllipsedText>
                        </ParagraphXSmall>
                        {place.teams.length > 0 && <TeamsBadges inline shortNames={place.teams.length > 2} ids={place.teams} max={2}/>}
                    </VFlex>
                </Button>
            </StyledCell>

            <StyledCell $style={placesColumnStyle[3]}>
                {unit.distanceToString(place.radius)}
            </StyledCell>

            <StyledCell $style={placesColumnStyle[4]}>
                <TimeBadge startMillis={place.created} withDate/>
            </StyledCell>
        </StyledRow>
    )
}