import length from "@turf/length";
import along from "@turf/along";
import { TrackEntry, TrackEntryAndData, TrackOrStopEntry } from "../types/track";
import { TimelineEntry } from "../types/timeline";
import { Gps } from "../types/core";

export function pointsAlongLineStrings(layers: GeoJSON.FeatureCollection[], progress: number, filterUid?: string | false) {
    return layers.flatMap(layer => {
            if (!layer || !layer.features) {
                return [];
            }
            return layer.features
                .filter(feat => feat.geometry.type === "LineString" && feat.geometry.coordinates.length > 0)
                .filter(feat => !filterUid || feat.properties?.uid === filterUid)
                .map(feat => featureAlongLineString(feat, progress))
                .filter(p => p && p.lat && p.lng);
        }
    )
}

export function featureAlongLineString(feat: any, progress: number) {
    const lineString = feat.geometry as any;
    const km = length(feat);
    const point = along(lineString, km * progress);
    return {
        lat: point.geometry.coordinates[1],
        lng: point.geometry.coordinates[0],
        properties: feat.properties,
    };
}

export function retrieveTracksFromGeojson(layer: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined): TrackEntryAndData[] {
    const trackFeatures = layer ? layer.features?.filter(feat => feat.geometry.type === "LineString") : [];
    return trackFeatures?.map(feat => {
        return {
            entry: feat.properties as TrackEntry,
            data: "coordinates" in feat.geometry ? feat.geometry.coordinates as [] : [],
        } as TrackEntryAndData;
    });
}

export function retrieveStopsFromGeojson(layer: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined): TrackOrStopEntry[] {
    const trackFeatures = layer ? layer.features?.filter(feat => feat?.properties?.kind === "stop") : [];
    return trackFeatures?.map(feat => feat.properties as TrackOrStopEntry);
}

export function retrieveTrackEntriesFromGeojson(layer: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined): TrackEntry[] {
    const trackFeatures = layer ? layer.features?.filter(feat => feat.properties?.kind === "track") : [];
    return trackFeatures?.map(feat => feat.properties as TrackEntry);
}

export function retrieveTimelineEntriesFromGeojson(layer: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined) {
    return layer && layer.features ? layer.features.map(feat => feat.properties as TimelineEntry) : [];
}

export function retrieveTrackEntriesOrStopsFromGeojson(layer: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined): TrackOrStopEntry[] {
    const features = layer?.features?.filter(feat => feat.properties?.kind === "track" || feat.properties?.kind === "stop") || [];
    return  features?.map(feat => feat.properties as TrackOrStopEntry);
}

export function retrieveGpsFromGeojson(layer: GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined): TrackOrStopEntry[] {
    const features = layer?.features?.filter(feat => feat.properties?.kind === "wp") || [];
    return features?.map(feat => {
        const point = feat.geometry as GeoJSON.Point;
        const gps: Gps = {
            lat: point.coordinates[1],
            lng: point.coordinates[0],
            ts: feat.properties?.ts || 0,
            speed: feat.properties?.speed || 0,
            accuracy: 0,
            head: 0,
        };
        return {
            kind: "waypoint",
            uid: "wp" + gps.ts,
            startTs: gps.ts * 1000,
            startLng: gps.lng,
            startLat: gps.lat,
            waypoint: gps,
            title: feat.properties?.title,
            color: feat.properties?.color,
        } as TrackOrStopEntry;
    });
}