import { useRecoilValue } from "recoil";
import React from "react";
import { zoneState } from "../../states/zoneState";
import { Tag, Tooltip } from "antd";
import { useZoneSelection } from "../../hooks/zones/useZoneSelection";
import { getTruncatedZoneName } from "../../types/zone";
import { ZoneIcon } from "../ui/svg";
import { HFlex } from "../containers/HFlex";
import { COLOR_WHITE } from "../../constants/colors";
import { Uid } from "../../types/core";

type Props = {
    uid: Uid;
    closable: boolean;
    style?: any
}

export function ZoneBadge({ uid, closable, style }: Props) {
    const zone = useRecoilValue(zoneState(uid));
    const { toggleSelected } = useZoneSelection();
    if (!zone) {
        return null;
    }
    return (
        <Tooltip title={
            <HFlex alignCenter spacing>
                <ZoneIcon size={14} color={COLOR_WHITE}/>
                {zone.name}
            </HFlex>
        }>
            <Tag key={uid} style={style} color={zone.color} closable={closable} onClose={() => toggleSelected(uid)}>
                {getTruncatedZoneName(zone)}
            </Tag>
        </Tooltip>
    );
}