import { HFlex } from "../containers/HFlex";
import { LabelXSmall } from "baseui/typography";
import { SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { useUnit } from "../../hooks/useUnit";
import { TrackEntry } from "../../types/track";
import { useTime } from "../../hooks/useTime";
import { Badge } from "antd";
import { MemberBadge } from "../badges/MemberBadge";
import { memo } from "react";
import { TrackSpeedHistogram } from "../histogram/TrackSpeedHistogram";
import { useTheme } from "../../hooks/useTheme";
import { LabeledField } from "./LabeledField";
import { useStrings } from "../../hooks/useStrings";

export const TrackInfoBubble = memo(function ({ track, width = 200 }: { track: TrackEntry, width?: number }) {
    const { strings } = useStrings();
    const unit = useUnit();
    const time = useTime();
    const { theme } = useTheme();

    if (!track) {
        return null;
    }

    return (
        <VFlex spacing padding style={
            {
                width: width + "px",
                borderStyle: "solid",
                borderRadius: "4px",
                borderWidth: "1px",
                borderColor: theme.colors.contentTertiary,
                backgroundColor: theme.colors.backgroundSecondary
            }
        }
        >
            <HFlex>
                <HFlex alignCenter>
                    <Badge color={"#000000"} style={{ color: "white" }} count={track.startLetter}/>
                    <LabelXSmall>—</LabelXSmall>
                    <Badge color={"#000000"} style={{ color: "white" }} count={track.endLetter}/>
                </HFlex>
                <SpacerExpand/>
                <MemberBadge uid={track.account}/>
            </HFlex>
            <HFlex>
                <LabelXSmall>{track.timeText}</LabelXSmall>
                <SpacerExpand/>
                <LabelXSmall>{time.formatDuration(track.endTs - track.startTs)}</LabelXSmall>
            </HFlex>
            <HFlex>
                <LabelXSmall>{time.formatDateOnly(track.startTs)}</LabelXSmall>
                <SpacerExpand/>
                <LabelXSmall>{unit.distanceToString(track.distance)}</LabelXSmall>
            </HFlex>
            <TrackSpeedHistogram entry={track} width={width}/>
            {track.purpose &&
                <LabeledField label={strings.General.Purpose} content={track.purpose} direction="horizontal"/>}
            {track.comment &&
                <LabeledField label={strings.General.Comment} content={track.comment} direction="horizontal"/>}
        </VFlex>
    );
});