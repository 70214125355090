import { LabelSmall, ParagraphSmall } from 'baseui/typography';
import { useTheme } from '../../hooks/useTheme';
import { Clickable } from '../containers/Clickable';
import { HFlex } from '../containers/HFlex';
import { VFlex } from '../containers/VFlex';
import { RemoveIcon } from '../ui/svg';

type Props = {
    reasons?: string[];
    onRemove?: (index: number) => void;
};

export function ReasonList({ reasons, onRemove }: Props) {
    const { theme, css } = useTheme();

    if (!reasons?.length) {
        return null;
    }

    return (
        <VFlex style={{ rowGap: theme.sizing.scale600, marginBottom: theme.sizing.scale600 }}>
            {reasons.map((reason, index) => {
                return (
                    <HFlex
                        key={index}
                        spacing
                        style={{
                            alignItems: 'flex-start',
                        }}
                    >
                        <div
                            className={css({
                                display: 'flex',
                                columnGap: theme.sizing.scale600,
                                rowGap: theme.sizing.scale300,
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                [theme.mediaQuery.medium]: {
                                    flexDirection: 'row',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                },
                            })}
                        >
                            <LabelSmall
                                overrides={{
                                    Block: {
                                        style: {
                                            lineHeight: theme.sizing.scale700,
                                        },
                                    },
                                }}
                            >
                                {`#${index+1}`}
                            </LabelSmall>
                            <ParagraphSmall margin={0}>{reason}</ParagraphSmall>
                        </div>
                        {onRemove && (
                            <Clickable onClick={() => onRemove(index)}>
                                <RemoveIcon size={14} color={theme.colors.contentPrimary}/>
                            </Clickable>
                        )}
                    </HFlex>
                );
            })}
        </VFlex>
    );
}
