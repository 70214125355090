import { Button } from "baseui/button";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useJobForms } from "../../../hooks/jobs/useJobForms";
import { useModal } from "../../../hooks/useModal";
import { useStyleOverrides } from "../../../hooks/useStyleOverrides";
import { SpacerExpand } from "../../containers/Spacer";
import { TrashIcon } from "../../ui/svg";
import { ActionButton } from "../../buttons/ActionButton";
import { useTheme } from "../../../hooks/useTheme";
import { useStrings } from "../../../hooks/useStrings";
import { useState } from "react";
import { ConfirmModal } from "../../ui/ConfirmModal";
import { useSequenceSaver } from "../../../hooks/jobs/useSequenceSaver";
import { editJobState } from "../../../states/editJobState";
import { editingJobIdState } from "../../../states/appState";
import { useAllJobsSelection } from "../../../hooks/jobs/useAllJobsSelection";

export function SequenceModalFooter() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const { isLoading, canSubmit, isNewJob, hasChanges, updateSequence } = useJobForms();
    const { closeModal } = useModal();
    const { cancelButtonStyles } = useStyleOverrides();
    const [showDeleteConfirm, setDeleteShowConfirm] = useState(false);
    const saver = useSequenceSaver();
    const jobId = useRecoilValue(editingJobIdState);
    const resetEditingJob = useResetRecoilState(editingJobIdState);
    const fields = useRecoilValue(editJobState(jobId || ''));
    const resetFields = useResetRecoilState(editingJobIdState);
    const selection = useAllJobsSelection();

    const onDeleteConfirmed = async () => {
        if (fields?.sequenceId && jobId) {
            await saver.removeJobFromSequence(fields?.sequenceId, [jobId]);
            selection.clearSelection();
        }
        setDeleteShowConfirm(false);
        resetEditingJob();
        resetFields();
    };

    return (
        <>
            <ActionButton
                tooltip={strings.General.Delete}
                onClick={() => setDeleteShowConfirm(true)}
                size='compact'
                kind='tertiary'

            >
                <TrashIcon color={theme.colors.warning} size={16}/>
            </ActionButton>
            <SpacerExpand/>
            {hasChanges && (
                <Button
                    onClick={closeModal}
                    size='compact'
                    kind='tertiary'
                    overrides={cancelButtonStyles}
                >
                    {strings.General.Cancel}
                </Button>
            )}
            <Button
                onClick={hasChanges ? updateSequence : closeModal}
                size='compact'
                isLoading={isLoading}
                disabled={!canSubmit && hasChanges}
            >
                {hasChanges || isNewJob ? strings.General.Save : strings.General.OK}
            </Button>
            <ConfirmModal
                isOpen={showDeleteConfirm}
                description={fields?.destinationName}
                onCancel={() => setDeleteShowConfirm(false)}
                onConfirm={onDeleteConfirmed}
                title={strings.Job.ReallyDeleteThisJob}
                confirmText={strings.General.Delete}
                cancelText={strings.General.Cancel}
                danger
            />
        </>
    );
}