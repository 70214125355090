import { Button } from 'baseui/button';
import { Checkbox } from 'baseui/checkbox';
import { FormControl } from 'baseui/form-control';
import { LabelSmall, ParagraphSmall } from 'baseui/typography';
import { ChangeEvent, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { editingMemberIdState } from '../../../states/appState';
import { editMemberState } from '../../../states/editMemberState';
import { memberState } from '../../../states/membersState';
import { teamState } from '../../../states/teamsState';
import { dateToTime } from '../../../utils/time';
import { FlexGridLayout } from '../../containers/FlexGridLayout';
import { SectionedWrapper } from '../../containers/SectionedWrapper';
import { StyledTimePicker } from '../../pickers/StyledTimePicker';
import { FormInput } from '../../ui/FormInput';
import { Section } from '../../ui/Section';
import { StaticMap } from "../../staticmap/StaticMap";
import { useEditMemberRouting } from '../../../hooks/members/useEditMemberRouting';
import { useTheme } from "../../../hooks/useTheme";
import { useStrings } from "../../../hooks/useStrings";
import useAccount from "../../../hooks/useAccount";
import { StyledSelect } from '../../ui/StyledSelect';
import { CreatableTagsInput } from "../../ui/CreatableTagsInput";

export function RoutingTab() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const editingMember = useRecoilValue(editingMemberIdState);
    const member = useRecoilValue(memberState(editingMember));
    const [memberFields, setMemberFields] = useRecoilState(editMemberState(editingMember));
    const usingTeamLocation = memberFields.route_start_lat === 0 && memberFields.route_start_lng === 0 && memberFields.route_end_lat === 0 && memberFields.route_end_lng === 0;
    const [isIndividualLocation, setIsIndividualLocation] = useState(!usingTeamLocation);
    const { initEditRouting } = useEditMemberRouting();
    const hasTeams = memberFields?.teams && memberFields.teams.length > 0;
    const team = useRecoilValue(teamState(hasTeams ? memberFields.teams[0] : -1));
    const teamHasLocation = team && !!team?.route;
    const { company } = useAccount();

    if (!member) {
        return null;
    }

    const onChangeInput = (key: string, value: string | string[] | number) => {
        setMemberFields((prev) => ({ ...prev, [key]: value }));
    };

    const handleIndividualLocation = (event: ChangeEvent<HTMLInputElement>) => {
        const isIndividual = event.target.checked;
        setIsIndividualLocation(isIndividual);
        onChangeInput('route_start_lat', isIndividual ? company?.location.lat || member.location.lat : 0);
        onChangeInput('route_start_lng', isIndividual ? company?.location.lng || member.location.lng : 0);
        onChangeInput('route_end_lat', isIndividual ? company?.location.lat || member.location.lat : 0);
        onChangeInput('route_end_lng', isIndividual ? company?.location.lng || member.location.lng : 0);
    }

    const startLocationIsSet = !!memberFields.route_start_lat && !!memberFields.route_start_lng;
    const endLocationIsSet = !!memberFields.route_end_lat && !!memberFields.route_end_lng;

    const startEndLocationsAreSet = startLocationIsSet && endLocationIsSet;

    return (
        <SectionedWrapper>
            <Section title="" noMarginTop>
                <FlexGridLayout>
                    <FormControl
                        label={strings.Routing.RoutingTravelMode}
                        caption={strings.Routing.TravelModeDesc}
                    >
                        <StyledSelect
                            closeOnSelect
                            size="compact"
                            options={[
                                { label: strings.TravelModes.Car, id: 'car' },
                                { label: strings.TravelModes.Bicycle, id: 'bicycle' },
                                { label: strings.TravelModes.Pedestrian, id: 'pedestrian' },
                                { label: strings.TravelModes.Truck, id: 'truck' },
                                { label: strings.TravelModes.Van, id: 'van' },
                                { label: strings.TravelModes.Motorcycle, id: 'motorcycle' },
                            ]}
                            value={[{ id: memberFields.travel_mode || 'car' }]}
                            openOnClick
                            type="select"
                            clearable={false}
                            onChange={({ option }) => onChangeInput('travel_mode', option?.id || '')}
                        />
                    </FormControl>
                    <FormInput
                        label={strings.Vehicles.VehicleCapacity}
                        caption={strings.Routing.LoadCapacityDesc}
                        value={memberFields.vehicle_capacity}
                        field="vehicle_capacity"
                        type="number"
                        onChange={onChangeInput}
                        min={0}
                    />
                </FlexGridLayout>
                <FlexGridLayout>
                    <FormControl label={strings.Routing.RoutingRouteStartTime}>
                        <StyledSelect
                            closeOnSelect
                            size="compact"
                            options={[
                                { label: strings.Routing.RoutingUseWorkingHours, id: 'workingHours' },
                                { label: strings.Routing.RoutingUseFixedHours, id: 'fixedHours' },
                            ]}
                            value={[{ id: memberFields.route_start_time === -1 ? 'workingHours' : 'fixedHours' }]}
                            openOnClick
                            type="select"
                            clearable={false}
                            onChange={() => onChangeInput('route_start_time', memberFields.route_start_time === -1 ? 600 : -1)}
                        />
                    </FormControl>
                    {memberFields.route_start_time !== -1 && (
                        <FormControl
                            label={`${strings.formatString(strings.Routing.XStartsRouteAt, memberFields.name)}`}>
                            <StyledTimePicker
                                value={memberFields.route_start_time}
                                onChange={(date) => onChangeInput('route_start_time', date ? dateToTime(date) : 0)}
                            />
                        </FormControl>
                    )}
                </FlexGridLayout>
                <FormControl
                    label={strings.Routing.StartEndRouteLocation}
                    caption={
                        !isIndividualLocation &&
                        hasTeams && (
                            <ParagraphSmall color={theme.colors.contentSecondary} marginTop={0}>
                                {strings.Routing.RoutingTeamUsed}
                            </ParagraphSmall>
                        )
                    }
                >
                    <Checkbox
                        checked={isIndividualLocation}
                        onChange={handleIndividualLocation}
                    >
                        {strings.Routing.IndividualRouteStartEndLocation}
                    </Checkbox>
                </FormControl>
                {!startEndLocationsAreSet && !hasTeams && !isIndividualLocation && (
                    <>
                        <ParagraphSmall color={theme.colors.contentSecondary} marginTop={0}>
                            {strings.Routing.RoutingNothingSetDesc}
                        </ParagraphSmall>
                        <LabelSmall marginBottom={theme.sizing.scale600}>
                            {strings.Routing.RoutingNothingSet}
                        </LabelSmall>
                    </>
                )}
                {(startEndLocationsAreSet || isIndividualLocation) && (
                    <FlexGridLayout>
                        <>
                            {startLocationIsSet && (
                                <StaticMap location={{
                                    lat: memberFields.route_start_lat,
                                    lng: memberFields.route_start_lng,
                                }} onClick={() => initEditRouting('start')}/>
                            )}
                            <Button
                                onClick={() => initEditRouting('start')}
                                kind="secondary"
                                size="mini"
                            >
                                {strings.Routing.StartLocation}
                            </Button>
                        </>
                        <>
                            {endLocationIsSet && (
                                <StaticMap location={{
                                    lat: memberFields.route_end_lat,
                                    lng: memberFields.route_end_lng,
                                }} onClick={() => initEditRouting('end')}/>
                            )}
                            <Button
                                onClick={() => initEditRouting('end')}
                                kind="secondary"
                                size="mini"
                            >
                                {strings.Routing.EndLocation}
                            </Button>
                        </>
                    </FlexGridLayout>
                )}
                {teamHasLocation && team.route && !isIndividualLocation && (
                    <StaticMap location={{
                        lat: team.route.start_lat,
                        lng: team.route.start_lng,
                    }}/>
                )}
                <FormControl label={" "}>
                    <CreatableTagsInput
                        label={strings.Routing.Skills}
                        caption={strings.Routing.SkillsCaption}
                        tags={memberFields.skills || []}
                        onTagsChange={(tags) => {
                            onChangeInput("skills", tags);
                        }}
                    />
                </FormControl>
            </Section>
        </SectionedWrapper>
    );
}
