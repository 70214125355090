import { useRecoilValue, useSetRecoilState } from "recoil";
import { editJobState } from "../../../states/editJobState";
import { editingJobIdState } from "../../../states/appState";
import { Labeled } from "../../containers/Labeled";
import { Job } from "../../../types/job";
import { Textarea } from "baseui/textarea";
import { Input } from "baseui/input";

type Props = {
    label: string,
    field: string,
    val: string | number,
    type: "number" | "text",
    readonly?: boolean
    textArea?: boolean
    labelWidth?: number
};

export function StaffInput({
                               label,
                               field,
                               val,
                               type,
                               readonly = false,
                               textArea = false,
                               labelWidth
                           }: Props) {
    const jobId = useRecoilValue(editingJobIdState);
    const setFields = useSetRecoilState(editJobState(jobId || ''));

    const onChangeInput = (key: string, value: string | number) => {
        setFields((prev) => ({ ...prev, [key]: value } as Job));
    };

    return (
        <Labeled label={label} labelWidth={labelWidth}>
            {textArea ?
                <Textarea
                    value={val}
                    size={"mini"}
                    type={type}
                    onChange={(ev) => onChangeInput(field, ev.target.value)}
                    readOnly={readonly}
                /> : <Input
                    value={val}
                    size={"mini"}
                    type={type}
                    onChange={(ev) => onChangeInput(field, ev.target.value)}
                    readOnly={readonly}
                />}
        </Labeled>
    );
}