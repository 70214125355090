import { useRecoilState } from "recoil";
import { statsSortDirectionState, statsSortState } from "../states/statsState";
import { getNextDirection } from "../utils/sort";

export function useStatsSort() {
    const [direction, setDirection] = useRecoilState(statsSortDirectionState)
    const [statsSort, setStatsSort] = useRecoilState(statsSortState);

    const handleSort = (sortNumber: number) => {
        const nextDirection = getNextDirection(direction);
        setStatsSort(sortNumber);
        setDirection(nextDirection);
    };

    const getDirection = (sortNumber: number) => {
        return statsSort === sortNumber ? direction : null;
    }

    return {
        handleSort,
        getDirection,
    };
}
