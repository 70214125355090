import { useRecoilValue } from "recoil";
import { stringsState } from "../../states/localeState";
import { StyledSelect } from "../ui/StyledSelect";

type Props = {
    carryOver?: number;
    onCarryOverChange: (carryOver: number) => void;
}

export function JobCarryOverSelect({ carryOver, onCarryOverChange }: Props) {
    const strings = useRecoilValue(stringsState);

    const options = [
        { id: 1, label: strings.Job.CarryOver },
        { id: 0, label: strings.Job.NoCarryOver },
    ]

    const value = carryOver !== undefined ? [{ id: carryOver }] : [];

    return (
        <StyledSelect
            closeOnSelect
            size="compact"
            options={options}
            value={value}
            openOnClick
            type="select"
            onChange={(params) => onCarryOverChange(params.option?.id as number)}
            placeholder={strings.Job.CarryOverQuestion}
            clearable={false}
        />
    )
}