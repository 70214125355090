import { useRecoilValue, useSetRecoilState } from "recoil";
import React from "react";
import { placeState } from "../../states/placeState";
import { getTruncatedPlaceName } from "../../types/place";
import { Tag, Tooltip } from "antd";
import useAccount from "../../hooks/useAccount";
import { COLOR_COMPANY, COLOR_WHITE } from "../../constants/colors";
import { HFlex } from "../containers/HFlex";
import { EditIcon } from "../ui/svg";
import { editingPlaceIdState } from "../../states/appState";
import { Clickable } from "../containers/Clickable";
import { Uid } from "../../types/core";

type Props = {
    uid: Uid;
    onClose?: () => void;
    style?: any;
}

export function PlaceBadge({ uid, onClose, style }: Props) {
    const place = useRecoilValue(placeState(uid));
    const { company } = useAccount();
    const editPlace = useSetRecoilState(editingPlaceIdState);

    if (company?.uid === uid) {
        return (
            <Tag color={COLOR_COMPANY}>
                {company.name}
            </Tag>
        );
    }
    if (!place) {
        return null;
    }
    return (
        <Tooltip
            title={
                <HFlex alignCenter spacing>
                    {place.name}
                    <Clickable onClick={(event) => {
                        event.preventDefault();
                        editPlace(uid);
                    }}>
                        <EditIcon size={14} color={COLOR_WHITE}/>
                    </Clickable>
                </HFlex>
            }
            style={{ pointerEvents: "auto" }}
        >
            <Tag key={uid} color={place.color} closable={onClose !== undefined} onClose={onClose} style={style}>
                {getTruncatedPlaceName(place)}
            </Tag>
        </Tooltip>
    );
}