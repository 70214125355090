import { VFlex } from "../components/containers/VFlex";
import { PlaceGrid } from "../components/places/PlaceGrid";
import { PlacesEditRow } from "../components/places/PlacesEditRow";
import { PlacesFilterRow } from "../components/places/PlacesFilterRow";
import { useRecoilValue } from "recoil";
import { activePlacesZonesTabState } from "../states/viewState";
import { useZoneSelection } from "../hooks/zones/useZoneSelection";
import { ZonesFilterRow } from "../components/zones/ZonesFilterRow";
import { ZonesEditRow } from "../components/zones/ZonesEditRow";
import { ZoneGrid } from "../components/zones/ZoneGrid";
import React from "react";

export function PlacesAndZonesView() {
    const activeKey = useRecoilValue(activePlacesZonesTabState);
    const { selectionCount } = useZoneSelection();

    return (
        <VFlex spacing>
            {activeKey === "places" &&
                <>
                    <PlacesFilterRow/>
                    <PlacesEditRow/>
                    <PlaceGrid/>
                </>
            }
            {activeKey === "zones" &&
                <>
                    {selectionCount === 0 && <ZonesFilterRow/>}
                    {selectionCount > 0 && <ZonesEditRow/>}
                    <ZoneGrid/>
                </>
            }
        </VFlex>
    );
}