import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { OpenAiIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { Alert, Divider, Segmented, Tag } from "antd";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { LabelMedium, ParagraphMedium } from "baseui/typography";
import { ModalBody, ModalHeader } from "baseui/modal";
import { StyledModal } from "../ui/StyledModal";
import { AssistantHistory } from "./AssistantHistory";
import { assistantInputState, assistantOpenState, assistantQueryingState } from "../../states/assistantState";
import { AssistantSamples } from "./AssistantSamples";
import { AssistantFeedback } from "./AssistantFeedback";
import { AssistantFulfillments } from "./AssistantFulfillments";
import { AssistantInput } from "./AssistantInput";
import { VFlex } from "../containers/VFlex";
import { useStrings } from "../../hooks/useStrings";
import useAccount from "../../hooks/useAccount";
import { Button } from "baseui/button";
import React, { ReactNode, useState } from "react";
import { useEditCompany } from "../../hooks/company/useEditCompany";
import { ChatAiBox } from "../salesai/ChatAiBox";


export function AssistantModal() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const loading = useRecoilValue(assistantQueryingState);
    const [isModalOpen, setModalOpen] = useRecoilState(assistantOpenState);
    const setInput = useSetRecoilState(assistantInputState);
    const { company, isSelfAdminOrCompany } = useAccount();
    const editCompany = useEditCompany();
    const [activeKey, setActiveKey] = useState<"dispatch" | "help">("dispatch");

    if (!isModalOpen) {
        return null;
    }

    const onEnableClick = async () => {
        await editCompany.editField("assistant_enabled", true);
    };

    const Wrapper = function ({ children }: { children: ReactNode }) {
        return (
            <VFlex spacing={"16px"} style={{ overflow: "auto", height: "60vh" }}>
                {children}
            </VFlex>
        );
    }

    return (
        <StyledModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            customSize={750}
        >
            <ModalHeader>
                <HFlex spacing alignCenter>
                    <OpenAiIcon size={16} color={theme.colors.contentPrimary} animated repeatCount={1}
                                 duration={"0.5s"}/>
                    <LabelMedium>{strings.Assistant.Title}</LabelMedium>
                    <Tag color={"gold"}>Beta</Tag>
                </HFlex>
            </ModalHeader>
            <ModalBody>
                <HFlex>
                    <SpacerExpand/>
                    <Segmented
                        value={activeKey}
                        size={"large"}
                        options={[
                            {
                                value: "dispatch",
                                label: strings.Assistant.DispatchAssistant,
                            },
                            {
                                value: "help",
                                label: strings.Assistant.HelpAssistant,
                            },
                        ]}
                        onChange={(value) => setActiveKey(value as "dispatch" | "help")}
                    />
                    <SpacerExpand/>
                </HFlex>
                <Spacer/>
                {activeKey === "dispatch" &&
                    <>
                        {isSelfAdminOrCompany && !company?.assistant_enabled &&
                            <>
                                <Alert description={
                                    <VFlex>
                                        <ParagraphMedium>{strings.Assistant.DisabledDescription}</ParagraphMedium>
                                        <HFlex>
                                            <Button size={"mini"} onClick={onEnableClick}>
                                                {strings.Assistant.Enable}
                                            </Button>
                                            <SpacerExpand/>
                                        </HFlex>
                                    </VFlex>}/>
                                <Spacer/>
                            </>
                        }
                        <AssistantInput/>
                        <Divider orientation="left"/>
                        <AssistantFeedback/>
                        <Wrapper>
                            {!loading && <AssistantFulfillments/>}
                            {!loading && <AssistantHistory onClick={query => setInput(query)}/>}
                            <AssistantSamples/>
                        </Wrapper>
                    </>
                }
                {activeKey === "help" &&
                    <Wrapper>
                        <ChatAiBox ai={"help"}/>
                    </Wrapper>
                }
            </ModalBody>
        </StyledModal>
    )
}