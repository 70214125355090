import { memo } from "react";
import { default as AutoSizer } from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import { useRecoilValue } from "recoil";
import { pinnedJobsState } from "../../../states/jobsState";
import { PinnedJob } from "./PinnedJob";

type Props = {
    isUsingPlaceholder: boolean;
};

function PinnedJobList({ isUsingPlaceholder }: Props) {
    const jobs = useRecoilValue(pinnedJobsState);
    const itemCount = isUsingPlaceholder ? jobs.length + 1 : jobs.length;

    const getItemSize = (index: number) => {
        return 52;
    };

    return (
        <AutoSizer>
            {({ height, width }) => (
                <List height={height}
                      itemCount={itemCount}
                      itemSize={getItemSize}
                      width={width - 2}
                      itemData={jobs.map(job => job.id)}>
                    {PinnedJob}
                </List>
            )}
        </AutoSizer>
    );
}

export default memo(PinnedJobList);
