import { Category } from "../components/statistics/visx/StackedBars";
import { asNumber } from "../utils/objects";
import { Datum } from "../components/statistics/visx/Donut";
import { Day, Uid } from "./core";

export interface MemberStat {
    memberUid: Uid;
    totalDist: number;
    totalTracks: number;
    max: number;
    avg: number;
    totalJobs: number;
    ontimeCount: number;
    delaysCount: number;
    successCount: number;
    issueCount: number;
    engagedCount: number;
    passiveCount: number;
    totalJobOnsiteTime: number;
    avgJobOnsiteTime: number;
    totalPlaceOnsiteTime: number;
    avgPlaceOnsiteTime: number;
    totalPauseTime: number;
    avgPauseTime: number;
    avgTravelTime: number;
    totalTravelTime: number;
    totalTimeBetweenJobs: number;
    avgTimeBetweenJobs: number;
    jobCountForCalcTimeBetweenJobs: number;
    jobTypes: IdCount[];
    jobTeams: IdCount[]
}

export interface IdCount {
    id: number;
    count: number;
}

export interface MemberStatDay {
    day: Day;
    data: MemberStat[]
}

export function calcSumOfTotals(stats: MemberStatDay[], totalKey: Category): number {
    const [totals] = calcTotalsAndAverages(stats, totalKey);
    return totals && totals.length > 0 ? totals.reduce((sum, current) => sum + current) : 0
}

export function calcTotalsAndAverages(stats: MemberStatDay[], totalKey: Category, avgKey?: Category) {
    const totals: number[] = stats.map(s => s.data
        .map(d => asNumber(d, totalKey))
        .reduce((sum, current) => sum + current, 0)
    );
    const averages: number[] = stats.map(s => {
        if (s.data.length === 0 || !avgKey) {
            return 0;
        }
        const sum = s.data
            .map(d => asNumber(d, avgKey))
            .reduce((sum, current) => sum + current, 0);
        return sum / s.data.length;
    });
    return [totals, averages];
}

export function calcTotalsAndAveragesByMember(stats: MemberStatDay[], memberUids: Uid[], totalKey: Category, avgKey?: Category) {
    const totalsByMember: number[] = memberUids.map(uid =>
        stats.reduce((sum, s) =>
                sum + s.data
                    .filter(d => d.memberUid === uid)
                    .map(d => asNumber(d, totalKey))
                    .reduce((sum, current) => sum + current, 0),
            0)
    );

    const averagesByMember: number[] = memberUids.map(uid => {
        let sum = 0;
        let count = 0;

        stats.forEach(s => {
            const filteredData = s.data.filter(d => d.memberUid === uid);
            if (filteredData.length > 0 && avgKey) {
                sum += filteredData
                    .map(d => asNumber(d, avgKey))
                    .reduce((sum, current) => sum + current, 0);
                count += filteredData.length;
            }
        });

        return count > 0 ? sum / count : 0;
    });

    return [totalsByMember, averagesByMember];
}

export function retrieveMemberUids(stats: MemberStatDay[]) {
    return Array.from(new Set(stats.flatMap(s => s.data.map(d => d.memberUid))))
}

export function retrieveDonutData(field: "jobTypes" | "jobTeams", stats: MemberStatDay[], labelFor: (id: number) => string): Datum[] {
    const flatArray = stats.flatMap(s => s.data.flatMap(d => d[field]));
    const map: Map<number, number> = flatArray.reduce((acc, d) => {
        acc.set(d.id, (acc.get(d.id) || 0) + d.count);
        return acc;
    }, new Map<number, number>());
    const counts: IdCount[] = Array.from(map, ([id, count]) => ({ id, count }));
    const total = flatArray.reduce((sum, cur) => sum + cur.count, 0);
    const data: Datum[] = counts.map(d => ({
        label: labelFor(d.id),
        id: d.id,
        count: d.count,
        percent: total > 0 ? Math.round(d.count * 100 / total) : 0
    } as Datum));
    return data;
}