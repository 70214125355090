import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { LOADING, LOGGED_OUT } from '../constants/directorStatuses';
import { loggedInState } from '../states/accountState';
import { directorState } from '../states/directorState';
import useAccount from './useAccount';
import { getBilling, webLogin } from "../services/privateApi";
import { STATUS_ERROR_FORCELOGOUT, STATUS_ERROR_PASSWORDMISMATCH, STATUS_ERROR_USERUNKNOWN } from "../services/api";
import { currentLanguageState } from "../states/localeState";
import { mapTypeState } from "../states/mapState";
import { deleteDb } from "../utils/persistence";
import { HT_LOGIN_TS, setCookie } from "../utils/cookies";

export default function useDirector() {
    const [director, setDirector] = useRecoilState(directorState);
    const setLoggedIn = useSetRecoilState(loggedInState);
    const account = useAccount();
    const setCurrentLanguage = useSetRecoilState(currentLanguageState);
    const [mapType, setMapType] = useRecoilState(mapTypeState);
    const resetMapType = useResetRecoilState(mapTypeState);

    const handleLoginResponse = (data: any) => {
        data.access && account.setAccess(data.access);
        data.token && account.setToken(data.token);
        data.key && account.setAccountKey(data.key);

        const ts = Date.now();

        account.setName(data.name);
        account.setUid(data.uid);
        account.setLoginTs(ts);
        account.setBusiness(data.business)

        setCookie(HT_LOGIN_TS, "" + ts, 28);

        if (data.account) {
            account.setRole(data.account.role);
            account.setIcon(data.account.portrait);
            account.setTimeFormat(data.account.unit_time);
            account.setDistanceFormat(data.account.unit_distance);
            account.setLanguage(data.account.language);
            account.setTimezone(data.account.timezone);
            account.setCompany(data.company);
            account.setInviteCodes(data.inviteCodes || []);
            account.setCompanyKey(data.companyKey);
            account.setCompanyUsername(data.companyUsername);
            account.setSlackTeamId(data.slackTeamId);
            account.setTimestamps(data.account.timestamps);
            setCurrentLanguage(data.account.language);
        }
    };

    const performLogin = async (hardlogin = false, code?: string | undefined) => {
        const result = await webLogin(false, code);
        if (result.status && result.data.access && result.data.token) {
            handleLoginResponse(result.data);
            setLoggedIn(true);
            setDirector(LOADING);
            if (hardlogin) {
                account.setHardLoginTs(Date.now);
            }
        }
        return result;
    };

    const performPoll = async () => {
        const result = await webLogin(true);
        if (result.status) {
            handleLoginResponse(result.data);
        } else {
            if (result.error === STATUS_ERROR_PASSWORDMISMATCH
                || result.error === STATUS_ERROR_FORCELOGOUT
                || result.error === STATUS_ERROR_USERUNKNOWN) {
                logout();
            }
        }
    };

    const performBilling = async () => {
        const result = await getBilling();
        if (result.status) {
            account.setBilling(result.billing);
        }
    };

    const logout = () => {
        const rememberMapType = mapType;
        const rememberUsername = account.username;
        deleteDb();
        resetMapType();
        setMapType(rememberMapType);
        account.setUsername("");
        account.setUsername(rememberUsername);
        setLoggedIn(false);
        setDirector(LOGGED_OUT);
        window.location.reload();
    }

    return { performLogin, setDirector, director, performPoll, performBilling, logout, setCurrentLanguage };
}
