import { MicrophoneIcon } from "../ui/svg";
import { Spacer } from "../containers/Spacer";
import { Button } from "baseui/button";
import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";

export function AudioStartRecordingButton({ onClick }: { onClick: () => void }) {
    const { theme } = useTheme();
    const { strings } = useStrings();

    return (
        <Button kind={"secondary"} size={"mini"} onClick={onClick}>
            <MicrophoneIcon size={14} color={theme.customColors.accent}/>
            <Spacer/>
            {strings.Audio.StartRecordingAudioTranscript}
        </Button>
    )
}