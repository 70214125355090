import { Mentions } from "antd";
import { Button } from "baseui/button";
import { SendIcon } from "../ui/svg";
import { HFlex } from "../containers/HFlex";
import { useAssistant } from "../../hooks/useAssistant";
import { useRecoilState, useRecoilValue } from "recoil";
import { assistantInputState, assistantQueryingState } from "../../states/assistantState";
import { useTheme } from "../../hooks/useTheme";
import { useEffect, useState } from "react";
import { memberNamesState } from "../../states/membersState";
import { allPlacesState } from "../../states/placeState";
import { MentionsOptionProps } from "antd/es/mentions";
import { UidName } from "../../types/core";
import { useStrings } from "../../hooks/useStrings";
import useAccount from "../../hooks/useAccount";
import { useToast } from "../../hooks/useToast";

type PrefixType = "@" | "#";

export function AssistantInput() {
    const { strings } = useStrings();
    const assistant = useAssistant();
    const [input, setInput] = useRecoilState(assistantInputState);
    const [loading, setLoading] = useRecoilState(assistantQueryingState);
    const { theme } = useTheme();
    const [membersMentioned, setMembersMentioned] = useState<UidName[]>([]);
    const [placesMentioned, setPlacesMentioned] = useState<UidName[]>([]);
    const { company, isSelfAdminOrCompany } = useAccount();
    const toast = useToast();

    const memberNames = useRecoilValue(memberNamesState).map(m => {
        return {
            label: m.name,
            value: m.name,
            key: m.uid,
        }
    });
    const placeNames = useRecoilValue(allPlacesState).map(p => {
        return {
            label: p.name,
            value: p.name,
            key: p.uid,
        }
    });
    const [prefix, setPrefix] = useState<PrefixType>('@');

    useEffect(() => {
        if (input.length === 0) {
            setMembersMentioned([]);
            setPlacesMentioned([]);
        }
    }, [input]);

    useEffect(() => {
        const el = document.querySelector('#assistant-input') as any;
        if (el?.focus) {
            setTimeout(() => el.focus(), 100); // a bit hacky, is there a diff way?
        }
    }, []);

    const onSendClick = async () => {
        if (!isSelfAdminOrCompany && !company?.assistant_enabled) {
            toast.showWarning(strings.Assistant.DisabledWarning);
            return;
        }

        setLoading(true);

        let members = [...membersMentioned];
        if (membersMentioned.length > 0) {
            for (const uidName of members) {
                if (!input.includes(uidName.name)) {
                    members = members.filter(m => m.uid === uidName.uid);
                }
            }
        }

        let places = [...placesMentioned];
        if (input.includes("#") && placesMentioned.length === 0) {
            const remaining = input.substring(input.indexOf("#") + 1);
            for (let place of placeNames) {
                if (remaining.startsWith(place.label)) {
                    places.push({
                        uid: place.key,
                        name: place.label,
                    });

                }
            }
        } else if (placesMentioned.length > 0) {
            for (const uidName of places) {
                if (!input.includes(uidName.name)) {
                    places = places.filter(p => p.uid === uidName.uid);
                }
            }
        }
        const success = await assistant.sendQuery(input, members.map(m => m.uid), places.map(m => m.uid));
        if (success) {
            setInput("");
        }
        setLoading(false);
    };

    const onSelect = (option: MentionsOptionProps) => {
        if (prefix === "@") {
            setMembersMentioned(prev => [...prev, {
                uid: option.key,
                name: option.label,
            } as UidName]);
        } else {
            setPlacesMentioned(prev => [...prev, {
                uid: option.key,
                name: option.label,
            } as UidName]);
        }
    };

    return (
        <HFlex alignCenter spacing>
            <Mentions
                id={"assistant-input"}
                autoSize
                style={{ width: '100%' }}
                onChange={(text) => setInput(text)}
                value={input}
                onSelect={onSelect}
                prefix={['@', '#']}
                onSearch={(_, newPrefix) => {setPrefix(newPrefix as PrefixType)}}
                options={prefix === "@" ? memberNames : placeNames}
                placeholder={strings.Assistant.InputPlaceholder}
                disabled={loading}
            />
            <Button size={"mini"}
                    disabled={input.length === 0}
                    onClick={onSendClick}
                    $disabled={loading}
                    kind={"secondary"}
            >
                <SendIcon color={theme.colors.contentPrimary} size={16}/>
            </Button>
        </HFlex>
    );
}