import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Button } from "baseui/button";
import { useState } from "react";
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";
import { FormItemCard } from "./FormItemCard";
import { FormBetweenItems } from "./FormBetweenItems";
import { useForms } from "../../hooks/useForms";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { HFlex } from "../containers/HFlex";
import { FormPlusButton } from "./FormPlusButton";
import { useRecoilState, useRecoilValue } from "recoil";
import { editingJobIdState } from "../../states/appState";
import { editJobState } from "../../states/editJobState";
import { getInputFormConfig } from "../../types/job";
import { FormContent } from "../../types/form";

type Props = {
    onClose: (saved: boolean) => void;
};

export function IndividualFormModal({ onClose }: Props) {
    const { strings } = useStrings();
    const [loading, setLoading] = useState(false);
    const { theme } = useTheme();
    const jobId = useRecoilValue(editingJobIdState);
    const [editingJob, setEditingJob] = useRecoilState(editJobState(jobId || ""));
    const [fields, setFields] = useState(editingJob);
    const forms = useForms();

    if (!fields || !editingJob) {
        return null;
    }

    const apply = async (content: FormContent) => {
        if (fields.inputFormConfig) {
            setFields({ ...fields, inputFormConfig: JSON.stringify(content) });
        }
    };

    const form = getInputFormConfig(fields);

    if (!form) {
        return null;
    }

    const items = forms.jobOptions;

    return (
        <Modal
            closeable={false}
            isOpen
            overrides={{
                Dialog: {
                    style: {
                        backgroundColor: theme.colors.backgroundSecondary,
                    },
                },
            }}
        >
            <ModalHeader>{strings.ShortForm.ConfigurationOfIndividualForm}</ModalHeader>
            <ModalBody $style={{ maxHeight: "60vh", overflow: "auto" }}>
                {form.content.map((item, index) => (
                    <div key={index}>
                        <FormBetweenItems
                            items={items}
                            onPlusClick={(option) => apply(forms.addItem(index, form, option))}
                        />
                        <FormItemCard
                            formItem={item}
                            onSetName={(name) => apply(forms.setName(index, form, name))}
                            onSetType={(type) => apply(forms.setType(index, form, type))}
                            onSetOptions={(options: string[]) => apply(forms.setOptions(index, form, options))}
                            onSetRequired={(required: boolean) => apply(forms.setRequired(index, form, required))}
                            onRemove={() => apply(forms.removeItem(index, form))}
                            jobInputForm={true}
                        />
                    </div>
                ))}
                <Spacer />
                <HFlex>
                    <SpacerExpand />
                    <FormPlusButton items={items} onPlusClick={(option) => apply(forms.addItem(-1, form, option))} />
                    <SpacerExpand />
                </HFlex>
            </ModalBody>
            <ModalFooter>
                <Button
                    kind="tertiary"
                    size="compact"
                    onClick={() => onClose(false)}
                    overrides={{
                        BaseButton: {
                            style: ({ $theme }) => ({
                                marginRight: $theme.sizing.scale400,
                            }),
                        },
                    }}
                >
                    {strings.General.Cancel}
                </Button>
                <Button
                    onClick={async () => {
                        setLoading(true);
                        if (fields.inputFormConfig) {
                            setEditingJob({ ...editingJob, inputFormConfig: fields.inputFormConfig });
                        }
                        setLoading(false);
                        onClose(true);
                    }}
                    size="compact"
                    isLoading={loading}
                >
                    {strings.General.Save}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
