import { useRecoilState } from 'recoil';
import { getNextDirection } from '../../utils/sort';
import { zonesSortDirectionState, zonesSortState } from '../../states/zoneState';


export function useZonesSort() {
    const [direction, setDirection] = useRecoilState(zonesSortDirectionState);
    const [zonesSort, setZonesSort] = useRecoilState(zonesSortState);

    const handleSort = (sortNumber: number) => {
        const nextDirection = getNextDirection(direction);
        setZonesSort(sortNumber);
        setDirection(nextDirection);
    };

    const getDirection = (sortNumber: number) => {
        return zonesSort === sortNumber ? direction : null;
    }

    return {
        handleSort,
        getDirection,
    };
}