import { useRecoilValue } from 'recoil';
import { editingMemberIdState } from '../../../states/appState';
import { editMemberState } from '../../../states/editMemberState';
import { FlexGridLayout } from '../../containers/FlexGridLayout';
import { FormInput } from '../../ui/FormInput';
import { Section } from '../../ui/Section';
import {
    AndroidIcon,
    AppleIcon,
    BatteryChargingIcon,
    BatteryIcon,
    InfoCircledIcon,
    LoginIcon,
    LogoIcon,
    SingleCheckIcon,
    UserPositionOutlineIcon,
} from "../../ui/svg";
import { SectionedWrapper } from '../../containers/SectionedWrapper';
import { DAY } from "../../../utils/time";
import { DEVICE_MODE_SUSPENDED, hasValidDevice } from "../../../types/member";
import { memberState } from '../../../states/membersState';
import { useTime } from "../../../hooks/useTime";
import { useMemberStatusInfo } from "../../../hooks/members/useMemberStatusInfo";
import { useStrings } from '../../../hooks/useStrings';
import { useTheme } from '../../../hooks/useTheme';
import { Spacer } from "../../containers/Spacer";
import React from "react";
import { DeviceDiagnosticsSection } from "../../timebar/DeviceDiagnosticsSection";
import { Banner } from "baseui/banner";
import { ParagraphMedium } from "baseui/typography";
import { StoreButtons } from "../../shared/AppButtons";
import { InviteCodeSection } from "../../team/InviteCodeSection";

export function DeviceTab() {
    const editingMember = useRecoilValue(editingMemberIdState);
    const member = useRecoilValue(memberState(editingMember));
    const memberFields = useRecoilValue(editMemberState(editingMember));
    const { strings } = useStrings()
    const { theme } = useTheme();
    const time = useTime();
    const statusInfo = useMemberStatusInfo(member);

    let batteryIcon;
    if (memberFields.battery_plugged) {
        batteryIcon = <BatteryChargingIcon size={14} color={theme.colors.positive}/>;
    } else {
        batteryIcon = <BatteryIcon size={14} color={memberFields.battery_level < 10
            ? theme.colors.negative : theme.colors.positive}/>;
    }

    if (!hasValidDevice(member)) {
        return (
            <Banner title={strings.DeviceDiagnostics.UnavailableNotLoggedIn}>
                <ParagraphMedium>{strings.General.DownloadAppDescription}</ParagraphMedium>
                <StoreButtons/>
                <InviteCodeSection/>
            </Banner>
        )
    }

    return (
        <SectionedWrapper>
            <Section title={strings.ProfileModal.Device} noMarginTop>
                <FlexGridLayout>
                    <FormInput
                        label={""}
                        value={memberFields.device.id}
                        field="readonly"
                        startEnhancer={"ID"}
                    />
                    <FormInput
                        label={""}
                        value={strings.ProfileModal.AppVersion + " " + memberFields.device.app_version_name}
                        field="readonly"
                        startEnhancer={<LogoIcon size={14} color={theme.colors.contentPrimary}/>}
                    />
                    <FormInput
                        label={""}
                        value={`${memberFields.device.model} ${memberFields.device.manufacturer}`}
                        field="readonly"
                        startEnhancer={statusInfo.isAppleDevice
                            ? <AppleIcon size={14} color={theme.colors.contentPrimary}/>
                            : <AndroidIcon size={14} color={theme.colors.contentPrimary}/>}
                    />
                    <FormInput
                        label={""}
                        value={memberFields.device.os}
                        field="readonly"
                    />
                </FlexGridLayout>
            </Section>

            <Section title={strings.General.Status}>
                <FormInput
                    label={strings.ProfileModal.MostRecentLocation}
                    value={time.formatExactTime(memberFields.location.ts)}
                    field="readonly"
                    startEnhancer={<UserPositionOutlineIcon size={14}
                                                            color={(Date.now() - memberFields.location.ts) < DAY
                                                                ? theme.colors.positive : theme.colors.contentPrimary}/>}
                />
                <Spacer/>
                <FormInput
                    label={""}
                    value={statusInfo.loginStatusText}
                    field="readonly"
                    startEnhancer={<LoginIcon size={14} color={memberFields.states.login_status
                        ? theme.colors.positive : theme.colors.contentPrimary}/>}
                />
                <Spacer/>
                <FormInput
                    label={""}
                    value={statusInfo.trackingStatusText}
                    field="readonly"
                    startEnhancer={memberFields.states.tracking_status
                        ? <SingleCheckIcon size={14} color={theme.colors.positive}/>
                        : <InfoCircledIcon size={14} color={theme.colors.negative}/>}
                />
                <Spacer/>
                <FlexGridLayout>
                    {statusInfo.isLoggedInAndActive &&
                        <>
                            {statusInfo.isAndroidDevice &&
                                <FormInput
                                    label={""}
                                    value={memberFields.states.location_services
                                        ? strings.General.LocationServicesOn : strings.General.LocationServicesOff}
                                    field="readonly"
                                    startEnhancer={memberFields.states.location_services
                                        ? <SingleCheckIcon size={14} color={theme.colors.positive}/>
                                        : <InfoCircledIcon size={14} color={theme.colors.negative}/>}
                                />
                            }
                            {statusInfo.isAppleDevice &&
                                <FormInput
                                    label={""}
                                    value={statusInfo.modeText}
                                    field="readonly"
                                    startEnhancer={memberFields.device.mode !== DEVICE_MODE_SUSPENDED
                                        ? <SingleCheckIcon size={14} color={theme.colors.positive}/>
                                        : <InfoCircledIcon size={14} color={theme.colors.contentPrimary}/>}
                                />
                            }
                        </>
                    }
                    <FormInput
                        label={""}
                        value={statusInfo.permissionText}
                        field="readonly"
                        startEnhancer={memberFields.states.location_permission
                            ? <SingleCheckIcon size={14} color={theme.colors.positive}/>
                            : <InfoCircledIcon size={14} color={theme.colors.negative}/>}
                    />
                    <FormInput
                        label={""}
                        value={memberFields.states.battery_save ? strings.General.BatterySaveOn : strings.General.BatterySaveOff}
                        field="readonly"
                        startEnhancer={!memberFields.states.battery_save
                            ? <SingleCheckIcon size={14} color={theme.colors.positive}/>
                            : <InfoCircledIcon size={14} color={theme.colors.negative}/>}
                    />
                    <FormInput
                        label={""}
                        value={memberFields.states.off_trackrecording ? strings.General.TrackRecordingOff : strings.General.TrackRecordingOn}
                        field="readonly"
                        startEnhancer={!memberFields.states.off_trackrecording
                            ? <SingleCheckIcon size={14} color={theme.colors.positive}/>
                            : <InfoCircledIcon size={14} color={theme.colors.negative}/>}
                    />
                    <FormInput
                        label={""}
                        value={memberFields.states.off_gps_stationary
                            ? strings.General.GpsStationaryOff
                            : strings.General.GpsStationaryOn}
                        field="readonly"
                        startEnhancer={!memberFields.states.off_gps_stationary
                            ? <SingleCheckIcon size={14} color={theme.colors.positive}/>
                            : <InfoCircledIcon size={14} color={theme.colors.negative}/>}
                    />
                    <FormInput
                        label={""}
                        value={memberFields.battery_level}
                        field="readonly"
                        startEnhancer={batteryIcon}
                        endEnhancer={"%"}
                        caption={time.formatDateTime(memberFields.timestamps.signal)}
                    />
                </FlexGridLayout>
            </Section>

            <DeviceDiagnosticsSection member={member}/>
        </SectionedWrapper>
    );
}
