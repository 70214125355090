import { calcTotalsAndAveragesByMember, MemberStatDay, retrieveMemberUids } from "../../types/stats";
import { Datum, StackedBars } from "../../components/statistics/visx/StackedBars";
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { memberState } from "../../states/membersState";
import { asNumber } from "../../utils/objects";
import React from "react";
import { useStrings } from "../useStrings";
import { ChartPanel } from "../../components/statistics/ChartPanel";

export function useEngagementCharts({
                                        stats,
                                        fetching,
                                        width
                                    }: { stats: MemberStatDay[], fetching: boolean, width: number }) {
    const { strings } = useStrings();
    const memberUids = retrieveMemberUids(stats);

    const [totalEngagedCountByMember,] = calcTotalsAndAveragesByMember(stats, memberUids, 'engagedCount', 'engagedCount');
    const [totalPassiveCountByMember,] = calcTotalsAndAveragesByMember(stats, memberUids, 'passiveCount', 'passiveCount');
    const [totalJobsCountByMember,] = calcTotalsAndAveragesByMember(stats, memberUids, 'totalJobs', 'totalJobs');

    const dataByMember: Datum[] = memberUids.map((uid, index) => ({
        bucketField: getRecoil(memberState(uid))?.name || uid,
        totalCount: totalJobsCountByMember[index],
        engagedCount: totalEngagedCountByMember[index],
        passiveCount: totalJobsCountByMember[index] - totalEngagedCountByMember[index],
        engagedPercent: totalJobsCountByMember[index] > 0 ? totalEngagedCountByMember[index] * 100 / totalJobsCountByMember[index] : 0,
        passivePercent: totalJobsCountByMember[index] > 0 ? (totalJobsCountByMember[index] - totalEngagedCountByMember[index]) * 100 / totalJobsCountByMember[index] : 0,
    } as Datum));

    const dataByMemberFiltered = dataByMember.filter(d => asNumber(d, "totalCount") > 0);

    const dataByMemberSortedByTotal = [...dataByMemberFiltered]
        .sort((d1: Datum, d2: Datum) => {
            const sum1 = asNumber(d1, "engagedCount") * 100 + asNumber(d1, "passiveCount");
            const sum2 = asNumber(d2, "engagedCount") * 100 + asNumber(d2, "passiveCount");
            return sum1 - sum2;
        });

    const rowHeight = 24;
    const rowPad = 100;

    return [
        <ChartPanel title={strings.Stats.Engagement} key={strings.Stats.Engagement}>
            <StackedBars width={width}
                            height={dataByMemberSortedByTotal.length * rowHeight + rowPad}
                            data={dataByMemberSortedByTotal}
                            fetching={fetching}
                            categories={["engagedPercent", "passivePercent"]}
                            labels={[strings.Stats.Engaged + " %", strings.Stats.Passive + " %"]}
                            type={"count"}
            />
        </ChartPanel>
    ];
}