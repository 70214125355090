import { Button } from "baseui/button";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { useJobForms } from "../../../hooks/jobs/useJobForms";
import { useModal } from "../../../hooks/useModal";
import { useStyleOverrides } from "../../../hooks/useStyleOverrides";
import { stringsState } from "../../../states/localeState";
import { SpacerExpand } from "../../containers/Spacer";
import { ConfirmModal } from "../../ui/ConfirmModal";

export function TemplateModalFooter() {
    const strings = useRecoilValue(stringsState);
    const { onSubmit, isLoading, canSubmit, isNewJob, hasChanges, onDelete } = useJobForms();
    const { closeModal } = useModal();
    const { cancelButtonStyles } = useStyleOverrides();
    const [showConfirm, setShowConfirm] = useState(false);

    const onDeleteConfirmed = async () => {
        if (onDelete) {
            await onDelete();
        }
        setShowConfirm(false);
    };

    return (
        <>
            {!isNewJob && (
                <Button
                    onClick={() => setShowConfirm(true)}
                    size='compact'
                    kind='tertiary'
                >
                    {strings.General.Delete}
                </Button>
            )}
            <SpacerExpand/>
            {hasChanges && (
                <Button
                    onClick={closeModal}
                    size='compact'
                    kind='tertiary'
                    overrides={cancelButtonStyles}
                >
                    {strings.General.Cancel}
                </Button>
            )}
            <Button
                onClick={hasChanges ? onSubmit : closeModal}
                size='compact'
                isLoading={isLoading}
                disabled={!canSubmit && hasChanges}
            >
                {hasChanges || isNewJob ? strings.General.SaveTemplate : strings.General.OK}
            </Button>
            <ConfirmModal
                isOpen={showConfirm}
                description={strings.Job.ReallyDeleteThisTemplate}
                onCancel={() => setShowConfirm(false)}
                onConfirm={onDeleteConfirmed}
                cancelText={strings.General.Cancel}
                confirmText={strings.General.Delete}
                danger
            />
        </>
    );
}