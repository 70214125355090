import { useRecoilState, useRecoilValue } from "recoil";
import { setRecoil } from "../../providers/RecoilAccessProvider";
import { zonesSelectedUidsState, zonesState } from "../../states/zoneState";
import { Zone } from "../../types/zone";
import { Uid } from "../../types/core";

export function useZoneSelection() {
    const [selectedUids, setSelectedUids] = useRecoilState(zonesSelectedUidsState);
    const zones = useRecoilValue(zonesState);
    const allSelected = zones.map(z => z.uid).every(uid => selectedUids.includes(uid));
    const selectionCount = selectedUids.length;
    const selectedZone = selectionCount === 1 ? zones.find((z) => z.uid === selectedUids[0]) : undefined;

    const isSelected = (uid: Uid) => {
        return selectedUids.includes(uid);
    };

    const toggleSelected = (uid: Uid) => {
        if (isSelected(uid)) {
            setSelectedUids([...selectedUids.filter(id => id !== uid)]);
        } else {
            setSelectedUids([...selectedUids, uid]);
        }
    };

    const clearSelection = () => setSelectedUids([]);

    const select = (zoneUids: Uid[]) => {
        const newIds = [...selectedUids.filter(uid => !zoneUids.includes(uid)), ...zoneUids];
        setRecoil(zonesSelectedUidsState, newIds);
    }

    const unselect = (zoneUids: Uid[]) => {
        const newIds = [...selectedUids.filter(uid => !zoneUids.includes(uid))]
        setRecoil(zonesSelectedUidsState, newIds);
    }

    const toggleAllSelected = () => {
        if (selectionCount > 0) {
            unselect(zones.map(z => z.uid))
        } else {
            select(zones.map(z => z.uid));
        }
    };

    const tableMultiSelect = (zone: Zone, zones: Zone[]) => {
        if (selectedUids.length === 1 && zones.length > 0) {
            const initialZoneUid = selectedUids[0];
            const initialZoneIndex = zones.findIndex((z) => z.uid === initialZoneUid);
            const endZoneIndex = zones.findIndex((z) => z.uid === zone.uid);
            if (initialZoneIndex !== -1 && endZoneIndex !== -1) {
                const start = initialZoneIndex < endZoneIndex ? initialZoneIndex + 1 : endZoneIndex;
                const end = initialZoneIndex > endZoneIndex ? initialZoneIndex : endZoneIndex + 1;
                const zonesInRange = zones.slice(start, end);
                select(zonesInRange.map((z) => z.uid))
                return true;
            }
        }
        return false;
    }

    return {
        toggleSelected,
        selectedUids,
        setSelectedUids,
        isSelected,
        selectionCount,
        clearSelection,
        allSelected,
        toggleAllSelected,
        selectedZone,
        tableMultiSelect,
    };
}