import { StyledHead } from "baseui/table";
import { useStatsSort } from "../../../hooks/useStatsSort";
import { useStrings } from "../../../hooks/useStrings";
import { useStyleOverrides } from "../../../hooks/useStyleOverrides";
import { StyledSortableHeadCell } from "../../ui/StyledSortableHeadCell";
import { StatsSort } from "../../../states/statsState";

export function StatsHeaderRow() {
    const { strings } = useStrings();
    const { statsColumnStyle } = useStyleOverrides();
    const { getDirection, handleSort } = useStatsSort();

    return (
        <StyledHead role="row">
            <StyledSortableHeadCell
                title={strings.General.Member}
                direction={getDirection(StatsSort.NAME)}
                styles={statsColumnStyle[0]}
                onSort={() => handleSort(StatsSort.NAME)}
            />
            <StyledSortableHeadCell
                title={strings.General.Distance}
                direction={getDirection(StatsSort.TOTAL_DIST)}
                styles={statsColumnStyle[1]}
                onSort={() => handleSort(StatsSort.TOTAL_DIST)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.AvgSpeed}
                direction={getDirection(StatsSort.AVG)}
                styles={statsColumnStyle[2]}
                onSort={() => handleSort(StatsSort.AVG)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.MaxSpeed}
                direction={getDirection(StatsSort.MAX)}
                styles={statsColumnStyle[3]}
                onSort={() => handleSort(StatsSort.MAX)}
            />
            <StyledSortableHeadCell
                title={strings.General.Tracks}
                direction={getDirection(StatsSort.TOTAL_TRACKS)}
                styles={statsColumnStyle[4]}
                onSort={() => handleSort(StatsSort.TOTAL_TRACKS)}
            />
            <StyledSortableHeadCell
                title={strings.General.Jobs}
                direction={getDirection(StatsSort.TOTAL_JOBS)}
                styles={statsColumnStyle[5]}
                onSort={() => handleSort(StatsSort.TOTAL_JOBS)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.OnTime}
                direction={getDirection(StatsSort.ONTIME_COUNT)}
                styles={statsColumnStyle[6]}
                onSort={() => handleSort(StatsSort.ONTIME_COUNT)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.Delays}
                direction={getDirection(StatsSort.DELAYS_COUNT)}
                styles={statsColumnStyle[7]}
                onSort={() => handleSort(StatsSort.DELAYS_COUNT)}
            />
            <StyledSortableHeadCell
                title={strings.JobStatuses.Done}
                direction={getDirection(StatsSort.SUCCESS_COUNT)}
                styles={statsColumnStyle[8]}
                onSort={() => handleSort(StatsSort.SUCCESS_COUNT)}
            />
            <StyledSortableHeadCell
                title={strings.JobStatuses.Issue}
                direction={getDirection(StatsSort.ISSUE_COUNT)}
                styles={statsColumnStyle[9]}
                onSort={() => handleSort(StatsSort.ISSUE_COUNT)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.Engagement}
                direction={getDirection(StatsSort.ENGAGED)}
                styles={statsColumnStyle[10]}
                onSort={() => handleSort(StatsSort.ENGAGED)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.TotalJobOnsite}
                direction={getDirection(StatsSort.TOTAL_JOB_ONSITE)}
                styles={statsColumnStyle[11]}
                onSort={() => handleSort(StatsSort.TOTAL_JOB_ONSITE)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.AvgJobOnsite}
                direction={getDirection(StatsSort.AVG_JOB_ONSITE)}
                styles={statsColumnStyle[12]}
                onSort={() => handleSort(StatsSort.AVG_JOB_ONSITE)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.TotalPlaceOnsite}
                direction={getDirection(StatsSort.TOTAL_PLACE_ONSITE)}
                styles={statsColumnStyle[13]}
                onSort={() => handleSort(StatsSort.TOTAL_PLACE_ONSITE)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.AvgPlaceOnsite}
                direction={getDirection(StatsSort.AVG_PLACE_ONSITE)}
                styles={statsColumnStyle[14]}
                onSort={() => handleSort(StatsSort.AVG_PLACE_ONSITE)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.TotalPause}
                direction={getDirection(StatsSort.TOTAL_PAUSE)}
                styles={statsColumnStyle[15]}
                onSort={() => handleSort(StatsSort.TOTAL_PAUSE)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.AvgPause}
                direction={getDirection(StatsSort.AVG_PAUSE)}
                styles={statsColumnStyle[16]}
                onSort={() => handleSort(StatsSort.AVG_PAUSE)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.TotalTravelTime}
                direction={getDirection(StatsSort.TOTAL_TRAVEL_TIME)}
                styles={statsColumnStyle[17]}
                onSort={() => handleSort(StatsSort.TOTAL_TRAVEL_TIME)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.AvgTravelTime}
                direction={getDirection(StatsSort.TOTAL_TRAVEL_TIME)}
                styles={statsColumnStyle[17]}
                onSort={() => handleSort(StatsSort.TOTAL_TRAVEL_TIME)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.AccumulatedTimeBetweenJobs}
                direction={getDirection(StatsSort.TOTAL_TIME_BETWEEN_JOBS)}
                styles={statsColumnStyle[18]}
                onSort={() => handleSort(StatsSort.TOTAL_TIME_BETWEEN_JOBS)}
            />
            <StyledSortableHeadCell
                title={strings.Stats.AvgTimeBetweenJobs}
                direction={getDirection(StatsSort.AVG_TIME_BETWEEN_JOBS)}
                styles={statsColumnStyle[19]}
                onSort={() => handleSort(StatsSort.AVG_TIME_BETWEEN_JOBS)}
            />
        </StyledHead>
    );
}