import { setRecoil } from "../providers/RecoilAccessProvider";
import { dayRouteState } from "../states/geojsonState";
import { updateDayRouteLayer } from "../services/geojson";
import { routingStatusState } from "../states/viewState";
import { Day, Uid } from "../types/core";

export function useDayRouteUpdater() {
    const clear = (uid: Uid, day: Day) => {
        setRecoil(dayRouteState({ uid: uid, day: day }), "");
    }
    const update = async (uid: Uid, day: Day) => {
        clear(uid, day);
        setRecoil(routingStatusState({ day: day, uid: uid }), true);
        const routeOk = await updateDayRouteLayer(uid, day);
        if (!routeOk) {
            setRecoil(routingStatusState({ day: day, uid: uid }), false);
        }
    }
    const unsuccessful = (uid: Uid, day: Day) => {
        setRecoil(routingStatusState({ day: day, uid: uid }), false);
    }
    return { clear, update, unsuccessful }
}