import { Button } from 'baseui/button';
import { useRecoilValue } from 'recoil';
import { memberNamesFilteredState } from '../../states/membersState';
import { VFlex } from '../containers/VFlex';
import { useBoard } from "../../hooks/useBoard";
import { MemberBadges } from "../badges/MemberBadges";
import { RemoveIcon } from "../ui/svg";
import { MembersFilters } from './MembersFilters';
import { useTheme } from "../../hooks/useTheme";
import { pageState } from "../../states/appState";
import { PAGE } from "../../constants/pages";
import React from "react";
import { default as AutoSizer } from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import MemberItemVirtualized from './MemberItemVirtualized';
import { Droppable } from "react-beautiful-dnd";
import { DROPPABLE_TYPE_JOB, MEMBER_ITEM_ID_PREFIX } from "../../constants/dnd";
import DroppableMemberItem from "./DroppableMemberItem";
import { UnorderedList } from '../containers/UnorderedList';

function MemberList({ showSelectedBadges = false }: { showSelectedBadges?: boolean; height?: string }) {
    const filteredMemberNames = useRecoilValue(memberNamesFilteredState);
    const board = useBoard();
    const { theme } = useTheme();
    const page = useRecoilValue(pageState);
    const sortedMemberUids = [...filteredMemberNames]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(m => m.uid);

    return (
        <VFlex spacing>
            {showSelectedBadges &&
                <div style={{ minHeight: "20px" }}>
                    <MemberBadges members={board.memberUids} max={2} onClose={(uid) => board.remove(uid)}>
                        {board.memberUids.length > 1 ? (
                            <Button size={"mini"}
                                    kind={"tertiary"}
                                    shape={"pill"}
                                    onClick={() => board.setMemberUids([])}>
                                <RemoveIcon size={14} color={theme.colors.contentTertiary}/>
                            </Button>
                        ) : ""}
                    </MemberBadges>
                </div>
            }
            <MembersFilters/>
            {page !== PAGE.DISPATCH && (
                <UnorderedList
                    style={{
                        marginBottom: theme.sizing.scale600,
                        overflowY: "hidden",
                        flex: 1,
                    }}
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                height={height}
                                itemCount={sortedMemberUids.length}
                                itemSize={() => 54}
                                width={width}
                                itemData={sortedMemberUids}
                            >
                                {MemberItemVirtualized}
                            </List>
                        )}
                    </AutoSizer>
                </UnorderedList>
            )}
            {page === PAGE.DISPATCH && (
                <div style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <UnorderedList
                                style={{
                                    height: `${height}px`,
                                    width: `${width}px`,
                                    marginBottom: theme.sizing.scale600,
                                    flex: 1,
                                }}
                            >
                                {sortedMemberUids.map((uid) => (
                                    <Droppable
                                        droppableId={MEMBER_ITEM_ID_PREFIX + uid}
                                        key={uid}
                                        type={DROPPABLE_TYPE_JOB}
                                        direction='horizontal'
                                    >
                                        {(provided, snapshot) => (
                                            <DroppableMemberItem
                                                provided={provided}
                                                uid={uid}
                                                isDraggingOver={snapshot.isDraggingOver}
                                            />
                                        )}
                                    </Droppable>
                                ))}
                            </UnorderedList>
                        )}
                    </AutoSizer>
                </div>
            )}
        </VFlex>
    );
}

export default React.memo(MemberList);
