import { SpacerExpand } from "../containers/Spacer";
import { LabelXSmall } from "baseui/typography";
import { TimeBadge } from "../badges/TimeBadge";
import { VFlex } from "../containers/VFlex";
import React from "react";
import { TrackOrStopEntry } from "../../types/track";
import { useTime } from "../../hooks/useTime";
import { useTheme } from "../../hooks/useTheme";

export function DateCell({ track }: { track: TrackOrStopEntry }) {
    const time = useTime();
    const { theme } = useTheme();

    return (
        <VFlex style={{ width: "120px", flexShrink: 0 }}>
            <SpacerExpand/>
            <LabelXSmall color={theme.colors.contentSecondary}
                         style={{ fontSize: "10px", lineHeight: "10px", paddingLeft: "4px" }}
            >
                {time.formatDateOnly(track.startTs || track.endTs)}
            </LabelXSmall>
            <div><TimeBadge startMillis={track.startTs} endMillis={track.endTs}/></div>
            <SpacerExpand/>
        </VFlex>
    );
}