import { useEffect } from "react";
import { useMediumCard } from "../components/skeleton/hooks/useMediumCard";
import { useBodyWidth } from "../components/skeleton/hooks/useBodyWidth";
import { useRecoilValue } from "recoil";
import { customMediumCardWidthState, mediumState, visibleCardsState } from "../states/skeletonState";
import { pageState } from "../states/appState";
import { useCards } from "../components/skeleton/hooks/useCards";

export function SkeletonHandler() {
    const page = useRecoilValue(pageState);
    const { smallIsVisible, mediumIsVisible } = useRecoilValue(visibleCardsState);
    const { isOpened } = useRecoilValue(mediumState);
    const { setCardWidth, setMediumDefaults, setSmallDefaults } = useMediumCard()
    const { bodyWidth } = useBodyWidth();
    const { resetCards } = useCards();
    const customMediumCardWidth = useRecoilValue(customMediumCardWidthState(page));

    useEffect(() => {
        resetCards();
    }, [smallIsVisible, mediumIsVisible, page]);

    useEffect(() => {
        if (isOpened) {
            setCardWidth(customMediumCardWidth !== -1 ? customMediumCardWidth || 0 : 0);
        }
        if (bodyWidth > 767) {
            setMediumDefaults(true);
        } else if (!!bodyWidth) {
            setMediumDefaults(false);
            setSmallDefaults(true);
        }
    }, [bodyWidth, isOpened, customMediumCardWidth]);

    return null;
}