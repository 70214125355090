import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { LockIcon, RemoveIcon, SequenceIcon, UnlockedIcon } from "../../ui/svg";
import { useBoard } from "../../../hooks/useBoard";
import { Droppable } from "react-beautiful-dnd";
import { Spacer, SpacerExpand } from "../../containers/Spacer";
import { HFlex } from "../../containers/HFlex";
import { LabelMedium, LabelSmall, LabelXSmall, ParagraphSmall } from "baseui/typography";
import { HoverButton } from "../../buttons/HoverButton";
import { Card } from "baseui/card";
import { sequenceState } from "../../../states/sequencesState";
import { VFlex } from "../../containers/VFlex";
import { Button } from "baseui/button";
import { memberState } from "../../../states/membersState";
import { MemberBadges } from "../../badges/MemberBadges";
import { MembersPickerModalContent } from "../../member/modal/MembersPickerModalContent";
import { StyledModal } from "../../ui/StyledModal";
import { dayState } from "../../../states/appState";
import { Member } from "../../../types/member";
import { assignSequence } from "../../../services/api";
import { useStateUpdater } from "../../../hooks/useStateUpdater";
import { useTheme } from "../../../hooks/useTheme";
import { useWeekDaysEncoder } from "../../../hooks/useWeekDaysEncoder";
import { useToast } from "../../../hooks/useToast";
import { createPortal } from "react-dom";
import { SequenceAssignModalContent, SequenceAssignType } from "./SequenceAssignModalContent";
import { useState } from "react";
import { useStrings } from "../../../hooks/useStrings";
import { pickerMembersConfigState, pickerMembersState } from "../../../states/membersPickerState";
import { useSequenceSaver } from "../../../hooks/jobs/useSequenceSaver";
import { SavableSequence } from "../../../types/sequence";
import { Space } from "antd";
import { SequenceJobList } from "./SequenceJobList";
import { ColumnCard } from "../../board/ColumnCard";
import { DROPPABLE_TYPE_JOB } from "../../../constants/dnd";
import { ActionButton } from "../../buttons/ActionButton";
import { EMPTY_UID, Uid } from "../../../types/core";

export const SEQ_COL_ID_PREFIX = "seq-col-";

export function SequenceColumn({ id }: { id: string }) {
    const { strings, format } = useStrings();
    const [sequence, setSequence] = useRecoilState(sequenceState(id));
    const date = useRecoilValue(dayState);
    const board = useBoard();
    const assignedMember = useRecoilValue(memberState(sequence?.assignToUid || EMPTY_UID));
    const ownerUids = sequence?.owners ? sequence.owners.split(",") as Uid[] : [];
    const stateUpdater = useStateUpdater();
    const { theme, isDarkTheme } = useTheme();
    const hoverColor = isDarkTheme ? theme.colors.positive700 : theme.colors.positive50;
    const { weekDaysText, calendarDays } = useWeekDaysEncoder();
    const calDays = calendarDays(sequence?.assignCalendarDays || "");
    const daysText = weekDaysText(sequence?.assignWeekDays || 0)
        + (calDays.length > 0 ? " + " + calDays.length + ` ${strings.General.Days}` : "");
    const toast = useToast();
    const [assignModalType, setAssignModalType] = useState<SequenceAssignType>();
    const [membersPickerType, setMembersPickerType] = useState<"assignee" | "owners">();
    const setSelectedMembers = useSetRecoilState(pickerMembersState);
    const { updateSequence } = useSequenceSaver();
    const setPickerMembersConfig = useSetRecoilState(pickerMembersConfigState);

    if (!sequence) {
        return null;
    }

    const onSelectMember = () => {
        setMembersPickerType('assignee');
    };

    const closeMemberPickerModal = () => {
        setMembersPickerType(undefined);
    };

    const onOwnersClick = () => {
        setPickerMembersConfig({
            workers: false,
            operators: true,
            admins: true,
            excludedUids: [],
        });
        setSelectedMembers((prev) => prev.map((member) => ({ ...member, selected: ownerUids.includes(member.uid) })));
        setMembersPickerType('owners');
    };

    const handleOpenAssignModal = (type: SequenceAssignType) => {
        setAssignModalType(type);
    }

    const handleCloseAssignModal = () => {
        setAssignModalType(undefined);
    }

    const onAcceptAssignee = async (members: Member[]) => {
        if (members.length) {
            const { status, jobs } = await assignSequence(sequence.id, members[0].uid, date);
            if (status) {
                stateUpdater.updateJobs(jobs);
                toast.showSuccess(
                    <Space>
                        <VFlex spacing style={{ alignItems: "start" }}>
                            <LabelMedium color={theme.colors.contentPrimary}>{sequence.name}</LabelMedium>
                            <LabelSmall
                                color={theme.colors.contentPrimary}>{strings.General.SuccessAssignTo}</LabelSmall>
                            <MemberBadges members={members.map(m => m.uid)}/>
                        </VFlex>
                    </Space>
                )
            }
        }
    };

    const onUpdateOwners = async (members: Member[]) => {
        const newSequence = {
            ...sequence,
            jobs: sequence.jobs.map((job) => job.id),
            owners: members.map((member) => member.uid).join(','),
        } as SavableSequence;
        await updateSequence(newSequence, setSequence);
        closeMemberPickerModal();
    };

    const colId = SEQ_COL_ID_PREFIX + sequence.id;

    return (
        <ColumnCard
            color={"#888888"}
            title={(
                <HFlex alignCenter spacing>
                    <SequenceIcon color={theme.colors.contentPrimary} size={16}/>
                    <LabelSmall>{sequence.name}</LabelSmall>
                    <SpacerExpand/>
                    <HoverButton tooltip={strings.General.RemoveFromBoard}
                                 kind={"tertiary"}
                                 onClick={() => board.removeSequence(id)}
                    >
                        <RemoveIcon size={14} color={theme.colors.contentPrimary}/>
                    </HoverButton>
                </HFlex>)}
        >
            <VFlex style={{
                width: "100%",
                height: "fit-content",
            }}>
                <Card>
                    <HFlex alignCenter>
                        <LabelXSmall>{strings.General.AssignOnceNow}</LabelXSmall>
                        <SpacerExpand/>
                        <Button size={"mini"} kind={"tertiary"} onClick={onSelectMember}>
                            {strings.Members.SelectMember}
                        </Button>
                    </HFlex>
                </Card>
                <Spacer/>
                <Card>
                    <HFlex alignCenter>
                        <LabelXSmall>{strings.General.RecurringlyAssignTo}</LabelXSmall>
                        <SpacerExpand/>
                        <Button size={"mini"} kind={"tertiary"} onClick={() => handleOpenAssignModal("member")}>
                            {assignedMember?.name || strings.Members.SelectMember}
                        </Button>
                    </HFlex>
                    <HFlex alignCenter>
                        <LabelXSmall>{strings.General.Every}</LabelXSmall>
                        <SpacerExpand/>
                        <Button size={"mini"} kind={"tertiary"} onClick={() => handleOpenAssignModal("day")}>
                            {daysText}
                        </Button>
                    </HFlex>
                    <HFlex alignCenter>
                        {ownerUids.length === 0 &&
                            <ActionButton tooltip={strings.Sequences.VisibleForAllOperators} size={"mini"}
                                          kind={"tertiary"} onClick={onOwnersClick}>
                                <UnlockedIcon size={14} color={theme.colors.contentTertiary}/>
                            </ActionButton>
                        }
                        {ownerUids.length > 0 &&
                            <ActionButton tooltip={strings.Sequences.Restricted} size={"mini"} kind={"tertiary"}
                                          onClick={onOwnersClick}>
                                <LockIcon size={14} color={theme.colors.contentTertiary}/>
                            </ActionButton>
                        }
                        <SpacerExpand/>
                        {ownerUids.length > 0 &&
                            <Button size={"mini"} kind={"tertiary"} onClick={onOwnersClick}>
                                <MemberBadges members={ownerUids} max={1}/>
                            </Button>
                        }
                    </HFlex>
                </Card>
                <Spacer height={"24px"}/>
                <Droppable droppableId={colId} type={DROPPABLE_TYPE_JOB}>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                backgroundColor: snapshot.isDraggingOver ? hoverColor : "inherit",
                            }}
                        >
                            <SequenceJobList id={id}/>
                            {provided.placeholder}
                            <Spacer/>
                        </div>
                    )}
                </Droppable>
            </VFlex>
            {createPortal(
                <StyledModal isOpen={!!membersPickerType} onClose={closeMemberPickerModal}>
                    {membersPickerType === 'assignee' && (
                        <MembersPickerModalContent
                            closeModal={closeMemberPickerModal}
                            onAccept={onAcceptAssignee}
                            title={strings.formatString(strings.General.SelectMemberToAutoAssignX, sequence.name)}
                            multi={false}
                        />
                    )}
                    {membersPickerType === 'owners' && (
                        <MembersPickerModalContent
                            closeModal={closeMemberPickerModal}
                            onAccept={onUpdateOwners}
                            title={format(strings.Sequences.SelectOwnersForX, sequence.name)}
                            helper={
                                <ParagraphSmall>{strings.Sequences.SelectOwnersDesc}</ParagraphSmall>
                            }
                            extraActions={
                                <Button
                                    onClick={() => onUpdateOwners([])}
                                    size='compact'
                                    kind='tertiary'
                                >
                                    {strings.General.None}
                                </Button>
                            }
                        />
                    )}
                </StyledModal>,
                document.body
            )}
            {createPortal(
                <StyledModal isOpen={!!assignModalType} onClose={handleCloseAssignModal} defaultSize animate={!!assignModalType}>
                    <SequenceAssignModalContent
                        id={sequence.id}
                        closeModal={handleCloseAssignModal}
                        type={assignModalType}
                    />
                </StyledModal>,
                document.body
            )}
        </ColumnCard>
    );
}
