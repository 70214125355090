import { ReactNode } from "react";
import { LabelXSmall } from "baseui/typography";
import { HFlex } from "./HFlex";

type Props = {
    label: string;
    labelWidth?: number;
    children: ReactNode,
};

export function Labeled({ label, labelWidth = 150, children }: Props) {

    return (
        <HFlex alignCenter style={{ marginTop: "2px", maxWidth: "400px", flexShrink: 0 }}>
            <LabelXSmall style={{ flexShrink: 0, width: labelWidth }}>{label}</LabelXSmall>
            {children}
        </HFlex>
    );
}