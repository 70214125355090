import { useRecoilState } from 'recoil';
import { tracksSortDirectionState, tracksSortState } from '../../states/tracksState';
import { getNextDirection } from '../../utils/sort';
import { SORT_DIRECTION } from "baseui/table";

export function useTracksSort() {
    const [direction, setDirection] = useRecoilState(tracksSortDirectionState);
    const [tracksSort, setTracksSort] = useRecoilState(tracksSortState);

    const handleSort = (sortNumber: number) => {
        if (tracksSort !== sortNumber) {
            setTracksSort(sortNumber);
            setDirection(SORT_DIRECTION.ASC);
        } else {
            setTracksSort(sortNumber);
            setDirection(getNextDirection(direction));
        }
    };

    const getDirection = (sortNumber: number) => {
        return tracksSort === sortNumber ? direction : null;
    }

    return {
        handleSort,
        getDirection,
    };
}