export const COLOR_WHITE = "#ffffff";
export const COLOR_OFFWHITE = "#f9f9f7";
export const COLOR_SNOW = "#f4f4f4";
export const COLOR_CLOUDS = "#e1e1e1";
export const COLOR_SILVER = "#dadada";
export const COLOR_CONCRETE = "#999999";
export const COLOR_ASPHALT = "#666666";
export const COLOR_ASBESTOS = "#444444";
export const COLOR_ONYX = "#3a3a3a";
export const COLOR_SUPERDARK = "#111111";
export const COLOR_BLACK = "#000000";

export const COLOR_MOVING = "#346DF1";
export const COLOR_IDLING = "#CCCCCC";

export const COLOR_SUCCESS = "#00CC00";
export const COLOR_ISSUE = "#fd4b29";
export const COLOR_UNASSIGNED = "#aaaaaa";
export const COLOR_SCHEDULED = "#263238";
export const COLOR_ASSIGNED_ACCEPTED = "#199DD5";
export const COLOR_IN_PROGRESS = "#FFA000";
export const COLOR_REJECTED = "#fd4b29";
export const COLOR_DAYROUTE = COLOR_SUPERDARK;
export const COLOR_DAYROUTE1 = COLOR_DAYROUTE;
export const COLOR_DAYROUTE2 = COLOR_DAYROUTE;
export const COLOR_DAYROUTE3 = COLOR_DAYROUTE;
export const TEAM_ORANGE = "#DC7633";
export const TEAM_SECONDARY = "#84461e";
export const MEMBER_BLUE = "#2196F3";
export const COLOR_MARINE = "#4981CE";
export const COLOR_ACCENT = "#22C328";
export const COLOR_DANGER = "#FF5733";
export const COLOR_JOB_COUNT = "#9A7D0A";
export const COLOR_JOB_SELECTED = "#1766D4";
export const COLOR_COMPANY = "#8B008B";

export const COLOR_GREEN = "#22C328";
export const COLOR_YELLOW = "#D7D742";
export const COLOR_ORANGE = "#DD9A3C";
export const COLOR_RED = "#CC3333";
export const COLOR_PURPLE = "#8C28BD";
export const COLOR_BLUE = "#3366CC";
export const COLOR_GRAY = "#888888";
export const COLOR_STEELGRAY = "#71797E";

export const COLOR_ARRAY = [
    '#B20000', // Dark Red
    '#B23000', // Dark Orange-Red
    '#B25900', // Dark Orange
    '#B27A00', // Dark Orange-Yellow
    '#B29B00', // Dark Gold
    '#B2B200', // Dark Yellow
    '#6EB200', // Dark Green-Yellow
    '#39B200', // Dark Chartreuse
    '#00B200', // Dark Lime (Pure Green)
    '#00B257', // Dark Spring Green
    '#00B27B', // Dark Medium Spring Green
    '#00B29C', // Dark Light Sea Green
    '#00B2B2', // Dark Aqua (Pure Cyan)
    '#0079B2', // Dark Deep Sky Blue
    '#003EB2', // Dark Dodger Blue
    '#0026B2', // Dark Royal Blue
    '#0000B2', // Dark Blue (Pure Blue)
    '#5800B2', // Dark Blue Violet
    '#6E00B2', // Dark Violet
    '#580058'  // Dark Purple
]

export const COLOR_PLACE_DEFAULT = "#FA605C";
export const COLOR_PLACE_STROKE = "#7B3130";

export const COLOR_LOGIN_BACKGROUND = "#242626";

export const COLOR_JOB_NEEDLE = COLOR_SUPERDARK;
export const COLOR_TRIP = "#33B5E5";

export const COLOR_TRANSLUCENT = "#8f8f9224";
export const COLOR_TRANSLUCENT_LIGHT = "rgba(0, 0, 0, 0.02)";
