import { Clickable } from "../containers/Clickable";
import { ListItem } from "baseui/list";
import * as React from "react";
import { forwardRef, memo, useEffect } from "react";
import { useListItem } from "../../hooks/useListItem";

type Props = {
    isSelected?: boolean;
    isDraggingOver?: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    onHover?: React.ComponentType<{}>;
    offHover?: React.ComponentType<{}>;
    onHoverChange?: (isHover: boolean) => void;
    children: React.ReactNode;
    shrink?: boolean;
}

export const ClickableListItem = memo(forwardRef<HTMLDivElement, Props>(({
                                                                        isSelected = false,
                                                                        isDraggingOver = false,
                                                                        onClick,
                                                                        onHover,
                                                                        offHover,
                                                                        onHoverChange,
                                                                        children,
                                                                        shrink = false
                                                                    }: Props, ref) => {
    const [isHovering, setIsHovering] = React.useState(false);
    const { listItemRootStyle, listItemContentStyle } = useListItem();

    useEffect(() => onHoverChange && onHoverChange(isHovering), [isHovering]);

    return (
        <Clickable ref={ref} onClick={onClick}>
            <ListItem
                endEnhancer={isHovering ? onHover : offHover}
                overrides={{
                    Root: {
                        style: listItemRootStyle({ isSelected, isDraggingOver }),
                        props: {
                            onMouseEnter: () => {
                                setIsHovering(true)
                            },
                            onMouseLeave: () => {
                                setIsHovering(false)
                            }
                        }
                    },
                    Content: {
                        style: listItemContentStyle({ isSelected, isHovering, small: shrink }),
                    },
                }}
            >
                {children}
            </ListItem>
        </Clickable>
    );
}));