import { ParagraphSmall } from 'baseui/typography';
import { useRecoilValue } from 'recoil';
import { useTheme } from '../../hooks/useTheme';
import { stringsState } from '../../states/localeState';

type Props = {
    required?: boolean;
};

export function FieldType({ required = false }: Props) {
    const strings = useRecoilValue(stringsState);
    const { theme, css } = useTheme();

    if (!required) {
        return null;
    }

    return (
        <ParagraphSmall
            className={css({ marginLeft: theme.sizing.scale100 })}
            margin={0}
            color={theme.colors.contentTertiary}
        >
            {required && "*"}
        </ParagraphSmall>
    );
}
