import { ConfirmModal } from "../ui/ConfirmModal";
import React from "react";
import { useStrings } from "../../hooks/useStrings";
import { useSetRecoilState } from "recoil";
import { paymentMethodReminderTsState } from "../../states/accountState";
import useAccount from "../../hooks/useAccount";
import { companyTabFilterState, editingCompanyIdState } from "../../states/appState";
import { useBilling } from "../../hooks/useBilling";

export function EnterPaymentMethodReminder() {
    const { strings, formatDate } = useStrings();
    const setReminderTs = useSetRecoilState(paymentMethodReminderTsState);
    const { company, isBlocked } = useAccount();
    const { endOfTrial } = useBilling();
    const setEditingCompany = useSetRecoilState(editingCompanyIdState);
    const setCompanyTabFilter = useSetRecoilState(companyTabFilterState);

    if (!company || isBlocked) {
        return null;
    }

    const onCancel = () => {
        setReminderTs(Date.now);
    };

    const onConfirm = () => {
        setReminderTs(Date.now);
        setCompanyTabFilter("billing");
        setEditingCompany(company.uid);
    };

    return (
        <ConfirmModal
            isOpen
            title={formatDate(strings.Billing.TrialingUntil, endOfTrial)}
            description={strings.Billing.ReminderMessage}
            onCancel={onCancel}
            onConfirm={onConfirm}
            confirmText={strings.General.Billing}
            cancelText={strings.General.Cancel}
        />
    );
}