import { DetailedLocation } from '../../types/core';
import { useRecoilState, useRecoilValue } from "recoil";
import { editingJobIdState } from "../../states/appState";
import { editJobState } from "../../states/editJobState";
import { Job } from "../../types/job";
import { useDrawMode } from "../useDrawMode";

export function useEditJobLocation() {
    const editingJob = useRecoilValue(editingJobIdState);
    const [jobFields, setJobFields] = useRecoilState(editJobState(editingJob || ""));
    const { drawMode, enterDrawMode } = useDrawMode();

    const location = drawMode?.location;

    const initEditJob = () => {
        if (editingJob !== undefined && jobFields) {
            enterDrawMode({
                type: "job",
                action: "edit",
                location: {
                    address: jobFields.destinationText,
                    lat: jobFields.destinationLat,
                    lng: jobFields.destinationLng,
                    uid: jobFields.placeUid || "",
                } as DetailedLocation
            })
        }
    }

    const editJob = () => {
        if (location) {
            setJobFields((prev) => ({
                ...prev,
                destinationText: location.address,
                destinationLat: location?.lat || 0,
                destinationLng: location?.lng || 0,
                placeUid: location.uid || "",
            } as Job));
        }
        enterDrawMode({
            type: "job",
            action: "edit",
            location,
        })
    };

    return {
        initEditJob,
        editJob,
    };
}