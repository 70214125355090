import { flushSync } from 'react-dom';
import { emailValidator } from './../constants/validators';
import { checkUserAvailability, sendPersonalize, webSignup } from './../services/privateApi';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { currentLanguageState } from '../states/localeState';
import useAccount from './useAccount';
import useDirector from './useDirector';
import { useNavigator } from './useNavigator';
import { PAGE } from '../constants/pages';
import {
    getBrowserDateFormat,
    getBrowserDistanceFormat,
    getBrowserTimeFormat,
    getBrowserTimezone
} from "../utils/browser";
import { getPasswordValidation } from "../utils/password";


export function useSignup() {
    const account = useAccount();
    const director = useDirector();
    const emailIsValid = !!account.username && emailValidator.test(account.username);
    const hasEmailError = !!account.username && !emailIsValid;
    const [isLoading, setIsLoading] = useState(false);
    const lang = useRecoilValue(currentLanguageState);
    const { navigateToPage } = useNavigator();

    const userIsAvailable = async () => {
        setIsLoading(true);
        const { status, available } = await checkUserAvailability({
            username: account.username,
        });
        setIsLoading(false);
        return {
            error: !status,
            available: available || false,
        }
    }

    const createAccount = async () => {
        setIsLoading(true);
        const data = {
            "name": account.name,
            "username": account.username,
            "password": account.password,
            "language": lang,
            "accounttype": "person",
            "business": true,
            "timezone": getBrowserTimezone(),
            "timeformat": getBrowserTimeFormat(),
            "dateformat": getBrowserDateFormat(),
            "distance": getBrowserDistanceFormat(),
            "query": window.location.search,
        }
        const { status, loginToken, userUid, errorCode } = await webSignup(data);
        if (status && userUid && loginToken) {
            account.setUid(userUid);
            account.setToken(loginToken);
            flushSync(async () => {
                await director.performLogin(true);
            });
        }
        setIsLoading(false);
        return {
            errorCode,
        };
    }

    const personalize = async (data = {}) => {
        setIsLoading(true);
        if (!account.uid) {
            return;
        }
        const newData = {
            "useruid": account.uid,
            ...data,
        }
        await sendPersonalize(newData);
        setIsLoading(false);
        navigateToPage(PAGE.LIVE);
    }

    const passwordValid = getPasswordValidation(account.password);

    return {
        userIsAvailable,
        hasEmailError,
        passwordValid,
        createAccount,
        personalize,
        isLoading,
    };
}