import { Button } from 'baseui/button';
import { useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useEffectOnce } from 'usehooks-ts';
import { useStyleOverrides } from '../../../hooks/useStyleOverrides';
import { useTeamForms } from '../../../hooks/members/useTeamForms';
import { useTeamManagement } from '../../../hooks/members/useTeamManagement';
import { availableTeamIdState, fieldsForTeamEditState } from '../../../states/editTeamState';
import { stringsState } from '../../../states/localeState';
import {
    pickerMembersConfigState,
    pickerMembersState,
    pickerSelectedMembersState
} from '../../../states/membersPickerState';
import { SpacerExpand } from '../../containers/Spacer';
import { StyledModalContent } from '../../ui/StyledModalContent';
import { NewTeam } from './NewTeam';

type Props = {
    closeModal: () => void,
}

export function NewTeamModalContent({ closeModal }: Props) {
    const strings = useRecoilValue(stringsState);
    const selectedMembers = useRecoilValue(pickerSelectedMembersState);
    const resetPickerMembers = useResetRecoilState(pickerMembersState);
    const resetPickerMemberConfig = useResetRecoilState(pickerMembersConfigState);
    const newTeamId = useRecoilValue(availableTeamIdState);
    const teamFields = useRecoilValue(fieldsForTeamEditState(newTeamId));
    const resetTeamFields = useResetRecoilState(fieldsForTeamEditState(newTeamId));
    const [isLoading, setIsLoading] = useState(false);
    const { onSubmit } = useTeamForms(newTeamId, true);
    const { addMembers } = useTeamManagement();

    const canSubmit = teamFields.name;

    const { cancelButtonStyles } = useStyleOverrides();

    useEffectOnce(() => {
        return () => {
            resetPickerMemberConfig();
            resetTeamFields();
            resetPickerMembers();
        }
    });

    const handleSubmit = async () => {
        setIsLoading(true);
        await onSubmit();
        await addMembers(newTeamId, selectedMembers);
        setIsLoading(false);
        closeModal();
    }

    return (
        <StyledModalContent
            header={strings.TeamManagement.NewTeam}
            body={
                <NewTeam/>
            }
            footer={
                <>
                    <SpacerExpand/>
                    <Button
                        onClick={closeModal}
                        size='compact'
                        kind='tertiary'
                        overrides={cancelButtonStyles}
                    >
                        {strings.General.Cancel}
                    </Button>
                    <Button
                        onClick={() => handleSubmit()}
                        size='compact'
                        isLoading={isLoading}
                        disabled={!canSubmit}
                    >
                        {strings.General.Save}
                    </Button>
                </>
            }
        />
    );
}