import { forwardRef, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import MediumCardContent from "../../../views/MediumCardContent";
import { StyledCard } from "../../ui/StyledCard";
import { useCardTitles } from "../hooks/useCardTitles";
import { draggingState } from "../../../states/appState";
import { useTheme } from "../../../hooks/useTheme";
import { useHover } from "usehooks-ts";
import { hoveringMediumCardState } from "../../../states/viewState";
import { visibleCardsState } from "../../../states/skeletonState";

type Props = {}

export const SkeletonMediumCard = forwardRef<HTMLDivElement, Props>((props, ref: any) => {
    const { css } = useTheme();
    const { mediumCardTitle, mediumCardTitleNode } = useCardTitles();
    const isDragging = useRecoilValue(draggingState);
    const isHovering = useHover(ref);
    const setMediumHover = useSetRecoilState(hoveringMediumCardState);
    const { mediumIsVisible } = useRecoilValue(visibleCardsState);

    useEffect(() => {
        setMediumHover(isHovering);
        return () => setMediumHover(false);
    }, [isHovering]);

    return (
        <div
            ref={ref}
            className={css({
                height: "100%",
                position: "relative",
                overflow: "visible",
                userSelect: "none",
                transition: "transform 0.25s ease-out",
                transform: mediumIsVisible ? "translateX(0)" : "translateX(-100%)",
            })}
        >
            <StyledCard
                blur={!isDragging}
                title={mediumCardTitle}
                actions={mediumCardTitleNode}
                rounded={false}
                border={true}
                card={"medium"}
            >
                <MediumCardContent/>
            </StyledCard>
        </div>
    );
});
