import { ORIENTATION } from 'baseui/datepicker';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { useRecoilState, useRecoilValue } from 'recoil';
import { JOB_TABS } from '../../../constants/tabs';
import { editingJobIdState, jobActiveTabState } from '../../../states/appState';
import { jobState } from '../../../states/jobsState';
import { stringsState } from '../../../states/localeState';
import { JobHistoryTab } from "./JobHistoryTab";
import { JobInfoTab } from './JobInfoTab';
import { JobStaffInputTab } from './JobStaffInputTab';
import { useJobForms } from "../../../hooks/jobs/useJobForms";
import { JobInputFormTab } from "./JobInputFormTab";
import React, { useEffect } from "react";
import { tabOverridesVertical } from "../../../constants/ui";
import { JobAttachmentsSection } from "./JobAttachmentsSection";
import { NoWrapText } from '../../containers/NoWrapText';
import { SectionedWrapper } from "../../containers/SectionedWrapper";
import { JobLinkedSection } from "./JobLinkedSection";
import { Spacer } from "../../containers/Spacer";

export function JobTabs() {
    const strings = useRecoilValue(stringsState);
    const jobId = useRecoilValue(editingJobIdState);
    const [jobActiveTab, setJobActiveTab] = useRecoilState(jobActiveTabState);
    const job = useRecoilValue(jobState(jobId));
    const { isNewJob } = useJobForms();
    const tabOverrides = tabOverridesVertical;

    useEffect(() => setJobActiveTab(JOB_TABS.jobInfo), []);

    return (
        <Tabs
            onChange={({ activeKey }) => {
                setJobActiveTab(activeKey as string);
            }}
            orientation={ORIENTATION.vertical}
            activeKey={jobActiveTab}
            activateOnFocus
            overrides={{
                Root: {
                    style: {
                        height: "70vh",
                    }
                }
            }}
        >
            <Tab
                key={JOB_TABS.jobInfo}
                title={
                    <NoWrapText>{strings.Job.JobInfo}</NoWrapText>
                }
                overrides={tabOverrides}
            >
                <JobInfoTab/>
            </Tab>
            <Tab
                key={JOB_TABS.attachments}
                title={
                    <NoWrapText>{strings.Attachments.Attachments}</NoWrapText>
                }
                overrides={tabOverrides}
            >
                <SectionedWrapper>
                    <JobAttachmentsSection/>
                    <JobLinkedSection/>
                    <Spacer height={"48px"}/>
                </SectionedWrapper>
            </Tab>
            <Tab
                key={JOB_TABS.shortForm}
                title={
                    <NoWrapText>{strings.General.ConfigureShortForm}</NoWrapText>
                }
                overrides={tabOverrides}
            >
                <JobInputFormTab/>
            </Tab>
            {!isNewJob &&
                <Tab
                    key={JOB_TABS.staffInput}
                    title={
                        <NoWrapText>{strings.Job.StaffInput}</NoWrapText>
                    }
                    overrides={tabOverrides}
                >
                    <JobStaffInputTab/>
                </Tab>
            }
            {!isNewJob && !job?.isPartOfSequence && !job?.isTemplate && (
                <Tab
                    key={JOB_TABS.statusHistory}
                    title={
                        <NoWrapText>{strings.Job.StatusHistory}</NoWrapText>
                    }
                    overrides={tabOverrides}
                >
                    <JobHistoryTab/>
                </Tab>
            )}
        </Tabs>
    );
}
