import React from 'react';
import { BarStackHorizontal } from '@visx/shape';
import { SeriesPoint } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { defaultStyles, Tooltip, withTooltip } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { LegendOrdinal } from '@visx/legend';
import { useTime } from "../../../hooks/useTime";
import { Skeleton } from "baseui/skeleton";
import { useUnit } from "../../../hooks/useUnit";
import { RadialGradient } from "@visx/gradient";
import { randomNumber } from "../../../utils/app";
import { COLOR_ORANGE } from "../../../constants/colors";
import { asNumber } from "../../../utils/objects";
import { HOUR, MINUTE } from "../../../utils/time";


function getTicks([start, end]: [number, number], numTicks: number) {
    const range = end - start;
    const exactInterval = Math.round(range / numTicks);
    const niceIntervals = [15 * MINUTE, 30 * MINUTE, 60 * MINUTE, 2 * HOUR, 3 * HOUR, 6 * HOUR, 12 * HOUR, 24 * HOUR, 100 * HOUR];
    const interval = niceIntervals.find((i) => i >= exactInterval) || niceIntervals[niceIntervals.length - 1];
    const ticks = [];
    for (let t = start; t <= end; t += interval) {
        ticks.push(t);
    }
    return ticks;
}

type TooltipData = {
    bar: SeriesPoint<Datum>;
    key: Category;
    index: number;
    height: number;
    width: number;
    x: number;
    y: number;
    color: string;
};

export type BarStackHorizontalProps = {
    width: number;
    height: number;
    margin?: { top: number; right: number; bottom: number; left: number };
    events?: boolean;
};

const color1 = COLOR_ORANGE + "EE";
const color2 = "#FFFFFFBB";
const color3 = "#EEEEEE55";
const defaultMargin = { top: 40, left: 150, right: 40, bottom: 40 };
const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: 'rgba(0,0,0,0.9)',
    color: 'white',
};

let tooltipTimeout: number;

export interface Datum {
    [key: string]: string | number;

    bucketField: string;
}

export type Category = string;

type BarsProps = {
    data: Datum[],
    categories: Category[],
    labels: string[],
    fetching: boolean;
    type?: "time" | "distance" | "count";
    numTicks?: number;
    debug?: boolean;
}

export const StackedBars = withTooltip<BarsProps & BarStackHorizontalProps, TooltipData>(
    ({
         width,
         height,
         events = false,
         margin = defaultMargin,
         tooltipOpen,
         tooltipLeft,
         tooltipTop,
         tooltipData,
         hideTooltip,
         showTooltip,
         data,
         categories,
         labels,
         fetching,
         type = "time",
         numTicks = 6,
     }: BarsProps & BarStackHorizontalProps & WithTooltipProvidedProps<TooltipData>) => {

        const time = useTime();
        const unit = useUnit();
        const keys = categories;
        const totals = data.reduce((allTotals, currentDate) => {
            const total = keys.reduce((dailyTotal, k) => {
                dailyTotal += Number(currentDate[k]);
                return dailyTotal;
            }, 0);
            allTotals.push(total);
            return allTotals;
        }, [] as number[]);

        const valueScale = scaleLinear<number>({
            domain: [0, Math.max(...totals)],
            nice: true,
        });
        const bucketScale = scaleBand<string>({
            domain: data.map(d => d.bucketField),
            padding: 0.2,
            range: [0, data.length],
        });
        const colorScale = scaleOrdinal<Category, string>({
            domain: keys,
            range: [color1, color2, color3],
        });
        const labelScale = scaleOrdinal<Category, string>({
            domain: keys,
            range: labels,
        });

        // bounds
        const xMax = width - margin.left - margin.right;
        const yMax = height - margin.top - margin.bottom;

        valueScale.rangeRound([0, xMax]);
        bucketScale.rangeRound([yMax, 0]);

        if (fetching) {
            return <Skeleton width={width + "px"} height={height + "px"} animation overrides={{
                Root: {
                    style: {
                        borderRadius: "12px"
                    }
                }
            }}/>
        }

        const id = randomNumber() + "";

        let categoryToLabelMap = categories.reduce((map, cat, index) => {
            map.set(cat, labels[index]);
            return map;
        }, new Map());

        const ticks = type === "time" ? getTicks([0, Math.max(...totals)], numTicks) : undefined;

        return width < 10 ? null : (
            <div>
                <svg width={width} height={height}>
                    <RadialGradient id={id} from="#444444" to="#222222" r="80%"/>
                    <rect rx={8} width={width} height={height} fill={`url(#${id})`}/>

                    <Group top={margin.top} left={margin.left}>
                        <BarStackHorizontal<Datum, Category>
                            data={data}
                            keys={keys}
                            height={yMax}
                            y={d => d.bucketField}
                            xScale={valueScale}
                            yScale={bucketScale}
                            color={colorScale}
                        >
                            {(barStacks) =>
                                barStacks.map((barStack) =>
                                    barStack.bars.map((bar) => (
                                        <rect
                                            key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                                            x={bar.x}
                                            y={bar.y}
                                            width={bar.width}
                                            height={bar.height}
                                            fill={bar.color}
                                            onMouseLeave={() => {
                                                tooltipTimeout = window.setTimeout(() => {
                                                    hideTooltip();
                                                }, 300);
                                            }}
                                            onMouseMove={() => {
                                                if (tooltipTimeout) {
                                                    clearTimeout(tooltipTimeout);
                                                }
                                                const top = bar.y + margin.top;
                                                const left = bar.x + bar.width + margin.left;
                                                showTooltip({
                                                    tooltipData: bar,
                                                    tooltipTop: top,
                                                    tooltipLeft: left,
                                                });
                                            }}
                                        />
                                    )),
                                )
                            }
                        </BarStackHorizontal>
                        <AxisLeft
                            hideAxisLine
                            hideTicks
                            scale={bucketScale}
                            stroke={color3}
                            tickStroke={color3}
                            tickLabelProps={{
                                fill: "white",
                                fontSize: 11,
                                textAnchor: 'end',
                                dy: '0.33em',
                            }}
                            tickValues={bucketScale.domain()}
                        />
                        <AxisBottom
                            top={yMax}
                            scale={valueScale}
                            stroke={"white"}
                            tickFormat={(value) => {
                                if (type === "time") {
                                    return time.formatDuration(Number(value))
                                } else if (type === "distance") {
                                    return unit.distanceToString(Number(value), true);
                                } else {
                                    return Number(value) + "";
                                }
                            }}
                            tickValues={ticks}
                            tickStroke={"white"}
                            tickLabelProps={{
                                fill: "white",
                                fontSize: 11,
                                textAnchor: 'middle',
                            }}
                        />
                    </Group>
                </svg>
                <div
                    style={{
                        position: 'absolute',
                        top: margin.top / 2 - 10,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '14px',
                        color: "white",
                    }}
                >
                    <LegendOrdinal labelFormat={labelScale}
                                   scale={colorScale}
                                   direction="row"
                                   labelMargin="0 15px 0 0"/>
                </div>
                {tooltipOpen && tooltipData && (
                    <Tooltip top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
                        <div style={{ color: colorScale(tooltipData.key) }}>
                            <strong>{categoryToLabelMap.get(tooltipData.key)}</strong>
                        </div>
                        <div>
                            <strong>{tooltipData.bar.data.bucketField}</strong>
                        </div>
                        <div>
                            <strong>{type === "time"
                                ? time.formatDuration(asNumber(tooltipData.bar.data, tooltipData.key))
                                : type === "distance" ? unit.distanceToString(asNumber(tooltipData.bar.data, tooltipData.key))
                                    : "" + asNumber(tooltipData.bar.data, tooltipData.key)
                            }</strong>
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    },
);