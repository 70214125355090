import { Button } from "baseui/button";
import { StyledHead, StyledHeadCell } from "baseui/table";
import { tableHeaderStyle } from "../../constants/ui";
import { useBoard } from "../../hooks/useBoard";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { useTracksSelection } from "../../hooks/tracks/useTracksSelection";
import { TristateCheckbox } from "../ui/TristateCheckbox";
import { useTrackColumns } from "../../hooks/tracks/useTrackColumns";
import { useTables } from "../../hooks/useTables";
import { useTheme } from "../../hooks/useTheme";
import { TRACKS_NAME_COLUMN, TRACKS_OPTIONS_COLUMN, TRACKS_SELECT_COLUMN } from "../../constants/tracks";
import { Fragment } from "react";

export function LocationHeaderRow() {
    const { isDarkTheme } = useTheme();
    const tracksSelection = useTracksSelection();
    const { fixedColumnStyle } = useStyleOverrides();
    const multipleMembersSelected = useBoard().memberUids.length > 1;
    const selectionCount = tracksSelection.selectionCount;
    const allSelected = tracksSelection.allSelected;
    const { columnsConfiguration, tableWidth, columnsWidth, enabledColumns } = useTrackColumns();
    const { getHeadCell } = useTables(columnsConfiguration);

    return (
        <StyledHead role="row" $width={`${tableWidth}px`} style={{
            minWidth: "100%",
            backgroundColor: isDarkTheme ? "#00000088" : "#FFFFFF88"
        }}>
            <StyledHeadCell $style={{ ...fixedColumnStyle(columnsWidth[TRACKS_SELECT_COLUMN]), ...tableHeaderStyle, justifyContent: "center" }}>
                <Button
                    size="mini"
                    kind="tertiary"
                    onClick={(event) => {
                        if (selectionCount > 0) {
                            tracksSelection.clearSelection();
                        } else {
                            tracksSelection.selectAll();
                        }
                    }}
                >
                    <TristateCheckbox allSelected={allSelected} anySelected={selectionCount > 0}/>
                </Button>
            </StyledHeadCell>
            {multipleMembersSelected && (
                <>
                    {getHeadCell(TRACKS_NAME_COLUMN)}
                </>
            )}
            {enabledColumns.map((column, index) => (
                <Fragment key={column+index}>
                    {getHeadCell(column)}
                </Fragment>
            ))}
            <StyledHeadCell
                $style={{ ...fixedColumnStyle(columnsWidth[TRACKS_OPTIONS_COLUMN]), ...tableHeaderStyle }}/>
        </StyledHead>
    );
}