import { getDiffFields } from './../utils/objects';
import { emailValidator } from './../constants/validators';
import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { Member } from './../types/member';
import { memberState } from './membersState';
import { editingMemberIdState } from './appState';
import { Uid } from "../types/core";

const editMemberInitializer = selectorFamily<Member, Uid>({
    key: 'edit.member.initializer',
    get: (id) => ({ get }) => {
        const member = get(memberState(id));
        return {
            ...member
        } as Member
    }
});

export const editMemberState = atomFamily<Member, Uid>({
    key: 'edit.member',
    default: editMemberInitializer,
});

export const editMemberProfileImageState = atom<File | null>({
    key: 'edit.member.profile.image',
    default: null,
});

export const editMemberValidState = selectorFamily<{ [key: string]: boolean }, Uid>({
    key: 'edit.member.valid',
    get: (id) => ({ get }) => {
        const fields = get(editMemberState(id));
        const validFields = Object.fromEntries(Object.keys(fields).map((key) => [key, true]));

        if (!!fields.email) {
            validFields['email'] = emailValidator.test(fields.email);
        }

        return validFields;
    }
});

export const editMemberErrorState = selectorFamily<boolean, Uid>({
    key: 'edit.member.error',
    get: (id) => ({ get }) => {
        const validFields = get(editMemberValidState(id));

        return Object.values(validFields).some((valid) => !valid)
    }
});

export const editMemberModifiedState = selector({
    key: 'edit.member.modified',
    get: ({ get }) => {
        const memberId = get(editingMemberIdState);
        const member = get(memberState(memberId));
        const memberFields = get(editMemberState(memberId));

        return getDiffFields(member || {}, memberFields);
    },
});

export const editMemberHasModifiedState = selector({
    key: 'edit.member.modified.count',
    get: ({ get }) => {
        return !!Object.keys(get(editMemberModifiedState)).length;
    },
});
