import { HFlex } from "../containers/HFlex";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { selectedConversationMemberState, selectedConversationState } from "../../states/messagesState";
import { SpacerExpand } from "../containers/Spacer";
import { ActionButton } from "../buttons/ActionButton";
import { useStrings } from "../../hooks/useStrings";
import { RemoveIcon } from "../ui/svg";
import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { MemberBadge } from "../badges/MemberBadge";

export function ConversationTitleBar() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const member = useRecoilValue(selectedConversationMemberState);
    const resetSelected = useResetRecoilState(selectedConversationState);

    return <HFlex spacing alignCenter>
        <SpacerExpand/>
        {member && <MemberBadge uid={member.uid} edit/>}
        <SpacerExpand/>
        <ActionButton tooltip={strings.General.Close} kind={"tertiary"} onClick={() => resetSelected()}>
            <RemoveIcon size={16} color={theme.colors.contentTertiary} style={{ flexShrink: 0 }}/>
        </ActionButton>
    </HFlex>
}