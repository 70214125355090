import { Descriptions, Statistic } from "antd";
import { COLOR_ISSUE, COLOR_SUCCESS } from "../../../constants/colors";
import { useTheme } from "../../../hooks/useTheme";
import { useStrings } from "../../../hooks/useStrings";
import { useTime } from "../../../hooks/useTime";
import { calcTotalsAndAverages, MemberStatDay } from "../../../types/stats";
import { Skeleton } from "baseui/skeleton";
import React from "react";

export function JobStats({
                             stats,
                             fetching,
                             width,
                             height
                         }: { stats: MemberStatDay[], fetching: boolean, width: number, height: number }) {
    const { theme } = useTheme();
    const { strings, format } = useStrings();
    const time = useTime();
    const data = stats.flatMap(s => s.data);
    const issueCount = data.map(d => d.issueCount).reduce((sum, current) => sum + current, 0);
    const successCount = data.map(d => d.successCount).reduce((sum, current) => sum + current, 0);
    const totalCount = data.map(d => d.totalJobs).reduce((sum, current) => sum + current, 0);
    const [totalJobOnsiteTimes, avgJobOnsiteTimes] = calcTotalsAndAverages(stats, 'totalJobOnsiteTime', 'avgJobOnsiteTime');
    const totalEngagedCount = data.map(d => d.engagedCount).reduce((sum, current) => sum + current, 0);
    const totalOnsiteTime = totalJobOnsiteTimes.reduce((sum, current) => sum + current, 0);
    const sumAvgOnsiteTime = avgJobOnsiteTimes.reduce((sum, current) => sum + current, 0);
    const avgJobOnsiteTime = sumAvgOnsiteTime > 0 ? sumAvgOnsiteTime / avgJobOnsiteTimes.length : 0;
    const avgTimeBetween = data.map(d => d.avgTimeBetweenJobs).reduce((sum, current) => sum + current, 0) / data.length;
    const delaysCount = data.map(d => d.delaysCount).reduce((sum, current) => sum + current, 0);
    const ontimeCount = data.map(d => d.ontimeCount).reduce((sum, current) => sum + current, 0);

    if (fetching) {
        return <Skeleton width={width + "px"} height={height + "px"} animation overrides={{
            Root: {
                style: {
                    borderRadius: "12px"
                }
            }
        }}/>
    }

    return (
        <Descriptions bordered column={1} size="small">
            <Descriptions.Item label={strings.Stats.TotalAssignedJobs}>
                <Statistic value={format(strings.Job.XJobs, totalCount)}
                           valueStyle={{ color: theme.colors.contentPrimary }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.Stats.SuccessRate}>
                <Statistic
                    value={totalCount && Math.round(successCount * 100 / totalCount) + "% (" + successCount + ")"}
                    valueStyle={{ color: COLOR_SUCCESS }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.Stats.IssueRate}>
                <Statistic value={totalCount && Math.round(issueCount * 100 / totalCount) + "% (" + issueCount + ")"}
                           valueStyle={{ color: COLOR_ISSUE }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.Stats.EngagementWithJobw}>
                <Statistic value={totalCount && Math.round(totalEngagedCount * 100 / totalCount) + "%"}
                           valueStyle={{ color: theme.colors.contentPrimary }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.Stats.TotalTimeAtJobs}>
                <Statistic value={time.formatDuration(totalOnsiteTime, true)}
                           valueStyle={{ color: theme.colors.contentPrimary }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.Stats.AvgTimeAtJobSites}>
                <Statistic value={time.formatDuration(avgJobOnsiteTime, true)}
                           valueStyle={{ color: theme.colors.contentPrimary }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.Stats.AvgTimeBetweenJobs}>
                <Statistic value={time.formatDuration(avgTimeBetween, true)}
                           valueStyle={{ color: theme.colors.contentPrimary }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.Stats.OnTime + " / " + strings.Stats.Delays}>
                <Statistic value={ontimeCount + " / " + delaysCount}
                           valueStyle={{ color: theme.colors.contentPrimary }}
                />
            </Descriptions.Item>
        </Descriptions>
    );
}