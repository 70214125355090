import { FloatButton } from "antd";
import { useRecoilState } from "recoil";
import { useTheme } from "../../hooks/useTheme";
import { mapToolState } from "../../states/mapState";
import { EyeAnimatedOpenCloseIcon, PointToolIcon, SelectionToolIcon, ViewIcon } from "../ui/svg";
import { COLOR_ACCENT } from "../../constants/colors";
import { useStrings } from "../../hooks/useStrings";
import { hideAllPanelsState } from "../../states/appState";
import { useDrawMode } from "../../hooks/useDrawMode";

export function ToolButtons() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const [tool, setTool] = useRecoilState(mapToolState);
    const [hideAllPanels, setHideAllPanels] = useRecoilState(hideAllPanelsState);
    const { drawMode } = useDrawMode();

    if (drawMode) {
        return null;
    }

    return (
        <FloatButton.Group
            shape="square"
            style={{
                top: "50%",
                right: "10px",
                width: "40px",
                zIndex: "auto",
                height: "fit-content",
                transform: "translateY(-50%)",
            }}
        >
            <FloatButton
                tooltip={strings.Map.SelectTool}
                icon={<SelectionToolIcon size={18}
                                         color={tool === "selection" ? COLOR_ACCENT : theme.colors.contentPrimary}/>}
                onClick={() => {
                    setTool(tool !== "selection" ? "selection" : undefined);
                }}
            />
            <FloatButton
                tooltip={strings.Map.PointTool}
                icon={<PointToolIcon size={18}
                                     color={tool === "point" ? COLOR_ACCENT : theme.colors.contentPrimary}/>}
                onClick={() => {
                    setTool(tool !== "point" ? "point" : undefined);
                }}
            />
            <FloatButton
                tooltip={strings.General.HideAllPanels}
                icon={hideAllPanels
                    ? <EyeAnimatedOpenCloseIcon size={18} color={theme.customColors.accent}/>
                    : <ViewIcon size={18} color={theme.colors.contentPrimary}/>}
                onClick={() => setHideAllPanels(old => !old)}
                style={{
                    backgroundColor: hideAllPanels ? theme.colors.backgroundInversePrimary : undefined,
                }}
            />
        </FloatButton.Group>
    );
}
