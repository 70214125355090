import {
    Job,
    PROGRESS_STATUS_ACCEPTED,
    PROGRESS_STATUS_IN_PROGRESS,
    PROGRESS_STATUS_ISSUE,
    PROGRESS_STATUS_REJECTED,
    PROGRESS_STATUS_SCHEDULED,
    PROGRESS_STATUS_SUCCESS,
    PROGRESS_STATUS_UNASSIGNED,
} from '../../types/job';
import { getColorFromProgress, getProgressStatus, numberOrLetter } from '../../utils/jobUtils';
import { BookmarkFillIcon } from "../../components/ui/svg";
import { useTheme } from "../useTheme";
import { useStrings } from "../useStrings";

export function useJobInfo() {
    const { strings } = useStrings();
    const { theme } = useTheme();

    const getStatusText = (job: Job) => {
        const validJob = !!Object.keys(job).length;
        if (!validJob) {
            return "";
        }
        return getStatusTextFromProgress(getProgressStatus(job));
    };

    const getStatusTextFromProgress = (progress: number) => {
        switch (progress) {
            case PROGRESS_STATUS_SUCCESS:
                return strings.JobStatuses.Done;
            case PROGRESS_STATUS_ISSUE:
                return strings.JobStatuses.Issue;
            case PROGRESS_STATUS_UNASSIGNED:
                return strings.JobStatuses.Unassigned;
            case PROGRESS_STATUS_IN_PROGRESS:
                return strings.JobStatuses.InProgress;
            case PROGRESS_STATUS_ACCEPTED:
                return strings.JobStatuses.Accepted;
            case PROGRESS_STATUS_SCHEDULED:
                return strings.JobStatuses.Scheduled;
            case PROGRESS_STATUS_REJECTED:
                return strings.JobStatuses.Rejected;
            default:
                return '';
        }
    }

    const getStatusColor = getColorFromProgress;

    const iconOrLetter = (job: Job) => {
        if (job.isTemplate) {
            return <BookmarkFillIcon size={10} color={theme.colors.primaryB}/>;
        }
        return numberOrLetter(job);
    }

    const getPriorityText = (priority: number) => {
        return priority < 5 ? strings.General.LowPriority : priority > 5 ? strings.General.HighPriority : strings.General.NormalPriority
    }

    return {
        getStatusText,
        getStatusColor,
        getStatusTextFromProgress,
        iconOrLetter,
        getPriorityText,
    };
}
