import { API_ENDPOINT } from "../services/api";
import { ApexOptions } from "apexcharts";
import useAccount from "./useAccount";
import { MileageCalendarHeatmap } from "../types/charts";
import { Day, Uid } from "../types/core";

export function useCharts() {
    const { uid, accountKey } = useAccount();

    const fetchMileage = async (from: number, until: number) => {
        const result = await fetch(API_ENDPOINT + `/chart/mileage?uid=${uid}&key=${accountKey}&from=${from}&until=${until}`);
        const options = await result.json() as ApexOptions;
        return options;
    }

    const fetchJobPerformance = async (from: number, until: number) => {
        const result = await fetch(API_ENDPOINT + `/chart/jobperformance?uid=${uid}&key=${accountKey}&from=${from}&until=${until}`);
        const options = await result.json() as ApexOptions;
        return options;
    }

    const fetchMileageCalendarHeatmap = async (memberUid: Uid, fromDay: Day, untilDay: Day) => {
        const result = await fetch(API_ENDPOINT + `/chart/calendarheatmap/mileage?uid=${uid}&key=${accountKey}&fromDay=${fromDay}&untilDay=${untilDay}&members=${memberUid}`);
        const json = await result.json();
        return json?.mileage[memberUid] as MileageCalendarHeatmap;
    }

    return { fetchMileage, fetchJobPerformance, fetchMileageCalendarHeatmap }
}