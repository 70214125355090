import { atomWithIndexedPersistence, idbStorageEffect } from './../utils/persistence';
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { ReadableSequence } from "../types/sequence";
import { Job } from "../types/job";

export const sequenceState = atomFamily<ReadableSequence | undefined, string>({
    key: 'sequence',
    default: undefined,
    effects: [idbStorageEffect]
});

export const sequenceIdsState = atomWithIndexedPersistence<string[]>("sequence.ids", []);

export const sequencesState = selector({
    key: "sequences.all",
    get: ({ get }) => {
        const filter = get(sequenceFilterState).toLowerCase();
        return get(sequenceIdsState)
            .map(id => get(sequenceState(id)))
            .filter(seq => !!seq) as ReadableSequence[];
    }
});

export const sequenceFilterState = atom<string>({
   key: "sequences.filter",
   default: "",
});

export const sequenceJobsDispatchState = atomFamily<Job[], string>({
    key: 'sequence.dispatch',
    default: selectorFamily({
        key: 'sequence.dispatch.initializer',
        get: (id) => ({ get }) => {
            return get(sequenceState(id))?.jobs || [];
        }
    }),
});