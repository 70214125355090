import { useRecoilValue, useResetRecoilState } from "recoil";
import { formQuestionsState, selectedFormState, selectedFormSubmissionState } from "../states/formsState";
import React, { useEffect, useState } from "react";
import { FormQuestions } from "../components/forms/FormQuestions";
import { FormSubmissions } from "../components/forms/FormSubmissions";
import { useStrings } from "../hooks/useStrings";
import { Centered } from "../components/containers/Centered";
import { useTheme } from "../hooks/useTheme";
import { Button } from "baseui/button";
import { VFlex } from "../components/containers/VFlex";
import { HFlex } from "../components/containers/HFlex";
import { Spacer, SpacerExpand } from "../components/containers/Spacer";
import { HideIcon, ViewIcon } from "../components/ui/svg";
import { DeviceFrame } from "../components/device/DeviceFrame";
import { FormPreviewContent } from "../components/forms/FormPreviewContent";
import { Segmented } from "antd";

export function FormView() {
    const { strings } = useStrings();
    const { theme, css } = useTheme();
    const selectedForm = useRecoilValue(selectedFormState);
    const isNewForm = selectedForm?.id == "";
    const [activeKey, setActiveKey] = useState<"questions" | "submissions">("questions");
    const resetSelectedFormSubmission = useResetRecoilState(selectedFormSubmissionState);
    const [showRecentSubmissions, setShowRecentSubmissions] = useState(false);
    const formQuestions = useRecoilValue(formQuestionsState);

    useEffect(() => {
        resetSelectedFormSubmission();
        setActiveKey("questions");
    }, [selectedForm]);

    if (!selectedForm) {
        if (showRecentSubmissions) {
            return (
                <VFlex style={{ overflow: "auto" }}>
                    <HFlex>
                        <SpacerExpand/>
                        <Button
                            size={"mini"}
                            kind={"secondary"}
                            onClick={() => setShowRecentSubmissions(false)}>
                            {strings.General.RecentSubmissions}
                            <Spacer/>
                            <HideIcon size={16} color={theme.colors.contentPrimary}/>
                        </Button>
                        <SpacerExpand/>
                    </HFlex>
                    <FormSubmissions/>
                </VFlex>
            );
        }

        return <Centered>
            <div style={{ color: theme.colors.contentTertiary }}
                 dangerouslySetInnerHTML={{ __html: strings.Forms.FormsDesc }}
            />
            <Button size={"mini"} kind={"secondary"} onClick={() => setShowRecentSubmissions(true)}>
                {strings.General.RecentSubmissions}
                <Spacer/>
                <ViewIcon size={16} color={theme.colors.contentPrimary}/>
            </Button>
        </Centered>;
    }

    return (
        <HFlex spacing={"48px"} style={{ height: "100%", marginBottom: "20px", overflow: "hidden" }}>
            <VFlex spacing style={{ flexGrow: 1 }}>
                <HFlex>
                    <SpacerExpand/>
                    <Segmented
                        value={activeKey}
                        options={[
                            {
                                value: "questions",
                                label: strings.General.Questions,
                            },
                            {
                                value: "submissions",
                                label: strings.General.RecentSubmissions,
                                disabled: isNewForm
                            },
                        ]}
                        onChange={(value) => setActiveKey(value as "questions" | "submissions")}
                    />
                    <SpacerExpand/>
                </HFlex>
                <HFlex spacing style={{ overflow: "hidden", flex: 1 }}>
                    {activeKey === "questions"
                        ? <FormQuestions/>
                        : <FormSubmissions
                            formContentIds={selectedForm
                                && { templateId: selectedForm.id,  contentId: selectedForm.content.id}} />}
                    {formQuestions && activeKey === "questions" &&
                        <span className={css({
                            maxHeight: "70vh",
                            marginBottom: "40px"
                        })}>
                            <DeviceFrame height={"100%"} width={"300px"}
                                         backgroundColor={theme.colors.backgroundSecondary}>
                                <FormPreviewContent form={formQuestions}/>
                            </DeviceFrame>
                        </span>
                    }
                </HFlex>
            </VFlex>


        </HFlex>
    );
}