import { UnorderedList } from "../containers/UnorderedList";
import { useRecoilValue } from "recoil";
import { uploadedReportsState } from "../../states/reportState";
import { ListItem } from "baseui/list";
import { Tag } from "antd";
import { useTime } from "../../hooks/useTime";
import { HTTPS_DOMAIN } from "../../services/api";
import { MemberBadge } from "../badges/MemberBadge";
import { HFlex } from "../containers/HFlex";
import { useStrings } from "../../hooks/useStrings";
import { StyledLink } from "baseui/link";
import { SpacerExpand } from "../containers/Spacer";
import { EnterIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { TimeBadge } from "../badges/TimeBadge";

export function RecentReports() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const time = useTime();
    const reports = useRecoilValue(uploadedReportsState);

    const orderedByTimestamp = [...reports]
        .sort((r1, r2) => r2?.timestamp - r1?.timestamp);

    return (
        <UnorderedList>
            {orderedByTimestamp.map(r =>
                <ListItem key={r.id}>
                    <HFlex spacing alignCenter>
                        <TimeBadge startMillis={r.timestamp} withDate/>
                        <StyledLink animateUnderline href={HTTPS_DOMAIN + r.link} target={"_blank"}>
                            <EnterIcon size={8} color={theme.colors.contentPrimary}/> {r.name ? r.name : r.reportType}
                        </StyledLink>
                        <SpacerExpand/>
                        {r.uploaderUid && <MemberBadge uid={r.uploaderUid}/>}
                        {r.source === "recurring" &&
                            <Tag style={{ marginLeft: "0px", marginRight: "0px" }}>
                                {strings.General.Recurring}
                            </Tag>
                        }
                        <Tag style={{ marginLeft: "0px", marginRight: "0px", width: "60px" }}>
                            {Math.round(r.bytes / 1024 * 10) / 10} KB
                        </Tag>
                    </HFlex>
                </ListItem>)
            }
        </UnorderedList>
    );
}