import { Centered } from "../containers/Centered";
import { themedUseStyletron as useStyletron } from "../../styles/theme";
import { ReactNode } from "react";

type Props = {
    title?: string | ReactNode;
    subtitle?: string | ReactNode;
    cta?: ReactNode;
    children?: ReactNode;
};

export function EmptyState({ title, subtitle, cta, children }: Props) {
    const [css, theme] = useStyletron();

    return (<Centered>
        {children}
        {title && <div
            className={css({
                ...theme.typography.HeadingXSmall,
                paddingBlockStart: theme.sizing.scale650,
                paddingBlockEnd: theme.sizing.scale500,
                color: theme.colors.contentTertiary,
                textAlign: "center",
            })}
        >
            {title}
        </div>}
        {subtitle && <div
            className={css({
                ...theme.typography.ParagraphSmall,
                paddingBlockEnd: theme.sizing.scale650,
                maxWidth: '300px',
                color: theme.colors.contentTertiary,
                textAlign: "center",
            })}
        >
            {subtitle}
        </div>}
        {
            cta
        }
    </Centered>);
}