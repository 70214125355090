import React, { useEffect, useState } from "react";
import { VFlex } from "../containers/VFlex";
import { Button } from "baseui/button";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { useBilling } from "../../hooks/useBilling";
import { PlanPrice, StripePlan } from "../../types/billing";
import { Radio, RadioGroup } from 'baseui/radio';
import { Collapse, Tag } from "antd";
import { useTheme } from "../../hooks/useTheme";
import { HeadingXSmall, LabelMedium, ParagraphMedium, ParagraphSmall } from "baseui/typography";
import { useStrings } from "../../hooks/useStrings";
import { StyledSelect } from "../ui/StyledSelect";
import { changePlan, getPlanPrices } from "../../services/privateApi";
import { ConfirmModal } from "../ui/ConfirmModal";
import useDirector from "../../hooks/useDirector";
import { useToast } from "../../hooks/useToast";
import { COLOR_MARINE } from "../../constants/colors";

const { Panel } = Collapse;

type Plan = {
    name: string;
    planMonthly: StripePlan;
    planYearly: StripePlan;
    features: string[];
};

type Currency = {
    id: string;
    label: string;
    prefix: string;
    suffix: string;
}

const currencies: Currency[] = [
    { id: 'USD', label: 'USD - $', prefix: "$", suffix: "USD" },
    { id: 'EUR', label: 'EUR - €', prefix: "€", suffix: "EUR" },
    { id: 'GBP', label: 'GBP - £', prefix: "£", suffix: "GBP" },
    { id: 'AUD', label: 'AUD - $', prefix: "$", suffix: "AUD" },
    { id: 'CAD', label: 'CAD - $', prefix: "$", suffix: "CAD" },
    { id: 'NZD', label: 'NZD - $', prefix: "$", suffix: "NZD" },
    { id: 'MXN', label: 'MXN - $', prefix: "$", suffix: "MXN" },
    { id: 'BRL', label: 'BRL - R$', prefix: "R$", suffix: "BRL" },
    { id: 'COP', label: 'COP - $', prefix: "$", suffix: "COP" },
    { id: 'AED', label: 'AED - د.إ', prefix: "د.إ", suffix: "AED" },
    { id: 'VND', label: 'VND - ₫', prefix: "₫", suffix: "VND" },
    { id: 'ZAR', label: 'ZAR - R', prefix: "R", suffix: "ZAR" },
    { id: 'PHP', label: 'PHP - ₱', prefix: "₱", suffix: "PHP" },
    { id: 'INR', label: 'INR - ₹', prefix: "₹", suffix: "INR" },
    { id: 'IDR', label: 'IDR - Rp', prefix: "Rp", suffix: "IDR" },
];

export function ChoosePlan() {
    const { strings, format } = useStrings();
    const { theme } = useTheme();
    const { billing, isLegacyPlan, getStripePlanName, isTestAccount, isDirectBilling, getCurrencyFromBrowser } = useBilling();
    const monthlyPlans: StripePlan[] = ["business_location_mo", "business_dispatch_mo"];
    const yearlyPlans: StripePlan[] = ["business_location_yr", "business_dispatch_yr"];
    const currentPlan = billing?.subscription?.plan || "";
    const isCurrentlyMonthly = currentPlan ? monthlyPlans.includes(currentPlan) : false;
    const isCurrentlyYearly = currentPlan ? yearlyPlans.includes(currentPlan) : false;
    const [pricingOption, setPricingOption] = useState<'monthly' | 'yearly'>(isCurrentlyMonthly ? 'monthly' : "yearly");
    const browserCurrency = getCurrencyFromBrowser();
    const [currency, setCurrency] = useState<Currency>(currencies.find(c => c.id === browserCurrency) || currencies[0]);
    const [planPrices, setPlanPrices] = useState<PlanPrice[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<Plan>();
    const { performBilling, performPoll } = useDirector();
    const toast = useToast();

    useEffect(() => {
        getPlanPrices().then(({ status, planPrices }) => {
            if (status) {
                setPlanPrices(planPrices);
            }
        })
    }, []);

    const roundToOneDecimal = (num: number): number => {
        return Math.round(num * 10) / 10;
    }

    const getPriceString = (plan: Plan, isYearly: boolean) => {
        if (planPrices) {
            const planId = isYearly ? plan.planYearly : plan.planMonthly;
            const planPrice = planPrices.find(p => p.id === planId);
            if (planPrice) {
                const matchingPrice = planPrice.prices.find(p => p.currency.toUpperCase() === currency.id);
                if (matchingPrice) {
                    const monthlyAmount = roundToOneDecimal(matchingPrice.amount / (isYearly ? 12 : 1) / (currency.id !== "VND" ? 100 : 1))
                    return <HFlex spacing alignCenter>
                        <div style={{
                            fontWeight: "bold",
                            fontSize: "1.5em"
                        }}>{currency.prefix + monthlyAmount + " " + currency.suffix}</div>
                        <div style={{ color: COLOR_MARINE }}> / user / month</div>
                    </HFlex>;
                }
            }
        }
        return "";
    }

    const plans: Plan[] = [
        {
            name: "Hellotracks Business - Location",
            planMonthly: "business_location_mo",
            planYearly: "business_location_yr",
            features: [
                strings.LocationFeatures.RealTimeLocation,
                strings.LocationFeatures.UnlimitedLocationHistory,
                strings.LocationFeatures.Geofencing,
                strings.LocationFeatures.OnsiteTimeRecording,
                strings.LocationFeatures.Zones,
                strings.LocationFeatures.TimeTracker,
                strings.LocationFeatures.Alerts,
                strings.LocationFeatures.Reports,
            ]
        },
        {
            name: "Hellotracks Business - Dispatch",
            planMonthly: "business_dispatch_mo",
            planYearly: "business_dispatch_yr",
            features: [
                strings.DispatchFeatures.HellotracksLocation,
                strings.DispatchFeatures.JobScheduling,
                strings.DispatchFeatures.AutoDispatch,
                strings.DispatchFeatures.RouteOptimization,
                strings.DispatchFeatures.OnsiteTimeRecordingJobs,
                strings.DispatchFeatures.ClientNotifications,
                strings.DispatchFeatures.SignatureCapture,
                strings.DispatchFeatures.ImageUploads,
                strings.DispatchFeatures.Forms,
                strings.DispatchFeatures.ReportsJobPerformance,
                strings.DispatchFeatures.RecurringReports,
                strings.DispatchFeatures.Integrations,
                strings.DispatchFeatures.APIAccess,
                strings.DispatchFeatures.TwoFactorAuthentication,
            ]
        },
    ];

    const yourPlan = (
        <VFlex alignCenter spacing
               style={{
                   border: '1px solid ' + theme.customColors.accent,
                   paddingTop: '20px',
                   paddingLeft: '20px',
                   paddingBottom: '20px',
                   paddingRight: '20px',
                   marginBottom: '40px',
                   marginTop: "20px",
               }}>
            <LabelMedium>{format(strings.Plan.YourCurrentPlanIsX, getStripePlanName())}</LabelMedium>
            {isLegacyPlan &&
                <ParagraphSmall>{strings.Plan.LegacyPlanDescription}</ParagraphSmall>
            }
            {isTestAccount && <ParagraphSmall>This is a test account</ParagraphSmall>}
        </VFlex>
    );

    if (isTestAccount || isDirectBilling) {
        return yourPlan;
    }

    const onSelectPlanClick = (plan: Plan) => {
        setSelectedPlan(plan);
    };

    const onChangePlanConfirmed = async () => {
        if (!selectedPlan) {
            return;
        }
        const planId = pricingOption === "monthly" ? selectedPlan.planMonthly : selectedPlan.planYearly;
        const { status } = await changePlan(planId);
        if (status) {
            await performBilling();
            await performPoll();
            toast.showSuccess(strings.General.Success);
        } else {
            toast.showWarning(strings.General.SomethingWentWrong);
        }
        setSelectedPlan(undefined);
    }

    return (
        <VFlex alignCenter>
            {selectedPlan && (<ConfirmModal
                    title={selectedPlan.name + " - " + (pricingOption === "monthly"
                        ? strings.Plan.Monthly : strings.Plan.Yearly)}
                    description={strings.Plan.SwitchPlanPrompt}
                    isOpen
                    onConfirm={onChangePlanConfirmed}
                    onCancel={() => setSelectedPlan(undefined)}
                    cancelText={strings.General.Cancel}
                    confirmText={strings.General.Yes}
                />
            )}
            {yourPlan}
            <HFlex>
                <SpacerExpand/>
                <div>
                    <StyledSelect
                        options={currencies}
                        value={[currency]}
                        placeholder={strings.Plan.SelectCurrency}
                        clearable={false}
                        onChange={({ option }) => option && setCurrency(option as Currency)}
                    />
                </div>
            </HFlex>
            <HFlex style={{ marginBottom: '20px' }}>
                <SpacerExpand/>
                <RadioGroup
                    align="horizontal"
                    onChange={e => setPricingOption(e.target.value as 'monthly' | 'yearly')}
                    value={pricingOption}
                >
                    <Radio value="monthly">{strings.Plan.Monthly}</Radio>
                    <Radio value="yearly">{strings.Plan.Yearly} ({strings.Plan.Aprox} -17%)</Radio>
                </RadioGroup>
                <SpacerExpand/>
            </HFlex>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                {plans.map((plan, index) => (
                    <VFlex alignCenter key={index} style={{
                        border: '1px solid ' + theme.colors.contentSecondary,
                        paddingLeft: "8px",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        paddingRight: "8px",
                        marginLeft: "4px",
                        marginRight: "4px"
                    }}>
                        {currentPlan !== (pricingOption === "monthly" ? plan.planMonthly : plan.planYearly) ?
                            <Button kind={"primary"}
                                    onClick={() => onSelectPlanClick(plan)}
                            >
                                {strings.Plan.SelectPlan}
                            </Button> :
                            <Tag color={"green"}
                                 style={{ paddingTop: "12px", paddingBottom: "12px", fontWeight: "bold" }}>
                                {strings.Plan.YourPlan}
                            </Tag>
                        }
                        <HeadingXSmall style={{ textAlign: "center" }}
                                       color={theme.colors.contentPrimary}>{plan.name}</HeadingXSmall>
                        <ParagraphMedium color={theme.colors.contentPrimary}>
                            {getPriceString(plan, pricingOption === "yearly")}
                        </ParagraphMedium>
                        <Collapse bordered={false}
                                  style={{ backgroundColor: "transparent" }}
                        >
                            <Panel key={index} header={strings.Plan.SeeAllFeatures}>
                                <ul>
                                    {plan.features.map((feature, fIndex) => (
                                        <li color={theme.colors.contentPrimary} key={fIndex}>{feature}</li>
                                    ))}
                                </ul>
                            </Panel>
                        </Collapse>
                    </VFlex>
                ))}
            </div>
        </VFlex>
    );
}
