import { LangFields, TRANSLATIONS } from "../locale/strings";
import { Day, Timestamp, Uid } from "./core";

export type ReportFormat = "xlsx" | "csv" | "pdf" | "geojson" | "zip";
export type ReportType =
  "jobs"
  | "dayroute"
  | "places"
  | "alerts"
  | "checkins"
  | "gpx"
  | "members"
  | "mileage"
  | "placeonsite"
  | "memberonsite"
  | "stopsreport"
  | "timesheet"
  | "formsubmissions"
  | "activities"
  | "timeline"
  | "worksheet"
  | "statistics"
  ;

const MAX_DAYS = 10000;
const DEFAULT_FORMATS = ["xlsx", "csv", "geojson", "pdf"] as ReportFormat[];


type AvailableColumns = {
  key: string;
  lang: LangFields;
  isCheck?: boolean;
  isDefaultHidden?: boolean;
}

export interface ReportConfig {
  type: ReportType;
  canSelectPlaces: boolean;
  canSelectMembers: boolean;
  availableFormats: ReportFormat[];
  allowMultipleDates: boolean;
  allowMultipleAccounts: boolean;
  maxDays: number;
  filename: string;
  availableColumns?: AvailableColumns[];
}

export interface RecurringReportConfig {
  id?: string;
  name: string;
  type: ReportType;
  memberUids: Uid[];
  placeUids: Uid[];
  frame: "day" | "week" | "twoweeks";
  weekdays: number;
  notifyUids: Uid[];
  format: ReportFormat;
  status?: 0 | 1 | 2;
  startDay?: Day;
  columns?: ColumnConfiguration[];
}

export interface UploadedReport {
  id: string;
  uploaderUid: Uid;
  timestamp: Timestamp;
  name: string;
  link: string;
  bytes: number;
  reportType: string;
  source: "" | "api" | "recurring";
}

export const ActivitiesReport = {
  type: "activities",
  canSelectPlaces: false,
  canSelectMembers: true,
  availableFormats: DEFAULT_FORMATS,
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: 30,
  filename: "activities-report",
} as ReportConfig;

export const JobsReport = {
  type: "jobs",
  canSelectPlaces: true,
  canSelectMembers: true,
  availableFormats: DEFAULT_FORMATS,
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: MAX_DAYS,
  filename: "jobs-report",
} as ReportConfig;

export const DayRouteReport = {
  type: "dayroute",
  canSelectPlaces: false,
  canSelectMembers: true,
  availableFormats: DEFAULT_FORMATS,
  allowMultipleDates: false,
  allowMultipleAccounts: true,
  maxDays: MAX_DAYS,
  filename: "dayroute-report",
} as ReportConfig;

export const PlacesReport = {
  type: "places",
  canSelectPlaces: true,
  canSelectMembers: false,
  availableFormats: DEFAULT_FORMATS,
  allowMultipleDates: false,
  allowMultipleAccounts: true,
  maxDays: 0,
  filename: "places-report",
} as ReportConfig;

export const AlertsReport = {
  type: "alerts",
  canSelectPlaces: false,
  canSelectMembers: false,
  availableFormats: DEFAULT_FORMATS,
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: MAX_DAYS,
  filename: "alerts-report",
} as ReportConfig;

export const StopsReport = {
  type: "stopsreport",
  canSelectPlaces: false,
  canSelectMembers: true,
  availableFormats: DEFAULT_FORMATS,
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: 120,
  filename: "stops-report",
} as ReportConfig;

export const Checkins = {
  type: "checkins",
  canSelectPlaces: false,
  canSelectMembers: true,
  availableFormats: DEFAULT_FORMATS,
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: MAX_DAYS,
  filename: "checkins-report",
} as ReportConfig;

export const Members = {
  type: "members",
  canSelectPlaces: false,
  canSelectMembers: true,
  availableFormats: DEFAULT_FORMATS,
  allowMultipleDates: false,
  allowMultipleAccounts: true,
  maxDays: 0,
  filename: "members-report",
} as ReportConfig;

export const Mileage: ReportConfig = {
  type: "mileage",
  canSelectPlaces: false,
  canSelectMembers: true,
  availableFormats: ["xlsx"],
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: MAX_DAYS,
  filename: "mileage-report",
  availableColumns: [
    { key: "date", lang: TRANSLATIONS.General.Date },
    { key: "member", lang: TRANSLATIONS.General.Member },
    { key: "start", lang: TRANSLATIONS.General.Start },
    { key: "end", lang: TRANSLATIONS.General.End },
    { key: "distance", lang: TRANSLATIONS.General.Distance },
    { key: "duration", lang: TRANSLATIONS.General.Duration },
    { key: "minutes", lang: TRANSLATIONS.General.Minutes },
    { key: "maxspeed", lang: TRANSLATIONS.Stats.MaxSpeed },
    { key: "avgspeed", lang: TRANSLATIONS.Stats.AvgSpeed },
    { key: "from", lang: TRANSLATIONS.General.From },
    { key: "to", lang: TRANSLATIONS.General.To },
    { key: "purpose", lang: TRANSLATIONS.General.Purpose },
    { key: "comment", lang: TRANSLATIONS.General.Comment },
    { key: "approvalstatus", lang: TRANSLATIONS.Approval.Approval },
    { key: "approvedby", lang: TRANSLATIONS.Approval.ApprovedBy },
    { key: "placesheet", lang: TRANSLATIONS.Reports.AddSheetForVisitedPlaces, isCheck: true, isDefaultHidden: true }
  ],
};

export const PlaceOnSite = {
  type: "placeonsite",
  canSelectPlaces: true,
  canSelectMembers: false,
  availableFormats: DEFAULT_FORMATS,
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: MAX_DAYS,
  filename: "place-onsite-report",
} as ReportConfig;


export const MemberOnSite = {
  type: "memberonsite",
  canSelectPlaces: false,
  canSelectMembers: true,
  availableFormats: ["pdf", "xlsx"],
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: 120,
  filename: "member-onsite-report",
} as ReportConfig;

export const Timesheet = {
  type: "timesheet",
  canSelectPlaces: false,
  canSelectMembers: true,
  availableFormats: ["pdf", "xlsx"],
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: 120,
  filename: "timesheet",
} as ReportConfig;

export const Form = {
  type: "formsubmissions",
  canSelectPlaces: false,
  canSelectMembers: true,
  availableFormats: ["csv", "xlsx"],
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: 120,
  filename: "form-submissions",
} as ReportConfig;

export const Gpx = {
  type: "gpx",
  canSelectPlaces: false,
  canSelectMembers: true,
  availableFormats: ["zip"],
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: 120,
  filename: "gpx",
} as ReportConfig;

export const Timeline = {
  type: "timeline",
  canSelectPlaces: false,
  canSelectMembers: true,
  availableFormats: ["xlsx", "csv"],
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: 14,
  filename: "timeline"
} as ReportConfig;

export const WorksheetReport = {
  type: "worksheet",
  canSelectPlaces: false,
  canSelectMembers: true,
  availableFormats: DEFAULT_FORMATS,
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: 14,
  filename: "worksheet",
} as ReportConfig;

export const StatisticsReport = {
  type: "statistics",
  canSelectPlaces: false,
  canSelectMembers: true,
  availableFormats: DEFAULT_FORMATS,
  allowMultipleDates: true,
  allowMultipleAccounts: true,
  maxDays: MAX_DAYS,
  filename: "stats-report",
  availableColumns: [
    { key: "member", lang: TRANSLATIONS.General.Member },
    { key: "distance", lang: TRANSLATIONS.General.Distance },
    { key: "speed", lang: TRANSLATIONS.Stats.AvgSpeed },
    { key: "maxspeed", lang: TRANSLATIONS.Stats.MaxSpeed },
    { key: "tracks", lang: TRANSLATIONS.General.Tracks },
    { key: "jobs", lang: TRANSLATIONS.General.Jobs },
    { key: "ontime", lang: TRANSLATIONS.Stats.OnTime },
    { key: "delays", lang: TRANSLATIONS.Stats.Delays },
    { key: "success", lang: TRANSLATIONS.JobStatuses.Done },
    { key: "issues", lang: TRANSLATIONS.JobStatuses.Issue },
    { key: "engagement", lang: TRANSLATIONS.Stats.Engagement },
    { key: "totalJobOnsite", lang: TRANSLATIONS.Stats.TotalJobOnsite },
    { key: "avgJobOnsite", lang: TRANSLATIONS.Stats.AvgJobOnsite },
    { key: "totalPlaceOnsiteTime", lang: TRANSLATIONS.Stats.TotalPlaceOnsite },
    { key: "avgPlaceOnsiteTime", lang: TRANSLATIONS.Stats.AvgPlaceOnsite },
    { key: "totalPauseTime", lang: TRANSLATIONS.Stats.TotalPause },
    { key: "avgPauseTime", lang: TRANSLATIONS.Stats.AvgPause },
    { key: "totalTravelTime", lang: TRANSLATIONS.Stats.TotalTravelTime },
    { key: "avgTravelTime", lang: TRANSLATIONS.Stats.AvgTravelTime },
    { key: "totalTimeBetweenJobs", lang: TRANSLATIONS.Stats.AccumulatedTimeBetweenJobs },
    { key: "avgTimeBetweenJobs", lang: TRANSLATIONS.Stats.AvgTimeBetweenJobs },
  ]
} as ReportConfig;

export const ALL_REPORTS = [
  JobsReport,
  DayRouteReport,
  PlacesReport,
  AlertsReport,
  Checkins,
  Timesheet,
  Members,
  Mileage,
  PlaceOnSite,
  MemberOnSite,
  StopsReport,
  Gpx,
  ActivitiesReport,
  Timeline,
  WorksheetReport,
  StatisticsReport,
];

export type ColumnConfiguration = {
  key: string;
  rename?: string;
  isCheck?: boolean;
};