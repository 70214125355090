import { ReadableSequence, Sequence } from "../../../types/sequence";
import React, { useRef } from "react";
import { ClickableListItem } from "../../ui/ClickableListItem";
import { useBoard } from "../../../hooks/useBoard";
import { HFlex } from "../../containers/HFlex";
import { HoverButton } from "../../buttons/HoverButton";
import { useStrings } from "../../../hooks/useStrings";
import { useTheme } from "../../../hooks/useTheme";
import { ArrowRightIcon, EditIcon, TrashIcon } from "../../ui/svg";
import { LabelSmall } from "baseui/typography";
import { Badge, Tag } from "antd";
import { useTime } from "../../../hooks/useTime";
import { SpacerExpand } from "../../containers/Spacer";
import { useHover } from "usehooks-ts";
import { EllipsedText } from "../../ui/EllipsedText";

type Props = {
    sequence: ReadableSequence;
    onEditSequence: (sequence: Sequence) => void;
    onDeleteSequence: (sequence: Sequence) => void;
}

function SequenceItem({ sequence, onEditSequence, onDeleteSequence }: Props) {
    const board = useBoard();
    const { strings } = useStrings();
    const { theme } = useTheme();
    const time = useTime();
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);

    const onClick = () => {
        board.setSequences([sequence.id]);
    };

    const hoverButtonsFunction = () =>
        <HFlex spacing={theme.sizing.scale100}>
            <HoverButton
                tooltip={strings.Sequences.RenameSequence}
                onClick={() => onEditSequence(sequence)}
            >
                <EditIcon size={16} color={theme.colors.contentTertiary}/>
            </HoverButton>
            <HoverButton
                tooltip={strings.Sequences.DeleteSequence}
                onClick={() => onDeleteSequence(sequence)}
            >
                <TrashIcon color={theme.colors.contentTertiary} size={16}/>
            </HoverButton>
            <HoverButton
                tooltip={strings.General.AddToBoard}
                onClick={() => board.addSequence(sequence.id)}
            >
                <ArrowRightIcon size={16} color={theme.colors.contentTertiary}/>
            </HoverButton>
        </HFlex>;

    return (
        <div ref={hoverRef}>
            <ClickableListItem
                shrink
                isSelected={board.sequences.includes(sequence.id)}
                onClick={onClick}
                onHover={hoverButtonsFunction}
            >
                <HFlex spacing alignCenter>
                    <LabelSmall margin={0}>
                        <EllipsedText maxLines={2}>{sequence.name}</EllipsedText>
                    </LabelSmall>
                    {!isHover && <SpacerExpand/>}
                    {!isHover && <Tag>{time.formatDateOnly(sequence.lastSaved)}</Tag>}
                </HFlex>
                {!isHover && <Badge style={{ backgroundColor: theme.colors.contentPrimary, width: "30px" }}
                                    count={sequence.jobs.length}
                                    showZero/>}
            </ClickableListItem>
        </div>
    );
}

export default React.memo(SequenceItem);