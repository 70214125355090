import { ParagraphSmall } from 'baseui/typography';
import { useRecoilValue } from 'recoil';
import { useJobAttachments } from '../../../hooks/jobs/useJobAttachments';
import { useTheme } from '../../../hooks/useTheme';
import { editingJobIdState } from '../../../states/appState';
import { editJobState } from '../../../states/editJobState';
import { SectionedWrapper } from '../../containers/SectionedWrapper';
import { Spacer } from '../../containers/Spacer';
import { AttachmentList } from '../../shared/AttachmentList';
import { Section } from '../../ui/Section';
import { JobSignature } from '../JobSignature';
import { ShortFormResponseSection } from "../ShortFormResponseSection";
import { useStrings } from "../../../hooks/useStrings";
import { JobSubmissions } from './JobSubmissions';

export function JobStaffInputTab() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const jobId = useRecoilValue(editingJobIdState);
    const fields = useRecoilValue(editJobState(jobId || ''));
    const { staffAttachments } = useJobAttachments(fields);

    if (!fields || !jobId) {
        return null;
    }

    return (
        <SectionedWrapper>
            <ShortFormResponseSection job={fields} allowEdit/>
            <JobSubmissions/>
            <Section title={strings.Job.UploadedImages}>
                {!staffAttachments.length && (
                    <ParagraphSmall
                        color={theme.colors.contentTertiary}>{strings.General.NoUploadedImages}</ParagraphSmall>
                )}
                {!!staffAttachments.length && (
                    <AttachmentList attachments={staffAttachments}/>
                )}
            </Section>
            <JobSignature job={fields}/>
            <Spacer height="48px"/>
        </SectionedWrapper>
    );
}
