import { StyledHeadCell } from 'baseui/table';
import { StyledSortableHeadCell } from '../components/ui/StyledSortableHeadCell';
import { tableHeaderStyle } from '../constants/ui';
import { ColumnsConfiguration } from '../types/core';
import { useStyleOverrides } from './useStyleOverrides';

export function useTables(columnsConfiguration: ColumnsConfiguration) {
    const { tableHeaderLabelStyle } = useStyleOverrides();

    const getHeadCell = (column: string) => {
        const config = columnsConfiguration[column];
        if (!config) return null;
        if (config.onSort) {
            return (
                <StyledSortableHeadCell
                    {...columnsConfiguration[column]}
                />
            );
        }
        return (
            <StyledHeadCell
                $style={{ ...tableHeaderStyle, ...tableHeaderLabelStyle, ...config.styles, justifyContent: 'start' }}
            >
                {config.title}
            </StyledHeadCell>
        );
    };

    return { getHeadCell };
}
