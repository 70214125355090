import useAccount from "./useAccount";
import { createInviteCode, editInviteCode } from "../services/api";
import { DAY } from "../utils/time";
import useDirector from "./useDirector";

export function useInviteCode() {
    const { company } = useAccount();
    const defaultRemainSeconds = 30 * DAY / 1000;
    const director = useDirector();

    const newInviteCode = async () => {
        if (!company) {
            return;
        }
        await createInviteCode({ companyUid: company.uid, inviteRemainingSeconds: defaultRemainSeconds })
        await director.performPoll();
    }

    const renewInviteCode = async (code: string) => {
        if (!company) {
            return;
        }
        await editInviteCode({ inviteCode: code, inviteRemainingSeconds: defaultRemainSeconds })
        await director.performPoll();
    }

    const deleteInviteCode = async (code: string) => {
        if (!company) {
            return;
        }
        await editInviteCode({ inviteCode: code, inviteRemainingSeconds: 0 })
        await director.performPoll();
    }

    return { newInviteCode, renewInviteCode, deleteInviteCode };
}