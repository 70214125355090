import { Radio, RadioGroup } from "baseui/radio";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    selectedReportConfigState,
    selectedReportDayWeekState,
    selectedReportWeekdaysState
} from "../../states/reportState";
import { SELECTED_DAYS_NONE } from "../../constants/days";
import { useStrings } from "../../hooks/useStrings";

export function ReportDayWeekStep() {
    const { strings } = useStrings();
    const reportConfig = useRecoilValue(selectedReportConfigState);
    const [dayWeek, setDayWeek] = useRecoilState(selectedReportDayWeekState);
    const [weekdays, setWeekdays] = useRecoilState(selectedReportWeekdaysState);

    const onDayWeekChange = (newDayWeek: "day" | "week" | "twoweeks") => {
        setDayWeek(newDayWeek);
        if (newDayWeek === "week") {
            const firstDaySelected = Object.entries(weekdays).find(([, val]) => !!val);
            setWeekdays(firstDaySelected ? { ...SELECTED_DAYS_NONE, [firstDaySelected[0]]: true } : SELECTED_DAYS_NONE);
        }
    }

    if (!reportConfig) {
        return null;
    }

    return (
        <RadioGroup
            value={dayWeek}
            onChange={(e) => onDayWeekChange(e.currentTarget.value as "day" | "week" | "twoweeks")}
            name="format"
            align="vertical"
        >
            <Radio value={"day"}>{strings.Reports.DailyReport}</Radio>
            <Radio value={"week"}>{strings.Reports.WeeklyReport}</Radio>
            <Radio value={"twoweeks"}>{strings.Reports.EveryTwoWeeksReport}</Radio>
        </RadioGroup>
    );
}