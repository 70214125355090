import { useRecoilValue } from "recoil";
import {
    placesLayerMoreCounterState,
    placesLayerRetryState,
    placesLayerSkipCounterState
} from "../states/geojsonState";
import { useEffect } from "react";
import { usePlacesLayerUpdater } from "../hooks/places/usePlacesLayerUpdater";

export function PlacesLayerUpdateProvider() {
    const retry = useRecoilValue(placesLayerRetryState);
    const moreCounter = useRecoilValue(placesLayerMoreCounterState);
    const skipCounter = useRecoilValue(placesLayerSkipCounterState);
    const { updateLayer } = usePlacesLayerUpdater();

    useEffect(() => {
        if (moreCounter > 0) {
            updateLayer(skipCounter);
        }
    }, [moreCounter]);

    useEffect(() => {
        if (retry > 0) {
            updateLayer(skipCounter);
        }
    }, [retry]);

    return null;
}