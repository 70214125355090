import { createContext, MutableRefObject, useMemo, useRef } from "react";

export const OnboardContext = createContext({
    teamRef: {} as MutableRefObject<HTMLDivElement | null>,
    importMembersRef: {} as MutableRefObject<HTMLButtonElement | null>,
    addMemberRef: {} as MutableRefObject<HTMLDivElement | null>,
    placesRef: {} as MutableRefObject<HTMLDivElement | null>,
    importPlacesRef: {} as MutableRefObject<HTMLButtonElement | null>,
    addPlaceRef: {} as MutableRefObject<HTMLButtonElement | null>,
    checkinNotificationsRef: {} as MutableRefObject<HTMLDivElement | null>,
    autoCheckinRef: {} as MutableRefObject<HTMLDivElement | null>,
    delayRef: {} as MutableRefObject<HTMLDivElement | null>,
    checkinAreaRef: {} as MutableRefObject<HTMLDivElement | null>,
    companySettingsRef: {} as MutableRefObject<HTMLDivElement | null>,
    companyPermissionsRef: {} as MutableRefObject<HTMLDivElement | null>,
    downloadAppRef: {} as MutableRefObject<HTMLDivElement | null>,
    adminsPermissionsRef: {} as MutableRefObject<HTMLDivElement | null>,
    operatorsPermissionsRef: {} as MutableRefObject<HTMLDivElement | null>,
    workersPermissionsRef: {} as MutableRefObject<HTMLDivElement | null>,
    trackingSettingsRef: {} as MutableRefObject<HTMLDivElement | null>,
    trackingRef: {} as MutableRefObject<HTMLDivElement | null>,
    trackingWorkingHoursRef: {} as MutableRefObject<HTMLDivElement | null>,
    deleteTracksRef: {} as MutableRefObject<HTMLDivElement | null>,
    dispatchRef: {} as MutableRefObject<HTMLDivElement | null>,
    jobListRef: {} as MutableRefObject<HTMLDivElement | null>,
    importJobsRef: {} as MutableRefObject<HTMLButtonElement | null>,
    addJobRef: {} as MutableRefObject<HTMLButtonElement | null>,
});

type Props = {
    children: React.ReactNode;
};

export const OnboardProvider = ({ children }: Props) => {
    const teamRef = useRef<HTMLDivElement | null>(null);
    const importMembersRef = useRef<HTMLButtonElement | null>(null);
    const addMemberRef = useRef<HTMLDivElement | null>(null);
    const placesRef = useRef<HTMLDivElement | null>(null);
    const importPlacesRef = useRef<HTMLButtonElement | null>(null);
    const addPlaceRef = useRef<HTMLButtonElement | null>(null);
    const stopsRef = useRef<HTMLDivElement | null>(null);
    const checkinNotificationsRef = useRef<HTMLDivElement | null>(null);
    const autoCheckinRef = useRef<HTMLDivElement | null>(null);
    const delayRef = useRef<HTMLDivElement | null>(null);
    const checkinAreaRef = useRef<HTMLDivElement | null>(null);
    const companySettingsRef = useRef<HTMLDivElement | null>(null);
    const companyPermissionsRef = useRef<HTMLDivElement | null>(null);
    const downloadAppRef = useRef<HTMLDivElement | null>(null);
    const downloadAndroidRef = useRef<HTMLButtonElement | null>(null);
    const downloadIosRef = useRef<HTMLButtonElement | null>(null);
    const adminsPermissionsRef = useRef<HTMLDivElement | null>(null);
    const operatorsPermissionsRef = useRef<HTMLDivElement | null>(null);
    const workersPermissionsRef = useRef<HTMLDivElement | null>(null);
    const trackingSettingsRef = useRef<HTMLDivElement | null>(null);
    const trackingRef = useRef<HTMLDivElement | null>(null);
    const trackingWorkingHoursRef = useRef<HTMLDivElement | null>(null);
    const deleteTracksRef = useRef<HTMLDivElement | null>(null);
    const dispatchRef = useRef<HTMLDivElement | null>(null);
    const jobListRef = useRef<HTMLDivElement | null>(null);
    const importJobsRef = useRef<HTMLButtonElement | null>(null);
    const addJobRef = useRef<HTMLButtonElement | null>(null);

    const refs = useMemo(() => ({
        teamRef,
        importMembersRef,
        addMemberRef,
        placesRef,
        importPlacesRef,
        addPlaceRef,
        stopsRef,
        checkinNotificationsRef,
        autoCheckinRef,
        delayRef,
        checkinAreaRef,
        companySettingsRef,
        companyPermissionsRef,
        downloadAppRef,
        downloadAndroidRef,
        downloadIosRef,
        adminsPermissionsRef,
        operatorsPermissionsRef,
        workersPermissionsRef,
        trackingSettingsRef,
        trackingRef,
        trackingWorkingHoursRef,
        deleteTracksRef,
        dispatchRef,
        jobListRef,
        importJobsRef,
        addJobRef,
    }), []);

    return <OnboardContext.Provider value={refs}>{children}</OnboardContext.Provider>;
};
