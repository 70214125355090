import { useRecoilValue } from "recoil";
import { memberState } from "../../states/membersState";
import { Tag } from "antd";
import { useMemberInfo } from "../../hooks/members/useMemberInfo";
import { tinyTag } from "../../constants/ui";
import { Uid } from "../../types/core";

export function MemberInfoBadge({ uid }: { uid: Uid }) {
    const member = useRecoilValue(memberState(uid));
    const memberInfo = useMemberInfo();
    const badgeInfo = memberInfo.getBadgeInfo(member);

    if (!badgeInfo.text) {
        return null;
    }

    return (
        <Tag style={tinyTag} color={badgeInfo.color}>{badgeInfo.text}</Tag>
    );
}