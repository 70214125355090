import {
    getCustomField,
    getLabels,
    isAccepted,
    isAssigned,
    isFailed,
    isSuccess,
    Job,
    PROGRESS_STATUS_ACCEPTED,
    PROGRESS_STATUS_IN_PROGRESS,
    PROGRESS_STATUS_ISSUE,
    PROGRESS_STATUS_REJECTED,
    PROGRESS_STATUS_SCHEDULED,
    PROGRESS_STATUS_SUCCESS,
    PROGRESS_STATUS_UNASSIGNED
} from "../types/job";
import {
    COLOR_ASSIGNED_ACCEPTED,
    COLOR_IN_PROGRESS,
    COLOR_ISSUE,
    COLOR_REJECTED,
    COLOR_SCHEDULED,
    COLOR_SUCCESS,
    COLOR_UNASSIGNED
} from "../constants/colors";
import { isValidImage } from "./app";
import { JobSort } from "../states/allJobsState";
import { Uid } from "../types/core";

export function getProgressStatus(job: Job) {
    if (isSuccess(job)) {
        return PROGRESS_STATUS_SUCCESS;
    } else if (isFailed(job)) {
        return PROGRESS_STATUS_ISSUE;
    } else if (isAccepted(job)) {
        if (job.tsCheckIn > 0) {
            return PROGRESS_STATUS_IN_PROGRESS;
        } else {
            return PROGRESS_STATUS_ACCEPTED;
        }
    } else if ("" === job.receiverUid) {
        return PROGRESS_STATUS_UNASSIGNED;
    } else {
        return PROGRESS_STATUS_SCHEDULED;
    }
}

export function getColorFromProgress(job: Job) {
    return getProgressColor(getProgressStatus(job));
}

export function getProgressColor(progress: number) {
    switch (progress) {
        case PROGRESS_STATUS_SUCCESS:
            return COLOR_SUCCESS;
        case PROGRESS_STATUS_ISSUE:
            return COLOR_ISSUE;
        case PROGRESS_STATUS_UNASSIGNED:
            return COLOR_UNASSIGNED;
        case PROGRESS_STATUS_IN_PROGRESS:
            return COLOR_IN_PROGRESS;
        case PROGRESS_STATUS_ACCEPTED:
            return COLOR_ASSIGNED_ACCEPTED;
        case PROGRESS_STATUS_SCHEDULED:
            return COLOR_SCHEDULED;
        case PROGRESS_STATUS_REJECTED:
            return COLOR_REJECTED;
        default:
            return COLOR_SCHEDULED;
    }
}

const letter = (job: Job) => {
    return job.destinationName.length > 0 ? job.destinationName.substring(0, 1) : "";
};

export const numberOrLetter = (job: Job, prefix = "#") => {
    return isAssigned(job) || job.isPartOfSequence ? prefix + job.number : letter(job);
}

export const searchMatch = (job: Job, search: string) => {
    const term = search.toLowerCase();
    return search.length === 0
        || job.destinationName.toLowerCase().includes(term)
        || job.destinationText.toLowerCase().includes(term)
        || `${job.orderId}`.includes(term)
        || job.customFields && job.customFields.find(f => f.val.toLowerCase().includes(term));
};

export const limitTo = (maxCount: number, jobs: Job[]) => {
    if (jobs.length > maxCount) {
        return jobs.slice(0, maxCount);
    } else {
        return jobs;
    }
};

export const coordinatesOk = (job: Job) => {
    return job.destinationLat >= -90
        && job.destinationLat <= 90
        && job.destinationLng >= -180
        && job.destinationLng <= 180;
}

export const sortJobsAlphabetically = (j1: Job, j2: Job) => j1.destinationName && j2.destinationName
    ? j1.destinationName.localeCompare(j2.destinationName) : 0;

export const sortJobsByCreated = (j1: Job, j2: Job) => j2.tsCreated - j1.tsCreated;

export const sortJobsByNumber = (a: Job, b: Job) => a.number - b.number;

export const imagesFromJob = (job: Job): string[] => {
    return Object.entries(job)
        .filter(([key, url]) => {
            if (!key.startsWith('url_')) {
                return false;
            }
            return isValidImage(url);
        })
        .map(([_, value]) => value);
}

export const printableLink = (companyUid: Uid, job: Job) => {
    return `/!/@j/${companyUid}/${job.id}`;
}

export const sortJobsByHeader = (j1: Job, j2: Job, sort: number, customField?: string) => {
    if (sort === JobSort.CREATED) {
        return j1.tsCreated - j2.tsCreated
    } else if (sort === JobSort.TITLE) {
        return j2.destinationName.localeCompare(j1.destinationName);
    } else if (sort === JobSort.ADDRESS) {
        return j2.destinationText.localeCompare(j1.destinationText);
    } else if (sort === JobSort.SAVED) {
        return j1.tsSaved - j2.tsSaved;
    } else if (sort === JobSort.ORDER) {
        return j2.orderId - j1.orderId;
    } else if (sort === JobSort.ASSIGNEE) {
        return j2.assigneeName.localeCompare(j1.assigneeName);
    } else if (sort === JobSort.PROGRESS_STATUS) {
        return getProgressStatus(j2) - getProgressStatus(j1);
    } else if (sort === JobSort.PRIORITY) {
        return j1.priority - j2.priority;
    } else if (sort === JobSort.TEAM) {
        return j2.teamId - j1.teamId;
    } else if (sort === JobSort.NOTES) {
        return j2.textDispatcher.localeCompare(j1.textDispatcher);
    } else if (sort === JobSort.TIME_WINDOW) {
        return j2.windowStart - j1.windowStart;
    } else if (sort === JobSort.WORK_TYPE) {
        return j1.type - j2.type;
    } else if (sort === JobSort.CARRY_OVER) {
        return j1.carryOver - j2.carryOver;
    } else if (sort === JobSort.LABELS) {
        const l1 = getLabels(j1);
        const l2 = getLabels(j2);
        if (l1.length > 0 && l2.length > 0) {
            return l1[0].text.localeCompare(l2[0].text);
        } else {
            return l1.length - l2.length;
        }
    } else if (sort === JobSort.CUSTOM && customField) {
        const c1 = getCustomField(j1, customField);
        const c2 = getCustomField(j2, customField);
        if (c1 && c2) {
            return c2.val.localeCompare(c1.val);
        } else if (c2) {
            return 1;
        }
        return -1;
    }
    return j1.day - j2.day || j2.receiverUid.localeCompare(j1.receiverUid) || j2.number - j1.number;
}

export const mapJobIds = (job: Job) => {
    return job.id;
};