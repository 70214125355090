import React, { ErrorInfo, ReactElement } from "react";
import { uploadAppLog } from "../services/upload";
import { getRecoil } from "./RecoilAccessProvider";
import { companyState, usernameState } from "../states/accountState";
import { pageState } from "../states/appState";

interface State {
    hasError: boolean
    errorMessage: string
}

interface Props {
    children: ReactElement
}

export class ErrorCatcher extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            hasError: false,
            errorMessage: ""
        }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ hasError: true });
        this.setState({ errorMessage: error.message });
        console.error(error, errorInfo);

        if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
            return;
        }

        const username = getRecoil(usernameState);
        const page = getRecoil(pageState);
        const company = getRecoil(companyState);

        const isActualError = error instanceof Error;
        const errorMessage = "Caught by ErrorWrappper: " + isActualError ? error.message : String(error);
        const errorStack = isActualError ? error.stack : "Not an actual Error object";
        const componentStack = errorInfo.componentStack;
        const browserInfo = {
            appName: navigator.appName,
            appVersion: navigator.appVersion,
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            vendor: navigator.vendor,
        };

        const jsonString = JSON.stringify({
            error,
            errorInfo,
            errorMessage,
            errorStack,
            componentStack,
            username,
            page,
            company,
            browserInfo,
        }, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const file = new File([blob], "base-error");
        uploadAppLog({ file: file, extension: ".json" })
            .then(result => console.log("applog uploaded: ", result.status))
            .catch(error => console.error(error));
    }

    render(): React.ReactNode {
        if (this.state?.hasError) {
            return null;
        }
        return (this.props.children)
    }
}