import { Team } from '../../types/core';
import { useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { editAccount } from '../../services/api';
import { companyState } from '../../states/accountState';
import { fieldsForTeamEditState, teamFieldsHasAnyErrorState } from '../../states/editTeamState';
import { teamState } from '../../states/teamsState';
import { Company } from '../../types/company';
import { getDiffFields } from '../../utils/objects';


export function useTeamForms(teamId: number, isNewTeam = false) {
    const [company, setCompany] = useRecoilState(companyState);
    const team = useRecoilValue(teamState(teamId));
    const [teamFields, setTeamFields] = useRecoilState(fieldsForTeamEditState(teamId));
    const resetTeamFields = useResetRecoilState(fieldsForTeamEditState(teamId));
    const [isLoading, setIsLoading] = useState(false);
    const teamHasAnyError = useRecoilValue(teamFieldsHasAnyErrorState(teamId));

    const teamModifiedFields: { [key: string]: any } = team ? getDiffFields(team, teamFields) : teamFields;
    const teamHasModifiedFields = !!Object.keys(teamModifiedFields).length;
    const canSubmit = !teamHasAnyError;

    const onSubmit = async () => {
        if (!company) {
            return;
        }
        setIsLoading(true);

        let data: { [key: string]: any } = {};

        if (teamHasModifiedFields) {
            let newTeams: Team[] = [];
            if (isNewTeam) {
                newTeams = [...company.teams_config, { ...teamFields, id: teamId }];
            } else {
                newTeams = company.teams_config.map((t) => t.id === teamId ? teamFields : t);
            }
            data = {
                teams_config: newTeams,
            }
            const { status, account: updatedCompany } = await editAccount({ uid: company.uid, ...data });
            if (status) {
                setCompany(updatedCompany as Company);
            }
        }
        resetTeamFields();
        setIsLoading(false);
    };

    const submitTeam = async (team: Team) => {
        if (!company) {
            return;
        }
        setIsLoading(true);
        const data = { teams_config: [team] };
        const { status, account: updatedCompany } = await editAccount({ uid: company.uid, ...data });
        if (status) {
            setCompany(updatedCompany as Company);
        }
        resetTeamFields();
        setIsLoading(false);
    };

    return {
        canSubmit,
        isLoading,
        onSubmit,
        hasChanges: teamHasModifiedFields,
        teamFields,
        setTeamFields,
        resetTeamFields,
        submitTeam,
    };
}