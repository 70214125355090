import { AlertConfig } from "../../types/alerts";
import { LabelMedium } from "baseui/typography";
import React from "react";
import { useRecoilState } from "recoil";
import { HFlex } from "../containers/HFlex";
import { HoverButton } from "../buttons/HoverButton";
import { ArrowRight } from "baseui/icon";
import { selectedAlertConfigState } from "../../states/alertsState";
import { useTheme } from "../../hooks/useTheme";
import { ClickableListItem } from "../ui/ClickableListItem";
import { useStrings } from "../../hooks/useStrings";

export function AlertConfigItem({ alertConfig }: { alertConfig: AlertConfig }) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const [selectedAlertConfig, setSelectedAlertConfig] = useRecoilState(selectedAlertConfigState)

    const onEditClick = () => setSelectedAlertConfig(alertConfig);

    const hoverButtonsFunction = () =>
        <HFlex spacing>
            <HoverButton
                tooltip={strings.Alerts.OpenAlertConfig}
                onClick={onEditClick}
                size={"compact"}
            >
                <ArrowRight size={16} color={theme.colors.contentTertiary} style={{ flexShrink: 0 }}/>
            </HoverButton>
        </HFlex>;

    return (
        <ClickableListItem
            isSelected={selectedAlertConfig === alertConfig}
            onClick={onEditClick}
            onHover={hoverButtonsFunction}
        >
            <LabelMedium>{alertConfig.title}</LabelMedium>
        </ClickableListItem>
    );
}