import { ParagraphSmall } from "baseui/typography";
import { useStrings } from "../../hooks/useStrings";

export function PublicViewFooter({ name }: { name: string }) {
    const { strings, format } = useStrings();
    return (
        <ParagraphSmall>
            <span
                dangerouslySetInnerHTML={{
                    __html: format(strings.General.ProvidedByHTAndX, name || "")
                        .toString()
                        .toUpperCase(),
                }}
            />
        </ParagraphSmall>
    );
}