import { useEffectOnce } from "usehooks-ts";
import useAccount from "../hooks/useAccount";

export function LogoutProvider() {
    const { uid } = useAccount();
    useEffectOnce(() => {
        const onstorage = (event: StorageEvent) => {
            const { key, newValue } = event;
            if (key === "account.uid") {
                if (uid !== newValue?.replaceAll('"', "")) {
                    window.location.replace("/logout");
                }
            }
        };
        window.addEventListener("storage", onstorage);
        return () => {
            window.removeEventListener("storage", onstorage);
        };
    });
    return null;
}
