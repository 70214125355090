import { PAGE } from "../../../constants/pages";
import { Layer, Source } from "react-map-gl";
import { trafficLayerStyle } from "../mapStyle";
import { useRecoilValue } from "recoil";
import { pageState } from "../../../states/appState";
import { showTrafficLayerState } from "../../../states/mapState";

export function TrafficLayer() {
    const page = useRecoilValue(pageState);
    const showTrafficLayer = useRecoilValue(showTrafficLayerState);

    if (page === PAGE.HEATMAP || !showTrafficLayer) {
        return null;
    }
    return (
        <Source type="vector" url="mapbox://mapbox.mapbox-traffic-v1">
            <Layer {...trafficLayerStyle} />
        </Source>
    );
}