import { TourProps } from "antd";
import { flushSync } from "react-dom";
import { useSetRecoilState } from "recoil";
import { onboardStatusState } from "../../states/onboardState";
import { companyTabFilterState, editingCompanyIdState } from "../../states/appState";
import useAccount from "../useAccount";
import { useOnboardRefs } from "./useOnboardRefs";
import { useOnboardStep } from "./useOnboardStep";
import { useStrings } from '../useStrings';
import { ONBOARD_CHECK_IN_NOTIFICATIONS, TOUR_DELAY } from "../../constants/onboard";

export function useOnboardNotifications() {
    const { strings } = useStrings();
    const setCompanyTabFilter = useSetRecoilState(companyTabFilterState);
    const { autoCheckinRef, delayRef, companySettingsRef, checkinNotificationsRef, checkinAreaRef } = useOnboardRefs();
    const account = useAccount();
    const setEditingCompany = useSetRecoilState(editingCompanyIdState);
    const {
        step,
        nextStep,
        prevStep,
        closeStep,
        openStep,
        isOpen,
        canTrigger
    } = useOnboardStep(ONBOARD_CHECK_IN_NOTIFICATIONS);
    const setOnboardStatus = useSetRecoilState(onboardStatusState);
    const notificationsSteps: TourProps["steps"] = [
        {
            title: strings.Onboard.CompanySettingsTitle,
            description: strings.Onboard.CompanySettingsDescription,
            target: companySettingsRef.current as HTMLElement,
            placement: "bottom",
            onNext: nextStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.ChekinNotificationsTitle,
            description: strings.Onboard.ChekinNotificationsDescription,
            target: checkinNotificationsRef.current as HTMLElement,
            placement: "bottom",
            onNext: nextStep,
            onPrev: prevStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.AutoCheckinsTitle,
            description: strings.Onboard.AutoCheckinsDescription,
            target: autoCheckinRef.current as HTMLElement,
            placement: "bottom",
            onNext: nextStep,
            onPrev: prevStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.DelayTitle,
            description: strings.Onboard.DelayDescription,
            target: delayRef.current as HTMLElement,
            placement: "bottom",
            onNext: nextStep,
            onPrev: prevStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.AreaTitle,
            description: strings.Onboard.AreaDescription,
            target: checkinAreaRef.current as HTMLElement,
            placement: "bottom",
            onFinish: () => {
                finishOnboardNotifications();
            },
            onPrev: prevStep,
            onClose: closeStep,
        },
    ];

    const finishOnboardNotifications = () => {
        closeStep();
        completeOnboardNotifications();
    };

    const completeOnboardNotifications = () => {
        // TODO MARIO: Logic to mark as complete
        setOnboardStatus((prev) => ({ ...prev, checkInNotifications: true }));
    };

    const initOnboardNotifications = () => {
        flushSync(() => {
            setCompanyTabFilter("settings");
            setEditingCompany(account.company?.uid || "");
        });
        setTimeout(openStep, TOUR_DELAY);
    };

    const voidFn = () => {};

    return {
        notificationsSteps,
        initOnboardNotifications,
        onboardNotificationsIsOpen: isOpen,
        notificationStep: step,
        notificationsPrevStep: canTrigger ? prevStep : voidFn,
        notificationsNextStep: canTrigger ? nextStep : voidFn,
    };
}
