import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { useMemberRouting } from "../../../hooks/members/useMemberRouting";
import { useStrings } from "../../../hooks/useStrings";
import { Uid } from "../../../types/core";
import { RouteInfo } from "../../../types/job";
import DraggableJobItem from "../DraggableJobItem";
import LegLineWithAnnotation from "./LegLineWithAnnotation";
import { StartEnd } from "./StartEnd";

type Props = {
    data: {
        jobIds: string[];
        newJobIds: string[];
        uid: Uid;
        routeInfoObject?: RouteInfo;
        width: number;
    };
    index: number;
    style: React.CSSProperties;
};

function RouteJobListItem({ data, index, style }: Props) {
    const { strings } = useStrings();
    const { uid, jobIds, newJobIds, routeInfoObject, width } = data;
    const jobId = newJobIds[index];
    const { startLocation, endLocation } = useMemberRouting(uid);

    if (jobId === "start") {
        return (
            <div style={{ ...style, display: "flex", alignItems: "end" }}>
                <StartEnd
                    memberUid={uid}
                    text={strings.Routing.Start}
                    location={routeInfoObject?.startLocation || startLocation}
                />
            </div>
        );
    }

    if (jobId === "end") {
        return (
            <div style={style}>
                <LegLineWithAnnotation
                    uid={uid}
                    jobId1={jobIds.length > 0 ? jobIds[jobIds.length - 1] : undefined}
                    routeInfoObject={routeInfoObject}
                    index={jobIds.length}
                    count={jobIds.length}
                    width={width}
                    number={jobIds.length + 0.5}
                />
                <StartEnd
                    memberUid={uid}
                    text={strings.Routing.End}
                    location={routeInfoObject?.endLocation || endLocation}
                />
            </div>
        );
    }

    return (
        <div style={style}>
            {routeInfoObject && (
                <LegLineWithAnnotation
                    uid={uid}
                    jobId1={newJobIds[index - 1]}
                    jobId2={jobId}
                    routeInfoObject={routeInfoObject}
                    index={index - 1}
                    count={jobIds.length}
                    width={width}
                    number={index - 0.5}
                />
            )}
            {!routeInfoObject && (
                <LegLineWithAnnotation
                    uid={uid}
                    index={index}
                    count={jobIds.length}
                    width={width}
                    number={index - 0.5}
                />
            )}
            <Draggable key={jobId} draggableId={jobId} index={index - 1} isDragDisabled={false}>
                {(provided, snapshot) => (
                    <div
                        key={index}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                    >
                        <DraggableJobItem id={jobId} isDragging={snapshot.isDragging} />
                    </div>
                )}
            </Draggable>
        </div>
    );
}

export default React.memo(RouteJobListItem);