import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { useUpdateEffect } from "usehooks-ts";
import { usePlaceSelection } from "../../hooks/places/usePlaceSelection";
import { useStrings } from "../../hooks/useStrings";
import { useToast } from "../../hooks/useToast";
import { geolocatePlaces } from "../../services/privateApi";
import { placesSelectedState } from "../../states/placeState";
import { PlacesBadges } from "../badges/PlacesBadges";
import { HFlex } from "../containers/HFlex";
import { Spacer } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { RemoveIcon } from "../ui/svg";
import { PlacesBulkEdit } from "./PlacesBulkEdit";

export function PlacesEditRow() {
    const { strings, format } = useStrings();
    const { selectionCount, selectedUids, clearSelection, toggleSelected } = usePlaceSelection();
    const [bulkEditExpanded, setExpandedBulkEdit] = useState(false);
    const [, theme] = useStyletron();
    const toast = useToast();
    const selectedPlaces = useRecoilValue(placesSelectedState);

    useUpdateEffect(() => {
        if (selectionCount === 0) {
            setExpandedBulkEdit(false);
        }
    }, [selectionCount]);

    const placesWithoutAddress = useMemo(() => selectedPlaces.filter(p => !p?.address), [selectedPlaces]);

    const onGeolocateClick = () => {
        geolocatePlaces(placesWithoutAddress.map(p => p?.uid));
        toast.showInfo(strings.General.GeolocatingStartedInBackground);
    };

    if (selectionCount === 0) {
        return <div style={{ height: "28px" }}></div>
    }

    return (
        <VFlex style={{ rowGap: theme.sizing.scale300, height: "auto" }}>
            <HFlex spacing>
                <PlacesBadges uids={selectedUids} max={3} onClose={toggleSelected}>
                    {selectedUids.length > 0 && (
                        <Button size="mini"
                                kind="tertiary"
                                shape="pill"
                                onClick={() => clearSelection()}>
                            <RemoveIcon size={14} color={theme.colors.contentTertiary}/>
                        </Button>
                    )}
                </PlacesBadges>
                {!bulkEditExpanded &&
                    <>
                        {placesWithoutAddress.length > 0 &&
                            <>
                                <Button size="mini" kind="secondary" shape="pill" disabled={!!toast}
                                        onClick={() => onGeolocateClick()}>
                                    {strings.General.Geocode}
                                </Button>
                                <Spacer/>
                            </>
                        }
                        <Button size="mini" kind="primary" shape="pill" style={{ width: "180px" }}
                                onClick={() => setExpandedBulkEdit(true)}>
                            {format(strings.Places.BulkEditXPlaces, selectionCount)}
                        </Button>
                    </>
                }
            </HFlex>
            {bulkEditExpanded && <PlacesBulkEdit onFinish={() => setExpandedBulkEdit(false)}/>}
        </VFlex>
    );
}