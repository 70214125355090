import { HeadingXSmall, LabelXSmall } from "baseui/typography";
import { VFlex } from "../containers/VFlex";
import { useTheme } from "../../hooks/useTheme";
import { ReactNode } from "react";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { useRecoilState } from "recoil";
import { expandableSectionState } from "../../states/viewState";
import { Collapse } from "antd";

type Props = {
    title?: string | ReactNode;
    children: ReactNode;
    noMarginTop?: boolean;
    titleProps?: {};
    expandable?: boolean;
    expanded?: boolean;
    defaultExpanded?: boolean;
    collapsedNode?: ReactNode;
    persistenceKey?: string;
    onExpandedChange?: (isExpanded:boolean) => void;
    collapsableStye?: any;
    panelStye?: any;
};

export function Section({
                            title,
                            children,
                            noMarginTop = false,
                            titleProps,
                            expandable = false,
                            expanded = false,
                            defaultExpanded = false,
                            collapsedNode,
                            persistenceKey,
                            onExpandedChange,
                            collapsableStye,
                            panelStye,
                        }: Props) {
    const { theme } = useTheme();
    const sectionKey = persistenceKey || (typeof title === 'string' ? title : "");
    const [isExpanded, setExpanded] = useRecoilState(expandableSectionState({sectionKey, defaultExpanded}));

    if (expandable) {
        const titleNode = !collapsedNode
            ? title
            : (
                <HFlex>
                    {title}
                    <SpacerExpand/>
                    <LabelXSmall color={theme.colors.contentSecondary}
                                 style={{
                                     maxWidth: "280px",
                                     overflow: "hidden",
                                     whiteSpace: "nowrap",
                                     textOverflow: "ellipsis",
                                     paddingRight: "8px",
                                 }}>
                        {collapsedNode}
                    </LabelXSmall>
                </HFlex>
            );
        return (
            <Collapse defaultActiveKey={expanded || isExpanded ? ["0"] : []}
                      onChange={value => {
                          setExpanded(value.length > 0);
                          onExpandedChange && onExpandedChange(value.length > 0)
                      }}
                      className={collapsableStye && "override-collapse"}
                      bordered={false}
                      style={collapsableStye}
            >
                <Collapse.Panel key={"0"} header={titleNode} style={panelStye}>
                    {children}
                </Collapse.Panel>
            </Collapse>
        )
    }

    return (
        <VFlex
            style={{
                height: "auto",
            }}
        >
            {title &&
                (typeof title === "string" ? (
                    <HeadingXSmall
                        marginTop={noMarginTop ? "0px" : undefined}
                        marginBottom={theme.sizing.scale300}
                        {...titleProps}
                    >
                        {title}
                    </HeadingXSmall>
                ) : (
                    title
                ))}
            <VFlex>
                {children}
            </VFlex>
        </VFlex>
    );
}
