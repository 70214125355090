import { StatefulMenu } from "baseui/menu";
import { useTheme } from "../../hooks/useTheme";


export function StyledMenu({ overrides, ...props }: React.ComponentProps<typeof StatefulMenu>) {
    const { theme } = useTheme();

    return (
        <StatefulMenu
            {...props}
            overrides={{
                ...overrides,
                ListItem: {
                    ...overrides?.ListItem,
                    style: ({ $isHighlighted }) => ({
                        color: $isHighlighted ? theme.colors.contentPrimary : theme.colors.contentSecondary,
                    })
                }
            }}
        />
    )
}