import { Button } from "baseui/button";
import { useRecoilValue } from "recoil";
import { useModal } from "../../../hooks/useModal";
import { usePlaceForms } from "../../../hooks/places/usePlaceForms";
import { useStrings } from "../../../hooks/useStrings";
import { useStyleOverrides } from "../../../hooks/useStyleOverrides";
import { editingPlaceIdState } from "../../../states/appState";
import { editPlaceState } from "../../../states/editPlaceState";
import { SpacerExpand } from "../../containers/Spacer";
import { StyledModalContent } from "../../ui/StyledModalContent";
import { PlaceTabs } from "./PlaceTabs";

export function PlaceModalContent() {
    const placeUid = useRecoilValue(editingPlaceIdState);
    const { strings } = useStrings();
    const fields = useRecoilValue(editPlaceState(placeUid || ""));
    const { isNewPlace, hasChanges, onSubmit, isLoading, canSubmit } = usePlaceForms();
    const { closeModal, cancelModal } = useModal();
    const { cancelButtonStyles } = useStyleOverrides();

    return (
        <StyledModalContent
            header={isNewPlace ? strings.Places.NewPlace : fields.name}
            body={<PlaceTabs/>}
            footer={
                <>
                    <SpacerExpand/>
                    {(hasChanges || isNewPlace) && (
                        <Button onClick={cancelModal} size="compact" kind="tertiary" overrides={cancelButtonStyles}>
                            {strings.General.Cancel}
                        </Button>
                    )}
                    <Button
                        onClick={hasChanges ? onSubmit : closeModal}
                        size="compact"
                        isLoading={isLoading}
                        disabled={!canSubmit}
                    >
                        {hasChanges || isNewPlace ? strings.General.Save : strings.General.OK}
                    </Button>
                </>
            }
        />
    );
}
