import { Slider } from "baseui/slider";
import React from "react";
import { useTime } from "../../hooks/useTime";
import { useTheme } from "../../hooks/useTheme";
import { SliderOverrides } from "baseui/slider/types";
import { LabelXSmall } from "baseui/typography";
import { MAX_MINUTES } from "../../constants/days";
import { NoWrapText } from "../containers/NoWrapText";

type Props = {
    mini?: boolean;
    inverted?: boolean;
    values: number[];
    onChange: (values: number[]) => void;
    max?: number;
    step?: number;
}

export function TimeSlider({ max = MAX_MINUTES, values, onChange, step = 1 }: Props) {
    const { theme } = useTheme();
    const { formatSecondsToTime, formatDuration } = useTime();

    const miniOverrides: SliderOverrides = {
        Root: {
            style: {
                marginRight: "8px",
            }
        },
        InnerThumb: ({ $value, $thumbIndex }) => (
            <LabelXSmall style={{
                fontSize: "8px",
                lineHeight: "unset",
                color: theme.colors.contentInversePrimary,
                textAlign: "center"
            }}>
                <NoWrapText>
                    {values.length === 1
                        ? formatDuration($value[$thumbIndex] * 60_000, true, "→")
                        : formatSecondsToTime($value[$thumbIndex] * 60)}
                </NoWrapText>
            </LabelXSmall>
        ),
        Thumb: {
            style: {
                width: "42px",
            }
        },
        ThumbValue: () => null,
        TickBar: () => null,
    };

    const currentValues = values.length === 1 ? values : max < MAX_MINUTES
        ? [values[0], Math.min(max, values[1])] : values;

    return (
        <Slider value={currentValues}
                step={step}
                min={0}
                max={Math.max(max, values[0], 60)}
                persistentThumb
                onChange={({ value }) => onChange(value)}
                overrides={miniOverrides}
        />
    );
}