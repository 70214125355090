import { FormControl } from 'baseui/form-control';
import { Radio, RadioGroup } from 'baseui/radio';
import { TYPE } from 'baseui/select';
import { TimezonePicker } from 'baseui/timezonepicker';
import { LANGUAGES, UNIT_TIME_12, UNIT_TIME_24, UNITS_SI, UNITS_US } from '../../constants/app';
import { MemberField } from "../../types/fields";
import { StyledSelect } from '../ui/StyledSelect';
import { getLanguageFromLocale } from "../../utils/browser";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { useStrings } from "../../hooks/useStrings";
import { Spacer } from "../containers/Spacer";
import { Checkbox } from 'baseui/checkbox';
import { useRecoilState } from 'recoil';
import { snoozeNotificationsState } from '../../states/appState';

type Props = {
    languageValue: string;
    unitDistanceValue: string;
    unitTimeValue: string;
    timezoneValue: string;
    onFieldChange: (key: MemberField, value: string | number) => void;
};

export function AccountSettingsFields({
                                          languageValue,
                                          unitDistanceValue,
                                          unitTimeValue,
                                          timezoneValue,
                                          onFieldChange,
                                      }: Props) {
    const { strings } = useStrings();
    const { formControlSpacing } = useStyleOverrides();
    const [snoozeNotifications, setSnoozeNotifications] = useRecoilState(snoozeNotificationsState);

    return (
        <>
            <FormControl label={strings.General.Language} overrides={formControlSpacing}>
                <StyledSelect
                    closeOnSelect
                    size="compact"
                    clearable={false}
                    placeholder
                    options={LANGUAGES}
                    value={[{ id: getLanguageFromLocale(languageValue) }]}
                    openOnClick
                    type={TYPE.select}
                    onChange={({ option }) => onFieldChange('language', option?.id || '')}
                />
            </FormControl>
            <Spacer height={"32px"}/>
            <FormControl label={strings.General.UnitDistance} overrides={formControlSpacing}>
                <RadioGroup
                    value={unitDistanceValue}
                    align="horizontal"
                    onChange={(event) => onFieldChange('unit_distance', event.currentTarget.value)}
                    name="unit_distance"
                >
                    <Radio value={UNITS_SI}>{strings.General.UnitKilometer}</Radio>
                    <Radio value={UNITS_US}>{strings.General.UnitMiles}</Radio>
                </RadioGroup>
            </FormControl>
            <Spacer height={"32px"}/>
            <FormControl label={strings.General.UnitTime} overrides={formControlSpacing}>
                <RadioGroup
                    value={unitTimeValue}
                    align="horizontal"
                    onChange={(event) => onFieldChange('unit_time', event.currentTarget.value)}
                >
                    <Radio value={UNIT_TIME_24}>{strings.General.UnitTime24}</Radio>
                    <Radio value={UNIT_TIME_12}>{strings.General.UnitTime12}</Radio>
                </RadioGroup>
            </FormControl>
            <Spacer height={"32px"}/>
            <FormControl label={strings.General.TimeZone} overrides={formControlSpacing}>
                <TimezonePicker
                    value={timezoneValue}
                    size="compact"
                    onChange={(value) => onFieldChange('timezone', value?.id || '')}
                    overrides={{
                        Select: {
                            props: {
                                overrides: {
                                    Dropdown: {
                                        style: {
                                            maxHeight: "108px",
                                        }
                                    }
                                }
                            }
                        }
                    }}
                />
            </FormControl>
            <Spacer height={"46px"}/>
            <Checkbox
                checked={snoozeNotifications}
                checkmarkType='toggle'
                labelPlacement='right'
                onChange={(e) => setSnoozeNotifications(e.target.checked)}
            >
                {strings.General.SnoozeNotifications}
            </Checkbox>
        </>
    );
}
