import { arrayMove, List } from "baseui/dnd-list";
import { ListItem, ListItemLabel } from "baseui/list";
import { StatefulPopover } from "baseui/popover";
import { useTheme } from "../../hooks/useTheme";
import { VFlex } from "../containers/VFlex";
import { MenuContainer } from "../menu/MenuContainer";
import { VDotsIcon } from "../ui/svg";
import { Checkbox } from "baseui/checkbox";
import { Button } from "baseui/button";
import { SetterOrUpdater } from "recoil";
import { ColumnsConfiguration } from "../../types/core";

type Props = {
    columns: string[];
    enabledColumns: string[];
    configuration: ColumnsConfiguration;
    disabledColumns: string[];
    onReorder: SetterOrUpdater<string[]>;
    right?: string;
    top?: string;
}

export function ColumnOptions({enabledColumns, columns, configuration, disabledColumns, onReorder, right = "3px", top = "6px"}: Props) {
    const { theme, isDarkTheme } = useTheme();

    const onCheck = (checked: boolean, column: string) => {
        if (checked) {
            onReorder((prev) => [...prev, column]);
        } else {
            onReorder((prev) => prev.filter((col) => col !== column));
        }
    };

    return (
        <div style={{
            position: "absolute",
            right: right,
            top: top,
        }}>
            <StatefulPopover
                placement={"bottomRight"}
                content={({ close }) => (
                    <MenuContainer style={{ padding: theme.sizing.scale200, flexDirection: "row" }}>
                        <VFlex>
                            {columns.map((column) => (
                                <Checkbox
                                    key={column}
                                    checked={enabledColumns.includes(column)}
                                    onChange={(event) => {
                                        onCheck(event.target.checked, column);
                                    }}
                                    overrides={{
                                        Root: {
                                            style: {
                                                paddingTop: theme.sizing.scale0,
                                                paddingBottom: theme.sizing.scale0,
                                                paddingLeft: theme.sizing.scale0,
                                                paddingRight: theme.sizing.scale0,
                                            },
                                        },
                                        Checkmark: {
                                            style: {
                                                width: theme.sizing.scale550,
                                                height: theme.sizing.scale550,
                                            },
                                        },
                                    }}
                                />
                            ))}
                        </VFlex>
                        <VFlex>
                            <List
                                items={enabledColumns.map((column) => configuration[column]?.title ?? "[?]")}
                                onChange={({ oldIndex, newIndex }) => {
                                    onReorder(arrayMove(enabledColumns, oldIndex, newIndex));
                                }}
                                overrides={{
                                    List: {
                                        style: {
                                            marginTop: 0,
                                            marginBottom: 0,
                                        },
                                    },
                                    Item: {
                                        style: {
                                            paddingTop: 0,
                                            paddingLeft: 0,
                                            paddingBottom: 0,
                                            paddingRight: 0,
                                            backgroundColor: theme.colors.backgroundSecondary,
                                        },
                                    },
                                    DragHandle: {
                                        style: {
                                            marginRight: 0,
                                            marginLeft: theme.sizing.scale100,
                                            width: theme.sizing.scale650,
                                            height: theme.sizing.scale650,
                                            order: 2,
                                        },
                                    },
                                    Label: {
                                        style: {
                                            ...theme.typography.LabelXSmall,
                                            lineHeight: theme.sizing.scale650,
                                            order: 1,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "60px"
                                        },
                                    },
                                }}
                            />
                            <ul style={{ margin: 0, padding: 0 }}>
                                {disabledColumns.map((column) => (
                                    <ListItem
                                        key={column}
                                        overrides={{
                                            Content: {
                                                style: {
                                                    marginLeft: theme.sizing.scale0,
                                                    marginRight: theme.sizing.scale800,
                                                    paddingRight: 0,
                                                    backgroundColor: theme.colors.backgroundSecondary,
                                                    minHeight: theme.sizing.scale750,
                                                    borderBottomWidth: 0,
                                                },
                                            },
                                        }}
                                    >
                                        <ListItemLabel
                                            overrides={{
                                                LabelRoot: {
                                                    style: {
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                    }
                                                },
                                                LabelContent: {
                                                    style: {
                                                        ...theme.typography.LabelXSmall,
                                                        lineHeight: theme.sizing.scale650,
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        maxWidth: "120px"
                                                    },
                                                },
                                            }}
                                        >
                                            {configuration[column]?.title || '[?]'}
                                        </ListItemLabel>
                                    </ListItem>
                                ))}
                            </ul>
                        </VFlex>
                    </MenuContainer>
                )}
            >
                <Button
                    shape={"circle"}
                    kind={"secondary"}
                    size="mini"
                    overrides={{
                        BaseButton: {
                            style: {
                                backgroundColor: isDarkTheme ? "#333" : "#EEE",
                            },
                        },
                    }}
                >
                    <VDotsIcon size={14} color={theme.colors.contentSecondary} />
                </Button>
            </StatefulPopover>
        </div>
    );
}