import { SpacerExpand } from "../containers/Spacer";
import { HFlex } from "../containers/HFlex";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect } from "react";
import { useAllJobsSelection } from "../../hooks/jobs/useAllJobsSelection";
import { selectedJobsState } from "../../states/jobsState";
import { JobsBulkEdit } from "../job/JobsBulkEdit";
import { RemoveIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { ActionButton } from "../buttons/ActionButton";
import { Button } from "baseui/button";
import { expandedState } from "../../states/viewState";
import { ChevronDown } from "baseui/icon";
import { useStrings } from "../../hooks/useStrings";
import { Badge, Tooltip } from "antd";
import { LabelMedium } from "baseui/typography";

export function FocusActionRow() {
    const { theme, isDarkTheme } = useTheme();
    const { strings, format } = useStrings();
    const selection = useAllJobsSelection();
    const [expandedBulkEdit, setExpandedBulkEdit] = useRecoilState(expandedState("dispatch-bulk"));
    const selectedJobs = useRecoilValue(selectedJobsState);
    const jobs = selectedJobs.filter(j => !j.isTemplate && !j.isPartOfSequence);
    const templates = selectedJobs.filter(j => j.isTemplate);
    const partOfSeq = selectedJobs.filter(j => j.isPartOfSequence);

    useEffect(() => {
        return () => setExpandedBulkEdit(false);
    }, []);

    if (partOfSeq.length > 0) {
        // for now we do not accept bulk edit for sequences
        return null;
    }
    if (jobs.length > 0 && templates.length > 0) {
        // cannot bulk edit mix of jobs and templates
        return null;
    }

    const badge = (
        <Tooltip title={selectedJobs.length === 1
            ? strings.Job.OneSelectedJob
            : format(strings.Job.XSelectedJobs, jobs.length)
        }>
            <Badge count={selectedJobs.length}/>
        </Tooltip>
    );

    if (expandedBulkEdit) {
        return (
            <HFlex spacing>
                {badge}
                <LabelMedium>
                    {strings.formatString(strings.Dispatch.EditXJobs, selectedJobs.length)}
                </LabelMedium>
                <SpacerExpand/>
            </HFlex>
        );
    }

    return (
        <HFlex spacing style={{
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            whiteSpace: 'nowrap',
            backgroundColor: isDarkTheme ? "black" : "white",
            borderRadius: "4px",
            borderWidth: "1px",
            borderColor: isDarkTheme ? "#333333" : "#CCCCCC",
            borderStyle: "solid",
            marginTop: "2px",
            paddingLeft: "8px",
            paddingTop: "4px",
            paddingRight: "8px",
            paddingBottom: "4px",
            overflow: "visible",
        }}>
            {badge}
            <Button size="mini" kind="secondary" shape="pill"
                    onClick={() => setExpandedBulkEdit(true)}>
                {format(strings.General.EditX, format(strings.Job.XJobs, selectedJobs.length))} <ChevronDown/>
            </Button>
            <JobsBulkEdit mini/>
            <SpacerExpand/>
            <ActionButton kind={"tertiary"} size={"compact"} tooltip={strings.General.Clear}
                          onClick={() => selection.clearSelection()}>
                <RemoveIcon size={24} color={theme.colors.contentPrimary}/>
            </ActionButton>
        </HFlex>
    );
}