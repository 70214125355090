import { Button } from "baseui/button";
import { Hide, Show } from "baseui/icon";
import { useState } from "react";
import { useStrings } from "../../hooks/useStrings";

type Props = {
    content: string;
}

export function TemplatesInfo({ content }: Props) {
    const [showInfo, setShowInfo] = useState(false);
    const { strings } = useStrings();

    return (
        <div>
            <Button
                size="compact"
                kind="tertiary"
                onClick={() => setShowInfo((prev) => !prev)}
                shape={"pill"}
                startEnhancer={showInfo ? <Hide/> : <Show/>}
            >
                {strings.General.InfoAboutTemplates}
            </Button>
            {showInfo && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
            )}
        </div>
    );
}