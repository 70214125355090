import { FormControl } from "baseui/form-control";
import { useRecoilState, useRecoilValue } from "recoil";
import { useStrings } from "../../../hooks/useStrings";
import { useTheme } from "../../../hooks/useTheme";
import { editingZoneIdState } from "../../../states/appState";
import { editZoneState } from "../../../states/editZoneState";
import { membersDangerousState } from "../../../states/membersState";
import { teamsState } from "../../../states/teamsState";
import { Team } from "../../../types/core";
import { Member } from "../../../types/member";
import { SectionedWrapper } from "../../containers/SectionedWrapper";
import { Spacer } from "../../containers/Spacer";
import { ColorPicker } from "../../pickers/ColorPicker";
import { MembersSelect } from "../../pickers/MembersSelect";
import { TeamsSelect } from "../../pickers/TeamsSelect";
import { FormInput } from "../../ui/FormInput";
import { Section } from "../../ui/Section";

export function Zone() {
    const { strings } = useStrings()
    const { theme } = useTheme();
    const editingZoneUid = useRecoilValue(editingZoneIdState);
    const [zoneFields, setZoneFields] = useRecoilState(editZoneState(editingZoneUid || ""));
    const members = useRecoilValue(membersDangerousState);
    const teams = useRecoilValue(teamsState);
    const selectedMembers = members.filter((m) => zoneFields.assignedMembers.includes(m.id));
    const selectedTeams = teams.filter((t) => zoneFields.assignedTeams.includes(t.id));

    const onChangeInput = (key: string, value: string | number) => {
        setZoneFields((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const onTeamsChange = (teams: Team[]) => {
        setZoneFields((prev) => ({
            ...prev,
            assignedTeams: teams.map((t) => t.id),
        }));
    }

    const onMembersChange = (members: Member[]) => {
        setZoneFields((prev) => ({
            ...prev,
            assignedMembers: members.map((t) => t.id),
        }));
    }

    return (
        <SectionedWrapper
            style={{
                height: "50vh",
                overflowY: "auto",
                paddingLeft: "8px",
                paddingRight: "8px",
            }}
        >
            <Section>
                <Spacer height={"48px"}/>
                <FormInput
                    label={strings.General.Name}
                    value={zoneFields.name || ""}
                    onChange={onChangeInput}
                    placeholder={strings.Places.EnterName}
                    required
                    field="name"
                />
                <FormControl label={strings.General.Color} overrides={{
                    ControlContainer: {
                        style: {
                            [theme.mediaQuery.medium]: {
                                width: "90px",
                            }
                        }
                    }
                }}>
                    <ColorPicker selectedColor={zoneFields.color} onChange={(color) => onChangeInput("color", color)}/>
                </FormControl>
                <FormControl label={strings.General.Teams}>
                    <TeamsSelect
                        placeholder={`${strings.General.AssignToTeams}...`}
                        selectedTeams={selectedTeams}
                        onSelect={(teams) => onTeamsChange(teams as Team[])}
                    />
                </FormControl>
                <FormControl label={strings.General.Members}>
                    <MembersSelect
                        placeholder={`${strings.General.AssignToMembers}...`}
                        selectedMemberUids={selectedMembers.map(m => m.uid)}
                        onMemberSelect={(members) => onMembersChange(members as Member[])}
                    />
                </FormControl>
            </Section>
            <Spacer height={"48px"}/>
        </SectionedWrapper>
    );
}
