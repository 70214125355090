import { TourProps } from "antd";
import { flushSync } from "react-dom";
import { useSetRecoilState } from "recoil";
import { PAGE } from "../../constants/pages";
import { sidebarManageOpenState, sidebarPinnedState } from "../../states/appState";
import { useNavigator } from "../useNavigator";
import { useOnboardRefs } from "./useOnboardRefs";
import { useOnboardStep } from "./useOnboardStep";
import { useStrings } from "../useStrings";
import { ONBOARD_ADDING_USERS, TOUR_DELAY } from "../../constants/onboard";

export function useOnboardMembers() {
    const { strings } = useStrings();
    const { navigateToPage } = useNavigator();
    const { teamRef, importMembersRef, addMemberRef } = useOnboardRefs();
    const { step, nextStep, prevStep, closeStep, openStep, isOpen, canTrigger } = useOnboardStep(ONBOARD_ADDING_USERS);
    const setSetupOpen = useSetRecoilState(sidebarManageOpenState);
    const setSidebarPinned = useSetRecoilState(sidebarPinnedState);
    const memberSteps: TourProps["steps"] = [
        {
            title: strings.Onboard.TeamTitle,
            description: strings.Onboard.TeamDescription,
            target: teamRef.current as HTMLElement,
            placement: "right",
            onNext: () => {
                flushSync(() => {
                    navigateToPage(PAGE.TEAM);
                });
                setTimeout(nextStep, TOUR_DELAY);
            },
            onClose: closeStep,
        },
        {
            title: strings.Onboard.ImportMembersTitle,
            description: strings.Onboard.ImportMembersDescription,
            target: importMembersRef.current as HTMLElement,
            placement: "bottom",
            onNext: nextStep,
            onPrev: prevStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.AddMemberTitle,
            description: strings.Onboard.AddMemberDescription,
            target: addMemberRef.current as HTMLElement,
            placement: "bottom",
            onFinish: () => {
                finishOnboardMembers();
            },
            onPrev: prevStep,
            onClose: closeStep,
        },
    ];

    const finishOnboardMembers = () => {
        closeStep();
    };

    const initOnboardMembers = () => {
        flushSync(() => {
            setSetupOpen(true);
            setSidebarPinned(true);
        });
        setTimeout(openStep, TOUR_DELAY);
    };

    const voidFn = () => {};

    return {
        memberSteps,
        initOnboardMembers,
        onboardMembersIsOpen: isOpen,
        memberStep: step,
        membersNextStep: canTrigger ? nextStep : voidFn,
        membersPrevStep: canTrigger ? prevStep : voidFn,
        finishOnboardMembers: canTrigger ? finishOnboardMembers : voidFn,
    };
}
