import { useRecoilState } from "recoil";
import { getNextDirection } from "../../utils/sort";
import { allJobsCustomSortFieldState, allJobsSortDirectionState, allJobsSortState } from '../../states/allJobsState';
import { SORT_DIRECTION } from "baseui/table";

export function useAllJobsSort() {
    const [direction, setDirection] = useRecoilState(allJobsSortDirectionState)
    const [jobsSort, setJobsSort] = useRecoilState(allJobsSortState);
    const [customSortField, setCustomSortField] = useRecoilState(allJobsCustomSortFieldState);

    const handleSort = (sortNumber: number, customField?: string) => {
        setCustomSortField(customField)
        if (jobsSort !== sortNumber) {
            setJobsSort(sortNumber);
            setDirection(SORT_DIRECTION.DESC);
        } else {
            setJobsSort(sortNumber);
            setDirection(getNextDirection(direction));
        }
    };

    const getDirection = (sortNumber: number, customField?: string) => {
        if (jobsSort === sortNumber) {
            if (!customField || (customField && customField === customSortField)) {
                return direction;
            }
        }

        return null;
    }

    return {
        handleSort,
        getDirection,
    };
}
