import { JobType } from '../../types/core';
import { Company } from '../../types/company';
import { editAccount } from '../../services/api';
import { useState } from 'react';
import { randomNumber } from '../../utils/app';
import useAccount from '../useAccount';


export function useJobTypes() {
    const [isLoading, setLoading] = useState(false);
    const { company, setCompany } = useAccount();

    const createJobType = async (name: string): Promise<number | undefined> => {
        if (!company) {
            return;
        }
        setLoading(true);
        const customTypeIds = company.job_custom_types.map((customType) => customType.id);
        const optionId = randomNumber(customTypeIds);
        const data = {
            job_custom_types: [
                ...company.job_custom_types,
                {
                    name,
                    id: optionId,
                }
            ]
        }
        const { status, account: updatedCompany } = await editAccount({ uid: company.uid, ...data });
        if (status) {
            setCompany(updatedCompany as Company);
            setLoading(false);
            return optionId;
        }
        setLoading(false);
    }

    const bulkEditJobTypes = async (jobTypes: JobType[]) => {
        if (!company) {
            return;
        }
        setLoading(true);
        const existingJobTypes = jobTypes.filter((jobType) => jobType.id !== -1);
        const typeIds = [...existingJobTypes.map((jobType) => jobType.id)];
        const createdJobTypes = jobTypes.filter((jobType) => jobType.id === -1);
        const newJobTypes = createdJobTypes.map((newJobType) => {
            const optionId = randomNumber(typeIds);
            typeIds.push(optionId);
            return {
                name: newJobType.name,
                id: optionId,
            }
        });
        const data = {
            job_custom_types: [
                ...existingJobTypes,
                ...newJobTypes
            ]
        }
        const { status, account: updatedCompany } = await editAccount({ uid: company.uid, ...data });
        if (status) {
            setCompany(updatedCompany as Company);
        }
        setLoading(false);
    }


    return {
        jobTypesIsLoading: isLoading,
        createJobType,
        bulkEditJobTypes,
    }
}