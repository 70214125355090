import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from "./svg";
import { useTheme } from "../../hooks/useTheme";

export function TristateCheckbox({
                                     size = 18,
                                     allSelected,
                                     anySelected
                                 }: { size?: number, allSelected: boolean, anySelected: boolean }) {
    const { theme } = useTheme();

    return (
        allSelected
            ? <CheckboxCheckedIcon size={18} color={theme.colors.contentPrimary}/>
            : anySelected
                ? <CheckboxCheckedIcon size={18} color={theme.colors.contentTertiary}/>
                : <CheckboxUncheckedIcon size={18} color={theme.colors.contentPrimary}/>
    );
}