import { idbStorageEffect } from './../utils/persistence';
import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { Member } from '../types/member';
import { atomWithPersistence } from '../utils/persistence';
import { sortMembers, sortMembersByHeader } from "../utils/memberUtils";
import { Filter, Uid, UidNameUsername } from '../types/core';
import { SORT_DIRECTION, SortDirection } from 'baseui/table';
import { containsSubstring } from "../utils/filter";
import { MEMBERS_DEFAULT_COLUMNS } from '../constants/members';
import { getRecoil } from "../providers/RecoilAccessProvider";


export enum MemberSort {
    NAME,
    ROLE,
    LAST_LOCATION,
    APP,
}

export const membersSortState = atom({
    key: "members.sort",
    default: MemberSort.NAME,
});

export const membersSortDirectionState = atom<SortDirection>({
    key: "members.sort.direction",
    default: SORT_DIRECTION.ASC,
});

export const memberUidsState = atom<Uid[]>({
    key: "members.uids",
    default: [] as Uid[],
    effects: [idbStorageEffect]
});

export const memberState = atomFamily<Member | undefined, Uid>({
    key: 'member',
    default: undefined,
    effects: [idbStorageEffect]
});

export const membersDangerousState = selector<Member[]>({
    key: "members.all",
    get: ({ get }) => {
        const members = get(memberUidsState)
            .map(uid => get(memberState(uid)))
            .filter(member => !!member) as Member[];
        return members
            .filter(member => member.username)
            .sort(sortMembers);
    }
});

// use this to avoid unnecessary updates if you are only interested in member names
// it is updated in RecoilCacheProvider
export const memberNamesState = atom({
    key: "member.names",
    default: [] as UidNameUsername[],
});

export const memberIsVisibleState = selectorFamily<boolean, Uid>({
    key: "member.name.visibility",
    get: (uid) => ({ get }) => {
        const member = get(memberState(uid));
        const search = get(memberSearchState);
        const filters = get(membersFiltersState);
        if (search.length > 0) {
            if (!containsSubstring(member?.name, search) && !containsSubstring(member?.username, search)) {
                return false;
            }
        }
        if (filters.length > 0) {
            const grouped = filters.filter(f => f.group);
            const ungrouped = filters.filter(f => !f.group);
            const checkGrouped = grouped.length === 0 || grouped.some(f => f.matches(member, f.id));
            const checkUngrouped = ungrouped.length === 0 || ungrouped.every(f => f.matches(member, f.id));
            return checkGrouped && checkUngrouped;
        }
        return true;
    }
});

export const memberNamesFilteredState = selector({
    key: "members.names.filtered",
    get: ({ get }) => {
        const memberNames = get(memberNamesState);
        return memberNames.filter((memberName) => get(memberIsVisibleState(memberName.uid)));
    }
});

export const membersFilteredDangerousState = selector({
    key: "members.filtered",
    get: ({ get }) => {
        const members = get(membersDangerousState);
        const search = get(memberSearchState);
        const hasSearch = search.length > 0;
        const filters = get(membersFiltersState);
        return members
            .filter(member => !hasSearch
                || member.name.toLowerCase().includes(search.toLowerCase())
                || member.username.toLowerCase().includes(search.toLowerCase())
            )
            .filter(member => filters.length === 0 || filters.some(f => f.matches(member, f.id)));
    },
});

export const memberSearchState = atomWithPersistence("members.filter.search", '');

export const membersFiltersState = atom<Filter[]>({
    key: "members.filters",
    default: [] as Filter[]
});

export const sortedMembersState = selector<Member[]>({
    key: "members.table",
    get: ({ get }) => {
        const memberNames = [...get(memberNamesFilteredState)];
        const sort = get(membersSortState);
        const direction = get(membersSortDirectionState);
        const members = memberNames
            .map(m => getRecoil(memberState(m.uid)))
            .filter(m => !!m) as Member[];
        const sortedMembers = members.sort((m1, m2) => sortMembersByHeader(m1, m2, sort));
        return direction === SORT_DIRECTION.DESC ? sortedMembers.reverse() : sortedMembers;
    }
})

export const membersColumnsState = atomWithPersistence<string[]>("members.columns", MEMBERS_DEFAULT_COLUMNS);

export const updateTeamMembersState = atom<number>({
    key: "team.members.update",
    default: 0,
});