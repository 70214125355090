import { Button } from "baseui/button";
import { StyledHead, StyledHeadCell } from "baseui/table";
import { tableHeaderStyle } from "../../constants/ui";
import { usePlaceSelection } from "../../hooks/places/usePlaceSelection";
import { usePlacesSort } from "../../hooks/places/usePlacesSort";
import { useStrings } from "../../hooks/useStrings";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { StyledSortableHeadCell } from "../ui/StyledSortableHeadCell";
import { TristateCheckbox } from "../ui/TristateCheckbox";
import { PlaceSort } from "../../states/placeState";

export function PlaceHeaderRow() {
    const { strings } = useStrings();
    const { allSelected, toggleAllSelected, selectionCount } = usePlaceSelection();
    const { handleSort, getDirection } = usePlacesSort();
    const { placesColumnStyle } = useStyleOverrides();

    return (
        <StyledHead role="row">
            <StyledHeadCell $style={{ ...placesColumnStyle[0], ...tableHeaderStyle, justifyContent: "center" }}>
                <Button
                    size="mini"
                    kind="tertiary"
                    onClick={(event) => toggleAllSelected()}
                >
                    <TristateCheckbox allSelected={allSelected} anySelected={selectionCount > 0}/>
                </Button>
            </StyledHeadCell>
            <StyledSortableHeadCell title={strings.General.Color} direction={getDirection(PlaceSort.COLOR)} styles={placesColumnStyle[1]}
                                    onSort={() => handleSort(PlaceSort.COLOR)} alignment="center"/>
            <StyledSortableHeadCell title={strings.General.Name} direction={getDirection(PlaceSort.NAME)} styles={placesColumnStyle[2]}
                                    onSort={() => handleSort(PlaceSort.NAME)}/>
            <StyledSortableHeadCell title={strings.General.Radius} direction={getDirection(PlaceSort.RADIUS)} styles={placesColumnStyle[3]}
                                    onSort={() => handleSort(PlaceSort.RADIUS)}/>
            <StyledSortableHeadCell title={strings.General.Created} direction={getDirection(PlaceSort.CREATED)} styles={placesColumnStyle[4]}
                                    onSort={() => handleSort(PlaceSort.CREATED)}/>
        </StyledHead>
    )
}