import { FixedMarker } from "baseui/map-marker";
import { HFlex } from "../../containers/HFlex";
import { Marker } from "react-map-gl";
import useAccount from "../../../hooks/useAccount";
import { useSetRecoilState } from "recoil";
import { editingCompanyIdState } from "../../../states/appState";
import { Avatar } from "antd";
import { truncateText } from "../../../utils/strings";

export function CompanyMarker() {
    const { company } = useAccount();
    const setEditingCompany = useSetRecoilState(editingCompanyIdState);

    if (!company?.location) {
        return null;
    }

    const truncatedName = truncateText(company.name, 8);

    return (
        <Marker
            longitude={company.location.lng ?? 0}
            latitude={company.location.lat ?? 0}
            anchor="bottom"
            onClick={() => setEditingCompany(company?.uid)}
        >
            <FixedMarker size={"x-small-square"} label={
                <HFlex alignCenter spacing>
                    <Avatar src={company.portrait} size={"small"}></Avatar>
                    {truncatedName}
                </HFlex>
            }/>
        </Marker>
    );
}