import { SectionedWrapper } from "../containers/SectionedWrapper";
import { Section } from "../ui/Section";
import useAccount from "../../hooks/useAccount";
import { FormInput } from "../ui/FormInput";
import { useTheme } from "../../hooks/useTheme";
import { Button } from "baseui/button";
import { LabelMedium, LabelSmall, LabelXSmall } from "baseui/typography";
import { useStrings } from "../../hooks/useStrings";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { useBilling } from "../../hooks/useBilling";
import { useEffect, useState } from "react";
import { MemberBadges } from "../badges/MemberBadges";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { memberUidsState } from "../../states/membersState";
import { ConfirmModal } from "../ui/ConfirmModal";
import { useToast } from "../../hooks/useToast";
import { useCompanyActivation } from "../../hooks/company/useCompanyActivation";
import { StyledSelect } from "../ui/StyledSelect";
import { analyticsEvent } from "../../utils/analytics";
import { useEffectOnce } from "usehooks-ts";
import { Payment } from "./Payment";
import { paymentClientSecretState, showPaymentMethodState } from "../../states/paymentState";
import { createPaymentIntent, setDefaultPaymentMethod } from "../../services/privateApi";
import { VFlex } from "../containers/VFlex";
import { CreditCardIcon } from "../ui/svg";
import { Card, Tag } from "antd";
import { FlexGridLayout } from "../containers/FlexGridLayout";
import { PaymentSource } from "../../types/billing";
import useDirector from "../../hooks/useDirector";
import { Centered } from "../containers/Centered";
import { UpcomingInvoice } from "./UpcomingInvoice";
import { PastInvoices } from "./PastInvoices";
import { ChoosePlan } from "./ChoosePlan";
import { DAY, HOUR, MINUTE } from "../../utils/time";

type Reason = {
    id: string;
    labelKey: string;
}

export function BillingTab() {
    const { strings, format } = useStrings();
    const { company, isSelfAdminOrCompany } = useAccount();
    const { theme } = useTheme();
    const billing = useBilling();
    const [showMembers, setShowMembers] = useState(false);
    const memberUids = useRecoilValue(memberUidsState);
    const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
    const [showSelectReasonDialog, setShowSelectReasonDialog] = useState(false);
    const [selectedReason, setSelectedReason] = useState<Reason | undefined>(undefined);
    const toast = useToast();
    const activation = useCompanyActivation();
    const director = useDirector();
    const [loadingDefault, setLoadingDefault] = useState(false);

    useEffectOnce(() => {
        director.performBilling();
    });

    const [showPayment, setShowPayment] = useRecoilState(showPaymentMethodState);
    const setClientSecret = useSetRecoilState(paymentClientSecretState);

    useEffect(() => {
        createPaymentIntent().then(({ clientSecret }) => {
            if (clientSecret) {
                setClientSecret(clientSecret);
            }
        })
    }, []);

    const reasons = [
        { id: "no_time", labelKey: strings.Billing.ReasonNoTime },
        { id: "technical", labelKey: strings.Billing.ReasonTechnical },
        { id: "provider", labelKey: strings.Billing.ReasonProvider },
        { id: "price", labelKey: strings.Billing.ReasonPrice },
        { id: "feature", labelKey: strings.Billing.ReasonFeature },
        { id: "deployment", labelKey: strings.Billing.ReasonDeployment },
        { id: "not_working", labelKey: strings.Billing.ReasonNotWorking },
        { id: "integration", labelKey: strings.Billing.ReasonIntegration },
        { id: "difficult", labelKey: strings.Billing.ReasonDifficult },
        { id: "other", labelKey: strings.Billing.ReasonOther },
    ] as Reason[];

    const onLicensesClick = () => setShowMembers(!showMembers);
    const onActivateClick = async () => {
        const status = await activation.activate()
        if (status) {
            toast.showSuccess(strings.Billing.AccountIsActivated);
        }
    };
    const onDeactivateClick = () => {
        analyticsEvent("billing_deactivate_click");
        setShowDeactivateDialog(true);
    }
    const onDeactivateConfirmed = () => {
        analyticsEvent("billing_deactivate_confirmed");
        setShowDeactivateDialog(false);
        setShowSelectReasonDialog(true);
    };
    const onDeactivationFlowCompleted = async () => {
        const status = await activation.deactivate(selectedReason?.id as string, selectedReason?.labelKey as string, "");
        if (status) {
            setShowSelectReasonDialog(false);
            toast.showSuccess(strings.Billing.CompanyAccountDeactivatedConfirmation);
        } else {
            toast.showWarning(strings.General.SomethingWentWrong)
        }
    };

    const onSetAsDefaultClick = async (paymentMethod: PaymentSource) => {
        setLoadingDefault(true);
        await setDefaultPaymentMethod(paymentMethod);
        await director.performBilling();
        setLoadingDefault(false);
    }

    if (showPayment) {
        return <Payment/>;
    }

    if (!company) {
        return <LabelMedium>{strings.Company.NoCompanyAcc}</LabelMedium>;
    }

    const onSetupPaymentMethod = () => {
        setShowPayment(true);
    }

    const paymentMethodSection = (
        <Section title={strings.Billing.PaymentMethod}>
            {billing.hasPaymentSource &&
                <FlexGridLayout baseWidth={250}>
                    {billing.paymentMethods?.map((method, index) =>
                        <Card key={index}>
                            <VFlex spacing>
                                <HFlex alignCenter spacing>
                                    <CreditCardIcon size={24}/>
                                    <LabelXSmall>{method.isCard ? method.brand + " ••••" + method.last4 : method.type}</LabelXSmall>
                                </HFlex>
                                {method.isCard &&
                                    <LabelXSmall color={theme.colors.contentTertiary}>
                                        {strings.Billing.Expires + " " + method.expMonth + "/" + method.expYear}
                                    </LabelXSmall>
                                }
                                <HFlex>
                                    {method.id === billing.defaultPaymentMethod &&
                                        <Tag color={"green"}>{strings.Billing.Default}</Tag>}
                                    {method.id !== billing.defaultPaymentMethod && <HFlex spacing>
                                        <Button kind="secondary"
                                                size={"mini"}
                                                onClick={() => onSetAsDefaultClick(method)}
                                                isLoading={loadingDefault}
                                        >
                                            {strings.General.SetAsDefault}
                                        </Button>
                                    </HFlex>}
                                    <SpacerExpand/>
                                </HFlex>
                            </VFlex>
                        </Card>
                    )}
                    <Centered>
                        <Button size={"compact"}
                                kind={"primary"}
                                onClick={onSetupPaymentMethod}
                                disabled={!isSelfAdminOrCompany}
                        >
                            {strings.Billing.AddPaymentMethod}
                        </Button>
                    </Centered>
                </FlexGridLayout>
            }
            {!billing.hasPaymentSource && <>
                <HFlex>
                    <Button kind={"primary"} onClick={onSetupPaymentMethod}>
                        {strings.Billing.SetupPaymentMethodNow}
                    </Button>
                    <SpacerExpand/>
                </HFlex>
                <Spacer/>
                <LabelXSmall>{strings.Billing.YouWillBeChargedFirstInvoiceDue}</LabelXSmall>
            </>}
        </Section>
    );

    const showApplicationStatusTop = !billing.isActiveCompany
        || !billing.paymentMethods
        || billing.paymentMethods.length === 0;

    const millisLeftTrialing = billing.endOfTrial ? billing.endOfTrial - Date.now() : 0;
    const daysLeftTrialing = millisLeftTrialing > 0 ? Math.ceil(millisLeftTrialing / DAY) : 0;
    const hoursLeftTrialing = millisLeftTrialing > 0 ? Math.ceil(millisLeftTrialing / HOUR) : 0;
    const minutesLeftTrialing = millisLeftTrialing > 0 ? Math.ceil(millisLeftTrialing / MINUTE) : 0;
    const textLeftTrialing =
        daysLeftTrialing > 1 ? format(strings.General.XDays, daysLeftTrialing)
            : hoursLeftTrialing > 1 ? format(strings.General.XHours, hoursLeftTrialing)
                : minutesLeftTrialing > 0 ? format(strings.General.XMinutes, minutesLeftTrialing)
                    : "";
    const applicationStatusSection = (
        <Section title={strings.Billing.ApplicationStatus} noMarginTop>
            <FormInput
                label={""}
                value={billing.applicationStatusText as string}
                field="readonly"
                caption={textLeftTrialing && format(strings.Billing.YouHaveXLeftUntilTrialPeriodEnds, textLeftTrialing)}
            />
            {billing.isActiveCompany && !billing.isTrialing && !billing.hasPaymentSource && !billing.isDirectBilling &&
                <LabelXSmall color={theme.colors.warning500}>{strings.Billing.TrialExpiredDesc}</LabelXSmall>
            }
            <HFlex>
                <SpacerExpand/>
                {billing.isActiveCompany && isSelfAdminOrCompany &&
                    <Button size={"mini"} kind={"tertiary"} onClick={onDeactivateClick}>
                        {strings.Billing.Deactivate}
                    </Button>
                }
                {!billing.isActiveCompany &&
                    <Button size={"mini"} kind={"primary"} onClick={onActivateClick}>
                        {strings.Billing.ActivateAccountNow}
                    </Button>
                }
                {showDeactivateDialog && <ConfirmModal
                    title={strings.Billing.DeactivateCompanyAccount}
                    description={strings.Billing.DeactivateDesc}
                    isOpen={showDeactivateDialog}
                    onConfirm={onDeactivateConfirmed}
                    onCancel={() => setShowDeactivateDialog(false)}
                    cancelText={strings.General.Cancel}
                    confirmText={strings.Billing.Deactivate}
                    danger
                />}
                {showSelectReasonDialog &&
                    <ConfirmModal
                        title={strings.Billing.DeactivateCompanyAccountReasonTitle}
                        description={strings.Billing.DeactivateCompanyAccountReasonDesc}
                        isOpen={showSelectReasonDialog}
                        onConfirm={onDeactivationFlowCompleted}
                        onCancel={() => setShowSelectReasonDialog(false)}
                        cancelText={strings.General.Cancel}
                        confirmText={strings.Billing.Deactivate}
                        canConfirm={!!selectedReason}
                        danger
                    >
                        <StyledSelect
                            options={reasons}
                            value={selectedReason ? [selectedReason] : undefined}
                            labelKey="labelKey"
                            onChange={({ option }) => setSelectedReason(option as Reason)}
                        />
                    </ConfirmModal>
                }
            </HFlex>
        </Section>
    );

    const billingStatus = billing.billingStatus;
    const billingStatusSection = billingStatus !== "active" && billingStatus !== "trialing" && (
        <Section title={strings.Billing.BillingStatus}>
            <HFlex>
                <FormInput
                    label={""}
                    value={billing.statusText}
                    field="readonly"
                />
                <Spacer/>
                {billing.isDelinquent &&
                    <LabelXSmall color={theme.colors.warning500}>
                        {strings.Billing.DelinquentMessage}
                    </LabelXSmall>
                }
            </HFlex>
        </Section>
    );

    return (
        <SectionedWrapper>
            {showApplicationStatusTop && applicationStatusSection}
            {billingStatusSection}
            <Section title={strings.Plan.YourPlan}>
                <ChoosePlan/>
            </Section>
            {!billing.isDirectBilling && paymentMethodSection}
            <Section title={strings.Billing.Licenses}>
                <HFlex>
                    <Button size={"compact"} kind={"secondary"} onClick={onLicensesClick}>
                        {billing.licenseCaption}
                    </Button>
                </HFlex>
                {showMembers && <MemberBadges max={100} members={memberUids}/>}
            </Section>
            {!billing.isDirectBilling && !!billing.invoice?.nextPaymentAttempt &&
                <Section title={strings.Billing.UpcomingInvoice}>
                    <UpcomingInvoice/>
                </Section>
            }
            {!billing.isDirectBilling &&
                <Section title={strings.Billing.PastInvoices}>
                    <PastInvoices/>
                </Section>
            }
            <Section title={strings.Billing.Questions}>
                <LabelSmall>billing@hellotracks.com</LabelSmall>
            </Section>
            {!showApplicationStatusTop && applicationStatusSection}
            <Spacer height={"48px"}/>
        </SectionedWrapper>
    );
}