import { CSV_EXTENSION, EXCEL_EXTENSION } from "../constants/app";
import { HTTPS_DOMAIN } from "../services/api";
import { truncateText } from "../utils/strings";
import { CustomField, Day, Label, Latitude, LatLng, Longitude, Timestamp, Uid } from "./core";
import { FormContent } from "./form";
import { HOUR } from "../utils/time";

export const PROGRESS_STATUS_UNASSIGNED = 0;
export const PROGRESS_STATUS_SCHEDULED = 1;
export const PROGRESS_STATUS_ACCEPTED = 2;
export const PROGRESS_STATUS_IN_PROGRESS = 3;
export const PROGRESS_STATUS_SUCCESS = 4;
export const PROGRESS_STATUS_ISSUE = 5;
export const PROGRESS_STATUS_REJECTED = 6;

export const PRIO_HIGH = 9;
export const PRIO_NORMAL = 5;
export const PRIO_LOW = 2;
export const MAX_URLS = 16;
export const STATUS_ACTIVE = 'active';
export const STATUS_ARCHIVED = 'archived';
export const STATUS_DELETED = 'deleted';
export const NUMBER_LAST = 99;

export type JobStatus = typeof STATUS_ACTIVE | typeof STATUS_ARCHIVED | typeof STATUS_DELETED;

export enum JobTypes {
    WORK,
    PICKUP,
    DROPOFF,
}

export interface Job {
    id: string;
    assigneeName: string;
    assigneeUsername: string;
    carryOver: number;
    confirmationEmails: string;
    contactEmail: string;
    contactName: string;
    contactPhone: string;
    customFields: CustomField[];
    day: Day;
    destinationLat: Latitude;
    destinationLng: Longitude;
    destinationName: string;
    destinationText: string;
    destinationUrl: string;
    dispatcherName: string;
    dispatcherUid: Uid;
    dispatcherUrl: string;
    extra_number_types: string;
    extra_text_types: string;
    inputFormConfig: string; // stringified FormContent, empty if using standard
    formConfig: string; // read only stringified FormContent, also set if standard
    isPartOfSequence?: boolean;
    isTemplate?: boolean;
    itemsToDropoff: number;
    itemsToPickup: number;
    linkedZones: string;
    linkedForms: string;
    manualChecks: string;
    number: number;
    onSiteSeconds: number;
    orderId: number;
    placeName: string;
    placeUid: Uid;
    placeUrl: string;
    priority: number;
    radius: number;
    receiverUid: Uid;
    requiredEquipment?: string;
    requiredSkills?: string;
    sequenceId?: string;
    signatureName: string;
    signaturePdf: string;
    signatureSvg: string;
    status: JobStatus;
    submittedForms: string;
    teamId: number;
    textDispatcher: string;
    textReceiver: string;
    tsAccepted: Timestamp;
    tsAssigned: Timestamp;
    tsCheckIn: Timestamp;
    tsCheckOut: Timestamp;
    tsCreated: Timestamp;
    tsDoneFailed: Timestamp;
    tsDoneSuccess: Timestamp;
    tsRejected: Timestamp;
    tsSaved: Timestamp;
    tsScheduled: Timestamp;
    tsTripStarted: Timestamp;
    tsSignature: Timestamp;
    type: number;
    windowEnd: number;
    windowStart: number;
    worker: string;
    workerEmail: string;
    workerName: string;
    workerPhone: string;
    workerUsername: string;
    assigneeHistory?: string;
    progressStatusHistory?: string;
    createdBy?: Uid;
    createdVia?: string;
    associatedJobId?: string;
    comments?: string;
    labels: string;
    tripId: string;

    [key: `custom_${string}`]: string;

    [key: `url_${number}`]: string;

    [key: `extra_text_${number}_key`]: string;

    [key: `extra_text_${number}_val`]: string;

    [key: `extra_number_${number}_key`]: string;

    [key: `extra_number_${number}_val`]: number;
}

export function getJobReadOnlyFields(isNewJob: boolean) {
    const fields: (keyof Job)[] = [
        "tsDoneFailed",
        "tsDoneSuccess",
        "tsRejected",
        "tsSaved",
        "tsAccepted",
        "tsScheduled",
        "tsAssigned",
        "assigneeHistory",
        "progressStatusHistory"
    ];

    if (!isNewJob) {
        fields.push("number");
    }

    return fields;
};

export interface RouteInfo {
    src: "google" | "azure" | "solve" | "unknown";
    travelMode: "car" | "bicycle" | "pedestrian" | "truck" | "van" | "motorcycle";
    optimized: boolean;
    savedTs: Timestamp;
    routeTimeInSec: number;
    routeDistanceInKM: number;
    sequence: number[];
    legTimesInSec: number[];
    legWaitInSec: number[];
    legDistancesInKM: number[];
    metaOnSiteTimesInSec: number[];
    metaStartTime: number;
    startLocation: LatLng;
    endLocation: LatLng;
    memberUid: Uid;
    skills: string[],
    equipment: string[],
}

export interface ConfirmationEmail {
    email: string;
    onSuccess: boolean;
}

export interface ManualCheck {
    ts: Timestamp;
    type: "checkin" | "checkout";
}

export interface AssigneeHistoryEntry {
    ts: Timestamp;
    assigneeUid: Uid;
    dispatcherUid: Uid;
}

export interface ProgressStatusHistoryEntry {
    ts: Timestamp;
    status: typeof PROGRESS_STATUS_UNASSIGNED
        | typeof PROGRESS_STATUS_SCHEDULED
        | typeof PROGRESS_STATUS_ACCEPTED
        | typeof PROGRESS_STATUS_IN_PROGRESS
        | typeof PROGRESS_STATUS_SUCCESS
        | typeof PROGRESS_STATUS_ISSUE
        | typeof PROGRESS_STATUS_REJECTED;
    uid: Uid;
}

export type ImportJobFileFormat = typeof CSV_EXTENSION | typeof EXCEL_EXTENSION;

export function getConfirmationEmails(confirmationEmails: string): ConfirmationEmail[] {
    return confirmationEmails ? JSON.parse(confirmationEmails) : [];
}

export function getDoneTs(job: Job): number {
    return Math.max(job.tsDoneFailed, job.tsDoneSuccess);
}

export function isDone(job: Job): boolean {
    return getDoneTs(job) > job.tsScheduled
}

export function isSuccess(job: Job): boolean {
    return isDone(job) && job.tsDoneSuccess > job.tsDoneFailed;
}

export function isFailed(job: Job): boolean {
    return job.tsDoneFailed > job.tsDoneSuccess && job.tsDoneFailed > job.tsScheduled;
}

export function isAccepted(job: Job): boolean {
    return job.tsAccepted > job.tsRejected && job.tsAccepted >= job.tsScheduled;
}

export function isHighPriority(job: Job): boolean {
    return job.priority > PRIO_NORMAL;
}

export function isLowPriority(job: Job): boolean {
    return job.priority < PRIO_NORMAL;
}

export function hasAnyPriority(job: Job): boolean {
    return isHighPriority(job) || isLowPriority(job);
}

export function isCheckedIn(job: Job): boolean {
    return job.tsCheckIn > 0;
}

export function isCheckedOut(job: Job): boolean {
    return job.tsCheckOut > 0;
}

export function isAssigned(job: Job): boolean {
    return job.receiverUid !== '';
}

export function getTitle(job: Job, max = 100): string {
    const title = job?.destinationName || job.destinationText || "-";
    return title.length > max ? truncateText(title, max) : title;
}

export function getSignatureUrl(job: Job, companyUid?: string): string {
    if (job.signaturePdf) {
        return job.signaturePdf;
    } else if (job.signatureSvg && companyUid) {
        return `${HTTPS_DOMAIN}/api/image/job/signature/${companyUid}/${job.id}`;
    } else {
        return "";
    }
}

export function hasSignature(job: Job, companyUid?: string): boolean {
    return job.signaturePdf || (job.signatureSvg && companyUid) ? true : false;
}

export function getManualChecks(job: Job): ManualCheck[] {
    if (job.manualChecks) {
        const json = JSON.parse(job.manualChecks);
        return json.checks as ManualCheck[];
    } else {
        return [] as ManualCheck[];
    }
}

export function getInputFormConfig(job: Job): FormContent | undefined {
    if (job?.inputFormConfig) {
        return JSON.parse(job.inputFormConfig) as FormContent;
    }
    if (job?.formConfig) {
        return JSON.parse(job.formConfig) as FormContent;
    }
    return undefined;
}

export function getMostRecentTimestamp(job: Job): number {
    return Math.max(job.tsSaved, job.tsCreated, job.tsAssigned, job.tsAccepted, job.tsScheduled, job.tsCheckIn, job.tsDoneFailed, job.tsDoneSuccess);
}

export function isRecentlyUpdated(job: Job) {
    return Date.now() - getMostRecentTimestamp(job) < HOUR;
}

export function getLabels(job?: Job) {
    let labels: Label[];
    try {
        labels = JSON.parse(job?.labels || "[]") as Label[];
    } catch (error) {
        console.error("Failed to parse labels:", error);
        labels = [];
    }
    return labels;
}

export function isPickup(job: Job) {
    return job.type === JobTypes.PICKUP;
}

export function isDropoff(job: Job) {
    return job.type == JobTypes.DROPOFF;
}

export function isPickupOrDropoff(job: Job) {
    return job.type === JobTypes.PICKUP || job.type == JobTypes.DROPOFF;
}

export function getCustomField(job: Job, key: string) {
    return job.customFields.find((customField) => customField.key === key);
}
