import { Descriptions, Statistic } from "antd";
import { isIdling, isOnTheWay, Member } from "../../../types/member";
import { COLOR_IDLING, COLOR_MOVING } from "../../../constants/colors";
import { membersDangerousState } from "../../../states/membersState";
import { useTheme } from "../../../hooks/useTheme";
import { useStrings } from "../../../hooks/useStrings";
import { getRecoil } from "../../../providers/RecoilAccessProvider";
import { useEffectOnce, useInterval } from "usehooks-ts";
import { useState } from "react";
import { calcSumOfTotals, calcTotalsAndAverages, MemberStatDay } from "../../../types/stats";
import { useUnit } from "../../../hooks/useUnit";
import { useTime } from "../../../hooks/useTime";
import { useRecoilValue } from "recoil";
import { selectedMultiMemberState } from "../../../states/appState";

export function MemberStats({ stats }: { stats: MemberStatDay[], fetching: boolean, width: number, height: number }) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const unit = useUnit();
    const time = useTime();
    const [allMembers, setAllMembers] = useState<Member[]>([]);
    const selectedMembers = useRecoilValue(selectedMultiMemberState);

    useInterval(() => setAllMembers(getRecoil(membersDangerousState)), 20_000);
    useEffectOnce(() => setAllMembers(getRecoil(membersDangerousState)));

    const totalDist = calcSumOfTotals(stats, 'totalDist');
    const totalTravelTime = calcSumOfTotals(stats, 'totalTravelTime');
    const totalTracks = calcSumOfTotals(stats, 'totalTracks');
    const totalPauseTime = calcSumOfTotals(stats, 'totalPauseTime');
    const [, avgPauseTime] = calcTotalsAndAverages(stats, 'totalPauseTime', 'avgPauseTime');
    const avgPauseTimeAvg = avgPauseTime && avgPauseTime.length > 0
        ? avgPauseTime.reduce((sum, current) => sum + current) / avgPauseTime.length : 0;

    const members = selectedMembers.length > 0 ? selectedMembers : allMembers;

    return (
        <Descriptions bordered column={1} size="small">
            <Descriptions.Item label={strings.Stats.TotalMembers}>
                <Statistic value={members.length}
                           valueStyle={{ color: theme.colors.contentPrimary }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.General.OnTheWay}>
                <Statistic value={members.filter(m => isOnTheWay(m)).length}
                           valueStyle={{ color: COLOR_MOVING }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.General.Stationary}>
                <Statistic value={members.filter(m => isIdling(m)).length}
                           valueStyle={{ color: COLOR_IDLING }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.Stats.TotalDistanceTravelled}>
                <Statistic value={unit.distanceToString(totalDist)}
                           valueStyle={{ color: theme.colors.contentTertiary }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.Stats.TravelTime}>
                <Statistic value={time.formatDuration(totalTravelTime)}
                           valueStyle={{ color: theme.colors.contentTertiary }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.Stats.TotalNumberOfTracks}>
                <Statistic value={totalTracks}
                           valueStyle={{ color: theme.colors.contentTertiary }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.Stats.AvgPause}>
                <Statistic value={time.formatDuration(avgPauseTimeAvg)}
                           valueStyle={{ color: theme.colors.contentTertiary }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.Stats.TotalPause}>
                <Statistic value={time.formatDuration(totalPauseTime)}
                           valueStyle={{ color: theme.colors.contentTertiary }}
                />
            </Descriptions.Item>
        </Descriptions>
    );
}