import { useRecoilValue, useSetRecoilState } from 'recoil';
import { mediumState, smallState, visibleCardsState } from './../../../states/skeletonState';
import { INITIAL_MEDIUM_STATE, INITIAL_SMALL_STATE } from "../../../constants/cards";
import { MediumCard, SmallCard } from "../skeletonTypes";


export function useCards() {
    const { smallIsVisible, mediumIsVisible } = useRecoilValue(visibleCardsState);
    const setSmall = useSetRecoilState(smallState);
    const setMedium = useSetRecoilState(mediumState);

    const resetCards = () => {
        setSmall(smallIsVisible ? INITIAL_SMALL_STATE : {} as SmallCard)
        setMedium(mediumIsVisible ? INITIAL_MEDIUM_STATE : {} as MediumCard)
    }

    return {
        resetCards,
    };
}