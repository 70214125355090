import React from "react";
import { Check } from "../../types/checks";
import { HFlex } from "../containers/HFlex";
import { TimeBadge } from "../badges/TimeBadge";
import { MemberBadge } from "../badges/MemberBadge";
import { PlaceBadge } from "../badges/PlaceBadge";
import { StyledRow } from "baseui/table";
import { LabelXSmall } from "baseui/typography";
import { useStrings } from "../../hooks/useStrings";

type Props = {
    data: Check[];
    index: number;
    style: React.CSSProperties;
};

export function CheckRow({ data, index, style }: Props) {
    const check = data[index];
    const { strings, format } = useStrings();

    if (!check) {
        return null;
    }

    return (
        <StyledRow role="row" style={{ ...style }}>
            <HFlex spacing alignCenter style={{ height: "48px" }}>
                <TimeBadge startMillis={check.timestamp} withDate style={{ width: "110px" }}/>
                <MemberBadge uid={check.who}/>
                <LabelXSmall style={{ width: "100px", textAlign: "center" }}>
                    {format(check.isCheckIn ? strings.Alerts.CheckInAtX : strings.Alerts.CheckOutAtX, "")}
                </LabelXSmall>
                <PlaceBadge uid={check.where}/>
                {check.isManual && <LabelXSmall>({strings.General.ManualCheck})</LabelXSmall>}
            </HFlex>
        </StyledRow>
    )
}