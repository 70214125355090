import { FormControl } from 'baseui/form-control';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { editingJobIdState } from '../../../states/appState';
import { editJobState } from '../../../states/editJobState';
import { stringsState } from '../../../states/localeState';
import { Section } from '../../ui/Section';
import { Job } from "../../../types/job";
import { jobState } from "../../../states/jobsState";
import { LinkedZonesSelect } from '../../pickers/LinkedZonesSelect';
import { LinkedFormsSelect } from "../../pickers/LinkedFormsSelect";
import { getLinkedForms, getLinkedZones, getLinkedZonesString, LinkedForm, LinkedZone } from "../../../types/linked";
import { NotificationCircle } from "baseui/badge";
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';

export function JobLinkedSection() {
    const strings = useRecoilValue(stringsState);
    const jobId = useRecoilValue(editingJobIdState);
    const job = useRecoilValue(jobState(jobId));
    const [fields, setFields] = useRecoilState(editJobState(jobId || ''));
    const [linkedZones, setLinkedZones] = useState<LinkedZone[]>(getLinkedZones(fields?.linkedZones || job?.linkedZones || ""));
    const [linkedForms, setLinkedForms] = useState<LinkedForm[]>(getLinkedForms(fields?.linkedForms || job?.linkedForms || ""));

    useEffectOnce(() => {
        const emptyValue = job?.linkedZones ?? "";
        setFields(prev => ({ ...(prev || {} as Job), linkedZones: getLinkedZonesString(linkedZones, emptyValue) }));
    })

    useUpdateEffect(() => {
        setFields(prev => ({ ...(prev || {} as Job), linkedZones: getLinkedZonesString(linkedZones) }));
    }, [linkedZones]);

    useEffect(() => {
        let linkedFormsJsonString = '';
        if (!!linkedForms.length) {
            linkedFormsJsonString = JSON.stringify(linkedForms);
        }
        setFields(prev => ({ ...(prev || {} as Job), linkedForms: linkedFormsJsonString }));
    }, [linkedForms]);


    return (
        <>
            <Section title={strings.Job.LinkedZones}
                     expandable
                     collapsedNode={linkedZones?.length && <NotificationCircle content={linkedZones.length}/>}
            >
                <FormControl label={strings.Job.LinkedZonesDesc}>
                    <LinkedZonesSelect
                        linkedZones={linkedZones}
                        onChangeLinkedZones={(linkedZones) => setLinkedZones(linkedZones)}
                    />
                </FormControl>
            </Section>
            <Section title={strings.Job.FormsLinkedForms}
                     expandable
                     collapsedNode={linkedForms?.length && <NotificationCircle content={linkedForms.length}/>}
            >
                <FormControl label={strings.Job.LinkedFormsDesc}>
                    <LinkedFormsSelect
                        linkedForms={linkedForms}
                        onChangeLinkedForms={(linkedForm) => setLinkedForms(linkedForm as LinkedForm[])}
                    />
                </FormControl>
            </Section>
        </>
    );
}
