import { SpacerExpand } from "../containers/Spacer";
import { ProgressSteps, Step } from "baseui/progress-steps";
import { Radio, RadioGroup } from "baseui/radio";
import { VFlex } from "../containers/VFlex";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { chatAiGoalState, chatAiIndustryState, chatAiLoadingState } from "../../states/chatai";
import { HFlex } from "../containers/HFlex";
import { Button } from "baseui/button";
import { useStrings } from "../../hooks/useStrings";
import { useChatAi } from "../../hooks/useChatAi";
import { useSignupOptionalSteps } from "../../hooks/useSignupOptionalSteps";
import { Endpoint } from "../../services/api";

export function ChatAiForm() {
    const { strings, format } = useStrings();
    const loading = useRecoilValue(chatAiLoadingState);
    const [industryValue, setIndustryValue] = useRecoilState(chatAiIndustryState);
    const [goalValue, setGoalValue] = useRecoilState(chatAiGoalState);
    const [current, setCurrent] = useState(0);
    const { sendQuery } = useChatAi(Endpoint.salesaiquery);
    const steps = useSignupOptionalSteps();

    useEffect(() => {
        if (industryValue) {
            sendQuery(format(strings.ChatAi.MyIndustryIs, industryValue))
        }
        if (industryValue) {
            setCurrent(current + 1);
        }
    }, [industryValue]);

    useEffect(() => {
        if (goalValue) {
            sendQuery(format(strings.ChatAi.MyGoalWithHellotracksIs, goalValue))
        }
        if (goalValue) {
            setCurrent(current + 1);
        }
    }, [goalValue]);

    const continueStep = (next: number) => <HFlex spacing>
        <Button
            size="compact"
            onClick={() => setCurrent(next)}
            kind={"secondary"}
        >
            {strings.ChatAi.Skip}
        </Button>
    </HFlex>;

    return (
        <VFlex style={{ width: "300px" }}>
            <SpacerExpand/>
            <ProgressSteps current={current}>
                <Step title={industryValue || steps.industryStep.title}>
                    <RadioGroup
                        value={industryValue}
                        onChange={e => setIndustryValue(e.currentTarget.value)}
                        name="number"
                        align={"vertical"}
                        disabled={loading}
                    >
                        {steps.industryStep.options.map((option, idx) =>
                            <Radio key={idx} value={option.description}>{option.description}</Radio>
                        )}
                    </RadioGroup>
                    {continueStep(1)}
                </Step>
                <Step title={goalValue || steps.goalStep.title}>
                    <RadioGroup
                        value={goalValue}
                        onChange={e => setGoalValue(e.currentTarget.value)}
                        name="number"
                        align={"vertical"}
                        disabled={loading}
                    >
                        {steps.goalStep.options.map((option, idx) =>
                            <Radio key={idx} value={option.description}>{option.description}</Radio>)}
                    </RadioGroup>
                    {continueStep(2)}
                </Step>
            </ProgressSteps>
            <SpacerExpand/>
        </VFlex>
    );
}