import { memo, useCallback, useEffect, useState } from 'react';
import {
    Map as MapboxMap,
    MapboxGeoJSONFeature,
    MapLayerMouseEvent,
    useMap,
    ViewStateChangeEvent,
} from 'react-map-gl';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { MAPBOX_ACCESS_TOKEN } from '../../constants/mapConstants';
import {
    is3DEnabledState,
    mapBearingState,
    mapInteractiveLayersState,
    mapToolState,
    mapTypeObjectState,
    mapViewState,
} from '../../states/mapState';
import { mapPaddingState } from '../../states/skeletonState';
import MemberMarker from './markers/MemberMarker';
import { memberUidsState } from "../../states/membersState";
import { dayState, editingPlaceIdState, pageState } from "../../states/appState";
import DrawControl from './DrawControl';
import { EditPlaceCircle } from "./markers/EditPlaceCircle";
import { usePlaceSelection } from "../../hooks/places/usePlaceSelection";
import { BoxSelection } from "./BoxSelection";
import LocationMarker from './markers/LocationMarker';
import { NewPlaceCircle } from './markers/NewPlaceCircle';
import { useGeocode } from '../../hooks/useGeocode';
import { PAGE } from "../../constants/pages";
import { useBoard } from "../../hooks/useBoard";
import TempMarker from './markers/TempMarker';
import { tempLocationState } from '../../states/tempLocationState';
import { dayOfToday } from "../../utils/time";
import { useProSidebar } from "react-pro-sidebar";
import useAccount from "../../hooks/useAccount";
import { useCamera } from "../../hooks/useCamera";
import { HoverFeatureTooltip } from "./HoverFeatureTooltip";
import { TrafficLayer } from "./layers/TrafficLayer";
import { ZonesLayer } from "./layers/ZonesLayer";
import { PlacesLayer } from "./layers/PlacesLayer";
import DayRouteLayers from "./layers/DayRouteLayers";
import TrackLayers from "./layers/TrackLayers";
import LiveWaypointsLayers from "./layers/LiveWaypointsLayers";
import HeatmapLayers from "./layers/HeatmapLayers";
import { CompanyMarker } from "./markers/CompanyMarker";
import { useDrawMode } from "../../hooks/useDrawMode";
import { isLocationDrawMode } from "../../states/drawModeState";
import JobsLayers from "./layers/JobsLayers";
import { PulseMarker } from "./markers/PulseMarker";
import { hoveredTrackState } from "../../states/tracksState";
import { TrackEntry } from "../../types/track";
import { useTracksSelection } from "../../hooks/tracks/useTracksSelection";
import { H3WaypointsLayer } from "./layers/H3WaypointsLayer";
import { ErrorCatcher } from "../../providers/ErrorCatcher";
import { Uid } from "../../types/core";

export type HoverInfo = {
    feature: MapboxGeoJSONFeature;
    x: number;
    y: number;
}

function Map() {
    const { map } = useMap();
    const page = useRecoilValue(pageState);
    const enable3D = useRecoilValue(is3DEnabledState);
    const memberUids = useRecoilValue(memberUidsState);
    const mapPadding = useRecoilValue(mapPaddingState);
    const mapTypeObject = useRecoilValue(mapTypeObjectState);
    const [viewState, setViewState] = useRecoilState(mapViewState);
    const setMapBearing = useSetRecoilState(mapBearingState);
    const placeSelection = usePlaceSelection();

    const { geolocate } = useGeocode();
    const selectedDay = useRecoilValue(dayState);
    const board = useBoard();
    const tempLocation = useRecoilValue(tempLocationState);
    const today = dayOfToday();
    const { broken } = useProSidebar();
    const { company, hardLoginTs } = useAccount();
    const camera = useCamera();
    const [hoverInfo, setHoverInfo] = useState<HoverInfo | undefined>(undefined);
    const setEditingPlace = useSetRecoilState(editingPlaceIdState);
    const interactiveLayers = useRecoilValue(mapInteractiveLayersState);
    const { drawMode, updateLocation } = useDrawMode();
    const [mouseDownTs, setMouseDownTs] = useState(0);
    const tool = useRecoilValue(mapToolState);
    const resetTool = useResetRecoilState(mapToolState);
    const setTempLocation = useSetRecoilState(tempLocationState);
    const setHoveredTrack = useSetRecoilState(hoveredTrackState);
    const tracksSelection = useTracksSelection();

    useEffect(() => {
        if (company && Date.now() - hardLoginTs < 30_000) {
            camera.zoomTo({ lat: company?.location.lat, lng: company?.location.lng });
        }
    }, [company]);

    useEffect(() => {
        camera.resetPitch(); // TODO this is cancelling zoom animation, that's why we currently force 2D in places
    }, [enable3D, map]);

    useEffect(() => {
        if (!map) {
            return;
        }
        if (tool) {
            map.getCanvas().style.cursor = "crosshair";
        } else {
            map.getCanvas().style.cursor = "grab";
        }
    }, [map, tool]);

    useEffect(() => {
        if (map) {
            setTimeout(() => map.resize(), drawMode ? 0 : 10);
        }
    }, [broken, map, drawMode]);

    const onMove = (ev: ViewStateChangeEvent) => setMapBearing(ev.viewState.bearing);
    const onMoveEnd = (ev: ViewStateChangeEvent) => setViewState(ev.viewState);
    const onClick = async (ev: MapLayerMouseEvent) => {
        if (Date.now() - mouseDownTs > 300) {
            return;
        }
        if (isLocationDrawMode(drawMode)) {
            updateLocation(await geolocate(ev.lngLat.lat, ev.lngLat.lng));
        } else {
            if (ev.features
                && ev.features.length > 0
                && ev.features[0].layer.id === "places"
                && ev.features[0].properties) {
                setEditingPlace(ev.features[0].properties.uid as Uid);
            }
            if (ev.features
                && ev.features.length > 0
                && ev.features[0].properties?.kind === "track") {
                const trackUid = (ev.features[0].properties as TrackEntry).uid;
                if (page === PAGE.LOCATION || tracksSelection.isSelected(trackUid)) {
                    tracksSelection.toggleSelected(trackUid);
                } else {
                    tracksSelection.setSelectedUids([trackUid]);
                }
            }
        }
        setTempLocation(undefined);
    }
    const [longClickTimerId, setLongClickTimerId] = useState<NodeJS.Timeout | undefined>(undefined);
    const [hoverTimerId, setHoverTimerId] = useState<NodeJS.Timeout | undefined>(undefined);
    const onMouseMove = useCallback((event: MapLayerMouseEvent) => {
        clearLongClick();
        const { features, point: { x, y } } = event;
        setHoverInfo(features && features[0] && { feature: features[0], x, y } as HoverInfo);

        const hoveredTrackFeat = features && features[0] && features[0].properties?.kind === "track"
            ? features[0].properties as TrackEntry : undefined;
        if (hoveredTrackFeat && !hoverTimerId) {
            const id = setTimeout(() => setHoveredTrack(hoveredTrackFeat), 400);
            setHoverTimerId(id);
        } else if (!hoveredTrackFeat) {
            hoverTimerId && clearTimeout(hoverTimerId);
            setHoverTimerId(undefined)
            setHoveredTrack(undefined)
        }

    }, [longClickTimerId, hoverTimerId]);
    const onMouseDown = (event: MapLayerMouseEvent) => {
        setMouseDownTs(Date.now);
        if (drawMode) {
            return;
        }
        const location = { lat: event.lngLat.lat, lng: event.lngLat.lng };
        if (tool === "point") {
            camera.zoomWithTempMarker(location);
            resetTool();
        }  else {
            const id = setTimeout(() => {
                camera.setTempMarker(location);
            }, 1000);
            setLongClickTimerId(id);
        }
    };
    const clearLongClick = () => {
        if (longClickTimerId) {
            clearTimeout(longClickTimerId);
            setLongClickTimerId(undefined);
        }
    };
    const showMembers = page === PAGE.LIVE || page === PAGE.TIMELINE || page === PAGE.DISPATCH || page === PAGE.TEAM;

    let membersOnMap: Uid[];
    if (page === PAGE.HEATMAP || drawMode) {
        membersOnMap = [];
    } else if (page === PAGE.TIMELINE) {
        membersOnMap = selectedDay === today ? board.memberUids : [];
    } else if (page === PAGE.LIVE || page === PAGE.DISPATCH) {
        membersOnMap = board.memberUids.length > 0 ? board.memberUids : memberUids;
    } else {
        membersOnMap = memberUids;
    }

    return (
        <MapboxMap
            id="map"
            reuseMaps
            mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
            initialViewState={{ ...viewState }}
            mapStyle={mapTypeObject.url}
            onMove={onMove}
            onMouseMove={onMouseMove}
            onMouseDown={onMouseDown}
            onMouseUp={clearLongClick}
            onMoveEnd={onMoveEnd}
            onDragStart={clearLongClick}
            onPitchStart={clearLongClick}
            projection={"mercator"}
            interactiveLayerIds={interactiveLayers}
            padding={{
                left: mapPadding,
                bottom: 0,
                right: 0,
                top: 0,
            }}
            scrollZoom={true}
            boxZoom={true}
            dragRotate={true}
            dragPan={true}
            keyboard={true}
            doubleClickZoom={true}
            touchZoomRotate={true}
            touchPitch={true}
            minPitch={0}
            maxPitch={85}
            minZoom={1}
            onClick={onClick}
        >
            {hoverInfo && <ErrorCatcher><HoverFeatureTooltip hoverInfo={hoverInfo}/></ErrorCatcher>}
            <TrafficLayer/>
            <ZonesLayer/>
            {false && <H3WaypointsLayer/>}
            <DayRouteLayers/>
            <TrackLayers/>
            <HeatmapLayers/>
            <LiveWaypointsLayers/>
            <PulseMarker/>
            <PlacesLayer/>
            {showMembers && membersOnMap.map((uid) => (
                <MemberMarker key={uid} uid={uid}/>
            ))}
            <JobsLayers/>
            {page !== PAGE.HEATMAP && <CompanyMarker/>}
            {(drawMode?.type === "zone" || drawMode?.type === "route") && <DrawControl drawMode={drawMode}/>}
            <BoxSelection/>
            {placeSelection.selectionCount <= 10 && placeSelection.selectedUids.map(uid =>
                <EditPlaceCircle key={uid} placeUid={uid}/>
            )}
            <LocationMarker/>
            {drawMode?.type === "place" && drawMode?.action === "new" && <NewPlaceCircle/>}
            {tempLocation && <TempMarker/>}
        </MapboxMap>
    );
}

export default memo(Map);