import {
    CSV_EXTENSION,
    EXCEL_EXTENSION,
    JSON_EXTENSION,
    KML_EXTENSION,
    PDF_EXTENSION,
    VIDEO_FORMATS
} from './../constants/app';
import { IMAGE_FORMATS, IMPORT_FILE_FORMATS } from "../constants/app";
import { PageType } from '../types/core';
import { PAGE } from '../constants/pages';

export function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
}

export function getFileExtension(filename: string) {
    return filename.substring(filename.lastIndexOf('.'), filename.length) || filename;
}

export function isValidPDF(filename: string) {
    const fileExtension = getFileExtension(filename);
    return PDF_EXTENSION === fileExtension;
}

export function isValidImage(filename: string) {
    const fileExtension = getFileExtension(filename);
    return IMAGE_FORMATS.includes(fileExtension);
}

export function isValidVideo(filename: string) {
    const fileExtension = getFileExtension(filename);
    return VIDEO_FORMATS.includes(fileExtension);
}

export function isValidYouTube(url: string) {
    url = url.trim().toLowerCase();
    const pattern = /^(https?:\/\/)?(www\.)?(youtu\.be\/|youtube\.com\/(watch\?v=|shorts\/)).*$/;
    return pattern.test(url);
}

export function extractYouTubeID(url: string): string | null {
    if (!isValidYouTube(url)) {
        return null;
    }

    const shortUrlPattern = /youtu\.be\/([a-zA-Z0-9_-]+)/;
    const regularUrlPattern = /youtube\.com\/.*[?&]v=([a-zA-Z0-9_-]+)/;
    const embedUrlPattern = /youtube\.com\/embed\/([a-zA-Z0-9_-]+)/;
    const shortsUrlPattern = /youtube\.com\/shorts\/([a-zA-Z0-9_-]+)/;

    const shortUrlMatch = url.match(shortUrlPattern);
    const regularUrlMatch = url.match(regularUrlPattern);
    const embedUrlMatch = url.match(embedUrlPattern);
    const shortsUrlMatch = url.match(shortsUrlPattern);

    if (shortUrlMatch) return shortUrlMatch[1];
    if (regularUrlMatch) return regularUrlMatch[1];
    if (embedUrlMatch) return embedUrlMatch[1];
    if (shortsUrlMatch) return shortsUrlMatch[1];

    return null;
}

export function isValidImportFile(filename: string, validFormats = IMPORT_FILE_FORMATS) {
    const fileExtension = getFileExtension(filename);
    return validFormats.includes(fileExtension);
}

export function isCsv(filename: string) {
    const fileExtension = getFileExtension(filename);
    return fileExtension === CSV_EXTENSION;
}

export function isExcel(filename: string) {
    const fileExtension = getFileExtension(filename);
    return fileExtension === EXCEL_EXTENSION;
}

export function isJson(filename: string) {
    const fileExtension = getFileExtension(filename);
    return fileExtension === JSON_EXTENSION;
}

export function isKml(filename: string) {
    const fileExtension = getFileExtension(filename);
    return fileExtension === KML_EXTENSION;
}

export function randomString() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

export function randomNumber(excludedNumbers: number[] = []): number {
    const number = +Math.random().toString().substring(2).slice(0, 5);
    if (excludedNumbers.includes(number)) {
        return randomNumber(excludedNumbers);
    }
    return number;
}

export function isPageType(page: string): page is PageType {
    return Object.values(PAGE).includes(page as PageType);
}

export function isMac() {
    return navigator.userAgent.indexOf('Mac') !== -1;
}

export function isTouchDevice() {
    return ('ontouchstart' in window || navigator.maxTouchPoints > 0 || window.matchMedia("(pointer: coarse)").matches);
}

export function isTestEnvironment() {
    return window.location.hostname !== "live.hellotracks.com";
}