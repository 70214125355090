import { LabelMedium } from "baseui/typography";
import { useRecoilState } from "recoil";
import { selectedFormSubmitState } from "../../states/formsState";
import { FormTemplate } from "../../types/form";
import { ClickableListItem } from "../ui/ClickableListItem";
import { TimeBadge } from "../badges/TimeBadge";

export function NewFormSubmissionItem({ formTemplate, withTime = false }: { formTemplate: FormTemplate, withTime?: boolean }) {
    const [selectedForm, setSelectedForm] = useRecoilState(selectedFormSubmitState);

    const isSelected = selectedForm?.id === formTemplate.id;

    const onEditClick = () => setSelectedForm(isSelected ? undefined : formTemplate);

    return (
        <ClickableListItem onClick={onEditClick}>
            <LabelMedium>{formTemplate.content.title}</LabelMedium>
            {withTime && (
                <TimeBadge startMillis={formTemplate.content.timestamp} withDate/>
            )}
        </ClickableListItem>
    );
}
