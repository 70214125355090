import { PAGE } from "../../../constants/pages";
import { Marker } from "react-map-gl";
import { useRecoilValue } from "recoil";
import { pageState } from "../../../states/appState";
import { selectedDayRoutesState } from "../../../states/geojsonState";
import { pointsAlongLineStrings } from "../../../utils/geojsonUtils";
import { calculateFloatingRouteMarkerOffsets, FloatingRouteMarker } from "baseui/map-marker";
import { useStrings } from "../../../hooks/useStrings";
import { showRoutesLayerState } from "../../../states/mapState";
import { memo } from "react";
import { DayRouteLayer } from "./DayRouteLayer";

function DayRouteLayers() {
    const dayRouteLayers = useRecoilValue(selectedDayRoutesState);
    const routeStartpoints = pointsAlongLineStrings(dayRouteLayers, 0);
    const routeEndpoints = pointsAlongLineStrings(dayRouteLayers, 1);
    const { strings } = useStrings();
    const page = useRecoilValue(pageState);
    const showRoutesLayer = useRecoilValue(showRoutesLayerState);
    const actuallyShowingDayRoutes = !(!showRoutesLayer || page !== PAGE.TIMELINE && page !== PAGE.DISPATCH);

    if (!actuallyShowingDayRoutes) {
        return null;
    }

    return (
        <>
            {dayRouteLayers.map((dayRouteLayer, index) =>
                <DayRouteLayer key={index}
                               index={index}
                               uid={dayRouteLayer.uid}
                               layer={dayRouteLayer}
                />
            )}
            {routeEndpoints.map(({ lat, lng, properties }, index) =>
                <Marker key={index} longitude={lng} latitude={lat} anchor={"bottom"}>
                    <FloatingRouteMarker label={strings.Routing.End}
                                         key={index}
                                         anchorPosition={"bottom-left"}
                                         overrides={{
                                             Root: {
                                                 style: () => ({
                                                     transform: calculateFloatingRouteMarkerOffsets(
                                                         "bottom-left",
                                                         0,
                                                     ),
                                                 }),
                                             },
                                         }}
                    />
                </Marker>
            )}
            {routeStartpoints.map(({ lat, lng, properties }, index) =>
                <Marker key={index} longitude={lng} latitude={lat} anchor={"center"}>
                    <FloatingRouteMarker label={strings.Routing.Start}
                                         key={index}
                                         anchorPosition={"bottom-right"}
                                         overrides={{
                                             Root: {
                                                 style: () => ({
                                                     transform: calculateFloatingRouteMarkerOffsets(
                                                         "bottom-right",
                                                         0,
                                                     ),
                                                 }),
                                             },
                                         }}
                    />
                </Marker>
            )}
        </>
    );
}

export default memo(DayRouteLayers);