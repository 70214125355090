import { SIZE } from "baseui/button-group";
import { Filter } from "baseui/icon";
import { Input } from "baseui/input";
import { useRecoilState, useRecoilValue } from "recoil";
import { memberSearchState } from "../../states/membersState";
import { stringsState } from "../../states/localeState";
import { theme } from "antd";
import { useTheme } from "../../hooks/useTheme";

export function MemberSearch({ autoShrink = false, style }: { autoShrink?: boolean, style?: any }) {
    const strings = useRecoilValue(stringsState);
    const [memberSearch, setMemberSearch] = useRecoilState(memberSearchState);
    const { theme } = useTheme();

    return (
        <Input
            value={memberSearch}
            onChange={(e) => setMemberSearch(e.target.value)}
            type="text"
            placeholder={strings.General.SearchMember}
            clearable
            size={SIZE.mini}
            startEnhancer={<Filter color={theme.colors.contentTertiary}/>}
            overrides={{
                Root: {
                    style: ({ $isFocused }) => ({
                        maxWidth: !autoShrink || $isFocused ? "180px" : "136px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        transitionProperty: "max-width",
                        transitionDuration: "300ms",
                        transitionTimingFunction: "ease",
                        backgroundColor: "transparent",
                        borderRadius: "50px",
                        borderWidth: "1px",
                        ...style,
                    })
                },
                Input: {
                    style: {
                        paddingLeft: "0px",
                        paddingRight: "0px",
                    }
                },
                InputContainer: {
                    style: {
                        backgroundColor: "transparent",
                    }
                },
                StartEnhancer: {
                    style: {
                        backgroundColor: "transparent",
                    }
                }
            }}
        />
    );
}