import { useRouteInfo } from "../../hooks/useRouteInfo";
import { useRecoilValue } from "recoil";
import { useTime } from "../../hooks/useTime";
import { useStrings } from "../../hooks/useStrings";
import { VFlex } from "../containers/VFlex";
import { LabelXSmall } from "baseui/typography";
import { SpacerExpand } from "../containers/Spacer";
import { HFlex } from "../containers/HFlex";
import { RouteInfo } from "../../types/job";
import { dayState } from "../../states/appState";
import { COLOR_DAYROUTE } from "../../constants/colors";
import { MemberBadge } from "../badges/MemberBadge";
import { memo } from "react";
import { memberState } from "../../states/membersState";


export const RouteInfoBubble = memo(function ({ route }: { route: RouteInfo }) {
    const { strings } = useStrings();
    const routeInfo = useRouteInfo();
    const summary = route && routeInfo.summary(route);
    const time = useTime();
    const day = useRecoilValue(dayState);
    const member = useRecoilValue(memberState(route.memberUid));

    if (!route) {
        return null;
    }

    return (
        <VFlex padding style={{ width: "200px", border: "dashed " + COLOR_DAYROUTE, }}>
            <HFlex>
                <LabelXSmall>{strings.Routing.Route}</LabelXSmall>
                <SpacerExpand/>
                <MemberBadge uid={route.memberUid}/>
            </HFlex>
            <HFlex>
                <LabelXSmall>{time.formatDay(day)}</LabelXSmall>
                <SpacerExpand/>
                <LabelXSmall>{summary?.travelDuration + " | " + summary?.totalDistance}</LabelXSmall>
            </HFlex>
        </VFlex>
    );
});