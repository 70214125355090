import { Filter } from "baseui/icon";
import { Input } from "baseui/input";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { useStrings } from "../../hooks/useStrings";
import { useTime } from "../../hooks/useTime";
import { alertInstancesState } from "../../states/alertsState";
import { Timeline, TimelineItemProps } from "antd";
import { HeaderContentFooterLayout } from "../containers/HeaderContentFooterLayout";
import { VFlex } from "../containers/VFlex";
import { Spacer } from "../containers/Spacer";

export function RecentAlerts() {
    const { strings } = useStrings();
    const { formatDateTime } = useTime();
    const [alertSearch, setAlertSearch] = useState("");
    const alertInstances = useRecoilValue(alertInstancesState);

    const raw = alertInstances.map((alertInstance) => [formatDateTime(alertInstance.tsCreated), alertInstance.message]);

    const data = raw.filter(([date, message]) => {
        return (
            date.toLowerCase().includes(alertSearch.toLowerCase()) ||
            message.toLowerCase().includes(alertSearch.toLowerCase())
        );
    });

    const timelineItems: TimelineItemProps[] = data.map((entry, index) => ({
        key: index,
        color: "red",
        label: entry[0],
        children: entry[1],
    }));

    return (
        <HeaderContentFooterLayout header={
            <VFlex>
                <Input
                    value={alertSearch}
                    onChange={(e) => setAlertSearch(e.target.value)}
                    type="text"
                    placeholder={strings.General.Filter}
                    clearable
                    size="mini"
                    startEnhancer={<Filter/>}
                />
                <Spacer height={"8px"}/>
            </VFlex>
        }>
            <VFlex>
                <Spacer height={"8px"}/>
                <Timeline mode={"left"} style={{ width: "100%" }} items={timelineItems}/>
            </VFlex>
        </HeaderContentFooterLayout>
    );
}