import {
    ConditionalFormItem,
    ConditionalFormItemTouched,
    FormContent,
    FormContentTouched,
    FormItem,
    FormItemType
} from "../types/form";
import { editAccount } from "../services/api";
import { Company } from "../types/company";
import useAccount from "./useAccount";
import { useStrings } from "./useStrings";
import { useResetRecoilState } from "recoil";
import { editStandardFormContentState } from "../states/formsState";

export type FormItemOption = {
    id: FormItemType;
    label: string;
}

export function useForms() {
    const { strings, format } = useStrings();
    const { company, setCompany } = useAccount();
    const resetStandardFormContent = useResetRecoilState(editStandardFormContentState);

    const section = { id: "section", label: strings.Forms.FormsSection } as FormItemOption;
    const text = { id: "text", label: strings.Forms.FormsText } as FormItemOption;
    const paragraph = { id: "paragraph", label: strings.Forms.FormsParagraph } as FormItemOption;
    const number = { id: "number", label: strings.Forms.FormsNumber } as FormItemOption;
    const decimal = { id: "decimal", label: strings.Forms.FormsDecimal } as FormItemOption;
    const checkbox = { id: "checkbox", label: strings.Forms.FormsCheckbox } as FormItemOption;
    const radio = { id: "radio", label: strings.Forms.FormsRadio } as FormItemOption;
    const dropdown = { id: "dropdown", label: strings.Forms.FormsDropdown } as FormItemOption;
    const image = { id: "image", label: strings.Forms.FormsImage } as FormItemOption;
    const file = { id: "file", label: strings.Forms.FormsFile } as FormItemOption;
    const signature = { id: "signature", label: strings.Forms.FormsSignature } as FormItemOption;

    const allOptions = [section, text, paragraph, number, decimal, checkbox, radio, dropdown];
    const jobOptions = [text, number, decimal, checkbox];
    const equatableOptionIds = [number.id, decimal.id, checkbox.id, radio.id, dropdown.id];

    const newIndividualQuickForm = {
        title: "",
        id: "",
        description: "",
        timestamp: Date.now(),
        content: [],
    } as FormContent;

    const addItem = (index: number, formContent: FormContent, option: FormItemOption): FormContent => {
        const items = [...formContent.content];
        items.splice(index >= 0 ? index : items.length, 0, {
            type: option.id,
            name: format(strings.General.NewX, option.label),
            value: "",
            options: [],
            required: false,
        } as FormItem);
        return { ...formContent, content: items } as FormContent;
    };

    const removeItem = (index: number, formContent: FormContent) => {
        const pre = formContent.content.slice(0, index);
        const post = formContent.content.slice(index + 1);
        const items = [...pre, ...post];
        return { ...formContent, content: items } as FormContent;
    }

    const setName = (index: number, formContent: FormContent, name: string): FormContent => {
        const items = [...formContent.content];
        const item = items[index];
        items[index] = { ...item, name: name };
        return { ...formContent, content: items } as FormContent;
    };

    const setOptions = (index: number, formContent: FormContent, options: string[]): FormContent => {
        const items = [...formContent.content];
        const item = items[index];
        items[index] = { ...item, options: options };
        return { ...formContent, content: items } as FormContent;
    };

    const setType = (index: number, formContent: FormContent, type: FormItemType): FormContent => {
        const items = [...formContent.content];
        const item = items[index];
        items[index] = { ...item, type: type };
        return { ...formContent, content: items } as FormContent;
    };

    const saveStandardForm = async (content: FormContent) => {
        const { status, account: updatedCompany } = await editAccount({ uid: company?.uid, standard_form: content });
        if (status) {
            setCompany(updatedCompany as Company);
            resetStandardFormContent();
        }
    };

    const setRequired = (index: number, formContent: FormContent, required: boolean): FormContent => {
        const items = [...formContent.content];
        const item = items[index];
        items[index] = { ...item, required: required };
        return { ...formContent, content: items } as FormContent;
    };

    const addConditional = (itemIndex: number, formContent: FormContent, equalsTo: string, type: FormItemType): FormContent => {
        const label = allOptions.find(o => o.id === type)?.label ?? "";
        const items = [...formContent.content];
        const item = items[itemIndex];
        const conditional = [...item.conditionals ?? []];
        conditional.push({
            equalsTo,
            content: [{
                type,
                name: format(strings.General.NewX, label),
                value: "",
                options: [],
                required: false,
            }]
        })
        items[itemIndex] = {
            ...item,
            conditionals: conditional,
        };
        return { ...formContent, content: items } as FormContent;
    };

    const removeConditional = (itemIndex: number, conditionalIndex: number, formContent: FormContent) => {
        const items = [...formContent.content];
        const item = items[itemIndex];
        const pre = item.conditionals?.slice(0, conditionalIndex) ?? [];
        const post = item.conditionals?.slice(conditionalIndex + 1) ?? [];
        const newConditional = [...pre, ...post];
        items[itemIndex] = {
            ...item, 
            conditionals: newConditional,
        };
        return { ...formContent, content: items } as FormContent;
    }

    const addConditionalItem = (itemIndex: number, conditionalIndex: number, type: FormItemType, formContent: FormContent): FormContent => {
        const label = allOptions.find(o => o.id === type)?.label ?? "";
        const items = [...formContent.content];
        const item = items[itemIndex];
        if (item.conditionals) {
            const conditionals = [...item.conditionals];
            const conditional = conditionals[conditionalIndex];
            const conditionalItems = [...conditional.content]
            conditionalItems.push({
                type,
                name: format(strings.General.NewX, label),
                value: "",
                options: [],
                required: false,
            })
            conditionals[conditionalIndex] = {
                ...conditional,
                content: conditionalItems,
            }
            items[itemIndex] = {
                ...item,
                conditionals: conditionals,
            }
        }
        return { ...formContent, content: items } as FormContent;
    };

    const removeConditionalItem = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, formContent: FormContent) => {
        const items = [...formContent.content];
        const item = items[itemIndex]
        if (item.conditionals) {
            const conditionals = [...item.conditionals]
            const conditional = conditionals[conditionalIndex];
            const pre = conditional.content.slice(0, conditionalItemIndex);
            const post = conditional.content.slice(conditionalItemIndex + 1);
            const conditionalItems = [...pre, ...post]
            conditionals[conditionalIndex] = {
                ...conditional,
                content: conditionalItems,
            }
            items[itemIndex] = {
                ...item,
                conditionals: conditionals
            }
        }
        return { ...formContent, content: items } as FormContent;
    }

    const setConditionalItemName = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, formContent: FormContent, name: string): FormContent => {
        return getFormContentWithNewConditionalFormItem(itemIndex, conditionalIndex, conditionalItemIndex, formContent, "name", name);
    };

    const setConditionalItemOptions = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, formContent: FormContent, options: string[]): FormContent => {
        return getFormContentWithNewConditionalFormItem(itemIndex, conditionalIndex, conditionalItemIndex, formContent, "options", options);
    };

    const setConditionalItemType = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, formContent: FormContent, type: FormItemType): FormContent => {
        return getFormContentWithNewConditionalFormItem(itemIndex, conditionalIndex, conditionalItemIndex, formContent, "type", type);
    };

    const setConditionalItemRequired = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, formContent: FormContent, required: boolean): FormContent => {
        return getFormContentWithNewConditionalFormItem(itemIndex, conditionalIndex, conditionalItemIndex, formContent, "required", required);
    };

    const getFormContentWithNewConditionalFormItem = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, formContent: FormContent | FormContentTouched, field: keyof ConditionalFormItem | keyof ConditionalFormItemTouched, value: any): FormContent => {
        const items = [...formContent.content];
        const item = items[itemIndex]
        if (item.conditionals) {
            const newConditionals = [...item.conditionals];
            const conditional = newConditionals[conditionalIndex];
            const conditionalItems = [...conditional.content];
            const conditionalItem = conditionalItems[conditionalItemIndex];
            conditionalItems[conditionalItemIndex] = {
                ...conditionalItem,
                [field]: value,
            }
            newConditionals[conditionalIndex] = {
                ...conditional,
                content: conditionalItems,
            }
            items[itemIndex] = {
                ...item,
                conditionals: newConditionals,
            }
        }
        return { ...formContent, content: items } as FormContent;
    }

    const setItemValue = (itemIndex: number, value: string|number, formContent: FormContentTouched): FormContent => {
        const items = [...formContent.content];
        const item = {...items[itemIndex]}
        if (item.conditionals) {
            const newConditionals = [...item.conditionals].map((conditional) => ({
                ...conditional,
                content: conditional.content.map((conditionalItem) => ({
                    ...conditionalItem,
                    value: "",
                    touched: false,
                }))
            }));
            item.conditionals = newConditionals;
            items[itemIndex] = item
        }
        items[itemIndex] = { ...items[itemIndex], value };
        return { ...formContent, content: items }
    }

    const setItemTouched = (itemIndex: number, formContent: FormContentTouched): FormContent => {
        const items = [...formContent.content];
        items[itemIndex] = { ...items[itemIndex], touched: true };
        return { ...formContent, content: items }
    }

    const setConditionalItemValue = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, formContent: FormContentTouched, value: string|number): FormContent => {
        return getFormContentWithNewConditionalFormItem(itemIndex, conditionalIndex, conditionalItemIndex, formContent, "value", value);
    };

    const setConditionalItemTouched = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, formContent: FormContentTouched): FormContent => {
        return getFormContentWithNewConditionalFormItem(itemIndex, conditionalIndex, conditionalItemIndex, formContent, "touched", true);
    };

    return {
        newIndividualQuickForm,
        addItem,
        setName,
        setOptions,
        setType,
        setRequired,
        saveStandardForm,
        allOptions,
        jobOptions,
        removeItem,
        equatableOptionIds,
        addConditional,
        removeConditional,
        setConditionalItemName,
        setConditionalItemOptions,
        setConditionalItemType,
        setConditionalItemRequired,
        removeConditionalItem,
        addConditionalItem,
        setItemValue,
        setConditionalItemValue,
        setConditionalItemTouched,
        setItemTouched,
    };
}