import { useSetRecoilState } from "recoil";
import { attachedLocationState } from "../states/messagesState";
import { useDrawMode } from "./useDrawMode";

export function useLocationAttachment() {
    const updateAttachedLocation = useSetRecoilState(attachedLocationState);
    const { drawMode, enterDrawMode } = useDrawMode();
    const location = drawMode?.location;

    const initAttachment = () => {
        enterDrawMode({
            type: "attachment",
            action: "new",
        })
    }

    const setAttachment = () => {
        if (!location) {
            return;
        }
        updateAttachedLocation({
            lat: location.lat,
            lng: location.lng,
        });
    };

    return {
        initAttachment,
        setAttachment,
    };
}