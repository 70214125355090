export const reorder = <T>(arr: T[], startIndex: number, endIndex: number): T[] => {
    const list = [...arr];
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
};

export const removeAtIndex = <T>(arr: T[], index: number): T[] => {
    if (index < 0 || index >= arr.length) {
        return arr;
    }
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export const removeAtIndexes = <T>(arr: T[], indexes: number[]): T[] => {
    const list = [...arr];
    indexes.sort((a, b) => b - a);
    for (const index of indexes) {
        if (index < 0 || index >= list.length) {
            continue;
        }
        list.splice(index, 1);
    }
    return list;
}

export const addAtIndex = <T>(arr: T[], index: number, item: T): T[] => {
    if (index < 0 || index > arr.length) {
        return arr;
    }
    const firstPart = arr.slice(0, index);
    const secondPart = arr.slice(index);
    return [...firstPart, item, ...secondPart];
};

export const addXAtIndex = <T>(arr: T[], index: number, ...items: T[]): T[] => {
    if (index < 0 || index > arr.length) {
        return arr;
    }
    const firstPart = arr.slice(0, index);
    const secondPart = arr.slice(index);
    return [...firstPart, ...items, ...secondPart];
}