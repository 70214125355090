import { SORT_DIRECTION, SortDirection } from "baseui/table";
import { atom, selector } from "recoil";
import { durationMillis, TrackEntry, TrackOrStopEntry } from "../types/track";
import {
    retrieveGpsFromGeojson,
    retrieveStopsFromGeojson,
    retrieveTrackEntriesFromGeojson,
    retrieveTrackEntriesOrStopsFromGeojson
} from "../utils/geojsonUtils";
import { dateMinusDays } from "../utils/time";
import { allSelectedTracksLayerState, selectedMembersTracksLayerState } from "./geojsonState";
import { tracksAndStopsConfigState } from "./viewState";
import { atomWithPersistence } from "../utils/persistence";
import { TRACKS_DEFAULT_COLUMNS } from "../constants/tracks";
import { Uid } from "../types/core";

export const hoveredTrackState = atom<TrackEntry | undefined>({
    key: 'tracks.hovered',
    default: undefined,
});

export const tracksSelectedUidsState = atom<Uid[]>({
    key: "tracks.selected.uids",
    default: [],
});

export const waypointsEntriesState = selector<TrackOrStopEntry[]>({
    key: "tracks.waypoints",
    get: ({ get }) => {
        const layers = get(selectedMembersTracksLayerState);
        return layers.map((layer) => retrieveGpsFromGeojson(layer)).flat();
    }
});

export const trackOrStopEntriesState = selector<TrackOrStopEntry[]>({
    key: "tracks.entries",
    get: ({ get }) => {
        const layers = get(selectedMembersTracksLayerState);
        const config = get(tracksAndStopsConfigState);
        const entries: TrackOrStopEntry[] = [];
        if (config.showTracks) {
            const tracks = layers.map((layer) => retrieveTrackEntriesOrStopsFromGeojson(layer)).flat();
            entries.push(...tracks);
        }
        if (config.showWaypoints) {
            const wp = layers.map((layer) => retrieveGpsFromGeojson(layer)).flat();
            entries.push(...wp);
        }
        const sort = get(tracksSortState);
        const sortDirection = get(tracksSortDirectionState);
        entries.sort((e1, e2) => {
            if (sort === TrackSort.DURATION) {
                return durationMillis(e1) - durationMillis(e2);
            }
            if (sort === TrackSort.DISTANCE) {
                return e1.distance - e2.distance;
            }
            if (sort === TrackSort.NAME) {
                return e2.account.localeCompare(e1.account);
            }
            if (sort === TrackSort.APPROVAL) {
                return (e1.approvalStatus || 0) - (e2.approvalStatus || 0);
            }
            return (e1.startTs || e1.endTs) - (e2.startTs || e2.endTs);
        })
        return sortDirection === SORT_DIRECTION.DESC ? entries.reverse() : entries;
    }
});

export const selectedTrackEntriesState = selector<TrackEntry[]>({
    key: 'tracks.entries.selected',
    get: ({ get }) => {
        const selectedTracksLayer = get(allSelectedTracksLayerState);
        return selectedTracksLayer.map((layer) => retrieveTrackEntriesFromGeojson(layer)).flat();
    }
});

export const selectedStopEntriesState = selector<TrackEntry[]>({
    key: 'tracks.stops.selected',
    get: ({ get }) => {
        const selectedTracksLayer = get(allSelectedTracksLayerState);
        return selectedTracksLayer.map((layer) => retrieveStopsFromGeojson(layer)).flat();
    }
});

export const selectedTrackTimeSliderProgress = atom({ key: "tracks.entries.selected.slider.progress", default: 0 });

export enum TrackSort {
    DATE,
    DURATION,
    DISTANCE,
    NAME,
    APPROVAL,
}

export const tracksSortState = atom({
    key: "tracks.sort",
    default: TrackSort.DATE,
});

export const tracksSortDirectionState = atom<SortDirection>({
    key: "tracks.sort.direction",
    default: SORT_DIRECTION.ASC,
});

export const tracksDatesState = atom<(Date | null | undefined)[]>({
    key: "tracks.dates",
    default: [dateMinusDays(new Date(), 6), new Date()],
});

export const isMultiTracksDatesState = atomWithPersistence("tracks.dates.ismulti", false);

export const tracksColumnsState = atomWithPersistence<string[]>("tracks.columns", TRACKS_DEFAULT_COLUMNS);