import React, { useEffect, useState } from 'react';
import { TimeBarView } from "./TimeBarView";
import { useTheme } from "../../hooks/useTheme";
import { VFlex } from "../containers/VFlex";
import { useTime } from "../../hooks/useTime";
import { HOUR } from "../../utils/time";
import { Spacer } from "../containers/Spacer";
import { TimeBadge } from "../badges/TimeBadge";
import { HFlex } from "../containers/HFlex";
import { Slider } from "baseui/slider";
import { LoadingSpinner } from "baseui/button/styled-components";
import { useStrings } from "../../hooks/useStrings";

export function TrackingDiagnosticsSection({ dataUrl }: { dataUrl: string }) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const [startInterval, setStartInterval] = useState<number>(0);
    const [endInterval, setEndInterval] = useState<number>(24.0);
    const [data, setData] = useState<any>();
    const time = useTime();

    useEffect(() => {
        fetch(dataUrl).then(content => content.json()).then(json => setData(json))
    }, [dataUrl]);

    if (!data) {
        return <LoadingSpinner/>;
    }

    const analysis = {
        locationUpdatesStarted: data.locationUpdatesStarted as number[],
        locationUpdatesStopped: data.locationUpdatesStopped as number[],
        locationUpdatesRunning: data.locationUpdatesRunning as number[],
        geofenceExit: data.geofenceExit as number[],
        periodicBg: data.periodicBg as number[],
        periodicFg: data.periodicFg as number[],
        uploadApi: data.uploadApi as number[],
        uploadWs: data.uploadWs as number[],
        online: data.online as number[],
        offline: data.offline as number[],
        webSocketConnected: data.webSocketConnected as number[],
        webSocketDisconnected: data.webSocketDisconnected as number[],
        webSocketOnConnect: data.webSocketOnConnect as number[],
        webSocketSendSuccess: data.webSocketSendSuccess as number[],
        webSocketSendError: data.webSocketSendError as number[],
        webSocketReceive: data.webSocketReceive as number[],
        placeCheckin: data.placeCheckin as number[],
        placeCheckout: data.placeCheckout as number[],
        jobCheckin: data.jobCheckin as number[],
        jobCheckout: data.jobCheckout as number[],
        appStateLaunched: data.appStateLaunched as number[],
        appStateActive: data.appStateActive as number[],
        appStateBackground: data.appStateBackground as number[],
        appStateTerminated: data.appStateTerminated as number[],
        locationError: data.locationError as number[]
    };

    return (
        <VFlex spacing={"24px"} alignCenter>
            {data.timestamp &&
                <HFlex spacing alignCenter>
                    {strings.DeviceDiagnostics.DeviceDiagnosticsUploaded}
                    <TimeBadge withDate startMillis={data.timestamp}/>
                </HFlex>
            }
            <Slider value={[startInterval, endInterval]}
                    onChange={value => {
                        setStartInterval(value.value[0]);
                        setEndInterval(value.value[1]);
                    }}
                    valueToLabel={value => time.formatTimeOnly(data.timestamp - (24 - value) * HOUR)}
                    persistentThumb={false}
                    min={0}
                    max={24}
                    step={0.5}
            />
            <Spacer/>
            <TimeBarView
                title={strings.DeviceDiagnostics.StartStopTrackingEvents}
                tooltip={strings.DeviceDiagnostics.TrackingEventsTooltip}
                interval={[startInterval, endInterval]}
                uploadTs={data.timestamp}
                data={[
                    { timestamps: analysis.locationUpdatesStarted, color: 'green' },
                    { timestamps: analysis.locationUpdatesStopped, color: 'red' },
                ]}
            />
            <TimeBarView
                title={strings.DeviceDiagnostics.WakeUpsRunningEvents}
                tooltip={strings.DeviceDiagnostics.WakeUpsRunningEventsTooltip}
                interval={[startInterval, endInterval]}
                uploadTs={data.timestamp}
                data={[
                    { timestamps: analysis.locationUpdatesRunning, color: 'blue' },
                    { timestamps: analysis.geofenceExit, color: 'green' },
                ]}
            />
            <TimeBarView
                title={strings.DeviceDiagnostics.AppStateEvents}
                tooltip={strings.DeviceDiagnostics.AppStateEventsTooltip}
                interval={[startInterval, endInterval]}
                uploadTs={data.timestamp}
                data={[
                    { timestamps: analysis.appStateLaunched, color: 'blue' },
                    { timestamps: analysis.appStateActive, color: 'green' },
                    { timestamps: analysis.appStateBackground, color: 'orange' },
                    { timestamps: analysis.appStateTerminated, color: 'red' },
                ]}
            />
            <TimeBarView
                title={strings.DeviceDiagnostics.BackgroundTickEvents}
                tooltip={strings.DeviceDiagnostics.BackgroundTickEventsTooltip}
                interval={[startInterval, endInterval]}
                uploadTs={data.timestamp}
                data={[
                    { timestamps: analysis.periodicFg, color: 'green' },
                    { timestamps: analysis.periodicBg, color: theme.colors.contentPrimary },
                ]}
                eventBarWidth={1.0}
            />
            <TimeBarView
                title={strings.DeviceDiagnostics.LocationUploadEvents}
                tooltip={strings.DeviceDiagnostics.LocationUploadEventsTooltip}
                interval={[startInterval, endInterval]}
                uploadTs={data.timestamp}
                data={[
                    { timestamps: analysis.uploadApi, color: 'purple' },
                    { timestamps: analysis.uploadWs, color: theme.colors.contentPrimary },
                ]}
            />
            <TimeBarView
                title={strings.DeviceDiagnostics.ConnectivityEvents}
                tooltip={strings.DeviceDiagnostics.ConnectivityEventsTooltip}
                interval={[startInterval, endInterval]}
                uploadTs={data.timestamp}
                data={[
                    { timestamps: analysis.online, color: 'green' },
                    { timestamps: analysis.offline, color: 'gray' },
                ]}
            />
            <TimeBarView
                title={strings.DeviceDiagnostics.WebsocketConnectivity}
                tooltip={strings.DeviceDiagnostics.WebsocketConnectivityTooltip}
                interval={[startInterval, endInterval]}
                uploadTs={data.timestamp}
                data={[
                    { timestamps: analysis.webSocketConnected, color: 'blue' },
                    { timestamps: analysis.webSocketDisconnected, color: 'orange' },
                    { timestamps: analysis.webSocketOnConnect, color: 'green' },
                ]}
            />
            <TimeBarView
                title={strings.DeviceDiagnostics.WebsocketSendEvents}
                tooltip={strings.DeviceDiagnostics.WebsocketSendEventsTooltip}
                interval={[startInterval, endInterval]}
                uploadTs={data.timestamp}
                data={[
                    { timestamps: analysis.webSocketSendSuccess, color: 'purple' },
                    { timestamps: analysis.webSocketSendError, color: 'gray' },
                ]}
            />
            <TimeBarView
                title={strings.DeviceDiagnostics.WebsocketReceiveEvents}
                tooltip={strings.DeviceDiagnostics.WebsocketReceiveEventsTooltip}
                interval={[startInterval, endInterval]}
                uploadTs={data.timestamp}
                data={[
                    { timestamps: analysis.webSocketReceive, color: theme.colors.contentPrimary },
                ]}
            />
            <TimeBarView
                title={strings.DeviceDiagnostics.CheckInEvents}
                tooltip={strings.DeviceDiagnostics.CheckInEventsTooltip}
                interval={[startInterval, endInterval]}
                uploadTs={data.timestamp}
                data={[
                    { timestamps: analysis.placeCheckin, color: 'blue' },
                    { timestamps: analysis.placeCheckout, color: theme.colors.contentPrimary },
                    { timestamps: analysis.jobCheckin, color: 'purple' },
                    { timestamps: analysis.jobCheckout, color: 'gray' },
                ]}
            />
        </VFlex>
    );
}