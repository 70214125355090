import LocalizedStrings from 'react-localization';
import { selector } from 'recoil';
import { strings } from './../locale/strings';
import { atomWithPersistence } from "../utils/persistence";
import { getBrowserLanguage } from "../utils/browser";

export const currentLanguageState = atomWithPersistence<string>(
    "locale.language.selected",
    window.navigator.languages[0] || getBrowserLanguage()
);

export const stringsState = selector({
    key: "locale.strings",
    get: ({ get }) => {
        const lang = get(currentLanguageState);
        const localizedStrings = new LocalizedStrings(strings);
        localizedStrings.setLanguage(lang);
        return localizedStrings;
    },
});
