import { RecoilState, RecoilValue, useRecoilCallback } from 'recoil';

interface RecoilAccess {
    get?: <T>(atom: RecoilValue<T>) => T;
    set?: <T>(
        atom: RecoilState<T>,
        valOrUpdater: T | ((currVal: T) => T)
    ) => void;
    reset?: <T>(atom: RecoilState<T>) => void;
}

const recoilAccess: RecoilAccess = {};

export default function RecoilAccessProvider() {
    recoilAccess.get = useRecoilCallback<[atom: RecoilValue<any>], any>(
        ({ snapshot }) =>
            function <T>(atom: RecoilValue<T>) {
                return snapshot.getLoadable(atom).contents;
            },
        []
    );

    recoilAccess.set = useRecoilCallback<[atom: RecoilState<any>, valOrUpdater: any | ((currVal: any) => any)],
        void>(
        ({ transact_UNSTABLE }) =>
            function <T>(
                atom: RecoilState<T>,
                valOrUpdater: T | ((currVal: T) => T)
            ) {
                transact_UNSTABLE(({ set }) => {
                    set(atom, valOrUpdater);
                });
            }
    );

    recoilAccess.reset = useRecoilCallback<[atom: RecoilState<any>], void>(
        ({ reset }) =>
            function <T>(atom: RecoilState<T>) {
                reset(atom);
            }
    );

    return null;
}

export function getRecoil<T>(atom: RecoilValue<T>): T {
    return recoilAccess.get!(atom);
}

export function setRecoil<T>(atom: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) {
    recoilAccess.set!(atom, valOrUpdater);
}

export function resetRecoil<T>(atom: RecoilState<T>) {
    recoilAccess.reset!(atom);
}