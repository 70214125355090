import { LabelSmall, ParagraphXSmall } from "baseui/typography";
import { memo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useCamera } from "../../../hooks/useCamera";
import { useStrings } from "../../../hooks/useStrings";
import { useTheme } from "../../../hooks/useTheme";
import { editingMemberIdState } from "../../../states/appState";
import { HoverButton } from "../../buttons/HoverButton";
import { HFlex } from "../../containers/HFlex";
import { Spacer, SpacerExpand } from "../../containers/Spacer";
import { VFlex } from "../../containers/VFlex";
import MemberAvatar from "../../member/MemberAvatar";
import { EllipsedText } from "../../ui/EllipsedText";
import { EditUserIcon, LocateIcon } from "../../ui/svg";
import { memberState } from "../../../states/membersState";
import { Uid } from "../../../types/core";

function MemberRowItem({ uid }: { uid: Uid }) {
    const member = useRecoilValue(memberState(uid));
    const { theme } = useTheme();
    const { strings } = useStrings();
    const camera = useCamera();
    const setEditingMember = useSetRecoilState(editingMemberIdState);

    if (!member) {
        return null;
    }

    return (
        <HFlex alignCenter>
            <MemberAvatar uid={member.uid} forMap={false}/>
            <Spacer/>
            <VFlex style={{ overflow: "hidden" }}>
                <LabelSmall style={{ textAlign: "start" }}>
                    <EllipsedText inline breakAll>
                        {member.name}
                    </EllipsedText>
                </LabelSmall>
                <ParagraphXSmall color={theme.colors.contentSecondary}
                                marginTop={0}
                                marginBottom={0}
                                style={{ fontSize: "10px", lineHeight: "10px", textAlign: "start" }}
                >
                    <EllipsedText inline breakAll>
                        {member.username}
                    </EllipsedText>
                </ParagraphXSmall>
            </VFlex>
            <SpacerExpand/>
            <HoverButton
                tooltip={strings.General.OpenProfile}
                size={"mini"}
                onClick={() => setEditingMember(member.uid)}
                kind={"tertiary"}
            >
                <EditUserIcon size={12} color={theme.colors.contentTertiary}/>
            </HoverButton>
            <HoverButton
                tooltip={strings.General.Locate}
                size={"mini"}
                onClick={() => camera.zoomTo(member?.location)}
                kind={"tertiary"}
            >
                <LocateIcon size={12} color={theme.colors.contentTertiary}/>
            </HoverButton>
        </HFlex>
    );
}

export default memo(MemberRowItem);