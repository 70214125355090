import { HFlex } from '../containers/HFlex';
import { TrackEntry } from "../../types/track";
import { LabelSmall } from "baseui/typography";
import { VFlex } from "../containers/VFlex";
import { useCamera } from "../../hooks/useCamera";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { hoveredTrackState } from "../../states/tracksState";
import { prefAutoZoomState } from "../../states/appState";
import { Bullet } from "./Bullet";
import { Event } from "./Event";
import { TimelineEntry } from "../../types/timeline";
import { useTheme } from "../../hooks/useTheme";
import { BULLET_SIZE } from "../../constants/timeline";
import { Line } from "./Line";
import { useEffect, useRef, useState } from "react";
import { COLOR_MOVING } from "../../constants/colors";
import { useHover } from "usehooks-ts";
import { EnlargeVerticalIcon, InfoIcon } from '../ui/svg';
import { Button } from 'baseui/button';
import { useUnit } from "../../hooks/useUnit";
import { EllipsedText } from "../ui/EllipsedText";
import { useStrings } from "../../hooks/useStrings";
import { TimeBadge } from "../badges/TimeBadge";

type Props = {
    track: TrackEntry;
    data: [];
    events: TimelineEntry[];
    onTrackInfo: (track?: TrackEntry) => void;
};

export function TrackSection({ track, data, events, onTrackInfo }: Props) {
    const { theme } = useTheme();
    const { strings, format } = useStrings();
    const camera = useCamera();
    const setHoveredTrack = useSetRecoilState(hoveredTrackState);
    const prefAutoZoom = useRecoilValue(prefAutoZoomState);
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const { distanceToString } = useUnit();
    const defaultEventsShown = 4;
    const [showEvents, setShowEvents] = useState(events.length <= defaultEventsShown);

    useEffect(() => {
        setHoveredTrack(isHover ? track : undefined);
        if (isHover && prefAutoZoom) {
            camera.fitToLngLats(data);
        }
    }, [isHover])

    const size = 64;
    const padding = (size - BULLET_SIZE) / 2;

    const onStartStopClick = (isStart: boolean) => {
        camera.zoomWithTempMarker({
            lat: isStart ? track.startLat : track.endLat,
            lng: isStart ? track.startLng : track.endLng,
        }, isStart ? track.startText : track.endText);
    };

    const startStopRow = (isStart: boolean) => {
        return (
            <HFlex spacing alignCenter
                   onClick={() => onStartStopClick(isStart)}
                   style={{ height: size + "px" }}
            >
                <TimeBadge startMillis={isStart ? track.startTs : track.endTs}
                           color={isHover ? COLOR_MOVING : "geekblue"}
                           style={{ minWidth: '68px' }}/>
                <LabelSmall>
                    <EllipsedText maxLines={3}>{isStart ? track.startText : track.endText}</EllipsedText>
                </LabelSmall>
            </HFlex>
        );
    };

    return (
        <>
            <div ref={hoverRef}>
                <HFlex style={{ gap: theme.sizing.scale400 }}>
                    <VFlex alignCenter style={{ height: "auto" }}>
                        <Line height={padding + "px"}/>
                        <Bullet content={track.startLetter}/>
                        <Line primary hovering={isHover}/>
                        <div style={{
                            writingMode: "vertical-rl",
                            fontSize: "9px"
                        }}>{distanceToString(track.distance, true)}</div>
                        <Line primary hovering={isHover}/>
                        <Bullet content={track.endLetter}/>
                        <Line height={padding + "px"}/>
                    </VFlex>
                    <VFlex style={{
                        rowGap: theme.sizing.scale300,
                    }}>
                        {startStopRow(true)}
                        {showEvents && events.map((child, index) =>
                            <Event key={index} entry={child}/>
                        )}
                        {!showEvents && events.length > defaultEventsShown &&
                            <Button size={"mini"}
                                    kind={"secondary"}
                                    onClick={() => setShowEvents(true)}>
                                <EnlargeVerticalIcon size={12} color={theme.colors.contentPrimary}/>
                                {format(strings.Timeline.ShowAllXEvents, events.length)}
                            </Button>
                        }
                        {startStopRow(false)}
                    </VFlex>
                    <div>
                        <Button
                            size="mini"
                            kind="tertiary"
                            shape="circle"
                            onClick={() => onTrackInfo(track)}
                        >
                            <InfoIcon size={14} color={theme.colors.contentPrimary}/>
                        </Button>
                    </div>
                </HFlex>
            </div>
        </>
    );
}
