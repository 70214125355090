import { VFlex } from "../containers/VFlex";
import { StyledTable } from "baseui/table";
import { AllJobsHeaderRow } from "./AllJobsHeaderRow";
import { default as AutoSizer } from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import AllJobsRow from "./AllJobsRow";
import React from "react";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { useAllJobsColumns } from "../../hooks/jobs/useAllJobsColumns";
import { Job } from "../../types/job";
import { Droppable } from "react-beautiful-dnd";
import { DROPPABLE_ALL_JOBS_TABLE, DROPPABLE_TYPE_JOB } from "../../constants/dnd";
import { TableJob } from "../dispatch/TableJob";

export function JobsGrid({ jobs }: { jobs: Job[] }) {
    const { tableStyle } = useStyleOverrides();
    const { tableWidth } = useAllJobsColumns();

    return (
        <VFlex
            style={{
                flex: 1,
                overflowX: "auto",
                overflowY: "hidden",
            }}
        >
            <StyledTable
                role="grid"
                style={{ ...tableStyle, width: `${tableWidth}px`, minWidth: "100%", boxSizing: "border-box" }}
            >
                <AllJobsHeaderRow jobs={jobs}/>
                <Droppable
                    droppableId={DROPPABLE_ALL_JOBS_TABLE}
                    type={DROPPABLE_TYPE_JOB}
                    isDropDisabled={true}
                    mode="virtual"
                    renderClone={(provided, snapshot, rubric) => (
                        <TableJob
                            jobId={jobs[rubric.source.index].id}
                            provided={provided}
                            isDragging={snapshot.isDragging}
                        />
                    )}
                >
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                flex: 1,
                            }}
                        >
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        height={height}
                                        itemCount={jobs.length}
                                        itemSize={() => 32}
                                        width={width}
                                        itemData={jobs}
                                        style={{ overflowX: "hidden" }}
                                    >
                                        {AllJobsRow}
                                    </List>
                                )}
                            </AutoSizer>
                        </div>
                    )}
                </Droppable>
            </StyledTable>
        </VFlex>
    );
}