import { Button } from "baseui/button";
import { NumberedStep, ProgressSteps } from "baseui/progress-steps";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import {
    selectedReportConfigState,
    selectedReportDatesState,
    selectedReportFormatState,
    selectedReportMembersState,
    selectedReportPlacesState
} from "../../states/reportState";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { ReportFilterStep } from "./ReportFilterStep";
import { ReportDatesStep } from "./ReportDatesStep";
import { ReportFormatStep } from "./ReportFormatStep";
import { useReports } from "../../hooks/useReports";
import { CheckCircledIcon } from "../ui/svg";
import { useEffectOnce } from "usehooks-ts";
import { ReportColumnsStep } from "./ReportColumnsStep";

export function ReportCreation() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const { getFilterTitle, getDatesTitle } = useReports();
    const [reportConfig, setReportConfig] = useRecoilState(selectedReportConfigState);
    const hasFilterStep = reportConfig?.canSelectMembers || reportConfig?.canSelectPlaces;
    const [current, setCurrent] = useState(hasFilterStep ? 0 : 1);
    const format = useRecoilValue(selectedReportFormatState);
    const members = useRecoilValue(selectedReportMembersState);
    const places = useRecoilValue(selectedReportPlacesState);
    const dates = useRecoilValue(selectedReportDatesState);
    const [isLoading, setIsLoading] = useState(false);
    const reports = useReports();
    const hasAvailableColumns = !!reportConfig?.availableColumns?.length;

    useEffectOnce(() => {
        return reports.cleanup;
    });

    const handleNext = () => {
        setCurrent((prev) => prev + 1);
    }

    const handleCancel = () => {
        setReportConfig(undefined);
    };

    const handleBack = () => {
        if (current === 0 || (current === 1 && !hasFilterStep)) {
            setReportConfig(undefined);
        } else {
            setCurrent((prev) => prev - 1);
        }
    };

    const canContinue = () => {
        if (current === 0) {
            if (reportConfig?.canSelectMembers || reportConfig?.canSelectPlaces) {
                return !!places.length || !!members.length;
            }
            return false;
        }
        if (current === 1) {
            if (reportConfig?.allowMultipleDates) {
                return dates.length === 2;
            }
            return !!dates.length;
        }
        if (current === totalStepsCount) {
            return false;
        }
        return true;
    };

    if (!reportConfig) {
        return null;
    }

    const generateReport = async () => {
        if (!format) {
            return;
        }
        setIsLoading(true);
        await reports.generateReport()
        setIsLoading(false);
        setReportConfig(undefined);
    };

    const coreStepsCount = hasAvailableColumns ? 2 : 1;
    const totalStepsCount = reportConfig.maxDays === 0 ? coreStepsCount : coreStepsCount + 1;

    const buttons = <HFlex>
        {current > 0 &&
            <Button kind="secondary" size="compact" onClick={handleBack}>
                {strings.General.Back}
            </Button>
        }
        {current < totalStepsCount && (<Button
            kind="primary"
            size="compact"
            onClick={handleNext}
            disabled={!canContinue()}
        >
            {strings.General.Next}
        </Button>)}
        {current === totalStepsCount && (
            <>
                <Button
                    kind="primary"
                    size="compact"
                    onClick={generateReport}
                    disabled={!format}
                    isLoading={isLoading}
                >
                    <CheckCircledIcon color={theme.colors.contentInversePrimary} size={16}/>
                    <Spacer/> {strings.General.Download}
                </Button>
                <Spacer/>
            </>
        )}
    </HFlex>;

    const filterStep = (
        <NumberedStep key="filter" title={getFilterTitle()}>
            <ReportFilterStep onNext={() => {}}/>
            <Spacer/>
            {buttons}
        </NumberedStep>
    );

    const datesStep = (
        <NumberedStep key="dates" title={getDatesTitle()}>
            <ReportDatesStep/>
            <Spacer/>
            {buttons}
        </NumberedStep>
    );

    const columnsStep = (
        <NumberedStep key="columns" title={strings.Reports.Columns}>
            <ReportColumnsStep/>
            <Spacer/>
            {buttons}
        </NumberedStep>
    );

    const formatStep = (
        <NumberedStep key="format" title={strings.General.Format}>
            <ReportFormatStep/>
            <Spacer height="48px"/>
            {buttons}
        </NumberedStep>
    );

    const coreSteps = hasAvailableColumns ? [filterStep, columnsStep, formatStep] : [filterStep, formatStep];
    const steps = reportConfig.maxDays === 0 ? coreSteps : [...coreSteps.slice(0, 1), datesStep, ...coreSteps.slice(1)];

    return (
        <VFlex>
            <ProgressSteps
                current={current}
                overrides={{
                    Root: {
                        style: {
                            height: "100%",
                            overflowY: "auto",
                        },
                    },
                }}
            >
                {steps}
            </ProgressSteps>
            <HFlex spacing alignCenter style={{
                marginRight: "8px",
                marginBottom: "8px"
            }}>
                <Button kind="tertiary" size="compact" onClick={handleCancel}>
                    {strings.General.Cancel}
                </Button>
                <SpacerExpand/>
            </HFlex>
        </VFlex>
    );
}
