import { JobType } from '../types/core';
import { companyState } from './accountState';
import { atom, selector } from 'recoil';

export const editJobTypesState = atom<JobType[]>({
    key: 'edit.jobTypes',
    default: selector({
        key: 'edit.jobTypes.initializer',
        get: ({ get }) => {
            const company = get(companyState);
            if (!company) {
                return [];
            }
            return company.job_custom_types;
        },
    }),
});