import { Button, KIND } from 'baseui/button';
import { StatefulTooltip } from 'baseui/tooltip';
import { SHAPE, SIZE } from "baseui/button/constants";
import { ReactNode } from "react";
import { TetherPlacement } from "baseui/layer";

type Props = {
    tooltip: string | ReactNode;
    disabled?: boolean;
    onClick?: Function;
    size?: keyof typeof SIZE;
    kind?: keyof typeof KIND;
    children: React.ReactNode;
    shape?: keyof typeof SHAPE;
    color?: string;
    loading?: boolean;
    opacity?: number;
    placement?: TetherPlacement;
};

export function ActionButton({
                                 tooltip,
                                 disabled = false,
                                 onClick = () => {
                                 },
                                 size = "compact",
                                 kind = "secondary",
                                 shape = "default",
                                 placement,
                                 children,
                                 color,
                                 loading = false,
                                 opacity = 1
                             }: Props) {

    const baseButton: any = color && { style: { backgroundColor: color, ":hover": { backgroundColor: color } } };

    return (
        <StatefulTooltip content={tooltip} showArrow placement={placement}>
            <Button
                disabled={disabled}
                size={size}
                kind={kind}
                onClick={() => onClick()}
                shape={shape}
                isLoading={loading}
                overrides={
                    {
                        Root: {
                            style: {
                                opacity,
                                transitionProperty: 'opacity',
                                transitionDuration: '0.5s',
                                transitionTimingFunction: 'ease',
                            }
                        },
                        BaseButton: baseButton,
                    }
                }
            >
                {children}
            </Button>
        </StatefulTooltip>
    );
}
