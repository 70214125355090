import { Button } from "baseui/button";

export function StoreButtons({noPadding = false}: {noPadding?: boolean}) {
    const overrides = {
        BaseButton: noPadding ? {
            style: {
                paddingTop: 0,
                paddingRight: 0,
                paddingBottom: 0,
                paddingLeft: 0,
            }
        } : undefined
    }
    return (
        <>
            <Button
                kind={"tertiary"}
                overrides={overrides}
                onClick={() => window.open("https://play.google.com/store/apps/details?id=com.hellotracks", "_blank")}
            >
                <img src={"/images/google-play.svg"} loading="lazy" alt="" />
            </Button>

            <Button
                kind={"tertiary"}
                overrides={overrides}
                onClick={() =>
                    window.open("https://apps.apple.com/app/hellotracks-gps-locator/id1131659686", "_blank")
                }
            >
                <img src={"/images/app-store.svg"} loading="lazy" alt="" />
            </Button>
        </>
    );
}
