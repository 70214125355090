import { FormControl } from "baseui/form-control";
import { HFlex } from "../../containers/HFlex";
import { CalendarMiniIcon, ClockMiniIcon, StopWatchOutlineIcon } from "../../ui/svg";
import { Checkbox } from "baseui/checkbox";
import { LabelXSmall, ParagraphXSmall } from "baseui/typography";
import { Tooltip } from "antd";
import { Button } from "baseui/button";
import { Datepicker } from "baseui/datepicker";
import { dateToDayBrowserTimezone, dateToTime, dayToDateBrowserTimezone } from "../../../utils/time";
import { FlexGridLayout } from "../../containers/FlexGridLayout";
import { hasTimeWindow } from "../../../utils/timeWindow";
import { StyledTimePicker } from "../../pickers/StyledTimePicker";
import { Badge, COLOR } from "baseui/badge";
import { Spacer } from "../../containers/Spacer";
import { FormInput } from "../../ui/FormInput";
import { Section } from "../../ui/Section";
import React, { useState } from "react";
import { useCompanyDefaults } from "../../../hooks/company/useCompanyDefaults";
import { useTime } from "../../../hooks/useTime";
import { useStrings } from "../../../hooks/useStrings";
import { useTheme } from "../../../hooks/useTheme";
import { useRecoilState, useRecoilValue } from "recoil";
import { editJobState } from "../../../states/editJobState";
import { editingJobIdState } from "../../../states/appState";
import { Job } from "../../../types/job";
import { jobCompactVisualizationState } from "../../../states/optionsState";

export function JobDateAndTimeSection() {
    const jobId = useRecoilValue(editingJobIdState);
    const [fields, setFields] = useRecoilState(editJobState(jobId || ''));
    const [editTimeWindowClicked, setEditTimeWindowClicked] = useState(false);
    const {
        setCarryOverDefault,
        setOnSiteDefault,
    } = useCompanyDefaults();
    const { longFormDateFormat } = useTime();
    const time = useTime();
    const { strings } = useStrings();
    const { theme } = useTheme();
    const compact = useRecoilValue(jobCompactVisualizationState);

    if (!fields) {
        return null;
    }

    const onCarryOverChange = () => {
        setFields((prev) => ({ ...prev, carryOver: prev && prev.carryOver > 0 ? 0 : 1 } as Job));
    }

    const onDateChange = (date?: Date) => {
        setFields((prev) => ({ ...prev, day: date ? dateToDayBrowserTimezone(date) : 0 } as Job));
    }

    const onSiteSecondsChange = (key: string, value: string | number) => {
        setFields((prev) => ({ ...prev, onSiteSeconds: value as number * 60 } as Job));
    }

    const onWindowTimeChange = (date: Date | null, target: string) => {
        if (date) {
            setFields((prev) => ({ ...prev, [target]: dateToTime(date) } as Job));
        } else {
            setFields((prev) => ({ ...prev, [target]: null } as Job))
        }
    }

    return (
        <Section title={strings.Job.DateAndTime}
                 expandable={compact}
                 collapsedNode={fields.day && time.formatDay(fields.day)}
        >
            {!fields.isPartOfSequence &&
                <FormControl label={
                    <HFlex spacing={"4px"}>
                        <CalendarMiniIcon color={theme.colors.contentTertiary} size={16}/>
                        {strings.Job.Date}
                    </HFlex>
                } caption={
                    <HFlex spacing alignCenter style={{
                        alignItems: 'flex-start',
                    }}>
                        <Checkbox
                            overrides={{
                                Root: {
                                    style: {
                                        flexGrow: 1
                                    }
                                },
                                Label: {
                                    style: {
                                        fontSize: theme.sizing.scale550,
                                    },
                                },
                            }}
                            checked={fields.carryOver > 0}
                            onChange={onCarryOverChange}
                        >
                        </Checkbox>
                        <LabelXSmall>{strings.Job.CarryOverNextDayIfUnfinished}</LabelXSmall>
                        <Tooltip title={strings.Job.RememberCarryOverSetting}>
                            <Button
                                onClick={() => setCarryOverDefault(fields.carryOver > 0)}
                                kind="secondary"
                                size="mini"
                            >
                                {strings.General.SetAsDefault}
                            </Button>
                        </Tooltip>
                    </HFlex>
                }>
                    <Datepicker
                        value={fields.day ? [dayToDateBrowserTimezone(fields.day)] : []}
                        size="compact"
                        formatString={longFormDateFormat}
                        placeholder={longFormDateFormat}
                        onChange={({ date }) => {
                            if (date instanceof Date) {
                                onDateChange(date)
                            } else {
                                onDateChange();
                            }
                        }}
                        clearable
                    />
                </FormControl>
            }
            <FlexGridLayout>
                <FormControl
                    label={
                        <HFlex spacing={"4px"}>
                            <ClockMiniIcon color={theme.colors.contentTertiary} size={16}/>
                            {strings.General.TimeWindow}
                        </HFlex>
                    }
                    caption={strings.General.TimeWindowDesc}
                >
                    <HFlex alignCenter>
                        {editTimeWindowClicked || hasTimeWindow(fields.windowStart, fields.windowEnd) ?
                            <>
                                <StyledTimePicker
                                    value={fields.windowStart}
                                    onChange={(date) => onWindowTimeChange(date, 'windowStart')}
                                />
                                {"—"}
                                <StyledTimePicker
                                    value={fields.windowEnd}
                                    onChange={(date) => onWindowTimeChange(date, 'windowEnd')}
                                />
                            </> : <>
                                <Badge content={strings.General.NotSet} hierarchy={"secondary"}
                                       color={COLOR.primary}/>
                                <Spacer/>
                                <Button
                                    size={"mini"}
                                    kind={"secondary"}
                                    onClick={() => setEditTimeWindowClicked(true)}>
                                    {strings.General.Edit}
                                </Button>
                            </>}
                    </HFlex>
                </FormControl>
                <FormInput
                    label={
                        <HFlex spacing={"4px"}>
                            <StopWatchOutlineIcon size={16} color={theme.colors.contentTertiary}/>
                            {strings.Job.OnSiteTime}
                        </HFlex>
                    }
                    value={Math.floor(fields.onSiteSeconds / 60) || ''}
                    field="onSiteSeconds"
                    type="number"
                    min={0}
                    onChange={onSiteSecondsChange}
                    caption={
                        <HFlex spacing style={{
                            alignItems: 'flex-start',
                        }}>
                            <span style={{ flexGrow: 1 }}>{strings.Job.OnSiteCaption}</span>
                            <Tooltip title={strings.Job.RememberOnSiteSetting}>
                                <Button
                                    onClick={() => setOnSiteDefault(fields.onSiteSeconds)}
                                    kind="tertiary"
                                    size="mini"
                                >
                                    {strings.General.SetAsDefault}
                                </Button>
                            </Tooltip>
                        </HFlex>
                    }
                    startEnhancer={<StopWatchOutlineIcon size={14} color={theme.colors.contentTertiary}/>}
                    endEnhancer={<ParagraphXSmall marginTop={0}
                                                  marginBottom={0}>{strings.Job.minutes}</ParagraphXSmall>}
                />
            </FlexGridLayout>
        </Section>
    );
}