import { PAGE } from "../../../constants/pages";
import { Layer, Source } from "react-map-gl";
import { zonesLayerStyle, zonesOutlineLayerStyle, zonesSelectedOutlineLayerStyle } from "../mapStyle";
import { useRecoilValue } from "recoil";
import { pageState } from "../../../states/appState";
import { showZonesLayerState } from "../../../states/mapState";
import { selectedZonesLayerState, zonesLayerState } from "../../../states/geojsonState";

export function ZonesLayer() {
    const page = useRecoilValue(pageState);
    const showZonesLayer = useRecoilValue(showZonesLayerState);
    const zonesLayer = useRecoilValue(zonesLayerState);
    const selectedZonesLayer = useRecoilValue(selectedZonesLayerState);

    if ((page !== PAGE.PLACES && !showZonesLayer) || !zonesLayer) {
        return null;
    }

    return (
        <>
            <Source type="geojson" data={zonesLayer}>
                <Layer {...zonesLayerStyle}/>
                <Layer {...zonesOutlineLayerStyle}/>
            </Source>
            {selectedZonesLayer && (
                <Source type="geojson" data={selectedZonesLayer}>
                    <Layer {...zonesSelectedOutlineLayerStyle}/>
                </Source>
            )}
        </>
    );
}