import { SpacerExpand } from "../containers/Spacer";
import { HFlex } from "../containers/HFlex";
import React from "react";
import { LocationDateRange } from "./LocationDateRange";
import { SwapTab } from "../ui/SwapTab";
import { useRecoilState, useRecoilValue } from "recoil";
import { JourneyIcon, TimelineIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";
import { GlobalDatePicker } from "../pickers/GlobalDatePicker";
import { pageState } from "../../states/appState";
import { useNavigator } from "../../hooks/useNavigator";
import { PAGE } from "../../constants/pages";
import { Button } from "baseui/button";
import { NoWrapText } from "../containers/NoWrapText";
import { ButtonGroup } from "baseui/button-group";
import { isMultiTracksDatesState } from "../../states/tracksState";

export function LocationCardTitle() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const page = useRecoilValue(pageState);
    const { navigateToPage } = useNavigator();
    const [multiDay, setMultiDay] = useRecoilState(isMultiTracksDatesState);

    return (
        <HFlex spacing alignCenter style={{ width: "100%", height: "64px" }}>
            <SwapTab activeKey={page}
                     onChange={(activeKey) => navigateToPage(activeKey)}
                     tab1={{
                         key: PAGE.TIMELINE,
                         label: <TimelineIcon color={theme.colors.contentPrimary} size={14}/>,
                         description: strings.General.Timeline,
                     }}
                     tab2={{
                         key: PAGE.LOCATION,
                         label: <JourneyIcon color={theme.colors.contentPrimary} size={14}/>,
                         description: strings.General.Location,
                     }}
            />
            <SpacerExpand/>
            {page === PAGE.TIMELINE ? <>
                <GlobalDatePicker expanded/>
            </> : <>
                <ButtonGroup size="mini"
                             mode="radio"
                             selected={multiDay ? 1 : 0}
                             onClick={(_, index) => { setMultiDay(index > 0)}}
                >
                    <Button><NoWrapText>1d</NoWrapText></Button>
                    <Button><NoWrapText>{strings.General.Range}</NoWrapText></Button>
                </ButtonGroup>
                {multiDay ? <LocationDateRange/> : <GlobalDatePicker expanded/>}
            </>
            }
        </HFlex>
    );
}