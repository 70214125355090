import { useRecoilValue } from "recoil";
import { sortedMembersState } from "../../../states/membersState";
import { StyledTable } from "baseui/table";
import { MembersTableRow } from "./MembersTableRow";
import { VFlex } from "../../containers/VFlex";
import { Spacer, SpacerExpand } from "../../containers/Spacer";
import { LiveMemberStats } from "../../statistics/stats/LiveMemberStats";
import { HFlex } from "../../containers/HFlex";
import { useStyleOverrides } from "../../../hooks/useStyleOverrides";
import { MembersTableHeaderRow } from "./MembersTableHeaderRow";
import { VariableSizeList as List } from "react-window";
import { default as AutoSizer } from "react-virtualized-auto-sizer";
import React, { memo } from "react";
import { InviteCodeSection } from "../InviteCodeSection";
import { useWindowSize } from "usehooks-ts";
import { cardSizesState } from "../../../states/skeletonState";
import { pageState } from "../../../states/appState";
import { ColumnOptions } from "../../shared/ColumnOptions";
import { useMembersColumns } from "../../../hooks/members/useMembersColumns";


export const MembersTable = memo(() => {
    const { tableStyle } = useStyleOverrides();
    const sortedMembers = useRecoilValue(sortedMembersState);
    const window = useWindowSize();
    const page = useRecoilValue(pageState);
    const { smallCardSize } = useRecoilValue(cardSizesState(page));
    const cardPixels = smallCardSize * window.width / 100;
    const { columns, enabledColumns, columnsConfiguration, disabledColumns, reorderColumns, tableWidth } = useMembersColumns();
    const uids = sortedMembers.map(m => m.uid);

    return (
        <VFlex style={{ overflow: "hidden", flexGrow: 1 }}>
            <VFlex style={{
                flex: 1,
                position: "relative",
            }}>
                <ColumnOptions
                    columns={columns}
                    enabledColumns={enabledColumns}
                    configuration={columnsConfiguration}
                    disabledColumns={disabledColumns}
                    onReorder={reorderColumns}
                    top="3px"
                />
                <div style={{ flex: 1, overflowX: "auto", overflowY: "hidden" }}>
                    <StyledTable
                        role="grid"
                        style={{
                            ...tableStyle,
                            width: `${tableWidth}px`,
                            minWidth: "100%",
                            boxSizing: "border-box",
                        }}
                    >
                        <MembersTableHeaderRow/>
                        <div style={{ flex: 1 }}>
                            <AutoSizer>
                                {({ height, width }) => {
                                    return (
                                        <List 
                                            height={height}
                                            itemCount={uids.length}
                                            itemSize={() => 60}
                                            width={width}
                                            itemData={uids}
                                            style={{ overflowX: "hidden" }}
                                        >
                                            {MembersTableRow}
                                        </List>
                                    )
                                }}
                            </AutoSizer>
                        </div>
                    </StyledTable>
                </div>
            </VFlex>
            <Spacer/>
            <HFlex spacing style={{ width: "100%" }}>
                <SpacerExpand/>
                {cardPixels > 600 && <LiveMemberStats/>}
                <InviteCodeSection/>
                <SpacerExpand/>
            </HFlex>
            <Spacer/>
        </VFlex>
    );
});