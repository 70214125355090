import { Menu, Sidebar as ProSidebar, SubMenu, useProSidebar } from "react-pro-sidebar";
import React, { memo, useEffect, useRef } from "react";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { useMenu } from "../../hooks/useMenu";
import { Centered } from "../containers/Centered";
import { LabelXSmall } from "baseui/typography";
import { LogoIcon, PinIcon } from "../ui/svg";
import { COLOR_OFFWHITE, COLOR_SUPERDARK, COLOR_WHITE } from "../../constants/colors";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    accountMenuIsOpenState,
    blurState,
    pageState,
    sidebarAnalyzeOpenState,
    sidebarBrokenState,
    sidebarCollapsedState,
    sidebarManageOpenState,
    sidebarMoreOpenState,
    sidebarPinnedState,
    sidebarWidthState
} from "../../states/appState";
import { HELLOTRACKS, SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_ITEM_HEIGHT, SIDEBAR_WIDTH } from "../../constants/app";
import { AccountMenu } from "./AccountMenu";
import { HFlex } from "../containers/HFlex";
import { useStrings } from "../../hooks/useStrings";
import { Tooltip, Tour } from "antd";
import { useOnboardMembers } from "../../hooks/onboard/useOnboardMembers";
import { useOnboardPlaces } from "../../hooks/onboard/useOnboardPlaces";
import { useOnboardNotifications } from "../../hooks/onboard/useOnboardNotifications";
import { useOnboardDownload } from "../../hooks/onboard/useOnboardDownload";
import { useOnboardPermissions } from "../../hooks/onboard/useOnboardPermissions";
import { useOnboardTrackingSettings } from "../../hooks/onboard/useOnboardTrackingSettings";
import { useOnboardJobs } from "../../hooks/onboard/useOnboardJobs";
import { useOnboardRefs } from "../../hooks/onboard/useOnboardRefs";
import SidebarItem from "./SidebarItem";
import { useTheme } from "../../hooks/useTheme";
import { PAGE } from "../../constants/pages";
import { PageType } from "../../types/core";
import { useHover, useUpdateEffect } from "usehooks-ts";
import { VFlex } from "../containers/VFlex";
import SubmenuItem from "./SubmenuItem";
import { Button } from "baseui/button";

function Sidebar() {
    const { strings } = useStrings();
    const { broken, collapseSidebar, collapsed } = useProSidebar();
    const { css, theme, isDarkTheme } = useTheme();
    const menu = useMenu();
    const sidebarWidth = useRecoilValue(sidebarWidthState);
    const setSidebarBroken = useSetRecoilState(sidebarBrokenState);
    useEffect(() => {
        setSidebarBroken(broken);
    }, [broken]);
    const setSidebarCollapsed = useSetRecoilState(sidebarCollapsedState);
    useEffect(() => setSidebarCollapsed(collapsed), [collapsed]);
    const [sidebarPinned, setSidebarPinned] = useRecoilState(sidebarPinnedState);
    useEffect(() => {
        if (sidebarPinned) {
            collapseSidebar(false);
        } else {
            collapseSidebar(true);
        }
    }, [sidebarPinned]);
    const [manageOpen, setManageOpen] = useRecoilState(sidebarManageOpenState);
    const [analyzeOpen, setAnalyzeOpen] = useRecoilState(sidebarAnalyzeOpenState);
    const [moreOpen, setMoreOpen] = useRecoilState(sidebarMoreOpenState);
    const { memberSteps, onboardMembersIsOpen, memberStep, membersNextStep } = useOnboardMembers();
    const { placesNextStep, onboardPlacesIsOpen, placeStep, placesSteps } = useOnboardPlaces();
    const { onboardNotificationsIsOpen, notificationStep, notificationsSteps } = useOnboardNotifications();
    const { teamRef, placesRef, dispatchRef } = useOnboardRefs();
    const {
        downloadSteps,
        onboardDownloadIsOpen,
        downloadStep
    } = useOnboardDownload();
    const { permissionsSteps, onboardPermissionsIsOpen, permissionStep } = useOnboardPermissions();
    const { trackingSettingsSteps, onboardTrackingSettingsIsOpen, trackingSettingsStep } = useOnboardTrackingSettings();
    const { jobsSteps, onboardJobsIsOpen, jobStep } = useOnboardJobs();
    const page = useRecoilValue(pageState);
    const sidebarRef = useRef(null);
    const sidebarIsHover = useHover(sidebarRef);
    const accountMenuIsOpen = useRecoilValue(accountMenuIsOpenState);
    const isBlurEnabled = useRecoilValue(blurState);
    useUpdateEffect(() => {
        if (sidebarPinned) {
            return;
        }
        if (sidebarIsHover) {
            collapseSidebar(false);
        } else if (!accountMenuIsOpen) {
            collapseSidebar(true);
        }
    }, [sidebarIsHover, accountMenuIsOpen]);

    const analyzePages: PageType[] = [PAGE.STATS, PAGE.TIMESHEET, PAGE.REPORTS];
    const managePages: PageType[] = [PAGE.TEAM, PAGE.PLACES, PAGE.FORMS, PAGE.SETTINGS];
    const morePages: PageType[] = [PAGE.ALERTS, PAGE.INTEGRATIONS, PAGE.MESSAGES, PAGE.HELP, PAGE.UPLOADS, PAGE.HEATMAP];

    const indicators = () => <span/>;

    const setMenuOpen = (menu: "jobs" | "manage" | "analyze" | "more", isOpen: boolean) => {
        switch (menu) {
            case "manage":
                setManageOpen(isOpen);
                break;
            case "analyze":
                setAnalyzeOpen(isOpen);
                break;
            case "more":
                setMoreOpen(isOpen)
                break;
        }
    };

    const AnalyzeMenuItems = memo(() =>
        <>
            <SidebarItem pageMenuItem={menu.dashboard}/>
            <SidebarItem pageMenuItem={menu.reports}/>
            <SidebarItem pageMenuItem={menu.timesheet}/>
            <SidebarItem pageMenuItem={menu.activitylog}/>
        </>
    );

    const MoreMenuItems = memo(() =>
        <>
            <SidebarItem pageMenuItem={menu.alerts}/>
            <SidebarItem pageMenuItem={menu.messages}/>
            <SidebarItem pageMenuItem={menu.heatmap}/>
            <SidebarItem pageMenuItem={menu.uploads}/>
            <SidebarItem pageMenuItem={menu.integrations}/>
            <SidebarItem pageMenuItem={menu.help}/>
        </>
    );

    const fontColor = isDarkTheme ? COLOR_WHITE : "rgba(60, 60, 67, .6)";
    const subColor = isDarkTheme ? "rgba(235, 235, 245, .3)" : "rgba(60, 60, 67, .4)";
    const divColor = isDarkTheme ? "rgba(235, 235, 245, .1)" : "rgba(60, 60, 67, .1)";

    return (
        <ProSidebar
            width={(broken ? SIDEBAR_WIDTH : sidebarWidth) + "px"}
            backgroundColor={isBlurEnabled
                ? (isDarkTheme ? "#0009" : "#FFFC")
                : (isDarkTheme ? COLOR_SUPERDARK : COLOR_OFFWHITE)}
            style={{
                zIndex: 3,
                height: "100%",
                backdropFilter: isBlurEnabled ? "blur(50px)" : undefined,
                WebkitBackdropFilter: isBlurEnabled ? "blur(50px)" : undefined,
            }}
            breakPoint="lg"
            collapsedWidth={SIDEBAR_COLLAPSED_WIDTH + "px"}
            ref={sidebarRef}
        >
            <Menu
                menuItemStyles={{
                    icon: {
                        width: "32px",
                        minWidth: "32px",
                        maxWidth: "32px",
                        height: "32px",
                        minHeight: "32px",
                        maxHeight: "32px",
                    },
                    button: ({ active, isSubmenu }) => {
                        return {
                            backgroundColor: active && !collapsed
                                ? (isDarkTheme ? "rgba(255, 255, 255, .08)" : "rgba(0, 0, 0, .04)")
                                : "transparent",
                            backgroundImage: "none",
                            borderRadius: "8px",
                            marginLeft: "0px",
                            marginRight: "4px",
                            marginTop: isSubmenu ? "12px" : "0px",
                            paddingLeft: "8px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            color: isSubmenu ? subColor : fontColor,
                            '&:hover': {
                                backgroundColor: "rgba(0, 0, 0, .04)",
                            },
                            fontSize: "13px",
                            fontWeight: "bolder",
                            height: SIDEBAR_ITEM_HEIGHT + "px",
                            flexDirection: isSubmenu ? "row-reverse" : undefined,
                            minWidth: isSubmenu ? "176px" : undefined,
                        };
                    },
                    subMenuContent: ({ level, active }) => ({
                        backgroundColor: active ? "red" : "transparent",
                        overflow: "hidden !important",
                    }),
                    SubMenuExpandIcon: () => ({
                        lineHeight: 0,
                        width: "22px",
                        height: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "12px"
                    }),
                }}
                className={css({
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    overflow: "hidden",
                    borderRightWidth: "1px",
                    borderRightStyle: "solid",
                    borderRightColor: isDarkTheme ? "#252525" : "#DEDEDE",
                })}
                rootStyles={{
                    "> ul": {
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                    }
                }}
            >
                <HFlex alignCenter style={{
                    paddingTop: sidebarPinned ? "12px" : "8px",
                    paddingBottom: "8px",
                    minHeight: theme.sizing.scale600,
                }}>
                    <SpacerExpand/>
                    <LogoIcon size={12} color={theme.colors.contentPrimary}/>
                    {!collapsed && (
                        <>
                            <Spacer/>
                            <LabelXSmall color={theme.colors.contentPrimary}>{HELLOTRACKS}</LabelXSmall>
                        </>
                    )}
                    <SpacerExpand/>
                </HFlex>
                <Centered style={{ height: "auto" }}>
                    <AccountMenu/>
                </Centered>
                <Spacer/>
                <div style={{ height: "1px", backgroundColor: divColor }}/>
                <VFlex style={{ overflowY: collapsed ? "hidden" : "auto" }}>
                    <Spacer height={"24px"}/>
                    <SidebarItem pageMenuItem={menu.live}/>
                    <SidebarItem ref={dispatchRef} pageMenuItem={menu.dispatch}/>
                    <SidebarItem pageMenuItem={menu.timeline}/>
                    <SidebarItem pageMenuItem={menu.location}/>
                    {sidebarPinned && (
                        <>
                            <SubMenu label={strings.General.NavMenuManage}
                                     open={manageOpen || managePages.includes(page)}
                                     onOpenChange={isOpen => setMenuOpen("manage", isOpen)}
                            >
                                <SidebarItem ref={teamRef} pageMenuItem={menu.team} onClick={membersNextStep}/>
                                <SidebarItem ref={placesRef} pageMenuItem={menu.places} onClick={placesNextStep}/>
                                <SidebarItem pageMenuItem={menu.forms}/>
                                <SidebarItem pageMenuItem={menu.settings}/>
                            </SubMenu>
                            <SubMenu label={strings.General.NavMenuAnalyze}
                                     open={analyzeOpen || analyzePages.includes(page)}
                                     onOpenChange={isOpen => setMenuOpen("analyze", isOpen)}>
                                <AnalyzeMenuItems/>
                            </SubMenu>
                            <Spacer/>
                            <SubMenu label={strings.General.More}
                                     open={moreOpen || morePages.includes(page)}
                                     onOpenChange={isOpen => setMenuOpen("more", isOpen)}>
                                <MoreMenuItems/>
                            </SubMenu>
                        </>
                    )}
                    {!sidebarPinned && (
                        <>
                            <Spacer/>
                            <SubmenuItem
                                title={strings.General.NavMenuManage}
                                isOpen={manageOpen}
                                itemsCount={4}
                                onClick={() => {
                                    setMenuOpen("manage", !manageOpen || managePages.includes(page));
                                }}
                            >
                                <SidebarItem pageMenuItem={menu.team}/>
                                <SidebarItem pageMenuItem={menu.places}/>
                                <SidebarItem pageMenuItem={menu.forms}/>
                                <SidebarItem pageMenuItem={menu.settings}/>
                            </SubmenuItem>
                            <Spacer/>
                            <SubmenuItem
                                title={strings.General.NavMenuAnalyze}
                                isOpen={analyzeOpen}
                                itemsCount={4}
                                onClick={() => {
                                    setMenuOpen("analyze", !analyzeOpen || analyzePages.includes(page));
                                }}
                            >
                                <AnalyzeMenuItems/>
                            </SubmenuItem>
                            <Spacer/>
                            <SubmenuItem
                                title={strings.General.More}
                                isOpen={moreOpen}
                                itemsCount={6}
                                onClick={() => {
                                    setMenuOpen("more", !moreOpen || morePages.includes(page));
                                }}
                            >
                                <MoreMenuItems/>
                            </SubmenuItem>
                        </>
                    )}
                    <Spacer/>
                </VFlex>
                <div style={{ height: "1px", backgroundColor: divColor }}/>
                {!broken && (
                    <Tooltip title={sidebarPinned ? strings.General.UnpinMenu : strings.General.PinMenu}>
                        <Button size={"mini"}
                                kind={"tertiary"}
                                overrides={{
                                    BaseButton: {
                                        style: ({ $theme }) => ({
                                            ':hover': {
                                                backgroundColor: 'transparent',
                                            }
                                        }),
                                    },
                                }}
                                onClick={() => {
                                    setSidebarPinned((prev) => !prev);
                                }}>
                            <PinIcon size={16}
                                     color={fontColor}
                                     style={{
                                         transform: sidebarPinned ? "rotate(45deg)" : "rotate(-45deg)",
                                         transition: "transform 340ms ease-in-out"
                                     }}
                            />
                        </Button>
                    </Tooltip>
                )}
                <Spacer/>
            </Menu>
            <Tour open={onboardMembersIsOpen} steps={memberSteps} current={memberStep} indicatorsRender={indicators}/>
            <Tour open={onboardPlacesIsOpen} steps={placesSteps} current={placeStep} indicatorsRender={indicators}/>
            <Tour open={onboardNotificationsIsOpen} steps={notificationsSteps} current={notificationStep}
                  indicatorsRender={indicators}/>
            <Tour open={onboardDownloadIsOpen} steps={downloadSteps} current={downloadStep}
                  indicatorsRender={indicators}/>
            <Tour open={onboardPermissionsIsOpen} steps={permissionsSteps} current={permissionStep}
                  indicatorsRender={indicators}/>
            <Tour open={onboardTrackingSettingsIsOpen} steps={trackingSettingsSteps} current={trackingSettingsStep}
                  indicatorsRender={indicators}/>
            <Tour open={onboardJobsIsOpen} steps={jobsSteps} current={jobStep} indicatorsRender={indicators}/>
        </ProSidebar>
    );
}

export default memo(Sidebar);