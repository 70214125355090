import { PRIO_HIGH, PRIO_LOW, PRIO_NORMAL } from "../../types/job";
import { useJobInfo } from "../../hooks/jobs/useJobInfo";
import { useStrings } from "../../hooks/useStrings";
import { StyledSelect } from "../ui/StyledSelect";

type Props = {
    priority: number;
    onSelect: (priority?: number) => void;
    size?: "mini" | "compact";
};

export function PrioritySelect({ priority, onSelect, size = "compact" }: Props) {
    const { strings } = useStrings();
    const jobInfo = useJobInfo();
    const option = (prio: number) => {
        return {
            id: prio,
            label: jobInfo.getPriorityText(prio),
        }
    }
    const options = [
        option(PRIO_HIGH),
        option(PRIO_NORMAL),
        option(PRIO_LOW),
    ];
    const value = priority > 0 ? [option(priority)] : [];
    const getLabel = ({ option }: any) => option.label;

    return (
        <StyledSelect
            options={options}
            value={value}
            onChange={params => {
                onSelect(params.option ? params.option.id as number : undefined)
            }}
            getOptionLabel={getLabel}
            getValueLabel={getLabel}
            placeholder={strings.General.Priority}
            multi={false}
            size={size}
        />
    );
}