import { useEffect, useState } from "react";
import { useStrings } from "../../hooks/useStrings";
import { ConfirmModal } from "../ui/ConfirmModal";
import { VFlex } from "../containers/VFlex";
import { Tag } from "antd";
import { HFlex } from "../containers/HFlex";
import { PinCode } from "baseui/pin-code";
import { Spacer } from "../containers/Spacer";

type Props = {
    open: boolean;
    onConfirmed: (confirmed: boolean) => void;
    onClose: () => void;
}

export function PinConfirmation({  open = false, onConfirmed, onClose }: Props) {
    const { strings } = useStrings();
    const [values, setValues] = useState([ "", "", "", "" ]);
    const [pinCode, ] = useState(Math.floor(1000 + Math.random() * 9000).toString().split(""))

    useEffect(() => {
        const isEqual = values.length === pinCode.length
            && values.every((value, index) => value === pinCode[index]);
        if (isEqual) {
            onConfirmed(true);
            onClose();
        }
    }, [values]);

    const handleCancelConfirm = () => {
        onClose();
    };

    return (
        <ConfirmModal
            isOpen={open}
            description={
                <VFlex spacing>
                    <HFlex alignCenter>
                        {pinCode.map((digit) => <Tag>{digit}</Tag>)}
                    </HFlex>
                    <Spacer/>
                    <PinCode
                        values={values}
                        onChange={({ values }) => setValues(values)}
                    />
                </VFlex>
            }
            onCancel={handleCancelConfirm}
            title={strings.General.ConfirmPinToProceed}
            cancelText={strings.General.Cancel}
        />
    )
}