import { LabelLarge } from "baseui/typography";
import { HFlex } from "../containers/HFlex";
import { Card } from "antd";

type Props = {
    icon: React.ReactNode;
    title: string;
    content: string;
    actions?: React.ReactNode;
};

export function IntegrationItem({ icon, title, content, actions }: Props) {
    return (
        <Card title={
            <HFlex alignCenter spacing>
                {icon}
                <LabelLarge>{title}</LabelLarge>
            </HFlex>
        }>
            <div dangerouslySetInnerHTML={{ __html: content }}/>
            {actions}
        </Card>
    );
}
