import { PageType } from "../../types/core";
import { PAGE } from "../../constants/pages";
import { AppointmentIcon, WorldIcon } from "../ui/svg";
import { useNavigator } from "../../hooks/useNavigator";
import { useRecoilValue } from "recoil";
import { pageState } from "../../states/appState";
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";
import { SwapTab } from "../ui/SwapTab";
import React from "react";

export function SwapPage() {
    const page = useRecoilValue(pageState);
    const { theme } = useTheme();
    const { strings } = useStrings();
    const { navigateToPage } = useNavigator();

    return (
        <SwapTab activeKey={page as string}
                 onChange={(activeKey) => {
                     navigateToPage(activeKey as PageType);
                 }}
                 tab1={{
                     key: PAGE.LIVE,
                     label: <WorldIcon color={theme.colors.contentPrimary} size={14}/>,
                     description: strings.Map.LiveMap,
                 }}
                 tab2={{
                     key: PAGE.DISPATCH,
                     label: <AppointmentIcon color={theme.colors.contentPrimary} size={14}/>,
                     description: strings.General.Dispatch,
                 }}
        />
    )
}