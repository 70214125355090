import { DraggableProvided } from "react-beautiful-dnd";
import DraggableJobItem from "../DraggableJobItem";

type Props = {
    provided: DraggableProvided;
    jobId: string;
    style?: React.CSSProperties;
    isDragging?: boolean;
};

export function PinnedJobItem({ provided, jobId, style = {}, isDragging = false }: Props) {
    return (
        <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={{
                ...provided.draggableProps.style,
                ...style,
                height: isDragging ? "auto" : style.height,
            }}
        >
            <DraggableJobItem id={jobId} isDragging={isDragging}/>
        </div>
    );
}