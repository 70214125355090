import { useStrings } from "../hooks/useStrings";
import { useRecoilState } from "recoil";
import { alertsViewActiveTab } from "../states/viewState";
import { Tab, Tabs } from "baseui/tabs-motion";
import { tabOverrides, tabsOverrides } from "../constants/ui";
import { RecentAlerts } from "../components/alerts/RecentAlerts";
import { AlertList } from "../components/alerts/AlertList";

type Keys = "config" | "recent";

export function AlertsView() {
    const { strings } = useStrings();
    const [activeKey, setActiveKey] = useRecoilState(alertsViewActiveTab);

    return (
        <Tabs
            activeKey={activeKey}
            onChange={({ activeKey }) => {
                setActiveKey(activeKey as Keys);
            }}
            overrides={tabsOverrides}
        >
            <Tab key={"config"} title={strings.Alerts.ConfigureAlerts} overrides={tabOverrides}>
                <AlertList/>
            </Tab>
            <Tab key={"recent"} title={strings.Alerts.RecentAlerts} overrides={tabOverrides}>
                <RecentAlerts/>
            </Tab>
        </Tabs>
    );
}