import { useStyletron } from "baseui";
import { LabelSmall } from "baseui/typography";
import { memo, ReactNode } from "react";
import { useStrings } from "../../hooks/useStrings";
import { HFlex } from "../containers/HFlex";
import { Spacer } from "../containers/Spacer";
import { TeamBadge } from "./TeamBadge";

export const TeamsBadges = memo(({
                                ids,
                                max = 4,
                                children = null,
                                inline = false,
                                shortNames = false,
                            }: { ids: number[], max?: number, children?: ReactNode, inline?: boolean, shortNames?: boolean }) => {
    const { strings } = useStrings();
    const maxSelectedVisible = max;
    const [, theme] = useStyletron();
    const selectedCount = ids.length;

    if (ids.length === 0) {
        return null;
    }

    return (
        <HFlex alignCenter style={{
            flexWrap: inline ? 'nowrap' : 'wrap',
            minHeight: theme.sizing.scale850,
            rowGap: theme.sizing.scale200,
        }}>
            {ids.slice(0, maxSelectedVisible).map((id, index) => (
                <TeamBadge id={id} key={index} shortName={shortNames}/>
            ))}
            {selectedCount > maxSelectedVisible && (
                <LabelSmall alignSelf="center" color={theme.colors.contentTertiary} whiteSpace="nowrap">
                    {`${selectedCount - maxSelectedVisible} ${strings.General.More.toLowerCase()}...`}
                </LabelSmall>
            )}
            {children && (
                <>
                    <Spacer/>
                    {children}
                </>
            )}
        </HFlex>
    );
});