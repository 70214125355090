import { HeadingXSmall } from "baseui/typography";
import { useRecoilValue } from "recoil";
import { useStrings } from "../../hooks/useStrings";
import { recentSubmissionFormsSortedState } from "../../states/formsState";
import { UnorderedList } from "../containers/UnorderedList";
import { VFlex } from "../containers/VFlex";
import { NewFormSubmissionItem } from "./NewFormSubmissionItem";

export function NewFormRecentSubmissionList() {
    const { strings } = useStrings();
    const recentSubmissionForms = useRecoilValue(recentSubmissionFormsSortedState);

    if (!recentSubmissionForms.length) {
        return null;
    }

    return (
        <VFlex spacing style={{ overflow: "hidden", width: "100%", maxHeight: "230px", height: "auto" }}>
            <HeadingXSmall margin={0}>
                {strings.General.RecentSubmissions}
            </HeadingXSmall>
            <UnorderedList>
                {recentSubmissionForms.map((f, index) => (
                    <NewFormSubmissionItem key={f.submissionUid ?? index} formTemplate={f} withTime/>
                ))}
            </UnorderedList>
        </VFlex>
    );
}
