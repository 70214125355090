import { atom, selector } from "recoil";
import { AlertConfig } from "../types/alerts";
import { getDiffFields } from "../utils/objects";
import { selectedAlertConfigState } from "./alertsState";


const editAlertInitializer = selector<AlertConfig | undefined>({
    key: 'edit.alert.initializer',
    get: ({ get }) => get(selectedAlertConfigState),
})

export const editAlertState = atom<AlertConfig | undefined>({
    key: 'edit.alert',
    default: editAlertInitializer,
});

export const editAlertValidState = selector<{ [key: string]: boolean }>({
    key: 'edit.alert.valid',
    get: ({ get }) => {
        const fields = get(editAlertState);
        if (!fields) {
            return {};
        }
        const validFields = Object.fromEntries(Object.keys(fields).map((key) => [key, true]));

        // Required fields
        if (!fields.title) {
            validFields['title'] = false;
        }

        return validFields;
    }
});

export const editAlertErrorState = selector<boolean>({
    key: 'edit.alert.error',
    get: ({ get }) => {
        const validFields = get(editAlertValidState);

        return Object.values(validFields).some((valid) => !valid)
    }
});

export const editAlertModifiedState = selector<{ [key: string]: any }>({
    key: 'edit.alert.modified',
    get: ({ get }) => {
        const alert = get(selectedAlertConfigState);
        const alertFields = get(editAlertState);
        if (!alert || !alertFields) {
            return {}
        }
        return getDiffFields(alert, alertFields);
    }
});

export const editAlertHasModifiedState = selector({
    key: 'edit.alert.modified.count',
    get: ({ get }) => {
        const alert = get(selectedAlertConfigState);
        const alertFields = get(editAlertState);
        if (!alert || !alertFields) {
            return {}
        }
        const alertFieldsChanged = Object.keys(alert).length !== Object.keys(alertFields).length;
        return !!Object.keys(get(editAlertModifiedState)).length || alertFieldsChanged;
    }
});