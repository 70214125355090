import { VFlex } from "../components/containers/VFlex";
import { MemberSearch } from "../components/member/MemberSearch";
import { MemberFilter } from "../components/member/MemberFilter";
import { HFlex } from "../components/containers/HFlex";
import React from "react";
import MemberList from "../components/member/MemberList";
import { Spacer, SpacerExpand } from "../components/containers/Spacer";
import { useRecoilState, useRecoilValue } from "recoil";
import { pageState } from "../states/appState";
import { PAGE } from "../constants/pages";
import { useTheme } from "../hooks/useTheme";
import { Clickable } from "../components/containers/Clickable";
import { ChevronDown, ChevronUp } from "baseui/icon";
import { cardSizesState } from "../states/skeletonState";

export function MemberView() {
    const page = useRecoilValue(pageState);
    const { theme } = useTheme();
    const [cardSizes, setCardSizes] = useRecoilState(cardSizesState(PAGE.LIVE));

    const onToggleExpand = () => {
        setCardSizes({ ...cardSizes, smallHeightExpanded: !cardSizes.smallHeightExpanded });
    };

    return (
        <VFlex>
            <Spacer/>
            <HFlex alignCenter>
                <SpacerExpand/>
                <MemberSearch style={{ height: "22px" }}/>
                <MemberFilter/>
            </HFlex>
            <MemberList/>

            {page === PAGE.LIVE && (
                <div style={{ position: 'relative', width: '100%', height: 0 }}>
                    <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
                        <Clickable onClick={onToggleExpand}>
                            {cardSizes.smallHeightExpanded
                                ? <ChevronUp size={16} color={theme.colors.contentPrimary}/>
                                : <ChevronDown size={16} color={theme.colors.contentPrimary}/>}
                        </Clickable>
                    </div>
                </div>
            )}
        </VFlex>
    );
}