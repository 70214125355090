import { deleteZone, saveZone } from '../../services/api';
import { updateZonesLayer } from "../../services/geojson";
import { useZoneSelection } from "./useZoneSelection";
import { allZonesState } from "../../states/zoneState";
import { useRecoilValue } from "recoil";
import { Zone } from "../../types/zone";
import { LonLat, Uid } from "../../types/core";

export function useEditZones() {

    const selection = useZoneSelection();
    const zones = useRecoilValue(allZonesState);

    const editZones = async (uids: Uid[], data: {}) => {
        for (const uid of uids) {
            const zone = zones.find(z => z.uid === uid);
            if (zone) {
                await saveZone({ ...zone, ...data });
            }
        }
        updateZonesLayer();
    };

    const deleteZones = async (uids: Uid[]) => {
        for (const uid of uids) {
            const zone = zones.find(z => z.uid === uid);
            if (zone) {
                await deleteZone(zone);
            }
        }
        updateZonesLayer();
        selection.clearSelection();
    };

    const save = async (name: string, points: LonLat[]) => {
        const { status } = await saveZone({
            name: name,
            color: "#000000",
            assignedMembers: [] as string[],
            assignedTeams: [] as number[],
            points: points,
        } as Zone);
        if (status) {
            updateZonesLayer();
        }
        return status;
    }

    const editZone = async (data: Zone) => {
        if (data.uid) {
            await saveZone(data);
            updateZonesLayer();
        }
    };

    return { editZones, deleteZones, save, editZone };
}