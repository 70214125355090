import { Tag, Tooltip } from "antd";
import { memo, useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { tinyTag } from "../../constants/ui";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { editingJobIdState } from "../../states/appState";
import { jobIdsPinnedState, jobState } from "../../states/jobsState";
import { getTitle, isRecentlyUpdated } from "../../types/job";
import { numberOrLetter } from "../../utils/jobUtils";
import { EllipsedText } from "../ui/EllipsedText";
import { DragHandleIcon, FlagColoredIcon, PinFilledIcon, PinIcon } from "../ui/svg";
import { HFlex } from "../containers/HFlex";
import { Spacer } from "../containers/Spacer";
import { useHover } from "usehooks-ts";
import { Button } from "baseui/button";

function TableJobItem({ id }: { id: string }) {
    const job = useRecoilValue(jobState(id));
    const setEditingJob = useSetRecoilState(editingJobIdState);
    const { strings } = useStrings();
    const { theme } = useTheme();
    const hoverRef = useRef(null);
    const isHovering = useHover(hoverRef);

    const [pinned, setPinned] = useRecoilState(jobIdsPinnedState);
    const isPinned = pinned.includes(id);

    const onPinClick = () => {
        setPinned(old => isPinned
            ? [...old.filter(pinnedId => pinnedId !== id)]
            : [...old, id]
        )
    }

    if (!job) {
        return null;
    }

    return (
        <HFlex
            ref={hoverRef}
            alignCenter
            onClick={() => setEditingJob(job.id)}
            style={{
                textAlign: "start",
                width: "auto",
                ...theme.typography.LabelXSmall,
            }}
        >
            {!job.isTemplate &&
                <DragHandleIcon size={14} color={theme.colors.contentSecondary} style={{ marginRight: "4px" }}/>}
            {job.number > 0 && job.receiverUid && job.day > 0
                && <Tag style={{
                    width: "36px",
                    flexShrink: 0,
                    fontSize: job.number < 100 ? "11px" : "8px",
                    textAlign: "center",
                    lineHeight: "14px"
                }}>
                    {numberOrLetter(job)}
                </Tag>}
            <EllipsedText maxLines={1}>{getTitle(job)}</EllipsedText>
            {!job.isTemplate && (isHovering || isPinned) && (
                <Tooltip title={isPinned ? strings.Dispatch.Unpin : strings.Dispatch.PinJob}>
                    <Button size={"mini"} kind={"tertiary"} onClick={(event) => {
                        event.stopPropagation();
                        onPinClick();
                    }}>
                        {isPinned
                            ? <PinFilledIcon size={12} color={theme.colors.contentPrimary}/>
                            : <PinIcon size={12} color={theme.colors.contentPrimary}/>}
                    </Button>
                </Tooltip>
            )}
            <Spacer width={"16px"}/>
            {isRecentlyUpdated(job) && (
                <Tooltip title={strings.General.RecentlyUpdated}>
                    <Tag
                        style={{
                            ...tinyTag,
                            marginLeft: "8px",
                            paddingLeft: "4px",
                            paddingRight: "4px",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                        }}
                    >
                        <FlagColoredIcon size={12}/>
                    </Tag>
                </Tooltip>
            )}
        </HFlex>
    );
}

export default memo(TableJobItem);
