import { atomWithPersistence } from "../utils/persistence";
import { atom } from "recoil";
import { ReactNode } from "react";
import { AssistantFulfillment } from "../types/assistant";

export const assistantQueryHistoryState = atomWithPersistence<string[]>("assistant.query.history", []);

export const assistantInputState = atom({
    key: "assistant.input",
    default: "",
});

export const assistantQueryingState = atom({
    key: "assistant.querying",
    default: false,
});

export const assistantOpenState = atom({
    key: "assistant.open",
    default: false,
});

export const assistantPlainTextResponseState = atom({
    key: "assistant.response.plaintext",
    default: "",
});

export const assistantFulfillmentsState = atom({
    key: "assistant.fulfillments",
    default: [] as AssistantFulfillment[],
});

export const assistantChipsState = atom({
    key: "assistant.chips",
    default: [] as ReactNode[],
});
