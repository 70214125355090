import { useRecoilValue } from "recoil";
import {
    onboardAddingUsersState,
    onboardDownloadState,
    onboardJobsState,
    onboardPlacesState,
    onboardStatusState
} from "../../states/onboardState";
import { OnboardStep } from "../../types/onboard";
import { useOnboardDownload } from "./useOnboardDownload";
import { useOnboardJobs } from "./useOnboardJobs";
import { useOnboardMembers } from "./useOnboardMembers";
import { useOnboardNotifications } from "./useOnboardNotifications";
import { useOnboardPermissions } from "./useOnboardPermissions";
import { useOnboardPlaces } from "./useOnboardPlaces";
import { useOnboardTrackingSettings } from "./useOnboardTrackingSettings";
import { useStrings } from "../useStrings";
import {
    ONBOARD_ADDING_USERS,
    ONBOARD_CHECK_IN_NOTIFICATIONS,
    ONBOARD_COMPANY_SETUP,
    ONBOARD_CREATING_PLACES,
    ONBOARD_DOWNLOAD_APP,
    ONBOARD_JOBS,
    ONBOARD_SIGN_UP,
    ONBOARD_USERS_PERMISSIONS
} from "../../constants/onboard";

export function useOnboardSteps() {
    const { strings } = useStrings();
    const { initOnboardMembers } = useOnboardMembers();
    const { initOnboardPlaces } = useOnboardPlaces();
    const { initOnboardNotifications } = useOnboardNotifications();
    const { initOnboardDownload } = useOnboardDownload();
    const { initOnboardPermissions } = useOnboardPermissions();
    const { initOnboardTrackingSettings } = useOnboardTrackingSettings();
    const { initOnboardJobs } = useOnboardJobs();
    const onboardStatus = useRecoilValue(onboardStatusState);
    const onboardJobsStatus = useRecoilValue(onboardJobsState);
    const onboardAddingUsersStatus = useRecoilValue(onboardAddingUsersState);
    const onboardPlacesStatus = useRecoilValue(onboardPlacesState);
    const onboardDownloadStatus = useRecoilValue(onboardDownloadState);

    const onboardSteps: OnboardStep[] = [
        {
            id: ONBOARD_SIGN_UP,
            nr: 1,
            checked: onboardStatus.signup!,
            evaluate: () => false,
            onClick: () => {},
            title: strings.Onboard.Signup
        },
        {
            id: ONBOARD_COMPANY_SETUP,
            nr: 2,
            checked: onboardStatus.companySetup!,
            evaluate: () => false,
            onClick: () => {},
            title: strings.Onboard.CreateCompany
        },
        {
            id: ONBOARD_DOWNLOAD_APP,
            nr: 3,
            checked: onboardDownloadStatus,
            evaluate: () => false,
            onClick: initOnboardDownload,
            title: strings.Onboard.AppLogin
        },
        {
            id: ONBOARD_ADDING_USERS,
            nr: 4,
            checked: onboardAddingUsersStatus,
            evaluate: () => false,
            onClick: initOnboardMembers,
            title: strings.Onboard.Invite
        },
        {
            id: ONBOARD_USERS_PERMISSIONS,
            nr: 5,
            checked: onboardStatus.usersPermissions!,
            evaluate: () => false,
            onClick: initOnboardPermissions,
            title: strings.Onboard.Permissions
        },
        {
            id: ONBOARD_CREATING_PLACES,
            nr: 6,
            checked: onboardPlacesStatus,
            evaluate: () => false,
            onClick: initOnboardPlaces,
            title: strings.Onboard.Place
        },
        {
            id: ONBOARD_CHECK_IN_NOTIFICATIONS,
            nr: 7,
            checked: onboardStatus.checkInNotifications!,
            evaluate: () => false,
            onClick: initOnboardNotifications,
            title: strings.Onboard.Checkin
        },
        // {
        //     id: ONBOARD_TRACKING_SETTINGS,
        //     nr: 8,
        //     checked: onboardStatus.trackingSettings!,
        //     evaluate: () => false,
        //     onClick: initOnboardTrackingSettings,
        //     title: strings.Onboard.TrackingSettings
        // },
        {
            id: ONBOARD_JOBS,
            nr: 8,
            checked: onboardJobsStatus,
            evaluate: () => false,
            onClick: initOnboardJobs,
            title: strings.Onboard.Jobs
        },
    ];

    return {
        onboardSteps,
    };
}
