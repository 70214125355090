import { useRecoilValue, useSetRecoilState } from "recoil";

import * as React from 'react';
import { memo, useState } from 'react';
import { editingJobIdState, editingMemberIdState, editingPlaceIdState } from "../../states/appState";
import { useCamera } from "../../hooks/useCamera";
import { Option, UniversalPicker } from "../pickers/UniversalPicker";
import { useStrings } from "../../hooks/useStrings";
import { windowIsMobileState } from "../../states/skeletonState";
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { memberState } from "../../states/membersState";

export const GlobalSearch = memo(({ isHover, opacity = 1 }: { isHover: boolean, opacity?: number }) => {
    const { strings } = useStrings();
    const setEditingJob = useSetRecoilState(editingJobIdState);
    const setEditingMember = useSetRecoilState(editingMemberIdState);
    const setEditingPlace = useSetRecoilState(editingPlaceIdState);
    const camera = useCamera();
    const isMobile = useRecoilValue(windowIsMobileState);
    const [isOpen, setOpen] = useState(false);

    const onOptionSelected = (option: Option) => {
        if (option.job) {
            camera.fitToJobs([option.job]);
            setEditingJob(option.job.id);
        } else if (option.memberUid) {
            const member = getRecoil(memberState(option.memberUid));
            if (member) {
                camera.flyTo(member.location);
            }
            setEditingMember(option.memberUid);
        } else if (option.place) {
            camera.flyTo(option.place.location);
            setEditingPlace(option.place.uid);
        } else if (option.location) {
            camera.zoomWithTempMarker(option.location, option.label);
        }
    };

    const maxPx = isHover || isOpen ? isMobile ? "60px" : "100px" : "48px";

    return (
        <UniversalPicker
            placeholder={strings.General.GlobalSearchPlaceholder}
            onOptionSelected={onOptionSelected}
            width={`max(48px, ${maxPx})`}
            centeredOptions={false}
            growOnFocus
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            opacity={opacity}
        />
    );
});