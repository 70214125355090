import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { editAccount } from '../../services/api';
import { companyState } from '../../states/accountState';
import {
    editCompanyErrorState,
    editCompanyHasModifiedState,
    editCompanyModifiedState
} from '../../states/editCompanyState';
import { Company } from '../../types/company';
import { Language } from '../../types/core';
import useAccount from '../useAccount';
import useDirector from '../useDirector';
import { useModal } from '../useModal';


export function useCompanyForms() {
    const [company, setCompany] = useRecoilState(companyState);
    const [isLoading, setIsLoading] = useState(false);
    const { closeModal } = useModal();
    const companyHasAnyError = useRecoilValue(editCompanyErrorState);
    const companyModifiedFields = useRecoilValue(editCompanyModifiedState);
    const companyHasModifiedFields = useRecoilValue(editCompanyHasModifiedState);
    const { isSelfAccount } = useAccount();
    const { setCurrentLanguage, performPoll } = useDirector();

    const canSubmit = !companyHasAnyError;

    const onSubmit = async () => {
        if (!company) {
            return;
        }
        setIsLoading(true);

        let data: { [key: string]: any } = {};

        if (companyHasModifiedFields) {
            data = { ...companyModifiedFields };

            const { status, account: updatedCompany } = await editAccount({ uid: company.uid, ...data });
            if (status) {
                setCompany(updatedCompany as Company);
                if (updatedCompany && "language" in data && isSelfAccount(updatedCompany.uid)) {
                    setCurrentLanguage(updatedCompany.language as Language)
                }
                performPoll();
            }
        }

        setIsLoading(false);
        closeModal();
    };

    return {
        canSubmit,
        isLoading,
        onSubmit,
        hasChanges: companyHasModifiedFields
    };
}