import { Alert, Modal, Popconfirm, Spin } from "antd";
import { Button } from "baseui/button";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useEffectOnce, useUpdateEffect } from "usehooks-ts";
import { HFlex } from "../components/containers/HFlex";
import { Spacer, SpacerExpand } from "../components/containers/Spacer";
import { VFlex } from "../components/containers/VFlex";
import { NewFormSubmissionContent } from "../components/forms/NewFormSubmissionContent";
import { NewFormSubmissionList } from "../components/forms/NewFormSubmissionList";
import { SimplePageLayout } from "../components/shared/SimplePageLayout";
import { EmptyState } from "../components/ui/EmptyState";
import { CheckCircledIcon, RemoveIcon } from "../components/ui/svg";
import { useRedirect } from "../hooks/useRedirect";
import { useStrings } from "../hooks/useStrings";
import { useTheme } from "../hooks/useTheme";
import { useTime } from "../hooks/useTime";
import { loadFormSubmissions, loadFormTemplates, submitForm } from "../services/api";
import {
    formSubmitIsValidState,
    formSubmitState,
    formTemplatesState,
    recentSubmissionFormsState,
    recentSubmissionFormUidsState,
    selectedFormSubmitState
} from "../states/formsState";
import { FormContent, FormTemplate } from "../types/form";
import { flushSync } from "react-dom";
import { NewFormRecentSubmissionList } from "../components/forms/NewFormRecentSubmissionList";
import { useForms } from "../hooks/useForms";
import { Centered } from "../components/containers/Centered";
import { isTestEnvironment } from "../utils/app";
import { Timestamp } from "../types/core";

export default function NewFormSubmissionView() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const [selectedForm, setSelectedForm] = useRecoilState(selectedFormSubmitState);
    const [form, setForm] = useRecoilState(formSubmitState(selectedForm?.id ?? selectedForm?.submissionUid ?? ""));
    const formIsValid = useRecoilValue(formSubmitIsValidState(selectedForm?.id ?? selectedForm?.submissionUid ?? ""));
    const [sending, setSending] = useState(false);
    const [succeed, setSucceed] = useState<boolean | undefined>();
    const setFormTemplates = useSetRecoilState(formTemplatesState);
    const setRecentSubmissionForms = useSetRecoilState(recentSubmissionFormsState);
    const [recentSubmissionFormIds, setRecentSubmissionFormIds] = useRecoilState(recentSubmissionFormUidsState);
    const { timeMinusHours } = useTime()
    const [formTemplatesCopy, setFormTemplatesCopy] = useState<FormTemplate[]>([])
    const forms = useForms();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!form) {
            setForm(selectedForm);
        }
    }, [selectedForm]);

    // This is to prevent the access to other pages that should not be allowed
    useRedirect();

    useEffectOnce(() => {
        setLoading(true);
        loadFormTemplates().then(({ status, formtemplates }) => {
            if (status) {
                flushSync(() => {
                    setFormTemplatesCopy(formtemplates);
                });
                if (recentSubmissionFormIds.length) {
                    setRecentSubmissionFormIds((prev) =>
                        prev.filter((formId) =>
                            formId.timestamp > timeMinusHours(Date.now() as Timestamp, 1)));
                } else {
                    setFormTemplates(formtemplates)
                    setRecentSubmissionForms([])
                    setLoading(false);
                }
            }
        });
    });

    useUpdateEffect(() => {
        const getRecentSubmissionForms = () => {
            const submissionIds = recentSubmissionFormIds.map((formId) => formId.uid);
            return loadFormSubmissions({ submissionIds }).then(({ status, formsubmissions }) => {
                if (status) {
                    return formsubmissions;
                }
                return []
            });
        }

        const setRecentSubmissionDefaults = () => {
            setFormTemplates(formTemplatesCopy);
            setRecentSubmissionForms([])
            setLoading(false);
        }

        if (recentSubmissionFormIds.length) {
            getRecentSubmissionForms().then((recentSubmissionForms) => {
                setFormTemplates(formTemplatesCopy);
                setRecentSubmissionForms(recentSubmissionForms);
                setLoading(false);
            }).catch(() => {
                setRecentSubmissionDefaults();
            });
        } else {
            setRecentSubmissionDefaults();
        }
    }, [recentSubmissionFormIds]);

    const onCancel = () => {
        setForm(undefined);
        setSelectedForm(undefined);
        closeModal();
    };

    const onSubmitClick = async () => {
        if (form?.content) {
            setSending(true);
            const { status, submissionUid } = await submitForm({
                ...form.content,
                timestamp: Date.now()
            } as FormContent);
            if (status) {
                setForm(selectedForm);
                setRecentSubmissionFormIds((prev) =>
                    [...prev, {uid: submissionUid, timestamp: Date.now() as Timestamp}])
            }
            setSending(false);
            setSucceed(status);
        }
    };

    const closeModal = () => {
        setSucceed(undefined);
    };

    const onItemValueChange = (itemIndex: number, value: string|number) => {
        if (!form) return;
        setForm({ ...form, content: forms.setItemValue(itemIndex, value, form.content) });
    }

    const onItemTouchedChange = (itemIndex: number) => {
        if (!form) return;
        setForm({ ...form, content: forms.setItemTouched(itemIndex, form.content) });
    }

    const onConditionalItemValueChange = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, value: string|number) => {
        if (!form) return;
        setForm({
            ...form,
            content: forms.setConditionalItemValue(itemIndex, conditionalIndex, conditionalItemIndex, form.content, value),
        });
    }
    
    const onConditionalItemTouchedChange = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number) => {
        if (!form) return;
        setForm({
            ...form,
            content: forms.setConditionalItemTouched(itemIndex, conditionalIndex, conditionalItemIndex, form.content),
        });
    }

    return (
        <SimplePageLayout>
            {!form && loading && (
                <Centered>
                    <Spin/>
                </Centered>
            )}
            {!form && !loading && (
                <VFlex spacing>
                    {isTestEnvironment() && <NewFormRecentSubmissionList/>}
                    <NewFormSubmissionList/>
                </VFlex>
            )}
            {form && (
                <VFlex
                    style={{
                        flex: 1,
                        boxSizing: "border-box",
                        maxWidth: "700px",
                        overflow: "auto",
                    }}
                >
                    <VFlex style={{ flex: 1, overflow: "auto" }}>
                        <HFlex>
                            <Alert
                                message={strings.Forms.FormSubmissionSavedLocally}
                                type="info"
                                showIcon
                                closable
                            />
                        </HFlex>
                        <NewFormSubmissionContent
                            form={form}
                            onChange={onItemValueChange}
                            onTouched={onItemTouchedChange}
                            onChangeConditionalItem={onConditionalItemValueChange}
                            onTouchedConditionalItem={onConditionalItemTouchedChange}
                        />
                        <Spacer height="32px"/>
                    </VFlex>
                    <div style={{
                        width: "100%",
                        borderTop: "1px solid" + theme.colors.borderOpaque,
                        height: "1px",
                        marginBottom: theme.sizing.scale300
                    }}/>
                    <HFlex spacing padding style={{ boxSizing: "border-box" }}>
                        <Popconfirm title={strings.General.ConfirmToProceed} onConfirm={onCancel}
                                    cancelText={strings.General.No} okType="danger"
                                    okText={strings.General.Confirm}>
                            <Button kind="tertiary" size="compact">
                                {strings.General.Cancel}
                            </Button>
                        </Popconfirm>
                        <SpacerExpand/>
                        <Button
                            size={"compact"}
                            onClick={onSubmitClick}
                            disabled={selectedForm === form || !formIsValid}
                            isLoading={sending}
                        >
                            {strings.General.Submit}
                        </Button>
                    </HFlex>
                </VFlex>
            )}
            <Modal open={succeed !== undefined} footer={null} closable={false}>
                {!succeed && (
                    <EmptyState
                        title={strings.General.SomethingWentWrong}
                        subtitle={strings.General.CheckInternetConnection}
                        cta={
                            <HFlex spacing style={{ justifyContent: "center" }}>
                                <Button size="compact" kind="primary" onClick={closeModal}>
                                    {strings.General.Accept}
                                </Button>
                            </HFlex>
                        }
                    >
                        <RemoveIcon color={theme.colors.negative} size={40}/>
                    </EmptyState>
                )}
                {succeed && (
                    <EmptyState
                        title={strings.General.Success}
                        subtitle={strings.Forms.FormSubmissionSucceed}
                        cta={
                            <HFlex spacing style={{ justifyContent: "center" }}>
                                <Button size="compact" kind="secondary" onClick={onCancel}>
                                    {strings.Forms.BackToForms}
                                </Button>
                                <Button size="compact" kind="primary" onClick={closeModal}>
                                    {strings.Forms.NewFormSubmission}
                                </Button>
                            </HFlex>
                        }
                    >
                        <CheckCircledIcon color={theme.customColors.primary} size={36}/>
                    </EmptyState>
                )}
            </Modal>
        </SimplePageLayout>
    );
}
