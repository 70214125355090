import { JSONbigint } from "../constants/app";

export interface LinkedZone {
    id: BigInt;
    title: string;
}

export interface LinkedForm {
    id: string;
    title: string;
}

export function getLinkedZones(linkedZones: string): LinkedZone[] {
    return linkedZones ? JSONbigint.parse(linkedZones) as LinkedZone[] : [];
}

export function getLinkedForms(linkedForms: string): LinkedForm[] {
    return linkedForms ? JSON.parse(linkedForms) as LinkedForm[] : [];
}

export function getLinkedZonesString(linkedZones: LinkedZone[] | undefined, emptyValue = "") {
    if (linkedZones && linkedZones.length > 0) {
        return JSONbigint.stringify(linkedZones);
    }
    return emptyValue;
}