import { OnChangeParams } from "baseui/select";
import { useRecoilValue } from "recoil";
import { memberNamesState } from "../../states/membersState";
import { MemberItemSimplified } from "../member/MemberItemSimplified";
import { Member } from "../../types/member";
import { stringsState } from "../../states/localeState";
import { StyledSelect } from "../ui/StyledSelect";

type Props = {
    placeholder: string;
    onMemberSelect: Function;
    selectedMember?: Member | '';
    avatar?: boolean;
    size?: "mini" | "compact";
    hideUnassigned?: boolean;
};

export function MemberSelect({
                                 placeholder,
                                 onMemberSelect,
                                 selectedMember,
                                 avatar = true,
                                 size = "compact",
                                 hideUnassigned = false
                             }: Props) {
    const memberNames = useRecoilValue(memberNamesState);
    const strings = useRecoilValue(stringsState);
    const unassignedOption = { label: strings.JobStatuses.Unassigned, id: '' }
    const value = selectedMember
        ? [{ label: selectedMember.name, id: selectedMember.id, member: selectedMember }]
        : selectedMember === ''
            ? [unassignedOption]
            : [];

    const options = memberNames.map(member => {
        return { label: member.name, id: member.uid, member: member }
    });

    const handleMemberSelect = (params: OnChangeParams) => {
        if (!params.option) {
            onMemberSelect(undefined);
        } else if (params.option?.member) {
            onMemberSelect(params.option.member);
        } else if (params.option) {
            onMemberSelect('');
        }
    }

    const getLabel = ({ option }: any) => {
        if (!option.member) {
            return option.label;
        }
        return avatar ? <MemberItemSimplified uid={option.member.uid}/> : option.member.name;
    };

    const selectOptions = hideUnassigned ? options : [unassignedOption, ...options];

    return (
        <StyledSelect
            options={selectOptions}
            value={value}
            onChange={handleMemberSelect}
            getOptionLabel={getLabel}
            getValueLabel={getLabel}
            placeholder={placeholder}
            multi={false}
            size={size}
        />
    );
}