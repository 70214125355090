import { Collapse, List } from "antd";
import { useRecoilState } from "recoil";
import { assistantQueryHistoryState } from "../../states/assistantState";
import { VFlex } from "../containers/VFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { HFlex } from "../containers/HFlex";
import { Button } from "baseui/button";
import { RemoveIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { Clickable } from "../containers/Clickable";
import { useStrings } from "../../hooks/useStrings";

const { Panel } = Collapse;

export function AssistantHistory({ onClick }: { onClick: (query: string) => void }) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const [history, setHistory] = useRecoilState(assistantQueryHistoryState);

    if (history.length === 0) {
        return null;
    }

    return (
        <VFlex>
            <Collapse
                bordered={false}
                collapsible={"header"}
                size={"small"}
                defaultActiveKey={"1"}
            >
                <Panel key={"1"} header={strings.Assistant.History}>
                    <List
                        size={"small"}
                        bordered={false}
                        dataSource={history}
                        renderItem={(item) => (
                            <List.Item>
                                <HFlex alignCenter spacing>
                                    <Clickable onClick={() => onClick(item)}>
                                        {item}
                                    </Clickable>
                                    <SpacerExpand/>
                                    <Button size={"mini"}
                                            kind={"tertiary"}
                                            onClick={() => setHistory(prev => [...prev.filter(q => q !== item)])}
                                    >
                                        <RemoveIcon size={14} color={theme.colors.contentPrimary}/>
                                    </Button>
                                </HFlex>
                            </List.Item>
                        )}
                    />
                </Panel>
            </Collapse>
            <Spacer/>
        </VFlex>
    );
}