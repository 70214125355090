import { StatefulDatepicker } from "baseui/datepicker";
import { Input } from "baseui/input";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import React from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { MAX_JOBS } from "../../constants/jobs";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import {
    allJobsFiltersState,
    allJobsFromUntilDaysState,
    allJobsFromUntilRangeState,
    allJobsState,
    CUSTOM_DATES_KEY,
    LAST_30_DAYS_KEY,
    LAST_6_MONTS_KEY,
} from "../../states/allJobsState";
import { Filter, Team } from "../../types/core";
import {
    getLabels,
    isAssigned,
    isHighPriority,
    isLowPriority,
    isRecentlyUpdated,
    Job,
    PRIO_NORMAL
} from "../../types/job";
import { dateToDayBrowserTimezone, dayOfToday } from "../../utils/time";
import { HFlex } from "../containers/HFlex";
import { VFlex } from "../containers/VFlex";
import { FilterFilledIcon, FilterIcon, InfoCircledIcon } from "../ui/svg";
import { MemberSelect } from "../pickers/MemberSelect";
import { JobProgressStatusSelect } from "../pickers/JobProgressStatusSelect";
import { PrioritySelect } from "../pickers/PrioritySelect";
import { TeamSelect } from "../pickers/TeamSelect";
import { Member } from "../../types/member";
import { useJobInfo } from "../../hooks/jobs/useJobInfo";
import { getProgressStatus } from "../../utils/jobUtils";
import { LabelXSmall } from "baseui/typography";
import { NoWrapText } from "../containers/NoWrapText";
import { MenuContainer } from "../menu/MenuContainer";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { Tag, Tooltip } from "antd";
import { SpacerExpand } from "../containers/Spacer";
import { tinyTag } from "../../constants/ui";
import { ButtonGroup } from "baseui/button-group";
import { Button } from "baseui/button";
import { jobIdsPinnedState } from "../../states/jobsState";

export function AllJobsFilterRow() {
    const { strings, format } = useStrings();
    const [filters, setFilters] = useRecoilState(allJobsFiltersState);
    const today = dayOfToday();
    const { theme } = useTheme();
    const setDays = useSetRecoilState(allJobsFromUntilDaysState);
    const resetDays = useResetRecoilState(allJobsFromUntilDaysState);
    const jobInfo = useJobInfo();
    const selectedMember = filters.find((f) => f.id === "assignee")?.value as Member | "" | undefined;
    const selectedProgress = filters.find((f) => f.id === "status")?.value as number | undefined;
    const selectedPriority = filters.find((f) => f.id === "priority")?.value as number | undefined;
    const selectedTeam = filters.find((f) => f.id === "team")?.value as Team | undefined;
    const filterNotes = filters.find((f) => f.id === "notes")?.value as string | undefined;
    const filterLabels = filters.find((f) => f.id === "labels")?.value as string | undefined;
    const isMoreFiltering = filters.some(f => ["assignee", "status", "priority", "team", "notes"].includes(f.id));
    const jobs = useRecoilValue(allJobsState);
    const [selectedRange, setSelectedRange] = useRecoilState(allJobsFromUntilRangeState);
    const isCustomRange = selectedRange === CUSTOM_DATES_KEY;
    const pinned = useRecoilValue(jobIdsPinnedState);

    const noDateFilter: Filter = {
        id: "nodate",
        label: strings.General.NoAssignedDate, matches: (job: Job) => job.day === 0
    };

    const updatedFilter: Filter = {
        id: "updated",
        label: strings.General.RecentlyUpdated,
        matches: (job: Job) => isRecentlyUpdated(job),
    };

    const pinnedFilter: Filter = {
        id: "pinned",
        label: strings.Dispatch.PinnedJobs,
        matches: (job: Job) => pinned.includes(job.id),
    };

    const todayFilter: Filter = {
        id: "today",
        label: strings.Job.TodaysJobs,
        matches: (job: Job) => job.day === today
    };

    const unassignedFilter: Filter = {
        id: "unassigned",
        label: strings.General.Unassigned,
        matches: (job: Job) => job.receiverUid === ""
    };

    const assigneeFilter = <MemberSelect
        placeholder={strings.General.Assignee}
        avatar={false}
        selectedMember={selectedMember}
        onMemberSelect={(member?: Member | "") => {
            const filter = filters.filter((f) => f.id !== "assignee");
            if (member !== undefined) {
                setFilters([
                    ...filter,
                    {
                        label: member ? member.name : strings.JobStatuses.Unassigned,
                        id: "assignee",
                        matches: (job: Job) =>
                            member ? job.receiverUid === member.uid : !isAssigned(job),
                        hidden: true,
                        value: member,
                    },
                ]);
            } else {
                setFilters(filter);
            }
        }}
    />;

    const progressFilter = <JobProgressStatusSelect
        progress={selectedProgress || 0}
        onSelect={(progress?: number) => {
            const filter = filters.filter((f) => f.id !== "status");
            if (progress && progress > 0) {
                setFilters([
                    ...filter,
                    {
                        label: jobInfo.getStatusTextFromProgress(progress),
                        id: "status",
                        matches: (job: Job) => getProgressStatus(job) === progress,
                        hidden: true,
                        value: progress,
                    },
                ]);
            } else {
                setFilters(filter);
            }
        }}
    />;

    const priorityFilter = <PrioritySelect
        priority={selectedPriority || 0}
        onSelect={(priority?: number) => {
            const filter = filters.filter((f) => f.id !== "priority");
            if (priority && priority > 0) {
                setFilters([
                    ...filter,
                    {
                        label: jobInfo.getPriorityText(priority),
                        id: "priority",
                        matches: (job: Job) =>
                            job.priority === priority ||
                            (priority > PRIO_NORMAL && isHighPriority(job)) ||
                            (priority < PRIO_NORMAL && isLowPriority(job)),
                        hidden: true,
                        value: priority,
                    },
                ]);
            } else {
                setFilters(filter);
            }
        }}
    />;

    const teamFilter = <TeamSelect
        selectedTeam={selectedTeam}
        onSelect={(team) => {
            const filter = filters.filter((f) => f.id !== "team");
            if (team) {
                setFilters([
                    ...filter,
                    {
                        label: team.name,
                        id: "team",
                        matches: (job: Job) => job.teamId === team.id,
                        hidden: true,
                        value: team,
                    },
                ]);
            } else {
                setFilters(filter);
            }
        }}
    />;

    const notesFilter = <Input
        size="compact"
        placeholder={strings.Job.Notes}
        value={filterNotes || ''}
        clearable
        onChange={({ target: { value } }) => {
            const filter = filters.filter((f) => f.id !== "status");
            const cleanedUpFilters = filter.filter((f) => f.id !== "notes");
            if (value.trim().length > 0) {
                setFilters([
                    ...cleanedUpFilters,
                    {
                        label: strings.Job.Notes,
                        id: "notes",
                        matches: (job: Job) =>
                            job.textDispatcher.toLowerCase().includes(value.toLowerCase()),
                        hidden: true,
                        value
                    },
                ]);
            } else {
                setFilters(cleanedUpFilters);
            }
        }}
    />;

    const labelsFilter = <Input
        size="compact"
        placeholder={strings.Job.Labels}
        value={filterLabels || ''}
        clearable
        onChange={({ target: { value } }) => {
            const filter = filters.filter((f) => f.id !== "status");
            const cleanedUpFilters = filter.filter((f) => f.id !== "labels");
            if (value.trim().length > 0) {
                setFilters([
                    ...cleanedUpFilters,
                    {
                        label: strings.Job.Labels,
                        id: "labels",
                        matches: (job: Job) => {
                            const labels = getLabels(job);
                            return labels.filter(l => l.text.toLowerCase().includes(value.toLowerCase())).length > 0;
                        },
                        hidden: true,
                        value
                    },
                ]);
            } else {
                setFilters(cleanedUpFilters);
            }
        }}
    />;

    const checkableFilter = (filter: Filter) => {
        const isIncluded = filters.map(f => f.id).includes(filter.id);
        const style = isIncluded ? {
            backgroundColor: theme.customColors.accent,
            color: "white",
        } : {
            color: theme.colors.contentPrimary,
        };
        return (
            <CheckableTag
                style={style}
                checked={isIncluded}
                onChange={(checked) => {
                    if (checked) {
                        setFilters([...filters, filter]);
                    } else {
                        setFilters([...filters].filter(f => f.id !== filter.id));
                    }
                }}
            >
                {filter.label}
            </CheckableTag>
        )
    };

    const selectedRangeIndex = (() => {
        if (selectedRange === LAST_30_DAYS_KEY) {
            return 0;
        } else if (selectedRange === LAST_6_MONTS_KEY) {
            return 1;
        }
        return 2;
    })()

    const onSelectedRangeChange = (index: number) => {
        if (index === 0) {
            setSelectedRange(LAST_30_DAYS_KEY);
        } else if (index === 1) {
            setSelectedRange(LAST_6_MONTS_KEY);
        } else {
            setSelectedRange(CUSTOM_DATES_KEY);
        }
    }

    return (
        <VFlex style={{ height: "auto", rowGap: theme.sizing.scale300 }}>
            <HFlex alignCenter spacing style={{ minHeight: theme.sizing.scale950 }}>
                <LabelXSmall color={theme.colors.contentTertiary}>{strings.General.Range}:</LabelXSmall>
                <ButtonGroup size="mini"
                             mode="radio"
                             selected={selectedRangeIndex}
                             onClick={(_, index) => {
                                 onSelectedRangeChange(index);
                             }}
                >
                    <Button><NoWrapText>{format(strings.General.LastXDays, "" + 30)}</NoWrapText></Button>
                    <Button><NoWrapText>{format(strings.General.LastXMonths, "" + 6)}</NoWrapText></Button>
                    <Button><NoWrapText>{strings.General.Custom}</NoWrapText></Button>
                </ButtonGroup>
                {isCustomRange && (
                    <>
                        <StatefulDatepicker
                            onChange={({ date }) => {
                                if (date instanceof Array<Date> && date[0] && date[1]) {
                                    setDays([dateToDayBrowserTimezone(date[0]), dateToDayBrowserTimezone(date[1])]);
                                } else if (date instanceof Date && date) {
                                    setDays([dateToDayBrowserTimezone(date), dateToDayBrowserTimezone(date)]);
                                } else {
                                    resetDays();
                                }
                            }}
                            autoFocusCalendar
                            range
                            size="compact"
                            monthsShown={2}
                            clearable
                            quickSelect
                        />
                    </>
                )}
                <SpacerExpand/>
                <LabelXSmall><NoWrapText>{format(strings.Job.XJobs, "" + jobs.length)}</NoWrapText></LabelXSmall>
                {jobs.length >= MAX_JOBS &&
                    <Tooltip title={strings.Job.NarrowTimeframe}>
                        <Tag style={{ ...tinyTag }}>
                            <InfoCircledIcon color={theme.colors.contentPrimary} size={12}/>
                        </Tag>
                    </Tooltip>}
            </HFlex>
            <HFlex>
                <SpacerExpand/>

                {checkableFilter(updatedFilter)}
                {checkableFilter(pinnedFilter)}
                {checkableFilter(todayFilter)}
                {checkableFilter(noDateFilter)}
                {checkableFilter(unassignedFilter)}

                <StatefulPopover
                    focusLock
                    placement={PLACEMENT.bottomLeft}
                    content={({ close }) => (
                        <MenuContainer style={{
                            maxWidth: "350px",
                            marginLeft: "8px",
                            marginRight: "8px",
                            marginTop: "8px",
                            marginBottom: "8px",
                        }}>
                            {assigneeFilter}
                            {progressFilter}
                            {priorityFilter}
                            {teamFilter}
                            {notesFilter}
                            {labelsFilter}
                        </MenuContainer>
                    )}
                >
                    <Tag style={{
                        background: "transparent",
                        borderStyle: 'dashed',
                    }}>
                        {!isMoreFiltering &&
                            <FilterIcon size={8} color={theme.colors.contentPrimary} style={{ marginRight: "8px" }}/>}
                        {isMoreFiltering &&
                            <FilterFilledIcon size={8} color={theme.customColors.accent}
                                              style={{ marginRight: "8px" }}/>}
                        {strings.General.MoreFilters}
                    </Tag>
                </StatefulPopover>
                <SpacerExpand/>
            </HFlex>
        </VFlex>
    );
}