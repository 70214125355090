import { Day } from "../types/core";

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const Y2100 = 4102444800000;

export function dateToDayBrowserTimezone(date: Date): Day {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const monthString = month < 10 ? '0' + month : '' + month;
    const dayString = day < 10 ? '0' + day : '' + day;
    return +(year + monthString + dayString) as Day;
}

export function dayToDateBrowserTimezone(numberedDay: Day): Date {
    if (!numberedDay) {
        return new Date();
    }
    const dayString = `${numberedDay}`;
    const year = Number(dayString.substring(0, 4));
    const month = Number(dayString.substring(4, 6)) - 1;
    const day = Number(dayString.substring(6, 8));
    return new Date(year, month, day); // browser timezone, which is needed for date pickers
}

export function dayToMillisBrowserTimezone(numberedDay: Day): number {
    return dayToDateBrowserTimezone(numberedDay).getTime();
}

export function isoDateToDay(dateString: string): Day {
    const numberString = dateString.replace(/-/g, "");
    return parseInt(numberString, 10) as Day;
}

export function createTimeStringFromInt(isAmPm: boolean, i: number) {
    let s = "" + i;
    while (s.length < 4) {
        s = "0" + s;
    }
    if (isAmPm) {
        let time = i;
        let ampm: string;
        if (time === 2400) {
            time = 1200;
            ampm = " AM";
        } else if (time < 100) {
            time += 1200;
            ampm = " AM";
        } else if (time < 1200) {
            ampm = " AM";
        } else if (time < 1300) {
            ampm = " PM";
        } else {
            time -= 1200;
            ampm = " PM";
        }
        let h = Math.floor(time / 100);
        let m = Math.floor(time % 100);
        if (m > 0) {
            let sb = "" + h + ":";
            if (m < 10) {
                sb += "0";
            }
            sb += (m + ampm);
            return sb;
        } else {
            return h + ampm;
        }
    } else {
        return s.substring(0, 2) + ":" + s.substring(2);
    }
}

export function datePlusDays(old: Date, days: number): Date {
    return new Date(old.getTime() + days * DAY);
}

export function dateMinusDays(old: Date, days: number): Date {
    return new Date(old.getTime() - days * DAY);
}

export function floorDate(original: Date) {
    let date = new Date(original);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
}

export function timeToDate(time: number): Date {
    let date = new Date();
    date.setHours(Math.floor(time / 100));
    date.setMinutes(time % 100);
    date.setSeconds(0);
    return date;
}

export function dateToTime(date: Date): number {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return Number(`${hours}${minutes < 10 ? '0' + minutes : minutes}`);
}

export function dayOfToday(): Day {
    return dateToDayBrowserTimezone(new Date());
}

export function dayOfMinus(days: number): Day {
    return dateToDayBrowserTimezone(dateMinusDays(new Date(), days));
}

export function getDatesBetween(startDate: Date, endDate: Date): number[] {
    const dates: Date[] = [];

    const currentDate = new Date(startDate);
    const stopDate = new Date(endDate);

    while (currentDate <= stopDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates.map((date) => dateToDayBrowserTimezone(date));
}

export function currentMinutesOfDay() {
    const now = new Date();
    return Math.floor((now.getTime() - now.setHours(0, 0, 0, 0)) / MINUTE);
}

export function millisToMinutes(millis: number) {
    const date = new Date(millis);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return (hours * 60) + minutes; 
}
