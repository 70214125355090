import { FormItem, FormItemType } from "../../types/form";
import { HFlex } from "../containers/HFlex";
import { Input } from "baseui/input";
import { LabelSmall } from "baseui/typography";
import { Spacer } from "../containers/Spacer";
import { SplitTaskIcon } from "../ui/svg";
import { Button } from "baseui/button";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { useRef, useState } from "react";
import { useHover } from "usehooks-ts";
import { FormItemSelect } from "./FormItemSelect";
import { FormItemElement } from "./FormItemElement";
import { VFlex } from "../containers/VFlex";
import { StatefulPopover } from "baseui/popover";
import { Block } from "baseui/block";
import { useForms } from "../../hooks/useForms";
import { FormItemCardHeader } from "./FormItemCardHeader";
import { StatefulTooltip } from "baseui/tooltip";
import { isTestEnvironment } from "../../utils/app";

export type FormItemCardProps = {
    formItem: FormItem;
    onSetName: (name: string) => void;
    onSetType: (type: FormItemType) => void;
    onSetOptions: (options: string[]) => void;
    onSetRequired?: (required: boolean) => void;
    onRemove: () => void;
    onAddConditional?: (equalsTo: string, type: FormItemType) => void;
    jobInputForm?: boolean;
    canEdit?: boolean;
    children?: React.ReactNode;
}

export function FormItemCard(props: FormItemCardProps) {
    const {
        formItem,
        onSetName,
        onSetType,
        onSetRequired,
        onRemove,
        jobInputForm = false,
        canEdit = true,
        onAddConditional,
        children,
    } = props;
    const { strings } = useStrings();
    const { isDarkTheme, theme } = useTheme();
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const item = formItem;
    const { equatableOptionIds } = useForms();
    const [equalsTo, setEqualsTo] = useState("");
    const [conditionalType, setConditionalType] = useState<FormItemType>("text");

    return (
        <div ref={hoverRef} style={{
            backgroundColor: isDarkTheme ? "#333333" : "#DDDDDD",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "8px",
            paddingRight: "8px",
        }}>
            <VFlex>
                <FormItemCardHeader
                    canEdit={canEdit}
                    isHover={isHover}
                    item={item}
                    onRemove={onRemove}
                    onSetName={onSetName}
                    onSetType={onSetType}
                    jobInputForm={jobInputForm}
                    onSetRequired={onSetRequired}
                />
                <Spacer/>
                <FormItemElement {...props}/>
                {children && (
                    <>
                        <Spacer/>
                        {children}
                    </>
                )}
                {isTestEnvironment()
                    && onAddConditional
                    && equatableOptionIds.includes(formItem.type) && (
                    <>
                        <Spacer/>
                        <HFlex style={{ justifyContent: "center" }}>
                            <StatefulPopover
                                returnFocus
                                ignoreBoundary
                                placement="top"
                                onClose={() => {
                                    setEqualsTo("");
                                    setConditionalType("text");
                                }}
                                content={({ close }) => (
                                    <Block padding={theme.sizing.scale600}>
                                        <HFlex spacing alignCenter>
                                            <LabelSmall>{strings.General.ValueEqualsTo}</LabelSmall>
                                            <Block maxWidth={"100px"}>
                                                <Input autoFocus value={equalsTo} size={"mini"}
                                                onChange={value => setEqualsTo(value.target.value)}/>
                                            </Block>
                                            <LabelSmall>{strings.General.Show}</LabelSmall>
                                            <FormItemSelect value={conditionalType} jobInputForm={jobInputForm}
                                                        onChange={(type) => setConditionalType(type)}/>
                                            <Button size={"mini"} shape={"default"} onClick={() => {
                                                onAddConditional(equalsTo, conditionalType);
                                                close()
                                            }}>
                                                {strings.General.Done}
                                            </Button>
                                        </HFlex>
                                    </Block>
                                )}
                            >
                                <span>
                                    <StatefulTooltip content={strings.General.Conditional} showArrow placement="top"> 
                                        <Button
                                            kind={"primary"}
                                            shape={"round"}
                                            size={"mini"}
                                            overrides={{
                                                Root: {
                                                    style: {
                                                        "visibility": isHover ? "visible" : "hidden"
                                                    }
                                                }
                                            }}
                                        >
                                            <SplitTaskIcon size={12} color={theme.colors.contentInversePrimary}/>
                                        </Button>
                                    </StatefulTooltip>
                                </span>
                            </StatefulPopover>
                        </HFlex>
                    </>
                )}
            </VFlex>
        </div>
    );
}