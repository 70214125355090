import { StatusBadge } from "../../badges/StatusBadge";
import { getProgressStatus } from "../../../utils/jobUtils";
import { HFlex } from "../../containers/HFlex";
import { useRecoilValue } from "recoil";
import { jobState } from "../../../states/jobsState";
import { useJobForms } from "../../../hooks/jobs/useJobForms";
import { editingJobIdState } from "../../../states/appState";
import { useStrings } from "../../../hooks/useStrings";
import { editJobState } from "../../../states/editJobState";
import { useTheme } from "../../../hooks/useTheme";
import { ArchivedFillIcon, BookmarkFillIcon } from "../../ui/svg";
import { EllipsedText } from "../../ui/EllipsedText";
import { isDropoff, isPickup, STATUS_ACTIVE, STATUS_ARCHIVED } from "../../../types/job";
import React from "react";
import { Tag } from "antd";

export function JobModalHeader() {
    const jobId = useRecoilValue(editingJobIdState);
    const job = useRecoilValue(jobState(jobId));
    const fields = useRecoilValue(editJobState(jobId || ''));
    const { isNewJob } = useJobForms();
    const { strings } = useStrings();
    const { theme } = useTheme();

    if (!job || !fields) {
        return null;
    }

    const title = isNewJob ? strings.Job.NewJob : fields.destinationName ? fields.destinationName : '';

    return (
        <HFlex alignCenter spacing>
            {fields.isTemplate && <BookmarkFillIcon size={16} color={theme.colors.contentPrimary}/>}
            {job.status === STATUS_ARCHIVED && <ArchivedFillIcon size={16} color={theme.colors.contentPrimary}/>}
            <EllipsedText maxLines={2}>{title}</EllipsedText>
            {!isNewJob
                && !fields.isTemplate
                && !fields.isPartOfSequence
                && job.status === STATUS_ACTIVE
                && <StatusBadge progress={getProgressStatus(job)}/>}
            {isPickup(fields) && <Tag>{strings.Job.Pickup}</Tag>}
            {isDropoff(fields) && <Tag>{strings.Job.Dropoff}</Tag>}
        </HFlex>
    )
}