import { useState } from "react";
import { useRecoilValue } from "recoil";
import { zoneState } from "../../states/zoneState";
import { editingZoneIdState } from "../../states/appState";
import { editZoneErrorState, editZoneHasModifiedState, editZoneModifiedState } from '../../states/editZoneState';
import { useEditZones } from './useEditZones';
import { useModal } from "../useModal";

export function useZoneForms() {
    const editingZone = useRecoilValue(editingZoneIdState);
    const zone = useRecoilValue(zoneState(editingZone || ""));
    const [isLoading, setIsLoading] = useState(false);
    const { closeModal } = useModal();
    const zoneHasAnyError = useRecoilValue(editZoneErrorState(editingZone || ""));
    const zoneModifiedFields = useRecoilValue(editZoneModifiedState);
    const zoneHasModifiedFields = useRecoilValue(editZoneHasModifiedState);
    const { editZone } = useEditZones()

    const canSubmit = !zoneHasAnyError;

    const onSubmit = async () => {
        if (!zone) {
            return;
        }
        setIsLoading(true);
        if (zoneHasModifiedFields) {
            await editZone({ ...zone, ...zoneModifiedFields })
        }
        setIsLoading(false);
        closeModal();
    };

    return {
        canSubmit,
        isLoading,
        onSubmit,
        hasChanges: zoneHasModifiedFields,
    };
}
