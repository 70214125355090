import { SECOND } from "../utils/time";
import { Gps, Latitude, Longitude, Timestamp, Uid } from "./core";

export interface TrackEntry {
    uid: Uid;
    account: Uid;
    infoText: string;
    dateText: string;
    timeText: string;
    startLat: Latitude;
    startLng: Longitude;
    startTs: Timestamp;
    startText: string;
    startLetter: string;
    endLat: Latitude;
    endLng: Longitude;
    endTs: Timestamp;
    endText: string;
    endLetter: string;
    distance: number;
    published: boolean;
    speeds: number[]; // km/h
    timestamps: number[]; // in seconds
    purpose: string;
    comment: string;
    approvalStatus?: ApprovalStatus;
    approvalTs: Timestamp;
    approvalUid?: Uid;
}

export enum ApprovalStatus {
    NONE = 0,
    NOTIFIED = 1,
    READY = 2,
    APPROVED = 3,
    DISAPPROVED = 4,
}

export interface TrackEntryAndData {
    entry: TrackEntry;
    data: [];
}

export interface TrackOrStopEntry extends TrackEntry {
    place?: Uid;
    job?: string;
    at?: "place" | "job" | "between_tracks";
    title?: string;
    waypoint?: Gps;
    color?: string;
    kind: "track" | "stop" | "waypoint";
}

export const publishedLink = (trackEntry: TrackEntry): string => {
    return trackEntry.published ? "/!/@t/" + trackEntry.account + "/" + trackEntry.uid : ""
}

export const durationMillis = (trackEntry: TrackEntry): number => {
    return trackEntry.startTs > 0 && trackEntry.endTs > 0 ? trackEntry.endTs - trackEntry.startTs : 0;
}

export const averageSpeed = (trackEntry: TrackEntry): number => {
    const meter = trackEntry.distance;
    const seconds = durationMillis(trackEntry) / SECOND;
    return seconds > 0 ? meter / seconds * 3.6 : 0;
}

export const findNearestTimestampIndex = (trackEntry: TrackEntry, targetTsMillis: number): number =>  {
    const targetTS = targetTsMillis / 1000;
    const tsArray = trackEntry.timestamps;
    let nearestIndex = 0;
    let smallestDiff = Math.abs(tsArray[0] - targetTS);

    for (let i = 1; i < tsArray.length; i++) {
        let diff = Math.abs(tsArray[i] - targetTS);
        if (diff < smallestDiff) {
            smallestDiff = diff;
            nearestIndex = i;
        }
    }

    return nearestIndex;
}