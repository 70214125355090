import { LightTheme } from "baseui";
import { Button } from "baseui/button";
import { Centered } from "../components/containers/Centered";
import { HFlex } from "../components/containers/HFlex";
import { EmptyState } from "../components/ui/EmptyState";
import { useStrings } from "../hooks/useStrings";
import { LogoIcon } from "../components/ui/svg";

export function LogoutView() {
    const { strings } = useStrings();

    return (
        <HFlex
            style={{
                display: "flex",
                background: LightTheme.colors.backgroundPrimary,
                overflowY: "auto",
                justifyContent: "center",
                height: "100%",
            }}
        >
            <Centered>
                <EmptyState
                    title={strings.General.Welcome}
                    subtitle={strings.General.LoginToContinue}
                    cta={
                        <HFlex spacing style={{ width: "auto" }}>
                            <Button
                                onClick={() => {
                                    window.location.replace("/login");
                                }}
                            >
                                {strings.General.Login}
                            </Button>
                            <Button
                                onClick={() => {
                                    window.location.replace("/signup");
                                }}
                            >
                                {strings.General.Signup}
                            </Button>
                        </HFlex>
                    }
                >
                    <LogoIcon size={40} color={LightTheme.colors.contentPrimary}/>
                </EmptyState>
            </Centered>
        </HFlex>
    );
}
