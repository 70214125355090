import { Spacer } from "../containers/Spacer";
import { Button } from "baseui/button";
import { HFlex } from "../containers/HFlex";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { LabelSmall } from "baseui/typography";
import { Input } from "baseui/input";
import React, { useEffect, useState } from "react";
import { useToast } from "../../hooks/useToast";
import { editZoneState } from "../../states/zoneState";
import { useEditZones } from "../../hooks/zones/useEditZones";
import { useCamera } from "../../hooks/useCamera";
import { LocateIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { quickRouteCoordinatesState } from "../../states/quickRouteState";
import { useStrings } from "../../hooks/useStrings";
import { useMap } from "react-map-gl";
import { Alert } from "antd";
import { useEscTrigger } from "../../hooks/useEscTrigger";
import { useDrawMode } from "../../hooks/useDrawMode";
import { NoWrapText } from "../containers/NoWrapText";

export function NavbarCoordinatesPicker() {
    const { strings, format } = useStrings();
    const { theme } = useTheme();
    const [name, setName] = useState("");
    const { drawMode, reset } = useDrawMode();
    const onCancel = () => reset();
    const editZone = useRecoilValue(editZoneState);
    const resetEditZone = useResetRecoilState(editZoneState);
    const setQuickRouteCoordinates = useSetRecoilState(quickRouteCoordinatesState);
    const toast = useToast();
    const editZones = useEditZones();
    useEscTrigger(onCancel)
    const title = () => {
        if (drawMode.type === "zone" && drawMode.action === "new") {
            return strings.Zones.NewZone;
        } else if (drawMode.type === "zone" && drawMode.action === "edit") {
            return format(strings.General.EditX, editZone.name);
        } else if (drawMode.type === "route" && drawMode.action === "new") {
            return strings.Routing.NewRoute;
        }
    }
    const description = () => {
        if (drawMode.type === "zone" && drawMode.action === "new") {
            return !hasCoordinates && strings.General.NavDrawNewZoneDesc;
        } else if (drawMode.type === "zone" && drawMode.action === "edit") {
            return format(strings.General.NavDrawEditZoneXDesc, editZone.name);
        } else if (drawMode.type === "route" && drawMode.action === "new") {
            return strings.General.NavDrawQuickRouteDesc;
        }
    }
    const camera = useCamera();
    const onLocateCoordinates = () => camera.fitToLngLats(drawMode.coordinates);
    const hasCoordinates = drawMode.coordinates && drawMode.coordinates.length >= 2
    const [saving, setSaving] = useState(false);
    const onSave = async () => {
        if (drawMode.type === "zone" && drawMode.action === "edit" && drawMode.coordinates) {
            editZones.editZones([editZone.uid], { points: drawMode.coordinates });
            toast.showSuccess(strings.Zones.ZoneUpdated);
            reset()
        } else if (drawMode.type === "zone" && drawMode.action === "new" && drawMode.coordinates) {
            if (name.length > 0) {
                setSaving(true);
                const status = await editZones.save(name, drawMode.coordinates)
                setSaving(false);
                if (status) {
                    toast.showSuccess(name + ` ${strings.General.Created.toLowerCase()}`);
                    reset();
                }
            } else {
                toast.showInfo(strings.Zones.PleaseEnterZoneName);
            }
        } else if (drawMode.type === "route" && drawMode.action === "new" && drawMode.coordinates) {
            const coordinates = [...drawMode.coordinates];
            setQuickRouteCoordinates([...coordinates]);
            reset();
        }
    };

    useEffect(() => () => {
        resetEditZone();
    }, []);
    useEffect(() => {
        if (drawMode.type === "route" && hasCoordinates) {
            onSave();
        }
    }, [hasCoordinates, drawMode]);
    const { map } = useMap();
    useEffect(() => {
        if (!map) {
            return;
        }
        map.getCanvas().style.cursor = "crosshair";
        return () => {
            map.getCanvas().style.cursor = "grab";
        }
    }, [map]);

    const content = (
        <HFlex alignCenter>
            <LabelSmall><NoWrapText>{title()}</NoWrapText></LabelSmall>
            <Spacer/>
            {drawMode.type === "zone" && drawMode.action === "new" && hasCoordinates &&
                <Input size={"compact"}
                       autoFocus
                       placeholder={strings.Zones.EnterNewZoneName}
                       value={name}
                       onChange={({ target: { value } }) => setName(value)}
                       overrides={{
                           Root: {
                               style: {
                                   width: "200px"
                               }
                           }
                       }}
                />
            }
            {drawMode.type === "zone" && drawMode.action === "edit" &&
                <Button size={"compact"} kind={"secondary"} onClick={onLocateCoordinates} disabled={!hasCoordinates}
                        isLoading={saving}>
                    <LocateIcon size={14}
                                color={hasCoordinates ? theme.colors.contentPrimary : theme.colors.contentTertiary}/>
                </Button>
            }
            <Spacer/>
            {drawMode.type === "zone" && hasCoordinates &&
                <>
                    <Spacer/>
                    <Button kind={"primary"} size={"compact"} onClick={onSave}
                            disabled={!hasCoordinates}>{strings.General.Save}</Button>
                </>
            }
        </HFlex>
    );

    return (
        <HFlex style={{ justifyContent: "center", pointerEvents: "auto" }}>
            <Alert description={description()} message={content} showIcon type={"info"} closable onClose={onCancel}/>
        </HFlex>
    );
}