import { Button } from "baseui/button";
import { useState } from "react";
import { useEditZones } from "../../hooks/zones/useEditZones";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { useZoneSelection } from "../../hooks/zones/useZoneSelection";
import { Team } from "../../types/core";
import { Member } from "../../types/member";
import { FlexGridLayout } from "../containers/FlexGridLayout";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { ColorPicker } from "../pickers/ColorPicker";
import { MembersSelect } from "../pickers/MembersSelect";
import { TeamsSelect } from "../pickers/TeamsSelect";
import { ConfirmModal } from "../ui/ConfirmModal";
import { TrashIcon } from "../ui/svg";

type Props = {
    onFinish: () => void;
    initialColor?: string;
};

export function ZonesBulkEdit({ onFinish, initialColor = "" }: Props) {
    const { theme } = useTheme();
    const { strings, format } = useStrings();
    const [color, setColor] = useState(initialColor);
    const [teams, setTeams] = useState<Team[] | "">([]);
    const [members, setMembers] = useState<Member[] | "">([]);
    const { editZones, deleteZones } = useEditZones();
    const selection = useZoneSelection();
    const [saving, setSaving] = useState(false);
    const [showDeleteConfirm, setDeleteShowConfirm] = useState(false);

    const onDeleteConfirmed = async () => {
        await deleteZones(selection.selectedUids);
        setDeleteShowConfirm(false);
        onFinish();
    };

    const onApplyClick = async () => {
        setSaving(true);
        let data: {[key: string]: any} = {}
        if (color) {
            data["color"] = color;
        }
        if (teams === "") {
            data["assignedTeams"] = [];
        } else if (teams.length) {
            data["assignedTeams"] = teams.map((t) => t.id);
        }
        if (members === "") {
            data["assignedMembers"] = [];
        } else if (members.length) {
            data["assignedMembers"] = members.map((t) => t.id);
        }
        await editZones(selection.selectedUids, data);
        setColor(initialColor);
        setMembers([]);
        setTeams([]);
        setSaving(false);
    }

    const onCancelClick = () => {
        onFinish();
    };

    return (
        <VFlex spacing>
            <FlexGridLayout>
                <ColorPicker selectedColor={color} onChange={setColor}/>
                <TeamsSelect
                    placeholder={`${strings.General.AssignToTeams}...`}
                    selectedTeams={teams}
                    onSelect={setTeams}
                    hideUnassigned={false}
                />
                <MembersSelect
                    placeholder={`${strings.General.AssignToMembers}...`}
                    selectedMemberUids={members === "" ? "" : members.map(m => m.uid)}
                    onMemberSelect={setMembers}
                    hideUnassigned={false}
                />
            </FlexGridLayout>
            <HFlex>
                <Button
                    kind="tertiary"
                    size="compact"
                    onClick={() => setDeleteShowConfirm(true)}
                    startEnhancer={<TrashIcon size={14} color={theme.colors.contentTertiary}/>}
                >
                    {strings.Zones.DeleteZones}
                </Button>
                <SpacerExpand/>
                <Button kind="secondary" size="compact" onClick={() => onCancelClick()}>
                    {strings.General.Cancel}
                </Button>
                <Spacer/>
                <Button isLoading={saving} size="compact" onClick={() => onApplyClick()}>
                    {strings.General.Apply}
                </Button>
            </HFlex>
            <ConfirmModal
                isOpen={showDeleteConfirm}
                title={format(strings.Zones.DeleteXZones, selection.selectionCount)}
                description={strings.Zones.ConfirmDeleteZonesDesc}
                onCancel={() => setDeleteShowConfirm(false)}
                onConfirm={onDeleteConfirmed}
            />
        </VFlex>
    );
}