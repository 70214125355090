import { PAGE } from "../../../constants/pages";
import { Layer, Marker, Source } from "react-map-gl";
import {
    trackMarkersCircleLayerStyle,
    trackMarkersTextLayerStyle,
    useStopsLayerStyle,
    useTrackLayerStyle,
    useWaypointsLayerStyle
} from "../mapStyle";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { dayState, pageState } from "../../../states/appState";
import { selectedTracksLayersState } from "../../../states/geojsonState";
import { useTimeframeFilter } from "../../../hooks/useTimeframeFilter";
import {
    hoveredTrackState,
    selectedTrackEntriesState,
    selectedTrackTimeSliderProgress
} from "../../../states/tracksState";
import { mapInteractiveLayersState, showWaypointsLayerState } from "../../../states/mapState";
import { memo, useEffect, useState } from "react";
import { retrieveStopsFromGeojson, retrieveTracksFromGeojson } from "../../../utils/geojsonUtils";
import { useCamera } from "../../../hooks/useCamera";
import { useTracksSelection } from "../../../hooks/tracks/useTracksSelection";
import { tracksAndStopsConfigState } from "../../../states/viewState";
import { FixedMarker } from "baseui/map-marker";
import { dayOfToday, MINUTE } from "../../../utils/time";
import { useTime } from "../../../hooks/useTime";
import { findNearestTimestampIndex } from "../../../types/track";
import { Latitude, Longitude } from "../../../types/core";

function TrackLayers() {
    const waypointsLayerId = "tracks-waypoints";
    const page = useRecoilValue(pageState);
    const { trackLayerStyle, trackOuterLayerStyle } = useTrackLayerStyle();
    const { waypointsLayerStyle } = useWaypointsLayerStyle(waypointsLayerId);
    const { timeframeFilter } = useTimeframeFilter();
    const hoveredTrack = useRecoilValue(hoveredTrackState);
    const day = useRecoilValue(dayState);
    const tracksLayers = useRecoilValue(selectedTracksLayersState({day: page === PAGE.LIVE ? dayOfToday() : day}));
    const tracksAndStopsConfig = useRecoilValue(tracksAndStopsConfigState);
    const showTrackWaypoints = useRecoilValue(showWaypointsLayerState) && tracksAndStopsConfig.showTracks;
    const setInteractiveLayers = useSetRecoilState(mapInteractiveLayersState);
    const [hoveringOnMap, setHoveringOnMap] = useState(false);
    const showTracksLayer = page === PAGE.LIVE
        || page === PAGE.TIMELINE
        || page === PAGE.DISPATCH
        || page === PAGE.LOCATION;
    const totalFeaturesCount = tracksLayers.reduce((total, layer) => total + layer.features.length, 0);
    const actuallyShowingWaypoints = totalFeaturesCount > 0 && showTrackWaypoints && showTracksLayer;
    const camera = useCamera();
    const tracksSelection = useTracksSelection();
    const { stopsTextLayerStyle, stopsCircleLayerStyle } = useStopsLayerStyle();
    const time = useTime();
    const isTracksTab = page === PAGE.LOCATION;

    useEffect(() => {
        setInteractiveLayers((old) => {
                const newLayers = [...old]
                    .filter(id => id !== waypointsLayerId && id != stopsCircleLayerStyle.id && id != trackLayerStyle.id);
                actuallyShowingWaypoints && newLayers.push(waypointsLayerId);
                totalFeaturesCount > 0 && newLayers.push(stopsCircleLayerStyle.id);
                totalFeaturesCount > 0 && newLayers.push(trackLayerStyle.id);
                return newLayers;
            }
        );
        return () => setInteractiveLayers((old) => [...[...old]
            .filter(id => id !== waypointsLayerId)
            .filter(id => id != stopsCircleLayerStyle.id)
            .filter(id => id != trackLayerStyle.id)
        ]);
    }, [actuallyShowingWaypoints, totalFeaturesCount]);

    useEffect(() => {
        if (tracksSelection.selectionCount) {
            let mergedData = [] as any[];
            for (const trackLayer of tracksLayers) {
                for (const { data } of retrieveTracksFromGeojson(trackLayer)) {
                    data.forEach(lngLat => mergedData.push(lngLat))
                }
                for (const stop of retrieveStopsFromGeojson(trackLayer)) {
                    mergedData.push([stop.startLng, stop.startLat])
                }
            }
            if (mergedData.length > 0) {
                camera.fitToLngLats(mergedData);
            }
        }
    }, [tracksLayers]);


    useEffect(() => {
        if (!showTracksLayer) {
            return;
        }
        const onMapListener = () => {
            setHoveringOnMap(true);
        }
        const leaveMapListener = () => {
            setHoveringOnMap(false);
        }

        const map = document.getElementById("map");
        map?.addEventListener("mousemove", onMapListener);
        map?.addEventListener("mouseleave", leaveMapListener);
        return () => {
            map?.removeEventListener("mousemove", onMapListener);
            map?.removeEventListener("mouseleave", leaveMapListener);
        }
    }, [hoveringOnMap]);

    const selectedTracks = useRecoilValue(selectedTrackEntriesState);
    const selectedTrackProgress = useRecoilValue(selectedTrackTimeSliderProgress);

    if (!showTracksLayer) {
        return null;
    }

    const noneFilter = ["==", "kind", "NONE"];
    const trackLayerFilter = !isTracksTab ? noneFilter : hoveredTrack
        ? ["==", ["get", "uid"], hoveredTrack.uid]
        : ["all", ["==", "$type", "LineString"]];
    const trackMarkersFilter = !isTracksTab ? noneFilter : hoveredTrack
        ? ["==", ["get", "trackUid"], hoveredTrack.uid]
        : ["all", ["==", "$type", "Point"], ["has", "letter"]];
    const waypointsLayerFilter = timeframeFilter || ["all", ["==", "kind", "wp"]];
    const stopsFilter = !isTracksTab ? noneFilter : ["all", ["==", "kind", "stop"]];

    !tracksAndStopsConfig.showTracks && trackLayerFilter.push(["==", "true", "false"]);
    !tracksAndStopsConfig.showTracks && trackMarkersFilter.push(["==", "true", "false"]);
    !tracksAndStopsConfig.showStopsAtPlaces && stopsFilter.push(["!=", "at", "place"]);
    !tracksAndStopsConfig.showStopsAtJobs && stopsFilter.push(["!=", "at", "job"]);
    !tracksAndStopsConfig.showStopsOther && stopsFilter.push(["!=", "at", "between_tracks"]);

    let lng: Longitude | undefined = undefined;
    let lat: Latitude | undefined = undefined;
    let label: string | undefined = undefined;
    if (selectedTracks.length === 1) {
        const track = selectedTracks[0];
        for (const trackLayer of tracksLayers) {
            const feature = trackLayer.features.find(f => f?.properties?.uid === track.uid);
            if (feature) {
                const duration = (track.endTs - track.startTs) / MINUTE;
                const ts = track.startTs + selectedTrackProgress * duration * MINUTE;
                label = time.formatTimeOnly(ts);
                const idx = findNearestTimestampIndex(track, ts);
                const latLng = (feature as any).geometry?.coordinates[idx];
                lng = latLng && latLng[0];
                lat = latLng && latLng[1];
            }
        }
    }

    return (
        <>
            {tracksLayers.map((tracksLayer, index) =>
                <Source key={index} id="tracks" type="geojson" data={tracksLayer}>
                    <Layer {...trackOuterLayerStyle} filter={trackLayerFilter}/>
                    <Layer {...trackLayerStyle} filter={trackLayerFilter}/>
                    {showTrackWaypoints && <Layer {...waypointsLayerStyle} filter={waypointsLayerFilter}/>}
                    <Layer {...trackMarkersCircleLayerStyle} filter={trackMarkersFilter}/>
                    <Layer {...trackMarkersTextLayerStyle} filter={trackMarkersFilter}/>
                    <Layer {...stopsCircleLayerStyle} filter={stopsFilter}/>
                    <Layer {...stopsTextLayerStyle} filter={stopsFilter}/>
                </Source>
            )}
            {lat && lng && <Marker longitude={lng} latitude={lat} anchor="bottom"><FixedMarker label={label}/></Marker>}
        </>
    );
}

export default memo(TrackLayers);