export const getDiffFields = (obj1: { [key: string]: any }, obj2: { [key: string]: any }) => {
    return Object.keys(obj2).reduce((diff, key) => {
        if ((Array.isArray(obj1[key]) || Array.isArray(obj2[key])) && !!obj1[key]) {
            const isNotModified = obj2[key].every((value: any, index: number) => {
                if (typeof value === 'object' && typeof obj1[key][index] === 'object') {
                    return JSON.stringify(value) === JSON.stringify(obj1[key][index]);
                }
                return value === obj1[key][index];
            }) && obj1[key].length === obj2[key].length;
            if (isNotModified) {
                return diff;
            }
            return {
                ...diff,
                [key]: obj2[key]
            }
        }
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
            const diffFields: {} = getDiffFields(obj1[key], obj2[key]);
            if (Object.keys(diffFields).length) {
                return {
                    ...diff,
                    [key]: diffFields
                };
            }
            return diff;
        }
        if (obj1[key] === obj2[key]) {
            return diff;
        }
        return {
            ...diff,
            [key]: obj2[key],
        };
    }, {});
};

export function asNumber(data: any, key: string): number {
    return Number(data[key]) || 0
}
