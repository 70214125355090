import { atom, selector, selectorFamily } from "recoil";
import { placesLayerState } from "./geojsonState";
import { Place } from "../types/place";
import { atomWithIndexedPersistence } from "../utils/persistence";
import { SORT_DIRECTION, SortDirection } from "baseui/table";
import { containsSubstring } from "../utils/filter";
import { Uid } from "../types/core";

export enum PlaceSort {
    NAME,
    CREATED,
    COLOR,
    RADIUS,
}

export const placesSortState = atom({
    key: "places.sort",
    default: PlaceSort.NAME,
});

export const placesSortDirectionState = atom<SortDirection>({
    key: "places.sort.direction",
    default: SORT_DIRECTION.ASC,
});

export const placesSearchState = atom({
    key: "places.search",
    default: "",
});

export const placesTeamFilterState = atom({
    key: "places.teamfilter",
    default: 0,
});

export const allPlacesState = selector({
    key: "places.all",
    get: ({ get }) => {
        const placesLayer = get(placesLayerState);
        if (!placesLayer) {
            return [] as Place[];
        }
        const obj = placesLayer as unknown as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
        if (!obj.features) {
            return [] as Place[];
        }
        return obj.features
            .filter(feat => !!feat?.geometry && !!feat?.properties)
            .map(feat => {
                const point = feat.geometry as any;
                const place = {
                    ...feat.properties,
                    latitude: point.coordinates[1],
                    longitude: point.coordinates[0],
                }
                return place as Place;
            });
    }
});

export const placesState = selector({
    key: "places",
    get: ({ get }) => {
        const allPlaces = get(allPlacesState);
        const search = get(placesSearchState);
        const sort = get(placesSortState);
        const teamfilter = get(placesTeamFilterState);
        const sortDirection = get(placesSortDirectionState);
        const sortedPlaces = allPlaces
            .filter(p => teamfilter === 0 || p.teams.includes(teamfilter))
            .filter(p => search === ""
                || containsSubstring(p.name, search)
                || containsSubstring(p.address, search))
            .sort((p1, p2) => {
                if (sort === PlaceSort.CREATED) {
                    return p2.created - p1.created;
                } else if (sort === PlaceSort.COLOR) {
                    return p1.color.localeCompare(p2.color);
                } else if (sort === PlaceSort.RADIUS) {
                    return p1.radius - p2.radius;
                } else {
                    return p1.name.localeCompare(p2.name);
                }
            });
        return sortDirection === SORT_DIRECTION.DESC ? sortedPlaces.reverse() : sortedPlaces;
    }
});

export const placeState = selectorFamily<Place | undefined, string>({
    key: 'place',
    get: (uid) => ({ get }) => {
        const places = get(allPlacesState);
        return places.find(p => p.uid === uid);
    }
});

export const placesSelectedUidsState = atomWithIndexedPersistence("places.selected.ids", [] as Uid[]);

export const placesSelectedState = selector<Place[]>({
    key: "places.selected",
    get: ({ get }) => get(placesSelectedUidsState)
        .map(uid => get(placeState(uid)))
        .filter(place => !!place) as Place[],
});