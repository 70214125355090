import { useRecoilValue } from 'recoil';
import { placesState } from "../../states/placeState";
import { PlaceRow } from "./PlaceRow";
import { StyledTable } from 'baseui/table';
import { VariableSizeList as List } from "react-window";
import { default as AutoSizer } from "react-virtualized-auto-sizer";
import { useTheme } from '../../hooks/useTheme';
import { requestingState } from "../../states/viewState";
import { VFlex } from "../containers/VFlex";
import { SpacerExpand } from "../containers/Spacer";
import { HFlex } from "../containers/HFlex";
import { PlaceHeaderRow } from './PlaceHeaderRow';
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { Spin } from "antd";
import React from "react";
import { EmptyState } from '../ui/EmptyState';
import { PlacesIcon } from '../ui/svg';
import { useStrings } from '../../hooks/useStrings';

export function PlaceGrid() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const places = useRecoilValue(placesState);
    const requesting = useRecoilValue(requestingState({ req: "places" }));
    const { tableStyle } = useStyleOverrides();

    const loading = places.length === 0 && requesting;

    if (loading) {
        return (
            <VFlex>
                <SpacerExpand/>
                <HFlex>
                    <SpacerExpand/>
                    <Spin/>
                    <SpacerExpand/>
                </HFlex>
                <SpacerExpand/>
            </VFlex>
        );
    }

    if (places.length === 0) {
        return (
            <EmptyState title={strings.General.Empty}>
                <PlacesIcon size={64} color={theme.colors.primary400}/>
            </EmptyState>
        )
    }

    return (
        <div style={{
            flex: 1,
            marginBottom: theme.sizing.scale800,
        }}>
            <StyledTable role="grid" style={tableStyle}>
                <PlaceHeaderRow/>
                <div style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ height, width }) => {
                            return (
                                <List height={height} itemCount={places.length} itemSize={() => 72} width={width}
                                    itemData={places}>
                                    {PlaceRow}
                                </List>
                            )
                        }}
                    </AutoSizer>
                </div>
            </StyledTable>
        </div>
    );
}
