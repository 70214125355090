import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { dayState, pageState } from "./appState";
import { atomWithIndexedPersistence, idbStorageEffect } from "../utils/persistence";
import { RouteInfo } from "../types/job";
import { boardMemberUidsState } from "./boardState";
import { jobsState } from "./jobsState";
import { dayOfToday } from "../utils/time";
import { PAGE } from "../constants/pages";
import {
    selectedStopEntriesState,
    selectedTrackEntriesState,
    tracksSelectedUidsState,
    waypointsEntriesState
} from "./tracksState";
import { TrackEntry } from "../types/track";
import { matchesAnyTrackEntry } from "../utils/trackUtils";
import { memberState, memberUidsState } from "./membersState";
import { cellToBoundary, latLngToCell } from "h3-js";
import { h3CellResolutionState } from "./mapState";
import { zonesSelectedUidsState } from "./zoneState";
import { Day, Uid } from "../types/core";
import { tracksAndStopsConfigState } from "./viewState";

export const placesLayerState = atom({
    key: "layer.geojson.places",
    default: undefined as (GeoJSON.FeatureCollection | undefined)
});

export const placesLayerRetryState = atom({ key: "layer.geojson.places.retry", default: 0 });
export const placesLayerMoreCounterState = atom({ key: "layer.geojson.places.morecounter", default: 0 });
export const placesLayerSkipCounterState = atom({ key: "layer.geojson.places.skipcounter", default: 0 });

export const zonesLayerState = atomWithIndexedPersistence("layer.geojson.zones", undefined as (GeoJSON.FeatureCollection | undefined));

export const selectedZonesLayerState = selector({
    key: "layer.geojson.zones.selected",
    get: ({ get }) => {
        const uids = get(zonesSelectedUidsState);
        const layer = get(zonesLayerState);
        if (!layer?.features) {
            return undefined;
        }
        return {
            ...layer,
            features: layer.features
                .filter(f => uids.includes(f.properties?.uid))
        } as GeoJSON.FeatureCollection;
    }
})

export const waypointsLayerState = atomFamily({
        key: "layer.geojson.waypoints",
        default: undefined as (GeoJSON.FeatureCollection | undefined),
    }
);

export const heatmapLayerState = atomFamily<GeoJSON.FeatureCollection | undefined, { member: string | undefined, fromDay: Day, untilDay: Day }>({
        key: "layer.geojson.heatmap",
        default: undefined as (GeoJSON.FeatureCollection | undefined),
    }
);

export const dayRouteState = atomFamily({
    key: "layer.geojson.dayroute",
    default: "",
    effects: [idbStorageEffect],
});

export interface FeatureCollectionWithUid extends GeoJSON.FeatureCollection {
    uid: Uid;
    color?: string;
}

export const selectedDayRoutesState = selector({
    key: 'layer.geojson.dayroute.selected',
    get: ({ get }) => {
        const selectedDay = get(dayState);
        const uids = get(boardMemberUidsState);
        const page = get(pageState);
        const day = page === PAGE.LIVE ? dayOfToday() : selectedDay;
        if (day === 0 || uids.length === 0) {
            return [] as FeatureCollectionWithUid[];
        }
        return uids.map(uid => {
                const dayRoute = get(dayRouteState({ uid, day }));
                const color = get(memberState(uid))?.color;
                if (dayRoute && dayRoute.features && dayRoute.features.length > 0) {
                    return { ...dayRoute, uid, color } as FeatureCollectionWithUid;
                } else {
                    let lines = get(jobsDirectLinesState({ uid, day }));
                    return { ...lines, uid, color } as FeatureCollectionWithUid;
                }
            }
        ).filter(el => el !== undefined) as FeatureCollectionWithUid[];
    }
});

export const dayRouteInfoState = selectorFamily<RouteInfo | undefined, { uid: Uid; day: Day; }>({
    key: 'layer.geojson.dayroute.routeinfo',
    get: (uidDay) => ({ get }) => {
        const dayRoute = get(dayRouteState(uidDay));
        if (dayRoute && dayRoute.features && dayRoute.features.length > 0) {
            const coord = dayRoute.features[0].geometry.coordinates;
            const locations = {
                startLocation: {
                    lat: coord[0][1],
                    lng: coord[0][0],
                },
                endLocation: {
                    lat: coord[coord.length - 1][1],
                    lng: coord[coord.length - 1][0],
                }
            };
            return {
                ...(dayRoute as any).features[0].properties,
                ...locations,
            } as RouteInfo;
        }
        return undefined;
    }
});


export const selectedTracksLayersState = selectorFamily<GeoJSON.FeatureCollection[], { day: Day; }>({
    key: 'layer.geojson.tracks.selected',
    get: ({ day }) =>  ({ get }) => {
        const page = get(pageState);
        if (page === PAGE.LOCATION) {
            const layers = get(selectedMembersTracksLayerState);
            const uids = get(tracksSelectedUidsState);
            if (uids.length === 0) {
                return layers;
            }
            const trackEntries = layers.flatMap(layer => {
                return layer.features
                    .filter(feature => uids.includes(feature?.properties?.uid))
                    .map(feature => feature.properties as TrackEntry);
            });
            return layers.map(layer => {
                const features = layer.features
                    .filter(feature => uids.includes(feature?.properties?.uid || feature?.properties?.trackUid)
                        || matchesAnyTrackEntry(trackEntries, feature?.properties?.ts)
                    );
                return { ...layer, features };
            }) as GeoJSON.FeatureCollection[];
        }
        return get(boardMemberUidsState)
            .map(uid => get(tracksLayerState({ uid, day })))
            .filter(layer => layer && layer.features) as GeoJSON.FeatureCollection[];
    }
});

export const tracksLayerState = atomFamily<GeoJSON.FeatureCollection | undefined, { uid: Uid; day: Day; }>({
    key: "layer.geojson.tracks",
    default: undefined as (GeoJSON.FeatureCollection | undefined),
});

export const memberTracksLayerState = atomFamily<GeoJSON.FeatureCollection | undefined, { uid: Uid }>({
    key: "layer.geojson.member.tracks",
    default: undefined,
});

export const selectedMembersTracksLayerState = selector<GeoJSON.FeatureCollection[]>({
    key: 'layer.geojson.member.tracks.selected',
    get: ({ get }) => {
        const page = get(pageState);
        const boardMemberUids = get(boardMemberUidsState);
        const allMemberUids = get(memberUidsState);
        const membersTracksLayers = (page === PAGE.STATS ? allMemberUids : boardMemberUids)
            .map(uid => get(memberTracksLayerState({ uid })))
            .filter(layer => layer && layer.features) as GeoJSON.FeatureCollection[];
        return membersTracksLayers;
    }
});

export const allSelectedTracksLayerState = selector<GeoJSON.FeatureCollection[]>({
    key: 'layer.geojson.tracks.all.selected',
    get: ({ get }) => {
        const membersTracksLayers = get(selectedMembersTracksLayerState);
        const selectedTracks = get(tracksSelectedUidsState);
        if (selectedTracks.length) {
            const filteredFeatures = membersTracksLayers.map(layer => ({
                ...layer,
                features: layer.features.filter((f) => selectedTracks.includes(f.properties?.uid)),
            }));
            return filteredFeatures;
        }
        return [];
    }
});

export const selectedTimelineState = selectorFamily<GeoJSON.FeatureCollection | undefined, Uid>({
    key: 'layer.geojson.timeline.selected',
    get: (uid) => ({ get }) => {
        const day = get(dayState);
        if (day === 0) {
            return undefined;
        }
        return get(timelineState({ uid: uid, day: day }));
    }
});

export const timelineState = atomFamily<GeoJSON.FeatureCollection | undefined, { uid: Uid; day: Day; }>({
    key: "layer.geojson.timeline",
    default: undefined as (GeoJSON.FeatureCollection | undefined),
});

const jobsDirectLinesState = selectorFamily<GeoJSON.FeatureCollection | undefined, { uid: Uid; day: Day; }>({
    key: 'layers.geojson.jobs.directlines',
    get: ({ uid, day }) => ({ get }) => {
        const sameDayJobs = get(jobsState)
            .filter(job => job.day === day)
            .filter(job => job.receiverUid === uid);

        const coordinates = sameDayJobs
            .filter(j => j.receiverUid === uid)
            .sort((a, b) => a.number - b.number)
            .map(j => [j.destinationLng, j.destinationLat]);

        if (coordinates.length > 0) {
            const geoJson = {
                features: [
                    {
                        geometry: {
                            coordinates: coordinates,
                            type: "LineString",
                        },
                        type: "Feature",
                    } as GeoJSON.Feature
                ],
                type: "FeatureCollection",
            } as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
            return geoJson;
        }
        return undefined;
    },
});

export const h3GeojsonLayerState = selector({
        key: "layer.geojson.h3",
        get: ({ get }) => {
            const entries = get(waypointsEntriesState);
            const cellResolution = get(h3CellResolutionState);
            const selectedTracks = get(selectedTrackEntriesState);
            const selectedStops = get(selectedStopEntriesState);
            const page = get(pageState);
            const config = get(tracksAndStopsConfigState);

            if (page !== PAGE.LOCATION || !config.showWaypoints) {
                return {
                    "type": "FeatureCollection",
                    "features": []
                } as GeoJSON.FeatureCollection;
            }

            const selected = [...selectedTracks, ...selectedStops];
            const cellCountMap = new Map();
            let cellCountMax = 0;
            let totalCount = 0;
            entries
                .filter(e => e.waypoint)
                .filter(e => selected.length === 0
                    || selected.some(s => e.startTs >= s.startTs && e.startTs <= s.endTs))
                .forEach(entry => {
                    totalCount += 1;
                    const cellId = latLngToCell(entry.waypoint!.lat, entry.waypoint!.lng, cellResolution);
                    if (cellCountMap.has(cellId)) {
                        const count = cellCountMap.get(cellId) + 1
                        cellCountMap.set(cellId, count);
                        cellCountMax = Math.max(count, cellCountMax);
                    } else {
                        cellCountMap.set(cellId, 1);
                    }
                });

            const styles = [
                {
                    color: '#FEDD87',
                    opacity: 0.4
                },
                {
                    color: '#FED976',
                    opacity: 0.5
                },
                {
                    color: "#FC9653",
                    opacity: 0.6,
                },
                {
                    color: "#F77645",
                    opacity: 0.7
                },
                {
                    color: "#E14C48",
                    opacity: 0.8
                }
            ];

            const h3Features: GeoJSON.Feature[] = Array.from(cellCountMap).map(([cellId, count]) => {
                const hexCoord: number[][] = cellToBoundary(cellId, true);
                const polygon = {
                    "type": "Polygon",
                    "coordinates": [hexCoord]
                } as GeoJSON.Polygon;

                const fraction = count / cellCountMax;
                let styleIdx;
                if (fraction >= 0.8) {
                    styleIdx = 4;
                } else if (fraction >= 0.7) {
                    styleIdx = 3;
                } else if (fraction >= 0.6) {
                    styleIdx = 2;
                } else if (fraction >= 0.4) {
                    styleIdx = 1;
                } else {
                    styleIdx = 0;
                }
                return {
                    "type": "Feature",
                    "geometry": polygon,
                    "properties": {
                        "count": count,
                        "opacity": styles[styleIdx].opacity,
                        "color": styles[styleIdx].color,
                    }
                } as GeoJSON.Feature;
            });

            return {
                type: "FeatureCollection",
                features: h3Features,
                properties: {
                    count: totalCount,
                    maxDensity: cellCountMax,
                }
            } as GeoJSON.FeatureCollection;
        }
    }
)