import { SORT_DIRECTION, SortDirection } from "baseui/table";


export function getNextDirection(direction: SortDirection) {
    if (direction === SORT_DIRECTION.ASC) {
        return SORT_DIRECTION.DESC;
    }
    if (direction === SORT_DIRECTION.DESC) {
        return SORT_DIRECTION.ASC;
    }
    return SORT_DIRECTION.ASC;
}