import { useSetRecoilState } from 'recoil';
import { editJobState } from "../../states/editJobState";
import { Job } from "../../types/job";

export const useResetUneditedFields = (jobId: string) => {
    const setJob = useSetRecoilState(editJobState(jobId));

    return (updatedJob: Job, excludedFields: string[]) => {
        setJob(currentJob => {
            if (!currentJob || !updatedJob) return currentJob;
            const resetJob = { ...currentJob };
            for (const key in updatedJob) {
                if (Object.prototype.hasOwnProperty.call(updatedJob, key) && !excludedFields.includes(key)) {
                    // @ts-ignore
                    resetJob[key] = updatedJob[key];
                }
            }
            return resetJob;
        });
    };
};
