import {Tab, Tabs} from "baseui/tabs-motion";
import {useRecoilValue} from "recoil";
import {COMPANY_TABS} from "../../../constants/tabs";
import {companyTabFilterState} from "../../../states/appState";
import {stringsState} from "../../../states/localeState";
import {CompanySettingsTab} from "./CompanySettingsTab";
import {CompanyProfileTab} from "./CompanyProfileTab";
import {BillingTab} from "../../billing/BillingTab";
import useAccount from "../../../hooks/useAccount";
import {useState} from "react";
import {tabOverridesVertical} from "../../../constants/ui";
import {useOnboardRefs} from "../../../hooks/onboard/useOnboardRefs";
import {useEffectOnce} from "usehooks-ts";
import {CompanyPermissionsTab} from "./CompanyPermissionsTab";
import {JobSettingsTab} from "./JobSettingsTab";
import {showPaymentMethodState} from "../../../states/paymentState";
import {isLocationOnlyPlan} from "../../../utils/account";

export function CompanyTabs() {
    const strings = useRecoilValue(stringsState);
    const [activeTab, setActiveTab] = useState("");
    const companyTabFilter = useRecoilValue(companyTabFilterState);
    const isEmptyFilter = companyTabFilter === "";
    const {isBlocked, company } = useAccount();
    const {companySettingsRef, companyPermissionsRef} = useOnboardRefs();
    const showProfile = !isBlocked && (isEmptyFilter || companyTabFilter === COMPANY_TABS.company);
    const showSettings = !isBlocked && (isEmptyFilter || companyTabFilter === COMPANY_TABS.settings);
    const showPermissions = !isBlocked && (isEmptyFilter || companyTabFilter === COMPANY_TABS.permissions);
    const showDispatchSettings = !isBlocked && (isEmptyFilter || companyTabFilter === COMPANY_TABS.jobSettings);
    const showBilling = isBlocked || isEmptyFilter || companyTabFilter === COMPANY_TABS.billing;
    const showPaymentMethod = useRecoilValue(showPaymentMethodState);
    const tabOverrides = tabOverridesVertical;

    useEffectOnce(() => {
        setActiveTab(isEmptyFilter ? COMPANY_TABS.company : companyTabFilter);
    });

    if (isBlocked || showPaymentMethod) {
        return (
            <div style={{maxHeight: "70vh", minHeight: "200px", overflowX: "hidden"}}>
                <BillingTab/>
            </div>
        );
    }

    const isDispatchPlan = !isLocationOnlyPlan(company?.stripe_plan);

    return (
        <Tabs
            onChange={({activeKey}) => setActiveTab(activeKey)}
            orientation="vertical"
            activeKey={activeTab}
            activateOnFocus
            overrides={{
                Root: {
                    style: {
                        height: "70vh",
                    }
                }
            }}
        >
            {showProfile && <Tab
                key={COMPANY_TABS.company}
                title={strings.Company.Profile}
                overrides={tabOverrides}
            >
                <CompanyProfileTab/>
            </Tab>}
            {showSettings && <Tab
                key={COMPANY_TABS.settings}
                title={strings.General.CompanySettings}
                overrides={tabOverrides}
                tabRef={companySettingsRef}
                onChange={({activeKey}) => setActiveTab(activeKey)}
            >
                <CompanySettingsTab/>
            </Tab>}
            {showPermissions && <Tab
                key={COMPANY_TABS.permissions}
                title={strings.Company.CompanyPermissions}
                overrides={tabOverrides}
                tabRef={companyPermissionsRef}
                onChange={({activeKey}) => setActiveTab(activeKey)}
            >
                <CompanyPermissionsTab/>
            </Tab>}
            {isDispatchPlan && showDispatchSettings && <Tab
                key={COMPANY_TABS.jobSettings}
                title={strings.Job.JobSettings}
                overrides={tabOverrides}
            >
                <JobSettingsTab/>
            </Tab>}
            {showBilling && <Tab
                key={COMPANY_TABS.billing}
                title={strings.General.Billing}
                overrides={tabOverrides}
            >
                <BillingTab/>
            </Tab>}
        </Tabs>
    );
}