import { Button } from "baseui/button";
import { FileUploader } from "baseui/file-uploader";
import { ParagraphSmall } from "baseui/typography";
import { Column } from "jspreadsheet-ce";
import { createRef, useState } from "react";
import { createPortal } from "react-dom";
import { useRecoilState } from "recoil";
import { VISIBLE_FILE_FORMATS } from "../../constants/app";
import { useFileFeatures } from "../../hooks/useFileFeatures";
import { useImport } from "../../hooks/useImports";
import { useStrings } from "../../hooks/useStrings";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { useTheme } from "../../hooks/useTheme";
import { importTypeState } from "../../states/appState";
import { SpreadsheetElement } from "../../types/spreadsheets";
import { isJson, isKml, isValidImportFile } from "../../utils/app";
import { getColumnConfig } from "../../utils/spreadsheets";
import { SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import SpreadSheet from "../jspreadsheet/SpreadSheet";
import { StyledModal } from "../ui/StyledModal";
import { StyledModalContent } from "../ui/StyledModalContent";
import { TemplatesInfo } from "./TemplatesInfo";
import { Alert } from "antd";
import { useToast } from "../../hooks/useToast";


export function ImportModal() {
    const { strings } = useStrings();
    const [importType, setImportType] = useRecoilState(importTypeState);
    const { cancelButtonStyles } = useStyleOverrides();
    const { theme, css } = useTheme();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<string[][]>([[]]);
    const [spreadsheetStyles, setSpreadsheetStyles] = useState({});
    const {
        title,
        templatesContent,
        useImportConfig,
        upload,
        fileFormats,
        onUpload,
        canProcessImport,
        getCellStyles
    } = useImport();
    const {
        advancedColumnsConfig,
        defaultColumns,
        getAdvancedColumnsOptions
    } = useImportConfig();
    const [columns, setColumns] = useState<Column[]>(defaultColumns);
    const [dataErrors, setDataErrors] = useState<{ [key: string]: string }>();
    const spreadSheetRef = createRef<SpreadsheetElement>();
    const [unvisibleFile, setUnvisibleFile] = useState<File>();
    const hasAnyVisibleFormat = VISIBLE_FILE_FORMATS.some((format) => fileFormats.includes(format));
    const succeedRows: number[] = [];
    const toast = useToast();

    const handleLoadedData = ({ header, rows }: { header: string[]; rows: { [key: string]: string }[] }) => {
        const newColumns: Column[] = header.map((title) => {
            // LOAD DATA WITH TEXT FIELDS
            return getColumnConfig(title);
            // LOAD DATA WITH CONFIGURED FIELDS
            // const column = defaultColumnsConfig[title] || advancedColumnsConfig[title];
            // return column ? column : getConfiguration(title);
        });
        setColumns(newColumns);
        const newData = rows.map((row) => {
            return Object.values(row);
        });
        setData(newData);
    };

    const { loadFile, createCsvFile } = useFileFeatures({ onFileLoaded: handleLoadedData });

    const onValidFile = (file: File) => {
        const filename = file.name;
        if (isValidImportFile(filename, fileFormats)) {
            if (isKml(filename) || isJson(filename)) {
                setUnvisibleFile(file);
            } else {
                loadFile(file);
                setUnvisibleFile(undefined);
            }
        }
        setError("");
    };

    const onInvalidFile = () => {
        setError(strings.Errors.FileNotAccepted);
    };

    const closeModal = () => {
        setImportType(undefined);
    };

    const addCustomField = (field: string) => {
        setColumns((prev) => {
            const newColumn = getColumnConfig(field);
            return [...prev, newColumn];
        });
    };

    const startImport = async () => {
        setIsLoading(true);
        let file = undefined;
        let columnsLength = 0;
        let succedStyles: { [key: string]: string } = {};
        if (!unvisibleFile && hasAnyVisibleFormat) {
            const headers: string[] = spreadSheetRef.current?.jspreadsheet.getHeaders(true);
            columnsLength = headers.length;
            succeedRows.forEach((rowNumber) => {
                const index = rowNumber + 1;
                succedStyles = {
                    ...succedStyles,
                    ...getCellStyles(index, columnsLength, true),
                }
            });
            const data = spreadSheetRef.current?.jspreadsheet.getData() as string[][];
            const { canProcess, errors, cellErrors, isEmpty } = canProcessImport(headers, data);
            setDataErrors(errors);
            setSpreadsheetStyles({ ...cellErrors, ...succedStyles });
            if (canProcess) {
                const filteredData = data.map((row, index) => succeedRows.includes(index) ? [] : row);
                const csvData = [headers, ...filteredData];
                file = createCsvFile(`${importType || 'temp'}.csv`, csvData);
            } else if (isEmpty) {
                closeModal();
            }
        } else if (unvisibleFile) {
            file = unvisibleFile
        }
        if (file) {
            const { status, result } = await upload(file);
            if (status) {
                onUpload();
                if (result) {
                    let styles: { [key: string]: string } = {};
                    result.forEach((entry) => {
                        if (entry.success) {
                            succeedRows.push(entry.index);
                        }
                        const index = entry.index + 1;
                        styles = {
                            ...styles,
                            ...getCellStyles(index, columnsLength, entry.success),
                        }
                    });
                    setSpreadsheetStyles({ ...styles, ...succedStyles });
                    const errors = result.filter((entry) => !entry.success);
                    if (errors.length) {
                        setDataErrors(Object.fromEntries(errors.map((entry) => [entry.index + 1, entry.errorMessage])));
                    } else {
                        closeModal();
                        toast.showSuccess(strings.General.SucceedImport);
                    }
                }
            }
        }
        setIsLoading(false);
    };

    const addAdvancedField = (field: string) => {
        const newColumn = advancedColumnsConfig[field];
        setColumns((prev) => [...prev, newColumn]);
    };


    return createPortal(
        <StyledModal onClose={closeModal} isOpen customSize={750}>
            <StyledModalContent
                header={title}
                body={
                    <VFlex style={{ rowGap: theme.sizing.scale400, maxHeight: "60vh", overflowY: "auto" }}>
                        <TemplatesInfo
                            content={templatesContent}
                        />
                        <FileUploader
                            accept={fileFormats}
                            onDrop={(accepted) => {
                                if (accepted.length) {
                                    onValidFile(accepted[0]);
                                } else {
                                    onInvalidFile();
                                }
                            }}
                            errorMessage={error}
                            onRetry={() => setError("")}
                            multiple={false}
                            maxSize={undefined}
                        />
                        {!unvisibleFile && hasAnyVisibleFormat && (
                            <>
                                <SpreadSheet
                                    options={{
                                        data: data,
                                        columns: columns,
                                        minDimensions: [undefined, 10],
                                        contextMenu: () => {},
                                        onselection: (_, startColumnIndex, startRowIndex, endColumnIndex, endRowIndex) => {
                                            const column = columns[startColumnIndex];
                                            if (column.type === "autocomplete" || column.type === "dropdown") {
                                                const cell = spreadSheetRef.current?.jspreadsheet?.records[startRowIndex][startColumnIndex];
                                                cell.onclick = function() {
                                                    spreadSheetRef.current?.jspreadsheet?.openEditor(cell, true, undefined);
                                                };
                                            }
                                        },
                                        style: spreadsheetStyles,
                                    }}
                                    maxHeight={30}
                                    advancedFields={getAdvancedColumnsOptions(columns)}
                                    onAddAdvancedField={addAdvancedField}
                                    onAddCustomField={addCustomField}
                                    ref={spreadSheetRef}
                                />
                                {dataErrors && (
                                    <div className={css({
                                        maxHeight: "60px",
                                        overflowY: "auto",
                                    })}>
                                        {Object.entries(dataErrors).map(([key, value]) => (
                                            <ParagraphSmall margin={0} key={key}>
                                                <span
                                                    className={css({ ...theme.typography.LabelSmall })}>{strings.General.Row} {key}:</span> {value}
                                            </ParagraphSmall>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                        {unvisibleFile && (
                            <Alert showIcon type={"info"} message={strings.General.FileToUpload}
                                   description={unvisibleFile.name}/>
                        )}
                    </VFlex>
                }
                footer={
                    <>
                        <SpacerExpand/>
                        <Button onClick={closeModal} size="compact" kind="tertiary" overrides={cancelButtonStyles}>
                            {strings.General.Cancel}
                        </Button>
                        <Button onClick={startImport} size="compact" isLoading={isLoading}>
                            {strings.General.StartImporting}
                        </Button>
                    </>
                }
            />
        </StyledModal>,
        document.body
    )
}