import * as React from "react";
import { useEffect, useState } from "react";
import { Check } from "../../types/checks";
import { getChecks } from "../../services/api";
import { useStrings } from "../../hooks/useStrings";
import { DAY } from "../../utils/time";
import { VariableSizeList as List } from "react-window";
import { default as AutoSizer } from "react-virtualized-auto-sizer";
import { CheckRow } from "./CheckRow";
import { useTheme } from "../../hooks/useTheme";
import { Spin } from "antd";
import { EmptyState } from "../ui/EmptyState";
import { Timestamp, Uid } from "../../types/core";

export function ChecksTable({ uid, isPlace = false }: { uid: Uid, isPlace?: boolean }) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const [checks, setChecks] = useState<Check[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (uid) {
            getChecks({ uid, fromTs: (Date.now() - (DAY * 30)) as Timestamp }).then(resp => {
                if (resp.status) {
                    setChecks(resp.checks.reverse());
                }
                setLoading(false);
            })
        }
    }, [uid]);

    if (loading) {
        return (
            <EmptyState>
                <Spin/>
            </EmptyState>
        );
    }

    if (checks.length === 0) {
        return (
            <EmptyState title={strings.General.Empty}/>
        );
    }

    return (
        <div style={{
            flex: 1,
            marginTop: "8px",
            marginBottom: "8px",
            height: "90%",
            borderStyle: "solid",
            borderRadius: "4px",
            borderColor: theme.colors.backgroundSecondary,
            padding: "4px",
        }}>

            <AutoSizer>
                {({ height, width }) => {
                    return (
                        <List width={width}
                              height={height}
                              itemCount={checks.length}
                              itemSize={() => 48}
                              itemData={checks}>
                            {CheckRow}
                        </List>
                    )
                }}
            </AutoSizer>
        </div>
    );
}