import { StyleObject } from 'styletron-react';
import { VFlex } from './VFlex';
import { useTheme } from "../../hooks/useTheme";

type Props = {
    children: React.ReactNode;
    style?: StyleObject;
}

export function SectionedWrapper({ children, style }: Props) {
    const { theme } = useTheme();

    return (
        <VFlex
            style={{
                rowGap: "8px",
                ...style,
            }}
        >
            {children}
        </VFlex>
    )
}