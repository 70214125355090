import {
    PROGRESS_STATUS_ACCEPTED,
    PROGRESS_STATUS_IN_PROGRESS,
    PROGRESS_STATUS_ISSUE,
    PROGRESS_STATUS_SCHEDULED,
    PROGRESS_STATUS_SUCCESS
} from "../../types/job";
import { useJobInfo } from "../../hooks/jobs/useJobInfo";
import { useStrings } from "../../hooks/useStrings";
import { StyledSelect } from "../ui/StyledSelect";

type Props = {
    progress: number;
    onSelect: (progress?: number) => void;
    size?: "mini" | "compact";
};

export function JobProgressStatusSelect({ progress, onSelect, size = "compact" }: Props) {
    const { strings } = useStrings();
    const jobInfo = useJobInfo();
    const option = (progress: number) => {
        return {
            id: progress,
            label: jobInfo.getStatusTextFromProgress(progress)
        }
    }
    const options = [
        option(PROGRESS_STATUS_SCHEDULED),
        option(PROGRESS_STATUS_ACCEPTED),
        option(PROGRESS_STATUS_IN_PROGRESS),
        option(PROGRESS_STATUS_SUCCESS),
        option(PROGRESS_STATUS_ISSUE),
    ];
    const value = progress > 0 ? [option(progress)] : [];
    const getLabel = ({ option }: any) => option.label;

    return (
        <StyledSelect
            options={options}
            value={value}
            onChange={params => {
                onSelect(params.option ? params.option.id as number : undefined)
            }}
            getOptionLabel={getLabel}
            getValueLabel={getLabel}
            placeholder={strings.General.ProgressStatus}
            multi={false}
            size={size}
        />
    );
}