import { useRecoilState, useRecoilValue } from "recoil";
import { selectedReportConfigState, selectedReportNotifyState } from "../../states/reportState";
import { useStrings } from "../../hooks/useStrings";
import { MembersSelect } from "../pickers/MembersSelect";
import { Member } from "../../types/member";

export function ReportNotifyStep() {
    const { strings } = useStrings();
    const reportConfig = useRecoilValue(selectedReportConfigState);
    const [notify, setNotify] = useRecoilState(selectedReportNotifyState);

    if (!reportConfig) {
        return null;
    }

    return (
        <MembersSelect
            selectedMemberUids={notify.map(m => m.uid)}
            placeholder={strings.Members.SelectMembers}
            onMemberSelect={(members) => {setNotify(members as Member[])}}
            admins
            operators
            workers
        />
    );
}