import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { VFlex } from '../containers/VFlex';
import React from "react";
import { useStrings } from "../../hooks/useStrings";
import { SequenceList } from "../dispatch/sequences/SequenceList";
import MemberList from "../member/MemberList";
import { DispatchTreeActiveCard, dispatchTreeActiveCardState, expandableSectionState } from "../../states/viewState";
import DispatchTreeItem from "./DispatchTreeItem";
import { LabelSmall } from "baseui/typography";
import { boardMemberUidsState, boardVisibleSequencesState } from "../../states/boardState";
import { useOnboardRefs } from "../../hooks/onboard/useOnboardRefs";
import { BookmarkIcon, MultiRouteIcon, PinIcon, TableIcon, UnassignIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { HFlex } from "../containers/HFlex";
import { Droppable } from 'react-beautiful-dnd';
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { SequenceSearch } from "./sequences/SequenceSearch";
import './dispatchTreeStyles.css'
import { Section } from "../ui/Section";
import { MemberSearch } from "../member/MemberSearch";
import { MemberFilter } from "../member/MemberFilter";
import { DROPPABLE_TYPE_JOB, DROPPABLE_UNASSIGNEDLIST } from "../../constants/dnd";
import { Badge } from "antd";
import { jobsForUnassignedState, pinnedJobsState } from "../../states/jobsState";

export function DispatchTree() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const [activeCard, setActiveCard] = useRecoilState(dispatchTreeActiveCardState);
    const resetCard = useResetRecoilState(dispatchTreeActiveCardState);
    const resetMemberUids = useResetRecoilState(boardMemberUidsState);
    const resetVisibleSequences = useResetRecoilState(boardVisibleSequencesState);
    const { jobListRef } = useOnboardRefs();
    const isMembersExpanded = useRecoilValue(expandableSectionState({
        sectionKey: "dispatch_members",
        defaultExpanded: true,
    }));
    const isSequencesExpanded = useRecoilValue(expandableSectionState({
        sectionKey: "dispatch_sequences",
        defaultExpanded: false
    }));
    const pinned = useRecoilValue(pinnedJobsState);
    const unassigned = useRecoilValue(jobsForUnassignedState);

    const onClick = (card: DispatchTreeActiveCard) => {
        resetMemberUids();
        resetVisibleSequences();
        setActiveCard(activeCard !== card ? card : undefined);
    };

    const onAdd = (card: DispatchTreeActiveCard) => {
        setActiveCard(activeCard !== card ? card : undefined);
    };

    return (
        <VFlex spacing style={{ flexGrow: 1, overflowY: "auto" }}>
            <Section defaultExpanded persistenceKey={"dispatch_jobs"} title={strings.General.Jobs} expandable>
                <DispatchTreeItem
                    ref={jobListRef}
                    isSelected={activeCard === "jobs"}
                    onClick={() => onClick("jobs")}
                    onAdd={() => onAdd("jobs")}
                    onRemove={() => resetCard()}
                >
                    <HFlex spacing alignCenter>
                        <TableIcon size={14} color={theme.colors.contentPrimary} style={{ flexShrink: 0 }}/>
                        <LabelSmall>{strings.General.AllJobs}</LabelSmall>
                    </HFlex>
                </DispatchTreeItem>
                <Droppable
                    droppableId={DROPPABLE_UNASSIGNEDLIST}
                    type={DROPPABLE_TYPE_JOB}
                    direction="horizontal"
                >
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            <DispatchTreeItem
                                isSelected={activeCard === "unassigned"}
                                onClick={() => onClick("unassigned")}
                                onAdd={() => onAdd("unassigned")}
                                onRemove={() => resetCard()}
                                isDraggingOver={snapshot.isDraggingOver}
                            >
                                <VFlex>
                                    <HFlex spacing alignCenter>
                                        <UnassignIcon size={14} color={theme.colors.contentPrimary}
                                                      style={{ flexShrink: 0 }}/>
                                        <LabelSmall>{strings.Dispatch.UnassignedJobs}</LabelSmall>
                                        {unassigned.length > 0 && <Badge color={theme.colors.contentPrimary}
                                                                         count={unassigned.length}
                                                                         overflowCount={9999}/>}
                                    </HFlex>
                                    <div style={{ maxHeight: "0px" }}>{provided.placeholder}</div>
                                </VFlex>
                            </DispatchTreeItem>
                        </div>
                    )}
                </Droppable>
                <DispatchTreeItem
                    isSelected={activeCard === "pinned"}
                    onClick={() => onClick("pinned")}
                    onAdd={() => onAdd("pinned")}
                    onRemove={() => resetCard()}
                >
                    <HFlex spacing alignCenter>
                        <PinIcon size={14} color={theme.colors.contentPrimary} style={{ flexShrink: 0 }}/>
                        <LabelSmall>{strings.Dispatch.PinnedJobs}</LabelSmall>
                        {pinned.length > 0 && <Badge color={theme.colors.contentPrimary}
                                                     count={pinned.length}/>}
                    </HFlex>
                </DispatchTreeItem>
                <DispatchTreeItem
                    isSelected={activeCard === "templates"}
                    onClick={() => onClick("templates")}
                    onAdd={() => onAdd("templates")}
                    onRemove={() => resetCard()}
                >
                    <HFlex spacing alignCenter>
                        <BookmarkIcon size={14} color={theme.colors.contentPrimary} style={{ flexShrink: 0 }}/>
                        <LabelSmall>{strings.JobTemplates.JobTemplates}</LabelSmall>
                    </HFlex>
                </DispatchTreeItem>
                <DispatchTreeItem
                    ref={jobListRef}
                    isSelected={activeCard === "multiroute"}
                    onClick={() => onClick("multiroute")}
                    onAdd={() => onAdd("multiroute")}
                    onRemove={() => resetCard()}
                >
                    <HFlex spacing alignCenter>
                        <MultiRouteIcon size={14} color={theme.colors.contentPrimary} style={{ flexShrink: 0 }}/>
                        <LabelSmall>Multi-Route Optimization</LabelSmall>
                    </HFlex>
                </DispatchTreeItem>
            </Section>
            <Section defaultExpanded persistenceKey={"dispatch_members"} title={isMembersExpanded ?
                <HFlex alignCenter>
                    {strings.General.Members}
                    <SpacerExpand/>
                    <div onClick={event => {event.stopPropagation()}}>
                        <MemberSearch autoShrink style={{ height: "22px" }}/>
                    </div>
                    <div onClick={event => {event.stopPropagation()}}>
                        <MemberFilter/>
                    </div>
                </HFlex> : strings.General.Members}
                     expandable
                     collapsableStye={{
                         flex: isMembersExpanded ? 1 : 'none',
                         display: "flex",
                         flexDirection: "column"
                     }}
                     panelStye={{ flex: 1, display: isMembersExpanded ? "flex" : "block", flexDirection: "column" }}
            >
                <MemberList/>
            </Section>
            <Section persistenceKey={"dispatch_sequences"}
                     title={isSequencesExpanded ?
                         <HFlex alignCenter>
                             {strings.Sequences.Sequences}
                             <SpacerExpand/>
                             <SequenceSearch/>
                         </HFlex> : strings.Sequences.Sequences} 
                     collapsableStye={{
                        flex: isSequencesExpanded ? 1 : 'none',
                        display: "flex",
                        flexDirection: "column",
                     }}
                     panelStye={{ flex: 1, display: isSequencesExpanded ? "flex" : "block", flexDirection: "column" }}
                     expandable>
                {isSequencesExpanded && (
                    <SequenceList/>
                )}
            </Section>
            <Spacer/>
        </VFlex>
    );
}