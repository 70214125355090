import { OnChangeParams } from 'baseui/select';
import { useRecoilValue } from 'recoil';
import { memberState, memberUidsState } from '../../states/membersState';
import { isAdmin, isOperator, isWorker, Member } from '../../types/member';
import { StyledSelect } from '../ui/StyledSelect';
import { useStrings } from '../../hooks/useStrings';
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { sortMembers } from "../../utils/memberUtils";
import { Uid } from "../../types/core";

type Props = {
    placeholder: string;
    onMemberSelect: (members: Member[] | "") => void;
    selectedMemberUids?: Uid[] | "";
    size?: 'mini' | 'compact';
    admins?: boolean;
    operators?: boolean;
    workers?: boolean;
    hideUnassigned?: boolean;
};

export function MembersSelect({
                                  placeholder,
                                  onMemberSelect,
                                  selectedMemberUids = [],
                                  size = 'compact',
                                  admins = true,
                                  operators = true,
                                  workers = true,
                                  hideUnassigned = true,
                              }: Props) {
    const { strings } = useStrings();
    const memberUids = useRecoilValue(memberUidsState);

    const members = memberUids
        .map(uid => getRecoil(memberState(uid)))
        .filter(m => {
            return m
                && (workers || !isWorker(m.role))
                && (operators || !isOperator(m.role))
                && (admins || !isAdmin(m.role))
        })
        .sort((a, b) => sortMembers(a as Member, b as Member)) as Member[]
    ;
    const unassignedOption = { label: strings.JobStatuses.Unassigned, id: '' }
    const option = (member: Member) => ({ id: member.id, label: member.name || member.username, member });
    const options = members.map(option);
    const selectedMembers = selectedMemberUids === ""
        ? ""
        : selectedMemberUids.map(uid => getRecoil(memberState(uid))).filter(m => !!m) as Member[]
    const value = selectedMembers
        ? selectedMembers.map(option)
        : selectedMembers === ""
            ? [unassignedOption]
            : [];

    const handleMemberSelect = (params: OnChangeParams) => {
        if (params.type === 'clear') {
            onMemberSelect([]);
        } else if (params.type === 'remove') {
            const newMembers = selectedMembers ? selectedMembers.filter((m) => m.id !== params.option?.id) : [];
            onMemberSelect(newMembers);
        } else if (params.type === 'select' && params.option?.member) {
            onMemberSelect(selectedMembers ? [...selectedMembers, params.option.member] : [params.option.member]);
        } else if (params.type === 'select' && params.option?.id === "") {
            onMemberSelect("");
        }
    };

    const selectOptions = hideUnassigned ? options : [unassignedOption, ...options];

    return (
        <StyledSelect
            options={selectOptions}
            value={value}
            onChange={handleMemberSelect}
            placeholder={placeholder}
            size={size}
            multi
        />
    );
}