import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import { StyledBody } from "baseui/card";
import { Checkbox, CheckboxOverrides } from "baseui/checkbox";
import { ParagraphSmall } from "baseui/typography";
import { useState } from "react";
import Draggable from "react-draggable";
import { useSetRecoilState } from "recoil";
import { useDraggable } from "../../hooks/useDraggable";
import { useOnboardSteps } from "../../hooks/onboard/useOnboardSteps";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { onboardDisabledState, onboardHiddenState } from "../../states/onboardState";
import { VFlex } from "../containers/VFlex";
import { ConfirmModal } from "../ui/ConfirmModal";
import { StyledCard } from "../ui/StyledCard";
import { CloseIcon } from "../ui/svg";
import { analyticsOnboard } from "../../utils/analytics";
import { Spacer } from "../containers/Spacer";

export function OnboardSteps() {
    const { css, theme } = useTheme();
    const { dragStart, bounds, disabled, draggleRef, setDisabled } = useDraggable();
    const { onboardSteps } = useOnboardSteps();
    const { strings, format } = useStrings();
    const [forceClose, setForceClose] = useState(false);
    const [showCloseModal, setShowCloseModal] = useState(false);
    const setOnboardDisabled = useSetRecoilState(onboardDisabledState);
    const setOnboardHidden = useSetRecoilState(onboardHiddenState);

    const floatingStyles = css({
        position: "absolute",
        overflow: "hidden",
        right: "50px",
        top: "100px",
        maxWidth: "350px",
    });

    const checkboxOverrides = (checked: boolean): CheckboxOverrides => ({
        Checkmark: {
            style: () => ({
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                backgroundColor: checked ? theme.customColors.accent : "inherit",
            }),
        },
        Label: {
            style: {
                color: checked ? theme.colors.contentTertiary : theme.colors.contentPrimary,
                textDecoration: checked ? "line-through" : undefined,
            },
        },
    });

    const closeOnboard = () => {
        if (forceClose) {
            setOnboardDisabled(true);
        } else {
            setOnboardHidden(true);
        }
    }

    return (
        <Draggable disabled={disabled} bounds={bounds} onStart={(event, uiData) => dragStart(event, uiData)}>
            <div ref={draggleRef} className={floatingStyles}>
                <StyledCard
                    title={
                        <span
                            className={css({
                                cursor: "move",
                                userSelect: "none",
                            })}
                            onMouseOver={() => {
                                if (disabled) {
                                    setDisabled(false);
                                }
                            }}
                            onMouseOut={() => {
                                setDisabled(true);
                            }}
                        >
                            {strings.Onboard.Header}
                        </span>
                    }
                    actions={
                        <Button size={SIZE.mini} shape={SHAPE.circle} onClick={() => setShowCloseModal(true)}
                                kind={KIND.tertiary}>
                            <CloseIcon color={theme.colors.backgroundInversePrimary}/>
                        </Button>
                    }
                >
                    <StyledBody>
                        <VFlex style={{ rowGap: theme.sizing.scale300 }}>
                            {onboardSteps.map((step) => (
                                <Checkbox
                                    key={step.id}
                                    checked={step.checked}
                                    onMouseUp={() => {
                                        analyticsOnboard(step.id);
                                        step.onClick();
                                    }}
                                    overrides={checkboxOverrides(step.checked)}
                                >
                                    {`${step.nr}) ${step.title}`}
                                </Checkbox>
                            ))}
                            <Spacer/>
                            <video controls style={{ borderRadius: "8px" }}>
                                <source src="https://storage.googleapis.com/ht-web/intro-v1.mp4" type="video/mp4" />
                            </video>
                        </VFlex>
                    </StyledBody>
                </StyledCard>
                <ConfirmModal
                    isOpen={showCloseModal}
                    title={strings.Onboard.CloseHeader}
                    description={
                        <VFlex>
                            <ParagraphSmall marginTop={0}>{strings.Onboard.CloseDescription}</ParagraphSmall>
                            <Checkbox checked={forceClose} onChange={(e) => setForceClose(e.target.checked)}>
                                {format(strings.Onboard.DontShowXAgain, strings.Onboard.Header)}
                            </Checkbox>
                        </VFlex>
                    }
                    onConfirm={closeOnboard}
                    onCancel={() => setShowCloseModal(false)}
                    confirmText={strings.General.Close}
                    cancelText={strings.General.Cancel}
                />
            </div>
        </Draggable>
    );
}
