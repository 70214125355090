import { useRecoilValue, useSetRecoilState } from "recoil";
import { memberTracksLayerState, tracksLayerState } from "../../states/geojsonState";
import { fetchGeoJson } from "../../services/geojson";
import { requestingState } from "../../states/viewState";
import { setRecoil } from "../../providers/RecoilAccessProvider";
import { tracksDatesState } from "../../states/tracksState";
import { dateToDayBrowserTimezone } from "../../utils/time";
import { Day, Uid } from "../../types/core";

export function useTracksLayerUpdater() {
    const setTracksLayerRequesting = useSetRecoilState(requestingState({ req: "tracks" }));
    const tracksDates = useRecoilValue(tracksDatesState);

    const update = (uid: Uid, day: Day) => {
        setTracksLayerRequesting(true);
        fetchGeoJson("tracks", "member=" + uid + "&day=" + day + "&markers=true&locations=true")
            .then(result => result?.json())
            .then(json => setRecoil(tracksLayerState({ uid: uid, day: day }), json))
            .finally(() => setTracksLayerRequesting(false));
    };

    const updateMemberTracks = (uid: Uid, fromDay: Day, untilDay: Day) => {
        setTracksLayerRequesting(true);
        return fetchGeoJson("tracks", "member=" + uid + "&fromDay=" + fromDay + "&untilDay=" + untilDay
            + "&stops=true&markers=true&locations=true")
            .then(result => result?.json())
            .then(json => setRecoil(memberTracksLayerState({ uid }), json))
            .finally(() => setTracksLayerRequesting(false));
    };

    const updateMemberTracksSameDates = async (account: Uid) => {
        if (account && !!tracksDates[0] && !!tracksDates[1]) {
            await updateMemberTracks(account,
                dateToDayBrowserTimezone(tracksDates[0]!),
                dateToDayBrowserTimezone(tracksDates[1]!)
            );
        }
    }

    return { update, updateMemberTracks, updateMemberTracksSameDates };
}