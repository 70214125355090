import { LabelSmall, LabelXSmall } from "baseui/typography";
import React, { memo, useRef, useState } from "react";
import { DroppableProvided } from "react-beautiful-dnd";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useBoard } from "../../hooks/useBoard";
import { useStrings } from "../../hooks/useStrings";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { useTheme } from "../../hooks/useTheme";
import { dayState } from "../../states/appState";
import { jobsByMemberDayState } from "../../states/jobsState";
import { memberState } from "../../states/membersState";
import { HFlex } from "../containers/HFlex";
import { NoWrapText } from "../containers/NoWrapText";
import { VFlex } from "../containers/VFlex";
import { Progress, Tag } from "antd";
import { getColorFromProgress } from "../../utils/jobUtils";
import MemberAvatar from "./MemberAvatar";
import { dispatchTreeActiveCardState } from "../../states/viewState";
import { isDone } from "../../types/job";
import { SpacerExpand } from "../containers/Spacer";
import { useListItem } from "../../hooks/useListItem";
import { ClickableListItem } from "../ui/ClickableListItem";
import { boardMemberIsPresentState } from "../../states/boardState";
import { EtaBadge } from "../badges/EtaBadge";
import { dayOfToday } from "../../utils/time";
import MemberItemHoverButtons from "./MemberItemHoverButtons";
import { Uid } from "../../types/core";

type Props = {
    uid: Uid;
    isDraggingOver: boolean;
    provided: DroppableProvided;
};

function DroppableMemberItem({ uid, isDraggingOver, provided }: Props) {
    const { theme } = useTheme();
    const { strings, format } = useStrings();
    const member = useRecoilValue(memberState(uid));
    const isSelected = useRecoilValue(boardMemberIsPresentState(uid));
    const board = useBoard();
    const [visibleOptions, setVisibleOptions] = useState(false);
    const { labelEllipsisStyles } = useStyleOverrides();
    const day = useRecoilValue(dayState);
    const jobs = useRecoilValue(jobsByMemberDayState({ uid, day }));
    const jobsCount = jobs.length;
    const progressStepsColors = jobs.map(j => getColorFromProgress(j));
    const percent = jobsCount > 0 ? 100 * jobs.filter(j => isDone(j)).length / jobsCount : 0;
    const resetDispatchCard = useResetRecoilState(dispatchTreeActiveCardState);
    const ref = useRef(null);
    const { listItemHeightPx } = useListItem();
    const isToday = day === dayOfToday();

    if (!member) {
        return null;
    }

    const onMemberClick = (event: React.MouseEvent<HTMLDivElement>) => {
        resetDispatchCard();
        if (event.metaKey) {
            board.add(member.uid);
        } else if (board.memberUids.includes(member.uid)) {
            board.remove(member.uid);
        } else {
            board.setMemberUids([member.uid]);
        }
    };

    return (
        <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
                maxHeight: listItemHeightPx,
            }}
        >
            <ClickableListItem
                isSelected={isSelected}
                isDraggingOver={isDraggingOver}
                ref={ref}
                onClick={(event) => onMemberClick(event)}
                shrink
                onHover={() => <MemberItemHoverButtons uid={member.uid}/>}
                onHoverChange={(isHover) => setVisibleOptions(isHover)}
            >
                <HFlex
                    alignCenter
                    style={{
                        flexGrow: 1,
                        flexShrink: 1,
                        flexBasis: "0%",
                        overflow: "hidden",
                        justifyContent: "space-between",
                        width: "200px",
                        columnGap: theme.sizing.scale200,
                    }}
                >
                    <MemberAvatar uid={member.uid} forMap={false} size={14}/>
                    <NoWrapText>
                        <LabelSmall margin={0} overrides={labelEllipsisStyles}>
                            {member.name || member.username}
                        </LabelSmall>
                    </NoWrapText>
                    <EtaBadge visible={isToday} uid={uid}/>
                    {!visibleOptions && jobsCount > 0 && (
                        <VFlex spacing={"0px"} style={{ alignItems: "end", flexBasis: "auto" }}>
                            <NoWrapText>
                                <LabelXSmall style={{ fontSize: "11px" }}>
                                    {jobsCount === 1
                                        ? strings.Job.OneJob
                                        : format(strings.Job.XJobs, jobsCount)}
                                </LabelXSmall>
                            </NoWrapText>
                            <HFlex alignCenter>
                                <SpacerExpand/>
                                {percent > 0 && percent < 100 && (
                                    <Tag style={{
                                        fontSize: "9px",
                                        lineHeight: "11px",
                                        paddingBottom: "0px",
                                        paddingTop: "1px",
                                        paddingLeft: "2px",
                                        paddingRight: "2px"
                                    }}>
                                        {Math.round(percent)}%
                                    </Tag>
                                )}
                                {jobsCount < 25 &&
                                    <Progress percent={100}
                                              steps={jobsCount}
                                              size="small"
                                              strokeColor={progressStepsColors}
                                              showInfo={percent == 100}
                                              style={{}}
                                    />
                                }
                            </HFlex>
                        </VFlex>
                    )}
                </HFlex>
            </ClickableListItem>
            <div style={{ maxHeight: "20px" }}>{provided.placeholder}</div>
        </div>
    );
}

export default memo(DroppableMemberItem);
