import { HFlex } from "../containers/HFlex";
import { useStrings } from "../../hooks/useStrings";
import { SpacerExpand } from "../containers/Spacer";
import { Button } from "baseui/button";
import { Plus } from "baseui/icon";
import React from "react";
import { useSetRecoilState } from "recoil";
import useAccount from "../../hooks/useAccount";
import { selectedAlertConfigState } from "../../states/alertsState";
import { AlertConfig } from "../../types/alerts";
import { emptyAlertConfig } from "../../constants/alerts";
import { EMPTY_UID } from "../../types/core";

export function AlertsTitleBar() {
    const { strings } = useStrings();

    const setSelectedAlertConfig = useSetRecoilState(selectedAlertConfigState);
    const { company } = useAccount();

    const onNewClick = () => {
        const newAlertConfig: AlertConfig = {
            ...emptyAlertConfig,
            companyUid: company ? company.uid : EMPTY_UID
        }
        setSelectedAlertConfig(newAlertConfig);
    };


    return (
        <HFlex alignCenter>
            {strings.Alerts.Alerts}
            <SpacerExpand/>
            <Button size={"mini"} kind={"primary"} onClick={onNewClick}>
                <Plus/> {strings.Alerts.AlertNewConfig}
            </Button>
        </HFlex>
    );
}