import { Account, CustomField, Latitude, Longitude, Timestamp } from './core';
import { CSV_EXTENSION, EXCEL_EXTENSION, JSON_EXTENSION, KML_EXTENSION } from "../constants/app";
import { TRUNCATE_LENGTH_DEFAULT, truncateText } from '../utils/strings';

export interface Place extends Account {
    address: string;
    radius: number;
    color: string;
    latitude: Latitude;
    longitude: Longitude;
    created: Timestamp;
    teams: number[];
    city: string;
    country_code: string;
    postalcode: string;
    state: string;
    extended_data: CustomField[],
    linkedForms: string,
}

export interface PlaceListItem extends Place {
    selected: boolean;
}

export type ImportPlaceFileFormat =
    typeof CSV_EXTENSION
    | typeof EXCEL_EXTENSION
    | typeof KML_EXTENSION
    | typeof JSON_EXTENSION;

export const getTruncatedPlaceName = (place: Place) => {
    return place.name && place.name.length > TRUNCATE_LENGTH_DEFAULT ? truncateText(place.name) : place.name;
}
