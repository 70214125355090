import { ApprovalStatus, TrackEntry } from "../../types/track";
import { useStrings } from "../../hooks/useStrings";
import { Tag } from "antd";
import React from "react";

export function TrackApprovalBadge({ track }: { track: TrackEntry }) {

    const { strings } = useStrings();

    if (track?.approvalStatus === ApprovalStatus.NOTIFIED) {
        return <Tag color={"blue"} style={{ borderStyle: "none" }}>{strings.Approval.Notified}</Tag>;
    } else if (track?.approvalStatus === ApprovalStatus.READY) {
        return <Tag color={"orange"} style={{ borderStyle: "none" }}>{strings.Approval.Pending}</Tag>;
    } else if (track?.approvalStatus === ApprovalStatus.APPROVED) {
        return <Tag color={"green"} style={{ borderStyle: "none" }}>{strings.Approval.Approved}</Tag>;
    } else if (track?.approvalStatus === ApprovalStatus.DISAPPROVED) {
        return <Tag color={"red"}  style={{ borderStyle: "none" }}>{strings.Approval.Disapproved}</Tag>;
    }
    return <Tag style={{ borderStyle: "none" }}>{strings.General.NotSet}</Tag>;
}