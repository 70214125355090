import { Button } from "baseui/button";
import { LabelMedium, LabelXSmall } from "baseui/typography";
import { useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useRecoilValue } from "recoil";
import { useEditJobLocation } from "../../hooks/jobs/useEditJobLocation";
import { useEditTeamLocation } from "../../hooks/members/useEditTeamLocation";
import { useNewJob } from "../../hooks/jobs/useNewJob";
import { useNewPlace } from "../../hooks/places/useNewPlace";
import { useTheme } from "../../hooks/useTheme";
import { DetailedLocation } from "../../types/core";
import { HFlex } from "../containers/HFlex";
import { useEditMemberRouting } from "../../hooks/members/useEditMemberRouting";
import { Option, UniversalPicker } from "../pickers/UniversalPicker";
import { useStrings } from "../../hooks/useStrings";
import { useLocationAttachment } from "../../hooks/useLocationAttachment";
import { Alert } from "antd";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { EditIcon } from "../ui/svg";
import { useCamera } from "../../hooks/useCamera";
import { useEscTrigger } from "../../hooks/useEscTrigger";
import { locationDraggingState } from "../../states/drawModeState";
import { useDrawMode } from "../../hooks/useDrawMode";
import { useEditPlaceLocation } from "../../hooks/places/useEditPlaceLocation";
import { NoWrapText } from "../containers/NoWrapText";
import { useEditCompanyLocation } from "../../hooks/company/useEditCompanyLocation";
import { PlaceBadge } from "../badges/PlaceBadge";

export function NavbarLocationPicker() {
    const { strings, format } = useStrings();
    const { createNewPlace } = useNewPlace();
    const { createNewJob } = useNewJob();
    const [isLoading, setIsLoading] = useState(false);
    const locationDragging = useRecoilValue(locationDraggingState);
    const { editJob } = useEditJobLocation();
    const { editPlace } = useEditPlaceLocation();
    const { editTeam, cancelEditTeam } = useEditTeamLocation();
    const { editMemberRouting } = useEditMemberRouting();
    const { editCompany } = useEditCompanyLocation();
    const { setAttachment } = useLocationAttachment();
    const { map } = useMap();
    const { isDarkTheme, theme } = useTheme();
    const camera = useCamera();
    const { drawMode, reset, updateLocation } = useDrawMode();

    const close = () => {
        console.log("resetting draw mode")
        reset();
    }
    useEscTrigger(close);

    useEffect(() => {
        if (drawMode.location) {
            camera.zoomTo({ lat: drawMode.location.lat, lng: drawMode.location.lng });
        }
    }, [drawMode.location]);

    useEffect(() => {
        if (!map) {
            return;
        }
        map.getCanvas().style.cursor = locationDragging ? "unset" : "crosshair";
        return () => {
            map.getCanvas().style.cursor = "grab";
        }
    }, [map, locationDragging]);

    useEffect(() => {
        return () => {
            // cleanup
            cancelEditTeam();
            reset();
        }
    }, []);

    const title = () => {
        if (drawMode.type === "place" && drawMode.action === "new") {
            return strings.Places.NewPlace;
        } else if (drawMode.type === "place" && drawMode.action === "edit") {
            return strings.Places.EditPlace;
        } else if (drawMode.type === "job" && drawMode.action === "new") {
            return strings.Job.NewJob;
        } else if (drawMode.type === "job" && drawMode.action === "edit") {
            return format(strings.General.EditX, strings.General.Job);
        } else if (drawMode.type === "team") {
            return format(strings.General.EditX, strings.General.Team);
        } else if (drawMode.type === "start" || drawMode.type === "end") {
            return format(strings.General.EditX, strings.General.MemberRouting);
        } else if (drawMode.type === "attachment") {
            return strings.General.AttachLocation;
        } else {
            return "";
        }
    };

    const description = () => {
        if (drawMode.type === "place" && drawMode.action !== "edit") {
            return drawMode.location ? strings.General.NavLocationPlaceHelp : strings.General.NavLocationPlaceDesc;
        }
        if (drawMode.type === "job" && !drawMode.location) {
            return strings.General.NavLocationGeneralDesc;
        }
        return undefined;
    }

    const onSave = async () => {
        setIsLoading(true);
        if (drawMode.location) {
            switch (drawMode.type) {
                case "place":
                    if (drawMode.action === "new") {
                        createNewPlace(drawMode.location, drawMode.radius);
                    } else {
                        editPlace();
                    }
                    break;
                case "job":
                    if (drawMode.action === "new") {
                        createNewJob();
                    } else {
                        editJob();
                    }
                    break;
                case "team":
                    editTeam();
                    break;
                case "start":
                case "end":
                    editMemberRouting();
                    break;
                case "attachment":
                    setAttachment();
                    break;
                case "company":
                    editCompany();
                    break;
            }
        }
        setIsLoading(false);
        close();
    };

    const onOptionSelected = (option: Option) => {
        if (option.location) {
            updateLocation(option.location);
        } else if (option.place) {
            const p = option.place;
            updateLocation({
                display: p.name,
                name: p.name,
                address: p.address,
                lat: p.latitude,
                lng: p.longitude,
                uid: p.uid,
            } as DetailedLocation)
        }
    }

    const content = (
        <HFlex alignCenter spacing style={{ justifyContent: "center", pointerEvents: "auto" }}>
            <LabelMedium><NoWrapText>{title() + ":"}</NoWrapText></LabelMedium>
            <Spacer width={"32px"}/>
            {drawMode?.location
                ? <HFlex alignCenter spacing style={{ maxWidth: "250px" }}>
                    <LabelXSmall>
                        {drawMode.location.uid
                            ? <PlaceBadge uid={drawMode.location.uid}/>
                            : drawMode.location.address
                        }
                    </LabelXSmall>
                    <Button size={"default"} kind={"secondary"} onClick={() => { updateLocation(undefined) }}>
                        <HFlex spacing alignCenter>
                            <EditIcon size={14} color={theme.colors.contentPrimary}/>
                            {strings.General.Edit}
                        </HFlex>
                    </Button>
                </HFlex>
                : <UniversalPicker
                    placeholder={drawMode.type === "place" ? `${strings.General.FindAddress}...` : `${strings.General.AddressOrPlace}...`}
                    onOptionSelected={onOptionSelected}
                    width="max(20vw, 140px)"
                    job={false}
                    member={false}
                    autoFocus
                    place={drawMode.type !== "place"}
                />
            }
            {drawMode.location && <Button kind={"primary"}
                                          size={"default"}
                                          onClick={onSave}
                                          isLoading={isLoading}>
                {strings.General.OK}
            </Button>
            }
        </HFlex>
    );

    const borderColor = isDarkTheme ? "#00000099" : "#00000033";

    return (
        <HFlex style={{ rowGap: theme.sizing.scale300, pointerEvents: "auto" }}>
            <SpacerExpand/>
            <Alert description={description()}
                   message={content}
                   type={"info"}
                   closable
                   onClose={close}
                   style={{
                       backgroundColor: isDarkTheme ? '#00000055' : '#FFFFFF99',
                       backdropFilter: "blur(50px)",
                       WebkitBackdropFilter: "blur(50px)",
                       borderColor: borderColor,
                   }}
            />
            <SpacerExpand/>
        </HFlex>
    );
}