import { useSetRecoilState } from "recoil";
import { messagesInboxState } from "../states/messagesState";
import { getAllConversations } from "../services/api";

export function useConversations() {

    const setMessagesInbox = useSetRecoilState(messagesInboxState);

    const loadAll = () => {
        getAllConversations().then(({ status, messages }) => {
            if (status) {
                setMessagesInbox(messages);
            }
        });
    }

    return { loadAll };
}