declare function sa_event(eventName: string): void;

type Event = "chatai_userquery"
    | "chatai_suggestionquery"
    | "chatai_cta_signup"
    | "chatai_cta_contactus"
    | "billing_method_started"
    | "billing_method_completed"
    | "assistant_query"
    | "assistant_fulfillment_job"
    | "assistant_fulfillment_location"
    | "assistant_fulfillment_other"
    | "blocked_gain_5_min"
    | "blocked_logout"
    | "reactivation_reactivate"
    | "reactivation_logout"
    | "billing_deactivate_click"
    | "billing_deactivate_confirmed"
    | "activation"
    | "password_recovery"
    | "routing_calc"
    | "routing_optimize"
    | "routing_undo"
    | "routing_multi"
    | "upgrade_back"
    | "upgrade_logout"
    | "upgrade_success"
    ;

export function analyticsEvent(event: Event, data = "") {
    sendEvent(event + (data ? ":" + data : ""));
}

export function analyticsSignup(step: number) {
    sendEvent("signup_step_" + step);
}

export function analyticsCompanySetup(step: number | string) {
    sendEvent("companysetup_" + step);
}

export function analyticsOnboard(id: string) {
    sendEvent("onboard_" + id);
}

function sendEvent(event: string) {
    try {
        sa_event(event);
    } catch (error) {
        console.warn("sa not ready", event);
    }
}