import { SelectedDays } from "../types/days";
import { useStrings } from "./useStrings";


export function useWeekDaysEncoder() {
    const { strings } = useStrings();

    const weekDaysText = (days: number) => {
        let sb = "";
        if (days === 0) {
            sb = strings.General.None;
        } else {
            if ((days & (1 << 0)) > 0) {
                sb += strings.General.WeekdayMo + " ";
            }
            if ((days & (1 << 1)) > 0) {
                sb += strings.General.WeekdayTu + " ";
            }
            if ((days & (1 << 2)) > 0) {
                sb += strings.General.WeekdayWe + " ";
            }
            if ((days & (1 << 3)) > 0) {
                sb += strings.General.WeekdayTh + " ";
            }
            if ((days & (1 << 4)) > 0) {
                sb += strings.General.WeekdayFr + " ";
            }
            if ((days & (1 << 5)) > 0) {
                sb += strings.General.WeekdaySa + " ";
            }
            if ((days & (1 << 6)) > 0) {
                sb += strings.General.WeekdaySu + " ";
            }
        }
        return sb;
    };

    const selectedWeekDays = (weekDays?: number): SelectedDays => ({
        monday: weekDays ? (weekDays & (1 << 0)) > 0 : false,
        tuesday: weekDays ? (weekDays & (1 << 1)) > 0 : false,
        wednesday: weekDays ? (weekDays & (1 << 2)) > 0 : false,
        thursday: weekDays ? (weekDays & (1 << 3)) > 0 : false,
        friday: weekDays ? (weekDays & (1 << 4)) > 0 : false,
        saturday: weekDays ? (weekDays & (1 << 5)) > 0 : false,
        sunday: weekDays ? (weekDays & (1 << 6)) > 0 : false,
    });

    const weekDaysFromSelectedDays = (days: SelectedDays) => {
        let newWeekDays = 0;
        newWeekDays += +days.monday << 0;
        newWeekDays += +days.tuesday << 1;
        newWeekDays += +days.wednesday << 2;
        newWeekDays += +days.thursday << 3;
        newWeekDays += +days.friday << 4;
        newWeekDays += +days.saturday << 5;
        newWeekDays += +days.sunday << 6;
        return newWeekDays;
    }

    const calendarDays = (days: string) => days.length > 0 ? days.split(",").map(d => parseInt(d)) : [];

    return { weekDaysText, calendarDays, selectedWeekDays, weekDaysFromSelectedDays };
}