import { AllJobsFilterRow } from "../components/alljobs/AllJobsFilterRow";
import { AllJobsGrid } from "../components/alljobs/AllJobsGrid";
import { VFlex } from "../components/containers/VFlex";

export function AllJobsView() {
    return (
        <VFlex spacing>
            <AllJobsFilterRow/>
            <AllJobsGrid/>
        </VFlex>
    );
}