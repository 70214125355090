import { API_ENDPOINT, Endpoint, fetchFromServer, getAuthObjWithCredentials, prepareRequest, STATUS_OK } from "./api";
import { Address, Constraints, Day, DetailedLocation, Latitude, Longitude, Uid } from "../types/core";
import { Job } from "../types/job";
import { Billing, Invoice, PaymentSource, PlanPrice } from "../types/billing";
import { getRecoil } from "../providers/RecoilAccessProvider";
import { usernameState } from "../states/accountState";
import { AssistantFulfillment } from "../types/assistant";

export const fetchFromServerStreamed = async (endpoint: Endpoint, dataObj: object, callback: (buffer: string) => void) => {
    const url = API_ENDPOINT + '/' + endpoint;
    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(prepareRequest(dataObj)),
    });
    if (!response.ok || !response.body) {
        return { status: false, data: {}, error: response.status };
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let full = '';
    let done = false;
    while (!done) {
        const { value, done: readerDone } = await reader.read();
        if (value) {
            const chunk = decoder.decode(value, { stream: true });
            full += chunk;
            callback(full);
        }
        if (readerDone) {
            done = true;
        }
    }
    const tailIdx = full.indexOf("[END-STREAM]");
    const tail = full.substring(tailIdx + "[END-STREAM]".length);
    const json = JSON.parse(tail);
    if (json.status === STATUS_OK) {
        return { status: true, data: json };
    } else {
        return { status: false, data: {}, error: json.status };
    }
}

export const webLogin = async (poll: boolean = false, code?: string | undefined) => {
    return fetchFromServer(Endpoint.weblogin, { code, poll });
};

export interface CalculateRouteRequest {
    day: Day;
    account: string;
    clear_upfront: boolean;
    constraints?: Constraints;
}

export interface CalculateRouteResponse {
    status: boolean;
    route_updated: boolean;
    original_order?: string[];
    unassigned?: string[];
    jobs: Job[];
    abort_reason?: string | "no_region" | "impossible_route";
}

export const optimizeRoute = async (req: CalculateRouteRequest) => {
    const response = await fetchFromServer(Endpoint.optimizeroute, req);
    return {
        status: response.status,
        route_updated: response.status ? response.data.route_updated : false,
        original_order: response.data.original_order,
        unassigned: response.data.unassigned,
        jobs: response.data.jobs,
        abort_reason: response.data.abort_reason,
    } as CalculateRouteResponse;
}

export const calculateRoute = async (req: CalculateRouteRequest) => {
    const response = await fetchFromServer(Endpoint.calculateroute, req);
    return {
        status: response.status,
        route_updated: response.status ? response.data.route_updated : false,
        jobs: response.status ? response.data.jobs : [] as Job[],
    } as CalculateRouteResponse;
}

interface Route {
    uid: Uid;
    jobs: Job[];
}

interface OptimizeMultiRouteRequest {
    day: Day;
    uids: Uid[];
    jobs: string[];
    constraints: Constraints;
}

export interface OptimizeMultiRouteResponse {
    status: boolean;
    routes: Route[];
    abortReason: "no_region" | "impossible_route" | string | undefined;
    unassigned: string[];
}

export const optimizeMultiRoute = async (req: OptimizeMultiRouteRequest) => {
    const response = await fetchFromServer(Endpoint.optimizemultiroute, req);
    return {
        status: response.status,
        routes: response.status ? response.data.routes as Route[] : [],
        unassigned: response.status ? response.data.unassigned as string[] : [],
        abortReason: response.status ? response.data.abort_reason as string : undefined,
    } as OptimizeMultiRouteResponse;
}

export const reorderRoute = async ({ day, account, field }: { day: Day; account: string, field: "orderId" | "priority" }) => {
    const response = await fetchFromServer(Endpoint.reorderroute, {
        day,
        account,
        field,
        clear_upfront: true,
    });
    return {
        status: response.status,
        route_updated: response.status ? response.data.route_updated : false,
        jobs: response.status ? response.data.jobs : [] as Job[],
    } as CalculateRouteResponse;
}

interface PlacesAutoCompleteRequest {
    query: string;
}

export const placesAutoComplete = async (request: PlacesAutoCompleteRequest) => {
    const response = await fetchFromServer(Endpoint.placesautocomplete, request);
    return {
        status: response.status,
        results: response.data.results as DetailedLocation[],
    };
}

interface AssignJobRequest {
    id: string;
    day: Day;
    index: number;
    assignee: Uid;
}

export const assignJob = async (req: AssignJobRequest) => {
    const requestData = { ...req, push: true, notify: true, webhook: true };
    const response = await fetchFromServer(Endpoint.assignjob, requestData);
    return {
        status: response.status,
        jobs: response.data.jobs as Job[],
    }
}

interface GeocodeRequest {
    query?: string;
    lat?: number;
    lng?: number;
}

interface GeocodeResponse {
    query: string;
    lat: Latitude;
    lng: Longitude;
    address?: Address;
}

export const geocode = async (req: GeocodeRequest) => {
    const response = await fetchFromServer(Endpoint.geocode, req);
    return {
        status: response.status,
        result: response.data.result as GeocodeResponse,
    }
}
export const geolocatePlaces = async (uids: Uid[]) => {
    const response = await fetchFromServer(Endpoint.geolocateplaces, { "uids": uids });
    return {
        status: response.status,
        count: response.data.count as number,
    }
}

export const notifyRouteChange = async (uids: Uid[]) => {
    const response = await fetchFromServer(Endpoint.notifyroutechange, { "uids": uids });
    return {
        status: response.status,
        count: response.data.count as number,
    }
}

export const getBilling = async () => {
    const response = await fetchFromServer(Endpoint.getbilling, {});
    return {
        status: response.status,
        billing: response.data.billing as Billing,
    }
}

export const setCompanyActive = async (active: boolean, data: any = {}) => {
    const response = await fetchFromServer(Endpoint.setcompanyeactive, { active: active, ...data });
    return { status: response.status };
}

export const checkPassword = async (plainPassword: string) => {
    const username = getRecoil(usernameState);
    const url = API_ENDPOINT + '/' + Endpoint.weblogin;
    const requestBody = {
        auth: { ...getAuthObjWithCredentials(username, plainPassword) },
        data: { poll: true },
        target: "base",
    };
    const result = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(requestBody),
    });
    const json = await result.json();
    return json.status === STATUS_OK;
}

export interface UserAvailabilityResponse {
    status: boolean;
    available?: boolean;
    authUrl?: string;
    company?: string;
    employee?: boolean;
}

export const checkUserAvailability = async (data: any = {}): Promise<UserAvailabilityResponse> => {
    const response = await fetchFromServer(Endpoint.usernameavailable, { ...data, platform: "web" });
    return {
        status: response.status,
        available: response.data ? response.data.available : undefined,
        authUrl: response.data?.auth_url,
        employee: response.data.employee,
        company: response.data?.company,
    };
}

export interface WebSignupResponse {
    status: boolean;
    userUid?: Uid;
    loginToken?: string;
    errorCode?: number;
}

export const webSignup = async (data: any = {}): Promise<WebSignupResponse> => {
    const response = await fetchFromServer(Endpoint.websignup, data);
    return {
        status: response.status,
        userUid: response.data ? response.data.useruid : undefined,
        loginToken: response.data ? response.data.logintoken : undefined,
        errorCode: response.error ? response.error : undefined,
    };
}

export const sendPersonalize = async (data: any = {}) => {
    const response = await fetchFromServer(Endpoint.personalize, data);
    return { status: response.status };
}


export interface AiMessage {
    role: "assistant" | "user";
    content: string;
}

export interface AiRequest {
    query: string;
    history: AiMessage[]
}

export const aiQuery = async (endpoint: Endpoint.salesaiquery | Endpoint.helpaiquery, request: AiRequest, streamCallback: (buffer: string) => void) => {
    const response = await fetchFromServerStreamed(endpoint, request, streamCallback);
    return {
        status: response.status,
        plaintext: response.data.plaintext as string,
    };
}

export const createPaymentIntent = async () => {
    const response = await fetchFromServer(Endpoint.prepareaddpaymentmethod, {});
    return {
        status: response.status,
        clientSecret: response.data.clientSecret as string,
    }
}

export const setDefaultPaymentMethod = async (paymentMethod: PaymentSource) => {
    const response = await fetchFromServer(Endpoint.setdefaultpaymentmethod, { paymentMethodId: paymentMethod.id });
    return {
        status: response.status,
    }
}

export const getInvoices = async () => {
    const response = await fetchFromServer(Endpoint.getinvoices, {});
    return {
        status: response.status,
        invoices: response.data.invoices as Invoice[],
    }
}

export const getPlanPrices = async () => {
    const response = await fetchFromServer(Endpoint.getplanprices, {});
    return {
        status: response.status,
        planPrices: response.data.plans as PlanPrice[],
    }
}

export const changePlan = async (planId: string) => {
    const response = await fetchFromServer(Endpoint.changeplan, { newPlan: planId });
    return {
        status: response.status,
    }
}

interface AssistantQueryRequest {
    query: string;
    membersMentioned: string[],
    placesMentioned: string[],
}

export const assistantQuery = async (request: AssistantQueryRequest, streamCallback: (buffer: string) => void) => {
    const response = await fetchFromServerStreamed(Endpoint.assistantquery, request, streamCallback);
    return {
        status: response.status,
        fulfillments: response.data.fulfillments as AssistantFulfillment[],
        plaintext: response.data.plaintext as string,
    };
}