import { useRecoilValue } from "recoil";
import { editingJobIdState } from "../../../states/appState";
import { jobState } from "../../../states/jobsState";
import { Section } from "../../ui/Section";
import { useStrings } from "../../../hooks/useStrings";
import { getProgressStatus } from "../../../utils/jobUtils";
import { StatusBadge } from "../../badges/StatusBadge";
import React, { useState } from "react";
import { JobProgressStatusSelect } from "../../pickers/JobProgressStatusSelect";
import { HFlex } from "../../containers/HFlex";
import { Button } from "baseui/button";
import { useJobAssignment } from "../../../hooks/jobs/useJobAssignment";
import { Popconfirm } from "antd";
import { jobCompactVisualizationState } from "../../../states/optionsState";

export function JobStatusSection() {
    const { strings } = useStrings();
    const jobId = useRecoilValue(editingJobIdState);
    const job = useRecoilValue(jobState(jobId));
    const progressStatus = job ? getProgressStatus(job) : -1;
    const [selectedProgressStatus, setSelectedProgressStatus] = useState(progressStatus);
    const compact = useRecoilValue(jobCompactVisualizationState);
    const { bulkEdit } = useJobAssignment();

    if (!jobId || !job) {
        return null;
    }

    const onAppyClick = () => {
        if (selectedProgressStatus >= 0) {
            bulkEdit([jobId], undefined, { status: selectedProgressStatus });
        }
    };

    return (
        <Section title={strings.General.Status}
                 expandable={compact}
                 collapsedNode={<StatusBadge progress={progressStatus}/>}
        >
            <HFlex spacing alignCenter>
                <JobProgressStatusSelect
                    progress={selectedProgressStatus}
                    onSelect={(progress?: number) => setSelectedProgressStatus(progress || -1)}
                />
                <Popconfirm title={strings.General.StatusChange}
                            onConfirm={onAppyClick}
                            cancelText={strings.General.Cancel}
                            okType="danger"
                            okText={strings.General.Confirm}>
                    <Button kind={"primary"} size={"mini"} disabled={selectedProgressStatus < 0}>
                        {strings.General.Apply}
                    </Button>
                </Popconfirm>
            </HFlex>
        </Section>
    );
}