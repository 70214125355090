type DateFormat = "dd.MM.yyyy" | "MM-dd-yyyy" | "yyyy/MM/dd";

export function getBrowserDateFormat(): DateFormat {
    const testDate = new Date(2000, 12, 31);
    const formattedDate = new Intl.DateTimeFormat(navigator.language).format(testDate);
    if (formattedDate.startsWith("31")) {
        return "dd.MM.yyyy";
    } else if (formattedDate.startsWith("12")) {
        return "MM-dd-yyyy";
    } else {
        return "yyyy/MM/dd";
    }
}

export function getBrowserTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getBrowserTimeFormat(): "12" | "24" {
    const options: Intl.DateTimeFormatOptions = { hour: 'numeric' };
    const formattedHour: string = new Intl.DateTimeFormat(navigator.language, options).format(new Date());
    return formattedHour.includes('AM') || formattedHour.includes('PM') ? '12' : '24';
}

export function getBrowserDistanceFormat(): "US" | "SI" {
    return getBrowserTimeFormat() === "12" ? "US" : "SI";
}

export function getBrowserLanguage(): string {
    return window.navigator.language;
}

export function getLanguageFromLocale(locale: string) {
    return locale?.length > 2 ? locale.substring(0, 2) : locale;
}
