import { Button } from 'baseui/button';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useJobTypes } from '../../../hooks/jobs/useJobTypes';
import { useStyleOverrides } from '../../../hooks/useStyleOverrides';
import { companyState } from '../../../states/accountState';
import { editingJobTypesState } from '../../../states/appState';
import { editJobTypesState } from '../../../states/editJobTypesState';
import { stringsState } from '../../../states/localeState';
import { getDiffFields } from '../../../utils/objects';
import { SpacerExpand } from '../../containers/Spacer';
import { StyledModal } from '../../ui/StyledModal';
import { StyledModalContent } from '../../ui/StyledModalContent';
import { JobTypesManager } from '../JobTypesManager';

type Props = {
    title?: React.ReactNode;
};

export function JobTypesManagerModal({ title }: Props) {
    const strings = useRecoilValue(stringsState);
    const jobTypes = useRecoilValue(editJobTypesState);
    const resetEditJobTypes = useResetRecoilState(editJobTypesState);
    const { cancelButtonStyles } = useStyleOverrides();
    const company = useRecoilValue(companyState);
    const [editingJobTypes, setEditingJobTypes] = useRecoilState(editingJobTypesState);
    const { bulkEditJobTypes, jobTypesIsLoading } = useJobTypes();

    if (!title) {
        title = strings.Job.ManageJobTypeDesc;
    }
    const diffFields = getDiffFields(company ? company.job_custom_types : [], jobTypes);
    const hasChanges = company?.job_custom_types.length !== jobTypes.length || !!Object.keys(diffFields).length;

    const closeModal = () => {
        setEditingJobTypes(false);
        resetEditJobTypes();
    };

    const saveJobTypes = async () => {
        await bulkEditJobTypes(jobTypes);
        closeModal();
    };

    return (
        <>
            <StyledModal isOpen={editingJobTypes} onClose={closeModal} defaultSize>
                <StyledModalContent
                    header={title}
                    body={<JobTypesManager/>}
                    footer={
                        <>
                            <SpacerExpand/>
                            {hasChanges && (
                                <Button
                                    onClick={closeModal}
                                    size="compact"
                                    kind="tertiary"
                                    overrides={cancelButtonStyles}
                                >
                                    {strings.General.Cancel}
                                </Button>
                            )}
                            <Button onClick={hasChanges ? saveJobTypes : closeModal} size="compact"
                                    isLoading={jobTypesIsLoading}>
                                {hasChanges ? strings.General.Save : strings.General.OK}
                            </Button>
                        </>
                    }
                />
            </StyledModal>
        </>
    );
}