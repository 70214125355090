import { useRecoilValue, useResetRecoilState } from 'recoil';
import { jobIdsByMemberDispatchState, jobsByMemberDayState, jobsSearchState } from '../../../states/jobsState';
import { dayState, } from '../../../states/appState';
import RouteInfoBanner from "../banners/RouteInfoBanner";
import { Droppable } from "react-beautiful-dnd";
import { dayRouteInfoState } from "../../../states/geojsonState";
import React, { memo } from "react";
import { useRouting } from "../../../hooks/useRouting";
import { SelectAllBar } from "../SelectAllBar";
import { useTheme } from "../../../hooks/useTheme";
import { useRouteInfo } from "../../../hooks/useRouteInfo";
import { memberState } from "../../../states/membersState";
import { Spacer } from "../../containers/Spacer";
import EmptyRouteBanner from "../banners/EmptyRouteBanner";
import RouteJobList from './RouteJobList';
import { useUpdateEffect } from 'usehooks-ts';
import { ColumnCard } from "../../board/ColumnCard";
import { ColumnTitle } from "../../board/ColumnTitle";
import { DROPPABLE_TYPE_JOB } from "../../../constants/dnd";
import DraggableJobItem from '../DraggableJobItem';
import RoutingBanner from "../banners/RoutingBanner";
import { Uid } from "../../../types/core";

type Props = {
    uid: Uid;
    showTitle?: boolean;
}

export const RouteColumn = memo(({ uid, showTitle = true }: Props) => {
    const day = useRecoilValue(dayState);
    const jobs = useRecoilValue(jobsByMemberDayState({ uid, day }));
    const jobsCount = jobs.length;
    const jobIds = useRecoilValue(jobIdsByMemberDispatchState({ uid, day }));
    const resetJobs = useResetRecoilState(jobIdsByMemberDispatchState({ uid, day }));
    const routeInfoObj = useRecoilValue(dayRouteInfoState({ uid: uid, day: day }));
    const routeInfo = useRouteInfo();
    const { isDarkTheme, theme } = useTheme();
    const member = useRecoilValue(memberState(uid));
    const hoverColor = isDarkTheme ? theme.colors.positive700 : theme.colors.positive50;
    const search = useRecoilValue(jobsSearchState);
    const { isRequesting } = useRouting();

    useUpdateEffect(() => {
        resetJobs();
    }, [jobsCount]);

    if (!member) {
        return null;
    }

    const isRouteInfoValid = routeInfo.isValid(routeInfoObj, jobs);
    const routeInfoObject = isRouteInfoValid ? routeInfoObj : undefined;
    const routeInfoText = isRequesting(uid)
        ? <RoutingBanner/>
        : jobIds.length > 0
            ? <RouteInfoBanner routeInfoObj={routeInfoObject} memberUid={uid}/>
            : null;
    const emptyState = jobIds.length === 0 && search.length === 0 && <EmptyRouteBanner uid={uid}/>;

    return (
        <ColumnCard
            key={uid}
            color={member.color}
            title={showTitle && <ColumnTitle uid={uid} jobIds={jobIds}/>}
            sticky={
                <>
                    <Spacer/>
                    {routeInfoText}
                    {emptyState}
                    <Spacer height={"12px"}/>
                    <SelectAllBar uid={uid} day={day}/>
                </>
            }
        >
            <Droppable droppableId={uid}
                       type={DROPPABLE_TYPE_JOB}
                       mode='virtual'
                       renderClone={(provided, snapshot, rubric) => (
                            <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                            >
                                <DraggableJobItem id={jobs[rubric.source.index].id} isDragging={snapshot.isDragging} />
                            </div>
                       )}
            >
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            backgroundColor: snapshot.isDraggingOver ? hoverColor : "inherit",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "scroll",
                            flex: 1,
                        }}
                    >
                        <div style={{ flex: 1 }}>
                            <RouteJobList routeInfoObject={routeInfoObject} uid={uid} outerRef={provided.innerRef}/>
                        </div>
                    </div>
                )}
            </Droppable>
        </ColumnCard>
    );
});
