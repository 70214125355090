import { VFlex } from "../containers/VFlex";
import { UnorderedList } from "../containers/UnorderedList";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useTheme } from "../../hooks/useTheme";
import { alertConfigsState, selectedAlertConfigState } from "../../states/alertsState";
import { AlertConfigItem } from "./AlertConfigItem";
import { EmptyState } from "../ui/EmptyState";
import { useStrings } from "../../hooks/useStrings";
import { AlertsReportIcon } from "../ui/svg";
import React from "react";
import { AlertConfig } from "./AlertConfig";
import { ModalBody, ModalHeader } from "baseui/modal";
import { StyledModal } from "../ui/StyledModal";

export function AlertList() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const configs = useRecoilValue(alertConfigsState);
    const alertConfig = useRecoilValue(selectedAlertConfigState);
    const resetAlertConfig = useResetRecoilState(selectedAlertConfigState);
    const sorted = [...configs].sort((a,b) => a.title.localeCompare(b.title));

    return (
        <VFlex spacing>
            {configs.length === 0 &&
                <EmptyState subtitle={strings.Alerts.AlertsDesc}>
                    <AlertsReportIcon size={64} color={theme.colors.contentPrimary}/>
                </EmptyState>
            }
            <UnorderedList>
                {sorted.map(config => <AlertConfigItem key={config.id} alertConfig={config}/>)}
            </UnorderedList>
            {alertConfig &&
                <StyledModal onClose={() => resetAlertConfig()}>
                    <ModalHeader>
                        {alertConfig.title}
                    </ModalHeader>
                    <ModalBody>
                        <AlertConfig/>
                    </ModalBody>
                </StyledModal>
            }
        </VFlex>
    );
}