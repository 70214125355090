import { ALL_REPORTS, RecurringReportConfig, ReportConfig } from "../types/reports";
import { useStrings } from "./useStrings";
import { ReactNode } from "react";
import {
    AlertsReportIcon,
    CheckinsReportIcon,
    DayRouteReportIcon,
    FileLogIcon,
    FormReportIcon,
    GpxReportIcon,
    JobsReportIcon,
    MembersReportIcon,
    MileageReportIcon,
    OnSiteReportIcon,
    PlacesReportIcon,
    StatisticsReportIcon,
    StopsReportIcon,
    TimelineReportIcon,
    TimesheetReportIcon,
    WorksheetReportIcon,
} from "../components/ui/svg";
import { useTheme } from "./useTheme";
import {
    recurringReportsState,
    selectedRecurringReportState,
    selectedReportColumnsState,
    selectedReportConfigState,
    selectedReportDatesState,
    selectedReportDayWeekState,
    selectedReportFormatState,
    selectedReportMembersState,
    selectedReportNameState,
    selectedReportNotifyState,
    selectedReportPayloadState,
    selectedReportPlacesState,
    selectedReportStartDayState,
    selectedReportWeekdaysState,
    setupRecurringReportState,
    uploadedReportsState
} from "../states/reportState";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { dateToDayBrowserTimezone, dayOfToday } from "../utils/time";
import { useText } from "./useText";
import { useTime } from "./useTime";
import { loadRecurringReportConfigs, loadUploadedReports, saveRecurringReportConfig } from "../services/api";
import { createReport, CreateReportRequest } from "../services/download";
import { useWeekDaysEncoder } from "./useWeekDaysEncoder";
import { useToast } from "./useToast";
import { reportsViewActiveTab } from "../states/viewState";
import { membersDangerousState } from "../states/membersState";
import { allPlacesState } from "../states/placeState";
import { getRecoil } from "../providers/RecoilAccessProvider";

export function useReports() {
    const { strings, format: formatString } = useStrings();
    const { theme } = useTheme();
    const encoder = useWeekDaysEncoder();
    const toast = useToast();

    const [dayWeek, setDayWeek] = useRecoilState(selectedReportDayWeekState);
    const [format, setFormat] = useRecoilState(selectedReportFormatState);
    const [members, setMembers] = useRecoilState(selectedReportMembersState);
    const [name, setName] = useRecoilState(selectedReportNameState);
    const [startDay, setStartDay] = useRecoilState(selectedReportStartDayState);
    const [notify, setNotify] = useRecoilState(selectedReportNotifyState);
    const [places, setPlaces] = useRecoilState(selectedReportPlacesState);
    const [selectedRecurringReport, setSelectedRecurringReport] = useRecoilState(selectedRecurringReportState);
    const [selectedReport, setSelectedReport] = useRecoilState(selectedReportConfigState);
    const [weekdays, setWeekdays] = useRecoilState(selectedReportWeekdaysState);
    const [columns, setColumns] = useRecoilState(selectedReportColumnsState);

    const dates = useRecoilValue(selectedReportDatesState);
    const payload = useRecoilValue(selectedReportPayloadState);

    const { formatDay } = useTime();
    const { getMembersTextFromArray, getPlacesTextFromArray } = useText();
    const resetReportConfig = useResetRecoilState(selectedReportConfigState);
    const resetMembers = useResetRecoilState(selectedReportMembersState);
    const resetPlaces = useResetRecoilState(selectedReportPlacesState);
    const resetDates = useResetRecoilState(selectedReportDatesState);
    const resetFormat = useResetRecoilState(selectedReportFormatState);
    const resetName = useResetRecoilState(selectedReportNameState);
    const resetNotify = useResetRecoilState(selectedReportNotifyState);
    const resetWeekday = useResetRecoilState(selectedReportWeekdaysState);
    const resetDayWeek = useResetRecoilState(selectedReportDayWeekState);
    const resetRecurringReport = useResetRecoilState(setupRecurringReportState);
    const resetSelectedRecurringReport = useResetRecoilState(selectedRecurringReportState);
    const resetPayload = useResetRecoilState(selectedReportPayloadState);
    const resetColumns = useResetRecoilState(selectedReportColumnsState);

    const setRecurringReport = useSetRecoilState(setupRecurringReportState);
    const setUploadedReports = useSetRecoilState(uploadedReportsState);
    const setRecurringReports = useSetRecoilState(recurringReportsState);
    const setActiveTab = useSetRecoilState(reportsViewActiveTab);

    const cleanup = () => {
        resetReportConfig();
        resetMembers();
        resetPlaces();
        resetDates();
        resetFormat();
        resetName();
        resetNotify();
        resetWeekday();
        resetDayWeek();
        resetRecurringReport();
        resetSelectedRecurringReport();
        resetPayload();
        resetColumns();
    };

    const reportsTitle = (): string => {
        const reportsString = strings.General.Reports;
        if (selectedRecurringReport) {
            return `${reportsString} - ${formatString(strings.General.EditX, selectedRecurringReport.name)}`;
        } else {
            return selectedReport ? `${reportsString} - ${titleOf(selectedReport)}` : reportsString;
        }
    }

    const titleOf = (report: ReportConfig): string => {
        return titleOfType(report.type);
    }

    const titleOfType = (type: string): string => {
        switch (type) {
            case "jobs":
                return strings.Reports.JobReport;
            case "dayroute":
                return strings.Reports.DayRouteReport;
            case "places":
                return strings.Reports.PlacesReport;
            case "alerts":
                return strings.Reports.AlertsReport;
            case "checkins":
                return strings.Reports.CheckinsReport;
            case "placeonsite":
                return strings.Reports.PlaceOnSiteReport;
            case "memberonsite":
                return strings.Reports.MemberOnSiteReport;
            case "members":
                return strings.Reports.MembersReport;
            case "mileage":
                return strings.Reports.MileageReport;
            case "timesheet":
                return strings.Reports.TimesheetReport;
            case "formsubmissions":
                return strings.Reports.ExportFormSubmissions;
            case "gpx":
                return strings.Reports.GpxReport;
            case "stopsreport":
                return strings.Reports.StopsReport;
            case "activities":
                return strings.Reports.ActivitiesReport;
            case "timeline":
                return strings.Reports.TimelineReport;
            case "worksheet":
                return strings.Reports.Worksheet;
            case "statistics":
                return strings.Stats.Statistics;
        }
        return "";
    }

    const descriptionOf = (report: ReportConfig): string => {
        switch (report.type) {
            case "jobs":
                return strings.Reports.JobReportDesc;
            case "dayroute":
                return strings.Reports.DayRouteReportDesc;
            case "members":
                return strings.Reports.MembersReportDesc;
            case "places":
                return strings.Reports.PlacesReportDesc;
            case "alerts":
                return strings.Reports.AlertsReportDesc;
            case "checkins":
                return strings.Reports.CheckinsReportDesc;
            case "mileage":
                return strings.Reports.MileageReportDesc;
            case "placeonsite":
                return strings.Reports.PlaceOnSiteReportDesc;
            case "memberonsite":
                return strings.Reports.PlaceOnSiteReportDesc;
            case "timesheet":
                return strings.Reports.TimesheetReportDesc;
            case "formsubmissions":
                return strings.Reports.ExportFormSubmissionsDesc;
            case "gpx":
                return strings.Reports.GpxReportDesc;
            case "stopsreport":
                return strings.Reports.StopsReportDesc;
            case "activities":
                return strings.Reports.ActivitiesReportDesc;
            case "timeline":
                return strings.Reports.TimelineReportDesc;
            case "worksheet":
                return strings.Reports.WorksheetDesc;
            case "statistics":
                return strings.Reports.StatisticsReportDesc;
        }

        return "";
    }

    const iconOf = (report: ReportConfig): ReactNode => {
        switch (report.type) {
            case "jobs":
                return <JobsReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "dayroute":
                return <DayRouteReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "places":
                return <PlacesReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "alerts":
                return <AlertsReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "checkins":
                return <CheckinsReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "placeonsite":
                return <OnSiteReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "memberonsite":
                return <OnSiteReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "members":
                return <MembersReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "mileage":
                return <MileageReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "timesheet":
                return <TimesheetReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "formsubmissions":
                return <FormReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "gpx":
                return <GpxReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "stopsreport":
                return <StopsReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "activities":
                return <FileLogIcon size={64} color={theme.colors.contentPrimary}/>;
            case "timeline":
                return <TimelineReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "worksheet":
                return <WorksheetReportIcon size={64} color={theme.colors.contentPrimary}/>;
            case "statistics":
                return <StatisticsReportIcon size={64} color={theme.colors.contentPrimary}/>;
        }
        return null;
    }

    const getFilterTitle = () => {
        if (places.length) {
            return `${strings.General.Places}: ${getPlacesTextFromArray(places, "", 1)}`;
        } else if (members.length) {
            return `${strings.General.Members}: ${getMembersTextFromArray(members, "", 1)}`;
        }
        return strings.General.Filter;
    };

    const getDatesTitle = () => {
        if (dates.length === 2 && dates[0] && dates[1]) {
            const startDate = formatDay(dateToDayBrowserTimezone(dates[0]));
            const endDate = formatDay(dateToDayBrowserTimezone(dates[1]));
            return formatString(strings.General.FromXToX, startDate, endDate).toString();
        } else if (dates.length && dates[0]) {
            const date = formatDay(dateToDayBrowserTimezone(dates[0]));
            return `${strings.General.Date}: ${date}`;
        }
        return strings.General.Dates;
    };

    const loadRecurringConfigs = () => {
        loadRecurringReportConfigs()
            .then(({ status, configs }) => {
                if (status) {
                    setRecurringReports(configs);
                }
            });
    }

    const loadUploads = () => {
        loadUploadedReports()
            .then(({ status, reports }) => {
                if (status) {
                    setUploadedReports(reports);
                }
            });
    };

    const saveNewRecurringConfig = async (status: number = 0) => {
        const isSuccessful = await saveRecurringConfig({
            name: name,
            weekdays: encoder.weekDaysFromSelectedDays(weekdays),
            frame: dayWeek,
            type: selectedReport?.type,
            memberUids: members.map(m => m.uid),
            placeUids: places.map(p => p.uid),
            notifyUids: notify.map(m => m.uid),
            status: status,
            format: format,
            startDay: startDay,
            columns: columns,
        } as RecurringReportConfig);
        if (isSuccessful) {
            toast.showSuccess(strings.General.Saved);
            setActiveTab("recurring");
        }
    };

    const saveRecurringConfig = async (config: RecurringReportConfig) => {
        const { status, configs } = await saveRecurringReportConfig(config);
        if (status) {
            setRecurringReports(configs);
        }
        return status;
    };

    const generateReport = async () => {
        const data: CreateReportRequest = {
            reporttype: selectedReport!.type,
            filename: selectedReport!.filename,
            format: format!,
            accounts: [...places.map((place) => place.uid), ...members.map((member) => member.uid)],
            payload: payload,
            startDay: startDay,
            columns: columns,
        };
        if (dates.length === 2 && dates[0] && dates[1]) {
            data.from = dateToDayBrowserTimezone(dates[0]);
            data.until = dateToDayBrowserTimezone(dates[1]);
        } else if (dates.length && dates[0]) {
            data.day = dateToDayBrowserTimezone(dates[0]);
        } else {
            data.day = dayOfToday();
        }
        await createReport(data);
        loadUploads();
        toast.showSuccess(strings.General.Success);
        setActiveTab("recent");
    }

    const editRecurringReport = (report: RecurringReportConfig) => {
        const allMembers = getRecoil(membersDangerousState);
        const allPlaces = getRecoil(allPlacesState);
        setRecurringReport(true);
        setSelectedReport(ALL_REPORTS.find((r) => r.type === report.type));
        setSelectedRecurringReport(report);
        setMembers(allMembers.filter((m) => report.memberUids.includes(m.uid)));
        setPlaces(allPlaces.filter((p) => report.placeUids.includes(p.uid)));
        setFormat(report.format);
        setWeekdays(encoder.selectedWeekDays(report.weekdays));
        setDayWeek(report.frame);
        setStartDay(report.startDay || dayOfToday);
        setName(report.name);
        setNotify(allMembers.filter((m) => report.notifyUids.includes(m.uid)));
        if (report.columns) {
            setColumns(report.columns)
        }
    }

    const saveEditRecurringConfig = async () => {
        const isSuccessful = await saveRecurringConfig({
            ...selectedRecurringReport,
            name: name,
            weekdays: encoder.weekDaysFromSelectedDays(weekdays),
            frame: dayWeek,
            type: selectedReport?.type,
            memberUids: members.map(m => m.uid),
            placeUids: places.map(p => p.uid),
            notifyUids: notify.map(m => m.uid),
            format: format,
            startDay: startDay,
            columns: columns,
        } as RecurringReportConfig);
        if (isSuccessful) {
            toast.showSuccess(strings.General.Saved);
        }
    };

    return {
        cleanup,
        iconOf,
        descriptionOf,
        titleOf,
        titleOfType,
        reportsTitle,
        getFilterTitle,
        getDatesTitle,
        loadUploads,
        loadRecurringConfigs,
        saveNewRecurringConfig,
        saveRecurringConfig,
        generateReport,
        editRecurringReport,
        saveEditRecurringConfig
    };
}