import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { audioRecorderBlobState, audioRecordingState } from "../states/audioState";

export type PermissionStatus = 'granted' | 'denied' | 'prompt';

export function useAudioRecorder(maxSeconds: number) {
    const [isRecording, setRecording] = useRecoilState(audioRecordingState);
    const isRecordingRef = useRef(isRecording);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [stream, setStream] = useState<MediaStream | null>(null);
    const setRecordedBlob = useSetRecoilState(audioRecorderBlobState);
    const resetRecordedBlob = useResetRecoilState(audioRecorderBlobState);
    const [timerId, setTimerId] = useState<any>();
    const [permissionGranted, setPermissionGranted] = useState(false);
    const [timedOut, setTimedOut] = useState(false);

    useEffect(() => {
        if (timedOut) {
            setTimedOut(false);
            stopRecording();
        }
    }, [timedOut]);

    useEffect(() => {
        checkPermission().then(granted => setPermissionGranted(granted));
    }, []);

    useEffect(() => {
        const handlePermissionChange = (event: Event) => {
            const permissionStatus = (event.target as any).state as PermissionStatus;
            setPermissionGranted(permissionStatus === "granted");
        };

        (async () => {
            try {
                const permissionStatus = await navigator.permissions.query({ name: 'microphone' } as any) as any;
                setPermissionGranted(permissionStatus === "granted");

                permissionStatus.addEventListener('change', handlePermissionChange);
                return () => {
                    permissionStatus.removeEventListener('change', handlePermissionChange);
                };
            } catch (error) {
                console.error('Failed to monitor audio recording permission status', error);
            }
        })();
    }, []);

    useEffect(() => {
        isRecordingRef.current = isRecording;
    }, [isRecording]);

    useEffect(() => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                const newMediaRecorder = new MediaRecorder(stream);
                setMediaRecorder(newMediaRecorder);
                setStream(stream);
            });
        } else {
            console.error('getUserMedia API is not supported in this browser');
        }
    }, []);

    useEffect(() => {
        if (mediaRecorder) {
            const handleDataAvailable = async (event: BlobEvent) => {
                if (event.data && event.data.size > 0) {
                    setRecordedBlob(event.data);
                }
            };
            mediaRecorder.addEventListener('dataavailable', handleDataAvailable);
            return () => {
                stream?.getTracks().forEach(track => track.stop());
                mediaRecorder?.removeEventListener('dataavailable', handleDataAvailable);
                try {
                    mediaRecorder?.stop();
                } catch (exc) {
                    console.error("stopping recording on cleanup", exc);
                }
            };
        }
    }, [mediaRecorder]);

    const startRecording = () => {
        clearTimeout(timerId);
        if (mediaRecorder && !isRecording) {
            resetRecordedBlob()
            try {
                mediaRecorder.start();
            } catch (exc) {
                console.error("starting recording", exc);
            }
            setRecording(true);
            setTimerId(setTimeout(() => {
                if (isRecordingRef.current) {
                    stopRecording();
                }
                setTimedOut(true);
            }, maxSeconds * 1000));
        }
    };

    const stopRecording = () => {
        clearTimeout(timerId);
        setTimerId(undefined);
        if (mediaRecorder && isRecording) {
            try {
                mediaRecorder.stop();
            } catch (exc) {
                console.error("stopping recording", exc);
            }
        }
        setRecording(false);
    };

    const checkPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            stream.getTracks().forEach(track => track.stop()); // stop the stream
            return true;
        } catch (error) {
            console.error('Failed to get audio recording permission', error);
            return false;
        }
    }

    const ready = permissionGranted && mediaRecorder;

    return { isRecording, startRecording, stopRecording, ready };
}