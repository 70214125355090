import { Modal, Timeline, TimelineItemProps } from "antd";
import { LabelXSmall } from "baseui/typography";
import { useMemo } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { useTime } from "../../hooks/useTime";
import { selectedFormSubmissionState } from "../../states/formsState";
import { FormTemplate } from "../../types/form";
import { MemberBadge } from "../badges/MemberBadge";
import { ActionButton } from "../buttons/ActionButton";
import { HFlex } from "../containers/HFlex";
import { VFlex } from "../containers/VFlex";
import { FormIcon, ViewIcon } from "../ui/svg";
import { FormSubmission } from "./FormSubmission";
import { EmptyState } from "../ui/EmptyState";

export function FormSubmissionsTimeline({ submissions }: { submissions: FormTemplate[] }) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const time = useTime();
    const [selected, setSelected] = useRecoilState(selectedFormSubmissionState);
    const reset = useResetRecoilState(selectedFormSubmissionState);

    const timelineItems: TimelineItemProps[] = useMemo(() => {
        return submissions.map((submission, index) => ({
            key: index,
            label: (
                <LabelXSmall>{time.formatDateTime(submission.content.timestamp)}</LabelXSmall>
            ),
            children: (
                <VFlex>
                    <HFlex alignCenter spacing>
                        <MemberBadge uid={submission.creatorUid} />
                        <ActionButton tooltip={strings.General.Open}
                            size={"mini"}
                            kind={"tertiary"}
                            onClick={() => setSelected(submission)}
                        >
                            <ViewIcon size={14} color={theme.colors.contentPrimary} />
                        </ActionButton>
                    </HFlex>
                    <LabelXSmall>{submission.content.title}</LabelXSmall>
                </VFlex>
            )
        }))
    }, [submissions]);

    if (!timelineItems.length) {
        return (
            <EmptyState>
                <FormIcon size={64} color={theme.colors.contentPrimary} />
            </EmptyState>
        )
    }

    return (
        <HFlex spacing={theme.sizing.scale800} style={{ paddingTop: "48px", overflow: "auto" }}>
            <Timeline mode={"left"} style={{ margin: "0 auto" }} items={timelineItems} />
            <Modal title={""} open={!!selected} onCancel={() => reset()} footer={null}>
                <FormSubmission />
            </Modal>
        </HFlex>
    );
}