import { HFlex } from "../containers/HFlex";
import { Button } from "baseui/button";
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from "../ui/svg";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import React from "react";
import { useAllJobsSelection } from "../../hooks/jobs/useAllJobsSelection";
import { useCamera } from "../../hooks/useCamera";
import { useRecoilValue } from "recoil";
import { jobsByMemberOrUnassignedState } from "../../states/jobsState";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { LabelXSmall } from "baseui/typography";
import { Day, Uid } from "../../types/core";

export function SelectAllBar({ uid, day }: { uid: Uid; day: Day }) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const camera = useCamera();
    const allSelection = useAllJobsSelection();
    const jobs = useRecoilValue(jobsByMemberOrUnassignedState({ uid, day }));
    const allSelected = jobs.length > 0 && jobs.map(j => j.id).every(id => allSelection.selectedIds.includes(id));
    const toggleAllSelected = () => {
        if (allSelected) {
            allSelection.unselect(jobs.map(j => j.id))
        } else {
            allSelection.select(jobs.map(j => j.id));
        }
    };

    return (
        <HFlex alignCenter>
            {allSelected
                ?
                <CheckboxCheckedIcon size={18}
                                     color={theme.colors.contentPrimary}
                                     onClick={toggleAllSelected}/>
                : <CheckboxUncheckedIcon size={18}
                                         color={theme.colors.contentPrimary}
                                         onClick={toggleAllSelected}/>
            }
            <Spacer/>
            <LabelXSmall>{strings.General.SelectAll}</LabelXSmall>
            <SpacerExpand/>
            <Button
                size={"mini"}
                shape={"pill"}
                onClick={() => camera.fitToJobs(jobs)}
                kind={"secondary"}
                disabled={jobs.length === 0}
            >
                {strings.Job.ZoomToJobs}
            </Button>
        </HFlex>
    );
}