import { useRecoilValue } from "recoil";
import { recurringReportsState } from "../../states/reportState";
import { HeadingXSmall, LabelMedium, LabelSmall } from "baseui/typography";
import { Card, Popconfirm, Switch, Tag } from "antd";
import { MemberBadges } from "../badges/MemberBadges";
import { PlacesBadges } from "../badges/PlacesBadges";
import { HFlex } from "../containers/HFlex";
import { VFlex } from "../containers/VFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { useStrings } from "../../hooks/useStrings";
import { useReports } from "../../hooks/useReports";
import { EditIcon, TrashIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { Button } from "baseui/button";
import { useWeekDaysEncoder } from "../../hooks/useWeekDaysEncoder";
import { FlexGridLayout } from "../containers/FlexGridLayout";

export function RecurringReports() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const reports = useRecoilValue(recurringReportsState);
    const { saveRecurringConfig, editRecurringReport } = useReports();
    const encoder = useWeekDaysEncoder();
    const { titleOfType } = useReports();

    return (
        <FlexGridLayout baseWidth={420} columnGap={32} rowGap={32}>
            {reports.map(r =>
                <Card key={r.id}
                      title={
                          <HFlex spacing>
                              <LabelMedium>{r.name}</LabelMedium>
                              <SpacerExpand/>
                              <Tag
                                  color={"orange"}>{r.frame === "day" ? strings.General.Daily : r.frame === "twoweeks"
                                      ? strings.Reports.EveryTwoWeeksReport : strings.General.Weekly}</Tag>
                              <Tag>{r.format}</Tag>
                              <Tag color={"blue"}>{encoder.weekDaysText(r.weekdays)}</Tag>
                          </HFlex>
                      }
                      actions={[
                          <Switch
                              checkedChildren={strings.General.Active}
                              unCheckedChildren={strings.General.Paused}
                              checked={r.status === undefined || r.status === 0}
                              onChange={() => saveRecurringConfig({
                                  ...r,
                                  status: r.status === 1 ? 0 : 1
                              })}
                          />
                          ,
                          <Button size={"mini"} kind={"tertiary"} onClick={() => editRecurringReport(r)}>
                              <EditIcon size={12} color={theme.colors.contentPrimary}/>
                              <Spacer/>
                              {strings.General.Edit}
                          </Button>
                          ,
                          <Popconfirm
                              title={strings.General.Delete}
                              description={strings.General.ReallyDelete}
                              okText={strings.General.Delete}
                              okButtonProps={{
                                  danger: true
                              }}
                              onConfirm={() => saveRecurringConfig({
                                  ...r,
                                  status: 2
                              })}
                          >
                              <Button size={"mini"} kind={"tertiary"}>
                                  <TrashIcon size={12} color={theme.colors.contentPrimary}/>
                              </Button>
                          </Popconfirm>
                      ]}
                >
                    <VFlex spacing style={{
                        width: "100%",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                    }}>
                        <HeadingXSmall marginBottom={0} marginTop={0}>{titleOfType(r.type)}</HeadingXSmall>
                        <HFlex spacing>
                            {r.memberUids.length > 0 &&
                                <HFlex spacing alignCenter>
                                    <LabelSmall>{strings.General.Members + ": "}</LabelSmall>
                                    <MemberBadges max={5} members={r.memberUids}/>
                                </HFlex>}
                            {r.placeUids.length > 0 &&
                                <HFlex spacing alignCenter>
                                    <LabelSmall>{strings.General.Places + ": "}</LabelSmall>
                                    <PlacesBadges max={5} uids={r.placeUids}/>
                                </HFlex>}
                        </HFlex>
                        {r.notifyUids &&
                            <HFlex spacing alignCenter>
                                <LabelSmall>{strings.General.Notifying + ": "}</LabelSmall>
                                <MemberBadges members={r.notifyUids}/>
                            </HFlex>
                        }
                    </VFlex>
                </Card>)
            }
        </FlexGridLayout>
    );
}