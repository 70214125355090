import { useEffect, useState } from "react";
import { ConfirmationEmail, getConfirmationEmails, Job } from "../../../types/job";
import { emailValidator } from "../../../constants/validators";
import { LabelSmall, ParagraphSmall } from "baseui/typography";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Button } from "baseui/button";
import { UnorderedList } from "../../containers/UnorderedList";
import { ListItem } from "baseui/list";
import { HFlex } from "../../containers/HFlex";
import { StyledSelect } from "../../ui/StyledSelect";
import { ItemButton } from "../../buttons/ItemButton";
import { arrayRemove } from "baseui/dnd-list";
import { TrashIcon } from "../../ui/svg";
import { Section } from "../../ui/Section";
import { useRecoilState, useRecoilValue } from "recoil";
import { stringsState } from "../../../states/localeState";
import { useTheme } from "../../../hooks/useTheme";
import { editJobState } from "../../../states/editJobState";
import { editingJobIdState } from "../../../states/appState";
import { jobState } from "../../../states/jobsState";
import { FormInput } from "../../ui/FormInput";
import { Textarea } from "baseui/textarea";
import { Collapse } from "antd";
import useAccount from "../../../hooks/useAccount";
import { Spacer } from "../../containers/Spacer";
import { jobCompactVisualizationState } from "../../../states/optionsState";

export function JobConfirmationEmailSection() {
    const strings = useRecoilValue(stringsState);
    const { css, theme } = useTheme();
    const jobId = useRecoilValue(editingJobIdState);
    const job = useRecoilValue(jobState(jobId));
    const [fields, setFields] = useRecoilState(editJobState(jobId || ''));
    const { company } = useAccount();
    const compact = useRecoilValue(jobCompactVisualizationState);

    const [confirmationEmail, setConfirmationEmail] = useState<ConfirmationEmail>({
        email: '',
        onSuccess: true,
    });
    const emailIsValid = !confirmationEmail.email || emailValidator.test(confirmationEmail.email);
    const [confirmationEmails, setConfirmationEmails] = useState(getConfirmationEmails(fields?.confirmationEmails || job?.confirmationEmails || ""));

    useEffect(() => {
        let confirmationEmailsJsonString = '';
        if (!!confirmationEmails.length) {
            confirmationEmailsJsonString = JSON.stringify(confirmationEmails);
        }
        setFields(prev => ({ ...(prev || {} as Job), confirmationEmails: confirmationEmailsJsonString }));
    }, [confirmationEmails]);

    const onAddEmail = () => {
        setConfirmationEmails((prev) => {
            const newEmails = [...prev];
            newEmails.push(confirmationEmail);
            return newEmails;
        });
        setConfirmationEmail({
            email: '',
            onSuccess: true,
        });
    };


    return (
        <Section title={strings.Job.DispatchJobCompletion}
                 expandable={compact}
                 collapsedNode={confirmationEmails ? confirmationEmails.length : undefined}
        >
            <LabelSmall marginBottom={theme.sizing.scale200}>
                {strings.Job.DispatchJobCompletionSend}
            </LabelSmall>
            <div
                className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    columnGap: theme.sizing.scale600,
                    [theme.mediaQuery.medium]: {
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                    },
                })}
            >
                <FormControl
                    error={!emailIsValid ? strings.General.PleaseEnterAValidEmailAddress : undefined}
                    overrides={{
                        ControlContainer: {
                            style: {
                                marginBottom: 0,
                            }
                        }
                    }}
                >
                    <Input
                        value={confirmationEmail.email}
                        onChange={(event) => {
                            setConfirmationEmail((prev) => ({ ...prev, email: event.target.value }));
                        }}
                        size="compact"
                        type="email"
                        placeholder={strings.Job.EnterEmail}
                    />
                </FormControl>
                <Button onClick={onAddEmail} disabled={!confirmationEmail.email || !emailIsValid} size="compact">
                    {strings.General.Add}
                </Button>
            </div>
            <UnorderedList>
                {confirmationEmails.map((ce, index) => (
                    <ListItem
                        key={index}
                        overrides={{
                            Content: {
                                style: {
                                    borderBottomColor: 'transparent',
                                    paddingRight: 0,
                                    marginLeft: 0,
                                    columnGap: theme.sizing.scale600,
                                    rowGap: theme.sizing.scale300,
                                    flexDirection: 'column',
                                    [theme.mediaQuery.medium]: {
                                        flexDirection: 'row',
                                    },
                                },
                            },
                        }}
                    >
                        <HFlex alignCenter spacing>
                            <span>#{index + 1}</span>
                            <ParagraphSmall marginTop={0} marginBottom={0} className={css({ flex: 3 })}>
                                {ce.email}
                            </ParagraphSmall>
                        </HFlex>
                        <HFlex alignCenter spacing>
                            <StyledSelect
                                closeOnSelect
                                size="compact"
                                options={[
                                    { label: strings.Job.OnCompleting, id: 1 },
                                    { label: strings.Job.OnIssue, id: 0 },
                                ]}
                                value={[{ id: Number(ce.onSuccess) }]}
                                openOnClick
                                clearable={false}
                                overrides={{
                                    Root: {
                                        style: {
                                            flex: 2,
                                        },
                                    },
                                }}
                                onChange={params => setConfirmationEmails((prev) => {
                                    const newEmails = [...prev];
                                    newEmails[index].onSuccess = !!params.option?.id;
                                    return newEmails;
                                })}
                            />
                            <ItemButton
                                tooltip={strings.General.Delete}
                                onClick={() => {
                                    setConfirmationEmails((prev) => {
                                        const newEmails = arrayRemove(prev, index);
                                        return newEmails;
                                    });
                                }}
                            >
                                <TrashIcon size={14} color={theme.colors.contentPrimary}/>
                            </ItemButton>
                        </HFlex>
                    </ListItem>
                ))}
            </UnorderedList>
            <Spacer/>
            {company &&
                <Collapse>
                    <Collapse.Panel
                        key={"0"}
                        header={
                            <HFlex spacing alignCenter style={{ height: "22px" }}>
                                <LabelSmall>{strings.Job.EmailPreview}</LabelSmall>
                                <LabelSmall color={theme.colors.contentSecondary}>
                                    {strings.Job.DispatchJobCompletionEditInCompany}
                                </LabelSmall>
                            </HFlex>
                        }>
                        <FormInput
                            label={strings.Dispatch.EmailSubject}
                            value={company.send_job_completion_subject}
                            placeholder={strings.Dispatch.EmailSubjectDescription}
                            field="send_job_completion_subject"
                        />
                        <FormControl label={strings.Dispatch.EmailBody}>
                            <Textarea
                                placeholder={strings.Dispatch.EmailBodyDescription}
                                value={company.send_job_completion_body}
                                name={"send_job_completion_body"}
                            />
                        </FormControl>
                    </Collapse.Panel>
                </Collapse>
            }
        </Section>
    );
}