import { useState } from 'react';
import { flushSync } from 'react-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
    archiveJobs,
    cloneJobs,
    CloneJobsRequest,
    createJobs,
    deleteJobs,
    editJobs,
    getJobs,
    GetJobsRequest,
    restoreJobs
} from '../../services/api';
import { editingJobIdState } from '../../states/appState';
import { editJobErrorState, editJobHasModifiedState, editJobModifiedState } from '../../states/editJobState';
import { jobState } from '../../states/jobsState';
import { useModal } from '../useModal';
import { useSequenceSaver } from './useSequenceSaver';
import { useStateUpdater } from "../useStateUpdater";
import { allJobsIdsState } from "../../states/allJobsState";
import { Day, Uid, UidDay } from "../../types/core";
import { useDayRouteUpdater } from "../useDayRouteUpdater";
import { useJobTemplatesUpdater } from './useJobTemplatesUpdater';
import { getLabels, Job, STATUS_DELETED } from "../../types/job";
import { useAllJobsSelection } from "./useAllJobsSelection";
import useAccount from "../useAccount";
import { useEditCompany } from "../company/useEditCompany";

export function useJobForms() {
    const [editingJobId, setEditingJob] = useRecoilState(editingJobIdState);
    const job = useRecoilValue(jobState(editingJobId || ''));
    const [isLoading, setIsLoading] = useState(false);
    const { closeModal } = useModal();
    const jobHasAnyError = useRecoilValue(editJobErrorState(editingJobId || ''));
    const { updateJobInSequence } = useSequenceSaver();
    const isNewJob = !editingJobId;
    const jobModifiedFields = useRecoilValue(editJobModifiedState);
    const jobHasModifiedFields = useRecoilValue(editJobHasModifiedState);
    const updater = useStateUpdater();
    const setAllJobsTableIds = useSetRecoilState(allJobsIdsState);
    const canSubmit = !jobHasAnyError;
    const dayRouteUpdater = useDayRouteUpdater();
    const stateUpdater = useStateUpdater();
    const templatesUpdater = useJobTemplatesUpdater();
    const selection = useAllJobsSelection();
    const { company } = useAccount();
    const editCompany =  useEditCompany();

    const updateJobsForAssigneeAndDay = async (uid: Uid, day: Day) => {
        const { status, jobs } = await getJobs({
            day: day,
            worker: uid
        } as GetJobsRequest);
        if (status) {
            stateUpdater.updateJobs(jobs);
        }
        await dayRouteUpdater.update(uid, day);
    };

    const saveChanges = async () => {
        // Comment: Do not await for updateJobsForAssigneeAndDay call as it's result is not required to continue, but causing to lock/delay the UI.
        if (jobHasModifiedFields) {
            const jobFields = {
                ...jobModifiedFields,
                windowEnd: jobModifiedFields?.windowEnd !== null ? jobModifiedFields.windowEnd : 0,
                windowStart: jobModifiedFields?.windowStart !== null ? jobModifiedFields.windowStart : 0,
            } as Job;
            if (editingJobId && job) {
                const priorUid = job.receiverUid;
                const priorDay = job.day;
                const newUid = jobModifiedFields.receiverUid || job.receiverUid;
                const newDay = jobModifiedFields.day || priorDay;
                const { status, jobs } = await editJobs({ [job.id]: jobFields });
                if (status) {
                    updater.updateJobs(jobs);
                    if (priorUid && priorDay) {
                        updateJobsForAssigneeAndDay(priorUid, priorDay);
                    }
                    if (newUid && newDay && (newUid != priorUid || newDay != priorDay)) {
                        updateJobsForAssigneeAndDay(newUid, newDay);
                    }
                }
            } else {
                const { status, jobs } = await createJobs([jobFields]);
                if (status) {
                    updater.updateJobs(jobs);
                    setAllJobsTableIds((old) => [...old, ...jobs.map(j => j.id)]);
                    const uidDays = jobs
                        .filter(j => j.day && j.receiverUid)
                        .map(j => { return { uid: j.receiverUid, day: j.day } as UidDay});
                    const coveredKeys = [] as string[];
                    for (const uidDay of uidDays) {
                        const key = uidDay.uid + "_" + uidDay.day;
                        if (!coveredKeys.includes(key)) {
                            coveredKeys.push(key);
                            if (status) {
                                stateUpdater.updateJobs(jobs);
                            }
                            updateJobsForAssigneeAndDay(uidDay.uid, uidDay.day);
                        }
                    }
                }
            }

            // maybe we need to update recent labels
            const jobLabels = getLabels(jobFields);
            if (jobLabels.length > 0) {
                if (company?.recent_job_labels) {
                    let needsUpdate = false;
                    const labelMap = new Map(company.recent_job_labels.map(label => [label.id, label]));
                    jobLabels.forEach(jobLabel => {
                        const existingLabel = labelMap.get(jobLabel.id);
                        if (!existingLabel
                            || existingLabel.text !== jobLabel.text
                            || existingLabel.color !== jobLabel.color) {
                            labelMap.set(jobLabel.id, jobLabel);
                            needsUpdate = true;
                        }
                    });
                    if (needsUpdate) {
                        const updatedLabels = Array.from(labelMap.values()).slice(0, 20);
                        editCompany.editField("recent_job_labels", updatedLabels);
                    }
                } else {
                    editCompany.editField("recent_job_labels", jobLabels);
                }
            }
        }
    }

    const onSubmit = async () => {
        if (job?.isPartOfSequence) {
            console.warn("job submitted that actually is part of sequence: " + job.id + " " + job.destinationName);
            return;
        }
        setIsLoading(true);
        await saveChanges();
        setIsLoading(false);
        closeModal();
    };

    const onDelete = async () => {
        if (!job) {
            return;
        }
        if (isNewJob) {
            console.warn("job deleting when new");
            return;
        }

        if (job.isPartOfSequence) {
            console.warn("job deleting when part of sequence: " + job.id);
            return;
        }
        const { status } = await deleteJobs({ [editingJobId]: {} });
        if (status) {
            updater.updateJobs([{ ...job, status: STATUS_DELETED }]);
            if (job.isTemplate) {
                await templatesUpdater.updateJobTemplates();
            }
            selection.clearSelection();
        }
        closeModal();
    }

    const onArchive = async () => {
        if (!job) {
            return;
        }
        if (isNewJob) {
            console.warn('job archiving when new');
            return;
        }
        if (job.isPartOfSequence || job.isTemplate) {
            console.warn('job archiving when is sequence/template');
            return;
        }
        if (editingJobId) {
            const { status } = await archiveJobs({ [job.id]: {} });
            if (status) {
                console.info('job archived correctly:', job.id);
            }
        }
        closeModal();
    }

    const onRestore = async () => {
        if (!job) {
            return;
        }
        if (isNewJob) {
            console.warn('job restoring when new');
            return;
        }
        if (job.isPartOfSequence || job.isTemplate) {
            console.warn('job restoring when is sequence/template');
            return;
        }
        if (editingJobId) {
            const { status } = await restoreJobs({ [job.id]: {} });
            if (status) {
                console.info('job restored correctly:', job.id);
            }
        }
        closeModal();
    }

    const onClone = async (withAssignee: boolean, withDate: boolean) => {
        if (!job) {
            return;
        }
        if (isNewJob) {
            console.warn('job cloning when new');
            return;
        }
        if (job.isPartOfSequence || job.isTemplate) {
            console.warn('job cloning when is sequence/template');
            return;
        }
        if (editingJobId) {
            const data: CloneJobsRequest = {
                withAssignee,
                withDate,
                suffix: ' Copy',
                jobs: [job.id],
                templates: [],
            }
            const { status, jobs } = await cloneJobs(data);
            if (status) {
                updater.updateJobs(jobs);
                flushSync(() => {
                    setEditingJob(undefined);
                });
                setEditingJob(jobs[0].id);
            }
        }
    }

    const updateSequence = async () => {
        if (!job) {
            return;
        }
        if (!job.isPartOfSequence) {
            console.warn("job updating when is not part of sequence");
            return;
        }
        setIsLoading(true);
        if (jobHasModifiedFields) {
            await updateJobInSequence(job.sequenceId!, job.id, jobModifiedFields);
        }
        setIsLoading(false);
        closeModal();
    }

    return {
        canSubmit,
        isLoading,
        isNewJob,
        onDelete,
        onSubmit,
        onArchive,
        onRestore,
        onClone,
        updateSequence,
        saveChanges,
        hasChanges: jobHasModifiedFields,
    };
}
