export function interpolateHexColors(color1: string, color2: string, color3: string, t: number): string {
    // Calculate interpolation ranges
    const range1 = 0.5; // Interpolation range for color1 to color2
    const range2 = 0.5; // Interpolation range for color2 to color3

    if (t < range1) {
        // Interpolate between color1 and color2
        const t1 = t / range1;
        return interpolateHexColorsTwoColors(color1, color2, t1);
    } else {
        // Interpolate between color2 and color3
        const t2 = (t - range1) / range2;
        return interpolateHexColorsTwoColors(color2, color3, t2);
    }
}

function interpolateHexColorsTwoColors(color1: string, color2: string, t: number): string {
    // Convert hex colors to RGB
    const r1 = parseInt(color1.substr(1, 2), 16);
    const g1 = parseInt(color1.substr(3, 2), 16);
    const b1 = parseInt(color1.substr(5, 2), 16);

    const r2 = parseInt(color2.substr(1, 2), 16);
    const g2 = parseInt(color2.substr(3, 2), 16);
    const b2 = parseInt(color2.substr(5, 2), 16);

    // Interpolate RGB values
    const r = Math.round(r1 + (r2 - r1) * t);
    const g = Math.round(g1 + (g2 - g1) * t);
    const b = Math.round(b1 + (b2 - b1) * t);

    // Convert interpolated RGB values back to hex
    const interpolatedHex = `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;

    return interpolatedHex;
}

export function darkenColor(hexColor: string, darkenPercent: number): string {
    // Ensure the percentage is between 0 and 100
    darkenPercent = Math.min(100, Math.max(0, darkenPercent));

    // Convert hex to RGB
    let r = parseInt(hexColor.substring(1, 3), 16);
    let g = parseInt(hexColor.substring(3, 5), 16);
    let b = parseInt(hexColor.substring(5, 7), 16);

    // Calculate the adjustment factor
    let adjust = (1 - darkenPercent / 100);

    // Apply the adjustment to the RGB values
    r = Math.floor(r * adjust);
    g = Math.floor(g * adjust);
    b = Math.floor(b * adjust);

    // Convert RGB back to hex
    return "#" + r.toString(16).padStart(2, '0') +
        g.toString(16).padStart(2, '0') +
        b.toString(16).padStart(2, '0');
}