import { HOUR } from "../../utils/time";
import { AnalysisEvent, AnalysisEventCollection, AnalysisTick } from "../../types/analysis";
import { useTheme } from "../../hooks/useTheme";
import { useTime } from "../../hooks/useTime";
import { LabelXSmall } from "baseui/typography";
import { VFlex } from "../containers/VFlex";
import { HFlex } from "../containers/HFlex";
import { InfoIcon } from "../ui/svg";
import { Tag, Tooltip } from "antd";
import { tinyTag } from "../../constants/ui";
import React from "react";
import { SpacerExpand } from "../containers/Spacer";
import { Timestamp } from "../../types/core";

type Props = {
    title: string;
    tooltip?: string;
    interval: [number, number];
    uploadTs: Timestamp;
    data: AnalysisEventCollection[];
    eventBarWidth?: number;
};

export const TimeBarView = ({ title, tooltip, interval, uploadTs, data, eventBarWidth = 1.0 }: Props) => {
    const { theme } = useTheme();
    const time = useTime();
    const intervalHours: number = (interval[1] - interval[0]);
    const intervalMillis: number = intervalHours * HOUR;
    const ending = uploadTs - ((24 - interval[1]) * HOUR);
    const beginning = ending - intervalMillis;

    if (!data) {
        return null;
    }

    const events: AnalysisEvent[] = data.flatMap(dataElement => {
        if (!dataElement.timestamps) {
            return [];
        }
        return dataElement.timestamps
            .filter(timestamp => timestamp >= beginning && timestamp <= ending)
            .map(timestamp => {
                const xPosition = (timestamp - beginning) / intervalMillis;
                return [timestamp, dataElement.color, xPosition] as AnalysisEvent;
            });
    });

    const timestampForHour = (hour: number) => {
        const uploadDate = new Date(uploadTs);
        const uploadHour = uploadDate.getHours();
        uploadDate.setHours(uploadHour, 0, 0, 0);
        return uploadDate.getTime() - (hour * HOUR);
    };

    const ticks: AnalysisTick[] = (() => {
        let hours: number[] = [];
        if (intervalHours > 12) {
            for (let i = 0; i <= 24; i += 3) hours.push(i);
        } else if (intervalHours > 6) {
            for (let i = 0; i <= 24; i += 2) hours.push(i);
        } else if (intervalHours > 3) {
            for (let i = 0; i <= 24; i += 1) hours.push(i);
        } else if (intervalHours > 1) {
            for (let i = 0; i <= 24; i += 0.5) hours.push(i);
        } else {
            for (let i = -1; i <= 24; i += 0.25) hours.push(i);
        }

        return hours.map(hour => {
            const tickTimestamp = timestampForHour(hour);
            const timePassedSinceBeginning = tickTimestamp - beginning;
            const xPos = timePassedSinceBeginning / intervalMillis;
            return [tickTimestamp, xPos] as AnalysisTick;
        }).filter(Boolean) as AnalysisTick[];
    })();

    const containerWidth = 500;

    return (
        <VFlex style={{ width: containerWidth + "px" }}>
            <HFlex alignCenter>
                <SpacerExpand/>
                {tooltip && <Tooltip title={tooltip}>
                    <Tag style={{ ...tinyTag }}>
                        <InfoIcon color={theme.colors.contentPrimary} size={8}/>
                    </Tag>
                </Tooltip>}
                <LabelXSmall style={{ textAlign: 'right' }}>
                    {events.length} {title}
                </LabelXSmall>
            </HFlex>
            <svg width={containerWidth} height="64">
                {/* Render events */}
                {events.map((event, index) => (
                    <rect
                        key={index}
                        x={`${event[2] * 100}%`}
                        y="0"
                        width={eventBarWidth}
                        height="32"
                        fill={event[1]}
                    />
                ))}

                {/* Timeline */}
                <rect
                    x="0"
                    y="30"
                    width="100%"
                    height="4"
                    fill="rgba(128,128,128,0.5)"
                />

                {/* Ticks */}
                {ticks.map((tick, index) => {
                    const translateX = Math.round(tick[1] * containerWidth);
                    return (
                        <g key={index} transform={`translate(${translateX}, 34)`}>
                            <rect width="1" height="8" fill="#7F8C8D"/>
                            <text x="0" y="+18" fontSize="8" textAnchor="middle" fill={theme.colors.contentPrimary}>
                                {time.formatTimeOnly(tick[0])}
                            </text>
                        </g>
                    );
                })}
            </svg>
        </VFlex>
    );
}