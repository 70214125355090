import { useRecoilState, useRecoilValue } from "recoil";
import { memberNamesState, membersDangerousState } from "../states/membersState";
import { useEffect } from "react";

export function RecoilCacheProvider() {

    const [names, setNames] = useRecoilState(memberNamesState);
    const members = useRecoilValue(membersDangerousState);

    useEffect(() => {
        const newNames = members.map(m => { return { uid: m.uid, name: m.name || m.username, username: m.username }});
        let isEqual = true;
        if (names.length === newNames.length) {
            for (let i = 0; i < names.length; i++) {
                if (names[i].name !== newNames[i].name) {
                    isEqual = false;
                }
            }
        } else {
            isEqual = false;
        }
        if (!isEqual) {
            setNames(newNames);
        }
    }, [members]);

    return null;
}