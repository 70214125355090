import { Button } from "baseui/button";
import { useRecoilState, useRecoilValue } from "recoil";
import { usePlaceForms } from "../../../hooks/places/usePlaceForms";
import { useCompanyDefaults } from "../../../hooks/company/useCompanyDefaults";
import { editPlace } from "../../../services/api";
import { editingPlaceIdState } from "../../../states/appState";
import { editPlaceState, editPlaceValidState } from "../../../states/editPlaceState";
import { stringsState } from "../../../states/localeState";
import { placeState } from "../../../states/placeState";
import { CustomField, Team } from "../../../types/core";
import { HFlex } from "../../containers/HFlex";
import { SectionedWrapper } from "../../containers/SectionedWrapper";
import { Spacer, SpacerExpand } from "../../containers/Spacer";
import { AddressFields } from "../../shared/AddressFields";
import { ContactFields } from "../../shared/ContactFields";
import { CustomFields } from "../../shared/CustomFields";
import { ProfileImage } from "../../shared/ProfileImage";
import { FormInput } from "../../ui/FormInput";
import { Section } from "../../ui/Section";
import { LinkedFormsSelect } from "../../pickers/LinkedFormsSelect";
import { getLinkedForms, LinkedForm } from "../../../types/linked";
import { useEffect, useState } from "react";
import { VFlex } from "../../containers/VFlex";
import { teamsState } from "../../../states/teamsState";
import { TeamsSelect } from "../../pickers/TeamsSelect";
import { Slider } from "baseui/slider";
import { RADIUS_MAX, RADIUS_MIN } from "../../../constants/places";
import { useUnit } from "../../../hooks/useUnit";
import { LabelSmall } from "baseui/typography";
import { StaticMap } from "../../staticmap/StaticMap";
import { geocode } from "../../../services/privateApi";
import { useEditPlaceLocation } from "../../../hooks/places/useEditPlaceLocation";
import { useUpdateEffect } from "usehooks-ts";
import { Tooltip } from "antd";

export function Place() {
    const strings = useRecoilValue(stringsState);
    const editingPlaceUid = useRecoilValue(editingPlaceIdState);
    const place = useRecoilValue(placeState(editingPlaceUid || ""));
    const [placeFields, setPlaceFields] = useRecoilState(editPlaceState(editingPlaceUid || ""));
    const [linkedForms, setLinkedForms] = useState<LinkedForm[]>(getLinkedForms(placeFields.linkedForms));
    const [geocodeLoading, setGeocodeLoading] = useState(false);
    const validPlaceFields = useRecoilValue(editPlaceValidState(editingPlaceUid || ""));
    const { setPlaceCustomFieldsDefault, setPlaceLinkedFormsTemplate } = useCompanyDefaults();
    const { isNewPlace } = usePlaceForms();
    const { initEditPlace } = useEditPlaceLocation();
    const teams = useRecoilValue(teamsState);
    const selectedTeams = teams.filter((t) => placeFields.teams?.includes(t.id));
    const unit = useUnit();

    useUpdateEffect(() => {
        if (place?.portrait === undefined) {
            return;
        }
        setPlaceFields((prev) => ({ ...prev, portrait: place.portrait }));
    }, [place?.portrait]);

    useEffect(() => {
        let linkedFormsJsonString = '';
        if (!!linkedForms.length) {
            linkedFormsJsonString = JSON.stringify(linkedForms);
        }
        setPlaceFields(prev => ({ ...prev, linkedForms: linkedFormsJsonString }));
    }, [linkedForms]);

    if (!place && !isNewPlace) {
        return null;
    }

    const onChangeInput = (key: string, value: string | number) => {
        setPlaceFields((prev) => ({
            ...prev,
            phone_verified: key === "phone" ? false : prev.phone_verified,
            [key]: value,
        }));
    };

    const handleNewPhone = async () => {
        if (!place) {
            return { status: false };
        }
        const { status } = await editPlace({ uid: place.uid, phone: placeFields.phone });
        return { status };
    };

    const handlePhoneVerified = async () => {
        if (!place) {
            return { status: false };
        }
        const { status } = await editPlace({ uid: place.uid, phone_verified: true });
        if (status) {
            setPlaceFields((prev) => ({ ...prev, phone_verified: true }));
        }
        return { status };
    };

    const updatePlaceCustomFields = (customFields: CustomField[]) => {
        setPlaceFields((prev) => ({ ...prev, extended_data: customFields }));
    };

    const onTeamsChange = (teams: Team[]) => {
        setPlaceFields((prev) => ({
            ...prev,
            teams: teams.map((t) => t.id),
        }));
    }

    const geolocate = async () => {
        setGeocodeLoading(true);
        const geolocation = await geocode({ lat: placeFields.latitude, lng: placeFields.longitude });
        if (geolocation.status && geolocation.result.address) {
            setPlaceFields((prev) => ({ ...prev, address: geolocation.result.address?.formatted || '' }));
        }
        setGeocodeLoading(false);
    }

    return (
        <SectionedWrapper
            style={{
                overflowY: "auto",
                paddingLeft: "8px",
                paddingRight: "8px",
            }}
        >
            <Section>
                <HFlex spacing={"48px"} alignCenter>
                    {!isNewPlace && (
                        <ProfileImage uid={placeFields.uid}
                                      name={placeFields.name}
                                      portrait={placeFields.portrait}
                                      color={placeFields.color}
                                      isPlace/>
                    )}
                    <VFlex style={{ width: "100%" }}>
                        <FormInput
                            label={strings.General.Name}
                            value={placeFields.name || ""}
                            onChange={onChangeInput}
                            placeholder={strings.Places.EnterName}
                            required
                            field="name"
                        />
                    </VFlex>
                    <Spacer/>
                </HFlex>
            </Section>
            <Section>
                <VFlex>
                    <Spacer height={"32px"}/>
                    <HFlex>
                        <SpacerExpand/>
                        <VFlex style={{ maxWidth: "380px" }} spacing>
                            <StaticMap
                                width={380}
                                height={180}
                                location={{ lat: placeFields.latitude, lng: placeFields.longitude }}
                                onClick={initEditPlace}/>
                        </VFlex>
                        <SpacerExpand/>
                    </HFlex>
                    <Spacer height={"32px"}/>
                </VFlex>
                <AddressFields
                    addressValue={placeFields.address}
                    addressEnhancer={placeFields.latitude && placeFields.longitude ?
                        <Button size={"mini"}
                                kind={"tertiary"}
                                onClick={() => geolocate()}
                                isLoading={geocodeLoading}
                        >
                            {strings.General.Geocode}
                        </Button> : undefined}
                    cityValue={placeFields.city}
                    stateValue={placeFields.state}
                    postalCodeValue={placeFields.postalcode}
                    countryValue={placeFields.country_code}
                    onFieldChange={onChangeInput}
                />
            </Section>
            <LabelSmall>{strings.General.Radius}</LabelSmall>
            <Slider value={[placeFields.radius]}
                    max={Math.max(RADIUS_MAX, placeFields.radius)}
                    min={Math.min(RADIUS_MIN, placeFields.radius)}
                    onChange={ev => onChangeInput("radius", ev.value[0])}
                    valueToLabel={value => unit.distanceToString(value)}
            />
            <Section title={strings.General.Contact}>
                <ContactFields
                    uid={placeFields.uid}
                    emailValue={placeFields.email || ""}
                    emailIsVerified={placeFields.email_verified}
                    emailIsValid={validPlaceFields["email"]}
                    phoneValue={placeFields.phone || ""}
                    phoneIsVerified={placeFields.phone_verified}
                    onFieldChange={onChangeInput}
                    onPhoneVerified={handlePhoneVerified}
                    onNewPhone={handleNewPhone}
                />
            </Section>
            <Section title={strings.CustomFields.CustomFields}>
                <CustomFields
                    fields={placeFields.extended_data || []}
                    onSaveAsDefault={() => setPlaceCustomFieldsDefault(placeFields.extended_data)}
                    onUpdateFields={updatePlaceCustomFields}
                    target={"places"}
                />
            </Section>
            <Section title={strings.General.LinkedForms}>
                <LinkedFormsSelect
                    linkedForms={linkedForms}
                    onChangeLinkedForms={(linkedForm) => setLinkedForms(linkedForm as LinkedForm[])}
                />
                <HFlex spacing alignCenter>
                    <SpacerExpand/>
                    <Tooltip title={strings.General.SaveFormsForPlacesDesc}>
                        <Button size="compact" onClick={() => setPlaceLinkedFormsTemplate(linkedForms)}
                                kind="tertiary">
                            {strings.General.SaveTemplate}
                        </Button>
                    </Tooltip>
                </HFlex>
            </Section>
            <Section title={strings.General.Teams}>
                <TeamsSelect
                    placeholder={`${strings.General.AssignToTeams}...`}
                    selectedTeams={selectedTeams}
                    onSelect={(teams) => onTeamsChange(teams as Team[])}
                />
            </Section>
            <Spacer height={"48px"}/>
        </SectionedWrapper>
    );
}
