import { Checkbox } from 'baseui/checkbox';
import { HeadingXSmall, ParagraphMedium } from 'baseui/typography';
import { ChangeEvent, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { editingMemberIdState } from '../../../states/appState';
import { editMemberState } from '../../../states/editMemberState';
import { stringsState } from '../../../states/localeState';
import { memberState } from '../../../states/membersState';
import { teamState } from '../../../states/teamsState';
import { DEFAULT_WORKING_HOURS, SOURCE_DEFAULT, SOURCE_INDIVIDUAL, SOURCE_TEAM } from '../../../types/core';
import { WorkingHours } from '../../shared/WorkingHours';

export function WorkingHoursTab() {
    const editingMember = useRecoilValue(editingMemberIdState);
    const member = useRecoilValue(memberState(editingMember));
    const [memberFields, setMemberFields] = useRecoilState(editMemberState(editingMember));
    const strings = useRecoilValue(stringsState);
    const [isIndividual, setIsIndividual] = useState(member?.working_hours?.source === SOURCE_INDIVIDUAL);
    const hasTeams = !!memberFields.teams?.length;
    const team = useRecoilValue(teamState(hasTeams ? memberFields.teams[0] : -1));

    if (!member) {
        return null;
    }

    const handleIndividual = (event: ChangeEvent<HTMLInputElement>) => {
        const isIndividual = event.target.checked;
        setIsIndividual(isIndividual);
        setMemberFields((prev) => {
            if (isIndividual) {
                return {
                    ...prev,
                    working_hours:
                        member.working_hours.source === SOURCE_INDIVIDUAL
                            ? { ...member.working_hours }
                            : { ...DEFAULT_WORKING_HOURS, source: SOURCE_INDIVIDUAL },
                };
            } else if (team) {
                return { ...prev, working_hours: { ...team.working_hours } };
            } else {
                return {
                    ...prev, working_hours: {
                        ...DEFAULT_WORKING_HOURS // ideally default working hours come from the server
                    }
                };
            }
        });
    };

    return (
        <>
            <ParagraphMedium marginTop={0}>{strings.WorkingHours.IndividualAvailabilityDesc}</ParagraphMedium>
            <Checkbox checked={isIndividual} onChange={handleIndividual}>
                {strings.formatString(strings.WorkingHours.UseIndividualAvailabilityForX, memberFields.name)}
            </Checkbox>
            <HeadingXSmall marginTop={'scale600'} marginBottom={'scale300'}>
                {memberFields.working_hours.source === SOURCE_INDIVIDUAL && strings.WorkingHours.IndividualAvailability}
                {memberFields.working_hours.source === SOURCE_TEAM && `${strings.WorkingHours.TeamAvailability}: ${team?.name}`}
                {memberFields.working_hours.source === SOURCE_DEFAULT && strings.WorkingHours.DefaultWorkingHoursNoTeam}
            </HeadingXSmall>
            <WorkingHours
                workingHours={memberFields.working_hours.source === SOURCE_TEAM && team ? team.working_hours : memberFields.working_hours}
                setWorkingHours={setMemberFields}
                editable={memberFields.working_hours.source === SOURCE_INDIVIDUAL}
            />
        </>
    );
}
