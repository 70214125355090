import { useRecoilValue } from "recoil";
import { pageState } from "../../../states/appState";
import { PAGE } from "../../../constants/pages";
import { PlacesAndZonesTitleBar } from "../../../views/PlacesAndZonesTitleBar";
import { TeamTitleBar } from "../../team/TeamTitleBar";
import { selectedAlertConfigState } from "../../../states/alertsState";
import { useStrings } from "../../../hooks/useStrings";
import { useReports } from "../../../hooks/useReports";
import { CardTitleWithDate } from "../CardTitleWithDate";
import { ReactNode } from "react";
import { selectedFormState } from "../../../states/formsState";
import { FormsTitleBar } from "../../forms/FormsTitleBar";
import { AlertsTitleBar } from "../../alerts/AlertsTitleBar";
import { LocationCardTitle } from "../../location/LocationCardTitle";
import { DefaultActionRow } from "../../dispatch/DefaultActionRow";
import { useAllJobsSelection } from "../../../hooks/jobs/useAllJobsSelection";
import { FocusActionRow } from "../../dispatch/FocusActionRow";
import { dispatchTreeActiveCardState } from "../../../states/viewState";
import { StatisticsTitleBar } from "../../statistics/StatisticsTitleBar";
import { ConversationTitleBar } from "../../messages/ConversationTitleBar";

export function useCardTitles() {
    const { strings } = useStrings();
    const selectedAlertConfig = useRecoilValue(selectedAlertConfigState);
    const page = useRecoilValue(pageState);
    const reports = useReports();
    const selectedForm = useRecoilValue(selectedFormState);
    let smallCardTitle: string | undefined | ReactNode = undefined;
    let mediumCardTitle: string | undefined = undefined;
    let mediumCardTitleNode: ReactNode;
    const jobsSelection = useAllJobsSelection();
    const dispatchCard = useRecoilValue(dispatchTreeActiveCardState);

    switch (page) {
        case PAGE.LIVE:
            smallCardTitle = <CardTitleWithDate title={strings.Map.LiveMap} withClose withoutDate/>;
            break;
        case PAGE.DISPATCH:
            smallCardTitle = <CardTitleWithDate title={strings.General.Dispatch} withClose/>;
            mediumCardTitleNode = dispatchCard !== "clustering" && dispatchCard !== "multiroute"
                ? jobsSelection.selectionCount > 0
                    ? <FocusActionRow/>
                    : <DefaultActionRow/> : undefined;
            break;
        case PAGE.PLACES:
            mediumCardTitleNode = <PlacesAndZonesTitleBar/>;
            break;
        case PAGE.TEAM:
            mediumCardTitleNode = <TeamTitleBar/>;
            break;
        case PAGE.FORMS:
            smallCardTitle = <FormsTitleBar/>;
            mediumCardTitle = selectedForm?.content.title;
            break;
        case PAGE.ALERTS:
            smallCardTitle = <AlertsTitleBar/>;
            mediumCardTitle = selectedAlertConfig?.title;
            break;
        case PAGE.TIMESHEET:
            mediumCardTitle = strings.General.Timesheet;
            mediumCardTitleNode = <CardTitleWithDate expandedDate/>;
            break;
        case PAGE.MESSAGES:
            smallCardTitle = strings.General.Messages;
            mediumCardTitleNode = <ConversationTitleBar/>;
            break;
        case PAGE.STATS:
            mediumCardTitle = strings.Stats.Statistics;
            mediumCardTitleNode = <StatisticsTitleBar/>;
            break;
        case PAGE.REPORTS:
            mediumCardTitle = reports.reportsTitle();
            break;
        case PAGE.INTEGRATIONS:
            mediumCardTitle = strings.General.Integrations;
            break;
        case PAGE.SETTINGS:
            mediumCardTitle = strings.General.Settings;
            break;
        case PAGE.HELP:
            mediumCardTitle = strings.Help.Title;
            break;
        case PAGE.UPLOADS:
            mediumCardTitle = strings.General.UploadedImages;
            mediumCardTitleNode = <CardTitleWithDate expandedDate/>;
            break;
        case PAGE.LOCATION:
            mediumCardTitleNode = <LocationCardTitle/>;
            break;
        case PAGE.TIMELINE:
            mediumCardTitleNode = <LocationCardTitle/>;
            break;
        case PAGE.HEATMAP:
            smallCardTitle = <CardTitleWithDate title={strings.Map.Heatmap} withClose/>;
            break;
        case PAGE.ACTIVITYLOG:
            mediumCardTitle = strings.Activities.ActivityLog;
            break;
        default:
            mediumCardTitle = "";
    }

    const floatingPanelTitle = "";

    return { smallCardTitle, mediumCardTitle, mediumCardTitleNode, floatingPanelTitle }
}