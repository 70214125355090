import { useRecoilValue } from 'recoil';
import MemberList from '../components/member/MemberList';
import { PAGE } from '../constants/pages';
import { pageState } from '../states/appState';
import { FormList } from "../components/forms/FormList";
import { AlertsView } from "./AlertsView";
import { InboxView } from "./InboxView";
import { DispatchTree } from "../components/dispatch/DispatchTree";
import { MemberView } from "./MemberView";

export function SmallCardContent() {
    const page = useRecoilValue(pageState);

    if (page === PAGE.DISPATCH) {
        return <DispatchTree/>;
    }
    if (page === PAGE.LIVE || page === PAGE.LOCATION) {
        return <MemberView/>;
    }
    if (page === PAGE.FORMS) {
        return <FormList/>;
    }
    if (page === PAGE.ALERTS) {
        return <AlertsView/>;
    }
    if (page === PAGE.MESSAGES) {
        return <InboxView/>;
    }
    if (page === PAGE.HEATMAP) {
        return <MemberList showSelectedBadges={true}/>;
    }
    return <></>;
}
