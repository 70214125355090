import { Spacer } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { TimeSlider } from "./TimeSlider";
import { HFlex } from "../containers/HFlex";
import { useTheme } from "../../hooks/useTheme";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedTrackEntriesState, selectedTrackTimeSliderProgress } from "../../states/tracksState";
import { MINUTE } from "../../utils/time";
import React, { useEffect } from "react";
import { cardSizesState } from "../../states/skeletonState";
import { PAGE } from "../../constants/pages";
import { useWindowSize } from "usehooks-ts";

export function TrackTimeSlider() {
    const { theme } = useTheme();
    const selectedTracks = useRecoilValue(selectedTrackEntriesState);
    const [progress, setProgress] = useRecoilState(selectedTrackTimeSliderProgress);
    const { mediumCardSize } = useRecoilValue(cardSizesState(PAGE.LOCATION));
    const windowSize = useWindowSize();

    useEffect(() => setProgress(0), []);

    if (selectedTracks.length !== 1) {
        return null;
    }

    const track = selectedTracks[0];
    const minutes = (track.endTs - track.startTs) / MINUTE;
    const width = mediumCardSize / 100 * windowSize.width - 48;

    return (
        <HFlex style={{ marginTop: "8px", marginBottom: "8px" }}>
            <VFlex spacing alignCenter>
                <VFlex alignCenter style={{
                    width: width + "px",
                    backgroundColor: theme.colors.backgroundSecondary + "DD",
                    borderRadius: "48px",
                    pointerEvents: "auto",
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    paddingRight: '12px',
                    paddingLeft: '12px',
                }}>
                    <TimeSlider
                        max={minutes}
                        values={[minutes * progress]}
                        onChange={val => val && setProgress(val[0] / minutes)}
                        step={1 / 60}
                    />
                </VFlex>
            </VFlex>
            <Spacer width={"48px"}/>
        </HFlex>
    );
}