import React, { forwardRef } from 'react';
import { SmallCardContent } from '../../../views/SmallCardContent';
import { StyledCard } from '../../ui/StyledCard';
import { useCardTitles } from "../hooks/useCardTitles";
import { useRecoilValue } from "recoil";
import { pageState } from "../../../states/appState";
import { PAGE } from "../../../constants/pages";

type Props = {
    border?: boolean;
}

export const SkeletonSmallCard = forwardRef<HTMLDivElement, Props>((props, ref: any) => {
    const { smallCardTitle } = useCardTitles();
    const page = useRecoilValue(pageState);
    const rounded = page === PAGE.LIVE;

    return (
        <div ref={ref} style={{
            transition: "opacity 0.2s ease-out",
            height: props.border ? "calc(100% - 1px)" : "100%",
            position: "relative",
            userSelect: "none",
        }}>
            <StyledCard
                title={smallCardTitle}
                blur={true}
                rounded={rounded}
                card={"small"}
                border={props.border}
            >
                <SmallCardContent/>
            </StyledCard>
        </div>
    );
});