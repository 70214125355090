import { Button } from 'baseui/button';
import { Modal } from 'baseui/modal';
import React, { useEffect, useState } from 'react';
import { useStrings } from '../../../hooks/useStrings';
import { useTheme } from '../../../hooks/useTheme';
import { DetailedLocation } from '../../../types/core';
import { Spacer, SpacerExpand } from '../../containers/Spacer';
import { VFlex } from '../../containers/VFlex';
import { UniversalPicker } from '../../pickers/UniversalPicker';
import { StyledModalContent } from '../../ui/StyledModalContent';
import useAccount from "../../../hooks/useAccount";
import { HeadingSmall, LabelMedium, ParagraphXSmall } from "baseui/typography";
import { ProgressSteps, Step } from 'baseui/progress-steps';
import { Input } from "baseui/input";
import { MapTypePicker } from "../../skeleton/layers/MapTypePicker";
import { useCamera } from "../../../hooks/useCamera";
import { createCompany } from "../../../services/api";
import { useToast } from "../../../hooks/useToast";
import useDirector from "../../../hooks/useDirector";
import { HFlex } from "../../containers/HFlex";
import { CloseIcon, EditIcon } from "../../ui/svg";
import { Clickable } from "../../containers/Clickable";
import { analyticsCompanySetup, analyticsEvent } from "../../../utils/analytics";
import { Tooltip } from "antd";
import { HOUR } from "../../../utils/time";

export function CompanySetupModal() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const [location, setLocation] = useState<DetailedLocation>();
    const [locationOk, setLocationOk] = useState<boolean>(false);
    const [companyName, setCompanyName] = useState('');
    const [companyNameOk, setCompanyNameOk] = useState<boolean>(false);
    const { company, isBusiness, setBusiness, timestamps } = useAccount();

    const canContinue = !!companyName;
    const [isLoading, setLoading] = useState(false);
    const step = locationOk && companyNameOk ? 2 : locationOk ? 1 : 0;
    const camera = useCamera();
    const toast = useToast();
    const director = useDirector();

    useEffect(() => analyticsCompanySetup(step), [step]);

    useEffect(() => {
        if (location) {
            camera.flyTo(location);
        }
    }, [location]);

    if (company?.uid) {
        return null;
    }

    const onLogoutClick = () => {
        analyticsEvent("upgrade_logout");
        director.logout();
    }

    const onTryBusinessClick = () => {
        analyticsEvent("upgrade_success");
        setBusiness(true);
    }

    const onBackToWebsiteClick = () => {
        analyticsEvent("upgrade_back");
        window.location.replace("https://hellotracks.com");
    }

    const onContinueClick = async () => {
        setLoading(true);
        if (location) {
            analyticsCompanySetup("success");
            const result = await createCompany({
                name: companyName,
                formattedAddress: location.display,
                address: location.address,
                city: location.city,
                countryCode: location.country_code,
                streetName: location.streetname,
                streetNumber: location.streetnumber,
                postalCode: location.postalcode,
                lat: location.lat,
                lng: location.lng
            });
            if (result.status) {
                toast.showSuccess(strings.General.Success);
                await director.performPoll();
                director.performBilling();
            } else {
                toast.showWarning(strings.General.SomethingWentWrong);
            }
        }
        setLoading(false);
    }

    const steps = (
        <VFlex>
            <HeadingSmall>{strings.Company.QuickSetup}</HeadingSmall>
            <ProgressSteps current={step}>
                <Step title={strings.Company.CompanyAddress}>
                    <ParagraphXSmall>{strings.Company.CompanySetupDesc}</ParagraphXSmall>
                    {!location && <UniversalPicker
                        placeholder={strings.General.SearchAddress}
                        width="100%"
                        onOptionSelected={(option) => setLocation(option.location)}
                        place={false}
                        member={false}
                        job={false}
                    />}
                    <Spacer/>
                    {location?.address &&
                        <>
                            <HFlex spacing alignCenter>
                                <LabelMedium>{location.address}</LabelMedium>
                                <Clickable onClick={() => setLocation(undefined)}>
                                    <EditIcon size={12} color={theme.colors.contentPrimary}/>
                                </Clickable>
                            </HFlex>
                            <Spacer/>
                        </>
                    }
                    <Button
                        size="compact"
                        disabled={location === undefined}
                        onClick={() => setLocationOk(true)}
                    >
                        {strings.General.Next}
                    </Button>
                </Step>
                <Step title={strings.Company.CompanyName}>
                    <Input size={"compact"}
                           onChange={ev => setCompanyName(ev.target.value)}
                           placeholder={strings.Company.EnterCompanyName}
                    />
                    <Spacer/>
                    <Button
                        size="compact"
                        disabled={companyName.trim().length === 0}
                        onClick={() => setCompanyNameOk(true)}
                    >
                        {strings.General.Next}
                    </Button>
                </Step>
                <Step title={strings.Map.MapType}>
                    <ParagraphXSmall>{strings.Company.SelectThemeDesc}</ParagraphXSmall>
                    <MapTypePicker/>
                </Step>
            </ProgressSteps>
            <Spacer/>
        </VFlex>
    );

    const footer = isBusiness ?
        <>
            {((timestamps?.created || 0) < (Date.now() - HOUR)) &&
                <Tooltip title={"Exit Hellotracks Business"}>
                    <Button onClick={() => setBusiness(false)} size="mini" kind={"tertiary"}>
                        <CloseIcon size={12} color={theme.colors.contentTertiary}/>
                    </Button>
                </Tooltip>
            }
            <SpacerExpand/>
            <Button onClick={onContinueClick} size="compact" disabled={!canContinue} isLoading={isLoading}>
                {strings.General.Continue}
            </Button>
        </> : <>
            <Button onClick={onBackToWebsiteClick} size="compact" kind={"tertiary"}>
                {strings.ChatAi.BackToWebsite}
            </Button>
            <Button onClick={onLogoutClick} size="compact" kind={"tertiary"}>
                {strings.General.SignOut}
            </Button>
            <SpacerExpand/>
            <Button onClick={onTryBusinessClick} size="compact" autoFocus>
                {strings.General.TryBusiness}
            </Button>
        </>

    return (
        <Modal
            closeable={false}
            isOpen
            overrides={{
                Dialog: {
                    style: {
                        backgroundColor: theme.colors.backgroundSecondary,
                    },
                },
            }}
        >
            <StyledModalContent
                body={isBusiness ? steps :
                    <VFlex spacing>
                        {strings.General.TryBusinessDesc}
                        <video autoPlay controls style={{ borderRadius: "8px" }}>
                            <source src="https://storage.googleapis.com/ht-web/intro-v1.mp4" type="video/mp4"/>
                        </video>
                    </VFlex>
                }
                footer={footer}
            />
        </Modal>
    );
}
