import { getConversation, sendMessage } from "../services/api";
import { attachedLocationState, conversationState } from "../states/messagesState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useConversations } from "./useConversations";
import { Uid } from "../types/core";

export function useConversation(uid: Uid) {
    const setConversation = useSetRecoilState(conversationState(uid));
    const { loadAll } = useConversations();
    const attachedLocation = useRecoilValue(attachedLocationState);

    const load = () => {
        getConversation({ account: uid }).then(({ status, messages }) => {
            if (status) {
                setConversation(messages);
                loadAll();
            }
        });
    };

    const send = (input: string, uid: Uid) => {
        sendMessage({
            msg: input,
            receivers: [uid],
            lat: attachedLocation?.lat,
            lng: attachedLocation?.lng,
        }).then(({ status }) => {
            if (status) {
                load();
            }
        })
    };

    return { load, send };
}