import React from "react";
import {
    calcTotalsAndAverages,
    calcTotalsAndAveragesByMember,
    MemberStatDay,
    retrieveMemberUids
} from "../../types/stats";
import { Datum, StackedBars } from "../../components/statistics/visx/StackedBars";
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { memberState } from "../../states/membersState";
import { useTime } from "../useTime";
import { useStrings } from "../useStrings";
import { asNumber } from "../../utils/objects";
import { ChartPanel } from "../../components/statistics/ChartPanel";


export function useJobPerformanceCharts({ stats, fetching, width }: { stats: MemberStatDay[], fetching: boolean, width: number }) {
    const { strings } = useStrings();
    const [totalSuccessCount] = calcTotalsAndAverages(stats, 'successCount');
    const [totalIssueCount] = calcTotalsAndAverages(stats, 'issueCount');
    const [totalJobsCount] = calcTotalsAndAverages(stats, 'totalJobs');
    const time = useTime();

    const dates = stats.map(s => s.day);
    const data: Datum[] = dates.map((day, index) => ({
        bucketField: time.formatDay(day),
        successCount: totalSuccessCount[index],
        issueCount: totalIssueCount[index],
        otherCount: totalJobsCount[index] - totalSuccessCount[index] - totalIssueCount[index],
    } as Datum));

    const memberUids = retrieveMemberUids(stats);
    const [totalSuccessCountByMember] = calcTotalsAndAveragesByMember(stats, memberUids, 'successCount');
    const [totalIssueCountByMember] = calcTotalsAndAveragesByMember(stats, memberUids, 'issueCount');
    const [totalJobsCountByMember] = calcTotalsAndAveragesByMember(stats, memberUids, 'totalJobs');

    const dataByMember: Datum[] = memberUids.map((uid, index) => ({
        bucketField: getRecoil(memberState(uid))?.name || uid,
        successCount: totalSuccessCountByMember[index],
        issueCount: totalIssueCountByMember[index],
        otherCount: totalJobsCountByMember[index] - totalSuccessCountByMember[index] - totalIssueCountByMember[index]
    } as Datum));

    const dataByMemberFiltered = dataByMember
        .filter(d => asNumber(d, 'successCount') + asNumber(d,'issueCount') + asNumber(d, 'otherCount') > 0);

    const dataByMemberSortedByTotal = [...dataByMemberFiltered]
        .sort((d1: Datum, d2: Datum) => {
            const sum1 = (asNumber(d1,'successCount') + asNumber(d1, 'issueCount')) * 100 + asNumber(d1,'otherCount');
            const sum2 = (asNumber(d2,'successCount') + asNumber(d2, 'issueCount')) * 100 + asNumber(d2,'otherCount');
            return sum1 - sum2;
        });

    const rowHeight = 24;
    const rowPad = 100;

    return [
        <ChartPanel title={strings.Stats.JobPerformanceByDate} key={strings.Stats.JobPerformanceByDate}>
            <StackedBars width={width}
                            height={data.length * rowHeight + rowPad}
                            data={data}
                            fetching={fetching}
                            categories={["successCount", "issueCount", "otherCount"]}
                            labels={[strings.JobStatuses.Done, strings.JobStatuses.Issue, strings.JobStatuses.Scheduled]}
                            type={"count"}
            />
        </ChartPanel>,
        <ChartPanel title={strings.Stats.JobPerformanceByMember} key={strings.Stats.JobPerformanceByMember}>
            <StackedBars width={width}
                            height={dataByMemberSortedByTotal.length * rowHeight + rowPad}
                            data={dataByMemberSortedByTotal}
                            fetching={fetching}
                            categories={["successCount", "issueCount", "otherCount"]}
                            labels={[strings.JobStatuses.Done, strings.JobStatuses.Issue, strings.JobStatuses.Scheduled]}
                            type={"count"}
            />
        </ChartPanel>
    ];
}