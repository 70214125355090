import { Spin } from "antd";
import { LightTheme } from "baseui";
import { Avatar } from "baseui/avatar";
import { Button } from "baseui/button";
import { Show } from "baseui/icon";
import { ParagraphSmall } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { Layer, Map as MapboxMap, Source, useMap } from "react-map-gl";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import { MAPBOX_ACCESS_TOKEN, ROAD_MAP_OBJ } from "../../constants/mapConstants";
import { useStrings } from "../../hooks/useStrings";
import { TrackEntryAndData } from "../../types/track";
import { Centered } from "../containers/Centered";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { PublicViewFooter } from "../shared/PublicViewFooter";
import { EmptyState } from "../ui/EmptyState";
import { TrackInfoModal } from "../location/TrackInfoModal";
import { GEOJSON_BASE_URL } from "../../services/geojson";
import {
    trackMarkersCircleLayerStyle,
    trackMarkersTextLayerStyle,
    useTrackLayerStyle,
    useWaypointsLayerStyle
} from "../map/mapStyle";
import { Md5 } from "ts-md5";
import { useCamera } from "../../hooks/useCamera";
import { retrieveTracksFromGeojson } from "../../utils/geojsonUtils";
import { ZoomButtons } from "../skeleton/ZoomButtons";


export function SharedTrack() {
    const { memberId, trackId } = useParams();
    const { strings } = useStrings();
    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [track, setTrack] = useState<TrackEntryAndData>();
    const [showTrackInfo, setShowTrackInfo] = useState(false);
    const [companyName, setCompanyName] = useState("");
    const [memberName, setMemberName] = useState("");
    const [memberPortrait, setMemberPortrait] = useState("");
    const [geojson, setGeojson] = useState<GeoJSON.FeatureCollection | undefined>();
    const { trackLayerStyle, trackOuterLayerStyle } = useTrackLayerStyle();
    const { waypointsLayerStyle } = useWaypointsLayerStyle("track-waypoints");
    const camera = useCamera();

    useEffectOnce(() => {
        const loadTrack = async () => {
            const hash = Md5.hashStr("8410" + memberId + trackId);
            const result = await fetch(`${GEOJSON_BASE_URL}tracks?target=base&member=${memberId}&trackId=${trackId}&hash=${hash}`);
            if (result.ok) {
                const json = await result.json();
                setCompanyName(json.meta?.companyName as string);
                setMemberName(json.meta?.accountName as string);
                setMemberPortrait(json.meta?.accountPortrait as string);
                const featColl = json as unknown as GeoJSON.FeatureCollection;
                setGeojson(featColl);
                if (featColl?.features?.length > 0) {
                    setTrack(retrieveTracksFromGeojson(featColl)[0]);
                }
            } else {
                setNotFound(true);
            }
            setIsLoading(false);
        };

        loadTrack();
    });

    const { map } = useMap();

    useEffect(() => {
        if (track && map) {
            camera.fitToLngLats(track.data, 0)
        }
    }, [geojson, map]);

    const trackLayerFilter = ["all", ["==", "$type", "LineString"]];
    const trackMarkersFilter = ["all", ["==", "$type", "Point"], ["has", "letter"]];
    const waypointsLayerFilter = ["==", "$type", "Point"];

    return (
        <HFlex
            style={{
                background: LightTheme.colors.backgroundPrimary,
                height: "100%",
            }}
        >
            {isLoading && (
                <Centered>
                    <EmptyState title={strings.General.Loading}>
                        <Spin/>
                    </EmptyState>
                </Centered>
            )}
            {notFound && (
                <Centered>
                    <EmptyState title={strings.General.TrackNotFound}/>
                </Centered>
            )}
            {track && (
                <VFlex
                    style={{
                        flex: 1,
                    }}
                >
                    <HFlex
                        style={{ width: "auto" }}
                        padding
                        alignCenter
                        spacing
                    >
                        <Avatar name={memberName} size="28px" src={memberPortrait}/>
                        <ParagraphSmall
                            margin={0}>{`${memberName} ${track.entry.dateText}, ${track.entry.timeText}`}</ParagraphSmall>
                        <SpacerExpand/>
                        <Button
                            size="mini"
                            kind="secondary"
                            onClick={() => setShowTrackInfo(true)}
                            shape="pill"
                            startEnhancer={<Show/>}
                        >
                            {strings.General.OpenDetails}
                        </Button>
                    </HFlex>
                    <MapboxMap
                        id="map"
                        reuseMaps
                        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                        mapStyle={ROAD_MAP_OBJ.url}
                        minPitch={0}
                        maxPitch={85}
                        minZoom={4}
                        initialViewState={{
                            longitude: -100,
                            latitude: 40,
                            zoom: 3.5,
                        }}
                        style={{ width: "100%", height: "100%" }}
                    >
                        <Source id="tracks" type="geojson" data={geojson}>
                            <Layer {...trackOuterLayerStyle} filter={trackLayerFilter}/>
                            <Layer {...trackLayerStyle} filter={trackLayerFilter}/>
                            <Layer {...waypointsLayerStyle} filter={waypointsLayerFilter}/>
                            <Layer {...trackMarkersCircleLayerStyle} filter={trackMarkersFilter}/>
                            <Layer {...trackMarkersTextLayerStyle} filter={trackMarkersFilter}/>
                        </Source>
                        <ZoomButtons/>
                    </MapboxMap>
                    <HFlex style={{ justifyContent: "center" }}>
                        <PublicViewFooter name={companyName || memberName}/>
                    </HFlex>
                </VFlex>
            )}
            {track &&
                <TrackInfoModal isOpen={showTrackInfo} track={track.entry} onClose={() => setShowTrackInfo(false)}/>}
        </HFlex>
    );
}
