import { useRecoilValue } from 'recoil';
import { editingJobIdState } from '../../../states/appState';
import { editJobState } from '../../../states/editJobState';
import { AssigneeHistoryEntry, getManualChecks, ProgressStatusHistoryEntry } from "../../../types/job";
import { useStrings } from '../../../hooks/useStrings';
import { Timeline, TimelineItemProps, Tooltip } from "antd";
import { VFlex } from "../../containers/VFlex";
import { Spacer, SpacerExpand } from "../../containers/Spacer";
import { TimeBadge } from "../../badges/TimeBadge";
import { MemberBadge } from "../../badges/MemberBadge";
import { HFlex } from "../../containers/HFlex";
import { InfoIcon } from "../../ui/svg";
import { useTheme } from "../../../hooks/useTheme";
import { Button } from "baseui/button";
import { getProgressColor } from "../../../utils/jobUtils";
import { useJobInfo } from "../../../hooks/jobs/useJobInfo";
import {
    COLOR_ASSIGNED_ACCEPTED,
    COLOR_IN_PROGRESS,
    COLOR_ISSUE,
    COLOR_REJECTED,
    COLOR_SUCCESS,
    COLOR_TRIP
} from "../../../constants/colors";

export function JobHistoryTab() {
    const { theme } = useTheme();
    const { strings, format } = useStrings();
    const editingJobId = useRecoilValue(editingJobIdState);
    const job = useRecoilValue(editJobState(editingJobId || ''));
    const { getStatusTextFromProgress } = useJobInfo();

    if (!job) {
        return null;
    }

    const raw = [];
    if (job.tsCreated < 1716367838000) {
        raw.push([job.tsAssigned, format(strings.General.AssignedByX, job.dispatcherName), theme.colors.contentPrimary]);
        raw.push([job.tsAccepted, strings.JobStatuses.Accepted, COLOR_ASSIGNED_ACCEPTED]);
        raw.push([job.tsRejected, strings.JobStatuses.Rejected, COLOR_REJECTED]);
        raw.push([job.tsDoneSuccess, strings.JobStatuses.Done, COLOR_SUCCESS]);
        raw.push([job.tsDoneFailed, strings.JobStatuses.Issue, COLOR_ISSUE]);
    }
    raw.push([job.tsCheckIn, strings.General.CheckIn, COLOR_IN_PROGRESS]);
    raw.push([job.tsCheckOut, strings.General.CheckOut, COLOR_IN_PROGRESS]);
    raw.push([job.tsTripStarted, strings.General.OnTheWay, COLOR_TRIP]);
    raw.push([job.tsSaved, strings.General.Saved, "orange"]);
    for (const check of getManualChecks(job)) {
        raw.push([check.ts, check.type === "checkin"
            ? strings.Job.ManualJobCheckin
            : strings.Job.ManualJobCheckout, "blue"])
    }
    const assigneeHistory = JSON.parse(job.assigneeHistory || "[]") as AssigneeHistoryEntry[];
    const assigneeHistoryItems = assigneeHistory
        .map((n) => [n.ts, <HFlex spacing alignCenter>
            <SpacerExpand/>
            {strings.General.Assigned}
            <Tooltip title={
                <VFlex alignCenter>
                    {strings.General.AssignedTo}
                    <MemberBadge uid={n.assigneeUid} inverse/>
                    <Spacer/>
                    {format(strings.General.AssignedByX, "")}
                    <MemberBadge uid={n.dispatcherUid} inverse/>
                </VFlex>
            }>
                <Button size={"mini"} kind={"tertiary"}>
                    <InfoIcon size={12} color={theme.colors.contentPrimary}/>
                </Button>
            </Tooltip>
        </HFlex>, "orange"])
    raw.push(...assigneeHistoryItems);

    const progressStatusHistory = JSON.parse(job.progressStatusHistory || "[]") as ProgressStatusHistoryEntry[];
    const progressStatusHistoryItems = progressStatusHistory
        .map((n) => [n.ts, <HFlex spacing alignCenter>
            <SpacerExpand/>
            {getStatusTextFromProgress(n.status)}
            <Tooltip title={<MemberBadge uid={n.uid} inverse/>}>
                <Button size={"mini"} kind={"tertiary"}>
                    <InfoIcon size={12} color={theme.colors.contentPrimary}/>
                </Button>
            </Tooltip>
        </HFlex>, getProgressColor(n.status)])
    raw.push(...progressStatusHistoryItems);

    const sorted: any[] = raw
        .filter(n => n[0] > 0)
        .sort((n1: any, n2: any) => n1[0] - n2[0]);
    const createdText = job.createdBy
        ? <HFlex spacing alignCenter>
            <SpacerExpand/>
            {strings.General.Created}
            <Tooltip title={<VFlex>
                {format(strings.General.CreatedByX, "")}
                <MemberBadge uid={job.createdBy} inverse/>
                <Spacer/>
                {format(strings.General.ViaX, job.createdVia || "")}
            </VFlex>}>
                <Button size={"mini"} kind={"tertiary"}>
                    <InfoIcon size={12} color={theme.colors.contentPrimary}/>
                </Button>
            </Tooltip>
        </HFlex> : strings.General.Created;
    sorted.unshift([job.tsCreated, createdText, theme.colors.contentPrimary]);
    const data = sorted
        .map((n: any) => [<TimeBadge startMillis={n[0]} withDate/>, n[1], n[2]]);

    const timelineItems: TimelineItemProps[] = data.map((entry, index) => ({
        key: index,
        label: entry[0],
        children: entry[1],
        color: entry[2]
    }));

    return (
        <VFlex>
            <Spacer height={"48px"}/>
            <Timeline mode={"right"} items={timelineItems}/>
        </VFlex>
    );
}
