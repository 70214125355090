import type { CircleLayer, FillLayer, LineLayer, SymbolLayer } from 'react-map-gl';
import { useRecoilValue } from "recoil";
import { mapIconSizeState } from "../../states/mapState";
import { themedUseStyletron as useStyletron } from "../../styles/theme";
import {
    COLOR_ASPHALT,
    COLOR_DAYROUTE1,
    COLOR_DAYROUTE2,
    COLOR_DAYROUTE3,
    COLOR_OFFWHITE
} from "../../constants/colors";
import { useTheme } from "../../hooks/useTheme";

export function usePlacesLayerStyle() {
    const size = useRecoilValue(mapIconSizeState);
    const radius = (size / 6);
    const { isDarkTheme } = useTheme();

    const placesLayerStyle: CircleLayer = {
        id: 'places',
        type: 'circle',
        source: 'places',
        paint: {
            'circle-radius': radius,
            'circle-color': ['get', 'color'],
            'circle-stroke-color': ['get', 'stroke-color'],
            'circle-stroke-width': 1,
            'circle-opacity': 1,
        },
    }

    const placeNamesLayerStyle: SymbolLayer = {
        'id': 'places-name',
        'type': 'symbol',
        layout: {
            'text-field': ['get', 'name'],
            'text-font': [
                'Open Sans Bold',
                'Arial Unicode MS Bold'
            ],
            'text-size': 11,
            'text-letter-spacing': 0.05,
            'text-offset': [0, 1.5],
        },
        'paint': {
            'text-color': ['get', 'color'],
            'text-halo-color': isDarkTheme ? '#444' : '#fff',
            'text-halo-width': 2
        },
    };

    return { placesLayerStyle, placeNamesLayerStyle };
}

export function useStopsLayerStyle() {
    const { isDarkTheme } = useTheme();

    const stopsCircleLayerStyle: CircleLayer = {
        'id': 'stops-markers-circle',
        'type': 'circle',
        'paint': {
            'circle-radius': 7,
            'circle-color': '#A00',
            'circle-stroke-color': isDarkTheme ? '#111' : "#EEE",
            'circle-stroke-width': 1,
            'circle-opacity': 1,
        },
        filter: ["all", ["==", "$type", "Point"], ["kind", "stop"]],
    };

    const stopsTextLayerStyle: SymbolLayer = {
        'id': 'stops-markers-text',
        'type': 'symbol',
        layout: {
            'text-field': "||",
            'text-font': [
                'Open Sans Bold',
                'Arial Unicode MS Bold'
            ],
            'text-size': 6,
            'text-letter-spacing': 0.05,
            'text-offset': [0, 0],
        },
        'paint': {
            'text-color': '#FFF',
            'text-halo-color': '#333',
            'text-halo-width': 2
        },
        filter: ["all", ["==", "$type", "Point"], ["kind", "stop"]],
    };

    return { stopsCircleLayerStyle, stopsTextLayerStyle };
}

export const trackMarkersCircleLayerStyle: CircleLayer = {
    'id': 'track-markers-circle',
    'type': 'circle',
    'paint': {
        'circle-radius': 11,
        'circle-color': '#000',
        'circle-stroke-color': '#FFF',
        'circle-stroke-width': 1,
        'circle-opacity': 1,
    },
    filter: ["all", ["==", "$type", "Point"], ["has", "letter"]],
};

export const trackMarkersTextLayerStyle: SymbolLayer = {
    'id': 'track-markers-text',
    'type': 'symbol',
    layout: {
        'text-field': ['get', 'letter'],
        'text-font': [
            'Open Sans Bold',
            'Arial Unicode MS Bold'
        ],
        'text-size': 11,
        'text-letter-spacing': 0.05,
        'text-offset': [0, 0],
    },
    'paint': {
        'text-color': '#FFF',
        'text-halo-color': '#333',
        'text-halo-width': 2
    },
    filter: ["all", ["==", "$type", "Point"], ["has", "letter"]],
};

export const zonesLayerStyle: FillLayer = {
    'id': 'zones',
    'type': 'fill',
    'paint': {
        'fill-color': ['get', 'color'],
        'fill-opacity': 0.1,
    }
};

export const zonesOutlineLayerStyle: LineLayer = {
    'id': 'zones-outline',
    'type': 'line',
    'paint': {
        'line-color': '#000',
        'line-width': 1,
        'line-opacity': 0.5,
    }
}

export const zonesSelectedOutlineLayerStyle: LineLayer = {
    'id': 'zones--selected-outline',
    'type': 'line',
    'paint': {
        'line-color': ['get', 'color'],
        'line-width': 3,
        'line-opacity': 1,
    }
}

export function useWaypointsLayerStyle(id: string) {
    const waypointsLayerStyle: CircleLayer = {
        'id': id,
        'type': 'circle',
        'paint': {
            'circle-radius': {
                'base': 0.5,
                'stops': [[7, 0.5], [12, 1.5], [17, 3]]
            },
            'circle-color': ['get', 'color'],
            'circle-stroke-color': ['get', 'color'],
            'circle-stroke-width': 1,
            'circle-opacity': 1
        }
    }
    return { waypointsLayerStyle };
}

export function useHeatmapStyle(id: string) {
    const heatmapStyle: LineLayer = {
        'id': id,
        'type': 'line',
        paint: {
            'line-color': ['get', 'color'],
            'line-width': ['get', 'width'],
            'line-opacity': ['get', 'opacity']
        }
    }

    return { heatmapStyle };
}

export function useTrackLayerStyle() {
    const [, theme] = useStyletron();

    const trackLayerStyle: LineLayer = {
        'id': 'track',
        'type': 'line',
        'paint': {
            'line-color': theme.colors.backgroundAlwaysDark,
            'line-width': 8,
        }
    }

    const trackOuterLayerStyle: LineLayer = {
        'id': 'track-outer',
        'type': 'line',
        'paint': {
            'line-color': theme.colors.backgroundAlwaysLight,
            'line-width': 10
        }
    }

    return { trackLayerStyle, trackOuterLayerStyle };
}

export function useDayRouteLayerStyle() {
    const { theme, isDarkTheme } = useTheme();

    const dayRouteLayerStyle = (id: string): LineLayer => {
        return {
            'id': id,
            'type': 'line',
            'paint': {
                'line-color': '#000',
                'line-width': 4,
                'line-dasharray': [1, 1],
                'line-gradient': [
                    'interpolate',
                    ['linear'],
                    ['line-progress'],
                    0,
                    COLOR_DAYROUTE1,
                    0.5,
                    COLOR_DAYROUTE2,
                    1,
                    COLOR_DAYROUTE3,
                ]
            }
        }
    }

    const multiDayRouteLayerStyle = (id: string, color:string): LineLayer => {
        return {
            'id': id,
            'type': 'line',
            'paint': {
                'line-color': color,
                'line-width': 4,
                'line-dasharray': [1, 1],
            }
        }
    }

    const dayRouteOuterLayerStyle = (id: string): LineLayer => {
        return {
            'id': id,
            'type': 'line',
            'paint': {
                'line-color': isDarkTheme ? COLOR_ASPHALT : COLOR_OFFWHITE,
                'line-width': 7,
            }
        }
    }

    return { dayRouteLayerStyle, dayRouteOuterLayerStyle, multiDayRouteLayerStyle };
}

export const trafficLayerStyle: LineLayer = {
    "id": "traffic",
    "source": "mapbox-traffic",
    "source-layer": "traffic",
    "type": "line",
    "paint": {
        "line-width": 1.5,
        "line-color": [
            "case",
            [
                "==",
                "low",
                [
                    "get",
                    "congestion"
                ]
            ],
            "#aab7ef",
            [
                "==",
                "moderate",
                [
                    "get",
                    "congestion"
                ]
            ],
            "#4264fb",
            [
                "==",
                "heavy",
                [
                    "get",
                    "congestion"
                ]
            ],
            "#ee4e8b",
            [
                "==",
                "severe",
                [
                    "get",
                    "congestion"
                ]
            ],
            "#b43b71",
            "#000000"
        ]
    }
}