import { useStyletron } from "baseui";
import { StyledLink } from "baseui/link";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { FlexGridLayout } from "../containers/FlexGridLayout";
import { FormInput } from "../ui/FormInput";
import { EmailIcon, PhoneIcon, VerifiedIcon } from "../ui/svg";
import { startVerifyEmail, startVerifyPhone } from "../../services/api";
import { useToast } from "../../hooks/useToast";
import { useStrings } from "../../hooks/useStrings";
import { emailValidator, phoneValidator } from "../../constants/validators";
import React, { useState } from "react";
import useAccount from "../../hooks/useAccount";
import { createPortal } from "react-dom";
import { ConfirmModal } from "../ui/ConfirmModal";
import { PinCode } from 'baseui/pin-code';
import { VFlex } from "../containers/VFlex";
import { ParagraphXSmall } from "baseui/typography";
import { HFlex } from "../containers/HFlex";
import { Spin } from "antd";
import { Uid } from "../../types/core";

type Props = {
    uid: Uid;
    emailValue: string;
    emailIsVerified: boolean;
    emailIsValid: boolean;
    phoneValue: string;
    phoneIsVerified: boolean;
    onFieldChange: (key: string, value: string | number) => void;
    onPhoneVerified: () => Promise<{ status: boolean }>;
    onNewPhone: () => Promise<{ status: boolean }>;
    disabled?: boolean;
    readonly?: boolean;
};

export function ContactFields({
                                  uid,
                                  emailValue,
                                  emailIsVerified,
                                  emailIsValid,
                                  phoneValue,
                                  phoneIsVerified,
                                  onFieldChange,
                                  onPhoneVerified,
                                  onNewPhone,
                                  disabled = false,
                                  readonly = false,
                              }: Props) {
    const [, theme] = useStyletron();
    const { linkStyles } = useStyleOverrides();
    const { strings, format } = useStrings();
    const toast = useToast();
    const [phoneCode, setPhoneCode] = useState("");
    const [emailEdited, setEmailEdited] = useState(false);
    const [phoneEdited, setPhoneEdited] = useState(false);
    const [phoneVerificationCode, setPhoneVerificationCode] = useState<string[]>(["", "", "", ""]);
    const [savingNewPhone, setSavingNewPhone] = useState(false);
    const [showVerifyPhone, setShowVerifyPhone] = useState(false);
    const [verifyPhoneError, setVerifyPhoneError] = useState("");
    const account = useAccount();
    const isSelfAccount = account.isSelfAccount(uid);

    const emailIcon = <EmailIcon size={12} color={theme.colors.contentTertiary}/>;
    const phoneIcon = <PhoneIcon size={12} color={theme.colors.contentTertiary}/>;
    const verifiedIcon = <VerifiedIcon size={12} color={theme.colors.positive}/>;

    const onVerifyEmailClick = async () => {
        if (emailValidator.test(emailValue)) {
            const { status, email } = await startVerifyEmail(uid);
            if (status) {
                if (email) {
                    toast.showSuccess(format(strings.ProfileModal.EmailVerificationCodeSentToX, email));
                }
            }
        } else {
            toast.showWarning(format(strings.ProfileModal.NoValidEmailAddressX, emailValue));
        }
    };

    const onVerifyPhoneClick = async () => {
        if (phoneValidator.test(phoneValue)) {
            let canVerify = true;
            if (phoneEdited) {
                setSavingNewPhone(true);
                const { status } = await onNewPhone();
                canVerify = status;
                if (status) {
                    setPhoneEdited(false);
                }
                setSavingNewPhone(false);
            }
            if (canVerify) {
                const { status, phone, code } = await startVerifyPhone(uid);
                if (status) {
                    if (account.uid === uid) {
                        setPhoneCode(code);
                        setShowVerifyPhone(true);
                    } else {
                        toast.showSuccess(format(strings.ProfileModal.PhoneVerificationCodeSentToX, phone));
                    }
                }
            }
        } else {
            toast.showWarning(format(strings.ProfileModal.NoValidPhoneNumberX, phoneValue));
        }
    };

    const verifyPhone = async () => {
        if (phoneCode === phoneVerificationCode.join("") && onPhoneVerified) {
            const { status } = await onPhoneVerified();
            if (status) {
                setPhoneEdited(false);
                toast.showSuccess(strings.ProfileModal.PhoneSuccessfullyVerified);
                closeVerifyPhone();
            }
        } else {
            setVerifyPhoneError(strings.Errors.IncorrectVerificationCode);
        }
    };

    const closeVerifyPhone = () => {
        setPhoneVerificationCode(["", "", "", ""]);
        setShowVerifyPhone(false);
    };

    return (
        <>
            <FlexGridLayout>
                <FormInput
                    label={strings.General.Email}
                    value={emailValue}
                    field="email"
                    onChange={(key: string, value: string | number) => {
                        setEmailEdited(true);
                        onFieldChange(key, value);
                    }}
                    startEnhancer={emailIcon}
                    endEnhancer={emailIsVerified && verifiedIcon}
                    type="email"
                    error={!emailIsValid ? strings.General.PleaseEnterAValidEmailAddress : undefined}
                    caption={
                        isSelfAccount &&
                        (!emailIsVerified || emailEdited) && (
                            <StyledLink className={linkStyles} onClick={onVerifyEmailClick}>
                                {strings.General.VerifyEmail}
                            </StyledLink>
                        )
                    }
                    disabled={disabled}
                    readonly={readonly}
                />
                <FormInput
                    label={strings.General.Phone}
                    value={phoneValue}
                    field="phone"
                    onChange={(key: string, value: string | number) => {
                        setPhoneEdited(true);
                        onFieldChange(key, value);
                    }}
                    startEnhancer={phoneIcon}
                    type="tel"
                    endEnhancer={phoneIsVerified && verifiedIcon}
                    caption={
                        isSelfAccount &&
                        (!phoneIsVerified || phoneEdited) && (
                            <HFlex alignCenter spacing>
                                <StyledLink className={linkStyles} onClick={onVerifyPhoneClick}>
                                    {strings.General.VerifyPhone}
                                </StyledLink>
                                {savingNewPhone && (
                                    <Spin size={"small"} style={{ marginBottom: theme.sizing.scale400 }}/>
                                )}
                            </HFlex>
                        )
                    }
                    disabled={disabled}
                    readonly={readonly}
                />
            </FlexGridLayout>
            {createPortal(
                <ConfirmModal
                    isOpen={showVerifyPhone}
                    title={strings.ProfileModal.EnterVerificationCode}
                    onCancel={closeVerifyPhone}
                    cancelText={strings.General.Cancel}
                    confirmText={strings.General.Accept}
                    description={
                        <VFlex style={{ rowGap: theme.sizing.scale300 }}>
                            <PinCode
                                values={phoneVerificationCode}
                                onChange={({ values }) => {
                                    setVerifyPhoneError("");
                                    setPhoneVerificationCode(values);
                                }}
                                error={!!verifyPhoneError}
                            />
                            {verifyPhoneError && (
                                <ParagraphXSmall color={theme.colors.negative}
                                                 margin={0}>{verifyPhoneError}</ParagraphXSmall>
                            )}
                        </VFlex>
                    }
                    canConfirm={!!phoneVerificationCode}
                    onConfirm={verifyPhone}
                />,
                document.body
            )}
        </>
    );
}
