import { SpacerExpand } from "../components/containers/Spacer";
import { Button } from "baseui/button";
import { HFlex } from "../components/containers/HFlex";
import { useRecoilState, useSetRecoilState } from "recoil";
import { importTypeState } from "../states/appState";
import { useNewPlace } from "../hooks/places/useNewPlace";
import { useStrings } from "../hooks/useStrings";
import { useOnboardRefs } from "../hooks/onboard/useOnboardRefs";
import { useOnboardPlaces } from "../hooks/onboard/useOnboardPlaces";
import { Plus } from "baseui/icon";
import React from "react";
import { Tag, Tooltip } from "antd";
import { activePlacesZonesTabState } from "../states/viewState";
import { useDrawMode } from "../hooks/useDrawMode";
import { tinyTag } from "../constants/ui";
import { InfoIcon, PlacesIcon, ZonesIcon } from "../components/ui/svg";
import { useTheme } from "../hooks/useTheme";
import { SwapTab } from "../components/ui/SwapTab";

export function PlacesAndZonesTitleBar() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const setImportType = useSetRecoilState(importTypeState);
    const { initNewPlace } = useNewPlace();
    const { importPlacesRef, addPlaceRef } = useOnboardRefs();
    const { finishOnboardPlaces } = useOnboardPlaces();
    const [activeKey, setActiveKey] = useRecoilState(activePlacesZonesTabState);
    const { enterDrawMode } = useDrawMode();

    const onImport = () => {
        setImportType(activeKey === "places" ? "places" : "zones");
    }

    const onNew = () => {
        if (activeKey === "places") {
            finishOnboardPlaces();
            initNewPlace();
        } else {
            enterDrawMode({
                type: "zone",
                action: "new",
            })
        }
    }

    return (
        <HFlex spacing alignCenter>
            <SwapTab activeKey={activeKey}
                     onChange={(activeKey) => setActiveKey(activeKey as "places" | "zones")}
                     tab1={{
                         key: "places",
                         label: <PlacesIcon color={theme.colors.contentPrimary} size={14}/>,
                         description: strings.General.Places,
                     }}
                     tab2={{
                         key: "zones",
                         label: <ZonesIcon color={theme.colors.contentPrimary} size={14}/>,
                         description: strings.General.Zones,
                     }}
            />
            <Tooltip title={strings.Places.PlacesAndZonesDescription}>
                <Tag style={{ ...tinyTag }}>
                    <InfoIcon color={theme.colors.contentPrimary} size={12}/>
                </Tag>
            </Tooltip>
            <SpacerExpand/>
            <Button ref={importPlacesRef} kind={"secondary"} size={"mini"} onClick={onImport}>
                {activeKey === "places" ? strings.Places.ImportPlaces : strings.Zones.ImportZones}
            </Button>
            <Button ref={addPlaceRef} kind={"primary"} size={"mini"} onClick={onNew}>
                <Plus/> {activeKey === "places" ? strings.Places.NewPlace : strings.Zones.NewZone}
            </Button>
        </HFlex>
    );
}