import { useRecoilState, useRecoilValue } from "recoil";
import { editingJobIdState } from "../../../states/appState";
import { editJobState } from "../../../states/editJobState";
import { Section } from "../../ui/Section";
import * as React from "react";
import { useStrings } from "../../../hooks/useStrings";
import { JobAttachments } from "./JobAttachments";
import { useJobAttachments } from "../../../hooks/jobs/useJobAttachments";
import { NotificationCircle } from "baseui/badge";

export function JobAttachmentsSection() {
    const { strings } = useStrings();
    const jobId = useRecoilValue(editingJobIdState);
    const [fields, setFields] = useRecoilState(editJobState(jobId || ''));
    const { jobAttachments, getNewAttachments, canAddAttachment, clearedAttachments } = useJobAttachments(fields);

    if (!fields) {
        return null;
    }

    const addNewAttachment = (url: string) => {
        const newJob = Object.assign(clearedAttachments, ...getNewAttachments(url));
        setFields(newJob);
    }

    const removeAttachment = (key: string) => {
        const newJob = Object.assign(clearedAttachments, ...getNewAttachments("", key));
        setFields(newJob);
    }

    return (
        <Section title={strings.Attachments.Attachments}
                 expandable={false}
                 collapsedNode={jobAttachments?.length && <NotificationCircle content={jobAttachments.length}/>}
                 noMarginTop
        >
            <JobAttachments attachments={jobAttachments}
                            onNewAttachment={addNewAttachment}
                            onRemoveAttachment={removeAttachment}
                            disabled={!canAddAttachment}
            />
        </Section>
    );
}