import { StatefulPopover } from "baseui/popover";
import { useTheme } from "../../hooks/useTheme";
import { HFlex } from "../containers/HFlex";
import { VFlex } from "../containers/VFlex";

type Props = {
    children: React.ReactNode;
    header: React.ReactNode;
    content: React.ReactNode;
    headerColor: string;
    placement?: "top" | "auto";
}

export function SectionedPopover({ children, header, headerColor, content, placement = "top" }: Props) {
    const { theme } = useTheme();

    return (
        <StatefulPopover
            content={() => (
                <VFlex>
                    <HFlex
                        style={{
                            backgroundColor: headerColor,
                            paddingLeft: theme.sizing.scale300,
                            paddingRight: theme.sizing.scale300,
                            paddingTop: theme.sizing.scale300,
                            paddingBottom: theme.sizing.scale300,
                            width: "auto"
                        }}>
                        {header}
                    </HFlex>
                    <HFlex style={{
                        paddingLeft: theme.sizing.scale300,
                        paddingRight: theme.sizing.scale300,
                        paddingTop: theme.sizing.scale300,
                        paddingBottom: theme.sizing.scale300,
                        width: "auto"
                    }}>
                        {content}
                    </HFlex>
                </VFlex>
            )}
            placement={placement}
            showArrow
            triggerType="hover"
            returnFocus
            autoFocus
            overrides={{
                Inner: {
                    style: {
                        overflow: "hidden"
                    }
                }
            }}
        >
            <span>{children}</span>
        </StatefulPopover>
    );
}