import { flushSync } from 'react-dom';
import { TourProps } from "antd";
import { useSetRecoilState } from "recoil";
import { onboardStatusState } from "../../states/onboardState";
import { companyTabFilterState, editingCompanyIdState } from "../../states/appState";
import useAccount from "../useAccount";
import { useOnboardStep } from "./useOnboardStep";
import { useOnboardRefs } from './useOnboardRefs';
import { useStrings } from '../useStrings';
import { ONBOARD_USERS_PERMISSIONS, TOUR_DELAY } from "../../constants/onboard";

export function useOnboardPermissions() {
    const { strings } = useStrings();
    const setCompanyTabFilter = useSetRecoilState(companyTabFilterState);
    const {
        adminsPermissionsRef,
        operatorsPermissionsRef,
        workersPermissionsRef,
        companyPermissionsRef
    } = useOnboardRefs();
    const account = useAccount();
    const setEditingCompany = useSetRecoilState(editingCompanyIdState);
    const {
        step,
        nextStep,
        prevStep,
        closeStep,
        openStep,
        isOpen,
        canTrigger
    } = useOnboardStep(ONBOARD_USERS_PERMISSIONS);
    const setOnboardStatus = useSetRecoilState(onboardStatusState);

    const permissionsSteps: TourProps["steps"] = [
        {
            title: strings.Onboard.CompanyPermissionsTitle,
            description: strings.Onboard.CompanyPermissionsDescription,
            target: companyPermissionsRef.current as HTMLElement,
            placement: "bottom",
            onNext: nextStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.AdministratorsTitle,
            description: strings.Onboard.AdministratorsDescription,
            target: adminsPermissionsRef.current as HTMLElement,
            placement: "top",
            onNext: nextStep,
            onPrev: prevStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.OperatorsTitle,
            description: strings.Onboard.OperatorsDescription,
            target: operatorsPermissionsRef.current as HTMLElement,
            placement: "bottom",
            onNext: nextStep,
            onPrev: prevStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.WorkersTitle,
            description: strings.Onboard.WorkersDescription,
            target: workersPermissionsRef.current as HTMLElement,
            placement: "bottom",
            onFinish: () => {
                finishOnboardPermissions();
            },
            onPrev: prevStep,
            onClose: closeStep,
        },
    ];

    const initOnboardPermissions = () => {
        flushSync(() => {
            setCompanyTabFilter("permissions");
            setEditingCompany(account.company?.uid || "");
        });
        setTimeout(openStep, TOUR_DELAY);
    };

    const finishOnboardPermissions = () => {
        closeStep();
        setOnboardStatus((prev) => ({ ...prev, usersPermissions: true }));
    };

    const voidFn = () => {};

    return {
        permissionsSteps,
        initOnboardPermissions,
        onboardPermissionsIsOpen: isOpen,
        permissionStep: step,
        permissionsPrevStep: canTrigger ? prevStep : voidFn,
        permissionsNextStep: canTrigger ? nextStep : voidFn,
    };
}
