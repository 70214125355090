import * as React from "react";
import { Table } from "baseui/table-semantic";
import { VFlex } from "../containers/VFlex";
import { useBilling } from "../../hooks/useBilling";
import { useTime } from "../../hooks/useTime";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { LabelSmall } from "baseui/typography";
import { Divider } from "antd";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";


export function UpcomingInvoice() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const { invoice } = useBilling();
    const { formatLongDate } = useTime();

    if (!invoice) {
        return null;
    }

    const usd = (amount: number) => "$" + amount / 100 + " USD";

    return (
        <VFlex>
            <HFlex spacing>
                <LabelSmall color={theme.colors.contentTertiary}>
                    {strings.Billing.PaymentAttempt}
                </LabelSmall>
                <LabelSmall color={theme.colors.contentTertiary}>
                    {formatLongDate(invoice.nextPaymentAttempt * 1000)}
                </LabelSmall>
            </HFlex>
            <Table
                size={"compact"}
                columns={["", strings.General.Description, strings.Billing.Amount]}
                data={invoice.lines.map(line => {
                    return [
                        formatLongDate(line.periodStart * 1000) + "-" + formatLongDate(line.periodEnd * 1000),
                        line.description,
                        usd(line.amount),
                    ];
                })}
            />
            <Divider/>
            <HFlex spacing>
                <SpacerExpand/>
                <VFlex spacing>
                    <LabelSmall>Subtotal</LabelSmall>
                    <LabelSmall>Total</LabelSmall>
                    <LabelSmall color={theme.colors.contentTertiary + "88"}>Applied Balance</LabelSmall>
                    <LabelSmall>Amount Due</LabelSmall>
                </VFlex>
                <VFlex spacing>
                    <LabelSmall>{usd(invoice.subtotal)}</LabelSmall>
                    <LabelSmall>{usd(invoice.total)}</LabelSmall>
                    <LabelSmall color={theme.colors.contentTertiary + "88"}>{usd(invoice.startingBalance)}</LabelSmall>
                    <LabelSmall style={{ fontWeight: "bold" }}>{usd(invoice.amountDue)}</LabelSmall>
                </VFlex>
            </HFlex>
        </VFlex>
    );
}