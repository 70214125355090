import { SpacerExpand } from "../containers/Spacer";
import { LightningIcon } from "../ui/svg";
import { FlexGridLayout } from "../containers/FlexGridLayout";
import { VFlex } from "../containers/VFlex";
import React from "react";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { MiniCard } from "../ui/MiniCard";
import { useChatAi } from "../../hooks/useChatAi";
import { analyticsEvent } from "../../utils/analytics";
import { Endpoint } from "../../services/api";

export function ChatAiSuggestions() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const { sendQuery } = useChatAi(Endpoint.salesaiquery);

    const suggestion = (text: string) => (
        <MiniCard onClick={
            () => {
                analyticsEvent("chatai_suggestionquery")
                sendQuery(text)
            }
        }>
            {text}
        </MiniCard>
    );

    return (
        <VFlex alignCenter spacing style={{ width: "300px", overflow: "auto" }}>
            <SpacerExpand/>
            <LightningIcon size={24} color={theme.colors.contentPrimary}/>
            <FlexGridLayout baseWidth={150}>
                {suggestion(strings.ChatAi.PricingCancellation)}
                {suggestion(strings.ChatAi.ScheduleDemo)}
                {suggestion(strings.ChatAi.HardwareNeeded)}
                {suggestion(strings.ChatAi.NewInformation)}
                {suggestion(strings.ChatAi.BenefitsCompared)}
                {suggestion(strings.ChatAi.ConcreteExamples)}
                {suggestion(strings.ChatAi.ContactHuman)}
                {suggestion(strings.ChatAi.TrialPeriod)}
                {suggestion(strings.ChatAi.GetStarted)}
            </FlexGridLayout>
            <SpacerExpand/>
        </VFlex>
    );
}