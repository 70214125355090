import { Labeled } from "../containers/Labeled";
import { StaffInput } from "./modal/StaffInput";
import { HFlex } from "../containers/HFlex";
import { Section } from "../ui/Section";
import { useJobExtraFields } from "../../hooks/jobs/useJobExtraFields";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { editJobState } from "../../states/editJobState";
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";
import { Job } from "../../types/job";
import { StatusBadge } from "../badges/StatusBadge";
import { getProgressStatus } from "../../utils/jobUtils";
import { MemberBadge } from "../badges/MemberBadge";
import { Checkbox } from "baseui/checkbox";
import { SpacerExpand } from "../containers/Spacer";
import { Button } from "baseui/button";

export function ShortFormResponseSection({
                                             job,
                                             allowEdit,
                                             showTitle = true
                                         }: { job: Job, allowEdit: boolean; showTitle?: boolean }) {
    const { theme } = useTheme();
    const { strings, format } = useStrings();
    const fields = job;
    const setFields = useSetRecoilState(editJobState(job.id || ''));
    const { items } = useJobExtraFields(fields);
    const [isEditing, setEditing] = useState(false);

    if (!fields) {
        return null;
    }

    const onChangeInput = (key: string, value: number) => {
        setFields({ ...fields, [key]: value });
    };

    const statusRow = (
        <HFlex spacing alignCenter style={{ marginBottom: "2px" }}>
            <StatusBadge progress={getProgressStatus(job)}/>
            <MemberBadge uid={job.receiverUid}/>
        </HFlex>
    );

    const labelWidth = showTitle ? 180 : 110;

    return (
        <Section title={showTitle ? strings.Job.StaffInput : ""} noMarginTop>
            {showTitle &&
                <>
                    <Labeled label={strings.General.Status} labelWidth={labelWidth}>
                        {statusRow}
                    </Labeled>
                    {job.textReceiver &&
                        <StaffInput
                            field=""
                            type={"text"}
                            label={strings.General.Message}
                            val={job.textReceiver}
                            readonly
                            textArea
                            labelWidth={labelWidth}
                        />
                    }
                </>
            }

            {items.map((field) => {
                if (field.type === "checkbox") {
                    return (
                        <Labeled key={field.keyFieldName} label={field.name} labelWidth={labelWidth}>
                            <Checkbox
                                checked={field.value > 0}
                                onChange={(ev) => isEditing && onChangeInput(field.valFieldName, ev.target.checked ? 1 : 0)}
                            />
                        </Labeled>
                    );
                } else {
                    return <StaffInput key={field.keyFieldName}
                                       type={field.type === "number" && isEditing ? "number" : "text"}
                                       label={field.name}
                                       field={field.valFieldName}
                                       val={field.value}
                                       readonly={!isEditing}
                                       labelWidth={labelWidth}
                    />;
                }
            })}
            {allowEdit && !isEditing && (
                <HFlex style={{
                    marginTop: theme.sizing.scale200,
                }}>
                    <SpacerExpand/>
                    <Button onClick={() => setEditing(true)} size='compact' kind='secondary'>
                        {strings.General.Edit}
                    </Button>
                </HFlex>
            )}
        </Section>
    );
}