import React from "react";

const _styles = require("baseui/styles");

function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}

function _iterableToArrayLimit(arr, i) {
    var _i = arr === null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i === null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally {
        try {
            if (!_n && _i["return"] !== null) _i["return"]();
        } finally {
            if (_d) throw _e;
        }
    }
    return _arr;
}

function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(o);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _arrayLikeToArray(arr, len) {
    if (len === null || len > arr.length) len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++) {
        arr2[i] = arr[i];
    }
    return arr2;
}

export function LocationArrow(_ref) {
    var heading = _ref.heading;
    var iconSize = _ref.iconSize * 0.7;
    var color = _ref.color;

    var _useStyletron = (0, _styles.useStyletron)(),
        _useStyletron2 = _slicedToArray(_useStyletron, 2),
        css = _useStyletron2[0],
        theme = _useStyletron2[1];

    return React.createElement("svg", {
        className: css({
            height: "".concat(6, "px"),
            width: "".concat(11, "px"),
            position: 'absolute',
            color: "".concat(color),
            transition: "".concat(theme.animation.timing100, " ").concat(theme.animation.easeOutCurve, " all")
        }),
        style: {
            transform: "rotate(".concat(heading, "deg) translateY(-").concat(iconSize, "px)")
        },
        width: "11",
        height: "6",
        viewBox: "0 0 11 6",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M5.5 0L0 6L5.5 5L11 6L5.5 0Z",
        fill: "currentColor"
    }));
}