import { CustomField } from '../../types/core';
import { getDiffFields } from '../../utils/objects';
import { useRecoilState } from 'recoil';
import { companyState } from '../../states/accountState';
import { editAccount } from '../../services/api';
import { Company } from '../../types/company';
import { useToast } from "../useToast";
import { useStrings } from "../useStrings";
import { LinkedForm } from "../../types/linked";

export function useCompanyDefaults() {
    const [company, setCompany] = useRecoilState(companyState);
    const toast = useToast();
    const { strings } = useStrings();

    const handleEditCompany = async (data: {}) => {
        if (!company) {
            return;
        }
        const diffFields = getDiffFields(company, data);
        const hasChanges = !!Object.entries(diffFields).length;
        if (!hasChanges) {
            return;
        }
        const { status, account: updatedCompany } = await editAccount({ uid: company.uid, ...data });
        if (status) {
            setCompany(updatedCompany as Company);
        }
    }

    const setCarryOverDefault = (carryOver: boolean) => {
        const newCompany = {} as Company;
        newCompany.job_carry_over_default = carryOver;
        handleEditCompany(newCompany);
        toast.showSuccess(strings.General.Saved);
    }

    const setOnSiteDefault = (onSite: number) => {
        const newCompany = {} as Company;
        newCompany.job_onsite_default = onSite;
        handleEditCompany(newCompany);
        toast.showSuccess(strings.General.Saved);
    }

    const setJobCustomFieldsDefault = (customFields: CustomField[]) => {
        const newCompany = {} as Company;
        newCompany.job_custom_fields = customFields.map((customField) => customField.key);
        handleEditCompany(newCompany);
        toast.showSuccess(strings.General.Saved);
    }

    const setAttachmentsDefault = (attachments: string[]) => {
        const newCompany = {} as Company;
        newCompany.job_attachment_template = attachments;
        handleEditCompany(newCompany);
        toast.showSuccess(strings.General.Saved);
    }

    const setPlaceCustomFieldsDefault = (customFields: CustomField[]) => {
        const newCompany = {} as Company;
        newCompany.place_custom_fields = customFields.map((customField) => customField.key);
        handleEditCompany(newCompany);
        toast.showSuccess(strings.General.Saved);
    }

    const setPlaceLinkedFormsTemplate = (linkedForms: LinkedForm[]) => {
        let linkedFormsJsonString = '';
        if (!!linkedForms.length) {
            linkedFormsJsonString = JSON.stringify(linkedForms);
        }
        const newCompany = {} as Company;
        newCompany.place_linked_forms_template = linkedFormsJsonString;
        handleEditCompany(newCompany);
        toast.showSuccess(strings.General.Saved);
    }

    const getDefaultAttachments = () => {
        const attachments: {[key: `url_${number}`]: string} = {};
        company?.job_attachment_template.forEach((attachment, index) => {
            attachments[`url_${index + 1}`] = attachment;
        });
        return attachments;
    }

    const getDefaultCustomFields = () => {
        return company?.job_custom_fields.map((customField) => ({
            key: customField,
            val: ''
        })) || [];
    }

    return {
        setCarryOverDefault,
        setOnSiteDefault,
        setJobCustomFieldsDefault,
        setAttachmentsDefault,
        setPlaceCustomFieldsDefault,
        setPlaceLinkedFormsTemplate,
        getDefaultAttachments,
        getDefaultCustomFields
    }
}