import { useRecoilValue, useResetRecoilState } from "recoil";
import { attachedLocationState, conversationState, newMessageEventState } from "../../states/messagesState";
import { VFlex } from "../containers/VFlex";
import { useConversation } from "../../hooks/useConversation";
import React, { memo, useEffect, useRef, useState } from "react";
import { HFlex } from "../containers/HFlex";
import { Input } from "baseui/input";
import { Button } from "baseui/button";
import { UnorderedList } from "../containers/UnorderedList";
import { ChatMessage } from "./ChatMessage";
import { useTheme } from "../../hooks/useTheme";
import { StaticMap } from "../staticmap/StaticMap";
import { RemoveIcon, WorldPinIcon } from "../ui/svg";
import { HoverButton } from "../buttons/HoverButton";
import { useStrings } from "../../hooks/useStrings";
import { useLocationAttachment } from "../../hooks/useLocationAttachment";
import { Uid } from "../../types/core";

export const ChatList = memo(function ({ memberUid }: { memberUid: Uid }) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const { load, send } = useConversation(memberUid);
    const conversation = useRecoilValue(conversationState(memberUid));
    const [input, setInput] = useState("");
    const location = useRecoilValue(attachedLocationState);
    const { initAttachment } = useLocationAttachment();
    const newMessageEvent = useRecoilValue(newMessageEventState);
    const resetNewMessageEvent = useResetRecoilState(newMessageEventState);
    const lastElementRef = useRef(null);
    const container: any = lastElementRef.current;
    const resetAttachedLocation = useResetRecoilState(attachedLocationState);

    useEffect(() => {
        container?.scrollIntoView({ behavior: "auto" });
    }, [lastElementRef, container, memberUid]);
    useEffect(() => load(), [memberUid]);
    useEffect(() => {
        if (newMessageEvent) {
            load();
            resetNewMessageEvent();
        }
    }, [newMessageEvent]);
    const messages = conversation;
    const onSendClick = () => {
        send(input, memberUid);
        resetAttachedLocation();
        setInput("");
    };
    const onAddLocationClick = () => initAttachment();
    return (
        <VFlex>
            <UnorderedList style={{ paddingLeft: "8px", paddingRight: "8px", paddingBottom: "32px" }}>
                {messages.map((m, index) => <ChatMessage key={index} message={m}/>)}
                <div ref={lastElementRef}/>
            </UnorderedList>
            <HFlex spacing style={{ marginBottom: "8px" }}>
                <Input placeholder={strings.Messages.TypeMessageHere}
                       value={input}
                       onChange={(event) => setInput(event.target.value)}
                       onKeyDown={event => {
                           if (event.key === "Enter") {
                               onSendClick();
                           }
                       }}
                       startEnhancer={() => {
                           return location
                               ? <HFlex style={{ alignItems: "start" }}>
                                   <StaticMap location={location} size={72} padding={4} viewMarker/>
                                   <Button kind={"secondary"}
                                           size={"mini"}
                                           style={{
                                               paddingTop: "2px",
                                               paddingBottom: "2px",
                                               paddingLeft: "2px",
                                               paddingRight: "2px",
                                           }}
                                           onClick={() => resetAttachedLocation()}
                                   >
                                       <RemoveIcon size={16} color={theme.colors.contentPrimary}/>
                                   </Button>
                               </HFlex>
                               : <HoverButton tooltip={strings.General.AttachLocation} size={"mini"} kind={"tertiary"}
                                              onClick={onAddLocationClick}>
                                   <WorldPinIcon size={24} color={theme.colors.contentPrimary}/>
                               </HoverButton>
                       }}
                />
                <Button size={"mini"}
                        disabled={input.length === 0}
                        onClick={onSendClick}>
                    {strings.General.Send}
                </Button>
            </HFlex>
        </VFlex>
    );
});