import React from "react";
import { StatefulTooltip } from "baseui/tooltip";
import { Button, KIND } from "baseui/button";
import { useStyletron } from "baseui";

type Props = {
    tooltip: string,
    onClick?: Function,
    disabled?: boolean;
    children: React.ReactNode,
}

export function ItemButton({ tooltip, onClick = () => {}, disabled = false, children }: Props) {

    const [, theme] = useStyletron();

    return (
        <StatefulTooltip content={() => (tooltip)} showArrow placement={"top"}>
            <Button size="compact"
                    kind={KIND.tertiary}
                    onClick={() => onClick()}
                    disabled={disabled}
                    style={{ color: theme.colors.contentSecondary }}>
                {children}
            </Button>
        </StatefulTooltip>
    );
}