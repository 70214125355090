import { Typography } from "antd";
import { Avatar } from "baseui/avatar";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { StyledDivider } from "baseui/divider";
import { ChevronDown } from "baseui/icon";
import { StatefulPopover } from "baseui/popover";
import { PLACEMENT } from "baseui/toast";
import { HeadingSmall } from "baseui/typography";
import { HELLOTRACKS } from "../../constants/app";
import { PAGE } from "../../constants/pages";
import useAccount from "../../hooks/useAccount";
import useDirector from "../../hooks/useDirector";
import { useNavigator } from "../../hooks/useNavigator";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { MenuButton } from "../menu/MenuButton";
import { MenuContainer } from "../menu/MenuContainer";
import { LogoIcon } from "../ui/svg";

const { Text } = Typography;

export function SimpleNavbar() {
    const { strings } = useStrings();
    const { theme, isDarkTheme } = useTheme();
    const { navigateToPage } = useNavigator();
    const { name, username, icon, isSelfWorker } = useAccount();
    const director = useDirector();

    return (
        <Block
            paddingLeft="8px"
            paddingRight="8px"
            paddingTop="8px"
            paddingBottom="8px"
            right={0}
            left={0}
            display="flex"
            alignItems="center"
            style={{
                width: "100%",
                borderBottom: "1px solid " + theme.colors.borderOpaque,
            }}
        >
            <span
                style={{ display: "flex", alignItems: "center", cursor: isSelfWorker ? "default" : "pointer" }}
                onClick={!isSelfWorker ? () => navigateToPage(PAGE.LIVE, true) : undefined}
            >
                <LogoIcon size={24} color={isDarkTheme ? "white" : "black"} />
                <Spacer />
                <HeadingSmall margin={0}>{HELLOTRACKS}</HeadingSmall>
            </span>
            <SpacerExpand />
            <StatefulPopover
                focusLock
                content={({ close }) => (
                    <MenuContainer>
                        <Button
                            kind={"tertiary"}
                            onClick={() => {
                                close();
                            }}
                        >
                            <VFlex style={{ textAlign: "start" }}>
                                <Text
                                    ellipsis
                                    style={{
                                        color: theme.colors.contentPrimary,
                                        maxWidth: "150px",
                                        fontWeight: "normal",
                                        fontSize: "14px",
                                        lineHeight: "15px",
                                    }}
                                >
                                    {name}
                                </Text>
                                <Text
                                    ellipsis
                                    style={{
                                        color: theme.colors.contentSecondary,
                                        maxWidth: "150px",
                                        fontWeight: "normal",
                                        fontSize: "11px",
                                        lineHeight: "11px",
                                    }}
                                >
                                    {username}
                                </Text>
                            </VFlex>
                        </Button>
                        <StyledDivider
                            $size="cell"
                            style={{
                                width: "auto",
                                marginLeft: "10px",
                                marginRight: "10px",
                                marginTop: 0,
                                marginBottom: 0,
                            }}
                        />
                        <MenuButton
                            title={strings.General.SignOut}
                            onClick={() => {
                                director.logout();
                                close();
                            }}
                        />
                    </MenuContainer>
                )}
                accessibilityType={"tooltip"}
                placement={PLACEMENT.bottomRight}
                showArrow
                triggerType={"click"}
                overrides={{
                    Body: {
                        style: {
                            zIndex: 3,
                        },
                    },
                }}
            >
                <Button
                    shape={"pill"}
                    kind={"tertiary"}
                    size={"compact"}
                    overrides={{
                        BaseButton: {
                            style: {
                                paddingTop: "4px",
                                paddingBottom: "4px",
                                paddingRight: "6px",
                                paddingLeft: "6px",
                                ":hover": {
                                    backgroundColor: "transparent",
                                },
                            },
                        },
                    }}
                >
                    <HFlex alignCenter spacing={theme.sizing.scale100}>
                        <Avatar name={name} size={"scale800"} src={icon} />
                        <ChevronDown />
                    </HFlex>
                </Button>
            </StatefulPopover>
        </Block>
    );
}
