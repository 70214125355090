import { VFlex } from "../containers/VFlex";
import { LabelXSmall } from "baseui/typography";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useDirector from "../../hooks/useDirector";
import { useTheme } from "../../hooks/useTheme";
import useAccount from "../../hooks/useAccount";
import {
    accountMenuIsOpenState,
    companyTabFilterState,
    editingCompanyIdState,
    editingMemberIdState,
    sidebarPinnedState
} from "../../states/appState";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { Button } from "baseui/button";
import { Avatar } from "baseui/avatar";
import { ChevronDown } from "baseui/icon";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { useStrings } from "../../hooks/useStrings";
import { useProSidebar } from "react-pro-sidebar";
import React from "react";
import { MenuButton } from "./MenuButton";
import { Divider } from "./Divider";
import { MenuContainer } from "./MenuContainer";
import { Typography } from 'antd';
import { CompanyIcon, EditUserIcon } from "../ui/svg";
import { EllipsedText } from "../ui/EllipsedText";
import { useToast } from "../../hooks/useToast";

const { Text } = Typography;

export function AccountMenu() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const account = useAccount();
    const director = useDirector();
    const setEditingMember = useSetRecoilState(editingMemberIdState);
    const setEditingCompany = useSetRecoilState(editingCompanyIdState);
    const setCompanyTabFilter = useSetRecoilState(companyTabFilterState);
    const { toggleSidebar, collapsed } = useProSidebar();
    const sidebarPinned = useRecoilValue(sidebarPinnedState);
    const setAccountMenuIsOpen = useSetRecoilState(accountMenuIsOpenState);
    const toast = useToast();

    const onClick = (id: "company" | "billing" | "user" | "signout") => {
        if (id === 'signout') {
            director.logout();
        } else if (!account.isBusiness) {
            toast.showInfo(strings.General.OnlyAllowedForBusiness);
        } else if (id === 'company' && account.company) {
            setEditingCompany(account.company.uid);
        } else if (id === 'billing' && account.company) {
            setCompanyTabFilter("billing");
            setEditingCompany(account.company.uid);
        } else if (id === 'user') {
            if (account.company?.uid !== account.uid) {
                setEditingMember(account.uid);
            } else if (account.company && account.company.uid === account.uid) {
                setEditingCompany(account.company.uid);
            }
        }
        toggleSidebar(false);
    }

    return (
        <StatefulPopover
            focusLock
            content={({ close }) => (
                <MenuContainer>
                    <Button kind={"tertiary"} onClick={() => {
                        onClick("user");
                        close();
                    }}>
                        <HFlex alignCenter>
                            <EditUserIcon size={16}
                                          color={theme.colors.contentPrimary}
                                          style={{ flexShrink: 0 }}/>
                            <VFlex>
                                <HFlex>
                                    <Spacer/>
                                    <Text ellipsis style={{
                                        color: theme.colors.contentPrimary,
                                        maxWidth: "116px",
                                        fontWeight: "normal",
                                        fontSize: "14px",
                                        lineHeight: "15px",
                                    }}>
                                        {account.name}
                                    </Text>
                                    <SpacerExpand/>
                                </HFlex>
                                <HFlex style={{ width: "100%" }}>
                                    <Spacer/>
                                    <Text ellipsis style={{
                                        color: theme.colors.contentSecondary,
                                        maxWidth: "116px",
                                        fontWeight: "normal",
                                        fontSize: "11px",
                                        lineHeight: "11px",
                                    }}>
                                        {account.username}
                                    </Text>
                                    <SpacerExpand/>
                                </HFlex>
                            </VFlex>
                        </HFlex>
                    </Button>
                    <Divider/>
                    <MenuButton icon={<CompanyIcon size={10} color={theme.colors.contentPrimary}/>} title={strings.Company.Company} onClick={() => {
                        onClick("company");
                        close();
                    }}/>
                    <Divider/>
                    <MenuButton title={strings.General.Billing} onClick={() => {
                        onClick("billing");
                        close();
                    }}/>
                    <Divider/>
                    <MenuButton title={strings.General.SignOut} onClick={() => {
                        onClick("signout");
                        close();
                    }}/>
                </MenuContainer>
            )}
            accessibilityType={'tooltip'}
            placement={PLACEMENT.bottom}
            showArrow
            triggerType={"click"}
            onOpen={() => setAccountMenuIsOpen(true)}
            onClose={() => setAccountMenuIsOpen(false)}
            ignoreBoundary
            overrides={{
                Body: {
                    style: {
                        zIndex: 3,
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "#FFFFFF66",
                    }
                },
                Arrow: {
                    style: {
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "#FFFFFF66",
                    }
                },
            }}
        >
            <Button
                shape={"pill"}
                kind={"tertiary"}
                size={"compact"}
                overrides={{
                    BaseButton: {
                        style: {
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            ":hover": {
                                backgroundColor: "transparent",
                            }
                        },
                    }
                }}>
                <VFlex alignCenter spacing={"4px"}>
                    <HFlex alignCenter style={{ maxWidth: sidebarPinned ? "200px" : "100px", justifyContent: "center", flexWrap: "nowrap" }}>
                        <Avatar name={account.name} size={"scale800"} src={account.icon}/>
                        {!collapsed && (
                            <>
                                {sidebarPinned && <>
                                    <Spacer/>
                                    <LabelXSmall style={{ maxWidth: "200px", textOverflow: 'ellipsis' }}
                                                 color={theme.colors.contentPrimary}
                                    >
                                        <EllipsedText inline breakAll>
                                            {account.name}
                                        </EllipsedText>
                                    </LabelXSmall>
                                </>}
                                <Spacer/>
                                <ChevronDown color={theme.colors.contentPrimary}/>
                            </>
                        )}
                    </HFlex>
                </VFlex>
            </Button>
        </StatefulPopover>
    );
}