import { useRecoilValue } from 'recoil';
import { CompanyModalContent } from '../components/company/modal/CompanyModalContent';
import { JobModalContent } from '../components/job/modal/JobModalContent';
import { MemberModalContent } from '../components/member/modal/MemberModalContent';
import { PlaceModalContent } from '../components/places/modal/PlaceModalContent';
import { ZoneModalContent } from '../components/zones/modal/ZoneModalContent';
import { modalContentState } from "../states/appState";

export function ModalContentView() {
    const modalContent = useRecoilValue(modalContentState);

    if (modalContent === 'member') {
        return <MemberModalContent/>;
    }
    if (modalContent === 'job') {
        return <JobModalContent/>;
    }
    if (modalContent === 'company') {
        return <CompanyModalContent/>;
    }
    if (modalContent === 'place') {
        return <PlaceModalContent/>;
    }
    if (modalContent === 'zone') {
        return <ZoneModalContent/>
    }

    return null;
}
