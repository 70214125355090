import { DAY } from "../utils/time";
import { useStrings } from "./useStrings";
import useAccount from "./useAccount";
import { Timestamp } from "../types/core";


export function useBilling() {
    const { strings, formatDate } = useStrings();
    const { company, billing } = useAccount();
    const createdTs = (company ? company.stat_creation : 0) as Timestamp;
    const activatedTs: Timestamp = (company ? company.activated_ts : 0) as Timestamp;
    const trialEndUnixTs = billing?.subscription?.trialEnd;
    const endOfTrial: Timestamp = (trialEndUnixTs ? trialEndUnixTs * 1000 : createdTs + DAY * 30) as Timestamp;
    const isTrialing = endOfTrial > Date.now();
    const isActiveCompany = activatedTs > 0;
    const paymentMethods = billing?.paymentMethods;
    const defaultPaymentMethod = billing?.defaultPaymentMethod;
    const hasPaymentSource = paymentMethods && paymentMethods.length > 0;
    const currentPlan = billing?.subscription?.plan || "";
    const isTestAccount = currentPlan === "test";
    const isDirectBilling = currentPlan === "price_1L7lw3HGYIdn33WtBLHJMwlu" || currentPlan === "free_license";
    const isLegacyPlan = billing?.subscription?.plan === "business_license"
    const isUnpaid = billing?.subscription?.status === "unpaid";
    const billingStatus = billing?.subscription?.status;
    const applicationStatusText = (!isActiveCompany ? strings.Billing.Inactive
        : (isDirectBilling || hasPaymentSource)
            ? formatDate(strings.Billing.ActiveSinceX, activatedTs)
            : isTrialing
                ? formatDate(strings.Billing.TrialingUntil, endOfTrial)
                : strings.Billing.PaymentMethodMissing) as string;
    let statusText: (string);
    const subscription = billing?.subscription;
    switch (subscription?.status) {
        case "trialing":
            statusText = formatDate(strings.Billing.TrialingUntil, ((subscription?.trialEnd || 0) * 1000) as Timestamp);
            break;
        case "active":
            statusText = strings.Billing.Active;
            break;
        case "past_due":
            statusText = strings.Billing.PastDue;
            break;
        case "canceled":
            statusText = formatDate(strings.Billing.CanceledAtX, ((subscription?.canceledAt || 0) * 1000) as Timestamp);
            break;
        case "unpaid":
            statusText = strings.Billing.Unpaid;
            break;
        default:
            statusText = "";
    }
    const licenseCaption = subscription?.quantity + " x " + strings.Billing.TeamMember;
    const isDelinquent = subscription?.status === "unpaid";
    const invoice = billing?.invoice;

    const getStripePlanName = () => {
        if (!billing?.subscription?.plan) {
            return "No Plan";
        }
        switch (billing?.subscription.plan) {
            case "price_1L7lw3HGYIdn33WtBLHJMwlu":
                return "Hellotracks Business — Direct Billing";
            case "free_license":
                return "Hellotracks Business — Direct Billing";
            case "business_license":
                return "Hellotracks Business";
            case "business_location_mo":
                return "Hellotracks Business - Location — Monthly Subscription";
            case "business_location_yr":
                return "Hellotracks Business - Location — Annual Subscription";
            case "business_dispatch_mo":
                return "Hellotracks Business - Dispatch — Monthly Subscription";
            case "business_dispatch_yr":
                return "Hellotracks Business - Dispatch — Annual Subscription";
            case "test":
                return "Hellotracks Business — Test Subscription";
        }
        return "Hellotracks Enterprise";
    }

    const getCurrencyFromBrowser = (): string => {
        const parts = window.navigator.language.split('-');
        const countryCode = parts.length > 1 ? parts[1] : parts[0];

        const countryToCurrency: { [country: string]: string } = {
            'US': 'USD',
            'GB': 'GBP',
            'CA': 'CAD',
            'AU': 'AUD',
            'FR': 'EUR',
            'BE': 'EUR',
            'LU': 'EUR',
            'CH': 'EUR',
            'DE': 'EUR',
            'AT': 'EUR',
            'MX': 'MXN',
            'CO': 'COP',
            'AE': 'AED',
            'VN': 'VND',
            'ZA': 'ZAR',
            'PH': 'PHP',
            'IN': 'INR',
            'ID': 'IDR',
            'BR': 'BRL',
            'ES': 'EUR',
            'IT': 'EUR',
            'NL': 'EUR',
            'PT': 'EUR',
            'GR': 'EUR',
            'FI': 'EUR',
            'EE': 'EUR',
            'LV': 'EUR',
            'LT': 'EUR',
            'SK': 'EUR',
            'SI': 'EUR',
            'MT': 'EUR',
            'CY': 'EUR',
            'AR': 'USD',
            'VE': 'USD',
            'NZ': 'NZD',
        };

        return countryToCurrency[countryCode] || 'USD';
    }


    return {
        endOfTrial,
        isTrialing,
        isDelinquent,
        isActiveCompany,
        licenseCaption,
        statusText,
        applicationStatusText,
        hasPaymentSource,
        paymentMethods,
        defaultPaymentMethod,
        invoice,
        billing,
        isTestAccount,
        isDirectBilling,
        isUnpaid,
        billingStatus,
        getStripePlanName,
        isLegacyPlan,
        getCurrencyFromBrowser,
    }
}