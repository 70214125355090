import { Alert, Spin } from "antd";
import { Button } from "baseui/button";
import { FormControl } from "baseui/form-control";
import { Alert as AlertIcon } from "baseui/icon";
import { Input } from "baseui/input";
import { Modal, ModalBody, ROLE, SIZE } from "baseui/modal";
import { HeadingXLarge, ParagraphSmall } from "baseui/typography";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import { Centered } from "../containers/Centered";
import { VFlex } from "../containers/VFlex";
import { EmptyState } from "../ui/EmptyState";
import { COLOR_LOGIN_BACKGROUND } from "../../constants/colors";
import { useNavigator } from "../../hooks/useNavigator";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { passwordRecovery, STATUS_ERROR_NOPERMISSION } from "../../services/api";
import { useRecoilState, useRecoilValue } from "recoil";
import { passwordRecoveryActivationState } from "../../states/viewState";
import { GetApp } from "./GetApp";
import useDirector from "../../hooks/useDirector";
import { passwordState, usernameState } from "../../states/accountState";
import useAccount from "../../hooks/useAccount";
import { analyticsEvent } from "../../utils/analytics";
import { useSignup } from "../../hooks/useSignup";
import { PasswordStrengthBar } from "../ui/PasswordStrengthBar";

export default function PasswordRecoveryView() {
    const { paramToken } = useParams();
    const { theme } = useTheme();
    const { strings } = useStrings();
    const { navigateToPage } = useNavigator();
    const [name, setName] = useState("");
    const [username, setUsername] = useRecoilState(usernameState);
    const [password, setPassword] = useRecoilState(passwordState);
    const { passwordValid } = useSignup();
    const [hasPermission, setHasPermission] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loadingData, setLoadingData] = useState(true);
    const [loading, setLoading] = useState(false);
    const passwordsDidMatch = password && confirmPassword ? password === confirmPassword : true;
    const activation = useRecoilValue(passwordRecoveryActivationState);
    const [allDone, setAllDone] = useState(false);
    const { performLogin, logout } = useDirector();
    const { uid } = useAccount();

    useEffectOnce(() => {
        const validateToken = async () => {
            setLoadingData(true);
            const { status, name, username, errorCode } = await passwordRecovery({
                token: paramToken || "",
            });
            if (status) {
                setName(name || "");
                setUsername(username || "");
                setHasPermission(true);
            } else if (errorCode === STATUS_ERROR_NOPERMISSION) {
                setHasPermission(false);
            } else {
                setErrorMessage(strings.General.SomethingWentWrong);
            }
            setLoadingData(false);
        };

        validateToken();
    });

    const onRestorePassword = async () => {
        setLoading(true);
        const { status, redirect, invalidPasswordMessage } = await passwordRecovery({
            token: paramToken || "",
            newPassword: password,
            ...(activation && { newName: name }),
        });
        if (status && redirect) {
            if (redirect === "login") {
                if (uid) {
                    analyticsEvent("password_recovery", "already_logged_in");
                    navigateToPage("login")
                    logout();
                } else {
                    setLoading(true);
                    const { status } = await performLogin(true);
                    setLoading(false);
                    setAllDone(true);
                    if (status) {
                        analyticsEvent("password_recovery", "logging_in_success");
                        navigateToPage("");
                    } else {
                        analyticsEvent("password_recovery", "logging_in_failed");
                    }
                }
            } else {
                setAllDone(true);
                analyticsEvent("password_recovery", "all_done");
            }
        } else if (status && invalidPasswordMessage) {
            setErrorMessage(invalidPasswordMessage);
        } else {
            setErrorMessage(strings.General.SomethingWentWrong);
        }
        setLoading(false);
    };

    return (
        <Modal
            isOpen={true}
            closeable={false}
            animate={false}
            autoFocus
            size={SIZE.default}
            role={ROLE.dialog}
            overrides={{
                Dialog: {
                    style: {
                        backgroundColor: theme.colors.backgroundTertiary + "F0",
                        padding: theme.sizing.scale1400,
                    },
                },
                DialogContainer: {
                    style: {
                        backgroundColor: COLOR_LOGIN_BACKGROUND,
                        backgroundImage: "url(/images/login-background.svg)",
                        backgroundPosition: "50%",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    },
                },
            }}
        >
            <ModalBody
                $style={{
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                }}
            >
                {loadingData && !hasPermission && (
                    <Centered>
                        <Spin/>
                    </Centered>
                )}
                {!loadingData && !hasPermission && (
                    <EmptyState
                        title={strings.General.NoPermission}
                        cta={
                            <Button
                                onClick={() => navigateToPage("login", true)}
                                overrides={{
                                    BaseButton: {
                                        style: {
                                            marginTop: theme.sizing.scale400,
                                            width: "100%",
                                        },
                                    },
                                }}
                            >
                                {strings.General.Login}
                            </Button>
                        }
                    >
                        <AlertIcon $size={50} $color={theme.colors.negative}/>
                    </EmptyState>
                )}
                {!loadingData && hasPermission && (
                    <>
                        <VFlex alignCenter style={{ marginBottom: theme.sizing.scale1000 }}>
                            <HeadingXLarge margin={0}>
                                {!activation
                                    ? strings.General.PasswordRecovery
                                    : strings.General.WelcomeToHellotracksBusiness}
                            </HeadingXLarge>
                        </VFlex>
                        <VFlex alignCenter spacing style={{ marginBottom: theme.sizing.scale600 }}>
                            {!activation && <ParagraphSmall margin={0}>{name}</ParagraphSmall>}
                            <ParagraphSmall margin={0}>{username}</ParagraphSmall>
                        </VFlex>
                        {activation &&
                            <FormControl label={strings.General.Name}>
                                <Input
                                    type="name"
                                    name="name"
                                    placeholder={strings.General.Name}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    clearOnEscape
                                />
                            </FormControl>
                        }
                        <FormControl
                            label={strings.General.Password}
                        >
                            <VFlex>
                                <Input
                                    type="password"
                                    name="password"
                                    placeholder={strings.General.NewPassword}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    clearOnEscape
                                />
                                <PasswordStrengthBar password={password}/>
                            </VFlex>
                        </FormControl>
                        <FormControl
                            label={strings.General.Confirm}
                            error={!passwordsDidMatch ? strings.General.NewPasswordsDoNotMatch : undefined}
                        >
                            <Input
                                type="password"
                                name="confirmPassword"
                                placeholder={strings.General.NewPassword}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                clearOnEscape
                            />
                        </FormControl>
                        {errorMessage && <Alert showIcon type={"warning"} description={errorMessage}/>}
                        <VFlex style={{ marginTop: theme.sizing.scale1000 }}>
                            {allDone && <GetApp
                                title={<span style={{ fontSize: "40px" }} role="img" aria-label="Well Done">🙌</span>}/>}
                            {!allDone && <Button
                                onClick={onRestorePassword}
                                disabled={!password || !confirmPassword || !passwordValid.valid || !passwordsDidMatch}
                                isLoading={loading}
                            >
                                {strings.General.Confirm}
                            </Button>
                            }
                        </VFlex>
                    </>
                )}
            </ModalBody>
        </Modal>
    );
}
