import { Button } from "baseui/button";
import { LabelLarge, LabelXSmall } from "baseui/typography";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useEditTeamLocation } from "../../hooks/members/useEditTeamLocation";
import { useStrings } from "../../hooks/useStrings";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { useTeamForms } from "../../hooks/members/useTeamForms";
import { useTheme } from "../../hooks/useTheme";
import { fieldsForTeamEditState } from "../../states/editTeamState";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { StaticMap } from "../staticmap/StaticMap";
import { memo } from "react";

type Props = {
    teamId: number;
    close: () => void;
};

export const TeamRouting = memo(({ teamId, close }: Props) => {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const teamFields = useRecoilValue(fieldsForTeamEditState(teamId));
    const { hasChanges, canSubmit, isLoading, onSubmit } = useTeamForms(teamId);
    const { cancelButtonStyles } = useStyleOverrides();
    const resetTeamFields = useResetRecoilState(fieldsForTeamEditState(teamId));
    const { initEditTeam } = useEditTeamLocation();

    const handleClose = () => {
        resetTeamFields();
        close();
    };

    const handleSubmit = async () => {
        await onSubmit();
        handleClose();
    };

    return (
        <>
            <LabelLarge>{strings.Routing.RoutingTitle}</LabelLarge>
            <Spacer/>
            <LabelXSmall color={theme.colors.contentSecondary}>{strings.Routing.RoutingDesc}</LabelXSmall>
            <Spacer/>
            {teamFields.route && (
                <StaticMap size={120} viewMarker markerCaption={strings.General.Start + "/" + strings.General.End}
                           location={{
                               lat: teamFields.route.start_lat,
                               lng: teamFields.route.start_lng,
                           }}/>
            )}
            <Spacer/>
            <Button onClick={() => initEditTeam(teamId)} kind="secondary" size="mini">
                {strings.General.SelectPlaceOrAddresOnMap}
            </Button>
            <HFlex
                style={{
                    marginTop: theme.sizing.scale600,
                }}
            >
                <SpacerExpand/>
                {hasChanges && (
                    <Button onClick={handleClose} size="mini" kind="tertiary" overrides={cancelButtonStyles}>
                        {strings.General.Cancel}
                    </Button>
                )}
                <Button
                    onClick={hasChanges ? handleSubmit : handleClose}
                    size="mini"
                    isLoading={isLoading}
                    disabled={!canSubmit && hasChanges}
                >
                    {hasChanges ? strings.General.Save : strings.General.OK}
                </Button>
            </HFlex>
        </>
    );
});