import { useSetRecoilState } from "recoil";
import { companyTabFilterState, editingCompanyIdState } from "../../states/appState";
import { VFlex } from "../containers/VFlex";
import ReactMarkdown from "react-markdown";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { Button } from "baseui/button";
import React from "react";
import useAccount from "../../hooks/useAccount";
import { useStrings } from "../../hooks/useStrings";
import { StyledLink } from "baseui/link";

export function Upsell() {
    const { strings } = useStrings();
    const setEditingCompany = useSetRecoilState(editingCompanyIdState);
    const setCompanyTabFilter = useSetRecoilState(companyTabFilterState);
    const { company, language } = useAccount();

    if (!company) {
        return null;
    }

    const contentEnglish = `
# Elevate Your Operations with Hellotracks Dispatch! 🚀

When you upgrade, you'll unlock:

- 📅 **Job Scheduling:** Streamline your task planning and prioritization.
- ⚡ **Auto-Dispatch:** Automate task assignments based on optimal fit.
- 🛣️ **Route Optimization:** Save both time and costs with intelligent routing.
- ⏱️ **Onsite Time Recording:** Accurately track the time spent on each job or service.
- 📣 **Client Notifications:** Keep your clients informed with real-time updates.
- ✍️ **Signature Capture:** Secure instant confirmations through digital signatures.
- 📸 **Image Uploads:** Field workers can instantly upload images onsite.
- 📋 **Forms:** Utilize both basic and advanced forms for in-depth data capture.
- 📊 **Comprehensive Reports:** Dive deep into job performance insights and more.
- 🔄 **Automated Recurring Reports:** Automate report generation and delivery.
- 🔗 **Integrations:** Seamlessly sync with tools like Slack, Google Calendar, and more.
- 🌐 **API Access:** Integrate Hellotracks smoothly into your existing systems.
- 🔒 **Two-Factor Authentication:** Add an extra layer of security with 2FA.
    `;

    const contentSpanish = `
# ¡Eleva tus operaciones con Hellotracks Dispatch! 🚀

Cuando actualices, desbloquearás:

- 📅 **Programación de Tareas:** Optimiza tu planificación y priorización de tareas.
- ⚡ **Auto-Despacho:** Automatiza las asignaciones de tareas basadas en la mejor adecuación.
- 🛣️ **Optimización de Rutas:** Ahorra tiempo y costos con rutas inteligentes.
- ⏱️ **Registro de Tiempo en Sitio:** Registra con precisión el tiempo dedicado a cada tarea o servicio.
- 📣 **Notificaciones para Clientes:** Mantén a tus clientes informados con actualizaciones en tiempo real.
- ✍️ **Captura de Firma:** Confirma al instante a través de firmas digitales.
- 📸 **Subida de Imágenes:** Los trabajadores en campo pueden subir imágenes al instante.
- 📋 **Formularios:** Utiliza formularios básicos y avanzados para una captura de datos detallada.
- 📊 **Informes Exhaustivos:** Profundiza en los detalles del rendimiento de las tareas y más.
- 🔄 **Informes Recurrentes Automatizados:** Automatiza la generación y entrega de informes.
- 🔗 **Integraciones:** Sincroniza sin problemas con herramientas como Slack, Google Calendar y más.
- 🌐 **Acceso a la API:** Integra Hellotracks sin problemas en tus sistemas actuales.
- 🔒 **Autenticación de Dos Factores:** Añade una capa extra de seguridad con 2FA.
    `;

    const contentGerman = `
# Verbessere deine Abläufe mit Hellotracks Dispatch! 🚀

Wenn du ein Upgrade durchführst, erhältst du:

- 📅 **Auftragsplanung:** Optimiere deine Aufgabenplanung und Priorisierung.
- ⚡ **Auto-Dispatch:** Automatisiere Auftragszuweisungen basierend auf optimaler Eignung.
- 🛣️ **Routenoptimierung:** Spare Zeit und Kosten mit intelligenter Routenführung.
- ⏱️ **Vor-Ort-Zeitaufnahme:** Verfolge genau die für jeden Auftrag oder Dienst verbrachte Zeit.
- 📣 **Kundenbenachrichtigungen:** Halte deine Kunden mit Echtzeit-Updates auf dem Laufenden.
- ✍️ **Signaturerfassung:** Sichere sofortige Bestätigungen durch digitale Signaturen.
- 📸 **Bilduploads:** Außendienstmitarbeiter können vor Ort sofort Bilder hochladen.
- 📋 **Formulare:** Nutze sowohl einfache als auch erweiterte Formulare für detaillierte Datenerfassung.
- 📊 **Umfassende Berichte:** Tauche tief in Auftragsleistungs-Einblicke und mehr ein.
- 🔄 **Automatisierte wiederkehrende Berichte:** Automatisiere die Berichtserstellung und -zustellung.
- 🔗 **Integrationen:** Synchronisiere nahtlos mit Tools wie Slack, Google Kalender und mehr.
- 🌐 **API-Zugriff:** Integriere Hellotracks reibungslos in deine bestehenden Systeme.
- 🔒 **Zwei-Faktor-Authentifizierung:** Füge mit 2FA eine zusätzliche Sicherheitsebene hinzu.
    `;

    return (
        <VFlex spacing padding style={{ overflow: "auto" }}>
            <ReactMarkdown>{language === "es" ? contentSpanish : language === "de" ? contentGerman : contentEnglish}</ReactMarkdown>
            <HFlex>
                <SpacerExpand/>
                <StyledLink animateUnderline href="https://hellotracks.com/job-dispatching" target="_blank">
                    {strings.Plan.ClickHereToLearnMore}
                </StyledLink>
                <SpacerExpand/>
            </HFlex>
            <Spacer/>
            <HFlex>
                <SpacerExpand/>
                <Button onClick={() => {
                    setCompanyTabFilter("billing");
                    setEditingCompany(company.uid);
                }}>{strings.Plan.ChoosePlanInBilling}</Button>
                <SpacerExpand/>
            </HFlex>
            <Spacer height={"48px"}/>
        </VFlex>
    );
}