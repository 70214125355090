import { getDiffFields } from './../utils/objects';
import { atomFamily, selector, selectorFamily } from 'recoil';
import { emailValidator } from '../constants/validators';
import { getJobReadOnlyFields, Job } from './../types/job';
import { editingJobIdState } from './appState';
import { jobState } from './jobsState';

export const editJobInitializer = selectorFamily<Job | undefined, string>({
    key: 'edit.job.initializer',
    get: (id) => ({ get }) => get(jobState(id)),
})

export const editJobState = atomFamily<Job | undefined, string>({
    key: 'edit.job',
    default: editJobInitializer,
});

export const editJobValidState = selectorFamily<{ [key: string]: boolean }, string>({
    key: 'edit.job.valid',
    get: (id) => ({ get }) => {
        const fields = get(editJobState(id));
        const validFields = fields && Object.fromEntries(Object.keys(fields).map((key) => [key, true]));
        // Required fields
        // if (!fields.destinationLat) validFields['destinationLat'] = false;
        // if (!fields.destinationLng) validFields['destinationLng'] = false;
        // if (validFields && !fields.destinationName) {
        //     validFields['destinationName'] = false;
        // }
        // Additional fields
        if (validFields && fields?.contactEmail) {
            validFields['contactEmail'] = emailValidator.test(fields.contactEmail);
        }
        return validFields || {};
    }
});

export const editJobErrorState = selectorFamily<boolean, string>({
    key: 'edit.job.error',
    get: (id) => ({ get }) => {
        const validFields = get(editJobValidState(id));

        return Object.values(validFields).some((valid) => !valid)
    }
});

export const editJobModifiedState = selector<{ [key: string]: any }>({
    key: 'edit.job.modified',
    get: ({ get }) => {
        const jobId = get(editingJobIdState);
        const job = get(jobState(jobId || ''));
        const jobFields = get(editJobState(jobId || ''));
        const diff: any = getDiffFields(job || {}, jobFields || {});
        const isNewJob = !jobId;
        for (const key of getJobReadOnlyFields(isNewJob)) {
            delete diff[key];
        }
        return diff;
    }
});

export const editJobHasModifiedState = selector({
    key: 'edit.job.modified.count',
    get: ({ get }) => {
        const jobId = get(editingJobIdState);
        const job = get(jobState(jobId || ''));
        const jobFields = get(editJobState(jobId || ''));
        const jobFieldsChanged = job && jobFields && Object.keys(job).length !== Object.keys(jobFields).length;
        return !!Object.keys(get(editJobModifiedState)).length || jobFieldsChanged;
    }
});