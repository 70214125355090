import { emailValidator } from "./../constants/validators";
import { allJobsFiltersState } from "../states/allJobsState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { IMPORT_JOB_FILE_FORMATS } from "../constants/jobs";
import { IMPORT_MEMBER_FILE_FORMATS } from "../constants/members";
import { IMPORT_PLACE_FILE_FORMATS } from "../constants/places";
import { TEMPLATE_URLS } from "../constants/templates";
import { IMPORT_ZONE_FILE_FORMATS } from "../constants/zones";
import { importTypeState } from "../states/appState";
import { UseImportConfig } from "../types/imports";
import { ImportJobFileFormat, Job } from "../types/job";
import { ImportMemberFileFormat } from "../types/member";
import { ImportPlaceFileFormat } from "../types/place";
import { ImportZoneFileFormat } from "../types/zone";
import { getFileExtension } from "../utils/app";
import {
    UploadFileResponse,
    uploadJobImport,
    uploadMemberImport,
    uploadPlaceImport,
    uploadZoneImport
} from "./../services/upload";
import { useJobsImportConfig } from "./jobs/useJobsImportConfig";
import { useMembersImportConfig } from "./members/useMembersImportConfig";
import { usePlacesImportConfig } from "./places/usePlacesImportConfig";
import { useStrings } from "./useStrings";
import { HOUR } from "../utils/time";
import { useTheme } from "./useTheme";

export function useImport() {
    const { strings, format } = useStrings();
    const { theme } = useTheme();
    const importType = useRecoilValue(importTypeState);
    const setAllJobsFilters = useSetRecoilState(allJobsFiltersState);

    const getTitle = () => {
        switch (importType) {
            case "jobs":
                return strings.Job.ImportJobsTitle;
            case "members":
                return strings.Members.ImportMembersTitle;
            case "places":
                return strings.Places.ImportPlacesTitle;
            case "zones":
                return strings.Zones.ImportZonesTitle;
        }
        return "";
    };

    const getImportConfig = (): (() => UseImportConfig) => {
        switch (importType) {
            case "jobs":
                return useJobsImportConfig;
            case "members":
                return useMembersImportConfig;
            case "places":
                return usePlacesImportConfig;
        }
        return () => ({
            defaultColumnsConfig: {},
            defaultColumns: [],
            getAdvancedColumnsOptions: () => [],
            advancedColumnsConfig: {},
        });
    };

    const getTemplatesContent = () => {
        switch (importType) {
            case "jobs":
                return `${format(strings.Job.ImportJobsInfo, TEMPLATE_URLS.jobsCsv, TEMPLATE_URLS.jobsXlsx)}`;
            case "members":
                return strings.Members.ImportMembersInfo;
            case "places":
                return `${format(
                    strings.Places.ImportPlacesInfo,
                    TEMPLATE_URLS.placesCsv,
                    TEMPLATE_URLS.placesXlsx,
                    TEMPLATE_URLS.placesKml
                )}`;
            case "zones":
                return strings.Zones.ImportZonesInfo;
        }
        return "";
    };

    const upload = (file: File) => {
        const extension = getFileExtension(file.name);
        switch (importType) {
            case "jobs":
                return uploadJobImport({ file, extension: extension as ImportJobFileFormat });
            case "members":
                return uploadMemberImport({ file, extension: extension as ImportMemberFileFormat });
            case "places":
                return uploadPlaceImport({ file, extension: extension as ImportPlaceFileFormat });
            case "zones":
                return uploadZoneImport({ file, extension: extension as ImportZoneFileFormat });
        }
        return { status: false } as UploadFileResponse;
    };

    const getFileFormats = () => {
        switch (importType) {
            case "jobs":
                return IMPORT_JOB_FILE_FORMATS;
            case "members":
                return IMPORT_MEMBER_FILE_FORMATS;
            case "places":
                return IMPORT_PLACE_FILE_FORMATS;
            case "zones":
                return IMPORT_ZONE_FILE_FORMATS;
        }
        return [];
    };

    const getOnUpload = () => {
        switch (importType) {
            case "jobs":
                return () => {
                    const filter = {
                        id: "updated",
                        label: strings.General.RecentlyUpdated,
                        matches: (job: Job) => Date.now() - job.tsSaved < HOUR,
                    };
                    setAllJobsFilters([filter]); // remove all filter and apply recently updated filter
                };
        }
        return () => {};
    };

    const canProcessImport = (
        headers: string[],
        data: string[][]
    ): {
        canProcess: boolean;
        errors?: { [key: string]: string };
        cellErrors?: { [key: string]: string };
        isEmpty?: boolean;
    } => {
        switch (importType) {
            case "members":
                const isEmpty = data.every((row) => !row.join("").length);
                if (isEmpty) {
                    return {
                        canProcess: false,
                        isEmpty,
                    }
                }
                const rowCount = data.filter((row) => row.join("").length > 0).length;
                if (rowCount > 200) {
                    return {
                        canProcess: false,
                        errors: {
                            "": strings.General.TooManyRows
                        }
                    }
                }
                const emailIndex = headers.findIndex((header) => header.toLowerCase() === "email");
                const nameIndex = headers.findIndex((header) => header.toLowerCase() === "name");
                let errors: { [key: string]: string } = {};
                let cellErrors: { [key: string]: string } = {};
                const columnsLength = headers.length;
                if (emailIndex !== -1 && nameIndex !== -1) {
                    data.forEach((row, index) => {
                        const isValidRow = !!row.join("").length;
                        const rowNumber = index + 1;
                        if (isValidRow) {
                            let rowErrors = [];
                            // NAME REQUIRED
                            if (!row[nameIndex]) {
                                rowErrors.push(strings.General.NameIsRequired);
                            }
                            // EMAIL REQUIRED
                            if (!row[emailIndex]) {
                                rowErrors.push(strings.General.EmailIsRequired);
                            }
                            // EMAIL VALIDATION
                            if (!!row[emailIndex]) {
                                const isInvalid = !emailValidator.test(row[emailIndex]);
                                if (isInvalid) {
                                    rowErrors.push(strings.General.InvalidEmail);
                                }
                            }
                            if (rowErrors.length) {
                                errors[index + 1] = rowErrors.join(", ");
                                cellErrors = {
                                    ...cellErrors,
                                    ...getCellStyles(rowNumber, columnsLength)
                                }
                            }
                        }
                    });
                    return {
                        canProcess: !Object.keys(errors).length,
                        errors,
                        cellErrors,
                    };
                }
                return {
                    canProcess: false,
                };
        }
        return {
            canProcess: true,
        };
    };

    const getCellStyles = (rowNumber: number, columnsLength: number, success: boolean = false) => {
        let styles: { [key: string]: string } = {};
        let counter = 0;
        while (counter < columnsLength) {
            styles[`${String.fromCharCode(counter + 65)}${rowNumber}`] = success ? "background-color: " + theme.customColors.accent : "background-color: " + theme.colors.warning;
            counter++;
        }
        return styles;
    }

    return {
        upload,
        title: getTitle(),
        useImportConfig: getImportConfig(),
        templatesContent: getTemplatesContent(),
        fileFormats: getFileFormats(),
        onUpload: getOnUpload(),
        canProcessImport,
        getCellStyles,
    };
}
