import { TEAM_ORANGE } from "../../constants/colors";
import { useRecoilValue } from "recoil";
import { teamsState } from "../../states/teamsState";
import { Tag } from "antd";
import { getTruncatedTeamName } from "../../types/core";

type Props = {
    id: number;
    shortName?: boolean,
    style?: any;
}

export function TeamBadge({ id, shortName = false, style }: Props) {
    const teams = useRecoilValue(teamsState);
    const team = teams.find(team => team.id === id);

    if (!team) {
        return null;
    }

    const teamName = team.name || "#" + id;
    return (
        <Tag color={TEAM_ORANGE} style={style}>
            {shortName && team?.name ? getTruncatedTeamName(team) : teamName}
        </Tag>
    );
}