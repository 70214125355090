import { Button } from "baseui/button";
import { useRecoilValue } from "recoil";
import { useModal } from "../../../hooks/useModal";
import { useStrings } from "../../../hooks/useStrings";
import { useStyleOverrides } from "../../../hooks/useStyleOverrides";
import { useZoneForms } from "../../../hooks/zones/useZoneForms";
import { editingZoneIdState } from "../../../states/appState";
import { editZoneState } from "../../../states/editZoneState";
import { zoneState } from "../../../states/zoneState";
import { SpacerExpand } from "../../containers/Spacer";
import { StyledModalContent } from "../../ui/StyledModalContent";
import { Zone } from "./Zone";

export function ZoneModalContent() {
    const zoneUid = useRecoilValue(editingZoneIdState);
    const { cancelButtonStyles } = useStyleOverrides();
    const { closeModal, cancelModal } = useModal();
    const { hasChanges, onSubmit, canSubmit, isLoading } = useZoneForms();
    const zone = useRecoilValue(zoneState(zoneUid || ""));
    const { strings } = useStrings();
    const fields = useRecoilValue(editZoneState(zoneUid || ""));

    if (!zone) {
        return null;
    }

    return (
        <StyledModalContent
            header={fields.name || zone.name || ""}
            body={<Zone/>}
            footer={
                <>
                    <SpacerExpand/>
                    {(hasChanges) && (
                        <Button onClick={cancelModal} size="compact" kind="tertiary" overrides={cancelButtonStyles}>
                            {strings.General.Cancel}
                        </Button>
                    )}
                    <Button
                        onClick={hasChanges ? onSubmit : closeModal}
                        size="compact"
                        isLoading={isLoading}
                        disabled={!canSubmit}
                    >
                        {hasChanges ? strings.General.Save : strings.General.OK}
                    </Button>
                </>
            }
        />
    );
}
