import React from "react";
import { themedUseStyletron as useStyletron } from "../../styles/theme";
import { StyleObject } from "styletron-react";

type Props = {
    children: React.ReactNode;
    style?: StyleObject;
}

export function UnorderedList({ children, style = undefined }: Props) {
    const [css] = useStyletron();

    return (
        <ul
            className={css({
                paddingLeft: 0,
                marginTop: 0,
                marginRight: 0,
                marginBottom: 0,
                marginLeft: 0,
                overflowY: "auto",
                ...style,
            })}
        >
            {children}
        </ul>
    );
}