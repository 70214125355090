import { Button } from "baseui/button";
import { Checkbox } from "baseui/checkbox";
import { Hide, Show } from "baseui/icon";
import { LabelSmall, ParagraphMedium } from "baseui/typography";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import useAccount from "../../hooks/useAccount";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { editingCompanyIdState, editingMemberIdState, importTypeState } from "../../states/appState";
import { dayOfToday } from "../../utils/time";
import { VFlex } from "../containers/VFlex";
import { ApiIcon, GoogleCalendarIcon, LoginIcon, MailIcon, SlackIcon, XlsxIcon } from "../ui/svg";
import { IntegrationItem } from "./IntegrationItem";
import { disconnectSlack, editAccount } from "../../services/api";
import useDirector from "../../hooks/useDirector";
import { Spacer } from "../containers/Spacer";
import { Alert } from "antd";
import { Banner } from "baseui/banner";
import { SsoConfiguration } from "./SsoConfiguration";
import { getSsoConfig } from "../../types/core";


export function IntegrationList() {
    const { strings, format } = useStrings();
    const { theme, css } = useTheme();
    const setImportType = useSetRecoilState(importTypeState);
    const [showApiInfo, setShowApiInfo] = useState(false);
    const account = useAccount();
    const setEditingMember = useSetRecoilState(editingMemberIdState);
    const setEditingCompany = useSetRecoilState(editingCompanyIdState);
    const director = useDirector();

    const apiKey = account.company?.uid?.toUpperCase() + "." + account.companyKey;
    const apiExample = `<code style="
        background-color: ${theme.colors.backgroundSecondary};
        border: 1px solid #ccc;
        padding: 8px;
        border-radius: 4px;
        font-family: 'Courier New', monospace;
        font-weight: bold;
        font-size: 14px;
        display: block;
        white-space: pre-wrap;
        overflow-x: auto;
            ">curl -X POST https://api.hellotracks.com/api/getjobs \\
    --header 'API-Key: ${apiKey}' -d \\
'
    {
        "data":{
            "worker":"*",
            "day": ${dayOfToday()}
        }
    }
'</code>`;

    const onImportJobs = () => setImportType("jobs");

    const onEditProfile = () => {
        if (account.company?.uid !== account.uid) {
            setEditingMember(account.uid);
        } else if (account.company && account.company.uid === account.uid) {
            setEditingCompany(account.company.uid);
        }
    };

    const onDisconnectSlackClick = async () => {
        const { status } = await disconnectSlack();
        if (status) {
            account.setSlackTeamId("");
        }
    };

    const onApiEnabledClick = async (checked: boolean) => {
        await editAccount({ uid: account.company?.uid, api_enabled: checked });
        await director.performPoll();
    };

    return (
        <div className={css({
            overflowY: "auto",
            paddingLeft: "32px",
            paddingRight: "32px",
            userSelect: "text",
        })}>
            <LabelSmall dangerouslySetInnerHTML={{ __html: strings.Integrations.IntegrationsDescHtml }}
                        marginBottom={theme.sizing.scale600}/>
            <VFlex style={{ rowGap: theme.sizing.scale1400 }}>
                <IntegrationItem
                    icon={<ApiIcon size={22}/>}
                    title={"API"}
                    content={strings.Integrations.IntegrationsApiHtml}
                    actions={
                        <VFlex style={{ rowGap: theme.sizing.scale600 }}>
                            <Checkbox
                                checked={account.company?.api_enabled}
                                onChange={e => onApiEnabledClick(e.target.checked)}
                                disabled={!account.isSelfAdminOrCompany}
                            >
                                {strings.Integrations.IntegrationsEnableApi}
                            </Checkbox>
                            {!account.isSelfAdminOrCompany && <Banner>
                                {strings.Integrations.IntegrationsApiUseAdminRole}
                            </Banner>}
                            {account.isSelfAdminOrCompany && account.company?.api_enabled && (
                                <VFlex style={{ rowGap: theme.sizing.scale300 }}>
                                    <div>
                                        <Button
                                            size="compact"
                                            kind="secondary"
                                            onClick={() => setShowApiInfo((prev) => !prev)}
                                            shape="pill"
                                            startEnhancer={showApiInfo ? <Hide/> : <Show/>}
                                        >
                                            {strings.Integrations.IntegrationsApiShowCredentials}
                                        </Button>
                                    </div>
                                    {showApiInfo && (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: format(strings.Integrations.IntegrationsApiCredentials,
                                                    apiKey, apiExample),
                                            }}
                                        />
                                    )}
                                </VFlex>
                            )}
                        </VFlex>
                    }
                />
                <IntegrationItem icon={<LoginIcon size={20} color={theme.colors.contentPrimary}/> }
                                 title={strings.General.SingleSignOn}
                                 content={strings.Integrations.IntegrationsOkta}
                                 actions={account.isSelfAdminOrCompany && <SsoConfiguration config={getSsoConfig(account.company?.sso_config)}/>}
                />
                <IntegrationItem
                    icon={<SlackIcon size={20}/>}
                    title={strings.Slack.Slack}
                    content={strings.Integrations.IntegrationsSlackHtml}
                    actions={
                        <>
                            {account.isSelfAdminOrCompany && account.slackTeamId && (
                                <Alert
                                    showIcon
                                    type={"success"}
                                    message={
                                        <span>{strings.General.ConnectedToWorkspace}: <strong>{account.slackTeamId}</strong></span>}
                                    action={
                                        <Button onClick={onDisconnectSlackClick} kind="tertiary" size="mini"
                                                shape="pill">
                                            {strings.Slack.SlackDisconnect}
                                        </Button>
                                    }
                                />
                            )}
                            {account.isSelfAdminOrCompany && !account.slackTeamId && (
                                <a target="_blank" rel="noreferrer"
                                   href={`https://slack.com/oauth/v2/authorize?client_id=29029654723.1312283799538&scope=commands,chat:write&user_scope=&state=${account.company?.uid}`}>
                                    <img alt="Add to Slack" height="40" width="139" src="images/add-to-slack.png"/>
                                </a>
                            )}
                            {!account.isSelfAdminOrCompany && (
                                <ParagraphMedium>{strings.Integrations.IntegrationsAskAdmin}</ParagraphMedium>
                            )}
                        </>
                    }
                />
                <IntegrationItem
                    icon={<GoogleCalendarIcon size={24}/>}
                    title={strings.GoogleCalendar.GoogleCalendar}
                    content={strings.Integrations.IntegrationsGoogleCalendarHtml}
                />
                <IntegrationItem
                    icon={<MailIcon size={22}/>}
                    title={strings.General.Email}
                    content={format(strings.Integrations.IntegrationsEmailHtml, strings.Integrations.IntegrationsEmailSubject, strings.Integrations.IntegrationsEmailBody).toString()}
                    actions={
                        <div>
                            <Button size="compact" shape="pill" kind="secondary" onClick={onEditProfile}>
                                {strings.ProfileModal.EditProfile}
                            </Button>
                        </div>
                    }
                />
                <IntegrationItem
                    icon={<XlsxIcon size={22}/>}
                    title={strings.Integrations.IntegrationsExcelCsv}
                    content={format(strings.Integrations.IntegrationsExcelCsvHtml, strings.Job.ImportJobs).toString()}
                    actions={
                        <div>
                            <Button size="compact" shape="pill" kind="secondary" onClick={onImportJobs}>
                                {strings.Job.ImportJobs}
                            </Button>
                        </div>
                    }
                />
                <Spacer height="48px"/>
            </VFlex>
        </div>
    )
}