import { useStyletron } from 'baseui';
import { FormControl } from 'baseui/form-control';
import { Input, StyledInput } from 'baseui/input';
import { Tag } from 'baseui/tag';
import { forwardRef, useState } from 'react';

const InputWithTags = forwardRef(({ tags, removeTag, ...restProps }: any, ref) => {
    const [css] = useStyletron();
    return (
        <div
            className={css({
                flex: '1 1 0%',
                flexWrap: 'wrap',
                display: 'flex',
                alignItems: 'center',
            })}
        >
            {tags?.map((tag: string, index: number) => (
                <Tag variant="solid" onActionClick={() => removeTag(tag)} key={index}>
                    {tag}
                </Tag>
            ))}
            <StyledInput ref={ref} {...restProps} />
        </div>
    );
});

type Props = {
    label: string;
    tags: string[];
    caption?: string;
    placeholder?: string;
    onTagsChange: (newTags: string[]) => void;
};

export function CreatableTagsInput({ label, tags = [], caption, placeholder, onTagsChange }: Props) {
    const [value, setValue] = useState('');

    const addTag = (tag: string) => {
        onTagsChange([...tags, tag]);
    };

    const removeTag = (tag: string) => {
        onTagsChange(tags.filter((t) => t !== tag));
    };

    const confirmTag = () => {
        if (!value) {
            return;
        }
        addTag(value);
        setValue('');
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'Enter': {
                confirmTag();
                return;
            }
            case 'Backspace': {
                if (value || !tags.length) {
                    return;
                }
                removeTag(tags[tags.length - 1]);
                return;
            }
        }
    };

    return (
        <FormControl label={label} caption={caption}>
            <Input
                size="compact"
                value={value}
                onChange={(event) => setValue(event.target.value)}
                onBlur={() => confirmTag()}
                placeholder={placeholder}
                overrides={{
                    Input: {
                        style: { width: 'auto', flexGrow: 1 },
                        component: InputWithTags,
                        props: {
                            tags: tags,
                            removeTag: removeTag,
                            onKeyDown: handleKeyDown,
                        },
                    },
                }}
            />
        </FormControl>
    );
}
