import { StatefulPanel } from 'baseui/accordion';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { StatefulPopover } from 'baseui/popover';
import React, { useState } from 'react';
import { ADMIN_ROLE, OPERATOR_ROLE, WORKER_ROLE } from '../../constants/app';
import { emailValidator } from '../../constants/validators';
import { useStateUpdater } from '../../hooks/useStateUpdater';
import { createAccount, STATUS_ERROR_PASSWORDMISMATCH, STATUS_ERROR_USERALREADYEXISTS } from '../../services/api';
import { Member, Role } from '../../types/member';
import { UnorderedList } from '../containers/UnorderedList';
import { VFlex } from '../containers/VFlex';
import { FormInput } from '../ui/FormInput';
import { EmailIcon, PhoneIcon } from '../ui/svg';
import { useToast } from "../../hooks/useToast";
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";
import { useOnboardMembers } from '../../hooks/onboard/useOnboardMembers';
import { useOnboardRefs } from '../../hooks/onboard/useOnboardRefs';
import { StyledSelect } from '../ui/StyledSelect';
import { Plus } from "baseui/icon";
import { Switch } from "antd";
import { hasWhiteSpace } from "../../utils/strings";
import { useRecoilValue } from "recoil";
import { newMemberEventState } from "../../states/viewState";
import { getPasswordValidation } from "../../utils/password";
import { PasswordStrengthBar } from "../ui/PasswordStrengthBar";
import { HFlex } from "../containers/HFlex";
import { LabelXSmall } from "baseui/typography";
import useAccount from "../../hooks/useAccount";

export function QuickAddMember() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const [withEmail, setWithEmail] = useState(true);
    const [name, setName] = useState("");
    const [member, setMember] = useState({} as Member);
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [usernameError, setUsernameError] = useState("");
    const { updateNewMember } = useStateUpdater();
    const toast = useToast();
    const { addMemberRef } = useOnboardRefs();
    const { finishOnboardMembers } = useOnboardMembers();
    const newMemberEvent = useRecoilValue(newMemberEventState);
    const { isSelfAdminOrCompany } = useAccount();
    const emailIsValid = !!member.email && emailValidator.test(member.email);
    const usernameIsValid = member.username && !hasWhiteSpace(member.username);
    const hasEmailError = member.email && !emailIsValid;

    const createNewMember = async (close: Function) => {
        setIsLoading(true);
        const data = {
            ...member,
            name: name || "",
            username: !withEmail ? member.username : member.email,
            password: !withEmail ? password : undefined,
        };
        const { status, account: newMember, errorCode } = await createAccount(data);
        if (status && newMember) {
            updateNewMember(newMember as Member);
            toast.showSuccess(strings.General.Success);
            close();
        } else if (errorCode && errorCode === STATUS_ERROR_USERALREADYEXISTS) {
            setUsernameError(strings.General.UserIsAlreadyRegistered);
        } else if (errorCode && errorCode === STATUS_ERROR_PASSWORDMISMATCH) {
            toast.showWarning(strings.General.PasswordTooCommon);
        } else {
            toast.showWarning(strings.General.SomethingWentWrong);
        }
        setIsLoading(false);
    };

    const resetStates = (checked: boolean) => {
        if (!checked) {
            setMember((prev) => ({ ...prev, email: '', username: '' }))
        } else {
            setMember({
                email: '',
                role: WORKER_ROLE,
            } as Member);
        }
        setWithEmail(checked);
        setPassword("");
        setName("");
        setUsernameError("")
    }

    const canAddMember = () => {
        if (!withEmail) {
            if (!password || !getPasswordValidation(password).valid || !member.username || !usernameIsValid) {
                return false;
            }
        } else if (!emailIsValid || !member.email) {
            return false;
        }
        return true;
    }

    const getRoleCaption = () => {
        if (member.role === WORKER_ROLE) {
            return strings.General.WorkersDesc;
        }
        if (member.role === ADMIN_ROLE) {
            return strings.General.AdministratorsDesc;
        }
        if (member.role === OPERATOR_ROLE) {
            return strings.General.OperatorsDesc;
        }

        return undefined;
    }

    return (
        <div ref={addMemberRef} onClick={finishOnboardMembers}>
            <StatefulPopover
                initialState={{ isOpen: (Date.now() - newMemberEvent) < 1000 }}
                content={({ close }) => (
                    <VFlex
                        style={{
                            paddingTop: theme.sizing.scale500,
                            paddingRight: theme.sizing.scale700,
                            paddingLeft: theme.sizing.scale700,
                            paddingBottom: theme.sizing.scale700,
                            maxWidth: '280px',
                        }}
                    >
                        <HFlex spacing alignCenter>
                            <Switch
                                checkedChildren={strings.General.Email}
                                unCheckedChildren={strings.General.NoEmail}
                                checked={withEmail}
                                onChange={checked => resetStates(checked)}
                                style={{
                                    maxWidth: "120px",
                                    minWidth: "120px",
                                }}
                            />
                            <LabelXSmall color={theme.colors.contentTertiary}>
                                {withEmail ? strings.General.EmailAsUsername : strings.General.SetIndividualUsername}
                            </LabelXSmall>
                        </HFlex>
                        {withEmail && (
                            <FormInput
                                inputSize="mini"
                                label={" "}
                                value={member.email}
                                placeholder={strings.General.EnterEmail}
                                field="email"
                                onChange={(_, value) => {
                                    setUsernameError("");
                                    setMember((prev) => ({ ...prev, email: value as string }));
                                }}
                                startEnhancer={<EmailIcon size={12} color={theme.colors.contentTertiary}/>}
                                type="email"
                                error={hasEmailError ? strings.General.PleaseEnterAValidEmailAddress : usernameError}
                            />
                        )}
                        {!withEmail && (
                            <>
                                <FormInput
                                    inputSize="mini"
                                    label={strings.General.Username}
                                    value={member.username ? member.username : ''}
                                    placeholder={strings.General.EnterUsername}
                                    field="username"
                                    onChange={(_, value) => {
                                        setUsernameError("");
                                        setMember((prev) => ({
                                            ...prev,
                                            username: value as string
                                        }));
                                    }}
                                    type="text"
                                    required
                                    error={usernameError || (!usernameIsValid ? strings.General.PasswordRequirements : undefined)}
                                />
                                <FormInput
                                    inputSize="mini"
                                    label={strings.General.Password}
                                    value={password}
                                    placeholder={strings.General.EnterPassword}
                                    field="password"
                                    onChange={(_, value) => setPassword(value as string)}
                                    type="password"
                                    required
                                />
                                <PasswordStrengthBar password={password}/>
                            </>
                        )}
                        <FormInput
                            inputSize="mini"
                            label={strings.General.Name}
                            placeholder={strings.General.EnterName}
                            value={name}
                            field="name"
                            onChange={(_, value) => setName(value as string)}
                        />
                        <FormControl label={strings.General.Role} caption={getRoleCaption()}>
                            <StyledSelect
                                closeOnSelect
                                size="mini"
                                clearable={false}
                                options={[
                                    { label: strings.General.Admin, id: ADMIN_ROLE },
                                    { label: strings.General.Operator, id: OPERATOR_ROLE },
                                    { label: strings.ProfileModal.Worker, id: WORKER_ROLE },
                                ]}
                                value={[{ id: member.role }]}
                                openOnClick
                                type="select"
                                onChange={({ option }) =>
                                    setMember((prev) => ({ ...prev, role: (option?.id as Role) || WORKER_ROLE }))
                                }
                            />
                        </FormControl>
                        <UnorderedList>
                            <StatefulPanel
                                title={strings.General.Phone}
                                overrides={{
                                    Header: {
                                        style: {
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            paddingTop: 0,
                                            backgroundColor: 'transparent',
                                        }
                                    },
                                    PanelContainer: {
                                        style: {
                                            backgroundColor: 'transparent',
                                            borderBottomColor: 'transparent',
                                        }
                                    },
                                    Content: {
                                        style: {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            paddingTop: 0,
                                            paddingBottom: theme.sizing.scale600,
                                            backgroundColor: "transparent"
                                        }
                                    }
                                }}
                            >
                                <FormInput
                                    inputSize="mini"
                                    label={""}
                                    value={member.phone ? member.phone : ''}
                                    field="phone"
                                    placeholder={"+1 123456789 (" + strings.General.Optional + ")"}
                                    onChange={(_, value) => setMember((prev) => ({ ...prev, phone: value as string }))}
                                    startEnhancer={<PhoneIcon size={12} color={theme.colors.contentTertiary}/>}
                                    type="tel"
                                />
                            </StatefulPanel>
                        </UnorderedList>
                        <Button
                            size="mini"
                            disabled={!canAddMember()}
                            onClick={() => createNewMember(close)}
                            isLoading={isLoading}
                        >
                            {strings.General.Add}
                        </Button>
                    </VFlex>
                )}
                showArrow={true}
                focusLock
                onOpen={() => resetStates(withEmail)}
                returnFocus
                overrides={{
                    Arrow: {
                        style: {
                            backgroundColor: theme.colors.backgroundPrimary,
                        },
                    },
                    Inner: {
                        style: {
                            backgroundColor: theme.colors.backgroundPrimary,
                        },
                    },
                }}
            >
                <Button size={'mini'} disabled={!isSelfAdminOrCompany}>
                    <Plus/> {strings.Members.NewMember}
                </Button>
            </StatefulPopover>
        </div>
    );
}
