import { VFlex } from "../containers/VFlex";
import React, { useEffect, useMemo, useState } from "react";
import { StatsTable } from "./table/StatsTable";
import { useOnsiteCharts } from "../../hooks/statistics/useOnsiteCharts";
import { MemberStatDay } from "../../types/stats";
import { statsMembersByDay } from "../../services/api";
import { useJobPerformanceCharts } from "../../hooks/statistics/useJobPerformanceCharts";
import { useMileageCharts } from "../../hooks/statistics/useMileageCharts";
import { useJobTimesCharts } from "../../hooks/statistics/useJobTimesCharts";
import { useRecoilValue } from "recoil";
import { selectedDayFrameState, selectedMultiMemberState } from "../../states/appState";
import { useEngagementCharts } from "../../hooks/statistics/useEngagementCharts";
import { JobOverviewCharts } from "./JobOverviewCharts";
import { JobPiesCharts } from "./JobPiesCharts";
import { isLocationOnlyPlan } from "../../utils/account";
import useAccount from "../../hooks/useAccount";
import { FlexGridLayout } from "../containers/FlexGridLayout";

export function Statistics() {
    const [statsData, setStatsData] = useState<MemberStatDay[]>([]);
    const [fetching, setFetching] = useState(true);
    const members = useRecoilValue(selectedMultiMemberState);
    const { company } = useAccount();
    const dates = useRecoilValue(selectedDayFrameState);
    const fromDay = dates[0];
    const untilDay = dates[1];
    const memberUids = members.map(m => m.uid);
    const stats = members.length === 0
        ? statsData
        : statsData.map(d => ({
            day: d.day,
            data: [...d.data.filter(d2 => memberUids.includes(d2.memberUid))]
        } as MemberStatDay))
    const width = 500;
    const chartsProps = {
        stats,
        fetching,
        width
    }
    const onsiteCharts = useOnsiteCharts(chartsProps);
    const jobTimesCharts = useJobTimesCharts(chartsProps);
    const jobPerformanceCharts = useJobPerformanceCharts(chartsProps);
    const engagementCharts = useEngagementCharts(chartsProps);
    const mileageCharts = useMileageCharts(chartsProps);

    useEffect(() => {
        setFetching(true);
        statsMembersByDay(fromDay, untilDay).then(({ status, stats }) => {
            if (status) {
                setStatsData(stats);
            }
        }).finally(() => setFetching(false));
    }, [fromDay, untilDay]);

    const isDispatchPlan = !isLocationOnlyPlan(company?.stripe_plan);

    const allCharts = useMemo(() => {
        const charts = [...onsiteCharts]
        if (isDispatchPlan) {
            charts.push(...jobTimesCharts);
            charts.push(...jobPerformanceCharts);
            charts.push(...engagementCharts);
        }
        charts.push(...mileageCharts);
        return charts;
    }, [onsiteCharts, jobTimesCharts, jobPerformanceCharts, engagementCharts, mileageCharts, isDispatchPlan]);


    return (
        <VFlex padding spacing={"48px"} style={{ overflow: "auto" }}>
            <JobOverviewCharts stats={stats} fetching={fetching} width={width} showJobs={isDispatchPlan}/>
            {isDispatchPlan && <JobPiesCharts stats={stats} fetching={fetching} width={width}/>}
            <FlexGridLayout baseWidth={width} rowGap={48} >
                {allCharts}
            </FlexGridLayout>
            <StatsTable/>
        </VFlex>
    );
}