import { Button } from "baseui/button";
import {
    ArchivedFillIcon,
    BookmarkFillIcon,
    CheckboxCheckedIcon,
    CheckboxUncheckedIcon,
    ClockMiniIcon,
    HighPriorityIcon,
    LowPriorityIcon,
} from "../ui/svg";
import { StatusBadge } from "../badges/StatusBadge";
import { getProgressStatus } from "../../utils/jobUtils";
import { getLabels, hasAnyPriority, isHighPriority, Job } from "../../types/job";
import { TeamBadge } from "../badges/TeamBadge";
import React, { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import { jobState } from "../../states/jobsState";
import { useSingleJobSelection } from "../../hooks/jobs/useSingleJobSelection";
import { MemberBadge } from "../badges/MemberBadge";
import { useTime } from "../../hooks/useTime";
import { useStrings } from "../../hooks/useStrings";
import { StyledCell, StyledRow } from "baseui/table";
import { EllipsedText } from "../ui/EllipsedText";
import { useTheme } from "../../hooks/useTheme";
import { HFlex } from "../containers/HFlex";
import { Popconfirm, Tag } from "antd";
import { useJobTemplatesUpdater } from "../../hooks/jobs/useJobTemplatesUpdater";
import { Plus } from "baseui/icon";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { useAllJobsSelection } from "../../hooks/jobs/useAllJobsSelection";
import {
    ALL_JOBS_ADDRESS_COLUMN,
    ALL_JOBS_ASSIGNED_DATE_COLUMN,
    ALL_JOBS_ASSIGNEE_COLUMN,
    ALL_JOBS_CARRY_OVER,
    ALL_JOBS_CREATED_DATE_COLUMN,
    ALL_JOBS_DROPOFF_COLUMN,
    ALL_JOBS_LABELS,
    ALL_JOBS_LAST_SAVED_COLUMN,
    ALL_JOBS_NOTES_COLUMN,
    ALL_JOBS_ONSITE_COLUMN,
    ALL_JOBS_ORDER_NUMBER_COLUMN,
    ALL_JOBS_PICKUP_COLUMN,
    ALL_JOBS_PRIORITY_COLUMN,
    ALL_JOBS_PROGRESS_STATUS_COLUMN,
    ALL_JOBS_SELECT_COLUMN,
    ALL_JOBS_TEAM_COLUMN,
    ALL_JOBS_TIME_WINDOW_COLUMN,
    ALL_JOBS_TITLE_COLUMN,
    ALL_JOBS_WORK_TYPE,
    ALL_JOBS_ZONES,
} from "../../constants/jobs";
import { useAllJobsColumns } from "../../hooks/jobs/useAllJobsColumns";
import { TABLE_JOB_ID_PREFIX } from "../../constants/dnd";
import { Draggable } from "react-beautiful-dnd";
import { TableJob } from "../dispatch/TableJob";
import TableJobItem from "../dispatch/TableJobItem";
import { LabelXSmall } from "baseui/typography";
import { createTimeStringFromInt } from "../../utils/time";
import { NoWrapText } from "../containers/NoWrapText";
import { hasTimeWindow } from "../../utils/timeWindow";
import useAccount from "../../hooks/useAccount";
import { CustomField } from "../../types/core";
import { useZones } from "../../hooks/zones/useZones";
import { ZonesBadges } from "../badges/ZonesBadges";
import { jobTypeOptionsState } from "../../states/optionsState";
import { JobLabelBadge } from "../badges/JobLabelBadge";

type Props = {
    data: Job[];
    index: number;
    style: React.CSSProperties;
};

function AllJobsRow({ data, index, style }: Props) {
    const id = data[index].id;
    const { strings } = useStrings();
    const job = useRecoilValue(jobState(id));
    const { theme } = useTheme();
    const time = useTime();
    const { isSelected, toggleSelected } = useSingleJobSelection(id);
    const { tableMultiSelect } = useAllJobsSelection();
    const { createFromTemplate } = useJobTemplatesUpdater();
    const [creatingFromTemplate, setCreatingFromTemplate] = useState(false);
    const { gridStyle } = useStyleOverrides();
    const { columnsWidth, columnsConfiguration, enabledColumns } = useAllJobsColumns();
    const { fixedColumnStyle } = useStyleOverrides();
    const draggableId = TABLE_JOB_ID_PREFIX + id;
    const { company } = useAccount();
    const { getZonesFor } = useZones();
    const jobTypeOptions = useRecoilValue(jobTypeOptionsState);
    const jobTypeLabel = useCallback((type: number) => {
        return jobTypeOptions.find((jobType) => jobType.id === type)?.label ?? ""
    }, [jobTypeOptions]);

    if (!job) {
        return null;
    }

    const zones = getZonesFor(job.destinationLat, job.destinationLng, 2);

    const onCreateFromTemplateClick = async (template: Job) => {
        setCreatingFromTemplate(true);
        await createFromTemplate(template);
        setCreatingFromTemplate(false);
    };

    const customFields = company?.job_custom_fields ? company.job_custom_fields : [];

    return (
        <StyledRow role="row"
                   style={{
                       ...style,
                       ...gridStyle(isSelected)
                   }}
        >
            <StyledCell
                $style={{ ...fixedColumnStyle(columnsWidth[ALL_JOBS_SELECT_COLUMN]), justifyContent: "center" }}>
                <span
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        if (event.shiftKey && tableMultiSelect(job, data)) {
                            return;
                        }
                        toggleSelected();
                    }}
                >
                    <Button
                        size="mini"
                        kind="tertiary"
                    >
                        {isSelected
                            ? <CheckboxCheckedIcon size={18} color={theme.colors.contentPrimary}/>
                            : <CheckboxUncheckedIcon size={18} color={theme.colors.contentPrimary}/>
                        }
                    </Button>
                </span>
            </StyledCell>
            <StyledCell $style={columnsConfiguration[ALL_JOBS_TITLE_COLUMN].styles}>
                <HFlex alignCenter spacing>
                    {job.isTemplate &&
                        <>
                            <BookmarkFillIcon size={16} color={theme.colors.contentPrimary}/>
                            <Popconfirm
                                title={strings.JobTemplates.JobTemplateCreateDesc}
                                icon={<BookmarkFillIcon size={12} color={theme.colors.contentPrimary}/>}
                                okText={strings.General.Create}
                                onConfirm={() => onCreateFromTemplateClick(job)}
                            >
                                <Button size={"mini"} shape={"pill"} kind={"secondary"}
                                        isLoading={creatingFromTemplate}>
                                    <Plus/> {strings.General.Job}
                                </Button>
                            </Popconfirm>
                            <TableJobItem id={id}/>
                        </>
                    }
                    {!job.isTemplate && (
                        <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={false}>
                            {(provided) => <TableJob jobId={id} provided={provided}/>}
                        </Draggable>
                    )}
                    {job.status === "archived" && <ArchivedFillIcon size={12} color={theme.colors.contentPrimary}/>}
                </HFlex>
            </StyledCell>
            {enabledColumns.map((column) => {
                return (
                    <StyledCell $style={columnsConfiguration[column].styles} key={column}>
                        {column === ALL_JOBS_ASSIGNEE_COLUMN && job.receiverUid &&
                            <MemberBadge uid={job.receiverUid} ellipsed edit/>}
                        {column === ALL_JOBS_PROGRESS_STATUS_COLUMN && <StatusBadge progress={getProgressStatus(job)}/>}
                        {column === ALL_JOBS_ASSIGNED_DATE_COLUMN && time.formatDay(job.day)}
                        {column === ALL_JOBS_PRIORITY_COLUMN && hasAnyPriority(job) && (
                            <Tag>
                                {isHighPriority(job) ? <HighPriorityIcon size={10}/> :
                                    <LowPriorityIcon size={10}/>}{" "}
                                {isHighPriority(job) ? strings.Job.High : strings.Job.Low}
                            </Tag>
                        )}
                        {column === ALL_JOBS_ADDRESS_COLUMN &&
                            <EllipsedText maxLines={1}>{job.destinationText}</EllipsedText>}
                        {column === ALL_JOBS_TEAM_COLUMN && !!job.teamId && <TeamBadge id={job.teamId}/>}
                        {column === ALL_JOBS_CREATED_DATE_COLUMN &&
                            <LabelXSmall>{time.formatDateTime(job.tsCreated)}</LabelXSmall>}
                        {column === ALL_JOBS_LAST_SAVED_COLUMN &&
                            <LabelXSmall>{time.formatDateTime(job.tsSaved)}</LabelXSmall>}
                        {column === ALL_JOBS_ORDER_NUMBER_COLUMN && !!job.orderId && job.orderId}
                        {column === ALL_JOBS_NOTES_COLUMN &&
                            <EllipsedText maxLines={1}>{job.textDispatcher}</EllipsedText>}
                        {column === ALL_JOBS_TIME_WINDOW_COLUMN && hasTimeWindow(job?.windowStart, job?.windowEnd) && (
                            <LabelXSmall>
                                <NoWrapText>
                                    <ClockMiniIcon size={7} color={theme.colors.contentPrimary}/>
                                    {createTimeStringFromInt(time.isAmPm(), job.windowStart)}
                                    -
                                    {createTimeStringFromInt(time.isAmPm(), job.windowEnd)}
                                </NoWrapText>
                            </LabelXSmall>
                        )}
                        {column === ALL_JOBS_ZONES && <ZonesBadges uids={zones.map(z => z.uid)} max={2}/>}
                        {column === ALL_JOBS_WORK_TYPE &&
                            <EllipsedText inline breakAll>{jobTypeLabel(job.type)}</EllipsedText>}
                        {column === ALL_JOBS_CARRY_OVER && <EllipsedText inline
                                                                         breakAll>{job.carryOver ? strings.Job.CarryOver : strings.Job.NoCarryOver}</EllipsedText>}
                        {column === ALL_JOBS_ONSITE_COLUMN &&
                            <LabelXSmall>{time.formatDuration(job?.onSiteSeconds * 1000)}</LabelXSmall>}
                        {column === ALL_JOBS_PICKUP_COLUMN && <LabelXSmall>{job?.itemsToPickup}</LabelXSmall>}
                        {column === ALL_JOBS_DROPOFF_COLUMN && <LabelXSmall>{job?.itemsToDropoff}</LabelXSmall>}
                        {column === ALL_JOBS_LABELS &&
                            <HFlex>{getLabels(job).map(l => <JobLabelBadge label={l}/>)}</HFlex>}
                        {customFields.includes(column) && (
                            <LabelXSmall>
                                <NoWrapText>
                                    {job?.customFields && job.customFields
                                        .find((customField: CustomField) => customField.key === column)?.val}
                                </NoWrapText>
                            </LabelXSmall>
                        )}
                    </StyledCell>
                );
            })}
        </StyledRow>
    );
}

export default React.memo(AllJobsRow);