import { selector } from "recoil";
import { companyState } from "./accountState";
import { stringsState } from "./localeState";
import { teamsState } from "./teamsState";
import { atomWithPersistence } from "../utils/persistence";
import { JobTypes } from "../types/job";

export const teamOptionsState = selector({
    key: "teams.options",
    get: ({ get }) => {
        const teams = get(teamsState);
        return teams.map((team) => ({ id: team.id, label: team.name }));
    },
});

export const jobCompactVisualizationState = atomWithPersistence("job.visualization.compact", false);

export const jobTypeOptionsState = selector({
    key: "jobTypes.options",
    get: ({ get }) => {
        const strings = get(stringsState);
        const company = get(companyState);
        const jobTypeOptions = [
            { label: strings.Job.JobTypeWork, id: JobTypes.WORK },
            { label: strings.Job.JobTypePickup, id: JobTypes.PICKUP },
            { label: strings.Job.JobTypeDropoff, id: JobTypes.DROPOFF },
        ];
        const customJobTypeOptions = company
            ? company.job_custom_types.map((jobType) => ({ id: jobType.id, label: jobType.name }))
            : [];
        return [...jobTypeOptions, ...customJobTypeOptions];
    },
});