import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { pageState, } from '../../states/appState';
import { memberState } from '../../states/membersState';
import { HFlex } from '../containers/HFlex';
import { hasRecentGps } from "../../types/member";
import { PAGE } from "../../constants/pages";
import { useBoard } from "../../hooks/useBoard";
import { useTheme } from "../../hooks/useTheme";
import { VFlex } from "../containers/VFlex";
import { LabelSmall, LabelXSmall } from "baseui/typography";
import { NoWrapText } from "../containers/NoWrapText";
import MemberAvatar from "./MemberAvatar";
import { MemberInfo } from "./MemberInfo";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { SpeedHistogram } from "../histogram/SpeedHistogram";
import MemberItemHoverButtons from "./MemberItemHoverButtons";
import { ClickableListItem } from "../ui/ClickableListItem";
import { Uid } from "../../types/core";

type Props = {
    uid: Uid;
    isSelected?: boolean;
    extraIcon?: React.ReactNode;
    onEditClick?: Function;
    onDispatchClick?: Function;
    onLocateClick?: Function;
    onMemberClick?: Function;
    onContextMenu?: Function;
    droppablePlaceholder?: React.ReactNode;
    isDraggingHover?: boolean;
};

function MemberItem({ uid, isSelected = false, extraIcon, droppablePlaceholder }: Props) {
    const member = useRecoilValue(memberState(uid));
    const [visibleOptions, setVisibleOptions] = useState(false);
    const page = useRecoilValue(pageState);
    const board = useBoard();
    const { theme } = useTheme();
    const { labelEllipsisStyles } = useStyleOverrides();

    if (!member) {
        return null;
    }

    const onMemberClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.metaKey) {
            board.add(member.uid);
        } else if (board.memberUids.includes(member.uid)) {
            board.remove(member.uid);
        } else {
            board.setMemberUids([member.uid]);
        }
    };

    return (
        <ClickableListItem
            isSelected={isSelected}
            onHover={() => <MemberItemHoverButtons uid={member.uid} extraIcon={extraIcon}/>}
            onClick={onMemberClick}
            onHoverChange={(isHover) => setVisibleOptions(isHover)}
        >
            <HFlex alignCenter style={{
                flexGrow: 1,
                flexShrink: 1,
                flexBasis: "0%",
                overflow: 'hidden',
                justifyContent: 'space-between',
                width: '200px',
                columnGap: theme.sizing.scale100,
            }}>
                <VFlex spacing={"4px"}>
                    <HFlex spacing alignCenter>
                        <MemberAvatar uid={member.uid} forMap={false} size={14}/>
                        <NoWrapText>
                            <LabelSmall margin={0} overrides={labelEllipsisStyles}>
                                {member.name || member.username}
                            </LabelSmall>
                        </NoWrapText>
                        {page === PAGE.TEAM && (
                            <LabelXSmall color={theme.colors.contentTertiary} style={{ fontSize: "10px" }}>
                                {member.username}
                            </LabelXSmall>
                        )}
                    </HFlex>
                    <MemberInfo uid={uid} lastLocation={hasRecentGps(member)}/>
                </VFlex>
                {droppablePlaceholder}
                {!visibleOptions && <SpeedHistogram uid={uid}/>}
            </HFlex>
        </ClickableListItem>
    );
}

export default React.memo(MemberItem)
