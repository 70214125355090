import { hasRecentGps, Member } from "../types/member";
import { COLOR_IDLING, COLOR_MOVING } from "../constants/colors";
import { MemberSort } from "../states/membersState";

export function getMovingColor(member: Member) {
    return hasRecentGps(member) ? COLOR_MOVING : COLOR_IDLING;
}

export const sortMembers = (m1: Member, m2: Member) => (m1.name || m1.username) && (m2.name || m2.username)
    ? (m1.name || m1.username).localeCompare(m2.name || m2.username)
    : 0;

export const sortMembersByHeader = (m1: Member, m2: Member, sort: number) => {
    switch (sort) {
        case MemberSort.NAME:
            return m1.name.localeCompare(m2.name);
        case MemberSort.ROLE:
            return m1.role.localeCompare(m2.role);
        case MemberSort.LAST_LOCATION:
            return m1.location.ts - m2.location.ts;
        case MemberSort.APP:
            return m1.device.app_version - m2.device.app_version;
    }
    return m1.name.localeCompare(m2.name);
}
