import React from 'react';
import { useRecoilValue } from "recoil";
import { selectedTrackTimeSliderProgress } from "../../states/tracksState";

export function ProgressSparklineSpot({ points, size = 2, style }: any) {
    const progress = useRecoilValue(selectedTrackTimeSliderProgress);

    const idx = Math.min(Math.floor(points.length * progress), points.length - 1);
    const {x, y} = points[idx];

    return (
        <g>
            <circle cx={x} cy={y} r={size} style={style} />
        </g>
    )
}

