import { Button } from "baseui/button";
import { ChevronDown } from "baseui/icon";
import { StatefulPopover } from "baseui/popover";
import { useRecoilState, useRecoilValue } from "recoil";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { membersFiltersState } from "../../states/membersState";
import { teamsState } from "../../states/teamsState";
import { hasRecentGps, isOffDuty, Member } from "../../types/member";
import { FilterFilledIcon, FilterIcon } from "../ui/svg";
import { memo, useCallback } from "react";
import { jobsAssignedState } from "../../states/jobsState";
import { StyledMenu } from "../ui/StyledMenu";
import { Filter } from "../../types/core";
import { useUpdateEffect } from "usehooks-ts";
import { selectedDayState } from "../../states/appState";


export const MemberFilter = memo(() => {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const [filters, setFilters] = useRecoilState(membersFiltersState);
    const teams = useRecoilValue(teamsState);
    const jobsAssigned = useRecoilValue(jobsAssignedState);
    const selectedDay = useRecoilValue(selectedDayState);

    const hasActiveFilter = !!filters.filter((f) => f.id === "active").length;
    const hasAssignedJobsFilter = !!filters.filter((f) => f.id === "assignedJobs").length;
    const hasOffDutyFilter = !!filters.filter((f) => f.id === "hideOffDuty").length;
    const ungroupedFilters = [];

    const matchAssignedJobs = useCallback((member: Member) => jobsAssigned.some((job) => job.assigneeUsername === member.username), [jobsAssigned]);

    useUpdateEffect(() => {
        const assignedJobsIndex = filters.findIndex((filter) => filter.id === "assignedJobs");
        if (assignedJobsIndex !== -1) {
            setFilters((prevFilters) => {
                const newFilters = [...prevFilters];
                newFilters[assignedJobsIndex] = {
                    ...newFilters[assignedJobsIndex],
                    matches: matchAssignedJobs,
                }
                return newFilters;
            });
        }
    }, [selectedDay]);

    if (!hasActiveFilter) {
        ungroupedFilters.push({
            id: "active",
            label: strings.General.CurrentlyActive,
            matches: (member: Member) => hasRecentGps(member)
        });
    }
    if (!hasAssignedJobsFilter) {
        ungroupedFilters.push({
            id: "assignedJobs",
            label: strings.General.JobsAssigned,
            matches: matchAssignedJobs
        });
    }
    if (!hasOffDutyFilter) {
        ungroupedFilters.push({
            id: "hideOffDuty",
            label: strings.General.HideOffDuty,
            matches: (member: Member) => !isOffDuty(member)
        });
    }

    const items = {
        __ungrouped: ungroupedFilters,
        [strings.General.Team]: teams.map((team) => ({
            id: team.id.toString(),
            label: team.name,
            matches: (member: Member, teamId: string) => member.teams.includes(+teamId),
            group: "team",
        } as Filter))
            .filter((item) => filters.filter((f) => f.id === item.id).length === 0),
    }

    return (
        <StatefulPopover
            focusLock
            placement={"bottomRight"}
            content={({ close }) => (
                <StyledMenu
                    items={items}
                    onItemSelect={(opt) => {
                        setFilters([...filters, opt.item]);
                        close();
                    }}
                    overrides={{
                        List: {
                            style: {
                                maxHeight: "60vh",
                                maxWidth: "300px",
                            }
                        },
                    }}
                />
            )}
        >
            <Button kind={"tertiary"}
                    size={"mini"}
                    overrides={{
                        Root: {
                            style: {
                                height: "22px"
                            }
                        }
                    }}
            >
                {filters.length > 0
                    ? <FilterFilledIcon size={12} color={theme.customColors.accent}/>
                    : <FilterIcon size={12} color={theme.colors.contentPrimary}/>}
                <ChevronDown size={24}/>
            </Button>
        </StatefulPopover>
    )
});