import { Button } from "baseui/button";
import { Checkbox } from "baseui/checkbox";
import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useJobForms } from "../../../hooks/jobs/useJobForms";
import { useModal } from "../../../hooks/useModal";
import { useStyleOverrides } from "../../../hooks/useStyleOverrides";
import { useTheme } from "../../../hooks/useTheme";
import { editingJobIdState } from "../../../states/appState";
import { jobState } from "../../../states/jobsState";
import { stringsState } from "../../../states/localeState";
import { HFlex } from "../../containers/HFlex";
import { SpacerExpand } from "../../containers/Spacer";
import { VFlex } from "../../containers/VFlex";
import { ConfirmModal } from "../../ui/ConfirmModal";
import { useJobTemplatesUpdater } from "../../../hooks/jobs/useJobTemplatesUpdater";
import { useTime } from "../../../hooks/useTime";
import { ArchiveIcon, BookmarkIcon, CloneJobIcon, PrinterIcon, TrashIcon } from "../../ui/svg";
import { ActionButton } from "../../buttons/ActionButton";
import { LabelSmall } from "baseui/typography";
import { useToast } from "../../../hooks/useToast";
import { MemberBadge } from "../../badges/MemberBadge";
import { Alert, Tag } from "antd";
import { printableLink } from "../../../utils/jobUtils";
import useAccount from "../../../hooks/useAccount";
import { editJobHasModifiedState } from "../../../states/editJobState";
import { flushSync } from "react-dom";
import { usePermissions } from "../../../hooks/usePermissions";

export function JobModalFooter() {
    const strings = useRecoilValue(stringsState);
    const jobId = useRecoilValue(editingJobIdState);
    const job = useRecoilValue(jobState(jobId));
    const jobHasChanges = useRecoilValue(editJobHasModifiedState);
    const {
        onSubmit,
        isLoading,
        canSubmit,
        isNewJob,
        hasChanges,
        onDelete,
        onArchive,
        onRestore,
        onClone,
        saveChanges,
    } = useJobForms();
    const { closeModal, cancelModal } = useModal();
    const { cancelButtonStyles } = useStyleOverrides();
    const [showArchiveConfirm, setArchiveShowConfirm] = useState(false);
    const [showDeleteConfirm, setDeleteShowConfirm] = useState(false);
    const [showCloneConfirm, setCloneShowConfirm] = useState(false);
    const [showTemplateConfirm, setTemplateShowConfirm] = useState(false);
    const [withAssignee, setWithAssignee] = useState(false);
    const [withDate, setWithDate] = useState(false);
    const { formatDay } = useTime();
    const { theme } = useTheme();
    const { saveAsTemplate } = useJobTemplatesUpdater();
    const toast = useToast();
    const { company } = useAccount();
    const { mayDeleteJobs } = usePermissions();
    const setEditingJob = useSetRecoilState(editingJobIdState);


    const onDeleteConfirmed = async () => {
        await onDelete();
        setDeleteShowConfirm(false);
    };

    const onArchiveConfirmed = async () => {
        await onArchive();
        setArchiveShowConfirm(false);
    };

    const closeCloneConfirm = () => {
        setCloneShowConfirm(false);
        setWithAssignee(false);
        setWithDate(false);
    }

    const onCloneConfirmed = async () => {
        await onClone(withAssignee, withDate);
        closeCloneConfirm();
    };

    const onTemplateConfirmed = async () => {
        if (!job) {
            return;
        }
        if (jobHasChanges) {
            await saveChanges();
        }
        const { id } = await saveAsTemplate(job);
        setTemplateShowConfirm(false);
        flushSync(() => {
            closeModal();
        });
        if (id) {
            setEditingJob(id);
        }
    };

    const onRestoreClick = async () => {
        await onRestore();
        toast.showSuccess(strings.General.Success);
    };

    return (
        <>
            {job && !isNewJob && (
                <>
                    {mayDeleteJobs() &&
                        <ActionButton
                            tooltip={strings.General.Delete}
                            onClick={() => setDeleteShowConfirm(true)}
                            size='compact'
                            kind='tertiary'
                        >
                            <TrashIcon color={theme.colors.warning} size={16}/>
                        </ActionButton>
                    }
                    {job.status !== "archived" && (
                        <ActionButton
                            tooltip={strings.Archive.Archive}
                            onClick={() => setArchiveShowConfirm(true)}
                            size='compact'
                            kind='tertiary'
                        >
                            <ArchiveIcon color={theme.colors.contentPrimary} size={16}/>
                        </ActionButton>
                    )}
                    {job.status === "archived" && (
                        <Button
                            onClick={() => onRestoreClick()}
                            size='compact'
                            kind='tertiary'
                        >
                            <LabelSmall color={theme.customColors.accent}>{strings.Archive.Restore}</LabelSmall>
                        </Button>
                    )}
                    <ActionButton
                        tooltip={strings.Clone.CloneJob}
                        onClick={() => setCloneShowConfirm(true)}
                        size='compact'
                        kind='tertiary'
                    >
                        <CloneJobIcon color={theme.colors.contentPrimary} size={16}/>
                    </ActionButton>
                    <ActionButton
                        tooltip={strings.General.ShowPrintableVersion}
                        onClick={() => {window.open(printableLink(company!.uid, job), "_blank")}}
                        size='compact'
                        kind='tertiary'
                    >
                        <PrinterIcon color={theme.colors.contentPrimary} size={16}/>
                    </ActionButton>
                </>
            )}
            {!isNewJob && (
                <ActionButton
                    tooltip={strings.General.SaveAsJobTemplate}
                    onClick={() => setTemplateShowConfirm(true)}
                    size='compact'
                    kind='tertiary'
                >
                    <BookmarkIcon color={theme.colors.contentPrimary} size={16}/>
                </ActionButton>
            )}
            <SpacerExpand/>
            {hasChanges && (
                <Button
                    onClick={cancelModal}
                    size='compact'
                    kind='tertiary'
                    overrides={cancelButtonStyles}
                >
                    {strings.General.Cancel}
                </Button>
            )}
            <Button
                onClick={hasChanges ? onSubmit : closeModal}
                size='compact'
                isLoading={isLoading}
                disabled={!canSubmit && hasChanges}
            >
                {hasChanges || isNewJob ? strings.General.Save : strings.General.OK}
            </Button>
            {job && !isNewJob && (
                <>
                    <ConfirmModal
                        isOpen={showDeleteConfirm}
                        description={job.destinationName}
                        onCancel={() => setDeleteShowConfirm(false)}
                        onConfirm={onDeleteConfirmed}
                        title={strings.Job.ReallyDeleteThisJob}
                        confirmText={strings.General.Delete}
                        cancelText={strings.General.Cancel}
                        danger
                    />
                    <ConfirmModal
                        isOpen={showArchiveConfirm}
                        title={strings.Archive.ArchiveDescription}
                        onCancel={() => setArchiveShowConfirm(false)}
                        onConfirm={onArchiveConfirmed}
                        description={job.destinationName}
                        cancelText={strings.General.Cancel}
                        confirmText={strings.Archive.Archive}
                    />
                    <ConfirmModal
                        isOpen={showCloneConfirm}
                        description={
                            <VFlex spacing>
                                <span>{strings.Clone.CloningCreatesADuplicateOfThisJob}</span>
                                {job.assigneeName && (
                                    <Checkbox
                                        checked={withAssignee}
                                        onChange={(event) => setWithAssignee(event.target.checked)}
                                    >
                                        <HFlex alignCenter style={{
                                            columnGap: theme.sizing.scale300,
                                        }}>
                                            {strings.General.CopyAssignee}
                                            <MemberBadge uid={job.receiverUid}/>
                                        </HFlex>
                                    </Checkbox>
                                )}
                                {!!job.day && (
                                    <Checkbox
                                        checked={withDate}
                                        onChange={(event) => setWithDate(event.target.checked)}
                                    >
                                        <HFlex alignCenter spacing>
                                            {strings.General.CopyDate}
                                            <Tag>{formatDay(job.day)}</Tag>
                                        </HFlex>
                                    </Checkbox>
                                )}
                            </VFlex>
                        }
                        cancelText={strings.General.Cancel}
                        confirmText={strings.Clone.Clone}
                        onCancel={closeCloneConfirm}
                        onConfirm={onCloneConfirmed}
                        title={strings.Clone.Clone}
                    />
                    <ConfirmModal
                        isOpen={showTemplateConfirm}
                        description={
                            <VFlex spacing>
                                <span>{strings.JobTemplates.JobTemplatesDesc}</span>
                                {jobHasChanges && (
                                    <Alert showIcon type="warning" message={strings.General.ActionWillSaveChanges}/>
                                )}
                            </VFlex>
                        }
                        onCancel={() => setTemplateShowConfirm(false)}
                        onConfirm={onTemplateConfirmed}
                        title={strings.General.SaveAsJobTemplate}
                        confirmText={strings.General.Save}
                        cancelText={strings.General.Cancel}
                    />
                </>
            )}
        </>
    );
}