import { AlertActionOption, AlertConfig } from './../types/alerts';
import { EMPTY_TS, EMPTY_UID } from "../types/core";

export enum ALERT_INTERVALS {
    none = 0,
    tenMinutes = 600000,
    thirtyMinutes = 1800000,
    oneHour = 3600000,
    twoHours = 7200000,
    threeHours = 10800000,
}

export enum ALERT_ACTIONS {
    NONE,
    CHECKS_IN,
    CHECKS_OUT,
    CHECKS_ANY,
    NO_CHECK_IN,
    NO_CHECK_OUT,
    NO_CHECK_ANY,
    IDLE,
    ENTERS_ZONE,
    LEAVES_ZONE,
    NO_CONNECTION_TO_DEVICE
}

export const alertActionsOptions: AlertActionOption[] = [
    {
        id: ALERT_ACTIONS.CHECKS_IN,
        labelKey: 'Alerts.AlertChecksIn',
        hasZone: false,
        hasDuration: false,
        hasPlace: true,
        hasAndFunction: true,
    },
    {
        id: ALERT_ACTIONS.CHECKS_OUT,
        labelKey: 'Alerts.AlertChecksOut',
        hasZone: false,
        hasDuration: false,
        hasPlace: true,
        hasAndFunction: true,
    },
    {
        id: ALERT_ACTIONS.NO_CHECK_IN,
        labelKey: 'Alerts.AlertDoesntCheckIn',
        hasZone: false,
        hasDuration: false,
        hasPlace: true,
        hasAndFunction: false,
    },
    {
        id: ALERT_ACTIONS.NO_CHECK_OUT,
        labelKey: 'Alerts.AlertDoesntCheckOut',
        hasZone: false,
        hasDuration: false,
        hasPlace: true,
        hasAndFunction: false,
    },
    {
        id: ALERT_ACTIONS.IDLE,
        labelKey: 'Alerts.AlertLongIdleTime',
        hasZone: false,
        hasDuration: true,
        hasPlace: false,
        hasAndFunction: false,
    },
    {
        id: ALERT_ACTIONS.NO_CONNECTION_TO_DEVICE,
        labelKey: 'Alerts.AlertNoConnectionToDevice',
        hasZone: false,
        hasDuration: true,
        hasPlace: true,
        hasAndFunction: false,
    },
    {
        id: ALERT_ACTIONS.ENTERS_ZONE,
        labelKey: 'Alerts.AlertEntersZone',
        hasZone: true,
        hasDuration: false,
        hasPlace: false,
        hasAndFunction: false,
    },
    {
        id: ALERT_ACTIONS.LEAVES_ZONE,
        labelKey: 'Alerts.AlertLeavesZone',
        hasZone: true,
        hasDuration: false,
        hasPlace: false,
        hasAndFunction: false,
    },
];

export const emptyAlertConfig: AlertConfig = {
    id: "0",
    tsCreated: EMPTY_TS,
    companyUid: EMPTY_UID,
    weekdays: 127,
    timeStart: 0,
    timeEnd: 2400,
    title: "",
    description: "",
    place1Uids: "",
    place2Uids: "",
    zones: "",
    action1: 1,
    action2: 0,
    interval: 0,
    email: "",
    phone: "",
    memberUids: "",
    teamIds: "",
    status: 0,
    type: 1,
}