import { useRecoilState, useRecoilValue } from 'recoil';
import { VFlex } from '../containers/VFlex';
import { TrackSection } from "./TrackSection";
import { selectedTimelineState, tracksLayerState } from "../../states/geojsonState";
import { dayState, prefAutoZoomState, selectedTimeFrameState } from "../../states/appState";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { useCamera } from "../../hooks/useCamera";
import React, { memo, useState } from "react";
import { requestingState, timelineMileageCalendarOpenState } from "../../states/viewState";
import { HFlex } from "../containers/HFlex";
import { EmptyState } from "../ui/EmptyState";
import { CrossRoadIcon, ViewIcon } from "../ui/svg";
import { Centered } from "../containers/Centered";
import { StopSection } from "./StopSection";
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";
import { Button as AntButton, Collapse, Segmented, Spin, Switch } from "antd";
import { retrieveTimelineEntriesFromGeojson, retrieveTracksFromGeojson } from "../../utils/geojsonUtils";
import { createTimelineItemsFromTracksAndEntries } from "./timelineUtils";
import { TrackInfoModal } from '../location/TrackInfoModal';
import { TrackEntry } from '../../types/track';
import { MileageHistory } from "../histogram/MileageHistory";
import { ColumnTitle } from "../board/ColumnTitle";
import { ColumnCard } from "../board/ColumnCard";
import { memberState } from "../../states/membersState";
import { millisToMinutes } from '../../utils/time';
import { LabelXSmall } from "baseui/typography";
import { Uid } from "../../types/core";

const { Panel } = Collapse;

export const TimelineColumn = memo(({ uid, showTitle = true }: { uid: Uid, showTitle?: boolean }) => {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const day = useRecoilValue(dayState);
    const member = useRecoilValue(memberState(uid));
    const fullDayLayer = useRecoilValue(tracksLayerState({ uid: uid, day: day }));
    const timelineLayer = useRecoilValue(selectedTimelineState(uid));
    const [prefAutoZoom, setPrefAutoZoom] = useRecoilState(prefAutoZoomState);
    const camera = useCamera();
    const [tracksOnly, setTracksOnly] = useState(false);
    const isRequesting = useRecoilValue(requestingState({ req: "tracks" }));
    const [selectedTrack, setSelectedTrack] = useState<TrackEntry>();
    const [mileageCalendarOpen, setMileageCalendarOpen] = useRecoilState(timelineMileageCalendarOpenState);
    const timeFrame = useRecoilValue(selectedTimeFrameState);

    if (!member) {
        return null;
    }

    if (!fullDayLayer) {
        if (isRequesting) {
            return (
                <Centered>
                    <Spin/>
                </Centered>
            );
        }
    }
    const inTimeFrame = (millis = timeFrame[0]) => {
        const minutes = millisToMinutes(millis);
        return minutes >= timeFrame[0] && minutes <= timeFrame[1];
    }

    const trackEntries = retrieveTracksFromGeojson(fullDayLayer);
    const timelineEntries = !tracksOnly ? retrieveTimelineEntriesFromGeojson(timelineLayer) : [];
    const timelineEntriesInTimeFrame = timelineEntries.filter(t => inTimeFrame(t.tsStart));
    const items = createTimelineItemsFromTracksAndEntries(trackEntries, timelineEntriesInTimeFrame);

    const filteredItems = items
        .filter((item, index) => inTimeFrame(item.track?.entry?.startTs || item.stop?.start))
        .map((item) => {
            if (item.track && item.events) {
                return {
                    ...item,
                    events: item.events.filter(event => inTimeFrame(event.tsStart)),
                }
            }
            return item;
        });

    const eventChooser = (
        <HFlex>
            <SpacerExpand/>
            <Segmented
                value={tracksOnly ? "onlytracks" : "allevents"}
                size={"small"}
                options={[
                    {
                        value: "allevents",
                        label: strings.Timeline.AllEvents
                    },
                    {
                        value: "onlytracks",
                        label: strings.Timeline.OnlyTracks,
                    },
                ]}
                onChange={(value) => setTracksOnly(value === "onlytracks")}
            />
            <SpacerExpand/>
        </HFlex>
    );

    const mileageCalendar = showTitle && (
        <Collapse bordered={false}
                  defaultActiveKey={mileageCalendarOpen ? ['1'] : []}
                  onChange={(key) => setMileageCalendarOpen(key.length > 0)}
        >
            <Panel key={"1"} header={strings.General.MileageHistory}>
                <MileageHistory uid={uid}/>
            </Panel>
        </Collapse>
    );

    if (items.length === 0) {
        return (
            <ColumnCard key={uid} color={member.color} title={showTitle && <ColumnTitle uid={uid}/>}>
                <VFlex style={{ height: "auto" }}>
                    {mileageCalendar}
                    {eventChooser}
                    <Spacer height="40px"/>
                    <EmptyState title={strings.EmptyStates.TracksEmptyTitle}
                                subtitle={strings.EmptyStates.TracksEmptyDescription}>
                        <CrossRoadIcon size={72} color={theme.colors.contentPrimary}></CrossRoadIcon>
                    </EmptyState>
                </VFlex>
            </ColumnCard>
        );
    }

    const showFullDayRoute = () => {
        let mergedDate = [] as any[];
        trackEntries.forEach(entry => entry.data.forEach(lngLat => mergedDate.push(lngLat)))
        camera.fitToLngLats(mergedDate);
    }

    const closeTrackInfo = () => {
        setSelectedTrack(undefined);
    }

    return (
        <ColumnCard key={uid} color={member.color} title={showTitle && <ColumnTitle uid={uid}/>}>
            <VFlex style={{ height: "auto" }}>
                {mileageCalendar}
                <Spacer/>
                {eventChooser}
                <HFlex>
                    <SpacerExpand/>
                    <AntButton type="ghost" size={"small"} onClick={showFullDayRoute}>
                        <HFlex spacing alignCenter>
                            <ViewIcon size={16} color={theme.colors.contentPrimary}/>
                            {strings.Timeline.ShowFullDayRoute}
                        </HFlex>
                    </AntButton>
                    <SpacerExpand/>
                </HFlex>
                <HFlex spacing>
                    <SpacerExpand/>
                    <Switch
                        checked={prefAutoZoom}
                        size={"small"}
                        onChange={value => setPrefAutoZoom(value)}/>
                    <LabelXSmall>{strings.Timeline.ZoomToTrack}</LabelXSmall>
                    <SpacerExpand/>
                </HFlex>
                <Spacer height={"20px"}/>
                {filteredItems.map((item, index) =>
                    <div key={index}>
                        {item.track &&
                            <TrackSection
                                track={item.track.entry}
                                data={item.track.data}
                                key={item.track.entry.uid}
                                events={item.events}
                                onTrackInfo={setSelectedTrack}
                            />}
                        {item.stop &&
                            <StopSection
                                events={item.events}
                                showStartBullet={index === 0}
                                showEndBullet={index === filteredItems.length - 1}
                            />
                        }
                    </div>
                )}
                {isRequesting && <>
                    <Spacer/>
                    <HFlex>
                        <SpacerExpand/>
                        <Spin/>
                        <SpacerExpand/>
                    </HFlex>
                </>}
                {selectedTrack && (
                    <TrackInfoModal track={selectedTrack} onClose={closeTrackInfo} canDelete canShare/>
                )}
            </VFlex>
        </ColumnCard>
    );
});
