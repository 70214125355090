import { Button } from "baseui/button";
import { LogoIcon } from "../ui/svg";
import { LightTheme } from "baseui";
import { EmptyState } from "../ui/EmptyState";
import { useStrings } from "../../hooks/useStrings";
import { useNavigator } from "../../hooks/useNavigator";

export function GoToDashboard() {
    const { strings } = useStrings();
    const { navigateToPage } = useNavigator();

    return (
        <EmptyState
            title={strings.General.WelcomeToHellotracksBusiness}
            cta={
                <Button
                    onClick={() => {
                        navigateToPage("", true);
                    }}
                >
                    {strings.General.GoToLiveDashboard}
                </Button>
            }
        >
            <LogoIcon size={40} color={LightTheme.colors.contentPrimary}/>
        </EmptyState>
    );
}