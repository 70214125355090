import { Spacer, SpacerExpand } from "../containers/Spacer";
import { OpenAiIcon } from "../ui/svg";
import { AssistantChips } from "./AssistantChips";
import { Button } from "baseui/button";
import { HFlex } from "../containers/HFlex";
import { useTheme } from "../../hooks/useTheme";
import { useRecoilState } from "recoil";
import { assistantQueryingState } from "../../states/assistantState";
import { Card } from "antd";
import { useStrings } from "../../hooks/useStrings";

export function AssistantFeedback() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const [loading, setLoading] = useRecoilState(assistantQueryingState);

    if (!loading) {
        return null;
    }

    return (
        <Card style={{ marginBottom: "16px" }}>
            <HFlex alignCenter style={{ marginTop: "16px", marginBottom: "16px" }}>
                <SpacerExpand/>
                <OpenAiIcon size={24} color={theme.colors.contentPrimary} animated={loading}/>
                <Spacer width={"32px"}/>
                <AssistantChips/>
                <Spacer width={"32px"}/>
                <Button kind={"secondary"} size={"mini"} onClick={() => setLoading(false)}>
                    {strings.General.Stop}
                </Button>
                <SpacerExpand/>
            </HFlex>
        </Card>
    );
}