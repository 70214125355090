import { useRecoilState, useRecoilValue } from "recoil";
import { editingMemberIdState } from "../../states/appState";
import { DetailedLocation, EMPTY_UID } from '../../types/core';
import { editMemberState } from '../../states/editMemberState';
import { useDrawMode } from "../useDrawMode";

export function useEditMemberRouting() {
    const editingMember = useRecoilValue(editingMemberIdState);
    const [memberFields, setMemberFields] = useRecoilState(editMemberState(editingMember || EMPTY_UID));
    const { drawMode, enterDrawMode } = useDrawMode();

    const location = drawMode?.location;

    const initEditRouting = (type: "start" | "end") => {
        if (editingMember !== undefined) {
            if (type === "start" && memberFields.route_start_lat && memberFields.route_start_lng) {
                enterDrawMode({
                    type: "start",
                    action: "edit",
                    location: {
                        address: `${memberFields.route_start_lat}, ${memberFields.route_start_lng}`,
                        lat: memberFields.route_start_lat,
                        lng: memberFields.route_start_lng,
                    } as DetailedLocation
                })
            } else if (type === "end" && memberFields.route_end_lat && memberFields.route_end_lng) {
                enterDrawMode({
                    type: "end",
                    action: "edit",
                    location: {
                        address: `${memberFields.route_end_lat}, ${memberFields.route_end_lng}`,
                        lat: memberFields.route_end_lat,
                        lng: memberFields.route_end_lng,
                    } as DetailedLocation
                })
            }
        }
    }

    const editMemberRouting = () => {
        if (location) {
            if (drawMode.type === "start") {
                setMemberFields((prev) => ({
                    ...prev,
                    route_start_lat: location.lat,
                    route_start_lng: location.lng,
                }));
            } else if (drawMode.type === "end") {
                setMemberFields((prev) => ({
                    ...prev,
                    route_end_lat: location.lat,
                    route_end_lng: location.lng,
                }));
            }
        }
    };

    return {
        initEditRouting,
        editMemberRouting,
    };
}