import { atomFamily, selector, selectorFamily } from 'recoil';
import { Zone } from '../types/zone';
import { getDiffFields } from './../utils/objects';
import { editingZoneIdState } from './appState';
import { zoneState } from './zoneState';

const editZoneInitializer = selectorFamily<Zone, string>({
    key: 'edit.zone.initializer',
    get: (uid) => ({ get }) => {
        const zone = get(zoneState(uid));
        return {
            ...zone,
        } as Zone;
    },
});

export const editZoneState = atomFamily<Zone, string>({
    key: 'edit.zone',
    default: editZoneInitializer,
});

export const editZoneValidState = selectorFamily<{ [key: string]: boolean }, string>({
    key: 'edit.zone.valid',
    get: (uid) => ({ get }) => {
        const fields = get(editZoneState(uid));
        const validFields = Object.fromEntries(Object.keys(fields).map((key) => [key, true]));
        if (!fields.name) {
            validFields['name'] = false;
        }
        return validFields;
    },
});

export const editZoneErrorState = selectorFamily<boolean, string>({
    key: 'edit.zone.error',
    get: (uid) => ({ get }) => {
        const validFields = get(editZoneValidState(uid));

        return Object.values(validFields).some((valid) => !valid);
    },
});

export const editZoneModifiedState = selector({
    key: 'edit.zone.modified',
    get: ({ get }) => {
        const zoneId = get(editingZoneIdState);
        const zone = get(zoneState(zoneId || ''));
        const zoneFields = get(editZoneState(zoneId || ''));
        return getDiffFields(zone || {}, zoneFields);
    },
});

export const editZoneHasModifiedState = selector({
    key: 'edit.zone.modified.count',
    get: ({ get }) => {
        return !!Object.keys(get(editZoneModifiedState)).length;
    },
});