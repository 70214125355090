import { assignJob } from "../../services/privateApi";
import { useStateUpdater } from "../useStateUpdater";
import { useDayRouteUpdater } from "../useDayRouteUpdater";
import { archiveJobs, deleteJobs, editJobs, restoreJobs } from "../../services/api";
import {
    Job,
    PROGRESS_STATUS_ACCEPTED,
    PROGRESS_STATUS_IN_PROGRESS,
    PROGRESS_STATUS_ISSUE,
    PROGRESS_STATUS_SCHEDULED,
    PROGRESS_STATUS_SUCCESS
} from "../../types/job";
import { Day, EMPTY_UID, EmptyUid, Uid, UidDay } from "../../types/core";
import { getLinkedZonesString, LinkedForm, LinkedZone } from "../../types/linked";

export function useJobAssignment() {
    const updater = useStateUpdater();
    const dayRouteUpdater = useDayRouteUpdater();

    const assign = async (jobId: string, day: Day, index: number, assignee: Uid, origAssignee: Uid) => {
        return assignJob({
            id: jobId,
            day: day,
            index: index,
            assignee: assignee,
        }).then(({ status, jobs }) => {
            if (status) {
                dayRouteUpdater.clear(assignee, day);
                dayRouteUpdater.update(assignee, day);
                if (origAssignee && assignee !== origAssignee) {
                    dayRouteUpdater.clear(origAssignee, day);
                    dayRouteUpdater.update(origAssignee, day);
                }
                updater.updateJobs(jobs);
            }
        });
    }

    const unassign = async (jobId: string, day: Day, origAssignee: Uid | EmptyUid) => {
        return assignJob({
            id: jobId,
            day: day,
            index: 0,
            assignee: EMPTY_UID,
        }).then(({ status, jobs }) => {
            if (status) {
                updater.updateJobs(jobs);
                if (origAssignee !== "") {
                    dayRouteUpdater.clear(origAssignee, day);
                    dayRouteUpdater.update(origAssignee, day);
                }
            }
        });
    }

    const bulkEdit = async (ids: string[],
                            uidDays: UidDay[] | undefined,
                            jobData: {
                                day?: Day;
                                assigneeUid?: Uid;
                                status?: number;
                                teamId?: number;
                                type?: number;
                                itemsToPickup?: number;
                                itemsToDropoff?: number;
                                priority?: number;
                                linkedZones?: LinkedZone[];
                                linkedForms?: LinkedForm[];
                                carryOver?: number;
                                onSiteMinutes?: number;
                            },
                            onDone?: Function) => {
        const updatedFields = {} as any;
        if (jobData.day) {
            updatedFields["day"] = jobData.day;
        }
        if (jobData.assigneeUid) {
            updatedFields["receiverUid"] = jobData.assigneeUid;
        } else if (jobData.assigneeUid === '') {
            updatedFields["receiverUid"] = "";
        }
        const now = Date.now();
        if (jobData.status === PROGRESS_STATUS_SUCCESS) {
            updatedFields["tsDoneSuccess"] = now;
        } else if (jobData.status === PROGRESS_STATUS_ISSUE) {
            updatedFields["tsDoneFailed"] = now;
        } else if (jobData.status === PROGRESS_STATUS_SCHEDULED) {
            updatedFields["tsScheduled"] = now;
        } else if (jobData.status === PROGRESS_STATUS_ACCEPTED) {
            updatedFields["tsScheduled"] = now - 1;
            updatedFields["tsAccepted"] = now;
        } else if (jobData.status === PROGRESS_STATUS_IN_PROGRESS) {
            updatedFields["tsScheduled"] = now - 2;
            updatedFields["tsAccepted"] = now - 1;
            updatedFields["tsCheckIn"] = now;
        }
        if (jobData.teamId && jobData.teamId > 0) {
            updatedFields["teamId"] = jobData.teamId;
        }
        if (jobData.type && jobData.type >= 0) {
            updatedFields["type"] = jobData.type;
        }
        if (jobData.itemsToPickup != null) {
            updatedFields["itemsToPickup"] = jobData.itemsToPickup;
        }
        if (jobData.itemsToDropoff != null) {
            updatedFields["itemsToDropoff"] = jobData.itemsToDropoff;
        }
        if (jobData.priority != null) {
            updatedFields["priority"] = jobData.priority;
        }
        if (jobData.linkedZones) {
            updatedFields["linkedZones"] = getLinkedZonesString(jobData.linkedZones);
        }
        if (jobData.linkedForms) {
            updatedFields["linkedForms"] = JSON.stringify(jobData.linkedForms);
        }
        if (jobData.carryOver !== undefined) {
            updatedFields["carryOver"] = jobData.carryOver;
        }
        if (jobData.onSiteMinutes && jobData.onSiteMinutes >= 0) {
            updatedFields["onSiteSeconds"] = jobData.onSiteMinutes * 60;
        }
        let data = {} as any;
        for (const id of ids) {
            data[id] = updatedFields
        }
        return editJobs(data).then(({ status, jobs }) => {
            handleJobResponse(status, jobs, uidDays);
            if (status && jobData.assigneeUid) {
                const day = jobData.day || (uidDays && uidDays[0].day);
                day && dayRouteUpdater.update(jobData.assigneeUid, day);
            }
            if (onDone) {
                onDone(status);
            }
        });
    }

    const bulkDelete = async (ids: string[], uidDays: UidDay[] | undefined) => {
        const data: { [key: string]: {} } = {};
        ids.forEach((id) => data[id] = {});
        const { status, jobs } = await deleteJobs(data);
        handleJobResponse(status, jobs, uidDays);
    }

    const bulkArchive = async (ids: string[], uidDays: UidDay[] | undefined) => {
        const data: { [key: string]: {} } = {};
        ids.forEach((id) => data[id] = {});
        const { status, jobs } = await archiveJobs(data);
        handleJobResponse(status, jobs, uidDays);
    }

    const bulkRestore = async (ids: string[], uidDays: UidDay[] | undefined) => {
        const data: { [key: string]: {} } = {};
        ids.forEach((id) => data[id] = {});
        const { status, jobs } = await restoreJobs(data);
        handleJobResponse(status, jobs, uidDays);
    }

    function handleJobResponse(status: boolean, jobs: Job[], uidDays: UidDay[] | undefined) {
        if (status) {
            updater.updateJobs(jobs);
            const alreadyUpdated = [] as UidDay[];
            uidDays?.filter(({ uid, day }) => uid !== "" && day > 0)
                .forEach(({ uid, day }) => {
                    if (alreadyUpdated.filter(el => el.uid === uid && el.day === day).length === 0) {
                        dayRouteUpdater.clear(uid, day);
                        dayRouteUpdater.update(uid, day);
                        alreadyUpdated.push({ uid, day });
                    }
                });
        }
    }

    return { assign, unassign, bulkEdit, bulkDelete, bulkArchive, bulkRestore }
}