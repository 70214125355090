import { LabelMedium, LabelSmall } from "baseui/typography";
import { Spacer } from "../containers/Spacer";
import { HoverButton } from "../buttons/HoverButton";
import { EditUserIcon, LocateIcon, RemoveIcon, SendToPhoneIcon, VDotsIcon } from "../ui/svg";
import { HFlex } from "../containers/HFlex";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { memberState } from "../../states/membersState";
import { useBoard } from "../../hooks/useBoard";
import React, { useRef, useState } from "react";
import { dayState, editingMemberIdState, pageState } from "../../states/appState";
import MemberAvatar from "../member/MemberAvatar";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { Button } from "baseui/button";
import { useSequenceSaver } from "../../hooks/jobs/useSequenceSaver";
import { ConfirmModal } from "../ui/ConfirmModal";
import { useToast } from "../../hooks/useToast";
import { sequencesState } from "../../states/sequencesState";
import { assignSequence } from "../../services/api";
import { useStateUpdater } from "../../hooks/useStateUpdater";
import { VFlex } from "../containers/VFlex";
import { MemberBadges } from "../badges/MemberBadges";
import { Sequence } from "../../types/sequence";
import { SequenceNameModal } from "../dispatch/sequences/SequenceNameModal";
import { useStrings } from "../../hooks/useStrings";
import { useAllJobsSelection } from "../../hooks/jobs/useAllJobsSelection";
import { useTheme } from "../../hooks/useTheme";
import { PAGE } from "../../constants/pages";
import { StyledSelect } from "../ui/StyledSelect";
import { EllipsedText } from "../ui/EllipsedText";
import ColumnTitleMenu from "./ColumnTitleMenu";
import { useHover } from "usehooks-ts";
import { useCamera } from "../../hooks/useCamera";
import { notifyRouteChange, reorderRoute } from "../../services/privateApi";
import { columnTitleReorderDialogState } from "../../states/viewState";
import { useRouting } from "../../hooks/useRouting";
import { Uid } from "../../types/core";

export function ColumnTitle({ uid, jobIds = [] }: { uid: Uid, jobIds?: string[] }) {
    const member = useRecoilValue(memberState(uid));
    const board = useBoard();
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const { strings, format } = useStrings();
    const { theme, isDarkTheme } = useTheme();
    const day = useRecoilValue(dayState);
    const sequenceSaver = useSequenceSaver();
    const [showNewSequence, setShowNewSequence] = useState(false);
    const toast = useToast();
    const jobSelection = useAllJobsSelection();
    const sequences = useRecoilValue(sequencesState);
    const [selectedSequence, setSelectedSequence] = useState<Sequence | undefined>();
    const [showSequencesModal, setShowSequencesModal] = useState(false);
    const { updateJobs } = useStateUpdater();
    const page = useRecoilValue(pageState);
    const camera = useCamera();
    const setEditMember = useSetRecoilState(editingMemberIdState);
    const [reorder, setShowReorderDialog] = useRecoilState(columnTitleReorderDialogState);
    const { responseHandler } = useRouting();

    if (!member) {
        return null;
    }

    const onLoadSequence = async () => {
        if (!selectedSequence) {
            return;
        }
        const { status, jobs } = await assignSequence(selectedSequence.id, uid, day);
        if (status) {
            updateJobs(jobs);
            toast.showSuccess(
                <VFlex>
                    <LabelMedium>{selectedSequence.name}</LabelMedium>
                    <Spacer/>
                    <LabelSmall>{strings.General.SuccessfullyLoadedTo}</LabelSmall>
                    <MemberBadges members={[uid]}/>
                </VFlex>
            )
        }
        closeLoadSequence();
    };
    const onSaveAsSequence = (newSequenceName: string) => {
        sequenceSaver.saveSequenceAs(newSequenceName, jobIds);
        closeNewSequence();
    };

    const closeNewSequence = () => {
        setShowNewSequence(false);
    }
    const closeLoadSequence = () => {
        setShowSequencesModal(false);
        setSelectedSequence(undefined);
    }

    const onReorderConfirmed = async () => {
        const reorderBy = reorder;
        if (reorderBy) {
            setShowReorderDialog(undefined);
            reorderRoute({ day, account: uid, field: reorderBy }).then(res => {
                responseHandler(uid, res);
                if (res.status) {
                    toast.showSuccess(strings.General.Done);
                } else {
                    toast.showWarning(strings.General.SomethingWentWrong);
                }
            });
        }
    };

    const onNotifyClick = async () => {
        const success = await notifyRouteChange([member.uid]);
        if (success) {
            toast.showSuccess(format(strings.General.XNotified, member.name));
        }
    };

    return (
        <div ref={hoverRef}>
            <HFlex alignCenter spacing>
                <MemberAvatar uid={member.uid} forMap={false}/>
                <LabelSmall style={{ flex: 1 }}>
                    <EllipsedText inline breakAll>{member.name || member.username}</EllipsedText>
                </LabelSmall>
                {isHover &&
                    <HoverButton tooltip={strings.General.Locate} size={"mini"}
                                 onClick={() => camera.zoomTo(member?.location)}>
                        <LocateIcon size={12} color={theme.colors.contentTertiary}/>
                    </HoverButton>
                }
                {isHover &&
                    <HoverButton tooltip={strings.General.OpenProfile} size={"mini"}
                                 onClick={() => setEditMember(member?.uid)}>
                        <EditUserIcon size={12} color={theme.colors.contentTertiary}/>
                    </HoverButton>
                }
                {isHover && page == PAGE.DISPATCH &&
                    <HoverButton tooltip={format(strings.Routing.SendRouteNotificationToX, member.name || member.username) as string}
                                 size={"mini"}
                                 onClick={() => onNotifyClick()}>
                        <SendToPhoneIcon size={12} color={theme.colors.contentTertiary}/>
                    </HoverButton>
                }
                {page === PAGE.DISPATCH &&
                    <StatefulPopover
                        content={({ close }) => <ColumnTitleMenu uid={uid} close={close}
                                                                 setShowNewSequence={setShowNewSequence}
                                                                 setShowSequencesModal={setShowSequencesModal}/>}
                        accessibilityType={'tooltip'}
                        placement={PLACEMENT.bottomLeft}
                        triggerType={"click"}
                        showArrow
                    >
                        <Button size={"mini"} shape={"round"} kind={"secondary"}>
                            <VDotsIcon size={14} color={theme.colors.contentSecondary}/>
                        </Button>
                    </StatefulPopover>
                }
                <HoverButton tooltip={strings.General.RemoveFromBoard}
                             kind={"secondary"}
                             onClick={() => {
                                 board.removeMember(member);
                                 jobSelection.unselect(jobIds);
                             }}
                >
                    <RemoveIcon color={theme.colors.contentPrimary} size={14}/>
                </HoverButton>
            </HFlex>
            <SequenceNameModal
                isOpen={showNewSequence}
                onCancel={closeNewSequence}
                onAccept={onSaveAsSequence}
            />
            <ConfirmModal
                isOpen={showSequencesModal}
                description={
                    <StyledSelect
                        closeOnSelect
                        size="compact"
                        options={sequences.map((sequence) => (
                            {
                                name: sequence.name + " (" + format(strings.Job.XJobs, sequence.jobs.length) + ")",
                                id: sequence.id
                            }))}
                        value={selectedSequence ? [selectedSequence] : []}
                        labelKey={'name'}
                        openOnClick
                        clearable={false}
                        onChange={params => setSelectedSequence(params.option as Sequence)}
                    />
                }
                onCancel={closeLoadSequence}
                onConfirm={onLoadSequence}
                title={format(strings.Sequences.LoadSequenceForX, member.name)}
                canConfirm={!!selectedSequence}
                cancelText={strings.General.Cancel}
                confirmText={strings.General.Accept}
            />
            <div style={{
                width: "100%",
                height: "1px",
                marginTop: "4px",
                marginBottom: "0px",
                backgroundColor: isDarkTheme ? "#FFFFFF11" : "#00000011",
            }}/>
            <ConfirmModal
                isOpen={reorder !== undefined}
                description={strings.Reorder.ConfirmationReorder}
                onCancel={() => setShowReorderDialog(undefined)}
                onConfirm={onReorderConfirmed}
                title={strings.Reorder.ReorderingState}
                canConfirm
                cancelText={strings.General.Cancel}
                confirmText={strings.Reorder.ReorderAction}
            />
        </div>
    );
}