import { LatLng } from "../../types/core";
import { useStaticMaps } from "../../hooks/useStaticMaps";
import { useCamera } from "../../hooks/useCamera";
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";
import { Image, Skeleton } from "antd";
import { Clickable } from "../containers/Clickable";
import { EditIcon } from "../ui/svg";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { getColorFromProgress, numberOrLetter } from "../../utils/jobUtils";
import { useRecoilValue } from "recoil";
import { jobState } from "../../states/jobsState";
import FixedJob from "../map/markers/FixedJob";

type Props = {
    location: LatLng;
    jobId?: string,
    size?: number;
    padding?: number;
    onClick?: Function;
    width?: number;
    height?: number;
    viewMarker?: boolean;
    markerCaption?: string;
}

export function StaticMap({
                              location,
                              jobId,
                              size = 250,
                              padding = 0,
                              onClick = undefined,
                              width = 0,
                              height = 0,
                              viewMarker = false,
                              markerCaption,
                          }: Props) {
    const { css, theme } = useTheme();
    const { urlForLatLng } = useStaticMaps();
    const camera = useCamera();
    const { strings } = useStrings();
    const job = useRecoilValue(jobState(jobId));

    const onMapClick = () => {
        if (onClick) {
            onClick();
        } else {
            camera.zoomWithTempMarker(location, viewMarker ? markerCaption || strings.General.Here : undefined);
        }
    }

    const w = width || size;
    const h = height || size;

    if (!location || !location.lat || !location.lng) {
        return (
            <Clickable onClick={onMapClick}>
                <Skeleton.Node style={{ width: w, height: h }}>
                    <HFlex spacing alignCenter>
                        <SpacerExpand/>
                        {strings.General.NoLocation}
                        <EditIcon size={16} color={theme.colors.contentTertiary}/>
                        <SpacerExpand/>
                    </HFlex>
                </Skeleton.Node>
            </Clickable>
        );
    }

    const staticMapStyles = css({
        objectFit: 'cover',
        borderRadius: '8px',
        cursor: 'pointer',
        padding: padding + 'px',
    });

    return (
        <div
            style={{
                position: 'relative',
                display: 'inline-block',
                width: w,
                height: h,
            }}
        >
            <Image
                src={urlForLatLng(location, w, h, !job)}
                alt=""
                loading="lazy"
                className={staticMapStyles}
                style={{ display: 'block', width: '100%', height: '100%' }}
                onClick={onMapClick}
                width={w}
                height={h}
                preview={false}
                placeholder={<Skeleton.Node active style={{ width: w, height: h }}/>}
            />
            {job && (
                <FixedJob
                    size={52}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -100%)',
                        pointerEvents: 'none',
                    }}
                    color={getColorFromProgress(job)}
                    text={numberOrLetter(job)}
                    needle={"medium"}
                />
            )}
        </div>
    );
}