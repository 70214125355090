export const MEMBER_TABS = {
    profile: 'profile',
    status: 'status',
    conversation: 'conversation',
    routing: 'routing',
    workingHours: 'workingHours',
    checks: 'checks',
    location: 'tracks',
    jobs: 'jobs',
    timeline: 'timeline',
    settings: 'settings',
}

export const JOB_TABS = {
    jobInfo: 'jobInfo',
    attachments: 'attachments',
    shortForm: 'inputForm',
    staffInput: 'staffInput',
    statusHistory: 'statusHistory',
}

export const COMPANY_TABS = {
    company: 'company',
    settings: 'settings',
    permissions: 'permissions',
    jobSettings: 'dispatchSettings',
    billing: 'billing',
}

export type CompanyTabKeys = 'company' | 'settings' | 'permissions' | 'dispatchSettings' | 'billing';