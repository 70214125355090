import { useRecoilValue } from 'recoil';
import { JSONbig } from '../../../constants/app';
import { editingJobIdState } from '../../../states/appState';
import { editJobState } from '../../../states/editJobState';
import { formSubmissionsForJobState } from '../../../states/formsState';
import { isValidJsonString } from '../../../utils/strings';
import { FormSubmissionsTimeline } from '../../forms/FormSubmissionsTimeline';

export function JobSubmissions() {
    const jobId = useRecoilValue(editingJobIdState);
    const fields = useRecoilValue(editJobState(jobId || ''));
    const submittedForms: {submissionId: string, templateId: string}[] = 
        isValidJsonString(fields?.submittedForms) ? JSONbig.parse(fields?.submittedForms!) : [];
    const submissions = useRecoilValue(formSubmissionsForJobState(submittedForms));

    if (!fields || !jobId || !submissions.length) {
        return null;
    }

    return (
        <div>
            <FormSubmissionsTimeline submissions={submissions}/>
        </div>
    );
}