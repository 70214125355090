export const EN_TRANSLATION = 0;
export const ES_TRANSLATION = 1;
export const DE_TRANSLATION = 2;

export type LangFields = [string, string, string];

export interface IStrings {
    Map: {
        Clean: LangFields;
        Darkmap: LangFields;
        Filter: LangFields;
        FilterActiveOnly: LangFields;
        FilterAll: LangFields;
        Heatmap: LangFields;
        History: LangFields;
        IconSize: LangFields;
        Layers: LangFields;
        Live: LangFields;
        LiveMap: LangFields;
        Logout: LangFields;
        MapType: LangFields;
        Places: LangFields;
        Roadmap: LangFields;
        Lightmap: LangFields,
        Satellite: LangFields;
        Traffic: LangFields;
        Zones: LangFields;
        Jobs: LangFields;
        DayRoutes: LangFields;
        PointTool: LangFields;
        PointToolDesc: LangFields;
        SelectTool: LangFields;
        SelectToolDesc: LangFields;
        Waypoints: LangFields;
        TranslucentVsSolidBackground: LangFields;
    };
    General: {
        Range: LangFields;
        XDays: LangFields;
        XHours: LangFields;
        XMinutes: LangFields;
        Accept: LangFields;
        Action: LangFields;
        ActionCannotBeUndone: LangFields;
        Add: LangFields;
        Address: LangFields;
        Admin: LangFields;
        AdministratorsDesc: LangFields;
        AssignedTo: LangFields;
        At: LangFields;
        Between: LangFields;
        SaveTemplate: LangFields;
        SaveCustomFieldsForJobsDesc: LangFields;
        SaveCustomFieldsForPlacesDesc: LangFields;
        SaveFormsForPlacesDesc: LangFields;
        Cancel: LangFields;
        Change: LangFields;
        City: LangFields;
        CloseWithoutSaving: LangFields;
        ConnectedToWorkspace: LangFields;
        Confirm: LangFields;
        Contact: LangFields;
        Copied: LangFields;
        Copy: LangFields;
        Assignee: LangFields;
        CopyAssignee: LangFields;
        CopyDate: LangFields;
        Country: LangFields;
        Credentials: LangFields;
        Date: LangFields;
        Delete: LangFields;
        Duration: LangFields;
        Minutes: LangFields;
        Edit: LangFields;
        Email: LangFields;
        Empty: LangFields;
        Filter: LangFields;
        Friday: LangFields;
        General: LangFields;
        Geocode: LangFields;
        ImageUpload: LangFields;
        FileToUpload: LangFields;
        IncorrectPassword: LangFields;
        InvalidUsernameOrPassword: LangFields;
        InvalidImageError: LangFields;
        Job: LangFields;
        Language: LangFields;
        Loading: LangFields;
        Ready: LangFields;
        Locate: LangFields;
        LocationServicesOff: LangFields;
        LoggedOut: LangFields;
        Member: LangFields;
        Members: LangFields;
        Monday: LangFields;
        More: LangFields;
        XMore: LangFields;
        Name: LangFields;
        Never: LangFields;
        No: LangFields;
        None: LangFields;
        OK: LangFields;
        OffDuty: LangFields;
        OnTheWay: LangFields;
        OneDay: LangFields;
        OneMonth: LangFields;
        Open: LangFields;
        Operator: LangFields;
        OperatorsDesc: LangFields;
        Overnight: LangFields;
        Password: LangFields;
        Phone: LangFields;
        Place: LangFields;
        PleaseEnterAValidEmailAddress: LangFields;
        PostalCode: LangFields;
        RadiusInMeter: LangFields;
        ReallyCloseUnsaved: LangFields;
        ResetPasswordLink: LangFields;
        Role: LangFields;
        Saturday: LangFields;
        Save: LangFields;
        SaveAsJobTemplate: LangFields;
        SelectMemberToAutoAssignX: LangFields;
        SelectPlaceOrAddresOnMap: LangFields;
        SetAsDefault: LangFields;
        Settings: LangFields;
        GeneralSettings: LangFields;
        Signature: LangFields;
        State: LangFields;
        Stationary: LangFields;
        Status: LangFields;
        StatusChange: LangFields;
        Sunday: LangFields;
        Team: LangFields;
        Teams: LangFields;
        Thursday: LangFields;
        TimeZone: LangFields;
        Title: LangFields;
        EnterTitle: LangFields;
        To: LangFields;
        Total: LangFields;
        TrackingOff: LangFields;
        Tuesday: LangFields;
        TwoFactorAuth: LangFields;
        UnitDistance: LangFields;
        UnitKilometer: LangFields;
        UnitMiles: LangFields;
        UnitTime12: LangFields;
        UnitTime24: LangFields;
        UnitTime: LangFields;
        Url: LangFields;
        Username: LangFields;
        VerifyEmail: LangFields;
        VerifyPhone: LangFields;
        Wednesday: LangFields;
        WeekdayFr: LangFields;
        WeekdayMo: LangFields;
        WeekdaySa: LangFields;
        WeekdaySu: LangFields;
        WeekdayTh: LangFields;
        WeekdayTu: LangFields;
        WeekdayWe: LangFields;
        MonthJan: LangFields;
        MonthFeb: LangFields;
        MonthMar: LangFields;
        MonthApr: LangFields;
        MonthMay: LangFields;
        MonthJun: LangFields;
        MonthJul: LangFields;
        MonthAug: LangFields;
        MonthSep: LangFields;
        MonthOct: LangFields;
        MonthNov: LangFields;
        MonthDec: LangFields;
        WorkersDesc: LangFields;
        XMonths: LangFields;
        Xmin: LangFields;
        Yes: LangFields;
        YourBusiness: LangFields;
        YourWebsite: LangFields;
        Required: LangFields;
        Optional: LangFields;
        Create: LangFields;
        InfoAboutTemplates: LangFields;
        StartImporting: LangFields;
        Message: LangFields;
        MessageSentTo: LangFields;
        LinkedForms: LangFields;
        Created: LangFields;
        Assigned: LangFields;
        Saved: LangFields;
        Archived: LangFields;
        RecentlyUpdated: LangFields;
        AssignedDate: LangFields;
        NoAssignedDate: LangFields;
        SearchForTitleAddressOrder: LangFields;
        ReduceFilters: LangFields;
        LoadMore: LangFields;
        CreatedDate: LangFields;
        LastSaved: LangFields;
        SomethingWentWrong: LangFields;
        Done: LangFields;
        Description: LangFields;
        Every: LangFields;
        Days: LangFields;
        Clear: LangFields;
        AssignOnSpecificDays: LangFields;
        ChooseDateRange: LangFields;
        From: LangFields;
        Until: LangFields;
        End: LangFields;
        Or: LangFields;
        Exit: LangFields;
        Calculate: LangFields;
        Optimize: LangFields;
        Optimized: LangFields;
        SelectAll: LangFields;
        SuccessAssignTo: LangFields;
        RemoveFromBoard: LangFields;
        AssignOnceNow: LangFields;
        RecurringlyAssignTo: LangFields;
        Everybody: LangFields;
        ShowAll: LangFields;
        SelectedDay: LangFields;
        CreatedByX: LangFields;
        AssignedByX: LangFields;
        Started: LangFields;
        Rejected: LangFields;
        CheckIn: LangFields;
        CheckOut: LangFields;
        Success: LangFields;
        Issue: LangFields;
        Time: LangFields;
        EnterAddressOrGeocode: LangFields;
        TimeWindow: LangFields;
        TimeWindowDesc: LangFields;
        NotSet: LangFields;
        NoUploadedImages: LangFields;
        NoUploadedImagesDesc: LangFields;
        NoSignature: LangFields;
        AssignTo: LangFields;
        Assign: LangFields;
        Reassign: LangFields;
        AllFields: LangFields;
        Send: LangFields;
        AttachLocation: LangFields;
        Billing: LangFields;
        SignOut: LangFields;
        EditX: LangFields;
        ToggleMenu: LangFields;
        NavMenuAnalyze: LangFields;
        NavMenuManage: LangFields;
        NavMenuJobAndDispatch: LangFields;
        MemberRouting: LangFields;
        NavLocationGeneralDesc: LangFields;
        NavLocationPlaceDesc: LangFields;
        Next: LangFields;
        FindAddress: LangFields;
        AddressOrPlace: LangFields;
        NavLocationPlaceHelp: LangFields;
        NavDrawNewZoneDesc: LangFields;
        NavDrawEditZoneXDesc: LangFields;
        NavDrawQuickRouteDesc: LangFields;
        Color: LangFields;
        ProgressStatus: LangFields;
        Type: LangFields;
        Priority: LangFields;
        PriorityCaption: LangFields;
        AssignTeamsTo: LangFields;
        Apply: LangFields;
        GeolocatingStartedInBackground: LangFields;
        Radius: LangFields;
        Sort: LangFields;
        SearchForPlaceAddress: LangFields;
        Stop: LangFields;
        Stops: LangFields;
        SelectAssignee: LangFields;
        CreateJobRoute: LangFields;
        Places: LangFields;
        PlacesAndZones: LangFields;
        Dates: LangFields;
        FromXToX: LangFields;
        ByPlaces: LangFields;
        ByMembers: LangFields;
        Format: LangFields;
        Back: LangFields;
        Download: LangFields;
        Start: LangFields;
        Remove: LangFields;
        LocationServicesOn: LangFields;
        Mode: LangFields;
        BatterySaveOn: LangFields;
        BatterySaveOff: LangFields;
        TrackRecordingOff: LangFields;
        TrackRecordingOn: LangFields;
        GpsStationaryOff: LangFields;
        GpsStationaryOn: LangFields;
        LocateOnMap: LangFields;
        OpenProfile: LangFields;
        AddToBoard: LangFields;
        Board: LangFields;
        CurrentlyActive: LangFields;
        FieldsAreRequired: LangFields;
        XNotValidFields: LangFields;
        Select: LangFields;
        Unselect: LangFields;
        Integrations: LangFields;
        Timesheet: LangFields;
        Dashboard: LangFields;
        Zones: LangFields;
        Reports: LangFields;
        Timeline: LangFields;
        Dispatch: LangFields;
        AllJobs: LangFields;
        SearchForStop: LangFields;
        SearchMember: LangFields;
        NoEmail: LangFields;
        PasswordRequirements: LangFields;
        UsernameRequirements: LangFields;
        XNotified: LangFields;
        SuccessfullyLoadedTo: LangFields;
        LocateXOnMap: LangFields;
        NewJobForXonX: LangFields;
        EditRegion: LangFields;
        AssignToMembers: LangFields;
        AssignToTeams: LangFields;
        SearchForZone: LangFields;
        Questions: LangFields;
        RecentSubmissions: LangFields;
        IncorrectCode: LangFields;
        WelcomeBack: LangFields;
        LogIntoHellotracks: LangFields;
        RequestPasswordReset: LangFields;
        RequestPasswordResetLink: LangFields;
        UsernameOrEmail: LangFields;
        YourPassword: LangFields;
        Login: LangFields;
        ForgotPassword: LangFields;
        BackTo: LangFields;
        Signup: LangFields;
        DontHaveAnAccountYet: LangFields;
        CompanySettings: LangFields;
        MySettings: LangFields;
        LowPriority: LangFields;
        NormalPriority: LangFields;
        HighPriority: LangFields;
        LoggedIn: LangFields;
        ActiveSwitchOn: LangFields;
        ServiceSwithOff: LangFields;
        LocationPermissionGranted: LangFields;
        LocationPermissionDenied: LangFields;
        FormSubmissions: LangFields;
        Messages: LangFields;
        NewX: LangFields;
        Here: LangFields;
        Uploads: LangFields;
        UploadedImages: LangFields;
        JobsAssigned: LangFields;
        Continue: LangFields;
        SearchAddress: LangFields;
        SearchAndPickAddress: LangFields;
        AboutYourCompany: LangFields;
        Today: LangFields;
        DeviceNotResponding: LangFields;
        LastLocation: LangFields;
        HideOffDuty: LangFields;
        Jobs: LangFields;
        Tracks: LangFields;
        Invited: LangFields;
        FilterByTeam: LangFields;
        Recurring: LangFields;
        Daily: LangFields;
        Weekly: LangFields;
        Notifying: LangFields;
        ReallyDelete: LangFields;
        Active: LangFields;
        ActiveJobs: LangFields;
        Paused: LangFields;
        Close: LangFields;
        Cancelled: LangFields;
        EnterInitials: LangFields;
        Max6Chars: LangFields;
        OrCreateWithText: LangFields;
        StopBetweenTracks: LangFields;
        StopAtPlace: LangFields;
        StopAtJob: LangFields;
        Snooze: LangFields;
        SnoozeNotifications: LangFields;
        FrequentLiveNotificationsDesc: LangFields;
        EnterPasswordConfirm: LangFields;
        ConfirmToProceed: LangFields;
        EditTeamName: LangFields;
        TeamRoutingSettings: LangFields;
        DeleteTeam: LangFields;
        NewEntry: LangFields;
        Info: LangFields;
        Actions: LangFields;
        Location: LangFields;
        LocationHistory: LangFields;
        ProvidedByHTAndX: LangFields;
        JobNotFound: LangFields;
        ShowPrintableVersion: LangFields;
        GlobalSearchPlaceholder: LangFields;
        OpenDetails: LangFields;
        Distance: LangFields;
        Speed: LangFields;
        GPS: LangFields;
        CreateURLToShareThisTrack: LangFields;
        ReallyDeleteThisTrack: LangFields;
        Track: LangFields;
        TrackInfo: LangFields;
        DownloadAsGPXFile: LangFields;
        Share: LangFields;
        TrackNotFound: LangFields;
        OpenLink: LangFields;
        DownloadAppDescription: LangFields;
        ConfigureShortForm: LangFields;
        AllDay: LangFields;
        UserIsAlreadyRegistered: LangFields;
        MileageHistory: LangFields;
        MileageHistoryDesc: LangFields;
        Import: LangFields;
        DayRoutes: LangFields;
        NameIsRequired: LangFields;
        EmailIsRequired: LangFields;
        InvalidEmail: LangFields;
        Row: LangFields;
        SucceedImport: LangFields;
        PasswordRecovery: LangFields;
        NewPassword: LangFields;
        NewPasswordsDoNotMatch: LangFields;
        NoLocation: LangFields;
        NoPermission: LangFields;
        EnterEmail: LangFields;
        EnterUsername: LangFields;
        EnterPassword: LangFields;
        Unlinked: LangFields;
        ActionWillSaveChanges: LangFields;
        AddToTable: LangFields;
        RemoveFromTable: LangFields;
        ReallyDeleteSelectedTracks: LangFields;
        DeleteTracks: LangFields;
        Reason: LangFields;
        Customize: LangFields;
        Welcome: LangFields;
        LoginToContinue: LangFields;
        Unassigned: LangFields;
        Update: LangFields;
        WelcomeToHellotracksBusiness: LangFields;
        NowYouAreReadyToInstall: LangFields;
        GetApp: LangFields;
        GoToLiveDashboard: LangFields;
        MoreFilters: LangFields;
        NoName: LangFields;
        TryBusiness: LangFields;
        TryBusinessDesc: LangFields;
        ResendInvitation: LangFields;
        TooManyRows: LangFields;
        FieldIsRequired: LangFields;
        Submit: LangFields;
        CheckInternetConnection: LangFields;
        Duplicate: LangFields;
        Comment: LangFields;
        Purpose: LangFields;
        WaypointDensity: LangFields;
        CellResolution: LangFields;
        Cells: LangFields;
        HideAllPanels: LangFields;
        SingleSignOn: LangFields;
        ManualCheck: LangFields;
        EmailAsUsername: LangFields;
        SetIndividualUsername: LangFields;
        PasswordTooCommon: LangFields;
        LastXDays: LangFields;
        LastXMonths: LangFields;
        Custom: LangFields;
        PinMenu: LangFields;
        UnpinMenu: LangFields;
        Conditional: LangFields;
        Show: LangFields;
        ValueEqualsTo: LangFields;
        VisibleWhenEqualsTo: LangFields;
        PDFUpload: LangFields;
        OnlyAllowedForBusiness: LangFields;
        ConfirmPinToProceed: LangFields;
        NothingPinnedYet: LangFields;
        ViaX: LangFields;
        EnterName: LangFields;
        AcceptAll: LangFields;
    },
    Settings: {
        SettingsAttention: LangFields;
        TimezoneMismatchDesc: LangFields;
        TimezoneMismatchUseBrowser: LangFields;
        TimezoneMismatchKeepUser: LangFields;
        TimeFormatMismatchDesc: LangFields;
        TimeFormatMismatchUseBrowser: LangFields;
        TimeFormatMismatchKeepUser: LangFields;
        DistanceFormatMismatchDesc: LangFields;
        DistanceFormatMismatchUseBrowser: LangFields;
        DistanceFormatMismatchKeepUser: LangFields;
        LanguageMismatchDesc: LangFields;
        LanguageMismatchUseBrowser: LangFields;
        LanguageMismatchKeepUser: LangFields;
    },
    Menu: {
        LiveMapDesc: LangFields;
        TimelineDesc: LangFields;
        TracksDesc: LangFields;
        StopsDesc: LangFields;
        AllJobsDesc: LangFields;
        DispatchDesc: LangFields;
        FormsAndDataInputDesc: LangFields;
        HeatmapDesc: LangFields;
        UploadsDesc: LangFields;
        TeamDesc: LangFields;
        PlacesDesc: LangFields;
        ZonesDesc: LangFields;
        AlertsDesc: LangFields;
        DashboardDesc: LangFields;
        ReportsDesc: LangFields;
        TimesheetDesc: LangFields;
        SettingsDesc: LangFields;
        MessagesDesc: LangFields;
        IntegrationsDesc: LangFields;
        HelpCenterDesc: LangFields;
    },
    Help: {
        Help: LangFields;
        Title: LangFields;
        Url: LangFields;
        Beacon: LangFields;
    },
    Dispatch: {
        PinJob: LangFields;
        Unpin: LangFields;
        AllUnassigned: LangFields;
        Calculate: LangFields;
        DoneRearranging: LangFields;
        EditXJobs: LangFields;
        More: LangFields;
        NewJob: LangFields;
        NextDay: LangFields;
        OpenJobTooltip: LangFields;
        Optimize: LangFields;
        PreviousDay: LangFields;
        ReallyDeleteSelectedJobs: LangFields;
        UnassignedJobs: LangFields;
        UnassignedJobsTooltip: LangFields;
        Description: LangFields;
        ShowUnassignedJobs: LangFields;
        RecurringReasonsTitle: LangFields;
        RecurringReasonsDescription: LangFields;
        InputFormConfiguration: LangFields;
        InputFormDescription: LangFields;
        CustomizeInputForm: LangFields;
        JobCompletion: LangFields;
        EmailSubject: LangFields;
        EmailSubjectDescription: LangFields;
        EmailBody: LangFields;
        EmailBodyDescription: LangFields;
        RecurringReasonsExamples: LangFields;
        WorkerChangesStatusOfJobToX: LangFields;
        PinnedJobs: LangFields;
        ScheduledArrival: LangFields;
        ScheduledDeparture: LangFields;
    };
    EmptyStates: {
        AllJobsEmptyDescription: LangFields;
        AllJobsEmptyTitle: LangFields;
        JobTableEmptyDayRouteDescription: LangFields;
        JobTableEmptyDayRouteTitle: LangFields;
        JobTableEmptySequenceDescription: LangFields;
        JobTableEmptySequenceTitle: LangFields;
        PlacesEmptyDescription: LangFields;
        PlacesEmptyTitle: LangFields;
        StopsEmptyDescription: LangFields;
        StopsEmptyTitle: LangFields;
        TeamEmptyDescription: LangFields;
        TeamEmptyTitle: LangFields;
        TimesheetEmptyDescription: LangFields;
        TimesheetEmptyTitle: LangFields;
        TracksEmptyDescription: LangFields;
        TracksEmptyTitle: LangFields;
        UnassignedEmptyDescription: LangFields;
        UnassignedEmptyTitle: LangFields;
        ZonesEmptyDescription: LangFields;
        ZonesEmptyTitle: LangFields;
        MemberTracksEmptyTitle: LangFields;
        NoSelectedMemberEmptyTitle: LangFields;
    };
    ProfileModal: {
        AppLaunches: LangFields;
        AppLogin: LangFields;
        AppRunningInBackground: LangFields;
        AppRunningInForeground: LangFields;
        AppSuspended: LangFields;
        AppVersion: LangFields;
        BatteryLevel: LangFields;
        Conversation: LangFields;
        Device: LangFields;
        DeviceName: LangFields;
        EditProfile: LangFields;
        GPSLocationProvider: LangFields;
        General: LangFields;
        Integrations: LangFields;
        MostRecentLocation: LangFields;
        NetWorkLocationProvider: LangFields;
        OperatingSystem: LangFields;
        Profile: LangFields;
        Routing: LangFields;
        Tracking: LangFields;
        TrackingStatus: LangFields;
        Worker: LangFields;
        WorkingHours: LangFields;
        ResetPasswordLinkSent: LangFields;
        EmailVerificationCodeSentToX: LangFields;
        NoValidEmailAddressX: LangFields;
        EnterVerificationCode: LangFields;
        PhoneVerificationTextWithLink: LangFields;
        PhoneSuccessfullyVerified: LangFields;
        PhoneVerificationCodeSentToX: LangFields;
        NoValidPhoneNumberX: LangFields;
    };
    Job: {
        AddField: LangFields;
        Address: LangFields;
        Advanced: LangFields;
        CarryOverNextDayIfUnfinished: LangFields;
        RememberCarryOverSetting: LangFields;
        Contact: LangFields;
        ContactName: LangFields;
        ContactPhone: LangFields;
        Date: LangFields;
        DispatchJobCompletion: LangFields;
        DispatchJobCompletionSend: LangFields;
        DispatchJobCompletionEditInCompany: LangFields;
        EmailPreview: LangFields;
        Email: LangFields;
        EndTimeWindow: LangFields;
        EnterEmail: LangFields;
        FormsLinkedForm: LangFields;
        FormsLinkedForms: LangFields;
        High: LangFields;
        JobDetails: LangFields;
        JobInfo: LangFields;
        JobTypeDropoff: LangFields;
        JobTypePickup: LangFields;
        JobTypeWork: LangFields;
        Latitude: LangFields;
        LinkedZone: LangFields;
        LinkedZones: LangFields;
        Longitude: LangFields;
        Low: LangFields;
        NewEmail: LangFields;
        NewJob: LangFields;
        Normal: LangFields;
        Notes: LangFields;
        Dropoff: LangFields;
        Pickup: LangFields;
        NumberOfItemsToDropoff: LangFields;
        NumberOfItemsToPickup: LangFields;
        OnCompleting: LangFields;
        OnIssue: LangFields;
        OnSiteCaption: LangFields;
        RememberOnSiteSetting: LangFields;
        OnSiteTime: LangFields;
        OrderId: LangFields;
        OrderIdCaption: LangFields;
        PickupAndDelivery: LangFields;
        Priority: LangFields;
        ReallyDeleteThisJob: LangFields;
        ReallyDeleteThisTemplate: LangFields;
        SendNow: LangFields;
        SetAsDefault: LangFields;
        StaffInput: LangFields;
        StartTimeWindow: LangFields;
        StatusHistory: LangFields;
        Type: LangFields;
        UploadedImages: LangFields;
        minutes: LangFields;
        ManageJobTypeDesc: LangFields;
        JobType: LangFields;
        JobTypeDeleteDesc: LangFields;
        CarryOver: LangFields;
        NoCarryOver: LangFields;
        CarryOverQuestion: LangFields;
        ChooseCarryOver: LangFields;
        CarryOverIfNotDoneOrIssue: LangFields;
        JobFinishedIfMarkedAsEither: LangFields;
        CarryOverIfNotDone: LangFields;
        JobFinishedOnlyIfDone: LangFields;
        ImportJobsInfo: LangFields;
        ImportJobs: LangFields;
        ImportJobsTitle: LangFields;
        XJobs: LangFields;
        OneJob: LangFields;
        TodaysJobs: LangFields;
        FilterByTitleAddressOrder: LangFields;
        DropJob: LangFields;
        DropJobAndArrange: LangFields;
        DropJobsToUnassign: LangFields;
        ZoomToJobs: LangFields;
        LinkedZonesDesc: LangFields;
        LinkedFormsDesc: LangFields;
        ManualJobCheckin: LangFields;
        ManualJobCheckout: LangFields;
        EditJobTypes: LangFields;
        EnterJobTitle: LangFields;
        ArchivedJobsDesc: LangFields;
        AssignJobToRoute: LangFields;
        SearchJobAndAssign: LangFields;
        JobSettings: LangFields;
        NarrowTimeframe: LangFields;
        XSelectedJobs: LangFields;
        OneSelectedJob: LangFields;
        Requirements: LangFields;
        SkillsRequired: LangFields;
        SkillsRequiredDesc: LangFields;
        EquipmentNeeded: LangFields;
        EquipmentNeededDesc: LangFields;
        DateAndTime: LangFields;
        Expanded: LangFields;
        Compact: LangFields;
        Comments: LangFields;
        AddComment: LangFields;
        EnterComment: LangFields;
        Labels: LangFields;
        AddLabel: LangFields;
        EnterLabel: LangFields;
        CarryJobOver: LangFields;
    };
    JobStatuses: {
        Accepted: LangFields;
        Issue: LangFields;
        InProgress: LangFields;
        Scheduled: LangFields;
        Done: LangFields;
        Unassigned: LangFields;
        Rejected: LangFields;
    };
    WorkingHours: {
        CopyTime: LangFields;
        DefaultWorkingHoursNoTeam: LangFields;
        IndividualAvailability: LangFields;
        IndividualAvailabilityDesc: LangFields;
        IndividualRouteStartEndLocation: LangFields;
        TeamAvailability: LangFields;
        UseIndividualAvailabilityForX: LangFields;
    };
    Routing: {
        QuickRouteDesc: LangFields;
        Start: LangFields;
        End: LangFields;
        Route: LangFields;
        RouteCalculationIssue: LangFields;
        CalculatedRoute: LangFields;
        EndLocation: LangFields;
        IndividualRouteStartEndLocation: LangFields;
        MaxNumberOfItems: LangFields;
        OnSiteTime: LangFields;
        OptimizeRoute: LangFields;
        OptimizeDistance: LangFields;
        OptimizeDistanceTooltip: LangFields;
        OptimizeRouteDesc: LangFields;
        OptimizedRoute: LangFields;
        SolveRoute: LangFields;
        SolveRouteTooltip: LangFields;
        UnableToAssign: LangFields;
        WaitingTime: LangFields;
        Routing: LangFields;
        RoutingAutoAssignSettings: LangFields;
        RoutingDesc: LangFields;
        RoutingEnd: LangFields;
        RoutingMaxNumberOfJobs: LangFields;
        RoutingNothingSet: LangFields;
        RoutingNothingSetDesc: LangFields;
        RoutingOriginDestination: LangFields;
        RoutingPreferences: LangFields;
        RoutingPreferencesDesc: LangFields;
        RoutingRouteStartTime: LangFields;
        RoutingSettings: LangFields;
        RoutingStart: LangFields;
        RoutingStartEnd: LangFields;
        RoutingTeamUsed: LangFields;
        RoutingTitle: LangFields;
        RoutingTrafficModel: LangFields;
        RoutingTravelMode: LangFields;
        RoutingUseFixedHours: LangFields;
        RoutingUseWorkingHours: LangFields;
        SetRouteStartTime: LangFields;
        StartEndRouteLocation: LangFields;
        StartLocation: LangFields;
        TeamRouting: LangFields;
        Travelling: LangFields;
        UseStartEndPointForRounting: LangFields;
        XStartsRouteAt: LangFields;
        RouteCalculationFailed: LangFields;
        NewRoute: LangFields;
        SendRouteNotificationToX: LangFields;
        StartSimulation: LangFields;
        StartSimulationDesc: LangFields;
        RouteSimulationStarted: LangFields;
        RouteSimulationFinished: LangFields;
        StopSimulation: LangFields;
        SimulationOffDutyDesc: LangFields;
        UndoOptimize: LangFields;
        VehicleCapacityDesc: LangFields;
        LoadCapacityDesc: LangFields;
        TravelModeDesc: LangFields;
        SeeFullRoute: LangFields;
        Skills: LangFields;
        SkillsCaption: LangFields;
        NoRegionWarning: LangFields;
        ImpossibleRouteWarning: LangFields;
        UnableToAssignCaption: LangFields;
        MultiRouteOptimizationDescription: LangFields;
        MultiRouteAlignment: LangFields;
        RouteOptimizationTooManyJobs: LangFields;
    };
    Vehicles: {
        Vehicle: LangFields;
        VehicleCapacity: LangFields;
        Vehicles: LangFields;
    };
    TravelModes: {
        Bicycle: LangFields;
        Car: LangFields;
        Motorcycle: LangFields;
        Pedestrian: LangFields;
        Truck: LangFields;
        Van: LangFields;
    };
    TripLists: {
        TripPurposeAndComment: LangFields;
        TripPurposeList: LangFields;
        TripPurposeListCaption: LangFields;
        TripCommentList: LangFields;
        TripCommentListCaption: LangFields;
    };
    TeamManagement: {
        CreateNewInviteCode: LangFields;
        InviteCode: LangFields;
        InviteCodeExpired: LangFields;
        ManageInviteCodesDesc: LangFields;
        NewTeam: LangFields;
        NoActiveInviteCode: LangFields;
        TeamName: LangFields;
        ThisInviteCodesExpiresInXDays: LangFields;
        AddMemberToX: LangFields;
        ImportMembers: LangFields;
        DeleteTeamDesc: LangFields;
        RemoveFromTeam: LangFields;
        RemoveXFromY: LangFields;
        NoTeamAssigned: LangFields;
    };
    CustomFields: {
        CustomFields: LangFields;
        CustomFieldsDesc: LangFields;
        EnterValue: LangFields;
        FieldName: LangFields;
        AddCustomField: LangFields;
        DuplicatedFieldError: LangFields;
    };
    Company: {
        AllPlacesAreVisible: LangFields;
        AllZonesAreVisible: LangFields;
        CheckIns: LangFields;
        ZoneCheckIns: LangFields;
        CheckInArea: LangFields;
        CheckInDelayMessage: LangFields;
        CheckInBigRadius: LangFields;
        CheckInDelay: LangFields;
        CheckInShortRadius: LangFields;
        CheckinSettings: LangFields;
        Company: LangFields;
        CompanyAddress: LangFields;
        CompanyLocation: LangFields;
        CompanyName: LangFields;
        EnterCompanyName: LangFields;
        CompanySettings: LangFields;
        QuickSetup: LangFields;
        CompanySetupDesc: LangFields;
        CompanySetupTitle: LangFields;
        DeleteTracksOlderThan: LangFields;
        DisableTrackingIfUnavailable: LangFields;
        WorkersMaySendMessage: LangFields;
        DispatchersAsWorkers: LangFields;
        EnableAutoCheckin: LangFields;
        EnableZoneCheckin: LangFields;
        JobSettingsForWorkers: LangFields;
        MapSettingsForWorkers: LangFields;
        Notifications: LangFields;
        OnlyAssignedPlacesAreVisible: LangFields;
        OnlyAssignedZonesAreVisible: LangFields;
        OvernightJobs: LangFields;
        OvernightJobsDesc: LangFields;
        PermissionAdmin1: LangFields;
        PermissionAdmin2: LangFields;
        PermissionAdmin3: LangFields;
        PermissionForAdministrators: LangFields;
        PermissionOperator1: LangFields;
        PermissionOperatorsMayUseAssistant: LangFields;
        PermissionOperatorMayDeleteJobs: LangFields;
        PermissionOperatorMaySeeEverybody: LangFields;
        PermissionWorkersMayAccessTheJobCalendar: LangFields;
        PermissionWorkersMayReassignDateOfJob: LangFields;
        PermissionWorkersMayRelocateJob: LangFields;
        PermissionWorkersMaySeeOtherWorkers: LangFields;
        PermissionsForOperators: LangFields;
        PermissionsForWorkers: LangFields;
        PlacesOnMobileApp: LangFields;
        RejectingAJob: LangFields;
        RejectingAllowWithReassign: LangFields;
        RejectingAllowed: LangFields;
        RejectingDisallowed: LangFields;
        SendEmailOnNewMessage: LangFields;
        ShowLiveNotificationsAsPopup: LangFields;
        StatusLabelList: LangFields;
        StatusLabelSection: LangFields;
        TrackingSettingsForWorkers: LangFields;
        WorkersAllowedToTrackingOff: LangFields;
        WorkersCreatePlaces: LangFields;
        WorkersMayOptimizeRoute: LangFields;
        ZonesOnMobileApp: LangFields;
        NoCompanyAcc: LangFields;
        SelectThemeDesc: LangFields;
        CompanyPermissions: LangFields;
        TrackingAndLocation: LangFields;
        AdvancedMobileSettings: LangFields;
        Profile: LangFields;
        SelectZones: LangFields;
        RestrictLocationLabel: LangFields;
        RestrictLocationDesc: LangFields;
        JobData: LangFields;
        AutoArchiveJobsDesc: LangFields;
        AutoArchiveJobs: LangFields;
        AutoDeleteJobsDesc: LangFields;
        AutoDeleteJobs: LangFields;
    };
    Attachments: {
        AddAttachment: LangFields;
        Attachment: LangFields;
        Attachments: LangFields;
        AttachmentsDesc: LangFields;
        UploadImage: LangFields;
        SaveTemplate: LangFields;
    };
    ShortForm: {
        ShortFormDesc: LangFields;
        ShortFormTitle: LangFields;
        UseIndividualForm: LangFields;
        UseStandardForm: LangFields;
        SwitchBackStandardFormDesc: LangFields;
        StandardForm: LangFields;
        ConfigurationOfIndividualForm: LangFields;
    };
    Archive: {
        Archive: LangFields;
        ArchiveDescription: LangFields;
        ArchiveOrDelete: LangFields;
        ArchiveSelectedJob: LangFields;
        ArchiveXJobs: LangFields;
        Archived: LangFields;
        Restore: LangFields;
        RestoreDescription: LangFields;
    };
    Clone: {
        Clone: LangFields;
        CloneJob: LangFields;
        CloneOfX: LangFields;
        CloningCreatesADuplicateOfThisJob: LangFields;
    };
    JobTemplates: {
        JobTemplateDesc: LangFields;
        JobTemplateEnterName: LangFields;
        JobTemplateInfo: LangFields;
        JobTemplateName: LangFields;
        JobTemplates: LangFields;
        JobTemplatesDesc: LangFields;
        JobTemplateCreateDesc: LangFields;
        CreateNewJobFromTemplate: LangFields;
    };
    AreYouSure: {
        AreYouSureToDeleteTheEntireConversation: LangFields;
        AreYouSureToRelocateX: LangFields;
        AreYouSureToRemoveThisImage: LangFields;
        AreYouSureToRemoveX: LangFields;
        AreYouSureToRemoveXAsContact: LangFields;
        AreYouSureToRemoveXFromYourInnerNetwork: LangFields;
        AreYouSureYouWantToDeactivateAccountX: LangFields;
    };
    Members: {
        AllLocationDataWillBeRemovedForThisUser: LangFields;
        DeleteMember: LangFields;
        DeleteMemberConfirmPassword: LangFields;
        SelectMember: LangFields;
        SelectMembers: LangFields;
        ImportMembers: LangFields;
        ImportMembersInfo: LangFields;
        ImportMembersTitle: LangFields;
        NewMember: LangFields;
        AllMembers: LangFields;
    };
    Sequences: {
        Sequences: LangFields;
        SequencesDesc: LangFields;
        NewSequence: LangFields;
        SaveSequenceAs: LangFields;
        SequenceName: LangFields;
        LoadSequence: LangFields;
        LoadSequenceForX: LangFields;
        NewEmptySequence: LangFields;
        SelectOwnersForX: LangFields;
        SelectOwnersDesc: LangFields;
        AssignSequenceX: LangFields;
        SavedSequences: LangFields;
        SaveAsSequence: LangFields;
        DeleteSequenceQuestion: LangFields;
        DeleteSequence: LangFields;
        RenameSequence: LangFields;
        Restricted: LangFields;
        VisibleForAllOperators: LangFields;
    };
    Billing: {
        CardSetup: LangFields;
        Payment: LangFields;
        Setup: LangFields;
        SetupPaymentMethodNow: LangFields;
        YouWillBeChargedFirstInvoiceDue: LangFields;
        PaymentMethod: LangFields;
        Licenses: LangFields;
        TrialingUntil: LangFields;
        Active: LangFields;
        PastDue: LangFields;
        CanceledAtX: LangFields;
        Unpaid: LangFields;
        TeamMember: LangFields;
        DelinquentMessage: LangFields;
        UpcomingInvoice: LangFields;
        InvoiceLineItems: LangFields;
        Period: LangFields;
        ApplicationStatus: LangFields;
        ActiveSinceX: LangFields;
        PaymentMethodMissing: LangFields;
        TrialExpiredDesc: LangFields;
        Inactive: LangFields;
        ActivateAccountNow: LangFields;
        Questions: LangFields;
        Deactivate: LangFields;
        DeactivateCompanyAccount: LangFields;
        DeactivateDesc: LangFields;
        DeactivateCompanyAccountReasonTitle: LangFields;
        DeactivateCompanyAccountReasonDesc: LangFields;
        CompanyAccountDeactivatedConfirmation: LangFields;
        ReasonNoTime: LangFields;
        ReasonTechnical: LangFields;
        ReasonProvider: LangFields;
        ReasonPrice: LangFields;
        ReasonFeature: LangFields;
        ReasonDeployment: LangFields;
        ReasonNotWorking: LangFields;
        ReasonIntegration: LangFields;
        ReasonDifficult: LangFields;
        ReasonOther: LangFields;
        EnterAComment: LangFields;
        AccountIsActivated: LangFields;
        Reactivate: LangFields;
        ReactivateDesc: LangFields;
        GoToHomepage: LangFields;
        BillingStatus: LangFields;
        Gain5Min: LangFields;
        Gain5MinTooltip: LangFields;
        AddPaymentMethod: LangFields;
        Expires: LangFields;
        Amount: LangFields;
        PaymentAttempt: LangFields;
        ShowPastInvoices: LangFields;
        PastInvoices: LangFields;
        Default: LangFields;
        ReminderMessage: LangFields;
        YouHaveXLeftUntilTrialPeriodEnds: LangFields;
    };
    Alerts: {
        Alert: LangFields;
        AlertActiveOn: LangFields;
        AlertAndAddContition: LangFields;
        AlertAnywhere: LangFields;
        AlertAtPlace: LangFields;
        AlertAtX: LangFields;
        AlertBetween: LangFields;
        AlertChecksIn: LangFields;
        AlertChecksInOrOut: LangFields;
        AlertChecksOut: LangFields;
        AlertDelayCheckIn: LangFields;
        AlertDelayCheckOut: LangFields;
        AlertDelayOnJob: LangFields;
        AlertDeleteQuest: LangFields;
        AlertDoesntCheckIn: LangFields;
        AlertDoesntCheckInOrOut: LangFields;
        AlertDoesntCheckOut: LangFields;
        AlertEmailInputPrompt: LangFields;
        AlertEnteringX: LangFields;
        AlertEntersZone: LangFields;
        AlertIfCondition: LangFields;
        AlertJobsUpdatedMessage: LangFields;
        AlertJobsUpdatedTitle: LangFields;
        AlertLeavesZone: LangFields;
        AlertLeavingX: LangFields;
        AlertLoggedIn: LangFields;
        AlertLoggedOut: LangFields;
        AlertLongIdleTime: LangFields;
        AlertNewConfig: LangFields;
        AlertNoConnectionToDevice: LangFields;
        AlertOrMemberOf: LangFields;
        AlertPhoneInputPrompt: LangFields;
        AlertScheduleDaysOfWeek: LangFields;
        AlertSendTo: LangFields;
        AlertTrackingTurnedOff: LangFields;
        AlertTrackingTurnedOn: LangFields;
        AlertUnvailableWithFirstAction: LangFields;
        AlertWeekdays: LangFields;
        AlertWithin: LangFields;
        Alerts: LangFields;
        AlertsConfiguration: LangFields;
        AlertsConfigurations: LangFields;
        AlertsDesc: LangFields;
        ShowRecentAlerts: LangFields;
        OpenAlertConfig: LangFields;
        RecentAlerts: LangFields;
        ConfigureAlerts: LangFields;
        StartOfWorkingHours: LangFields;
        EndOfWorkingHours: LangFields;
        ClockInSwitch: LangFields;
        ClockOutService: LangFields;
        CheckInAtX: LangFields;
        CheckOutAtX: LangFields;
        ManualCheckInAtX: LangFields;
        ManualCheckOutAtX: LangFields;
        ArrivedAtJobX: LangFields;
        LeftJobX: LangFields;
        CompletedMarkedJobX: LangFields;
        IssueMarkedJobX: LangFields;
    },
    Reports: {
        JobReport: LangFields;
        DayRouteReport: LangFields;
        PlacesReport: LangFields;
        AlertsReport: LangFields;
        CheckinsReport: LangFields;
        MembersReport: LangFields;
        MileageReport: LangFields;
        PlaceOnSiteReport: LangFields;
        MemberOnSiteReport: LangFields;
        JobReportDesc: LangFields;
        DayRouteReportDesc: LangFields;
        PlacesReportDesc: LangFields;
        AlertsReportDesc: LangFields;
        CheckinsReportDesc: LangFields;
        MembersReportDesc: LangFields;
        MileageReportDesc: LangFields;
        PlaceOnSiteReportDesc: LangFields;
        MemberOnSiteReportDesc: LangFields;
        NewReport: LangFields;
        EnterNameForThisReport: LangFields;
        TimesheetReport: LangFields;
        TimesheetReportDesc: LangFields;
        CreateNewReports: LangFields;
        RecurringReports: LangFields;
        RecentReports: LangFields;
        SelectTimeFrame: LangFields;
        SelectDayToCreate: LangFields;
        SelectDayToCreateFirstReport: LangFields;
        NotifyPeopleOnCreation: LangFields;
        SetupRecurringReport: LangFields;
        ExportFormSubmissions: LangFields;
        ExportFormSubmissionsDesc: LangFields;
        GpxReport: LangFields;
        GpxReportDesc: LangFields;
        StopsReport: LangFields;
        StopsReportDesc: LangFields;
        ActivitiesReport: LangFields;
        ActivitiesReportDesc: LangFields;
        TimelineReport: LangFields;
        TimelineReportDesc: LangFields;
        Worksheet: LangFields;
        WorksheetDesc: LangFields;
        StatisticsReport: LangFields;
        StatisticsReportDesc: LangFields;
        Columns: LangFields;
        DailyReport: LangFields;
        WeeklyReport: LangFields;
        EveryTwoWeeksReport: LangFields;
        StartDate: LangFields;
        AddSheetForVisitedPlaces: LangFields;
    },
    Dashboard: {
        Mileage: LangFields;
        JobPerformance: LangFields;
        SpeedDiagram: LangFields;
    };
    Errors: {
        FileNotAccepted: LangFields;
        IncorrectVerificationCode: LangFields;
    };
    Places: {
        PlacesAndZonesDescription: LangFields;
        ImportPlaces: LangFields;
        ImportPlacesInfo: LangFields;
        ImportPlacesTitle: LangFields;
        NewPlace: LangFields;
        SelectPlace: LangFields;
        InvalidRadiusDesc: LangFields;
        DeletePlaces: LangFields;
        DeleteXPlaces: LangFields;
        ConfirmDeletePlacesDesc: LangFields;
        BulkEditXPlaces: LangFields;
        SelectPlaces: LangFields;
        EnterName: LangFields;
        PlacesUpdate: LangFields;
        EditPlace: LangFields;
        PlaceImageUpdate: LangFields;
    };
    Zones: {
        ImportZones: LangFields;
        ImportZonesInfo: LangFields;
        ImportZonesTitle: LangFields;
        NewZone: LangFields;
        ZoneUpdated: LangFields;
        PleaseEnterZoneName: LangFields;
        EnterNewZoneName: LangFields;
        DeleteZones: LangFields;
        DeleteXZones: LangFields;
        ConfirmDeleteZonesDesc: LangFields;
        EditXZones: LangFields;
    };
    Integrations: {
        Integrations: LangFields;
        IntegrationsApiCredentials: LangFields;
        IntegrationsApiUseAdminRole: LangFields;
        IntegrationsApiHtml: LangFields;
        IntegrationsApiShowCredentials: LangFields;
        IntegrationsAskAdmin: LangFields;
        IntegrationsDescHtml: LangFields;
        IntegrationsEmailBody: LangFields;
        IntegrationsEmailHtml: LangFields;
        IntegrationsEmailSubject: LangFields;
        IntegrationsEnableApi: LangFields;
        IntegrationsExcelCsv: LangFields;
        IntegrationsExcelCsvHtml: LangFields;
        IntegrationsFeedbackPlaceholder: LangFields;
        IntegrationsGoogleCalendarHtml: LangFields;
        IntegrationsSlackHtml: LangFields;
        IntegrationsOkta: LangFields;
    };
    Slack: {
        Slack: LangFields;
        AddToSlack: LangFields;
        SlackConnectedDescHtml: LangFields;
        SlackDesc: LangFields;
        SlackDisconnect: LangFields;
        SlackDisconnectQuestion: LangFields;
        SlackWorkspaceNotConnected: LangFields;
    };
    GoogleCalendar: {
        GoogleCalendar: LangFields;
        GoogleCalendarAlreadyConnected: LangFields;
        GoogleCalendarClickHereToConnect: LangFields;
        GoogleCalendarClickHereToConnectHtml: LangFields;
        GoogleCalendarConnected: LangFields;
        GoogleCalendarDesc: LangFields;
        GoogleCalendarDisconnect: LangFields;
        GoogleCalendarDisconnectQuestion: LangFields;
        GoogleCalendarNowConnected: LangFields;
        GoogleCalendarSendLink: LangFields;
        GoogleCalendarXNeedsVerified: LangFields;
    };
    Forms: {
        FormsDesc: LangFields;
        SubmittedBy: LangFields;
        FormAddCheckbox: LangFields;
        FormAddDecimal: LangFields;
        FormAddNumber: LangFields;
        FormAddText: LangFields;
        Forms: LangFields;
        FormsAddOption: LangFields;
        FormsAndDataInput: LangFields;
        FormsAndDataInputDesc: LangFields;
        FormsCheckbox: LangFields;
        FormsDecimal: LangFields;
        FormsDecimalInput: LangFields;
        FormsDeleteQuestion: LangFields;
        FormsDropdown: LangFields;
        FormsFile: LangFields;
        FormsFormTitle: LangFields;
        FormsFormDescription: LangFields;
        FormsImage: LangFields;
        FormsLabel: LangFields;
        FormsLinkedForm: LangFields;
        FormsLinkedForms: LangFields;
        FormsMultilineInput: LangFields;
        FormsNumber: LangFields;
        FormsNumberInput: LangFields;
        FormsOption: LangFields;
        FormsParagraph: LangFields;
        FormsRadio: LangFields;
        FormsRequired: LangFields;
        FormsSection: LangFields;
        FormsSignature: LangFields;
        FormsSingleLineInput: LangFields;
        FormsSubmissions: LangFields;
        FormsTemplates: LangFields;
        FormsText: LangFields;
        FormsUntitledForm: LangFields;
        FormNewSection: LangFields;
        FormNew: LangFields;
        FormsDeleteForm: LangFields;
        FormSubmissionSavedLocally: LangFields;
        FormSubmissionSucceed: LangFields;
        BackToForms: LangFields;
        NewFormSubmission: LangFields;
        SelectForm: LangFields;
    },
    Login: {
        UserUnknown: LangFields;
        IncorrectPassword: LangFields;
        InvalidUsernameOrPassword: LangFields;
        NoPermissionEnterWeb: LangFields;
        Throttling: LangFields;
        Busy: LangFields;
        SomethingWentWrong: LangFields;
        TwoFactorAuthentication: LangFields;
        TwoFactorAuthenticationEnabled: LangFields;
        TwoFactorAuthenticationDisabled: LangFields;
        TwoFactorAuthenticationDesc: LangFields;
        TwoFactorAuthenticationVerify: LangFields;
        ForgotPasswordLinkToX: LangFields;
        ForgotPasswordTryAgain: LangFields;
        UserDoesNotExist: LangFields;
        UserNotSetUpForSSO: LangFields;
    },
    Timeline: {
        ShowFullDayRoute: LangFields;
        ZoomToTrack: LangFields;
        ShowWaypoints: LangFields;
        AllEvents: LangFields;
        OnlyTracks: LangFields;
        ShowAllXEvents: LangFields;
    },
    Spreadsheet: {
        AddRow: LangFields;
        AddCustomFieldColumn: LangFields;
        AddAdvancedField: LangFields;
        ChooseCustomFieldName: LangFields;
        CustomFieldName: LangFields;
    },
    Messages: {
        OpenChat: LangFields;
        TypeMessageHere: LangFields;
        MessageSent: LangFields;
        SendMessage: LangFields;
        SendMessageToX: LangFields;
        SendMessageTo: LangFields;
        SendingMessageTo: LangFields;
        EnterMessage: LangFields;
        NewMessage: LangFields;
        MessageFromX: LangFields;
        DeleteMessage: LangFields;
    },
    Stats: {
        Statistics: LangFields;
        TotalMembers: LangFields;
        MemberStats: LangFields;
        TotalAssignedJobs: LangFields;
        MaxSpeed: LangFields;
        AvgSpeed: LangFields;
        OnTime: LangFields;
        Delays: LangFields;
        Engagement: LangFields;
        Table: LangFields;
        Engaged: LangFields;
        Passive: LangFields;
        AccumulatedTimeAtAndBetweenJobs: LangFields
        AccumulatedTimeBetweenJobs: LangFields;
        AccumulatedTimeAtJobSites: LangFields;
        AvgTimeAtAndBetweenJobs: LangFields;
        AvgTimeBetweenJobs: LangFields;
        AvgTimeAtJobSites: LangFields;
        TimeAtJob: LangFields;
        TimeAtPlace: LangFields;
        TimeTravelling: LangFields;
        TravelDistances: LangFields;
        TravelTime: LangFields;
        Pause: LangFields;
        TravelAndPauseTime: LangFields;
        AvgOnsiteTimeByMember: LangFields;
        AvgOnsiteTimeByDate: LangFields;
        AccumulatedOnsiteTimeByMember: LangFields;
        AccumulatedOnsiteTimeByDate: LangFields;
        JobPerformanceByDate: LangFields;
        JobPerformanceByMember: LangFields;
        TotalJobOnsite: LangFields;
        AvgJobOnsite: LangFields;
        TotalPlaceOnsite: LangFields;
        AvgPlaceOnsite: LangFields;
        TotalPause: LangFields;
        AvgPause: LangFields;
        AvgTravelTime: LangFields;
        TotalTravelTime: LangFields;
        SuccessRate: LangFields;
        IssueRate: LangFields;
        EngagementWithJobw: LangFields;
        TotalTimeAtJobs: LangFields;
        TotalDistanceTravelled: LangFields;
        TotalNumberOfTracks: LangFields;
        JobStats: LangFields;
        JobTypes: LangFields;
        JobTeams: LangFields;
    },
    Onboard: {
        Header: LangFields;
        CloseDescription: LangFields;
        DontShowXAgain: LangFields;
        JobsTitle: LangFields;
        ImportJobsTitle: LangFields;
        PlacesTitle: LangFields;
        ImportPlacesTitle: LangFields;
        CreateCompany: LangFields;
        Invite: LangFields;
        Permissions: LangFields;
        Place: LangFields;
        Route: LangFields;
        Signup: LangFields;
        AppLogin: LangFields;
        Checkin: LangFields;
        Jobs: LangFields;
        TeamTitle: LangFields;
        ImportMembersTitle: LangFields;
        TimelineTitle: LangFields;
        StopsTitle: LangFields;
        AppLoginTitle: LangFields;
        CompanySettingsTitle: LangFields;
        ChekinNotificationsTitle: LangFields;
        DelayTitle: LangFields;
        AreaTitle: LangFields;
        TrackingDescription: LangFields;
        TrackingWorkingHoursDescription: LangFields;
        CloseHeader: LangFields;
        JobsDescription: LangFields;
        OpenListOfAllJobs: LangFields;
        ImportJobsDescription: LangFields;
        AddJobTitle: LangFields;
        AddJobDescription: LangFields;
        PlacesDescription: LangFields;
        ImportPlacesDescription: LangFields;
        AddPlaceTitle: LangFields;
        AddPlaceDescription: LangFields;
        TrackingSettings: LangFields;
        TeamDescription: LangFields;
        ImportMembersDescription: LangFields;
        AddMemberTitle: LangFields;
        AddMemberDescription: LangFields;
        TimelineDescription: LangFields;
        StopsDescription: LangFields;
        AppLoginDescription: LangFields;
        CompanySettingsDescription: LangFields;
        AdministratorsTitle: LangFields;
        AdministratorsDescription: LangFields;
        OperatorsTitle: LangFields;
        OperatorsDescription: LangFields;
        WorkersTitle: LangFields;
        WorkersDescription: LangFields;
        ChekinNotificationsDescription: LangFields;
        AutoCheckinsTitle: LangFields;
        AutoCheckinsDescription: LangFields;
        DelayDescription: LangFields;
        AreaDescription: LangFields;
        TrackingSettingsTitle: LangFields;
        TrackingSettingsDescription: LangFields;
        TrackingTitle: LangFields;
        TrackingWorkingHoursTitle: LangFields;
        ManageTracksTitle: LangFields;
        ManageTracksDescription: LangFields;
        OnboardFinished: LangFields;
        CompanyPermissionsTitle: LangFields;
        CompanyPermissionsDescription: LangFields;
    },
    Signup: {
        Title: LangFields;
        Paragraph: LangFields;
        ClientsDescription: LangFields;
        HaveAccount: LangFields;
        SignIn: LangFields;
        Continue: LangFields;
        NameTitle: LangFields;
        Description: LangFields;
        NamePlaceholder: LangFields;
        EmailTitle: LangFields;
        EmailPlaceholder: LangFields;
        Back: LangFields;
        SkipAll: LangFields;
        PasswordTitle: LangFields;
        CreateAccount: LangFields;
        Terms: LangFields;
        WelcomeTitle: LangFields;
        WelcomeDescription1: LangFields;
        WelcomeDescription2: LangFields;
        EstimatedUsersTitle: LangFields;
        IndustryTitle: LangFields;
        IndustryRepairMaintenance: LangFields;
        IndustryMobilityAndTransportation: LangFields;
        IndustryPublicSector: LangFields;
        IndustryLogisticsDistribution: LangFields;
        Other: LangFields;
        RoleTitle: LangFields;
        RoleOperationsmanagerDirector: LangFields;
        RoleFieldStaffManagerDispatcher: LangFields;
        RoleDeveloperItConsultant: LangFields;
        RoleHr: LangFields;
        RoleEntrepreneurOwner: LangFields;
        FindusTitle: LangFields;
        FindusGoogleSearch: LangFields;
        FindusFacebook: LangFields;
        FindusLinkedin: LangFields;
        FindusSoftwareMarketPlace: LangFields;
        FindusReferral: LangFields;
        GoalTitle: LangFields;
        GoalLiveLocation: LangFields;
        GoalCheckins: LangFields;
        GoalLocationHistory: LangFields;
        GoalDispatch: LangFields;
        GoalData: LangFields;
        DoneTitle: LangFields;
        DoneDescription: LangFields;
        ToDashboard: LangFields;
        EmailAlreadyUsed: LangFields;
        EmailInvalid: LangFields;
        PasswordInvalid: LangFields;
        PasswordLength: LangFields;
        SomethingWentWrong: LangFields;
        PasswordStrongPassword: LangFields;
        PasswordNoWhitespace: LangFields;
        Password8Chars: LangFields;
        PasswordLowerCase: LangFields;
        PasswordUpperCase: LangFields;
        PasswordNumber: LangFields;
    },
    Assistant: {
        Tooltip: LangFields;
        TooltipTip: LangFields;
        HangTight: LangFields;
        XCreated: LangFields;
        ZoneCreated: LangFields;
        JobCreated: LangFields;
        JobCreatedForX: LangFields;
        History: LangFields;
        InputPlaceholder: LangFields; // input @ to mention members, use # for places
        Title: LangFields;
        DisabledWarning: LangFields;
        DisabledDescription: LangFields;
        Enable: LangFields;
        DispatchAssistant: LangFields;
        HelpAssistant: LangFields;
    },
    Audio: {
        StartRecordingAudioTranscript: LangFields;
        StopRecording: LangFields;
        GrantMicPermission: LangFields;
    },
    ChatAi: {
        Title: LangFields;
        Skip: LangFields;
        WhatsYourName: LangFields;
        BriefDescription: LangFields;
        AskAnything: LangFields;
        PricingCancellation: LangFields;
        ScheduleDemo: LangFields;
        HardwareNeeded: LangFields;
        NewInformation: LangFields;
        BenefitsCompared: LangFields;
        ConcreteExamples: LangFields;
        ContactHuman: LangFields;
        TrialPeriod: LangFields;
        GetStarted: LangFields;
        MyGoalWithHellotracksIs: LangFields;
        MyIndustryIs: LangFields;
        BackToWebsite: LangFields;
        AmaTitle: LangFields;
        ContactUs: LangFields;
    },
    Timesheet: {
        NewEntry: LangFields;
    },
    Activities: {
        ActivityLog: LangFields;
        ActivityLogDesc: LangFields;
        Activity: LangFields;
        SearchActivity: LangFields;
    },
    Clustering: {
        Clustering: LangFields;
        Info: LangFields;
        ClusteringXSelectedJobs: LangFields;
        SelectUpToXJobsForClustering: LangFields;
        CreatingXClusters: LangFields;
        XClusters: LangFields;
        OneCluster: LangFields;
    },
    Plan: {
        YourPlan: LangFields;
        SelectPlan: LangFields;
        YourCurrentPlanIsX: LangFields;
        LegacyPlanDescription: LangFields;
        SeeAllFeatures: LangFields;
        SelectCurrency: LangFields;
        Monthly: LangFields;
        Yearly: LangFields;
        Aprox: LangFields;
        SwitchPlanPrompt: LangFields;
        ChoosePlanInBilling: LangFields;
        ClickHereToLearnMore: LangFields;
    },
    LocationFeatures: {
        RealTimeLocation: LangFields;
        UnlimitedLocationHistory: LangFields;
        Geofencing: LangFields;
        OnsiteTimeRecording: LangFields;
        Zones: LangFields;
        TimeTracker: LangFields;
        Alerts: LangFields;
        Reports: LangFields;
    },
    DispatchFeatures: {
        HellotracksLocation: LangFields;
        JobScheduling: LangFields;
        AutoDispatch: LangFields;
        RouteOptimization: LangFields;
        OnsiteTimeRecordingJobs: LangFields;
        ClientNotifications: LangFields;
        SignatureCapture: LangFields;
        ImageUploads: LangFields;
        Forms: LangFields;
        RecurringReports: LangFields;
        ReportsJobPerformance: LangFields;
        Integrations: LangFields;
        APIAccess: LangFields;
        TwoFactorAuthentication: LangFields;
    },
    DeviceDiagnostics: {
        DeviceDiagnostics: LangFields;
        Description: LangFields;
        Trigger: LangFields;
        DeviceDiagnosticsRequest: LangFields;
        Requested: LangFields;
        UnavailableNotLoggedIn: LangFields;
        UnavailableNeedsUpdate: LangFields;
        DeviceDiagnosticsUploaded: LangFields;
        StartStopTrackingEvents: LangFields;
        TrackingEventsTooltip: LangFields;
        WakeUpsRunningEvents: LangFields;
        WakeUpsRunningEventsTooltip: LangFields;
        AppStateEvents: LangFields;
        AppStateEventsTooltip: LangFields;
        BackgroundTickEvents: LangFields;
        BackgroundTickEventsTooltip: LangFields;
        LocationUploadEvents: LangFields;
        LocationUploadEventsTooltip: LangFields;
        ConnectivityEvents: LangFields;
        ConnectivityEventsTooltip: LangFields;
        WebsocketConnectivity: LangFields;
        WebsocketConnectivityTooltip: LangFields;
        WebsocketSendEvents: LangFields;
        WebsocketSendEventsTooltip: LangFields;
        WebsocketReceiveEvents: LangFields;
        WebsocketReceiveEventsTooltip: LangFields;
        CheckInEvents: LangFields;
        CheckInEventsTooltip: LangFields;
    },
    Reorder: {
        RouteReorderOrderNumber: LangFields;
        RouteReorderPriority: LangFields;
        ConfirmationReorder: LangFields;
        ReorderingState: LangFields;
        ReorderAction: LangFields;
    },
    Sso: {
        ConfigurationHiddenDesc: LangFields;
        ChangeSsoConfiguration: LangFields;
        SetupSsoConfiguration: LangFields;
        EnterTenantID: LangFields;
        EnterClientID: LangFields;
        EnterClientSecret: LangFields;
        EnterRedirectUrl: LangFields;
        Notes: LangFields;
        NotesContent: LangFields;
        LinkToLoginPage: LangFields;
        SelectIdentityProvider: LangFields;
    },
    Approval: {
        Approval: LangFields;
        Notified: LangFields;
        Pending: LangFields;
        Approved: LangFields;
        Disapproved: LangFields;
        Approve: LangFields;
        Disapprove: LangFields;
        ApprovedBy: LangFields;
    },
    Trips: {
        OnTheWayToX: LangFields;
        Started: LangFields;
        OriginalETA: LangFields;
        CurrentETA: LangFields;
    }
}

export const TRANSLATIONS: IStrings = {
    Map: {
        Clean: ['Clean', 'Limpio', 'Clean'],
        Darkmap: ['Dark map', 'Oscuro', 'Dunkel'],
        Filter: ['Filter', 'Filtrar', 'Filter'],
        FilterActiveOnly: ['Active', 'Activos', 'Aktiv'],
        FilterAll: ['All', 'Todos', 'Alle'],
        Heatmap: ['Heat map', 'Mapa de calor', 'Heatmap'],
        History: ['History', 'Histórico', 'History'],
        IconSize: ['Icon size', 'Tamaño del marcador', 'Größe'],
        Layers: ['Layers', 'Capas', 'Layers'],
        Live: ['Live', 'En vivo', 'Live'],
        LiveMap: ['Live', 'En vivo', 'Live'],
        Logout: ['Logout', 'Cerrar sesión', 'Ausloggen'],
        MapType: ['Map type', 'Tipo de mapa', 'Kartentyp'],
        Places: ['Places', 'Lugares', 'Plätze'],
        Roadmap: ['Road map', 'Mapa vial', 'Straßenkarte'],
        Lightmap: ['Light map', 'Claro', 'Hell'],
        Satellite: ['Satellite', 'Satélite', 'Satellit'],
        Traffic: ['Traffic', 'Tráfico', 'Verkehr'],
        Zones: ['Zones', 'Zonas', 'Zonen'],
        Jobs: ['Jobs', 'Tareas', 'Aufträge'],
        DayRoutes: ["Routes", "Rutas", "Routen"],
        PointTool: ["Point tool", "Herramienta de punto", "Punktwerkzeug"],
        PointToolDesc: [
            "Click on map to create place, job or see address",
            "Haz clic en el mapa para crear un lugar, un trabajo o ver la dirección.",
            "Klicke auf die Karte, um einen Ort zu erstellen, einen Job zu finden oder eine Adresse anzusehen.",
        ],
        SelectTool: [
            "Select tool",
            "Herramienta de selección",
            "Auswahlwerkzeug",
        ],
        SelectToolDesc: [
            "Click and drag to select jobs.",
            "Haz clic y arrastra para seleccionar tareas.",
            "Klicke und ziehe, um Jobs auszuwählen.",
        ],
        Waypoints: [
            "Waypoints",
            "Puntos de ruta",
            "Wegpunkte",
        ],
        TranslucentVsSolidBackground: [
            "Translucent or solid background",
            "Fondo translúcido o sólido",
            "Durchscheinender oder solider Hintergrund",
        ]
    },
    General: {
        Range: ['Range', 'Rango', "Bereich"],
        XDays: ["{0} days", "{0} días", "{0} Tage"],
        XHours: ["{0} hours", "{0} horas", "{0} Stunden"],
        XMinutes: ["{0} minutes", "{0} minutos", "{0} Minuten"],
        Accept: ['Accept', 'Aceptar', 'Akzeptieren'],
        Action: ['Action', 'Acción', 'Aktion',],
        ActionCannotBeUndone: [
            'This action <strong>cannot be undone</strong>, and will permanently delete data.',
            'Esta acción <strong>no se puede deshacer</strong> y eliminará datos permanentemente.',
            'Diese Aktion kann <strong>nicht rückgängig gemacht</strong> werden und löscht Daten dauerhaft.',
        ],
        Add: ['Add', 'Añadir', 'Hinzufügen'],
        Address: ['Address', 'Dirección', 'Adresse'],
        Admin: ['Administrator', 'Administrador', 'Administrator'],
        AdministratorsDesc: [
            'Administrators have same permissions as Operators. Additionally they can add, manage and remove members. ',
            'Administratoren haben alle Rechte von Disponenten. Zusätzlich können sie Mitglieder erstellen und verwalten.',
            'Los Administradores tienen los mismos permisos que los Operadores. Además pueden añadir, administrar y eliminar miembros.',
        ],
        AssignedTo: ['Assigned to', 'Asignado a', 'Zugewiesen zu'],
        At: ['at', 'en', 'in'],
        Between: ['between', 'entre', 'zwischen'],
        SaveTemplate: ['Save template', 'Guardar Plantilla', 'Template speichern'],
        SaveCustomFieldsForJobsDesc: [
            "Save custom fields as a template to apply automatically to new jobs",
            "Guardar campos personalizados como plantilla para aplicar automáticamente en nuevas tareas",
            "Benutzerdefinierte Felder als Vorlage speichern, um sie automatisch auf zukünftige Aufträge anzuwenden"
        ],
        SaveCustomFieldsForPlacesDesc: [
            "Save custom fields as a template to apply automatically to future places",
            "Guardar campos personalizados como plantilla para aplicar automáticamente en trabajos futuros",
            "Benutzerdefinierte Felder als Vorlage speichern, um sie automatisch auf zukünftige Aufträge anzuwenden"
        ],
        SaveFormsForPlacesDesc: [
            "Save forms as a template to apply automatically to new places",
            "Guardar formas como plantilla para aplicar automáticamente en nuevos lugares",
            "Formulare als Vorlage speichern, um sie automatisch auf neue Plätze anzuwenden"
        ],
        Cancel: ['Cancel', 'Cancelar', 'Abbrechen'],
        Change: ['Change', 'Cambiar', "Ändern"],
        City: ['City', 'Ciudad', 'Stadt'],
        CloseWithoutSaving: ['Close without saving', 'Cerrar sin guardar', 'Schließen ohne Speichern'],
        ConnectedToWorkspace: [
            'Connected to workspace',
            'Conectado con espacio',
            'Verbunden mit Workspace',
        ],
        Confirm: ['Confirm', 'Confirmar', 'Bestätigen'],
        Contact: ['Contact', 'Contacto', 'Kontakt'],
        Copied: ['Copied', 'Copiado', 'Kopiert'],
        Copy: ['Copy', 'Copiar', 'Kopieren'],
        Assignee: ['Assignee', 'Asignado', 'Assignee'],
        CopyAssignee: ['Copy assignee', 'Copiar asignado', 'Copy assignee'],
        CopyDate: ['Copy date', 'Copiar fecha', 'Copy date'],
        Country: ['Country', 'País', 'Land'],
        Credentials: ['Credentials', 'Credenciales', 'Login-Daten'],
        Date: ['Date', 'Fecha', 'Datum'],
        Delete: ['Delete', 'Borrar', 'Löschen'],
        Duration: ['Duration', 'Duración', 'Dauer'],
        Minutes: ['Minutes', 'Minutos', 'Minuten'],
        Edit: ['Edit', 'Editar ', 'Bearbeiten'],
        Email: ['Email', 'Email', 'E-Mail'],
        Empty: ['Empty', 'Vacío', 'Leer'],
        Filter: ['Filter', 'Filtrar', 'Filter'],
        Friday: ['Friday', 'Viernes', 'Freitag'],
        General: ['General', 'General', 'Allgemein'],
        Geocode: ['Geolocate', 'Geolocaliza', 'Geolokalisieren'],
        ImageUpload: ['Upload image', 'Sube imagen', 'Laden Bild hoch'],
        FileToUpload: ['File to upload', 'Archivo a subir', 'Datei zum Hochladen'],
        IncorrectPassword: ['Incorrect password', 'La contraseña es inváilida', 'Passwort ist nicht richtig'],
        InvalidUsernameOrPassword: [
            "Invalid username or password",
            "Nombre de usuario o contraseña inválidos",
            "Ungültiger Nutzername oder Passwort",
        ],
        InvalidImageError: ['File not accepted', 'Archivo no aceptado', 'Datei nicht akzeptiert'],
        Job: ['Job', 'Tarea', 'Auftrag'],
        Language: ['Language', 'Idioma ', 'Sprache'],
        Loading: ['Loading...', 'Cargando...', 'Wird geladen...'],
        Ready: ['Ready', 'Listo', 'Bereit'],
        Locate: ['Locate', 'Locate', 'Locate'],
        LocationServicesOff: ['Location services off', 'Servicios de ubicación apagado', 'Standortdienste aus'],
        LoggedOut: ['Logged out', 'Desconectado', 'Ausgeloggt'],
        Member: ['Member', 'Miembro', 'Mitglied'],
        Members: ['Members', 'Miembros', 'Mitglieder'],
        More: ['More', 'Más', 'Mehr'],
        XMore: ['{0} more', '{0} más', '{0} mehr'],
        Monday: ['Monday', 'Lunes', 'Montag'],
        Name: ['Name', 'Nombre', 'Name'],
        Never: ['Never', 'Nunca', 'Niemals'],
        No: ['No', 'No', 'Nein'],
        None: ['None', 'Ninguna', 'Keine'],
        OK: ['OK', 'OK', 'OK'],
        OffDuty: ['Off duty', 'Tiempo fuera de servicio', 'Außerhalb der Dienstzeit'],
        OnTheWay: ['On the way', 'En camino', 'Unterwegs'],
        OneDay: ['1 day', '1 día', '1 Tag'],
        OneMonth: ['1 month', '1 mes', '1 Monat'],
        Open: ['Open', 'Open', 'Open'],
        Operator: ['Operator', 'Operador', 'Disponent'],
        OperatorsDesc: [
            'Operators can access both the web-Dashboard and Mobile app. They can create, manage and dispatch jobs.',
            'Los Operadores tienen acceso al Dashboard web y a la aplicación móvil. Pueden crear, administrar y asignar tareas.',
            'Disponenten haben sowohl Zugang über das Web-Dashboard als auch über die Mobile-App. Sie können Aufträge erstellen, managen und zuweisen.',
        ],
        Overnight: ['Overnight', 'Horario nocturno', 'Nachtschicht'],
        Password: ['Password', 'Contraseña', 'Passwort'],
        Phone: ['Phone', 'Teléfono', 'Telefon'],
        Place: ['Place', 'Lugar', 'Ort'],
        PleaseEnterAValidEmailAddress: [
            'Please enter a valid e-mail address.',
            'Por favor ingresar una dirección de e-mail válida.',
            'Bitte eine gültige Email-Adresse eingeben.',
        ],
        PostalCode: ['Zip Code', 'Código postal', 'Postleitzahl'],
        RadiusInMeter: ['Radius in meters', 'Radio en metros', 'Radius in Meter'],
        ReallyCloseUnsaved: [
            'You have unsaved changes',
            'Tienes cambios sin guardar',
            'Es gibt nicht gespeicherte Änderungen',
        ],
        ResetPasswordLink: [
            'Send link to reset password',
            'Enviar enlace para restablecer la contraseña',
            'Link für Passwort-Rücksetzen senden',
        ],
        Role: ['Role', 'Rol', 'Rolle'],
        Saturday: ['Saturday', 'Sabado', 'Samstag'],
        Save: ['Save', 'Guardar', 'Speichern'],
        SaveAsJobTemplate: ['Save as job template', 'Guardar como plantilla de tarea', 'Als Vorlage speichern'],
        SelectMemberToAutoAssignX: [
            'Select member to recurringly assign {0}',
            'Selecciona el miembro para asignar de forma recurrente {0}',
            'Wähle Mitglied um {0} wiederkehrend zuzuweisen',
        ],
        SelectPlaceOrAddresOnMap: [
            'Select place or address on map',
            'Selecciona el lugar o dirección en el mapa',
            'Platz oder Adresse auf der Karte auswählen',
        ],
        SetAsDefault: ['Set as Default', 'Fijar por defecto', 'Als Default setzen'],
        Settings: ['Settings', 'Ajustes', 'Einstellungen'],
        Signature: ['Signature', 'Firma', 'Unterschrift'],
        GeneralSettings: ['General Settings', 'Ajustes Generales', 'Allgemeine Einstellungen'],
        State: ['State', 'Estado', 'Bundesland'],
        Stationary: ['Stationary', 'Parado', 'Stillstehend'],
        Status: ['Status', 'Estado', 'Status'],
        StatusChange: [
            'Status change will be applied immediately',
            'El cambio de estado se aplicará inmediatamente',
            'Die Statusänderung wird sofort angewendet',
        ],
        Sunday: ['Sunday', 'Domingo', 'Sonntag'],
        Team: ['Team', 'Equipo', 'Team'],
        Teams: ['Teams', 'Equipos', 'Teams'],
        Thursday: ['Thursday', 'Jueves', 'Donnerstag'],
        TimeZone: ['Time zone', 'Uso horario', 'Zeitzone'],
        Title: ['Title', 'Título', 'Titel'],
        EnterTitle: ["Enter title", "Ingresar título", "Titel eingeben"],
        To: ['To', 'Hasta', 'Nach'],
        Total: ['Total', 'Total', 'Total'],
        TrackingOff: ['Tracking off', 'Seguimiento apagado', 'Tracking aus'],
        Tuesday: ['Tuesday', 'Martes', 'Dienstag'],
        TwoFactorAuth: [
            'Enable Two-Factor Authentication (2FA)',
            'Habilitar autenticación de dos factores (2FA)',
            'Schakel twee-factor authenticatie in (2FA)',
        ],
        UnitDistance: ['Measure of length', 'Formato de distancia', 'Längenmaß'],
        UnitKilometer: ['Kilometer', 'Kilómetros', 'Kilometer'],
        UnitMiles: ['Miles', 'Millas', 'Meilen'],
        UnitTime12: ['12 hours (AM/PM)', '12 horas (AM/PM)', '12 Stunden (AM/PM)'],
        UnitTime24: ['24 hours', '24 horas', '24 Stunden'],
        UnitTime: ['Time format', 'Formato de hora', 'Uhrzeitformat'],
        Url: ['URL', 'URL', 'URL'],
        Username: ['Username', 'Nombre de usuario', 'Nutzername'],
        VerifyEmail: ['Verify E-mail', 'Verificar correo electrónico', 'E-Mail bestätigen'],
        VerifyPhone: ['Verify phone number', 'Verificar número de teléfono', 'Bestätigen'],
        Wednesday: ['Wednesday', 'Miercoles', 'Mittwoch'],
        WeekdayFr: ['Fr', 'Vi', 'Fr'],
        WeekdayMo: ['Mo', 'Lu', 'Mo'],
        WeekdaySa: ['Sa', 'Sa', 'Sa'],
        WeekdaySu: ['Su', 'Do', 'So'],
        WeekdayTh: ['Th', 'Jue', 'Do'],
        WeekdayTu: ['Tu', 'Ma', 'Di'],
        WeekdayWe: ['We', 'Mi', 'Mi'],
        MonthJan: ['Jan', 'Ene', 'Jan'],
        MonthFeb: ['Feb', 'Feb', 'Feb'],
        MonthMar: ['Mar', 'Mar', 'Mär'],
        MonthApr: ['Apr', 'Abr', 'Apr'],
        MonthMay: ['May', 'May', 'Mai'],
        MonthJun: ['Jun', 'Jun', 'Jun'],
        MonthJul: ['Jul', 'Jul', 'Jul'],
        MonthAug: ['Aug', 'Ago', 'Aug'],
        MonthSep: ['Sep', 'Sep', 'Sep'],
        MonthOct: ['Oct', 'Oct', 'Okt'],
        MonthNov: ['Nov', 'Nov', 'Nov'],
        MonthDec: ['Dec', 'Dic', 'Dez'],
        WorkersDesc: [
            'Workers only have access to the mobile app to see their jobs.',
            'Los Empleados sólo tienen acceso a la aplicación móvil para ver sus tareas.',
            'Arbeiter haben nur Zugang über die Hellotracks Mobile-App und sehen zugewiesene Aufträge.',
        ],
        XMonths: ['{0} months', '{0} meses', '{0} Monate'],
        Xmin: ['{0} min', '{0} min', '{0} min'],
        Yes: ['Yes', 'Sí', 'Ja'],
        YourBusiness: ['Your Business', 'Tu empresa', 'Dein Business'],
        YourWebsite: ['Your website', 'Tu página web', 'Deine Webseite'],
        Required: ['Required', 'Requerido', 'Erforderlich'],
        Optional: ['Optional', 'Opcional', 'Optional'],
        Create: ['Create', 'Crear', 'Erstellen'],
        InfoAboutTemplates: [
            'Info about templates',
            'Información sobre plantillas',
            'Informationen zu Templates',
        ],
        StartImporting: [
            'Start Importing',
            'Comenzar importación',
            'Import starten',
        ],
        Message: ['Message', 'Mensaje', 'Nachricht'],
        MessageSentTo: [
            'Message sent to {0}',
            'Mensaje enviado a {0}',
            'Nachricht an {0} gesendet',
        ],
        LinkedForms: ['Linked Forms', 'Formularios vinculados', 'Verknüpfte Formulare'],
        Created: ['Created', 'Creado', 'Erstellt'],
        Assigned: ['Assigned', 'Asignado', 'Zugewiesen'],
        Saved: ['Saved', 'Guardado', 'Gespeichert'],
        Archived: ['Archived', 'Archivado', 'Archiviert'],
        RecentlyUpdated: ['Recently updated', 'Recientemente actualizado', 'Kürzlich aktualisiert'],
        AssignedDate: ["Assigned date", "Fecha asignada", "Zugewiesenes Datum"],
        NoAssignedDate: ["No assigned date", "Sin fecha asignada", "Kein zugewiesenes Datum"],
        SearchForTitleAddressOrder: [
            "Search for Title, Address or Order#",
            "Buscar por título, dirección o # de orden",
            "Nach Titel, Adresse oder Auftragsnummer",
        ],
        ReduceFilters: [
            "Reduce filters",
            "Reducir los filtros",
            "Filter reduzieren",
        ],
        LoadMore: ['Load more', 'Cargar más', 'Mehr laden'],
        CreatedDate: [
            "Created Date",
            "Fecha de creación",
            "Datum erstellt",
        ],
        LastSaved: [
            "Last Saved",
            "Último guardado",
            "Zuletzt gespeichert",
        ],
        SomethingWentWrong: ['Something went wrong!', 'Algo salió mal!', 'Etwas lief schief!'],
        Done: ['Done', 'Listo', 'Fertig'],
        Description: ['Description', 'Descripción', 'Beschreibung'],
        Every: ['Every', 'Todos los', 'Jeden'],
        Days: ['Days', 'Días', 'Tage'],
        Clear: ['Clear', 'Limpiar ', 'Leeren'],
        AssignOnSpecificDays: [
            'Additionally assign on specific days',
            'Asignar además en días específicos',
            'Zusätzlich an bestimmten Tagen zuweisen'
        ],
        ChooseDateRange: [
            'Choose date range',
            'Seleccionar rango de fechas',
            'Datumsbereich auswählen'
        ],
        Until: ['Until', 'Hasta', 'Bis'],
        From: ['From', 'Desde', 'Von'],
        End: ['End', 'Fin', 'Ende'],
        Or: ['or', 'ó', 'oder'],
        Exit: ['Exit', 'Salir', 'Exit'],
        Optimize: ['Optimize', 'Optimizar', 'Optimieren'],
        Calculate: ['Calculate', 'Calcular', 'Berechnen'],
        Optimized: ['Optimized', 'Optimizado', 'Optimiert'],
        SelectAll: ['Select all', 'Seleccionar todo', 'Alle wählen'],
        SuccessAssignTo: [
            "successfully assigned to",
            "asignado con éxito a",
            "erfolgreich zugewiesen zu",
        ],
        RemoveFromBoard: [
            "Remove from Board",
            "Quitar del tablero",
            "Aus Board entfernen",
        ],
        AssignOnceNow: ['Assign once now', 'Asignar ahora (una vez)', 'Einmalig zuweisen'],
        RecurringlyAssignTo: [
            "Recurringly assign to",
            "Asignar recurrentemente a",
            "Wiederkehrend zuweisen",
        ],
        Everybody: ['Everybody', 'Todos', 'Alle'],
        ShowAll: ['Show all', 'Ver todo', 'Alle anzeigen'],
        SelectedDay: ['Selected day', 'Día seleccionado', 'Ausgewählter Tag'],
        CreatedByX: [
            "Created by {0}",
            "Creado por {0}",
            "Erstellt von {0}",
        ],
        AssignedByX: [
            "Assigned by {0}",
            "Asignado por {0}",
            "Zugewiesen von {0}",
        ],
        Time: ['Time', 'Hora', 'Zeit'],
        Issue: ['Issue', 'Problema', 'Problem'],
        Started: ['Started', 'Empezado', 'Gestartet'],
        Rejected: ['Rejected', 'Rechazada', 'Abgelehnt'],
        CheckIn: ['Check-in', 'Check-in', 'Check-in'],
        CheckOut: ['Check-out', 'Check-out', 'Check-out'],
        Success: ['Success', 'Completa', 'Erfolg'],
        EnterAddressOrGeocode: [
            "Enter street address or geolocate",
            "Ingresa dirección o geolocaliza",
            "Adresse eingeben oder geolokalisieren",
        ],
        TimeWindow: [
            'Time window',
            'Hora programada',
            'Zeitfenster',
        ],
        TimeWindowDesc: [
            "An optional time window for the job site's availability. Visible to field workers. Also helps for routing and auto-dispatching.",
            "Una hora programada opcional para la disponibilidad del sitio de la tarea. Visible para los empleados de campo.",
            "Ein optionales Zeitfenster für die Verfügbarkeit des Auftrags. Sichtbar für Außendienstmitarbeiter.",
        ],
        NotSet: [
            'not set',
            'no establecido',
            'nicht gesetzt',
        ],
        NoUploadedImages: [
            "No uploaded images",
            "No hay imágenes cargadas",
            "Keine Bilder hochgeladen",
        ],
        NoUploadedImagesDesc: [
            "Here you'll find all uploaded images from your field staff for the selected day. Your field staff can upload images to assigned job from their Hellotracks mobile app.",
            "Aquí encontrarás todas las imágenes cargadas por tu equipo de campo para el día seleccionado. Tu equipo de campo puede cargar imágenes para tareas asignadas desde su aplicación móvil de Hellotracks.",
            "Hier findest du alle hochgeladenen Bilder deiner Mitarbeiter für den ausgewählten Tag. Dein Außendienst kann Bilder zu den zugewiesenen Aufträgen über die Hellotracks app hochladen.",
        ],
        NoSignature: [
            "No signature",
            "Sin firma",
            "Keine Unterschrift",
        ],
        AssignTo: [
            'Assign to',
            'Asignar a',
            'Zuweisen',
        ],
        Assign: [
            'Assign',
            'Asignar',
            'Zuweisen',
        ],
        Reassign: [
            "Reassign",
            "Reasignar",
            "Neu zuweisen",
        ],
        AllFields: [
            "All fields",
            "Todos los campos",
            "Alle Felder",
        ],
        Send: [
            'Send',
            'Enviar',
            'Senden',
        ],
        AttachLocation: [
            "Attach location",
            "Adjuntar ubicación",
            "Adresse anhängen",
        ],
        Billing: [
            "Billing",
            "Facturación",
            "Zahlungsdaten",
        ],
        SignOut: [
            "Sign Out",
            "Cerrar sesión",
            "Ausloggen",
        ],
        EditX: ['Edit {0}', 'Editar {0}', ' {0} bearbeiten'],
        ToggleMenu: [
            "Toggle menu",
            "Alternar menú",
            "Menü toggeln",
        ],
        NavMenuAnalyze: [
            'Analyze',
            'Análisis',
            'Analysieren',
        ],
        NavMenuManage: [
            'Manage',
            'Administrar',
            'Verwalten',
        ],
        NavMenuJobAndDispatch: [
            "Jobs & Data",
            "Tareas & Data",
            "Aufträge & Daten",
        ],
        MemberRouting: [
            "Member Routing",
            "Ruta de miembro",
            "Routing",
        ],
        NavLocationGeneralDesc: [
            "Click on map to place the new job or search and pick by address/place.",
            "Haz clic en el mapa para colocar la nueva ubicación o busca y elija por dirección/lugar.",
            "Klicke auf die Karte, um den neuen Auftrag zu platzieren, oder suche und wähle eine Adresse oder ein Platz.",
        ],
        NavLocationPlaceDesc: [
            "Click on map to create the new place or search and pick by address.",
            "Haz clic en el mapa para crear el nuevo lugar o busca y elija por dirección.",
            "Klicke auf die Karte, um den neuen Ort zu erstellen, oder suche und wähle eine Adresse.",
        ],
        Next: ["Next", "Siguiente", "Weiter"],
        FindAddress: [
            'Find Address',
            'Buscar Dirección',
            'Addresse finden',
        ],
        AddressOrPlace: [
            "Address or place",
            "Dirección o lugar",
            "Adresse oder Platz",
        ],
        NavLocationPlaceHelp: [
            "Drag from center to move the place. Drag from sides to change the radius.",
            "Arrastrar desde el centro para mover el lugar. Arrastrar desde los lados para cambiar el radio.",
            "Ziehe vom Zentrum aus, um den Ort zu bewegen. Ziehe von den Seiten, um den Radius zu ändern.",
        ],
        NavDrawNewZoneDesc: [
            "Click on map to create new zone, click last point to finish.",
            "Haz clic en el mapa para crear una nueva zona, haz clic en el último punto para terminar.",
            "Klicke auf die Karte, um eine neue Zone zu erstellen. Klicke auf den letzten Punkt, um abzuschließen.",
        ],
        NavDrawEditZoneXDesc: [
            "Editing region for {0}",
            "Editando region para {0}",
            "Region bearbeiten für {0}",
        ],
        NavDrawQuickRouteDesc: [
            "Click points on map to create a quick route of multiple jobs, re-click last point to finish. Each point creates a new job.",
            "Haz clic en puntos del mapa para crear una ruta rápida de multiples tareas, haz clic en el último punto para finalizar. Cada punto crea una nueva tarea.",
            "Klicke Punkte auf der Karte, um eine schnelle Route aus mehreren Aufträgen zu erstellen. Klicke auf den letzten Punkt, um abzuschließen. Jeder Punkt erstellt einen neuen Auftrag.",
        ],
        Color: [
            "Color",
            "Color",
            "Farbe",
        ],
        ProgressStatus: [
            "Progress status",
            "Estatus del progreso",
            "Fortschritt",
        ],
        Type: ['Type', 'Tipo', 'Typ'],
        Priority: ['Priority', 'Prioridad', 'Priorität'],
        PriorityCaption: [
            "Priority indicates urgency to operators and workers, and is relevant for route optimization, ensuring higher priority jobs are scheduled first.",
            "La prioridad indica urgencia a los operadores y empleados, y es relevante para la optimización de rutas, asegurando que las tareas de mayor prioridad se programen primero.",
            "Priorität zeigt Dringlichkeit für Disponenten und Arbeiter an und ist relevant für die Routenoptimierung, um sicherzustellen, dass Aufträge mit höherer Priorität zuerst geplant werden."
        ],
        AssignTeamsTo: [
            "Assign teams to",
            "Asignar equipos a",
            "Teams zuweisen zu",
        ],
        Apply: ['Apply', 'Confirmar', 'Bestätigen'],
        GeolocatingStartedInBackground: [
            "Geolocating started in the background.",
            "Comenzó la geolocalización en segundo plano.",
            "Geolokalisierung wurde im Hintergrund gestartet.",
        ],
        Radius: [
            'Radius',
            'Radio',
            'Radius',
        ],
        Sort: [
            "Sort",
            "Ordenar",
            "Sortieren",
        ],
        SearchForPlaceAddress: [
            "Search for place name or address",
            "Buscar por nombre del lugar o dirección",
            "Platz oder Adresse suchen",
        ],
        Stop: [
            "Stop",
            "Parada",
            "Stopp",
        ],
        Stops: [
            "Stops",
            "Paradas",
            "Stopps",
        ],
        SelectAssignee: [
            "Select assignee",
            "Seleccionar recipiente",
            "Empfänger auswählen",
        ],
        CreateJobRoute: [
            'Create job route',
            'Crear ruta con tareas',
            'Auftragsroute erstellen',
        ],
        Places: ['Places', 'Lugares', 'Plätze'],
        PlacesAndZones: ["Places & Zones", "Lugares & Zonas", "Plätze & Zonen"],
        Dates: [
            'Dates',
            'Fechas',
            'Datum',
        ],
        FromXToX: [
            "From {0} to {1}",
            "Desde {0} hasta {1}",
            "Von {0} bis {1}",
        ],
        ByMembers: [
            "By members",
            "Por miembros",
            "Nach Mitglieder",
        ],
        ByPlaces: [
            "By places",
            "Por lugares",
            "Nach Plätzen",
        ],
        Format: [
            "Format",
            "Formato",
            "Format",
        ],
        Back: [
            "Back",
            "Regresar",
            "Zurück",
        ],
        Download: [
            'Download',
            'Descargar',
            'Download',
        ],
        Start: [
            'Start',
            'Comenzar',
            'Starten',
        ],
        Remove: ['Remove', 'Remover', 'Entfernen'],
        LocationServicesOn: ['Location services on', 'Servicios de ubicación encendido', 'Standortdienste aus'],
        Mode: [
            'Mode',
            'Modo',
            'Modus',
        ],
        BatterySaveOn: [
            "Battery save mode is ON",
            "El modo de ahorro de batería está encendido",
            "Batteriesparmodus EIN",
        ],
        BatterySaveOff: [
            "Battery save mode is OFF",
            "El modo de ahorro de batería está apagado",
            "Batteriesparmdous AUS",
        ],
        TrackRecordingOff: [
            "Track recording is OFF",
            "La grabación de ruta está desactivada",
            "Routenaufzeichnung ist AUS",
        ],
        TrackRecordingOn: [
            "Track recording is ON",
            "La grabación de ruta está encendida",
            "Routenaufzeichnung ist EIN",
        ],
        GpsStationaryOff: [
            "Locating is OFF while stationary",
            "La localización está apagada mientras está parado",
            "Ortung ist AUS während Stillstand",
        ],
        GpsStationaryOn: [
            "Locating is ON while stationary",
            "La localización está encendida mientras está parado",
            "Ortung ist EIN während Stillstand",
        ],
        LocateOnMap: [
            "Locate on map",
            "Localizar en el mapa",
            "In Karte lokalisieren",
        ],
        OpenProfile: [
            "Open Profile",
            "Abrir perfil",
            "Profil öffnen",
        ],
        AddToBoard: [
            "Add to Board",
            "Agregar al Tablero",
            "Zu Board hinzufügen",
        ],
        Board: ["Board", "Tablero", "Board"],
        CurrentlyActive: [
            "Currently active",
            "Actualmente activo",
            "Aktuell aktiv",
        ],
        FieldsAreRequired: [
            "These fields are required",
            "Estos campos son requeridos",
            "Diese Felder werden benötigt",
        ],
        XNotValidFields: [
            "These are not valid: {0}",
            "Estos camos no son válidos: {0}",
            "Diese Felder sind nicht gültig: {0}",
        ],
        Select: [
            'Select',
            'Seleccionar',
            'Auswählen',
        ],
        Unselect: [
            "Unselect",
            "Deseleccionar",
            "Abwählen",
        ],
        Integrations: [
            'API & Integrations',
            'API & Integraciones',
            'API & Integrationen',
        ],
        Timesheet: [
            'Timesheet',
            'Hoja de tiempo',
            'Zeiterfassung',
        ],
        Dashboard: [
            "Dashboard",
            "Tablero",
            "Dashboard",
        ],
        Zones: [
            "Zones",
            "Zonas",
            "Zonen",
        ],
        Reports: [
            'Reports',
            'Reportes',
            'Berichte',
        ],
        Timeline: [
            'Timeline',
            'Línea de tiempo',
            'Timeline',
        ],
        Dispatch: ['Dispatch', 'Despacho', 'Disposition'],
        AllJobs: [
            'List of all jobs',
            'Lista de todas las Tareas',
            'Liste aller Aufträge',
        ],
        SearchForStop: [
            "Search for stop",
            "Buscar por parada",
            "Stopp suchen",
        ],
        SearchMember: [
            'Search member',
            'Buscar miembro',
            'Mitglied suchen',
        ],
        NoEmail: ['Without E-mail', 'Sin email', 'Ohne E-Mail'],
        PasswordRequirements: [
            "Minimum 8 characters with no whitespace",
            "Mínimo 8 caracteres sin espacios en blanco",
            "Mindestens 8 Zeichen ohne Leerzeichen",
        ],
        UsernameRequirements: [
            "Minimum 6 characters with no whitespace",
            "Mínimo 6 caracteres sin espacios en blanco",
            "Mindestens 6 Zeichen ohne Leerzeichen",
        ],
        XNotified: [
            "{0} notified",
            "{0} notificado",
            "{0} benachrichtigt",
        ],
        SuccessfullyLoadedTo: [
            "successfully loaded to",
            "cargado con éxito a",
            "erfolgreich geladen für",
        ],
        LocateXOnMap: [
            "Locate {0} on map",
            "Localizar {0} en el mapa",
            "{0} auf Karte lokalisieren",
        ],
        NewJobForXonX: [
            "New job for {0} on {1}",
            "Nueva tarea para {0} en {1}",
            "Neuer Auftrag für {0} am {1}",
        ],
        EditRegion: [
            "Edit region",
            "Editar región",
            "Region bearbeiten",
        ],
        AssignToTeams: [
            'Assign to team',
            'Asignar a un equipo',
            'Zu Team zuweisen',
        ],
        AssignToMembers: [
            'Assign to member',
            'Asignar a un miembro',
            'Zu Mitglied zuweisen',
        ],
        SearchForZone: [
            "Search for zone",
            "Buscar por zona",
            "Search for zone",
        ],
        RecentSubmissions: [
            'Recent Submissions',
            'Envíos recientes',
            'Aktuelle Einsendungen',
        ],
        Questions: [
            'Questions',
            'Preguntas',
            'Fragen',
        ],
        IncorrectCode: [
            "Incorrect code",
            "Código incorrecto",
            "Code ist inkorrekt",
        ],
        WelcomeBack: [
            "Welcome back",
            "Bienvenido de nuevo",
            "Willkommen zurück",
        ],
        LogIntoHellotracks: [
            "Log into the Hellotracks Web-Dashboard",
            "Iniciar sesión en Hellotracks Web-Dashboard",
            "Ins Web-Dashboard einloggen",
        ],
        RequestPasswordReset: [
            "Request password reset",
            "Recupera tu contraseña",
            "Passwort rücksetzen",
        ],
        RequestPasswordResetLink: [
            "Request a password reset link.",
            "Solicitar un enlace para reestablecer tu contraseña.",
            "Einen Link zum Zurücksetzen des Passworts anfordern.",
        ],
        UsernameOrEmail: [
            "Username or email",
            "Usuario o email",
            "Nutzername oder E-mail",
        ],
        YourPassword: [
            "your password",
            "tu contraseña",
            "dein Passwort",
        ],
        Login: [
            "Login",
            "Iniciar sesión",
            "Login",
        ],
        ForgotPassword: [
            'Forgot Password?',
            '¿Olvidaste tu contraseña?',
            'Passwort vergessen?',
        ],
        BackTo: [
            "Back to",
            "Regresar a",
            "Zurück zu",
        ],
        Signup: [
            'Sign up',
            'Registrarse',
            'Anmeldung',
        ],
        DontHaveAnAccountYet: [
            "Don't have an account yet?",
            '¿No tienes cuenta aún?',
            'Noch kein Konto angelegt?',
        ],
        CompanySettings: [
            'Company Settings',
            'Configuración Companía',
            'Einstellungen Unternehmen',
        ],
        MySettings: [
            "My settings",
            "Mi configuración",
            "Meine Einstellungen",
        ],
        LowPriority: [
            "Low priority",
            "Prioridad baja",
            "Niedrige Priorität",
        ],
        NormalPriority: [
            "Normal priority",
            "Prioridad normal",
            "Normale Priorität",
        ],
        HighPriority: [
            "High priority",
            "Prioridad alta",
            "Hohe Priorität",
        ],
        LoggedIn: ["Logged in", "Sesión inciada", "Eingeloggt"],
        ActiveSwitchOn: [
            "Active (Switch is ON)",
            "Activo (Interruptor encendido)",
            "Aktiv (Switch ist EIN)",
        ],
        ServiceSwithOff: [
            "Not in service (Switch is OFF)",
            "Fuera de servicio (Interruptor apagado)",
            "Nicht im Dienst (Switch ist AUS)",
        ],
        LocationPermissionGranted: [
            "Location Permission Granted",
            "Permiso de ubicación otorgado",
            "Standorterlaubnis erteilt",
        ],
        LocationPermissionDenied: [
            "Location Permission Denied",
            "Permiso de ubicación denegado",
            "Standorterlaubnis verweigert",
        ],
        FormSubmissions: [
            "Form submissions",
            "Envíos de formulario",
            "Formulareinsendungen",
        ],
        Messages: [
            'Messages',
            'Mensajes',
            'Nachrichten',
        ],
        NewX: [
            "New {0}",
            "Nuevo {0}",
            "Neuer {0}",
        ],
        Here: ["Here", "Aquí", "Hier"],
        Uploads: ["Uploads", "Cargas", "Uploads"],
        UploadedImages: ["Uploaded images", "Imágenes cargadas", "Hochgeladene Bilder"],
        JobsAssigned: ["Jobs assigned", "Tareas asignadas", "Aufträge zugewiesen"],
        Continue: [
            'Continue',
            'Continuar',
            'Weiter',
        ],
        SearchAddress: [
            "Search address",
            "Buscar dirección",
            "Search address",
        ],
        SearchAndPickAddress: [
            "Search and pick your address",
            "Busca y selecciona tu dirección",
            "Search and pick your address",
        ],
        AboutYourCompany: [
            "About your company",
            "Acerca de tu empresa",
            "Über dein Unternehmen",
        ],
        Today: ["Today", "Hoy", "Heute"],
        DeviceNotResponding: ["Device not responding", "Dispositivo no responde", "Gerät antwortet nicht"],
        LastLocation: ["Last location", "Última locación", "Letzte Ortung"],
        HideOffDuty: [
            "Hide off duty",
            "Ocultar fuera de servicio",
            "Außer Dienst ausblenden",
        ],
        Jobs: [
            "Jobs",
            "Tareas",
            "Aufträge",
        ],
        Tracks: [
            "Tracks",
            "Rutas",
            "Tracks",
        ],
        Invited: ["Invited", "Invitado", "Eingeladen"],
        FilterByTeam: ["Filter by team", "Filtrar por equipo", "Per Team filtern"],
        Recurring: ["Recurring", "Recurrente", "Wiederkehrend"],
        Daily: ["Daily", "Diario", "Täglich"],
        Weekly: ["Weekly", "Semanal", "Wöchentlich"],
        Notifying: ["Notifying", "Notificación", "Benachrichtigung"],
        ReallyDelete: ["Are you sure to delete this?", "¿Estas seguro de eliminarlo?", "Wirklich löschen?"],
        Active: ["Active", "Activo", "Aktiv"],
        ActiveJobs: ["Active Jobs", "Tareas activas", "Aktive Aufträge"],
        Paused: ["Paused", "Pausado", "Pausiert"],
        Close: ['Close', 'Cerrar', 'Schließen'],
        Cancelled: ["Cancelled", "Cancelado", "Abgebrochen"],
        EnterInitials: ["Enter intials", "Ingresar iniciales", "Initialen eingeben"],
        Max6Chars: ["max 6 chars", "máx 6 letras", "max 6 Zeichen"],
        OrCreateWithText: ["or create with text", "ó crear con texto", "oder mit Text erstellen"],
        StopBetweenTracks: ["pause", "pausa", "Pause"],
        StopAtPlace: ["at place", "en lugar", "in Platz"],
        StopAtJob: ["at job", "en tarea", "bei Auftrag"],
        Snooze: ["Snooze", "Silencio", "Snooze"],
        SnoozeNotifications: [
            "Snooze notifications",
            "Silenciar notificaciones",
            "Benachrichtigungen pausieren"
        ],
        FrequentLiveNotificationsDesc: [
            "To avoid distraction, live notifications are temporarily silenced.",
            "Para evitar distracción, las notificaciones en vivo están temporalmente en silencio.",
            "Um Ablenkung zu vermeiden sind die Live-Benachrichtigungen temporär deaktiviert.",
        ],
        EnterPasswordConfirm: [
            "Enter your password to confirm",
            "Ingresa tu contraseña para confirmar",
            "Passwort eingeben um fortzufahren",
        ],
        ConfirmToProceed: [
            "Confirm to proceed",
            "Confirmar para proceder",
            "Bestätigen, um fortzufahren",
        ],
        EditTeamName: [
            "Edit team name",
            "Editar el nombre del equipo",
            "Teamname bearbeiten",
        ],
        TeamRoutingSettings: [
            "Team Routing",
            "Enrutamiento del equipo",
            "Team Routing",
        ],
        DeleteTeam: [
            "Delete team",
            "Borrar equipo",
            "Team löschen",
        ],
        NewEntry: [
            "New entry",
            "Nuevo registro",
            "Neuer Eintrag",
        ],
        Info: [
            "Info",
            "Información",
            "Info",
        ],
        Actions: [
            'Actions',
            'Acciones',
            'Aktionen',
        ],
        Location: ['Location', 'Ubicación', 'Position'],
        LocationHistory: ['Location History', "Historial de Ubicación", "Positionsverlauf"],
        ProvidedByHTAndX: [
            "Provided by <a href='https://hellotracks.com'>Hellotracks</a> and {0}",
            "Proporcionado por <a href='https://hellotracks.com'>Hellotracks</a> y {0}",
            "Zur Verfügung gestellt von <a href='https://hellotracks.com'>Hellotracks</a> und {0}",
        ],
        JobNotFound: [
            "Job not found",
            "Tarea no encontrada",
            "Auftrag nicht gefunden",
        ],
        ShowPrintableVersion: [
            "Show printable and sharable version",
            "Mostrar version para imprimir o compartir",
            "Version zum Ausdrucken oder Teilen anzeigen"
        ],
        GlobalSearchPlaceholder: [
            "Find in map",
            "Encontrar en el mapa",
            "Auf der Karte finden",
        ],
        OpenDetails: [
            'Open details',
            'Abrir detalles',
            'Details öffnen',
        ],
        Distance: [
            'Distance',
            'Distancia',
            'Distanz',
        ],
        Speed: [
            'Speed',
            'Velocidad',
            'Geschwindigkeit',
        ],
        GPS: [
            'GPS',
            'GPS',
            'GPS',
        ],
        CreateURLToShareThisTrack: [
            'Create URL to share this track',
            'Crear URL para compartir ruta',
            'URL erstellen um diesen Track zu teilen',
        ],
        ReallyDeleteThisTrack: [
            'Really delete this track permanently?',
            '¿Seguro de borrar esta ruta permanentamente?',
            'Track permantent löschen?',
        ],
        Track: [
            "Track",
            "Ruta",
            "Track",
        ],
        TrackInfo: [
            "Track Info",
            "Información de ruta",
            "Track Info",
        ],
        DownloadAsGPXFile: [
            'Download as GPX file',
            'Descargar como archivo GPX',
            'Download als GPX-Datei',
        ],
        Share: [
            'Share',
            'Compartir',
            'Teilen',
        ],
        TrackNotFound: [
            "Track not found",
            "Ruta no encontrada",
            "Track nicht gefunden",
        ],
        OpenLink: [
            "Open Link",
            "Abrir enlace",
            "Link öffnen",
        ],
        DownloadAppDescription: [
            "For all users that you would like to see on the map, it is necessary to download and install the Hellotracks mobile app.",
            "Todos los usuarios que deseas ver en el mapa deben descargar e instalar la aplicación móvil de Hellotracks.",
            "Um alle Benutzer auf der Karte zu sehen, müssen diese die mobile Hellotracks-App herunterladen und installieren.",
        ],
        ConfigureShortForm: [
            "Configure Form",
            "Configurar Formulario",
            "Formular konfigurieren",
        ],
        AllDay: [
            "All day",
            "Todo el día",
            "All day",
        ],
        UserIsAlreadyRegistered: [
            'User already exists.',
            'El usuario ya ha sido registrado.',
            'Der Benutzer ist bereits registriert.',
        ],
        MileageHistory: [
            "Mileage History",
            "Historial de kilometraje",
            "Kilometerstand-Historie"
        ],
        MileageHistoryDesc: [
            "The mileage history gives you a quick overview of recent driven distances. Each square is a day, darker means more mileage on that day.",
            "El historial de kilometraje brinda una vista rápida de las distancias recorridas recientemente. Cada cuadrado representa un día, cuanto más oscuro, más kilometraje en ese día.",
            "Die Kilometerhistorie gibt einen schnellen Überblick über die kürzlich gefahrenen Strecken. Jedes Quadrat stellt einen Tag dar, dunklere Farben bedeuten mehr Kilometer an diesem Tag",
        ],
        Import: ["Import", "Importar", "Importieren"],
        DayRoutes: ["Day routes", "Rutas del día", "Tagesrouten"],
        NameIsRequired: [
            "Name is required",
            '"Name" es requerido',
            "Name is required",
        ],
        EmailIsRequired: [
            "Email is required",
            '"Email" es requerido',
            "Email is required",
        ],
        InvalidEmail: [
            "Invalid email",
            '"Email" inválido',
            "Invalid email",
        ],
        Row: [
            'Row',
            'Fila',
            'Reihe',
        ],
        SucceedImport: [
            "Succeed import",
            "Importación exitosa",
            "Succeed import",
        ],
        PasswordRecovery: [
            'Password Recovery',
            'Recuperación de contraseña',
            'Passwort wiederherstellen',
        ],
        NewPassword: [
            'New password',
            'Nueva contraseña ',
            'Neues Passwort',
        ],
        NewPasswordsDoNotMatch: [
            'Passwords do not match',
            'Las contraseñas no coinciden. ',
            'Passwörter stimmen nicht überein.',
        ],
        NoLocation: [
            "No location set",
            "No se ha establecido la ubicación",
            "Kein Standort festgelegt",
        ],
        NoPermission: [
            'No Permission',
            'Sin Permiso',
            'Keine Berechtigung',
        ],
        EnterEmail: ["Enter email", "Ingresar email", "Email eingeben"],
        EnterPassword: ["Enter password", "Ingresar contraseña", "Passwort eingeben"],
        EnterUsername: ["Enter username", "Ingresar nombre de usuario", "Nutzername eingeben"],
        Unlinked: [
            "None",
            "Ninguna",
            "Keine",
        ],
        ActionWillSaveChanges: [
            "This action will additionally save changes to the current job.",
            "Esta acción guardará adicionalmente los cambios en la tarea actual.",
            "Diese Aktion wird zusätzlich Änderungen am aktuellen Auftrag speichern.",
        ],
        AddToTable: [
            "Add to table",
            "Agregar a la tabla",
            "Zu Tabelle hinzufügen to table",
        ],
        RemoveFromTable: [
            "Remove from table",
            "Quitar de la tabla",
            "Von Tabelle entfernen",
        ],
        ReallyDeleteSelectedTracks: [
            "Really delete selected tracks permanently?",
            "¿Seguro de borrar las rutas seleccionadas permanentemente?",
            "Ausgewählte Tracks wirklich dauerhaft löschen",
        ],
        DeleteTracks: [
            'Delete tracks',
            'Borrar rutas',
            'Tracks löschen',
        ],
        Reason: [
            "Reason",
            "Motivo",
            "Begründung",
        ],
        Customize: [
            "Customize",
            "Personalizar",
            "Anpassen",
        ],
        Welcome: [
            "Welcome to Hellotracks",
            "Bienvenido a Hellotracks",
            "Willkommen zu Hellotracks",
        ],
        LoginToContinue: [
            "Login with your account to continue",
            "Inicia sesión con tus cuenta para continuar",
            "Melde dich mit deinem Konto an um fortzufahren",
        ],
        Unassigned: ['Unassigned', 'Sin asignar', 'Nicht zugewiesen'],
        Update: [
            "Update",
            "Actualizar",
            "Aktualisieren",
        ],
        WelcomeToHellotracksBusiness: [
            'Welcome to Hellotracks Business',
            '¡Bienvenido a Hellotracks Business!',
            'Willkommen zu Hellotracks Business',
        ],
        NowYouAreReadyToInstall: [
            'Now you are ready to install and log-in to the Hellotracks app on your device.',
            'Ahora puedes instalar e iniciar sesión con la app de Hellotracks en tu dispositivo.',
            'Jetzt kannst du die Hellotracks-App auf deinem Gerät installieren.',
        ],
        GetApp: [
            'Get App',
            'Ir a página de instalación',
            'Zur Download-App Seite',
        ],
        GoToLiveDashboard: [
            'Go to Live Dashboard',
            'Ir al Live Dashboard',
            'Zum Live Dashboard gehen',
        ],
        MoreFilters: [
            "More filters",
            "Más filtros",
            "Mehr Filter",
        ],
        NoName: [
            "[no name]",
            "[sin nombre]",
            "[kein Name]",
        ],
        TryBusiness: [
            "Try Business",
            "Prueba la versión business",
            "Business ausprobieren"
        ],
        TryBusinessDesc: [
            "Discover the power of Hellotracks Business platform! If you're currently using our free plan without realizing the full potential, you can easily upgrade to a business account and unlock unrestricted access to all our advanced features. Try it free for 30 days and experience the difference!",
            "¡Descubre el poder de la plataforma de negocios de Hellotracks! Si actualmente estás utilizando nuestro plan gratuito sin darte cuenta de todo su potencial, puedes actualizar fácilmente a una cuenta de negocio y desbloquear acceso ilimitado a todas nuestras funciones avanzadas. ¡Pruébalo gratis durante 30 días y experimenta la diferencia!",
            "Entdecke die Leistungsfähigkeit der Hellotracks Business-Plattform! Wenn du derzeit unseren kostenlosen Plan nutzt, ohne das volle Potenzial zu erkennen, kannst du problemlos auf ein Business-Konto upgraden und uneingeschränkten Zugriff auf alle unsere fortschrittlichen Funktionen erhalten. Probiere es 30 Tage lang kostenlos aus und erlebe den Unterschied!"
        ],
        ResendInvitation: [
            "Resend invitation",
            "Reenviar invitación",
            "Einladung erneut senden",
        ],
        TooManyRows: [
            "Too many rows",
            "Demasiadas filas",
            "Zu viele Zeilen",
        ],
        FieldIsRequired: [
            "This field is required",
            "Este campo es requerido",
            "Dieses Feld ist erforderlich",
        ],
        Submit: [
            "Submit",
            "Enviar",
            "Senden",
        ],
        CheckInternetConnection: [
            "Please check your internet connection and try again.",
            "Confirma tu conexión de red e intenta más tarde.",
            "Bitte Internetverbindung überprüfen und später nochmals versuchen.",
        ],
        Duplicate: [
            "Duplicate",
            "Duplicar",
            "Duplizieren",
        ],
        Purpose: [
            "Purpose",
            "Propósito",
            "Zweck"
        ],
        Comment: [
            "Comment",
            "Comentario",
            "Kommentar",
        ],
        WaypointDensity: [
            "Waypoint Density",
            "Densidad de Puntos de Ruta",
            "Wegpunktdichte"
        ],
        CellResolution: [
            "Cell Resolution",
            "Resolución de Celda",
            "Zellenauflösung"
        ],
        Cells: [
            "Cells",
            "Celdas",
            "Zellen",
        ],
        HideAllPanels: [
            "Hide all panels to have a better view of the map.",
            "Oculta todos los paneles para tener una mejor vista del mapa.",
            "Verstecke alle Bedienfelder, um eine bessere Sicht auf die Karte zu haben."
        ],
        SingleSignOn: [
            "Single Sign-On (SSO)",
            "Single Sign-On (SSO)",
            "Single Sign-On (SSO)",
        ],
        ManualCheck: [
            "Manual",
            "Manual",
            "Manuell"
        ],
        EmailAsUsername: [
            "E-mail as username",
            "Correo electrónico como nombre de usuario",
            "E-Mail als Benutzername"
        ],
        SetIndividualUsername: [
            "Set individual username",
            "Establecer nombre de usuario individual",
            "Individuellen Nutzernamen festlegen"
        ],
        PasswordTooCommon: [
            "Password is too common and not secure",
            "La contraseña es demasiado común y no es segura",
            "Das Passwort ist zu häufig in Verwendung und nicht sicher",
        ],
        LastXDays: ['{0}d', '{0} días', '{0} Tage'],
        LastXMonths: ['{0}mo', '{0} mes.', '{0} Mon.'],
        Custom: ["Custom", "Personalizado", "Benutzerdef."],
        PinMenu: [
            "Pin menu",
            "Fijar menú",
            "Menü anheften"
        ],
        UnpinMenu: [
            "Unpin menu",
            "Desfijar menú",
            "Menü lösen",
        ],
        Conditional: [
            "Conditional",
            "Condicional",
            "Bedingung",
        ],
        ValueEqualsTo: [
            "Value equals",
            "Igual a",
            "Wert gleicht",
        ],
        Show: [
            "Show",
            "Mostrar",
            "Sehen",
        ],
        VisibleWhenEqualsTo: [
            "Visible when value equals to",
            "Visible cuando el valor es igual a",
            "Sichtbar wenn Wert gleich",
        ],
        PDFUpload: ['Upload PDF', 'Sube PDF', 'PDF hochladen'],
        OnlyAllowedForBusiness: [
            "This is enabled only for Business",
            "Esto está habilitado solo para Empresas",
            "Dies ist nur für Unternehmen aktiviert",
        ],
        ConfirmPinToProceed: [
            "Do you wish to proceed? Please confirm by entering the following pin code.",
            "¿Deseas continuar? Por favor, confirma ingresando el código pin siguente.",
            "Möchtest du fortfahren? Bitte bestätige, indem du den folgenden Pin eingibst."
        ],
        NothingPinnedYet: [
            "Nothing Pinned Yet",
            "Nada Anclado Aún",
            "Noch Nichts Angeheftet",
        ],
        ViaX: [
            "via {0}",
            "a través de {0}",
            "über {0}",
        ],
        EnterName: [
            "Enter name",
            "Ingresar nombre",
            "Name eingeben"
        ],
        AcceptAll: [
            "Accept all",
            "Aceptar todos",
            "All akzeptieren",
        ]
    },
    Settings: {
        SettingsAttention: [
            "Your browser settings may not match your saved preferences.",
            "La configuración de tu navegador puede no coincidir con tus preferencias guardadas.",
            "Deine Browser-Einstellungen stimmen eventuell nicht mit deinen gespeicherten Präferenzen übereinstimmen.",
        ],
        TimezoneMismatchDesc: [
            "Browser timezone doesn't match your user's saved timezone.",
            "La zona horaria del navegador no coincide con la zona horaria guardada de tu usuario.",
            "Die Zeitzone des Browsers stimmt nicht mit der gespeicherten Zeitzone deines Benutzers überein.",
        ],
        TimezoneMismatchUseBrowser: [
            "Update to browser timezone",
            "Actualizar a la zona horaria del navegador",
            "Auf die Zeitzone des Browsers aktualisieren",
        ],
        TimezoneMismatchKeepUser: [
            "Keep user's saved timezone",
            "Mantener la zona horaria guardada del usuario",
            "Die gespeicherte Zeitzone des Benutzers beibehalten",
        ],
        TimeFormatMismatchDesc: [
            "Browser time format doesn't match your user's language",
            "El formato de hora del navegador no coincide con el idioma de tu usuario",
            "Das Zeitformat des Browsers stimmt nicht mit der Sprache deines Benutzers überein",
        ],
        TimeFormatMismatchUseBrowser: [
            "Update to browser time format",
            "Actualizar al formato de hora del navegador",
            "Auf das Zeitformat des Browsers aktualisieren",
        ],
        TimeFormatMismatchKeepUser: [
            "Keep existing time format",
            "Mantener el formato de hora actual",
            "Das vorhandene Zeitformat beibehalten",
        ],
        DistanceFormatMismatchDesc: [
            "Browser distance format doesn't match your user's language",
            "El formato de distancia del navegador no coincide con el idioma de tu usuario",
            "Das Distanzformat des Browsers stimmt nicht mit der Sprache deines Benutzers überein",
        ],
        DistanceFormatMismatchUseBrowser: [
            "Update to browser distance format",
            "Actualizar al formato de distancia del navegador",
            "Auf das Distanzformat des Browsers aktualisieren",
        ],
        DistanceFormatMismatchKeepUser: [
            "Keep existing distance format",
            "Mantener el formato de distancia actual",
            "Das vorhandene Distanzformat beibehalten",
        ],
        LanguageMismatchDesc: [
            "Browser language doesn't match your user's language",
            "El idioma del navegador no coincide con el idioma de tu usuario",
            "Die Sprache des Browsers stimmt nicht mit der Sprache deines Benutzers überein",
        ],
        LanguageMismatchUseBrowser: [
            "Update to browser language",
            "Actualizar al idioma del navegador",
            "Auf die Sprache des Browsers aktualisieren",
        ],
        LanguageMismatchKeepUser: [
            "Keep user's language",
            "Mantener el idioma del usuario",
            "Die Sprache des Benutzers beibehalten",
        ],
    },
    Menu: {
        LiveMapDesc: [
            "Your whole team in real-time",
            "Todo tu equipo en tiempo real",
            "Das gesamte Team in Echtzeit",
        ],
        TimelineDesc: [
            "Find out what happened at any time",
            "Descubre lo que pasó en cualquier momento",
            "Finde heraus, was zu bestimmten Zeitpunkt passiert ist",
        ],
        TracksDesc: [
            "All driven routes in one list",
            "Todas las rutas recorridas en una lista",
            "Alle gefahrenen Routen in einer Liste",
        ],
        StopsDesc: [
            "View all stops made by your team",
            "Visualiza todas las paradas hechas por tu equipo",
            "Zeige alle Haltepunkte an, die von deinem Team gemacht wurden",
        ],
        AllJobsDesc: [
            "Manage all your jobs in one table",
            "Gestiona todos tus tareas en una tabla",
            "Verwalte alle Aufträge in einer Tabelle",
        ],
        DispatchDesc: [
            "Schedule and assign jobs",
            "Programar y asignar tareas",
            "Aufträge planen und zuweisen",
        ],
        FormsAndDataInputDesc: [
            "Retrieve data from your team in the field",
            "Recupera datos de tu equipo en el campo",
            "Daten von deinem Team vor Ort abrufen",
        ],
        HeatmapDesc: [
            "Visualize movement over a longer time period",
            "Visualiza el movimiento durante un periodo de tiempo mas largo",
            "Bewegung über eine länge Zeitspanne visualisieren",
        ],
        UploadsDesc: [
            "View uploaded pictures from jobs",
            "Ver fotos subidas de tareas",
            "Hochgeladene Bilder von Aufträgen anzeigen",
        ],
        TeamDesc: [
            "Manage and organize your team",
            "Gestiona y organiza tu equipo",
            "Verwalte und organisiere dein Team",
        ],
        PlacesDesc: [
            "Create and manage your places",
            "Crea y gestiona tus lugares",
            "Erstelle und verwalte deine Plätze",
        ],
        ZonesDesc: [
            "Set up and manage zones for your team",
            "Configura y gestiona zonas para tu equipo",
            "Richte Zonen für dein Team ein und verwalte sie",
        ],
        AlertsDesc: [
            "Get notifications and alerts for important events",
            "Recibe notificaciones y alertas para eventos importantes",
            "Erhalte Benachrichtigungen und Warnungen für wichtige Ereignisse",
        ],
        DashboardDesc: [
            "Get an overview of your team's activities",
            "Obtén una visión general de las actividades de tu equipo",
            "Erhalte einen Überblick über die Aktivitäten deines Teams",
        ],
        ReportsDesc: [
            "Generate reports and analyze your team's data",
            "Genera informes y analiza los datos de tu equipo",
            "Erstelle Berichte und analysiere die Daten deines Teams",
        ],
        TimesheetDesc: [
            "Monitor clock ins and outs from mobile staff",
            "Controla los registros de entrada y salida del personal móvil",
            "Ein- und Ausstempelzeiten von mobilen Mitarbeitern überwachen",
        ],
        SettingsDesc: [
            "Configure your account settings",
            "Configura los ajustes de tu cuenta",
            "Kontoeinstellungen konfigurieren",
        ],
        MessagesDesc: [
            "Communicate with your team",
            "Comunícate con tu equipo",
            "Mit deinem Team kommunizieren",
        ],
        IntegrationsDesc: [
            "Connect with third-party tools",
            "Conéctate con herramientas de terceros",
            "Mit Tools von Drittanbietern verbinden",
        ],
        HelpCenterDesc: [
            "Get support and assistance",
            "Obtén soporte y ayuda",
            "Unterstützung und Hilfe erhalten",
        ],
    },
    Help: {
        Help: ['Help', 'Ayuda', 'Hilfe'],
        Title: ['Help Center', 'Help Center', 'Help Center'],
        Url: [
            "https://help-docs.hellotracks.com",
            "https://help-hellotracks-es.helpscoutdocs.com/",
            "https://help-docs.hellotracks.com",
        ],
        Beacon: [
            "3a9a0701-e62a-4f9d-ac93-19c4293119de",
            "e8dada15-8df3-4e83-8b1f-38a12d80afc5",
            "3a9a0701-e62a-4f9d-ac93-19c4293119de",
        ],
    },
    Dispatch: {
        PinJob: ["Pin Job", "Fijar tarea", "Auftrag anheften"],
        Unpin: ["Unpin", "Desanclar", "Losheften"],
        AllUnassigned: [
            "All",
            "Todas",
            "Alle"
        ],
        Calculate: [
            '<strong>Calculate:</strong><p>Calculate route times and distances.</p>',
            '<strong>Calcular:</strong><p>Calcular tiempos de ruta y distancias..</p>',
            '<strong>Berechnen:</strong><p>Routenzeiten und Entfernungen berechnen.</p>',
        ],
        DoneRearranging: ['Done rearranging', 'Done rearranging', 'Done rearranging'],
        EditXJobs: ['Edit {0} jobs', 'Editar {0} tareas', '{0} Aufträge bearbeiten'],
        More: ['See more options', 'Ver más opciones', 'Mehr Optionen sehen'],
        NewJob: [
            "Insert new job",
            "Insertar nueva tarea",
            "Neuen Auftrag einfügen"
        ],
        NextDay: ['Next day', 'Día siguente', 'Nächster Tag'],
        OpenJobTooltip: ['Open and edit job', 'Abrir y editar tarea', 'Öffnen und Bearbeiten'],
        Optimize: [
            '<strong>Optimize:</strong><p>Apply route optimization, this will reorder the route.</p>',
            '<strong>Optimizar:</strong><p>Aplicar optimización de ruta, esto reordenará la ruta.</p>',
            '<strong>Optimieren:</strong><p>Routenoptimierung anwenden, dies wird die Route neu ordnen.</p>',
        ],
        PreviousDay: ['Previous day', 'Día anterior', 'Vorheriger tag'],
        ReallyDeleteSelectedJobs: [
            'Really delete selected jobs?',
            '¿Seguro de borrar las tareas seleccionadas?',
            'Ausgewählter Auftrag wirklich löschen?',
        ],
        UnassignedJobs: ['Unassigned jobs', 'Tareas no asignadas', 'Nichtzugewiesene Aufträge'],
        UnassignedJobsTooltip: [
            "Click to show unassigned jobs. Drop jobs to unassign.",
            "Haz clic para mostrar tareas sin asignar. Suelta las tareas para desasignarlos.",
            "Klick, um nicht zugewiesene Aufträge anzuzeigen. Zieh Aufträge hierher, um eine Zuweisung zu entfernen.",
        ],
        Description: [
            "You can create new jobs and assign them to users to create day routes, use our route optimization tool to reduce mileage and improve efficiency.",
            "Puedes crear nuevas tareas y asignárselos a los usuarios para crear rutas diarias. También puedes utilizar nuestra herramienta de optimización de rutas para reducir la cantidad de kilómetros recorridos y mejorar la eficiencia.",
            "Du kannst neue Aufträge erstellen und sie Benutzern zuweisen, um Tagesrouten zu erstellen. Außerdem kannst du unser Routenoptimierungstool nutzen, um die Kilometerzahl zu reduzieren und die Effizienz zu verbessern.",
        ],
        ShowUnassignedJobs: [
            "Show unassigned jobs",
            "Mostrar tareas no asignadas",
            "Nicht-zugewiesene Aufträge anzeigen",
        ],
        RecurringReasonsTitle: [
            "Recurring reasons for issues in the field",
            "Motivos recurrentes de problemas",
            "Wiederkehrende Gründe/Probleme bei Auftratgsbearbeitungen",
        ],
        RecurringReasonsDescription: [
            "Select recurring issues in the field that can be selected by field workers upon marking a job as Issue",
            "Selecciona problemas recurrentes en el campo que pueden ser seleccionados por los trabajadores de campo al marcar una tarea como Problema",
            "Wähle hier Standard-Probleme, die wiederkehrend auftreten. Diese können vom Außendienstmitarbeiter ausgewählt werden, wenn ein Job als Problem« markiert wird",
        ],
        InputFormConfiguration: [
            'Standard Completion Form Configuration',
            'Configuración del Formulario de Finalización Estándar',
            'Konfiguration des Standard-Formular für Auftragsabschluss',
        ],
        InputFormDescription: [
            "Set up a Completion Form which can be filled out by your staff in the field.",
            "Configura un Formulario de Finalización que pueda completar tu personal en el campo.",
            "Richte hier ein Formular für Auftragsabschluss ein, das von den Mitarbeitern vor Ort ausgefüllt werden kann.",
        ],
        CustomizeInputForm: [
            "Customize the Standard Completion Form",
            "Personalizar el Formulario de Finalización Estándar",
            "Standard-Formular für Auftragsabschluss anpassen",
        ],
        JobCompletion: [
            'Job completion confirmation / Proof of delivery',
            'Confirmación de finalización del trabajo / Prueba de entrega',
            'Bestätigung abgeschlossener Aufträge / Zulieferungsbeweis',
        ],
        EmailSubject: [
            "Email subject",
            "Asunto del correo",
            "Email-Betreff",
        ],
        EmailSubjectDescription: [
            "Job completion confirmation",
            "Confirmación de finalización del trabajo",
            "Auftragsabschluss Bestätigung",
        ],
        EmailBody: [
            "Email body",
            "Cuerpo del correo",
            "Nachrichtentext",
        ],
        EmailBodyDescription: [
            "This is a notice to let you know the Job below was completed successfully, click the link below to access details.",
            "Este es un aviso para informar que la tarea a continuación se ha completado correctamente, haga clic en el siguiente vínculo  para acceder a los detalles.",
            "Dies ist eine Benachrichtigung, damit Sie wissen, dass der hier aufgelistete Auftrag erfolgreich abgeschlossen wurde. Klicken Sie auf den Link unten, um auf die Details zuzugreifen.",
        ],
        RecurringReasonsExamples: [
            "e.g. Customer didn't order, No time to deliver...",
            "p.ej. El cliente no lo ordenó, Sin tiempo para entregar...",
            "e.g. Kunde hat nicht bestellt, Keine Zeit zur Lieferung...",
        ],
        WorkerChangesStatusOfJobToX: [
            "Worker updates status of job to {0}",
            "El empleado actualiza el estado de la tarea a {0}",
            "Der Mitarbeiter aktualisiert den Status des Auftrags auf {0}",
        ],
        PinnedJobs: [
            "Pinned Jobs",
            "Tareas fijadas",
            "Angeheftete Aufträge"
        ],
        ScheduledArrival: [
            "Scheduled Arrival",
            "Llegada Programada",
            "Geplante Ankunft"
        ],
        ScheduledDeparture: [
            "Scheduled Departure",
            "Salida Programada",
            "Geplante Abfahrt"
        ],
    },
    EmptyStates: {
        AllJobsEmptyDescription: [
            'Jobs are tasks assigned to your team in the field. A sequence of jobs form a day route. Workers can update the job status, upload images and more.',
            'Una secuencia de tareas forman una ruta para un día. Los empleados pueden actualizar el estado de la tarea, cargar imágenes y más.',
            'Aufträge sind Aufgaben, die deinem Team im Außendienst zugewiesen werden. Eine Folge von Aufträgen bildet eine Tagestour. Mitarbeiter können den Auftragsstatus aktualisieren, Bilder hochladen und vieles mehr.',
        ],
        AllJobsEmptyTitle: [
            "Looks like you haven't created a job yet",
            'Parece que aún no has creado una tarea',
            'Keine Aufträge vorhanden',
        ],
        JobTableEmptyDayRouteDescription: [
            'This day route is empty. Create or assign jobs to this user. You can manually drag and drop jobs or apply route optimization to reduce mileage.',
            'La ruta de este día está vacía. Crea o asigna tareas a este usuario. Puedes arrastrar y soltar tareas manualmente u optimizar la rutas para reducir el kilometraje.',
            'Diese Tagesroute ist leer. Erstelle oder weise Aufträge diesem Nutzer zu. Du kannst Aufträge manuell ziehen und ablegen oder die Routenoptimierung anwenden, um die Distanz zu reduzieren.',
        ],
        JobTableEmptyDayRouteTitle: ['Empty day route', 'Ruta del día vacía', 'Leere Tagesroute'],
        JobTableEmptySequenceDescription: [
            'This sequence is empty. Sequences are routes that can be reused or set up to be recurringly assigned.',
            'Esta secuencia está vacía. Las secuencias son rutas que se pueden reutilizar o configurar para que se asignen de forma recurrente.',
            'Diese Sequenz ist leer. Sequenzen sind Routen, die wiederverwendet werden können und können manuell oder automatisch wiederkehrend zugewiesen werden.',
        ],
        JobTableEmptySequenceTitle: ['Empty sequence', 'Secuencia vacía', 'Leere Sequenz'],
        PlacesEmptyDescription: [
            'Places are where team members interact with physical locations, often this is an HQ or a job site location. Use them for arrival and departure updates, tracking on-site time, and more.',
            'Recibe notificaciones sobre la hora de llegada y salida. Registra el tiempo total en cada lugar. Un lugar tiene una ubicación y un radio fijo. Los lugares son lugares de trabajo, por ejemplo ubicaci€on de clientes.',
            'Werde über Ankünfte und Abfahrten benachrichtigt und erfasse Vor-Ort-Zeiten. Ein Platz hat einen festen Ort und Radius. Plätzer sind die richtige Repräsentation des Kundenstamms.',
        ],
        PlacesEmptyTitle: [
            'Add places and record on-site time',
            'Agrega lugares y comeinza a tener un registro del tiempo que pasan tus empleados en cada lugar',
            'Füge Plätze hinzu und erfasse die Zeit vor Ort',
        ],
        StopsEmptyDescription: [
            'Stops are pauses of your team by check-ins at places or jobs or between tracks. This view gives and overview of how long your team remains idling at different locations and helps in calculating on-site times.',
            'Las paradas son las pausas de los miembros en lugares o tareas. Esta vista brinda una descripción general de cuánto tiempo tu equipo permanece inactivo en diferentes lugares y ayuda a calcular las paradas en cada lugar.',
            'Stopps sind Pausen deines Teams durch Check-Ins an Plätzern oder Aufträgen oder zwischen Tracks. Diese Ansicht gibt einen Überblick darüber, wie lange dein Team an verschiedenen Standorten verbleibt, und hilft bei der Berechnung der Vor-Ort-Zeiten.',
        ],
        StopsEmptyTitle: [
            'No stops found for this day',
            'No se encontraron paradas para este día',
            'Für diesen Tag wurden keine Stopps gefunden',
        ],
        TeamEmptyDescription: [
            "Add your mobile workforce to your team. They'll receive an invite to download the Hellotracks app on their mobile device and you will see them appear here on the live map.",
            'Agrega empleados a tu equipo. Recibirán una invitación para descargar la aplicación de Hellotracks en su dispositivo móvil y los verás aquí en el mapa en vivo.',
            'Hier kannst du dein Team erstellen. Die Außendienstmitarbeiter erhalten eine Einladung, die Hellotracks-App auf ihr Mobilgerät herunterzuladen und sie werden dann hier in der Live-Karte erscheinen.',
        ],
        TeamEmptyTitle: ['Building your Team', '¿Añadir a tu equipo?', 'Dein Team hinzufügen?'],
        TimesheetEmptyDescription: [
            "Your team can clock-in and out with the Hellotracks mobile app by turning the Active switch on/off. Here you can find a summary of your team's clock-in and clock-out times.",
            'Tu equipo puede fichar el comienzo y termino de su jornada del horario de trabajo con la aplicación móvil Hellotracks activando o desactivando el interruptor. Aquí puedes encontrar un resumen de las horas de activas de trabajo de tu equipo.',
            'Dein Team kann sich mit der Hellotracks Mobile App ein- und ausstempeln, indem sie den Aktiv-Schalter ein-/ausschalten. Hier findest du eine Zusammenfassung der Kommen- und Gehen-Zeiten deines Teams.',
        ],
        TimesheetEmptyTitle: [
            'No entries found for this day',
            'No se encontraron entradas para este día',
            'Keine Einträge für diesen Tag gefunden',
        ],
        TracksEmptyDescription: [
            'Invite your team to activate tracking in the Hellotracks mobile app to see all driven routes here.',
            'Invita a tu equipo a activar el seguimiento en la aplicación móvil Hellotracks para ver todas las rutas recorridas aquí.',
            'Laden dein Team ein, Tracking in der Hellotracks Mobile App zu aktivieren, um alle gefahrenen Routen hier zu sehen.',
        ],
        TracksEmptyTitle: [
            'No driven routes or events for this user within the selected day and timeframe.',
            'No hay rutas recorridas o eventos para este usuario dentro del día y período de tiempo seleccionado.',
            'Keine gefahrenen Routen oder Ereignisse für diesen Nutzer innerhalb des ausgewählten Tages und Zeitrahmens',
        ],
        UnassignedEmptyDescription: [
            'This view shows all jobs that are not assigned to any worker yet.',
            'Esta vista muestra todas las tareas que aún no han sido asignadas.',
            'Diese Ansicht zeigt alle Aufträge, die noch keinem Arbeiter zugewiesen sind.',
        ],
        UnassignedEmptyTitle: [
            'No unassigned jobs',
            'No hay tareas no asignadas',
            'Keine unzugewiesenen Aufträge'
        ],
        ZonesEmptyDescription: [
            'Zones are polygonal areas. Zones can help you visualize areas on the map, you can set up alerts for geofencing and zones can help you in dispatching jobs.',
            'Las zonas son áreas poligonales que permiten visualizar áreas en el mapa, puedes configurar alertas para cada zona y adjuntar zonas a tareas.',
            'Zonen sind polygonale Regionen. Zonen können dir dabei helfen, Regionen auf der Karte zu visualisieren, Du kannst Warnungen für Geofencing einrichten und Zonen können dir beim Verteilen von Aufträgen helfen.',
        ],
        ZonesEmptyTitle: ['No zones created yet', 'Todavía no se han creado zonas', 'Noch keine Zonen erstellt'],
        MemberTracksEmptyTitle: [
            'No driven routes or stops found within the selected timeframe.',
            'No se encontraron rutas conducidas ó paradas dentro del marco de tiempo seleccionado',
            'Keine gefahrenen Strecken oder Stopps innerhalb des ausgewählten Zeitrahmens gefunden'
        ],
        NoSelectedMemberEmptyTitle: [
            "Choose a member to view their driven routes and stops during the selected timeframe.",
            "Elige un miembro para ver sus rutas conducidas y paradas durante el marco de tiempo seleccionado",
            "Wähle ein Mitglied, um seine gefahrenen Strecken und Stopps für den ausgewählten Zeitrahmen anzuzeigen"
        ],
    },
    ProfileModal: {
        AppLaunches: ['App Launches', 'Activaciones de la app', 'App gestartet'],
        AppLogin: ['App Login', 'Sesiones en la app', 'App-Logins'],
        AppRunningInBackground: ['App running in background', 'App en segundo plano', 'App läuft im Hintergrund'],
        AppRunningInForeground: ['App is running in foreground', 'App en primer plano', 'App läuft im Vordergrund'],
        AppSuspended: [
            'App is suspended (compromises accuracy of track recognition)',
            'App suspendida (afecta el registro de rutas)',
            'App wurde suspendiert (beeinflusst Track-Aufzeichnung)',
        ],
        AppVersion: ['App Version', 'Versión app', 'App Version'],
        BatteryLevel: ['Battery Level', 'Nivel de bateria', 'Akku-Level'],
        Conversation: ['Chat', 'Chat', 'Chat'],
        Device: ['Device', 'Dispositivo', 'Gerät'],
        DeviceName: ['Device Name', 'Nombre del dispositivo', 'Gerätename'],
        EditProfile: ['Edit Profile', 'Editar perfil', 'Profil bearbeiten'],
        GPSLocationProvider: ['GPS location provider', 'Proveedor de ubicación GPS', 'GPS Ortung'],
        General: ['General', 'General', 'Allgemein'],
        Integrations: ['Integrations', 'Integraciones', 'Integrationen'],
        MostRecentLocation: ['Most Recent Location', 'Ubicación más reciente', 'Letzte Ortung'],
        NetWorkLocationProvider: ['Network location provider', 'Proveedor de ubicación de redes', 'Handy-Netz Ortung'],
        OperatingSystem: ['Operating System', 'Sistema operativo', 'Betriebssystem'],
        Profile: ['Profile', 'Perfil', 'Profil'],
        Routing: ['Routing', 'Enrutamiento', 'Routing'],
        Tracking: ['Tracking', 'Seguimiento', 'Tracking'],
        TrackingStatus: ['Active / Not in service', 'Activo / Inactivo', 'Aktiv / Nicht im Dienst'],
        Worker: ['Worker', 'Empleado', 'Arbeiter'],
        WorkingHours: ['Working hours', 'Horas Laborales', 'Dienstzeiten'],
        ResetPasswordLinkSent: ['Reset password link sent', 'Enlace enviado', 'Link wurde zugesendet'],
        EmailVerificationCodeSentToX: ['E-mail verification code is sent to {0}.', 'Código de verificación de E-mail enviado a {0}.', 'E-mail Bestätigungscode wurde an {0} gesendet.'],
        NoValidEmailAddressX: ['No valid e-mail address: {0}.', 'Dirección de e-mail inválida {0}.', 'Keine gültige E-Mail Adresse: {0}.'],
        EnterVerificationCode: ['Enter verification code', 'Ingrese el código de verificación', 'Bestätigungscode eingeben'],
        PhoneVerificationTextWithLink: ['Click {0} to verify your phone number on Hellotracks.', 'Haz clic en {0} para verificar tu número de teléfono en Hellotracks.', 'Klicke {0} um deine Telefonnummer in Hellotracks zu bestätigen.'],
        PhoneSuccessfullyVerified: ['Phone number successfully verified.', 'Número de teléfono verificado con éxito.', 'Telefonnummer erfolgreich bestätigt.'],
        PhoneVerificationCodeSentToX: ['Phone number verification code is sent to {0}.', 'Der Bestätigungscode wurde an {0} zugesendet.', 'Código de verificación enviado a {0}.'],
        NoValidPhoneNumberX: ['No valid phone number: {0}.', 'Número de teléfono inválido: {0}.', 'Keine gültige Telefonnummer: {0}.'],
    },
    Job: {
        AddField: ['Add field', 'Añadir campo', 'Feld hinzufügen'],
        Address: ['Address', 'Dirección', 'Adresse'],
        Advanced: ['Advanced', 'Avanzado', 'Erweitert'],
        CarryOverNextDayIfUnfinished: [
            'Carry over next day if unfinished',
            'Continuar al día siguente hasta finalizar',
            'An Folgetag übertragen falls unerledigt',
        ],
        RememberCarryOverSetting: [
            "Remember the carry over setting for future jobs.",
            "Recordar la configuración de continuar al día siguente para tareas futuras.",
            "Die Einstellung der Übetragung an Folgetag für zukünftige Aufträge speichern."
        ],
        Contact: ['Contact', 'Contacto', 'Kontakt'],
        ContactName: ['Contact name', 'Nombre de contacto', 'Kontakt-Name'],
        ContactPhone: ['Contact phone', 'Teléfono de contacto', 'Kontakt-Telefon'],
        Date: ['Date', 'Fecha', 'Datum'],
        DispatchJobCompletion: [
            'Job completion confirmation / Proof of delivery',
            'Confirmación de finalización del trabajo / Prueba de entrega',
            'Bestätigung abgeschlossener Aufträge / Zulieferungsbeweis',
        ],
        DispatchJobCompletionSend: [
            'Send email to customer on job completion',
            'Enviar email a cliente al finalizar tarea.',
            'Sende Email an Kunde bei Abschließung eines Auftrags',
        ],
        DispatchJobCompletionEditInCompany: [
            "Can be edited in Company > Job Settings",
            "Se puede editar en Empresa > Configuración de Tareas",
            "Kann in Unternehmen > Auftragseinstellungen bearbeitet werden",
        ],
        EmailPreview: [
            "Email preview",
            "Vista previa del correo electrónico",
            "E-Mail-Vorschau",
        ],
        Email: ['E-mail', 'Email', 'E-mail'],
        EndTimeWindow: ['End time window', 'Hora de finalización', 'Endzeitfenster'],
        EnterEmail: ['Enter e-mail', 'Ingresar email', 'E-mail eingeben'],
        FormsLinkedForm: ['Linked Form', 'Formulario vinculado', 'Verknüpftes Formular'],
        FormsLinkedForms: ['Linked Forms', 'Formularios vinculados', 'Verknüpfte Formulare'],
        High: ['High', 'Alta', 'Hoch'],
        JobDetails: ['Job Details', 'Detalles de la tarea', 'Auftrag Details'],
        JobInfo: ['Job Info', 'Información', 'Auftragsinfo'],
        JobTypeDropoff: ['Dropoff', 'Entregar', 'Zuliefern'],
        JobTypePickup: ['Pickup', 'Recoger', 'Abholen'],
        JobTypeWork: ['Work', 'Trabajo', 'Arbeit'],
        Latitude: ['Latitude', 'Latitud', 'Breitengrad'],
        LinkedZone: ['Linked Zone', 'Zona vinculada', 'Verknüpfte Zone'],
        LinkedZones: ['Linked Zones', 'Zonas vinculadas', 'Verknüpfte Zonen'],
        Longitude: ['Longitude', 'Longitud', 'Längengrad'],
        Low: ['Low', 'Baja', 'Niedrig'],
        NewEmail: ['New e-mail', 'Nuevo email', 'Neue E-Mail'],
        NewJob: ['New Job', 'Nueva tarea', 'Neuer Auftrag'],
        Normal: ['Normal', 'Normal', 'Normal'],
        Notes: ['Notes', 'Notas', 'Notizen'],
        NumberOfItemsToDropoff: [
            "Number of items to drop off",
            "Número de artículos para dejar",
            "Anzahl der abzuliefernden Objekte"
        ],
        NumberOfItemsToPickup: [
            "Number of items to pick up",
            "Número de artículos para recoger",
            "Anzahl der abzuholenden Objekte"
        ],
        Dropoff: ['Drop-off', 'Entregar', 'Abladen'],
        Pickup: ['Pick-up', 'Recoger', 'Aufladen'],
        OnCompleting: ['on completing', 'al completar', 'bei Erfolg'],
        OnIssue: ['on issue', 'al reportar problema', 'bei Problem'],
        OnSiteCaption: [
            'Duration of how long you expect the worker to reside on site',
            'Duración de cuánto tiempo esperas que el empleado resida en el sitio',
            'Dauer des Auftrags (wie lange braucht der Mitarbeiter voraussichtlich für den Auftrag)',
        ],
        RememberOnSiteSetting: [
            "Remember the duration setting for future jobs",
            "Recordar la configuración de duración para tareas futuras",
            "Die Zeiteinstellung speichern für zukünftigen Aufträge"
        ],
        OnSiteTime: ['On site', 'Tiempo en lugar', 'Vor Ort'],
        OrderId: ['Order #', '# de orden', 'Auftrags-Nr'],
        OrderIdCaption: [
            "The order number can be used to identify a job and likely represents your internal service number for this job.",
            "El número de orden se puede utilizar para identificar una tarea y probablemente representa su número de servicio interno para esta tarea.",
            "Die Auftragsnummer kann verwendet werden, um einen Auftrag zu identifizieren und stellt wahrscheinlich die interne Servicenummer für diesen Auftrag dar."
        ],
        PickupAndDelivery: ['Pickup & Delivery', 'Recolección y entrega', 'Auf- und Abladen'],
        Priority: ['Priority', 'Prioridad', 'Priorität'],
        ReallyDeleteThisJob: [
            'Do you want to delete this job?',
            '¿Quieres borrar esta tarea?',
            'Willst du diesen Auftrag löschen?',
        ],
        ReallyDeleteThisTemplate: [
            'Do you want to delete this template?',
            '¿Quieres borrar esta plantilla?',
            'Willst du diesen Vorlage löschen?',
        ],
        SendNow: ['Send now', 'Enviar ahora', 'Jetzt senden'],
        SetAsDefault: ['Set as Default', 'Fijar por defecto', 'Als Default setzen'],
        StaffInput: ['Form Responses', 'Respuestas del Forumlario', 'Antworten des Formular'],
        StartTimeWindow: ['Start time window', 'Hora de inicio', 'Startzeitfenster'],
        StatusHistory: ['Status History', 'Historial', 'Statusverlauf'],
        Type: ['Type', 'Tipo', 'Typ'],
        UploadedImages: ['Uploaded Images', 'Imágenes adjuntas', 'Angehängte Fotos'],
        minutes: ['minutes', 'minutos', 'Minuten'],
        ManageJobTypeDesc: [
            'You can create or remove custom job types here',
            'Aquí puede crear o eliminar los tipos de tarea personalizados',
            'Du kannst hier benutzerdefinierte Auftragstypen erstellen oder entfernen.',
        ],
        JobType: [
            'Custom job type',
            'Tipo de trabajo personalizado',
            'Benutzerdefinierte Auftragstyp',
        ],
        JobTypeDeleteDesc: [
            'By removing a custom job type, it will also be removed from any other job with the same job type previously created.',
            'Al remover un tipo de tarea, se le removerá también a las tareas creadas previamente.',
            'Bei Entfernen dieses Auftragstyp wird dieser auch von allen anderen Aufträgen, die den selben Typ haben, entfernt.',
        ],
        CarryOver: [
            'Carry over',
            'Continuar',
            'Übertragen',
        ],
        NoCarryOver: [
            'No carry over',
            'No continuar',
            'Nicht übertragen',
        ],
        CarryOverQuestion: [
            'Carry over?',
            '¿Continuar?',
            'Übertragen?',
        ],
        ChooseCarryOver: [
            "Choose when to carry over unfinished jobs to the next day",
            "Elige cuándo trasladar tareas no terminadas al día siguiente",
            "Wähle, wann nicht abgeschlossene Aufträge auf den nächsten Tag übertragen werden sollen",
        ],
        CarryOverIfNotDoneOrIssue: [
            "Carry over if not Done or Issue",
            "Trasladar si no está Terminado o Problema",
            "Übertragen, wenn nicht Erledigt oder Problem",
        ],
        JobFinishedIfMarkedAsEither: [
            "Job is considered finished if marked as either",
            "La tarea se considera terminada si está marcada como cualquiera",
            "Auftrag gilt als abgeschlossen, wenn er als eines von beiden markiert ist",
        ],
        CarryOverIfNotDone: [
            "Carry over if not Done",
            "Trasladar si no está Terminado",
            "Übertragen, wenn nicht Erledigt",
        ],
        JobFinishedOnlyIfDone: [
            "Job is considered finished only if marked as Done",
            "La tarea se considera terminada solo si está marcada como Terminada",
            "Auftrag gilt nur als abgeschlossen, wenn er als Erledigt markiert ist",
        ],
        ImportJobs: [
            'Import Jobs',
            'Importar Tareas',
            'Aufträge importieren',
        ],
        ImportJobsTitle: [
            'Import Jobs from CSV or Excel file',
            'Importar tareas desde un archivo CSV o Excel',
            'Aufträge von CSV oder Excel-Datei importieren',
        ],
        ImportJobsInfo: [
            '<p>Please structure your Excel/CSV file correctly, i.e. headers must be correct, and data formatting should be consistent.</p><p>You can download this <strong>template</strong>: <a href="{0}" download>CSV template</a>, <a href="{1}" download>Excel template</a>.<ul><li>Do not rename header fields</li><li>You may remove columns</li><li>Leave cells empty if they don\'t apply</li><li>If you use the CSV format, make sure the file encoding is in UTF-8</li></ul>',
            '<p>Por favor aseg&uacute;rate que tu archivo Excel/CSV tiene encabezados correctos y datos consistentes.</p><p>Puedes descargar una plantilla de ejemplo aqu&iacute;: <a href="{0}" download>plantilla de CSV</a>, <a href="{1}" download>plantilla de Excel</a>.</p><ul><li>No modifiques encabezados</li><li>Puedes remover columnas</li><li>Deja campos vac&iacute;os si no aplican</li><li>Aseg&uacute;rate de que el formato del CSV esta en UTF-8</li></ul>',
            '<p>Die Struktur der Excel/CSV Datei muss korrekt sein, d.h. die Kopfzeile muss dem richtigen Format entsprechen.</p><p>Du kannst dieses <strong>Template</strong> runterladen: <a href="{0}" download>CSV Template</a>, <a href="{1}" download>Excel Template</a>.</p><ul><li>Die Felder der Kopfzeile nicht &auml;ndern</li><li>Kolumnen enternen ist erlaubt</li><li>Du kannst Felder leer lassen</li><li>Wenn du das CSV-Format verwendest, versichere dich, dass das Datei-Encoding in UTF-8 ist.</li></ul>'
        ],
        XJobs: ['{0} jobs', '{0} tareas', '{0} Aufträge'],
        OneJob: ['1 job', '1 tarea', '1 Auftrag'],
        TodaysJobs: ["Today's Jobs", 'Tareas para hoy', 'Aufträge von Heute'],
        FilterByTitleAddressOrder: [
            "Filter jobs by title, address, order number",
            "Filtrar tareas por título, dirección, número de pedido",
            "Aufträge nach Titel, Adresse, Bestellnummer filtern",
        ],
        DropJob: [
            "Drop job",
            "Dejar caer",
            "Arbeitsplätze fallen",
        ],
        DropJobAndArrange: [
            "Drop jobs between Start and End and arrange order to create a route",
            "Puedes soltar tareas entre Comienzo y Final y organizar el orden para crear una ruta",
            "Mit Drag und Drop kanns du Aufträge zw. Start und Ende ziehen und die Reihenfolge einrichten, um eine Route zu erstellen",
        ],
        ZoomToJobs: [
            "Zoom to jobs",
            "Zoom a tareas",
            "Zu Aufträge zoomen",
        ],
        DropJobsToUnassign: [
            "Drop jobs to unassign",
            "Soltar tarea para asignar",
            "Aufträge herziehen um Zuweisung zu löschen",
        ],
        LinkedZonesDesc: [
            "Linked zones are displayed on the assigned worker's Hellotracks mobile app. You'll receive check-ins of assigned workers if they enter the zone.",
            "Las zonas vinculadas se muestran en la aplicación móvil Hellotracks del empleado asignado. Recibirá registros de tareas asignados si ingresan a la zona.",
            "Verknüpfte Zonen werden in der Hellotracks-Mobil-App des zugewiesenen Mitarbeiters angezeigt. Check-ins von zugewiesenen Mitarbeitern erscheinen, wenn sie die Zone betreten.",
        ],
        LinkedFormsDesc: [
            "If you link a form to a job assignees of the job have a direct link to fill out the form within the job view in their Hellotracks mobile app.",
            "Si vincula un formulario a una tarea, los asignados de la tarea tienen un enlace directo para completar el formulario dentro de la vista de la tarea en su aplicación móvil Hellotracks.",
            "Wenn du ein Formular mit einem Auftrag verknüpfst, haben die auftragsempfänger einen direkten Link zum Ausfüllen des Formulars in der Auftragsansicht in der Hellotracks-App für Mobilgeräte.",
        ],
        ManualJobCheckin: [
            'Manual check-in',
            'Check-in manual',
            'Manueller check-in',
        ],
        ManualJobCheckout: [
            'Manual check-out',
            'Check-out manual',
            'Manueller check-out',
        ],
        EditJobTypes: [
            "Edit job types",
            "Editar tipos de tarea",
            "Auftragstypen bearbeiten",
        ],
        EnterJobTitle: [
            "Enter job title",
            "Ingresa el título de la tarea",
            "Auftragstitel eingeben",
        ],
        ArchivedJobsDesc: [
            "Archived jobs are not visible to field staff and are not available to dispatch. You can restore or delete archived jobs.",
            "Las tareas archivadas no son visibles para el personal de campo y no están disponibles para el despacho. Puedes restaurar o eliminar las tareas archivadas.",
            "Aufträge sind für das Personal nicht sichtbar und stehen nicht zur Disposition. Du kannst archivierte Aufträge wiederherstellen oder löschen.",
        ],
        AssignJobToRoute: [
            "Assigning this job to the selected route",
            "Asignar esta tarea a la ruta seleccionada",
            "Auftrag der ausgewählten Route zuweisen",
        ],
        SearchJobAndAssign: [
            "Search for job and assign",
            "Buscar tarea y asignar",
            "Auftrag suchen und zuweisen",
        ],
        JobSettings: [
            "Job Settings",
            "Configuración Tareas",
            "Einstellungen Aufträge",
        ],
        NarrowTimeframe: [
            "Consider narrowing the timeframe to ensure all relevant jobs are included in the list.",
            "Considera reducir el plazo para asegurarse de que se incluyan todas las tareas relevantes en la lista.",
            "Erwäge den Zeitrahmen einzugrenzen, um sicherzustellen, dass alle relevanten Aufträge in der Liste enthalten sind.",
        ],
        XSelectedJobs: [
            "{0} selected jobs",
            "{0} tareas seleccionadas",
            "{0} ausgewählte Aufträge",
        ],
        OneSelectedJob: [
            "1 selected job",
            "1 tarea seleccionada",
            "1 ausgewählter Auftrag",
        ],
        Requirements: [
            "Requirements",
            "Requisitos",
            "Anforderungen"
        ],
        SkillsRequired: [
            "Skills required",
            "Habilidades requeridas",
            "Erforderliche Fähigkeiten"
        ],
        SkillsRequiredDesc: [
            "A listing of the skills necessary to complete this job, which are taken into account during route solving to meet routing requirements. This includes specific technical knowledge, certifications, and experience with essential tools and equipment.",
            "Una lista de las habilidades necesarias para completar esa tarea, que se tienen en cuenta durante la resolución de rutas para cumplir con los requisitos de enrutamiento. Esto incluye conocimientos técnicos específicos, certificaciones y experiencia con herramientas y equipos esenciales.",
            "Eine Auflistung der Skills, die für die Erledigung dieser Aufgabe erforderlich sind und bei der Routenplanung berücksichtigt werden, um die Routing-Anforderungen zu erfüllen. Dies umfasst spezifische technische Kenntnisse, Zertifizierungen und Erfahrung mit wesentlichen Werkzeugen und Ausrüstungen."
        ],
        EquipmentNeeded: [
            "Equipment needed",
            "Equipo necesario",
            "Benötigte Ausrüstung"
        ],
        EquipmentNeededDesc: [
            "Listing any special tools or equipment required for the job, which ensures that field staff arrive prepared.",
            "Listado de cualquier herramienta o equipo especial requerido para la tarea, lo que garantiza que el personal de campo llegue preparado.",
            "Auflistung aller speziellen Werkzeuge oder Ausrüstungen, die für den Auftrag erforderlich sind, was sicherstellt, dass das Außendienstpersonal vorbereitet ankommt."
        ],
        DateAndTime: [
            "Date and Time",
            "Fechy y Hora",
            "Datum und Zeit",
        ],
        Expanded: ["Unfolded", "Desplegado", "Ausgeklappt"],
        Compact: ["Compact", "Compacto", "Kompakt"],
        Comments: [
            "Comments",
            "Comentarios",
            "Kommentare",
        ],
        AddComment: [
            "Add comment",
            "Agregar comentario",
            "Kommentar hinzufügen",
        ],
        EnterComment: [
            "Enter comment",
            "Ingresar comentario",
            "Kommentar eingeben",
        ],
        Labels: [
            "Labels",
            "Etiquetas",
            "Labels"
        ],
        AddLabel: [
            "Add Label",
            "Agregar etiquta",
            "Label hinzufügen"
        ],
        EnterLabel: [
            "Enter label",
            "Ingresar etiquta",
            "Label eingeben",
        ],
        CarryJobOver: [
            "Carry job over to next day if marked as Issue 👎 by worker",
            "Lleva la tarea al día siguiente si el empleado lo marca como Problema 👎",
            "Übertrage den Auftrag auf den nächsten Tag, wenn er vom Arbeiter als Problem 👎 markiert wird"
        ]
    },
    JobStatuses: {
        Accepted: ['Started', 'Empezado', 'Gestartet'],
        Issue: ['Issue', 'Incompleta', 'Problem'],
        InProgress: ['In progress', 'En progreso', 'In Bearbeitung'],
        Scheduled: ['Scheduled', 'Programada', 'Geplant'],
        Done: ['Done', 'Completa', 'Erfolg'],
        Unassigned: ['Unassigned', 'Sin asignar', 'Nicht zugewiesen'],
        Rejected: ["Rejected", "Rechazado", "Abgelehnt"],
    },
    WorkingHours: {
        CopyTime: ['Copy time to all', 'Copiar horas a todos', 'Zeiten zu allen kopieren'],
        DefaultWorkingHoursNoTeam: [
            'Default Working Hours (no team)',
            'Horario de trabajo (sin equipo)',
            'Standard Dienstzeiten (kein Team)',
        ],
        IndividualAvailability: ['Working Hours', 'Horas Laborales', 'Dienstzeiten'],
        IndividualAvailabilityDesc: [
            "You can set the individual working hours for this account (overruling the team's working hours). It is used e.g. on applying Auto-Assign in Dispatch.",
            'Puedes configurar el horario de trabajo de un miembro aquí (rechazando el horario de trabajo del equipo). Esto se tomará en cuenta p.e. al aplicar Auto Asignación en Despacho. ',
            'Die Dienstzeiten eines Mitglieds kann für alle Mitglieder pro Wochentag gesetzt werden (falls gesetzt wird für dieses Mitglied die Dienstzeit des Teams ignoriert). Die Dienstzeit wird z.B. während der Auto-Zuweisung in der Auftragsverwaltung berücksichtigt.',
        ],
        IndividualRouteStartEndLocation: [
            'Individual (overrules team setting)',
            'Individual (anula la configuración del equipo)',
            'Individuell (überschreibt Team-Einstellung)',
        ],
        TeamAvailability: ['Team Working Hours', 'Horario de trabajo del equipo', 'Team-Dienstzeiten'],
        UseIndividualAvailabilityForX: [
            "Use individual working hours for {0} (overrules the team's working hours)",
            'Usar horario de trabajo individual para {0} (rechaza el horario de trabajo del equipo)',
            'Individuelle Dienstzeit für {0} verwenden (überschreibt die Team-Dienstzeit)',
        ],
    },
    Routing: {
        QuickRouteDesc: [
            "Create multiple jobs quickly via Route tool",
            "Crea múltiples tareas rápidamente a través de la herramienta de ruta",
            "Erstelle schnell mehrere Aufträge über das Routenwerkzeug"
        ],
        Start: ["Route Start", "Comienzo de la ruta", "Start der Tagesroute"],
        End: ["Route End", "Final de la ruta", "Ender der Tagesroute"],
        Route: ['Route', 'Ruta', 'Route'],
        RouteCalculationIssue: [
            "Route Calculation Issue",
            "Problema de Cálculo de Ruta",
            "Problem bei der Routenberechnung",
        ],
        CalculatedRoute: ['Calculated Route', 'Ruta calculada', 'Berechnete Route'],
        EndLocation: ['End location', 'Ubicación final', 'End-Punkt'],
        IndividualRouteStartEndLocation: [
            'Individual (overrules team setting)',
            'Individual (anula la configuración del equipo)',
            'Individuell (überschreibt Team-Einstellung)',
        ],
        MaxNumberOfItems: ['Max number of items', 'Número máximo de unidades', 'Maximale Anzahl an Artikel'],
        OnSiteTime: ['On site', 'Tiempo en lugar', 'Vor Ort'],
        OptimizeRoute: ['Optimize Route', 'Optimizar Ruta', 'Route optimieren'],
        OptimizeDistance: ['Optimize Distance', 'Optimizar Distancia', 'Distanz optimieren'],
        OptimizeDistanceTooltip: [
            "Optimize for distance & time with traffic",
            "Optimizar por distancia y tiempo con tráfico",
            "Optimieren für Strecke & Zeit mit Verkehr",
        ],
        OptimizeRouteDesc: [
            'Route optimization reorders your jobs to reduce travel time and mileage.',
            'La optimización de ruta reorganiza tus trabajos para reducir el tiempo de viaje y la distancia recorrida.',
            'Die Routenoptimierung sortiert Ihre Aufträge um, um Reisezeit und Kilometerleistung zu reduzieren.',
        ],
        SolveRoute: [
            "Solve Route",
            "Resolver Ruta",
            "Route lösen",
        ],
        SolveRouteTooltip: [
            "Optimize route with all constraints: time windows, capacity, skills, working hours",
            "Optimiza la ruta con todas las restricciones: ventanas de tiempo, capacidad, habilidades, horas laborales",
            "Optimiere die Route mit allen Einschränkungen: Zeitfenster, Kapazität, Fähigkeiten, Arbeitszeiten",
        ],
        UnableToAssign: [
            "Unable to assign",
            "No asignable",
            "Nicht zuweisbar",
        ],
        WaitingTime: [
            "Waiting",
            "Espera",
            "Wartezeit",
        ],
        OptimizedRoute: ['Optimized Route', 'Ruta optimizada', 'Optimierte Route'],
        Routing: ['Routing', 'Enrutamiento', 'Routing'],
        RoutingAutoAssignSettings: [
            'Auto-Assign Settings',
            'Configuración para la auto-asignación',
            'Einstellungen für Auto-Zuweisung',
        ],
        RoutingDesc: [
            'Select the route start- and end-locations for your teams. Note that this only applies to users that do not have individual route start-/end-locations set.',
            'Seleccione las ubicaciones de inicio y fin de ruta para sus equipos. Recuerda que solo aplica a los usuarios que no tienen configuradas ubicaciones de inicio / fin de ruta individuales.',
            'Wähle die Start- und Endorte für die Route pro Team aus. Beachte, dass dies nur für Nutzer gilt, für die keine individuellen Start-/Endpositionen für Routen festgelegt sind.',
        ],
        RoutingEnd: ['Routing end', 'Enrutamiento llegada', 'Routing Ende'],
        RoutingMaxNumberOfJobs: [
            'Max number of jobs per day and worker',
            'Número máximo de tareas por día y por empleado',
            'Max. Anzahl von Aufträgen pro Tag und Arbeiter',
        ],
        RoutingNothingSet: [
            "The route's first job will be the start-location, and the route's last job will will be the end-location of the route.",
            'La primera tarea será la ubicación de inicio y la última tarea será la ubicación final de la ruta.',
            'Der erste Auftrag der Route ist der Startort, und der letzte Auftrag der Route ist der Endort der Route.',
        ],
        RoutingNothingSetDesc: [
            "No individual start- and end-location are set for this user, and there are no start/end-locations set for this user's team.",
            'No hay ubicaciones de inicio y fin de ruta establecidas para este usuario y no hay ubicaciones de inicio / fin establecidas para el equipo de este usuario.',
            'Für diesen Nutzer sind keine individuellen Start- und Endpositionen festgelegt, und für das Team dieses Nutzers sind keine Start-/Endpositionen festgelegt.',
        ],
        RoutingOriginDestination: ['Origin and Destination', 'Origen y Destino', 'Abfahrt und Ankunft'],
        RoutingPreferences: ['Routing Preferences', 'Preferencias de ruta', 'Routing Einstellungen'],
        RoutingPreferencesDesc: [
            'Here you can modify preferences for calculating and optimizing routes ',
            'Aquí puedes modificar tus preferencias para calcular y optimizar rutas.',
            'Hier sind die Einstellungen für die Berechnung und Optimierung der Routen. ',
        ],
        RoutingRouteStartTime: ['Route start time', 'Hora de inicio de ruta', 'Routenstart-Zeit'],
        RoutingSettings: ['Routing Settings', 'Ajustes de Enrutamiento', 'Routing Einstellungen'],
        RoutingStart: ['Routing start', 'Enrutamiento salida', 'Routing Start'],
        RoutingStartEnd: ['Routing start/end:', 'Enrutamiento salida/llegada:', 'Routing Start/Ende:'],
        RoutingTeamUsed: [
            "The start- and end-location of the user's team will be applied.",
            'Se aplicará la ubicación inicial y final del equipo del usuario.',
            'Der Start- und Endort des Teams wird angewendet.',
        ],
        RoutingTitle: [
            'Start- and end-locations for directions and route optimization',
            'Ubicaciones de inicio y fin para direcciones y optimización de rutas',
            'Start- und Endorte für die Routenberechnung und -optimierung',
        ],
        RoutingTrafficModel: ['Traffic model', 'Modo tráfico', 'Verkehrsmodell'],
        RoutingTravelMode: ['Travel mode ', 'Modo de viaje', 'Reisemodus'],
        RoutingUseFixedHours: [
            'Use fixed route start time',
            'Usar hora fija de inicio de ruta',
            'Verwende fixe Routenstart-Zeit',
        ],
        RoutingUseWorkingHours: ['Use working hours', 'Usar horario de trabajo', 'Verwende Dienstzeiten'],
        SetRouteStartTime: ['Set route start time', 'Tiempo de inicio de ruta', 'Start-Zeitpunkt der Route festlegen'],
        StartEndRouteLocation: ['Start/end route location', 'Ubicación inicio/final', 'Routen Start/End-Punkt'],
        StartLocation: ['Start location', 'Ubicación de inicio', 'Start-Punkt'],
        TeamRouting: ['Team routing', 'Enrutamiento equipo', 'Team routing'],
        Travelling: ['Traveling', 'Viaje', 'Fahrt'],
        UseStartEndPointForRounting: [
            'Set start- and end-locations for routing per team',
            'Establecer ubicaciones de inicio y fin para el enrutamiento por equipo',
            'Verwende Start/Endpunkt für Routing pro Team',
        ],
        XStartsRouteAt: ['{0} starts route at', '{0} ruta comienza a las', '{0} startet Route um'],
        RouteCalculationFailed: [
            "Route calculation was not successful. Probably a job location is not set or not accessible by road. Also check start and end location in routing settings.",
            "El cálculo de la ruta no fue exitoso. Probablemente una ubicación de tarea no está establecida o no se puede acceder por carretera. También verifica la ubicación de inicio y finalización en la configuración de enrutamiento.",
            "Die Routenberechnung war nicht erfolgreich. Wahrscheinlich ist ein Auftragsort nicht per Straße zugänglich. Überprüfe auch den Start- und Endstandort in den Routing-Einstellungen.",
        ],
        NewRoute: [
            "New Route",
            "Nueva Ruta",
            "Neue Route",
        ],
        SendRouteNotificationToX: [
            "Send route change notification to {0}",
            "Enviar notificación de cambio de ruta a {0}",
            "Benachrichtigung über Routenänderung senden an {0}",
        ],
        StartSimulation: ['Start Route Simulation', 'Iniciar simulación de ruta', 'Routensimulation starten'],
        RouteSimulationStarted: [
            "Route simulation started",
            "Simulación de ruta iniciada",
            "Routensimulation gestartet"
        ],
        RouteSimulationFinished: [
            "Route simulation finished",
            "Simulación de ruta iniciada",
            "Routensimulation abgeschlossen"
        ],
        StartSimulationDesc: [
            'This will start a quick 2 min simulation of this day route',
            'Esto iniciará una simulación rápida de 2 minutos de esta ruta del día.',
            'Dies startet eine schnelle 2-minütige Simulation dieser Tagesroute.',
        ],
        StopSimulation: ['Stop Simulation', 'Terminar simulación', 'Simulation stoppen'],
        SimulationOffDutyDesc: [
            'Route simulation does not work while in off duty.',
            'La simulación de ruta no funciona mientras está fuera del horario de trabajo.',
            'Die Routensimulation funktioniert nicht wenn der Nutzer außer Dienst ist',
        ],
        UndoOptimize: [
            "Undo Optimize",
            "Deshacer optimización",
            "Optimierung rückgängig machen"
        ],
        VehicleCapacityDesc: [
            "This count is relevant for effective route optimization and auto-assignment. Make sure the vehicle's capacity for carrying these items is correctly set in the routing settings.",
            "Este conteo es relevante para la optimización efectiva de la ruta y la asignación automática. Asegúrate de que la capacidad del vehículo para llevar estos artículos esté correctamente configurada en los ajustes de enrutamiento.",
            "Diese Zählung ist relevant für eine effektive Routenoptimierung und automatische Zuweisung. Stellen Sie sicher, dass die Kapazität des Fahrzeugs für den Transport dieser Gegenstände korrekt in den Routing-Einstellungen festgelegt ist."
        ],
        LoadCapacityDesc: [
            "Enter the Maximum Load Capacity: Specify the number of items this vehicle can carry. This information is crucial for optimizing routes and auto-assigning jobs, taking into account the pick-up and drop-off requirements of each task.",
            "Ingresa la Capacidad Máxima de Carga: Especifica la cantidad de artículos que este vehículo puede llevar. Esta información es crucial para optimizar rutas y asignar tareas automáticamente, teniendo en cuenta los requisitos de recogida y entrega de cada tarea.",
            "Gebe die maximale Ladekapazität ein: Gib die Anzahl der Gegenstände an, die dieses Fahrzeug transportieren kann. Diese Informationen sind entscheidend für die Optimierung von Routen und die automatische Zuweisung von Aufträgen, unter Berücksichtigung der Abhol- und Lieferanforderungen jeder Aufgabe."
        ],
        TravelModeDesc: [
            "Choose the type of vehicle used by this user. This choice is important to create the most efficient routes and provide accurate routing time estimates.",
            "Elija el tipo de vehículo utilizado por este usuario. Esta elección es importante para crear las rutas más eficientes y proporcionar estimaciones de tiempo de ruta precisas.",
            "Wähle die Art des Fahrzeugs, das von diesem Nutzer verwendet wird. Diese Wahl ist wichtig, um die effizientesten Routen zu erstellen und genaue Zeitabschätzungen für die Routenplanung zu liefern."
        ],
        SeeFullRoute: [
            "See full route in map",
            "Ver ruta completa en el mapa",
            "Vollständige Route in der Karte anzeigen",
        ],
        Skills: [
            "Skills",
            "Habilidades",
            "Skills",
        ],
        SkillsCaption: [
            "Enter the skills for this member to ensure accurate job routing based on required competencies.",
            "Ingresa las habilidades de este miembro para asegurar una asignación precisa de tareas basada en las competencias requeridas.",
            "Gib die Skills dieses Mitglieds ein, um eine genaue Zuweisung von Aufträgen basierend auf den erforderlichen Kompetenzen zu gewährleisten.",
        ],
        NoRegionWarning: [
            "Sorry, but we currently do not support solving routes in this region.",
            "Lo siento, pero actualmente no admitimos la resolución de rutas en esta región.",
            "Entschuldigung, aber wir unterstützen derzeit keine Routenplanung in dieser Region.",
        ],
        ImpossibleRouteWarning: [
            "Routing aborted: no viable job assignment path found",
            "Enrutamiento abortado: no se encontró un camino viable para la asignación de tareas",
            "Routenplanung abgebrochen: kein Weg für die Auftragszuweisung gefunden",
        ],
        UnableToAssignCaption: [
            "Please check skills, time windows, capacity and working hours",
            "Por favor verifica habilidades, ventanas de tiempo, capacidad y horarios laborales",
            "Bitte überprüfen Sie Fähigkeiten, Zeitfenster, Kapazität und Arbeitszeiten",
        ],
        MultiRouteOptimizationDescription: [
            "Multi-Route Optimization assigns up to 1000 jobs to multiple employees, considering constraints such as <strong>time windows</strong>, <strong>capacity</strong>, and <strong>skills</strong>.",
            "La optimización de múltiples rutas asigna hasta 1000 trabajos a varios empleados, considerando restricciones como <strong>ventanas de tiempo</strong>, <strong>capacidad</strong> y <strong>habilidades</strong>.",
            "Die Multi-Routen-Optimierung weist bis zu 1000 Jobs verschiedenen Mitarbeitern zu, unter Berücksichtigung von Einschränkungen wie <strong>Zeitfenstern</strong>, <strong>Kapazität</strong> und <strong>Fähigkeiten</strong>."
        ],
        MultiRouteAlignment: [
            "It ensures that each route aligns with individual schedules and capabilities.",
            "Garantiza que cada ruta se alinee con los horarios y capacidades individuales.",
            "Es stellt sicher, dass jede Route mit den individuellen Zeitplänen und Fähigkeiten übereinstimmt."
        ],
        RouteOptimizationTooManyJobs: [
            "Route Optimization is only supported up to 150 jobs. Please use Route Solving instead.",
            "La optimización de rutas solo es compatible hasta 150 tareas. Por favor, usa la solución de rutas en su lugar.",
            "Die Routenoptimierung wird nur bis zu 150 Aufträgen unterstützt. Bitte benutze stattdessen Route lösen."
        ]
    },
    Vehicles: {
        Vehicle: ['Vehicle', 'Vehículo', 'Fahrzeug'],
        VehicleCapacity: ['Vehicle capacity', 'Capacidad del vehículo', 'Kapazität des Fahrzeugs'],
        Vehicles: ['Vehicles', 'Vehículos ', 'Fahrzeuge'],
    },
    TravelModes: {
        Bicycle: ['Bicycle', 'Bicicleta', 'Fahrrad'],
        Car: ['Car', 'Coche', 'Auto'],
        Motorcycle: ['Motorcycle', 'Motocicleta', 'Motorrad'],
        Pedestrian: ['Pedestrian', 'Pedestre', 'Fußgänger'],
        Truck: ['Truck', 'Camión', 'LKW'],
        Van: ['Van', 'Camioneta', 'Van'],
    },
    TeamManagement: {
        CreateNewInviteCode: [
            'Create new invite code',
            'Crea un nuevo código de invitación',
            'Neuer Einladungscode erstellen',
        ],
        InviteCode: ['Invite code', 'Codigo de invitacion', 'Einladungscode'],
        InviteCodeExpired: [
            'This invite code expired',
            'Este código de invitación venció',
            'Dieser Einladungscode ist abgelaufen',
        ],
        ManageInviteCodesDesc: [
            'Share the invite code with all your mobile team members with you messaging app of choice. On mobile, clicking the link will automatically forward to the Hellotracks app in iOS App Store or Android Play Store.',
            'Comparta el código de invitación con todos los miembros de tu equipo. En el dispositivo móvil, al hacer clic en el enlace, se redireccionará automáticamente a la aplicación Hellotracks en la App Store de iOS o Play Store de Android.',
            'Teile den Einladungscode mit allen Teammitgliedern mit der Messaging-App deiner Wahl. Wenn der Link auf dem Smartphone geklickt wird, wird automatisch zur Hellotracks-App im iOS App Store oder Android Play Store weitergeleitet.',
        ],
        NewTeam: ['New team', 'Nuevo equipo', 'Neues Team'],
        NoActiveInviteCode: ['No active invite code', 'Sin código de invitación activo', 'Kein aktiver Einladungscode'],
        TeamName: ['Team Name', 'Nombre del equipo', 'Team Name'],
        ThisInviteCodesExpiresInXDays: [
            'This invite code expires automatically in {0} days.',
            'Este código de invitación vence automáticamente en {0} días.',
            'Dieser Einladungscode läuft automatisch in {0} Tagen aus.',
        ],
        AddMemberToX: ['Add member to {0}', 'Agregar miembro a {0}', 'Mitglied zu {0} hinzufügen'],
        ImportMembers: ['Import members', 'Importar miembros', 'Mitglieder importieren'],
        DeleteTeamDesc: ['Do you want to delete this team?', 'Quieres borrar este equipo?', 'Dieses Team wirklich löschen?'],
        RemoveFromTeam: ['Remove from team', 'Quitar del equipo', 'Von Team entfernen'],
        RemoveXFromY: ['Do you want to remove {0} from {1}?', '¿Quieres quitar {0} de {1}?', '{0} von {1} entfernen?'],
        NoTeamAssigned: [
            "No team assigned",
            "Sin equipo asignado",
            "No team assigned",
        ],
    },
    TripLists: {
        TripPurposeAndComment: [
            "Purpose and comment",
            "Propósito y comentario",
            "Zweck und -kommentar"
        ],
        TripPurposeList: [
            "Trip Purpose List",
            "Lista de Propósitos de Viaje",
            "Reisezweckliste",
        ],
        TripPurposeListCaption: [
            "This is a preset list of trip purposes. Workers have the option to assign a purpose to each trip from this list.",
            "Esta es una lista predefinida de propósitos de viaje. Los trabajadores tienen la opción de asignar un propósito a cada viaje de esta lista.",
            "Dies ist eine voreingestellte Liste von Reisezwecken. Mitarbeiter haben die Möglichkeit, jedem Trip aus dieser Liste einen Zweck zuzuweisen.",
        ],
        TripCommentList: [
            "Trip Comment List",
            "Lista de Comentarios de Viaje",
            "Reisekommentarliste",
        ],
        TripCommentListCaption: [
            "This is a preset list of trip comments. Workers have the option to assign a comment to each trip from this list.",
            "Esta es una lista predefinida de comentarios de viaje. Los trabajadores tienen la opción de asignar un comentario a cada viaje de esta lista.",
            "Dies ist eine voreingestellte Liste von Reisekommentaren. Mitarbeiter haben die Möglichkeit, jedem Trip aus dieser Liste einen Kommentar zuzuweisen.",
        ],
    },
    CustomFields: {
        CustomFields: ['Custom fields', 'Campos personalizados', 'Zusätzliche Felder'],
        CustomFieldsDesc: [
            'Additional fields visible to workers in the field.',
            'Campos adicionales visibles para empleados en el campo.',
            'Zusätliche Felder die für Arbeiter sichtbar sind.',
        ],
        EnterValue: ['Enter value', 'Ingresar valor', 'Wert eingeben'],
        FieldName: ['Field name', 'Nombre del campo', 'Feldname'],
        AddCustomField: [
            'Add custom field',
            'Agregar campo personalizado',
            'Add custom field',
        ],
        DuplicatedFieldError: [
            'Fields must be unique.',
            'Los campos deben ser únicos.',
            'Die Felder müssen eindeutig sein.',
        ]
    },
    Company: {
        AllPlacesAreVisible: ['All places are visible', 'Todos los lugares', 'Alle Plätze'],
        AllZonesAreVisible: ['All zones are visible', 'Todas las zonas', 'Alle Zonen'],
        CheckIns: [
            "Auto Check-ins at Places and Jobs",
            "Registro automático de entrada/salida de lugares y tareas",
            "Automatische Ein- und Auschecken von Plätzen und Aufträgen",
        ],
        ZoneCheckIns: [
            "Check-ins at Zones",
            "Registro de entrada/salida de zonas",
            "Ein- und Auschecken von Zonen",
        ],
        CheckInArea: [
            'Area size for check-ins at Jobs',
            'Tamaño del área para registros en Tareas',
            'Größe für Check-ins bei Aufträgen'
        ],
        CheckInDelayMessage: [
            "Check-ins shorter than the configured delay will be ignored.",
            "Check-ins, die kürzer sind als die konfigurierte Verzögerung, werden ignoriert.",
            "Los registros más cortos que el retraso configurado serán ignorados."
        ],
        CheckInBigRadius: ['Big geofence (~300m/1000ft)', 'Geocerca grande (300m)', 'Große Geofence (300m)'],
        CheckInDelay: ['Delay', 'Retraso', 'Verzögerung'],
        CheckInShortRadius: ['Small geofence (~100m/330ft)', 'Geocerca pequeña (100m)', 'Kleine Geofence (100m)'],
        CheckinSettings: [
            'Check-in/out notifications and on-site',
            'Configuración de Check-in y Check-out',
            'Check-in- und Check-out-Einstellungen',
        ],
        Company: ['Company', 'Empresa', 'Unternehmen'],
        CompanyAddress: ['Company address', 'Dirección de la empresa', 'Adresse des Unternehmens'],
        CompanyLocation: ['Company Location', 'Ubicación de la Empresa', 'Adresse des Unternehmens'],
        CompanyName: ['Company name', 'Nombre de la empresa', 'Name des Unternehmens '],
        EnterCompanyName: ["Enter company name", "Ingresar nombre de la empresa", "Name des Unternehmens eingeben"],
        CompanySettings: ['Settings', 'Configuración', 'Unternehmenseinstellungen'],
        QuickSetup: ["Quick Setup", "Configuración rápida", "Schnelleinrichtung"],
        CompanySetupDesc: [
            'This location will be your first geofence, you can edit it at any time.',
            'Esta ubicación será la primera geocerca, podrás editarla en cualquier momento.',
            'Dieser Standort ist der erster Geofence, du kannst ihn jederzeit bearbeiten.',
        ],
        CompanySetupTitle: ['Enter the Company Address', 'La dirección de la empresa', 'Adresse des Unternehmens ein'],
        DeleteTracksOlderThan: ['Delete tracks older than', 'Borrar rutas', 'Tracks löschen älter als'],
        DisableTrackingIfUnavailable: [
            'Disable tracking outside individual/team working hours',
            'Desactivar el seguimiento fuera del horario de trabajo del miembro/equipo',
            'Deaktiviere Tracking außerhalb der Dienstzeiten',
        ],
        WorkersMaySendMessage: [
            "Workers may send messages to members of the same team",
            "Los trabajadores pueden enviar mensajes a miembros del mismo equipo",
            "Arbeiter dürfen Nachrichten an Mitglieder desselben Teams senden"
        ],
        DispatchersAsWorkers: [
            'Operators are visible on the map and may receive jobs',
            'Los operadores están visibles en el mapa y pueden recibir tareas.',
            'Disponenten sind in der Karte sichtbar und können selbst Aufträge empfangen',
        ],
        EnableAutoCheckin: [
            'Enable auto check-ins at places and jobs. With check-ins you get notified on arrivals and departures of your Places and Jobs.',
            'Permitir check-ins automáticos en lugares y tareas. Con los check-ins se te notifica sobre las llegadas y salidas de tus lugares y tareas.',
            'Aktiviere automatische Check-ins für Plätze und Aufträge. Mit Check-Ins wirst du über Ankünfte und Abfahrten deiner Plätze und Aufträge benachrichtigt.',
        ],
        EnableZoneCheckin: [
            "Enable automatic check-ins at zones. If enabled, you will receive notifications upon entering and exiting the regions of your zones.",
            "Habilitar check-ins automáticos en zonas. Si está activado, recibirás notificaciones al entrar y salir de las regiones de tus zonas.",
            "Automatische Einchecken in Zonen aktivieren. Wenn aktiviert, erhältst du Benachrichtigungen beim Betreten und Verlassen der Regionen deiner Zonen."
        ],
        JobSettingsForWorkers: [
            'Job settings for workers (mobile app)',
            'Configuración de tareas para empleados (aplicación movil)',
            'Auftrags-Einstellungen für Arbeiter (mobile App)',
        ],
        MapSettingsForWorkers: [
            'Map settings for workers (mobile app)',
            'Configuración de mapa para empleados (aplicación movil)',
            'Karten-Einstellungen für Arbeiter (mobile App)',
        ],
        Notifications: ['Notifications', 'Notificaciones', 'Benachrichtigungen'],
        OnlyAssignedPlacesAreVisible: [
            'Only places assigned to same team are visible',
            'Sólo los lugares asignados al mismo equipo',
            'Nur Plätze mit zugewiesenem Team',
        ],
        OnlyAssignedZonesAreVisible: [
            'Only assigned zones are visible',
            'Sólo las zonas asignadas',
            'Nur zugewiesene Zonen',
        ],
        OvernightJobs: ['Overnight jobs', 'Tareas durante la noche', 'Aufträge über Nacht'],
        OvernightJobsDesc: [
            "Hold worker's assigned jobs overnight until working hours end.",
            'Mantener tareas asignadas por la noche hasta que el horario de trabajo del empleado finalice.',
            'Die zugewiesenen Aufträge über Nacht halten bis die Arbeitszeiten des Mitarbeiters enden.',
        ],
        PermissionAdmin1: [
            'Administrators have all permissions, including the ability to add and remove users.',
            'Los administradores tienen todos los permisos, incluida la capacidad de agregar y eliminar usuarios.',
            'Administratoren haben alle Berechtigungen, einschließlich der Möglichkeit, Nutzer hinzuzufügen und zu entfernen.',
        ],
        PermissionAdmin2: [
            'Administrators can use both the web dashboard and the mobile app, but they will never be visible to operators or workers.',
            'Los administradores pueden usar tanto el panel web como la aplicación móvil, pero nunca serán visibles para los operadores o empleados.',
            'Administratoren können sowohl das Web-Dashboard als auch die mobile App verwenden, aber sie sind niemals für Disponenten oder Mitarbeiter sichtbar.',
        ],
        PermissionAdmin3: [
            "Administrators have access to all member locations, including other Administrators using the mobile app.",
            "Administradores tienen acceso a la ubicación de todos los miembros, incluido otros administradores utilizando la aplicación móvil.",
            "Administradoren haben Zugriff auf den Standort aller Mitglieder, einschließlich anderer Administratoren, die die mobile App verwenden.",
        ],
        PermissionForAdministrators: [
            'Permissions for Administrators',
            'Permisos para administradores',
            'Berechtigungen für Administratoren',
        ],
        PermissionOperator1: [
            'Operators can use the web dashboard, monitor live location and dispatch jobs.',
            'Los operadores pueden usar el panel web, monitorear la ubicación en vivo y enviar tareas.',
            'Disponenten können das Web-Dashboard verwenden, den Live-Standort überwachen und Aufträge versenden.',
        ],
        PermissionOperatorsMayUseAssistant: [
            "Operators may use the AI assistant to create jobs.",
            "Los operadores pueden utilizar el asistente de AI para crear tareas.",
            "Operatoren können den KI-Assistenten zur Erstellung von Aufträgen verwenden."
        ],
        PermissionOperatorMayDeleteJobs: [
            'Operators have permission to delete jobs',
            'Los operadores tienen permiso para eliminar tareas',
            'Disponenten haben die Berechtigung, Aufträge zu löschen',
        ],
        PermissionOperatorMaySeeEverybody: [
            'Operators may see workers of other teams on the map',
            'Los operadores pueden ver empleados de otros equipos en el mapa',
            'Disponenten können Arbeiter anderer Teams in der Karte sehen',
        ],
        PermissionWorkersMayAccessTheJobCalendar: [
            'Workers may access the job calendar',
            'Los empleados tienen acceso al calendario de tareas',
            'Arbeiter haben Zugriff auf den Auftragskalender',
        ],
        PermissionWorkersMayReassignDateOfJob: [
            'Workers may reassign the date of the job',
            'Los empleados pueden reasignar la fecha de una tarea',
            'Arbeiter dürfen das Datum des Auftrages bearbeiten',
        ],
        PermissionWorkersMayRelocateJob: [
            'Workers may update location of jobs',
            'Los empleados pueden cambiar la ubicación de una tarea',
            'Arbeiter können den Standort eines Auftrages ändern',
        ],
        PermissionWorkersMaySeeOtherWorkers: [
            'Workers may see members of the same team on the map',
            'Los empleados pueden ver los miembros de su equipo',
            'Arbeiter können Teammitglieder auf der Karte sehen',
        ],
        PermissionsForOperators: [
            'Permissions for Operators',
            'Permisos para Operadores',
            'Berechtigungen für Disponenten',
        ],
        PermissionsForWorkers: ['Permissions for Workers', 'Permisos para Empleados', 'Berechtigungen für Arbeiter'],
        PlacesOnMobileApp: ['Places on mobile app', 'Lugares visibles', 'Plätze sichtbar'],
        RejectingAJob: ['Rejecting a job', 'Rechazar tarea', 'Aufträge ablehnen'],
        RejectingAllowWithReassign: [
            'Enabled + auto-reassign to nearest worker',
            'Habilitado + auto-asignar al empleado más cercano',
            'Erlaubt + autom. Zuweisung zu nächsten Arbeiter',
        ],
        RejectingAllowed: ['Enabled', 'Habilitado', 'Erlaubt'],
        RejectingDisallowed: ['Disabled', 'Deshabilitado', 'Nicht erlaubt'],
        SendEmailOnNewMessage: [
            'Send email notification on new messages',
            'Enviar notificación de email al recibir nuevo mensajes',
            'E-Mail-Benachrichtigung bei neuen Nachrichten senden',
        ],
        ShowLiveNotificationsAsPopup: [
            'Show live notification as pop-up',
            'Mostrar notificaciones en vivo como popup',
            'Live-Benachrichtigungen als Popup anzeigen',
        ],
        StatusLabelList: ['Status-List', 'Lista-Estados', 'Status-List'],
        StatusLabelSection: [
            'Predefined statuses workers can pick from, e.g. Available, Busy, On Leave',
            'Estados predefinidos para que los miembros elijan, p. ej. Disponible, Ocupado, Ausente',
            'Vordefinierte Status-Liste aus der Arbeiter selektieren können, z.B. Verfügbar, Beschäftigt, Abwesend',
        ],
        TrackingSettingsForWorkers: [
            'Tracking Settings for workers (mobile app)',
            'Configuración de seguimiento para empleados (aplicación móvil)',
            'Tracking-Einstellungen für Mitarbeiter (mobile App)',
        ],
        WorkersAllowedToTrackingOff: [
            'Workers can deactivate tracking',
            'Los empleados pueden desactivar el seguimiento',
            'Mitarbeiter können Tracking ausschalten',
        ],
        WorkersCreatePlaces: [
            'Workers can create and edit places from the mobile app',
            'Los empleados pueden crear o editar lugares desde la aplicación móvil',
            'Mitarbeiter können Plätze von der App aus erstellen und bearbeiten',
        ],
        WorkersMayOptimizeRoute: [
            'Workers may apply route optimization from within their mobile app',
            'Empleados pueden aplicar optimización de ruta desde la app',
            'Mitarbeiter dürfen Routenoptimierung von der App anwenden',
        ],
        ZonesOnMobileApp: ['Zones on mobile app', 'Zonas visibles', 'Zonen sichtbar'],
        NoCompanyAcc: [
            "No company account",
            "Sin cuenta de empresa",
            "Kein Firmenkonto",
        ],
        SelectThemeDesc: ["Select your preferred theme here", "Elige tu diseño preferido aquí", "Wähle hier dein bevorzugtes Design aus"],
        CompanyPermissions: [
            "Member Permissions",
            "Permisos Miembros",
            "Berechtigungen Mitglieder"
        ],
        TrackingAndLocation: [
            "Location data",
            "Datos de ubicación",
            "Standortdaten",
        ],
        AdvancedMobileSettings: [
            "Advanced mobile app settings",
            "Configuración avanzada para la aplicación móvil",
            "Erweiterte Einstellungen für die Mobil-App",
        ],
        Profile: [
            "Company Profile",
            "Perfil Companía",
            "Profil Unternehmen",
        ],
        SelectZones: [
            "Select Zones",
            "Seleccionar Zonas",
            "Zonen auswählen",
        ],
        RestrictLocationLabel: [
            "Limit location data to specific zones",
            "Limitar datos de ubicación a zonas específicas",
            "Standortdaten auf bestimmte Zonen beschränken"
        ],
        RestrictLocationDesc: [
            "Note: Location data will not be registered outside the selected zones. Only choose this option if you want tracking to be confined to a specific area for everyone.",
            "Nota: Los datos de ubicación no se registrarán fuera de las zonas seleccionadas. Elije esta opción solo si deseas que el seguimiento se limite a un área específica para todos.",
            "Hinweis: Standortdaten werden außerhalb der ausgewählten Zonen nicht registriert. Wähle diese Option nur, wenn du möchtest, dass das Tracking für alle auf einen bestimmten Bereich beschränkt ist.",
        ],
        JobData: [
            "Job Data",
            "Datos del trabajo",
            "Jobdaten"
        ],
        AutoArchiveJobsDesc: [
            "Stale jobs are archived post-last activity, keeping active tasks in play. Archived jobs remain accessible for reassignment as needed.",
            "Las tareas obsoletas se archivan después de la última actividad, manteniendo las tareas activas en juego. Las tareas archivadas permanecen accesibles para su reasignación según sea necesario.",
            "Veraltete Aufträge werden nach der letzten Aktivität archiviert, wobei aktive Aufgaben aktiv bleiben. Archivierte Aufträge bleiben bei Bedarf für eine Neuzuweisung zugänglich."
        ],
        AutoArchiveJobs: [
            "Automatically archive stale jobs after",
            "Archivar automáticamente tareas obsoletas después de",
            "Automatisches Archivieren von veralteten Aufträgen nach"
        ],
        AutoDeleteJobsDesc: [
            "Enabling this option ensures archived jobs are purged after the chosen duration, optimizing workspace efficiency, safeguarding privacy, and ensuring a clutter-free environment.",
            "Activar esta opción garantiza que las tareas archivadas se eliminen después de la duración elegida, optimizando la eficiencia del espacio de trabajo, protegiendo la privacidad y asegurando un entorno libre de desorden.",
            "Durch Aktivieren dieser Option wird sichergestellt, dass archivierte Aufträge nach der gewählten Dauer gelöscht werden, was die Effizienz des Arbeitsbereichs optimiert, die Privatsphäre schützt und eine unübersichtliche Umgebung verhindert."
        ],
        AutoDeleteJobs: [
            "Automatically delete archived jobs after",
            "Eliminar automáticamente tareas archivadas después de",
            "Automatisches Löschen von archivierten Aufträgen nach"
        ]
    },
    Attachments: {
        AddAttachment: ['Add attachment', 'Añadir adjunto', 'Anhang hinzufügen'],
        Attachment: ['Attachment', 'Adjunto', 'Anhang'],
        Attachments: ['Attachments', 'Adjuntos', 'Anhänge'],
        AttachmentsDesc: [
            'You can add attachments as URLs such as Google Forms, PDFs or images. Workers in the field have access to these attachments.',
            'Puedes añadir archivos adjuntos como dirección URL, p. ej. archivos en línea como formas, PDFs o imágenes. Los empleados en el campo tendrán acceso a estos archivos.',
            'Anhänge können als URLs wie z.B. Formulare, PDFs, Bilder die online sind hinzugefügt werden. Arbeiter haben Zugriff auf diese Anhänge.',
        ],
        UploadImage: [
            "Upload image",
            "Cargar imagen",
            "Upload image",
        ],
        SaveTemplate: [
            "Save attachments as default for new jobs",
            "Save attachments as default for new jobs",
            "Save attachments as default for new jobs",
        ]
    },
    ShortForm: {
        ShortFormDesc: [
            'Workers can can fill out a Form on completing a job, upload images and a signature. You can select between a Standard Completion Form shared between multiple jobs or an Individual Completion Form configuration for this job.',
            'Los empleados pueden completar un Formulario al finalizar una tarea, cargar imágenes y una firma. Puedes seleccionar entre un Formulario de Finalización Estándar aplicable como defecto para todas las tareas o un Formulario de Finalización Individual para esta tarea.',
            'Mitarbeiter können nach Abschluss eines Auftrags ein Formular ausfüllen, Bilder und eine Unterschrift hochladen. Du kannst zwischen einem Standard-Formular für Auftragsabschluss, das von mehreren Aufträgen geteilt wird, und einem individuellen Formular für diesen Auftrag wählen.',
        ],
        ShortFormTitle: [
            'Form on job completion',
            'Formulario al finalizar la tarea',
            'Formular für Auftragsabschluss',
        ],
        UseIndividualForm: [
            'Use Individual Form',
            'Usar Formulario Individual',
            'Individuelles Formular verwenden',
        ],
        UseStandardForm: [
            'Use Standard Form',
            'Usar Formulario Estándar',
            'Standard-Formular verwenden',
        ],
        SwitchBackStandardFormDesc: [
            'By switching back to the Standard Completion Form, the current individual form will be erased.',
            'Volver al Formulario de Finalización Estándar, se eliminará el formulario individual actual.',
            'Mit Zurücksetzen auf das Standard-Formular für Auftragsabschluss wird das aktuelle individuelle Formular gelöscht.',
        ],
        StandardForm: [
            'Standard Form',
            'Formulario Estándar',
            'Standard-Formular',
        ],
        ConfigurationOfIndividualForm: [
            'Configure form for this job',
            'Configurar formulario para esta tarea',
            'Formular für diesen Job konfigurieren',
        ]
    },
    Archive: {
        Archive: ['Archive', 'Archivar', 'Archivieren'],
        ArchiveDescription: [
            'Do you want to archive the selected jobs? Archived jobs will disappear from the list but you can always easily restore them afterwards.',
            'Quieres archivar tareas seleccionadas? Tareas archivadas desaparecen de la lista pero pueden ser restabledicas en cualquier momento.',
            'Auswgewählte Aufträge archivieren? Archivierte Aufträge werden in der Tabelle ausgeblendet, können jedoch sehr einfach wieder wiederhergestellt werden.',
        ],
        ArchiveOrDelete: ['Archive or Delete', 'Archivar o Borrar', 'Archivieren or Löschen'],
        ArchiveSelectedJob: ['Archive selected job', 'Archivar tarea seleccionada', 'Ausgewählten Auftrag archivieren'],
        ArchiveXJobs: ['Archive {0} jobs', 'Archivar {0} Tareas', '{0} Aufträge archivieren'],
        Archived: ['Archived', 'Archivado', 'Archiviert'],
        Restore: ['Restore', 'Restablecer', 'Wiederherstellen'],
        RestoreDescription: [
            'Restore selected jobs?',
            'Restablecer tareas seleccionadas?',
            'Ausgewählte Aufträge wiederherstellen?'
        ],
    },
    Clone: {
        Clone: ['Clone', 'Duplicar', 'Klonen'],
        CloneJob: ['Clone Job', 'Duplicar Tarea', 'Auftrag klonen'],
        CloneOfX: ['Clone of {0}', 'Clón de {0}', 'Klon von {0}'],
        CloningCreatesADuplicateOfThisJob: [
            'Cloning creates a duplicate of this job',
            'Duplicar genera una copia de la Tarea',
            'Ein Duplikat dieses Auftrags erstellen',
        ],
    },
    JobTemplates: {
        JobTemplateDesc: [
            'This is a job template. New jobs can apply this template with prefilled fields. Leave fields empty if they should not be applied.',
            'Esta es una plantilla de tarea. Las nuevas tareas pueden usar esta plantilla con campos predeterminados. Deja los campos vacíos si no aplican.',
            'Dies ist eine Auftragsvorlage. Neue Aufträge können diese Vorlage mit vorausgefüllten Feldern anwenden. Felder können leer gelassen werden, wenn sie nicht angewendet werden sollen.',
        ],
        JobTemplateEnterName: [
            'Enter job template name',
            'Ingresa el nombre de la plantilla de tarea',
            'Namen der Auftragsvorlage eingeben',
        ],
        JobTemplateInfo: ['Job Template Info', 'Plantilla', 'Auftrags-Template Info'],
        JobTemplateName: ['Template name', 'Nombre de la plantilla', 'Name der Vorlage'],
        JobTemplates: ['Templates', 'Plantillas', 'Vorlagen'],
        JobTemplatesDesc: [
            'Job templates make it easy to create jobs with prefilled values. You can create job templates by saving an existing job as a job template.',
            'Las plantillas de tareas facilitan la creación de tareas con valores predeterminados. Puedes crear plantillas de tareas cuando guardas una tarea existente como plantilla.',
            'Vorlagen erleichtern das Erstellen von Aufträgen mit vorausgefüllten Werten. Vorlagen können erstellt werden, indem ein vorhandener Auftrag als Vorlage abgespeichert wird.',
        ],
        JobTemplateCreateDesc: [
            "Create new job from this template",
            "Crear nueva tarea con esta plantilla",
            "Neuen Auftrag von diesem Template erstellen",
        ],
        CreateNewJobFromTemplate: [
            "Create new job from template",
            "Crear nueva tarea con plantilla",
            "Neuen Auftrag von Template erstellen",
        ],
    },
    AreYouSure: {
        AreYouSureToDeleteTheEntireConversation: [
            'Do you want to delete the entire conversation?',
            '¿Estas seguro de que quieres borrar toda la conversación?',
            'Das komplette Gespräch wirklich löschen?',
        ],
        AreYouSureToRelocateX: [
            'Are you sure to relocate {0}?',
            'Estas seguro de trasladar {0}?',
            'Position für {0} wirklich wechseln?',
        ],
        AreYouSureToRemoveThisImage: [
            'Are your sure to remove this image?',
            '¿Quitar esta imágen?',
            'Bild wirklich entfernen?',
        ],
        AreYouSureToRemoveX: ['Are you sure to remove {0}?', '¿Quitar {0}?', '{0} wirklich entfernen?'],
        AreYouSureToRemoveXAsContact: [
            'Are you sure to remove {0} as your contact?',
            '¿Quitar {0} de tus contactos?',
            '{0} wirklich als Kontakt entfernen?',
        ],
        AreYouSureToRemoveXFromYourInnerNetwork: [
            'Are you sure to remove {0} from you internal network?',
            '¿Quitar {0} de tu red interna?',
            '{0} wirklich aus dem internen Netzwerk entfernen?',
        ],
        AreYouSureYouWantToDeactivateAccountX: [
            'Are you sure you want to deactivate the account {0}?',
            '¿Estás seguro de desactivar la cuenta {0}?',
            'Bist du dir sicher, dass du das Konto {0} deaktivieren willst?',
        ],
    },
    Members: {
        AllLocationDataWillBeRemovedForThisUser: [
            'All location data will be removed for this user.',
            'Se eliminarán todos los datos de ubicación de este usuario.',
            'Alle Standortdaten werden für diesen Nutzer entfernt.',
        ],
        DeleteMember: ['Delete member', 'Borrar miembro', 'Mitglied löschen'],
        DeleteMemberConfirmPassword: [
            'Please enter your password to confirm deleting member',
            'Por favor ingresar tu contraseña para confirmar la eliminación de miembros',
            'Bitte dein Passwort eingeben um die Löschung der Mitglieder zu bestätigen',
        ],
        SelectMember: ['Select Member', 'Seleccionar Miembro', 'Element auswählen'],
        SelectMembers: ['Select Members', 'Seleccionar Miembros', 'Mitglieder auswählen'],
        ImportMembers: [
            'Import members',
            'Importar miembros',
            'Mitglieder importieren',
        ],
        ImportMembersInfo: [
            '<p>You can import members from a CSV or Excel file. Make sure to include the headers: <strong>Name, Email, Phone, Role</strong>.</p><p><strong>Phone</strong> and <strong>Role</strong> are optional. <strong>Role</strong> defaults to <strong>Worker</strong>. Alternatively you can set it to <strong>Administrator</strong> or <strong>Operator</strong>. E-mail will become the username.',
            '<p>Puedes importar miembros desde un archivo CSV o Excel. Asegúrate de incluir el siguiente encabezado: <strong>Name, Email, Phone, Role</strong>.</p><p><strong>Phone</strong> y <strong>Role</strong> son opcionales. <strong>Role</strong> se establece de forma predeterminada en <strong>Worker</strong>. Alternativamente, puedes configurarlo en <strong>Administrator</strong> u <strong>Operator</strong>. El e-mail será el usuario.',
            '<p>Mitglieder können aus einer CSV- oder Excel-Datei importiert werden. Versichere folgende Kopfzeile: <strong>Name, Email, Phone, Role</strong>.</p><p><strong>Phone</strong> und <strong>Role</strong> sind optional. <strong>Role</strong> ist standardmäßig <strong>Worker</strong>. Alternativ kann man die Rolle auf <strong>Administrator</strong> oder <strong>Disponent</strong> festlegen. E-mail wird zum Nutzername.',
        ],
        ImportMembersTitle: [
            'Import Members from Excel or CSV',
            'Importar miembros desde un archivo CSV ó Excel',
            'Mitglieder von Excel- oder CSV-Datei importieren',
        ],
        NewMember: [
            "New Member",
            "Nuevo miembro",
            "Neues Mitglied",
        ],
        AllMembers: [
            "Members",
            "Miembros",
            "Mitglieder",
        ],
    },
    Sequences: {
        Sequences: ["Recurring routes", "Rutas recurrentes", "Wiederkehrende Routen"],
        SequencesDesc: [
            'Recurring routes represent a saved list of jobs that can be assigned to a member at any time or recurringly. You can save a member\'s day route directly and you can edit the list of jobs by drag and drop.',
            'Las ruta recurrentes representan una lista de tareas guardadas que se pueden asignar a un miembro en cualquier momento o de forma recurrente. Puedes guardar la ruta diaria de un miembro directamente y puedes editar la lista de tareas arrastrando y soltando.',
            'Wiederkehrende Routen stellen eine gespeicherte Liste von Aufträgen dar, die einem Mitglied jederzeit oder wiederholt zugewiesen werden können. Du kannst die Tagesroute eines Mitglieds direkt speichern und die Liste der Aufträge per Drag & Drop bearbeiten.'
        ],
        NewSequence: ['New Route', 'Nueva ruta', 'Neue Route'],
        SaveSequenceAs: [
            'Save route in recurring routes',
            'Guardar ruta en rutas recurrentes',
            'Route in wiederkehrende Routen speichern',
        ],
        SequenceName: [
            'Route Name',
            'Nombre de ruta',
            'Routenname',
        ],
        LoadSequence: [
            'Load recurring route',
            'Cargar ruta recurrente',
            'Wiederkehrende Route laden',
        ],
        LoadSequenceForX: [
            'Load route for {0}',
            'Cargar ruta para {0}',
            'Route für {0} laden',
        ],
        NewEmptySequence: [
            'New empty recurring route',
            'Nueva ruta recurrente vacía',
            'Neue leere wiederkehrende Route',
        ],
        SelectOwnersForX: [
            'Restrict access to selected owners for {0}',
            'Restringir el acceso para {0} a los propietarios seleccionados',
            'Zugriff auf ausgewählte Besitzer beschränken für {0}',
        ],
        SelectOwnersDesc: [
            'Only owners have access to this route',
            'Sólo propietarios tienen acceso a esta ruta',
            'Nur Besitzer haben Zugriff auf diese Route',
        ],
        AssignSequenceX: [
            'Recurringly assign {0}',
            'Asignar recurrentemente {0}',
            'Wiederkehrend zuweisen {0}',
        ],
        SavedSequences: [
            "Saved recurring routes",
            "Rutas recurrentes guardadas",
            "Gespeicherte Routen",
        ],
        SaveAsSequence: [
            "Save as recurring route",
            "Guardar como ruta recurrente",
            "Als wiederkehrende Route speichern",
        ],
        DeleteSequenceQuestion: [
            'Delete route?',
            '¿Eliminar ruta?',
            'Route löschen?',
        ],
        DeleteSequence: [
            'Delete route',
            'Eliminar ruta',
            'Route löschen',
        ],
        RenameSequence: [
            'Rename route',
            'Renombrar ruta',
            'Routenname ändern',
        ],
        Restricted: [
            "Restricted",
            "Restringido",
            "Beschränkt"
        ],
        VisibleForAllOperators: [
            "Visible for all operators",
            "Visible para todos los operadores",
            "Für alle Disponenten sichtbar"
        ],
    },
    Billing: {
        CardSetup: ["Card Setup", "Configuración de tarjeta", "Einrichtung"],
        Payment: ['Billing', 'Pago', 'Bezahlungsmethode'],
        Setup: ['Setup', 'Configuración', 'Setup'],
        SetupPaymentMethodNow: ['Setup payment method now', 'Ingresar método de pago', 'Bezahlmethode wählen'],
        YouWillBeChargedFirstInvoiceDue: ['You will be charged when your first invoice is due!', 'El cobro se realizará cuando recibas tu primera factura!', 'Der Betrag wird automatisch zum ersten fälligen Datum behoben'],
        PaymentMethod: ['Payment Method', 'Método de Pago', 'Zahlungsmethode'],
        Licenses: ['Licenses', 'Licencias', 'Lizenzen'],
        TrialingUntil: ['Trialing until {0}', 'Probando hasta {0}', 'Probe seit {0}'],
        Active: ['Active', 'Activo', 'Aktiv'],
        PastDue: ['Past due', 'Vencida', 'Fällig'],
        CanceledAtX: ['Canceled at {0}', 'Cancelado el {0}', 'Abgebrochen am {0}'],
        Unpaid: ['Unpaid', 'Sin pagar', 'Nicht bezahlt'],
        TeamMember: ['Team Member', 'Miembro del equipo', 'Teammitglied'],
        DelinquentMessage: ['Please update your payment information to continue using the service.', 'Actualiza la información de pago para continuar usando el servicio.', 'Bitte aktualisiere die Zahlungsinformationen, um den Dienst weiterhin nutzen zu können.'],
        UpcomingInvoice: ['Upcoming Invoice', 'Próxima factura', 'Nächste Rechnung'],
        InvoiceLineItems: ['Invoice Items', 'Unidades en la factura', 'Einträge'],
        Period: ['Period', 'Periodo', 'Periode'],
        ApplicationStatus: ['Application Status', 'Estado', 'Applikation-Status'],
        ActiveSinceX: ['Active since {0}', 'Activo desde {0}', 'Aktiv seit {0}'],
        PaymentMethodMissing: ['Payment method missing.', 'Forma de pago faltante.', 'Zahlungsmethode ausständig.'],
        TrialExpiredDesc: ['Your 30-day free trial with Hellotracks Business has expired. Please enter your payment method in order to continue using the service. Contact us at support@hellotracks.com if you have any question. We thank you for your business.', 'Tu prueba gratuita por 30 días con Hellotracks Business ha expirado. Por favor ingresa un método de pago para continuar utilizando el servicio. Contáctanos a support@hellotracks.com si tienes alguna duda. Agradecemos su preferencia.', 'Die kostenlose 30-Tage Probezeit mit Hellotracks Business ist abgelaufen. Bitte gib die Zahlungsmethode ein wenn du den Dienst weiterhin nutzen willst. Bei Fragen kontaktiere uns unter support@hellotracks.com.'],
        Inactive: ['Inactive', 'Inactivo', 'Inaktiv'],
        ActivateAccountNow: ['Activate account now', 'Activar cuenta ahora', 'Konto jetzt aktivieren'],
        Questions: ['Questions?', '¿Preguntas?', 'Fragen?'],
        Deactivate: ['Deactivate', 'Desactivar', 'Deaktivieren'],
        DeactivateCompanyAccount: ['Deactivate company account', 'Desactivar cuenta de la empresa', 'Unternehmensaccount deaktivieren'],
        DeactivateDesc: ['If you deactivate the company account, your subscription will be canceled immediately and you and your team won\'t be able to access the service. Deactivate company account?', 'Si decides desactivar la cuenta de la empresa, tu suscripción será cancelada inmediatamente y no podrás usar más el servicio. ¿Desactivar cuenta?', 'Wenn du das Unternehmenskonto deaktivierst wird dein Abonnement sofort gekündigt und du kannst den Dienst nicht mehr verwenden. Unternehmenskonto wirklich deaktivieren?'],
        DeactivateCompanyAccountReasonTitle: ['If you deactivate the business account, everybody loses access to the web and mobile platforms.', 'Si desactivas la cuenta empresarial, todos pierden el acceso a la web y las plataformas móviles.', 'Wenn du das Business-Konto deaktivierst, verliert jeder den Zugriff auf das Web und die mobilen Plattformen.'],
        DeactivateCompanyAccountReasonDesc: ['Please tell us your reason for cancelling.', 'Selecciona el motivo de la cancelación.', 'Bitte teile uns den Grund für die Deaktivierung mit.'],
        CompanyAccountDeactivatedConfirmation: ['Your account has been successfully deactivated.', 'Tu cuenta ha sido desactivada.', 'Das Konto wurde erfolgreich deaktiviert.'],
        ReasonNoTime: ['No time for deployment', 'Sin tiempo para despliegue', 'Keine Zeit'],
        ReasonTechnical: ['Technical difficulties', 'Dificultades Técnicas', 'Technische Schwierigkeiten'],
        ReasonProvider: ['We chose a different provider', 'Elegimos a otro proveedor', 'Wir haben einen anderen Anbieter ausgewählt'],
        ReasonPrice: ['Price / Cost of service', 'Precio / Costo del Servicio', 'Preis / Servicekosten'],
        ReasonFeature: ['Missing a feature', 'Nos faltó una función', 'Eine Funktion fehlte uns'],
        ReasonDeployment: ['Issues with the deployment', 'Problemas con el despliegue', 'Probleme mit der Bereitstellung'],
        ReasonNotWorking: ['Something not working', 'Algo no funcionó correctamente', 'Etwas funktioniert nicht'],
        ReasonIntegration: ['Missing an integration', 'Necesito una integración con otro software', 'Fehlende Integration'],
        ReasonDifficult: ['Difficult to use', 'Difícil de usar', 'Schwer zu bedienen'],
        ReasonOther: ['Other', 'Otra', 'Andere Gründe'],
        EnterAComment: ['Please enter a comment', 'Deja un mensaje', 'Hinterlasse bitte eine Nachricht'],
        AccountIsActivated: ['Your account has been activated', 'Tu cuenta ha sido activada', 'Dein Konto wurde deaktiviert'],
        Reactivate: ['Reactivate account', 'Reactivar cuenta', "Konto reaktivieren"],
        ReactivateDesc: ['This account has been deactivated. You can reactivate the account.', 'Esta cuenta ha sido desactivada. Puedes reactivar la cuenta.', 'Dieses Konto wurde deaktiviert. Du kannst das Konto reaktivieren.'],
        GoToHomepage: ['Go to homepage', 'Ir a la página principal', 'Gehe zur Startseite'],
        BillingStatus: [
            "Billing Status",
            "Estado de facturación",
            "Abrechnungsstatus",
        ],
        Gain5Min: [
            "Gain 5 min access",
            "Accesa 5 minutos",
            "5 Minuten Zugriff bekommen",
        ],
        Gain5MinTooltip: [
            "This allows you to arrange your account.",
            "Esto te permite ordenar tu cuenta.",
            "Das ermöglicht es dir, dein Konto zu verwalten."
        ],
        AddPaymentMethod: [
            "Add payment method",
            "Agregar método de pago",
            "Zahlungsmethode hinzufügen",
        ],
        Expires: [
            "Expires",
            "Expira",
            "Ablaufdatum"
        ],
        Amount: [
            "Amount",
            "Monto",
            "Betrag",
        ],
        PaymentAttempt: [
            "Payment attempt",
            "Intento de pago",
            "Zahlungsversuch",
        ],
        ShowPastInvoices: [
            "Show past invoices",
            "Mostrar facturas anteriores",
            "Ältere Rechnungen anzeigen",
        ],
        PastInvoices: [
            "Past invoices",
            "Facturas anteriores",
            "Ältere Rechnungen",
        ],
        Default: [
            "Default",
            "Principal",
            "Standard",
        ],
        ReminderMessage: [
            "To continue enjoying our services without interruption, please update your payment details soon.",
            "Para seguir disfrutando de nuestros servicios sin interrupciones, actualiza los detalles de pago pronto.",
            "Um unseren Service weiterhin ohne Unterbrechung zu genießen, aktualisiere bitte bald die Zahlungsdetails.",
        ],
        YouHaveXLeftUntilTrialPeriodEnds: [
            "You have {0} left until trial period ends.",
            "{0} hasta que finalice el período de prueba.",
            "{0} bis zum Ende der Testphase."
        ]
    },
    Alerts: {
        Alert: ["Alert", "Alerta", "Alarm"],
        AlertActiveOn: ["This alert is active on", "Esta alerta está activada", "Dieser Alarm ist aktiv"],
        AlertAndAddContition: ["and (add condition)", "y (condición adicional)", "und (zus. Bedingung)"],
        AlertAnywhere: ["anywhere", "cualquier lugar", "irgendwo"],
        AlertAtPlace: ["at place", "en lugar", "in Platz"],
        AlertAtX: ["at {0}", "en {0}", "in {0}"],
        AlertBetween: ["between", "entre", "zwischen"],
        AlertChecksIn: ["checks in", "check-in", "checkt ein"],
        AlertChecksInOrOut: ["checks in or out", "check-in ó -out", "checkt ein oder aus"],
        AlertChecksOut: ["checks out", "check-out", "checkt aus"],
        AlertDelayCheckIn: [
            "Alert: {0} arrived {1} min late at Job {2}.",
            "Alerta: {0} llegó {1} min tarde a la tarea {2}.",
            "Achtung: {0} ist {1} min zu spät zum Auftrag {2} angekommen.",
        ],
        AlertDelayCheckOut: [
            "Alert: {0} left {1} min late from Job {2}.",
            "Alerta: {0} salió {1} min tarde de la tarea {2}.",
            "Achtung: {0} ist {1} min zu spät vom Auftrag {2} losgefahren.",
        ],
        AlertDelayOnJob: [
            "Alert: {0} left {1} min late from Job {2}.",
            "Alerta: {0} salió {1} min tarde de la tarea {2}.",
            "Achtung: {0} ist {1} min zu spät vom Auftrag {2} losgefahren.",
        ],
        AlertDeleteQuest: [
            "Delete this alert configuration?",
            "¿Borrar esta configuración de alerta?",
            "Diese Alarm-Konfigurations entfernen?",
        ],
        AlertDoesntCheckIn: [
            "doesn't check in until alert time ends",
            "no hay check-in hasta tiempo se acaba",
            "checkt nicht ein bis Alarmzeit endet",
        ],
        AlertDoesntCheckInOrOut: ["doesn't check in or out", "no hay check-in/out", "checkt weder ein noch aus"],
        AlertDoesntCheckOut: [
            "doesn't check out until alert time ends",
            "no hay check-out hasta tiempo se acaba",
            "checkt nicht aus bis Alarmzeit endet",
        ],
        AlertEmailInputPrompt: [
            "Separate multiple addresses with commas",
            "Separar multiples direcciones con comas",
            "Mehrere Adressen mit Kommas teilen",
        ],
        AlertEnteringX: ["Entering {0}", "Llegando a {0}", "Eintritt in {0}"],
        AlertEntersZone: ["Enters Zone", "Llegando a Zona", "Eintritt in Zone"],
        AlertIfCondition: ["if (condition)", "Si (condición)", "wenn (Bedingung)"],
        AlertJobsUpdatedMessage: [
            "Open today's job list now?",
            "¿Abrir lista de tareas para hoy?",
            "Aufträge für heute öffnen?",
        ],
        AlertJobsUpdatedTitle: ["Today's jobs updated", "Tareas actualizadas", "Auftäge aktualisiert"],
        AlertLeavesZone: ["Leaving Zone", "Saliendo de Zona", "Verlassen von Zone"],
        AlertLeavingX: ["Leaving {0}", "Saliendo de {0}", "Verlassen von {0}"],
        AlertLoggedIn: ["Logged in", "Sesión inciada", "Eingeloggt"],
        AlertLoggedOut: ["Logged out", "Sesión terminada", "Ausgeloggt"],
        AlertLongIdleTime: ["Exceeds stationary time", "Excede el tiempo parado", "Langes Stillstehen (stationär)"],
        AlertNewConfig: ["New Config", "Nueva configuración", "Neue Konfiguration"],
        AlertNoConnectionToDevice: [
            "No connection to device",
            "Sin conexión al dispositivo",
            "Keine Verbindung zum Gerät",
        ],
        AlertOrMemberOf: ["or member of", "ó miembro de", "oder Mitglied von"],
        AlertPhoneInputPrompt: [
            "Enter international code e.g. +1 415 222 2111",
            "Ingresar código internacional p.e. +1 415 222 2111",
            "Mit internationalem Code z.B. +49123456",
        ],
        AlertScheduleDaysOfWeek: [
            "Schedule days of the week",
            "Selecciona días de la semana",
            "Wochentage auswu00E4hlen",
        ],
        AlertSendTo: ["Send alert to", "Enviar alerta a", "Sende Alarm zu"],
        AlertTrackingTurnedOff: ["Tracking deactivated", "Seguimiento desactivado", "Tracking deaktiviert"],
        AlertTrackingTurnedOn: ["Tracking active", "Seguimiento activado", "Tracking aktiv"],
        AlertUnvailableWithFirstAction: [
            "Not available for the selected first action",
            "No disponible con la primera acción seleccionada",
            "Nicht verfügbar mit der ausgewählten ersten Aktion",
        ],
        AlertWeekdays: ["weekdays", "días de la semana", "Wochentage"],
        AlertWithin: ["within", "dentro de", "binnen"],
        Alerts: ["Alerts", "Alertas", "Alarms"],
        AlertsConfiguration: ["Alerts configuration", "Configuración de alertas", "Alarmkonfiguration"],
        AlertsConfigurations: ["Configurations", "Configuraciones", "Konfigurationen"],
        AlertsDesc: [
            "Here you can create and edit alert configurations",
            "Aquí puedes crear y editar configuraciones de alertas",
            "Du kannst Alarm-Konfigurationen hier erstellen und bearbeiten",
        ],
        ShowRecentAlerts: [
            "Show recent alerts",
            "Mostrar alertas recientes",
            "Aktuelle Alarms anzeigen",
        ],
        OpenAlertConfig: [
            "Open alert config",
            "Abrir configuracion de alerta",
            "Konfiguration öffnen",
        ],
        RecentAlerts: ['Recent Alerts', 'Alertas recientes', 'Jüngste Benachrichtigungen'],
        ConfigureAlerts: ['Configure alerts', 'Configurar alertas', 'Warnungen konfigurieren'],
        StartOfWorkingHours: [
            "Start of working hours",
            "Inicio del horario de trabajo",
            "Beginn der Dienstzeit",
        ],
        EndOfWorkingHours: [
            "End of working hours",
            "Fin del horario de trabajo",
            "Ende der Dienstzeit",
        ],
        ClockInSwitch: [
            "Time in / Switch activated",
            "Hora de entrada / interruptor activo",
            "Eingestempelt / Schalter aktiviert",
        ],
        ClockOutService: [
            "Time out / Not in service",
            "Hora de salida / interruptor inactivo",
            "Ausgestempelt / nicht im Dienst",
        ],
        CheckInAtX: [
            "Check-in at {0}",
            "Entró en {0}",
            "Check-in in {0}",
        ],
        CheckOutAtX: [
            "Check-out at {0}",
            "Salió de {0}",
            "Check-out aus {0}",
        ],
        ManualCheckInAtX: [
            'Manual check-in at {0}',
            'Check-in manual en {0}',
            'Manueller check-in in {0}',
        ],
        ManualCheckOutAtX: [
            'Manual check-out at {0}',
            'Check-out manual de {0}',
            'Manueller check-out aus {0}',
        ],
        ArrivedAtJobX: [
            "Arrived at job {0}",
            "Llegó a la tarea {0}",
            "Am Auftrag {0} angekommen",
        ],
        LeftJobX: [
            "Left job {0}",
            "Salió de la tarea {0}",
            "Auftrag {0} verlassen",
        ],
        CompletedMarkedJobX: [
            "Marked job as completed: {0}",
            "Tarea marcado como completado: {0}",
            "Auftrag als erledigt markiert: {0}",
        ],
        IssueMarkedJobX: [
            "Marked job as issue: {0}",
            "Tarea marcado como problema: {0}",
            "Auftrag als Problem markiert: {0}",
        ],
    },
    Reports: {
        JobReport: ["Jobs", "Tareas", "Aufträge"],
        DayRouteReport: ["Day route", "Ruta del día", "Tagesroute"],
        PlacesReport: ["Export Places", "Exportar Lugares", "Plätze exportieren"],
        AlertsReport: ["Alerts", "Alertas", "Alarmbericht"],
        CheckinsReport: ["Check-ins", "Check-ins", "Check-ins"],
        MembersReport: ["Export Members", "Exportar Miembros", "Mitglieder exportieren"],
        MileageReport: ["Mileage Report", "Reporte de Kilometraje", "Kilometerstand-Bericht"],
        PlaceOnSiteReport: ["Place on-site times", "Tiempos en lugares", "Vor Ort Zeiten bei Plätzen"],
        MemberOnSiteReport: ["On-site times of your team", "Tiempos en lugares de tu equipo", "Vor Ort Zeiten deines Teams"],
        NewReport: ['New Report', 'Nuevo Reporte', 'Neuer Bericht'],
        JobReportDesc: [
            "A detailed report that exports all data related to jobs, including information entered by the worker such as job start and end times, location, and any notes or comments. It can be used to track job progress and performance.",
            "Un informe detallado que exporta todos los datos relacionados con las tareas, incluida la información ingresada por el empleado, el inicio y la hora final de las tareas, la ubicación y cualquier nota o comentario. Se puede usar para rastrear el progreso y el rendimiento de la tarea.",
            "A detailed report that exports all data related to jobs, including information entered by the worker such as job start and end times, location, and any notes or comments. It can be used to track job progress and performance.",
        ],
        DayRouteReportDesc: [
            "This report shows a list of jobs that a worker completed on a specific day, including the location and time of each job. This report can be useful for analyzing worker productivity and efficiency, as well as for optimizing future routes to reduce travel time and fuel consumption.",
            "Este informe muestra una lista de tareas que un empleado completó en un día específico, la ubicación y la hora de cada tarea. Este informe puede ser útil para analizar la productividad y la eficiencia de los empleados, así como para optimizar las rutas futuras para reducir el tiempo de viaje y el consumo de combustible.",
            "This report shows a list of jobs that a worker completed on a specific day, including the location and time of each job. This report can be useful for analyzing worker productivity and efficiency, as well as for optimizing future routes to reduce travel time and fuel consumption.",
        ],
        PlacesReportDesc: [
            "This report contains all data related to your Places in the system. This includes information such as the name, location, as well as any additional details or notes associated with the place.",
            "Este informe contiene todos los datos relacionados con sus lugares en el sistema. Esto incluye información como el nombre, la ubicación, así como cualquier detalle adicional o notas asociadas con el lugar.",
            "This report contains all data related to your Places in the system. This includes information such as the name, location, as well as any additional details or notes associated with the place.",
        ],
        AlertsReportDesc: [
            "This report shows a detailed list of all the alerts that have been triggered, including information such as the type of alert, the location, and the time it was triggered. It can be used to track and troubleshoot any issues that may have occurred.",
            "Este informe muestra una lista detallada de todas las alertas que se han activado, incluida la información como el tipo de alerta, la ubicación y el tiempo que se activó. Se puede usar para rastrear y solucionar los problemas que hayan ocurrido.",
            "This report shows a detailed list of all the alerts that have been triggered, including information such as the type of alert, the location, and the time it was triggered. It can be used to track and troubleshoot any issues that may have occurred.",
        ],
        CheckinsReportDesc: [
            "This report shows a list of all check-ins and check-outs of your team at Places in your system. It can be used to track worker attendance.",
            "Este informe muestra una lista de todos los check-ins y check-outs de su equipo en lugares de su sistema. Se puede usar para rastrear la asistencia de los empleados.",
            "This report shows a list of all check-ins and check-outs of your team at Places in your system. It can be used to track worker attendance.",
        ],
        MembersReportDesc: [
            "This report contains all data related to the members in the system, including information such as the name, role, and contact information.",
            "Este informe contiene todos los datos relacionados con los miembros del sistema, incluida la información como el nombre, el rol y la información de contacto.",
            "This report contains all data related to the members in the system, including information such as the name, role, and contact information.",
        ],
        MileageReportDesc: [
            "This report provides detailed information on the mileage traveled by workers, including the start and end location, distance traveled, and duration of the trip. It can be used to track worker productivity and optimize routes for future jobs.",
            "Este informe proporciona información detallada sobre el kilometraje recorrido por los empleados, incluida la ubicación de inicio y finalización, la distancia recorrida y la duración del viaje. Se puede utilizar para rastrear la productividad de los empleados y optimizar las rutas para futuras tareas.",
            "This report provides detailed information on the mileage traveled by workers, including the start and end location, distance traveled, and duration of the trip. It can be used to track worker productivity and optimize routes for future jobs.",
        ],
        PlaceOnSiteReportDesc: [
            "This report provides detailed information on the amount of time a worker has spent at a specific place, including the start and end time, and total duration of the visit. This report can be used to track worker productivity and efficiency.",
            "Este informe proporciona información detallada sobre la cantidad de tiempo que un empleado ha pasado en un lugar específico, incluido el tiempo de inicio y finalización, y la duración total de la visita. Este informe se puede utilizar para rastrear la productividad y la eficiencia de los empleados.",
            "This report provides detailed information on the amount of time a worker has spent at a specific place, including the start and end time, and total duration of the visit. This report can be used to track worker productivity and efficiency.",
        ],
        MemberOnSiteReportDesc: [
            "This report provides detailed information on the amount of time a worker has spent at a specific place, including the start and end time, and total duration of the visit. This report can be used to track worker productivity and efficiency.",
            "Este informe proporciona información detallada sobre la cantidad de tiempo que un empleado ha pasado en un lugar específico, incluido el tiempo de inicio y finalización, y la duración total de la visita. Este informe se puede utilizar para rastrear la productividad y la eficiencia de los empleados.",
            "This report provides detailed information on the amount of time a worker has spent at a specific place, including the start and end time, and total duration of the visit. This report can be used to track worker productivity and efficiency.",
        ],
        EnterNameForThisReport: ["Enter name for this recurring report", "Ingresar nombre de este reporte recurrente", "Name für diesen wiederkehrenden Bericht eingeben"],
        TimesheetReport: ['Timesheet', 'Timesheet', 'Timesheet'],
        TimesheetReportDesc: [
            "Your team can clock-in and out with the Hellotracks mobile app by turning the Active switch on/off. Here you can find a summary of your team's clock-in and clock-out times.",
            'Tu equipo puede fichar el comienzo y termino de su jornada del horario de trabajo con la aplicación móvil Hellotracks activando o desactivando el interruptor. Aquí puedes encontrar un resumen de las horas de activas de trabajo de tu equipo.',
            'Dein Team kann sich mit der Hellotracks Mobile App ein- und ausstempeln, indem sie den Aktiv-Schalter ein-/ausschalten. Hier findest du eine Zusammenfassung der Kommen- und Gehen-Zeiten deines Teams.',
        ],
        CreateNewReports: ["Create new reports", "Crear nuevos reportes", "Neue Berichte erstellen"],
        RecurringReports: ["Recurring reports", "Reportes recurrentes", "Wiederkehrende Berichte"],
        RecentReports: ["Recent reports", "Reportes recientes", "Aktuelle Berichte"],
        SelectTimeFrame: ["Select time frame", "Seleccionar marco de tiempo", "Zeitrahmen auswählen"],
        SelectDayToCreate: ["Select day to create", "Seleccionar día para crear", "Tag zum Erstellen auswählen"],
        SelectDayToCreateFirstReport: [
            "Select day to create first report (the day of report creation is not included)",
            "Seleccionar día para crear el primer reporte (el día de creación del reporte no está incluido)",
            "Wähle den Tag, um den ersten Bericht zu erstellen (der Tag der Berichtserstellung ist nicht enthalten)",
        ],
        NotifyPeopleOnCreation: [
            "Notify people on creation",
            "Notificar a las personas en la creación",
            "Personen bei Erstellung benachrichtigen",
        ],
        SetupRecurringReport: [
            "Setup recurring report",
            "Configurar informe recurrente",
            "Wiederkehrenden Bericht einrichten"
        ],
        ExportFormSubmissions: ["Export Form Submissions", "Exportar presentaciones de formularios", "Formular-Einsendungen exportieren"],
        ExportFormSubmissionsDesc: [
            "You can export form submissions here. This reports contains all data filled out by your staff in the field. Select a form below to start.",
            "Aquí puedes exportar las presentaciones de formularios. Este informe contiene todos los datos completados por tu personal en el campo. Selecciona un formulario a continuación para empezar.",
            "Du kannst hier Formular-Einsendungen exportieren. Dieser Bericht enthält alle Daten, die von deinem Personal vor Ort ausgefüllt wurden. Wähle unten ein Formular aus, um zu beginnen.",
        ],
        GpxReport: ["Tracks as GPX-file", "Rutas como archivo GPX", "Tracks als GPX-Datei"],
        GpxReportDesc: [
            "After the download unzip the file. You can analyze your track with tools such as gpsvisualizer.com",
            "Después de descargar, descomprime el archivo. Puedes analizar tu ruta con herramientas como gpsvisualizer.com.",
            "Nach dem Download die Zip-Datei entpacken. Du kannst deinen Track mit Werkzeugen wie gpsvisualizer.com analysieren.",
        ],
        StopsReport: [
            "Stops",
            "Paradas",
            "Stopps",
        ],
        StopsReportDesc: [
            "This report provides detailed information on the stops made by workers at places or other locations, including the duration of idling time spent without moving. This data can offer valuable insights into worker behavior and be helpful for client billing, as well as assist in optimizing productivity and efficiency.",
            "Este informe proporciona información detallada sobre las paradas realizadas por los empleados en lugares u otras ubicaciones, incluida la duración del tiempo de inactividad sin movimiento. Estos datos pueden ofrecer información valiosa sobre el comportamiento de los empleados, ser útiles para la facturación a los clientes y ayudar a optimizar la productividad y eficiencia.",
            "Dieser Bericht bietet detaillierte Informationen zu den von Mitarbeitern an Plätzen oder anderen Standorten durchgeführten Stopps, einschließlich der Dauer der Leerlaufzeit ohne Bewegung. Diese Daten können wertvolle Einblicke in das Verhalten von Mitarbeitern liefern, hilfreich für die Kundenabrechnung sein und bei der Optimierung von Produktivität und Effizienz unterstützen.",
        ],
        ActivitiesReport: [
            "Activities Report",
            "Informe de actividades",
            "Aktivitäten Bericht"
        ],
        ActivitiesReportDesc: [
            "The activities report provides a detailed record of all the events and actions that have occurred within your organization. It includes a list of activities per member, providing details on when they occurred and who performed them.",
            "El informe de actividades proporciona un registro detallado de todos los eventos y acciones que han ocurrido dentro de tu organización. Incluye una lista de actividades por miembro, detallando cuándo ocurrieron y quién las realizó.",
            "Der Aktivitäten Bericht liefert einen detaillierten Bericht über alle Ereignisse und Aktionen, die innerhalb deiner Organisation stattgefunden haben. Er enthält eine Liste der Aktivitäten pro Mitglied, die genauen Zeitpunkte der Ereignisse sowie die ausführenden Personen werden aufgeführt."
        ],
        TimelineReport: [
            "Timeline Report",
            "Informe de línea de tiempo",
            "Timeline-Bericht"
        ],
        TimelineReportDesc: [
            "The timeline report shows a chronological overview of all the actions and events that have occurred within your organization, visualizing progress and activity over time.",
            "El informe de línea de tiempo muestra una visión general cronológica de todas las acciones y eventos que han ocurrido dentro de su organización, visualizando el progreso y la actividad a lo largo del tiempo.",
            "Der Timeline-Bericht zeigt einen chronologischen Überblick über alle Aktionen und Ereignisse, die innerhalb Ihrer Organisation stattgefunden haben und visualisiert Fortschritte und Aktivitäten im Laufe der Zeit."
        ],
        Worksheet: [
            "Worksheet",
            "Hoja de trabajo",
            "Arbeitsblatt",
        ],
        WorksheetDesc: [
            "The worksheet report offers an analysis of travel times, on-site times at stops, pauses, logins, and other related activities. It is a tool for tracking, understanding, and optimizing operational efficiency.",
            "El informe de hoja de trabajo ofrece un análisis de los tiempos de viaje, tiempos en el sitio en las paradas, pausas, inicios de sesión y otras actividades relacionadas. Es una herramienta para rastrear, entender y optimizar la eficiencia operacional.",
            "Der Arbeitsblattbericht bietet eine Analyse der Fahrtzeiten, Standzeiten an Haltestellen, Pausen, Anmeldungen und anderer verwandter Aktivitäten. Es ist ein Werkzeug zum Verfolgen, Verstehen und Optimieren der Betriebseffizienz."
        ],
        StatisticsReport: [
            "Statistics Report",
            "Informe de estadísticas",
            "Statistikbericht"
        ],

        StatisticsReportDesc: [
            "The statistics report provides a comprehensive summary of key metrics and data points within your organization. It includes insights on performance, productivity, and other important indicators, helping you make informed decisions.",
            "El informe de estadísticas proporciona un resumen completo de las métricas clave y puntos de datos dentro de tu organización. Incluye información sobre el rendimiento, la productividad y otros indicadores importantes, ayudándote a tomar decisiones informadas.",
            "Der Statistikbericht liefert eine umfassende Zusammenfassung wichtiger Kennzahlen und Datenpunkte innerhalb deiner Organisation. Er enthält Einblicke in Leistung, Produktivität und andere wichtige Indikatoren und unterstützt dich bei fundierten Entscheidungen."
        ],
        Columns: [
            "Columns",
            "Columnas",
            "Spalten",
        ],
        DailyReport: [
            "Daily report",
            "Reporte diario",
            "Täglicher Bericht",
        ],
        WeeklyReport: [
            "Weekly report",
            "Reporte semanal",
            "Wöchentlicher Bericht",
        ],
        EveryTwoWeeksReport: [
            "Every two weeks report",
            "Reporte cada dos semanas ",
            "14 Tage Bericht",
        ],
        StartDate: [
            "Start date",
            "Fecha de inicio",
            "Startdatum",
        ],
        AddSheetForVisitedPlaces: [
            "Sheet for visited places",
            "Hoja con lugares visitados",
            "Liste mit besuchten Plätzen",
        ],
    },
    Dashboard: {
        Mileage: ["Mileage", "Kilometraje", "Kilometerstand"],
        JobPerformance: ["Job Performance", "Desempeño de las tareas", "Auftrag Performanz"],
        SpeedDiagram: ["Speed Diagram", "Diagrama de velocidad", "Geschwindigkeitsdiagramm"]
    },
    Errors: {
        FileNotAccepted: ["File not accepted", "Archivo no aceptado", "Datei nicht akzeptiert"],
        IncorrectVerificationCode: ["The code is incorrect", "El código es incorrecto", "Der Code ist falsch"],
    },
    Places: {
        PlacesAndZonesDescription: [
            "Places are specific locations with a radius. They allow for automatic check-ins and check-outs as team members enter or leave these predefined areas. Zones are similar, but are polygonal regions that can cover a larger, more irregular area. Both Places and Zones facilitate automatic location-based events and work together with jobs, forms and alerts.",
            "Los Lugares son ubicaciones específicas con un radio. Permiten registros automáticos de entrada y salida a medida que los miembros del equipo entran o salen de estas áreas predefinidas. Las Zonas son similares, pero son regiones poligonales que pueden cubrir un área más grande e irregular. Tanto los Lugares como las Zonas facilitan eventos automáticos basados en la ubicación y trabajaN conjuntamente con tareas, formularios y alertas.",
            "Plätze sind spezifische Standorte mit einem Radius. Sie ermöglichen automatische Ein- und Auscheckvorgänge, wenn Teammitglieder diese vordefinierten Bereiche betreten oder verlassen. Zonen sind ähnlich, jedoch mit polygonale Regionen, die eine größere, unregelmäßigere Fläche als die kreisförmigen Plätze abdecken können. Sowohl Plätze als auch Zonen ermöglichen automatische standortbasierte Ereignisse und arbeiten zusammen mit Aufträgen, Formularen und Alarmen.",
        ],
        ImportPlaces: [
            'Import places',
            'Importar lugares',
            'Plätze importieren',
        ],
        ImportPlacesInfo: [
            '<p>You can download these <strong>templates</strong>:<ul><li><a href="{0}" download>CSV template</a>, or</li><li><a href="{1}" download>Excel template</a>, or</li><li><a href="{2}" download>KML template</a> (e.g. used in Google Earth/Maps).</li><li><strong>GeoJSON</strong>: you can simply export your current places as GeoJSON to see an example.</li></ul><small><br><strong>If you chose to import from Excel or CSV:</strong><ul><li>Do not rename header fields</li><li>You may remove columns if they don\'t apply</li><li>You may also leave cells empty if they don\'t apply</li><li>If you use the CSV format, make sure the file encoding is in UTF-8</li></ul></small>',
            '<p>Puedes descargar una <strong>plantilla de ejemplo</strong> aqu&iacute;:<ul><li><a href="{0}" download>plantilla de CSV</a>, or</li><li><a href="{1}" download>plantilla de Excel</a>, or</li><li><a href="{2}" download>plantilla de KML</a> (p.e. Google Earth/Maps).</li><li><strong>GeoJSON</strong>: para ver un ejemplo , puedes exportar tus lugares actuales como GeoJSON.</li></ul><small><br><strong>Si eliges importar desde Excel o CSV:</strong><ul><li>No modifiques encabezados</li><li>Puedes remover columnas</li><li>Deja campos vac&iacute;os si no aplican</li><li>Asegúrate de que el formato del CSV esta en UTF-8</li></ul></small>',
            '<p>Du kannst diese <strong>Templates</strong> runterladen:<ul><li><a href="{0}" download>CSV Template</a>, or</li><li><a href="{1}" download>Excel Template</a>, or</li><li><a href="{2}" download>KML Template</a> (z.B. in Google Earth/Maps).</li><li><strong>GeoJSON</strong>: exportiere deine eigenen Plätze im GeoJSON format um es als Template zu verwenden.</li></ul><small><br><strong>Wenn du von Excel oder CSV importieren willst:</strong><ul><li>Die Felder der Kopfzeile nicht &auml;ndern</li><li>Kolumnen enternen ist erlaubt</li><li>Du kannst Felder leer lassen</li><li>Wenn du das CSV-Format verwendest, versichere dich, dass das Datei-Encoding in UTF-8 ist.</li></ul></small>',
        ],
        ImportPlacesTitle: [
            'Import Places from Excel, CSV, GeoJSON or KML file',
            'Importar lugares desde un archivo CSV, Excel, KML ó GeoJSON',
            'Plätze von Excel-, CSV-, KML-, oder GeoJSON-Datei importieren',
        ],
        NewPlace: [
            'New Place',
            'Crear Lugar',
            'Neuer Platz',
        ],
        SelectPlace: [
            "Select place",
            "Seleccione un lugar",
            "Platz auswählen",
        ],
        SelectPlaces: [
            "Select places",
            "Seleccionar lugares",
            "Plätze auswählen",
        ],
        InvalidRadiusDesc: [
            "Enter a number between {0} and {1}",
            "Ingreta un número entre {0} y {1}",
            "Enter a number between {0} and {1}",
        ],
        DeletePlaces: ['Delete places', 'Borrar lugares', 'Plätze löschen'],
        DeleteXPlaces: [
            "Delete {0} places",
            "Borrar {0} lugares",
            "{0} Plätze löschen",
        ],
        ConfirmDeletePlacesDesc: [
            "I'm aware that deleting places cannot be undone.",
            "Soy consciente de que eliminar los lugares no se puede deshacer.",
            "Ich bin mir bewusst, dass das Löschen von Orten nicht rückgängig gemacht werden kann."
        ],
        BulkEditXPlaces: [
            "Edit {0} places",
            "Editar {0} lugares",
            "Bearbeitung von {0} Plätzen",
        ],
        EnterName: [
            "Enter place name",
            "Ingresar nombre del lugar",
            "Name des Platzes eingeben",
        ],
        PlacesUpdate: [
            "Places are updating in background",
            "Los lugares se están actualizando en segundo plano",
            "Die Plätze werden im Hintergrund aktualisiert",
        ],
        EditPlace: [
            "Edit place",
            "Editar lugar",
            "Platz bearbeiten",
        ],
        PlaceImageUpdate: [
            "Image is updating in background",
            "La imagen se está actualizando en segundo plano",
            "Bild wird im Hintergrund aktualisiert",
        ],
    },
    Zones: {
        ImportZones: [
            'Import zones',
            'Importar zonas',
            'Zonen importieren',
        ],
        ImportZonesInfo: [
            'You can import zones from a KML file that contains polygons. Each polygon will be imported as a new zone.',
            'Puedes importar zonas desde un archivo KML. Cada polígono se va a convertir a una zona.',
            'Zonen können mit einer KML-Datei importiert werden. Für jedes Polygon der KML Datei wird eine neue Zone erstellt.',
        ],
        ImportZonesTitle: [
            'Import Zones from KML file',
            'Import zonas desde archivo KML',
            'Zonen mit KML-Datei importieren',
        ],
        NewZone: [
            "New Zone",
            "Nueva zona",
            "Neue Zone",
        ],
        ZoneUpdated: [
            "Zone updated",
            "Zona actualizada",
            "Zone aktualisiert",
        ],
        PleaseEnterZoneName: [
            "Please enter the zone name before saving",
            "Ingresa el nombre de la zona antes de guardar",
            "Bitte gib den Zonennamen vor dem Speichern ein.",
        ],
        EnterNewZoneName: [
            "Enter new zone name",
            "Ingrese el nombre de la nueva zona",
            "Zonenname eingeben",
        ],
        DeleteZones: [
            "Delete zones",
            "Borrar zonas",
            "Zonen löschen",
        ],
        DeleteXZones: [
            "Delete {0} zones",
            "Borrar {0} zonas",
            "{0} Zonen löschen",
        ],
        ConfirmDeleteZonesDesc: [
            "I'm aware that deleting zones cannot be undone.",
            "Soy consciente de que eliminar las zonas no se pueden deshacer.",
            "Mir ist bewusst, dass das Löschen von Zonen nicht rückgängig gemacht werden kann.",
        ],
        EditXZones: [
            "Edit {0} zones",
            "Editar {0} zonas",
            "{0} Zonen bearbeiten",
        ],
    },
    Integrations: {
        Integrations: [
            'API & Integrations',
            'API & Integraciones',
            'API & Integrationen',
        ],
        IntegrationsApiCredentials: [
            'These are your company credentials:<p>API-key (secret): <strong>{0}</strong><p>Example to retrieve jobs (you can copy-paste e.g. to your unix shell):<br><pre>{1}</pre>',
            'Estas son las credenciales de su empresa:<p>Clave API (secreta): <strong>{0}</strong><p>Ejemplo para recuperar tareas (puedes copiar -pegar, por ejemplo, a su shell de Unix):<br><pre>{1}</pre>',
            'Hier ist dein API-key und Nutzer:<p>API-key (secret): <strong>{0}</strong><p>Beispiel zum Abrufen von Aufträgen (bspw. in der Unix-Shell kopieren und einfügen):<br><pre>{1}</pre>',
        ],
        IntegrationsApiUseAdminRole: [
            "Your user has the Operator role. To get access to API, create a dedicated API user with Administrator role.",
            "Tu usuario tiene el rol de Operador. Para obtener acceso a la API, crea un usuario de API dedicado con rol de Administrador.",
            "Dein Benutzer hat die Rolle des Disponenten. Um Zugang zur API zu erhalten, erstelle einen dedizierten API-Benutzer mit Administrator-Rolle."
        ],
        IntegrationsApiHtml: [
            'The most powerful and flexible integration is with our APIs.<p>You can find our api-documentation here: <a href="https://api-docs.hellotracks.com" target="_blank">API-docs</a><br>',
            'La integración más poderosa y flexible es con nuestras API.<p>Puedes encontrar nuestra documentación de APIs aquí: <a href="https://api-docs.hellotracks.com" target="_blank">API-docs</a><br>',
            'Die leistungsstärkste und flexibelste Integration erfolgt mit unseren APIs.<p>Unsere API-Dokumentation findest du hier: <a href="https://api-docs.hellotracks.com" target="_blank">API-docs</a><br>',
        ],
        IntegrationsApiShowCredentials: [
            'Show credentials and example',
            'Mostrar credenciales y ejemplo',
            'API-key und Beispiel anzeigen',
        ],
        IntegrationsAskAdmin: [
            'Ask you administrator to enable this integration.',
            'Pídele a tu administrador que habilite esta integración.',
            'Bitte den Administrator, diese Integration zu aktivieren.',
        ],
        IntegrationsDescHtml: [
            'Integrations can help you automate tasks like creating new jobs or updating existing ones to keep synchronized with other tools.<br>We offer a variety of options for a seamless integration:',
            'Las integraciones te ayudan a automatizar diferentes actividades, como la creación de nuevas tareas o la actualización de las existentes. Esto para mantener la información en constante sincronización con otras herramientas.<br>Ofrecemos una variedad de opciones para una integración perfecta:',
            'Mithilfe von Integrationen kannst du Aufgaben wie das Erstellen neuer Aufträge oder das Aktualisieren vorhandener Aufträge automatisieren, um mit anderen Tools synchron zu bleiben.<br>Wir bieten eine Vielzahl von Optionen für eine nahtlose Integration an:',
        ],
        IntegrationsEmailBody: [
            'You can add any field that matches the CSV or Excel template.',
            'Puedes agregar cualquier campo que coincida con la plantilla de CSV o Excel.',
            'Du kannst jedes Feld hinzufügen, das der CSV- oder Excel-Vorlage entspricht.',
        ],
        IntegrationsEmailHtml: [
            'You can send an email to <strong>job@mail.hellotracks.com</strong> which will automatically create a new job in Hellotracks.<br>The subject of the email will become the new title of the job, the content of the email will be attached to the newly created job.<br>You can even set all individual fields of the job: click <a href="mailto:job@mail.hellotracks.com?subject={0}&body={1}">here</a> to open an <strong>e-mail template</strong>.<p>Note that the email must be sent by a verified e-mail address or the verified address needs to be Cc-ed.<br>You can verify the email-address in your profile settings.',
            'Puedes enviar un email a <strong>job@mail.hellotracks.com</strong> que creará automáticamente una nueva tarea en Hellotracks.<br>El asunto del correo electrónico se convertirá en el nuevo título de la tarea, el contenido del correo electrónico se adjuntará a la tarea recién creada.<br>Incluso puedes configurar todos los campos de la tarea: haz clic <a href="mailto:job@mail.hellotracks.com?subject={0}&body={1}">aquí</a> para abrir <strong>una plantilla de email</strong>.<p>Ten en cuenta que el correo electrónico debe ser enviado por una dirección de correo electrónico verificada o la dirección verificada debe estar incluída en Cc.<br>Puedes verificar la dirección de correo electrónico en la configuración de su perfil.',
            'Du kannst eine E-Mail an <strong>job@mail.hellotracks.com</strong> senden. Dadurch wird automatisch ein neuer Auftrag in Hellotracks erstellt.<br>Der Betreff der E-Mail wird zum neuen Titel des Auftrags. Der Inhalt der E-Mail wird an den neu erstellten Auftrag angehängt.<br>Du kannst sogar alle einzelnen Felder des Auftrags festlegen: klicke <a href="mailto:job@mail.hellotracks.com?subject={0}&body={1}">hier</a> um ein <strong>E-mail-Template</strong> zu öffnen.<p>Beachte, dass die E-Mail von einer verifizierten E-Mail-Adresse gesendet werden muss oder dass die verifizierte Adresse Cc-ed sein muss.<br>Du kannst die E-Mail-Adresse in deinen Profileinstellungen überprüfen.',
        ],
        IntegrationsEmailSubject: [
            'This is a job created via e-mail.',
            'Esta es una tarea creada a través de un mensaje de correo electrónico.',
            'Ein neuer Autrag direkt aus einer E-Mail',
        ],
        IntegrationsEnableApi: [
            'Enable API for your company account',
            'Habilita la API para la cuenta de tu empresa',
            'API für das Unternehmenskonto aktivieren',
        ],
        IntegrationsExcelCsv: [
            'Excel and CSV templates',
            'Plantillas de Excel y CSV',
            'Excel und CSV Templates',
        ],
        IntegrationsExcelCsvHtml: [
            'A simple yet powerful tool to import many jobs at once is to import jobs via sheets in Excel or CSV format.<br>Our templates allow a lot of flexibility, e.g.<ul><li>you can delete any column if it doesn\'t apply to your use case,</li><li>you can reorder all columns as you wish,</li><li>and you can add as many custom fields as you need.</li><p>Go to Jobs view and click <strong>{0}</strong> to start using sheets-templates.',
            'Una opción simple pero poderosa para importar muchos tareas a la vez es importar un archivo en formato Excel o CSV.<br>Nuestras plantillas pofrecen mucha flexibilidad, por ejemplo <ul><li>puedes eliminar cualquier columna si aplica,</li><li> puedes reordenar todas las columnas como requieras,</li><li> y puede agregar tantos campos personalizados como necesites.</li><p>Dirígete a la vista Tareas y haz clic en <strong>{0}</strong> para comenzar a usar las plantillas para Excel.',
            'Ein einfaches, aber leistungsstarkes Tool zum gleichzeitigen Importieren vieler Aufträge ist das Importieren von Aufträgen über Tabellen im Excel- oder CSV-Format.<br>Unsere Vorlagen bieten viel Flexibilität, z. B. <ul> <li> Du kannst jede Spalte löschen.</li><li>Du kannst alle Spalten nach Belieben neu anordnen.</li><li>und du kannst so viele benutzerdefinierte Felder hinzufügen, wie benötigt wird.</li><p>Um loszulegen, klicke in der Ansicht Aufträge auf <strong>{0}</strong>.',
        ],
        IntegrationsFeedbackPlaceholder: [
            'Let us know which other integration you\'d like to see...',
            'Por favor dinos qué otra integración te gustaría ver ...',
            'Lass uns wissen, welche andere Integration du sehen möchtest...',
        ],
        IntegrationsGoogleCalendarHtml: [
            'Hellotracks lets you easily synchronize jobs to your team members Google Calendar.<br>New jobs will automatically appear in the Google Calendar and will update based on any new changes.<p>In order for this to work, each team member needs to connect their Google Calendar with Hellotracks.<br>This can be done easily by sending a link to team members who can accept the Google Calendar integration with just a single click.<br> You will find the button to start the Google Calendar integration in the team members <strong>profile page</strong>:<ol><li>Select the <strong>Team</strong> view in the menu</li><li>Select a member</li><li>Click <strong>Edit Profile</strong></li><li>Scroll down to the <strong>Integration</strong> section</li></ol>',
            'Puedes conectar y sincronizar fácilmente las tareas en Hellotracks con la cuenta de Calendario de Google de los miembros de tu equipo.<br>Las nuevas tareas aparecerán automáticamente en el Calendario de Google y se actualizarán automáticamente.<p>Cada miembro debe conectar su Calendario de Google con Hellotracks de manera individual.<br>Simplemente envía un enlace para que los miembros puedan aceptar la integración con un sólo clic.<br>Encontrarás el botón para iniciar la integración de Google Calendar en la <strong>página de perfil</strong> del miembro: <ol><li>Selecciona la vista de <strong>Equipo</strong> en el menú</li><li>Selecciona un miembro</li><li>Haz clic en <strong>Editar perfil</strong></li><li>Desplázate hacia abajo hasta la sección de <strong>Integración</strong></li></ol>',
            'Aufträge können sehr einfach automatisiert mit Google Kalender synchronisiert werden.<br>Neue Aufträge erscheinen automatisch in Google Kalender und werden auch stetig aktualisiert.<p>Jedes Team-Mitglied muss eigens die Verbindung von Hellotracks zu Google Kalender einrichten.<br>Glücklicherweise kann sehr einfach ein Link geteilt werden, sodass Team-Mitglieder mit einem simplen Klick die Integration durchführen können.<br>Den Button um die Google Kalender Integration zu starten findest du auf der <strong>Profilseite</strong> des Team-Mitglieds:<ol><li>Wähle <strong>Team</strong> im Menü aus</li><li>Wähle ein Mitglied aus</li><li>Klicke <strong>Profil bearbeiten</strong></li><li>Scroll nach unten zur Sektion <strong>Integration</strong> section</li></ol>',
        ],
        IntegrationsSlackHtml: [
            'Create new jobs easily via shortcuts from within your Slack workspace.<br>E.g. type this into your Slack workspace:<p><code>/job New delivery at Lombard St.</code><p> a window opens up where you can enter all relevant job data and you can directly assign the job to the right team member.<br>If you enter an address, Hellotracks will automatically geolocate it to the right location.',
            'Crea nuevos tareas facilmente a través de los accesos directos de tu workspace en Slack.<br>P. ej. escribe esto en tu workspace en Slack<p><code>/job Nueva entrega a Lombard St.</code><p> una ventana se abrirá en dónde puedes ingresar todos los datos relevantes de una tarea y puedes también asignarla directamente a un miembro de tu equipo.<br>Si ingresas una dirección, Hellotracks la geolocalizará automáticamente.',
            'Erstelle neue Aufträge einfach über Shortcuts im Slack-Arbeitsbereich.<br>Z.B. gib dies im Slack-Arbeitsbereich ein:<p><code>/job Neuer Auftrag</code><p> Es öffnet sich ein Fenster, in dem du alle relevanten Daten eingeben und den Auftrag direkt dem richtigen Teammitglied zuweisen kannst.<br>Wenn du eine Adresse eingibst, wird diese von Hellotracks automatisch an der richtigen Stelle geolokalisiert.',
        ],
        IntegrationsOkta: [
            "<p>To configure your Single Sign On (SSO) integration with Okta, Azure AD or other Identity Providers, please follow the detailed instructions provided in our help article. These guides walk you through each step of the integration process. See <a target='_blank' href=\"https://help-docs.hellotracks.com/article/1028-how-to-integrate-with-okta\">How to Integrate with Okta</a> and <a target='_blank' href=\"https://help-docs.hellotracks.com/article/1029-how-to-integrate-with-microsoft-azure-active-directory-ad\">How to Integrate with Azure AD</a>.</p>",
            "<p>Para configurar tu integración de Single Sign On (SSO) con Okta, Azure AD u otros proveedores de identidad, sigue las instrucciones detalladas proporcionadas en nuestro artículo de ayuda. Esta guías te guiará a través de cada paso del proceso de integración. Consulta <a target='_blank' href=\"https://help-docs.hellotracks.com/article/1028-how-to-integrate-with-okta\">Cómo integrar con Okta</a> y <a target='_blank' href=\"https://help-docs.hellotracks.com/article/1029-how-to-integrate-with-microsoft-azure-active-directory-ad\">Cómo integrar con Azure AD</a>.</p>",
            "<p>Um deine Single Sign On (SSO)-Integration mit Okta, Azure AD oder anderen Identity Provider zu konfigurieren, folge bitte den detaillierten Anweisungen in unserem Hilfeartikel. Diese Anleitungen führen dich durch jeden Schritt des Integrationsprozesses. Siehe <a target='_blank' href=\"https://help-docs.hellotracks.com/article/1028-how-to-integrate-with-okta\">Wie man mit Okta integriert</a> und <a target='_blank' href=\"https://help-docs.hellotracks.com/article/1029-how-to-integrate-with-microsoft-azure-active-directory-ad\">Wie man mit Azure AD integriert</a>.</p>",
        ]
    },
    Slack: {
        Slack: [
            'Slack',
            'Slack',
            'Slack',
        ],
        AddToSlack: [
            'Add to Slack',
            'Agregar a Slack',
            'Zu Slack hinzufügen',
        ],
        SlackConnectedDescHtml: [
            'Type <code>/job</code> to create a new job from within your Slack workspace',
            'Ingresa <code>/job</code> para crear una nueva tarea dentro de Slack',
            'Gib <code>/job</code> ein um einen neuen Auftrag aus Slack zu erstellen',
        ],
        SlackDesc: [
            'You\'ll be able to create new jobs from within your Slack workspace',
            'Podrás crear nuevas tareas desde Slack',
            'Du kannst neue Aufträge direkt in Slack erstellen',
        ],
        SlackDisconnect: [
            'Disconnect Slack',
            'Desconectar Slack',
            'Von Slack trennen',
        ],
        SlackDisconnectQuestion: [
            'Are you sure to disconnect Slack?',
            '¿Estás seguro de desconectar Slack?',
            'Slack wirklich trennen?',
        ],
        SlackWorkspaceNotConnected: [
            'Your Slack workspace is not connected to any Hellotracks account.',
            'Tu espacio de Slack no está conectado a ninguna cuenta de Hellotracks.',
            'Dein Slack Workspace ist mit keinem Hellotracks-Konto verbunden',
        ],
    },
    GoogleCalendar: {
        GoogleCalendar: [
            'Google Calendar',
            'Google Calendar',
            'Google Kalender',
        ],
        GoogleCalendarAlreadyConnected: [
            'Your Google Calendar is already connected to Hellotracks!',
            '¡Tu Google Calendar ya está conectado a Hellotracks!',
            'Dein Google Kalender is bereits mit Hellotracks verbunden!',
        ],
        GoogleCalendarClickHereToConnect: [
            'Click {0} to connect Hellotracks with Google Calendar.',
            'Haz clic en {0} para conectar Hellotracks con tu Calendario de Google.',
            '{0} klicken um Hellotracks mit Google Kalender zu verbinden',
        ],
        GoogleCalendarClickHereToConnectHtml: [
            'Click <strong><a href="{0}">here</a></strong> to connect Hellotracks with your Google Calendar.',
            'Haz clic <strong><a href="{0}">aqu&iacute;</a></strong> para conectar Hellotracks con tu Calendario de Google.',
            'Klicke <strong><a href="{0}">hier</a></strong> um Hellotracks mit deinem Google Kalender zu verbinden.',
        ],
        GoogleCalendarConnected: [
            'Connected',
            'Conectado',
            'Verbunden',
        ],
        GoogleCalendarDesc: [
            'By connecting to the Google Calendar all Hellotracks jobs are automatically synchronized with the Calendar.',
            'Al conectarte al Calendario de Google, todas las tareas de Hellotracks se sincronizan automáticamente con el Calendario.',
            'Mit einer Verbindung zum Google Kalender werden alle Aufträge in Hellotracks automatisch mit dem Kalender synchronisiert.',
        ],
        GoogleCalendarDisconnect: [
            'Disconnect Google Calendar',
            'Desconectar Google Calendar',
            'Von Google Kalender trennen',
        ],
        GoogleCalendarDisconnectQuestion: [
            'Are you sure to disconnect Google Calendar? Jobs will not be synchronized between Hellotracks and this member\'s Google Calendar.',
            '¿Estás seguro de desconectar Google Calendar? Las tareas de este miembro no se sincronizarán entre Hellotracks y el Calendario de Google.',
            'Google Kalender wirklich trennen? Aufträge werden dann nicht mehr zwischen Hellotracks und dem Google Kalender synchronisiert.',
        ],
        GoogleCalendarNowConnected: [
            'Your Google account is now successfully connected to your Hellotracks account!',
            '¡Tu cuenta de Google ahora ha sido conectada con éxito a tu cuenta de Hellotracks!',
            'Dein Google Kalender ist nun erfolgreich mit Hellotracks verbunden!',
        ],
        GoogleCalendarSendLink: [
            'Send link to connect with Google Calendar',
            'Enviar enlace para conectarte con Google Calendar',
            'Link senden um mit Google Kalender zu verbinden',
        ],
        GoogleCalendarXNeedsVerified: [
            '{0} needs to have a verified email or phone to connect with Google Calendar.',
            '{0} necesitas tener un correo electrónico o teléfono verificado para conectarte con Google Calendar.',
            '{0} braucht eine verifizierte E-mail oder Telefonnummer um sich mit Google Kalender zu verbinden.',
        ],
    },
    Forms: {
        FormsDesc: [
            '<ul><li>You can create and manage Forms here.</li><li>Forms can be linked to jobs or places.</li><li>Your team can fill out and submit forms on the mobile app.</li><li>Submissions will appear here.</li>',
            '<ul><li>Puedes crear y administrar formularios aquí.</li><li>Los formularios pueden estar ligados a tareas o lugares.</li><li>Tu equipo puede completar y enviar formularios desde la aplicación móvil.</li><li>Los resultados aparecerán aquí.</li>',
            '<ul><li>Hier kannst du Formulare erstellen und verwalten.</li><li>Formulare können mit Aufträgen oder Plätzen verknüpft werden.</li><li>Dein Team kann Formulare in der mobilen App ausfüllen und einsenden.</li><li>Einsendungen werden hier angezeigt.</li>'
        ],
        SubmittedBy: [
            "Submitted by",
            "Enviado por",
            "Eingereicht",
        ],
        FormAddCheckbox: ['Add checkbox', 'Agregar casilla de verificación', 'Checkbox hinzufügen'],
        FormAddDecimal: ['Add decimal', 'Agregar decimal', 'Dezimalzahl hinzufügen'],
        FormAddNumber: ['Add number', 'Añadir un número', 'Zahleingabe hinzufügen'],
        FormAddText: ['Add text', 'Añadir texto', 'Text'],
        Forms: ['Forms', 'Formularios', 'Formulare'],
        FormsAddOption: ['Add option', 'Agregar opción', 'Option hinzufügen'],
        FormsAndDataInput: ['Forms', 'Formularios', 'Formulare'],
        FormsAndDataInputDesc: ['Retrieve data from your team in the field', 'Recupera datos de tu equipo en el campo', 'Daten von deinem Team vor Ort abrufen'],
        FormsCheckbox: ['Checkbox', 'Casilla de verificación', 'Checkbox'],
        FormsDecimal: ['Decimal', 'Decimal', 'Dezimal'],
        FormsDecimalInput: ['Decimal number input', 'Entrada de número decimal', 'Dezimalzahl'],
        FormsDeleteQuestion: ['Delete this form?', '¿Eliminar este formulario?', 'Formular wirklich löschen?'],
        FormsDropdown: ['Dropdown', 'Menú desplegable', 'Dropdown'],
        FormsFile: ['File upload', 'Carga de archivo', 'Datei hochladen'],
        FormsFormTitle: ['Enter form title', 'Ingrese el título del formulario', 'Gib einen Titel ein'],
        FormsFormDescription: ['Enter form description (optional)', 'Ingresa la descripción del formulario (opcional)', 'Formularbeschreibung eingeben (optional)'],
        FormsImage: ['Image', 'Imagen', 'Bild'],
        FormsLabel: ['Label', 'Etiqueta', 'Label'],
        FormsLinkedForm: ['Linked Form', 'Formulario vinculado', 'Verknüpftes Formular'],
        FormsLinkedForms: ['Linked Forms', 'Formularios vinculados', 'Verknüpfte Formulare'],
        FormsMultilineInput: ['Multiline input', 'Entrada multilínea', 'Mehrzeilige Eingabe'],
        FormsNumber: ['Number', 'Número', 'Zahl'],
        FormsNumberInput: ['Integer number input', 'Entrada de número entero', 'Ganzzahl'],
        FormsOption: ['Option', 'Opción', 'Option'],
        FormsParagraph: ['Paragraph', 'Párrafo', 'Absatz'],
        FormsRadio: ['Multiple choice', 'Opción múltiple', 'Mehrfachauswahl'],
        FormsRequired: ['Required', 'Obligatorio', 'Pflichtfeld'],
        FormsSection: ['Section', 'Sección', 'Sektion'],
        FormsSignature: ['Signature', 'Firma', 'Unterschrift'],
        FormsSingleLineInput: ['Single line input', 'Entrada de una sola línea', 'Einzeilige Eingabe'],
        FormsSubmissions: ['Submissions', 'Envíos', 'Einsendungen'],
        FormsTemplates: ['Forms', 'Formularios', 'Formulare'],
        FormsText: ['Text', 'Texto', 'Text'],
        FormsUntitledForm: ['Untitled form', 'Formulario sin título', 'Unbenanntes Formular'],
        FormNewSection: ["New section", "Nueva sección", "Neuer Bereich"],
        FormNew: ["New form", "Nuevo formulario", "Neues Formular"],
        FormsDeleteForm: [
            "Are you sure to delete this form?",
            "¿Estás seguro de eliminar este formulario?",
            "Dieses Formular wirklich löschen?",
        ],
        FormSubmissionSavedLocally: [
            "Your changes will be saved locally unless you cancel the submission.",
            "Tus cambios se guardarán localmente a menos que canceles el envío.",
            "Änderungen werden lokal gespeichert, es sei denn, die Einreichung wird abgebrochen",
        ],
        FormSubmissionSucceed: [
            "Your submission has been sent successfully.",
            "Tu envío se realizó con éxito.",
            "Einreichung wurde erfolgreich gesendet.",
        ],
        BackToForms: [
            "Back to forms",
            "Regresar a formularios",
            "Zurück zu Formulare",
        ],
        NewFormSubmission: [
            "New submission",
            "Nuevo envío",
            "Neue Einreichung",
        ],
        SelectForm: [
            "Select form",
            "Selecciona un formulario",
            "Formular auswählen",
        ],
    },
    Login: {
        UserUnknown: ['Username does not exists.', 'Éste nombre de usuario no existe', 'Benutzername exisitiert nicht'],
        IncorrectPassword: ['Incorrect password', 'La contraseña es inváilida', 'Passwort ist nicht richtig'],
        InvalidUsernameOrPassword: ["Invalid username or password", "Usuario o contraseña invalido", "Ungültiger Nutzername oder Passwort"],
        NoPermissionEnterWeb: ['This user has no permission to enter the web platform', 'Este usuario no tiene permiso para acceder a la plataforma web', 'Du hast keine Berechtigung, die Webplattform zu betreten'],
        Throttling: ['Too many intents. Please wait a minute and retry.', 'Demasiadas solicitudes. Por favor, espera un minuto y vuelve a intentarlo.', 'Zu viele Anfragen. Bitte warte eine Minute und versuche es erneut.'],
        Busy: ['Our servers are currently busy. Please hang on.', 'Nuestros servidores están ocupados en este momento. Por favor, espera.', 'Unsere Server sind momentan beschäftigt. Bitte warte'],
        SomethingWentWrong: ['Something went wrong. Please try again.', 'Algo salió mal. Por favor, vuelve a intentarlo.', 'Etwas ist schief gelaufen. Bitte versuch es erneut.'],
        TwoFactorAuthentication: ['Two-factor authentication (2FA)', 'Autenticación de dos factores (2FA)', 'Zwei-Faktor-Authentisierung (2FA)'],
        TwoFactorAuthenticationEnabled: ['Two-factor authentication (2FA) is now enabled', 'Autenticación de dos factores (2FA) activada', 'Zwei-Faktor-Authentisierung (2FA) ist nun aktiviert'],
        TwoFactorAuthenticationDisabled: ['Two-factor authentication (2FA) is now disabled', 'Autenticación de dos factores  (2FA) desactivada', 'Zwei-Faktor-Authentisierung (2FA) is nun deaktiviert'],
        TwoFactorAuthenticationDesc: ['Two-factor authentication (2FA) requires users to enter a one-time verification code sent using your preferred channel in order to access a Hellotracks account.', 'La autenticación de dos factores (2FA) requiere que los usuarios ingresen un código de verificación único enviado por el medio preferido para acceder a una cuenta en Hellotracks.', 'Bei der Zwei-Faktor-Authentisierung (2FA) müssen Nutzer einen einmaligen Bestätigungscode eingeben, der über Ihren bevorzugten Kanal gesendet wird, um auf ein Hellotracks-Konto zugreifen zu können.'],
        TwoFactorAuthenticationVerify: ['To enable two factor authentication, please verify first both your email address and phone number.', 'Para activar la autenticación de dos factores, verifica tu dirección de correo electrónico y tu número de teléfono.', 'Um die Zei-Faktor-Authentisierung zu aktivieren müssen zuerst die E-mail Addresse und Telefonnummer verifiziert sein.'],
        ForgotPasswordLinkToX: ['A link to recover your password has been sent to {0}', 'Un enlace para recuperar tu contraseña se ha enviado a {0}', 'Ein Link zur Wiederherstellung des Passworts wurde and {0} gesendet'],
        ForgotPasswordTryAgain: ['Please enter a valid username or email and try again', 'Por favor ingresa un nombre de usuario válido ó email e intenta de nuevo', 'Bitte gültigen Nutzername oder E-mail eingeben und erneut versuchen'],
        UserDoesNotExist: [
            "User does not exist",
            "Usuario no existe",
            "Nutzer existiert nicht"
        ],
        UserNotSetUpForSSO: [
            "User not set up for SSO",
            "Usuario no configurado para SSO",
            "Nutzer nicht für SSO eingerichtet"
        ]
    },
    Timeline: {
        ShowFullDayRoute: [
            "Show full day route on map",
            "Mostrar ruta de día completo en el mapa",
            "Tagesroute anzeigen",
        ],
        ShowWaypoints: [
            "Show waypoints",
            "Mostrar puntos de ruta",
            "Wegpunkte anzeigen",
        ],
        ZoomToTrack: [
            "Zoom to track on hover",
            "Acercar a la ruta al pasar el mouse",
            "Zu Track zoomen bei Mouse-Hover",
        ],
        AllEvents: ["All events", "Todos los eventos", "Alle Events"],
        OnlyTracks: ["Only tracks", "Solamente rutas", "Nur Tracks"],
        ShowAllXEvents: ["Show all {0} events", "Ver todos los {0} eventos", "Alle {0} Events anzeigen"],
    },
    Spreadsheet: {
        AddAdvancedField: [
            'Add field',
            'Agregar campo',
            'Feld hinzufügen',
        ],
        AddCustomFieldColumn: [
            'Add custom field column',
            'Agregar columna de campo personalizado',
            'Benutzerdefinierte Spalte hinzufügen',
        ],
        AddRow: [
            'Add row',
            'Agregar fila',
            'Reihe hinzufügen',
        ],
        ChooseCustomFieldName: [
            'Choose custom field name',
            'Elige un nombre de campo personalizado',
            'Feldnamen wählen',
        ],
        CustomFieldName: [
            'Custom field name',
            'Nombre de campo personalizado',
            'Feldname',
        ],
    },
    Messages: {
        TypeMessageHere: [
            "Type message here",
            "Escriba un mensaje aquí",
            "Nachricht hier eingeben",
        ],
        MessageSent: [
            'Message sent',
            'Mensaje enviado',
            'Nachricht gesendet',
        ],
        SendMessage: [
            'Send message',
            'Enviar mensaje',
            'Nachricht senden',
        ],
        SendMessageTo: [
            'Send message to',
            'Enviar mensaje a',
            'Nachricht senden an',
        ],
        SendMessageToX: [
            'Send message to {0}',
            'Enviar mensaje a {0}',
            'Nachricht an {0} senden',
        ],
        SendingMessageTo: [
            'Sending message to',
            'Enviando mensaje a',
            'Nachricht wird gesendet an',
        ],
        EnterMessage: [
            'Enter message',
            'Escribir un mensaje',
            'Nachricht eingeben',
        ],
        NewMessage: [
            "New Message",
            "Nuevo mensaje",
            "Neue Nachricht",
        ],
        OpenChat: [
            'Open Chat',
            'Abrir chat',
            'Chat öffnen',
        ],
        MessageFromX: [
            "Message from {0}",
            "Mensaje de {0}",
            "Nachricht von {0}"
        ],
        DeleteMessage: [
            "Delete message",
            "Borrar mensaje",
            "Nachricht löschen",
        ],
    },
    Stats: {
        Statistics: ["Statistics", "Estadísticas", "Statistik"],
        TotalMembers: ['Members', 'Miembros', 'Mitglieder'],
        MemberStats: ['Member Stats', 'Estadísticas de Miembros', 'Mitgliederstatistik'],
        TotalAssignedJobs: ['Total Assigned Jobs', 'Total de Tareas Asignadas', 'Insgesamt zugewiesene Aufträge'],
        MaxSpeed: ["Max Speed", "Velocidad Máxima", "Max. Geschw."],
        AvgSpeed: ["Avg Speed", "Velocidad Promedio", "Durchschn. Geschw."],
        OnTime: ["On-Time", "A Tiempo", "Pünktlich"],
        Delays: ["Delays", "Retrasos", "Verzögerungen"],
        Engagement: ["Engagement", "Compromiso", "Engagement"],
        Table: ["Table", "Tabla", "Tabelle"],
        Engaged: ["Engaged", "Comprometido", "Engagiert"],
        Passive: ["Passive", "Pasivo", "Passiv"],
        AccumulatedTimeAtAndBetweenJobs: [
            "Total time at and between jobs",
            "Tiempo acumulado en y entre treas",
            "Summierte Zeit bei und zw. Aufträgen",
        ],
        AccumulatedTimeBetweenJobs: [
            "Total time between jobs",
            "Tiempo acumulado entre tareas",
            "Summierte Zeit zw. Aufträgen",
        ],
        AccumulatedTimeAtJobSites: [
            "Total time at job sites",
            "Tiempo acumulado en lugares de tarea",
            "Summierte Zeit bei Auftragsorte",
        ],
        AvgTimeAtAndBetweenJobs: [
            "Average time at and between jobs",
            "Tiempo promedio en y entre tareas",
            "Durchschn. Zeit bei und zwischen Aufträgen",
        ],
        AvgTimeBetweenJobs: [
            "Average time between jobs",
            "Tiempo promedio entre tareas",
            "Durchschn. Zeit zw. Aufträgen",
        ],
        AvgTimeAtJobSites: [
            "Average time at job sites",
            "Tiempo promedio en lugares de tarea",
            "Durchschnittliche Zeit an Aufträgen",
        ],
        TimeAtJob: ["Time at jobs", "Tiempo en tareas", "Zeit bei Aufträgen"],
        TimeAtPlace: ["Time at places", "Tiempo en lugares", "Zeit an Pläzen"],
        TimeTravelling: ["Time travelling", "Tiempo viajando", "Reisezeit"],
        TravelDistances: ["Travel distances", "Distancias de viaje", "Reisedistanzen"],
        TravelTime: ["Travel time", "Tiempo de viaje", "Reisezeit"],
        Pause: ["Pause", "Pausa", "Pause"],
        TravelAndPauseTime: [
            "Travel and pause time",
            "Tiempo de viaje y pausa",
            "Reise- und Pausenzeit"
        ],
        AvgOnsiteTimeByMember: [
            "Avg onsite time by member",
            "Tiempo promedio en el sitio por miembro",
            "Durchschnittliche Vor-Ort-Zeit pro Mitglied"
        ],
        AvgOnsiteTimeByDate: [
            "Avg onsite time by date",
            "Tiempo promedio en el sitio por fecha",
            "Durchschnittliche Vor-Ort-Zeit nach Datum"
        ],
        AccumulatedOnsiteTimeByMember: [
            "Total onsite time by member",
            "Tiempo acumulado en el sitio por Miembro",
            "Summierte Vor-Ort-Zeit pro Mitglied"
        ],
        AccumulatedOnsiteTimeByDate: [
            "Total onsite time by date",
            "Tiempo acumulado en el sitio por fecha",
            "Summierte Vor-Ort-Zeit nach Datum"
        ],
        JobPerformanceByDate: [
            "Job performance by date",
            "Rendimiento de tarea por fecha",
            "Auftragsperformance nach Datum"
        ],
        JobPerformanceByMember: [
            "Job performance by member",
            "Rendimiento de tareas por miembro",
            "Arbeitsleistung pro Mitglied"
        ],
        TotalJobOnsite: ["Total job onsite", "Total sitio de tarea", "Summ. bei Auftrag"],
        AvgJobOnsite: ["Avg job onsite", "Promedio sitio de tarea", "Durchschn. bei Auftrag"],
        TotalPlaceOnsite: ["Total place onsite", "Total en lugar", "Summ. bei Platz"],
        AvgPlaceOnsite: ["Avg place onsite", "Promedio en lugar", "Durchschn. bei Platz"],
        TotalPause: ["Total pause", "Total de pausa", "Gesamte Pause"],
        AvgPause: ["Avg pause", "Pausa promedio", "Durchschn. Pause"],
        AvgTravelTime: ["Avg travel time", "Promedio de viaje", "Durchschn. Reisezeit"],
        TotalTravelTime: ["Total travel time", "Tiempo total de viaje", "Gesamte Reisezeit"],
        SuccessRate: ["Success rate", "Tasa de éxito", "Erfolgsrate"],
        IssueRate: ["Issue rate", "Tasa de problemas", "Problemrate"],
        EngagementWithJobw: ["Engagement with jobs", "Compromiso con los trabajos", "Engagement mit Aufträgen"],
        TotalTimeAtJobs: ["Total time at jobs", "Tiempo total en las tareas", "Gesamtzeit aller Aufträge"],
        TotalDistanceTravelled: ["Total distance driven", "Distancia total recorrida", "Gesamtfahrstrecke"],
        TotalNumberOfTracks: ["Total number of tracks", "Número total de rutas", "Gesamtanzahl an Tracks"],
        JobStats: ["Job Stats", "Estadísticas de tareas", "Auftragsstatistiken"],
        JobTypes: ["Job types", "Tipos de tarea", "Auftragstypen"],
        JobTeams: ["Job teams", "Equipos por tarea", "Teams für Aufträge"],
    },
    Onboard: {
        Header: [
            'To get up and running...',
            'Para ponerse en marcha...',
            'Um erfolgreich zu starten...',
        ],
        CloseDescription: [
            'There are still a few steps to explore. Unless you click the checkbox below the window will reopen on next login.',
            'Todavía hay algunos pasos para explorar. A menos que hagas clic en la casilla de verificación debajo, la ventana se volverá a abrir en el próximo inicio de sesión.',
            'Es gibt noch ein paar Schritte zu erkunden. Solange du nicht auf das Kontrollkästchen unten klickst, wird das Fenster bei der nächsten Anmeldung wieder geöffnet.',
        ],
        DontShowXAgain: [
            'Don\'t show "{0}" again',
            'No volver a mostrar "{0}"',
            '"{0}" nicht mehr anzeigen',
        ],
        JobsTitle: [
            "Jobs",
            "Tareas",
            "Aufträge",
        ],
        ImportJobsTitle: [
            'Import Jobs',
            'Importar Tareas',
            'Aufträge importieren',
        ],
        PlacesTitle: [
            'Places',
            'Lugares',
            'Plätze'
        ],
        ImportPlacesTitle: [
            'Import places',
            'Importar lugares',
            'Plätze importieren',
        ],
        CreateCompany: [
            "Company Set-up",
            "Crear Empresa",
            "Unternehmen erstellen",
        ],
        Invite: [
            'Adding users',
            'Invitar a un miembro del equipo',
            'Ein Teammitglied einaden'
        ],
        Permissions: [
            'User\'s Permissions',
            'Configurar permisos para operadores y empleados',
            'Berechtigungen für Disponenten und Arbeiter einrichten',
        ],
        Place: [
            'Creating Places',
            'Crear un lugar',
            'Erstelle einen Platz',
        ],
        Route: [
            'Jobs',
            'Aprende sobre tareas con una ruta de demostración',
            'Erfahre mehr über Jobs mit einer Demoroute',
        ],
        Signup: [
            'Sign up',
            'Registrarse',
            'Anmeldung',
        ],
        AppLogin: [
            'Download mobile app',
            'Descargar la aplicación móvil',
            'Mobile App herunterladen und anmelden',
        ],
        Checkin: [
            'Check-in Notifications',
            'Notificación de llegada y registro de tiempo en el sitio',
            'Ankunftsbenachrichtigung und Zeiterfassung vor Ort',
        ],
        Jobs: [
            "Jobs & Dispatch",
            "Tareas & Despacho",
            "Aufträge & Disposition",
        ],
        TeamTitle: [
            'Team',
            'Equipo',
            'Team',
        ],
        ImportMembersTitle: [
            'Import members',
            'Importar miembros',
            'Mitglieder importieren',
        ],
        TimelineTitle: [
            'Timeline',
            'Línea de tiempo',
            'Timeline',
        ],
        StopsTitle: [
            "Stops",
            "Paradas",
            "Stopps",
        ],
        AppLoginTitle: [
            'Download mobile app',
            'Descargar la aplicación móvil',
            'Mobile App herunterladen und anmelden',
        ],
        CompanySettingsTitle: [
            'Company Settings',
            'Configuración de la Companía',
            'Unternehmenseinstellungen',
        ],
        ChekinNotificationsTitle: [
            'Check-in Notifications',
            'Notificación de llegada y registro de tiempo en el sitio',
            'Ankunftsbenachrichtigung und Zeiterfassung vor Ort',
        ],
        DelayTitle: [
            'Delay',
            'Retraso',
            'Verzögerung',
        ],
        AreaTitle: [
            'Area',
            'Área',
            'Fläche',
        ],
        TrackingDescription: [
            'By default, workers can turn off tracking via the "In Service" switch in their Hellotracks mobile app.',
            'De forma predeterminada, los trabajadores móviles pueden desactivar el seguimiento a través del interruptor "Activo" en su aplicación móvil Hellotracks.',
            'Standardmäßig können mobile Mitarbeiter die Nachverfolgung über den Schalter "Aktiv" in der mobilen Hellotracks-App deaktivieren.',
        ],
        TrackingWorkingHoursDescription: [
            'Team member\'s daily location can be recorded during working hours.',
            'Las rutas conducidas de los miembros de su equipo se registran automáticamente entre el horario de trabajo.',
            'Gefahrene Routen der Teammitglieder werden zwischen den Arbeitszeiten automatisch aufgezeichnet.',
        ],
        CloseHeader: [
            "Close",
            "Cerrar",
            "Schließen",
        ],
        JobsDescription: [
            "You can manage all your jobs here.",
            "Puedes administrar todos tus trabajos/tareas aquí.",
            "Hier kannst du alle deine Aufträge verwalten.",
        ],
        OpenListOfAllJobs: [
            "Open list of all jobs",
            "Abrir lista de todas las tareas",
            "Liste aller Aufträge öffnen",
        ],
        ImportJobsDescription: [
            "Add multiple jobs here or import them from Excel or CSV.",
            "Agrega múltiples tareas aquí o impórtalos desde Excel o CSV.",
            "Füge hier mehrere Aufträge hinzu oder importiere sie aus Excel oder CSV.",
        ],
        AddJobTitle: [
            "Add job",
            "Agregar tarea",
            "Auftrag hinzufügen",
        ],
        AddJobDescription: [
            "Or start creating a new job here.",
            "O empieza aquí a crear una nueva tarea.",
            "Oder beginne hier mit der Erstellung eines neuen Aufrags.",
        ],
        PlacesDescription: [
            "You can manage your places here.",
            "Puedes administrar tus lugares aquí.",
            "Hier kannst du deine Plätze verwalten.",
        ],
        ImportPlacesDescription: [
            "Add multiple places here or import them from Excel, CSV, GeoJSON, or KML.",
            "Agrega múltiples lugares aquí o impórtalos desde Excel, CSV, GeoJSON o KML.",
            "Füge hier mehrere Plätze hinzu oder importiere sie aus Excel, CSV, GeoJSON oder KML.",
        ],
        AddPlaceTitle: [
            "Add place",
            "Agregar lugar",
            "Platz hinzufügen",
        ],
        AddPlaceDescription: [
            "Start creating a new place.",
            "Comienza a crear un nuevo lugar.",
            "Erstelle einen neuen Platz.",
        ],
        TrackingSettings: [
            "Tracking settings",
            "Configuración de seguimiento",
            "Tracking-Einstellungen",
        ],
        TeamDescription: [
            "You can manage your team here.",
            "Puedes administrar a tu equipo aquí.",
            "Hier kannst du dein Team verwalten.",
        ],
        ImportMembersDescription: [
            "Add multiple users here or import them from Excel or CSV.",
            "Agrega múltiples usuarios aquí o impórtalos desde Excel o CSV.",
            "Füge hier mehrere Benutzer hinzu oder importiere sie aus Excel oder CSV.",
        ],
        AddMemberTitle: [
            "Add member",
            "Agregar miembro",
            "Mitglied hinzufügen",
        ],
        AddMemberDescription: [
            "Add or invite a new member.",
            "Agrega o invita a un nuevo miembro.",
            "Ein neues Mitglied hinzufügen oder einladen.",
        ],
        TimelineDescription: [
            "A driven route is called a Track, all tracks can be seen here.",
            "Una ruta recorrida se llama Ruta, todas las rutas se pueden ver aquí.",
            "Eine gefahrene Strecke wird als Track bezeichnet, alle Tracks können hier gesehen werden.",
        ],
        StopsDescription: [
            "When team members arrive at a Place (Geofence), this can trigger a notification. These stops are listed here.",
            "Cuando los miembros del equipo lleguen a uno de sus lugares, se puede activar una notificación. Estas paradas se listan aquí.",
            "Wenn Teammitglieder einen Platz (Geofence) erreichen, kann dies eine Benachrichtigung auslösen. Diese Stopps werden hier aufgelistet.",
        ],
        AppLoginDescription: [
            '<p>We recommend trying out the mobile app to get a sense of your field staff experience. Operators and Workers cannot access Administrators’ location.</p><p>To see your location on the map:</p><ol><li>Download Hellotracks from App Store or Google Play</li><li>Login, your username is {0}</li><li>Review and accept permissions, especially your location access (always on)</li><li>Activate the "In Service" switch at the bottom of the app.</li></ol>',
            '<p>Te recomendamos probar la aplicación móvil para conocer la tener experiencia de tu personal de campo. Los operadores y empleados no pueden acceder a la ubicación de los administradores.</p><p>Para ver tu ubicación en el mapa:</p><ol><li>Descarga Hellotracks desde App Store o Google Play</li><li>Inicia sesión, tu nombre de usuario es {0}</li><li>Revisa y acepta los permisos, especialmente el acceso a tu ubicación (siempre activado)</li><li>Activa el interruptor "En servicio" en la parte inferior de la aplicación.</li></ol>',
            '<p>Wir empfehlen, die mobile App selber auszuprobieren. Disponenten und Arbeiter können nicht auf den Standort von Administratoren zugreifen.</p><p>Um deinen Standort auf der Karte anzuzeigen:</p><ol><li>Lade Hellotracks aus dem App Store oder Google Play herunter.</li><li>Melden dic an, deine Nutzername lautet {0}</li><li>Überprüfe und akzeptiere die Berechtigungen, insbesondere den Zugriff auf deinen Standort (immer aktiv)</li><li>Aktivieren den Schalter "In Service" unten in der App.</li></ol>',
        ],
        CompanySettingsDescription: [
            "You can manage global settings for your company here",
            "Aquí puedes administrar la configuración global de tu empresa.",
            "Du kannst hier globale Einstellungen für dein Unternehmen verwalten.",
        ],
        AdministratorsTitle: [
            "Administrators",
            "Administradores",
            "Administratoren",
        ],
        AdministratorsDescription: [
            "Manage permissions for administrators.",
            "Administra permisos para administradores.",
            "Berechtigungen für Administratoren verwalten.",
        ],
        OperatorsTitle: [
            "Operators",
            "Operadores",
            "Disponenten",
        ],
        OperatorsDescription: [
            "Manage permissions for operators.",
            "Administra permisos para operadores.",
            "Berechtigungen für Disponenten verwalten.",
        ],
        WorkersTitle: [
            "Workers",
            "Trabajadores",
            "Arbeiter",
        ],
        WorkersDescription: [
            "Manage permissions for workers.",
            "Administre permisos para trabajadores.",
            "Berechtigungen für Arbeiter verwalten.",
        ],
        ChekinNotificationsDescription: [
            "Manage auto check-ins, notifications delay and notifications area.",
            "Administre el registro automático de entrada/salida, el retraso de las notificaciones y el área de notificaciones.",
            "Automatische Ein- und Auschecken, Benachrichtigungsverzögerung und Benachrichtigungsbereich verwalten.",
        ],
        AutoCheckinsTitle: [
            "Auto Check-ins",
            "Registro automático de entrada/salida",
            "Automatische Ein- und Auschecken",
        ],
        AutoCheckinsDescription: [
            'An Operator can edit Company Settings to automatically check in/out a worker once they have entered/leave the radius of a Place.',
            'Un operador puede editar la configuración de la empresa para hacer que el registro de entrada/salida de un trabajador sea automático una vez que haya entrado/salido del radio de un lugar.',
            'Ein Operator kann die Unternehmenseinstellungen bearbeiten, um automatisch den Ein- und Auscheckvorgang eines Mitarbeiters zu erfassen, sobald er/sie den Radius eines Ortes betreten/verlassen hat.',
        ],
        DelayDescription: [
            "Adds a minimum time before check-in and out is registered (e.g. 10min in order to avoid workers driving by in a car).",
            "Agrega un tiempo mínimo antes de que se registre el check-in y check-out (p. ej. 10 minutos para evitar que los trabajadores pasen en un coche).",
            "Fügt eine Mindestzeit hinzu, bevor der Ein- und Auscheckvorgang erfasst wird (z. B. 10 Minuten, um zu vermeiden, dass Mitarbeiter im Auto vorbeifahren).",
        ],
        AreaDescription: [
            "Places (Geofencing) are customizable.",
            "Los lugares (geovallado) son personalizables.",
            "Plätze (Geofencing) sind anpassbar.",
        ],
        TrackingSettingsTitle: [
            "Tracking settings",
            "Configuración de seguimiento",
            "Tracking-Einstellungen",
        ],
        TrackingSettingsDescription: [
            "Enable/disable tracking. Manage older tracks.",
            "Habilitar/deshabilitar seguimiento. Gestionar los seguimientos antiguos.",
            "Tracking aktivieren/deaktivieren. Ältere Tracks verwalten.",
        ],
        TrackingTitle: [
            "Tracking",
            "Seguimiento",
            "Tracking",
        ],
        TrackingWorkingHoursTitle: [
            "Working hours",
            "Horario de trabajo",
            "Arbeitszeiten",
        ],
        ManageTracksTitle: [
            "Manage tracks",
            "Administrar seguimientos",
            "Tracks verwalten",
        ],
        ManageTracksDescription: [
            "Set the max time you want to keep your tracks.",
            "Establecer el tiempo máximo que deseas mantener tus rutas.",
            "Lege die maximale Zeit fest, wie lange du die Tracks behalten möchtest.",
        ],
        OnboardFinished: [
            "Congrats! You have finish the onboarding.",
            "¡Felicidades! Has terminado el recorrido.",
            "Herzlichen Glückwunsch! Du hast die Einführungsphase abgeschlossen",
        ],
        CompanyPermissionsTitle: [
            'Permissions',
            'Permisos',
            'Berechtigungen',
        ],
        CompanyPermissionsDescription: [
            "You can manage global permissions for your company here",
            "Aquí puedes administrar los permisos globales para tu empresa.",
            "Hier kannst du globale Berechtigungen für dein Unternehmen verwalten.",
        ],
    },
    Signup: {
        Title: [
            "Hellotracks Sign Up",
            "Hellotracks Regístrate",
            "Hellotracks Sign Up",
        ],
        Paragraph: [
            "“Ultimately, we were able to increase productivity, which decreases cost. Since implementation, our system has almost doubled in size but our headcount has not needed to grow at the same rate. All of our systems around the country were able to adopt Hellotracks and all of them still use it today.”",
            "“Fundamentalmente pudimos aumentar la productividad y reducir costos. Desde la implementación, nuestras operaciones se han duplicado en capacidad, pero nuestro equipo no ha tenido que crecer al mismo ritmo. Las nuestras operaciones en todo el país pudieron adoptar Hellotracks y todos los equipos todavía lo usan hoy”.",
            "„Letztendlich konnten wir die Produktivität steigern, was die Kosten senkt. Seit der Implementierung hat sich unser System fast verdoppelt, aber unsere Mitarbeiterzahl musste nicht im gleichen Maße wachsen. Alle unsere Systeme im ganzen Land konnten Hellotracks übernehmen und alle verwenden es noch heute.“",
        ],
        ClientsDescription: [
            "Trusted by thousands of teams worldwide",
            "Miles de equipos en todo el mundo confían en Hellotracks",
            "Tausende Teams weltweit vertrauen Hellotracks",
        ],
        HaveAccount: [
            "Already have an account?",
            "¿Ya tienes una cuenta?",
            "Hast du bereits ein Konto?",
        ],
        SignIn: [
            "Sign in",
            "Inicia sesión",
            "Login",
        ],
        Continue: [
            "Continue",
            "Continuar",
            "Weiter",
        ],
        NameTitle: [
            "Start your free 30-day trial at Hellotracks Business",
            "Inicia tu prueba gratuita por 30 días con Hellotracks Business",
            "Starte deine kostenlose 30-Tage-Testversion",
        ],
        Description: [
            "How should we greet you?",
            "¿Cómo deberíamos saludarte?",
            "Wie sollen wir dich begrüßen?",
        ],
        NamePlaceholder: [
            "Enter your name",
            "Ingresa tu nombre",
            "Name eingeben",
        ],
        EmailTitle: [
            "Enter your email",
            "Ingresa tu email",
            "E-mail eingeben",
        ],
        EmailPlaceholder: [
            "This will be your login",
            "Este será tu inicio de sesión",
            "Das wird dein Login sein",
        ],
        Back: [
            "Back",
            "Atrás",
            "Zurück",
        ],
        SkipAll: [
            "Skip all questions",
            "Saltar todas las preguntas",
            "Alle Fragen überspringen",
        ],
        PasswordTitle: [
            "Create new password",
            "Crear una nueva contraseña",
            "Neues Passwort erstellen",
        ],
        CreateAccount: [
            "Create Account",
            "Crear cuenta",
            "Konto erstellen",
        ],
        Terms: [
            "By clicking “Create Account” you agree to Hellotracks’ <a href=\"https://hellotracks.com/en/terms-of-service/\" target=\"_blank\" class=\"text-white\">Terms of Service</a> and <a href=\"https://hellotracks.com/en/privacy-notice/\" target=\"_blank\" class=\"text-white\">Privacy Policy</a>.",
            "Al hacer clic a \"Crear cuenta\" aceptas los <a href=\"https://hellotracks.com/en/terms-of-service/\" target=\"_blank\" class=\"text-white\">Términos de servicio</a> y <a href=\"https://hellotracks.com/en/privacy-notice/\" target=\"_blank\" class=\"text-white\">Política de privacidad</a>.",
            "Durch Klicken auf \"Konto erstellen\" stimmst du den <a href=\"https://hellotracks.com/en/terms-of-service/\" target=\"_blank\" class=\"text-white\">Nutzungsbedingungen</a> and <a href=\"https://hellotracks.com/en/privacy-notice/\" target=\"_blank\" class=\"text-white\">Datenschutzerklärungen</a> zu.",
        ],
        WelcomeTitle: [
            "We’ve got a couple questions",
            "Tenemos un par de preguntas",
            "Wir haben ein paar kurze Fragen",
        ],
        WelcomeDescription1: [
            "Welcome to Hellotracks,",
            "Te damos la bienvenida a Hellotracks,",
            "Willkommen zu Hellotracks,",
        ],
        WelcomeDescription2: [
            "They will help us tailor your experience with Hellotracks",
            "Nos ayudarán a personalizar tu experiencia con Hellotracks.",
            "Diese helfen uns, Hellotracks auf dich abzustimmen.",
        ],
        EstimatedUsersTitle: [
            "Estimated number of users in Hellotracks",
            "Número estimado de usuarios en Hellotracks",
            "Geschätzte Anzahl von Nutzern in Hellotracks",
        ],
        IndustryTitle: [
            "Please, select your industry",
            "Por favor, selecciona tu industria",
            "Bitte wähle deine Branche",
        ],
        IndustryRepairMaintenance: [
            "Repair & Maintenance",
            "Reparación & Mantenimiento",
            "Reparatur und Instandhaltung",
        ],
        IndustryMobilityAndTransportation: [
            "Mobility and Transportation",
            "Movilidad & Transporte",
            "Mobilität und Transport",
        ],
        IndustryPublicSector: [
            "Public Sector",
            "Sector Publico",
            "Öffentlicher Sektor",
        ],
        IndustryLogisticsDistribution: [
            "Logistics & Distribution",
            "Logistica & Distribución",
            "Logistik & Vertrieb",
        ],
        Other: [
            "Other...",
            "Otra...",
            "Andere...",
        ],
        RoleTitle: [
            "What best describes your role?",
            "¿Qué opción describe mejor tus funciones?",
            "Was beschreibt deine Rolle am besten?",
        ],
        RoleOperationsmanagerDirector: [
            "Operations Manager/Director",
            "Gerente de Operaciones",
            "Betriebsleiter/Direktor",
        ],
        RoleFieldStaffManagerDispatcher: [
            "Field Staff Manager/Dispatcher",
            "Gerente del personal de campo",
            "Außendienstleiter/Disponent",
        ],
        RoleDeveloperItConsultant: [
            "Developer / IT consultant",
            "Consultor(a) de IT o desarrollador(a)",
            "Entwickler/IT-Berater",
        ],
        RoleHr: [
            "HR",
            "HR",
            "RH",
        ],
        RoleEntrepreneurOwner: [
            "Entrepreneur / Owner",
            "Emprendedor(a) o Dueño(a)",
            "Unternehmer",
        ],
        FindusTitle: [
            "How did you find us?",
            "Cómo nos encontraste?",
            "Wie hast du uns gefunden?",
        ],
        FindusGoogleSearch: [
            "Google Search",
            "Búsqueda en Google",
            "Google Suche",
        ],
        FindusFacebook: [
            "Facebook",
            "Facebook",
            "Facebook",
        ],
        FindusLinkedin: [
            "Linkedin",
            "Linkedin",
            "Linkedin",
        ],
        FindusSoftwareMarketPlace: [
            "Software Market Place",
            "Software Market Place",
            "Software-Marktplatz",
        ],
        FindusReferral: [
            "Referral",
            "Recomendación",
            "Referral",
        ],
        GoalTitle: [
            "What is the #1 goal you would like to achieve?",
            "¿Cuál es el objetivo #1 que te gustaría lograr?",
            "Was ist dein #1 Ziel, das erreichen möchtest?",
        ],
        GoalLiveLocation: [
            "Access real-time location of my field staff.",
            "Acceder a la ubicación en tiempo real de mi personal en campo.",
            "Zugriff auf den Echtzeit-Standort meines Außendienstmitarbeiters.",
        ],
        GoalCheckins: [
            "Automatically register arrivals, departures and total onsite time.",
            "Registrar automáticamente las llegadas, salidas y el tiempo total.",
            "Automatisches Registrieren von Ankünften, Abfahrten und der Gesamtaufenthaltszeit.",
        ],
        GoalLocationHistory: [
            "Access to mileage, location history, total distance and stops.",
            "Acceder al kilometraje, historial de ubicaciones, distancia total y paradas.",
            "Zugriff auf die Standort-historie, Gesamtdistanz und Stopps.",
        ],
        GoalDispatch: [
            "Optimize jobs/tickets dispatch/assignation.",
            "Optimice la asignación/despacho de trabajos/tickets.",
            "Optimierung der Auftragszuweisung/-verteilung.",
        ],
        GoalData: [
            "Accurately retrieve service related data.",
            "Recupere datos relacionados con el servicio.",
            "Abrufen von dienstbezogenen Daten.",
        ],
        DoneTitle: [
            "All done!",
            "¡Listo!",
            "Alles erledigt!",
        ],
        DoneDescription: [
            "You are ready to start using Hellotracks",
            "Puedes comenzar a usar Hellotracks",
            "Jetzt bist du bereit, Hellotracks zu verwenden",
        ],
        ToDashboard: [
            "Go to dashboard",
            "Ir al Dashboard",
            "Gehe zu Dashboard",
        ],
        EmailAlreadyUsed: [
            "This email address is already being used",
            "Este email ya está registrado.",
            "Diese E-Mail-Adresse wird bereits verwendet",
        ],
        EmailInvalid: [
            "Enter a valid E-mail",
            "Ingresa un email válido.",
            "Gib eine gültige E-Mail ein",
        ],
        PasswordInvalid: [
            "One or more characters are not allowed",
            "Uno o más caracteres no son permitidos",
            "Ein oder mehrere Zeichen sind nicht erlaubt",
        ],
        PasswordLength: [
            "At least 6 characters",
            "Al menos 6 caracteres",
            "Mindestens 6 Zeichen",
        ],
        SomethingWentWrong: [
            "Something went wrong, please try again.",
            "Algo salió mal. Por favor, vuelva a intentarlo",
            "Etwas ist schief gelaufen. Bitte versuche es erneut.",
        ],
        PasswordStrongPassword: [
            "Your password is strong ✅",
            "Tu contraseña es fuerte ✅",
            "Dein Passwort ist stark ✅"
        ],
        PasswordNoWhitespace: [
            "Cannot contain spaces",
            "No debe contener espacios",
            "Darf keine Leerzeichen enthalten"
        ],
        Password8Chars: [
            "At least 8 characters",
            "Al menos 8 caracteres",
            "Mindestens 8 Zeichen"
        ],
        PasswordLowerCase: [
            "Include lowercase characters or use longer password",
            "Debe contener caracteres en minúscula o usar una contraseña más larga",
            "Sollte Kleinbuchstaben enthalten oder ein längeres Passwort verwenden"
        ],
        PasswordUpperCase: [
            "Include upper case characters or use longer password",
            "Debe contener caracteres en mayúscula o usar una contraseña más larga",
            "Sollte Großbuchstaben enthalten oder ein längeres Passwort verwenden"
        ],
        PasswordNumber: [
            "Include number or use longer password",
            "Debe contener un número o usar una contraseña más larga",
            "Sollte eine Zahl enthalten oder ein längeres Passwort verwenden"
        ],
    },
    Assistant: {
        Tooltip: ["Open AI Assistant", "Abrir asistente IA", "KI Assistent öffnen"],
        TooltipTip: [
            "Tip: Double-click {0}",
            "Consejo: Haz doble clic en {0}",
            "Tipp: Doppelklick auf {0}"
        ],
        HangTight: [
            "Usually takes a couple of seconds. Hang tight :)",
            "Normalmente tarda un par de segundos. :)",
            "Dauert normalerweise ein paar Sekunden. Bleib dran :)"
        ],
        XCreated: ["{0} created", "{0} ha sido creado", "{0} wurde erstellt"],
        ZoneCreated: ["Zone created", "Zona creada", "Zone erstellt"],
        JobCreated: ["Job created", "Tarea creada", "Auftrag erstellt"],
        JobCreatedForX: ["Job created for {0}", "Tarea creada para {0}", "Auftrag erstellt für {0}"],
        History: ["History", "Historial", "Verlauf"],
        InputPlaceholder: [
            "input @ to mention members, use # for places",
            "introduce @ para mencionar miembros, usa # para lugares",
            "Gib @ ein, um Mitglieder zu erwähnen, benutze # für Plätze"
        ],
        Title: ["AI Assistant", "Asistente de IA", "KI Assistent"],
        DisabledDescription: [
            "This feature is currently disabled for operators. You can enable it here and in the company settings | Permissions.",
            "Esta función está actualmente desactivada para los operadores. Puedes activarla aquí y en la configuración de la empresa | Permisos.",
            "Diese Funktion ist derzeit für Disponenten deaktiviert. Es kann hier und in den Unternehmenseinstellungen | Berechtigungen aktiviert werden.",
        ],
        DisabledWarning: [
            "The assistant is currently disabled for your account. It can be enabled by administrators in the Company Settings | Member Permissions.",
            "El asistente está actualmente desactivado para tu cuenta. Los administradores pueden activarlo en la Configuración Empresa | Permisos Miembros.",
            "Der Assistent ist derzeit für dein Konto deaktiviert. Administratoren können ihn in den Unternehmenseinstellungen | Berechtigungen Mitglieder aktivieren.",
        ],
        Enable: [
            "Enable for Operators",
            "Activar para Operadores",
            "Für Disponenten aktivieren",
        ],
        DispatchAssistant: [
            "Dispatch Assistant",
            "Asistente Despacho",
            "Dispatch Assistant",
        ],
        HelpAssistant: [
            "Help Assistant",
            "Asistente de Ayuda",
            "Help Assistant",
        ]
    },
    Audio: {
        StartRecordingAudioTranscript: [
            "Start recording audio transcript",
            "Comenzar a grabar la transcripción de audio",
            "Beginne mit der Aufzeichnung des Audio-Transkripts",
        ],
        StopRecording: [
            "Stop recording",
            "Detener la grabación",
            "Aufnahme beenden",
        ],
        GrantMicPermission: [
            "Please grant permission to use the mic",
            "Por favor, permíte usar el micrófono",
            "Bitte erlaube die Benutzung des Mikrofons"
        ]
    },
    ChatAi: {
        Title: [
            "Find out if Hellotracks fits your needs",
            "Descubre si Hellotracks se ajusta a tus necesidades",
            "Finde heraus, ob Hellotracks zu deinen Anforderungen passt",
        ],
        Skip: [
            "Skip",
            "Saltar",
            "Überspringen"
        ],
        WhatsYourName: [
            "What's your name?",
            "¿Cómo te llamas?",
            "Wie ist dein Name?",
        ],
        BriefDescription: [
            "Describe very briefly what you would like to achieve.",
            "Describe brevemente lo que te gustaría lograr.",
            "Beschreiben Sie kurz, was Sie erreichen möchten.",
        ],
        AskAnything: [
            "Ask me anything.",
            "Pregúntame lo que quieras.",
            "Frag mich alles.",
        ],
        PricingCancellation: [
            "Tell be about pricing. Can I cancel any time?",
            "¿Cuál es el precio y puedo cancelar en cualquier momento?",
            "Erzählen Sie mir von der Preisgestaltung. Kann ich jederzeit kündigen?",
        ],
        ScheduleDemo: [
            "How can I schedule a demo?",
            "¿Cómo puedo programar una demostración?",
            "Wie kann ich eine Demo planen?",
        ],
        HardwareNeeded: [
            "Do I need any hardware or special devices?",
            "¿Necesito algún hardware o dispositivos especiales?",
            "Benötige ich spezielle Hardware oder Geräte?",
        ],
        NewInformation: [
            "Tell me something I didn't know yet.",
            "Dime algo que aún no sepa.",
            "Erzähl mir etwas, was ich noch nicht wusste.",
        ],
        BenefitsCompared: [
            "What are the benefits compared to other solutions?",
            "¿Cuáles son los beneficios en comparación con otras soluciones?",
            "Welche Vorteile gibt es im Vergleich zu anderen Lösungen?",
        ],
        ConcreteExamples: [
            "Can you list a few concrete examples?",
            "¿Puede enumerar algunos ejemplos concretos?",
            "Können Sie ein paar konkrete Beispiele nennen?",
        ],
        ContactHuman: [
            "How can I contact a real human?",
            "¿Cómo puedo contactar a un humano real?",
            "Wie kann ich einen echten Menschen kontaktieren?",
        ],
        TrialPeriod: [
            "What's the trial period?",
            "¿Cuál es el periodo de prueba?",
            "Wie lange ist die Testphase?",
        ],
        GetStarted: [
            "How do I get started?",
            "¿Cómo puedo empezar?",
            "Wie fange ich an?",
        ],
        MyGoalWithHellotracksIs: [
            "My #1 goal with Hellotracks is {0}",
            "Mi objetivo principal con Hellotracks es {0}",
            "Mein Hauptziel mit Hellotracks ist {0}",
        ],
        MyIndustryIs: [
            "My industry is {0}",
            "Mi industria es {0}",
            "Meine Branche ist {0}",
        ],
        BackToWebsite: [
            "Back to website",
            "Volver al sitio web",
            "Zurück zur Website",
        ],
        AmaTitle: [
            "Ask me anything. I can help!",
            "¡Pregúntame lo que quieras. Puedo ayudarte!",
            "Frag mich alles. Ich kann helfen!",
        ],
        ContactUs: [
            "Contact us",
            "Contáctanos",
            "Kontaktiere uns",
        ],
    },
    Timesheet: {
        NewEntry: [
            "New timesheet entry",
            "Nuevo registro de hoja de tiempo",
            "Neue Zeiteintragung"
        ],
    },
    Activities: {
        ActivityLog: [
            "Activity Log",
            "Registro de actividad",
            "Aktivtätsprotokoll",
        ],
        ActivityLogDesc: [
            "Activity log table with recent activities in the field",
            "Tabla de registro de actividad con las actividades recientes en el campo",
            "Aktivitätsprotokolltabelle mit den jüngsten Aktivitäten im Feld",
        ],
        Activity: [
            "Activity",
            "Actividad",
            "Aktivität"
        ],
        SearchActivity: [
            "Search activity",
            "Buscar actividad",
            "Aktivität suchen",
        ]
    },
    Clustering: {
        Clustering: [
            "Clustering",
            "Agrupamiento",
            "Clustering"
        ],
        Info: [
            "You can group unassigned jobs and assign them to your team. Our clustering algorithm takes distance and zones into account",
            "Puedes agrupar tareas no asignadas y asignarlas a tu equipo. Nuestro algoritmo de agrupación tiene en cuenta la distancia y las zonas",
            "Du kannst unzugewiesene Aufträge gruppieren und sie deinem Team zuweisen. Unser Clustering-Algorithmus berücksichtigt Entfernung und Zonen",
        ],
        ClusteringXSelectedJobs: [
            "Clustering {0} selected jobs",
            "Agrupando {0} tareas seleccionadas",
            "Clustering von {0} ausgewählten Aufträgen",
        ],
        SelectUpToXJobsForClustering: [
            "Select up to {0} unassigned jobs for clustering",
            "Selecciona hasta {0} tareas no asignadas para el agrupamiento",
            "Wähle bis zu {0} nicht-zugewiesene Aufträge zum Clustering aus",
        ],
        CreatingXClusters: [
            "Creating {0} clusters",
            "Creando {0} grupos",
            "Erstelle {0} clusters",
        ],
        XClusters: [
            "{0} clusters",
            "{0} grupos",
            "{0} clusters",
        ],
        OneCluster: [
            "1 cluster",
            "1 grupo",
            "1 cluster",
        ],
    },
    Plan: {
        YourPlan: ["Your Plan", "Tu Plan", "Dein Plan"],
        SelectPlan: ["Select Plan", "Seleccionar Plan", "Wähle einen Plan aus"],
        YourCurrentPlanIsX: ["Your current plan is {0}", "Tu plan actual es {0}", "Dein aktueller Plan ist {0}"],
        LegacyPlanDescription: [
            "This is our standard Classic Plan. You have the option to continue with this plan until March 31, 2024. Alternatively, you can explore our other plans, such as those offering annual discounts, available for selection today. If you require further details or have any questions, please don't hesitate to ask.",
            "Este es nuestro plan Clásico estándar. Puedes continuar con este plan hasta el 31 de marzo de 2024. Alternativamente, puedes explorar nuestros otros planes, como aquellos que ofrecen descuentos anuales, disponibles para seleccionar hoy. Si necesitas más detalles o tienes alguna pregunta, no dudes en preguntar.",
            "Dies ist unser Standard Classic Plan. Du kannst diesen Plan bis zum 31. März 2024 beibehalten. Alternativ kannst du unsere anderen Pläne erkunden, wie zum Beispiel diejenigen, die jährliche Rabatte bieten, die heute zur Auswahl stehen. Wenn du weitere Details benötigst oder Fragen hast, zögere bitte nicht zu fragen.",
        ],
        SeeAllFeatures: ["See All Features", "Ver todas las características", "Sieh dir alle Funktionen an"],
        SelectCurrency: ["Select Currency", "Seleccionar Moneda", "Währung auswählen"],
        Monthly: ["Monthly", "Mensual", "Monatlich"],
        Yearly: ["Annual", "Anual", "Jährlich"],
        Aprox: ["approx.", "aprox.", "ca."],
        SwitchPlanPrompt: [
            "Do you wish to switch to this new plan?",
            "¿Deseas cambiar a este nuevo plan?",
            "Möchtest du zu diesem neuen Plan wechseln?",
        ],
        ChoosePlanInBilling: [
            "Choose your Plan in Billing",
            "Elije tu Plan en Facturación",
            "Wähle deinen Plan in Zahlungsdaten",
        ],
        ClickHereToLearnMore: [
            "Click here to learn more",
            "Haz clic aquí para saber más",
            "Klicke hier, um mehr zu erfahren",
        ]
    },
    LocationFeatures: {
        RealTimeLocation: ["Real-time location", "Ubicación en tiempo real", "Echtzeit-Standort"],
        UnlimitedLocationHistory: [
            "Unlimited Location history (Route recording)",
            "Historial de ubicación ilimitado (Registro de ruta)",
            "Unbegrenzter Standortverlauf (Routenaufzeichnung)"
        ],
        Geofencing: [
            "Geofencing - Auto Check-ins/outs",
            "Geocercas - Auto registros de entrada/salida",
            "Geofencing - Automatische Check-ins/outs"
        ],
        OnsiteTimeRecording: [
            "Onsite time recording at POI / client location",
            "Registro de tiempo en el lugar en POI / ubicación del cliente",
            "Zeiterfassung vor Ort bei POI / Kundenstandort"
        ],
        Zones: [
            "Zones (highlight areas, alerting)",
            "Zonas (resaltar áreas, alertas)",
            "Zonen (Hervorhebung von Bereichen, Alarmierung)"
        ],
        TimeTracker: [
            "Time Tracker (recording work hours)",
            "Rastreador de tiempo (registro de horas de trabajo)",
            "Zeiterfassung (Aufzeichnung der Arbeitsstunden)"
        ],
        Alerts: [
            "Alerts for idling, outside geofence and more",
            "Alertas por inactividad, fuera de geocerca y más",
            "Alarme bei Leerlauf, außerhalb des Geofence und mehr"
        ],
        Reports: [
            "Reports for Mileage and Onsite times",
            "Informes de kilometraje y tiempos en el lugar",
            "Berichte über Kilometerstand und Vor-Ort-Zeiten"
        ]
    },
    DispatchFeatures: {
        HellotracksLocation: [
            "All features from Hellotracks Location",
            "Todas las características de Hellotracks Location",
            "Alle Funktionen von Hellotracks Location",
        ],
        JobScheduling: [
            "Job scheduling",
            "Programación de trabajos",
            "Jobplanung",
        ],
        AutoDispatch: [
            "Auto-Dispatch",
            "Despacho automático",
            "Auto-Dispatch",
        ],
        RouteOptimization: [
            "Route optimization (mileage and time saving)",
            "Optimización de ruta (ahorro de kilometraje y tiempo)",
            "Routenoptimierung (Kilometer- und Zeiteinsparung)",
        ],
        OnsiteTimeRecordingJobs: [
            "Onsite time recording at Jobs / Services",
            "Registro de tiempo en el lugar en Trabajos / Servicios",
            "Zeiterfassung vor Ort bei Jobs / Dienstleistungen",
        ],
        ClientNotifications: [
            "Client notifications",
            "Notificaciones al cliente",
            "Kundenbenachrichtigungen",
        ],
        SignatureCapture: [
            "Signature capture",
            "Captura de firma",
            "Unterschriftserfassung",
        ],
        ImageUploads: [
            "Image uploads",
            "Subidas de imágenes",
            "Bilduploads",
        ],
        Forms: [
            "Forms (basic + advanced)",
            "Formularios (básicos + avanzados)",
            "Formulare (einfach + fortgeschritten)",
        ],
        ReportsJobPerformance: [
            "All Reports including Job performance",
            "Todos los informes incluido el rendimiento del trabajo",
            "Alle Berichte einschließlich Jobperformance",
        ],
        RecurringReports: [
            "Automated recurring reports",
            "Informes recurrentes automatizados",
            "Automatisierte wiederkehrende Berichte",
        ],
        Integrations: [
            "Integrations with Slack and Google Calendar",
            "Integraciones con Slack y Google Calendar",
            "Integrationen mit Slack und Google Kalender",
        ],
        APIAccess: [
            "API access",
            "Acceso a la API",
            "API-Zugriff",
        ],
        TwoFactorAuthentication: [
            "Two-Factor-Authentication",
            "Autenticación de dos factores",
            "Zwei-Faktor-Authentifizierung",
        ],
    },
    DeviceDiagnostics: {
        DeviceDiagnostics: [
            "Device Diagnostics",
            "Diagnóstico del Dispositivo",
            "Gerätediagnostik"
        ],
        Description: [
            "The device diagnostics provides a visual representation of key device activities, such as location changes, app states, connectivity, and server communication, to aid in diagnostics and troubleshooting.",
            "El diagnóstico del dispositivo proporciona una representación visual de las actividades clave del dispositivo, como cambios de ubicación, estados de la aplicación, conectividad y comunicación con el servidor, para ayudar en el diagnóstico y la resolución de problemas.",
            "Die Gerätediagnostik bietet eine visuelle Darstellung wichtiger Geräteaktivitäten, wie Standortänderungen, App-Status, Konnektivität und Serverkommunikation, um bei der Diagnose und Fehlerbehebung zu helfen.",
        ],
        Trigger: [
            "Trigger new device diagnostics",
            "Activar nuevo diagnóstico del dispositivo",
            "Neue Gerätediagnostik auslösen",
        ],
        DeviceDiagnosticsRequest: [
            "Device Diagnostics Request",
            "Solicitud de Diagnóstico del Dispositivo",
            "Anfrage zur Gerätediagnostik"
        ],
        Requested: [
            "Request sent",
            "Solicitud enviada",
            "Anfrage gesendet"
        ],
        UnavailableNotLoggedIn: [
            "User is not logged into a mobile device.",
            "El usuario no ha iniciado sesión en un dispositivo móvil.",
            "Benutzer ist nicht auf einem mobilen Gerät angemeldet."
        ],
        UnavailableNeedsUpdate: [
            "User needs to update mobile device.",
            "El usuario necesita actualizar el dispositivo móvil.",
            "Benutzer muss das mobile Gerät aktualisieren."
        ],
        DeviceDiagnosticsUploaded: [
            "Device Diagnostics uploaded:",
            "Diagnósticos del dispositivo subidos:",
            "Gerätediagnostik hochgeladen:"
        ],
        StartStopTrackingEvents: [
            "Start/Stop Tracking Events",
            "Iniciar/Detener Eventos de Seguimiento",
            "Start/Stopp der Verfolgungsereignisse"
        ],
        TrackingEventsTooltip: [
            "Tracks start and stop events of location updates",
            "Rastrea eventos de inicio y detención de actualizaciones de ubicación",
            "Verfolgt Start- und Stop-Ereignisse von Standortaktualisierungen"
        ],
        WakeUpsRunningEvents: [
            "Wake ups and Running Events",
            "Despertares y Eventos en Ejecución",
            "Aufwachen und laufende Ereignisse"
        ],
        WakeUpsRunningEventsTooltip: [
            "Displays wake-up triggers and running events. Blue for location updates running. Green for geofence exits.",
            "Muestra disparadores de despertar y eventos en ejecución. Azul para actualizaciones de ubicación en ejecución. Verde para salidas de geovalla.",
            "Zeigt Weckauslöser und laufende Ereignisse an. Blau für laufende Standortaktualisierungen. Grün für Geo-Zaun-Verlassen."
        ],
        AppStateEvents: [
            "App State Events",
            "Eventos de Estado de la App",
            "App-Zustandsereignisse"
        ],
        AppStateEventsTooltip: [
            "Shows different states of the app. Blue for app launched events. Green for app active state. Orange for app background state. Red for app terminated.",
            "Muestra los diferentes estados de la aplicación. Azul para eventos de aplicación lanzada. Verde para estado activo de la aplicación. Naranja para estado en segundo plano de la aplicación. Rojo para aplicación terminada.",
            "Zeigt verschiedene Zustände der App an. Blau für gestartete App-Ereignisse. Grün für den aktiven Zustand der App. Orange für den Hintergrundzustand der App. Rot für beendete App."
        ],
        BackgroundTickEvents: [
            "Background Tick Events",
            "Eventos de Marcado en Segundo Plano",
            "Hintergrund-Tick-Ereignisse"
        ],
        BackgroundTickEventsTooltip: [
            "Indicates background tick occurrences. Green for periodic foreground ticks. Black/white for periodic background ticks.",
            "Indica ocurrencias de marcado en segundo plano. Verde para marcas periódicas en primer plano. Blanco/negro para marcas periódicas en segundo plano.",
            "Zeigt Vorkommnisse von Hintergrund-Ticks an. Grün für periodische Vordergrund-Ticks. Schwarz/Weiß für periodische Hintergrund-Ticks."
        ],
        LocationUploadEvents: [
            "Location Upload Events",
            "Eventos de Carga de Ubicación",
            "Standort-Upload-Ereignisse"
        ],
        LocationUploadEventsTooltip: [
            "Logs events of location data uploads. Purple for uploads via API. Black/white for uploads via WebSocket.",
            "Registra eventos de cargas de datos de ubicación. Púrpura para cargas a través de API. Blanco/negro para cargas a través de WebSocket.",
            "Protokolliert Ereignisse von Standortdaten-Uploads. Lila für Uploads über API. Schwarz/Weiß für Uploads über WebSocket."
        ],
        ConnectivityEvents: [
            "Connectivity Events",
            "Eventos de Conectividad",
            "Konnektivitätsereignisse"
        ],
        ConnectivityEventsTooltip: [
            "Monitors connectivity changes. Green for when the device is online. Gray for when the device goes offline.",
            "Monitorea los cambios de conectividad. Verde cuando el dispositivo está en línea. Gris cuando el dispositivo está desconectado.",
            "Überwacht Konnektivitätsänderungen. Grün, wenn das Gerät online ist. Grau, wenn das Gerät offline geht."
        ],
        WebsocketConnectivity: [
            "Websocket Events (Connectivity)",
            "Eventos de WebSocket (Conectividad)",
            "WebSocket-Ereignisse (Konnektivität)"
        ],
        WebsocketConnectivityTooltip: [
            "Websocket connectivity status. Blue when connected. Orange when disconnected. Green upon successful connection attempt.",
            "Estado de la conectividad de WebSocket. Azul cuando está conectado. Naranja cuando está desconectado. Verde al intentar conectar con éxito.",
            "WebSocket-Konnektivitätsstatus. Blau bei Verbindung. Orange bei Trennung. Grün bei erfolgreichem Verbindungsversuch."
        ],
        WebsocketSendEvents: [
            "WebSocket Send Events",
            "Eventos de Envío WebSocket",
            "WebSocket-Sendevorgänge"
        ],
        WebsocketSendEventsTooltip: [
            "Tracks WebSocket send events. Purple for successful sends. Gray for send errors.",
            "Registra eventos de envío de WebSocket. Morado para envíos exitosos. Gris para errores de envío.",
            "Verfolgt WebSocket-Sendevorgänge. Lila für erfolgreiche Sendungen. Grau für Sendefehler."
        ],
        WebsocketReceiveEvents: [
            "WebSocket Receive Events",
            "Eventos de Recepción WebSocket",
            "WebSocket-Empfangsvorgänge"
        ],
        WebsocketReceiveEventsTooltip: [
            "Monitors received messages over WebSocket. Black/white indicates messages received.",
            "Monitorea los mensajes recibidos a través de WebSocket. El negro/blanco indica los mensajes recibidos.",
            "Überwacht empfangene Nachrichten über WebSocket. Schwarz/Weiß zeigt empfangene Nachrichten an."
        ],
        CheckInEvents: [
            "Check-In Events",
            "Eventos de Registro",
            "Check-In-Ereignisse"
        ],
        CheckInEventsTooltip: [
            "Registered check-in and check-out events. Blue for place check-ins. Black/white for place check-outs. Purple for job check-ins. Gray for job check-outs.",
            "Eventos registrados de registro y salida. Azul para registros de lugar. Negro/blanco para salidas de lugar. Morado para registros de tarea. Gris para salidas de tarea.",
            "Registrierte Check-in- und Check-out-Ereignisse. Blau für Orts-Check-ins. Schwarz/Weiß für Orts-Check-outs. Lila für Auftrags-Check-ins. Grau für Auftrags-Check-outs."
        ],
    },
    Reorder: {
        RouteReorderOrderNumber: [
            "Reorder all jobs by order #",
            "Reordena todas las tareas por el número de orden",
            "Alle Aufträge nach Auftragsnummer neu ordnen"
        ],
        RouteReorderPriority: [
            "Reorder all jobs by priority",
            "Reordena todas las tareas por prioridad",
            "Alle Aufträge nach Priorität ordnen"
        ],
        ConfirmationReorder: [
            "This will rearrange all jobs of this route and sort. Are you sure?",
            "Esto reorganizará todas las tareas de esta ruta y las ordenará. ¿Estás seguro?",
            "Dies wird alle Aufträge dieser Route neu anordnen und sortieren. Bist du sicher?"
        ],
        ReorderingState: [
            "Reordering",
            "Reordenando",
            "Neuordnung"
        ],
        ReorderAction: [
            "Reorder",
            "Reordenar",
            "Neu ordnen"
        ],
    },
    Sso: {
        ConfigurationHiddenDesc: [
            "Your configuration is securely stored and remains hidden for safety reasons.",
            "Tu configuración se almacena de forma segura y permanece oculta por razones de seguridad.",
            "Ihre Konfiguration wird sicher gespeichert und aus Sicherheitsgründen verborgen."
        ],
        ChangeSsoConfiguration: [
            "Change SSO configuration",
            "Cambiar la configuración de SSO",
            "SSO-Konfiguration ändern"
        ],
        SetupSsoConfiguration: [
            "Setup SSO configuration",
            "Configurar la configuración de SSO",
            "SSO-Konfiguration einrichten"
        ],
        EnterTenantID: [
            "Enter Tenant ID",
            "Ingresar ID de Tenant",
            "Tenant-ID eingeben",
        ],
        EnterClientID: [
            "Enter Client ID",
            "Ingresar ID de Cliente",
            "Client-ID eingeben"
        ],
        EnterClientSecret: [
            "Client Secret (remains hidden for safety reasons)",
            "Secreto de Cliente (permanece oculta por razones de seguridad)",
            "Client Secret (aus Sicherheitsgründen verborgen)"
        ],
        EnterRedirectUrl: [
            "Enter Redirect URL",
            "Ingresar RedirectURL",
            "Redirect URL eingeben"
        ],
        Notes: ['Notes', 'Notas', 'Notizen'],
        NotesContent: [
            "After a successful SSO login of a user that does not yet exist in Hellotracks, the user will automatically be created and the worker role will be assigned. To get into the SSO login page of your identity provider, you can either use an existing username in Hellotracks or set {0} as the username to get forwarded to the SSO login page of your identity provider.",
            "Después de un inicio de sesión SSO exitoso de un usuario que aún no existe en Hellotracks, el usuario será creado automáticamente y se le asignará el rol de trabajador. Para entrar en la página de inicio de sesión SSO de tu proveedor de identidad, puedes usar un nombre de usuario existente en Hellotracks o establecer {0} como el nombre de usuario para ser redirigido a la página de inicio de sesión SSO de tu proveedor de identidad.",
            "Nach einem erfolgreichen SSO-Login eines Benutzers, der in Hellotracks noch nicht existiert, wird der Benutzer automatisch erstellt und ihm wird die Rolle eines Arbeiters zugewiesen. Um auf die SSO-Login-Seite deines Identitätsanbieters zu gelangen, kannst du entweder einen vorhandenen Benutzernamen in Hellotracks verwenden oder {0} als Benutzernamen festlegen, um zur SSO-Login-Seite deines Identitätsanbieters weitergeleitet zu werden."
        ],
        LinkToLoginPage: [
            "Link to SSO-Login Page",
            "Enlace a la página de inicio de sesión SSO",
            "Link zur SSO-Login-Seite",
        ],
        SelectIdentityProvider: [
            "Select Identity Provider",
            "Selecciona Proveedor de Identidad",
            "Identity Provider auswählen"
        ]
    },
    Approval: {
        Approval: [
            "Approval",
            "Aprobación",
            "Genehmigung"
        ],
        Notified: [
            "Notified",
            "Notificado",
            "Benachrichtigt"
        ],
        Pending: [
            "Pending",
            "Pendiente",
            "Ausstehend"
        ],
        Approved: [
            "Approved",
            "Aprobado",
            "Genehmigt"
        ],
        Disapproved: [
            "Rejected",
            "Rechazado",
            "Abgelehnt"
        ],
        Approve: [
            "Approve",
            "Aprobar",
            "Genehmigen"
        ],
        Disapprove: [
            "Reject",
            "Rechazar",
            "Ablehnen"
        ],
        ApprovedBy: [
            "Approved by",
            "Aprobado por",
            "Genehmigt von"
        ],
    },
    Trips: {
        OnTheWayToX: [
            "On the way to {0}",
            "En camino a {0}",
            "Auf dem Weg nach {0}"
        ],
        Started: [
            "Started",
            "Comenzó",
            "Gestartet"
        ],
        OriginalETA: [
            "Original ETA",
            "ETA original",
            "Ursprüngliche ETA"
        ],
        CurrentETA: [
            "Current ETA",
            "ETA actual",
            "Aktuelle ETA"
        ]
    }
};

const TRANSLATION_ENTRIES = Object.entries(TRANSLATIONS) as [keyof typeof TRANSLATIONS, typeof TRANSLATIONS][];

const getTranslations = (index: number): { [k in keyof IStrings]: Record<keyof IStrings[k], string> } => {
    const entries = TRANSLATION_ENTRIES.map(([key, fields]) => {
        const FIELD_ENTRIES = Object.entries(fields);
        const values = FIELD_ENTRIES.map(([key2, value]) => [key2, value[index]]);
        return [key, Object.fromEntries(values)];
    });
    return Object.fromEntries(entries);
};

export const strings = {
    en: getTranslations(EN_TRANSLATION),
    es: getTranslations(ES_TRANSLATION),
    de: getTranslations(DE_TRANSLATION),
};
