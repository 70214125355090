import { LonLat } from "../../types/core";
import { HFlex } from "../containers/HFlex";
import { LabelSmall } from "baseui/typography";
import { Input } from "baseui/input";
import { geocode } from "../../services/privateApi";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTheme } from "../../hooks/useTheme";
import { quickRouteDataState } from "../../states/quickRouteState";
import { QuickRouteItemData } from "../../types/quickRoute";
import { useStrings } from "../../hooks/useStrings";
import { useEffectOnce } from "usehooks-ts";

type Props = {
    index: number;
    lonLat: LonLat;
}

export function QuickRouteJobItem({ index, lonLat }: Props) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const data = useRecoilValue(quickRouteDataState(lonLat));
    const setData = useSetRecoilState(quickRouteDataState(lonLat));

    const setTitle = (title: string) => {
        setData({
            ...data,
            title: title
        } as QuickRouteItemData);
    };

    useEffectOnce(() => {
        geocode({ lat: lonLat[1], lng: lonLat[0] })
            .then(({ status, result }) => {
                if (status && result.address?.formatted) {
                    const newData = {
                        ...data,
                        title: result.address?.formatted,
                        address: result.address,
                    } as QuickRouteItemData;
                    setData(newData);
                }
            });
    });

    return (
        <HFlex spacing alignCenter>
            <LabelSmall style={{ flexShrink: 0, whiteSpace: "nowrap" }}>{strings.General.Stop} #{index + 1}</LabelSmall>
            <Input size="compact" placeholder={strings.Job.EnterJobTitle} value={data.title}
                   onChange={(ev) => setTitle(ev.target.value)}/>
        </HFlex>
    );
}