import { Marker } from "react-map-gl";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { pulseMarkerState } from "../../../states/mapState";
import { useEffect } from "react";

export function PulseMarker() {
    const location = useRecoilValue(pulseMarkerState);
    const reset = useResetRecoilState(pulseMarkerState);

    useEffect(() => {
        if (location) {
            setTimeout(() => reset(), 1250);
        }
    }, [location]);

    if (!location) {
        return null;
    }

    return (
        <Marker
            longitude={location.lng ?? 0}
            latitude={location.lat ?? 0}
            anchor="center"
        >
            <div className={"pulsating-circle"}/>
        </Marker>
    );
}