import { useRecoilValue } from 'recoil';
import { teamsState } from '../../states/teamsState';
import { Team } from '../../types/core';
import { StyledSelect } from '../ui/StyledSelect';
import { HFlex } from "../containers/HFlex";
import { FilterIcon } from "../ui/svg";
import React from "react";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";

type Props = {
    onSelect: (team?: Team) => void;
    size?: 'mini' | 'compact';
    selectedTeam?: Team;
    showFilterIcon?: boolean
};

export function TeamSelect({ onSelect, size = 'compact', selectedTeam, showFilterIcon = false }: Props) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const teams = useRecoilValue(teamsState);
    const option = (team: Team) => {
        return {
            id: team.id,
            label: team.name,
            team: team,
        };
    };
    const options = teams.map((t) => option(t));
    const value = selectedTeam ? [option(selectedTeam)] : [];

    return (
        <StyledSelect
            options={options}
            onChange={(params) => {
                onSelect(params.option?.team);
            }}
            placeholder={(
                <HFlex spacing alignCenter>
                    {showFilterIcon && <FilterIcon size={14} color={theme.colors.contentTertiary}/>}
                    {strings.General.Team}
                </HFlex>
            )}
            size={size}
            value={value}
        />
    );
}