import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import useAccount from "../../hooks/useAccount";
import { showBeaconState } from "../../states/appState";
import { emailValidator } from "../../constants/validators";
import { useStrings } from "../../hooks/useStrings";

export function BeaconProvider() {
    const account = useAccount();
    const showBeacon = useRecoilValue(showBeaconState);
    const [ready, setReady] = useState(false);
    const { strings } = useStrings();

    useEffect(() => {
        document.addEventListener("loadedBeacon", () => {
            // @ts-ignore
            Beacon("config", { display: { style: "manual" } });
            // @ts-ignore
            Beacon("init", strings.Help.Beacon);
            setReady(true);
        });

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.innerHTML = `
            !function(e,t,n,ev){
                function a(){
                    var ev=new Event("loadedBeacon");
                    var e=t.getElementsByTagName("script")[0],
                    n=t.createElement("script");
                    n.type="text/javascript",
                    n.async=!0,
                    n.src="https://beacon-v2.helpscout.net",
                    e.parentNode.insertBefore(n,e),
                    document.dispatchEvent(ev)
                }
                if(e.Beacon=n=function(t,n,a){
                    e.Beacon.readyQueue.push({method:t,options:n,data:a})
                },
                n.readyQueue=[],"complete"===t.readyState)return a();
                e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)
            }(window,document,window.Beacon||function(){});
        `;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        const isEmail = emailValidator.test(account.username);
        const email = isEmail && account.username;
        // @ts-ignore
        Beacon("identify", { name: account.name, ...email, company: account.company?.username });
    }, [ready, account.name, account.username, account.company]);

    useEffect(() => {
        if (!ready || !showBeacon) {
            return;
        }
        // @ts-ignore
        Beacon("toggle");
    }, [ready, showBeacon]);

    return null;
}