import { NotificationCircle } from "baseui/badge";
import { Button } from "baseui/button";
import { FormControl } from 'baseui/form-control';
import { Radio, RadioGroup } from 'baseui/radio';
import { OnChangeParams } from 'baseui/select';
import { Textarea } from 'baseui/textarea';
import { LabelXSmall, ParagraphSmall, ParagraphXSmall } from "baseui/typography";
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import useAccount from '../../../hooks/useAccount';
import { useCompanyDefaults } from '../../../hooks/company/useCompanyDefaults';
import { useJobForms } from '../../../hooks/jobs/useJobForms';
import { editingJobIdState } from '../../../states/appState';
import { editJobState, editJobValidState } from '../../../states/editJobState';
import { memberState } from '../../../states/membersState';
import { CustomField, EMPTY_UID } from '../../../types/core';
import {
    isHighPriority,
    isLowPriority,
    isPickupOrDropoff,
    Job,
    PRIO_HIGH,
    PRIO_LOW,
    PRIO_NORMAL
} from '../../../types/job';
import { Member } from '../../../types/member';
import { ClipboardButton } from '../../buttons/ClipboardButton';
import { FlexGridLayout } from '../../containers/FlexGridLayout';
import { HFlex } from '../../containers/HFlex';
import { SectionedWrapper } from '../../containers/SectionedWrapper';
import { Spacer, SpacerExpand } from '../../containers/Spacer';
import { MemberSelect } from '../../pickers/MemberSelect';
import { CustomFields } from '../../shared/CustomFields';
import { FormInput } from '../../ui/FormInput';
import { EmailIcon, HighPriorityIcon, InfoIcon, LowPriorityIcon, NormalPriorityIcon, PhoneIcon } from '../../ui/svg';
import { Section } from '../../ui/Section';
import { JobTeamSelect } from "../../pickers/JobTeamSelect";
import { JobTypeSelect } from "../../pickers/JobTypeSelect";
import { geocode } from "../../../services/privateApi";
import { useJobTypes } from '../../../hooks/jobs/useJobTypes';
import { StaticMap } from "../../staticmap/StaticMap";
import { Input } from "baseui/input";
import { VFlex } from "../../containers/VFlex";
import { jobCompactVisualizationState } from '../../../states/optionsState';
import { useEditJobLocation } from '../../../hooks/jobs/useEditJobLocation';
import { useTheme } from "../../../hooks/useTheme";
import { useStrings } from "../../../hooks/useStrings";
import { AudioRecorder } from "../../audiorecorder/AudioRecorder";
import { JobConfirmationEmailSection } from "./JobConfirmationEmailSection";
import { Segmented, Tag, Tooltip } from "antd";
import { CreatableTagsInput } from "../../ui/CreatableTagsInput";
import { PlaceBadge } from "../../badges/PlaceBadge";
import { JobTypeBadge } from "../../badges/JobTypeBadge";
import { TeamBadge } from "../../badges/TeamBadge";
import { MemberBadge } from "../../badges/MemberBadge";
import { JobDateAndTimeSection } from "./JobDateAndTimeSection";
import { JobStatusSection } from "./JobStatusSection";
import { Centered } from "../../containers/Centered";
import { CommentsSection } from "./CommentsSection";
import { LabelsSection } from "./LabelsSection";
import { AssociatedJobSection } from "./AssociatedJobSection";
import { isTestEnvironment } from "../../../utils/app";
import { preventMinus } from "../../../utils/formUtils";

export function JobInfoTab() {
    const jobId = useRecoilValue(editingJobIdState);
    const [fields, setFields] = useRecoilState(editJobState(jobId || ''));
    const { isNewJob } = useJobForms()
    const originalAssignee = useRecoilValue(memberState(fields?.receiverUid || EMPTY_UID));
    const [selectedAssignee, setSelectedAssignee] = useState<Member | undefined>(originalAssignee);
    const { strings } = useStrings();
    const { theme } = useTheme();
    const { company } = useAccount();
    const validJobFields = useRecoilValue(editJobValidState(jobId || ''));
    const { setJobCustomFieldsDefault } = useCompanyDefaults();
    const { jobTypesIsLoading: jobTypeIsLoading, createJobType } = useJobTypes();
    const [geocodeLoading, setGeocodeLoading] = useState(false);
    const { initEditJob } = useEditJobLocation();
    const [compact, setCompact] = useRecoilState(jobCompactVisualizationState);

    if (!fields) {
        return null;
    }

    const onChangeInput = (key: string, value: string[] | string | number) => {
        setFields((prev) => ({ ...prev, [key]: value } as Job));
    };

    const onAudioText = (text: string) => {
        if (text) {
            const key = "textDispatcher";
            const value = fields["textDispatcher"] ? `${fields["textDispatcher"]}\n${text}` : text;
            setFields((prev) => ({ ...prev, [key]: value } as Job));
        }
    }

    const onTeamChange = ({ option }: OnChangeParams) => {
        setFields((prev) => ({ ...prev, teamId: option?.id as number || 0 } as Job));
    };

    const onTypeChange = async ({ option }: OnChangeParams) => {
        if (option?.isCreatable && company) {
            const newJobTypeId = await createJobType(option.id as string);
            if (newJobTypeId) {
                setFields((prev) => ({ ...prev, type: newJobTypeId } as Job));
            }
        } else {
            setFields((prev) => ({ ...prev, type: option?.id as number } as Job));
        }
    };

    const onAssigneeChange = (member?: Member) => {
        setSelectedAssignee(member);
        setFields((prev) => ({ ...prev, receiverUid: member?.uid || '' } as Job));
    };

    const updateJobCustomFields = (customFields: CustomField[]) => {
        setFields((prev) => ({ ...prev, customFields } as Job));
    };

    const geolocate = async () => {
        setGeocodeLoading(true);
        const geolocation = await geocode({ lat: fields.destinationLat, lng: fields.destinationLng });
        if (geolocation.status && geolocation.result.address) {
            setFields((prev) => ({ ...prev, destinationText: geolocation.result.address?.formatted || '' } as Job));
        }
        setGeocodeLoading(false);
    }

    const radioOverrides = {
        Label: {
            style: {
                display: 'flex',
                columnGap: theme.sizing.scale200,
                alignItems: 'center',
            }
        }
    };

    const title = fields.destinationName ?? "";
    const titleRows = (title.length > 100
        ? Math.ceil(fields.destinationName?.length / 70)
        : 2) + title.split('\n').length;

    return (
        <SectionedWrapper>
            <Centered>
                <Segmented
                    value={compact ? "compact" : "expanded"}
                    size={"middle"}
                    options={[
                        {
                            value: "expanded",
                            label: strings.Job.Expanded,
                        },
                        {
                            value: "compact",
                            label: strings.Job.Compact,
                        },
                    ]}
                    onChange={(value) => setCompact(value == "compact")}
                    style={{ marginBottom: "16px" }}
                />
            </Centered>
            <Section>
                {!isNewJob && !fields.isTemplate && !fields.isPartOfSequence && (
                    <FlexGridLayout>
                        <SpacerExpand/>
                        <FormInput
                            label={undefined}
                            value={fields.id ? fields.id : ''}
                            field="readonly"
                            inputSize={'mini'}
                            endEnhancer={<ClipboardButton content={fields.id}/>}
                            startEnhancer={'ID'}
                        />
                    </FlexGridLayout>
                )}
                {fields.isTemplate && <ParagraphSmall color={theme.colors.contentTertiary}>
                    {strings.JobTemplates.JobTemplateDesc}
                </ParagraphSmall>}
                <Section title={strings.General.Title}
                         expandable={compact}
                         collapsedNode={fields.destinationName}
                         expanded={isNewJob}
                         noMarginTop
                >
                    <Textarea
                        value={title}
                        placeholder={strings.General.EnterTitle}
                        rows={titleRows}
                        size="default"
                        name='destinationName'
                        onChange={(event) =>
                            onChangeInput(event.target.name, event.target.value)
                        }
                    />
                </Section>
            </Section>
            <JobStatusSection/>
            <Section title={strings.General.Location}
                     expandable={compact}
                     collapsedNode={fields.destinationText}
            >
                <VFlex>
                    <Spacer height={"32px"}/>
                    <HFlex>
                        <SpacerExpand/>
                        <VFlex style={{ maxWidth: "380px" }} spacing>
                            {fields.placeUid && (
                                <ParagraphSmall marginTop={0} marginBottom={0}>
                                    {strings.General.Place}: <PlaceBadge uid={fields.placeUid}/>
                                </ParagraphSmall>
                            )}
                            <StaticMap
                                width={380}
                                height={180}
                                jobId={jobId}
                                location={{ lat: fields.destinationLat, lng: fields.destinationLng }}
                                onClick={initEditJob}/>
                            <Input
                                value={fields.destinationText ? fields.destinationText : ''}
                                size="mini"
                                name="destinationText"
                                placeholder={strings.General.EnterAddressOrGeocode}
                                rows={2}
                                onChange={(event) =>
                                    onChangeInput(event.target.name, event.target.value)
                                }
                                endEnhancer={() => fields.destinationLat && fields.destinationLng ?
                                    <Button size={"mini"}
                                            kind={"tertiary"}
                                            onClick={() => geolocate()}
                                            isLoading={geocodeLoading}
                                    >
                                        {strings.General.Geocode}
                                    </Button> : undefined}
                            />
                        </VFlex>
                        <SpacerExpand/>
                    </HFlex>

                    <Spacer height={"32px"}/>
                </VFlex>
            </Section>
            <Section title={strings.General.AssignedTo}
                     expandable={compact}
                     collapsedNode={(
                         <HFlex spacing alignCenter>
                             {selectedAssignee && <MemberBadge uid={selectedAssignee.uid}/>}
                             <TeamBadge id={fields.teamId}/>
                         </HFlex>
                     )}
            >
                {!fields.isPartOfSequence &&
                    <FormControl label={strings.General.AssignedTo}>
                        <MemberSelect
                            placeholder=""
                            onMemberSelect={onAssigneeChange}
                            selectedMember={selectedAssignee}
                        />
                    </FormControl>
                }
                <FormControl label={(
                    <HFlex spacing alignCenter>
                        {strings.General.Team}
                        <LabelXSmall color={theme.colors.contentTertiary}>
                            ({strings.General.Optional})
                        </LabelXSmall>
                    </HFlex>
                )}>
                    <JobTeamSelect onTeamChange={onTeamChange} teamId={fields.teamId}/>
                </FormControl>
            </Section>
            <JobDateAndTimeSection/>
            <Section title={strings.Job.Type}
                     expandable={compact}
                     collapsedNode={<JobTypeBadge type={fields.type}/>}
            >
                <FormControl>
                    <JobTypeSelect
                        type={fields.type}
                        onTypeChange={onTypeChange}
                        isLoading={jobTypeIsLoading}
                        canDelete
                    />
                </FormControl>
            </Section>
            {isTestEnvironment() && isPickupOrDropoff(fields) && <AssociatedJobSection/>}
            <LabelsSection/>
            <Section title={strings.Job.Notes}
                     expandable={compact}
                     collapsedNode={fields.textDispatcher}
            >
                <Textarea
                    value={fields.textDispatcher ? fields.textDispatcher : ''}
                    placeholder={strings.Job.Notes}
                    rows={5}
                    size="compact"
                    name='textDispatcher'
                    onChange={(event) =>
                        onChangeInput(event.target.name, event.target.value)
                    }
                />
                <Spacer/>
                <AudioRecorder onText={onAudioText}/>
            </Section>
            <CommentsSection/>
            <Section title={strings.Job.OrderId}
                     expandable={compact}
                     collapsedNode={fields.orderId}
            >
                <FormInput
                    label={""}
                    value={fields.orderId ? fields.orderId : ''}
                    caption={strings.Job.OrderIdCaption}
                    field="orderId"
                    type="number"
                    min={0}
                    onChange={onChangeInput}
                />
            </Section>
            <Section title={strings.Job.Priority}
                     expandable={compact}
                     collapsedNode={isHighPriority(fields)
                         ? strings.General.HighPriority
                         : isLowPriority(fields) ? strings.General.LowPriority : undefined}>
                <FormControl
                    label={""}
                    caption={strings.General.PriorityCaption}
                >
                    <RadioGroup
                        value={fields.priority ? `${fields.priority}` : `${PRIO_NORMAL}`}
                        onChange={(event) =>
                            onChangeInput(event.target.name, event.target.value)
                        }
                        name="priority"
                        overrides={{
                            RadioGroupRoot: {
                                style: {
                                    columnGap: theme.sizing.scale700,
                                    [theme.mediaQuery.medium]: {
                                        flexDirection: 'row'
                                    }
                                }
                            },
                        }}
                    >
                        <Radio value={`${PRIO_LOW}`} overrides={radioOverrides}>
                            {strings.Job.Low}
                            <LowPriorityIcon size={14}/>
                        </Radio>
                        <Radio value={`${PRIO_NORMAL}`} overrides={radioOverrides}>
                            {strings.Job.Normal}
                            <NormalPriorityIcon size={14}/>
                        </Radio>
                        <Radio value={`${PRIO_HIGH}`} overrides={radioOverrides}>
                            {strings.Job.High}
                            <HighPriorityIcon size={14}/>
                        </Radio>
                    </RadioGroup>
                </FormControl>
            </Section>
            <Section title={strings.Job.Contact}
                     expandable={compact}
                     collapsedNode={fields.contactName || fields.contactEmail || fields.contactPhone}
            >
                <FormInput
                    label={strings.Job.ContactName}
                    value={fields.contactName ? fields.contactName : ''}
                    field="contactName"
                    onChange={onChangeInput}
                />
                <FlexGridLayout>
                    <FormInput
                        label={strings.Job.Email}
                        value={fields.contactEmail ? fields.contactEmail : ''}
                        field="contactEmail"
                        onChange={onChangeInput}
                        type='email'
                        startEnhancer={
                            <EmailIcon size={12} color={theme.colors.contentPrimary}/>
                        }
                        error={fields.contactEmail && !validJobFields['contactEmail'] ? strings.General.PleaseEnterAValidEmailAddress : undefined}
                    />
                    <FormInput
                        label={strings.Job.ContactPhone}
                        value={fields.contactPhone ? fields.contactPhone : ''}
                        field="contactPhone"
                        onChange={onChangeInput}
                        startEnhancer={
                            <PhoneIcon size={14} color={theme.colors.contentPrimary}/>
                        }
                    />
                </FlexGridLayout>
            </Section>
            <Section title={strings.Job.PickupAndDelivery}
                     expandable={compact}
                     collapsedNode={fields.itemsToPickup || fields.itemsToDropoff}
            >
                <FlexGridLayout>
                    <FormInput
                        label={strings.Job.Pickup}
                        caption={
                            <HFlex spacing alignCenter>
                                <ParagraphXSmall marginTop={0}
                                                 marginBottom={0}>{strings.Job.NumberOfItemsToPickup}</ParagraphXSmall>
                                <Tooltip title={strings.Routing.VehicleCapacityDesc}>
                                    <Tag><InfoIcon size={11} color={theme.colors.contentPrimary}/></Tag>
                                </Tooltip>
                            </HFlex>
                        }
                        value={fields.itemsToPickup ? fields.itemsToPickup : ''}
                        field="itemsToPickup"
                        type="number"
                        min={0}
                        onKeyDown={preventMinus}
                        onChange={onChangeInput}
                    />
                    <FormInput
                        label={strings.Job.Dropoff}
                        caption={
                            <HFlex spacing alignCenter>
                                <ParagraphXSmall marginTop={0}
                                                 marginBottom={0}>{strings.Job.NumberOfItemsToDropoff}</ParagraphXSmall>
                                <Tooltip title={strings.Routing.VehicleCapacityDesc}>
                                    <Tag><InfoIcon size={11} color={theme.colors.contentPrimary}/></Tag>
                                </Tooltip>
                            </HFlex>
                        }
                        value={fields.itemsToDropoff ? fields.itemsToDropoff : ''}
                        field="itemsToDropoff"
                        type="number"
                        min={0}
                        onKeyDown={preventMinus}
                        onChange={onChangeInput}
                    />
                </FlexGridLayout>
            </Section>

            <Section title={strings.Job.Requirements} expandable={compact}>
                <FormControl label={" "}>
                    <CreatableTagsInput
                        label={strings.Job.SkillsRequired}
                        caption={strings.Job.SkillsRequiredDesc}
                        tags={JSON.parse(fields.requiredSkills || "[]")}
                        onTagsChange={(tags) => {
                            onChangeInput("requiredSkills", JSON.stringify(tags));
                        }}
                    />
                </FormControl>
            </Section>

            <Section title={strings.CustomFields.CustomFields}
                     expandable={compact}
                     collapsedNode={fields.customFields?.length
                         && <NotificationCircle content={fields.customFields.length}/>}
            >
                <CustomFields
                    fields={fields.customFields || []}
                    onSaveAsDefault={() => setJobCustomFieldsDefault(fields.customFields!)}
                    onUpdateFields={updateJobCustomFields}
                    target={"jobs"}
                />
            </Section>
            <JobConfirmationEmailSection/>
            <Spacer height="48px"/>
        </SectionedWrapper>
    );
}
