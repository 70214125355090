import { Checkbox } from "antd";
import { Button } from "baseui/button";
import { Delete } from "baseui/icon";
import { Input } from "baseui/input";
import { LabelSmall, LabelXSmall } from "baseui/typography";
import { useState } from "react";
import { COLOR_RED } from "../../constants/colors";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { FormItem, FormItemType } from "../../types/form";
import { HoverButton } from "../buttons/HoverButton";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { EditIcon } from "../ui/svg";
import { FormItemSelect } from "./FormItemSelect";

type FormItemCardHeaderProps = {
    canEdit: boolean;
    isHover: boolean;
    item: FormItem;
    jobInputForm?: boolean;
    onSetName: (name: string) => void;
    onSetType: (type: FormItemType) => void;
    onRemove?: () => void;
    onSetRequired?: (required: boolean) => void;
}

export function FormItemCardHeader(props: FormItemCardHeaderProps) {
    const {
        canEdit,
        isHover,
        item,
        jobInputForm,
        onSetName,
        onSetType,
        onRemove,
        onSetRequired
    } = props;
    const [editing, setEditing] = useState<boolean>();
    const [newName, setNewName] = useState(item.name);
    const { theme } = useTheme();
    const { strings } = useStrings()
    
    return (
        <HFlex spacing alignCenter style={{ minHeight: "28px"}}>
            {editing
                ? <HFlex spacing>
                    <Input autoFocus value={newName} size={"mini"}
                            onChange={value => setNewName(value.target.value)}/>
                    <FormItemSelect value={item.type} jobInputForm={jobInputForm}
                                    onChange={(type) => onSetType(type)}/>
                </HFlex>
                : <LabelSmall>{item.name ? item.name : "..."}</LabelSmall>}
            {item.required && !editing && <LabelSmall color={COLOR_RED}>*</LabelSmall>}
            {isHover && !editing && canEdit &&
                <>
                    <HoverButton tooltip={item.type} onClick={() => {
                        setEditing(true);
                    }}>
                        <EditIcon color={theme.colors.contentPrimary} size={12}/>
                    </HoverButton>
                    <SpacerExpand/>
                    {onSetRequired && (
                        <HFlex alignCenter style={{ width: undefined }}>
                            <Checkbox checked={item.required}
                                    onChange={(ev) => onSetRequired(ev.target.checked)}/>
                            <LabelXSmall paddingLeft={"4px"}>
                                {strings.Forms.FormsRequired}
                            </LabelXSmall>
                        </HFlex>
                    )}
                    {onRemove && (
                        <>
                            <SpacerExpand/>
                            <HoverButton tooltip={strings.General.Delete} onClick={() => onRemove()}>
                                <Delete size={12}/>
                            </HoverButton>
                        </>
                    )}
                </>
            }
            {editing &&
                <Button size={"mini"} shape={"default"} onClick={() => {
                    onSetName(newName);
                    setEditing(false);
                }}>
                    {strings.General.Done}
                </Button>
            }
        </HFlex>
    );
}