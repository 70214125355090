import { useMap } from 'react-map-gl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DEFAULT_DURATION, DEFAULT_PITCH_3D } from '../constants/camera';
import { is3DEnabledState, pulseMarkerState } from '../states/mapState';
import { Latitude, LatLng, Longitude, LonLat } from '../types/core';
import { Job } from "../types/job";
import { tempLocationState } from "../states/tempLocationState";
import distance from "@turf/distance"

export function useCamera() {
    const { map } = useMap();
    const enable3D = useRecoilValue(is3DEnabledState);
    const setTempLocation = useSetRecoilState(tempLocationState);
    const setPulseMarker = useSetRecoilState(pulseMarkerState);

    const moreOptions = enable3D ? { pitch: DEFAULT_PITCH_3D } : { pitch: 0 };

    const flyTo = (location: LatLng) => {
        queueMicrotask(() => {
            map?.flyTo({
                center: [location.lng, location.lat],
                duration: DEFAULT_DURATION,
                ...moreOptions,
            });
        });
    };

    const resetPitch = () => {
        queueMicrotask(() => map?.easeTo({ pitch: enable3D ? DEFAULT_PITCH_3D : 0 }));
    };

    const zoomIn = () => {
        queueMicrotask(() => map?.zoomIn());
    };

    const zoomOut = () => {
        queueMicrotask(() => map?.zoomOut());
    };

    const resetNorth = () => {
        queueMicrotask(() => {
            if (map?.getBearing() !== 0) {
                map?.resetNorth();
            } else {
                map?.easeTo({ pitch: map?.getPitch() === 0 ? DEFAULT_PITCH_3D : 0 });
            }
        });
    };

    const zoomTo = (location: LatLng | undefined, zoom = 15, duration = DEFAULT_DURATION) => {
        if (location && location.lat && location.lng) {
            if (map && duration === DEFAULT_DURATION) {
                const p1 = [map.getCenter().lng, map.getCenter().lat];
                const p2 = [location.lng, location.lat];
                const dist = distance(p1, p2, { units: "meters" });
                if (dist < 2_000) {
                    duration = 300;
                }
            }
            setTimeout(() => setPulseMarker(location), duration);
            queueMicrotask(() => {
                map?.flyTo({
                    center: [location.lng, location.lat],
                    duration: duration,
                    zoom: zoom,
                    ...moreOptions,
                });
            });
        }
    };

    const zoomWithTempMarker = (location: LatLng, tempMarker: string | undefined = undefined) => {
        zoomTo(location);
        setTempMarker(location, tempMarker);
    }

    const setTempMarker = (location: LatLng, tempMarker: string | undefined = undefined) => {
        setTempLocation({ ...location, name: tempMarker || "" });
    }

    const fitToBounds = (minLat: Latitude, minLng: Longitude, maxLat: Latitude, maxLng: Longitude, duration = DEFAULT_DURATION) => {
        queueMicrotask(() => {
            map?.fitBounds(
                [
                    [minLng, minLat],
                    [maxLng, maxLat]
                ],
                { padding: 40, duration: duration }
            );
        });
    };

    const fitToLngLats = (lngLats?: LonLat[], duration = DEFAULT_DURATION) => {
        if (!lngLats || lngLats.length === 0) {
            return;
        }
        if (lngLats.length === 1) {
            const latLng = {
                lat: lngLats[0][1],
                lng: lngLats[0][0],
            } as LatLng;
            zoomTo(latLng);
            return;
        }
        let minLng = 1000;
        let minLat = 1000;
        let maxLat = -1000;
        let maxLng = -1000;
        for (let ll of lngLats) {
            minLat = Math.min(minLat, ll[1]);
            minLng = Math.min(minLng, ll[0]);
            maxLat = Math.max(maxLat, ll[1]);
            maxLng = Math.max(maxLng, ll[0]);
        }
        fitToBounds(minLat, minLng, maxLat, maxLng, duration);
    };

    const fitToJobs = (jobs: Job[]) => {
        if (jobs.length === 0) {
            return;
        }
        if (jobs.length === 1) {
            zoomTo({ lat: jobs[0].destinationLat, lng: jobs[0].destinationLng });
            return;
        }
        let minLng = 1000;
        let minLat = 1000;
        let maxLat = -1000;
        let maxLng = -1000;
        for (let job of jobs) {
            minLat = Math.min(minLat, job.destinationLat);
            minLng = Math.min(minLng, job.destinationLng);
            maxLat = Math.max(maxLat, job.destinationLat);
            maxLng = Math.max(maxLng, job.destinationLng);
        }
        fitToBounds(minLat, minLng, maxLat, maxLng);
    };

    return {
        flyTo,
        zoomTo,
        zoomWithTempMarker,
        fitToJobs,
        fitToLngLats,
        zoomIn,
        zoomOut,
        resetNorth,
        resetPitch,
        setTempMarker
    };
}
