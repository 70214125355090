import React, { forwardRef, ReactNode } from 'react';
import { useTheme } from "../../hooks/useTheme";
import { HoverButton } from "../buttons/HoverButton";
import { ArrowRightIcon, RemoveIcon } from "../ui/svg";
import { useStrings } from "../../hooks/useStrings";
import { ClickableListItem } from "../ui/ClickableListItem";

type Props = {
    isSelected?: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    onAdd?: () => void;
    onRemove?: () => void;
    children: ReactNode;
    isDraggingOver?: boolean;
};

const DispatchTreeItem = forwardRef<HTMLDivElement, Props>(({
                                         isSelected = false,
                                         onClick,
                                         onAdd,
                                         onRemove,
                                         children,
                                         isDraggingOver = false
                                     }: Props, ref) => {
    const { strings } = useStrings();
    const { theme } = useTheme();

    return (
        <ClickableListItem
            isSelected={isSelected}
            shrink
            ref={ref}
            onClick={onClick}
            onHover={() => <HoverButton
                size={"mini"}
                tooltip={isSelected ? strings.General.RemoveFromBoard : strings.General.AddToBoard}
                onClick={() => {
                    if (isSelected) {
                        onRemove && onRemove();
                    } else {
                        onAdd && onAdd();
                    }
                }}
            >
                {!isSelected ? (
                    <ArrowRightIcon size={12} color={theme.colors.contentTertiary}/>
                ) : (
                    <RemoveIcon size={12} color={theme.colors.contentTertiary}/>
                )}
            </HoverButton>}
        >
            {children}
        </ClickableListItem>
    );
});

export default React.memo(DispatchTreeItem)
