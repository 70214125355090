import { ParagraphSmall } from 'baseui/typography';
import { useTheme } from '../../hooks/useTheme';
import { isValidImage, isValidPDF, isValidYouTube } from '../../utils/app';
import { Clickable } from '../containers/Clickable';
import { HFlex } from '../containers/HFlex';
import { VFlex } from '../containers/VFlex';
import { EnterIcon, RemoveIcon } from '../ui/svg';
import { Image } from "antd";
import { EllipsedText } from '../ui/EllipsedText';
import { StyledLink } from "baseui/link";
import { YouTubeEmbed } from "./YouTubeEmbed";

type Props = {
    attachments: { key: string; val: string }[];
    onRemove?: (key: string) => void;
};

export function AttachmentList({ attachments, onRemove }: Props) {
    const { theme, css } = useTheme();

    return (
        <VFlex style={{ rowGap: theme.sizing.scale600, marginBottom: theme.sizing.scale600 }}>
            {attachments.map((attachment, index) => {
                const validImage = isValidImage(attachment.val);
                const validPdf = isValidPDF(attachment.val);
                const validYouTube = isValidYouTube(attachment.val);
                return (
                    <HFlex
                        key={index}
                        spacing
                        style={{
                            alignItems: 'flex-start',
                        }}
                    >
                        <div
                            className={css({
                                display: 'flex',
                                columnGap: theme.sizing.scale600,
                                rowGap: theme.sizing.scale300,
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                [theme.mediaQuery.medium]: {
                                    flexDirection: 'row',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                },
                            })}
                        >
                            {!validImage && !validPdf && !validYouTube && (
                                <ParagraphSmall margin={0}>
                                    <EllipsedText breakAll>{attachment.val}</EllipsedText>
                                </ParagraphSmall>
                            )}
                            {validImage && (
                                <Image
                                    src={attachment.val}
                                    loading="lazy"
                                    alt={attachment.val}
                                    style={{
                                        width: '170px',
                                        borderRadius: theme.sizing.scale700,
                                    }}
                                />
                            )}
                            {validYouTube && <YouTubeEmbed url={attachment.val}/>}
                            {validPdf && (
                                <HFlex spacing style={{ alignItems: "top"}}>
                                    <StyledLink href={attachment.val} target={"_blank"}>
                                        <EnterIcon size={12} color={theme.colors.contentSecondary}/>
                                    </StyledLink>
                                    <iframe src={attachment.val} width="170" height="300">
                                        <p>Your browser does not support PDFs. Please download the PDF to view it: <a
                                            href={attachment.val}>Download PDF</a>.</p>
                                    </iframe>
                                </HFlex>
                            )}
                        </div>
                        {onRemove && (
                            <Clickable onClick={() => onRemove(attachment.key)}>
                                <RemoveIcon size={14} color={theme.colors.contentPrimary}/>
                            </Clickable>
                        )}
                    </HFlex>
                );
            })}
        </VFlex>
);
}
