import { anyDigit, extraNumberValidator, extraTextValidator } from '../../constants/validators';
import { getInputFormConfig, Job } from '../../types/job';
import { useRecoilValue } from "recoil";
import { standardFormContentState } from "../../states/formsState";
import { FormItem } from "../../types/form";

type ExtraField = {
    keyFieldName: string;
    valFieldName: string;
    name: string;
    value: string | number;
}

type FormItemWithExtraField = FormItem & ExtraField;

export function useJobExtraFields(job: Job | undefined) {
    const standardForm = useRecoilValue(standardFormContentState);
    const sortNumbers = (a: number, b: number) => a - b;

    const extractIndices = (extraValidator: RegExp): number[] => {
        if (!job) {
            return [];
        }
        const indexList: number[] = [];
        Object.keys(job)
            .filter((key: string) => extraValidator.test(key))
            .forEach((key) => {
                const index = anyDigit.exec(key);
                if (!index) {
                    return;
                }
                if (!indexList.includes(+index[0])) {
                    indexList.push(+index[0]);
                }
            });
        return indexList.sort(sortNumbers);
    };

    const removeStarPrefix = (str: string): string => {
        if (str?.startsWith('* ')) {
            return str.slice(2);
        }
        return str;
    }

    const getExtraTextFields = (): ExtraField[] => {
        if (!job) {
            return [];
        }
        return extractIndices(extraTextValidator)
            .map((index) => {
                const keyFieldName = `extra_text_${index}_key`;
                const valFieldName = `extra_text_${index}_val`;
                const name = removeStarPrefix(job[`extra_text_${index}_key`]);
                const value = job[`extra_text_${index}_val`];
                return { keyFieldName, valFieldName, name, value} as ExtraField
            });
    };

    const getExtraNumberFields = (): ExtraField[] => {
        if (!job) {
            return [];
        }
        return extractIndices(extraNumberValidator)
            .map((index) => {
                const keyFieldName = `extra_number_${index}_key`;
                const valFieldName = `extra_number_${index}_val`;
                const name = removeStarPrefix(job[`extra_number_${index}_key`]);
                const value = job[`extra_number_${index}_val`];
                return { keyFieldName, valFieldName, name, value} as ExtraField
            });
    };

    const allFields = [
        ...getExtraTextFields(),
        ...getExtraNumberFields(),
    ];

    const form = (job && getInputFormConfig(job)) || standardForm;

    const items = form.content.map(item => {
        const field = allFields.find(f => f.name === item.name);
        return {
            ...item,
            ...field,
        } as FormItemWithExtraField;
    });

    return { items };
}
