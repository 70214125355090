import { Company } from './../types/company';
import { Job } from "../types/job";
import { Md5 } from 'ts-md5';
import { getRecoil } from '../providers/RecoilAccessProvider';
import { passwordState, tokenState, uidState, usernameState } from '../states/accountState';
import { Member } from "../types/member";
import { Message } from "../types/message";
import { Zone } from "../types/zone";
import { ReadableSequence, SavableSequence } from "../types/sequence";
import { FormContent, FormTemplate } from "../types/form";
import { dayOfToday } from "../utils/time";
import { SortConfig } from '../types/jobFilters';
import { AlertConfig, AlertInstance } from "../types/alerts";
import { Stop } from "../types/stops";
import { TimesheetEntry } from "../types/timesheet";
import { RecurringReportConfig, UploadedReport } from "../types/reports";
import { Day, Latitude, Longitude, Timestamp, Uid, UidDay } from "../types/core";
import { MemberStat, MemberStatDay } from "../types/stats";
import { Activity } from "../types/notification";
import { Cluster } from "../types/cluster";
import { isTestEnvironment } from "../utils/app";
import { Check } from "../types/checks";
import { ApprovalStatus, TrackEntry } from "../types/track";
import { JSONbig } from '../constants/app';
import { Trip } from "../types/trip";

type Mode = "PROD" | "QA" | "LOCAL";

export const MODE: Mode = isTestEnvironment() ? "QA" : "PROD";
export const PROD_DOMAIN = 'live.hellotracks.com';
export const QA_DOMAIN = 'qa.hellotracks.com';
export const LOCAL_DOMAIN = 'localhost:8766';
export const HTTPS_DOMAIN = "https://hellotracks.com";

// @ts-ignore
const SCHEMA = MODE === "LOCAL" ? "http://" : "https://";
// @ts-ignore
export const API_DOMAIN = MODE === "LOCAL" ? LOCAL_DOMAIN : MODE === "QA" ? QA_DOMAIN : PROD_DOMAIN;
export const API_ENDPOINT = SCHEMA + API_DOMAIN + "/api";

export const STATUS_OK = 0;
export const STATUS_ERROR_USERUNKNOWN = -2;
export const STATUS_ERROR_PASSWORDMISMATCH = -3;
export const STATUS_ERROR_USERALREADYEXISTS = -4;
export const STATUS_ERROR_NOPERMISSION = -5;
export const STATUS_ERROR_NOTREADY = -7;
export const STATUS_ERROR_THROTTLING = -9;
export const STATUS_ERROR_FORCELOGOUT = -12;
export const STATUS_ERROR_NETWORK = -999;

export enum Endpoint {
    weblogin = 'weblogin',
    getaccounts = 'getaccounts',
    getjobs = 'getjobs',
    createaccount = 'createaccount',
    editaccount = 'editaccount',
    deleteaccount = 'deleteaccount',
    editjobs = 'editjobs',
    createjobs = 'createjobs',
    clonejobs = 'clonejobs',
    deletejobs = 'deletejobs',
    uploadprofileimage = 'uploadprofileimage',
    uploadjobattachment = 'uploadfile/jobattachment',
    uploadapplog = 'uploadfile/applog',
    uploadaudiotranscription = 'uploadfile/audiotranscription',
    uploadjobimport = 'uploadfile/jobimport',
    uploadzoneimport = 'uploadfile/zoneimport',
    uploadplaceimport = 'uploadfile/placeimport',
    uploadmemberimport = 'uploadfile/memberimport',
    optimizeroute = 'optimizeroute',
    calculateroute = 'calculateroute',
    optimizemultiroute = 'optimizemultiroute',
    reorderroute = 'reorderroute',
    placesautocomplete = 'placesautocomplete',
    assignjob = 'assignjob',
    geocode = 'geocode',
    savezone = 'savezone',
    deletezone = 'deletezone',
    loadsequences = 'loadsequences',
    savesequence = 'savesequence',
    editsequencejob = 'editsequencejob',
    getjobtemplates = 'getjobtemplates',
    geolocateplaces = "geolocateplaces",
    assignsequence = 'assignsequence',
    loadformsubmissions = 'loadformsubmissions',
    loadformtemplates = 'loadformtemplates',
    saveformtemplate = 'saveformtemplate',
    archivejobs = 'archivejobs',
    restorejobs = 'restorejobs',
    savejobastemplate = 'savejobastemplate',
    notifyroutechange = 'notifyroutechange',
    conversations = 'conversations',
    conversation = 'conversation',
    sendmsg = 'sendmsg',
    delmsg = 'delmsg',
    getbilling = 'getbilling',
    setcompanyeactive = 'setcompanyactive',
    getalertinstances = 'getalertinstances',
    getalertconfigs = 'getalertconfigs',
    savealertconfig = 'savealertconfig',
    getstops = 'getstops',
    gettimesheet = 'gettimesheet',
    createreport = 'createreport',
    requestpassword = 'requestpassword',
    startverifyemail = 'startverifyemail',
    startverifyphone = 'startverifyphonel',
    editinvitecode = 'editinvitecode',
    createinvitecode = 'createinvitecode',
    sendgooglecalendarinvite = 'sendgooglecalendarinvite',
    resetfield = 'resetfield',
    disconnectslack = "disconnectslack",
    editplace = 'editplace',
    createplace = 'createplace',
    loaduploadedreports = 'loaduploadedreports',
    loadrecurringreportconfigs = 'loadrecurringreportconfigs',
    saverecurringreportconfig = 'saverecurringreportconfig',
    createcompany = 'createcompany',
    setprofileimageinitials = 'setprofileimageinitials',
    startroutesimulation = 'startroutesimulation',
    stoproutesimulation = 'stoproutesimulation',
    savetimesheetentry = 'savetimesheetentry',
    deletetracks = 'deletetracks',
    publishtracks = 'publishtracks',
    edittrack = "edittrack",
    downloadtracks = 'downloadtracks',
    usernameavailable = 'usernameavailable',
    websignup = 'websignup',
    personalize = 'personalize',
    assistantquery = 'assistant/query',
    salesaiquery = "salesai/query",
    helpaiquery = "helpai/query",
    passwordrecovery = 'passwordrecovery',
    activation = 'activation',
    statsmembers = 'stats/members',
    prepareaddpaymentmethod = 'prepareaddpaymentmethod',
    setdefaultpaymentmethod = "setdefaultpaymentmethod",
    getinvoices = "getinvoices",
    getplanprices = "getplanprices",
    changeplan = "changeplan",
    getactivities = "getactivities",
    resendinvite = "resendinvite",
    distributejobs = "distributejobs",
    submitform = "submitform",
    getchecks = "getchecks",
    gettrips = "gettrips",
}

export function getAuthObj() {
    const uid = getRecoil(uidState);
    const usr = getRecoil(usernameState);
    const pass = getRecoil(passwordState);
    const token = getRecoil(tokenState);
    if (token) {
        return {
            usr: usr,
            uid: uid,
            bearer: token,
        }
    }
    return getAuthObjWithCredentials(usr, pass);
}

export function getAuthObjWithCredentials(usr: string, pass: string) {
    const nonce = Date.now();
    const hash = Md5.hashStr(nonce + pass);
    return { usr, pwd: hash, tok: nonce };
}

export function prepareRequest(dataObj = {}) {
    return { auth: getAuthObj(), data: dataObj, target: "base" };
}

export const fetchFromServer = async (endpoint: Endpoint, dataObj: object) => {
    const url = API_ENDPOINT + '/' + endpoint;
    try {
        const result = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(prepareRequest(dataObj)),
        });
        if (result.ok) {
            const json = JSONbig.parse(await result.text());
            if (json.status === STATUS_OK) {
                return { status: true, data: json };
            } else {
                return { status: false, data: {}, error: json.status };
            }
        } else {
            return { status: false, data: {}, error: result.status };
        }
    } catch (exc) {
        console.error("fetchFromServer", endpoint, dataObj, exc);
        return { status: false, data: {}, error: STATUS_ERROR_NETWORK };
    }
}

export const getAccounts = async (uids?: string[]) => {
    const response = await fetchFromServer(Endpoint.getaccounts, { uids });
    const result = {
        status: response.status,
        accounts: response.data.accounts as Member[],
    };
    return result;
}

export interface AccountResponse {
    status: boolean;
    account?: Member | Company;
    errorCode?: number;
}

export const createAccount = async (data: {}): Promise<AccountResponse> => {
    const response = await fetchFromServer(Endpoint.createaccount, data);
    return {
        status: response.status,
        account: response.data ? response.data.account : undefined,
        errorCode: response.error ? response.error : undefined,
    }
}

export const editAccount = async (data: {}): Promise<AccountResponse> => {
    const response = await fetchFromServer(Endpoint.editaccount, data);
    return {
        status: response.status,
        account: response.data.account
    }
}

export const deleteAccount = async (uid: Uid): Promise<{ status: boolean }> => {
    const response = await fetchFromServer(Endpoint.deleteaccount, { uid: uid });
    return {
        status: response.status,
    }
}

export const getJobById = async (id: string ) => {
    const response = await fetchFromServer(Endpoint.getjobs, { id });
    const result = {
        status: response.status,
        jobs: response.data.jobs as Job[],
    };
    return result;
};

export interface GetJobsRequest {
    day: Day;
    worker: string;
}

export const getJobs = async ({ day, worker = "*" }: GetJobsRequest) => {
    const response = await fetchFromServer(Endpoint.getjobs, { day: day, worker: worker });
    const result = {
        status: response.status,
        jobs: response.data.jobs as Job[],
    };
    return result;
};

export const getAllJobs = async (config: SortConfig) => {
    const response = await fetchFromServer(Endpoint.getjobs,
        {
            worker: "*",
            sort: config,
        });
    const result = {
        status: response.status,
        jobs: response.data.jobs as Job[],
    };
    return result;
};

export const getAllUnassignedJobs = async () => {
    const response = await fetchFromServer(Endpoint.getjobs,
        {
            worker: "",
            day: 0,
            sort: {
                field: "",
                ascending: false,
                limit: 500,
                skip: 0,
                from: 0,
                until: Date.now(),
                status: 0,
            } as SortConfig
        });
    const result = {
        status: response.status,
        jobs: response.data.jobs as Job[],
    };
    return result;
};

export interface JobsResponse {
    status: boolean;
    jobs: Job[];
}

export const editJobs = async (data: {}): Promise<JobsResponse> => {
    const response = await fetchFromServer(Endpoint.editjobs, {
        jobs: data,
        notify: true,
        sendRouteChange: true,
    });
    return {
        status: response.status,
        jobs: response.data.jobs || []
    };
}

export const createJobs = async (data: {}): Promise<JobsResponse> => {
    const response = await fetchFromServer(Endpoint.createjobs, {
        jobs: data,
        sendRouteChange: true,
    });
    return {
        status: response.status,
        jobs: response.data.jobs || []
    }
}

export const archiveJobs = async (data: {}) => {
    const response = await fetchFromServer(Endpoint.archivejobs, {
        jobs: data
    });
    return {
        status: response.status,
        jobs: response.data.jobs,
    }
}

export const restoreJobs = async (data: {}) => {
    const response = await fetchFromServer(Endpoint.restorejobs, {
        jobs: data
    });
    return {
        status: response.status,
        jobs: response.data.jobs,
    }
}

export interface CloneJobsRequest {
    withAssignee: boolean;
    withDate: boolean;
    day?: number;
    suffix: string;
    jobs: string[]; // job ids
    templates: string[]; // template ids
}

export const cloneJobs = async (req: CloneJobsRequest): Promise<JobsResponse> => {
    const response = await fetchFromServer(Endpoint.clonejobs, req);
    return {
        status: response.status,
        jobs: response.data.jobs || []
    }
}

export const deleteJobs = async (data: {}) => {
    const response = await fetchFromServer(Endpoint.deletejobs, {
        jobs: data,
        notify: true,
    });
    return {
        status: response.status,
        jobs: response.data.jobs,
    };
}

export const saveZone = async (zone: Zone) => {
    const response = await fetchFromServer(Endpoint.savezone, {
        zone: zone,
    });
    return {
        status: response.status,
        zone: response.data.zone,
    };
}

export const deleteZone = async (zone: Zone) => {
    const response = await fetchFromServer(Endpoint.deletezone, { uid: zone.uid });
    return {
        status: response.status,
    };
}

export const loadSequences = async () => {
    const response = await fetchFromServer(Endpoint.loadsequences, { archived: false });
    return {
        status: response.status,
        sequences: response.data.sequences as ReadableSequence[],
    };
}

export const editSequenceJob = async (sequenceId: string, jobId: string, data: {}) => {
    const response = await fetchFromServer(Endpoint.editsequencejob, { ...data, sequenceId: sequenceId, jobId: jobId });
    return {
        status: response.status,
        sequence: response.data?.sequence || {} as ReadableSequence,
    };
}

export const saveSequence = async (sequence: SavableSequence) => {
    const response = await fetchFromServer(Endpoint.savesequence, { sequence: sequence });
    return {
        status: response.status,
        sequence: response.data.sequence as ReadableSequence,
    };
}

export const assignSequence = async (sequenceId: string, assigneeUid: Uid, day = dayOfToday()) => {
    const response = await fetchFromServer(Endpoint.assignsequence, {
        id: sequenceId,
        assignee: assigneeUid,
        day,
    });
    return {
        status: response.status,
        jobs: response.data.jobs as Job[],
    };
}

export const getJobTemplates = async () => {
    const response = await fetchFromServer(Endpoint.getjobtemplates, {});
    return {
        status: response.status,
        jobs: response.data.jobs as Job[],
    }
}

export interface LoadFormSubmissionsRequest {
    from: number;
    until: number;
    limit: number;
}

export interface LoadFormSubmissionIdsRequest {
    submissionIds?: string[];
}

export const loadFormSubmissions = async (req: LoadFormSubmissionsRequest|LoadFormSubmissionIdsRequest) => {
    const response = await fetchFromServer(Endpoint.loadformsubmissions, req);
    return {
        status: response.status,
        formsubmissions: response.data.formsubmissions as FormTemplate[],
    }
}

export const loadFormTemplates = async () => {
    const response = await fetchFromServer(Endpoint.loadformtemplates, {});
    return {
        status: response.status,
        formtemplates: response.data.formtemplates as FormTemplate[],
    }
}

export const saveFormTemplate = async (form: FormTemplate) => {
    const data = { formContent: JSON.stringify(form.content) } as any;
    if (form.id) {
        data.templateId = form.id;
    }
    const response = await fetchFromServer(Endpoint.saveformtemplate, data);
    return {
        status: response.status,
        id: response.data.id as string,
        formTemplates: response.data.formtemplates as FormTemplate[],
    }
}

export const deleteFormTemplate = async (form: FormTemplate) => {
    const response = await fetchFromServer(Endpoint.saveformtemplate, {
        templateId: form.id,
        formContent: JSON.stringify(form.content),
        status: 2
    });
    return {
        status: response.status,
        id: response.data.id as string,
        formTemplates: response.data.formtemplates as FormTemplate[],
    }
}

export const saveJobAsTemplate = async (req: { id: string; }) => {
    const response = await fetchFromServer(Endpoint.savejobastemplate, req);
    return {
        status: response.status,
        id: response.data?.id as string,
    }
}

export const getAllConversations = async () => {
    const response = await fetchFromServer(Endpoint.conversations, {});
    return {
        status: response.status,
        messages: response.data.messages as Message[],
    }
}

export interface ConversationRequest {
    account: string;
}

export const getConversation = async (req: ConversationRequest) => {
    const response = await fetchFromServer(Endpoint.conversation, req);
    return {
        status: response.status,
        messages: response.data.messages as Message[],
    }
}

export interface SendMessageRequest {
    msg: string;
    receivers?: string[],
    lat?: number;
    lng?: number;
}

export const sendMessage = async (req: SendMessageRequest) => {
    const response = await fetchFromServer(Endpoint.sendmsg, req);
    return { status: response.status }
}

export interface DeleteMessageRequest {
    ids: string[]
}

export const deleteMessage = async (req: DeleteMessageRequest) => {
    const response = await fetchFromServer(Endpoint.delmsg, req);
    return { status: response.status }
}

export const getAlertInstances = async () => {
    const response = await fetchFromServer(Endpoint.getalertinstances, {});
    return {
        status: response.status,
        alertinstances: response.data.alertinstances as AlertInstance[],
    }
}

export const getAlertConfigs = async () => {
    const response = await fetchFromServer(Endpoint.getalertconfigs, {});
    return {
        status: response.status,
        alertconfigs: response.data.alertconfigs as AlertConfig[],
    }
}

export const saveAlertConfig = async (alertConfig: AlertConfig) => {
    const response = await fetchFromServer(Endpoint.savealertconfig, {
        alertconfig: alertConfig
    });
    return {
        status: response.status,
        alertconfigs: response.data.alertconfigs as AlertConfig[],
    }
}

export const getStops = async (day: Day) => {
    const response = await fetchFromServer(Endpoint.getstops, { day: day });
    return {
        status: response.status,
        stops: response.data.stops as Stop[],
    }
}

export const getTimesheet = async (day: Day) => {
    const response = await fetchFromServer(Endpoint.gettimesheet, { day: day });
    return {
        status: response.status,
        timesheet: response.data.timesheet as TimesheetEntry[],
    }
}

export const sendResetPasswordLinkTo = async (uidOrUsername: string) => {
    const response = await fetchFromServer(Endpoint.requestpassword, { account: uidOrUsername });
    return {
        status: response.status,
        email: response.data.email,
        phone: response.data.phone,
    }
}

export const startVerifyEmail = async (uid: Uid) => {
    const response = await fetchFromServer(Endpoint.startverifyemail, { uid: uid });
    return {
        status: response.status,
        email: response.data.email,
    }
}

export const startVerifyPhone = async (uid: Uid) => {
    const response = await fetchFromServer(Endpoint.startverifyphone, { uid: uid });
    return {
        status: response.status,
        code: response.data.code,
        phone: response.data.phone,
    }
}

export const editInviteCode = async (data: { inviteCode: string, inviteRemainingSeconds: number }) => {
    const response = await fetchFromServer(Endpoint.editinvitecode, data);
    return {
        status: response.status,
    }
}

export const createInviteCode = async ({
                                           companyUid,
                                           inviteRemainingSeconds
                                       }: { companyUid: Uid, inviteRemainingSeconds: number }) => {
    const response = await fetchFromServer(Endpoint.createinvitecode, {
        inviteRemainingSeconds,
        groupUid: companyUid,
    });
    return {
        status: response.status,
    }
}

export const resetGoogleCalendar = async ({ uid }: { uid: Uid }) => {
    const response = await fetchFromServer(Endpoint.resetfield, { uid: uid, field: "google_calendar" });
    return {
        status: response.status,
    }
}

export const sendGoogleCalendarInvite = async ({ uid }: { uid: Uid }) => {
    const response = await fetchFromServer(Endpoint.sendgooglecalendarinvite, { uid: uid });
    return {
        status: response.status,
        receiver: response.data.receiver as string,
    }
}

export const disconnectSlack = async () => {
    const response = await fetchFromServer(Endpoint.disconnectslack, {});
    return { status: response.status }
}

export const createPlace = async (data: {}) => {
    const response = await fetchFromServer(Endpoint.createplace, data);
    return {
        status: response.status
    };
}

export const editPlace = async (data: {}) => {
    const response = await fetchFromServer(Endpoint.editplace, {
        ...data,
    });
    return {
        status: response.status
    };
}

export const loadUploadedReports = async () => {
    const response = await fetchFromServer(Endpoint.loaduploadedreports, {});
    return {
        status: response.status,
        reports: response.data.reports as UploadedReport[],
    };
}

export const loadRecurringReportConfigs = async () => {
    const response = await fetchFromServer(Endpoint.loadrecurringreportconfigs, {});
    return {
        status: response.status,
        configs: response.data.configs as RecurringReportConfig[],
    };
}

export const saveRecurringReportConfig = async (config: RecurringReportConfig) => {
    const response = await fetchFromServer(Endpoint.saverecurringreportconfig, { config });
    return {
        status: response.status,
        configs: response.data.configs as RecurringReportConfig[],
    };
}

interface CreateCompanyRequest {
    name: string;
    formattedAddress: string;
    address: string;
    city: string;
    countryCode: string;
    streetName: string;
    streetNumber: string;
    postalCode: string;
    lat: Latitude;
    lng: Longitude;
}

export const createCompany = async (data: CreateCompanyRequest) => {
    const response = await fetchFromServer(Endpoint.createcompany, data);
    return {
        status: response.status,
        company: response.data.company as Company,
    };
}

export const setProfileImageInitials = async (uid: Uid, initials: string) => {
    const response = await fetchFromServer(Endpoint.setprofileimageinitials, { uid, initials });
    return {
        status: response.status,
    };
}

export const startRouteSimulation = async (uidDay: UidDay) => {
    const response = await fetchFromServer(Endpoint.startroutesimulation, uidDay);
    return {
        status: response.status,
    };
}

export const stopRouteSimulation = async (uidDay: UidDay) => {
    const response = await fetchFromServer(Endpoint.stoproutesimulation, uidDay);
    return {
        status: response.status,
    };
}

export interface SaveTimeSheetEntryRequest {
    memberUid: Uid;
    clockInId: string;
    clockOutId: string;
    clockInTs: Timestamp;
    clockOutTs: Timestamp;
}

export const saveTimeSheetEntry = async (request: SaveTimeSheetEntryRequest) => {
    const response = await fetchFromServer(Endpoint.savetimesheetentry, request);
    return {
        status: response.status,
    };
}

export const deleteTracks = async (memberUid: Uid, entries: TimesheetEntry[]) => {
    const response = await fetchFromServer(Endpoint.deletetracks, { memberUid, trackIds: entries.map(e => e.uid) });
    return {
        status: response.status,
    };
}

export const publishTracks = async (memberUid: Uid, entries: TimesheetEntry[], publish: boolean) => {
    const response = await fetchFromServer(Endpoint.publishtracks, {
        memberUid,
        publish,
        trackIds: entries.map(e => e.uid)
    });
    return {
        status: response.status,
    };
}

export const editTrackApprovalStatus = async (trackEntry: TrackEntry, approvalStatus: ApprovalStatus) => {
    const response = await fetchFromServer(Endpoint.edittrack, {
        track: trackEntry.uid,
        approval_status: approvalStatus,
    });
    return {
        status: response.status,
    };
};

interface PasswordRecoveryRequest {
    token: string;
    newPassword?: string;
    newName?: string;
}

interface PasswordRecoveryResponse {
    status: boolean;
    name?: string;
    username?: string;
    redirect: "login" | "getapp";
    invalidPasswordMessage?: string | undefined;
    errorCode?: number;
}

export const passwordRecovery = async (request: PasswordRecoveryRequest): Promise<PasswordRecoveryResponse> => {
    const response = await fetchFromServer(Endpoint.passwordrecovery, request);
    return {
        status: response.status,
        name: response.data?.name,
        username: response.data?.username,
        redirect: response.data?.redirect,
        invalidPasswordMessage: response.data?.invalidPasswordMessage,
        errorCode: response.error,
    };
}

interface ActivationRequest {
    token: string;
}

interface ActivationResponse {
    status: boolean;
    recoveryToken?: string;
    redirect?: 'passwordrecovery' | 'login' | 'getapp';
}

export const activation = async (request: ActivationRequest): Promise<ActivationResponse> => {
    const response = await fetchFromServer(Endpoint.activation, request);
    return {
        status: response.status,
        recoveryToken: response.data.recoveryToken ? response.data.recoveryToken : undefined,
        redirect: response.data.redirect ? response.data.redirect : undefined,
    };
}

export const statsMembers = async (fromDay: Day, untilDay: Day) => {
    const response = await fetchFromServer(Endpoint.statsmembers, { fromDay, untilDay });
    return {
        status: response.status,
        stats: response.data.stats as MemberStat[],
    };
}

export const statsMembersByDay = async (fromDay: Day, untilDay: Day, bucketByDay = false) => {
    const response = await fetchFromServer(Endpoint.statsmembers, { fromDay, untilDay, bucketByDay: true });
    return {
        status: response.status,
        stats: response.data.stats as MemberStatDay[],
    };
}

export const getActivities = async (uid: Uid) => {
    const response = await fetchFromServer(Endpoint.getactivities, { max: 10_000, uid });
    return {
        status: response.status,
        activities: response.data.activities as Activity[],
    };
}

export const resendInvite = async (uid: Uid) => {
    const response = await fetchFromServer(Endpoint.resendinvite, { uid });
    return {
        status: response.status
    }
}

interface DistributeJobsRequest {
    regions: number;
    jobs: string[];
    max?: number;
    method: "distance" | "zones";
}

export const distributeJobs = async (req: DistributeJobsRequest) => {
    const mappedIds = req.jobs.reduce((obj: any, id) => {
        obj[id] = {};
        return obj;
    }, {});
    const response = await fetchFromServer(Endpoint.distributejobs, {
        regions: req.regions,
        jobs: mappedIds,
        method: req.method,
        max: req.max
    });
    return {
        status: response.status,
        clusters: response.data.clusters as Cluster[],
    };
}

export const submitForm = async (content: FormContent) => {
    const response = await fetchFromServer(Endpoint.submitform, {
        content: JSON.stringify(content), 
    });

    return {
        status: response.status,
        submissionUid: response.data.submissionUid
    };
}

interface GetChecksRequest {
    uid: Uid;
    fromTs: Timestamp;
}

export const getChecks = async (req: GetChecksRequest) => {
    const response = await fetchFromServer(Endpoint.getchecks, req);

    return {
        status: response.status,
        checks: response.data.checks as Check[]
    }
}

export const getTrips = async (req: { accountUids: Uid[] }) => {
    const response = await fetchFromServer(Endpoint.gettrips, req);

    return {
        status: response.status,
        trips: response.data.trips as Trip[]
    }
}