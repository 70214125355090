import { Button } from "baseui/button";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { useState } from "react";
import { emailValidator } from "../../constants/validators";
import { useEditPlaces } from "../../hooks/places/useEditPlaces";
import { usePlaceSelection } from "../../hooks/places/usePlaceSelection";
import { useTheme } from "../../hooks/useTheme";
import { Team } from "../../types/core";
import { FlexGridLayout } from "../containers/FlexGridLayout";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { ColorPicker } from "../pickers/ColorPicker";
import { TeamsSelect } from "../pickers/TeamsSelect";
import { ConfirmModal } from "../ui/ConfirmModal";
import { EmailIcon, PhoneIcon, TrashIcon } from "../ui/svg";
import { LinkedFormsSelect } from "../pickers/LinkedFormsSelect";
import { LinkedForm } from "../../types/linked";
import { RADIUS_MAX, RADIUS_MIN } from "../../constants/places";
import { useStrings } from "../../hooks/useStrings";
import { useToast } from "../../hooks/useToast";

type Props = {
    onFinish: () => void;
    initialColor?: string;
};

export function PlacesBulkEdit({ onFinish, initialColor = "" }: Props) {
    const { theme } = useTheme();
    const { strings, format } = useStrings();
    const [color, setColor] = useState(initialColor);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [radius, setRadius] = useState<number>();
    const [teams, setTeams] = useState<Team[] | "">([]);
    const selection = usePlaceSelection();
    const { editPlaces, deletePlaces } = useEditPlaces();
    const [saving, setSaving] = useState(false);
    const [showDeleteConfirm, setDeleteShowConfirm] = useState(false);
    const [linkedForms, setLinkedForms] = useState<LinkedForm[] | "">([]);
    const toast = useToast();
    const emailIsValid = !!email && !emailValidator.test(email) ? false : true;
    const radiusIsValid = !!radius && !(radius >= RADIUS_MIN && radius <= RADIUS_MAX) ? false : true;
    const hasChanges = !(!email && !phone && !radius && color === initialColor && !teams.length && teams !== "" && !linkedForms.length && linkedForms !== "");

    const onDeleteConfirmed = async () => {
        await deletePlaces(selection.selectedUids);
        setDeleteShowConfirm(false);
        onFinish();
    };

    const onApplyClick = async () => {
        if (!hasChanges) { return }

        setSaving(true);
        let data = {
            color: color || undefined,
            email: email && emailIsValid ? email : undefined,
            phone: phone || undefined,
            radius: radiusIsValid ? radius : undefined,
            teams: teams === "" ? "" : teams.length ? teams.map((t) => t.id) : undefined,
            linkedForms: linkedForms === "" ? "" : linkedForms.length ? JSON.stringify(linkedForms) : undefined,
        };
        await editPlaces(selection.selectedUids, data);
        setColor(initialColor);
        setEmail("");
        setPhone("");
        setRadius(undefined);
        setTeams([]);
        setLinkedForms([]);
        toast.showInfo(strings.Places.PlacesUpdate);
        setSaving(false);
    };

    const onCancelClick = () => {
        onFinish();
    };

    return (
        <VFlex spacing>
            <FlexGridLayout>
                <FormControl
                    error={!radiusIsValid ? format(strings.Places.InvalidRadiusDesc, RADIUS_MIN, RADIUS_MAX) : undefined}
                    overrides={{
                        ControlContainer: {
                            style: { marginBottom: 0 },
                        },
                    }}
                >
                    <Input
                        type="number"
                        value={radius || ""}
                        onChange={({ target: { value } }) => setRadius(+value)}
                        min={RADIUS_MIN}
                        max={RADIUS_MAX}
                        size="compact"
                        placeholder={strings.General.RadiusInMeter}
                    />
                </FormControl>
                <FormControl
                    error={email && !emailIsValid ? strings.General.PleaseEnterAValidEmailAddress : undefined}
                    overrides={{
                        ControlContainer: {
                            style: { marginBottom: 0 },
                        },
                    }}
                >
                    <Input
                        placeholder={strings.General.Email}
                        type="email"
                        value={email}
                        onChange={({ target: { value } }) => setEmail(value)}
                        size="compact"
                        startEnhancer={<EmailIcon size={12} color={theme.colors.contentTertiary}/>}
                    />
                </FormControl>
                <Input
                    placeholder={strings.General.Phone}
                    type="tel"
                    value={phone}
                    onChange={({ target: { value } }) => setPhone(value)}
                    size="compact"
                    startEnhancer={<PhoneIcon size={12} color={theme.colors.contentTertiary}/>}
                />
            </FlexGridLayout>
            <FlexGridLayout>
                <ColorPicker selectedColor={color} onChange={setColor}/>
                <TeamsSelect placeholder={`${strings.General.AssignTeamsTo}...`} selectedTeams={teams}
                             onSelect={setTeams} hideUnassigned={false}/>
                <LinkedFormsSelect
                    linkedForms={linkedForms}
                    onChangeLinkedForms={(linkedForms => setLinkedForms(linkedForms))}
                    placeholder={strings.Job.FormsLinkedForms}
                    hideUnlinked={false}
                />
            </FlexGridLayout>
            <HFlex>
                <Button
                    kind="tertiary"
                    size="compact"
                    onClick={() => setDeleteShowConfirm(true)}
                    startEnhancer={<TrashIcon size={14} color={theme.colors.contentTertiary}/>}
                >
                    {strings.Places.DeletePlaces}
                </Button>
                <SpacerExpand/>
                <Button kind="secondary" size="compact" onClick={() => onCancelClick()}>
                    {strings.General.Cancel}
                </Button>
                <Spacer/>
                <Button isLoading={saving} size="compact" onClick={() => onApplyClick()}
                        disabled={!hasChanges || !emailIsValid || !radiusIsValid}>
                    {strings.General.Apply}
                </Button>
            </HFlex>
            <ConfirmModal
                isOpen={showDeleteConfirm}
                title={format(strings.Places.DeleteXPlaces, selection.selectionCount)}
                description={strings.Places.ConfirmDeletePlacesDesc}
                onCancel={() => setDeleteShowConfirm(false)}
                onConfirm={onDeleteConfirmed}
            />
        </VFlex>
    );
}