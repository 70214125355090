import { useRecoilValue } from "recoil";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { EmptyState } from "../ui/EmptyState";
import { AllJobsIcon } from "../ui/svg";
import { allJobsFiltersState } from "../../states/allJobsState";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { requestingState } from "../../states/viewState";
import { Alert, Spin } from "antd";
import React from "react";
import { JobsGrid } from "./JobsGrid";
import { templatesState } from "../../states/templatesState";
import { useAllJobsColumns } from "../../hooks/jobs/useAllJobsColumns";
import { ColumnOptions } from "../shared/ColumnOptions";

export function JobTemplatesGrid() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const jobs = useRecoilValue(templatesState(false));
    const allJobsTableFilters = useRecoilValue(allJobsFiltersState);
    const requesting = useRecoilValue(requestingState({ req: "alljobs" }));
    const { columnsConfiguration, disabledColumns, enabledColumns, reorderColumns, columns } = useAllJobsColumns();

    const loading = jobs.length === 0 && requesting;

    if (loading) {
        return (
            <VFlex>
                <SpacerExpand/>
                <HFlex>
                    <SpacerExpand/>
                    <Spin/>
                    <SpacerExpand/>
                </HFlex>
                <SpacerExpand/>
            </VFlex>
        );
    }

    if (jobs.length === 0 && allJobsTableFilters.length === 0) {
        return (
            <EmptyState title={strings.General.Empty} subtitle={strings.General.ReduceFilters}>
                <AllJobsIcon size={64} color={theme.colors.primary400}/>
            </EmptyState>
        );
    }

    return (
        <VFlex
            spacing
            style={{
                flex: 1,
                marginBottom: theme.sizing.scale300,
                position: "relative"
            }}
        >
            <ColumnOptions
                columns={columns}
                enabledColumns={enabledColumns}
                configuration={columnsConfiguration}
                disabledColumns={disabledColumns}
                onReorder={reorderColumns}
            />
            <JobsGrid jobs={jobs}/>
            <Alert message={strings.JobTemplates.JobTemplatesDesc} showIcon closable/>
        </VFlex>
    );
}
