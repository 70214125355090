import { useRecoilState, useRecoilValue } from "recoil";
import { useStrings } from "../../hooks/useStrings";
import { membersFiltersState } from "../../states/membersState";
import { hasRecentGps, isOffDuty, Member } from "../../types/member";
import { HFlex } from "../containers/HFlex";
import { VFlex } from "../containers/VFlex";
import { memo, useCallback } from "react";
import { jobsAssignedState } from "../../states/jobsState";
import { useUpdateEffect } from "usehooks-ts";
import { selectedDayState } from "../../states/appState";
import { FilterBadge } from "../badges/FilterBadge";


export function MembersFiltersInternal() {
    const { strings } = useStrings();
    const [filters, setFilters] = useRecoilState(membersFiltersState);
    const jobsAssigned = useRecoilValue(jobsAssignedState);
    const selectedDay = useRecoilValue(selectedDayState);

    const hasActiveFilter = !!filters.filter((f) => f.id === "active").length;
    const hasAssignedJobsFilter = !!filters.filter((f) => f.id === "assignedJobs").length;
    const hasOffDutyFilter = !!filters.filter((f) => f.id === "hideOffDuty").length;
    const ungroupedFilters = [];

    const matchAssignedJobs = useCallback((member: Member) => jobsAssigned.some((job) => job.assigneeUsername === member.username), [jobsAssigned]);

    useUpdateEffect(() => {
        const assignedJobsIndex = filters.findIndex((filter) => filter.id === "assignedJobs");
        if (assignedJobsIndex !== -1) {
            setFilters((prevFilters) => {
                const newFilters = [...prevFilters];
                newFilters[assignedJobsIndex] = {
                    ...newFilters[assignedJobsIndex],
                    matches: matchAssignedJobs,
                }
                return newFilters;
            });
        }
    }, [selectedDay]);

    if (!hasActiveFilter) {
        ungroupedFilters.push({
            id: "active",
            label: strings.General.CurrentlyActive,
            matches: (member: Member) => hasRecentGps(member)
        });
    }
    if (!hasAssignedJobsFilter) {
        ungroupedFilters.push({
            id: "assignedJobs",
            label: strings.General.JobsAssigned,
            matches: matchAssignedJobs
        });
    }
    if (!hasOffDutyFilter) {
        ungroupedFilters.push({
            id: "hideOffDuty",
            label: strings.General.HideOffDuty,
            matches: (member: Member) => !isOffDuty(member)
        });
    }

    return (
        <VFlex spacing style={{ height: "auto" }}>
            {!!filters.length && (
                <HFlex style={{
                    justifyContent: "flex-end",
                    flexWrap: "wrap",
                    paddingTop: "8px",
                }}>
                    {filters.map((filter) => (
                        <FilterBadge
                            key={filter.id}
                            name={filter.label}
                            onCloseClick={() => setFilters(filters.filter((f) => f.id !== filter.id))}
                        />
                    ))}
                </HFlex>
            )}
        </VFlex>
    )
}

export const MembersFilters = memo(MembersFiltersInternal);