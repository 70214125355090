import { TimesheetEntry } from "../../types/timesheet";
import { HFlex } from "../containers/HFlex";
import { MemberItemSimplified } from "../member/MemberItemSimplified";
import { LabelXSmall, ParagraphSmall } from "baseui/typography";
import { useTime } from "../../hooks/useTime";
import { useTheme } from "../../hooks/useTheme";
import { Popconfirm, Progress } from "antd";
import { HOUR } from "../../utils/time";
import { EditIcon, TrashIcon } from "../ui/svg";
import { useStrings } from "../../hooks/useStrings";
import { Button } from "baseui/button";
import { useState } from "react";
import { useTimesheet } from "../../hooks/useTimesheet";
import { ClickableListItem } from "../ui/ClickableListItem";
import { StyledTimePicker } from "../pickers/StyledTimePicker";
import { TimeBadge } from "../badges/TimeBadge";

export function TimesheetLine({
                                  entry,
                                  canModify,
                                  onRequestModify
                              }: { entry: TimesheetEntry; canModify: boolean, onRequestModify: () => void }) {
    const { strings } = useStrings();
    const time = useTime();
    const { theme } = useTheme();
    const entryIn = entry?.clockIn?.ts || 0;
    const entryOut = entry.clockOut?.ts || 0;
    const duration = entryOut - entryIn;
    const percentOf8hrs = (100 * duration) / (HOUR * 8);
    const [entryInInput, setEntryInInput] = useState<Date | null>(entryIn ? new Date(entryIn) : null);
    const [entryOutInput, setEntryOutInput] = useState<Date | null>(entryOut ? new Date(entryOut) : null);
    const onClick = () => {};
    const timesheet = useTimesheet();
    const [showEditPop, setShowEditPop] = useState(false);
    const [showDeletePop, setShowDeletePop] = useState(false);

    const openEditPop = () => {
        setShowEditPop(true);
    };

    const handleConfirmEdit = () => {
        modifyTimesheet(false);
        setShowEditPop(false);
    };

    const handleCancelEdit = () => {
        setShowEditPop(false);
    };

    const openDeletePop = () => {
        setShowDeletePop(true);
    };

    const handleConfirmDelete = () => {
        modifyTimesheet(true)
        setShowDeletePop(false);
    };

    const handleCancelDelete = () => {
        setShowDeletePop(false);
    };

    const modifyTimesheet = async (remove = false) => {
        if (remove) {
            return timesheet.removeEntry(entry);
        } else {
            return timesheet.editEntry(entry, entryInInput, entryOutInput);
        }
    };

    return (
        <ClickableListItem onClick={onClick}>
            <HFlex spacing alignCenter>
                <MemberItemSimplified uid={entry.uid} openButton style={{ width: "auto", flex: 3 }}/>
                <HFlex alignCenter>
                    <TimeBadge
                        startMillis={entryIn}
                        style={{ textAlign: "center", width: "68px", marginRight: "0px" }}
                        color={theme.colors.backgroundAlwaysDark}
                    />
                    <LabelXSmall style={{ textAlign: "end" }}>
                        —
                    </LabelXSmall>
                    <TimeBadge
                        startMillis={entryOut}
                        style={{ textAlign: "center", width: "68px", marginRight: "0px" }}
                        color={theme.colors.backgroundAlwaysDark}
                    />
                    {canModify && (
                        <Popconfirm
                            title={strings.General.Edit}
                            open={showEditPop}
                            onConfirm={handleConfirmEdit}
                            onCancel={handleCancelEdit}
                            description={
                                <HFlex alignCenter>
                                    <StyledTimePicker
                                        value={entryInInput}
                                        onChange={setEntryInInput}
                                    />
                                    {"—"}
                                    <StyledTimePicker
                                        value={entryOutInput}
                                        onChange={setEntryOutInput}
                                    />
                                </HFlex>
                            }
                            okText={strings.General.Save}
                        >
                            <Button size={"mini"} kind={"tertiary"} onClick={openEditPop}>
                                <EditIcon size={12} color={theme.colors.contentTertiary}/>
                            </Button>
                        </Popconfirm>
                    )}
                    {!canModify && (
                        <Button size={"mini"} kind={"tertiary"} onClick={() => {
                            onRequestModify();
                            openEditPop();
                        }}>
                            <EditIcon size={12} color={theme.colors.contentTertiary}/>
                        </Button>
                    )}
                </HFlex>
                <HFlex alignCenter spacing style={{ width: "280px" }}>
                    {!!entryIn && !!entryOut && (
                        <>
                            <Progress
                                percent={percentOf8hrs}
                                steps={5}
                                strokeColor={theme.customColors.accent}
                                showInfo={false}
                            />
                            <LabelXSmall color={theme.colors.contentTertiary}>
                                {time.formatDuration(duration)}
                            </LabelXSmall>
                        </>
                    )}
                </HFlex>
                {canModify && (
                    <Popconfirm
                        title={strings.General.Delete}
                        open={showDeletePop}
                        onConfirm={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                        description={<ParagraphSmall margin={0}>{strings.General.ReallyDelete}</ParagraphSmall>}
                        okText={strings.General.Delete}
                    >
                        <Button size={"mini"} kind={"tertiary"} onClick={openDeletePop}>
                            <TrashIcon color={theme.colors.contentTertiary} size={12}/>
                        </Button>
                    </Popconfirm>
                )}
                {!canModify && (
                    <Button size={"mini"} kind={"tertiary"} onClick={() => {
                        onRequestModify();
                        openDeletePop();
                    }}>
                        <TrashIcon color={theme.colors.contentTertiary} size={12}/>
                    </Button>
                )}
            </HFlex>
        </ClickableListItem>
    );
}