import { Checkbox } from "baseui/checkbox";
import { useRecoilValue } from "recoil";
import { DAY_STRING_KEYS } from "../../constants/days";
import { useTheme } from "../../hooks/useTheme";
import { stringsState } from "../../states/localeState";
import { DayKey, SelectedDays } from "../../types/days";

type Props = {
    editable?: boolean;
    days: SelectedDays;
    onCheck: (day: DayKey) => void;
};

export function DaysPicker({ days, onCheck, editable = true }: Props) {
    const strings = useRecoilValue(stringsState);
    const { css, theme } = useTheme();

    const daysArray = Object.entries(days);

    return (
        <div
            className={css({
                display: "flex",
                flexDirection: "row",
                columnGap: theme.sizing.scale600,
                marginBottom: theme.sizing.scale600,
            })}
        >
            {daysArray.map(([day, selected]) => {
                return (
                    <Checkbox
                        key={day}
                        checked={selected}
                        onChange={() => onCheck(day as DayKey)}
                        labelPlacement="top"
                        disabled={!editable}
                    >
                        {strings.getString(DAY_STRING_KEYS[day as DayKey].short)}
                    </Checkbox>
                )
            })}
        </div>
    );
}
