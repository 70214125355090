import { Avatar } from 'baseui/avatar';
import { LOCATION_PUCK_TYPES, } from 'baseui/map-marker';
import { useRecoilValue } from 'recoil';
import { mapBearingState, mapIconSizeState } from '../../states/mapState';
import { isOnTheWay } from '../../types/member';
import { LocationArrow } from "../map/LocationArrow";
import { COLOR_MOVING } from "../../constants/colors";
import { getMovingColor } from "../../utils/memberUtils";
import { memberState } from "../../states/membersState";
import { memo } from 'react';
import { Uid } from "../../types/core";

type Props = {
    uid: Uid;
    forMap: boolean;
    size?: number;
};

const MemberAvatar = memo(({ uid, forMap, size = 24 }: Props) => {
    const member = useRecoilValue(memberState(uid));
    const iconSize = useRecoilValue(mapIconSizeState);
    const mapBearing = useRecoilValue(mapBearingState);

    if (!member) {
        return null;
    }

    const translate = forMap ? 'translate(-50%, -50%)' : undefined;
    const center = forMap ? '50%' : undefined;
    const circleSize = forMap ? `${iconSize + 2}px` : size + 'px';
    const boxSize = forMap ? `${iconSize}px` : (size - 2) + 'px';
    const overrides = {
        Root: {
            style: () => ({
                position: 'absolute',
                top: center,
                left: center,
                transform: translate,
            }),
        },
    };

    const circleBorder = '2px solid' + getMovingColor(member);

    return (
        <div
            style={{
                borderRadius: '50%',
                border: circleBorder,
                width: circleSize,
                height: circleSize,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                flexShrink: 0,
            }}
        >

            <Avatar
                name={member.name || member.username}
                size={boxSize}
                src={member.portrait}
                overrides={overrides}
            />
            {isOnTheWay(member) && (
                <LocationArrow
                    type={LOCATION_PUCK_TYPES.consumer}
                    heading={member.location.head - mapBearing}
                    overrides={overrides}
                    iconSize={forMap ? iconSize : size}
                    color={COLOR_MOVING}
                />
            )}
        </div>
    );
});

export default MemberAvatar; 