import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import { Button } from "baseui/button";
import { useEffect, useState } from "react";
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";
import { FormItemCard } from "./FormItemCard";
import { FormBetweenItems } from "./FormBetweenItems";
import { useForms } from "../../hooks/useForms";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { HFlex } from "../containers/HFlex";
import { FormPlusButton } from "./FormPlusButton";
import { useRecoilState, useResetRecoilState } from "recoil";
import { editStandardFormContentState } from "../../states/formsState";

type Props = {
    onClose: (saved: boolean) => void;
}

export function StandardFormModal({ onClose }: Props) {
    const { strings } = useStrings()
    const [loading, setLoading] = useState(false);
    const { theme } = useTheme();
    const [content, setContent] = useRecoilState(editStandardFormContentState);
    const resetContent = useResetRecoilState(editStandardFormContentState);
    const forms = useForms();

    useEffect(() => {
        return () => resetContent();
    }, []);

    return (
        <Modal closeable={false} isOpen overrides={{
            Dialog: {
                style: {
                    backgroundColor: theme.colors.backgroundSecondary
                }
            }
        }}>
            <ModalHeader>{strings.ShortForm.StandardForm}</ModalHeader>
            <ModalBody $style={{ maxHeight: "60vh", overflow: "auto" }}>
                {content.content.map((item, index) =>
                    <div key={index}>
                        <FormBetweenItems items={forms.jobOptions}
                                          onPlusClick={(option) => setContent(forms.addItem(index, content, option))}/>
                        <FormItemCard formItem={item}
                                      onSetName={(name) => setContent(forms.setName(index, content, name))}
                                      onSetType={(type) => setContent(forms.setType(index, content, type))}
                                      onSetOptions={(options) => setContent(forms.setOptions(index, content, options))}
                                      onRemove={() => setContent(forms.removeItem(index, content))}
                                      jobInputForm={true}
                        />
                    </div>
                )}
                <Spacer/>
                <HFlex>
                    <SpacerExpand/>
                    <FormPlusButton items={forms.jobOptions}
                                    onPlusClick={(option) => setContent(forms.addItem(-1, content, option))}/>
                    <SpacerExpand/>
                </HFlex>
            </ModalBody>
            <ModalFooter>
                <Button
                    kind="tertiary"
                    size='compact'
                    onClick={() => onClose(false)}
                    overrides={{
                        BaseButton: {
                            style: ({ $theme }) => ({
                                marginRight: $theme.sizing.scale400,
                            }),
                        },
                    }}
                >
                    {strings.General.Cancel}
                </Button>
                <Button
                    onClick={async () => {
                        setLoading(true);
                        await forms.saveStandardForm(content);
                        setLoading(false);
                        onClose(true);
                    }}
                    size='compact'
                    isLoading={loading}
                >
                    {strings.General.Save}
                </Button>
            </ModalFooter>
        </Modal>
    );
}