import { useRecoilValue } from "recoil";
import { stringsState } from "../../states/localeState";
import {
    hasValidDevice,
    isAdmin,
    isAdminOrOperator,
    isIdling,
    isLoggedIn,
    isOffDuty,
    isOnTheWay,
    isOperator,
    isTrackingOn,
    isWorker,
    Member
} from "../../types/member";

export interface StatusInfo {
    text: string;
    color: "red" | "blue" | "green" | "";
    icon: string;
    status: "admin-or-operator" | "invited" | "offduty" | "loggedout" | "trackingoff" | "locationservicesoff" | "idling" | "none";
}

export function useMemberInfo() {
    const strings = useRecoilValue(stringsState);

    function getRole(role: string, shortName = false) {
        if (isAdmin(role)) {
            return shortName ? "Admin" : strings.General.Admin;
        } else if (isOperator(role)) {
            return strings.General.Operator;
        } else if (isWorker(role)) {
            return strings.ProfileModal.Worker;
        } else {
            return "";
        }
    }

    function getBadgeInfo(member: Member | undefined, showAllBadges: boolean = true): StatusInfo {
        const info: StatusInfo = { text: "", color: "", icon: "", status: "none" };
        if (!member || (isAdminOrOperator(member) && !isIdling(member) && !isOnTheWay(member)) || !member.states) {
            info.status =  "admin-or-operator";
        } else if (!hasValidDevice(member)) {
            info.text = strings.General.Invited
            info.status = "invited";
        } else if (isOffDuty(member)) {
            info.text = strings.General.OffDuty
            info.icon = "clock";
        } else if (showAllBadges && !isLoggedIn(member)) {
            info.text = strings.General.LoggedOut
            info.icon = "red";
        } else if (showAllBadges && !isTrackingOn(member)) {
            info.text = strings.General.TrackingOff
            info.icon = "red";
        } else if (showAllBadges && !member.states.location_services) {
            info.text = strings.General.LocationServicesOff
            info.color = "red";
        } else if (isOnTheWay(member)) {
            info.text = strings.General.OnTheWay
            info.color = "green";
        } else if (isIdling(member)) {
            info.text = strings.General.Stationary
            info.color = "blue";
        }
        return info;
    }

    return { getRole, getBadgeInfo };
}