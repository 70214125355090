import { AssistantFulfillment } from "../../types/assistant";
import { useTheme } from "../../hooks/useTheme";
import JobItem from "../dispatch/JobItem";
import { LabelXSmall } from "baseui/typography";
import { Button } from "baseui/button";
import { useCamera } from "../../hooks/useCamera";
import { LocateIcon, RemoveIcon } from "../ui/svg";
import { useStrings } from "../../hooks/useStrings";
import { HFlex } from "../containers/HFlex";
import { ReactNode } from "react";
import { memberState } from "../../states/membersState";
import MemberItem from "../member/MemberItem";
import { StaticMap } from "../staticmap/StaticMap";
import { useRecoilValue } from "recoil";
import { jobState } from "../../states/jobsState";
import { STATUS_DELETED } from "../../types/job";
import { Card } from "antd";
import { placeState } from "../../states/placeState";
import { PlaceBadge } from "../badges/PlaceBadge";
import { EMPTY_UID } from "../../types/core";

export function AssistantFulfillmentCard({
                                             fulfillment,
                                             onRemove
                                         }: { fulfillment: AssistantFulfillment, onRemove: () => void }) {
    const camera = useCamera();
    const { theme } = useTheme();
    const { strings, format } = useStrings();
    const member = useRecoilValue(memberState(fulfillment?.locateUid || EMPTY_UID));
    const place = useRecoilValue(placeState(fulfillment?.locateUid || EMPTY_UID));
    const job = useRecoilValue(jobState(fulfillment?.job?.id || ""));

    const title = fulfillment.action === "job"
        ? job?.receiverUid
            ? format(strings.Assistant.JobCreatedForX, job?.assigneeName)
            : strings.Assistant.JobCreated
        : fulfillment.action === "zone"
            ? strings.Assistant.ZoneCreated
            : fulfillment.userText;

    if (fulfillment.job && (!job || job.status === STATUS_DELETED)) {
        return null;
    }

    const locatedLocation = member ? member.location : place ? place.location : undefined;

    const onLocateClick = () => {
        if (locatedLocation) {
            camera.zoomTo(locatedLocation);
        } else {
            camera.fitToLngLats(fulfillment.coordinates.map(ll => [ll.lng, ll.lat]));
        }
    };

    const actions: ReactNode[] = []

    if (fulfillment.coordinates?.length > 0 || fulfillment.locateUid) {
        actions.push(
            <Button size={"compact"} kind={"tertiary"}>
                <HFlex alignCenter spacing>
                    {strings.General.Locate}
                    <LocateIcon size={16} color={theme.colors.contentPrimary}/>
                </HFlex>
            </Button>
        );
    }

    return (
        <Card
            style={{ maxWidth: "300px" }}
            cover={locatedLocation && <StaticMap viewMarker
                                                 location={locatedLocation}
                                                 width={300}
                                                 height={150}
                                                 onClick={onLocateClick}/>}
            actions={[
                <Button onClick={onRemove}
                        size={"mini"}
                        kind={"tertiary"}>
                    <RemoveIcon size={16} color={theme.colors.contentPrimary}/>
                </Button>
            ]}
        >
            {title}
            <HFlex>
                {fulfillment.job && <JobItem id={fulfillment.job.id}/>}
                {member && <MemberItem uid={member.uid}/>}
                {place && <PlaceBadge uid={place.uid}/>}
            </HFlex>
            {fulfillment.zone &&
                <HFlex>
                    <LabelXSmall>{fulfillment.zone.name}</LabelXSmall>
                </HFlex>
            }
        </Card>
    )
}