import { Constraint } from "../constants/routing";
import { Constraints } from "../types/core";

export function createConstraints(constraints: Constraint[], maxJobs?: number): Constraints {
    return {
        working_hours: constraints.includes(Constraint.WORKING_HOURS),
        time_window: constraints.includes(Constraint.TIME_WINDOWS),
        skills: constraints.includes(Constraint.SKILLS),
        capacity: constraints.includes(Constraint.CAPACITY),
        max_jobs: constraints.includes(Constraint.EVEN_DISTRIBUTION) ? maxJobs : undefined,
    } as Constraints;
}