import { Latitude, Longitude, Timestamp, Uid } from "./core";

export interface Message {
    id?: string;
    uid: Uid;
    msg: string;
    ts: Timestamp;
    in: boolean;
    unread?: boolean;
    lat: Latitude;
    lng: Longitude;
    read?: (0 | 1 | 2 | 3); // 0..none, 1..me_only, 2..other_only, 3..both
    name?: string;
}

export function hasLocationAttached(message: Message) {
    return message.lng + message.lat !== 0;
}
