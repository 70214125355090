import { useRecoilState } from "recoil";
import { editCompanyState } from '../../states/editCompanyState';
import { Company } from '../../types/company';
import { DetailedLocation } from '../../types/core';
import { useDrawMode } from "../useDrawMode";

export function useEditCompanyLocation() {
    const [companyFields, setCompanyFields] = useRecoilState(editCompanyState);
    const { drawMode, enterDrawMode } = useDrawMode();

    const location = drawMode?.location;

    const initEditCompany = () => {
        enterDrawMode({
            type: "company",
            action: "edit",
            location: {
                address: companyFields.address,
                lat: companyFields.location.lat,
                lng: companyFields.location.lng,
            } as DetailedLocation
        });
    }

    const editCompany = () => {
        if (location) {
            setCompanyFields((prev) => ({
                ...prev,
                location: {
                    lat: location?.lat || 0,
                    lng: location?.lng || 0,
                },
                address: location.address,
            } as Company));
        }
    };

    return {
        initEditCompany,
        editCompany,
    };
}