import { LabelSmall } from "baseui/typography";
import { HFlex } from "../containers/HFlex";
import { ReactNode } from "react";
import { MemberBadge } from "./MemberBadge";
import { useStrings } from "../../hooks/useStrings";
import { Spacer } from "../containers/Spacer";
import { Uid } from "../../types/core";
import { useTheme } from "../../hooks/useTheme";

type Props = {
    members: Uid[];
    max?: number;
    onClose?: (uid: Uid) => void,
    children?: ReactNode;
    inline?: boolean;
    shortNames?: boolean;
    edit?: boolean;
}

export function MemberBadges({
                                 members,
                                 max = 10,
                                 onClose,
                                 children = null,
                                 inline = false,
                                 shortNames = false,
                                 edit = false
                             }: Props) {
    const { strings, format } = useStrings();
    const maxSelectedVisible = max;
    const { theme } = useTheme();
    const selectedCount = members.length;

    if (members.length === 0) {
        return null;
    }

    return (
        <HFlex spacing alignCenter style={{
            flexWrap: inline ? 'nowrap' : 'wrap',
            minHeight: theme.sizing.scale850,
            width: "auto",
            rowGap: theme.sizing.scale200,
        }}>
            {members.slice(0, maxSelectedVisible).map((member, index) => (
                <MemberBadge uid={member}
                             key={index}
                             shortName={shortNames}
                             onClose={onClose ? () => { onClose(member); } : undefined}
                             edit={edit}
                />
            ))}
            {selectedCount > maxSelectedVisible && (
                <LabelSmall alignSelf="center" color={theme.colors.contentTertiary} whiteSpace="nowrap">
                    {format(strings.General.XMore, selectedCount - maxSelectedVisible) + "..."}
                </LabelSmall>
            )}
            {children && (
                <>
                    <Spacer/>
                    {children}
                </>
            )}
        </HFlex>
    );
}