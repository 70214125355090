import { ReactNode } from "react";
import { useTheme } from "../../hooks/useTheme";


type Props = {
    incoming?: boolean;
    children: ReactNode;
    style?: any;
}

export function MessageBubble({ incoming = false, style, children }: Props) {
    const { theme, isDarkTheme } = useTheme();
    const color = isDarkTheme ? "#555555" : "#d8e2dc";
    return (
        <div style={{
            position: "relative",
            backgroundColor: incoming ? color : color + "33",
            color: incoming ? "#000000" : theme.colors.contentPrimary,
            marginBottom: "16px",
            marginLeft: incoming ? "48px" : "0px",
            marginRight: incoming ? "0px" : "48px",
            borderColor: color,
            borderWidth: "1px",
            borderStyle: "solid",
            borderTopLeftRadius: incoming ? "8px" : "0px",
            borderTopRightRadius: !incoming ? "8px" : "0px",
            borderBottomLeftRadius: incoming ? "8px" : "0px",
            borderBottomRightRadius: !incoming ? "8px" : "0px",
            paddingTop: "24px",
            paddingLeft: "24px",
            paddingBottom: "24px",
            paddingRight: "24px",
            maxWidth: "100%",
            alignSelf: !incoming ? "start" : "end",
            ...style,
        }}>
            {children}
        </div>
    );
}