import { createThemedUseStyletron, Theme } from 'baseui';
import { COLOR_ACCENT, COLOR_ASPHALT, COLOR_DANGER, COLOR_SILVER } from "../constants/colors";

export const themedUseStyletron = createThemedUseStyletron<Theme & {customColors: { primary: string; accent: string; contentLight: string; }}>();

export const primitives = {
    warning: COLOR_DANGER,
};

export const overridesLight = {
    colors: {
        backgroundPrimary: 'transparent',
        buttonSecondaryFill: "white",
        buttonSecondaryBorder: "black",
        inputFill: "white",
        inputBorder: "#55555533",
    },
    customColors: {
        primary: COLOR_ACCENT + "AA",
        accent: COLOR_ACCENT,
        contentLight: COLOR_SILVER,
    }
};
export const overridesDark = {
    colors: {
        backgroundPrimary: 'transparent',
        buttonSecondaryFill: "black",
        inputFill: "black",
        inputBorder: "#FFFFFF33",
    },
    customColors: {
        primary: COLOR_ACCENT + "AA",
        accent: COLOR_ACCENT,
        contentLight: COLOR_ASPHALT,
    }
};

export const spreadSheetLightTheme = {
    "--jexcel_header_color": "#000",
    "--jexcel_header_color_highlighted": "#000",
    "--jexcel_header_background": "#f3f3f3",
    "--jexcel_header_background_highlighted": "#dcdcdc",
    "--jexcel_content_color": "#000",
    "--jexcel_content_color_highlighted": "#000",
    "--jexcel_content_background": "#fff",
    "--jexcel_content_background_highlighted": "rgba(0,0,0,0.05)",
    "--jexcel_menu_background": "#fff",
    "--jexcel_menu_background_highlighted": "#ebebeb",
    "--jexcel_menu_color": "#555",
    "--jexcel_menu_color_highlighted": "#555",
    "--jexcel_menu_box_shadow": "2px 2px 2px 0px rgba(143, 144, 145, 1)",
    "--jexcel_border_color": "#ccc",
    "--jexcel_border_color_highlighted": "#000",
    "--jexcel_active_color": "#007aff"
}

export const spreadSheetDarkTheme = {
    "--jexcel_header_color": "#fff",
    "--jexcel_header_color_highlighted": "#fff",
    "--jexcel_header_background": "#313131",
    "--jexcel_header_background_highlighted": "#777",
    "--jexcel_content_color": "#e9e9e9",
    "--jexcel_content_color_highlighted": "#fff",
    "--jexcel_content_background": "#3e3e3e",
    "--jexcel_content_background_highlighted": "#333",
    "--jexcel_menu_background": "#7e7e7e",
    "--jexcel_menu_background_highlighted": "#ebebeb",
    "--jexcel_menu_color": "#ddd",
    "--jexcel_menu_color_highlighted": "#222",
    "--jexcel_menu_box_shadow": "unset",
    "--jexcel_border_color": "#5f5f5f",
    "--jexcel_border_color_highlighted": "#999",
    "--jexcel_active_color": "#eee"
}
