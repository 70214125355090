import { useSetRecoilState } from "recoil";
import { setRecoil } from "../../providers/RecoilAccessProvider";
import { editAccount } from "../../services/api";
import { memberState, updateTeamMembersState } from "../../states/membersState";
import { Member } from "../../types/member";
import useDirector from "../useDirector";

export function useTeamManagement() {
    const director = useDirector();
    const setUpdateTeamMembers = useSetRecoilState(updateTeamMembersState);

    const triggerTeamMembersUpdate = () => {
        setUpdateTeamMembers((prev) => prev + 1);
    }

    const handleUpdateMember = async (memberId: string, data: {}) => {
        const { status, account: updatedMember } = await editAccount({ uid: memberId, ...data });
        if (status && updatedMember) {
            setRecoil(memberState(updatedMember.uid), updatedMember as Member);
        }
    };

    const deleteTeam = async (teamId: number, membersInTeam: { id: string; teams: number[] }[]) => {
        const membersPromises = membersInTeam.map((member) => {
            return handleUpdateMember(member.id, { teams: member.teams.filter((id) => id !== teamId) });
        });
        await Promise.allSettled(membersPromises);
        await director.performPoll();
    };

    const removeMember = async (teamId: number, member: { id: string; teams: number[] }, teamMembersCount: number) => {
        await handleUpdateMember(member.id, { teams: member.teams.filter((id) => id !== teamId) });
        if (teamMembersCount === 1) {
            await director.performPoll();
        }
        triggerTeamMembersUpdate()
    };

    const addMembers = async (teamId: number, newMembers: Member[]) => {
        const membersPromises = newMembers.map((member) => {
            return handleUpdateMember(member.id, { teams: [...member.teams, teamId] });
        });
        await Promise.allSettled(membersPromises);
        await director.performPoll();
        triggerTeamMembersUpdate()
    };

    return {
        deleteTeam,
        removeMember,
        addMembers,
    };
}
