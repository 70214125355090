import { Progress } from "antd";
import { VFlex } from "../containers/VFlex";
import React from "react";
import { useTheme } from "../../hooks/useTheme";
import { useTime } from "../../hooks/useTime";
import { durationMillis, TrackOrStopEntry } from "../../types/track";
import { HOUR } from "../../utils/time";
import { LabelXSmall } from "baseui/typography";

export function LocationRowDuration({ entry } : { entry: TrackOrStopEntry }) {
    const { theme } = useTheme();
    const time = useTime();
    const duration = durationMillis(entry);
    const percentOf2hrs = 100 * duration / (HOUR * 2);

    return (
        <VFlex>
            <LabelXSmall>{time.formatDuration(duration)}</LabelXSmall>
            <Progress percent={Math.min(100, percentOf2hrs)}
                      steps={5}
                      strokeColor={theme.customColors.accent}
                      showInfo={false}
            />
        </VFlex>
    );
}