import { deleteAccount, editAccount } from '../../services/api';
import { usePlaceSelection } from "./usePlaceSelection";
import { usePlacesLayerUpdater } from "./usePlacesLayerUpdater";
import { getRecoil, setRecoil } from "../../providers/RecoilAccessProvider";
import { placesLayerState } from "../../states/geojsonState";
import { Uid } from "../../types/core";

export function useEditPlaces() {
    const selection = usePlaceSelection();
    const updater = usePlacesLayerUpdater();

    const editPlaces = async (uids: Uid[], data: {}) => {
        for (const uid of uids) {
            await editAccount({ uid: uid, ...data });
        }
        updater.updateFiltered(uids.join(","));
    };

    const deletePlaces = async (uids: Uid[]) => {
        for (const uid of uids) {
            await deleteAccount(uid);
        }
        const existingLayer = getRecoil(placesLayerState);
        if (existingLayer) {
            const features = existingLayer.features.filter(feature => !uids.includes(feature?.properties?.uid));
            const newLayer = {
                type: "FeatureCollection",
                features: features,
            } as GeoJSON.FeatureCollection;
            setRecoil(placesLayerState, newLayer);
        }
        selection.clearSelection();
    }

    return { editPlaces, deletePlaces };
}