export const PAGE = {
    MAP: 'map',
    LIVE: 'live',
    DISPATCH: 'dispatch',
    TIMELINE: 'timeline',
    PLACES: 'places',
    TEAM: 'team',
    FORMS: 'forms',
    HELP: 'help',
    MESSAGES: 'messages',
    SETTINGS: 'settings',
    REPORTS: 'reports',
    ALERTS: 'alerts',
    TIMESHEET: 'timesheet',
    STATS: 'statistics',
    INTEGRATIONS: 'integrations',
    HEATMAP: 'heatmap',
    UPLOADS: "uploads",
    LOCATION: 'location',
    ACTIVITYLOG: 'activitylog',
    FORMSUBMISSION: 'forms/submission'
} as const;
