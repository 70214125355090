import { Badge } from "antd";
import { useAllJobsSelection } from "../../hooks/jobs/useAllJobsSelection";
import { useSingleJobSelection } from "../../hooks/jobs/useSingleJobSelection";
import JobItem from "./JobItem";
import { memo } from "react";

type Props = {
    isDragging: boolean;
    id: string;
};

function DraggableJobItem({ isDragging, id }: Props) {
    const { isSelected } = useSingleJobSelection(id || "");
    const { selectionCount } = useAllJobsSelection();

    if (isDragging && isSelected && selectionCount > 1) {
        return (
            <Badge count={selectionCount} offset={[-28, 3]}>
                <JobItem id={id} spacing={false} />
            </Badge>
        );
    }

    return <JobItem id={id} spacing={false} />
}

export default memo(DraggableJobItem);
