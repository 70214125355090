import { useRecoilValue } from "recoil";
import { formSubmissionsByTemplateState, formSubmissionsState } from "../../states/formsState";
import { FormSubmissionsTimeline } from "./FormSubmissionsTimeline";
import { FormContentIds } from "../../types/form";

type Props = {
    formContentIds?: FormContentIds
}

export function FormSubmissions({formContentIds} : Props) {
    const submissions = useRecoilValue(formContentIds
        ? formSubmissionsByTemplateState(formContentIds)
        : formSubmissionsState).slice(0, 300);

    return (
        <FormSubmissionsTimeline submissions={submissions}/>
    );
}