import { DetailedLocation } from '../../types/core';
import { useRecoilState, useRecoilValue } from "recoil";
import { editingPlaceIdState } from "../../states/appState";
import { useDrawMode } from "../useDrawMode";
import { editPlaceState } from '../../states/editPlaceState';
import { Place } from '../../types/place';

export function useEditPlaceLocation() {
    const editingPlace = useRecoilValue(editingPlaceIdState);
    const [placeFields, setPlaceFields] = useRecoilState(editPlaceState(editingPlace || ""));
    const { drawMode, enterDrawMode } = useDrawMode();

    const location = drawMode?.location;

    const initEditPlace = () => {
        if (editingPlace && placeFields) {
            enterDrawMode({
                type: "place",
                action: "edit",
                location: {
                    address: placeFields.address,
                    lat: placeFields.latitude,
                    lng: placeFields.longitude,
                } as DetailedLocation
            })
        }
    }

    const editPlace = () => {
        if (location) {
            setPlaceFields((prev) => ({
                ...prev,
                address: location.address,
                location: {
                    lat: location?.lat || 0,
                    lng: location?.lng || 0,
                },
                latitude: location?.lat || 0,
                longitude: location?.lng || 0,
            }) as Place);
        }
        enterDrawMode({
            type: "place",
            action: "edit",
            location,
        });
    };

    return {
        initEditPlace,
        editPlace,
    };
}