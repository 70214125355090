import { useScript } from "usehooks-ts";

export function usePlacesAutocomplete() {
    const apiKey = "AIzaSyCTKCen8_IHL6jPC8oXTqPow_TS6rRP6Io";
    const scriptStatus = useScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`, {
        removeOnUnmount: false,
    });

    const retrievePredictions = async (query: string) => {
        if (query && scriptStatus === "ready") {
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            const request = {
                input: query,
            };
            const result = await autocompleteService.getPlacePredictions(request);
            return result.predictions;
        }
        return [];
    };

    const retrieveDetails = async (placeId: string): Promise<google.maps.places.PlaceResult> => {
        return new Promise((resolve, reject) => {
            const service = new google.maps.places.PlacesService(document.createElement('div'));
            service.getDetails({ placeId }, (result, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(result as google.maps.places.PlaceResult);
                } else {
                    reject(`Failed to retrieve place details: ${status}`);
                }
            });
        });
    }

    return { retrievePredictions, retrieveDetails };
}