import { ORIENTATION } from 'baseui/datepicker';
import { Tab, Tabs } from 'baseui/tabs-motion';
import * as React from "react";
import { useState } from "react";
import { tabOverridesVertical } from "../../../constants/ui";
import { useStrings } from "../../../hooks/useStrings";
import { Place } from "./Place";
import { useRecoilValue } from "recoil";
import { editingPlaceIdState } from "../../../states/appState";
import { ChecksTable } from "../../checks/ChecksTable";

type Keys = "general" | "checks";

export function PlaceTabs() {
    const { strings } = useStrings();
    const [activeTab, setActiveTab] = useState<Keys>("general");
    const uid = useRecoilValue(editingPlaceIdState);

    const tabOverrides = tabOverridesVertical;

    return (
        <Tabs
            onChange={({ activeKey }) => {
                setActiveTab(activeKey as Keys);
            }}
            orientation={ORIENTATION.vertical}
            activeKey={activeTab}
            activateOnFocus
            overrides={{
                Root: {
                    style: {
                        height: "70vh",
                    }
                }
            }}
        >
            <Tab
                key={"general" as Keys}
                title={strings.General.Info}
                overrides={tabOverrides}
            >
                <Place/>
            </Tab>
            <Tab
                key={"checks" as Keys}
                title={strings.Reports.CheckinsReport}
                overrides={tabOverrides}
            >
                {uid && <ChecksTable uid={uid} isPlace/>}
            </Tab>
        </Tabs>
    );
}
