import { LabelMedium, LabelXSmall } from "baseui/typography";
import { HoverInfo } from "./Map";
import { useTheme } from "../../hooks/useTheme";
import { useRecoilValue } from "recoil";
import { placeState } from "../../states/placeState";
import { HFlex } from "../containers/HFlex";
import { Avatar } from "baseui/avatar";
import { VFlex } from "../containers/VFlex";
import { LocationCell } from "../location/LocationCell";
import { TrackEntry, TrackOrStopEntry } from "../../types/track";
import { LocationRowDuration } from "../location/LocationRowDuration";
import { MemberBadge } from "../badges/MemberBadge";
import { useStrings } from "../../hooks/useStrings";
import { TrackInfoBubble } from "../ui/TrackInfoBubble";
import { RouteInfoBubble } from "../ui/RouteInfoBubble";
import { RouteInfo } from "../../types/job";
import { DateCell } from "../location/DateCell";

export function HoverFeatureTooltip({ hoverInfo }: { hoverInfo: HoverInfo }) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const props = hoverInfo?.feature?.properties;
    const place = useRecoilValue(placeState(props?.uid));

    if (!props) {
        return null;
    }
    const isRoute = !!props.routeTimeInSec;
    const routeContent = isRoute && <RouteInfoBubble route={props as RouteInfo}/>
    const isTrack = props.kind === "track";
    const trackContent = isTrack && <TrackInfoBubble track={props as TrackEntry}/>;
    const isStop = props.kind === "stop";
    const stopContent = isStop && (
        <VFlex
            spacing
            style={{
                marginLeft: "8px",
                marginRight: "8px",
                marginTop: "8px",
                marginBottom: "8px",
            }}>
            <LabelMedium>{strings.General.Stop}</LabelMedium>
            <DateCell track={props as TrackOrStopEntry}/>
            <LocationCell entry={props as TrackOrStopEntry}/>
            <LocationRowDuration entry={props as TrackOrStopEntry}/>
            <MemberBadge uid={props.account} edit/>
        </VFlex>
    );

    return (
        <div style={{
            left: hoverInfo.x,
            top: hoverInfo.y,
            position: 'absolute',
            margin: '8px',
            padding: '4px',
            background: theme.colors.backgroundSecondary,
            borderRadius: '4px',
            maxWidth: '300px',
            pointerEvents: 'none'
        }}>
            <HFlex spacing alignCenter>
                {place && <Avatar
                    name={place.name}
                    size="scale800"
                    overrides={{
                        Root: {
                            style: {
                                backgroundColor: place.color
                            }
                        }
                    }}
                />}
                <VFlex>
                    {isStop ? stopContent
                        : isTrack ? trackContent
                            : isRoute ? routeContent
                                : (
                                    <>
                                        {props.name && <LabelXSmall>{props.name}</LabelXSmall>}
                                        {props.title && <LabelXSmall>{props.title}</LabelXSmall>}
                                        {props.description &&
                                            <LabelXSmall color={theme.colors.contentTertiary}>
                                                {props.description}
                                            </LabelXSmall>
                                        }
                                    </>
                                )
                    }
                </VFlex>
            </HFlex>
        </div>
    );
}