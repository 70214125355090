import React from "react";
import { useTheme } from "../../hooks/useTheme";

export function Divider() {
    const { isDarkTheme } = useTheme();

    return (
        <div style={{
            width: "100%",
            height: "1px",
            backgroundColor: isDarkTheme ? "#FFFFFF11" : "#00000011",
            marginLeft: "10px",
            marginRight: "10px",
        }}/>
    )
}