import { LabelSmall } from "baseui/typography";
import { ReactNode } from "react";
import { useStrings } from "../../hooks/useStrings";
import { HFlex } from "../containers/HFlex";
import { ZoneBadge } from "./ZoneBadge";
import { useTheme } from "../../hooks/useTheme";
import { Uid } from "../../types/core";

type Props = {
    uids: Uid[];
    max?: number; closable?: boolean;
    badgeStyle?: any;
    children?: ReactNode;
}

export function ZonesBadges({
                                uids,
                                max = 10,
                                closable = false,
                                children = null,
                                badgeStyle = undefined,
                            }: Props) {
    const { strings } = useStrings();
    const maxSelectedVisible = max;
    const { theme } = useTheme();
    const selectedCount = uids.length;

    if (uids.length === 0) {
        return null;
    }

    return (
        <HFlex alignCenter style={{ width: "auto" }}>
            {uids.slice(0, maxSelectedVisible).map((uid, index) => (
                <ZoneBadge uid={uid} key={index} closable={closable} style={badgeStyle}/>
            ))}
            {selectedCount > maxSelectedVisible && (
                <LabelSmall alignSelf="center" color={theme.colors.contentTertiary}>
                    {`${selectedCount - maxSelectedVisible} ${strings.General.More.toLowerCase()}...`}
                </LabelSmall>
            )}
            {children}
        </HFlex>
    );
}