import { Checkbox } from 'baseui/checkbox';
import { StyledLink } from 'baseui/link';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useStyleOverrides } from '../../hooks/useStyleOverrides';
import { stringsState } from '../../states/localeState';
import { FormInput } from '../ui/FormInput';
import { editAccount, sendResetPasswordLinkTo } from "../../services/api";
import { isAdminOrOperator, Member } from "../../types/member";
import { useToast } from "../../hooks/useToast";
import { useState } from "react";
import { memberState } from "../../states/membersState";
import { ParagraphXSmall } from "baseui/typography";

type Props = {
    member: Member;
    emailIsVerified: boolean;
    phoneIsVerified: boolean;
};

export function CredentialFields({ member, emailIsVerified, phoneIsVerified }: Props) {
    const strings = useRecoilValue(stringsState);
    const { linkStyles, checkboxStyles } = useStyleOverrides();
    const toast = useToast();
    const [resetPasswordLinkSent, setResetPasswordLinkSent] = useState(false);
    const setMember = useSetRecoilState(memberState(member.uid));

    const onResetPasswordClick = async () => {
        const { status, email, phone } = await sendResetPasswordLinkTo(member.uid);
        if (status) {
            setResetPasswordLinkSent(true);
            toast.showSuccess(strings.ProfileModal.ResetPasswordLinkSent + " " + email + " " + phone);
        }
    };

    const showTwoFactorAuth = isAdminOrOperator(member);

    const on2FAChange = async (checked: boolean) => {
        if (checked && (!emailIsVerified || !phoneIsVerified)) {
            toast.showWarning(strings.Login.TwoFactorAuthenticationVerify);
            return;
        }
        const { status, account: updatedMember } = await editAccount({
            uid: member.uid,
            twofactor_auth_enabled: checked
        });
        if (status) {
            setMember(updatedMember as Member);
            if (checked) {
                toast.showSuccess(strings.Login.TwoFactorAuthenticationEnabled);
            } else {
                toast.showWarning(strings.Login.TwoFactorAuthenticationDisabled);
            }
        }
    };

    return (
        <>
            <FormInput
                label={strings.General.Username}
                value={member.username}
                field="readonly"
                caption={!resetPasswordLinkSent &&
                    <StyledLink className={linkStyles} onClick={onResetPasswordClick}>
                        {strings.General.ResetPasswordLink}
                    </StyledLink>
                }
            />
            {showTwoFactorAuth && (
                <>
                    <Checkbox checked={member.twofactor_auth_enabled}
                              overrides={checkboxStyles}
                              onChange={(ev) => on2FAChange(ev.target.checked)}>
                        {strings.General.TwoFactorAuth}
                    </Checkbox>
                    <ParagraphXSmall>{strings.Login.TwoFactorAuthenticationDesc}</ParagraphXSmall>
                </>
            )}
        </>
    );
}
