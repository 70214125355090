import { calculateRoute, CalculateRouteResponse, optimizeMultiRoute, optimizeRoute } from "../services/privateApi";
import { useRecoilValue } from "recoil";
import { dayState, routingRecentlyUnassignedState, routingUndoOptimizeState } from "../states/appState";
import { useStateUpdater } from "./useStateUpdater";
import { useDayRouteUpdater } from "./useDayRouteUpdater";
import { requestingState } from "../states/viewState";
import { editJobs } from "../services/api";
import { analyticsEvent } from "../utils/analytics";
import { getRecoil, setRecoil } from "../providers/RecoilAccessProvider";
import { Job } from "../types/job";
import { Constraints, Day, Uid } from "../types/core";

export function useRouting() {
    const day = useRecoilValue(dayState);
    const stateUpdater = useStateUpdater();
    const dayRouteUpdater = useDayRouteUpdater();

    const isRequesting = (memberUid: Uid) => {
        return getRecoil(requestingState({ req: "routecalc", uid: memberUid, day: day }));
    }

    const setRequesting = (memberUid: Uid, value: boolean) => {
        setRecoil(requestingState({ req: "routecalc", uid: memberUid, day: day }), value);
    }

    const setUndoOptimizeOrder = (memberUid: Uid, value: string[]) => {
        setRecoil(routingUndoOptimizeState({ uid: memberUid, day }), value)
    }

    const setRecentlyUnassigned = (memberUid: Uid, value: string[]) => {
        setRecoil(routingRecentlyUnassignedState({ uid: memberUid, day }), value)
    }

    const responseHandler = (memberUid: Uid, response: CalculateRouteResponse) => {
        setRequesting(memberUid, false);
        if (response.status) {
            if (response.original_order) {
                setUndoOptimizeOrder(memberUid, response.original_order);
            }
            if (response.unassigned) {
                setRecentlyUnassigned(memberUid, response.unassigned);
            }
            if (response.jobs) {
                stateUpdater.updateJobs(response.jobs);
            }
            if (response.route_updated) {
                dayRouteUpdater.update(memberUid, day);
            } else {
                dayRouteUpdater.unsuccessful(memberUid, day);
            }
        }
    };

    const calculate = (memberUid: Uid) => {
        analyticsEvent("routing_calc");
        setRequesting(memberUid, true);
        const request = {
            day: day,
            account: memberUid,
            clear_upfront: true,
            update_account: true,
        };
        calculateRoute(request).then(response => responseHandler(memberUid, response));
    };

    const optimize = (memberUid: Uid,
                      solve = false,
                      constraints?: Constraints,
                      customHandler: (response: CalculateRouteResponse) => void = () => {}) => {
        analyticsEvent("routing_optimize");
        setRequesting(memberUid, true);
        const request = { day: day, account: memberUid, clear_upfront: true, solve, constraints };
        optimizeRoute(request).then(response => {
            responseHandler(memberUid, response);
            customHandler && customHandler(response);
        });
    };

    const undo = async (memberUid: Uid) => {
        analyticsEvent("routing_undo");
        let data: any = {};
        let number = 1;
        const undoOptimizeOrder = getRecoil(routingUndoOptimizeState({ uid: memberUid, day }));
        for (const id of undoOptimizeOrder) {
            data[id] = { number };
            number += 1;
        }
        const { status, jobs } = await editJobs(data);
        if (status) {
            stateUpdater.updateJobs(jobs);
        }
        setUndoOptimizeOrder(memberUid, []);
        calculate(memberUid);
    };

    const multiRoute = async (uids: Uid[], jobs: string[], day: Day, constraints: Constraints) => {
        analyticsEvent("routing_multi");
        const response = await optimizeMultiRoute({ uids, jobs, day, constraints });
        const { status, routes, abortReason } = response;
        if (status) {
            let updatedJobs: Job[] = [];
            for (let route of routes) {
                updatedJobs.push(...route.jobs);
            }
            stateUpdater.updateJobs(updatedJobs);
            for (let route of routes) {
                await dayRouteUpdater.update(route.uid, day);
            }
        }
        return response;
    }

    return { isRequesting, calculate, optimize, undo, multiRoute, responseHandler }
}