import { useNavigate } from 'react-router-dom';
import { PageType } from '../types/core';


export function useNavigator() {
    const navigate = useNavigate();

    const navigateToPage = (page: PageType | string = "", replace = false) => {
        navigate("/" + page, { replace });
    }

    return {
        navigateToPage,
    };
}