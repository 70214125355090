import {Button} from "baseui/button";
import {useRecoilState, useRecoilValue} from "recoil";
import {useCompanyForms} from "../../../hooks/company/useCompanyForms";
import {useModal} from "../../../hooks/useModal";
import {useStyleOverrides} from "../../../hooks/useStyleOverrides";
import {unblockTsState} from "../../../states/accountState";
import {stringsState} from "../../../states/localeState";
import {SpacerExpand} from "../../containers/Spacer";
import {StyledModalContent} from "../../ui/StyledModalContent";
import {CompanyTabs} from "./CompanyTabs";
import useAccount from "../../../hooks/useAccount";
import {ActionButton} from "../../buttons/ActionButton";
import {analyticsEvent} from "../../../utils/analytics";
import useDirector from "../../../hooks/useDirector";
import {MINUTE} from "../../../utils/time";
import {showPaymentMethodState} from "../../../states/paymentState";


export function CompanyModalContent() {
    const strings = useRecoilValue(stringsState);
    const {closeModal} = useModal();
    const {onSubmit, isLoading, canSubmit, hasChanges} = useCompanyForms();
    const {modalCancelButtonStyles} = useStyleOverrides();
    const {isBlocked} = useAccount();
    const [unblockTs, setUnblockTs] = useRecoilState(unblockTsState);
    const director = useDirector();
    const showPayment = useRecoilValue(showPaymentMethodState);

    const onGain5MinAccessClick = () => {
        analyticsEvent("blocked_gain_5_min");
        setUnblockTs(Date.now() + (5 * MINUTE));
    };

    const onSignOut = () => {
        analyticsEvent("blocked_logout");
        director.logout();
    };

    return (
        <StyledModalContent
            header={showPayment ? strings.Billing.AddPaymentMethod : strings.Company.Company}
            body={<CompanyTabs/>}
            footer={!showPayment &&
                <>
                    {isBlocked && unblockTs === 0 && (
                        <ActionButton
                            tooltip={strings.Billing.Gain5MinTooltip}
                            onClick={onGain5MinAccessClick}
                            size='compact'
                            kind='secondary'
                            overrides={modalCancelButtonStyles}
                        >
                            {strings.Billing.Gain5Min}
                        </ActionButton>
                    )}
                    {isBlocked && unblockTs > 0 && (
                        <Button
                            onClick={onSignOut}
                            size='compact'
                            kind='secondary'
                            overrides={modalCancelButtonStyles}
                        >
                            {strings.General.SignOut}
                        </Button>
                    )}
                    <SpacerExpand/>
                    {hasChanges && (
                        <Button
                            onClick={closeModal}
                            size='compact'
                            kind='tertiary'
                            overrides={modalCancelButtonStyles}
                        >
                            {strings.General.Cancel}
                        </Button>
                    )}
                    <Button
                        onClick={hasChanges ? onSubmit : closeModal}
                        size='compact'
                        isLoading={isLoading}
                        disabled={!canSubmit && hasChanges}
                    >
                        {hasChanges ? strings.General.Save : strings.General.OK}
                    </Button>
                </>
            }
        />
    );
}