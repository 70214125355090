import { MenuItem, useProSidebar } from "react-pro-sidebar";
import { PageMenuItem } from "../../hooks/useMenu";
import { useRecoilValue } from "recoil";
import { pageState } from "../../states/appState";
import { forwardRef, memo } from "react";
import { useNavigator } from "../../hooks/useNavigator";
import { useCards } from "../skeleton/hooks/useCards";
import { Tooltip } from "antd";

type Props = {
    pageMenuItem: PageMenuItem;
    onClick?: () => void;
};

const SidebarItem = forwardRef<HTMLDivElement | null, Props>(({ pageMenuItem, onClick }, ref) => {
    const currentPage = useRecoilValue(pageState);
    const isActive = currentPage === pageMenuItem.page;
    const { navigateToPage } = useNavigator();
    const { toggleSidebar } = useProSidebar();
    const { resetCards } = useCards();

    return (
        <div ref={ref} onClick={onClick}>
            <Tooltip title={pageMenuItem.tooltip} placement={"right"}>
                <MenuItem
                    title={pageMenuItem.title}
                    icon={isActive ? pageMenuItem.activeIcon : pageMenuItem.icon}
                    active={isActive}
                    onClick={() => {
                        navigateToPage(pageMenuItem.page);
                        toggleSidebar(false);
                        if (currentPage === pageMenuItem.page) {
                            resetCards();
                        }
                    }}
                >
                    {pageMenuItem.title}
                </MenuItem>
            </Tooltip>
        </div>
    );
});

export default memo(SidebarItem);
