import { useRecoilValue } from 'recoil';
import { PAGE } from "../constants/pages";
import { PlacesAndZonesView } from "./PlacesAndZonesView";
import { pageState } from "../states/appState";
import { TeamView } from "./TeamView";
import { DispatchView } from './DispatchView';
import { ReportsView } from "./ReportsView";
import { FormView } from "./FormView";
import { TimesheetTable } from "../components/timesheet/TimesheetTable";
import { Statistics } from "../components/statistics/Statistics";
import { IntegrationList } from '../components/integrations/IntegrationList';
import { ChatView } from "./ChatView";
import { SettingsView } from "./SettingsView";
import { HelpView } from "./HelpView";
import { Uploads } from "../components/uploads/Uploads";
import { HistoryView } from './HistoryView';
import React, { memo } from 'react';
import { ActivityLog } from "../components/activitylog/ActivityLog";
import useAccount from "../hooks/useAccount";
import { isLocationOnlyPlan } from "../utils/account";
import { Upsell } from "../components/dispatch/Upsell";

function MediumCardContent() {
    const page = useRecoilValue(pageState);

    const { company } = useAccount();

    if ((page === PAGE.FORMS || page === PAGE.DISPATCH) && isLocationOnlyPlan(company?.stripe_plan)) {
        return <Upsell/>;
    }

    if (page === PAGE.PLACES) {
        return <PlacesAndZonesView/>
    }
    if (page === PAGE.TEAM) {
        return <TeamView/>;
    }
    if (page === PAGE.DISPATCH) {
        return <DispatchView/>;
    }
    if (page === PAGE.FORMS) {
        return <FormView/>
    }
    if (page === PAGE.REPORTS) {
        return <ReportsView/>;
    }
    if (page === PAGE.TIMESHEET) {
        return <TimesheetTable/>;
    }
    if (page === PAGE.MESSAGES) {
        return <ChatView/>;
    }
    if (page === PAGE.STATS) {
        return <Statistics/>;
    }
    if (page === PAGE.INTEGRATIONS) {
        return <IntegrationList/>;
    }
    if (page === PAGE.SETTINGS) {
        return <SettingsView/>;
    }
    if (page === PAGE.HELP) {
        return (
            <HelpView/>
        );
    }
    if (page === PAGE.UPLOADS) {
        return (
            <Uploads/>
        )
    }
    if (page === PAGE.LOCATION || page === PAGE.TIMELINE) {
        return <HistoryView/>;
    }
    if (page === PAGE.ACTIVITYLOG) {
        return <ActivityLog/>
    }
    return <></>;
}

export default memo(MediumCardContent);