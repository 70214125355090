import { Button } from 'baseui/button';
import { ParagraphXSmall } from 'baseui/typography';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    JOBS_LAYER,
    MAP_LAYERS,
    PLACES_LAYER,
    ROUTES_LAYER,
    TRAFFIC_LAYER,
    WAYPOINTS_LAYER,
    ZONES_LAYER,
} from '../../../constants/mapConstants';
import {
    showJobsLayerState,
    showPlacesLayerState,
    showRoutesLayerState,
    showTrafficLayerState,
    showWaypointsLayerState,
    showZonesLayerState,
} from '../../../states/mapState';
import { HFlex } from '../../containers/HFlex';
import { VFlex } from '../../containers/VFlex';
import { useTheme } from "../../../hooks/useTheme";
import { useStrings } from "../../../hooks/useStrings";
import { windowIsMobileState } from "../../../states/skeletonState";

export function LayersPicker() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const [showPlacesLayer, setShowPlacesLayer] = useRecoilState(showPlacesLayerState);
    const [showZonesLayer, setShowZonesLayer] = useRecoilState(showZonesLayerState);
    const [showTrafficLayer, setShowTrafficLayer] = useRecoilState(showTrafficLayerState);
    const [showJobsLayer, setShowJobsLayer] = useRecoilState(showJobsLayerState);
    const [showRoutesLayer, setShowRoutesLayer] = useRecoilState(showRoutesLayerState);
    const [showWaypointsLayer, setShowWaypointsLayer] = useRecoilState(showWaypointsLayerState);
    const isMobile = useRecoilValue(windowIsMobileState);

    const toggleLayer = (layerId: string) => {
        if (layerId === PLACES_LAYER) {
            setShowPlacesLayer((prev) => !prev);
        }
        if (layerId === ZONES_LAYER) {
            setShowZonesLayer((prev) => !prev);
        }
        if (layerId === TRAFFIC_LAYER) {
            setShowTrafficLayer((prev) => !prev);
        }
        if (layerId === JOBS_LAYER) {
            setShowJobsLayer((prev) => !prev);
        }
        if (layerId === ROUTES_LAYER) {
            setShowRoutesLayer((prev) => !prev);
        }
        if (layerId === WAYPOINTS_LAYER) {
            setShowWaypointsLayer((prev) => !prev)
        }
    };

    const layerIsEnabled = (layerId: string) => {
        if (layerId === PLACES_LAYER) {
            return showPlacesLayer;
        }
        if (layerId === ZONES_LAYER) {
            return showZonesLayer;
        }
        if (layerId === TRAFFIC_LAYER) {
            return showTrafficLayer;
        }
        if (layerId === JOBS_LAYER) {
            return showJobsLayer;
        }
        if (layerId === ROUTES_LAYER) {
            return showRoutesLayer;
        }
        if (layerId === WAYPOINTS_LAYER) {
            return showWaypointsLayer;
        }
    };

    return (
        <HFlex
            style={{
                justifyContent: 'space-between',
            }}
        >
            {MAP_LAYERS.map((layer) => {
                const Icon = layer.icon;
                const isEnabled = layerIsEnabled(layer.id);
                return (
                    <VFlex
                        key={layer.id}
                        alignCenter
                    >
                        <Button
                            onClick={() => toggleLayer(layer.id)}
                            size={isMobile ? "mini" : "compact"}
                            colors={{
                                color: '',
                                backgroundColor: isEnabled
                                    ? theme.colors.backgroundInversePrimary
                                    : theme.colors.backgroundTertiary,
                            }}
                        >
                            <Icon
                                size={28}
                                color={
                                    isEnabled
                                        ? theme.colors.primaryB
                                        : theme.colors.backgroundInversePrimary
                                }
                            />
                        </Button>
                        <ParagraphXSmall marginTop="scale200" marginBottom={0}>
                            {strings.getString(layer.labelKey)}
                        </ParagraphXSmall>
                    </VFlex>
                );
            })}
        </HFlex>
    );
}
