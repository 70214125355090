import { useRecoilValue } from "recoil";
import { companyState } from "../states/accountState";
import { stringsState } from "../states/localeState";
import { memberNamesState } from "../states/membersState";
import { teamsState } from "../states/teamsState";
import { allZonesState } from "../states/zoneState";

export function useImportOptions() {
    const strings = useRecoilValue(stringsState);
    const teamOptions = useRecoilValue(teamsState).map((team) => team.name);
    const memberOptions = useRecoilValue(memberNamesState).map((uidName) => uidName.name);
    const company = useRecoilValue(companyState);
    const zoneOptions = useRecoilValue(allZonesState).map((zone) => zone.name);
    const carryOverOptions = [strings.General.Yes, strings.General.No];
    const autoAssignOptions = [strings.General.Yes, strings.General.No];
    const priorityOptions = [strings.Job.Low, strings.Job.Normal, strings.Job.High];
    const jobTypeDefaultOptions = [strings.Job.JobTypeWork, strings.Job.JobTypePickup, strings.Job.JobTypeDropoff];
    const customJobTypeOptions = company ? company.job_custom_types.map((jobType) => jobType.name) : [];
    const jobTypeOptions = [...jobTypeDefaultOptions, ...customJobTypeOptions];
    const roleOptions = [strings.General.Admin, strings.General.Operator, strings.ProfileModal.Worker];

    return {
        teamOptions,
        memberOptions,
        jobTypeOptions,
        priorityOptions,
        zoneOptions,
        carryOverOptions,
        autoAssignOptions,
        roleOptions,
    };
}
