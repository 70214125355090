import { memberState } from "../../../states/membersState";
import { useEffect, useState } from "react";
import { Layer, Source } from "react-map-gl";
import { waypointsLayerState } from "../../../states/geojsonState";
import { useRecoilValue } from "recoil";
import { useWaypointsLayerStyle } from "../mapStyle";
import { updateWaypointsLayer } from "../../../services/geojson";
import { useTimeframeFilter } from "../../../hooks/useTimeframeFilter";
import { Uid } from "../../../types/core";

export function LiveWaypointsLayer({ uid }: { uid: Uid }) {
    const ID = "live-waypoints";
    const layer = useRecoilValue(waypointsLayerState({ uid }));
    const { waypointsLayerStyle } = useWaypointsLayerStyle(ID);
    const { timeframeFilter } = useTimeframeFilter();
    const member = useRecoilValue(memberState(uid));
    const [lastRequest, setLastRequest] = useState(0);

    useEffect(() => {
        if (Date.now() - lastRequest > 10_000) {
            updateWaypointsLayer(uid);
            setLastRequest(Date.now());
        }
    }, [member]);

    const layerData = layer || { type: "FeatureCollection", features: [] } as GeoJSON.FeatureCollection;
    const filter = timeframeFilter || ["==", "$type", "Point"];

    return (
        <Source id={ID} type="geojson" data={layerData}>
            <Layer {...waypointsLayerStyle} filter={filter}/>
        </Source>
    );
}