import { Button } from "baseui/button";
import { Input } from "baseui/input";
import { ParagraphSmall } from "baseui/typography";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { useAbortController } from "../../../hooks/useAbortController";
import { stringsState } from "../../../states/localeState";
import { getFileExtension, isValidImage, isValidPDF } from "../../../utils/app";
import { HFlex } from "../../containers/HFlex";
import { AttachmentList } from "../../shared/AttachmentList";
import { ImageUploader } from "../../ui/ImageUploader";
import { uploadJobAttachment } from "../../../services/upload";
import { VFlex } from "../../containers/VFlex";
import { useTheme } from "../../../hooks/useTheme";
import { PDFUploader } from "../../ui/PDFUploader";
import { useCompanyDefaults } from "../../../hooks/company/useCompanyDefaults";
import { BookmarkAddIcon } from "../../ui/svg";

type Props = {
    attachments: { key: string; val: string; }[];
    onNewAttachment: (url: string) => void;
    onRemoveAttachment: (key: string) => void;
    disabled: boolean;
}

export function JobAttachments({ attachments, onNewAttachment, onRemoveAttachment, disabled }: Props) {
    const { theme } = useTheme();
    const strings = useRecoilValue(stringsState);
    const [newAttachment, setNewAttachment] = useState('');
    const [loadingNewImage, setLoadingNewImage] = useState(false);
    const [loadingNewPDF, setLoadingNewPDF] = useState(false);
    const { controller } = useAbortController();
    const { setAttachmentsDefault } = useCompanyDefaults();

    const handleNewAttachment = () => {
        onNewAttachment(newAttachment);
        setNewAttachment('');
    }

    const uploadAttachmentImage = async (file: File) => {
        setLoadingNewImage(true);
        const fileExtension = getFileExtension(file.name);

        if (isValidImage(file.name)) {
            const { status, url } = await uploadJobAttachment({ file: file, extension: fileExtension, controller });
            if (status && url) {
                onNewAttachment(url);
            }
        }
        setLoadingNewImage(false);
    };

    const uploadAttachmentPDF = async (file: File) => {
        setLoadingNewPDF(true);
        const fileExtension = getFileExtension(file.name);

        if (isValidPDF(file.name)) {
            const { status, url } = await uploadJobAttachment({ file: file, extension: fileExtension, controller });
            if (status && url) {
                onNewAttachment(url);
            }
        }
        setLoadingNewPDF(false);
    };

    return (
        <>
            <ParagraphSmall marginTop={theme.sizing.scale300}>{strings.Attachments.AttachmentsDesc}</ParagraphSmall>
            <AttachmentList attachments={attachments} onRemove={onRemoveAttachment}/>
            <HFlex style={{
                       marginBottom: theme.sizing.scale400,
                   }}
            >
                <Button
                    onClick={() => setAttachmentsDefault(attachments.map((attachment) => attachment.val))}
                    kind="tertiary"
                    size="mini"
                    style={{
                        width: "100%",
                    }}
                >
                    <HFlex spacing alignCenter style={{ justifyContent: "center" }}>
                        <BookmarkAddIcon size={12} color={theme.colors.contentPrimary}/>
                        {strings.Attachments.SaveTemplate}
                    </HFlex>
                </Button>
            </HFlex>
            {!disabled && (
                <VFlex spacing>
                    <HFlex spacing>
                        <Input
                            startEnhancer={strings.Attachments.Attachment}
                            value={newAttachment}
                            onChange={(event) => setNewAttachment(event.target.value)}
                            size="compact"
                            placeholder={"https://url.com/file.pdf"}
                        />
                        <Button onClick={handleNewAttachment} disabled={!newAttachment} size="compact">
                            {strings.General.Add}
                        </Button>
                    </HFlex>
                    <HFlex spacing>
                        <ImageUploader
                            onValid={uploadAttachmentImage}
                            isLoading={loadingNewImage}
                            styles={{ flex: 1 }}
                        />
                        <PDFUploader 
                            onValid={uploadAttachmentPDF}
                            isLoading={loadingNewPDF}
                            styles={{ flex: 1 }}
                        />
                    </HFlex>
                </VFlex>
            )}
        </>
    );
}
