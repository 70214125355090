import { Button } from "baseui/button";
import { Datepicker } from "baseui/datepicker";
import { arrayRemove } from "baseui/dnd-list";
import { HeadingXSmall, LabelXSmall } from "baseui/typography";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useSequenceSaver } from "../../../hooks/jobs/useSequenceSaver";
import { useStrings } from "../../../hooks/useStrings";
import { useStyleOverrides } from "../../../hooks/useStyleOverrides";
import { useTheme } from "../../../hooks/useTheme";
import { useTime } from "../../../hooks/useTime";
import { useWeekDaysEncoder } from "../../../hooks/useWeekDaysEncoder";
import { memberState } from "../../../states/membersState";
import { sequenceState } from "../../../states/sequencesState";
import { DayKey } from "../../../types/days";
import { Member } from "../../../types/member";
import { SavableSequence } from "../../../types/sequence";
import { getDatesBetween } from "../../../utils/time";
import { HFlex } from "../../containers/HFlex";
import { Spacer, SpacerExpand } from "../../containers/Spacer";
import { DaysPicker } from "../../pickers/DaysPicker";
import { MemberSelect } from "../../pickers/MemberSelect";
import { StyledModalContent } from "../../ui/StyledModalContent";
import { RemoveIcon } from "../../ui/svg";
import { StatefulPanel } from "baseui/accordion";
import { UnorderedList } from "../../containers/UnorderedList";
import { Day, EMPTY_UID } from "../../../types/core";

export type SequenceAssignType = "day" | "member";

type Props = {
    id: string;
    type?: SequenceAssignType;
    closeModal: () => void;
};

export function SequenceAssignModalContent({ id, type, closeModal }: Props) {
    const { strings, format } = useStrings();
    const [sequence, setSequence] = useRecoilState(sequenceState(id));
    const assignedMember = useRecoilValue(memberState(sequence?.assignToUid || EMPTY_UID));
    const { theme } = useTheme();
    const { cancelButtonStyles } = useStyleOverrides();
    const { formatDay, dateFormat } = useTime();
    const { calendarDays, selectedWeekDays, weekDaysFromSelectedDays } = useWeekDaysEncoder();
    const { updateSequence } = useSequenceSaver();
    const [days, setDays] = useState(selectedWeekDays(sequence?.assignWeekDays));
    const [selectedCalendarDays, setSelectedCalendarDays] = useState(calendarDays(sequence?.assignCalendarDays || ""));
    const [selectedMember, setSelectedMember] = useState<Member | undefined>(assignedMember);
    const [selectedDates, setSelectedDates] = useState<(Date | null | undefined)[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    if (!sequence) {
        return null;
    }

    const title = format(strings.Sequences.AssignSequenceX, sequence.name);

    const hasSelectedCalendarDays = !!selectedCalendarDays.length;
    const handleDayCheck = (day: DayKey) => {
        setDays((prev) => ({
            ...prev,
            [day]: !prev[day],
        }));
    };

    const validDateRange = selectedDates.length === 2 && selectedDates[0] && selectedDates[1];

    const handleAccept = async () => {
        setIsLoading(true);
        const newSequence = {
            ...sequence,
            jobs: sequence.jobs.map((job) => job.id),
            assignToUid: type === "member" ? selectedMember?.uid || "" : undefined,
            assignWeekDays: type !== "member" ? weekDaysFromSelectedDays(days) : undefined,
            assignCalendarDays: type !== "member" ? selectedCalendarDays.join(",") : undefined,
        } as SavableSequence;
        await updateSequence(newSequence, setSequence);
        setIsLoading(false);
        closeModal();
    };

    return (
        <StyledModalContent
            header={title}
            body={
                <>
                    {type !== "day" && (
                        <MemberSelect
                            onMemberSelect={(member: Member) => {
                                setSelectedMember(member);
                            }}
                            placeholder={strings.Members.SelectMember}
                            selectedMember={selectedMember}
                            avatar={false}
                        />
                    )}
                    {type !== "member" && (
                        <>
                            <HeadingXSmall marginTop={theme.sizing.scale300} marginBottom={theme.sizing.scale300}>
                                {strings.General.Every}
                            </HeadingXSmall>
                            <DaysPicker days={days} onCheck={handleDayCheck}/>
                            <Spacer/>
                            <StatefulPanel
                                initialState={{ expanded: hasSelectedCalendarDays }}
                                title={strings.General.AssignOnSpecificDays}
                                overrides={{
                                    Header: {
                                        style: {
                                            paddingLeft: 0,
                                            paddingRight: theme.sizing.scale300,
                                            marginTop: theme.sizing.scale300,
                                            paddingTop: theme.sizing.scale300,
                                            paddingBottom: theme.sizing.scale300,
                                        },
                                    },
                                    PanelContainer: {
                                        style: {
                                            borderBottomColor: "transparent",
                                        },
                                    },
                                    Content: {
                                        style: {
                                            display: "flex",
                                            flexDirection: "column",
                                            rowGap: theme.sizing.scale300,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                        },
                                    },
                                }}
                            >
                                {hasSelectedCalendarDays && (
                                    <HFlex style={{ alignItems: "start"}}>
                                        <UnorderedList 
                                            style={{
                                                maxHeight: "200px",
                                            }}
                                        >
                                            {selectedCalendarDays.map((day, index) => (
                                                <HFlex key={index} alignCenter>
                                                    <SpacerExpand/>
                                                    <LabelXSmall>
                                                        {formatDay(Number(day) as Day)}
                                                    </LabelXSmall>
                                                    <Button
                                                        size="mini"
                                                        kind="tertiary"
                                                        shape="circle"
                                                        onClick={() => {
                                                            const newSelectedCalendarDays = arrayRemove(
                                                                selectedCalendarDays,
                                                                index
                                                            );
                                                            setSelectedCalendarDays(newSelectedCalendarDays);
                                                        }}
                                                    >
                                                        <RemoveIcon size={14} color={theme.colors.contentPrimary}/>
                                                    </Button>
                                                    <SpacerExpand/>
                                                </HFlex>
                                            ))}
                                        </UnorderedList>
                                        {hasSelectedCalendarDays && (
                                            <Button
                                                size="mini"
                                                kind="tertiary"
                                                onClick={() => setSelectedCalendarDays([])}
                                            >
                                                {strings.General.Clear}
                                            </Button>
                                        )}
                                    </HFlex>
                                )}
                                <HFlex spacing>
                                    <Datepicker
                                        value={selectedDates}
                                        range
                                        size="compact"
                                        startDateLabel={""}
                                        endDateLabel={""}
                                        formatString={dateFormat}
                                        placeholder={strings.General.ChooseDateRange}
                                        onChange={({ date }) => {
                                            if (Array.isArray(date)) {
                                                setSelectedDates(date);
                                            }
                                        }}

                                    />
                                    <Button
                                        size="mini"
                                        kind="primary"
                                        disabled={!validDateRange}
                                        onClick={() => {
                                            if (validDateRange) {
                                                const newDates = getDatesBetween(
                                                    selectedDates[0] as Date,
                                                    selectedDates[1] as Date
                                                );
                                                setSelectedCalendarDays((prev) =>
                                                    Array.from(new Set([...prev, ...newDates]))
                                                );
                                                setSelectedDates([]);
                                            }
                                        }}
                                    >
                                        {strings.General.Add}
                                    </Button>
                                </HFlex>
                            </StatefulPanel>
                        </>
                    )}
                </>
            }
            footer={
                <>
                    <SpacerExpand/>
                    <Button onClick={closeModal} size="compact" kind="tertiary" overrides={cancelButtonStyles}>
                        {strings.General.Cancel}
                    </Button>
                    <Button onClick={handleAccept} size="compact" isLoading={isLoading}>
                        {strings.General.Accept}
                    </Button>
                </>
            }
        />
    );
}
