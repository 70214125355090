import { StyledHead, StyledHeadCell } from "baseui/table";
import { useStrings } from "../../hooks/useStrings";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";

export function ActivityLogHeaderRow() {
    const { strings } = useStrings();
    const { activityLogColumnStyle } = useStyleOverrides();

    return (
        <StyledHead role="row">
            <StyledHeadCell
                $style={activityLogColumnStyle[0]}>
                {strings.General.Member}
            </StyledHeadCell>
            <StyledHeadCell
                $style={activityLogColumnStyle[1]}>
                {strings.General.Time}
            </StyledHeadCell>
            <StyledHeadCell
                $style={activityLogColumnStyle[2]}>
                {strings.Activities.Activity}
            </StyledHeadCell>
        </StyledHead>
    );
}