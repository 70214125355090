import { atomWithIndexedPersistence, idbStorageEffect } from './../utils/persistence';
import { Message } from "../types/message";
import { atom, atomFamily, selector } from "recoil";
import { memberState } from "./membersState";
import { Member } from "../types/member";
import { EMPTY_UID, LatLng, Uid } from "../types/core";

export const messagesInboxState = atomWithIndexedPersistence("messages.inbox", [] as Message[]);

export const conversationState = atomFamily<Message[], string>({
    key: 'messages.conversation',
    default: [] as Message[],
    effects: [idbStorageEffect],
});

export const selectedConversationState = atom<Uid>({ key: "messages.inbox.selected.uid", default: EMPTY_UID });

export const selectedConversationMemberState = selector<Member | undefined>({
    key: "messages.inbox.selected.member",
    get: ({ get }) => {
        const uid = get(selectedConversationState);
        return uid ? get(memberState(uid)) : undefined;
    }
});

export const attachedLocationState = atom<LatLng | undefined>({
    key: "messages.location.attached",
    default: undefined,
});

export const newMessagesCountState = selector({
    key: "messages.inbox.unread.count",
    get: ({ get }) => {
        return get(messagesInboxState).filter(m => m.unread).length;
    }
});

export const newMessageEventState = atom<undefined | Message>({ key: 'messages.inbox.event', default: undefined });