import { CSV_EXTENSION, EXCEL_EXTENSION } from "./app";

export enum MembersImportDefaultFields {
    "Name",
    "Email",
    "Phone",
    "Role",
}

export enum MembersImportAdvancedFields {
    "Password",
}

export const IMPORT_MEMBER_FILE_FORMATS = [CSV_EXTENSION, EXCEL_EXTENSION];

export const MEMBERS_NAME_COLUMN = "name";
export const MEMBERS_STATUS_COLUMN = "status";
export const MEMBERS_ROLE_COLUMN = "role";
export const MEMBERS_TEAMS_COLUMN = "teams";
export const MEMBERS_LAST_LOCATION_COLUMN = "lastLocation";
export const MEMBERS_APP_COLUMN = "app";
export const MEMBERS_OPTIONS_COLUMN = "options";

export const MEMBERS_DEFAULT_COLUMNS = [
    MEMBERS_STATUS_COLUMN,
    MEMBERS_ROLE_COLUMN,
    MEMBERS_TEAMS_COLUMN,
    MEMBERS_LAST_LOCATION_COLUMN,
    MEMBERS_APP_COLUMN,
];