import { TourProps } from "antd";
import { flushSync } from "react-dom";
import { useSetRecoilState } from "recoil";
import { PAGE } from "../../constants/pages";
import { sidebarManageOpenState, sidebarPinnedState } from "../../states/appState";
import { useNavigator } from "../useNavigator";
import { useOnboardRefs } from "./useOnboardRefs";
import { useOnboardStep } from "./useOnboardStep";
import { useStrings } from "../useStrings";
import { ONBOARD_CREATING_PLACES, TOUR_DELAY } from "../../constants/onboard";

export function useOnboardPlaces() {
    const { strings } = useStrings();
    const { navigateToPage } = useNavigator();
    const { placesRef, importPlacesRef, addPlaceRef } = useOnboardRefs();
    const {
        step,
        nextStep,
        prevStep,
        closeStep,
        openStep,
        isOpen,
        canTrigger
    } = useOnboardStep(ONBOARD_CREATING_PLACES);
    const setSetupOpen = useSetRecoilState(sidebarManageOpenState);
    const setSidebarPinned = useSetRecoilState(sidebarPinnedState);
    const placesSteps: TourProps["steps"] = [
        {
            title: strings.Onboard.PlacesTitle,
            description: strings.Onboard.PlacesDescription,
            target: placesRef.current as HTMLElement,
            placement: "right",
            onNext: () => {
                flushSync(() => {
                    navigateToPage(PAGE.PLACES);
                });
                setTimeout(nextStep, TOUR_DELAY);
            },
            onClose: closeStep,
        },
        {
            title: strings.Onboard.ImportPlacesTitle,
            description: strings.Onboard.ImportPlacesDescription,
            target: importPlacesRef.current as HTMLElement,
            placement: "bottom",
            onNext: nextStep,
            onPrev: prevStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.AddPlaceTitle,
            description: strings.Onboard.AddPlaceDescription,
            target: addPlaceRef.current as HTMLElement,
            placement: "bottom",
            onFinish: () => {
                finishOnboardPlaces();
            },
            onPrev: prevStep,
            onClose: closeStep,
        },
    ];

    const finishOnboardPlaces = () => {
        closeStep();
    };

    const initOnboardPlaces = () => {
        flushSync(() => {
            setSetupOpen(true);
            setSidebarPinned(true);
        });
        setTimeout(openStep, TOUR_DELAY);
    };

    const voidFn = () => {};

    return {
        placesSteps,
        initOnboardPlaces,
        onboardPlacesIsOpen: isOpen,
        placeStep: step,
        placesPrevStep: canTrigger ? prevStep : voidFn,
        placesNextStep: canTrigger ? nextStep : voidFn,
        finishOnboardPlaces: canTrigger ? finishOnboardPlaces : voidFn,
    };
}
