import { LabelSmall, ParagraphSmall } from "baseui/typography";
import { useTheme } from "../../hooks/useTheme";
import { HFlex } from "../containers/HFlex";
import { VFlex } from "../containers/VFlex";

type Props = {
    label: string;
    labelWidth?: number;
    content: React.ReactNode | string;
    direction?: "horizontal" | "vertical";
    verticalAlign?: "baseline" | "center";
};

export function LabeledField({ label, labelWidth = 100, content, direction = "vertical", verticalAlign = "baseline" }: Props) {
    const { theme } = useTheme();

    const styles = {
        marginBottom: theme.sizing.scale550,
    }

    const info = (
        <HFlex alignCenter spacing>
            <LabelSmall width={labelWidth + "px"}>{label}:</LabelSmall>
            {typeof content === "string" && <ParagraphSmall margin={0}>{content}</ParagraphSmall>}
            {typeof content !== "string" && content}
        </HFlex>
    );

    if (direction === "horizontal") {
        return (
            <HFlex spacing={theme.sizing.scale300} style={{ alignItems: verticalAlign, ...styles }}>
                {info}
            </HFlex>
        );
    }

    return <VFlex style={{ height: "auto", ...styles }}>{info}</VFlex>;
}
