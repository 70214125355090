import { useRecoilState, useRecoilValue } from "recoil";
import { editingJobIdState } from "../../../states/appState";
import { editJobState } from "../../../states/editJobState";
import { useStrings } from "../../../hooks/useStrings";
import { useTheme } from "../../../hooks/useTheme";
import { jobCompactVisualizationState } from "../../../states/optionsState";
import { getLabels, Job } from "../../../types/job";
import { Tag } from "antd";
import { MessageBubble } from "../../salesai/MessageBubble";
import { VFlex } from "../../containers/VFlex";
import { HFlex } from "../../containers/HFlex";
import { Spacer, SpacerExpand } from "../../containers/Spacer";
import { Button } from "baseui/button";
import { LabelIcon, PlusIcon, RemoveIcon } from "../../ui/svg";
import { Section } from "../../ui/Section";
import React, { useEffect, useState } from "react";
import { uuid } from "../../../utils/uuid";
import { ColorPicker } from "../../pickers/ColorPicker";
import { JobLabelBadge } from "../../badges/JobLabelBadge";
import { Label } from "../../../types/core";
import { Select } from "baseui/select";
import useAccount from "../../../hooks/useAccount";
import { COLOR_ACCENT } from "../../../constants/colors";


export function LabelsSection() {
    const jobId = useRecoilValue(editingJobIdState);
    const [fields, setFields] = useRecoilState(editJobState(jobId || ''));
    const { strings } = useStrings();
    const { theme } = useTheme();
    const compact = useRecoilValue(jobCompactVisualizationState);
    const [addingLabel, setAddingLabel] = useState(false);
    const { company } = useAccount();
    const [selected, setSelected] = useState<Label>();

    useEffect(() => {
        onOkClick();
    }, [selected]);

    if (!fields) {
        return null;
    }

    const labels = getLabels(fields);

    const onOkClick = () => {
        if (!selected) {
            return;
        }
        const newLabel: Label = {
            id: selected.id === selected.text ? uuid() : selected.id,
            text: selected.text,
            color: selected.color || COLOR_ACCENT,
        };
        const stringified = JSON.stringify([...labels, newLabel]);
        setFields((prev) => ({ ...prev, labels: stringified } as Job));
        setSelected(undefined);
    };

    const onDeleteClick = (label: Label) => {
        const stringified = JSON.stringify([...labels.filter(l => l.id !== label.id)]);
        setFields((prev) => ({ ...prev, labels: stringified } as Job));
    };

    const onColorChange = (label: Label, color: string) => {
        const index = labels.indexOf(label);
        if (index !== -1) {
            const newLabel: Label = { ...label, color };
            const updatedLabels = [...labels];
            updatedLabels[index] = newLabel;
            const stringified = JSON.stringify(updatedLabels);
            setFields((prev) => ({ ...prev, labels: stringified } as Job));
        }
    }

    return (
        <Section title={strings.Job.Labels}
                 expandable={compact}
                 collapsedNode={<HFlex>{labels && labels.map(l => <JobLabelBadge label={l}/>)}</HFlex>}
        >
            {labels.map((label, index) => (
                <MessageBubble key={index} style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                }}>
                    <VFlex spacing>
                        <HFlex spacing alignCenter>
                            <LabelIcon size={24} color={label.color}/>
                            <Tag color={label.color}>{label.text}</Tag>
                            <Spacer width={"32px"}/>
                            <ColorPicker onChange={(color) => onColorChange(label, color)}
                                         selectedColor={label.color}
                            />
                            <SpacerExpand/>
                            <Button kind={"tertiary"}
                                    onClick={() => onDeleteClick(label)}
                            >
                                <RemoveIcon color={theme.colors.contentPrimary} size={14}/>
                            </Button>
                        </HFlex>
                    </VFlex>
                </MessageBubble>
            ))}
            {!addingLabel &&
                <Button kind={"secondary"}
                        size={"mini"}
                        onClick={() => setAddingLabel(true)}>
                    <PlusIcon size={12} color={theme.colors.contentPrimary}/>
                    <Spacer/>
                    {strings.Job.AddLabel}
                </Button>
            }
            {addingLabel &&
                <HFlex spacing style={{ marginBottom: "8px" }}>
                    <Select
                        creatable
                        options={company?.recent_job_labels.filter(l => !labels.find(l2 => l2.id === l.id))}
                        labelKey="text"
                        valueKey="id"
                        onChange={({ value }) => setSelected(value.length > 0 ? value[0] as Label : undefined)}
                        value={selected ? [selected] : []}
                    />
                </HFlex>
            }
        </Section>
    );
}