import { Button } from "baseui/button";
import { Plus } from "baseui/icon";
import { Input } from "baseui/input";
import { StatefulPopover } from "baseui/popover";
import jspreadsheet, { Options } from "jspreadsheet-ce";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { SpreadsheetElement, SpreadsheetRef } from "../../types/spreadsheets";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { ConfirmModal } from "../ui/ConfirmModal";
import "jspreadsheet-ce/dist/jspreadsheet.css";
import "jspreadsheet-ce/dist/jspreadsheet.theme.css";
import "jsuites/dist/jsuites.css";
import { StyledMenu } from "../ui/StyledMenu";

type Props = {
    options: Options;
    maxHeight?: number;
    advancedFields?: string[];
    onAddAdvancedField?: (field: string) => void;
    onAddCustomField?: (field: string) => void;
};

const SpreadSheet = forwardRef<SpreadsheetElement, Props>(
    ({ options, maxHeight, advancedFields, onAddAdvancedField, onAddCustomField }, ref) => {
        const { css } = useTheme();
        const { strings } = useStrings();
        const sheetRef = useRef() as SpreadsheetRef;
        useImperativeHandle(ref, () => sheetRef.current);

        const advancedFieldOptions = advancedFields?.map((field) => ({ id: field, label: field }));
        const [newFieldIsOpen, setNewFieldIsOpen] = useState(false);
        const [newField, setNewField] = useState("");
        useEffect(() => {
            if (sheetRef === null) {
                return;
            }
            const current = sheetRef.current;
            if (!current || !current.jspreadsheet) {
                jspreadsheet(sheetRef.current, options);
            } else {
                current.jspreadsheet.destroy();
                jspreadsheet(sheetRef.current, options);
            }
        }, [options]);

        const closeNewField = () => {
            setNewField("");
            setNewFieldIsOpen(false);
        };

        const handleAddCustomField = () => {
            if (onAddCustomField) {
                onAddCustomField(newField);
            }
            closeNewField();
        };

        const addRow = () => {
            if (!sheetRef.current.jspreadsheet) {
                return;
            }
            const {
                jspreadsheet: { insertRow },
            } = sheetRef.current;
            if (insertRow) {
                insertRow();
            }
        };

        return (
            <>
                <HFlex spacing>
                    {advancedFieldOptions && (
                        <StatefulPopover
                            placement={"bottomLeft"}
                            content={({ close }) => (
                                <StyledMenu
                                    items={advancedFieldOptions}
                                    onItemSelect={(opt) => {
                                        if (onAddAdvancedField) {
                                            onAddAdvancedField(opt.item.id);
                                        }
                                        close();
                                    }}
                                />
                            )}
                        >
                            <Button kind={"secondary"} size={"mini"}>
                                {strings.Spreadsheet.AddAdvancedField}
                            </Button>
                        </StatefulPopover>
                    )}
                    <Button onClick={() => setNewFieldIsOpen(true)} size="mini" kind="secondary">
                        {strings.Spreadsheet.AddCustomFieldColumn}
                    </Button>
                    <SpacerExpand/>
                    <Button onClick={addRow} size="mini" startEnhancer={<Plus/>}>
                        {strings.Spreadsheet.AddRow}
                    </Button>
                </HFlex>
                <div
                    className={css({
                        overflow: "auto",
                        width: "100%",
                        maxHeight: maxHeight ? `${maxHeight}vh` : undefined,
                        position: "relative",
                        minHeight: "20vh",
                    })}
                >
                    <div ref={sheetRef}/>
                </div>
                {createPortal(
                    <ConfirmModal
                        title={strings.Spreadsheet.ChooseCustomFieldName}
                        description={
                            <Input
                                value={newField}
                                onChange={(event) => setNewField(event.target.value)}
                                size="compact"
                                placeholder={strings.Spreadsheet.CustomFieldName}
                            />
                        }
                        isOpen={newFieldIsOpen}
                        onConfirm={async () => handleAddCustomField()}
                        onCancel={closeNewField}
                        cancelText={strings.General.Cancel}
                        confirmText={strings.General.Accept}
                        canConfirm={!!newField}
                    />,
                    document.body
                )}
            </>
        );
    }
);

export default SpreadSheet;
