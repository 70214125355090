import React, { memo } from "react";
import { useBoard } from "../../hooks/useBoard";
import { RouteColumn } from "./routes/RouteColumn";
import { SequenceColumn } from "./sequences/SequenceColumn";
import { Board } from "../board/Board";
import { PinnedJobsColumn } from "./pinned/PinnedJobsColumn";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { allJobsActiveTabState, dispatchTreeActiveCardState, expandedState } from "../../states/viewState";
import { AllJobsGrid } from "../alljobs/AllJobsGrid";
import { ColumnCard } from "../board/ColumnCard";
import { useStrings } from "../../hooks/useStrings";
import { AllJobsView } from "../../views/AllJobsView";
import { JobTemplatesGrid } from "../alljobs/JobTemplatesGrid";
import { HFlex } from "../containers/HFlex";
import { Switch } from "antd";
import { SpacerExpand } from "../containers/Spacer";
import { importTypeState } from "../../states/appState";
import { Button } from "baseui/button";
import { RemoveIcon } from "../ui/svg";
import { HoverButton } from "../buttons/HoverButton";
import { useTheme } from "../../hooks/useTheme";
import { NoWrapText } from "../containers/NoWrapText";
import { windowIsDesktopState, windowIsMobileState } from "../../states/skeletonState";
import { Plus } from "baseui/icon";
import { useNewJob } from "../../hooks/jobs/useNewJob";
import { useOnboardRefs } from "../../hooks/onboard/useOnboardRefs";
import { useOnboardJobs } from "../../hooks/onboard/useOnboardJobs";
import { useListItem } from "../../hooks/useListItem";
import { Droppable } from "react-beautiful-dnd";
import { ClusterColumn } from "./clustering/ClusterColumn";
import { UnassignedAllOrSelected } from "./UnassignedAllOrSelected";
import { DROPPABLE_TYPE_JOB, DROPPABLE_UNASSIGNEDLIST } from "../../constants/dnd";
import { MultiRouteColumn } from "./routing/MultiRouteColumn";

export const DispatchBoard = memo(function DispatchBoardInternal() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const board = useBoard();
    const dispatchCard = useRecoilValue(dispatchTreeActiveCardState);
    const resetDispatchCard = useResetRecoilState(dispatchTreeActiveCardState);
    const [allJobsActiveTab, setAllJobsActiveTab] = useRecoilState(allJobsActiveTabState);
    const setImportType = useSetRecoilState(importTypeState);
    const expandedBulkEdit = useRecoilValue(expandedState("dispatch-bulk"));
    const isDesktop = useRecoilValue(windowIsDesktopState);
    const isMobile = useRecoilValue(windowIsMobileState);
    const { initNewJob } = useNewJob();
    const { importJobsRef, addJobRef } = useOnboardRefs();
    const { finishOnboardJobs } = useOnboardJobs();
    const { draggingOverColor } = useListItem();

    return (
        <Board>
            {dispatchCard === "multiroute" && <MultiRouteColumn key={"multiroute"}/>}
            {board.sequences.map(id => <SequenceColumn id={id} key={id}/>)}
            {board.memberUids.map(uid => <RouteColumn uid={uid} key={uid}/>)}
            {dispatchCard === "pinned" && <PinnedJobsColumn/>}
            {dispatchCard === "unassigned" &&
                <ColumnCard
                    key={"unassigned"}
                    color={"transparent"}
                    title={expandedBulkEdit ? null :
                        <HFlex alignCenter spacing>
                            {isDesktop && strings.Dispatch.UnassignedJobs}
                            <SpacerExpand/>
                            <UnassignedAllOrSelected/>
                            <HoverButton tooltip={strings.General.RemoveFromBoard}
                                         kind={"tertiary"}
                                         onClick={() => resetDispatchCard()}
                            >
                                <RemoveIcon color={theme.colors.contentPrimary} size={14}/>
                            </HoverButton>
                        </HFlex>
                    }
                    width={600}
                    borderless
                >
                    <Droppable
                        droppableId={"board-" + DROPPABLE_UNASSIGNEDLIST}
                        type={DROPPABLE_TYPE_JOB}
                    >
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    flex: 1,
                                    backgroundColor: snapshot.isDraggingOver ? draggingOverColor : undefined,
                                    overflow: "hidden"
                                }}
                            >
                                <AllJobsGrid unassigned/>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </ColumnCard>
            }
            {dispatchCard === "jobs" &&
                <ColumnCard
                    key={"jobs"}
                    color={"transparent"}
                    title={expandedBulkEdit ? null :
                        <HFlex alignCenter spacing>
                            {isDesktop && strings.General.Jobs}
                            <Switch checkedChildren={strings.General.ActiveJobs}
                                    unCheckedChildren={strings.General.Archived}
                                    checked={allJobsActiveTab === "jobs"}
                                    onChange={checked => setAllJobsActiveTab(checked ? "jobs" : "archived")}
                                    style={allJobsActiveTab === "jobs" ? {} : { backgroundColor: "darkorange" }}
                            />
                            <SpacerExpand/>
                            <Button ref={importJobsRef}
                                    size={"mini"}
                                    kind={"secondary"}
                                    onClick={() => setImportType("jobs")}
                            >
                                {!isDesktop ? strings.General.Import :
                                    <NoWrapText>{strings.Job.ImportJobs}</NoWrapText>}
                            </Button>
                            <Button ref={addJobRef}
                                    size={"mini"}
                                    kind={"primary"}
                                    onClick={() => {
                                        finishOnboardJobs();
                                        initNewJob();
                                    }}>
                                {isMobile ? <Plus/> :
                                    <HFlex alignCenter spacing={"4px"}>
                                        <Plus/> <NoWrapText>{strings.Job.NewJob}</NoWrapText>
                                    </HFlex>
                                }
                            </Button>
                            <HoverButton tooltip={strings.General.RemoveFromBoard}
                                         kind={"tertiary"}
                                         onClick={() => resetDispatchCard()}
                            >
                                <RemoveIcon color={theme.colors.contentPrimary} size={14}/>
                            </HoverButton>
                        </HFlex>
                    }
                    width={600}
                    borderless
                >
                    <AllJobsView/>
                </ColumnCard>
            }
            {dispatchCard === "templates" &&
                <ColumnCard
                    key={"templates"}
                    color={"transparent"}
                    title={
                        <HFlex alignCenter spacing>
                            {strings.JobTemplates.JobTemplates}
                            <SpacerExpand/>
                            <HoverButton tooltip={strings.General.RemoveFromBoard}
                                         kind={"tertiary"}
                                         onClick={() => resetDispatchCard()}
                            >
                                <RemoveIcon color={theme.colors.contentPrimary} size={14}/>
                            </HoverButton>
                        </HFlex>
                    }
                    width={600}
                    borderless
                >
                    <JobTemplatesGrid/>
                </ColumnCard>
            }
            {dispatchCard === "clustering" && <ClusterColumn key={"clusters"}/>}
        </Board>
    );
});
