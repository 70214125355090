import { layersCardIsOpenState } from '../../../states/skeletonState';
import { FloatButton } from "antd";
import { LayersIcon } from "../../ui/svg";
import { useTheme } from "../../../hooks/useTheme";
import { useRecoilState } from "recoil";
import { memo } from 'react';

export function LayersOpenButton() {
    const [isOpen, setLayersCardIsOpen] = useRecoilState(layersCardIsOpenState);
    const { theme } = useTheme();

    const onOpenLayersMenu = () => {
        setLayersCardIsOpen(true);
    };

    if (isOpen) {
        return null;
    }

    return (
        <FloatButton
            shape="square"
            icon={<LayersIcon size={18} color={theme.colors.contentPrimary}/>}
            onClick={onOpenLayersMenu}
            style={{
                bottom: "24px",
                right: "60px",
                width: "40px",
                height: "40px",
                zIndex: "auto",
            }}
        />
    );
}

export default memo(LayersOpenButton);