import { useBoard } from "../../../hooks/useBoard";
import { HeatmapLayer } from "./HeatmapLayer";
import { useRecoilValue } from "recoil";
import { pageState } from "../../../states/appState";
import { PAGE } from "../../../constants/pages";
import { memo } from "react";

function HeatmapLayers() {
    const board = useBoard();
    const page = useRecoilValue(pageState);

    if (page !== PAGE.HEATMAP) {
        return null;
    }

    return (
        <>
            {board.memberUids.length > 0
                ? board.memberUids.map(uid => <HeatmapLayer key={uid} member={uid}/>)
                : <HeatmapLayer member={undefined}/>
            }
        </>
    );
}

export default memo(HeatmapLayers);