import { memo, useMemo } from "react";
import { default as AutoSizer } from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import { useRecoilValue } from "recoil";
import { dayState } from "../../../states/appState";
import { jobIdsByMemberDispatchState } from "../../../states/jobsState";
import { RouteInfo } from "../../../types/job";
import { Uid } from "../../../types/core";
import RouteJobListItem from "./RouteJobListItem";

type Props = {
    uid: Uid;
    routeInfoObject?: RouteInfo;
    outerRef: (element: HTMLElement | null) => any;
};

function RouteJobList({ routeInfoObject, uid, outerRef }: Props) {
    const day = useRecoilValue(dayState);
    const jobIds = useRecoilValue(jobIdsByMemberDispatchState({ uid, day }));
    const newJobIds = useMemo(() => ["start", ...jobIds, "end"], [jobIds]);

    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    height={height}
                    itemCount={newJobIds.length}
                    itemSize={() => 66}
                    width={width}
                    itemData={{
                        jobIds,
                        newJobIds,
                        uid,
                        routeInfoObject,
                        width,
                    }}
                    overscanCount={10}
                    outerRef={outerRef}
                >
                    {RouteJobListItem}
                </List>
            )}
        </AutoSizer>
    );
}

export default memo(RouteJobList);
