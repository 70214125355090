import { LocationHeaderRow } from "./LocationHeaderRow";
import { Skeleton } from "baseui/skeleton";
import { default as AutoSizer } from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import { LocationRow } from "./LocationRow";
import { StyledTable } from "baseui/table";
import React, { CSSProperties, useState } from "react";
import { useRecoilValue } from "recoil";
import { requestingState, tracksAndStopsConfigState } from "../../states/viewState";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { TrackEntry, TrackOrStopEntry } from "../../types/track";
import { useTrackColumns } from "../../hooks/tracks/useTrackColumns";
import { TrackInfoModal } from "./TrackInfoModal";

export function LocationTable({ entries }: { entries: TrackOrStopEntry[] }) {
    const fetching = useRecoilValue(requestingState({ req: "tracks" }));
    const { tableStyle } = useStyleOverrides();
    const [selectedTrack, setSelectedTrack] = useState<TrackEntry>();
    const { tableWidth } = useTrackColumns();

    const config = useRecoilValue(tracksAndStopsConfigState);
    const entriesFiltered = entries.filter(e => {
        const isStop = e.kind === "stop";
        if (!isStop) {
            return config.showTracks;
        }
        if (e.at === "place") {
            return config.showStopsAtPlaces;
        }
        if (e.at === "job") {
            return config.showStopsAtJobs;
        }
        return config.showStopsOther;
    })

    const tableStyles: CSSProperties = {
        width: `${tableWidth}px`,
        minWidth: "100%",
        boxSizing: "border-box",
    }

    const closeTrackInfo = () => {
        setSelectedTrack(undefined);
    }

    const itemHeight = 40;

    return (
        <StyledTable role="grid" style={{ ...tableStyle, ...tableStyles }}>
            <LocationHeaderRow/>
            <div style={{ flex: 1 }}>
                {fetching ? <Skeleton width={"100%"} height={"100%"} animation/> :
                    <AutoSizer>
                        {({ height, width }) => {
                            return (
                                <List height={height}
                                      itemCount={entriesFiltered.length}
                                      itemSize={() => itemHeight}
                                      width={width}
                                      style={{
                                          overflowX: "hidden",
                                      }}
                                      itemData={{
                                          onTrackInfo: setSelectedTrack,
                                          entries: entriesFiltered,
                                      }}>
                                    {LocationRow}
                                </List>
                            )
                        }}
                    </AutoSizer>
                }
            </div>
            {selectedTrack && (
                <TrackInfoModal track={selectedTrack} onClose={closeTrackInfo} canDelete canShare/>
            )}
        </StyledTable>
    );
}