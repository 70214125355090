import { KML_EXTENSION } from "../constants/app";
import { TRUNCATE_LENGTH_DEFAULT, truncateText } from "../utils/strings";
import { LonLat, Timestamp, Uid } from "./core";

export interface Zone {
    companyUid: Uid;
    name: string;
    created: Timestamp;
    uid: Uid;
    points: LonLat[];
    assignedMembers: Uid[];
    assignedTeams: number[];
    color: string;
}

export type ImportZoneFileFormat = typeof KML_EXTENSION;

export const getTruncatedZoneName = (zone: Zone) => {
    return zone.name && zone.name.length > TRUNCATE_LENGTH_DEFAULT ? truncateText(zone.name) : zone.name;
}
