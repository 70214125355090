import { Block } from "baseui/block";
import { FormControl } from "baseui/form-control";
import { useTheme } from "../../../hooks/useTheme";


export function StylishFormControl({ ...props }: React.ComponentProps<typeof FormControl>) {
    const { theme } = useTheme();

    return (
        <Block>
            <FormControl
                {...props}
                overrides={{
                    ControlContainer: {
                        style: {
                            marginBottom: 0,
                        }
                    },
                    LabelContainer: {
                        style: {
                            marginTop: 0,
                            marginBottom: 0,
                        }
                    },
                    Label: {
                        style: {
                            ...theme.typography.LabelLarge,
                            fontSize: theme.typography.HeadingSmall.fontSize,
                            lineHeight: theme.typography.HeadingSmall.lineHeight,
                        },
                    },
                    Caption: {
                        style: {
                            backgroundColor: "#ad1414cf",
                            paddingTop: theme.sizing.scale300,
                            paddingBottom: theme.sizing.scale300,
                            marginTop: 0,
                            marginBottom: 0,
                            borderBottomLeftRadius: theme.borders.radius200,
                            borderBottomRightRadius: theme.borders.radius200,
                            color: theme.colors.contentPrimary,
                            fontSize: theme.typography.ParagraphSmall.fontSize,
                        }
                    }
                }}
            />
        </Block>
    );
}