import { useState } from "react";
import { DraggableLocation, DragStart, DragUpdate, DropResult } from "react-beautiful-dnd";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { SEQ_COL_ID_PREFIX } from "../components/dispatch/sequences/SequenceColumn";
import {
    DROPPABLE_ALL_JOBS_TABLE,
    DROPPABLE_PINNED,
    DROPPABLE_TYPE_JOB,
    DROPPABLE_UNASSIGNEDLIST,
    MEMBER_ITEM_ID_PREFIX,
    PINNED_JOB_ID_PREFIX,
    TABLE_JOB_ID_PREFIX
} from "../constants/dnd";
import { dayState, draggingState } from "../states/appState";
import {
    jobIdsByMemberDispatchState,
    jobIdsPinnedState,
    jobIdsUnassignedDispatchState,
    jobsState,
    jobsUpdatingDispatchState
} from "../states/jobsState";
import { sequenceJobsDispatchState } from "../states/sequencesState";
import { EMPTY_UID, Uid, UidDay } from "../types/core";
import { addAtIndex, addXAtIndex, removeAtIndex, removeAtIndexes, reorder } from "../utils/arrays";
import { useAllJobsSelection } from "./jobs/useAllJobsSelection";
import { useJobAssignment } from "./jobs/useJobAssignment";
import { useSequenceSaver } from "./jobs/useSequenceSaver";
import { useStrings } from "./useStrings";
import { useToast } from "./useToast";
import { mapJobIds } from "../utils/jobUtils";

export function useDrag() {
    const allJobs = useRecoilValue(jobsState);
    const { assign, unassign, bulkEdit } = useJobAssignment();
    const selection = useAllJobsSelection();
    const setDragging = useSetRecoilState(draggingState);
    const [providerUid, setProviderUid] = useState(EMPTY_UID);
    const [receiverUid, setReceiverUid] = useState(EMPTY_UID);
    const { updateJobsInSequence, addJobToSequence } = useSequenceSaver();
    const day = useRecoilValue(dayState);
    const setUpdatingDispatch = useSetRecoilState(jobsUpdatingDispatchState);
    const [providerJobIds, setProviderJobIds] = useRecoilState(jobIdsByMemberDispatchState({ day, uid: providerUid }));
    const [receiverJobIds, setReceiverJobIds] = useRecoilState(jobIdsByMemberDispatchState({ day, uid: receiverUid }));
    const [sequenceJobs, setSequenceJobs] = useRecoilState(sequenceJobsDispatchState(providerUid.substring(SEQ_COL_ID_PREFIX.length)));
    const [unassignedJobIds, setUnassignedJobIds] = useRecoilState(jobIdsUnassignedDispatchState);
    const resetUpdatingDispatch = useResetRecoilState(jobsUpdatingDispatchState);
    const toast = useToast();
    const { strings } = useStrings();
    const setPinned = useSetRecoilState(jobIdsPinnedState);

    const onDragStart = (dragStart: DragStart) => {
        setProviderUid(dragStart.source.droppableId as Uid);
        setDragging(true);
    };

    const onBeforeCapture = (dragStart: DragStart) => {
        setDragging(true);
    };

    const onBeforeDragStart = (dragStart: DragStart) => {
    };

    const onDragUpdate = (initial: DragUpdate) => {
        const destinationId = initial.destination?.droppableId as Uid;
        setReceiverUid(destinationId || EMPTY_UID);
    };

    const onDragEnd = (result: DropResult) => {
        const { destination, draggableId, reason, source, type } = result;
        const { droppableId: sourceDroppableId, index: sourceIndex } = source;
        const { droppableId: destinationDroppableId } = destination || {};

        const sourceIsSequence = sourceDroppableId.startsWith(SEQ_COL_ID_PREFIX);
        const destinationIsSequence = destinationDroppableId?.startsWith(SEQ_COL_ID_PREFIX) || false;

        const destinationIsUnassign = destinationDroppableId?.includes(DROPPABLE_UNASSIGNEDLIST);
        const sourceIsUnassign = sourceDroppableId?.includes(DROPPABLE_UNASSIGNEDLIST);

        if (sourceIsSequence) {
            if (destinationIsSequence && sourceDroppableId !== destinationDroppableId) {
                toast.showWarning("Cannot drop sequence jobs into another sequence");
                return;
            }
            if (destinationIsUnassign) {
                toast.showWarning("Cannot unassign sequence jobs");
                return;
            }
            if (!destinationIsSequence) {
                toast.showWarning("Cannot drop sequence jobs into members");
                return;
            }
        }
        if (sourceIsUnassign && destinationIsUnassign) {
            return;
        }
        setDragging(false);
        setUpdatingDispatch({
            providerUid: sourceDroppableId as Uid || null,
            receiverUid: destinationDroppableId as Uid || null,
        });
        if (!destination || reason !== "DROP") {
            resetUpdatingDispatch();
            return;
        }
        if (draggableId && destinationIsUnassign && selection.selectedIds.length <= 1) {
            unassignJobOnDrop(sourceIndex, destination, draggableId, sourceDroppableId);
        } else if (sourceIsSequence && sourceDroppableId === destinationDroppableId) {
            reorderSequenceOnDrop(sourceDroppableId, draggableId, destination, sourceIndex);
        } else if (destinationIsSequence) {
            copyJobToSequence(draggableId, destination);
        } else if (type === DROPPABLE_TYPE_JOB) {
            if (destination?.droppableId === DROPPABLE_UNASSIGNEDLIST) {
                bulkEditOnDrop(sourceDroppableId, destination, EMPTY_UID);
                return;
            }
            if (destination?.droppableId === DROPPABLE_PINNED) {
                setPinned(old => {
                    const jobId = getJobIdFromDraggableId(draggableId);
                    const filtered = old.filter(id => id !== jobId);
                    const result = destination.index < filtered.length
                        ? addAtIndex(filtered, destination.index, jobId)
                        : [...filtered, jobId];
                    return result;
                });
                return;
            }
            let memberUid = destination?.droppableId as Uid;
            if (memberUid.startsWith(MEMBER_ITEM_ID_PREFIX)) {
                memberUid = memberUid.substring(MEMBER_ITEM_ID_PREFIX.length) as Uid;
                if (memberUid === source.droppableId) {
                    // dropping on same member as already assigned
                    resetUpdatingDispatch();
                    return;
                }
            }
            const jobId = getJobIdFromDraggableId(draggableId);
            if (selection.selectedIds.length > 0
                && selection.selectedIds.includes(jobId)
                && memberUid !== sourceDroppableId) {
                bulkEditOnDrop(sourceDroppableId, destination, memberUid);
            } else {
                assignOnDrop(sourceDroppableId, destination, sourceIndex, jobId, memberUid);
            }
        }
    };

    function getJobIdFromDraggableId(draggableId: string) {
        let jobId = draggableId;
        if (jobId.startsWith(PINNED_JOB_ID_PREFIX)) {
            jobId = jobId.substring(PINNED_JOB_ID_PREFIX.length);
        } else if (jobId.startsWith(TABLE_JOB_ID_PREFIX)) {
            jobId = jobId.substring(TABLE_JOB_ID_PREFIX.length);
        }
        return jobId;
    }

    function unassignJobOnDrop(sourceIndex: number, destination: DraggableLocation, draggableId: string, sourceDroppableId: string) {
        const jobId = providerJobIds[sourceIndex];
        setProviderJobIds(removeAtIndex(providerJobIds, sourceIndex));
        setUnassignedJobIds(addAtIndex(unassignedJobIds, destination.index, jobId));
        unassign(draggableId, day, sourceDroppableId as Uid).then(() => {
            resetUpdatingDispatch();
        });
    }

    function reorderSequenceOnDrop(sourceDroppableId: string, draggableId: string, destination: DraggableLocation, sourceIndex: number) {
        const sequenceId = sourceDroppableId.substring(SEQ_COL_ID_PREFIX.length);
        const jobid = draggableId;
        const index = destination.index;
        setSequenceJobs(reorder(sequenceJobs, sourceIndex, destination.index));
        updateJobsInSequence(sequenceId, jobid, index);
    }

    function copyJobToSequence(draggableId: string, destination: DraggableLocation) {
        const sequenceId = destination.droppableId.substring(SEQ_COL_ID_PREFIX.length);
        const jobId = getJobIdFromDraggableId(draggableId);
        if (addJobToSequence(sequenceId, jobId, destination.index)) {
            toast.showSuccess(strings.General.Copied);
            resetUpdatingDispatch();
        }
    }

    function bulkEditOnDrop(sourceDroppableId: string, destination: DraggableLocation, memberUid: Uid | undefined) {
        const allSelectedJobs = allJobs.filter(job => selection.selectedIds.includes(job.id));
        let selectedJobs = allSelectedJobs;
        if (sourceDroppableId.includes(DROPPABLE_UNASSIGNEDLIST)) {
            // Ignore selected jobs on member
            selectedJobs = selectedJobs.filter(job => !receiverJobIds.includes(job.id))
            // Moving multiple jobs from unassigned to member
            const selectedJobIndexes = selectedJobs.map((job) => unassignedJobIds.findIndex((id) => id === job.id));
            setUnassignedJobIds(removeAtIndexes(unassignedJobIds, selectedJobIndexes));
            setReceiverJobIds(addXAtIndex(receiverJobIds, destination.index, ...selectedJobs.map(mapJobIds)));
        } else if (destination.droppableId.includes(DROPPABLE_UNASSIGNEDLIST)) {
            // Ignore selected jobs on unassigned
            selectedJobs = selectedJobs.filter(job => !unassignedJobIds.includes(job.id))
            // Moving multiple jobs from member to unassigned
            const selectedJobIndexes = selectedJobs.map((job) => providerJobIds.findIndex((id) => id === job.id));
            setProviderJobIds(removeAtIndexes(providerJobIds, selectedJobIndexes));
            setUnassignedJobIds(addXAtIndex(unassignedJobIds, destination.index, ...selectedJobs.map(mapJobIds)));
        } else if (sourceDroppableId !== DROPPABLE_PINNED && sourceDroppableId !== DROPPABLE_ALL_JOBS_TABLE) {
            // Ignore selected jobs on member
            selectedJobs = selectedJobs.filter(job => !receiverJobIds.includes(job.id))
            // Moving multiple jobs from one member and/or unassign to another member
            const selectedJobIndexes = selectedJobs.map((job) => providerJobIds.findIndex((id) => id === job.id));
            const selectedUnassignedJobIndexes = selectedJobs.map((job) => unassignedJobIds.findIndex((id) => id === job.id));
            setProviderJobIds(removeAtIndexes(providerJobIds, selectedJobIndexes));
            setReceiverJobIds(addXAtIndex(receiverJobIds, destination.index, ...selectedJobs.map(mapJobIds)));
            setUnassignedJobIds(removeAtIndexes(unassignedJobIds, selectedUnassignedJobIndexes));
        }
        const uidDays = allSelectedJobs
            .map(job => {
                return {
                    uid: job.receiverUid,
                    day: job.day
                } as UidDay
            });
        bulkEdit(selection.selectedIds, uidDays, { day, assigneeUid: memberUid }).then(() => {
            resetUpdatingDispatch();
            selection.clearSelection();
        });
    }

    function assignOnDrop(sourceDroppableId: string, destination: DraggableLocation, sourceIndex: number, jobId: string, memberUid: Uid) {
        if (sourceDroppableId === destination.droppableId) {
            // Ordering member jobs
            setProviderJobIds(reorder(providerJobIds, sourceIndex, destination.index));
        } else {
            if (sourceDroppableId.includes(DROPPABLE_UNASSIGNEDLIST)) {
                // Moving job from unassigned to member
                const jobId = unassignedJobIds[sourceIndex];
                setUnassignedJobIds(removeAtIndex(unassignedJobIds, sourceIndex));
                setReceiverJobIds(addAtIndex(receiverJobIds, destination.index, jobId));
            } else if (destination.droppableId.includes(DROPPABLE_UNASSIGNEDLIST)) {
                // Moving job from member to unassigned
                const jobId = providerJobIds[sourceIndex];
                setProviderJobIds(removeAtIndex(providerJobIds, sourceIndex));
                setUnassignedJobIds(addAtIndex(unassignedJobIds, destination.index, jobId));
            } else if (sourceDroppableId !== DROPPABLE_PINNED && sourceDroppableId !== DROPPABLE_ALL_JOBS_TABLE) {
                // Moving jobs from one member to another
                const jobId = providerJobIds[sourceIndex];
                setProviderJobIds(removeAtIndex(providerJobIds, sourceIndex));
                setReceiverJobIds(addAtIndex(receiverJobIds, destination.index, jobId));
            }
        }

        assign(jobId, day, destination?.index, memberUid, sourceDroppableId as Uid).then(() => {
            resetUpdatingDispatch();
            selection.clearSelection();
        });
    }

    return {
        onDragStart,
        onDragEnd,
        onBeforeDragStart,
        onBeforeCapture,
        onDragUpdate
    }
}