import { useRecoilValue } from "recoil";
import { stringsState } from "../states/localeState";
import { useTime } from "./useTime";
import { Timestamp } from "../types/core";

type Formatted = number | string;

export function useStrings() {
    const strings = useRecoilValue(stringsState);
    const time = useTime();
    const format = (str: string, ...values: Formatted[]) => {
        for (let i = 0; i < values.length; i++) {
            if (!values[i] && values[i] !== 0) {
                values[i] = "";
            }
        }
        return strings.formatString(str, ...values) as string;
    }
    const formatDate = (str: string, ts: Timestamp) => strings.formatString(str, time.formatDateOnly(ts)) as string;

    return { strings, format, formatDate };
}