import { Button } from 'baseui/button';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useStyleOverrides } from '../../hooks/useStyleOverrides';
import { useTeamForms } from '../../hooks/members/useTeamForms';
import { useTheme } from '../../hooks/useTheme';
import { fieldsForTeamEditState } from '../../states/editTeamState';
import { stringsState } from '../../states/localeState';
import { HFlex } from '../containers/HFlex';
import { SpacerExpand } from '../containers/Spacer';
import { WorkingHours } from '../shared/WorkingHours';
import { memo } from 'react';

type Props = {
    teamId: number;
    close: () => void;
};

export const TeamWorkingHours = memo(({ teamId, close }: Props) => {
    const { theme } = useTheme();
    const strings = useRecoilValue(stringsState);
    const [teamFields, setTeamFields] = useRecoilState(fieldsForTeamEditState(teamId));
    const { hasChanges, canSubmit, isLoading, onSubmit } = useTeamForms(teamId);
    const { cancelButtonStyles } = useStyleOverrides();
    const resetTeamFields = useResetRecoilState(fieldsForTeamEditState(teamId));

    const handleClose = () => {
        resetTeamFields();
        close();
    }

    const handleSubmit = async () => {
        await onSubmit();
        handleClose();
    }

    return (
        <>
            <WorkingHours workingHours={teamFields.working_hours} setWorkingHours={setTeamFields}/>
            <HFlex style={{
                marginTop: theme.sizing.scale600
            }}>
                <SpacerExpand/>
                {hasChanges && (
                    <Button
                        onClick={handleClose}
                        size="mini"
                        kind="tertiary"
                        overrides={cancelButtonStyles}
                    >
                        {strings.General.Cancel}
                    </Button>
                )}
                <Button
                    onClick={hasChanges ? handleSubmit : handleClose}
                    size="mini"
                    isLoading={isLoading}
                    disabled={!canSubmit && hasChanges}
                >
                    {hasChanges ? strings.General.Save : strings.General.OK}
                </Button>
            </HFlex>
        </>
    );
});
