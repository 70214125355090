import { atom } from "recoil";
import { QueryResponse } from "../types/chatai";

export const chatAiHistoryState = atom<QueryResponse[]>({
    key: "chatai.history",
    default: [],
});

export const chatAiIndustryState = atom<string>({
    key: "chatai.industry",
    default: "",
});

export const chatAiGoalState = atom<string>({
    key: "chatai.goal",
    default: "",
});

export const chatAiLoadingState = atom<boolean>({
    key: "chatai.loading",
    default: false,
});

export const chatAiInputTextState = atom<string>({
    key: "chatai.inputtext",
    default: "",
});

export const chatAiActiveTabState = atom<"dispatch" | "help">({
    key: "chatai.activetab",
    default: "dispatch",
});