import { useTime } from "../../hooks/useTime";
import { timeToDate } from "../../utils/time";
import { TimePicker } from "antd";
import dayjs from 'dayjs';

type Props = {
    value: number | Date | null;
    onChange: (date: Date | null) => void;
    disabled?: boolean;
    nullable?: boolean;
};

export function StyledTimePicker({ value, onChange, disabled = false }: Props) {
    const { timePlaceholder, isAmPm } = useTime();
    const time = typeof value === "number" ? timeToDate(value) : value

    return (
        <TimePicker
            value={time && dayjs(time)}
            placeholder={timePlaceholder()}
            use12Hours={isAmPm()}
            format={isAmPm() ? "hh:mm A" : "HH:mm"}
            disabled={disabled}
            onSelect={(value) => onChange(value ? value.toDate() : null)}
            onChange={(value) => onChange(value ? value.toDate() : null)}
            style={{ minWidth: "108px"}}
        />
    );
}