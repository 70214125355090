export const HELLOTRACKS = "Hellotracks";

export const LANGUAGES = [
    { label: 'English', id: 'en' },
    { label: 'Español', id: 'es' },
    { label: 'Deutsch', id: 'de' },
];

export const UNITS_US = 'US';
export const UNITS_SI = 'SI';

export const UNIT_TIME_24 = '24';
export const UNIT_TIME_12 = '12';

export const ADMIN_ROLE = 'admin';
export const WORKER_ROLE = 'worker';
export const OPERATOR_ROLE = 'operator';

export const IMAGE_FORMATS = ['.png', '.jpg', '.jpeg'];
export const VIDEO_FORMATS = ['.mp4', '.webm'];
export const CSV_EXTENSION = '.csv';
export const EXCEL_EXTENSION = '.xlsx';
export const JSON_EXTENSION = '.json';
export const KML_EXTENSION = '.kml';
export const IMPORT_FILE_FORMATS = [CSV_EXTENSION, EXCEL_EXTENSION, JSON_EXTENSION, KML_EXTENSION];
export const VISIBLE_FILE_FORMATS = [CSV_EXTENSION, EXCEL_EXTENSION];
export const PDF_EXTENSION = '.pdf';

export const WEB_UPLOAD_BUCKET_ID = 'webupld';

export const SIDEBAR_WIDTH = 200;
export const SIDEBAR_COLLAPSED_WIDTH = 56;
export const SIDEBAR_ITEM_HEIGHT = 40;

export const IOS_BUILD =  301;
export const ANDROID_BUILD= 263;

export const IS_SAFARI = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const JSONbig = require('json-bigint')({ storeAsString: true });
export const JSONbigint = require('json-bigint')({ storeAsString: false });