import { Button } from "baseui/button";
import { ChevronLeft } from "baseui/icon";
import { Spacer } from "../containers/Spacer";
import { PAGE } from "../../constants/pages";
import { useNavigator } from "../../hooks/useNavigator";
import { ReactNode } from "react";
import { useBoard } from "../../hooks/useBoard";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { boardVisibleSequencesState } from "../../states/boardState";
import { dispatchTreeActiveCardState, dispatchTreeCollapsedState } from "../../states/viewState";
import { pageState } from "../../states/appState";
import { cardSizesState, visibleCardsState } from "../../states/skeletonState";
import { COLOR_TRANSLUCENT } from "../../constants/colors";

export function ClosePageButton({ onClose, icon = <ChevronLeft/> }: { onClose?: () => void, icon?: ReactNode }) {
    const { navigateToPage } = useNavigator();
    const page = useRecoilValue(pageState);
    const board = useBoard();
    const resetVisibleSequences = useResetRecoilState(boardVisibleSequencesState);
    const resetActiveCard = useResetRecoilState(dispatchTreeActiveCardState);
    const { mediumIsVisible } = useRecoilValue(visibleCardsState);
    const isDispatchCollapsed = useRecoilValue(dispatchTreeCollapsedState);
    const resetDispatchTreeCollapsed = useResetRecoilState(dispatchTreeCollapsedState);
    const resetCardSizes = useResetRecoilState(cardSizesState(PAGE.DISPATCH));

    const onCloseClick = () => {
        if (onClose) {
            onClose();
        } else if (page === PAGE.DISPATCH && isDispatchCollapsed && mediumIsVisible) {
            board.setMemberUids([]);
            resetActiveCard();
            resetDispatchTreeCollapsed();
            resetVisibleSequences();
            resetCardSizes();
        } else {
            navigateToPage(PAGE.MAP);
        }
    };

    return (
        <>
            <Button size={"mini"}
                    kind="secondary"
                    shape={"circle"}
                    onClick={onCloseClick}
                    overrides={{
                        Root: {
                            style: {
                                backgroundColor: COLOR_TRANSLUCENT,
                                flexShrink: 0,
                            }
                        }
                    }}>
                {icon}
            </Button>
            <Spacer/>
        </>
    );
}