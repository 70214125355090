import { VFlex } from "../containers/VFlex";
import { MemberSelect } from "../pickers/MemberSelect";
import { DatePicker } from "baseui/datepicker";
import { dateToDayBrowserTimezone } from "../../utils/time";
import { useState } from "react";
import { Member } from "../../types/member";
import { useAllJobsSelection } from "../../hooks/jobs/useAllJobsSelection";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { Button } from "baseui/button";
import { useRecoilValue } from "recoil";
import { JobProgressStatusSelect } from "../pickers/JobProgressStatusSelect";
import { JobTeamSelect } from "../pickers/JobTeamSelect";
import { HFlex } from "../containers/HFlex";
import { JobTypeSelect } from "../pickers/JobTypeSelect";
import { stringsState } from "../../states/localeState";
import { useJobAssignment } from "../../hooks/jobs/useJobAssignment";
import { EMPTY_UID, UidDay } from "../../types/core";
import { jobsState, selectedJobsState } from "../../states/jobsState";
import { ConfirmModal } from "../ui/ConfirmModal";
import { useTime } from "../../hooks/useTime";
import { usePermissions } from "../../hooks/usePermissions";
import { Input } from "baseui/input";
import { JobPrioritySelect } from "../pickers/JobPrioritySelect";
import { JobCarryOverSelect } from "../pickers/JobCarryOverSelect";
import { LinkedZonesSelect } from "../pickers/LinkedZonesSelect";
import { StopWatchOutlineIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { ParagraphXSmall } from "baseui/typography";
import { StatefulPanel } from "baseui/accordion";
import { FlexGridLayout } from "../containers/FlexGridLayout";
import { useGap } from "../../hooks/useGap";
import { LinkedFormsSelect } from "../pickers/LinkedFormsSelect";
import { LinkedForm, LinkedZone } from "../../types/linked";
import { useJobTemplatesUpdater } from "../../hooks/jobs/useJobTemplatesUpdater";
import { allJobsActiveTabState, dispatchTreeActiveCardState } from "../../states/viewState";
import { preventMinus } from "../../utils/formUtils";

export function JobsBulkEdit({
                                 mini = false,
                                 onFinish
                             }: { mini?: boolean; onFinish?: Function }) {
    const strings = useRecoilValue(stringsState);
    const { theme, isDarkTheme } = useTheme();
    const [assignee, setAssignee] = useState<Member | ''>();
    const [date, setDate] = useState<Date>();
    const { dateFormat } = useTime();
    const selection = useAllJobsSelection();
    const [status, setStatus] = useState(0);
    const [teamId, setTeamId] = useState(0);
    const [itemsToPickup, setItemsToPickup] = useState<number>();
    const [itemsToDropoff, setItemsToDropoff] = useState<number>();
    const [linkedZones, setLinkedZones] = useState<LinkedZone[]>();
    const [linkedForms, setLinkedForms] = useState<LinkedForm[]>();
    const [carryOver, setCarryOver] = useState<number>();
    const [priority, setPriority] = useState<number>();
    const [onSiteMinutes, setOnSiteMinutes] = useState<number>();
    const [type, setType] = useState(-1);
    const assign = useJobAssignment();
    const allJobs = useRecoilValue(jobsState);
    const [showArchiveConfirm, setArchiveShowConfirm] = useState(false);
    const [showDeleteConfirm, setDeleteShowConfirm] = useState(false);
    const [showRestoreConfirm, setRestoreShowConfirm] = useState(false);
    const { mayDeleteJobs } = usePermissions();
    const { gap300 } = useGap();
    const selectedJobs = useRecoilValue(selectedJobsState);
    const jobTemplatesUpdater = useJobTemplatesUpdater();
    const activeTab = useRecoilValue(allJobsActiveTabState);
    const activeCard = useRecoilValue(dispatchTreeActiveCardState);
    const archivedJobs = activeTab === "archived" && activeCard === "jobs";

    const isTemplateJobsSelected = selectedJobs.filter(j => j.isTemplate).length > 0
    const isSequenceJobsSelected = selectedJobs.filter(j => j.isPartOfSequence).length > 0
    const isTemplateOrSequenceJobsSelected = isTemplateJobsSelected || isSequenceJobsSelected;
    const isArchiveDisabled = isTemplateOrSequenceJobsSelected;
    const isDeleteDisabled = isSequenceJobsSelected || !mayDeleteJobs();

    function onAssigneeSelected(member?: Member | '') {
        setAssignee(member);
    }

    const onDeleteConfirmed = async () => {
        await assign.bulkDelete(selection.selectedIds, uidDays());
        if (isTemplateJobsSelected) {
            await jobTemplatesUpdater.updateJobTemplates();
        }
        onDone();
    }

    const onArchiveConfirmed = async () => {
        await assign.bulkArchive(selection.selectedIds, uidDays());
        onDone();
    }

    const onRestoreConfirmed = async () => {
        await assign.bulkRestore(selection.selectedIds, uidDays());
        onDone();
    }

    function onDone() {
        selection.clearSelection();
        if (onFinish) {
            onFinish();
        }
    }

    function uidDays() {
        return allJobs
            .filter(job => selection.selectedIds.includes(job.id))
            .map(job => {
                return {
                    uid: job.receiverUid,
                    day: job.day
                } as UidDay
            });
    }

    function onAssignClick() {
        assign.bulkEdit(
            selection.selectedIds,
            uidDays(),
            {
                day: date ? dateToDayBrowserTimezone(date) : undefined,
                assigneeUid: assignee === '' ? EMPTY_UID : assignee?.uid,
                status,
                teamId,
                type,
                itemsToPickup,
                itemsToDropoff,
                priority,
                linkedZones,
                linkedForms,
                carryOver,
                onSiteMinutes,
            },
            onDone
        );
    }

    if (mini && isTemplateJobsSelected) {
        return null;
    }

    if (mini && !isTemplateOrSequenceJobsSelected) {
        return <>
            <MemberSelect placeholder={`${strings.General.AssignTo}...`}
                          onMemberSelect={onAssigneeSelected}
                          selectedMember={assignee}
                          avatar={false}
                          size="mini"
            />
            <Spacer width={"4px"}/>
            <Button kind={"primary"}
                    size={"mini"}
                    onClick={onAssignClick}
                    disabled={assignee === undefined}
            >
                {strings.General.Assign}
            </Button>
        </>
    }

    const onCancelClick = () => {
        if (onFinish) {
            onFinish();
        }
    }

    const onNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setter: (value: React.SetStateAction<number | undefined>) => void) => {
        const value = event.target.value;
        if (value === "") {
            setter(undefined);
        } else {
            setter(+value >= 0 ? +value : 0);
        }
    }

    return (
        <VFlex spacing style={{
            height: "auto",
            backgroundColor: isDarkTheme ? "black" : "white",
            borderRadius: "8px",
            borderWidth: "1px",
            borderColor: isDarkTheme ? "#333333" : "#CCCCCC",
            borderStyle: "solid",
            paddingLeft: "8px",
            paddingTop: "8px",
            paddingRight: "8px",
            paddingBottom: "8px",
        }}>
            <FlexGridLayout rowGap={gap300} columnGap={gap300} baseWidth={150}>
                <MemberSelect placeholder={`${strings.General.AssignTo}...`}
                              onMemberSelect={onAssigneeSelected}
                              selectedMember={assignee}
                              avatar={false}
                />
                <DatePicker
                    size="compact"
                    value={date}
                    formatString={dateFormat}
                    placeholder={strings.General.Date}
                    clearable
                    onChange={({ date }) => {
                        if (date instanceof Date) {
                            setDate(date)
                        } else {
                            setDate(undefined);
                        }
                    }}
                />
                <JobTypeSelect type={type} onTypeChange={({ option }) => {
                    setType(option?.id as number)
                }}/>
                <JobProgressStatusSelect progress={status}
                                         onSelect={(progress?: number) => setStatus(progress || 0)}/>
            </FlexGridLayout>
            <StatefulPanel
                title={strings.General.AllFields}
                overrides={{
                    Header: {
                        style: {
                            paddingLeft: theme.sizing.scale500,
                            paddingRight: theme.sizing.scale500,
                            paddingTop: 0,
                            paddingBottom: theme.sizing.scale300,
                            backgroundColor: 'transparent'
                        }
                    },
                    PanelContainer: {
                        style: {
                            borderBottomColor: 'transparent',
                        }
                    },
                    Content: {
                        style: {
                            backgroundColor: 'transparent',
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: theme.sizing.scale300,
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                        }
                    }
                }}
            >
                <FlexGridLayout rowGap={gap300} columnGap={gap300} baseWidth={200}>
                    <JobTeamSelect teamId={teamId} onTeamChange={({ option }) => {
                        setTeamId(option?.id as number)
                    }}/>
                    <JobCarryOverSelect carryOver={carryOver}
                                        onCarryOverChange={(carryOver) => setCarryOver(carryOver ? 1 : 0)}/>
                    <Input
                        placeholder={strings.Job.Pickup}
                        value={itemsToPickup ?? ""}
                        onKeyDown={preventMinus}
                        type="number"
                        size="compact"
                        onChange={(event) => onNumberChange(event, setItemsToPickup)}
                    />
                    <Input
                        placeholder={strings.Job.Dropoff}
                        value={itemsToDropoff ?? ""}
                        onKeyDown={preventMinus}
                        type="number"
                        size="compact"
                        onChange={(event) => onNumberChange(event, setItemsToDropoff)}
                    />
                    <JobPrioritySelect priority={priority} onPriorityChange={(value) => setPriority(value)}/>
                    <Input
                        value={onSiteMinutes ?? ""}
                        type="number"
                        min={0}
                        size="compact"
                        onKeyDown={preventMinus}
                        onChange={(event) => onNumberChange(event, setOnSiteMinutes)}
                        startEnhancer={
                            <StopWatchOutlineIcon size={14} color={theme.colors.contentTertiary}/>
                        }
                        endEnhancer={
                            <ParagraphXSmall marginTop={0} marginBottom={0}>min</ParagraphXSmall>
                        }
                        placeholder={strings.Job.OnSiteTime}
                    />
                    <LinkedZonesSelect
                        linkedZones={linkedZones || []}
                        onChangeLinkedZones={(linkedZones) => setLinkedZones(linkedZones)}
                        placeholder={strings.Job.LinkedZones}
                    />
                    <LinkedFormsSelect
                        linkedForms={linkedForms || []}
                        onChangeLinkedForms={(linkedForms => setLinkedForms(linkedForms as LinkedForm[]))}
                        placeholder={strings.Job.FormsLinkedForm}
                    />
                </FlexGridLayout>
            </StatefulPanel>
            <HFlex>
                <Button kind={"tertiary"}
                        size={"compact"}
                        disabled={isDeleteDisabled}
                        onClick={() => setDeleteShowConfirm(true)}>
                    {strings.General.Delete}
                </Button>
                {!archivedJobs &&
                    <Button kind={"tertiary"}
                            size={"compact"}
                            disabled={isArchiveDisabled}
                            onClick={() => setArchiveShowConfirm(true)}>
                        {strings.Archive.Archive}
                    </Button>
                }
                {archivedJobs &&
                    <Button kind={"tertiary"}
                            size={"compact"}
                            disabled={isArchiveDisabled}
                            onClick={() => setRestoreShowConfirm(true)}>
                        {strings.Archive.Restore}
                    </Button>
                }
                <SpacerExpand/>
                <Button kind={"secondary"} size={"compact"}
                        onClick={() => onCancelClick()}>{strings.General.Cancel}</Button>
                <Spacer/>
                <Button size={"compact"} onClick={() => onAssignClick()}>Apply</Button>
            </HFlex>
            <ConfirmModal
                isOpen={showDeleteConfirm}
                description={strings.Dispatch.ReallyDeleteSelectedJobs}
                onCancel={() => setDeleteShowConfirm(false)}
                onConfirm={onDeleteConfirmed}
                title={strings.General.Delete}
            />
            <ConfirmModal
                isOpen={showArchiveConfirm}
                description={strings.Archive.ArchiveDescription}
                onCancel={() => setArchiveShowConfirm(false)}
                onConfirm={onArchiveConfirmed}
                title={strings.Archive.Archive}
            />
            <ConfirmModal
                isOpen={showRestoreConfirm}
                description={strings.Archive.RestoreDescription}
                onCancel={() => setRestoreShowConfirm(false)}
                onConfirm={onRestoreConfirmed}
                title={strings.Archive.Restore}
            />
        </VFlex>
    );
}