import { ChangeEvent, useState } from "react";
import { useStrings } from "../../hooks/useStrings";
import { ConfirmModal } from "../ui/ConfirmModal";
import { checkPassword } from "../../services/privateApi";
import Password from "antd/es/input/Password";
import { VFlex } from "../containers/VFlex";
import { ParagraphXSmall } from "baseui/typography";
import { useTheme } from "../../hooks/useTheme";

type Props = {
    title?: string;
    open: boolean;
    onPasswordConfirmed: (confirmed: boolean) => void;
    onClose: () => void;
}

export function PasswordConfirmation({ title, open = false, onPasswordConfirmed, onClose }: Props) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const onPasswordConfirm = async () => {
        if (await checkPassword(confirmPassword)) {
            onPasswordConfirmed(true);
            onClose();
        } else {
            onPasswordConfirmed(false);
            setPasswordError(strings.General.IncorrectPassword);
        }
    };

    const handleCancelConfirm = () => {
        setConfirmPassword("");
        setPasswordError("");
        onClose();
    };

    const passwordChange = ({ target: { value }}: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(value);
        setPasswordError("")
    }

    return (
        <ConfirmModal
            isOpen={open}
            description={
                <VFlex spacing>
                    <Password
                        autoComplete="new-password"
                        placeholder={strings.General.EnterPasswordConfirm}
                        value={confirmPassword}
                        onChange={passwordChange}
                        status={passwordError ? "error" : undefined}
                    />
                    {passwordError && (
                        <ParagraphXSmall
                            margin={0}
                            overrides={{
                                Block: {
                                    style: {
                                        color: theme.colors.negative,
                                    }
                                }
                            }}
                        >
                            {passwordError}
                        </ParagraphXSmall>
                    )}
                </VFlex>
            }
            onCancel={handleCancelConfirm}
            onConfirm={onPasswordConfirm}
            title={title || strings.General.EnterPasswordConfirm}
            canConfirm={!!confirmPassword}
            cancelText={strings.General.Cancel}
            confirmText={strings.General.Accept}
        />
    )
}