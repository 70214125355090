import React from 'react';
import { Marker } from 'react-map-gl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useCamera } from '../../../hooks/useCamera';
import { MAP_ICON_DEFAULT_SIZE, mapIconSizeState } from '../../../states/mapState';
import { getColorFromProgress, getProgressStatus, numberOrLetter } from '../../../utils/jobUtils';
import { jobState } from "../../../states/jobsState";
import { editingJobIdState, pageState } from "../../../states/appState";
import { PAGE } from "../../../constants/pages";
import { useSingleJobSelection } from "../../../hooks/jobs/useSingleJobSelection";
import { COLOR_JOB_SELECTED } from "../../../constants/colors";
import { JobTooltip } from '../../job/JobTooltip';
import { addSpaceBetweenChars } from "../../../utils/strings";
import { clustersState } from "../../../states/clusteringState";
import { getClusterColor } from "../../../types/cluster";
import { dispatchTreeActiveCardState } from "../../../states/viewState";
import { sanitizeCoordinate } from "../../../utils/math";
import { Avatar } from "baseui/avatar";
import { PROGRESS_STATUS_SCHEDULED } from "../../../types/job";
import FixedJob from "./FixedJob";

type Props = {
    jobId: string;
    overrideLat?: number;
    overrideLng?: number;
};

function JobMarker({ jobId, overrideLat = undefined, overrideLng = undefined }: Props) {
    const job = useRecoilValue(jobState(jobId));
    const iconSize = useRecoilValue(mapIconSizeState);
    const camera = useCamera();
    const page = useRecoilValue(pageState);
    const selection = useSingleJobSelection(jobId);
    const setEditingJob = useSetRecoilState(editingJobIdState);
    const clusters = useRecoilValue(clustersState);
    const activeCard = useRecoilValue(dispatchTreeActiveCardState);

    const lat = sanitizeCoordinate(overrideLat || job?.destinationLat || 0, -90, 90);
    const lng = sanitizeCoordinate(overrideLng || job?.destinationLng || 0, -180, 180);

    const onMarkerClick = () => {
        camera.zoomTo({ lat, lng });
        if (page === PAGE.DISPATCH) {
            selection.toggleSelected();
        } else {
            setEditingJob(job?.id);
        }
    };

    if (!job) {
        return null;
    }

    const clusterIdx = page === PAGE.DISPATCH
        && activeCard === "clustering"
        && clusters.findIndex(c => c.jobs.includes(jobId));

    const color = clusterIdx
        ? getClusterColor(clusters[clusterIdx])
        : selection.isSelected ? COLOR_JOB_SELECTED : getColorFromProgress(job);

    const mini = iconSize < MAP_ICON_DEFAULT_SIZE;
    const abbreviation = numberOrLetter(job, mini ? "" : undefined);
    const miniSize = 20 * iconSize / 28;

    return (
        <Marker
            key={job.id + "" + mini}
            onClick={onMarkerClick}
            longitude={lng}
            latitude={lat}
            anchor={mini ? "center" : "bottom"}
        >
            <JobTooltip
                job={job}
                header={`${abbreviation} ${job.destinationName}`}
            >
                {mini
                    ? <Avatar
                        name={addSpaceBetweenChars(abbreviation)}
                        size={miniSize + "px"}
                        overrides={{
                            Initials: {
                                style: {
                                    fontSize: (miniSize * 0.66) + "px",
                                    color: getProgressStatus(job) === PROGRESS_STATUS_SCHEDULED ? "white" : "black",
                                }
                            },
                            Root: {
                                style: {
                                    backgroundColor: color
                                }
                            },
                        }}/>
                    : <FixedJob
                        text={abbreviation}
                        size={iconSize}
                        color={color}
                        needle={"medium"}
                    />
                }
            </JobTooltip>
        </Marker>
    );
}

export default React.memo(JobMarker)
