import { Column } from "jspreadsheet-ce";
import { getColumnConfig } from "../../utils/spreadsheets";
import { MembersImportAdvancedFields, MembersImportDefaultFields } from "../../constants/members";
import { useImportOptions } from "../useImportOptions";

export function useMembersImportConfig() {
    const { roleOptions } = useImportOptions();

    const name = MembersImportDefaultFields[MembersImportDefaultFields.Name];
    const email = MembersImportDefaultFields[MembersImportDefaultFields.Email];
    const phone = MembersImportDefaultFields[MembersImportDefaultFields.Phone];
    const role = MembersImportDefaultFields[MembersImportDefaultFields.Role];
    // Advanced fields
    const password = MembersImportAdvancedFields[MembersImportAdvancedFields.Password];

    const defaultColumnsConfig = {
        [name]: getColumnConfig(name, { width: 220 }),
        [email]: getColumnConfig(email, { width: 220 }),
        [phone]: getColumnConfig(phone),
        [role]: getColumnConfig(role, { type: "autocomplete", source: roleOptions }),
    };

    const advancedColumnsConfig = {
        [password]: getColumnConfig(password),
    };

    const defaultColumns = Object.values(defaultColumnsConfig);

    const getAdvancedColumnsOptions = (columns: Column[]) => {
        const columnFields = columns.map((column) => column.title);
        const advancedFields = Object.keys(advancedColumnsConfig);
        return advancedFields.filter((field) => !columnFields.includes(field));
    };

    return { defaultColumnsConfig, defaultColumns, getAdvancedColumnsOptions, advancedColumnsConfig };
}
