import {
    JobsLayerIcon,
    PlacesIcon,
    RoutesLayerIcon,
    TrafficIcon,
    WaypointsLayerIcon,
    ZonesIcon
} from '../components/ui/svg';
import { MODE } from "../services/api";

export const MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1IjoiaGVsbG90cmFja3MiLCJhIjoiY2' + (MODE === "PROD"
            ? "xmZm1zY3FnM2VoejN6bzRmOGNwYWhucSJ9._MPdOGzWOtcUYJwMEIhYjw"
            : 'swZmZxa3RjMDEwbTNtbXR5am53Zjh5MiJ9.GzersJJhrPg4zmPe-UTj8Q'
    );

const ROAD_MAP = 'roadmap';
const LIGHT_MAP = 'lightmap';
const SATELLITE_MAP = 'satellite';
const DARK_MAP = 'darkTheme';
const CLEAN_MAP = 'cleanMap';

export const HEAT_MAP = 'heatMap';
export const DEFAULT_MAP = ROAD_MAP;

export const ROAD_MAP_OBJ = {
    labelKey: 'Map.Roadmap',
    url: 'mapbox://styles/hellotracks/clz3qhiay00mq01pfepxtcq9x',
    img: 'images/db-roadmap.png',
    isDark: false,
};

export const LIGHT_MAP_OBJ = {
    labelKey: 'Map.Lightmap',
    url: 'mapbox://styles/hellotracks/cljfekxli008301qs54if596e',
    img: 'images/db-lightmap.png',
    isDark: false,
};

const SATELLITE_MAP_OBJ = {
    labelKey: 'Map.Satellite',
    url: 'mapbox://styles/hellotracks/cldptf6ef004e01tccrg8kt6d',
    img: 'images/db-satellite.png',
    isDark: true,
};

const DARK_MAP_OBJ = {
    labelKey: 'Map.Darkmap',
    url: 'mapbox://styles/hellotracks/cldptaz3s005601p4x0k6rr1u',
    img: 'images/db-darkmap.png',
    isDark: true,
};

const CLEAN_MAP_OBJ = {
    labelKey: 'Map.Clean',
    url: 'mapbox://styles/hellotracks/cl02hjd98001v14k9i1he3s14',
    img: 'images/db-quiet.png',
    isDark: true,
};

export const HEAT_MAP_OBJ = {
    labelKey: 'Map.Heatmap',
    url: 'mapbox://styles/hellotracks/ckh94ujvz3ta819t97mgrej8c',
    img: '',
    isDark: true,
};

export const MAP_TYPES_OBJ: {
    [key: string]: {
        labelKey: string;
        url: string;
        img: string;
        isDark: boolean;
    };
} = {
    [ROAD_MAP]: ROAD_MAP_OBJ,
    [LIGHT_MAP]: LIGHT_MAP_OBJ,
    [SATELLITE_MAP]: SATELLITE_MAP_OBJ,
    [DARK_MAP]: DARK_MAP_OBJ,
    [CLEAN_MAP]: CLEAN_MAP_OBJ,
};

export const PLACES_LAYER = 'places';
export const TRAFFIC_LAYER = 'traffic';
export const ZONES_LAYER = 'zones';
export const JOBS_LAYER = 'jobs';
export const ROUTES_LAYER = 'routes';
export const WAYPOINTS_LAYER = 'waypoints';

const PLACES_LAYER_OBJ = {
    id: PLACES_LAYER,
    labelKey: 'Map.Places',
    icon: PlacesIcon,
};

const TRAFFIC_LAYER_OBJ = {
    id: TRAFFIC_LAYER,
    labelKey: 'Map.Traffic',
    icon: TrafficIcon,
};

const ZONES_LAYER_OBJ = {
    id: ZONES_LAYER,
    labelKey: 'Map.Zones',
    icon: ZonesIcon,
};

const JOBS_LAYER_OBJ = {
    id: JOBS_LAYER,
    labelKey: 'Map.Jobs',
    icon: JobsLayerIcon,
};

const DAYROUTE_LAYER_OBJ = {
    id: ROUTES_LAYER,
    labelKey: 'Map.DayRoutes',
    icon: RoutesLayerIcon,
};

const WAYPOINTS_LAYER_OBJ = {
    id: WAYPOINTS_LAYER,
    labelKey: 'Map.Waypoints',
    icon: WaypointsLayerIcon,
};

export const MAP_LAYERS = [PLACES_LAYER_OBJ, TRAFFIC_LAYER_OBJ, ZONES_LAYER_OBJ, JOBS_LAYER_OBJ, DAYROUTE_LAYER_OBJ, WAYPOINTS_LAYER_OBJ];

export const MAP_TYPES = Object.entries(MAP_TYPES_OBJ).map(([key, value]) => ({
    mapType: key,
    ...value,
}));