import { Radio, RadioGroup } from "baseui/radio";
import { ReportFormat } from "../../types/reports";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedReportConfigState, selectedReportFormatState } from "../../states/reportState";

export function ReportFormatStep() {
    const reportConfig = useRecoilValue(selectedReportConfigState);
    const [format, setFormat] = useRecoilState(selectedReportFormatState);

    if (!reportConfig) {
        return null;
    }

    return (
        <RadioGroup
            value={format}
            onChange={(e) => setFormat(e.currentTarget.value as ReportFormat)}
            name="format"
            align="vertical"
        >
            {reportConfig.availableFormats.map((format) => (
                <Radio key={format} value={format}>
                    {format}
                </Radio>
            ))}
        </RadioGroup>
    );
}