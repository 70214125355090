import { Spacer } from "../../containers/Spacer";
import { HFlex } from "../../containers/HFlex";
import React, { useRef } from "react";
import { useHover } from "usehooks-ts";
import { EditIcon, LocateIcon } from "../../ui/svg";
import { Button } from "baseui/button";
import { LatLng, Uid } from "../../../types/core";
import { useCamera } from "../../../hooks/useCamera";
import { useRecoilState, useSetRecoilState } from "recoil";
import { editingMemberIdState, memberActiveTabState, memberRoutingOnlyState } from "../../../states/appState";
import { useTime } from "../../../hooks/useTime";
import { useTheme } from "../../../hooks/useTheme";
import { Tag } from "antd";
import { MEMBER_TABS } from "../../../constants/tabs";
import { COLOR_ONYX } from "../../../constants/colors";

type Props = {
    memberUid: Uid;
    text: string;
    location?: LatLng;
}

export function StartEnd({ memberUid, text, location }: Props) {
    const { formatSecondsToTime } = useTime();
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const { theme } = useTheme();
    const camera = useCamera();
    const setRoutingOnly = useSetRecoilState(memberRoutingOnlyState);
    const [editingMember, setEditingMember] = useRecoilState(editingMemberIdState);
    const setMemberActiveTab = useSetRecoilState(memberActiveTabState);

    const onLocateClick = () => {
        if (location) {
            camera.zoomTo(location);
        }
    }

    const onEditClick = () => {
        if (!editingMember) {
            setRoutingOnly(true);
            setEditingMember(memberUid);
        } else {
            setMemberActiveTab(MEMBER_TABS.routing);
        }
    }

    return (
        <div ref={hoverRef}>
            <HFlex alignCenter style={{ paddingTop: "4px", paddingBottom: "4px", height: "30px" }}>
                <Spacer width="24px"/>
                <Tag color={"default"} style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    borderColor: COLOR_ONYX,
                }}>{text}</Tag>
                <Spacer/>
                {isHover &&
                    <>
                        {location &&
                            <>
                                <Button
                                    size="mini"
                                    kind="secondary"
                                    shape="circle"
                                    onClick={(onLocateClick)}>
                                    <LocateIcon
                                        size={12}
                                        color={theme.colors.contentPrimary}/>
                                </Button>
                                <Spacer/>
                            </>}
                        <Button
                            size="mini"
                            kind="secondary"
                            shape="circle"
                            onClick={(onEditClick)}>
                            <EditIcon
                                size={12}
                                color={theme.colors.contentPrimary}/>
                        </Button>
                    </>
                }
            </HFlex>
        </div>
    );
}