import { atom, atomFamily } from "recoil";
import { atomWithPersistence, localStorageEffect } from "../utils/persistence";
import { Stop } from "../types/stops";
import { TimesheetEntry } from "../types/timesheet";

export type Requesting = {
    req: "timeline" | "tracks" | "routecalc" | "alljobs" | "stops" | "timesheet" | "places" | "jobtemplates" | "activities"
    uid?: string;
    day?: number;
}

export const requestingState = atomFamily<boolean, Requesting>({
    key: 'requesting',
    default: false,
});

export const expandedState = atomFamily<boolean, "dispatch-bulk">({
    key: 'view.expanded',
    default: false,
});

export const routingStatusState = atomFamily({
    key: "routing.status",
    default: true,
});

export const stopsByDayState = atomFamily<Stop[], number>({
    key: 'stops',
    default: [] as Stop[],
});

export const timesheetByDayState = atomFamily<TimesheetEntry[], number>({
    key: 'timesheet',
    default: [] as TimesheetEntry[],
});

export const timesheetEventState = atom({
    key: 'timesheet.event',
    default: 0,
});

export const formSubmissionEventState = atom({
    key: "formsubmission.event",
    default: 0,
})


export const activeTeamTabState = atomWithPersistence<"team" | "members">("team.tab.active", "members");

export const activePlacesZonesTabState = atomWithPersistence<"places" | "zones">("placeszones.tab.active", "places");

export const newMemberEventState = atom({
    key: 'newmember.event',
    default: 0,
});

export const globalDatePickerHighlightedState = atom({ 'key': "globaldatepicker.highlighted", 'default': false });

export const reportsViewActiveTab = atomWithPersistence("reports.view.activetab", "new");

export const alertsViewActiveTab = atomWithPersistence("alerts.view.activetab", "config");

export const timelineMileageCalendarOpenState = atomWithPersistence("timeline.mileagecalendar.open", false);

export type AllJobsActiveTab = "jobs" | "archived";

export const allJobsActiveTabState = atom<AllJobsActiveTab>({
    key: "alljobs.view.activetab",
    default: "jobs",
});

export const passwordRecoveryActivationState = atom<boolean>({
    key: "passwordrecovery.activation",
    default: false,
});

export type DispatchTreeActiveCard = "unassigned" | "jobs" | "templates" | "clustering" | "multiroute" | "pinned" | undefined;

export const dispatchTreeActiveCardState = atom<DispatchTreeActiveCard>({
    key: "dispatchtree.view.activecard",
    default: undefined,
});

export const dispatchTreeCollapsedState = atom<boolean>({
   key: "dispatchtree.view.collapsed",
   default: false,
});

export type TracksAndStopsConfig = {
    showTracks: boolean;
    showStopsAtPlaces: boolean;
    showStopsAtJobs: boolean;
    showStopsOther: boolean;
    showWaypoints: boolean;
}

export const tracksAndStopsConfigState = atomWithPersistence<TracksAndStopsConfig>(
    "view.tracksstops.config",
    {
        showTracks: true,
        showStopsAtPlaces: true,
        showStopsAtJobs: true,
        showStopsOther: true,
        showWaypoints: false,
    }
);

export const columnTitleReorderDialogState = atom<"orderId" | "priority" | undefined>({
    key: "view.column.title.reorder.dialog",
    default: undefined,
});

export type ExpandableSection = {
    sectionKey: string;
    defaultExpanded: boolean;
}

export const expandableSectionState = atomFamily<boolean, ExpandableSection>({
    key: 'expanded',
    default: (section) => section.defaultExpanded,
    effects: [localStorageEffect],
});

export const hoveringMediumCardState = atom({
    key: "view.mediumcard.hover",
    default: false,
});