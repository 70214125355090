import { memo, ReactNode } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { PAGE } from "../../constants/pages";
import { useBoard } from "../../hooks/useBoard";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { editingMemberIdState, pageState } from "../../states/appState";
import { HoverButton } from "../buttons/HoverButton";
import { HFlex } from "../containers/HFlex";
import { ArrowRightIcon, EditUserIcon, RemoveIcon } from "../ui/svg";
import { boardMemberIsPresentState } from "../../states/boardState";
import { Uid } from "../../types/core";

function MemberItemHoverButtons({ uid, extraIcon, children }: { uid: Uid; extraIcon?: ReactNode, children?: ReactNode }) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const page = useRecoilValue(pageState);
    const board = useBoard();
    const isPresent = useRecoilValue(boardMemberIsPresentState(uid))
    const setEditingMember = useSetRecoilState(editingMemberIdState);

    const onEditClick = () => {
        setEditingMember(uid);
    };

    const addRemoveTitle = (() => {
        const addTitle =
            page === PAGE.LOCATION
                ? strings.General.AddToTable
                : page === PAGE.LIVE
                    ? strings.General.Select
                    : strings.General.AddToBoard;
        const removeTitle =
            page === PAGE.LOCATION
                ? strings.General.RemoveFromTable
                : page === PAGE.LIVE
                    ? strings.General.Unselect
                    : strings.General.RemoveFromBoard;
        return isPresent ? removeTitle : addTitle;
    })();

    return (
        <HFlex alignCenter spacing={theme.sizing.scale100}>
            {children}
            <HoverButton size={"mini"} tooltip={strings.General.OpenProfile} onClick={onEditClick}>
                <EditUserIcon size={12} color={theme.colors.contentTertiary}/>
            </HoverButton>
            {(page === PAGE.DISPATCH ||
                page === PAGE.TIMELINE ||
                page === PAGE.HEATMAP ||
                page === PAGE.LOCATION ||
                page === PAGE.LIVE) && (
                <HoverButton
                    size={"mini"}
                    tooltip={addRemoveTitle}
                    onClick={() => {
                        if (isPresent) {
                            board.remove(uid);
                        } else {
                            board.add(uid);
                        }
                    }}
                >
                    {!isPresent ? (
                        <ArrowRightIcon size={12} color={theme.colors.contentTertiary}/>
                    ) : (
                        <RemoveIcon size={12} color={theme.colors.contentTertiary}/>
                    )}
                </HoverButton>
            )}
            {extraIcon}
        </HFlex>
    );
}

export default memo(MemberItemHoverButtons);
