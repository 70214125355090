import { ReactNode } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { messageApiState, notificationApiState, snoozeNotificationsState } from "../states/appState";
import { HFlex } from "../components/containers/HFlex";
import { SpacerExpand } from "../components/containers/Spacer";
import { AlarmDisableIcon } from "../components/ui/svg";
import { useTheme } from "./useTheme";
import { useStrings } from "./useStrings";
import { ActionButton } from "../components/buttons/ActionButton";

export function useToast() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const messageApi = useRecoilValue(messageApiState);
    const notificationApi = useRecoilValue(notificationApiState);
    const [snoozeNotifications, setSnoozeNotifications] = useRecoilState(snoozeNotificationsState);

    const showInfo = (text: string | ReactNode, key?: string) => {
        messageApi?.open({
            key,
            type: "info",
            content: text,
        });
    };

    const showSuccess = (text: string | ReactNode, key?: string) => {
        messageApi?.open({
            key,
            type: "success",
            content: text,
        });
    };

    const showWarning = (text: string | ReactNode, key?: string) => {
        messageApi?.open({
            key,
            type: "warning",
            content: text,
        });
    };

    const showLoading = (text: string | ReactNode, key?: string) => {
        messageApi?.open({
            key,
            type: "loading",
            content: text,
        });
    };

    const showNotification = (message: string | ReactNode, description?: string | ReactNode, snooze = false) => {
        if (snooze && snoozeNotifications) {
            return;
        }
        const title = snooze
            ? <HFlex>
                {message}
                <SpacerExpand/>
                <ActionButton tooltip={strings.General.Snooze}
                              size={"mini"}
                              kind={"tertiary"}
                              onClick={() => {
                                  setSnoozeNotifications(true);
                                  showNotification(strings.General.Snooze, strings.General.FrequentLiveNotificationsDesc);
                              }}
                >
                    <AlarmDisableIcon size={16} color={theme.colors.contentPrimary}/>
                </ActionButton>
            </HFlex>
            : message;

        notificationApi?.open({
            message: title,
            description: description,
        });
    };

    return { showInfo, showSuccess, showWarning, showLoading, showNotification };
}