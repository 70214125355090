import { useRecoilValue, useResetRecoilState } from 'recoil';
import { editingJobIdState } from '../../../states/appState';
import { editJobState } from '../../../states/editJobState';
import { StyledModalContent } from '../../ui/StyledModalContent';
import { JobModalFooter } from './JobModalFooter';
import { JobTabs } from './JobTabs';
import { SequenceModalFooter } from './SequenceModalFooter';
import { TemplateModalFooter } from './TemplateModalFooter';
import { JobModalHeader } from "./JobModalHeader";
import { EmptyState } from '../../ui/EmptyState';
import { AllJobsIcon } from '../../ui/svg';
import { Button } from 'baseui/button';
import { useTheme } from '../../../hooks/useTheme';
import { useStrings } from '../../../hooks/useStrings';
import { requestingState } from '../../../states/viewState';
import { Spin } from 'antd';
import { VFlex } from '../../containers/VFlex';

export function JobModalContent() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const jobId = useRecoilValue(editingJobIdState);
    const fields = useRecoilValue(editJobState(jobId || ''));
    const resetEditingJobId = useResetRecoilState(editingJobIdState);
    const allJobsRequesting = useRecoilValue(requestingState({ req: "alljobs" }));

    if (!fields) {
        return (
            <StyledModalContent
                body={
                    <VFlex style={{
                               minHeight: "250px",
                               justifyContent: "center",
                           }}
                    >
                        {allJobsRequesting ? (
                            <EmptyState>
                                <Spin/>
                            </EmptyState>
                        ) : (
                            <EmptyState title={strings.General.JobNotFound}
                                        cta={
                                            <Button size='compact'
                                                    onClick={() => {
                                                        resetEditingJobId();
                                                    }}
                                            >
                                                {strings.General.Close}
                                            </Button>
                                        }
                            >
                                <AllJobsIcon size={64} color={theme.colors.primary400}/>
                            </EmptyState>
                        )}
                    </VFlex>
                }
            />
        )
    }

    return (
        <StyledModalContent
            header={<JobModalHeader/>}
            body={<JobTabs/>}
            footer={
                fields?.isPartOfSequence ? (
                    <SequenceModalFooter/>
                ) : fields?.isTemplate ? (
                    <TemplateModalFooter/>
                ) : (
                    <JobModalFooter/>
                )
            }
        />
    );
}
