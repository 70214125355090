import { TourProps } from "antd";
import { flushSync } from "react-dom";
import { PAGE } from "../../constants/pages";
import { useNavigator } from "../useNavigator";
import { useOnboardRefs } from "./useOnboardRefs";
import { useOnboardStep } from "./useOnboardStep";
import { useStrings } from "../useStrings";
import { ONBOARD_JOBS } from "../../constants/onboard";
import { setRecoil } from "../../providers/RecoilAccessProvider";
import { dispatchTreeActiveCardState } from "../../states/viewState";

export function useOnboardJobs() {
    const { strings } = useStrings();
    const { navigateToPage } = useNavigator();
    const { dispatchRef, importJobsRef, jobListRef, addJobRef } = useOnboardRefs();
    const { step, nextStep, prevStep, closeStep, openStep, isOpen, canTrigger } = useOnboardStep(ONBOARD_JOBS);
    const jobsSteps: TourProps["steps"] = [
        {
            title: strings.Onboard.JobsTitle,
            description: strings.Onboard.JobsDescription,
            target: dispatchRef.current as HTMLElement,
            placement: "right",
            onNext: () => {
                flushSync(() => {
                    navigateToPage(PAGE.DISPATCH);
                });
                nextStep();
            },
            onClose: closeStep,
        },
        {
            title: strings.General.AllJobs,
            description: strings.Onboard.OpenListOfAllJobs,
            target: jobListRef.current as HTMLElement,
            placement: "bottom",
            onNext: () => {
                flushSync(() => {
                    setRecoil(dispatchTreeActiveCardState, "jobs");
                });
                nextStep();
            },
            onPrev: prevStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.ImportJobsTitle,
            description: strings.Onboard.ImportJobsDescription,
            target: importJobsRef.current as HTMLElement,
            placement: "bottom",
            onNext: nextStep,
            onPrev: prevStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.AddJobTitle,
            description: strings.Onboard.AddJobDescription,
            target: addJobRef.current as HTMLElement,
            placement: "bottom",
            onFinish: () => {
                finishOnboardJobs();
            },
            onPrev: prevStep,
            onClose: closeStep,
        },
    ];

    const finishOnboardJobs = () => {
        closeStep();
    };

    const initOnboardJobs = () => {
        openStep();
    };

    const voidFn = () => {};

    return {
        jobsSteps,
        initOnboardJobs,
        onboardJobsIsOpen: isOpen,
        jobStep: step,
        jobsPrevStep: canTrigger ? prevStep : voidFn,
        jobsNextStep: canTrigger ? nextStep : voidFn,
        finishOnboardJobs: canTrigger ? finishOnboardJobs : voidFn,
    };
}
