import useAccount from "./useAccount";

export function useUnit() {
    const { isImperial } = useAccount();

    const distanceToString = (meter: number, short = false) => {
        if (isImperial()) {
            const miles = meter * 0.621371 / 1000.0;
            const ft = meter * 3.28084;
            const roundedMiles = short ? Math.round(miles) : Math.round(miles * 10) / 10;
            const roundedFt = short ? Math.round(ft) : Math.round(Math.round(ft) * 10) / 10;
            return roundedMiles > 1 ? roundedMiles + "mi" : roundedFt + "ft";
        } else {
            const km = meter / 1000;
            const roundedKm = short ? Math.round(km) : Math.round(km * 10) / 10;
            const roundedMeter = short ? Math.round(meter) : Math.round(meter * 10) / 10;
            return km > 1 ? roundedKm + "km" : roundedMeter + "m";
        }
    };

    const speedToString = (kmh: number | undefined) => {
        if (!kmh) {
            return isImperial() ? "0mph" : "0km/h";
        } else if (isImperial()) {
            return Math.round(kmh * 0.621) + "mph";
        } else {
            return Math.round(kmh) + "km/h";
        }
    }

    return { distanceToString, speedToString };
}