import { useRecoilState } from "recoil";
import { companyState } from "../../states/accountState";
import { editAccount } from "../../services/api";
import { Company } from "../../types/company";
import { CompanyField } from "../../types/fields";

export function useEditCompany() {
    const [company, setCompany] = useRecoilState(companyState);

    const editField = async (field: CompanyField, value: string | number | boolean | any[]) => {
        if (!company) {
            return;
        }
        const { status, account: updatedCompany } = await editAccount({
            uid: company.uid, [field]: value
        });
        if (status) {
            setCompany(updatedCompany as Company);
        }
    }

    return { editField };
}