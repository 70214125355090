import { Spacer, SpacerExpand } from "../containers/Spacer";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Textarea } from "baseui/textarea";
import { FormItemCard } from "./FormItemCard";
import { VFlex } from "../containers/VFlex";
import { useStrings } from "../../hooks/useStrings";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { formQuestionsState, formTemplatesState, selectedFormState } from "../../states/formsState";
import { HFlex } from "../containers/HFlex";
import { Button } from "baseui/button";
import * as React from "react";
import { useEffect, useState } from "react";
import { FormItemType, FormTemplate } from "../../types/form";
import { FormBetweenItems } from "./FormBetweenItems";
import { deleteFormTemplate, saveFormTemplate } from "../../services/api";
import { useTheme } from "../../hooks/useTheme";
import { DocumentCopy, TrashIcon } from "../ui/svg";
import { ConfirmModal } from "../ui/ConfirmModal";
import { FormItemOption, useForms } from "../../hooks/useForms";
import { FormPlusButton } from "./FormPlusButton";
import { ActionButton } from "../buttons/ActionButton";
import { FormConditionalCard } from "./FormConditionalCard";

export function FormQuestions() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const selectedForm = useRecoilValue(selectedFormState);
    const setSelectedForm = useSetRecoilState(selectedFormState);
    const resetSelectedForm = useResetRecoilState(selectedFormState);
    const [form, setForm] = useRecoilState(formQuestionsState);
    const setFormTemplates = useSetRecoilState(formTemplatesState);
    const [saving, setSaving] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const forms = useForms();
    useEffect(() => setForm(selectedForm), [selectedForm]);
    const isNewForm = selectedForm?.id === "";
    if (!form) {
        return null;
    }

    const onSave = () => {
        const isNew = form.id === "";
        save(form, isNew);
    };

    const onDuplicate = () => {
        save({
            id: "",
            content: {
                ...form.content,
                title: strings.General.Copy + " " + form.content.title,
            }
        } as FormTemplate, true)
    }

    const save = (form: FormTemplate, isNew: boolean) => {
        setSaving(true);
        saveFormTemplate(form)
            .then(({ status, formTemplates, id }) => {
                if (status) {
                    setFormTemplates(formTemplates);
                    if (isNew) {
                        const newForm = formTemplates.find(f => f.id === id);
                        if (newForm) {
                            setSelectedForm(newForm);
                        }
                    } else {
                        setSelectedForm(form)
                    }
                }
            })
            .finally(() => setSaving(false));
    }

    const onDelete = () => {
        setSaving(true);
        deleteFormTemplate(form).then(({ status, formTemplates, id }) => {
            setSaving(false);
            if (status) {
                setFormTemplates(formTemplates);
                resetSelectedForm();
            }
        })
    };

    const onCancel = () => {
        resetSelectedForm();
    }

    const onPlusClick = (index: number, option: FormItemOption) => {
        setForm({
            ...form,
            content: forms.addItem(index, form.content, option),
        });
    };

    const onSetName = (index: number, name: string) => {
        setForm({
            ...form,
            content: forms.setName(index, form.content, name),
        });
    }

    const onSetOptions = (index: number, options: string[]) => {
        setForm({
            ...form,
            content: forms.setOptions(index, form.content, options),
        });
    };

    const onSetRequired = (index: number, required: boolean) => {
        setForm({
            ...form,
            content: forms.setRequired(index, form.content, required),
        });
    }

    const onRemove = (index: number) => {
        setForm({
            ...form,
            content: forms.removeItem(index, form.content),
        });
    }

    const onSetType = (index: number, type: FormItemType) => {
        setForm({
            ...form,
            content: forms.setType(index, form.content, type),
        });
    }

    const onSetTitle = (title: string) => {
        const copy = {
            ...form, content: {
                ...form.content,
                title: title,
            }
        } as FormTemplate;
        setForm(copy);
    };

    const onSetDescription = (description: string) => {
        const copy = {
            ...form, content: {
                ...form.content,
                description: description,
            }
        } as FormTemplate;
        setForm(copy);
    };

    const onAddConditional = (itemIndex: number, equalsTo: string, type: FormItemType) => {
        setForm({
            ...form,
            content: forms.addConditional(itemIndex, form.content, equalsTo, type),
        });
    };

    const onRemoveConditional = (itemIndex: number, conditionalIndex: number) => {
        setForm({
            ...form,
            content: forms.removeConditional(itemIndex, conditionalIndex, form.content),
        });
    }

    const onAddConditionalItem = (itemIndex: number, conditionalIndex: number, type: FormItemType) => {
        setForm({
            ...form,
            content: forms.addConditionalItem(itemIndex, conditionalIndex, type, form.content),
        });
    };

    const onRemoveConditionalItem = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number) => {
        setForm({
            ...form,
            content: forms.removeConditionalItem(itemIndex, conditionalIndex, conditionalItemIndex, form.content),
        });
    }

    const onSetConditionalItemName = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, name: string) => {
        setForm({
            ...form,
            content: forms.setConditionalItemName(itemIndex, conditionalIndex, conditionalItemIndex, form.content, name),
        });
    }

    const onSetConditionalItemOptions = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, options: string[]) => {
        setForm({
            ...form,
            content: forms.setConditionalItemOptions(itemIndex, conditionalIndex, conditionalItemIndex, form.content, options),
        });
    };

    const onSetConditionalItemRequired = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, required: boolean) => {
        setForm({
            ...form,
            content: forms.setConditionalItemRequired(itemIndex, conditionalIndex, conditionalItemIndex, form.content, required),
        });
    }

    const onSetConditionalItemType = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, type: FormItemType) => {
        setForm({
            ...form,
            content: forms.setConditionalItemType(itemIndex, conditionalIndex, conditionalItemIndex, form.content, type),
        });
    }

    return (
        <VFlex spacing style={{ flex: 1, paddingLeft: theme.sizing.scale600, paddingRight: theme.sizing.scale600 }}>
            <VFlex style={{ overflow: "auto" }}>
                <FormControl label={strings.General.Title}>
                    <Input placeholder={strings.Forms.FormsFormTitle}
                           value={form.content.title}
                           onChange={(ev) => onSetTitle(ev.target.value)}/>
                </FormControl>

                <FormControl label={strings.General.Description}>
                    <Textarea placeholder={strings.Forms.FormsFormDescription}
                              value={form.content.description}
                              onChange={(ev) => onSetDescription(ev.target.value)}/>
                </FormControl>
                {form.content.content.map((item, itemIndex) =>
                    <div key={itemIndex}>
                        <FormBetweenItems items={forms.allOptions}
                                          onPlusClick={(option) => onPlusClick(itemIndex, option)}/>
                        <FormItemCard formItem={item}
                                      onAddConditional={(equalsTo, type) => onAddConditional(itemIndex, equalsTo, type)}
                                      onSetName={(name) => onSetName(itemIndex, name)}
                                      onSetOptions={(options) => onSetOptions(itemIndex, options)}
                                      onSetRequired={(required) => onSetRequired(itemIndex, required)}
                                      onSetType={(type) => onSetType(itemIndex, type)}
                                      onRemove={() => onRemove(itemIndex)}
                        >
                            {item.conditionals?.map((conditionalItem, conditionalIndex) => (
                                <React.Fragment key={conditionalIndex}>
                                    <FormConditionalCard formConditionalItem={conditionalItem}
                                                         onAddConditionalItem={(type) => onAddConditionalItem(itemIndex, conditionalIndex, type)}
                                                         onSetConditionalItemName={(conditionalItemIndex, name) => onSetConditionalItemName(itemIndex, conditionalIndex, conditionalItemIndex, name)}
                                                         onSetConditionalItemOptions={(conditionalItemIndex, options) => onSetConditionalItemOptions(itemIndex, conditionalIndex, conditionalItemIndex, options)}
                                                         onSetConditionalItemRequired={(conditionalItemIndex, required) => onSetConditionalItemRequired(itemIndex, conditionalIndex, conditionalItemIndex, required)}
                                                         onSetConditionalItemType={(conditionalItemIndex, type) => onSetConditionalItemType(itemIndex, conditionalIndex, conditionalItemIndex, type)}
                                                         onRemoveConditional={() => onRemoveConditional(itemIndex, conditionalIndex)}
                                                         onRemoveConditionalItem={(conditionalItemIndex) => onRemoveConditionalItem(itemIndex, conditionalIndex, conditionalItemIndex)}
                                    />
                                    {conditionalIndex < (item.conditionals!.length - 1)  && <Spacer/>}
                                </React.Fragment>
                            ))}
                        </FormItemCard>
                    </div>
                )}
                <Spacer/>
                <HFlex>
                    <SpacerExpand/>
                    <FormPlusButton
                        items={forms.allOptions}
                        onPlusClick={(option) => onPlusClick(-1, option)}
                    />
                    <SpacerExpand/>
                </HFlex>
            </VFlex>
            <HFlex spacing>
                {!isNewForm && (
                    <ActionButton
                        size={"mini"}
                        onClick={onDuplicate}
                        kind={"tertiary"}
                        loading={saving}
                        tooltip={strings.General.Duplicate}
                    >
                        <DocumentCopy size={14} color={theme.colors.contentPrimary}/>
                    </ActionButton>
                )}
                {!isNewForm && (
                    <ActionButton
                        size={"mini"}
                        onClick={() => setShowDeleteModal(true)}
                        loading={saving}
                        kind={"tertiary"}
                        tooltip={strings.General.Delete}
                    >
                        <TrashIcon size={14} color={theme.colors.contentPrimary}/>
                    </ActionButton>
                )}
                <SpacerExpand/>
                <Button kind="tertiary" size="mini" onClick={onCancel}>
                    {strings.General.Cancel}
                </Button>
                <Button
                    size={"mini"}
                    onClick={onSave}
                    disabled={selectedForm === form}
                    isLoading={saving}
                >
                    {strings.General.Save}
                </Button>
            </HFlex>
            <ConfirmModal
                isOpen={showDeleteModal}
                description={strings.Forms.FormsDeleteForm}
                cancelText={strings.General.Cancel}
                confirmText={strings.General.Delete}
                onCancel={() => setShowDeleteModal(false)}
                onConfirm={onDelete}
            />
        </VFlex>
    );
}