import { Button } from 'baseui/button';
import { arrayMove, arrayRemove, List } from 'baseui/dnd-list';
import { Plus } from 'baseui/icon';
import { Input } from 'baseui/input';
import { StatefulPopover } from 'baseui/popover';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTheme } from '../../hooks/useTheme';
import { DE_TRANSLATION, EN_TRANSLATION, ES_TRANSLATION } from '../../locale/strings';
import { currentLanguageState } from '../../states/localeState';
import { selectedReportColumnsState, selectedReportConfigState } from '../../states/reportState';
import { HFlex } from '../containers/HFlex';
import { VFlex } from '../containers/VFlex';
import { StyledMenu } from '../ui/StyledMenu';
import { Checkbox } from "baseui/checkbox";
import { LabelXSmall } from "baseui/typography";

export function ReportColumnsStep() {
    const { theme } = useTheme();
    const reportConfig = useRecoilValue(selectedReportConfigState);
    const [reportColumns, setReportColumns] = useRecoilState(selectedReportColumnsState);
    const currentLanguage = useRecoilValue(currentLanguageState);
    const langIndex = currentLanguage === "de" ? DE_TRANSLATION : currentLanguage === "es" ? ES_TRANSLATION : EN_TRANSLATION;
    const [columns, setColumns] = useState(reportColumns);
    const columnKeys = columns?.map((c) => c.key);
    const removedColumns = reportConfig?.availableColumns?.filter((c) => {
        return !columnKeys?.includes(c.key)
    }).map((c) => ({ id: c.key, label: c.lang[langIndex], isCheck: c.isCheck }));

    useEffect(() => {
        setReportColumns(columns);
    }, [columns]);

    const items = useMemo(() => {
        return columns?.map((column, index) => {
            const availableColumn = reportConfig?.availableColumns?.find((c) => c.key === column.key);
            if (!availableColumn) {
                return null;
            }
            if (column.isCheck) {
                return (
                    <HFlex alignCenter spacing>
                        <Checkbox checked/>
                        <LabelXSmall>{availableColumn.lang[langIndex]}</LabelXSmall>
                    </HFlex>
                );
            }
            return (
                <Input
                    placeholder={availableColumn.lang[langIndex]}
                    value={column.rename ? column.rename : ''}
                    onChange={(e) => {
                        onChangeColumnName(e.target.value, index);
                    }}
                    size='mini'
                />
            )
        })
    }, [columns, langIndex, reportConfig?.availableColumns]);

    const onChangeColumnName = (value: any, index: number) => {
        if (!reportColumns) {
            return;
        }
        setColumns((prev) => {
            const newColumns = [...prev!];
            newColumns[index] = {
                ...newColumns[index],
                rename: value ? value : undefined
            };
            return newColumns
        });
    }

    const onPlusClick = (opt: { id: string, isCheck: boolean }) => {
        if (!columns) {
            return;
        }
        setColumns((prev) => [...prev!, { key: opt.id, rename: undefined, isCheck: opt.isCheck }])
    }

    if (!reportConfig || !columns || !reportColumns) {
        return null;
    }

    return (
        <VFlex style={{ maxWidth: "300px" }} spacing>
            <List
                items={items}
                removable
                onChange={({ oldIndex, newIndex }) => {
                    setColumns(newIndex === -1 ? arrayRemove(columns, oldIndex) : arrayMove(columns, oldIndex, newIndex));
                }}
                overrides={{
                    Item: {
                        style: {
                            paddingTop: theme.sizing.scale300,
                            paddingBottom: theme.sizing.scale300,
                            paddingRight: theme.sizing.scale300,
                            paddingLeft: theme.sizing.scale300,
                        }
                    }
                }}
            />
            {removedColumns && removedColumns?.length > 0 && (
                <HFlex style={{ justifyContent: "center" }}>
                    <StatefulPopover
                        placement='top'
                        content={({ close }) => (
                            <StyledMenu
                                items={removedColumns}
                                onItemSelect={(opt) => {
                                    onPlusClick(opt.item);
                                    close();
                                }}
                            />
                        )}
                    >
                        <Button
                            kind="primary"
                            shape="round"
                            size="mini"
                        >
                            <Plus size={12}/>
                        </Button>
                    </StatefulPopover>
                </HFlex>
            )}
        </VFlex>
    );
}
