import { TourProps } from "antd";
import { flushSync } from "react-dom";
import { useSetRecoilState } from "recoil";
import { sidebarMoreOpenState } from "../../states/appState";
import { useOnboardStep } from "./useOnboardStep";
import { PAGE } from "../../constants/pages";
import { useOnboardRefs } from "./useOnboardRefs";
import { useStrings } from "../useStrings";
import useAccount from "../useAccount";
import { useNavigator } from "../useNavigator";
import { ONBOARD_DOWNLOAD_APP, TOUR_DELAY } from "../../constants/onboard";

export function useOnboardDownload() {
    const { strings, format } = useStrings();
    const { username } = useAccount();
    const { downloadAppRef } = useOnboardRefs();
    const { navigateToPage } = useNavigator();
    const { step, nextStep, prevStep, closeStep, openStep, isOpen, canTrigger } = useOnboardStep(ONBOARD_DOWNLOAD_APP);
    const setOpenMore = useSetRecoilState(sidebarMoreOpenState);
    const downloadSteps: TourProps["steps"] = [
        {
            title: strings.Onboard.AppLoginTitle,
            description: (
                <div
                    dangerouslySetInnerHTML={{
                        __html: format(strings.Onboard.AppLoginDescription, username).toString(),
                    }}
                />
            ),
            target: downloadAppRef.current as HTMLElement,
            placement: "top",
            onNext: nextStep,
            onClose: closeStep,
            onFinish: () => {
                finishOnboardDownload();
            },
        },
    ];

    const finishOnboardDownload = async () => {
        closeStep();
    };

    const initOnboardDownload = () => {
        flushSync(() => {
            setOpenMore(true);
            navigateToPage(PAGE.SETTINGS);
        });
        setTimeout(openStep, TOUR_DELAY);
    };

    const voidFn = () => {};

    return {
        downloadSteps,
        initOnboardDownload,
        onboardDownloadIsOpen: isOpen,
        downloadStep: step,
        downloadsPrevStep: canTrigger ? prevStep : voidFn,
        downloadsNextStep: canTrigger ? nextStep : voidFn,
    };
}
