import React, { memo, useState } from "react";
import { useTheme } from "../../../hooks/useTheme";
import { useStrings } from '../../../hooks/useStrings';
import { ColumnCard } from "../../board/ColumnCard";
import { COLOR_ACCENT } from "../../../constants/colors";
import { LabelXSmall } from "baseui/typography";
import { Alert, Badge, Spin, Tag } from "antd";
import { NumberedStep, ProgressSteps } from "baseui/progress-steps";
import { VFlex } from "../../containers/VFlex";
import { HFlex } from "../../containers/HFlex";
import { Spacer } from "../../containers/Spacer";
import { AllJobsGrid } from "../../alljobs/AllJobsGrid";
import { Button } from "baseui/button";
import { ArrowRight, Check } from "baseui/icon";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { selectedJobIdsState } from "../../../states/jobsState";
import { useText } from "../../../hooks/useText";
import { pickerMembersState } from "../../../states/membersPickerState";
import { createPortal } from "react-dom";
import { StyledModal } from "../../ui/StyledModal";
import { MembersPickerModalContent } from "../../member/modal/MembersPickerModalContent";
import { useEffectOnce } from "usehooks-ts";
import { Member } from "../../../types/member";
import { useRouting } from "../../../hooks/useRouting";
import { constraintsState, dayState } from "../../../states/appState";
import { GlobalDatePicker } from "../../pickers/GlobalDatePicker";
import { useTime } from "../../../hooks/useTime";
import { AllJobsFilterRow } from "../../alljobs/AllJobsFilterRow";
import { OptimizeMultiRouteResponse } from "../../../services/privateApi";
import { useBoard } from "../../../hooks/useBoard";
import { MemberBadge } from "../../badges/MemberBadge";
import { JobBadge } from "../../badges/JobBadge";
import { Section } from "../../ui/Section";
import { Banner } from "baseui/banner";
import { MultiRouteSelectedMember } from "./MultiRouteSelectedMember";
import { ConstraintsTiles } from "./ConstraintsTiles";
import { createConstraints } from "../../../utils/routing";

export const MultiRouteColumn = memo(() => {
    const { format, strings } = useStrings();
    const { theme } = useTheme();
    const jobs = useRecoilValue(selectedJobIdsState);
    const [current, setCurrent] = useState(0);
    const resetSelectedMembers = useResetRecoilState(pickerMembersState);
    const [members, setMembers] = useState<Member[]>([]);
    const { getMembersTextFromArray } = useText();
    const [pickingMembers, setPickingMembers] = useState(false);
    const { multiRoute } = useRouting();
    const day = useRecoilValue(dayState);
    const constraints = useRecoilValue(constraintsState);
    const { formatDay } = useTime();
    const [processing, setProccessing] = useState(false);
    const [response, setResponse] = useState<OptimizeMultiRouteResponse>();
    const board = useBoard();

    useEffectOnce(() => {
        resetSelectedMembers();
    });

    const maxTotal = 1000;
    const selectedJobsString = jobs.length === 1
        ? strings.Job.OneSelectedJob
        : format(strings.Job.XSelectedJobs, jobs.length);

    const onMembersPicker = () => {
        setPickingMembers(true);
    }

    const closeMembersPickerModal = () => {
        setPickingMembers(false);
    };

    const onApply = async () => {
        setProccessing(true);
        const maxJobs = Math.ceil(jobs.length / members.length)
        const response = await multiRoute(members.map(m => m.uid), jobs, day, createConstraints(constraints, maxJobs));
        if (response.status && response.routes.length > 0 && response.routes.length <= 5) {
            board.add(response.routes.map(route => route.uid));
        }
        setResponse(response);
        setProccessing(false);
        setCurrent(old => old + 1);
    };

    const ActionRow = ({
                           nextText = strings.General.Next,
                           disabled = false,
                           onNext
                       }: { nextText?: string, disabled: boolean, onNext?: () => void }) => {
        return (
            <HFlex spacing>
                <Button
                    size={"compact"}
                    shape={"pill"}
                    onClick={() => setCurrent(old => old - 1)}
                    kind={"secondary"}
                >
                    {strings.General.Back}
                </Button>
                <Button
                    size={"compact"}
                    shape={"pill"}
                    kind={"primary"}
                    onClick={() => {
                        if (onNext) {
                            onNext();
                        } else {
                            setCurrent(old => old + 1);
                        }
                    }}
                    disabled={disabled}
                >
                    {nextText}
                    <Spacer/>
                    <ArrowRight size={14} color={theme.colors.contentInversePrimary}/>
                </Button>
            </HFlex>
        );
    }


    return (
        <ColumnCard
            color={COLOR_ACCENT}
            title={"Multi-Route Optimization"}
        >
            {!response && (
                <Alert closable
                       showIcon
                       type={"info"}
                       style={{ marginTop: "8px", marginBottom: "8px" }}
                       description={
                           <>
                               <Tag color={"gold"}>Beta</Tag>
                               <div dangerouslySetInnerHTML={{ __html: strings.Routing.MultiRouteOptimizationDescription }}/>
                               <LabelXSmall color={theme.colors.contentTertiary}>{strings.Routing.MultiRouteAlignment}</LabelXSmall>
                           </>
                       }
                />
            )}

            <ProgressSteps current={current}>
                <NumberedStep
                    title={current === 0
                        ? format(strings.Clustering.SelectUpToXJobsForClustering, maxTotal) + (jobs.length > 0 ? " (" + selectedJobsString + ")" : "")
                        : selectedJobsString}>
                    <VFlex spacing style={{ height: "400px" }}>
                        <AllJobsFilterRow/>
                        <AllJobsGrid/>
                        <HFlex>
                            <Button
                                size="compact"
                                onClick={() => setCurrent(1)}
                                shape={"pill"}
                                kind={"primary"}
                                disabled={jobs.length <= 1 || jobs.length > maxTotal}
                            >
                                {strings.General.Next}
                                <Spacer/>
                                <ArrowRight size={14}
                                            color={theme.colors.contentInversePrimary}
                                />
                            </Button>
                        </HFlex>
                    </VFlex>
                </NumberedStep>
                <NumberedStep title={current <= 1 ? "Select day" : formatDay(day)}>
                    <VFlex spacing>
                        <GlobalDatePicker/>
                        <ActionRow disabled={jobs.length <= 1}/>
                    </VFlex>
                </NumberedStep>
                <NumberedStep
                    title={current === 2
                        ? "Select members"
                        : getMembersTextFromArray(members, strings.Members.SelectMembers)}
                >
                    <VFlex spacing>
                        <HFlex spacing>
                            <div>
                                <Button
                                    size={"compact"}
                                    shape={"pill"}
                                    onClick={onMembersPicker}
                                    kind={members.length === 0 ? "primary" : "secondary"}
                                >
                                    {strings.Members.SelectMembers}
                                </Button>
                            </div>
                            <VFlex spacing>
                                {members.map(member => <MultiRouteSelectedMember
                                    uid={member.uid}
                                    onMemberExcluded={(uid) => setMembers(members.filter(member => member.uid !== uid))}
                                />)}
                            </VFlex>
                        </HFlex>
                        <ActionRow disabled={members.length === 0}/>
                    </VFlex>
                </NumberedStep>
                <NumberedStep
                    title={current <= 3 ? "Select routing constraints" : constraints.length + " routing constraints"}>
                    <VFlex spacing>
                        <ConstraintsTiles withEventDistribution/>
                        <ActionRow disabled={false}/>
                    </VFlex>
                </NumberedStep>
                <NumberedStep title={strings.General.Apply}>
                    {processing
                        ? <Spin/>
                        : <ActionRow nextText={"Apply Multi-Route Optimization"}
                                     disabled={false}
                                     onNext={onApply}
                        />}
                </NumberedStep>
                <NumberedStep title={"Summary"}>
                    <VFlex spacing>
                        {response?.routes
                            && response.routes.length > 0
                            && <Section expandable
                                        title={
                                            <HFlex
                                                spacing>{"Assigned routes"}
                                                <Badge color={theme.customColors.accent}
                                                       count={response.routes.length}/>
                                            </HFlex>
                                        }>
                                {response?.routes?.map(route => (
                                        <HFlex spacing alignCenter>
                                            <Check color={theme.customColors.accent}/>
                                            <MemberBadge edit uid={route.uid}/>
                                            <LabelXSmall>{format(strings.Job.XJobs, route.jobs.length)}</LabelXSmall>
                                        </HFlex>
                                    )
                                )}
                            </Section>
                        }

                        {response?.unassigned && response.unassigned.length > 0
                            && <Section expandable
                                        title={
                                            <HFlex spacing>{strings.Dispatch.UnassignedJobs}
                                                <Badge count={response.unassigned.length}/>
                                            </HFlex>
                                        }
                            >
                                {response?.unassigned?.map(id => <JobBadge id={id}/>)}
                            </Section>}
                        {response?.abortReason && <Banner kind={"warning"} title={"Multi routing aborted"}>
                            {response?.abortReason === "impossible_route"
                                ? "Unable to find a route. Please check the constraints and try again."
                                : response.abortReason}
                        </Banner>}
                    </VFlex>
                </NumberedStep>
            </ProgressSteps>
            {createPortal(
                <StyledModal onClose={closeMembersPickerModal} isOpen={pickingMembers}>
                    <MembersPickerModalContent
                        closeModal={closeMembersPickerModal}
                        onAccept={async (members) => setMembers(members)}
                        multi
                    />
                </StyledModal>,
                document.body
            )}
        </ColumnCard>
    );
});
