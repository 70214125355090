import { StyleObject } from "styletron-react";
import { TabOverrides } from "baseui/tabs-motion";

export const tabsOverrides = {
    Root: {
        style: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }
    },
};

export const tabOverrides = {
    TabPanel: {
        style: {
            overflowY: 'auto',
            scrollBehavior: 'smooth',
            flexGrow: 1,
            height: 'auto',
        },
    },
};

export const tabOverridesVertical = {
    TabPanel: {
        style: {
            overflowY: 'auto',
            scrollBehavior: 'smooth',
            flexGrow: 1,
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingRight: "8px",
        },
    },
    Tab: {
        style: {
            width: "min(150px, 25vw)",
            textAlign: "end",
        }
    }
} as TabOverrides;

export const tinyTag = {
    fontSize: "10px",
    paddingLeft: "4px",
    paddingRight: "4px",
    lineHeight: "13px",
}

export const tableHeaderStyle = {
    paddingTop: "4px",
    paddingBottom: "4px",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "32px",
}

export const fixedColumnStyle = (width: number, expandable = false, style?: any): StyleObject => {
    return {
        flex: `${ expandable ? '1' : '0'} 0 ${width}px`,
        width: width + "px",
        maxWidth: !expandable ? width + "px" : undefined,
        minWidth: width + "px",
        overflow: "hidden",
        boxSizing: "border-box",
        ...style,
    };
};