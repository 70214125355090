export const emailValidator = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

export const phoneValidator = /^(\+|00)/; // checks if start with + or 00

export const extraNumberValidator = /extra_number_\d+/;

export const extraTextValidator = /extra_text_\d+/;

export const anyDigit = /\d+/;

export const onlyNumbers = /^\d+$/;