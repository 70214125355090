import { useSetRecoilState } from "recoil";
import { newMemberState, updateAccountState, updateJobsState, updateMembersState } from "../states/updaterState";

export function useStateUpdater() {
    const updateNewMember = useSetRecoilState(newMemberState);
    const updateJobs = useSetRecoilState(updateJobsState);
    const updateAccount = useSetRecoilState(updateAccountState);
    const updateMembers = useSetRecoilState(updateMembersState);

    return { updateMembers, updateJobs, updateAccount, updateNewMember };
}