import { useTheme } from "./useTheme";

export function useComponentStyles() {
    const { css } = useTheme();

    const classnameEllipseText = ({ maxLines = 3, breakAll = false }) => css({
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": maxLines,
        display: "-webkit-box",
        overflow: "hidden",
        wordBreak: breakAll ? "break-all" : "break-word",
    });

    return { classnameEllipseText };
}