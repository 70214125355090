import { useRecoilValue, useSetRecoilState } from "recoil";
import { jobState, selectedJobIdsState } from "../../states/jobsState";
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { Job } from "../../types/job";

export function useAllJobsSelection() {
    const selectedIds = useRecoilValue(selectedJobIdsState);
    const setSelectedIds = useSetRecoilState(selectedJobIdsState);

    const clearSelection = () => {
        setSelectedIds([]);
    };

    const selectionCount = selectedIds.length;

    const select = (jobIds: string[]) => {
        const newIds = [...selectedIds.filter(id => !jobIds.includes(id)), ...jobIds];
        setSelectedIds(newIds);
    }

    const unselect = (jobIds: string[]) => {
        const newIds = [...selectedIds.filter(id => !jobIds.includes(id))];
        setSelectedIds(newIds);
    }

    const multiSelect = (job: Job, jobs: Job[]) => {
        if (selectedIds.length === 1 && jobs.length > 0) {
            const other = getRecoil(jobState(selectedIds[0]));
            if (other && other.receiverUid === job.receiverUid) {
                if (job.receiverUid.length > 0) {
                    const min = Math.min(other.number, job.number);
                    const max = Math.max(other.number, job.number);
                    select(jobs
                        .filter(job => job.number >= min && job.number <= max)
                        .map(job => job.id));
                } else {
                    const min = Math.min(other.tsCreated, job.tsCreated);
                    const max = Math.max(other.tsCreated, job.tsCreated);
                    select(jobs
                        .filter(job => job.tsCreated >= min && job.tsCreated <= max)
                        .map(job => job.id));
                }
                return true;
            }
        }
        return false;
    }

    const tableMultiSelect = (job: Job, jobs: Job[]) => {
        if (selectedIds.length === 1 && jobs.length > 0) {
            const initialJobId = selectedIds[0];
            const initialJobIndex = jobs.findIndex((j) => j.id === initialJobId);
            const endJobIndex = jobs.findIndex((j) => j.id === job.id);
            if (initialJobIndex !== -1 && endJobIndex !== -1) {
                const start = initialJobIndex < endJobIndex ? initialJobIndex + 1 : endJobIndex;
                const end = initialJobIndex > endJobIndex ? initialJobIndex : endJobIndex + 1;
                const jobsInRange = jobs.slice(start, end);
                select(jobsInRange.map((j) => j.id))
                return true;
            }
        }
        return false;
    }

    return { clearSelection, selectedIds, selectionCount, select, unselect, multiSelect, tableMultiSelect };
}