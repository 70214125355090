export const DISALLOWED_REJECT_JOB = 'disallowed';
export const ALLOWED_REJECT_JOB = 'allowed';
export const ALLOW_REASSIGN_REJECT_JOB = 'allowReassign';

export enum CHECK_IN_DELAY_MINUTES {
    zero = '0',
    one = '1',
    five = '5',
    ten = '10',
}

export enum CHECK_IN_RADIUS {
    short = '100',
    big = '300',
}

export enum DELETE_DAYS {
    never = '0',
    oneDay = '1',
    oneMonth = '30',
    threeMonths = '90',
}
