import { StyleObject } from "styletron-react";
import { Property } from "csstype";
import { useTheme } from "../../hooks/useTheme";

type Props = {
    children: React.ReactNode;
    style?: StyleObject;
    expand?: boolean;
    spacing?: Property.Gap;
    maxWidth?: number;
};

export function ResponsiveFlex({ children, style, maxWidth, expand = false, spacing = "0px" }: Props) {
    const { css, theme } = useTheme();
    const expanding = expand ? { flexGrow: 1 } : {};
    return (
        <div
            className={css({
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: spacing,
                [theme.mediaQuery.medium]: {
                    flexDirection: 'row',
                    maxWidth: `${maxWidth}px`,
                    alignItems: 'center',
                },
                ...style,
                ...expanding,
            })}>
            {children}
        </div>
    );
}