import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTeamManagement } from '../../hooks/members/useTeamManagement';
import { addingTeamState } from '../../states/appState';
import { teamsState } from '../../states/teamsState';
import { Member } from '../../types/member';
import { VFlex } from '../containers/VFlex';
import { InviteCodeSection } from './InviteCodeSection';
import { NoTeamSection } from './NoTeamSection';
import { TeamSection } from './TeamSection';
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";
import { pickerMembersConfigState } from "../../states/membersPickerState";
import { StyledModal } from "../ui/StyledModal";
import { MembersPickerModalContent } from "../member/modal/MembersPickerModalContent";
import { NewTeamModalContent } from "./modal/NewTeamModalContent";
import { ConfirmModal } from "../ui/ConfirmModal";
import { memberNamesFilteredState, memberSearchState } from '../../states/membersState';

export function TeamManagement() {
    const { theme } = useTheme();
    const { strings, format } = useStrings();
    const teams = useRecoilValue(teamsState);
    const [pickingMembers, setPickingMembers] = useState(false);
    const [addingTeam, setAddingTeam] = useRecoilState(addingTeamState);
    const [selectedTeam, setSelectedTeam] = useState<{ id: number; name: string; members: Member[] } | undefined>(undefined);
    const setPickersMembersConfigState = useSetRecoilState(pickerMembersConfigState);
    const [openRemoveMemberModal, setOpenRemoveMemberModal] = useState(false);
    const [memberToRemove, setMemberToRemove] = useState<Member | undefined>();
    const { addMembers, removeMember } = useTeamManagement();
    const memberFiltered = useRecoilValue(memberNamesFilteredState);
    const memberSearch = useRecoilValue(memberSearchState);
    const hideTeams = memberSearch.length > 0 && memberFiltered.length === 0;

    const onAddMember = (team: { id: number; name: string; members: Member[] }) => {
        setSelectedTeam(team);
        setPickersMembersConfigState({
            workers: true,
            operators: true,
            admins: true,
            excludedUids: team.members.map(m => m.uid),
        });
        setPickingMembers(true);
    };

    const onAcceptAddMembers = async (members: Member[]) => {
        if (!selectedTeam) {
            return;
        }
        await addMembers(selectedTeam.id, members);
    };

    const closeMemberPickerModal = () => {
        setPickingMembers(false);
        setSelectedTeam(undefined);
    };

    const closeNewTeamModal = () => {
        setAddingTeam(false);
    };

    const onRemoveMember = (team: { id: number; name: string; members: Member[] }, member: Member) => {
        setSelectedTeam(team);
        setMemberToRemove(member);
        setOpenRemoveMemberModal(true);
    };

    const handleRemoveMember = async () => {
        if (!selectedTeam || !memberToRemove) {
            return;
        }
        await removeMember(selectedTeam.id, memberToRemove, selectedTeam.members.length);
        setSelectedTeam(undefined);
        setMemberToRemove(undefined);
        setOpenRemoveMemberModal(false);
    };

    return (
        <VFlex
            spacing
            style={{
                flexGrow: 1,
                overflow: "auto",
                marginBottom: "28px",
                rowGap: theme.sizing.scale600,
            }}
        >
            <div>
                <InviteCodeSection/>
            </div>
            <div>
                <NoTeamSection/>
            </div>
            {!hideTeams && (
                teams.map((team) => (
                    <TeamSection
                        team={team}
                        onAddMember={onAddMember}
                        onRemoveMember={onRemoveMember}
                        key={team.id}
                    />
                ))
            )}
            <StyledModal isOpen={pickingMembers} onClose={closeMemberPickerModal}>
                <MembersPickerModalContent closeModal={closeMemberPickerModal} onAccept={onAcceptAddMembers}/>
            </StyledModal>
            <StyledModal isOpen={addingTeam} onClose={closeNewTeamModal}>
                <NewTeamModalContent closeModal={closeNewTeamModal}/>
            </StyledModal>
            {memberToRemove && selectedTeam && <ConfirmModal
                isOpen={openRemoveMemberModal}
                description={format(strings.TeamManagement.RemoveXFromY, memberToRemove.name, selectedTeam.name)}
                onCancel={() => setOpenRemoveMemberModal(false)}
                onConfirm={handleRemoveMember}
                cancelText={strings.General.Cancel}
                confirmText={strings.General.Remove}
                danger
            />}
        </VFlex>
    );
}
