import { useRecoilValue } from "recoil";
import { useTheme } from "../../hooks/useTheme";
import { formTemplatesSortedState } from "../../states/formsState";
import { UnorderedList } from "../containers/UnorderedList";
import { VFlex } from "../containers/VFlex";
import { EmptyState } from "../ui/EmptyState";
import { FormIcon } from "../ui/svg";
import { NewFormSubmissionItem } from "./NewFormSubmissionItem";
import { HeadingXSmall } from "baseui/typography";
import { useStrings } from "../../hooks/useStrings";

export function NewFormSubmissionList() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const templates = useRecoilValue(formTemplatesSortedState);

    return (
        <VFlex spacing style={{ flex: 1, overflow: "hidden", width: "100%" }}>
            <HeadingXSmall margin={0}>
                {strings.Forms.SelectForm}
            </HeadingXSmall>
            {templates.length === 0 && (
                <EmptyState>
                    <FormIcon size={64} color={theme.colors.contentPrimary}/>
                </EmptyState>
            )}
            <UnorderedList>
                {templates.map((f, index) => (
                    <NewFormSubmissionItem key={f.id || f.submissionUid || index} formTemplate={f}/>
                ))}
            </UnorderedList>
        </VFlex>
    );
}
