import 'string.prototype.replaceall/auto';

import { BaseProvider, createDarkTheme, createLightTheme, LightTheme } from 'baseui';
import { useEffect } from 'react';
import { Client as Styletron } from 'styletron-engine-monolithic';
import { Provider as StyletronProvider } from 'styletron-react';
import './index.css';
import { WindowHandler } from "./providers/WindowHandler";
import { useRecoilValue } from "recoil";
import { mapTypeObjectState } from "./states/mapState";
import { Route, Routes, useLocation } from 'react-router-dom'
import { DirectorView } from "./views/DirectorView";
import { PrintableJob } from "./components/job/PrintableJob";
import { PublishedTrackView } from "./views/PublishedTrackView";
import { Signup } from './components/signup/Signup';
import LoginView from './views/LoginView';
import PasswordRecoveryView from './components/activation/PasswordRecoveryView';
import { ConfigProvider, ThemeConfig } from 'antd';
import { ChatAi } from "./components/salesai/ChatAi";
import { SwitchView } from './views/SwitchView';
import { overridesDark, overridesLight, primitives, spreadSheetDarkTheme, spreadSheetLightTheme } from "./styles/theme";
import { LogoutView } from './views/LogoutView';
import { ActivationView } from './components/activation/ActivationView';
import NewFormSubmissionView from './views/NewFormSubmissionView';
import { AuthenticatedView } from "./views/AuthenticatedView";
import SsoView from "./views/SsoView";

const engine = new Styletron();

export default function HellotracksApp() {
    const location = useLocation();

    const isSignup = location.pathname.startsWith("/signup");
    const isSalesAi = location.pathname.startsWith("/chat");
    const isSinglePage = location.pathname.startsWith("/!") || location.pathname.startsWith("/go");
    const darkTheme = createDarkTheme({...primitives, ...overridesDark});
    const lightTheme = createLightTheme({...primitives, ...overridesLight});
    const mapTypeObject = useRecoilValue(mapTypeObjectState);
    const isDarkTheme = mapTypeObject.isDark;
    const appTheme = isDarkTheme ? darkTheme : lightTheme;
    const antdTheme: ThemeConfig = {
        components: {
            Switch: {
                colorPrimary: lightTheme.colors.buttonPrimaryFill,
                colorPrimaryHover: isDarkTheme ? appTheme.colors.buttonSecondaryHover : appTheme.colors.buttonPrimaryHover,
            },
            Input: {
                colorPrimaryHover: isDarkTheme ? appTheme.colors.buttonSecondaryHover : appTheme.colors.buttonPrimaryHover,
            }
        }
    }

    useEffect(() => {
        const spreadSheetThemeEntries = Object.entries(isDarkTheme ? spreadSheetDarkTheme : spreadSheetLightTheme);
        spreadSheetThemeEntries.forEach(([variable, value]) => {
            document.documentElement.style.setProperty(variable, value);
        });
        if (isDarkTheme) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    }, [isDarkTheme]);

    const theme = isSalesAi || isSinglePage ? LightTheme : isSignup ? darkTheme : appTheme;
    return (
        <StyletronProvider value={engine}>
            <BaseProvider theme={theme}>
                <ConfigProvider theme={antdTheme}>
                    <WindowHandler/>
                    <Routes>
                        <Route path="/chat" element={<ChatAi ai={"sales"}/>}/>
                        <Route path="/ama" element={<ChatAi ai={"help"}/>}/>
                        <Route path="/forms/submission" element={<NewFormSubmissionView />}/>
                        <Route path="/!/@j/:companyId/:jobId" element={<PrintableJob/>}/>
                        <Route path="/!/@t/:memberId/:trackId" element={<PublishedTrackView/>}/>
                        <Route path="/go/pwdrecovery/:paramToken" element={<PasswordRecoveryView/>}/>
                        <Route path="/go/activate/:paramToken" element={<ActivationView/>}/>
                        <Route path="/signup" element={<Signup/>}/>
                        <Route path="/login" element={<LoginView/>}/>
                        <Route path="/sso" element={<SsoView/>}/>
                        <Route path="/switch" element={<SwitchView/>}/>
                        <Route path="/authenticated" element={<AuthenticatedView/>}/>
                        <Route path="/logout" element={<LogoutView/>}/>
                        <Route path="/*" element={<DirectorView/>}/>
                    </Routes>
                </ConfigProvider>
            </BaseProvider>
        </StyletronProvider>
    );
}