import React from 'react';
import { useRecoilValue } from "recoil";
import { newestTripForAccountState, tripState } from "../../states/tripsState";
import { useTime } from "../../hooks/useTime";
import { COLOR_TRIP } from "../../constants/colors";
import { Tag, Tooltip } from "antd";
import { VFlex } from "../containers/VFlex";
import { Spacer } from "../containers/Spacer";
import { useStrings } from "../../hooks/useStrings";
import { tinyTag } from "../../constants/ui";
import { TripActiveStatus, TripProgressStatus } from "../../types/trip";

export function EtaBadge({ visible = true, uid, tripId }: { visible?: boolean, uid?: string, tripId?: string }) {

    const trip = useRecoilValue(uid ? newestTripForAccountState(uid) : tripState(tripId || ""));
    const { formatTimeOnly } = useTime();
    const { strings, format } = useStrings();

    if (!visible
        || !trip
        || trip.progressStatus !== TripProgressStatus.IN_PROGRESS
        || trip.activeStatus !== TripActiveStatus.ACTIVE) {
        return null;
    }

    return (
        <Tooltip title={
            <VFlex>
                <div>{format(strings.Trips.OnTheWayToX, trip.targetName)}</div>
                <Spacer/>
                <div>{strings.Trips.Started}: <strong>{formatTimeOnly(trip.createdTs)}</strong></div>
                <div>{strings.Trips.OriginalETA}: <strong>{formatTimeOnly(trip.originalEta)}</strong></div>
                <div>{strings.Trips.CurrentETA}: <strong>{formatTimeOnly(trip.etaTs)}</strong></div>
            </VFlex>
        }>
            <Tag color={COLOR_TRIP} style={tinyTag}>
                ETA {formatTimeOnly(trip.etaTs)}
            </Tag>
        </Tooltip>
    );
}