import { Button } from "baseui/button";
import { useAllJobsSelection } from "../../hooks/jobs/useAllJobsSelection";
import { StyledHead, StyledHeadCell } from "baseui/table";
import { ALL_JOBS_OPTIONS_COLUMN, ALL_JOBS_SELECT_COLUMN, ALL_JOBS_TITLE_COLUMN } from "../../constants/jobs";
import { tableHeaderStyle } from "../../constants/ui";
import { TristateCheckbox } from "../ui/TristateCheckbox";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { useAllJobsColumns } from "../../hooks/jobs/useAllJobsColumns";
import { useTheme } from "../../hooks/useTheme";
import { Job } from "../../types/job";
import { useTables } from "../../hooks/useTables";
import { Fragment } from "react";

export function AllJobsHeaderRow({ jobs }: { jobs: Job[] }) {
    const { isDarkTheme } = useTheme();
    const allSelection = useAllJobsSelection();
    const { fixedColumnStyle } = useStyleOverrides();
    const { columnsConfiguration, tableWidth, columnsWidth, enabledColumns } = useAllJobsColumns();
    const { getHeadCell } = useTables(columnsConfiguration);

    const toggleAllSelected = () => {
        if (allSelection.selectionCount > 0) {
            allSelection.unselect(allSelection.selectedIds)
        } else if (jobs.length <= 1000) {
            allSelection.select(jobs.map(j => j.id));
        }
    };

    return (
        <StyledHead role="row" $width={`${tableWidth}px`} style={{
            minWidth: "100%",
            backgroundColor: isDarkTheme ? "#00000088" : "#FFFFFF88"
        }}>
            <StyledHeadCell $style={{
                ...fixedColumnStyle(columnsWidth[ALL_JOBS_SELECT_COLUMN]), ...tableHeaderStyle,
                justifyContent: "center"
            }}>
                <Button
                    size="mini"
                    kind="tertiary"
                    disabled={allSelection.selectionCount === 0 && jobs.length > 1000}
                    onClick={(event) => toggleAllSelected()}
                >
                    <TristateCheckbox allSelected={false} anySelected={allSelection.selectionCount > 0}/>
                </Button>
            </StyledHeadCell>
            {getHeadCell(ALL_JOBS_TITLE_COLUMN)}
            {enabledColumns.map((column, index) => (
                <Fragment key={column+index}>
                    {getHeadCell(column)}
                </Fragment>
            ))}
            <StyledHeadCell
                $style={{ ...fixedColumnStyle(columnsWidth[ALL_JOBS_OPTIONS_COLUMN]), ...tableHeaderStyle }}/>
        </StyledHead>
    );
}