import { ThumbDownFilledIcon, ThumbUpFilledIcon } from "./svg";
import { COLOR_ISSUE, COLOR_SUCCESS } from "../../constants/colors";
import { LabelXSmall } from "baseui/typography";
import { VFlex } from "../containers/VFlex";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";

export function DoneDiv({ issue = false }: { issue?: boolean }) {
    const { strings } = useStrings();
    const { isDarkTheme } = useTheme();

    const color = issue ? COLOR_ISSUE : COLOR_SUCCESS;

    return (
        <VFlex style={{
            display: 'inline-flex',
            alignItems: 'center',
            verticalAlign: 'middle',
            borderStyle: "solid",
            borderColor: isDarkTheme ? "#222222" : "#EEEEEE",
            borderRadius: "4px",
            padding: "1px",
        }}>
            {issue
                ? <ThumbDownFilledIcon size={12} color={color}/>
                : <ThumbUpFilledIcon size={12} color={color}/>
            }
            <LabelXSmall style={{
                fontSize: "8px",
                fontWeight: "bold",
                lineHeight: "9px",
                color: color,
                marginLeft: '2px',
                marginRight: '2px'
            }}>
                {issue ? strings.JobStatuses.Issue : strings.JobStatuses.Done}
            </LabelXSmall>
        </VFlex>
    )
}