import { useMap } from "react-map-gl";
// @ts-ignore
import MapboxCircle from 'mapbox-gl-circle';
import { useRecoilValue } from "recoil";
import { placeState } from "../../../states/placeState";
import { useTheme } from "../../../hooks/useTheme";
import { useEditPlaces } from "../../../hooks/places/useEditPlaces";
import { RADIUS_MAX, RADIUS_MIN } from "../../../constants/places";
import { useEffect } from "react";
import { Uid } from "../../../types/core";

type Props = {
    placeUid: Uid;
};

export function EditPlaceCircle({ placeUid }: Props) {
    const { map } = useMap();
    const { theme } = useTheme();
    const place = useRecoilValue(placeState(placeUid));
    const editPlace = useEditPlaces();

    const onCenterChanged = (circle: MapboxCircle) => {
        editPlace.editPlaces([placeUid], {
            "location": {
                "lat": circle.center[1],
                "lng": circle.center[0],
            }
        });
    };

    const onRadiusChanged = (circle: MapboxCircle) => {
        circle.radius = Math.min(RADIUS_MAX, circle.radius);
        circle.radius = Math.max(RADIUS_MIN, circle.radius);
        editPlace.editPlaces([placeUid], { "radius": circle.radius });
    };

    useEffect(() => {
        if (!place || !place.longitude || !place.latitude || !place.radius) {
            return;
        }
        const circle = new MapboxCircle({ lat: place.latitude, lng: place.longitude }, place.radius, {
            editable: true,
            fillColor: place?.color,
            fillOpacity: 0.5,
            strokeColor: theme.colors.contentPrimary,
            strokeOpacity: 1,
            strokeWeight: 2,
            refineStroke: true,
        });
        circle.addTo(map?.getMap());

        circle.on('centerchanged', onCenterChanged);
        circle.on('radiuschanged', onRadiusChanged);
        return () => {
            if (map && map.getMap()) {
                try {
                    circle.remove();
                } catch (exc) {
                    console.warn("Circle", exc);
                    // FIXME on unselecting last place Uncaught TypeError: Cannot read properties of undefined (reading 'off') at MapboxCircle.remove (main.js:1252:1)
                }
            }
        }
    }, [place?.radius]);

    return null;
}