import { Tag } from "antd";
import { Button } from "baseui/button";
import { ChevronDown, ChevronRight } from "baseui/icon";
import { Input } from "baseui/input";
import { LabelLarge } from "baseui/typography";
import { forwardRef, memo } from "react";
import { SetterOrUpdater } from "recoil";
import useAccount from "../../hooks/useAccount";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { ItemButton } from "../buttons/ItemButton";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { ClockOutlineIcon, EditIcon, SettingsIcon, TrashIcon, VDotsIcon } from "../ui/svg";

type Props = {
    expanded: boolean;
    setExpanded: SetterOrUpdater<boolean>;
    isEditingWorkingHours: boolean;
    isEditingRouting: boolean; 
    children: React.ReactNode;
}

export const TeamSectionTitle = memo(forwardRef<HTMLDivElement, Props>(({ expanded, setExpanded, isEditingRouting, isEditingWorkingHours, children }: Props, ref) => {
    return (
        <div ref={ref}>
            <HFlex alignCenter>
                <Button
                    size="compact"
                    kind="tertiary"
                    onClick={() => setExpanded(!expanded)}
                    shape="square"
                    disabled={isEditingWorkingHours || isEditingRouting}
                >
                    {expanded ? <ChevronDown size={18}/> : <ChevronRight size={18}/>}
                </Button>
                {children}
            </HFlex>
        </div>
    )
}));

type TeamSectionTitleHoverProps = {
    onEditWorkingHours: () => void;
    onEditRouting: () => void;
    onDelete: () => void; 
}

export const TeamSectionTitleHover = memo(({ onDelete, onEditRouting, onEditWorkingHours }: TeamSectionTitleHoverProps) => {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const { isSelfAdminOrCompany } = useAccount();

    return (
        <>
            <ItemButton tooltip={strings.WorkingHours.TeamAvailability}
                        onClick={onEditWorkingHours}>
                <ClockOutlineIcon size={16} color={theme.colors.contentTertiary}/>
            </ItemButton>
            <ItemButton tooltip={strings.General.TeamRoutingSettings}
                        onClick={onEditRouting}>
                <SettingsIcon size={16} color={theme.colors.contentTertiary}/>
            </ItemButton>
            {isSelfAdminOrCompany &&
                <ItemButton tooltip={strings.General.DeleteTeam}
                            onClick={onDelete}>
                    <TrashIcon size={16} color={theme.colors.contentTertiary}/>
                </ItemButton>
            }
        </>
    )
});

type EditingTeamNameProps = {
    name: string;
    onNameChange: (value: string) => void;
    onUpdateTeamName: () => void;
    canSubmit: boolean;
    isLoading: boolean;
}

export const EditingTeamName = memo(({ name, onNameChange, onUpdateTeamName, canSubmit, isLoading }: EditingTeamNameProps) => {
    const { theme } = useTheme();
    const { strings } = useStrings();

    return (
        <HFlex spacing={theme.sizing.scale600}>
            <Input
                value={name}
                onChange={({ target: { value } }) => onNameChange(value)}
                size="mini"
                autoFocus
            />
            <Button
                onClick={onUpdateTeamName}
                size="mini"
                isLoading={isLoading}
                disabled={!canSubmit}
            >
                {strings.General.Save}
            </Button>
        </HFlex>
    )
});

type TeamSectionNameProps = {
    teamName: string;
    membersLength: number;
    showHoverContent: boolean;
    canEdit: boolean;
    onEditName: () => void;
    hoverContent?: React.ReactNode;
    showMoreButton: boolean;
}

export const TeamSectionName = memo(({ teamName, membersLength, showHoverContent, canEdit, onEditName, hoverContent, showMoreButton}: TeamSectionNameProps) => {
    const { theme } = useTheme();
    const { strings } = useStrings();

    return (
        <>
            <LabelLarge>{teamName}</LabelLarge>
            <Spacer/>
            {membersLength === 1 && <Tag>{membersLength} {strings.General.Member}</Tag>}
            {membersLength > 1 && <Tag>{membersLength} {strings.General.Members}</Tag>}
            {showHoverContent && canEdit && (
                <>
                    <Spacer/>
                    <ItemButton tooltip={strings.General.EditTeamName}
                                onClick={onEditName}
                    >
                        <EditIcon size={16} color={theme.colors.contentTertiary}/>
                    </ItemButton>
                </>
            )}
            <SpacerExpand/>
            {showMoreButton && (
                <ItemButton tooltip={strings.General.More}>
                    <VDotsIcon size={16} color={theme.colors.contentTertiary}/>
                </ItemButton>
            )}
            {hoverContent}
        </>
    )
});