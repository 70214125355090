import { selector } from "recoil";
import { CARD_WIDTH_SMALL } from "../constants/cards";
import { quickRouteCoordinatesState } from "./quickRouteState";

export const actionPanelModeState = selector({
    key: "action.panel.mode",
    get: ({ get }) => {
        const quickRouteCoordinates = get(quickRouteCoordinatesState);
        if (quickRouteCoordinates.length > 0) {
            return "quickroute";
        }
        return "none";
    },
});

export const actionPanelWidthState = selector({
    key: 'action.panel.width',
    get: ({ get }) => {
        const actionPanelMode = get(actionPanelModeState);
        if (actionPanelMode === "quickroute") {
            return CARD_WIDTH_SMALL;
        }
        return CARD_WIDTH_SMALL;
    }
});
