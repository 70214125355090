import { StyleObject } from 'styletron-react';
import { SortDirection } from 'baseui/table';
import { PAGE } from "../constants/pages";
import { TRUNCATE_LENGTH_DEFAULT, truncateText } from "../utils/strings";

export type Latitude = number;
export type Longitude = number;

export type Day = number & { readonly __brand: unique symbol };
export const EMPTY_DAY = 0 as Day;

export type Uid = string & { readonly __brand: unique symbol };
export type EmptyUid = Uid | "";
export const EMPTY_UID = "" as (Uid & EmptyUid);

export type Timestamp = number & { readonly __brand: unique symbol };
export const EMPTY_TS = 0 as Timestamp;

export interface LatLng {
    lat: Latitude;
    lng: Longitude;
}

export interface Waypoint extends LatLng {
    ts: Timestamp;
}

export interface Gps extends Waypoint {
    accuracy: number;
    speed: number;
    head: number;
}

export type LonLat = [Longitude, Latitude];

export interface Credentials {
    user: string;
    pass: string;
}

export interface Team {
    route?: Route;
    working_hours: WorkingHours;
    name: string;
    id: number;
}

export interface Route {
    start_lng: Longitude;
    start_lat: Latitude;
}

export interface JobType {
    id: number;
    name: string;
}

export interface SimpleLocation extends LatLng {
    name: string;
}

export interface DetailedLocation extends SimpleLocation {
    display: string;
    address: string;
    city: string;
    postalcode: string;
    state: string;
    country_code: string;
    streetname: string;
    streetnumber: string;
    uid: Uid;
}

export interface DetailedLocationWithRadius extends DetailedLocation {
    radius?: number;
}

export const TYPE_COMPANY = 'company';
export const TYPE_PERSON = 'person';
export const TYPE_PLACE = 'place';

export type AccountType = typeof TYPE_COMPANY | typeof TYPE_PERSON | typeof TYPE_PLACE;

export interface Account {
    uid: Uid;
    email: string;
    email_verified: boolean;
    language: string;
    location: Gps;
    name: string;
    phone: string;
    phone_verified: boolean;
    portrait: string;
    timezone: string;
    type: AccountType;
}

export const SOURCE_INDIVIDUAL = 'individual';
export const SOURCE_TEAM = 'team';
export const SOURCE_DEFAULT = 'default';

export type WorkingHoursSource = typeof SOURCE_INDIVIDUAL | typeof SOURCE_TEAM | typeof SOURCE_DEFAULT;

export interface WorkingHours {
    sunday: DayWindow;
    saturday: DayWindow;
    tuesday: DayWindow;
    wednesday: DayWindow;
    thursday: DayWindow;
    friday: DayWindow;
    monday: DayWindow;
    source: WorkingHoursSource;
}

const DEFAULT_WORKING_HOUR_DAY: DayWindow = {
    start: 800,
    end: 1800
}

export const DEFAULT_WORKING_HOURS: WorkingHours = {
    sunday: DEFAULT_WORKING_HOUR_DAY,
    saturday: DEFAULT_WORKING_HOUR_DAY,
    tuesday: DEFAULT_WORKING_HOUR_DAY,
    wednesday: DEFAULT_WORKING_HOUR_DAY,
    thursday: DEFAULT_WORKING_HOUR_DAY,
    friday: DEFAULT_WORKING_HOUR_DAY,
    monday: DEFAULT_WORKING_HOUR_DAY,
    source: SOURCE_DEFAULT
}

export interface DayWindow {
    start: number;
    end: number;
}

export interface CustomField {
    key: string;
    val: string;
}

export interface InviteCodeData {
    groupName: string;
    expirationTs: Timestamp;
    isValid: boolean;
    inviteCode: string;
    isCompany: boolean;
    isMember: boolean;
    isExpired: boolean;
    isEditPermitted: boolean;
    isUsagePermitted: boolean;
    groupUid: Uid;
}

export interface Address {
    streetNumber: string;
    streetAddress: string;
    city: string;
    state: string;
    country: string;
    countryCode: string;
    postalCode: string;
    formatted: string;
}

export type IdentityProvider = "okta" | "azure_ad" | "basic_oidc";

export type SsoConfig = {
    identity_provider: IdentityProvider;
    tenant_id?: string;
    client_id: string;
    client_secret?: string;
    redirect_url: string;
}

export type UidDay = {
    uid: Uid;
    day: Day;
}

export type UidName = {
    uid: Uid;
    name: string;
}

export type UidNameUsername = {
    uid: Uid;
    name: string;
    username: string;
}

export type UidTimestamp = {
    uid: Uid;
    ts: Timestamp;
}

export type UidNumber = {
    uid: Uid;
    number: number;
}

export interface LocationData extends LatLng {
    address?: string;
    placeUid?: string;
}

export type Filter = {
    id: string;
    label: string;
    matches: Function;
    hidden?: boolean;
    value?: any;
    group?: string;
}

export type PageType = typeof PAGE[keyof typeof PAGE];

export type Language = "en" | "es" | "de";

export const getTruncatedTeamName = (team: Team) => {
    return team.name && team.name.length > TRUNCATE_LENGTH_DEFAULT ? truncateText(team.name) : team.name;
}

export function getSsoConfig(ssoConfig: string | undefined): SsoConfig | undefined {
    return ssoConfig ? JSON.parse(ssoConfig) as SsoConfig : undefined;
}

export type ColumnsWidthType = {
    [key: string]: number;
};

export interface ColumnsConfiguration {
    [key: string]: {
        title: string;
        direction: SortDirection;
        styles: StyleObject;
        onSort?: () => void;
        onResize?: (width: number) => void;
        initialWidth?: number;
        minWidth?: number;
    };
}

export interface Comment {
    ts: Timestamp;
    uid: Uid;
    text: string;
}

export interface Label {
    id: string;
    text: string;
    color: string;
}

export interface Constraints {
    working_hours: boolean;
    time_window: boolean;
    skills: boolean;
    capacity: boolean;
    max_jobs?: number;
}