import { VFlex } from "../containers/VFlex";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { useUnit } from "../../hooks/useUnit";
import { COLOR_MOVING } from "../../constants/colors";
import React from "react";
import { TrackEntry } from "../../types/track";
import { interpolateSpeeds } from "../../utils/trackUtils";
import { LabelXSmall } from "baseui/typography";
import { useStrings } from "../../hooks/useStrings";
import { ProgressSparklineSpot } from "./ProgressSparklineSpot";

export function TrackSpeedHistogram({ entry, width = 360 }: { entry: TrackEntry, width?: number }) {
    const unit = useUnit();
    const { strings } = useStrings();

    // workaround as the hover info object returned by mapbox converts the array to a string
    const timestamps = typeof entry.timestamps === "string"
        ? JSON.parse(entry.timestamps).map((element: any) => Number(element))
        : entry.timestamps;
    const speeds = typeof entry.speeds === "string"
        ? JSON.parse(entry.speeds).map((element: any) => Number(element))
        : entry.speeds;
    if (speeds.length !== timestamps.length) {
        return null;
    }
    const max = Math.max(...speeds);
    const data = interpolateSpeeds(speeds, timestamps, 200);

    return (
        <VFlex alignCenter style={{ width: "100%", height: "60px", marginTop: "8px" }}>
            <Sparklines data={data} margin={8} width={width * 2} height={100}>
                <SparklinesLine style={{ strokeWidth: 3, stroke: COLOR_MOVING, fill: "none" }}/>
                <ProgressSparklineSpot size={8}
                                       style={{ stroke: COLOR_MOVING, strokeWidth: 3, fill: "white" }}/>
            </Sparklines>
            <LabelXSmall color={COLOR_MOVING}>{strings.Dashboard.SpeedDiagram} (max {unit.speedToString(max)})</LabelXSmall>
        </VFlex>
    );
}