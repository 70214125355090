import useAccount from "./useAccount";

export function usePermissions() {
    const account = useAccount();

    const mayDeleteJobs = () => {
        return account.isSelfAdminOrCompany || (account.isSelfOperator && account.company?.dispatcher_delete_jobs);
    }

    return { mayDeleteJobs };
}