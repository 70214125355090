import { useRecoilState, useRecoilValue } from "recoil";
import { jobsByMemberDayState, selectedJobIdsState } from "../../../states/jobsState";
import { dayState } from "../../../states/appState";
import { MemberBadge } from "../../badges/MemberBadge";
import { SingleCheckIcon, WarningSignIcon } from "../../ui/svg";
import { Dropdown, Tooltip } from "antd";
import { Button } from "baseui/button";
import { HFlex } from "../../containers/HFlex";
import React from "react";
import { useTheme } from "../../../hooks/useTheme";
import { useStrings } from "../../../hooks/useStrings";
import { Uid } from "../../../types/core";

export function MultiRouteSelectedMember({ uid, onMemberExcluded }: { uid: Uid; onMemberExcluded: (uid: Uid) => void }) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const day = useRecoilValue(dayState);
    const jobs = useRecoilValue(jobsByMemberDayState({ uid, day }));
    const [selectedJobs, setSelectedJobs] = useRecoilState(selectedJobIdsState);

    const buttonOverrides: any = {
        BaseButton: {
            style: {
                fontSize: '12px',
                paddingTop: '2px',
                paddingBottom: '2px',
                paddingLeft: '8px',
                paddingRight: '8px',
            },
        },
    };

    const missingJobs = jobs.filter(job => !selectedJobs.includes(job.id));
    const showWarning = missingJobs.length > 0;

    return (
        <HFlex spacing alignCenter>
            <MemberBadge uid={uid}/>
            {!showWarning && <SingleCheckIcon size={12} color={theme.customColors.accent}/>}
            {showWarning &&
                <Tooltip title={"This member has existing job assignments. Multi-route optimization will not consider these jobs. "}>
                    <Dropdown onOpenChange={(isOpen) => {}} menu={{
                        items: [
                            {
                                key: '1',
                                label: "Include all already assigned jobs to multi-route optimization",
                                onClick: () => setSelectedJobs(old => [...old, ...missingJobs.map(job => job.id)]),
                            },
                            {
                                key: '2',
                                label: "Exclude this member for multi-route optimization",
                                onClick: () => onMemberExcluded(uid),
                            },
                        ]
                    }} placement="bottomLeft" arrow>
                        <Button size={"mini"}
                                kind={"primary"}
                                overrides={buttonOverrides}
                                onClick={() => {}}
                        >
                            <WarningSignIcon size={12} color={theme.colors.contentInversePrimary}/>
                        </Button>
                    </Dropdown>
                </Tooltip>}
        </HFlex>
    );
}