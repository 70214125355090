import { Zone } from "../../types/zone";
import { CheckboxCheckedIcon, CheckboxUncheckedIcon, ZoneIcon } from "../ui/svg";
import { Button } from "baseui/button";
import React from "react";
import { useZoneSelection } from "../../hooks/zones/useZoneSelection";
import { useTheme } from "../../hooks/useTheme";
import { useSetRecoilState } from "recoil";
import { Centered } from "../containers/Centered";
import { editingZoneIdState } from "../../states/appState";
import { MemberBadges } from "../badges/MemberBadges";
import { StyledCell, StyledRow } from "baseui/table";
import { EllipsedText } from "../ui/EllipsedText";
import { TeamsBadges } from "../badges/TeamsBadges";
import { LabelSmall } from "baseui/typography";
import { VFlex } from "../containers/VFlex";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { TimeBadge } from "../badges/TimeBadge";

type Props = {
    data: Zone[];
    index: number;
    style: React.CSSProperties;
}

export function ZoneRow({ data, index, style }: Props) {
    const zone = data[index];
    const { theme } = useTheme();
    const { isSelected, toggleSelected, tableMultiSelect } = useZoneSelection();
    const setEditingZone = useSetRecoilState(editingZoneIdState);
    const { gridStyle, zonesColumnStyle } = useStyleOverrides();

    if (!zone) {
        return null;
    }

    return (
        <StyledRow role="row" style={{ ...style, ...gridStyle(isSelected(zone.uid)) }}>
            <StyledCell $style={{ ...zonesColumnStyle[0], justifyContent: "center" }}>
                <span
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        if (event.shiftKey && tableMultiSelect(zone, data)) {
                            return;
                        }
                        toggleSelected(zone.uid);
                    }}
                >
                    <Button
                        size="mini"
                        kind="tertiary"
                    >
                        {isSelected(zone.uid)
                            ? <CheckboxCheckedIcon size={18} color={theme.colors.contentPrimary}/>
                            : <CheckboxUncheckedIcon size={18} color={theme.colors.contentPrimary}/>
                        }
                    </Button>
                </span>
            </StyledCell>
            <StyledCell $style={zonesColumnStyle[1]}>
                <Centered>
                    <ZoneIcon size={18} color={zone.color}/>
                </Centered>
            </StyledCell>
            <StyledCell $style={zonesColumnStyle[2]}>
                <Button size={"mini"} kind={"tertiary"} onClick={() => setEditingZone(zone.uid)}
                        style={{ overflow: "hidden" }}>
                    <VFlex style={{ overflow: "hidden" }}>
                        <LabelSmall style={{ textAlign: "start" }}>
                            <EllipsedText inline breakAll>{zone.name}</EllipsedText>
                        </LabelSmall>
                        <TeamsBadges inline shortNames={zone.assignedTeams.length > 3} ids={zone.assignedTeams}
                                     max={3}/>
                        <MemberBadges inline shortNames={zone.assignedMembers.length > 3} members={zone.assignedMembers}
                                      max={3}/>
                    </VFlex>
                </Button>
            </StyledCell>
            <StyledCell $style={zonesColumnStyle[3]}>
                <TimeBadge startMillis={zone.created} withDate/>
            </StyledCell>
        </StyledRow>
    );
}