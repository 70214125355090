import { Tag } from "antd";
import { memo, useState } from "react";
import { StatusInfo } from "../../../hooks/members/useMemberInfo";
import { useStrings } from "../../../hooks/useStrings";
import { useTheme } from "../../../hooks/useTheme";
import { useToast } from "../../../hooks/useToast";
import { resendInvite } from "../../../services/api";
import { ActionButton } from "../../buttons/ActionButton";
import { HFlex } from "../../containers/HFlex";
import { SendIcon } from "../../ui/svg";
import { Uid } from "../../../types/core";

function MemberStatusRowItem({ uid, statusInfo }: { uid: Uid, statusInfo: StatusInfo }) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const [loading, setLoading] = useState(false);
    const [inviteResent, setInviteResent] = useState(false);
    const toast = useToast();

    const onResendInviteClick = async () => {
        setLoading(true);
        const response = await resendInvite(uid);
        if (response.status) {
            setInviteResent(true);
            toast.showSuccess(strings.General.ResendInvitation);
        } else {
            toast.showWarning(strings.General.SomethingWentWrong);
        }
        setLoading(false);
    };

    return (
        <Tag color={statusInfo.color}>
            {statusInfo.status === "invited" ? (
                <HFlex alignCenter>
                    {statusInfo.text}
                    <ActionButton
                        tooltip={strings.General.ResendInvitation}
                        size={"mini"}
                        kind={"tertiary"}
                        disabled={inviteResent}
                        loading={loading}
                        onClick={onResendInviteClick}
                    >
                        <SendIcon size={9} color={theme.colors.contentPrimary}/>
                    </ActionButton>
                </HFlex>
            ) : (
                statusInfo.text
            )}
        </Tag>
    );
}

export default memo(MemberStatusRowItem);