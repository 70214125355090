import { VFlex } from "../containers/VFlex";
import { MemberSearch } from "../member/MemberSearch";
import { Skeleton } from "baseui/skeleton";
import { StyledTable } from "baseui/table";
import React, { useState } from "react";
import { useActivities } from "../../hooks/useActivities";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { ActivityLogRow } from "./ActivityLogRow";
import { ActivityLogHeaderRow } from "./ActivityLogHeaderRow";
import { useRecoilValue } from "recoil";
import { memberNamesFilteredState, memberSearchState } from "../../states/membersState";
import { SIZE } from "baseui/button-group";
import { Filter } from "baseui/icon";
import { Input } from "baseui/input";
import { HFlex } from "../containers/HFlex";
import { Spacer } from "../containers/Spacer";
import { default as AutoSizer } from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import { useStrings } from "../../hooks/useStrings";

export function ActivityLog() {
    const { strings } = useStrings();
    const { activities, fetching } = useActivities();
    const { tableStyle } = useStyleOverrides();
    const memberSearch = useRecoilValue(memberSearchState);
    const memberNames = useRecoilValue(memberNamesFilteredState);
    const [activitySearch, setActivitySearch] = useState("");

    const isSearching = activitySearch.length > 0;

    const activitiesFilteredByMember = memberSearch.length === 0
        ? activities
        : activities.filter(activity => memberNames.some(member => activity.uid === member?.uid));

    const filteredActivities = isSearching
        ? activitiesFilteredByMember.filter(a => a.message.toLowerCase().includes(activitySearch.toLowerCase()))
        : activitiesFilteredByMember;

    const activitySearchInput = (<Input
            value={activitySearch}
            onChange={(e) => setActivitySearch(e.target.value)}
            type="text"
            placeholder={strings.Activities.SearchActivity}
            clearable
            size={SIZE.mini}
            startEnhancer={<Filter/>}
            overrides={{
                Root: {
                    style: {
                        paddingLeft: "0px",
                        paddingRight: "0px",
                    }
                },
                Input: {
                    style: {
                        paddingLeft: "0px",
                        paddingRight: "0px",
                    }
                }
            }}
        />
    );

    return (
        <VFlex spacing>
            <HFlex>
                <MemberSearch/>
                {activitySearchInput}
            </HFlex>
            <VFlex spacing style={{ overflowY: "hidden", overflowX: "auto" }}>
                {fetching && <Skeleton width={"100%"} height={"200px"} animation/>}
                {!fetching && (
                    <StyledTable role="grid" style={{ ...tableStyle, minWidth: "580px" }}>
                        <ActivityLogHeaderRow/>
                        <div style={{ flex: 1 }}>
                            <AutoSizer>
                                {({ height, width }) => {
                                    return (
                                        <List height={height}
                                              itemCount={filteredActivities.length}
                                              itemSize={() => 42}
                                              width={width}
                                              itemData={{ activities: filteredActivities }}>
                                            {ActivityLogRow}
                                        </List>
                                    )
                                }}
                            </AutoSizer>
                        </div>
                    </StyledTable>
                )}
            </VFlex>
            <Spacer/>
        </VFlex>
    );
}