import { StatefulTooltip } from 'baseui/tooltip';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { stringsState } from '../../states/localeState';
import { copyToClipboard } from '../../utils/app';
import { ClipboardIcon } from '../ui/svg';
import { Clickable } from "../containers/Clickable";
import { useTheme } from '../../hooks/useTheme';

type Props = {
    content: string | number;
};

export function ClipboardButton({ content }: Props) {
    const { theme } = useTheme();
    const strings = useRecoilValue(stringsState);
    const [tooltip, setTooltip] = useState(strings.General.Copy);

    const onClick = () => {
        copyToClipboard(`${content}`);
        setTooltip(strings.General.Copied);
    };

    return (
        <StatefulTooltip
            content={tooltip}
            showArrow
            placement={'top'}
            onMouseLeave={() => setTooltip(strings.General.Copy)}
            overrides={{
                Body: {
                    style: {
                        minWidth: '85px',
                        textAlign: 'center',
                    },
                },
            }}
        >
            <span>
                <Clickable onClick={onClick}>
                    <ClipboardIcon size={12} color={theme.customColors.accent}/>
                </Clickable>
            </span>
        </StatefulTooltip>
    );
}
