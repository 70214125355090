import { Card } from 'baseui/card';
import { HeadingXSmall } from 'baseui/typography';
import { HFlex } from '../containers/HFlex';
import { VFlex } from '../containers/VFlex';
import { StyleObject } from "styletron-react";
import { useTheme } from "../../hooks/useTheme";
import { ClosePageButton } from "../skeleton/ClosePageButton";
import { useRecoilValue } from "recoil";
import { visibleCardsState } from "../../states/skeletonState";
import { CloseIcon } from "./svg";
import { Spacer } from "../containers/Spacer";
import { blurState } from "../../states/appState";

type Props = {
    actions?: React.ReactNode;
    children: React.ReactNode;
    title?: React.ReactNode | string;
    blur?: boolean;
    style?: StyleObject;
    rounded?: boolean;
    border?: boolean;
    card?: "small" | "medium" | "other";

};

export function StyledCard({
                               actions,
                               children,
                               title,
                               blur = false,
                               style = {},
                               rounded = true,
                               border = false,
                               card = "other"
                           }: Props) {
    const { isDarkTheme, css, theme } = useTheme();
    const isBlurEnabled = useRecoilValue(blurState);

    const styles = blur && isBlurEnabled
        ? {
            backgroundColor: isDarkTheme ? '#0009' : "#FFF9",
            backdropFilter: "blur(50px)",
            WebkitBackdropFilter: "blur(50px)",
            transition: 'background-color 0.5s ease',
        } : {
            backgroundColor: theme.colors.backgroundSecondary,
        };
    const borderColor = isDarkTheme ? "#00000099" : "#00000033";
    const { smallIsVisible, mediumIsVisible } = useRecoilValue(visibleCardsState);

    return (
        <Card
            overrides={{
                Root: {
                    style: {
                        borderBottomWidth: border && rounded ? "1px" : "0px",
                        borderLeftWidth: "0px",
                        borderRightWidth: border ? "1px" : "0px",
                        borderTopWidth: "0px",
                        borderLeftColor: borderColor,
                        borderRightColor: borderColor,
                        borderTopColor: borderColor,
                        borderBottomColor: borderColor,
                        borderBottomLeftRadius: "0px",
                        borderBottomRightRadius: rounded ? "8px" : "0px",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                        boxShadow: undefined,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        ...styles,
                        ...style,
                    },
                },
                Contents: {
                    style: {
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        height: '100%',
                        marginBottom: '0px',
                        marginLeft: '8px',
                        marginRight: '8px',
                        marginTop: '20px',
                    },
                },
                Body: {
                    style: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        overflow: 'hidden',
                        marginBottom: '0px',
                    },
                },
            }}
        >
            {(actions || title) && (
                <HFlex
                    style={{
                        alignItems: "center",
                        justifyContent: 'flex-end',
                        marginBottom: theme.sizing.scale300,
                        minHeight: "40px",
                    }}
                >
                    {title && (
                        <HeadingXSmall marginTop={0} marginBottom={0}
                                       className={css({ flexGrow: 1, whiteSpace: 'nowrap', })}>
                            {title}
                        </HeadingXSmall>
                    )}
                    {actions && <>
                        <Spacer/>
                        {actions}
                    </>}
                    {((card === "small" && !mediumIsVisible) || (card === "medium" && !smallIsVisible)) &&
                        <>
                            <Spacer/>
                            <ClosePageButton icon={<CloseIcon color={theme.colors.contentPrimary}/>}/>
                        </>
                    }
                </HFlex>
            )}
            <VFlex
                style={{
                    overflow: 'hidden',
                }}
            >
                {children}
            </VFlex>
        </Card>
    );
}
