import { Button } from "baseui/button";
import { HTTPS_DOMAIN } from "../../services/api";
import { LogoIcon } from "../ui/svg";
import { LightTheme } from "baseui";
import { EmptyState } from "../ui/EmptyState";
import { useStrings } from "../../hooks/useStrings";
import { useNavigator } from "../../hooks/useNavigator";
import { ReactNode } from "react";

export function GetApp({title, subtitle}: { title?: ReactNode, subtitle?: ReactNode}) {
    const { strings } = useStrings();
    const { navigateToPage } = useNavigator();

    return (
        <EmptyState
            title={title || strings.General.WelcomeToHellotracksBusiness}
            subtitle={subtitle || strings.General.NowYouAreReadyToInstall}
            cta={
                <Button
                    onClick={() => {
                        navigateToPage(`${HTTPS_DOMAIN}/get/app/`, true);
                    }}
                >
                    {strings.General.GetApp}
                </Button>
            }
        >
            <LogoIcon size={40} color={LightTheme.colors.contentPrimary}/>
        </EmptyState>
    );
}