import { membersDangerousState } from './membersState';
import { atom, selector } from 'recoil';
import { isAdmin, isOperator, isWorker, MemberListItem } from './../types/member';
import { Uid } from "../types/core";

type MembersPickerConfig = {
    workers: boolean;
    operators: boolean;
    admins: boolean;
    excludedUids: Uid[],
};

export const pickerMembersConfigState = atom<MembersPickerConfig>({
    key: "members.picker.config",
    default: {
        workers: true,
        operators: true,
        admins: true,
        excludedUids: [],
    }
});

export const pickerMembersState = atom<MemberListItem[]>({
    key: 'members.picker',
    default: selector({
        key: 'members.picked.default',
        get: ({ get }) => {
            const config = get(pickerMembersConfigState);
            return get(membersDangerousState)
                .filter(m => {
                    return config.workers && isWorker(m.role)
                        || config.operators && isOperator(m.role)
                        || config.admins && isAdmin(m.role)
                })
                .filter(m => !config.excludedUids.includes(m.uid))
                .map((member) => ({ ...member, selected: false }));
        },
    }),
});

export const pickerSelectedMembersState = selector<MemberListItem[]>({
    key: 'members.picker.selected',
    get: ({ get }) => {
        const members = get(pickerMembersState);
        return members.filter((member) => member.selected);
    },
});
