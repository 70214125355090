import { Checkbox } from 'baseui/checkbox';
import { FormControl } from 'baseui/form-control';
import { Radio, RadioGroup } from 'baseui/radio';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useOnboardRefs } from '../../../hooks/onboard/useOnboardRefs';
import { editCompanyState } from '../../../states/editCompanyState';
import { stringsState } from '../../../states/localeState';
import { SectionedWrapper } from '../../containers/SectionedWrapper';
import { Section } from '../../ui/Section';
import useAccount from "../../../hooks/useAccount";
import { useStyleOverrides } from '../../../hooks/useStyleOverrides';
import { Spacer } from "../../containers/Spacer";

export function CompanyPermissionsTab() {
    const strings = useRecoilValue(stringsState);
    const [companyFields, setCompanyFields] = useRecoilState(editCompanyState);
    const { isSelfAdminOrCompany } = useAccount();
    const { formControlSpacing } = useStyleOverrides();
    const {
        operatorsPermissionsRef,
        adminsPermissionsRef,
        workersPermissionsRef,
    } = useOnboardRefs();

    const onChangeToggle = (key: string, value: boolean) => {
        setCompanyFields((prev) => ({ ...prev, [key]: value }));
    };


    return (
        <SectionedWrapper>
            <div ref={adminsPermissionsRef}>
                <Section title={strings.Company.PermissionForAdministrators} noMarginTop>
                    <Checkbox
                        checked
                        disabled
                    >
                        {strings.Company.PermissionAdmin1}
                    </Checkbox>
                    <Checkbox
                        checked
                        disabled
                    >
                        {strings.Company.PermissionAdmin2}
                    </Checkbox>
                    <Checkbox
                        checked
                        disabled
                    >
                        {strings.Company.PermissionAdmin3}
                    </Checkbox>
                </Section>
            </div>
            <div ref={operatorsPermissionsRef}>
                <Section title={strings.Company.PermissionsForOperators}>
                    <Checkbox
                        checked
                        disabled
                    >
                        {strings.Company.PermissionOperator1}
                    </Checkbox>
                    <Checkbox
                        checked={companyFields.assistant_enabled}
                        onChange={(event) => onChangeToggle('assistant_enabled', event.target.checked)}
                        disabled={!isSelfAdminOrCompany}
                    >
                        {strings.Company.PermissionOperatorsMayUseAssistant}
                    </Checkbox>
                    <Checkbox
                        checked={companyFields.dispatchers_as_workers}
                        onChange={(event) => onChangeToggle('dispatchers_as_workers', event.target.checked)}
                        disabled={!isSelfAdminOrCompany}
                    >
                        {strings.Company.DispatchersAsWorkers}
                    </Checkbox>
                    <Checkbox
                        checked={!companyFields.vis_team_only}
                        onChange={(event) => onChangeToggle('vis_team_only', !event.target.checked)}
                        disabled={!isSelfAdminOrCompany}
                    >
                        {strings.Company.PermissionOperatorMaySeeEverybody}
                    </Checkbox>
                    <Checkbox
                        checked={companyFields.dispatcher_delete_jobs}
                        onChange={(event) => onChangeToggle('dispatcher_delete_jobs', event.target.checked)}
                        disabled={!isSelfAdminOrCompany}
                    >
                        {strings.Company.PermissionOperatorMayDeleteJobs}
                    </Checkbox>
                </Section>
            </div>
            <div ref={workersPermissionsRef}>
                <Section title={strings.Company.PermissionsForWorkers}>
                    <Checkbox
                        checked
                        disabled
                    >
                        {strings.General.WorkersDesc}
                    </Checkbox>
                    <Checkbox
                        checked={companyFields.user_map}
                        onChange={(event) => onChangeToggle('user_map', event.target.checked)}
                        disabled={!isSelfAdminOrCompany}
                    >
                        {strings.Company.PermissionWorkersMaySeeOtherWorkers}
                    </Checkbox>
                    <Checkbox
                        checked={companyFields.workers_allowed_tracking_off}
                        onChange={(event) => onChangeToggle('workers_allowed_tracking_off', event.target.checked)}
                        disabled={!isSelfAdminOrCompany}
                    >
                        {strings.Company.WorkersAllowedToTrackingOff}
                    </Checkbox>
                    <Checkbox
                        checked={companyFields.tracking_off_outside_availability}
                        onChange={(event) => onChangeToggle('tracking_off_outside_availability', event.target.checked)}
                        disabled={!isSelfAdminOrCompany}
                    >
                        {strings.Company.DisableTrackingIfUnavailable}
                    </Checkbox>
                    <Checkbox
                        checked={companyFields.workers_message}
                        onChange={(event) => onChangeToggle('workers_message', event.target.checked)}
                        disabled={!isSelfAdminOrCompany}
                    >
                        {strings.Company.WorkersMaySendMessage}
                    </Checkbox>
                    <Checkbox
                        checked={companyFields.workers_create_places}
                        onChange={(event) => onChangeToggle('workers_create_places', event.target.checked)}
                        disabled={!isSelfAdminOrCompany}
                    >
                        {strings.Company.WorkersCreatePlaces}
                    </Checkbox>
                    <FormControl label={strings.Company.PlacesOnMobileApp} overrides={formControlSpacing}>
                        <RadioGroup
                            value={`${companyFields.workers_view_places}`}
                            onChange={(event) => onChangeToggle('workers_view_places', event.target.value === 'true')}
                            align="vertical"
                            disabled={!isSelfAdminOrCompany}
                        >
                            <Radio value="false">{strings.Company.OnlyAssignedPlacesAreVisible}</Radio>
                            <Radio value="true">{strings.Company.AllPlacesAreVisible}</Radio>
                        </RadioGroup>
                    </FormControl>
                    <FormControl label={strings.Company.ZonesOnMobileApp} overrides={formControlSpacing}>
                        <RadioGroup
                            value={`${companyFields.workers_view_zones}`}
                            onChange={(event) => onChangeToggle('workers_view_zones', event.target.value === 'true')}
                            align="vertical"
                            disabled={!isSelfAdminOrCompany}
                        >
                            <Radio value="false">{strings.Company.OnlyAssignedZonesAreVisible}</Radio>
                            <Radio value="true">{strings.Company.AllZonesAreVisible}</Radio>
                        </RadioGroup>
                    </FormControl>
                </Section>
            </div>
            <Spacer height={"48px"}/>
        </SectionedWrapper>
    );
}