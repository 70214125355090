import { ReactNode } from "react";
import { VFlex } from "../containers/VFlex";
import { LabelMedium } from "baseui/typography";

export function ChartPanel({ title, children }: { title: string, children: ReactNode | ReactNode[] }) {

    return (
        <VFlex alignCenter spacing={"4px"}>
            <LabelMedium>{title}</LabelMedium>
            {children}
        </VFlex>
    )
}