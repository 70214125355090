import { FixedMarker } from "baseui/map-marker";
import { Marker } from "react-map-gl";
import { useRecoilState } from "recoil";
import { useDrawMode } from "../../../hooks/useDrawMode";
import { useGeocode } from "../../../hooks/useGeocode";
import { memo } from "react";
import { locationDraggingState } from "../../../states/drawModeState";

function LocationMarker() {
    const { geolocate } = useGeocode();
    const [locationDragging, setLocationDragging] = useRecoilState(locationDraggingState);
    const { drawMode, updateLocation } = useDrawMode();
    const location = drawMode?.location;

    if (!drawMode || !location || (drawMode.type === "place" && drawMode.action === "new")) {
        return null;
    }

    const markerDescription = location.address || location.name;

    return (
        <Marker
            longitude={location.lng ?? 0}
            latitude={location.lat ?? 0}
            anchor="bottom"
            draggable={true}
            onDragStart={() => setLocationDragging(true)}
            onDragEnd={async (event) => {
                const newLocation = await geolocate(event.lngLat.lat, event.lngLat.lng);
                updateLocation(newLocation);
                setLocationDragging(false);
            }}
        >
            <FixedMarker label={markerDescription || `${location.lat}, ${location.lng}`} dragging={locationDragging}/>
        </Marker>
    );
}

export default memo(LocationMarker);