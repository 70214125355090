import { Layer, Source } from "react-map-gl";
import { useRecoilValue } from "recoil";
import { useHeatmapStyle } from "../mapStyle";
import { heatmapLayerState } from "../../../states/geojsonState";
import { useEffect } from "react";
import { retrieveTracksFromGeojson } from "../../../utils/geojsonUtils";
import { useCamera } from "../../../hooks/useCamera";
import { useBoard } from "../../../hooks/useBoard";
import { selectedDayFrameState } from "../../../states/appState";
import { Day } from "../../../types/core";

export function HeatmapLayer({ member }: { member: string | undefined }) {
    const id = `heatmap-${member}`;
    const { heatmapStyle } = useHeatmapStyle(id);
    const dates = useRecoilValue(selectedDayFrameState);
    const fromDay: Day = dates[0];
    const untilDay: Day = dates[1];
    const heatmapLayer = useRecoilValue(heatmapLayerState({
        member,
        fromDay,
        untilDay,
    }));
    const board = useBoard();
    const camera = useCamera();

    useEffect(() => {
        if (board.memberUids.length <= 1) {
            const trackEntries = retrieveTracksFromGeojson(heatmapLayer);
            let mergedDate = [] as any[];
            trackEntries.forEach(entry => entry.data.forEach(lngLat => mergedDate.push(lngLat)))
            camera.fitToLngLats(mergedDate);
        }
    }, [heatmapLayer, board.memberUids]);

    if (!heatmapLayer) {
        return null;
    }

    return (
        <Source id={id} type="geojson" data={heatmapLayer}>
            <Layer {...heatmapStyle}/>
        </Source>
    );
}