import { Filter } from "baseui/icon";
import { useStrings } from "../../../hooks/useStrings";
import { useSetRecoilState } from "recoil";
import { sequenceFilterState } from "../../../states/sequencesState";
import { Input } from "baseui/input";
import { SIZE } from "baseui/button-group";

export function SequenceSearch() {
    const { strings } = useStrings();
    const setFilter = useSetRecoilState(sequenceFilterState);

    return (
        <div onClick={event => {event.stopPropagation()}}>
            <Input
                placeholder={strings.General.Filter}
                onChange={val => setFilter(val.target.value)}
                clearable
                size={SIZE.mini}
                startEnhancer={<Filter/>}
                overrides={{
                    Root: {
                        style: {
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            maxWidth: "100px",
                            height: "22px",
                        }
                    },
                    Input: {
                        style: {
                            paddingLeft: "0px",
                            paddingRight: "0px",
                        }
                    }
                }}/>
        </div>
    );
}