import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import {
    TRACKS_ACTIONS_COLUMN,
    TRACKS_ALL_COLUMNS,
    TRACKS_APPROVAL_COLUMN,
    TRACKS_DATE_COLUMN,
    TRACKS_DISTANCE_COLUMN,
    TRACKS_DURATION_COLUMN,
    TRACKS_LOCATION_COLUMN,
    TRACKS_NAME_COLUMN,
    TRACKS_OPTIONS_COLUMN,
    TRACKS_SELECT_COLUMN,
    TRACKS_TRIP_PURPOSE_COLUMN
} from '../../constants/tracks';
import { ColumnsConfiguration, ColumnsWidthType } from '../../types/core';
import { useStrings } from '../useStrings';
import { useStyleOverrides } from '../useStyleOverrides';
import { useTracksSort } from './useTracksSort';
import { tracksColumnsState, TrackSort } from "../../states/tracksState";

export function useTrackColumns() {
    const { strings } = useStrings();
    const { handleSort, getDirection } = useTracksSort();
    const { fixedColumnStyle } = useStyleOverrides();
    const [enabledColumns, setEnabledColumns] = useRecoilState(tracksColumnsState);
    const disabledColumns = useMemo(
        () => [...TRACKS_ALL_COLUMNS].filter((column) => !enabledColumns.includes(column)),
        [enabledColumns]
    );
    const columns = [...enabledColumns, ...disabledColumns];

    const columnsWidth: ColumnsWidthType = {
        [TRACKS_SELECT_COLUMN]: 50,
        [TRACKS_NAME_COLUMN]: 150,
        [TRACKS_DATE_COLUMN]: 130,
        [TRACKS_LOCATION_COLUMN]: 190,
        [TRACKS_DURATION_COLUMN]: 90,
        [TRACKS_DISTANCE_COLUMN]: 90,
        [TRACKS_TRIP_PURPOSE_COLUMN]: 120,
        [TRACKS_APPROVAL_COLUMN]: 100,
        [TRACKS_ACTIONS_COLUMN]: 120,
        [TRACKS_OPTIONS_COLUMN]: 35,
    };

    const columnsConfiguration: ColumnsConfiguration = {
        [TRACKS_NAME_COLUMN]: {
            title: strings.General.Name,
            direction: getDirection(TrackSort.NAME),
            styles: fixedColumnStyle(columnsWidth[TRACKS_NAME_COLUMN], true),
            onSort: () => handleSort(TrackSort.NAME),
        },
        [TRACKS_DATE_COLUMN]: {
            title: strings.General.Date,
            direction: getDirection(TrackSort.DATE),
            styles: fixedColumnStyle(columnsWidth[TRACKS_DATE_COLUMN], false),
            onSort: () => handleSort(TrackSort.DATE),
        },
        [TRACKS_LOCATION_COLUMN]: {
            title: strings.General.Location,
            direction: null,
            styles: fixedColumnStyle(columnsWidth[TRACKS_LOCATION_COLUMN], true),
        },
        [TRACKS_DURATION_COLUMN]: {
            title: strings.General.Duration,
            direction: getDirection(TrackSort.DURATION),
            styles: fixedColumnStyle(columnsWidth[TRACKS_DURATION_COLUMN]),
            onSort: () => handleSort(TrackSort.DURATION),
        },
        [TRACKS_DISTANCE_COLUMN]: {
            title: strings.General.Distance,
            direction: getDirection(TrackSort.DISTANCE),
            styles: fixedColumnStyle(columnsWidth[TRACKS_DISTANCE_COLUMN]),
            onSort: () => handleSort(TrackSort.DISTANCE),
        },
        [TRACKS_TRIP_PURPOSE_COLUMN]: {
            title: strings.TripLists.TripPurposeAndComment,
            direction: null,
            styles: fixedColumnStyle(columnsWidth[TRACKS_TRIP_PURPOSE_COLUMN], true),
        },
        [TRACKS_APPROVAL_COLUMN]: {
            title: strings.Approval.Approval,
            direction: getDirection(TrackSort.APPROVAL),
            styles: fixedColumnStyle(columnsWidth[TRACKS_APPROVAL_COLUMN]),
            onSort: () => handleSort(TrackSort.APPROVAL),
        },
        [TRACKS_ACTIONS_COLUMN]: {
            title: strings.General.Actions,
            direction: null,
            styles: fixedColumnStyle(columnsWidth[TRACKS_ACTIONS_COLUMN]),
        },
    };

    const tableWidth =
        columnsWidth[TRACKS_SELECT_COLUMN] +
        columnsWidth[TRACKS_NAME_COLUMN] +
        columnsWidth[TRACKS_OPTIONS_COLUMN] +
        Object.entries(columnsWidth)
            .filter(([key]) => enabledColumns.includes(key))
            .map(([_, width]) => width)
            .reduce((accumulator, current) => accumulator + current, 0);

    return {
        columns,
        columnsConfiguration,
        columnsWidth,
        disabledColumns,
        enabledColumns,
        tableWidth,
        reorderColumns: setEnabledColumns,
    };
}
