import { ConfigProvider, theme as antdTheme } from "antd";
import { HeadingXSmall } from "baseui/typography";
import { useRecoilValue } from "recoil";
import { useTheme } from "../../hooks/useTheme";
import { loggedInState } from "../../states/accountState";
import { VFlex } from "../containers/VFlex";
import { SimpleNavbar } from "../navbar/SimpleNavbar";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";

type Props = {
    title?: string;
    children: React.ReactNode;
};

export function SimplePageLayout({ title, children }: Props) {
    const { theme, isDarkTheme } = useTheme();
    const isLoggedIn = useRecoilValue(loggedInState);

    const padding = 18;

    return (
        <div style={{
            height: "100vh",
            width: "100vw",
            background:
            "        linear-gradient(#800000, transparent),\n" +
            "        linear-gradient(to top left, #004000, transparent),\n" +
            "        linear-gradient(to top right, #000080, transparent)",
            backgroundBlendMode: "screen",
        }}>
            <ConfigProvider
                theme={{
                    algorithm: isDarkTheme ? antdTheme.darkAlgorithm : antdTheme.defaultAlgorithm,
                }}
            >
                <HFlex style={{
                    width: `calc(100% - ${padding * 2}px)`,
                    height: `calc(100% - ${padding * 4}px)`,
                    paddingLeft: padding + "px",
                    paddingRight: padding + "px",
                    paddingTop: 2 * padding + "px",
                    paddingBottom: 2 * padding + "px",
                }}>
                    <SpacerExpand/>
                    <VFlex
                        alignCenter
                        style={{
                            height: "auto",
                            overflow: "hidden",
                            minWidth: "max(40%, 250px)",
                            maxWidth: "max(90%, 800px)",
                            backgroundColor: isDarkTheme ? "black" : "white",
                            borderRadius: "8px",
                            boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.5)",
                            paddingLeft: "32px",
                            paddingRight: "32px",
                            paddingTop: "8px",
                            paddingBottom: "0px",
                        }}
                    >
                        {isLoggedIn && (
                            <>
                                <SimpleNavbar/>
                                <VFlex
                                    style={{
                                        paddingTop: theme.sizing.scale300,
                                        paddingRight: theme.sizing.scale300,
                                        paddingBottom: theme.sizing.scale300,
                                        paddingLeft: theme.sizing.scale300,
                                        flex: 1,
                                        overflow: "hidden",
                                        width: "100%",
                                    }}
                                >
                                    {title && (
                                        <HeadingXSmall marginTop={0} marginBottom={theme.sizing.scale300}>
                                            {title}
                                        </HeadingXSmall>
                                    )}
                                    {children}
                                </VFlex>
                            </>
                        )}
                    </VFlex>
                    <SpacerExpand/>
                </HFlex>
            </ConfigProvider>
        </div>
    );
}
