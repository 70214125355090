import { atomWithIndexedPersistence } from "../utils/persistence";
import { selectorFamily } from "recoil";
import { limitTo, searchMatch, sortJobsAlphabetically, sortJobsByHeader } from "../utils/jobUtils";
import { jobsSearchState, jobState } from "./jobsState";
import { MAX_JOBS } from "../constants/jobs";
import { Job } from "../types/job";
import { allJobsCustomSortFieldState, allJobsSortDirectionState, allJobsSortState } from "./allJobsState";
import { SORT_DIRECTION } from "baseui/table";

export const templateIdsState = atomWithIndexedPersistence("template.ids", [] as string[]);

export const templatesState = selectorFamily<Job[], boolean>({
    key: 'templates',
    get: (all) => ({ get }) => {
        const jobs = get(templateIdsState)
            .map(id => get(jobState(id)))
            .filter(job => !!job) as Job[];
        if (all) {
            const sorted = jobs.sort(sortJobsAlphabetically);
            return limitTo(MAX_JOBS, sorted);
        }
        const search = get(jobsSearchState);
        const filteredJobs = jobs
            .filter(job => searchMatch(job, search))
        const sort = get(allJobsSortState);
        const direction = get(allJobsSortDirectionState);
        const customSortField = get(allJobsCustomSortFieldState);
        const sorted = filteredJobs.sort((j1, j2) => sortJobsByHeader(j1, j2, sort, customSortField));
        return limitTo(MAX_JOBS, direction === SORT_DIRECTION.DESC ? sorted.reverse() : sorted);
    },
});