import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useRecoilValue } from "recoil";
import { stringsState } from "../../../states/localeState";
import { ConfirmModal } from "../../ui/ConfirmModal";
import { FormInput } from "../../ui/FormInput";

type Props = {
    isOpen: boolean;
    onCancel: () => void;
    onAccept: (title: string) => void | Promise<void>;
};

export function AlertTitleModal({ isOpen, onAccept, onCancel }: Props) {
    const strings = useRecoilValue(stringsState);
    const [alertTitle, setAlertTitle] = useState("");

    useEffect(() => {
        setAlertTitle("");
    }, [isOpen]);

    return createPortal(
        <ConfirmModal
            title={strings.Clone.Clone}
            description={
                <FormInput
                    label={strings.General.Title}
                    field="title"
                    value={alertTitle}
                    onChange={(_, value) => setAlertTitle(value as string)}
                />
            }
            isOpen={isOpen}
            onConfirm={() => onAccept(alertTitle)}
            onCancel={onCancel}
            cancelText={strings.General.Cancel}
            confirmText={strings.General.Save}
            canConfirm={!!alertTitle}
        />,
        document.body
    );
}
