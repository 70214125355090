import { MicrophoneIcon } from "../ui/svg";
import { COLOR_RED } from "../../constants/colors";
import { Spacer } from "../containers/Spacer";
import { ButtonTimed } from "baseui/button-timed";
import React from "react";
import { useStrings } from "../../hooks/useStrings";
import { MAX_SECONDS } from "./AudioRecording";

export function AudioStopRecordingButton({ onClick }: { onClick: () => void }) {
    const { strings } = useStrings();

    return (
        <ButtonTimed initialTime={MAX_SECONDS} onClick={onClick}>
            <MicrophoneIcon size={18} color={COLOR_RED}/>
            <Spacer/>
            {strings.Audio.StopRecording}
        </ButtonTimed>
    )
}