import { Layer, Source } from "react-map-gl";
import { FeatureCollectionWithUid } from "../../../states/geojsonState";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { mapInteractiveLayersState } from "../../../states/mapState";
import { useDayRouteLayerStyle } from "../mapStyle";
import { COLOR_DAYROUTE } from "../../../constants/colors";
import { Uid } from "../../../types/core";

type Props = {
    index: number;
    uid: Uid;
    layer: FeatureCollectionWithUid;
}

export function DayRouteLayer({ index, uid, layer }: Props) {
    const setInteractiveLayers = useSetRecoilState(mapInteractiveLayersState);
    const { dayRouteLayerStyle, dayRouteOuterLayerStyle, multiDayRouteLayerStyle } = useDayRouteLayerStyle();

    const innerLayerId = "dayroute-inner-" + uid;
    const outerLayerId = "dayroute-outer-" + uid;

    useEffect(() => {
        setInteractiveLayers((old) => {
                const newLayers = [...old].filter(id => id !== outerLayerId);
                newLayers.push(outerLayerId);
                return newLayers;
            }
        );
        return () => setInteractiveLayers((old) => [...old].filter(id => id !== outerLayerId));
    }, []);

    const innerLayerStyle = multiDayRouteLayerStyle(innerLayerId + index, COLOR_DAYROUTE);

    return (
        <Source key={uid} id={"dayroute-" + uid} type="geojson" data={layer} lineMetrics>
            <Layer {...dayRouteOuterLayerStyle(outerLayerId)} />
            <Layer {...innerLayerStyle} />
        </Source>
    );
}