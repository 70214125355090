export const getPasswordValidation = (password: string) => {
    const passwordCriteria = {
        hasLow: password.match(/[a-z]+/g),
        hasCap: password.match(/[A-Z]+/g),
        hasNumber: password.match(/\d+/g),
        has8digit: password.length >= 8,
        has12digit: password.length >= 12,
        noWhitespace: password.length > 0 && !password.match(/\s/)
    }

    const isPasswordValid = passwordCriteria.noWhitespace
        && passwordCriteria.has8digit
        && (passwordCriteria.has12digit
            || (passwordCriteria.hasCap && passwordCriteria.hasLow && passwordCriteria.hasNumber));

    const maxStrength = Object.values(passwordCriteria).reduce((a, item) => a + 1, 0);

    return  {
        ...passwordCriteria,
        length: password.length,
        strength: isPasswordValid
            ? maxStrength
            : Object.values(passwordCriteria).reduce((a, item) => a + (item ? 1 : 0), 0),
        maxStrength: maxStrength,
        valid: isPasswordValid,
    }
};