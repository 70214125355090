import { useRecoilState, useRecoilValue } from "recoil";
import { editCompanyState, editCompanyValidState } from "../../../states/editCompanyState";
import { stringsState } from "../../../states/localeState";
import { SectionedWrapper } from "../../containers/SectionedWrapper";
import { ProfileImage } from "../../shared/ProfileImage";
import { AddressFields } from "../../shared/AddressFields";
import { ContactFields } from "../../shared/ContactFields";
import { FormInput } from "../../ui/FormInput";
import { Section } from "../../ui/Section";
import { HFlex } from "../../containers/HFlex";
import { Spacer, SpacerExpand } from "../../containers/Spacer";
import { editAccount } from "../../../services/api";
import useAccount from "../../../hooks/useAccount";
import { Company } from "../../../types/company";
import { VFlex } from "../../containers/VFlex";
import { StaticMap } from "../../staticmap/StaticMap";
import { useEditCompanyLocation } from "../../../hooks/company/useEditCompanyLocation";
import { useState } from "react";
import { geocode } from "../../../services/privateApi";
import { Button } from "baseui/button";
import { COLOR_COMPANY } from "../../../constants/colors";


export function CompanyProfileTab() {
    const strings = useRecoilValue(stringsState);
    const { company, setCompany, isSelfAdminOrCompany } = useAccount();
    const [companyFields, setCompanyFields] = useRecoilState(editCompanyState);
    const validCompanyFields = useRecoilValue(editCompanyValidState);
    const { initEditCompany } = useEditCompanyLocation();
    const [geocodeLoading, setGeocodeLoading] = useState(false);

    const onChangeInput = (key: string, value: string | number) => {
        setCompanyFields((prev) => ({
            ...prev,
            phone_verified: key === "phone" ? false : prev.phone_verified,
            [key]: value,
        }));
    };

    const handleNewPhone = async () => {
        if (!company) {
            return { status: false };
        }
        const { status, account: updatedCompany } = await editAccount({ uid: company.uid, phone: companyFields.phone });
        if (status) {
            setCompany(updatedCompany as Company);
        }
        return { status };
    }

    const handlePhoneVerified = async () => {
        if (!company) {
            return { status: false };
        }
        const { status, account: updatedCompany } = await editAccount({ uid: company.uid, phone_verified: true });
        if (status) {
            setCompany(updatedCompany as Company);
            setCompanyFields((prev) => ({ ...prev, phone_verified: true }));
        }
        return { status };
    }

    const geolocate = async () => {
        setGeocodeLoading(true);
        const geolocation = await geocode({ lat: companyFields.location.lat, lng: companyFields.location.lng });
        if (geolocation.status && geolocation.result.address) {
            setCompanyFields((prev) => ({ ...prev, address: geolocation.result.address?.formatted || '' }));
        }
        setGeocodeLoading(false);
    }

    return (
        <SectionedWrapper>
            <Section>
                <HFlex spacing={"48px"} style={{ alignItems: "start" }}>
                    <ProfileImage uid={companyFields.uid}
                                  name={companyFields.name}
                                  portrait={companyFields.portrait}
                                  color={COLOR_COMPANY}
                                  isCompany={true}/>
                    <VFlex>
                        <FormInput
                            label={strings.General.Name}
                            value={companyFields.name}
                            onChange={onChangeInput}
                            field="name"
                            disabled={!isSelfAdminOrCompany}
                        />
                    </VFlex>
                </HFlex>
            </Section>
            <Section title={strings.General.Contact}>
                <ContactFields
                    uid={companyFields.uid}
                    emailValue={companyFields.email}
                    emailIsVerified={companyFields.email_verified}
                    emailIsValid={validCompanyFields['email']}
                    phoneValue={companyFields.phone}
                    phoneIsVerified={companyFields.phone_verified}
                    onFieldChange={onChangeInput}
                    onPhoneVerified={handlePhoneVerified}
                    onNewPhone={handleNewPhone}
                    readonly={!isSelfAdminOrCompany}
                />
                <FormInput
                    label={strings.General.YourWebsite}
                    value={companyFields.business_website}
                    startEnhancer={"https://"}
                    placeholder="yourwebsite.com"
                    field="business_website"
                    onChange={onChangeInput}
                    readonly={!isSelfAdminOrCompany}
                />
            </Section>
            <Section title={strings.General.Address}>
                <VFlex>
                    <Spacer height={"32px"}/>
                    <HFlex>
                        <SpacerExpand/>
                        <VFlex style={{ maxWidth: "380px" }} spacing>
                            <StaticMap
                                width={380}
                                height={180}
                                location={{ lat: companyFields.location.lat, lng: companyFields.location.lng }}
                                onClick={initEditCompany}/>
                        </VFlex>
                        <SpacerExpand/>
                    </HFlex>
                    <Spacer height={"32px"}/>
                </VFlex>
                <AddressFields
                    addressValue={companyFields.address}
                    addressEnhancer={companyFields.location.lat && companyFields.location.lng ?
                        <Button size={"mini"}
                                kind={"tertiary"}
                                onClick={() => geolocate()}
                                isLoading={geocodeLoading}
                        >
                            {strings.General.Geocode}
                        </Button> : undefined}
                    cityValue={companyFields.city}
                    stateValue={companyFields.state}
                    postalCodeValue={companyFields.postalcode}
                    countryValue={companyFields.country_code}
                    onFieldChange={onChangeInput}
                    readonly={!isSelfAdminOrCompany}
                />
            </Section>
            <Spacer height={"48px"}/>
        </SectionedWrapper>
    );
}