import { ApprovalStatus, TrackEntry } from "../../types/track";
import { useStrings } from "../../hooks/useStrings";
import { Dropdown, MenuProps } from "antd";
import React from "react";
import { TrackApprovalBadge } from "../badges/TrackApprovalBadge";
import { editTrackApprovalStatus } from "../../services/api";
import { useTracksLayerUpdater } from "../../hooks/tracks/useTracksLayerUpdater";
import useAccount from "../../hooks/useAccount";

export function TrackApprovalBar({ track }: { track: TrackEntry }) {
    const { strings } = useStrings();
    const { updateMemberTracksSameDates } = useTracksLayerUpdater();
    const { company } = useAccount();

    const changeApprovalStatus = async (approvalStatus: ApprovalStatus) => {
        const { status } = await editTrackApprovalStatus(track, approvalStatus);
        if (status) {
            await updateMemberTracksSameDates(track.account);
        }
    }

    const items: MenuProps['items'] = [
        {
            key: '0',
            label: strings.Approval.Pending,
            disabled: track.approvalStatus === ApprovalStatus.NOTIFIED || track.approvalStatus === ApprovalStatus.READY,
            onClick: () => { changeApprovalStatus(ApprovalStatus.READY).then(() => {}) }
        },
        {
            key: '1',
            label: strings.Approval.Approve,
            disabled: track.approvalStatus === ApprovalStatus.APPROVED || (!track.purpose && !!company?.track_purpose_list),
            onClick: () => { changeApprovalStatus(ApprovalStatus.APPROVED).then(() => {}) }
        },
        {
            key: '2',
            label: strings.Approval.Disapprove,
            danger: true,
            disabled: track.approvalStatus === ApprovalStatus.DISAPPROVED,
            onClick: () => { changeApprovalStatus(ApprovalStatus.DISAPPROVED).then(() => {}) }
        },
    ];

    return (
        <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
                <TrackApprovalBadge track={track}/>
            </a>
        </Dropdown>
    );
}