import { VFlex } from "../containers/VFlex";
import { UnorderedList } from "../containers/UnorderedList";
import { useRecoilValue } from "recoil";
import { formTemplatesSortedState } from "../../states/formsState";
import React from "react";
import { FormTemplateItem } from "./FormTemplateItem";
import { useTheme } from "../../hooks/useTheme";
import { EmptyState } from "../ui/EmptyState";
import { FormIcon } from "../ui/svg";

export function FormList() {
    const { theme } = useTheme();
    const templates = useRecoilValue(formTemplatesSortedState);

    return (
        <VFlex spacing>
            {templates.length === 0 && <EmptyState>
                <FormIcon size={64} color={theme.colors.contentPrimary}/>
            </EmptyState>}
            <UnorderedList>
                {templates.map(f => <FormTemplateItem key={f.id} formTemplate={f}/>)}
            </UnorderedList>
        </VFlex>
    );
}