import { TimeSlider } from "./TimeSlider";
import { useRecoilState, useRecoilValue } from "recoil";
import { dayState, selectedTimeFrameState } from "../../states/appState";
import { useState } from "react";
import { useInterval } from "usehooks-ts";
import { currentMinutesOfDay, dayOfToday } from "../../utils/time";
import { MAX_MINUTES } from "../../constants/days";

export function GlobalTimeSlider() {
    const day = useRecoilValue(dayState);
    const [time, setTime] = useRecoilState(selectedTimeFrameState);
    const [minutesSinceMidnight, setMinutesSinceMidnight] = useState(currentMinutesOfDay());

    useInterval(() => setMinutesSinceMidnight(currentMinutesOfDay()), 1_000);

    const max = day === dayOfToday() ? minutesSinceMidnight : MAX_MINUTES;

    return (
        <TimeSlider max={max} values={time} onChange={values => setTime(values)}/>
    );
}