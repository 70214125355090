import { useTheme } from "../../hooks/useTheme";
import { ResponsiveFlex } from "./ResponsiveFlex";

type Props = {
    maxWidth?: number;
    children: React.ReactNode;
};

export function ResponsiveFieldWrapper({ maxWidth, children }: Props) {
    const { theme } = useTheme();

    return (
        <ResponsiveFlex
            spacing={theme.sizing.scale300}
            maxWidth={maxWidth}
        >
            {children}
        </ResponsiveFlex>
    );
}
