import { VFlex } from "../../containers/VFlex";
import { LabelMedium, ParagraphSmall } from "baseui/typography";
import { Alert } from "antd";
import { Button } from "baseui/button";
import { Section } from "../../ui/Section";
import { createPortal } from "react-dom";
import { ConfirmModal } from "../../ui/ConfirmModal";
import { useTheme } from "../../../hooks/useTheme";
import { useState } from "react";
import { useStrings } from "../../../hooks/useStrings";
import { useRecoilValue } from "recoil";
import { memberState } from "../../../states/membersState";
import { editingMemberIdState } from "../../../states/appState";
import { resetGoogleCalendar, sendGoogleCalendarInvite } from "../../../services/api";
import { useMembersUpdater } from "../../../hooks/members/useMembersUpdater";

export function IntegrationsSections() {
    const { strings, format } = useStrings()
    const { theme } = useTheme();
    const editingMember = useRecoilValue(editingMemberIdState);
    const member = useRecoilValue(memberState(editingMember));
    const [showInvalidGC, setShowInvalidGC] = useState(false);
    const [showConnectGC, setShowConnectGC] = useState(false);
    const [showDisconnectGC, setShowDisconnectGC] = useState(false);
    const [sendingGCLink, setSendingGCLink] = useState(false);
    const [GCReceiver, setGCReceiver] = useState("");
    const { updateSingleMember } = useMembersUpdater();

    if (!member) {
        return null;
    }

    const onLinkGoogleCalendar = async () => {
        if (member.email_verified || member.phone_verified) {
            setSendingGCLink(true);
            const { status, receiver } = await sendGoogleCalendarInvite({ uid: member.uid });
            if (status) {
                setGCReceiver(receiver);
                setShowConnectGC(true);
            }
            setSendingGCLink(false);
        } else {
            setShowInvalidGC(true);
        }
    }

    const onDisconnectGoogleCalendar = async () => {
        const { status } = await resetGoogleCalendar({ uid: member.uid });
        if (status) {
            setShowDisconnectGC(false);
            updateSingleMember(member.uid);
        }
    }

    return (
        <Section title={strings.ProfileModal.Integrations}>
            {member.google_calendar && (
                <VFlex style={{ rowGap: theme.sizing.scale300 }}>
                    <LabelMedium>{strings.GoogleCalendar.GoogleCalendar}</LabelMedium>
                    <Alert type={"success"}
                           message={strings.GoogleCalendar.GoogleCalendarConnected}
                           showIcon
                           action={
                               <Button onClick={() => setShowDisconnectGC(true)}
                                       size="mini"
                                       shape="pill"
                                       kind={"tertiary"}
                               >
                                   {strings.GoogleCalendar.GoogleCalendarDisconnect}
                               </Button>
                           }
                    />
                </VFlex>
            )}
            {!member.google_calendar && (
                <div>
                    <Button kind="secondary" shape="pill" size="compact" onClick={onLinkGoogleCalendar}
                            isLoading={sendingGCLink}>{strings.GoogleCalendar.GoogleCalendarSendLink}</Button>
                    <ParagraphSmall marginTop={theme.sizing.scale300}
                                    color={theme.colors.contentSecondary}>{strings.GoogleCalendar.GoogleCalendarDesc}</ParagraphSmall>
                </div>
            )}
            {createPortal(
                <ConfirmModal
                    isOpen={showInvalidGC}
                    title={strings.GoogleCalendar.GoogleCalendar}
                    description={format(strings.GoogleCalendar.GoogleCalendarXNeedsVerified, member.name)}
                    onConfirm={() => setShowInvalidGC(false)}
                    confirmText={strings.General.OK}
                />,
                document.body
            )}
            {createPortal(
                <ConfirmModal
                    isOpen={showConnectGC}
                    title={strings.GoogleCalendar.GoogleCalendar}
                    description={format(strings.General.MessageSentTo, GCReceiver)}
                    onConfirm={() => setShowConnectGC(false)}
                    confirmText={strings.General.OK}
                />,
                document.body
            )}
            {createPortal(
                <ConfirmModal
                    isOpen={showDisconnectGC}
                    title={strings.GoogleCalendar.GoogleCalendar}
                    description={strings.GoogleCalendar.GoogleCalendarDisconnectQuestion}
                    onCancel={() => setShowDisconnectGC(false)}
                    onConfirm={onDisconnectGoogleCalendar}
                />,
                document.body
            )}
        </Section>
    )
}