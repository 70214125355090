import React, { memo } from "react";
import { useStrings } from "../../../hooks/useStrings";
import { Section } from "../../ui/Section";
import { LoadingSpinner } from "baseui/button/styled-components";


function RoutingBanner() {
    const { strings } = useStrings();

    return (
        <Section title={`${strings.Routing.Routing}...`} expandable persistenceKey="routeinfobanner">
            <LoadingSpinner/>
        </Section>
    );
}

export default memo(RoutingBanner);