import { useRecoilValue } from "recoil";
import { showJobsLayerState } from "../../../states/mapState";
import { dayState, editingJobIdState, pageState } from "../../../states/appState";
import JobMarker from "../markers/JobMarker";
import {
    jobsForSelectedMemberState,
    jobsForUnassignedState,
    jobState,
    selectedJobsState
} from "../../../states/jobsState";
import { editJobState } from "../../../states/editJobState";
import { dispatchTreeActiveCardState } from "../../../states/viewState";
import { allJobsState } from "../../../states/allJobsState";
import { MAX_VISIBLE_JOBS } from "../../../constants/jobs";
import { memo } from "react";
import { dayOfToday } from "../../../utils/time";
import { PAGE } from "../../../constants/pages";

function JobsLayers() {
    const page = useRecoilValue(pageState);
    const showJobsLayer = useRecoilValue(showJobsLayerState);
    const day = useRecoilValue(dayState);
    const jobsForSelectedMember = useRecoilValue(jobsForSelectedMemberState({ day: page === PAGE.LIVE ? dayOfToday() : day}));
    const selectedJobs = useRecoilValue(selectedJobsState);
    const editingJobId = useRecoilValue(editingJobIdState);
    const editingJob = useRecoilValue(jobState(editingJobId));
    const editingJobFields = useRecoilValue(editJobState(editingJobId || ''));
    const activeCard = useRecoilValue(dispatchTreeActiveCardState);
    const unassignedJobs = useRecoilValue(jobsForUnassignedState);
    const allJobs = useRecoilValue(allJobsState);

    if (!showJobsLayer) {
        return null;
    }

    const otherJobs = (
        page === "dispatch"
        && activeCard === "unassigned"
        && unassignedJobs
    ) || (
        page === "dispatch"
        && activeCard === "jobs"
        && allJobs
    ) || [];

    return (
        <>
            {otherJobs.length <= MAX_VISIBLE_JOBS && otherJobs.map((job) => (
                <JobMarker key={job.id} jobId={job.id}/>
            ))}
            {(page === "dispatch" || page === "timeline" || page === "live") && jobsForSelectedMember.map((job) => (
                <JobMarker key={job.id} jobId={job.id}/>
            ))}
            {selectedJobs.map((job) => (
                <JobMarker key={job.id} jobId={job.id}/>
            ))}
            {editingJob && editingJobId &&
                <JobMarker jobId={editingJobId}
                           overrideLat={editingJobFields?.destinationLat}
                           overrideLng={editingJobFields?.destinationLng}/>
            }
        </>
    );
}

export default memo(JobsLayers);