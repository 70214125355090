import { geocode } from "../services/privateApi";
import { DetailedLocation, Latitude, Longitude } from "../types/core";

export function useGeocode() {
    const geolocate = async (lat: Latitude, lng: Longitude): Promise<DetailedLocation> => {
        const { result } = await geocode({ lat, lng });
        const address = result?.address;
        return {
            lat,
            lng,
            address: address?.formatted || "",
            city: address?.city || "",
            postalcode: address?.postalCode || "",
            state: address?.state || "",
            country_code: address?.countryCode || "",
            streetname: address?.streetAddress || "",
            streetnumber: address?.streetNumber || "",
        } as DetailedLocation;
    };

    return {
        geolocate,
    };
}
