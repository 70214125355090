import { Button } from 'baseui/button';
import { useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useEffectOnce } from 'usehooks-ts';
import { useStyleOverrides } from '../../../hooks/useStyleOverrides';
import { stringsState } from '../../../states/localeState';
import { pickerExcludedPlaces, pickerSelectedPlacesState, placesPickerState } from '../../../states/placesPickerState';
import { Place } from '../../../types/place';
import { SpacerExpand } from '../../containers/Spacer';
import { PlacesListPicker } from '../../pickers/PlacesListPicker';
import { StyledModalContent } from '../../ui/StyledModalContent';

type Props = {
    closeModal: () => void,
    onAccept: (places: Place[]) => Promise<void>,
    multi?: boolean;
    title?: React.ReactNode;
    extraActions?: React.ReactNode;
    helper?: React.ReactNode;
}

export function PlacesPickerModalContent({ closeModal, onAccept, title, multi = true, extraActions, helper }: Props) {
    const strings = useRecoilValue(stringsState);
    const selectedPlaces = useRecoilValue(pickerSelectedPlacesState);
    const resetPlacesPicker = useResetRecoilState(placesPickerState);
    const resetPickerExcludedPlaces = useResetRecoilState(pickerExcludedPlaces);
    const [isLoading, setIsLoading] = useState(false);
    const { cancelButtonStyles } = useStyleOverrides();

    if (!title) {
        title = strings.Places.SelectPlace;
    }

    useEffectOnce(() => {
        return () => {
            resetPlacesPicker();
            resetPickerExcludedPlaces();
        }
    });

    const handleAccept = async () => {
        setIsLoading(true);
        await onAccept([...selectedPlaces]);
        setIsLoading(false);
        closeModal();
    }

    return (
        <StyledModalContent
            header={title}
            body={
                <>
                    <PlacesListPicker multi={multi}/>
                    {helper}
                </>
            }
            footer={
                <>
                    {extraActions}
                    <SpacerExpand/>
                    <Button
                        onClick={closeModal}
                        size='compact'
                        kind='tertiary'
                        overrides={cancelButtonStyles}
                    >
                        {strings.General.Cancel}
                    </Button>
                    <Button
                        onClick={handleAccept}
                        size='compact'
                        isLoading={isLoading}
                    >
                        {strings.General.Accept}
                    </Button>
                </>
            }
        />
    );
}