import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { MediumCard, SmallCard, VisibleCards } from '../components/skeleton/skeletonTypes';
import { ALL_CARDS, COLLAPSED, HALF, MEDIUM_CARD_ONLY, NO_CARDS, SMALL_CARD_ONLY } from '../constants/cards';
import { PAGE } from '../constants/pages';
import { pageState } from "./appState";
import { getWindowSize } from "../utils/window";
import { selectedConversationState } from "./messagesState";
import { actionPanelModeState } from "./actionPanelState";
import { drawModeState } from "./drawModeState";
import { localStorageEffect } from '../utils/persistence';
import { boardMemberUidsState, boardVisibleSequencesState } from "./boardState";
import { dispatchTreeActiveCardState, dispatchTreeCollapsedState } from "./viewState";
import { companyState } from "./accountState";
import { isLocationOnlyPlan } from "../utils/account";

const MAP_PADDING_KEY = 'MapPadding';
const ACTIONS_KEY = 'Actions';
const CONTENT_WRAPPER_KEY = 'ContentWrapper';
const FLOATING_PANEL_KEY = 'FloatingPannel';
const IMMERSIVE_MODE_KEY = 'ImmersiveMode';
const LAYERS_CARD_KEY = 'LayersCard';
const MEDIUM_CARD_KEY = 'MediumCard';
const SMALL_CARD_KEY = 'SmallCard';

export const mediumState = atom({
    key: MEDIUM_CARD_KEY,
    default: {} as MediumCard,
});

export const smallState = atom({
    key: SMALL_CARD_KEY,
    default: {} as SmallCard,
});

export const actionsIsOpenState = selector({
    key: ACTIONS_KEY,
    get: ({ get }) => get(actionPanelModeState) !== "none",
});

export const contentWrapperState = selector({
    key: CONTENT_WRAPPER_KEY,
    get: ({ get }) => {
        const { smallIsVisible, mediumIsVisible } = get(visibleCardsState);
        const immersiveMode = get(immersiveModeState);
        const { isOpened: smallIsOpened } = get(smallState);
        const { isOpened: mediumIsOpened } = get(mediumState);
        const hiddenContentWrapper = get(hiddenContentWrapperState);

        if (hiddenContentWrapper) {
            return COLLAPSED;
        }
        if (window.innerWidth > 767) {
            if (immersiveMode) {
                return HALF;
            } else if (
                (!smallIsOpened || !smallIsVisible) &&
                (!mediumIsOpened || !mediumIsVisible)
            ) {
                return COLLAPSED;
            }
        } else if (immersiveMode) {
            return HALF;
        } else if ((!mediumIsOpened && mediumIsVisible) || (!smallIsOpened && !mediumIsVisible)) {
            return COLLAPSED;
        }
        return '';
    },
});

export const hiddenContentWrapperState = selector({
    key: "app.cards.hidden",
    get: ({ get }) => {
        const drawMode = get(drawModeState);

        if (drawMode && drawMode.type === "team") {
            return true;
        }
        return false;
    }
});

export const floatingPanelIsOpenState = atom({
    key: FLOATING_PANEL_KEY,
    default: false,
});

export const layersCardIsOpenState = atom({
    key: LAYERS_CARD_KEY,
    default: false,
});

export const immersiveModeState = selector({
    key: IMMERSIVE_MODE_KEY,
    get: ({ get }) => {
        const { isOpened: smallIsOpened } = get(smallState);
        const { isOpened: mediumIsOpened } = get(mediumState);
        const { smallIsVisible, mediumIsVisible } = get(visibleCardsState);

        return !smallIsOpened && smallIsVisible && mediumIsOpened && mediumIsVisible;
    },
});

export const mapPaddingState = atom({
    key: MAP_PADDING_KEY,
    default: 0,
});

export const smallCardWidthState = selector({
    key: "app.cards.small.width",
    get: ({ get }) => {
        const isMobile = get(windowIsMobileState);
        const visibleCards = get(visibleCardsState);
        const page = get(pageState);
        const narrowPage = page !== "dispatch";
        return visibleCards.smallIsVisible ? isMobile || narrowPage ? 280 : 400 : 0;
    }
});

export const visibleCardsState = selector<VisibleCards>({
    key: 'app.cards.visible',
    get: ({ get }) => {
        const page = get(pageState);
        const selectedConversation = get(selectedConversationState);
        const drawMode = get(drawModeState);
        const company = get(companyState);

        if (drawMode && drawMode.type !== "team" || page === PAGE.MAP) {
            return NO_CARDS;
        }
        if ((page === PAGE.DISPATCH || page === PAGE.FORMS) && isLocationOnlyPlan(company?.stripe_plan)) {
            return MEDIUM_CARD_ONLY;
        }
        if (page === PAGE.TEAM) {
            return MEDIUM_CARD_ONLY;
        }
        if (page === PAGE.LIVE) {
            return SMALL_CARD_ONLY;
        }
        if (page === PAGE.DISPATCH) {
            const membersCount = get(boardMemberUidsState).length;
            const sequencesCount = get(boardVisibleSequencesState).length;
            const dispatchCard = get(dispatchTreeActiveCardState);
            const dispatchCollapsed = get(dispatchTreeCollapsedState);
            const isSmallCardOnly = membersCount + sequencesCount === 0 && !dispatchCard;
            if (isSmallCardOnly) {
                return SMALL_CARD_ONLY;
            }
            if (dispatchCollapsed) {
                return MEDIUM_CARD_ONLY;
            }
            return ALL_CARDS;
        }
        if (page === PAGE.PLACES) {
            return MEDIUM_CARD_ONLY;
        }
        if (page === PAGE.FORMS) {
            return ALL_CARDS;
        }
        if (page === PAGE.REPORTS) {
            return MEDIUM_CARD_ONLY
        }
        if (page === PAGE.ALERTS) {
            return SMALL_CARD_ONLY;
        }
        if (page === PAGE.MESSAGES) {
            if (selectedConversation) {
                return ALL_CARDS;
            } else {
                return SMALL_CARD_ONLY;
            }
        }
        if (page === PAGE.TIMESHEET) {
            return MEDIUM_CARD_ONLY;
        }
        if (page === PAGE.STATS) {
            return MEDIUM_CARD_ONLY;
        }
        if (page === PAGE.INTEGRATIONS) {
            return MEDIUM_CARD_ONLY;
        }
        if (page === PAGE.SETTINGS) {
            return MEDIUM_CARD_ONLY;
        }
        if (page === PAGE.HELP) {
            return MEDIUM_CARD_ONLY;
        }
        if (page === PAGE.HEATMAP) {
            return SMALL_CARD_ONLY;
        }
        if (page === PAGE.UPLOADS) {
            return MEDIUM_CARD_ONLY;
        }
        if (page === PAGE.LOCATION || page === PAGE.TIMELINE) {
            return MEDIUM_CARD_ONLY;
        }
        if (page === PAGE.ACTIVITYLOG) {
            return MEDIUM_CARD_ONLY;
        }
        return NO_CARDS;
    }
});

export const mediumWidthState = atom({
    key: 'app.cards.medium.width',
    default: 0,
});

export const windowSizeState = atom({
    key: "window.size",
    default: getWindowSize()
});

export const windowIsDesktopState = selector({
    key: "window.isdesktop",
    get: ({ get }) => {
        return !get(windowIsMobileState) && !get(windowIsTabletState);
    }
});

export const windowIsTabletState = selector({
    key: "window.istablet",
    get: ({ get }) => {
        return get(windowSizeState).innerWidth < 950 && !get(windowIsMobileState);
    }
});

export const windowIsMobileState = selector({
    key: "window.ismobile",
    get: ({ get }) => {
        return get(windowSizeState).innerWidth < 600;
    }
});

export const customMediumCardWidthState = atomFamily<number, string>({
    key: "app.cards.medium.width.custom",
    default: 0,
    effects: [
        localStorageEffect,
    ]
});

export type CardSizes = {
    smallCardSize: number;
    mediumCardSize: number;
    mapCardSize: number;
    smallHeightExpanded?: boolean;
}

export const cardSizesState = atomFamily<CardSizes, string>({
    key: "app.card.sizes",
    default: {
        smallCardSize: 0,
        mediumCardSize: 0,
        mapCardSize: 0,
    },
    effects: [localStorageEffect],
});

export const panelsDefaultState = selectorFamily<{ defaultSmallCardSize: number; defaultMediumCardSize: number; defaultMapSize: number }, string>({
    key: "app.card.sizes.default",
    get:
        (page) =>
        ({ get }) => {
            let defaultSmallCardSize = 30;
            let defaultMediumCardSize = 40;

            if (page === PAGE.PLACES || page === PAGE.LOCATION || page === PAGE.MESSAGES) {
                defaultMediumCardSize = 50;
            }
            if (page === PAGE.FORMS || page === PAGE.INTEGRATIONS || page === PAGE.HELP) {
                defaultMediumCardSize = 60;
            }
            if (page === PAGE.REPORTS || page === PAGE.TIMESHEET) {
                defaultMediumCardSize = 70;
            }
            if (page === PAGE.TEAM || page === PAGE.ACTIVITYLOG) {
                defaultMediumCardSize = 80;
            }
            if (page === PAGE.STATS) {
                defaultMediumCardSize = 100;
            }

            const { smallIsVisible, mediumIsVisible } = get(visibleCardsState);
            defaultSmallCardSize = smallIsVisible ? defaultSmallCardSize : 0;
            defaultMediumCardSize = mediumIsVisible ? defaultMediumCardSize : 0;

            const defaultMapSize = 100 - defaultSmallCardSize - defaultMediumCardSize;

            return {
                defaultSmallCardSize,
                defaultMediumCardSize,
                defaultMapSize,
            };
        },
});

export const cardSizesValidState = selectorFamily<boolean, string>({
    key: "app.card.sizes.valid",
    get: (page) => ({ get }) => {
        const { smallCardSize, mediumCardSize, mapCardSize} = get(cardSizesState(page));
        return smallCardSize >= 0 && mediumCardSize >= 0 && mapCardSize >= 0
            && (smallCardSize + mediumCardSize + mapCardSize) === 100;
    }
});