import { atom } from "recoil";

export const audioRecorderBlobState = atom<Blob>({
    key: "audio.recorder.blob",
    default: undefined,
});

export const audioTranscriptionTextState = atom({
    key: "audio.transcription.text",
    default: "",
});

export const audioRecordingState = atom({
    key: "audio.recording",
    default: false,
});