import { useTheme } from "../../../hooks/useTheme";
import { StyledModal } from "../../ui/StyledModal";
import { ModalBody, ModalHeader } from "baseui/modal";
import React, { useState } from "react";
import { HFlex } from "../../containers/HFlex";
import { BookmarkIcon } from "../../ui/svg";
import { LabelMedium } from "baseui/typography";
import { Spacer } from "../../containers/Spacer";
import { Divider } from "antd";
import { VFlex } from "../../containers/VFlex";
import { templatesState } from "../../../states/templatesState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import JobItem from "../JobItem";
import { Input } from "baseui/input";
import { SIZE } from "baseui/button-group";
import { Search } from "baseui/icon";
import { useStrings } from "../../../hooks/useStrings";
import { Job } from "../../../types/job";
import { useJobAssignment } from "../../../hooks/jobs/useJobAssignment";
import { dayState, editingJobIdState } from "../../../states/appState";
import { MemberBadge } from "../../badges/MemberBadge";
import { useJobTemplatesUpdater } from "../../../hooks/jobs/useJobTemplatesUpdater";
import { useToast } from "../../../hooks/useToast";
import { Uid } from "../../../types/core";

type Props = {
    uid: Uid;
    index: number;
    onClose: () => void;
}

export function SelectTemplateModal({ uid, index, onClose }: Props) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const templates = useRecoilValue(templatesState(true));
    const [search, setSearch] = useState("");
    const jobAssignment = useJobAssignment();
    const day = useRecoilValue(dayState);
    const { createFromTemplate } = useJobTemplatesUpdater();
    const toast = useToast();
    const setEditingJob = useSetRecoilState(editingJobIdState);

    const lowercaseSearch = search.toLowerCase();
    const filtered = templates.filter(t => search.length === 0
        || t.destinationName.toLowerCase().includes(lowercaseSearch)
        || t.destinationText.toLowerCase().includes(lowercaseSearch)
    );

    const onSelected = async (template: Job) => {
        const job = await createFromTemplate(template, false);
        if (job) {
            await jobAssignment.assign(job.id, day, index, uid, job.receiverUid);
            setEditingJob(job.id);
            toast.showSuccess(strings.General.Created);
        }
        onClose();
    };

    return (
        <StyledModal
            isOpen={true}
            onClose={onClose}
            customSize={750}
        >
            <ModalHeader>
                <HFlex spacing alignCenter>
                    <BookmarkIcon size={16} color={theme.colors.contentPrimary}/>
                    <LabelMedium>{strings.JobTemplates.CreateNewJobFromTemplate}</LabelMedium>
                    <MemberBadge uid={uid}/>
                </HFlex>
            </ModalHeader>
            <ModalBody>
                <Input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    placeholder={strings.Map.Filter}
                    clearable
                    size={SIZE.mini}
                    startEnhancer={<Search/>}
                />
                <Spacer/>
                <Divider orientation="left"/>
                <VFlex spacing={"16px"} style={{ overflow: "auto", height: "50vh" }}>
                    {filtered.map(t => <JobItem key={t.id}
                                                id={t.id}
                                                onSelected={onSelected}
                                                pinnable={false}/>)}
                </VFlex>
            </ModalBody>
        </StyledModal>
    )
}