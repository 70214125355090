import { AiMessage, aiQuery } from "../services/privateApi";
import { useRecoilState, useResetRecoilState } from "recoil";
import { chatAiHistoryState, chatAiInputTextState, chatAiLoadingState } from "../states/chatai";
import { Endpoint } from "../services/api";

export function useChatAi(endpoint: Endpoint.salesaiquery | Endpoint.helpaiquery) {
    const [queries, setQueries] = useRecoilState(chatAiHistoryState);
    const [loading, setLoading] = useRecoilState(chatAiLoadingState);
    const resetInputText = useResetRecoilState(chatAiInputTextState);

    const sendQuery = async (queryString: string) => {
        if (loading) {
            return;
        }
        let currentId = Date.now();
        const newQuery = { query: queryString, response: "", id: currentId };
        const callback = (bufferUnfiltered: string) => {
            const idx = bufferUnfiltered.indexOf("[END-STREAM]");
            const buffer = idx > 0 ? bufferUnfiltered.substring(0, idx) : bufferUnfiltered;
            const updatedQuery = { ...newQuery, response: buffer };
            const newQueries = [...queries.filter(q => q.id !== currentId), updatedQuery];
            setQueries(newQueries);
        };
        setQueries([...queries, newQuery]);

        const history: AiMessage[] = [];
        if (endpoint === Endpoint.salesaiquery) {
            for (let q of queries) {
                history.push({ role: "user", content: q.query });
                history.push({ role: "assistant", content: q.response });
            }
        } else {
            const q = queries.length > 0 ? queries[queries.length - 1] : undefined;
            if (q) {
                history.push({ role: "user", content: q.query });
                history.push({ role: "assistant", content: q.response });
            }
        }
        resetInputText();
        setLoading(true)
        await aiQuery(endpoint, { query: queryString, history }, callback);
        setLoading(false);
    };

    return { sendQuery };
}