import React, { memo, ReactNode } from "react";
import { HFlex } from "../../containers/HFlex";
import { COLOR_DAYROUTE1, COLOR_DAYROUTE2, COLOR_DAYROUTE3 } from "../../../constants/colors";
import { interpolateHexColors } from "../../../utils/colors";

type Props = {
    index: number;
    count: number;
    height: string;
    toTheLeft?: ReactNode;
    children?: ReactNode;
}

function LegLine({ index, count, height, toTheLeft, children }: Props) {
    const progress = count > 0 ? index / count : 1;
    const color = interpolateHexColors(COLOR_DAYROUTE1, COLOR_DAYROUTE2, COLOR_DAYROUTE3, progress);

    return (
        <HFlex style={{ flexGrow: 0, flexShrink: 0, width: "100%" }}>
            <HFlex style={{ width: "64px" }}>
                {toTheLeft}
            </HFlex>
            <div style={{
                borderLeftStyle: "dashed",
                borderLeftWidth: "1.5px",
                borderLeftColor: color,
                borderRightStyle: "dashed",
                borderRightWidth: "1.5px",
                borderRightColor: color,
                width: 0,
                height: height,
            }}/>
            {children}
        </HFlex>
    );
}

export default memo(LegLine);