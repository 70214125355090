import { Spacer } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { useStrings } from "../../hooks/useStrings";
import { useRecoilValue } from "recoil";
import { selectedFormSubmissionState } from "../../states/formsState";
import { LabelMedium, LabelSmall, LabelXSmall } from "baseui/typography";
import { MemberBadge } from "../badges/MemberBadge";
import { useTheme } from "../../hooks/useTheme";
import { useTime } from "../../hooks/useTime";
import { FormItem } from "../../types/form";
import { Fragment } from "react";
import { getMatchingConditional } from "../../utils/formUtils";

export function FormSubmission() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const time = useTime();
    const form = useRecoilValue(selectedFormSubmissionState);

    const itemProps = {
        spacing: theme.sizing.scale300,
        style: {
            marginTop: theme.sizing.scale300,
            marginBottom: theme.sizing.scale600
        }
    };

    const getFormItemValue = (formItem: FormItem) => {
        if (formItem.type === "checkbox") {
            return +(formItem.value ?? 0);
        }
        if (formItem.type === "dropdown" || formItem.type === "radio") {
            return formItem.value ? formItem.value : "-" // FIXME: set the desired string for empty/null value
        }
        return formItem.value;
    } 

    if (!form) {
        return null;
    }

    return (
        <VFlex
            style={{
                paddingLeft: "8px",
                paddingRight: "8px",
            }}>
            <LabelMedium>
                {form.content.title}
            </LabelMedium>

            {form.content.description && <LabelSmall>
                {form.content.description}
            </LabelSmall>}

            <Spacer height="10px"/>

            <LabelXSmall color={theme.customColors.accent}>
                {strings.Forms.SubmittedBy} <MemberBadge uid={form.creatorUid}/> {time.formatDateTime(form.createdTs)}
            </LabelXSmall>

            <Spacer height="30px"/>

            {form.content.content.map((item, itemIndex) => {
                const matchingConditional = getMatchingConditional(item);

                return (
                    <Fragment key={itemIndex}>
                        <VFlex {...itemProps}>
                            <LabelSmall>{item.name}</LabelSmall>
                            <LabelXSmall>{getFormItemValue(item)}</LabelXSmall>
                        </VFlex>
                        {matchingConditional?.content.map((conditionalItem, conditionalItemIndex) => (
                            <VFlex key={conditionalItemIndex}
                                   {...itemProps}
                            >
                                <LabelSmall>{conditionalItem.name}</LabelSmall>
                                <LabelXSmall>{getFormItemValue(conditionalItem)}</LabelXSmall>
                            </VFlex>
                        ))}
                    </Fragment>
                )
            })}
            <Spacer/>
        </VFlex>
    );
}