import { LabelLarge } from 'baseui/typography';
import { useRecoilState, useRecoilValue } from 'recoil';
import { stringsState } from '../../../states/localeState';
import { layersCardIsOpenState } from '../../../states/skeletonState';
import { Spacer, SpacerExpand } from '../../containers/Spacer';
import { StyledCard } from '../../ui/StyledCard';
import { Enable3DPicker } from './Enable3DPicker';
import { IconSizeSlider } from './IconSizeSlider';
import { LayersPicker } from './LayersPicker';
import { MapTypePicker } from './MapTypePicker';
import { memo, useRef } from 'react';
import { useHover } from "usehooks-ts";
import { useTheme } from "../../../hooks/useTheme";
import { isTouchDevice } from "../../../utils/app";
import { Button } from "baseui/button";
import { BlurBackgroundIcon, CloseIcon } from "../../ui/svg";
import { HFlex } from "../../containers/HFlex";
import { blurState } from "../../../states/appState";
import { Switch, Tooltip } from "antd";

function LayersCard() {
    const { css, theme } = useTheme();
    const [layersCardIsOpen, setLayersCardIsOpen] = useRecoilState(layersCardIsOpenState);
    const strings = useRecoilValue(stringsState);
    const hoverRef = useRef(null);
    const isHovering = useHover(hoverRef);
    const isTouch = isTouchDevice();
    const [blur, setBlur] = useRecoilState(blurState);

    const dividerStyles = css({
        borderColor: theme.colors.borderTransparent,
        borderBottomWidth: "1px",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderTopWidth: "1px",
        marginTop: theme.sizing.scale600,
        marginBottom: theme.sizing.scale600,
    });

    const onCloseLayersMenu = () => {
        setLayersCardIsOpen(false);
    };

    const scaleFull = isTouch && layersCardIsOpen || !isTouch && isHovering;

    return (
        <div
            id="layersCard"
            ref={hoverRef}
            className={`layers-card`}
            style={{
                transform: scaleFull ? "scale(1)" : `scale(${isTouch ? 0 : 0.15})`,
            }}
        >
            <StyledCard
                blur={true}
                actions={
                    isTouch && <Button
                        size={"mini"}
                        shape={"circle"}
                        onClick={onCloseLayersMenu}
                        kind={"tertiary"}
                    >
                        <CloseIcon color={theme.colors.backgroundInversePrimary}/>
                    </Button>
                }
                style={{
                    borderBottomLeftRadius: scaleFull ? "8px" : "40px",
                    borderBottomRightRadius: scaleFull ? "8px" : "40px",
                    borderTopLeftRadius: scaleFull ? "8px" : "40px",
                    borderTopRightRadius: scaleFull ? "8px" : "40px",
                    borderWidth: scaleFull ? "1px" : "5px",
                    transition: "border-width 0.2s ease, border-radius 0.2s ease",
                    paddingLeft: "8px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingRight: "8px",
                }}
            >
                <div
                    className={css({
                        overflow: 'auto',
                    })}
                >
                    <HFlex alignCenter>
                        <Enable3DPicker/>
                        <SpacerExpand/>
                        <Tooltip title={strings.Map.TranslucentVsSolidBackground}>
                            <Switch
                                checked={blur}
                                checkedChildren={<BlurBackgroundIcon color={"white"}/>}
                                unCheckedChildren={<BlurBackgroundIcon color={"white"}/>}
                                onChange={setBlur}
                            />
                        </Tooltip>
                    </HFlex>
                    <Spacer/>
                    <LabelLarge marginBottom="scale500">{strings.Map.MapType}</LabelLarge>
                    <MapTypePicker/>
                    <hr className={dividerStyles}/>
                    <LabelLarge marginBottom="scale500">{strings.Map.Layers}</LabelLarge>
                    <LayersPicker/>
                    <hr className={dividerStyles}/>
                    <LabelLarge marginBottom="scale500">{strings.Map.IconSize}</LabelLarge>
                    <IconSizeSlider/>
                    <Spacer/>
                </div>
            </StyledCard>
        </div>
    );
}

export default memo(LayersCard);
