import { VFlex } from "../containers/VFlex";
import React from "react";
import { Button } from "baseui/button";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { useStrings } from "../../hooks/useStrings";
import { useRecoilState, useRecoilValue } from "recoil";
import { windowIsMobileState } from "../../states/skeletonState";
import { LinearFlex } from "../containers/LinearFlex";
import { ChatAiSuggestions } from "./ChatAiSuggestions";
import { ChatAiForm } from "./ChatAiForm";
import { StyledLink } from "baseui/link";
import { ButtonGroup } from "baseui/button-group";
import { currentLanguageState } from "../../states/localeState";
import { ChatAiBox } from "./ChatAiBox";

export function ChatAi({ ai }: { ai: "sales" | "help" }) {
    const { strings } = useStrings();
    const isMobile = useRecoilValue(windowIsMobileState);
    const [language, setLanguage] = useRecoilState(currentLanguageState);

    return (
        <VFlex style={{ backgroundColor: "white", width: "100%" }}>
            <HFlex spacing alignCenter style={{
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "12px",
                paddingBottom: "12px",
                width: "auto",
            }}>
                <StyledLink animateUnderline href="https://hellotracks.com">
                    ← {strings.ChatAi.BackToWebsite}
                </StyledLink>
                <SpacerExpand/>
                <ButtonGroup size={"mini"} kind={"secondary"}
                             selected={language?.startsWith("de") ? 2 : language?.startsWith("es") ? 1 : 0}>
                    <Button onClick={() => setLanguage("en")}>
                        EN
                    </Button>
                    <Button onClick={() => setLanguage("es")}>
                        ES
                    </Button>
                    <Button onClick={() => setLanguage("de")}>
                        DE
                    </Button>
                </ButtonGroup>
            </HFlex>
            <LinearFlex orientation={isMobile ? "vertical" : "horizontal"}
                        style={{ overflow: "auto" }}
                        spacing
            >
                <SpacerExpand/>
                {!isMobile && ai === "sales" && <ChatAiSuggestions/>}
                <SpacerExpand/>
                <ChatAiBox ai={ai}/>
                <SpacerExpand/>
                {ai === "sales" && <ChatAiForm/>}
                <SpacerExpand/>
            </LinearFlex>
        </VFlex>
    )
}