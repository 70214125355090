import * as Papa from "papaparse";
import * as XLSX from "xlsx";
import { isCsv, isExcel, isValidImportFile } from "../utils/app";

type Props = {
    onFileLoaded: (data: { header: string[]; rows: { [key: string]: string }[] }) => void;
};

export function useFileFeatures({ onFileLoaded }: Props) {
    const readCsvFile = (file: File) => {
        const reader = new FileReader();

        reader.onload = function () {
            const csvString = reader.result as string;
            Papa.parse(csvString, {
                header: true,
                complete: (results) => {
                    const dataArray = results.data as { [key: string]: string }[];
                    const header = Object.keys(dataArray[0]);
                    onFileLoaded({ header, rows: dataArray });
                },
            });
        };

        reader.readAsText(file);
    };

    const readExcelFile = (file: File) => {
        const reader = new FileReader();

        reader.onload = function () {
            const data = new Uint8Array(reader.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: "array", cellText: false, cellDates: true });

            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const dataArray: { [key: string]: string }[] = XLSX.utils.sheet_to_json(worksheet, {
                dateNF: 'yyyy"-"mm"-"dd',
                raw: false,
                defval: null,
            });
            const header = Object.keys(dataArray[0]);
            onFileLoaded({ header, rows: dataArray });
        };

        reader.readAsArrayBuffer(file);
    };

    const loadFile = (file: File) => {
        const filename = file.name;
        if (isValidImportFile(filename)) {
            if (isCsv(filename)) {
                readCsvFile(file);
            } else if (isExcel(filename)) {
                readExcelFile(file);
            }
        }
    }

    const createCsvFile = (fileName: string, data: string[][] = []): File => {
        const csvString = Papa.unparse(data);
        const csvBlob = new Blob([csvString], { type: "text/csv" });
        return new File([csvBlob], fileName);
    }

    return {
        loadFile,
        createCsvFile,
    };
}
