import { allPlacesState } from './placeState';
import { memberState, memberUidsState } from './membersState';
import { jobIdsState } from './jobsState';
import { atom, atomFamily, selector } from "recoil";
import { OnboardStepType } from "../types/onboard";
import { atomWithPersistence } from "../utils/persistence";
import { companyState, uidState } from './accountState';
import { DAY } from "../utils/time";

export const activeStepState = atom<OnboardStepType | undefined>({
    key: "onboard.steps.active",
    default: undefined,
});

export const onboardStepState = atomFamily<number, OnboardStepType | undefined>({
    key: "onboard.steps",
    default: 0,
});

export const onboardStatusState = atomWithPersistence<{ [K in OnboardStepType]?: boolean }>("onboard.steps.status", {
    signup: true,
    companySetup: true,
    usersPermissions: false,
    checkInNotifications: false,
    trackingSettings: false,
    concepts: false,
});

export const onboardJobsState = selector<boolean>({
    key: "onboard.steps.jobs.status",
    get: ({ get }) => {
        return !!get(jobIdsState).length;
    }
});

export const onboardAddingUsersState = selector<boolean>({
    key: "onboard.steps.addingusers.status",
    get: ({ get }) => {
        return get(memberUidsState).length > 1;
    }
});

export const onboardPlacesState = selector<boolean>({
    key: "onboard.steps.places.status",
    get: ({ get }) => {
        return !!get(allPlacesState).length;
    }
});

export const onboardDownloadState = selector<boolean>({
    key: "onboard.steps.download.status",
    get: ({ get }) => {
        const accountId = get(uidState);
        const member = get(memberState(accountId));
        return !!member?.device?.id;
    }
});

export const onboardStepsDoneState = selector<boolean>({
    key: "onboard.steps.done",
    get: ({ get }) => {
        return Object.values(get(onboardStatusState)).every((status) => status === true)
    }
});

export const onboardVisibleState = selector({
    key: "onboard.visibility",
    get: ({ get }) => {
        const isDisabled = get(onboardDisabledState);
        const isHidden = get(onboardHiddenState);
        const stepsDone = get(onboardStepsDoneState);
        const company = get(companyState);
        const uid = get(uidState);
        const member = get(memberState(uid));

        return !isDisabled
            && !isHidden
            && !stepsDone
            && company
            && company.activated_ts > 0
            && member?.timestamps
            && Date.now() - member.timestamps.created < DAY * 60;
    }
});

export const onboardHiddenState = atom({
    key: "onboard.hidden",
    default: false,
});

export const onboardDisabledState = atomWithPersistence("onboard.disabled", false);
