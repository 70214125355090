import { HeadingMedium } from "baseui/typography";
import { Spacer } from "../containers/Spacer";
import { MessageBubble } from "./MessageBubble";
import ReactMarkdown from "react-markdown";
import { MessageComponents } from "./MessageComponents";
import { HFlex } from "../containers/HFlex";
import TextArea from "antd/lib/input/TextArea";
import { Button } from "baseui/button";
import { SendIcon } from "../ui/svg";
import { VFlex } from "../containers/VFlex";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "../../hooks/useTheme";
import { useRecoilState, useRecoilValue } from "recoil";
import { chatAiHistoryState, chatAiInputTextState, chatAiLoadingState } from "../../states/chatai";
import { useStrings } from "../../hooks/useStrings";
import { analyticsEvent } from "../../utils/analytics";
import { useChatAi } from "../../hooks/useChatAi";
import { Endpoint } from "../../services/api";

export function ChatAiBox({ ai }: { ai: "sales" | "help" }) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const queries = useRecoilValue(chatAiHistoryState);
    const loading = useRecoilValue(chatAiLoadingState);
    const [inputText, setInputText] = useRecoilState(chatAiInputTextState);
    const [userQueriesCount, setUserQueriesCount] = useState(0);
    const { sendQuery } = useChatAi(ai === "help" ? Endpoint.helpaiquery : Endpoint.salesaiquery);
    const lastElementRef = useRef(null);
    const container: any = lastElementRef.current;

    useEffect(() => {
        container?.scrollIntoView({ behavior: "smooth" });
    }, [lastElementRef, container, queries]);

    const onSendClick = () => {
        if (loading) {
            return;
        }
        analyticsEvent("chatai_userquery");
        setUserQueriesCount(userQueriesCount + 1);
        sendQuery(inputText);
    }

    const placeholder = ai === "help"
        ? strings.ChatAi.AskAnything
        : userQueriesCount === 0
            ? strings.ChatAi.WhatsYourName
            : queries.length <= 2
                ? strings.ChatAi.BriefDescription
                : strings.ChatAi.AskAnything;

    return (
        <VFlex style={{
            maxWidth: ai === "sales" ? "580px" : "660px",
            overflow: "auto",
            paddingLeft: "8px",
            paddingRight: "8px"
        }}>
            <HeadingMedium>{ai === "sales" ? strings.ChatAi.Title : strings.ChatAi.AmaTitle}</HeadingMedium>
            <Spacer/>
            {queries.map((query, index) =>
                <div key={index} style={{ width: "100%" }}>
                    <MessageBubble incoming={false}>
                        {query.query}
                    </MessageBubble>
                    <MessageBubble incoming>
                        <style>
                            {"div.markdown img { max-width: 500px; height: auto;}"}
                        </style>
                        <ReactMarkdown className={"markdown"}>{query.response}</ReactMarkdown>
                        <MessageComponents query={query}/>
                    </MessageBubble>
                </div>
            )}
            <HFlex>
                <TextArea
                    rows={2}
                    autoFocus
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onKeyDown={event => {
                        if (event.key === "Enter") {
                            onSendClick();
                            event.preventDefault();
                        }
                    }}
                    placeholder={placeholder}
                />
                <Button size={"mini"}
                        disabled={inputText.length === 0}
                        onClick={onSendClick}
                        $disabled={loading}
                        kind={"tertiary"}
                >
                    <SendIcon color={theme.colors.contentPrimary} size={16}/>
                </Button>
            </HFlex>
            <Spacer height={"48px"}/>
            <div ref={lastElementRef}/>
        </VFlex>
    )
}