import { Button } from "baseui/button";
import { useState } from "react";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { averageSpeed, durationMillis, publishedLink, TrackEntry } from "../../types/track";
import { Clickable } from "../containers/Clickable";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { ConfirmModal } from "../ui/ConfirmModal";
import { LabeledField } from "../ui/LabeledField";
import { StyledModal } from "../ui/StyledModal";
import { StyledModalContent } from "../ui/StyledModalContent";
import { TrashIcon } from "../ui/svg";
import { downloadTracksAsGpx } from "../../services/download";
import { deleteTracks, HTTPS_DOMAIN, publishTracks } from "../../services/api";
import { StyledLink } from "baseui/link";
import { ClipboardButton } from "../buttons/ClipboardButton";
import { useTracksLayerUpdater } from "../../hooks/tracks/useTracksLayerUpdater";
import { useRecoilValue } from "recoil";
import { dayState, pageState } from "../../states/appState";
import { useTime } from "../../hooks/useTime";
import { useUnit } from "../../hooks/useUnit";
import { PAGE } from "../../constants/pages";
import { useTracksSelection } from "../../hooks/tracks/useTracksSelection";
import { PasswordConfirmation } from "../shared/PasswordConfirmation";
import { TrackSpeedHistogram } from "../histogram/TrackSpeedHistogram";
import { TimeBadge } from "../badges/TimeBadge";
import { TrackApprovalBar } from "./TrackApprovalBar";

type Props = {
    track: TrackEntry;
    canShare?: boolean;
    canDelete?: boolean;
    isOpen?: boolean;
    onClose: () => void;
};

export function TrackInfoModal({ track, onClose, canShare = false, canDelete = false, isOpen = true }: Props) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [passwordConfirmed, setPasswordConfirmed] = useState(false);
    const day = useRecoilValue(dayState);
    const { update, updateMemberTracksSameDates } = useTracksLayerUpdater();
    const time = <TimeBadge startMillis={track.startTs} endMillis={track.endTs} withDate/>
    const [published, setPublished] = useState(track.published);
    const url = publishedLink({ ...track, published });
    const { formatDuration } = useTime();
    const { distanceToString, speedToString } = useUnit();
    const duration = formatDuration(durationMillis(track));
    const distance = distanceToString(track.distance);
    const speed = "Ø " + speedToString(averageSpeed(track));
    const page = useRecoilValue(pageState);
    const [loading, setLoading] = useState(false);
    const { clearSelection } = useTracksSelection();

    const removeTrack = async () => {
        setLoading(true);
        await deleteTracks(track.account, [track]);
        update(track.account, day);
        if (page === PAGE.LOCATION) {
            updateMemberTracksSameDates(track.account).then(() => {});
            clearSelection();
        }
        setLoading(false);
        onClose();
    }

    const downloadGpx = async () => {
        setLoading(true);
        await downloadTracksAsGpx(track.account, [track]);
        setLoading(false);
    }

    const createSharableUrl = async () => {
        setLoading(true);
        const { status } = await publishTracks(track.account, [track], true);
        if (status) {
            update(track.account, day);
            if (page === PAGE.LOCATION) {
                updateMemberTracksSameDates(track.account).then(() => {});
            }
            setPublished(true);
        }
        setLoading(false);
    }

    return (
        <StyledModal isOpen={isOpen} onClose={onClose} defaultSize>
            <StyledModalContent
                header={strings.General.TrackInfo}
                body={
                    <VFlex>
                        <TrackSpeedHistogram entry={track}/>
                        <Spacer height={"24px"}/>
                        <LabeledField label={strings.General.Time} content={time} direction="horizontal"/>
                        <LabeledField label={strings.General.Start} content={track.startText} direction="horizontal"/>
                        <LabeledField label={strings.General.End} content={track.endText} direction="horizontal"/>
                        <LabeledField label={strings.General.Duration} content={duration} direction="horizontal"/>
                        <LabeledField label={strings.General.Distance} content={distance} direction="horizontal"/>
                        <LabeledField label={strings.General.Speed} content={speed} direction="horizontal"/>
                        {track.purpose && <LabeledField label={strings.General.Purpose} content={track.purpose}
                                                        direction="horizontal"/>}
                        {track.comment && <LabeledField label={strings.General.Comment} content={track.comment}
                                                        direction="horizontal"/>}
                        <LabeledField label={strings.Approval.Approval} content={<TrackApprovalBar track={track}/>}/>
                        <LabeledField label={strings.General.Download} content={
                            <StyledLink onClick={downloadGpx} href={"#"} animateUnderline>
                                {strings.General.DownloadAsGPXFile}
                            </StyledLink>
                        } direction="horizontal"/>
                        <LabeledField label={strings.General.Share} content={
                            <HFlex spacing alignCenter style={{ width: "unset" }}>
                                {url && (
                                    <>
                                        <StyledLink
                                            href={url}
                                            animateUnderline
                                            target={"_blank"}
                                        >
                                            {strings.General.OpenLink}
                                        </StyledLink>
                                        <ClipboardButton content={HTTPS_DOMAIN + url}/>
                                    </>
                                )}
                                {canShare && !url && (
                                    <Clickable onClick={createSharableUrl}>
                                        {`${strings.General.CreateURLToShareThisTrack}...`}
                                    </Clickable>
                                )}
                            </HFlex>
                        } direction="horizontal"/>
                    </VFlex>
                }
                footer={
                    <>
                        {canDelete && (
                            <Button
                                kind="tertiary"
                                size="compact"
                                onClick={() => passwordConfirmed ? setShowDeleteConfirm(true) : setShowPasswordConfirm(true)}
                                startEnhancer={<TrashIcon size={14} color={theme.colors.contentTertiary}/>}
                            >
                                {strings.General.Delete}
                            </Button>
                        )}
                        <SpacerExpand/>
                        <Button size="compact" onClick={onClose} isLoading={loading}>
                            {strings.General.OK}
                        </Button>
                    </>
                }
            />
            <ConfirmModal
                isOpen={showDeleteConfirm}
                description={strings.General.ReallyDeleteThisTrack}
                onConfirm={removeTrack}
                onCancel={() => setShowDeleteConfirm(false)}
                danger
                cancelText={strings.General.Cancel}
                confirmText={strings.General.Delete}
            />
            <PasswordConfirmation
                open={showPasswordConfirm}
                onClose={() => setShowPasswordConfirm(false)}
                onPasswordConfirmed={(confirmed) => {
                    setPasswordConfirmed(confirmed);
                    if (confirmed) {
                        setShowPasswordConfirm(false);
                        setShowDeleteConfirm(true);
                    }
                }}
            />
        </StyledModal>
    )
}