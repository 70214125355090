import { sortJobsByHeader } from './../utils/jobUtils';
import { atom, atomFamily, DefaultValue, selector, selectorFamily } from 'recoil';
import { Job, STATUS_ARCHIVED } from '../types/job';
import { dayState } from './appState';
import { boardMemberUidsState, boardShowAllUnassignedState } from "./boardState";
import { Day, Uid, UidDay, UidNumber } from "../types/core";
import { coordinatesOk, limitTo, searchMatch, sortJobsByNumber } from "../utils/jobUtils";
import { templatesState } from "./templatesState";
import { sequencesState } from "./sequencesState";
import { MAX_JOBS } from '../constants/jobs';
import { allJobsCustomSortFieldState, allJobsSortDirectionState, allJobsSortState } from './allJobsState';
import { SORT_DIRECTION } from 'baseui/table';
import { atomWithPersistence } from "../utils/persistence";

export const jobState = atomFamily<Job | undefined, string | undefined>({
    key: 'job',
    default: undefined,
});

export const jobIdsState = atom<string[]>({
    key: 'jobs.ids',
    default: [] as string[],
});

export const jobsState = selector({
    key: 'jobs.all',
    get: ({ get }) => {
        const jobs = get(jobIdsState)
            .map(id => get(jobState(id)))
            .filter(job => !!job) as Job[];
        return jobs
            .filter(job => coordinatesOk(job))
            .filter(job => job.status !== STATUS_ARCHIVED)
    },
});

export const jobsEvenArchivedState = selector({
    key: 'jobs.all.evenArchived',
    get: ({ get }) => {
        const jobs = get(jobIdsState)
            .map(id => get(jobState(id)))
            .filter(job => !!job) as Job[];
        return jobs
            .filter(job => coordinatesOk(job))
    },
});

export const jobsUpdateEventState = atom({ key: 'job.update.event', default: 0 });

export const jobsSearchState = atom({ key: 'jobs.search', default: '' });

export const jobsForSelectedMemberState = selectorFamily<Job[], {day: Day}>({
    key: 'jobs.member.selected',
    get: ({day}) => ({ get }) => {
        const memberUids = get(boardMemberUidsState);
        if (!memberUids || day === 0) {
            return [] as Job[];
        }
        const search = get(jobsSearchState);
        const allJobs = get(jobsState);
        const jobs = allJobs
            .filter(job => job.day === day)
            .filter(job => memberUids.includes(job.receiverUid))
            .filter(job => searchMatch(job, search))
            .sort(sortJobsByNumber);
        return limitTo(MAX_JOBS, jobs);
    },
});

export const jobsByDayState = selectorFamily<Job[], number>({
    key: 'jobs.day',
    get: (day) =>
        ({ get }) => {
            return get(jobsState).filter(job => job.day === day);
        },
});

export const jobsByMemberDayState = selectorFamily<Job[], UidDay>({
    key: 'jobs.member.day',
    get: ({ uid, day }) =>
        ({ get }) => {
            const memberUids = get(boardMemberUidsState);
            const day = get(dayState);
            if (!memberUids || day === 0) {
                return [] as Job[];
            }
            const search = get(jobsSearchState);
            const jobs = get(jobsByDayState(day))
                .filter(job => job.receiverUid === uid)
                .filter(job => searchMatch(job, search))
                .sort(sortJobsByNumber);
            return limitTo(MAX_JOBS, jobs);
        },
});

export const jobIdsByMemberDispatchState = selectorFamily<string[], UidDay>({
    key: 'jobs.member.dispatch',
    get: (uidDay) => ({ get }) => {
        const jobsByMemberDay = get(jobsByMemberDayState(uidDay));
        return jobsByMemberDay.map((job) => job.id);
    },
    set: (uidDay) => ({ get, set }, newOrderedIds) => {
        if (newOrderedIds instanceof DefaultValue) return;
        const jobs = newOrderedIds.map((id) => get(jobState(id))).filter((job) => !!job) as Job[];
        jobs.forEach((job) =>
            set(jobState(job.id), {
                ...job,
                number: newOrderedIds.findIndex((id) => id === job.id) + 1,
                receiverUid: uidDay.uid,
                day: uidDay.day,
            })
        );
    },
});

export const jobsUpdatingDispatchState = atom<{ providerUid: Uid | null; receiverUid: Uid | null; }>({
    key: 'jobs.member.updating',
    default: {
        providerUid: null,
        receiverUid: null,
    }
});

export const jobsByMemberOrUnassignedState = selectorFamily<Job[], UidDay>({
    key: 'jobs.member.day',
    get: (uidDay) => ({ get }) => {
        if (uidDay.uid === '') {
            return get(jobsForUnassignedState);
        } else {
            return get(jobsByMemberDayState(uidDay))
        }
    },
});

export const jobsForUnassignedState = selector({
    key: 'jobs.unassigned',
    get: ({ get }) => {
        const search = get(jobsSearchState);
        const showAllUnassigned = get(boardShowAllUnassignedState);
        const day = get(dayState);
        const sort = get(allJobsSortState);
        const direction = get(allJobsSortDirectionState);
        const customSortField = get(allJobsCustomSortFieldState);
        const jobs = get(jobsState)
            .filter(job => job.receiverUid === '')
            .filter(job => showAllUnassigned || job.day === day)
            .filter(job => searchMatch(job, search))
            .sort((j1, j2) => sortJobsByHeader(j1, j2, sort, customSortField));
        return limitTo(MAX_JOBS, direction === SORT_DIRECTION.DESC ? jobs.reverse() : jobs);
    },
});

export const jobIdsUnassignedDispatchState = atom<string[]>({
    key: 'jobs.unassiged.dispatch',
    default: selector({
        key: 'jobs.unassiged.dispatch.initializer',
        get: ({ get }) => {
            const unassignedJobs = get(jobsForUnassignedState);
            return unassignedJobs.map((job) => job.id); 
        }
    })
});

export const selectedJobIdsState = atom({
    key: 'jobs.selected.uids',
    default: [] as string[],
});

export const selectedJobsState = selector({
    key: 'jobs.selected',
    get: ({ get }) => {
        const ids = get(selectedJobIdsState);
        if (ids.length === 0) {
            return [] as Job[];
        }
        const jobs = get(jobsEvenArchivedState).filter(job => ids.includes(job.id));
        const templates = get(templatesState(true)).filter(job => ids.includes(job.id));
        let selection = [...jobs, ...templates];
        get(sequencesState).forEach(seq => {
            const selSeqJobs = seq.jobs.filter(job => ids.includes(job.id));
            if (selSeqJobs.length > 0) {
                selection = [...selection, ...selSeqJobs];
            }
        });
        return selection;
    }
});

export const selectedJobsCountState = selector({
    key: 'jobs.selected.count',
    get: ({ get }) => {
        let uids = get(selectedJobIdsState);
        return uids.length;
    }
});

export const jobsAssignedState = selector({
    key: 'jobs.assigned',
    get: ({ get }) => {
        const day = get(dayState);
        return get(jobsByDayState(day))
            .filter(job => !!job.assigneeUsername);
    }
});

export const creatingJobForUidState = atom<UidNumber | undefined>({
    key: 'job.creating.uidnumber',
    default: undefined,
});

export const jobIdsPinnedState = atomWithPersistence<string[]>("jobs.ids.pinned", []);

export const pinnedJobsState = selector<Job[]>({
    key: 'jobs.pinned',
    get: ({ get }) => {
        const search = get(jobsSearchState);
        const allJobs = get(jobsState);
        const pinnedIds = get(jobIdsPinnedState);
        const jobs = pinnedIds
            .map(id => allJobs.find(job => job.id === id));
        const filtered = jobs.filter(job => job && searchMatch(job, search));
        return filtered as Job[];
    },
});