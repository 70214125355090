import { selector } from 'recoil';
import { inviteCodesState } from './accountState';
import { InviteCodeData } from "../types/core";

export const validInviteCodeState = selector<InviteCodeData | undefined>({
    key: 'invites.code.valid',
    get: ({ get }) => {
        const inviteCodes = get(inviteCodesState);
        return [...inviteCodes]
            .filter((inviteCode) => !inviteCode.isExpired)
            .sort((c1, c2) => c2.expirationTs - c1.expirationTs)[0];
    },
});
