import { SignupStep } from "./../types/signup";
import { useStrings } from "./useStrings";

export function useSignupOptionalSteps() {
    const { strings } = useStrings();

    const numberOfUsersStep = {
        title: strings.Signup.EstimatedUsersTitle,
        fieldName: "users",
        options: [
            {
                description: "0-10",
                value: "0-10",
            },
            {
                description: "10-20",
                value: "10-20",
            },
            {
                description: "20-50",
                value: "20-50",
            },
            {
                description: "50-100",
                value: "50-100",
            },
            {
                description: "100+",
                value: "100+",
            },
        ],
        multiple: false,
        customOption: false,
    };

    const industryStep = {
        title: strings.Signup.IndustryTitle,
        fieldName: "industry",
        options: [
            {
                description: strings.Signup.IndustryRepairMaintenance,
                value: "repair_maintenance",
            },
            {
                description: strings.Signup.IndustryMobilityAndTransportation,
                value: "mobility_and_transportation",
            },
            {
                description: strings.Signup.IndustryPublicSector,
                value: "public_sector",
            },
            {
                description: strings.Signup.IndustryLogisticsDistribution,
                value: "logistics_distribution",
            },
        ],
        multiple: false,
        customOption: true,
    };

    const roleStep = {
        title: strings.Signup.RoleTitle,
        fieldName: "role",
        options: [
            {
                description: strings.Signup.RoleOperationsmanagerDirector,
                value: "operationsmanager_director",
            },
            {
                description: strings.Signup.RoleFieldStaffManagerDispatcher,
                value: "field_staff_manager_dispatcher",
            },
            {
                description: strings.Signup.RoleDeveloperItConsultant,
                value: "developer_it_consultant",
            },
            {
                description: strings.Signup.RoleHr,
                value: "hr",
            },
            {
                description: strings.Signup.RoleEntrepreneurOwner,
                value: "entrepreneur_owner",
            },
        ],
        multiple: false,
        customOption: true,
    };

    const findusStep = {
        title: strings.Signup.FindusTitle,
        fieldName: "leadsource",
        options: [
            {
                description: strings.Signup.FindusGoogleSearch,
                value: "google",
            },
            {
                description: strings.Signup.FindusFacebook,
                value: "facebook",
            },
            {
                description: strings.Signup.FindusLinkedin,
                value: "linkedin",
            },
            {
                description: strings.Signup.FindusSoftwareMarketPlace,
                value: "market_place",
            },
            {
                description: strings.Signup.FindusReferral,
                value: "referral",
            },
        ],
        multiple: true,
        customOption: true,
    };

    const goalStep = {
        title: strings.Signup.GoalTitle,
        fieldName: "goal",
        options: [
            {
                description: strings.Signup.GoalLiveLocation,
                value: "livelocation",
            },
            {
                description: strings.Signup.GoalCheckins,
                value: "checkins",
            },
            {
                description: strings.Signup.GoalLocationHistory,
                value: "locationhistory",
            },
            {
                description: strings.Signup.GoalDispatch,
                value: "dispatch",
            },
            {
                description: strings.Signup.GoalData,
                value: "data",
            },
        ],
        multiple: false,
        customOption: true,
    }

    const optionalSteps: SignupStep[] = [
        numberOfUsersStep,
        industryStep,
        roleStep,
        findusStep,
        goalStep,
    ];

    return { optionalSteps, numberOfUsersStep, industryStep, roleStep, findusStep, goalStep };
}
