import { SORT_DIRECTION, SortDirection } from "baseui/table";
import { atom } from "recoil";

export enum StatsSort {
    NAME = 1,
    TOTAL_DIST,
    AVG,
    MAX,
    TOTAL_TRACKS,
    TOTAL_JOBS,
    ONTIME_COUNT,
    DELAYS_COUNT,
    SUCCESS_COUNT,
    ISSUE_COUNT,
    ENGAGED,
    TOTAL_JOB_ONSITE,
    AVG_JOB_ONSITE,
    TOTAL_PLACE_ONSITE,
    AVG_PLACE_ONSITE,
    TOTAL_PAUSE,
    AVG_PAUSE,
    TOTAL_TRAVEL_TIME,
    TOTAL_TIME_BETWEEN_JOBS,
    AVG_TIME_BETWEEN_JOBS
}

export const statsSortState = atom({
    key: "stats.sort",
    default: StatsSort.NAME,
});

export const statsSortDirectionState = atom<SortDirection>({
    key: "stats.sort.direction",
    default: SORT_DIRECTION.ASC,
});