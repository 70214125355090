import React, { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from "recoil";
import { audioTranscriptionTextState } from "../../states/audioState";
import { AudioRecording } from "./AudioRecording";
import { AudioStartRecordingButton } from "./AudioStartRecordingButton";


export function AudioRecorder({ onText }: { onText: (text: string) => void }) {
    const text = useRecoilValue(audioTranscriptionTextState);
    const resetText = useResetRecoilState(audioTranscriptionTextState);
    const [showRecording, setShowRecording] = useState(false);

    useEffect(() => {
        if (text) {
            onText(text);
            resetText();
        }
    }, [text]);

    return (
        showRecording
            ? <AudioRecording/>
            : <AudioStartRecordingButton onClick={() => setShowRecording(true)}/>
    );
}