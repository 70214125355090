import { StyledCell, StyledRow } from "baseui/table";
import { LabelSmall } from "baseui/typography";
import { useStyleOverrides } from "../../../hooks/useStyleOverrides";
import { useUnit } from "../../../hooks/useUnit";
import { EllipsedText } from "../../ui/EllipsedText";
import { MemberStat } from "../../../types/stats";
import { useRecoilValue } from "recoil";
import { memberState } from "../../../states/membersState";
import { useRef } from "react";
import { useHover } from "usehooks-ts";
import { useTime } from "../../../hooks/useTime";

type Props = {
    stat: MemberStat
}

export function StatsRow({ stat }: Props) {
    const unit = useUnit();
    const time = useTime();
    const { gridStyle, statsColumnStyle } = useStyleOverrides();
    const member = useRecoilValue(memberState(stat?.memberUid));
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);

    if (!stat || !member) {
        return null;
    }

    return (
        <StyledRow ref={hoverRef} role="row" style={{ ...gridStyle(isHover) }}>
            <StyledCell $style={statsColumnStyle[0]}>
                <LabelSmall style={{ textAlign: "start", whiteSpace: "nowrap" }}>
                    <EllipsedText inline breakAll>{member.name || member.username}</EllipsedText>
                </LabelSmall>
            </StyledCell>
            <StyledCell $style={statsColumnStyle[1]}>
                {stat.totalDist > 0 ? unit.distanceToString(stat.totalDist) : ""}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[2]}>
                {stat.avg > 0 ? unit.speedToString(stat.avg) : ""}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[3]}>
                {stat.max > 0 ? unit.speedToString(stat.max) : ""}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[4]}>
                {stat.totalTracks > 0 ? stat.totalTracks : ""}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[5]}>
                {stat.totalJobs > 0 ? stat.totalJobs : ""}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[6]}>
                {stat.ontimeCount > 0 ? stat.ontimeCount : ""}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[7]}>
                {stat.delaysCount > 0 ? stat.delaysCount : ""}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[8]}>
                {stat.successCount > 0 ? stat.successCount : ""}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[9]}>
                {stat.issueCount > 0 ? stat.issueCount : ""}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[10]}>
                {stat.engagedCount + stat.passiveCount > 0
                    ? Math.round(stat.engagedCount * 100 / (stat.engagedCount + stat.passiveCount)) + "%"
                    : ""
                }
            </StyledCell>
            <StyledCell $style={statsColumnStyle[11]}>
                {time.formatDuration(stat.totalJobOnsiteTime)}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[12]}>
                {time.formatDuration(stat.avgJobOnsiteTime)}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[13]}>
                {time.formatDuration(stat.totalPlaceOnsiteTime)}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[14]}>
                {time.formatDuration(stat.avgPlaceOnsiteTime)}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[15]}>
                {time.formatDuration(stat.totalPauseTime)}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[16]}>
                {time.formatDuration(stat.avgPauseTime)}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[17]}>
                {time.formatDuration(stat.totalTravelTime)}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[18]}>
                {time.formatDuration(stat.avgTravelTime)}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[19]}>
                {time.formatDuration(stat.totalTimeBetweenJobs)}
            </StyledCell>
            <StyledCell $style={statsColumnStyle[20]}>
                {time.formatDuration(stat.avgTimeBetweenJobs)}
            </StyledCell>
        </StyledRow>
    );
}