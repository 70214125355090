import { atom, selector } from "recoil";
import { Place, PlaceListItem } from "./../types/place";
import { allPlacesState } from "./placeState";

export const pickerExcludedPlaces = atom<Place[]>({
    key: "places.picker.excluded",
    default: [],
});

export const placesPickerState = atom<PlaceListItem[]>({
    key: "places.picker",
    default: selector({
        key: "places.picked.default",
        get: ({ get }) => {
            const places = get(allPlacesState);
            const excludedPlaces = get(pickerExcludedPlaces);
            const excludedPlacesIds = excludedPlaces.map((place) => place.uid);

            return places
                .filter((place) => !excludedPlacesIds.includes(place.uid))
                .map((place) => ({ ...place, selected: false }));
        },
    }),
});

export const pickerSelectedPlacesState = selector<PlaceListItem[]>({
    key: "places.picker.selected",
    get: ({ get }) => {
        const places = get(placesPickerState);
        return places.filter((place) => place.selected);
    },
});
