import { useRecoilState } from "recoil";
import { placesSortDirectionState, placesSortState } from "../../states/placeState";
import { getNextDirection } from "../../utils/sort";

export function usePlacesSort() {
    const [direction, setDirection] = useRecoilState(placesSortDirectionState);
    const [placesSort, setPlacesSort] = useRecoilState(placesSortState);

    const handleSort = (sortNumber: number) => {
        const nextDirection = getNextDirection(direction);
        setPlacesSort(sortNumber);
        setDirection(nextDirection);
    };

    const getDirection = (sortNumber: number) => {
        return placesSort === sortNumber ? direction : null;
    }

    return {
        handleSort,
        getDirection,
    };
}
