import { HFlex } from '../containers/HFlex';
import MemberAvatar from './MemberAvatar';
import { LabelSmall } from "baseui/typography";
import { Spacer } from "../containers/Spacer";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { memberState } from "../../states/membersState";
import { StyleObject } from "styletron-react";
import { RoleBadge } from "../badges/RoleBadge";
import React, { useRef } from "react";
import { useHover } from "usehooks-ts";
import { editingMemberIdState } from "../../states/appState";
import { EditUserIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { HoverButton } from "../buttons/HoverButton";
import { useStrings } from "../../hooks/useStrings";
import { Uid } from "../../types/core";

type Props = {
    uid: Uid;
    style?: StyleObject,
    avatar?: boolean;
    role?: boolean;
    openButton?: boolean;
};

export function MemberItemSimplified({ uid, style, avatar = true, role = false, openButton = false }: Props) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const member = useRecoilValue(memberState(uid));
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const setEdit = useSetRecoilState(editingMemberIdState);

    if (!member) {
        return null;
    }

    return (
        <div ref={hoverRef} style={{ width: "100%" }}>
            <HFlex alignCenter style={{ ...style, minHeight: "24px" }}>
                {avatar &&
                    <>
                        <MemberAvatar uid={member.uid} forMap={false} size={16}/>
                        <Spacer/>
                    </>
                }
                <LabelSmall ref={hoverRef} margin={0}>
                    {member.name || member.username}
                </LabelSmall>
                {isHover && openButton &&
                    <>
                        <Spacer/>
                        <HoverButton size={"mini"} onClick={() => setEdit(uid)} tooltip={strings.General.OpenProfile}>
                            <EditUserIcon size={12} color={theme.colors.contentTertiary}/>
                        </HoverButton>
                    </>
                }
                {role && <>
                    <Spacer/>
                    <RoleBadge uid={uid} showWorker={true} tiny/>
                </>}
            </HFlex>
        </div>
    );
}
