import { getActivities } from "../services/api";
import { useRecoilState } from "recoil";
import { activitiesState } from "../states/appState";
import { requestingState } from "../states/viewState";
import { Uid } from "../types/core";

export function useActivities() {
    const [fetching, setFetching] = useRecoilState(requestingState({ req: "activities" }))
    const [activities, setActivities] = useRecoilState(activitiesState);

    const fetchActivities = async (uids: Uid[]) => {
        setFetching(true);
        const promises = uids.map(uid => getActivities(uid));
        try {
            const responses = await Promise.all(promises);
            responses.forEach(response => {
                if (response.status) {
                    setActivities(old => [...response.activities, ...old]);
                }
            });
        } catch (error) {
            console.error("Error fetching activities", error);
        } finally {
            setFetching(false);
        }
    }

    return { activities, fetchActivities, fetching };
}