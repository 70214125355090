import { useRecoilValue } from "recoil";
import { teamState } from "../../states/teamsState";
import { memberState } from "../../states/membersState";
import { LatLng, Uid } from "../../types/core";

export function useMemberRouting(uid: Uid) {
    const member = useRecoilValue(memberState(uid));
    const hasTeams = member?.teams && member.teams.length > 0;
    const team = useRecoilValue(teamState(hasTeams ? member.teams[0] : -1));
    const teamHasLocation = team?.route ? true : false;
    const startLocationIsSet = member?.route_start_lat && member?.route_start_lng ? true : false;
    const endLocationIsSet = member?.route_end_lat && member?.route_end_lng ? true : false;
    const startEndLocationsAreSet = startLocationIsSet && endLocationIsSet;

    const startLocation: LatLng | undefined = startEndLocationsAreSet ?
        {
            lat: member!!.route_start_lat,
            lng: member!!.route_start_lng
        } : teamHasLocation ? {
            lat: team!!.route!!.start_lat,
            lng: team!!.route!!.start_lng,
        } : undefined;

    const endLocation: LatLng | undefined = startEndLocationsAreSet ?
        {
            lat: member!!.route_end_lat,
            lng: member!!.route_end_lng
        } : teamHasLocation ? {
            lat: team!!.route!!.start_lat,
            lng: team!!.route!!.start_lng,
        } : undefined;

    return { startLocation, endLocation };
}