import { useTheme } from "../../../hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { Card } from "antd";
import { SORT_DIRECTION, StyledTable } from "baseui/table";
import { useStyleOverrides } from "../../../hooks/useStyleOverrides";
import { statsMembers } from "../../../services/api";
import { MemberStat } from "../../../types/stats";
import { StatsRow } from "./StatsRow";
import { VFlex } from "../../containers/VFlex";
import { StatsHeaderRow } from "./StatsHeaderRow";
import { MemberSearch } from "../../member/MemberSearch";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { memberNamesFilteredState, memberSearchState } from "../../../states/membersState";
import { Skeleton } from "baseui/skeleton";
import { useStrings } from "../../../hooks/useStrings";
import { StatsSort, statsSortDirectionState, statsSortState, } from "../../../states/statsState";
import { useEffectOnce } from "usehooks-ts";
import { selectedDayFrameState } from "../../../states/appState";

export function StatsTable() {
    const { strings } = useStrings();
    const { tableStyle } = useStyleOverrides();
    const [stats, setStats] = useState<MemberStat[]>([]);
    const memberSearch = useRecoilValue(memberSearchState);
    const [fetching, setFetching] = useState(false);
    const memberNames = useRecoilValue(memberNamesFilteredState);
    const sort = useRecoilValue(statsSortState);
    const sortDirection = useRecoilValue(statsSortDirectionState);
    const resetSort = useResetRecoilState(statsSortState);
    const resetSortDirection = useResetRecoilState(statsSortDirectionState);

    useEffectOnce(() => {
        return () => {
            resetSort();
            resetSortDirection();
        }
    });

    const dates = useRecoilValue(selectedDayFrameState);
    const fromDay = dates[0];
    const untilDay = dates[1];

    useEffect(() => {
        setFetching(true);
        statsMembers(fromDay, untilDay).then(({ status, stats }) => {
            if (status) {
                setStats(stats);
            }
        }).finally(() => setFetching(false));
    }, [fromDay, untilDay]);

    const reducedStats = stats.filter(m => m.totalDist > 0 || m.totalJobs > 0);
    const filteredStats = memberSearch.length === 0
        ? reducedStats
        : reducedStats.filter(s => s && memberNames.some(m => s?.memberUid === m?.uid));

    const sortedStats = useMemo(() => {
        const sorted = [...filteredStats].sort((s1, s2) => {
            if (sort === StatsSort.TOTAL_DIST) {
                return s2.totalDist - s1.totalDist;
            }
            if (sort === StatsSort.AVG) {
                return s2.avg - s1.avg;
            }
            if (sort === StatsSort.MAX) {
                return s2.max - s1.max;
            }
            if (sort === StatsSort.TOTAL_TRACKS) {
                return s2.totalTracks - s1.totalTracks;
            }
            if (sort === StatsSort.TOTAL_JOBS) {
                return s2.totalJobs - s1.totalJobs;
            }
            if (sort === StatsSort.ONTIME_COUNT) {
                return s2.ontimeCount - s1.ontimeCount;
            }
            if (sort === StatsSort.DELAYS_COUNT) {
                return s2.delaysCount - s1.delaysCount;
            }
            if (sort === StatsSort.SUCCESS_COUNT) {
                return s2.successCount - s1.successCount;
            }
            if (sort === StatsSort.ISSUE_COUNT) {
                return s2.issueCount - s1.issueCount;
            }
            if (sort === StatsSort.ENGAGED) {
                return s2.engagedCount - s1.engagedCount;
            }
            if (sort === StatsSort.TOTAL_JOB_ONSITE) {
                return s2.totalJobOnsiteTime - s1.totalJobOnsiteTime;
            }
            if (sort === StatsSort.TOTAL_PLACE_ONSITE) {
                return s2.totalPlaceOnsiteTime - s1.totalPlaceOnsiteTime;
            }
            if (sort === StatsSort.TOTAL_PAUSE) {
                return s2.totalPauseTime - s1.totalPauseTime;
            }
            if (sort === StatsSort.AVG_JOB_ONSITE) {
                return s2.avgJobOnsiteTime - s1.avgJobOnsiteTime;
            }
            if (sort === StatsSort.AVG_PLACE_ONSITE) {
                return s2.avgPlaceOnsiteTime - s1.avgPlaceOnsiteTime;
            }
            if (sort === StatsSort.AVG_PAUSE) {
                return s2.avgPauseTime - s1.avgPauseTime;
            }
            if (sort === StatsSort.TOTAL_TRAVEL_TIME) {
                return s2.totalTravelTime - s1.totalTravelTime;
            }
            if (sort === StatsSort.TOTAL_TIME_BETWEEN_JOBS) {
                return s2.totalTimeBetweenJobs - s1.totalTimeBetweenJobs;
            }
            if (sort === StatsSort.AVG_TIME_BETWEEN_JOBS) {
                return s2.avgTimeBetweenJobs - s1.avgTimeBetweenJobs;
            }
            if (sort === StatsSort.NAME) {
                const name1 = memberNames.find((uidName) => uidName.uid === s1.memberUid)?.name;
                const name2 = memberNames.find((uidName) => uidName.uid === s2.memberUid)?.name;
                if (name1 && name2) {
                    return name1.localeCompare(name2);
                }
            }
            return s1.memberUid.localeCompare(s2.memberUid);
        });
        return sortDirection === SORT_DIRECTION.DESC ? sorted.reverse() : sorted;
    }, [filteredStats, sort, sortDirection, memberNames]);


    return (
        <Card title={strings.Stats.Table}>
            <VFlex spacing>
                <MemberSearch/>
                <VFlex spacing style={{ overflowY: "hidden", overflowX: "auto" }}>
                    {fetching && <Skeleton width={"100%"} height={"300px"} animation />}
                    {!fetching && (
                        <StyledTable role="grid" style={{ ...tableStyle, minWidth: "2100px" }}>
                            <StatsHeaderRow/>
                            <VFlex style={{ overflowX: "hidden", overflowY: "auto" }}>
                                {sortedStats.map((stat, index) => (
                                    <StatsRow key={index} stat={stat}/>
                                ))}
                            </VFlex>
                        </StyledTable>
                    )}
                </VFlex>
            </VFlex>
        </Card>
    );
}