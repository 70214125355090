import React, { memo } from "react";
import { useBoard } from "../../hooks/useBoard";
import { TimelineColumn } from "./TimelineColumn";
import { useTheme } from "../../hooks/useTheme";
import { EmptyState } from "../ui/EmptyState";
import { TimelineIcon } from "../ui/svg";
import { Board } from "../board/Board";

export const TimelineBoard = memo(function TimelineBoardInternal() {
    const board = useBoard();
    const { theme } = useTheme();

    if (board.memberUids.length === 0) {
        return (
            <EmptyState>
                <TimelineIcon size={64} color={theme.colors.contentTertiary}/>
            </EmptyState>
        );
    }

    return (
        <Board>
            {board.memberUids.map(uid => (
                <TimelineColumn key={uid} uid={uid}/>
            ))}
        </Board>
    );
});
