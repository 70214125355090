import * as React from "react";
import { FormTemplate } from "../../types/form";
import { NewFormSubmissionContent } from "./NewFormSubmissionContent";
import { useForms } from "../../hooks/useForms";
import { useRecoilState } from "recoil";
import { formPreviewState } from "../../states/formsState";


export function FormPreviewContent({form}: { form: FormTemplate}) {
    const forms = useForms();
    const [previewForm, setPreviewForm] = useRecoilState(formPreviewState(form?.id ?? form?.submissionUid ?? ""));

    React.useEffect(() => {
        setPreviewForm(form);
    }, [form]);

    const onItemValueChange = (itemIndex: number, value: string|number) => {
        if (!previewForm) return;
        setPreviewForm({ ...previewForm, content: forms.setItemValue(itemIndex, value, previewForm.content) });
    }

    const onItemTouchedChange = (itemIndex: number) => {
        if (!previewForm) return;
        setPreviewForm({ ...previewForm, content: forms.setItemTouched(itemIndex, previewForm.content) });
    }

    const onConditionalItemValueChange = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, value: string|number) => {
        if (!previewForm) return;
        setPreviewForm({
            ...previewForm,
            content: forms.setConditionalItemValue(itemIndex, conditionalIndex, conditionalItemIndex, previewForm.content, value),
        });
    }
    
    const onConditionalItemTouchedChange = (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number) => {
        if (!previewForm) return;
        setPreviewForm({
            ...previewForm,
            content: forms.setConditionalItemTouched(itemIndex, conditionalIndex, conditionalItemIndex, previewForm.content),
        });
    }

    return (
        <NewFormSubmissionContent
            form={previewForm ?? form}
            onChange={onItemValueChange}
            onTouched={onItemTouchedChange}
            onChangeConditionalItem={onConditionalItemValueChange}
            onTouchedConditionalItem={onConditionalItemTouchedChange}
        />
    );
}