export const TRACKS_SELECT_COLUMN = "select";
export const TRACKS_NAME_COLUMN = "name";
export const TRACKS_DATE_COLUMN = "date";
export const TRACKS_LOCATION_COLUMN = "location";
export const TRACKS_DURATION_COLUMN = "duration";
export const TRACKS_DISTANCE_COLUMN = "distance";
export const TRACKS_TRIP_PURPOSE_COLUMN = "tripPurpose";
export const TRACKS_APPROVAL_COLUMN = "approval";
export const TRACKS_ACTIONS_COLUMN = "actions";
export const TRACKS_OPTIONS_COLUMN = "options";

export const TRACKS_DEFAULT_COLUMNS = [
    TRACKS_DATE_COLUMN,
    TRACKS_LOCATION_COLUMN,
    TRACKS_DURATION_COLUMN,
    TRACKS_DISTANCE_COLUMN,
    TRACKS_TRIP_PURPOSE_COLUMN,
    TRACKS_ACTIONS_COLUMN,
];

export const TRACKS_ALL_COLUMNS = [
    TRACKS_DATE_COLUMN,
    TRACKS_LOCATION_COLUMN,
    TRACKS_DURATION_COLUMN,
    TRACKS_DISTANCE_COLUMN,
    TRACKS_TRIP_PURPOSE_COLUMN,
    TRACKS_APPROVAL_COLUMN,
    TRACKS_ACTIONS_COLUMN,
];