import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import {
    MEMBERS_APP_COLUMN,
    MEMBERS_DEFAULT_COLUMNS,
    MEMBERS_LAST_LOCATION_COLUMN,
    MEMBERS_NAME_COLUMN,
    MEMBERS_OPTIONS_COLUMN,
    MEMBERS_ROLE_COLUMN,
    MEMBERS_STATUS_COLUMN,
    MEMBERS_TEAMS_COLUMN
} from '../../constants/members';
import { ColumnsConfiguration, ColumnsWidthType } from '../../types/core';
import { useStrings } from '../useStrings';
import { useStyleOverrides } from '../useStyleOverrides';
import { useMembersSort } from './useMembersSort';
import { membersColumnsState, MemberSort } from "../../states/membersState";

export function useMembersColumns() {
    const { strings } = useStrings();
    const { handleSort, getDirection } = useMembersSort();
    const { fixedColumnStyle } = useStyleOverrides();
    const [enabledColumns, setEnabledColumns] = useRecoilState(membersColumnsState);
    const disabledColumns = useMemo(
        () => [...MEMBERS_DEFAULT_COLUMNS].filter((column) => !enabledColumns.includes(column)),
        [enabledColumns]
    );
    const columns = [...enabledColumns, ...disabledColumns];

    const columnsWidth: ColumnsWidthType = {
        [MEMBERS_NAME_COLUMN]: 250,
        [MEMBERS_STATUS_COLUMN]: 160,
        [MEMBERS_ROLE_COLUMN]: 100,
        [MEMBERS_TEAMS_COLUMN]: 250,
        [MEMBERS_LAST_LOCATION_COLUMN]: 140,
        [MEMBERS_APP_COLUMN]: 100,
        [MEMBERS_OPTIONS_COLUMN]: 35,
    };

    const columnsConfiguration: ColumnsConfiguration = {
        [MEMBERS_NAME_COLUMN]: {
            title: strings.General.Name,
            direction: getDirection(MemberSort.NAME),
            styles: fixedColumnStyle(columnsWidth[MEMBERS_NAME_COLUMN], true),
            onSort: () => handleSort(MemberSort.NAME),
        },
        [MEMBERS_STATUS_COLUMN]: {
            title: strings.General.Status,
            direction: null,
            styles: fixedColumnStyle(columnsWidth[MEMBERS_STATUS_COLUMN]),
        },
        [MEMBERS_ROLE_COLUMN]: {
            title: strings.General.Role,
            direction: getDirection(MemberSort.ROLE),
            styles: fixedColumnStyle(columnsWidth[MEMBERS_ROLE_COLUMN]),
            onSort: () => handleSort(MemberSort.ROLE),
        },
        [MEMBERS_TEAMS_COLUMN]: {
            title: strings.General.Teams,
            direction: null,
            styles: fixedColumnStyle(columnsWidth[MEMBERS_TEAMS_COLUMN]),
        },
        [MEMBERS_LAST_LOCATION_COLUMN]: {
            title: strings.General.LastLocation,
            direction: getDirection(MemberSort.LAST_LOCATION),
            styles: fixedColumnStyle(columnsWidth[MEMBERS_LAST_LOCATION_COLUMN]),
            onSort: () => handleSort(MemberSort.LAST_LOCATION),
        },
        [MEMBERS_APP_COLUMN]: {
            title: "App",
            direction: getDirection(MemberSort.APP),
            styles: fixedColumnStyle(columnsWidth[MEMBERS_APP_COLUMN]),
            onSort: () => handleSort(MemberSort.APP),
        },
    };

    const tableWidth =
        columnsWidth[MEMBERS_NAME_COLUMN] +
        columnsWidth[MEMBERS_OPTIONS_COLUMN] +
        Object.entries(columnsWidth)
            .filter(([key]) => enabledColumns.includes(key))
            .map(([_, width]) => width)
            .reduce((accumulator, current) => accumulator + current, 0);

    return {
        columns,
        columnsConfiguration,
        columnsWidth,
        disabledColumns,
        enabledColumns,
        tableWidth,
        reorderColumns: setEnabledColumns,
    };
}
