import Map from '../map/Map';
import { useTheme } from "../../hooks/useTheme";
import { useRecoilValue } from "recoil";
import { sidebarWidthState } from "../../states/appState";
import Sidebar from '../menu/Sidebar';

export default function SkeletonMainContainer() {
    const { css } = useTheme();
    const sidebarWidth = useRecoilValue(sidebarWidthState);

    return (
        <div
            className={css({
                width: "100%",
                height: '100%',
                overflow: 'hidden',
                overflowX: 'hidden',
                position: "relative",
            })}
        >
            <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            }}>
                <Map/>
            </div>
            <div style={{
                position: "absolute",
                bottom: 0,
                top: 0,
                left: 0,
                overflow: "hidden",
                width: sidebarWidth,
                transition: "width 0.3s ease"
            }}>
                <Sidebar/>
            </div>
        </div>
    );
}
