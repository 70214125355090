import React from "react";
import { calcTotalsAndAveragesByMember, MemberStatDay, retrieveMemberUids } from "../../types/stats";
import { Datum, StackedBars } from "../../components/statistics/visx/StackedBars";
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { memberState } from "../../states/membersState";
import { useStrings } from "../useStrings";
import { asNumber } from "../../utils/objects";
import { ChartPanel } from "../../components/statistics/ChartPanel";

export function useMileageCharts({ stats, fetching, width }: { stats: MemberStatDay[], fetching: boolean, width: number }) {
    const { strings } = useStrings();
    const memberUids = retrieveMemberUids(stats);

    const [totalTravelTimeByMember, ] = calcTotalsAndAveragesByMember(stats, memberUids, 'totalTravelTime', 'totalTravelTime');
    const [totalPauseTimeByMember, ] = calcTotalsAndAveragesByMember(stats, memberUids, 'totalPauseTime', 'avgPauseTime');
    const [totalDistByMember, ] = calcTotalsAndAveragesByMember(stats, memberUids, 'totalDist', 'totalDist');

    const dataByMember: Datum[] = memberUids.map((uid, index) => ({
        bucketField: getRecoil(memberState(uid))?.name || uid,
        totalTravelTimes: totalTravelTimeByMember[index],
        totalPauseTimes: totalPauseTimeByMember[index],
        totalDistances: totalDistByMember[index],
    } as Datum));

    const dataByMemberFiltered = dataByMember
        .filter(d => asNumber(d, 'totalTravelTimes') + asNumber(d, 'totalPauseTimeByMember') > 0);

    const dataByMemberSortedByTotal = [...dataByMemberFiltered]
        .sort((d1: Datum, d2: Datum) => {
            const sum1 = asNumber(d1, 'totalTravelTimes');
            const sum2 = asNumber(d2, 'totalTravelTimes');
            return sum1 - sum2;
        });

    const dataByMemberSortedByDist = [...dataByMemberFiltered]
        .sort((d1: Datum, d2: Datum) => {
            const sum1 = asNumber(d1, 'totalDistances');
            const sum2 = asNumber(d2, 'totalDistances');
            return sum1 - sum2;
        });

    const rowHeight = 24;
    const rowPad = 100;

    return [
        <ChartPanel title={strings.Stats.TravelAndPauseTime} key={strings.Stats.TravelAndPauseTime}>
            <StackedBars width={width}
                            height={dataByMemberSortedByTotal.length * rowHeight + rowPad}
                            data={dataByMemberSortedByTotal}
                            fetching={fetching}
                            categories={["totalTravelTimes", "totalPauseTimes"]}
                            labels={[strings.Stats.TravelTime, strings.Stats.Pause]}
            />
        </ChartPanel>,
        <ChartPanel title={strings.Stats.TravelDistances} key={strings.Stats.TravelDistances}>
            <StackedBars width={width}
                            height={dataByMemberSortedByDist.length * rowHeight + rowPad}
                            data={dataByMemberSortedByDist}
                            fetching={fetching}
                            categories={["totalDistances"]}
                            labels={[strings.Stats.TravelDistances]}
                            type="distance"
            />
        </ChartPanel>
    ];
}