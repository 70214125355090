import { Badge, Tag } from "antd";
import { StyledCell, StyledRow } from "baseui/table";
import { LabelXSmall } from "baseui/typography";
import React, { memo, useRef } from "react";
import {
    MEMBERS_APP_COLUMN,
    MEMBERS_LAST_LOCATION_COLUMN,
    MEMBERS_NAME_COLUMN,
    MEMBERS_OPTIONS_COLUMN,
    MEMBERS_ROLE_COLUMN,
    MEMBERS_STATUS_COLUMN,
    MEMBERS_TEAMS_COLUMN,
} from "../../../constants/members";
import { tinyTag } from "../../../constants/ui";
import { useMemberInfo } from "../../../hooks/members/useMemberInfo";
import { useMemberStatusInfo } from "../../../hooks/members/useMemberStatusInfo";
import { useMembersColumns } from "../../../hooks/members/useMembersColumns";
import { useStyleOverrides } from "../../../hooks/useStyleOverrides";
import { useTheme } from "../../../hooks/useTheme";
import { hasValidDevice } from "../../../types/member";
import { LastLocationBadge } from "../../badges/LastLocationBadge";
import { RoleBadge } from "../../badges/RoleBadge";
import { TeamsBadges } from "../../badges/TeamsBadges";
import { HFlex } from "../../containers/HFlex";
import { AndroidIcon, AppleIcon } from "../../ui/svg";
import MemberRowItem from "./MemberRowItem";
import MemberStatusRowItem from "./MemberStatusRowItem";
import { useRecoilValue } from "recoil";
import { memberState } from "../../../states/membersState";
import { useHover } from "usehooks-ts";
import { Uid } from "../../../types/core";

type Props = {
    data: Uid[];
    index: number;
    style: React.CSSProperties;
};

export const MembersTableRow = memo(({ data, index, style }: Props) => {
    const uid = data[index];
    const member = useRecoilValue(memberState(uid));
    const { theme } = useTheme();
    const info = useMemberInfo();
    const { gridStyle, fixedColumnStyle } = useStyleOverrides();
    const statusInfo = useMemberStatusInfo(member);
    const { columnsWidth, columnsConfiguration, enabledColumns } = useMembersColumns();
    const badgeInfo = info.getBadgeInfo(member, true);
    const hoverRef = useRef(null);
    const isHovering = useHover(hoverRef);

    if (!member || !member.teams) {
        return null;
    }

    return (
        <StyledRow
            ref={hoverRef}
            role="row"
            style={{
                ...style,
                ...gridStyle(isHovering),
            }}
        >
            <StyledCell $style={{ ...columnsConfiguration[MEMBERS_NAME_COLUMN].styles }}>
                <MemberRowItem uid={uid}/>
            </StyledCell>
            {enabledColumns.map((column) => {
                return (
                    <StyledCell $style={columnsConfiguration[column].styles} key={column}>
                        {column === MEMBERS_STATUS_COLUMN && (
                            <>
                                {badgeInfo.text && (
                                    <MemberStatusRowItem uid={member.uid} statusInfo={badgeInfo}/>
                                )}
                            </>
                        )}
                        {column === MEMBERS_ROLE_COLUMN && <RoleBadge uid={member.uid} showWorker={true} shortName/>}
                        {column === MEMBERS_TEAMS_COLUMN && (
                            <TeamsBadges ids={member.teams} inline shortNames={member.teams.length > 2} max={2}/>
                        )}
                        {column === MEMBERS_LAST_LOCATION_COLUMN && (
                            <>{hasValidDevice(member) && <LastLocationBadge memberUid={member.uid}/>}</>
                        )}
                        {column === MEMBERS_APP_COLUMN && (
                            <>
                                {hasValidDevice(member) && (
                                    <Tag style={tinyTag}>
                                        <HFlex spacing alignCenter>
                                            {statusInfo.isAppleDevice ? (
                                                <AppleIcon size={10} color={theme.colors.contentPrimary}/>
                                            ) : (
                                                <AndroidIcon size={10} color={theme.colors.contentPrimary}/>
                                            )}
                                            <Badge dot={statusInfo.isUpdateAvailable}>
                                                <LabelXSmall
                                                    style={{ fontSize: "11px" }}>{member.device.app_version_name}</LabelXSmall>
                                            </Badge>
                                        </HFlex>
                                    </Tag>
                                )}
                            </>
                        )}
                    </StyledCell>
                );
            })}
            <StyledCell $style={{ ...fixedColumnStyle(columnsWidth[MEMBERS_OPTIONS_COLUMN]) }}/>
        </StyledRow>
    );
}); 
