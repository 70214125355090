import { Image } from "antd";
import { Button } from "baseui/button";
import { StyledLink } from "baseui/link";
import React from "react";
import { QueryResponse } from "../../types/chatai";
import { useStrings } from "../../hooks/useStrings";
import { analyticsEvent } from "../../utils/analytics";
import { VFlex } from "../containers/VFlex";

export function MessageComponents({ query }: { query: QueryResponse }) {
    const { strings } = useStrings();

    return (
        <VFlex spacing style={{ alignItems: "start" }}>
            {query.response.match(/https?:\/\/[^\s]+/g)?.map((url, idx) => {
                const isVideo = url.match(/\.(mp4|ogg|webm)$/);
                const isImage = url.match(/\.(jpg|png|jpeg)$/);
                const isSignup = url.includes("/signup");
                const isContact = url.includes("/#contact");
                if (isVideo) {
                    return (
                        <video key={url} width="320" height="240" controls>
                            <source src={url} type="video/mp4"/>
                        </video>
                    );
                }
                if (isImage) {
                    return (
                        <div key={idx} style={{ maxWidth: "300px", maxHeight: "300px" }}>
                            <Image src={url}
                                   style={{
                                       maxWidth: "300px",
                                       maxHeight: "300px",
                                       objectFit: "contain",
                                   }}/>
                        </div>
                    );
                }
                if (isSignup) {
                    return (
                        <Button key={idx}
                                size={"compact"}
                                onClick={() => {
                                    analyticsEvent("chatai_cta_signup");
                                    window.open("https://hellotracks.com/signup", "_blank")
                                }}
                        >
                            {strings.General.Signup}
                        </Button>
                    );
                }
                if (isContact) {
                    return (
                        <Button key={idx}
                                size={"compact"}
                                onClick={() => {
                                    analyticsEvent("chatai_cta_contactus");
                                    window.open("https://hellotracks.com/#contact", "_blank")
                                }}>
                            {strings.ChatAi.ContactUs}
                        </Button>
                    );
                }
                return (
                    <StyledLink key={idx}
                                animateUnderline
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer">
                        {url.length > 40 ? url.substring(0, 37) + "..." : url}
                    </StyledLink>
                );
            })}
        </VFlex>
    )
}