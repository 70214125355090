import { VFlex } from "./VFlex";
import { ReactNode } from "react";

type Props = {
    header?: ReactNode;
    footer?: ReactNode;
    children: ReactNode;
}

export function HeaderContentFooterLayout({ header, children, footer }: Props) {

    return (
        <VFlex style={{ height: "100%" }}>
            {header && (
                <div style={{ flexGrow: 0 }}>
                    {header}
                </div>
            )}
            <div style={{ flexGrow: 1, overflowY: "auto", width: "100%" }}>
                {children}
            </div>
            {footer && (
                <div style={{ flexGrow: 0 }}>
                    {footer}
                </div>
            )}
        </VFlex>
    );
}