import { LightTheme } from "baseui";
import { AspectRatioBox, AspectRatioBoxBody } from "baseui/aspect-ratio-box";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";
import { HeadingLarge, HeadingMedium, LabelLarge, ParagraphMedium } from "baseui/typography";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useEffectOnce } from "usehooks-ts";
import useAccount from "../../hooks/useAccount";
import { useNavigator } from "../../hooks/useNavigator";
import { useRedirect } from "../../hooks/useRedirect";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { loggedInState } from "../../states/accountState";
import { signupStepState } from "../../states/signupState";
import { Centered } from "../containers/Centered";
import { HFlex } from "../containers/HFlex";
import { ResponsiveFlex } from "../containers/ResponsiveFlex";
import { VFlex } from "../containers/VFlex";
import { useBodyWidth } from "../skeleton/hooks/useBodyWidth";
import { AccountSetup } from "./AccountSetup";
import { useEffect, useRef, useState } from "react";
import { analyticsSignup } from "../../utils/analytics";
import { PauseIcon, PlayIcon, VolumeIcon, VolumeMuteIcon } from "../ui/svg";
import { Button } from "baseui/button";
import { SpacerExpand } from "../containers/Spacer";

export function Signup() {
    const { strings } = useStrings();
    const { theme, css } = useTheme();
    const { bodyWidth } = useBodyWidth();
    const { navigateToPage } = useNavigator();
    const step = useRecoilValue(signupStepState);
    const resetStep = useResetRecoilState(signupStepState);
    const isLoggedIn = useRecoilValue(loggedInState);
    const account = useAccount();
    const [isMuted, setIsMuted] = useState(true);
    const [isPlaying, setIsPlaying] = useState(true);
    const videoRef = useRef<HTMLVideoElement>(null);

    useRedirect();
    useEffectOnce(() => {
        if (!isLoggedIn && step === 1) {
            account.setName("");
            account.setToken("");
            account.setPassword("");
            account.setUsername("");
        }
        return () => {
            resetStep();
        }
    });
    const mediumScreenQuery = "@media screen and (min-width: 768px)";
    const largeScreenQuery = "@media screen and (min-width: 900px)";
    const isTablet = bodyWidth >= 768 && bodyWidth <= 900;
    const logoStyles = css({
        height: "5vw",
        [mediumScreenQuery]: {
            height: "2.7vw",
        },
        [largeScreenQuery]: {
            height: "1.7vw",
        },
    });

    useEffect(() => {
        analyticsSignup(step);
    }, [step]);

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
                setIsPlaying(true);
            } else {
                videoRef.current.pause();
                setIsPlaying(false);
            }
        }
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
    };


    return (
        <ResponsiveFlex
            expand
            style={{
                overflowY: "auto",
                [theme.mediaQuery.medium]: {},
                [largeScreenQuery]: {
                    flexDirection: "row",
                    alignItems: "center",
                },
            }}
        >
            <ResponsiveFlex
                expand
                style={{
                    height: "auto",
                    overflowY: "auto",
                    backgroundColor: "white",
                    order: 2,
                    [theme.mediaQuery.medium]: {},
                    [mediumScreenQuery]: {
                        flexDirection: "row",
                        oder: 1,
                    },
                    [largeScreenQuery]: {
                        flexDirection: "column",
                        alignItems: "center",
                        height: "100%",
                        flex: 2,
                    },
                }}
            >
                <AspectRatioBox className={css({ width: "100%" })} aspectRatio={isTablet ? 9 / 16 : 16 / 8}>
                    <AspectRatioBoxBody>
                        <div style={{ position: "relative" }}>
                            <video
                                ref={videoRef}
                                controls
                                muted={isMuted}
                                autoPlay
                                loop
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                }}
                                onClick={toggleMute}
                            >
                                <source src="https://storage.googleapis.com/ht-web/intro-v1.mp4" type="video/mp4"/>
                            </video>
                            <HFlex spacing style={{
                                position: "absolute",
                                bottom: "30px",
                                right: "10px",
                            }}>
                                <SpacerExpand/>
                                <Button size={"mini"} onClick={togglePlayPause}>
                                    {isPlaying ? <PauseIcon size={16} color={"black"}/> :
                                        <PlayIcon size={16} color={"black"}/>}
                                </Button>
                                <Button size={"mini"} onClick={toggleMute}>
                                    {isMuted ? <VolumeIcon size={16} color={"black"}/> :
                                        <VolumeMuteIcon size={16} color={"black"}/>}
                                </Button>
                            </HFlex>
                        </div>
                    </AspectRatioBoxBody>
                </AspectRatioBox>
                <VFlex
                    style={{ padding: theme.sizing.scale900, justifyContent: "space-between", flex: 1, height: "auto" }}
                >
                    <Block marginBottom={theme.sizing.scale900}>
                        <HeadingLarge marginTop={0} marginBottom={theme.sizing.scale700}
                                      color={LightTheme.colors.contentPrimary}>
                            {strings.Signup.Title}
                        </HeadingLarge>
                        <LabelLarge
                            color={LightTheme.colors.contentSecondary}
                            style={{
                                lineHeight: "1.5",
                                letterSpacing: "1px",
                            }}
                        >
                            {strings.Signup.Paragraph}
                        </LabelLarge>
                        <HeadingMedium
                            marginTop={theme.sizing.scale700}
                            marginBottom={0}
                            color={LightTheme.colors.contentSecondary}
                        >
                            Joe Desimone
                        </HeadingMedium>
                        <ParagraphMedium margin={0} color={LightTheme.colors.contentSecondary}>
                            General Manager, Motivate LLC New York
                        </ParagraphMedium>
                    </Block>
                    <Block>
                        <LabelLarge
                            marginBottom={theme.sizing.scale300}
                            className={css({
                                textAlign: "center",
                                [mediumScreenQuery]: {
                                    padding: theme.sizing.scale700,
                                },
                            })}
                            color={LightTheme.colors.contentTertiary}
                        >
                            {strings.Signup.ClientsDescription}
                        </LabelLarge>
                        <HFlex
                            alignCenter
                            spacing={theme.sizing.scale300}
                            style={{
                                justifyContent: "space-between",
                            }}
                        >
                            <img src={"images/lyft.png"} loading="lazy" alt="" className={logoStyles}/>
                            <img src={"images/houston.png"} loading="lazy" alt="" className={logoStyles}/>
                            <img src={"images/islington.png"} loading="lazy" alt="" className={logoStyles}/>
                            <img src={"images/pmi.png"} loading="lazy" alt="" className={logoStyles}/>
                        </HFlex>
                    </Block>
                </VFlex>
            </ResponsiveFlex>
            <VFlex
                style={{
                    minHeight: "100vh",
                    order: 1,
                    [mediumScreenQuery]: {
                        order: 2,
                    },
                    [largeScreenQuery]: {
                        flex: 3,
                    },
                    backgroundImage: "url(images/login-background.svg)",
                    backgroundPosition: "50%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    overflow: "hidden",
                }}
            >
                <Centered>
                    <AccountSetup/>
                </Centered>
                <ParagraphMedium style={{ textAlign: "center" }}>
                    {strings.Signup.HaveAccount}
                    {" "}
                    <StyledLink
                        animateUnderline
                        href="/login"
                        onClick={(event: any) => {
                            event.preventDefault();
                            navigateToPage("login", true);
                        }}
                    >
                        {strings.Signup.SignIn}
                    </StyledLink>
                </ParagraphMedium>
            </VFlex>
        </ResponsiveFlex>
    );
}
