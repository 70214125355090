import { DatePicker } from "baseui/datepicker";
import React from "react";
import { useTime } from "../../hooks/useTime";
import { useRecoilState, useResetRecoilState } from "recoil";
import { tracksDatesState } from "../../states/tracksState";

export function LocationDateRange() {
    const { dateFormat } = useTime();
    const [dates, setDates] = useRecoilState(tracksDatesState);
    const resetDates = useResetRecoilState(tracksDatesState);

    return (
        <DatePicker
            value={dates}
            range
            size="mini"
            placeholder={`${dateFormat} - ${dateFormat}`}
            formatString={dateFormat}
            onClose={() => {
                if (!dates[0] || !dates[1]) {
                    resetDates();
                }
            }}
            onChange={({ date }) => {
                if (Array.isArray(date)) {
                    setDates(date);
                }
            }}
            overrides={{
                Input: {
                    props: {
                        overrides: {
                            InputContainer: {
                                style: {
                                    boxSizing: "content-box",
                                }
                            }
                        }
                    }
                },
                InputWrapper: {
                    style: {
                        width: "140px",
                    },
                }
            }}
        />
    );
}