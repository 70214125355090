import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { mediumState, mediumWidthState, smallState, visibleCardsState } from '../../../states/skeletonState';
import { useBodyWidth } from './useBodyWidth';

export function useMediumCard() {
    const { smallIsVisible } = useRecoilValue(visibleCardsState);
    const [cardWidth, setCardWidth] = useRecoilState(mediumWidthState);
    const setMedium = useSetRecoilState(mediumState);
    const { bodyWidth } = useBodyWidth();
    const setSmall = useSetRecoilState(smallState);

    const onKnobMediumClick = useCallback(
        (shouldOpen: boolean) => {
            setMedium((oldMedium) => ({
                ...oldMedium,
                isOpened: !shouldOpen,
                knob: {
                    ...oldMedium.knob,
                    shouldOpen,
                },
            }));
            if (bodyWidth > 767 && smallIsVisible) {
                setSmall((oldSmall) => ({
                    ...oldSmall,
                    isOpened: !shouldOpen,
                    knob: {
                        ...oldSmall.knob,
                        shouldOpen,
                    },
                }));
            }
        },
        [bodyWidth, smallIsVisible, setCardWidth]
    );

    const setMediumDefaults = useCallback(
        (canExpand: boolean) => {
            setMedium((prevMedium) => ({
                ...prevMedium,
                canExpand,
                knob: {
                    ...prevMedium.knob,
                    isVisible: true,
                },
            }));
        },
        [setMedium]
    );

    const setSmallDefaults = useCallback(
        (isOpened: boolean) => {
            setSmall((prevSmall) => ({
                ...prevSmall,
                isOpened,
                knob: {
                    ...prevSmall.knob,
                    shouldOpen: !isOpened,
                },
            }));
        },
        [setSmall]
    );

    return {
        cardWidth,
        onKnobMediumClick,
        setMediumDefaults,
        setSmallDefaults,
        setCardWidth
    };
}
