import { useRecoilValue } from "recoil";
import { billingState } from "../../states/accountState";
import { Elements } from "@stripe/react-stripe-js";
import { AddPaymentMethodForm } from "./AddPaymentMethodForm";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import { paymentClientSecretState } from "../../states/paymentState";


export function Payment() {
    const billing = useRecoilValue(billingState);
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
    const clientSecret = useRecoilValue(paymentClientSecretState);

    useEffect(() => {
        if (billing?.stripePublicKey) {
            setStripePromise(loadStripe(billing?.stripePublicKey));
        }
    }, [billing?.stripePublicKey]);

    if (!stripePromise || !clientSecret) {
        return <Spin/>;
    }

    const options = {
        clientSecret: clientSecret,
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <AddPaymentMethodForm/>
        </Elements>
    );
}