import { membersSortDirectionState, membersSortState } from '../../states/membersState';
import { useRecoilState } from "recoil";
import { getNextDirection } from "../../utils/sort";

export function useMembersSort() {
    const [direction, setDirection] = useRecoilState(membersSortDirectionState);
    const [membersSort, setMembersSort] = useRecoilState(membersSortState);

    const handleSort = (sortNumber: number) => {
        const nextDirection = getNextDirection(direction);
        setMembersSort(sortNumber);
        setDirection(nextDirection);
    };

    const getDirection = (sortNumber: number) => {
        return membersSort === sortNumber ? direction : null;
    }

    return {
        handleSort,
        getDirection,
    };
}
