import { MemberStatDay, retrieveDonutData } from "../../types/stats";
import React from "react";
import { Skeleton } from "baseui/skeleton";
import { Donut } from "./visx/Donut";
import { useTeams } from "../../hooks/members/useTeams";

export function JobTeamsChart({
                                  stats,
                                  fetching,
                                  width,
                                  height
                              }: { stats: MemberStatDay[], fetching: boolean, width: number, height: number }) {
    const { getTeamName } = useTeams();


    if (fetching) {
        return (
            <Skeleton width={width + "px"} height={height + "px"} animation overrides={{
                Root: {
                    style: {
                        borderRadius: "12px"
                    }
                }
            }}/>
        )
    }

    const data = retrieveDonutData("jobTeams", stats, getTeamName)

    return (
        <Donut width={width} height={height} data={data}/>
    );
}