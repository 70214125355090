import { Spin } from "antd";
import { LightTheme } from "baseui";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import { Centered } from "../containers/Centered";
import { HFlex } from "../containers/HFlex";
import { EmptyState } from "../ui/EmptyState";
import { useNavigator } from "../../hooks/useNavigator";
import { activation } from "../../services/api";
import { useSetRecoilState } from "recoil";
import { passwordRecoveryActivationState } from "../../states/viewState";
import { GoToDashboard } from "./GoToDashboard";
import { GetApp } from "./GetApp";
import { analyticsEvent } from "../../utils/analytics";

export function ActivationView() {
    const { paramToken } = useParams();
    const [validating, setValidating] = useState(true);
    const [redirect, setRedirect] = useState<"getapp" | "login" | "passwordrecovery">();
    const { navigateToPage } = useNavigator();
    const setPasswordRecoveryActivation = useSetRecoilState(passwordRecoveryActivationState);

    useEffectOnce(() => {
        const validateToken = async () => {
            setValidating(true);
            const uid = paramToken?.substring(0, 6);
            if (uid) {
                const token = paramToken?.substring(6, paramToken.length);
                const { status, recoveryToken, redirect } = await activation({
                    token: uid + "-" + token,
                });
                if (status) {
                    analyticsEvent("activation", redirect);
                    if (redirect === "passwordrecovery" && recoveryToken) {
                        setPasswordRecoveryActivation(true);
                        navigateToPage("go/pwdrecovery/" + uid + "-" + recoveryToken, true);
                    } else {
                        setRedirect(redirect);
                    }
                } else {
                    analyticsEvent("activation", "invalid");
                    navigateToPage("login", true);
                }
            }
            setValidating(false);
        };

        validateToken();
    });

    return (
        <HFlex
            style={{
                display: "flex",
                background: LightTheme.colors.backgroundPrimary,
                overflowY: "auto",
                justifyContent: "center",
                height: "100%",
            }}
        >
            <Centered>
                {validating && (
                    <EmptyState>
                        <Spin/>
                    </EmptyState>
                )}
                {!validating && redirect === "getapp" && (
                    <GetApp/>
                )}
                {!validating && redirect === "login" && (
                    <GoToDashboard/>
                )}
            </Centered>
        </HFlex>
    );
}
