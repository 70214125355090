import { NUMBER_LAST } from "../../../types/job";
import { SpacerExpand } from "../../containers/Spacer";
import { ActionButton } from "../../buttons/ActionButton";
import { EmptyState } from "../../ui/EmptyState";
import React, { memo } from "react";
import { useNewJob } from "../../../hooks/jobs/useNewJob";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { dayState } from "../../../states/appState";
import { creatingJobForUidState } from "../../../states/jobsState";
import { useStrings } from "../../../hooks/useStrings";
import { useTime } from "../../../hooks/useTime";
import { memberState } from "../../../states/membersState";
import { useDrawMode } from "../../../hooks/useDrawMode";
import { HFlex } from "../../containers/HFlex";
import { Section } from "../../ui/Section";
import { Uid } from "../../../types/core";

function EmptyRouteBanner({ uid }: { uid: Uid }) {
    const { strings, format } = useStrings();
    const time = useTime();
    const setCreatingJobForUid = useSetRecoilState(creatingJobForUidState);
    const { initNewJob } = useNewJob();
    const day = useRecoilValue(dayState);
    const member = useRecoilValue(memberState(uid));
    const { enterDrawMode } = useDrawMode();

    if (!member) {
        return null;
    }

    return (
        <Section title={strings.Job.DropJobAndArrange} expandable>
            <EmptyState
                cta={(
                    <HFlex alignCenter spacing>
                        <SpacerExpand/>
                        <ActionButton tooltip={format(strings.General.NewJobForXonX, member.name, time.formatDay(day))}
                                      placement={"bottom"}
                                      size={"mini"}
                                      kind={"secondary"}
                                      onClick={() => initNewJob({ uid, number: NUMBER_LAST })}>
                            {strings.Job.NewJob}
                        </ActionButton>
                        <ActionButton tooltip={strings.Routing.QuickRouteDesc}
                                      placement={"bottom"}
                                      size={"mini"}
                                      kind={"tertiary"}
                                      onClick={() => {
                                          setCreatingJobForUid({ uid, number: 0 });
                                          enterDrawMode({
                                              type: "route",
                                              action: "new",
                                          });
                                      }}>
                            {strings.Routing.NewRoute}
                        </ActionButton>
                        <SpacerExpand/>
                    </HFlex>
                )}
            >
            </EmptyState>
        </Section>
    );
}

export default memo(EmptyRouteBanner);