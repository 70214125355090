import { Spin } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { Plus } from "baseui/icon";
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { useRecoilValue } from "recoil";
import { useTheme } from "../../hooks/useTheme";
import { stringsState } from "../../states/localeState";
import { StyleObject } from "styletron-react";

type Props = {
    onValid: (file: File) => void;
    isLoading?: boolean;
    styles?: StyleObject;
};

export function PDFUploader({ onValid, isLoading = false, styles = {} }: Props) {
    const strings = useRecoilValue(stringsState);
    const { css } = useTheme();

    const draggerProps: React.ComponentProps<typeof Dragger> = {
        showUploadList: false,
        accept: ".pdf",
        customRequest: (options: UploadRequestOption) => {
            onValid(options.file as File);
        },
        className: css({
            display: "block",
            height: "100px",
            ...styles,
        }),
    }

    return (
        <Dragger {...draggerProps}>
            <div>
                {isLoading ? <Spin />  : <Plus size={16}/> }
                <div>{strings.General.PDFUpload}</div>
            </div>
        </Dragger>
    );
}