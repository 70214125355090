import { Typography } from "antd";
import { FormControl } from "baseui/form-control";
import { useStrings } from "../../hooks/useStrings";
import { ConditionalFormItemTouched, FormItemTouched, FormSubmitTemplate } from "../../types/form";
import { FormPreviewElement } from "./FormPreviewElement";
import { Fragment } from "react";
import { getConditionalIndex, getMatchingConditional } from "../../utils/formUtils";

const { Text, Title } = Typography;

type Props = {
    form: FormSubmitTemplate;
    onChange: (index: number, value: string | number) => void;
    onTouched: (index: number) => void;
    onChangeConditionalItem: (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number, value: string|number) => void;
    onTouchedConditionalItem: (itemIndex: number, conditionalIndex: number, conditionalItemIndex: number) => void;
};

export function NewFormSubmissionContent(props: Props) {
    const {
        form,
        onChange,
        onTouched,
        onChangeConditionalItem,
        onTouchedConditionalItem
    } = props;
    const { strings } = useStrings();
    const { content: formContent } = form;

    return (
        <>
            <Title level={3}>{formContent.title}</Title>

            {formContent.description && <Text type="secondary">{formContent.description}</Text>}

            {formContent.content.map((item: FormItemTouched, itemIndex) => {
                const conditionalIndex = getConditionalIndex(item);
                const matchingConditional = getMatchingConditional(item);

                return (
                    <Fragment key={itemIndex}>
                        <FormControl
                            error={item.required && item.touched && !item.value && strings.General.FieldIsRequired}
                            overrides={{ 
                                ControlContainer: { style: {
                                    marginBottom: item.type !== "section" ? "24px" : 0}
                                },
                                Caption: {
                                    style: {
                                        marginTop: 0,
                                        marginBottom: 0,
                                    }
                                }
                            }}
                        >
                            <FormPreviewElement
                                index={itemIndex}
                                formItem={item}
                                onChange={(value) => {
                                    onChange(itemIndex, value);
                                }}
                                onTouched={() => onTouched(itemIndex)}
                                size="middle"
                                width={"90%"}
                            />
                        </FormControl>
                        {matchingConditional?.content.map((conditionalItem: ConditionalFormItemTouched, conditionalItemIndex) => (
                            <FormControl
                                key={conditionalItemIndex}
                                error={conditionalItem.required && conditionalItem.touched && !conditionalItem.value && strings.General.FieldIsRequired}
                                overrides={{ 
                                    ControlContainer: { style: {
                                        marginBottom: conditionalItem.type !== "section" ? "24px" : 0}
                                    },
                                    Caption: {
                                        style: {
                                            marginTop: 0,
                                            marginBottom: 0,
                                        }
                                    }
                                }}
                            >
                                <FormPreviewElement
                                    index={conditionalItemIndex}
                                    formItem={conditionalItem}
                                    onChange={(value) => onChangeConditionalItem(itemIndex, conditionalIndex!, conditionalItemIndex, value)}
                                    onTouched={() => onTouchedConditionalItem(itemIndex, conditionalIndex!, conditionalItemIndex)}
                                    size="middle"
                                    width={"90%"}
                                />
                            </FormControl>
                        ))}
                    </Fragment>
                )
            })}
        </>
    );
}
