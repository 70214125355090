import { Button } from 'baseui/button';
import { ChevronDown } from 'baseui/icon';
import { PLACEMENT, StatefulPopover } from 'baseui/popover';
import { SequenceIcon } from "../../ui/svg";
import { useRecoilValue } from "recoil";
import { sequencesState } from "../../../states/sequencesState";
import { useBoard } from "../../../hooks/useBoard";
import { SequenceNameModal } from './SequenceNameModal';
import { memo, useState } from 'react';
import { useTheme } from "../../../hooks/useTheme";
import { useStrings } from "../../../hooks/useStrings";
import { useNewSequence } from '../../../hooks/jobs/useNewSequence';
import { StyledMenu } from '../../ui/StyledMenu';
import { COLOR_TRANSLUCENT } from "../../../constants/colors";

function ShowSequenceMenu() {
    const { theme } = useTheme();
    const { strings, format } = useStrings();
    const board = useBoard();
    const sequences = useRecoilValue(sequencesState);
    const [showNewSequence, setShowNewSequence] = useState(false);
    const { createEmptySequence } = useNewSequence();
    const sequenceItems = sequences.map(s => {
        return {
            id: s.id,
            label: `${s.name} (${s.jobs.length > 0 ? format(strings.Job.XJobs, s.jobs.length) : strings.General.Empty})`,
            sequence: s,
        }
    });
    const items = {
        __ungrouped: [{ id: 'new', label: strings.Sequences.NewEmptySequence }],
        [`${strings.Sequences.SavedSequences}:`]: sequenceItems,
    };

    const closeNewSequence = () => {
        setShowNewSequence(false);
    }

    return (
        <>
            <StatefulPopover
                focusLock
                placement={PLACEMENT.bottomLeft}
                content={({ close }) => (
                    <StyledMenu
                        items={items}
                        onItemSelect={(opt) => {
                            if (opt.item.id === 'new') {
                                setShowNewSequence(true);
                            } else {
                                board.addSequence(opt.item.id);
                            }
                            close();
                        }}
                        overrides={{
                            List: {
                                style: {
                                    maxHeight: "50vh"
                                }
                            },
                        }}
                    />
                )}
            >
                <Button kind={"secondary"}
                        endEnhancer={() => <ChevronDown size={24}/>}
                        size={"mini"}
                        shape={"pill"}
                        overrides={{
                            BaseButton: {
                                style: {
                                    backgroundColor: COLOR_TRANSLUCENT,
                                    height: "28px",
                                    marginRight: "8px",
                                }
                            }
                        }}
                >
                    <SequenceIcon color={theme.colors.contentPrimary} size={14}/>
                </Button>
            </StatefulPopover>
            <SequenceNameModal
                isOpen={showNewSequence}
                onCancel={closeNewSequence}
                onAccept={(name) => createEmptySequence(name, closeNewSequence)}
                isNewSequence
            />
        </>
    );
}

export default memo(ShowSequenceMenu);