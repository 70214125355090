import { VFlex } from "../components/containers/VFlex";
import { DispatchBoard } from "../components/dispatch/DispatchBoard";
import { useAllJobsSelection } from "../hooks/jobs/useAllJobsSelection";
import { useRecoilState, useRecoilValue } from "recoil";
import { JobsBulkEdit } from "../components/job/JobsBulkEdit";
import { dispatchTreeActiveCardState, expandedState } from "../states/viewState";

export function DispatchView() {
    const { selectionCount } = useAllJobsSelection();
    const [expandedBulkEdit, setExpandedBulkEdit] = useRecoilState(expandedState("dispatch-bulk"));
    const dispatchCard = useRecoilValue(dispatchTreeActiveCardState);

    return (
        <VFlex>
            {selectionCount > 0
                && expandedBulkEdit
                && dispatchCard !== "clustering"
                && <JobsBulkEdit onFinish={() => setExpandedBulkEdit(false)}/>}
            <DispatchBoard/>
        </VFlex>
    );
}