import { Job, NUMBER_LAST } from '../../types/job';
import { useMap } from "react-map-gl";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { editingJobIdState, selectedDayState } from "../../states/appState";
import { editJobState } from "../../states/editJobState";
import useAccount from "../useAccount";
import { creatingJobForUidState } from "../../states/jobsState";
import { useBoard } from "../useBoard";
import { EMPTY_UID, UidNumber } from "../../types/core";
import { useDrawMode } from "../useDrawMode";
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { placeState } from "../../states/placeState";
import { useCompanyDefaults } from '../company/useCompanyDefaults';

export function useNewJob() {
    const setEditingJob = useSetRecoilState(editingJobIdState);
    const setEditJob = useSetRecoilState(editJobState(""));
    const { company } = useAccount();
    const { map } = useMap();
    const [creatingJobForUid, setCreatingJobForUid] = useRecoilState(creatingJobForUidState);
    const resetCreatingJobForUid = useResetRecoilState(creatingJobForUidState);
    const day = useRecoilValue(selectedDayState);
    const board = useBoard();
    const { drawMode, enterDrawMode } = useDrawMode();
    const { getDefaultAttachments, getDefaultCustomFields } = useCompanyDefaults();

    const location = drawMode?.location;

    const initNewJob = (uidNumber?: UidNumber) => {
        setCreatingJobForUid(uidNumber);
        enterDrawMode({
            type: "job",
            action: "new",
        })
    }

    const createNewJob = () => {
        const defaultFields = { ...getDefaultAttachments() } as Job;
        defaultFields.customFields = getDefaultCustomFields();
        const place = location?.uid ? getRecoil(placeState(location?.uid)) : undefined;
        if (place) {
            defaultFields.contactEmail = place.email;
            defaultFields.contactPhone = place.phone;
            for (const field of (place?.extended_data || [])) {
                defaultFields.customFields.push(field);
            }
        }
        defaultFields.carryOver = company?.job_carry_over_default ? 1 : 0;
        defaultFields.onSiteSeconds = company?.job_onsite_default ? company.job_onsite_default : 0;
        setEditingJob("");
        setEditJob({
            ...defaultFields,
            receiverUid: creatingJobForUid?.uid || (board.memberUids.length === 1 && board.memberUids[0]) || EMPTY_UID,
            number: creatingJobForUid?.number || NUMBER_LAST,
            day: day,
            destinationText: location?.address || "",
            destinationLat: location?.lat || map?.getCenter().lat || 0.0,
            destinationLng: location?.lng || map?.getCenter().lng || 0.0,
            placeUid: location?.uid || EMPTY_UID,
        });
        resetCreatingJobForUid();
    };

    return {
        createNewJob,
        initNewJob,
    };
}