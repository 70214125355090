import { ChartPanel } from "./ChartPanel";
import { FlexGridLayout } from "../containers/FlexGridLayout";
import React from "react";
import { MemberStatDay } from "../../types/stats";
import { JobTypesChart } from "./JobTypesChart";
import { JobTeamsChart } from "./JobTeamsChart";
import { useStrings } from "../../hooks/useStrings";

export function JobPiesCharts({
                                  stats,
                                  fetching,
                                  width
                              }: { stats: MemberStatDay[], fetching: boolean, width: number }) {
    const { strings } = useStrings();
    const jobStatsHeight = 55 * 8;

    return (
        <FlexGridLayout baseWidth={width} rowGap={48}>
            <ChartPanel title={strings.Stats.JobTypes}>
                <JobTypesChart stats={stats} width={jobStatsHeight} height={jobStatsHeight} fetching={fetching}/>
            </ChartPanel>
            <ChartPanel title={strings.Stats.JobTeams}>
                <JobTeamsChart stats={stats} width={jobStatsHeight} height={jobStatsHeight} fetching={fetching}/>
            </ChartPanel>
        </FlexGridLayout>
    );
}