import { Block } from "baseui/block";
import { useRecoilState, useRecoilValue } from "recoil";
import { actionsIsOpenState } from "../../states/skeletonState";
import { NavbarCoordinatesPicker } from "./NavbarCoordinatesPicker";
import { NavbarLocationPicker } from "./NavbarLocationPicker";
import { NavbarTools } from "./NavbarTools";
import { useDrawMode } from "../../hooks/useDrawMode";
import { isLocationDrawMode } from "../../states/drawModeState";
import { mapToolState } from "../../states/mapState";
import { Alert } from "antd";
import { useStrings } from "../../hooks/useStrings";
import { sidebarPinnedState, sidebarWidthState } from "../../states/appState";
import { SIDEBAR_COLLAPSED_WIDTH } from "../../constants/app";
import { useProSidebar } from "react-pro-sidebar";
import React from "react";
import { MenuToggleButton } from "./MenuToggleButton";
import { useTheme } from "../../hooks/useTheme";

export function Navbar() {
    const { strings } = useStrings();
    const actionIsOpen = useRecoilValue(actionsIsOpenState);
    const { drawMode } = useDrawMode();
    const [tool, setTool] = useRecoilState(mapToolState);
    const sidebarWidth = useRecoilValue(sidebarWidthState);
    const sidebarPinned = useRecoilValue(sidebarPinnedState);
    const { broken } = useProSidebar();
    const { isDarkTheme} = useTheme();

    if (actionIsOpen) {
        return null;
    }

    const divColor = isDarkTheme ? "rgba(235, 235, 245, .1)" : "rgba(60, 60, 67, .1)";

    return (
        <Block
            paddingLeft="8px"
            paddingRight="8px"
            paddingTop="8px"
            paddingBottom="8px"
            style={{
                position: "absolute",
                transition: "left 0.3s ease, " + drawMode ? "background-color 0.5s ease" : "",
                pointerEvents: "none",
                display: "flex",
                justifyContent: "flex-end",
                overflow: "visible",
                backgroundColor: broken ? (isDarkTheme ? '#0009' : "#FFF9") : undefined,
                backdropFilter: broken ? "blur(50px)" : undefined,
                borderBottomStyle: broken ? "solid" : undefined,
                borderBottomWidth: broken ? "1px" : undefined,
                borderBlockColor: broken ? divColor : undefined,
            }}
            right={0}
            left={(sidebarPinned || broken ? sidebarWidth : SIDEBAR_COLLAPSED_WIDTH) + "px"}
        >
            {!tool && broken && <MenuToggleButton/>}
            {!tool && !drawMode && <NavbarTools/>}
            {!tool && isLocationDrawMode(drawMode) && <NavbarLocationPicker/>}
            {!tool && (drawMode?.type === "zone" || drawMode?.type === "route") && <NavbarCoordinatesPicker/>}
            {tool && <Alert showIcon
                            closable
                            description={tool === "point"
                                ? strings.Map.PointToolDesc
                                : strings.Map.SelectToolDesc}
                            onClose={() => setTool(undefined)}
                            style={{ pointerEvents: "auto" }}
            />}
        </Block>
    );
}