import { useState } from "react";
import { createPortal } from "react-dom";
import { useUpdateEffect } from "usehooks-ts";
import { useStrings } from "../../hooks/useStrings";
import { useWeekDaysEncoder } from "../../hooks/useWeekDaysEncoder";
import { DayKey } from "../../types/days";
import { getDiffFields } from "../../utils/objects";
import { DaysPicker } from "../pickers/DaysPicker";
import { ConfirmModal } from "../ui/ConfirmModal";

type Props = {
    isOpen: boolean;
    onCancel: () => void;
    onAccept: (weekdays: number) => void | Promise<void>;
    weekDays?: number;
}

export function WeekdaysModal({ isOpen, onAccept, onCancel, weekDays = 0 }: Props) {
    const { strings } = useStrings();
    const { selectedWeekDays, weekDaysFromSelectedDays } = useWeekDaysEncoder();
    const [days, setDays] = useState(selectedWeekDays(weekDays));
    const hasChanges = !!Object.keys(getDiffFields(selectedWeekDays(weekDays), days)).length;

    const handleDayCheck = (day: DayKey) => {
        setDays((prev) => ({
            ...prev,
            [day]: !prev[day],
        }));
    }

    useUpdateEffect(() => {
        setDays(selectedWeekDays(weekDays));
    }, [isOpen]);

    return createPortal(
        <ConfirmModal
            title={strings.General.Select}
            description={
                <DaysPicker days={days} onCheck={handleDayCheck}/>
            }
            isOpen={isOpen}
            onConfirm={() => hasChanges ? onAccept(weekDaysFromSelectedDays(days)) : onCancel()}
            onCancel={onCancel}
            cancelText={strings.General.Cancel}
            confirmText={hasChanges ? strings.General.Save : strings.General.Accept}
        />,
        document.body
    );
}