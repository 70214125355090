import { ORIENTATION } from 'baseui/datepicker';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MEMBER_TABS } from '../../../constants/tabs';
import { stringsState } from '../../../states/localeState';
import { ProfileTab } from './ProfileTab';
import { editingMemberIdState, memberActiveTabState, memberRoutingOnlyState } from '../../../states/appState';
import { WorkingHoursTab } from './WorkingHoursTab';
import { SettingsTab } from './SettingsTab';
import { RoutingTab } from './RoutingTab';
import { useEffectOnce } from 'usehooks-ts';
import { ChatList } from "../../messages/ChatList";
import useAccount from "../../../hooks/useAccount";
import { tabOverridesVertical } from "../../../constants/ui";
import { editMemberState } from "../../../states/editMemberState";
import { EmptyState } from "../../ui/EmptyState";
import { DeviceTab } from "./DeviceTab";
import { ChecksTable } from "../../checks/ChecksTable";
import { LocationTableForMember } from "../../location/LocationTableForMember";
import { RouteColumn } from "../../dispatch/routes/RouteColumn";
import { GlobalDatePicker } from "../../pickers/GlobalDatePicker";
import { VFlex } from "../../containers/VFlex";
import { TimelineColumn } from "../../timeline/TimelineColumn";
import { LocationFilterRow } from "../../location/LocationFilterRow";
import { TracksBulkRow } from "../../location/TracksBulkRow";
import { LocationDateRange } from "../../location/LocationDateRange";
import { Spacer } from "../../containers/Spacer";
import { isLocationOnlyPlan } from "../../../utils/account";

export function MemberTabs() {
    const strings = useRecoilValue(stringsState);
    const [memberActiveTab, setMemberActiveTab] = useRecoilState(memberActiveTabState);
    const routingOnly = useRecoilValue(memberRoutingOnlyState);
    const editingMember = useRecoilValue(editingMemberIdState);
    const account = useAccount();

    const tabOverrides = tabOverridesVertical;

    useEffectOnce(() => {
        if (routingOnly) {
            setMemberActiveTab(MEMBER_TABS.routing)
        }
    });

    const memberFields = useRecoilValue(editMemberState(editingMember));
    const isDispatchPlan = !isLocationOnlyPlan(account.company?.stripe_plan);

    if (Object.keys(memberFields).length === 0) {
        return <EmptyState title={strings.General.SomethingWentWrong}/>;
    }

    return (
        <Tabs
            onChange={({ activeKey }) => {
                setMemberActiveTab(activeKey as string);
            }}
            orientation={ORIENTATION.vertical}
            activeKey={memberActiveTab}
            activateOnFocus
            overrides={{
                Root: {
                    style: {
                        height: "70vh",
                    }
                }
            }}
        >
            {!routingOnly && (
                <Tab
                    key={MEMBER_TABS.profile}
                    title={strings.ProfileModal.Profile}
                    overrides={tabOverrides}
                >
                    <ProfileTab/>
                </Tab>
            )}
            {!routingOnly && (
                <Tab
                    key={MEMBER_TABS.status}
                    title={strings.ProfileModal.Device}
                    overrides={tabOverrides}
                >
                    <DeviceTab/>
                </Tab>
            )}
            {!routingOnly && (
                <Tab
                    key={MEMBER_TABS.checks}
                    title={"Check-ins"}
                    overrides={tabOverrides}
                >
                    <ChecksTable uid={editingMember}/>
                </Tab>
            )}
            {!routingOnly && (
                <Tab
                    key={MEMBER_TABS.location}
                    title={strings.General.Tracks + " & " + strings.General.Stops}
                    overrides={tabOverrides}
                >
                    <LocationDateRange/>
                    <Spacer/>
                    <LocationFilterRow/>
                    <TracksBulkRow/>
                    <LocationTableForMember uid={editingMember}/>
                </Tab>
            )}
            {isDispatchPlan && !routingOnly && (
                <Tab
                    key={MEMBER_TABS.jobs}
                    title={strings.General.Jobs}
                    overrides={tabOverrides}
                >
                    <VFlex spacing>
                        <GlobalDatePicker/>
                        <div style={{ flexGrow: 1 }}>
                            <RouteColumn uid={editingMember} showTitle={false}/>
                        </div>
                    </VFlex>
                </Tab>
            )}
            {!routingOnly && (
                <Tab
                    key={MEMBER_TABS.timeline}
                    title={strings.General.Timeline}
                    overrides={tabOverrides}
                >
                    <VFlex spacing alignCenter>
                        <GlobalDatePicker expanded/>
                        <div style={{ flexGrow: 1 }}>
                            <TimelineColumn uid={editingMember} showTitle={false}/>
                        </div>
                    </VFlex>
                </Tab>
            )}
            {!routingOnly && account.uid !== editingMember && (
                <Tab
                    key={MEMBER_TABS.conversation}
                    title={strings.ProfileModal.Conversation}
                    overrides={tabOverrides}
                >
                    <ChatList memberUid={editingMember}/>
                </Tab>
            )}
            {!routingOnly && (
                <Tab
                    key={MEMBER_TABS.workingHours}
                    title={strings.ProfileModal.WorkingHours}
                    overrides={tabOverrides}
                >
                    <WorkingHoursTab/>
                </Tab>
            )}
            <Tab
                key={MEMBER_TABS.routing}
                title={strings.Routing.RoutingSettings}
                overrides={tabOverrides}
            >
                <RoutingTab/>
            </Tab>
            {!routingOnly && (
                <Tab
                    key={MEMBER_TABS.settings}
                    title={strings.General.GeneralSettings}
                    overrides={tabOverrides}
                >
                    <SettingsTab/>
                </Tab>
            )}
        </Tabs>
    );
}
