import { Job } from "../types/job";
import { getRecoil } from "../providers/RecoilAccessProvider";
import { memberState } from "../states/membersState";
import { useStrings } from "./useStrings";
import { useToast } from "./useToast";
import { useStateUpdater } from "./useStateUpdater";
import { useCamera } from "./useCamera";
import { useSetRecoilState } from "recoil";
import {
    assistantChipsState,
    assistantFulfillmentsState,
    assistantPlainTextResponseState,
    assistantQueryHistoryState
} from "../states/assistantState";
import { AssistantFulfillment } from "../types/assistant";
import { HighPriorityIcon, LowPriorityIcon, NormalPriorityIcon } from "../components/ui/svg";
import React, { ReactNode } from "react";
import { Tag } from "antd";
import { MemberBadge } from "../components/badges/MemberBadge";
import { PlaceBadge } from "../components/badges/PlaceBadge";
import { placeState } from "../states/placeState";
import { analyticsEvent } from "../utils/analytics";
import { assistantQuery } from "../services/privateApi";
import { Uid } from "../types/core";

export function useAssistant() {
    const { strings, format } = useStrings();
    const toast = useToast();
    const jobSaver = useStateUpdater();
    const camera = useCamera();
    const setHistory = useSetRecoilState(assistantQueryHistoryState);
    const setFulfillments = useSetRecoilState(assistantFulfillmentsState);
    const setPlainTextResponse = useSetRecoilState(assistantPlainTextResponseState);
    const setChips = useSetRecoilState(assistantChipsState);

    const fulfillment = (fulfillments: AssistantFulfillment[]) => {
        const createdJobs: Job[] = [];
        let anyAction = false;
        for (const fulfillment of fulfillments) {
            if (fulfillment.job) {
                const job = fulfillment.job;
                createdJobs.push(job);
                toast.showSuccess(format(strings.Assistant.XCreated, job.destinationName));
                anyAction = anyAction || fulfillment.action !== "unknown";
                analyticsEvent("assistant_fulfillment_job");
            }
        }
        if (createdJobs.length > 0) {
            jobSaver.updateJobs(createdJobs);
        }
        const fulfillment = fulfillments[0];
        if (fulfillment.coordinates?.length === 1) {
            analyticsEvent("assistant_fulfillment_other");
            toast.showSuccess(fulfillment.userText);
            camera.zoomWithTempMarker(fulfillment.coordinates[0], fulfillment.userText);
        } else if (fulfillment.coordinates?.length > 1) {
            analyticsEvent("assistant_fulfillment_other");
            toast.showSuccess(fulfillment.userText);
            camera.fitToLngLats(fulfillment.coordinates.map(ll => [ll.lng, ll.lat]));
        } else if (fulfillment.locateUid && fulfillment.locateUid.length > 0) {
            analyticsEvent("assistant_fulfillment_location");
            const member = getRecoil(memberState(fulfillment.locateUid));
            if (member) {
                toast.showSuccess(member.name);
                camera.zoomTo(member.location);
                return;
            }
            const place = getRecoil(placeState(fulfillment.locateUid));
            if (place) {
                toast.showSuccess(place.name);
                camera.zoomTo(place.location);
                return;
            }
        }
        return anyAction;
    }

    const sendQuery = async (input: string, membersMentioned: string[], placesMentioned: string[]) => {
        analyticsEvent("assistant_query");
        setHistory(prev => [input, ...prev.filter(q => q !== input)].slice(0, 30));
        setChips([]);
        const query = input
            .replaceAll("@", "")
            .replaceAll("#", "");
        let cutIdx = 0;

        const regex = /"(\w+)":\s*"([^"]+)"/g;
        const callback = (buffer: string) => {
            let match;
            if (buffer.includes("[END-STREAM]")) {
                return;
            }
            const text = buffer.substring(cutIdx);
            let count = 0;
            while ((match = regex.exec(text)) !== null) {
                const [, key, value] = match;
                let node: ReactNode;
                let gap = false;
                if (key === "action" && value === "job") {
                    gap = true;
                    node = <Tag style={{ flexBasis: "100%" }}>{strings.Job.NewJob}</Tag>;
                } else if (key === "action" && value === "locate") {
                    gap = true;
                    node = <Tag style={{ flexBasis: "100%" }}>{strings.General.Locate}</Tag>;
                } else if (key === "priority" && value === "high") {
                    node = <Tag><HighPriorityIcon size={10}/></Tag>;
                } else if (key === "priority" && value === "normal") {
                    node = <Tag><NormalPriorityIcon size={10}/></Tag>;
                } else if (key === "priority" && value === "low") {
                    node = <Tag><LowPriorityIcon size={10}/></Tag>;
                } else if (value === "work") {
                    node = <Tag>{strings.Job.JobTypeWork}</Tag>;
                } else if (value === "pickup") {
                    node = <Tag>{strings.Job.JobTypePickup}</Tag>;
                } else if (value === "dropoff") {
                    node = <Tag>{strings.Job.JobTypeDropoff}</Tag>;
                } else if (key === "assignToUid") {
                    node = <MemberBadge uid={value as Uid}/>;
                } else if (key === "placeUid") {
                    node = <PlaceBadge uid={value as Uid}/>;
                } else if (key === "locateUid") {
                    const member = getRecoil(memberState(value as Uid));
                    node = member ? <MemberBadge uid={value as Uid}/> : <PlaceBadge uid={value as Uid}/>;
                } else {
                    node = <Tag>{value}</Tag>;
                }
                if (gap) {
                    const spacer = "";
                    setChips(prev => [...prev, spacer, node]);
                } else {
                    setChips(prev => [...prev, node]);
                }
                count += 1;
            }
            if (count > 0) {
                cutIdx = buffer.length;
            }
        };
        const result = await assistantQuery({ query, membersMentioned, placesMentioned }, callback);
        if (!result.status) {
            setFulfillments([]);
            setPlainTextResponse("");
            toast.showInfo(strings.General.SomethingWentWrong);
            return false;
        }
        setPlainTextResponse(result.plaintext);
        setFulfillments(result.fulfillments);
        if (result.fulfillments?.length > 0) {
            return fulfillment(result.fulfillments);
        } else {
            return false;
        }
    }

    return { sendQuery };
}