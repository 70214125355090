import { PAGE } from "../../../constants/pages";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { pageState } from "../../../states/appState";
import { mapInteractiveLayersState, showWaypointsLayerState } from "../../../states/mapState";
import { memo, useEffect } from "react";
import { useBoard } from "../../../hooks/useBoard";
import { LiveWaypointsLayer } from "./LiveWaypointsLayer";

function LiveWaypointsLayers() {
    const ID = "live-waypoints";
    const page = useRecoilValue(pageState);
    const board = useBoard();
    const setInteractiveLayers = useSetRecoilState(mapInteractiveLayersState);
    const showWaypointsLayer = useRecoilValue(showWaypointsLayerState);

    const render = showWaypointsLayer && page === PAGE.LIVE && board.memberUids.length > 0;

    useEffect(() => {
        setInteractiveLayers((old) => render
            ? [...[...old].filter(id => id !== ID), ID]
            : [...old].filter(id => id !== ID)
        );
        return () => setInteractiveLayers((old) => [...old, ID]);
    }, [render]);

    if (!render) {
        return null;
    }

    return (
        <>
            {board.memberUids.map((uid, index) => (
                <LiveWaypointsLayer key={index} uid={uid}/>
            ))}
        </>
    );
}

export default memo(LiveWaypointsLayers);