import { useRecoilState, useRecoilValue } from "recoil";
import { setRecoil } from "../../providers/RecoilAccessProvider";
import { placesSelectedUidsState, placesState } from "../../states/placeState";
import { Place } from "../../types/place";
import { Uid } from "../../types/core";

export function usePlaceSelection() {
    const [selectedUids, setSelectedUids] = useRecoilState(placesSelectedUidsState);
    const places = useRecoilValue(placesState);
    const allSelected = places.map(p => p.uid).every(uid => selectedUids.includes(uid));

    const isSelected = (uid: Uid) => {
        return selectedUids.includes(uid);
    };

    const toggleSelected = (uid: Uid) => {
        if (isSelected(uid)) {
            setSelectedUids([...selectedUids.filter(id => id !== uid)]);
        } else {
            setSelectedUids([...selectedUids, uid]);
        }
    };

    const clearSelection = () => setSelectedUids([]);

    const select = (placeUids: Uid[]) => {
        const newIds = [...selectedUids.filter(uid => !placeUids.includes(uid)), ...placeUids];
        setRecoil(placesSelectedUidsState, newIds);
    }

    const unselect = (placeUids: Uid[]) => {
        const newIds = [...selectedUids.filter(uid => !placeUids.includes(uid))]
        setRecoil(placesSelectedUidsState, newIds);
    }

    const toggleAllSelected = () => {
        if (selectionCount > 0) {
            unselect(places.map(p => p.uid))
        } else {
            select(places.map(p => p.uid));
        }
    };

    const tableMultiSelect = (place: Place, places: Place[]) => {
        if (selectedUids.length === 1 && places.length > 0) {
            const initialPlaceUid = selectedUids[0];
            const initialPlaceIndex = places.findIndex((p) => p.uid === initialPlaceUid);
            const endPlaceIndex = places.findIndex((p) => p.uid === place.uid);
            if (initialPlaceIndex !== -1 && endPlaceIndex !== -1) {
                const start = initialPlaceIndex < endPlaceIndex ? initialPlaceIndex + 1 : endPlaceIndex;
                const end = initialPlaceIndex > endPlaceIndex ? initialPlaceIndex : endPlaceIndex + 1;
                const placesInRange = places.slice(start, end);
                select(placesInRange.map((p) => p.uid))
                return true;
            }
        }
        return false;
    }

    const selectionCount = selectedUids.length;

    return {
        toggleSelected,
        selectedUids,
        setSelectedUids,
        isSelected,
        selectionCount,
        clearSelection,
        allSelected,
        toggleAllSelected,
        tableMultiSelect,
    };
}