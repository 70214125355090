import { TimelineEntry, TimelineItem } from "../../types/timeline";
import { Stop } from "../../types/stops";
import { TrackEntryAndData } from "../../types/track";
import { Timestamp } from "../../types/core";

export const createTimelineItemsFromTracksAndEntries = (trackEntries: TrackEntryAndData[], timelineEntries: TimelineEntry[]): TimelineItem[] => {
    if (!trackEntries) {
        return [];
    }

    let timelineIndex = 0;

    const fillEventsUntil = (endTs: Timestamp): TimelineEntry[] => {
        let events = [] as TimelineEntry[];
        while (timelineIndex < timelineEntries.length && timelineEntries[timelineIndex].tsStart <= endTs) {
            events.push(timelineEntries[timelineIndex]);
            timelineIndex += 1;
        }
        return events;
    }

    let items = [] as TimelineItem[];

    if ((timelineEntries.length > 0 && trackEntries.length === 0)
        || (timelineEntries.length > 0 && timelineEntries[0].tsStart < trackEntries[0].entry.startTs)) {
        const startTs = timelineEntries[0].tsStart;
        const endTs = trackEntries.length > 0
            ? trackEntries[0].entry.startTs
            : timelineEntries[timelineEntries.length - 1].tsEnd;
        const events = fillEventsUntil(endTs);
        items.push({
            start: startTs,
            stop: {
                start: startTs,
                end: endTs
            } as Stop,
            events: events
        } as TimelineItem);
    }

    for (let i = 0; i < trackEntries.length; i++) {
        if (i > 0) {
            const startTs = trackEntries[i - 1].entry.endTs;
            const endTs = trackEntries[i].entry.startTs;
            const events = fillEventsUntil(endTs);
            items.push({
                start: startTs,
                stop: {
                    start: startTs,
                    end: endTs
                } as Stop,
                events: events
            } as TimelineItem);
        }
        const startTs = trackEntries[i].entry.startTs;
        const endTs = trackEntries[i].entry.endTs;
        const events = fillEventsUntil(endTs);
        items.push({ start: startTs, track: trackEntries[i], events: events });
    }

    if (timelineEntries.length > 0
        && trackEntries.length > 0
        && timelineEntries[timelineEntries.length - 1].tsStart > trackEntries[trackEntries.length - 1].entry.startTs) {
        const startTs = trackEntries[trackEntries.length - 1].entry.endTs;
        const endTs = timelineEntries[timelineEntries.length - 1].tsEnd;
        const events = fillEventsUntil(endTs);
        items.push({
            start: startTs,
            stop: {
                start: startTs,
                end: endTs
            } as Stop,
            events: events
        } as TimelineItem);
    }

    return items;
};