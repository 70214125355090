import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { ButtonGroup, MODE, SIZE } from 'baseui/button-group';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { user3DEnabledState } from '../../../states/mapState';

export function Enable3DPicker() {
    const [css] = useStyletron();
    const [enable3D, setEnable3D] = useRecoilState(user3DEnabledState);
    const [selected, setSelected] = useState(enable3D ? 1 : 0);

    const onDimensionChange = (_event: any, index: number) => {
        setEnable3D(index > 0)
        setSelected(index);
    };

    const buttonWidth = css({
        width: '80px',
    });

    return (
        <ButtonGroup
            mode={MODE.radio}
            selected={selected}
            onClick={onDimensionChange}
            size={SIZE.compact}
        >
            <Button className={buttonWidth}>2D</Button>
            <Button className={buttonWidth}>3D</Button>
        </ButtonGroup>
    );
}
