import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { PAGE } from "../constants/pages";
import { useCamera } from "../hooks/useCamera";
import {
    getAlertConfigs,
    getAlertInstances,
    getAllJobs,
    getAllUnassignedJobs,
    getJobById,
    getJobs,
    GetJobsRequest,
    getTimesheet,
    getTrips,
    loadFormSubmissions,
    loadFormTemplates
} from "../services/api";
import {
    dayState,
    editingJobIdState,
    editingMemberIdState,
    pageState,
    selectedDayFrameState,
    selectedDayState,
    selectedTimeFrameState,
} from "../states/appState";
import { jobsSearchState, jobsUpdateEventState, selectedJobsState } from "../states/jobsState";
import { useStateUpdater } from "../hooks/useStateUpdater";
import { useAllJobsSelection } from "../hooks/jobs/useAllJobsSelection";
import { useEffectOnce, useInterval, useUpdateEffect } from "usehooks-ts";
import { dateToDayBrowserTimezone, dayToDateBrowserTimezone } from "../utils/time";
import { useTracksLayerUpdater } from "../hooks/tracks/useTracksLayerUpdater";
import useDirector from "../hooks/useDirector";
import { useTimelineUpdater } from "../hooks/useTimelineUpdater";
import { boardShowAllUnassignedState } from "../states/boardState";
import { useBoard } from "../hooks/useBoard";
import { useDayRouteUpdater } from "../hooks/useDayRouteUpdater";
import {
    allJobsColumnsState,
    allJobsFromUntilDaysState,
    allJobsFromUntilRangeState,
    allJobsRequestRoundState,
    allJobsSortConfigState
} from "../states/allJobsState";
import {
    activePlacesZonesTabState,
    allJobsActiveTabState,
    dispatchTreeActiveCardState,
    dispatchTreeCollapsedState,
    formSubmissionEventState,
    requestingState,
    timesheetByDayState,
    timesheetEventState
} from "../states/viewState";
import { placesSelectedState } from "../states/placeState";
import { formSubmissionsState, formTemplatesState } from "../states/formsState";
import { useSequenceUpdater } from "../hooks/jobs/useSequenceUpdater";
import { useJobTemplatesUpdater } from "../hooks/jobs/useJobTemplatesUpdater";
import { useMembersUpdater } from "../hooks/members/useMembersUpdater";
import { zonesSelectedState } from "../states/zoneState";
import { usePlacesLayerUpdater } from "../hooks/places/usePlacesLayerUpdater";
import { alertConfigsState, alertInstancesState } from "../states/alertsState";
import { getRecoil, setRecoil } from "./RecoilAccessProvider";
import { updateHeatmapLayer, updateWaypointsLayer } from "../services/geojson";
import { memberSearchState, membersFiltersState, memberState, memberUidsState } from "../states/membersState";
import { Day, LonLat } from "../types/core";
import { useReports } from "../hooks/useReports";
import circle from "@turf/circle";
import { useConversations } from "../hooks/useConversations";
import { useToast } from "../hooks/useToast";
import { onboardStepsDoneState } from "../states/onboardState";
import { useStrings } from "../hooks/useStrings";
import { usePlaceSelection } from "../hooks/places/usePlaceSelection";
import { isMultiTracksDatesState, tracksDatesState } from "../states/tracksState";
import { SortConfig } from "../types/jobFilters";
import { drawModeState } from "../states/drawModeState";
import { useActivities } from "../hooks/useActivities";
import { clustersState } from "../states/clusteringState";
import useAccount from "../hooks/useAccount";
import { loggedInState } from "../states/accountState";
import { ALL_JOBS_ALL_COLUMNS } from "../constants/jobs";
import { tripEventState, tripsState } from "../states/tripsState";
import { cardSizesState } from "../states/skeletonState";
import { useSearchParams } from "react-router-dom";

export function AppStateHandler() {
    const { strings } = useStrings();
    const day = useRecoilValue(dayState);
    const resetSelectedDay = useResetRecoilState(selectedDayState);
    const board = useBoard();
    const selectedJobs = useRecoilValue(selectedJobsState);
    const placeSelection = usePlaceSelection();
    const selectedPlaces = useRecoilValue(placesSelectedState);
    const selectedZones = useRecoilValue(zonesSelectedState);
    const stepsDone = useRecoilValue(onboardStepsDoneState);
    const camera = useCamera();
    const toast = useToast();

    const editingMemberId = useRecoilValue(editingMemberIdState);
    const page = useRecoilValue(pageState);
    const stateUpdater = useStateUpdater();
    const tracksLayerUpdater = useTracksLayerUpdater();
    const timelineUpdater = useTimelineUpdater();
    const jobSelection = useAllJobsSelection();
    const [allJobsRequestRound, setAllJobsRequestRound] = useRecoilState(allJobsRequestRoundState);
    const director = useDirector();
    const dayRouteUpdater = useDayRouteUpdater();
    const setJobSearch = useSetRecoilState(jobsSearchState);
    const showAllUnassigned = useRecoilValue(boardShowAllUnassignedState);
    const allJobsTableSortConfig = useRecoilValue(allJobsSortConfigState);
    const setAllJobsRequesting = useSetRecoilState(requestingState({ req: "alljobs" }));
    const setFormTemplates = useSetRecoilState(formTemplatesState);
    const setFormSubmissions = useSetRecoilState(formSubmissionsState);
    const sequenceUpdater = useSequenceUpdater();
    const jobTemplatesUpdater = useJobTemplatesUpdater();
    const { updateMembers } = useMembersUpdater();
    const placesLayerUpdater = usePlacesLayerUpdater();
    const setAlertConfigs = useSetRecoilState(alertConfigsState);
    const setAlertInstances = useSetRecoilState(alertInstancesState);
    const jobsUpdateEvent = useRecoilValue(jobsUpdateEventState);
    const setTimesheetRequesting = useSetRecoilState(requestingState({ req: "timesheet" }));
    const memberUids = useRecoilValue(memberUidsState);
    const reports = useReports();
    const conversations = useConversations();
    const timesheetEvent = useRecoilValue(timesheetEventState);
    const resetSelectedTimeFrame = useResetRecoilState(selectedTimeFrameState);
    const resetMembersFiltersState = useResetRecoilState(membersFiltersState);
    const resetMembersSearchState = useResetRecoilState(memberSearchState);
    const activeAllJobsTab = useRecoilValue(allJobsActiveTabState);
    const tracksDates = useRecoilValue(tracksDatesState);
    const selectedDayFrameDates = useRecoilValue(selectedDayFrameState);
    const formSubmissionEvent = useRecoilValue(formSubmissionEventState);
    const dispatchTreeActiveCard = useRecoilValue(dispatchTreeActiveCardState);
    const resetDrawMode = useResetRecoilState(drawModeState);
    const placesOrZonesActiveTab = useRecoilValue(activePlacesZonesTabState);
    const { fetchActivities } = useActivities();
    const resetClustering = useResetRecoilState(clustersState);
    const [editingJobId, setEditingJobId] = useRecoilState(editingJobIdState);
    const resetAllJobsFromUntilDays = useResetRecoilState(allJobsFromUntilDaysState);
    const allJobsFromUntilRange = useRecoilValue(allJobsFromUntilRangeState);
    const { hasCompany, company } = useAccount();
    const [pollCount, setPollCount] = useState(0);
    const loggedIn = useRecoilValue(loggedInState);
    const setAllJobsColumns = useSetRecoilState(allJobsColumnsState);
    const [activitiesFetched, setActivitiesFetched] = useState(false);
    const setTrips = useSetRecoilState(tripsState);
    const tripEvent = useRecoilValue(tripEventState);
    const resetTrackDates = useResetRecoilState(tracksDatesState);
    const setTrackDates = useSetRecoilState(tracksDatesState);
    const isMultiTrackDates = useRecoilValue(isMultiTracksDatesState);
    const resetDispatchTreeCollapsed = useResetRecoilState(dispatchTreeCollapsedState);
    const resetDispatchCardSizes = useResetRecoilState(cardSizesState(PAGE.DISPATCH));
    const [searchParams, setSearchParams] = useSearchParams();

    // FIXME if two updates arrive and only one render is called, updates will be swallowed

    useEffect(() => {
        if (memberUids.length > 0 && !activitiesFetched) {
            fetchActivities(memberUids);
            setActivitiesFetched(true);
        }
    }, [memberUids])

    useEffectOnce(() => {
        director.performPoll();
    });

    useEffect(() => {
        if (day === 0 || !hasCompany) {
            return;
        }
        const retrieveJobs = async (day: Day) => {
            const { status, jobs } = await getJobs({ day: day } as GetJobsRequest);
            if (status) {
                stateUpdater.updateJobs(jobs);
            }
        }
        retrieveJobs(day);
    }, [day, hasCompany]);

    useEffect(() => {
        camera.fitToJobs(selectedJobs);
    }, [selectedJobs]);

    useEffect(() => {
        const isPlacesTabSelected = placesOrZonesActiveTab === "places";
        const isZonesTabSelected = placesOrZonesActiveTab === "zones";
        if (isPlacesTabSelected && selectedPlaces.length === 1 && selectedPlaces[0] && selectedPlaces[0].latitude && selectedPlaces[0].longitude) {
            const place = selectedPlaces[0];
            const polygon = circle([place.longitude, place.latitude], place.radius * 2, { steps: 3, units: "meters" });
            camera.fitToLngLats(polygon.geometry.coordinates[0] as LonLat[]);
        } else if (isPlacesTabSelected && selectedPlaces.length > 1) {
            camera.fitToLngLats(selectedPlaces.map(p => [p?.longitude, p?.latitude]));
        } else if (isZonesTabSelected && selectedZones.length === 1 && selectedZones[0]) {
            camera.fitToLngLats(selectedZones[0].points);
        } else if (isZonesTabSelected && selectedZones.length > 1) {
            camera.fitToLngLats(selectedZones.map(z => z.points).flat())
        }
    }, [selectedPlaces, selectedZones, placesOrZonesActiveTab]);

    useEffect(() => {
        if (page === PAGE.DISPATCH) {
            jobTemplatesUpdater.updateJobTemplates();
        }
    }, [page]);

    useEffect(() => {
        return () => {
            resetDispatchCardSizes();
            resetDispatchTreeCollapsed();
        }
    }, [page]);

    useEffect(() => {
        placeSelection.clearSelection();
    }, [page]);

    useUpdateEffect(() => {
        resetAllJobsFromUntilDays();
    }, [allJobsFromUntilRange]);

    useEffect(() => {
        if (!hasCompany) {
            return;
        }
        const retrieveJobsForAllJobs = async () => {
            setAllJobsRequesting(true)
            const config = {
                ...allJobsTableSortConfig,
                skip: allJobsRequestRound * allJobsTableSortConfig.limit,
            } as SortConfig;
            const { status, jobs } = await getAllJobs(config);
            if (status) {
                stateUpdater.updateJobs(jobs);
            }
            setAllJobsRequesting(false)
        };

        jobSelection.clearSelection();
        retrieveJobsForAllJobs();
    }, [hasCompany, allJobsTableSortConfig, allJobsRequestRound, jobsUpdateEvent]);

    useEffect(() => {
        jobSelection.clearSelection();
    }, [page, dispatchTreeActiveCard, board.memberUids, activeAllJobsTab]);

    useEffect(() => {
        setAllJobsRequestRound(0);
    }, [page, allJobsTableSortConfig]);


    useEffect(() => {
        if (loggedIn) {
            updateMembers();
        }
    }, [loggedIn])

    useInterval(() => {
        director.performPoll();
        if (pollCount % 3 === 0) {
            updateMembers();
        }
        setPollCount(pollCount + 1)
    }, 20_000);

    useEffect(() => {
        if (hasCompany) {
            director.performBilling();
            jobTemplatesUpdater.updateJobTemplates();
        }
    }, [hasCompany]);

    useEffect(() => {
        setJobSearch("");
    }, [page]);

    useEffect(() => {
        if (hasCompany && showAllUnassigned) {
            const retrieveAllUnassigned = async () => {
                const { status, jobs } = await getAllUnassignedJobs();
                if (status) {
                    stateUpdater.updateJobs(jobs);
                }
            }
            retrieveAllUnassigned();
        }
    }, [hasCompany, showAllUnassigned]);

    useEffect(() => {
        if (page === PAGE.DISPATCH) {
            sequenceUpdater.updateSequences();
        }
    }, [page]);

    useEffect(() => placesLayerUpdater.updateAll(), []);

    useEffect(() => {
        if (!hasCompany) {
            return;
        }
        loadFormTemplates().then(({ status, formtemplates }) => {
            if (status) {
                setFormTemplates(formtemplates);
            }
        });
    }, [hasCompany]);

    useEffectOnce(() => {
        getAlertConfigs().then(({ status, alertconfigs }) => {
            if (status) {
                setAlertConfigs(alertconfigs);
            }
        });
    });

    useEffectOnce(() => conversations.loadAll());

    useEffect(() => {
        if (page === PAGE.TIMESHEET) {
            setTimesheetRequesting(true);
            getTimesheet(day).then(({ status, timesheet }) => {
                if (status) {
                    setRecoil(timesheetByDayState(day), timesheet);
                }
            }).finally(() => setTimesheetRequesting(false));
        }
    }, [page, day, timesheetEvent]);

    useEffect(() => {
        if (page === PAGE.LIVE) {
            board.memberUids.map(uid => updateWaypointsLayer(uid));
        }
    }, [board.memberUids, page]);

    useEffect(() => {
        if (page === PAGE.HEATMAP) {
            board.setMemberUids([]);
        }
    }, [page]);

    useEffect(() => {
        if (page === PAGE.HEATMAP && !!selectedDayFrameDates[0] && !!selectedDayFrameDates[1]) {
            updateHeatmapLayer({
                    members: board.memberUids,
                    fromDay: selectedDayFrameDates[0],
                    untilDay: selectedDayFrameDates[1]
                }
            )
        }
    }, [page, selectedDayFrameDates, board.memberUids]);

    useEffect(() => {
        const latLngs = board.memberUids
            .map(uid => getRecoil(memberState(uid)))
            .filter(member => member && member.location)
            .map(member => [member?.location.lng, member?.location.lat] as LonLat);
        if (latLngs.length > 0) {
            camera.fitToLngLats(latLngs);
        }
    }, [board.memberUids]);

    useEffect((() => {
        if (page === PAGE.REPORTS) {
            reports.loadUploads();
            reports.loadRecurringConfigs();
        }
    }), [page])

    useEffect(() => {
        if (page === PAGE.TIMELINE || page === PAGE.LIVE || page === PAGE.DISPATCH) {
            memberUids.map(uid => tracksLayerUpdater.update(uid, day));
        }
    }, [page, day, memberUids]);

    useEffect(() => {
        if (!isMultiTrackDates) {
            setTrackDates([dayToDateBrowserTimezone(day), dayToDateBrowserTimezone(day)]);
        }
    }, [isMultiTrackDates, day]);

    useEffect(() => {
        if (isMultiTrackDates) {
            resetTrackDates();
        }
    }, [isMultiTrackDates]);

    useEffect(() => {
        if (!!tracksDates[0] && !!tracksDates[1]) {
            const fromDay = dateToDayBrowserTimezone(tracksDates[0]!);
            const untilDay = dateToDayBrowserTimezone(tracksDates[1]!);
            if (page === PAGE.LOCATION) {
                board.memberUids.map(uid => tracksLayerUpdater.updateMemberTracks(uid, fromDay, untilDay));
            } else if (editingMemberId) {
                tracksLayerUpdater.updateMemberTracks(editingMemberId, fromDay, untilDay).then(() => {});
            }
        }
    }, [page, board.memberUids, tracksDates, editingMemberId]);

    useEffect(() => {
        for (const uid of board.memberUids) {
            dayRouteUpdater.update(uid, day);
            tracksLayerUpdater.update(uid, day);
            timelineUpdater.update(uid, day);
        }
    }, [board.memberUids, day]);

    useUpdateEffect(() => {
        if (stepsDone) {
            toast.showSuccess(strings.Onboard.OnboardFinished)
        }
    }, [stepsDone]);

    useEffect(() => {
        resetSelectedTimeFrame();
    }, [day]);

    useEffect(() => {
        if (page !== PAGE.LIVE && page !== PAGE.TIMELINE && page !== PAGE.DISPATCH && page !== PAGE.LOCATION) {
            resetMembersFiltersState();
            resetMembersSearchState();
            resetSelectedDay();
        }
    }, [page]);

    useEffect(() => {
        loadFormSubmissions({ from: 0, until: Date.now(), limit: 200 })
            .then(({ status, formsubmissions }) => {
                if (status) {
                    setFormSubmissions(formsubmissions);
                }
            });
    }, [hasCompany, formSubmissionEvent]);

    useEffect(() => {
        getAlertInstances().then(({ status, alertinstances }) => {
            if (status) {
                setAlertInstances(alertinstances);
            }
        });
    }, [hasCompany]);

    useEffect(() => resetDrawMode(), [page]);

    useEffect(() => resetClustering(), [selectedJobs]);

    useEffect(() => {
        editingJobId && getJobById(editingJobId).then(({ status, jobs }) => {
            status && stateUpdater.updateJobs(jobs);
        });
    }, [editingJobId]);

    useEffectOnce(() => {
        if (page === PAGE.DISPATCH) {
            const jobId = searchParams.get("job");
            if (jobId && editingJobId !== jobId) {
                setEditingJobId(jobId);
            }
        }
    });

    useUpdateEffect(() => {
        if (page === PAGE.DISPATCH) {
            if (editingJobId) {
                setSearchParams({ job: editingJobId });
            } else {
                setSearchParams({});
            }
        }
    }, [page, editingJobId]);


    useEffect(() => {
        const allColumns = [...ALL_JOBS_ALL_COLUMNS, ...company?.job_custom_fields ?? []];
        setAllJobsColumns((prev) => prev.filter((column) => allColumns.includes(column)));
    }, [company?.job_custom_fields]);

    useEffect(() => {
        if (memberUids.length > 0) {
            getTrips({ accountUids: memberUids }).then(response => {
                if (response.status) {
                    setTrips(response.trips)
                }
            })
        }
    }, [memberUids, tripEvent]);

    return null;
}

