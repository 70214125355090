export const ONBOARD_SIGN_UP = 'signup';
export const ONBOARD_COMPANY_SETUP = 'companySetup';
export const ONBOARD_DOWNLOAD_APP = 'downloadApp';
export const ONBOARD_USERS_PERMISSIONS = 'usersPermissions';
export const ONBOARD_ADDING_USERS = 'addingUsers';
export const ONBOARD_CREATING_PLACES = 'creatingPlaces';
export const ONBOARD_CHECK_IN_NOTIFICATIONS = 'checkInNotifications';
export const ONBOARD_TRACKING_SETTINGS = 'trackingSettings';
export const ONBOARD_JOBS = 'jobs';
export const ONBOARD_CONCEPTS = 'concepts';

export const TOUR_DELAY = 1000;