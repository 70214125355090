import React from "react";
import {
    calcTotalsAndAverages,
    calcTotalsAndAveragesByMember,
    MemberStatDay,
    retrieveMemberUids
} from "../../types/stats";
import { Datum, StackedBars } from "../../components/statistics/visx/StackedBars";
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { memberState } from "../../states/membersState";
import { useTime } from "../useTime";
import { useStrings } from "../useStrings";
import { asNumber } from "../../utils/objects";
import { ChartPanel } from "../../components/statistics/ChartPanel";

export function useOnsiteCharts({ stats, fetching, width }: { stats: MemberStatDay[], fetching: boolean, width: number }) {
    const { strings } = useStrings();
    const dates = stats.map(s => s.day);
    const [totalJobOnsiteTimes, avgJobOnsiteTimes] = calcTotalsAndAverages(stats, 'totalJobOnsiteTime', 'avgJobOnsiteTime');
    const [totalPlaceOnsiteTimes, avgPlaceOnsiteTimes] = calcTotalsAndAverages(stats, 'totalPlaceOnsiteTime', 'avgPlaceOnsiteTime');
    const [totalPauseTimes, avgPauseTimes] = calcTotalsAndAverages(stats, 'totalPauseTime', 'avgPauseTime');
    const time = useTime();

    const data: Datum[] = dates.map((day, index) => ({
        bucketField: time.formatDay(day),
        totalJobOnsiteTime: totalJobOnsiteTimes[index],
        totalPlaceOnsiteTime: totalPlaceOnsiteTimes[index],
        totalPauseTime: totalPauseTimes[index],
        avgJobOnsiteTime: avgJobOnsiteTimes[index],
        avgPlaceOnsiteTime: avgPlaceOnsiteTimes[index],
        avgPauseTime: avgPauseTimes[index],
    } as Datum));

    const memberUids = retrieveMemberUids(stats);

    const [totalJobOnsiteTimeByMember, avgJobOnsiteTimesByMember] = calcTotalsAndAveragesByMember(stats, memberUids, 'totalJobOnsiteTime', 'avgJobOnsiteTime');
    const [totalPlaceOnsiteTimeByMember, avgPlaceOnsiteTimesByMember] = calcTotalsAndAveragesByMember(stats, memberUids, 'totalPlaceOnsiteTime', 'avgPlaceOnsiteTime');
    const [totalPauseTimeByMember, avgPauseTimesByMember] = calcTotalsAndAveragesByMember(stats, memberUids, 'totalPauseTime', 'avgPauseTime');

    const dataByMember: Datum[] = memberUids.map((uid, index) => ({
        bucketField: getRecoil(memberState(uid))?.name || uid,
        totalJobOnsiteTime: totalJobOnsiteTimeByMember[index],
        totalPlaceOnsiteTime: totalPlaceOnsiteTimeByMember[index],
        totalPauseTime: totalPauseTimeByMember[index],
        avgJobOnsiteTime: avgJobOnsiteTimesByMember[index],
        avgPlaceOnsiteTime: avgPlaceOnsiteTimesByMember[index],
        avgPauseTime: avgPauseTimesByMember[index],
    } as Datum));

    const dataByMemberFiltered = dataByMember
        .filter(d => asNumber(d, 'totalJobOnsiteTime') + asNumber(d, 'totalPlaceOnsiteTime') + asNumber(d, 'totalPauseTime') > 0);

    const dataByMemberSortedByTotal = [...dataByMemberFiltered]
        .sort((d1: Datum, d2: Datum) => {
            const sum1 = Math.max(asNumber(d1, 'totalJobOnsiteTime') * 100_000, asNumber(d1, 'totalPlaceOnsiteTime') * 100, asNumber(d1, 'totalPauseTime'));
            const sum2 = Math.max(asNumber(d2, 'totalJobOnsiteTime') * 100_000, asNumber(d2, 'totalPlaceOnsiteTime') * 100, asNumber(d2, 'totalPauseTime'));
            return sum1 - sum2;
        });

    const dataByMemberSortedByAvg = [...dataByMemberFiltered].sort((d1: Datum, d2: Datum) => {
        const sum1 = Math.max(asNumber(d1, 'avgJobOnsiteTime') * 100_000, asNumber(d1, 'avgPlaceOnsiteTime') * 100, asNumber(d1, 'avgPauseTime'));
        const sum2 = Math.max(asNumber(d2, 'avgJobOnsiteTime') * 100_000, asNumber(d2, 'avgPlaceOnsiteTime') * 100, asNumber(d2, 'avgPauseTime'));
        return sum1 - sum2;
    });

    const rowHeight = 24;
    const rowPad = 100;

    return [
        <ChartPanel title={strings.Stats.AccumulatedOnsiteTimeByDate} key={strings.Stats.AccumulatedOnsiteTimeByDate}>
            <StackedBars width={width}
                            height={data.length * rowHeight + rowPad}
                            data={data}
                            fetching={fetching}
                            categories={["totalJobOnsiteTime", "totalPlaceOnsiteTime", "totalPauseTime"]}
                            labels={[strings.Stats.TimeAtJob, strings.Stats.TimeAtPlace, strings.Stats.Pause]}
            />
        </ChartPanel>,
        <ChartPanel title={strings.Stats.AvgOnsiteTimeByDate} key={strings.Stats.AvgOnsiteTimeByDate}>
            <StackedBars width={width}
                            height={data.length * rowHeight + rowPad}
                            data={data}
                            fetching={fetching}
                            categories={["avgJobOnsiteTime", "avgPlaceOnsiteTime", "avgPauseTime"]}
                            labels={[strings.Stats.TimeAtJob, strings.Stats.TimeAtPlace, strings.Stats.Pause]}
            />
        </ChartPanel>,
        <ChartPanel title={strings.Stats.AccumulatedOnsiteTimeByMember} key={strings.Stats.AccumulatedOnsiteTimeByMember}>
            <StackedBars width={width}
                            height={dataByMemberSortedByTotal.length * rowHeight + rowPad}
                            data={dataByMemberSortedByTotal}
                            fetching={fetching}
                            categories={["totalJobOnsiteTime", "totalPlaceOnsiteTime", "totalPauseTime"]}
                            labels={[strings.Stats.TimeAtJob, strings.Stats.TimeAtPlace, strings.Stats.Pause]}
            />
        </ChartPanel>,
        <ChartPanel title={strings.Stats.AvgOnsiteTimeByMember} key={strings.Stats.AvgOnsiteTimeByMember}>
            <StackedBars width={width}
                            height={dataByMemberSortedByAvg.length * rowHeight + rowPad}
                            data={dataByMemberSortedByAvg}
                            fetching={fetching}
                            categories={["avgJobOnsiteTime", "avgPlaceOnsiteTime", "avgPauseTime"]}
                            labels={[strings.Stats.TimeAtJob, strings.Stats.TimeAtPlace, strings.Stats.Pause]}
            />
        </ChartPanel>
    ];
}