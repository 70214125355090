export const TRUNCATE_LENGTH_DEFAULT = 8;

export function truncateText(text: string, length = TRUNCATE_LENGTH_DEFAULT) {
    return text.length > length ? text.slice(0, length) + "…" : text;
}

export function hasWhiteSpace(s: string) {
    return /\s/g.test(s);
}

export function isValidJsonString(str: string = "") {
    try {
        JSON.parse(str);
        return true;
    } catch {
        return false;
    }
}

export function addSpaceBetweenChars(str: string) {
 return str?.split('').map(char => char + ' ').join('').trim() || "";
}