import { useRecoilState, useRecoilValue } from "recoil";
import { editingMemberIdState } from "../../../states/appState";
import { editMemberState } from "../../../states/editMemberState";
import { SectionedWrapper } from "../../containers/SectionedWrapper";
import { AccountSettingsFields } from "../../shared/AccountSettingsFields";
import { Section } from "../../ui/Section";


export function SettingsTab() {
    const editingMember = useRecoilValue(editingMemberIdState);
    const [member, setMember] = useRecoilState(editMemberState(editingMember));

    const onChangeInput = (key: string, value: string | number) => {
        setMember((prev) => ({ ...prev, [key]: value }));
    };

    return (
        <SectionedWrapper>
            <Section title={""}>
                <AccountSettingsFields
                    languageValue={member.language}
                    timezoneValue={member.timezone}
                    unitDistanceValue={member.unit_distance}
                    unitTimeValue={member.unit_time}
                    onFieldChange={onChangeInput}
                />
            </Section>
        </SectionedWrapper>
    )
}