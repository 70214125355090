import { useRecoilState, useRecoilValue } from "recoil";
import { selectedReportConfigState, selectedReportNameState } from "../../states/reportState";
import { useStrings } from "../../hooks/useStrings";
import { Input } from "baseui/input";
import React from "react";

export function ReportNameStep() {
    const { strings } = useStrings();
    const reportConfig = useRecoilValue(selectedReportConfigState);
    const [name, setName] = useRecoilState(selectedReportNameState);

    if (!reportConfig) {
        return null;
    }

    return (
        <Input
            size={"compact"}
            placeholder={strings.Reports.EnterNameForThisReport}
            value={name}
            onChange={({ target: { value } }) => setName(value)}
        />
    );
}