import LegLine from "./LegLine";
import { LabelXSmall } from "baseui/typography";
import { HFlex } from "../../containers/HFlex";
import React, { memo, useRef, useState } from "react";
import { RouteInfo } from "../../../types/job";
import { useTheme } from "../../../hooks/useTheme";
import { useRecoilValue } from "recoil";
import { dayState, draggingState } from "../../../states/appState";
import { useTime } from "../../../hooks/useTime";
import { useRouteInfo } from "../../../hooks/useRouteInfo";
import { COLOR_MOVING } from "../../../constants/colors";
import { Spacer, SpacerExpand } from "../../containers/Spacer";
import { VFlex } from "../../containers/VFlex";
import { DoubleCheckIcon, PlusIcon, SingleCheckIcon } from "../../ui/svg";
import { Centered } from "../../containers/Centered";
import { useHover } from "usehooks-ts";
import { useStrings } from "../../../hooks/useStrings";
import { useNewJob } from "../../../hooks/jobs/useNewJob";
import { Button } from "baseui/button";
import { SelectTemplateModal } from "../templates/SelectTemplateModal";
import { jobState } from "../../../states/jobsState";
import { SelectJobModal } from "../SelectJobModal";
import { Dropdown } from "antd";
import { useJobAssignment } from "../../../hooks/jobs/useJobAssignment";
import { TimeBadge } from "../../badges/TimeBadge";
import { Uid } from "../../../types/core";

type Props = {
    uid: Uid;
    jobId1?: string | undefined;
    jobId2?: string | undefined;
    routeInfoObject?: RouteInfo;
    index: number;
    number: number;
    count: number;
    width: number;
}

function LegLineWithAnnotation({ uid, jobId1, jobId2, routeInfoObject, index, number, count, width }: Props) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const { formatSecondsToTime } = useTime();
    const isDragging = useRecoilValue(draggingState);
    const routeInfo = useRouteInfo();
    const { isSameDay, formatDuration } = useTime();
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef)
    const job1 = useRecoilValue(jobState(jobId1));
    const job2 = useRecoilValue(jobState(jobId2));

    const checkTag = {
        fontSize: "8px",
        paddingLeft: "4px",
        paddingRight: "4px",
        lineHeight: "8px",
    };
    const isWide = width > 350;
    const top = routeInfoObject
        && (job1
            ? routeInfo.legInfo(routeInfoObject, job1).etdText
            : formatSecondsToTime(routeInfoObject.metaStartTime));
    const checkTop = job1 && job1.tsCheckOut > 0 && routeInfoObject &&
        <TimeBadge
            withDate={isWide && !isSameDay(job1.tsCheckOut, job1.day)}
            startMillis={job1.tsCheckOut}
            color={COLOR_MOVING}
            style={checkTag}
            icon={<SingleCheckIcon size={7} color={"#FFFFFF"} style={{ marginRight: "2px" }}/>}
            tooltipTitle={strings.General.CheckOut}
        />;
    const middle = routeInfoObject && routeInfo.travelInfo(routeInfoObject, index).durationText;
    const wait = routeInfoObject?.legWaitInSec
        && routeInfoObject.legWaitInSec.length > index
        && formatDuration(routeInfoObject.legWaitInSec[index] * 1000);
    const bottom = routeInfoObject &&
        (job2
            ? routeInfo.legInfo(routeInfoObject, job2).etaText
            : formatSecondsToTime(routeInfo.calcEtaEtdDist(routeInfoObject, routeInfoObject.legTimesInSec.length).etd))
    const checkBottom = job2 && job2.tsCheckIn > 0 &&
        <TimeBadge
            withDate={isWide && !isSameDay(job2.tsCheckIn, job2.day)}
            startMillis={job2.tsCheckIn}
            color={COLOR_MOVING}
            style={checkTag}
            icon={<DoubleCheckIcon size={7} color={"#FFFFFF"} style={{ marginRight: "2px" }}/>}
            tooltipTitle={strings.General.CheckIn}
        />;
    const { initNewJob } = useNewJob();
    const [selectingTemplate, setSelectingTemplate] = useState(false);
    const [selectingUnassigned, setSelectingUnassigned] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { assign } = useJobAssignment();
    const day = useRecoilValue(dayState);

    const styleLabelTop = { fontSize: "9px", lineHeight: "13px", fontWeight: "normal" };
    const styleLabelBottom = { ...styleLabelTop, lineHeight: "10px" };
    const height = "24px";

    return (
        <div ref={hoverRef}>
            <HFlex style={{
                alignItems: "stretch",
                position: "relative",
            }}>

                {!isDragging &&
                    <LegLine index={index} count={count} height={height} toTheLeft={
                        <>
                            <SpacerExpand/>
                            <VFlex>
                                <SpacerExpand/>
                                <LabelXSmall style={styleLabelBottom} color={theme.colors.contentPrimary}>
                                    {middle}
                                </LabelXSmall>
                                <SpacerExpand/>
                            </VFlex>
                            <Spacer width={"4px"}/>
                        </>
                    }>
                        <VFlex style={{ height: "auto", justifyContent: "space-between", flexGrow: 1 }}>
                            <HFlex>
                                <Spacer width={"4px"}/>
                                <LabelXSmall style={styleLabelTop} color={theme.colors.contentPrimary}>
                                    {top}
                                </LabelXSmall>
                                <Spacer width={"4px"}/>
                                {routeInfoObject && isHover && isWide && <LabelXSmall style={styleLabelTop} color={theme.colors.contentTertiary}>
                                    {strings.Dispatch.ScheduledDeparture}
                                </LabelXSmall>}
                            </HFlex>
                            <HFlex>
                                <Spacer width={"4px"}/>
                                <LabelXSmall style={styleLabelBottom} color={theme.colors.contentPrimary}>
                                    {bottom}
                                </LabelXSmall>
                                <Spacer width={"4px"}/>
                                {routeInfoObject && isHover && isWide && <LabelXSmall style={styleLabelBottom} color={theme.colors.contentTertiary}>
                                    {strings.Dispatch.ScheduledArrival}
                                </LabelXSmall>}
                            </HFlex>
                        </VFlex>
                        {wait ? <VFlex style={{ height: "auto", justifyContent: "space-between", flexGrow: 1 }}>
                            <Centered>
                                <LabelXSmall color={theme.colors.contentPrimary}>
                                    {"⏳" + wait + " " + strings.Routing.WaitingTime}
                                </LabelXSmall>
                            </Centered>
                        </VFlex> : undefined}
                        <SpacerExpand/>
                        {(checkTop || checkBottom) &&
                            <VFlex style={{
                                height: "auto",
                                justifyContent: "space-between",
                                flexGrow: 1,
                            }}>
                                <HFlex>
                                    <SpacerExpand/>
                                    {checkTop}
                                    <Spacer width={"28px"}/>
                                </HFlex>
                                <HFlex>
                                    <SpacerExpand/>
                                    {checkBottom}
                                    <Spacer width={"28px"}/>
                                </HFlex>
                            </VFlex>
                        }
                    </LegLine>
                }
                {isDragging && <Spacer height={height}/>}
                {!isDragging && (isHover || dropdownOpen) && <Centered style={{
                    position: "absolute",
                    pointerEvents: "none"
                }}>
                    <HFlex style={{ width: "50px", pointerEvents: "auto"}}>
                        <SpacerExpand/>
                        <Dropdown onOpenChange={(isOpen) => setDropdownOpen(isOpen)} menu={{
                            items: [
                                {
                                    key: '1',
                                    label: strings.Dispatch.NewJob,
                                    onClick: () => initNewJob({ uid, number }),
                                },
                                {
                                    key: '2',
                                    label: strings.JobTemplates.CreateNewJobFromTemplate,
                                    onClick: () => setSelectingTemplate(true),
                                },
                                {
                                    key: '3',
                                    label: strings.Job.SearchJobAndAssign,
                                    onClick: () => setSelectingUnassigned(true),
                                },
                            ]
                        }} placement="bottomLeft" arrow>
                            <Button kind={"tertiary"} size={"mini"} shape={"circle"}>
                                <PlusIcon size={16} color={theme.colors.contentPrimary}/>
                            </Button>
                        </Dropdown>
                        <SpacerExpand/>
                    </HFlex>
                </Centered>}
            </HFlex>
            {selectingTemplate &&
                <SelectTemplateModal
                    uid={uid}
                    index={index + 0.5}
                    onClose={() => setSelectingTemplate(false)}
                />
            }
            {selectingUnassigned &&
                <SelectJobModal
                    onJobSelected={(job) => assign(job.id, day, number, uid, job.receiverUid)}
                    onClose={() => setSelectingUnassigned(false)}
                />
            }
        </div>
    );
}

export default memo(LegLineWithAnnotation);