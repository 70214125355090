import * as React from "react";
import { useState } from "react";
import { Invoice } from "../../types/billing";
import { Button } from "baseui/button";
import { getInvoices } from "../../services/privateApi";
import { Spin } from "antd";
import { Table } from "baseui/table-semantic";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { useTime } from "../../hooks/useTime";
import { EnterIcon } from "../ui/svg";
import { StyledLink } from "baseui/link";

export function PastInvoices() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const { formatLongDate } = useTime();
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [showInvoices, setShowInvoices] = useState(false);
    const [loading, setLoading] = useState(false);

    const onShowInvoicesClick = async () => {
        setShowInvoices(true);
        setLoading(true);
        const { invoices } = await getInvoices();
        setInvoices(invoices);
        setLoading(false);
    };

    if (!showInvoices) {
        return (
          <Button size={"mini"} kind={"secondary"} onClick={onShowInvoicesClick}>
              {strings.Billing.ShowPastInvoices}
          </Button>
        );
    }

    if (loading) {
        return <Spin/>;
    }

    const usd = (amount: number) => "$" + amount / 100 + " USD";


    return (
        <Table
            size={"compact"}
            columns={[
                strings.Billing.Amount,
                strings.Billing.Period,
                strings.General.Created,
                strings.General.Status,
                "",
            ]}
            data={invoices.map(invoice => {
                return [
                    usd(invoice.amountDue),
                    formatLongDate(invoice.periodStart * 1000) + "-" + formatLongDate(invoice.periodEnd * 1000),
                    formatLongDate(invoice.created * 1000),
                    invoice.status,
                    <StyledLink animateUnderline href={invoice.pdf} target={"_blank"}>
                        <EnterIcon size={8} color={theme.colors.contentPrimary}/> PDF
                    </StyledLink>,
                ];
            })}
        />
    );
}