import { ActionButton } from "../buttons/ActionButton";
import React from "react";
import { useProSidebar } from "react-pro-sidebar";
import { MenuIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";

export function MenuToggleButton() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const { toggleSidebar } = useProSidebar();

    return (
        <div style={{ marginRight: 'auto', flexShrink: 0, pointerEvents: "auto" }}>
            <ActionButton tooltip={strings.General.ToggleMenu} onClick={() => toggleSidebar()} size={"compact"}>
                <MenuIcon size={16} color={theme.colors.contentPrimary}/>
            </ActionButton>
        </div>
    );
}