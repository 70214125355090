import { Marker } from "react-map-gl";
import MemberAvatar from "../../member/MemberAvatar";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { memberIsVisibleState, memberState, memberUidsState } from "../../../states/membersState";
import React, { useEffect, useState } from "react";
import distance from "@turf/distance"
import { useCamera } from "../../../hooks/useCamera";
import { useBoard } from "../../../hooks/useBoard";
import { SectionedPopover } from "../../shared/SectionedPopover";
import { MemberInfo } from "../../member/MemberInfo";
import { LabelSmall, LabelXSmall } from "baseui/typography";
import { VFlex } from "../../containers/VFlex";
import { SpeedHistogram } from "../../histogram/SpeedHistogram";
import { HFlex } from "../../containers/HFlex";
import { EditUserIcon } from "../../ui/svg";
import { HoverButton } from "../../buttons/HoverButton";
import { dayState, editingMemberIdState } from "../../../states/appState";
import { useTheme } from "../../../hooks/useTheme";
import { useStrings } from "../../../hooks/useStrings";
import { jobsByMemberDayState } from "../../../states/jobsState";
import { getColorFromProgress } from "../../../utils/jobUtils";
import { Progress } from "antd";
import { Uid } from "../../../types/core";

type Props = {
    uid: Uid;
};

function MemberMarker({ uid }: Props) {
    const membersCount = useRecoilValue(memberUidsState).length;
    const member = useRecoilValue(memberState(uid));
    const [renderedLocation, setRenderedLocation] = useState({
        lat: member?.location?.lat ?? 0,
        lng: member?.location?.lng ?? 0,
    });
    const camera = useCamera();
    const board = useBoard();
    const isVisible = useRecoilValue(memberIsVisibleState(uid));
    const setEditingMemberId = useSetRecoilState(editingMemberIdState);
    const { theme } = useTheme();
    const { strings, format } = useStrings();
    const day = useRecoilValue(dayState);
    const jobs = useRecoilValue(jobsByMemberDayState({ uid, day }));
    const jobsCount = jobs.length;
    const progressStepsColors = jobs.map(j => getColorFromProgress(j));

    useEffect(() => {
        if (!member) {
            return;
        }
        const p1 = [renderedLocation.lng, renderedLocation.lat];
        const p2 = [member.location.lng, member.location.lat];
        const dist = distance(p1, p2, { units: "meters" });
        if (dist < 2.5) {
            return;
        }
        if (membersCount > 100) {
            setRenderedLocation({
                lat: member.location.lat,
                lng: member.location.lng
            });
            return;
        }
        const animation = window.requestAnimationFrame(() => {
                const p1 = [renderedLocation.lng, renderedLocation.lat];
                const p2 = [member.location.lng, member.location.lat];
                const dist = distance(p1, p2, { units: "meters" });
                if (dist < 2.5) {
                    return;
                }
                let f = dist < 100_000 ? 0.04 : 1;
                const newLat = renderedLocation.lat + f * (member.location.lat - renderedLocation.lat);
                const newLng = renderedLocation.lng + f * (member.location.lng - renderedLocation.lng);
                setRenderedLocation({
                    lat: newLat,
                    lng: newLng
                });
            }
        );
        return () => window.cancelAnimationFrame(animation);
    });

    if (!member || !isVisible) {
        return null;
    }

    const onMarkerClick = () => {
        board.setMemberUids([member.uid]);
        camera.zoomTo(member.location);
    };

    return (
        <Marker
            onClick={onMarkerClick}
            longitude={renderedLocation.lng}
            latitude={renderedLocation.lat}
            anchor="center"
        >
            <SectionedPopover
                headerColor={member.color}
                header={
                    <LabelSmall margin={0} color={"white"}>
                        {member.name || member.username}
                    </LabelSmall>
                }
                content={
                    <HFlex style={{
                        alignItems: "flex-start"
                    }}>
                        <VFlex spacing>
                            <MemberInfo uid={member.uid} lastLocation vertical/>
                            <SpeedHistogram uid={member.uid}/>
                            {jobsCount > 0 &&
                                <HFlex spacing alignCenter>
                                    <LabelXSmall>{format(strings.Job.XJobs, jobs.length)}</LabelXSmall>
                                    {jobsCount < 25 &&
                                        <Progress percent={100}
                                                  steps={jobsCount}
                                                  size="small"
                                                  strokeColor={progressStepsColors}
                                                  showInfo={false}
                                        />
                                    }
                                </HFlex>
                            }
                        </VFlex>
                        <HoverButton
                            size={"compact"}
                            tooltip={strings.General.OpenProfile}
                            onClick={() => setEditingMemberId(member?.uid)}
                        >
                            <EditUserIcon size={16} color={theme.colors.contentTertiary}/>
                        </HoverButton>
                    </HFlex>
                }
            >
                <MemberAvatar uid={member.uid} forMap={true}/>
            </SectionedPopover>
        </Marker>
    );
}

export default React.memo(MemberMarker);