import { TrackOrStopEntry } from '../../types/track';
import { useRecoilState, useRecoilValue } from "recoil";
import { trackOrStopEntriesState, tracksSelectedUidsState } from "../../states/tracksState";
import { Uid } from "../../types/core";

export function useTracksSelection() {
    const [selectedUids, setSelectedUids] = useRecoilState(tracksSelectedUidsState);
    const entries = useRecoilValue(trackOrStopEntriesState);
    const allSelected = entries.map(t => t.uid).every(uid => selectedUids.includes(uid));
    const selectionCount = selectedUids.length;

    const isSelected = (uid: Uid) => selectedUids.includes(uid);

    const toggleSelected = (uid: Uid) => {
        if (isSelected(uid)) {
            setSelectedUids([...selectedUids.filter(id => id !== uid)]);
        } else {
            setSelectedUids([...selectedUids, uid]);
        }
    };

    const clearSelection = () => setSelectedUids([]);

    const select = (uids: Uid[]) => {
        setSelectedUids([...selectedUids.filter(uid => !uids.includes(uid)), ...uids]);
    }

    const selectAll = () => {
        select(entries.map(entry => entry.uid));
    };

    const tableMultiSelect = (entry: TrackOrStopEntry, entries: TrackOrStopEntry[]) => {
        if (selectedUids.length === 1 && entries.length > 0) {
            const initialTrackUid = selectedUids[0];
            const initialTrackIndex = entries.findIndex((t) => t.uid === initialTrackUid);
            const endTrackIndex = entries.findIndex((t) => t.uid === entry.uid);
            if (initialTrackIndex !== -1 && endTrackIndex !== -1) {
                const start = initialTrackIndex < endTrackIndex ? initialTrackIndex + 1 : endTrackIndex;
                const end = initialTrackIndex > endTrackIndex ? initialTrackIndex : endTrackIndex + 1;
                const tracksInRange = entries.slice(start, end);
                select(tracksInRange.filter(t => t.kind === "track").map((t) => t.uid))
                return true;
            }
        }
        return false;
    }

    return {
        toggleSelected,
        selectedUids,
        setSelectedUids,
        isSelected,
        selectionCount,
        clearSelection,
        allSelected,
        selectAll,
        tableMultiSelect,
    };
}