import { Button } from 'baseui/button';
import { useRecoilValue } from 'recoil';
import { useModal } from '../../../hooks/useModal';
import { useStyleOverrides } from '../../../hooks/useStyleOverrides';
import { editingMemberIdState, memberRoutingOnlyState } from '../../../states/appState';
import { stringsState } from '../../../states/localeState';
import { memberState } from '../../../states/membersState';
import { SpacerExpand } from '../../containers/Spacer';
import { useMemberForms } from '../../../hooks/members/useMemberForms';
import { StyledModalContent } from '../../ui/StyledModalContent';
import { MemberTabs } from './MemberTabs';
import { ConfirmModal } from '../../ui/ConfirmModal';
import { useEffect, useState } from 'react';
import { VFlex } from '../../containers/VFlex';
import { useTheme } from '../../../hooks/useTheme';
import useAccount from '../../../hooks/useAccount';
import { FormInput } from '../../ui/FormInput';
import { AnyField } from '../../../types/fields';
import { checkPassword } from "../../../services/privateApi";
import { useMembersUpdater } from "../../../hooks/members/useMembersUpdater";

export function MemberModalContent() {
    const strings = useRecoilValue(stringsState);
    const editingMember = useRecoilValue(editingMemberIdState);
    const member = useRecoilValue(memberState(editingMember));
    const { closeModal } = useModal();
    const { onSubmit, isLoading, hasChanges, canSubmit, onDelete } = useMemberForms();
    const { cancelButtonStyles } = useStyleOverrides();
    const [showDeleteConfirm, setDeleteShowConfirm] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const account = useAccount();
    const { theme } = useTheme();
    const routingOnly = useRecoilValue(memberRoutingOnlyState);
    const { updateSingleMember } = useMembersUpdater();

    useEffect(() => {
        member?.uid && updateSingleMember(member.uid);
    }, []);

    if (!member) {
        return null;
    }

    const onDeleteConfirmed = async () => {
        if (await checkPassword(confirmPassword)) {
            await onDelete();
            setDeleteShowConfirm(false);
        } else {
            setPasswordError(strings.General.IncorrectPassword);
        }
    };

    const getMemberData = () => {
        return `${member.name} <strong>(${member.username})</strong>`
    }

    const handleCancelConfirm = () => {
        setConfirmPassword('');
        setPasswordError('');
        setDeleteShowConfirm(false);
    }

    const passwordChange = (_: AnyField, value: string | number) => {
        setConfirmPassword(value as string);
        setPasswordError('');
    }

    return (
        <>
            <StyledModalContent
                header={member.name || member.username}
                body={
                    <MemberTabs/>
                }
                footer={
                    <>
                        {!account.isSelfAccount(member.uid) && account.isSelfAdminOrCompany && !routingOnly && (
                            <Button
                                onClick={() => setDeleteShowConfirm(true)}
                                size='compact'
                                kind='tertiary'
                            >
                                {strings.General.Delete}
                            </Button>
                        )}
                        <SpacerExpand/>
                        {hasChanges && (
                            <Button
                                onClick={closeModal}
                                size='compact'
                                kind='tertiary'
                                overrides={cancelButtonStyles}
                            >
                                {strings.General.Cancel}
                            </Button>
                        )}
                        <Button
                            onClick={hasChanges ? onSubmit : closeModal}
                            size='compact'
                            isLoading={isLoading}
                            disabled={!canSubmit && hasChanges}
                        >
                            {hasChanges ? strings.General.Save : strings.General.OK}
                        </Button>
                    </>
                }
            />
            {!account.isSelfAccount(member.uid) && (
                <ConfirmModal
                    isOpen={showDeleteConfirm}
                    description={
                        <VFlex spacing={theme.sizing.scale400}>
                            <span
                                dangerouslySetInnerHTML={{ __html: `${strings.formatString(strings.AreYouSure.AreYouSureToRemoveX, getMemberData())}` }}/>
                            <span>{strings.Members.AllLocationDataWillBeRemovedForThisUser}</span>
                            <span dangerouslySetInnerHTML={{ __html: strings.General.ActionCannotBeUndone }}></span>
                            <span>
                                <FormInput
                                    inputSize="compact"
                                    label={strings.Members.DeleteMemberConfirmPassword}
                                    value={confirmPassword}
                                    field="password"
                                    onChange={passwordChange}
                                    type="password"
                                    error={passwordError}
                                    autoComplete="new-password"
                                />
                            </span>
                        </VFlex>
                    }
                    onCancel={handleCancelConfirm}
                    onConfirm={onDeleteConfirmed}
                    title={strings.Members.DeleteMember}
                    canConfirm={!!confirmPassword}
                    cancelText={strings.General.Cancel}
                    confirmText={strings.Members.DeleteMember}
                    danger
                />
            )}
        </>
    );
}
