import * as React from 'react';
import { ComponentType, memo } from 'react';
import { FixedMarker } from 'baseui/map-marker';
import { MAP_ICON_DEFAULT_SIZE } from "../../../states/mapState";
import { COLOR_JOB_NEEDLE } from "../../../constants/colors";

type Props = {
    text?: string;
    size: number;
    color: string;
    style?: any;
    icon?: ComponentType<{ size: number; }> | undefined;
    needle?: "none" | "short" | "medium";
}

function FixedJob({ text, size, color, icon, needle = "none", style }: Props) {
    const [dragging, setDragging] = React.useState(false);

    return (
        <div
            onMouseEnter={() => setDragging(true)}
            onMouseLeave={() => setDragging(false)}
        >
            <FixedMarker
                dragging={dragging}
                needle={needle}
                size={size > MAP_ICON_DEFAULT_SIZE * 1.8
                    ? "large"
                    : size > MAP_ICON_DEFAULT_SIZE * 1.4
                        ? "medium"
                        : "small"}
                label={text}
                startEnhancer={icon}
                overrides={{
                    Root: {
                        style: {
                            ...style,
                        }
                    },
                    PinHead: {
                        style: {
                            backgroundColor: color,
                            outlineColor: COLOR_JOB_NEEDLE,
                            outlineStyle: "solid",
                            outlineWidth: "1px",
                        }
                    },
                    Label: {
                        style: {
                            color: "white",
                        }
                    },
                    Needle: {
                        style: {
                            backgroundColor: COLOR_JOB_NEEDLE
                        }
                    }
                }}
            />
        </div>
    );
}

export default memo(FixedJob);