import { useRecoilValue } from "recoil";
import { assistantChipsState } from "../../states/assistantState";
import { AssistantChip } from "./AssistantChip";
import { useStrings } from "../../hooks/useStrings";

export function AssistantChips() {
    const { strings } = useStrings();
    const chips = useRecoilValue(assistantChipsState);

    return (
        <div style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4px",
            overflow: "hidden",
            alignItems: "center",
            width: "100%"
        }}>
            {chips.map((chip, index) =>
                <AssistantChip key={index} chip={chip}/>
            )}
            {chips.length === 0 && strings.Assistant.HangTight}
        </div>
    );
}