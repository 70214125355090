import { Checkbox } from 'baseui/checkbox';
import { Search } from 'baseui/icon';
import { Input } from 'baseui/input';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { ChangeEvent, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useStrings } from '../../hooks/useStrings';
import { pickerSelectedPlacesState, placesPickerState } from '../../states/placesPickerState';
import { PlaceListItem } from '../../types/place';
import { sortPlaces } from '../../utils/placeUtils';
import { PlacesBadges } from '../badges/PlacesBadges';
import { VFlex } from '../containers/VFlex';
import { PlaceItemSimplified } from '../places/PlaceItemSimplified';
import { Uid } from "../../types/core";

type Props = {
    multi?: boolean;
}

export function PlacesListPicker({ multi = true }: Props) {
    const { strings } = useStrings();
    const [placeList, setPlaceList] = useRecoilState(placesPickerState);
    const [placeSearch, setPlaceSearch] = useState('');
    const hasAny = Boolean(placeList.length);
    const hasAll = hasAny && placeList.every((place) => place.selected);
    const hasSome = hasAny && placeList.some((place) => place.selected);
    const filteredPlaces = placeList
        .filter((place) => place.name.toLowerCase().includes(placeSearch.toLowerCase()))
        .sort(sortPlaces);
    const filteredHasAll = filteredPlaces.every((place) => place.selected);
    const showingAllPlaces = filteredPlaces.length === placeList.length;
    const selectedPlaces = [...useRecoilValue(pickerSelectedPlacesState)].sort(sortPlaces);
    const selectedCount = selectedPlaces.length;

    const toggleAll = ({ currentTarget: { checked } }: ChangeEvent<HTMLInputElement>) => {
        setPlaceList((prev) =>
            prev.map((place) => {
                if (!showingAllPlaces && filteredPlaces.findIndex((m) => m.uid === place.uid) !== -1) {
                    return {
                        ...place,
                        selected: !filteredHasAll,
                    };
                }
                if (showingAllPlaces) {
                    return { ...place, selected: !hasAll };
                }
                return {
                    ...place,
                };
            })
        );
    };

    const toggle = (uid: Uid, checked: boolean) => {
        setPlaceList((prev) =>
            prev.map((place) => ({
                ...place,
                selected: place.uid === uid ? checked : multi ? place.selected : false,
            }))
        );
    };

    return (
        <VFlex>
            <Input
                value={placeSearch}
                onChange={(e) => setPlaceSearch(e.target.value)}
                placeholder={strings.General.Filter}
                clearable
                size="mini"
                startEnhancer={<Search/>}
            />
            {!!selectedCount && (
                <PlacesBadges uids={selectedPlaces.map(m => m.uid)} onClose={(uid) => toggle(uid, false)}/>
            )}
            <TableBuilder
                data={filteredPlaces}
                overrides={{
                    Root: {
                        style: {
                            height: '50vh',
                            overflowY: 'auto',
                        },
                    },
                }}
            >
                <TableBuilderColumn
                    overrides={{
                        TableHeadCell: { style: { width: '1%' } },
                        TableBodyCell: { style: { width: '1%' } },
                    }}
                    header={multi &&
                        <Checkbox checked={hasAll} isIndeterminate={!hasAll && hasSome} onChange={toggleAll}/>}
                >
                    {(place: PlaceListItem) => (
                        <Checkbox name={place.uid} checked={place.selected} onChange={({currentTarget: {name, checked}}) => toggle(name as Uid, checked)}/>
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn header={strings.General.Place}>
                    {(place: PlaceListItem) => <PlaceItemSimplified uid={place.uid}/>}
                </TableBuilderColumn>
            </TableBuilder>
        </VFlex>
    );
}