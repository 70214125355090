import { useRecoilCallback, useSetRecoilState } from "recoil";
import { timelineState } from "../states/geojsonState";
import { fetchGeoJson } from "../services/geojson";
import { useState } from "react";
import { useUpdateEffect } from "usehooks-ts";
import { requestingState } from "../states/viewState";
import { Day, Uid } from "../types/core";

interface TimelineResponse {
    uid: Uid;
    day: Day;
    geoJson: JSON;
}

export function useTimelineUpdater() {
    const setTimelineRequesting = useSetRecoilState(requestingState({ req: "timeline" }));
    const [response, setResponse] = useState({} as TimelineResponse);
    const recoilCallback = useRecoilCallback(({ set }) => () => {
        set(timelineState({
            uid: response.uid,
            day: response.day
        }), response.geoJson as unknown as GeoJSON.FeatureCollection);
    }, [response]);
    useUpdateEffect(() => recoilCallback(), [response]);

    const update = (uid: Uid, day: Day) => {
        setTimelineRequesting(true);
        fetchGeoJson("timeline", "member=" + uid + "&day=" + day)
            .then(result => result?.json())
            .then(json => {
                setResponse({ uid: uid, day: day, geoJson: json });
                setTimelineRequesting(false);
            });
    }
    return { update };
}