import { ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { useTheme } from "../../hooks/useTheme";

type Props = {
    header?: React.ReactNode;
    body?: React.ReactNode;
    footer?: React.ReactNode;
};

export function StyledModalContent({ header, body, footer }: Props) {
    const { theme } = useTheme();

    return (
        <>
            {header && <ModalHeader style={{
                paddingLeft: "24px",
                paddingRight: "24px",
            }}>{header}</ModalHeader>}
            {body && <ModalBody style={{
                marginTop: "0px",
                marginBottom: "0px",
                marginLeft: "0px",
                marginRight: "0px",
                paddingLeft: "24px",
                paddingRight: "24px",
            }}>{body}</ModalBody>}
            {footer && (
                <ModalFooter
                    style={{
                        display: 'flex',
                        marginTop: "4px",
                        marginLeft: "0px",
                        marginRight: "0px",
                        paddingLeft: "24px",
                        paddingRight: "24px",
                        borderTopStyle: "solid",
                        borderTopWidth: "1px",
                        borderColor: theme.colors.backgroundSecondary,
                    }}
                >
                    {footer}
                </ModalFooter>
            )}
        </>
    );
}
