import { atomWithIndexedPersistence } from "../utils/persistence";
import { AlertConfig, AlertInstance } from "../types/alerts";
import { atom } from "recoil";

export const alertConfigsState = atomWithIndexedPersistence("alertconfigs", [] as AlertConfig[]);

export const alertInstancesState = atom({
    key: "alertinstances",
    default: [] as AlertInstance[],
});

export const selectedAlertConfigState = atom<AlertConfig | undefined>({
    key: "alertconfig.selected",
    default: undefined
});