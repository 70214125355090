import { Button } from "baseui/button";
import { Plus } from "baseui/icon";
import { StatefulPopover } from "baseui/popover";
import * as React from "react";
import { FormItemOption } from "../../hooks/useForms";
import { StyledMenu } from "../ui/StyledMenu";

export function FormPlusButton({
                                   items,
                                   onPlusClick
                               }: { items: FormItemOption[], onPlusClick: (option: FormItemOption) => void }) {
    return (
        <StatefulPopover
            focusLock
            content={({ close }) => (
                <StyledMenu
                    items={items}
                    onItemSelect={(opt) => {
                        onPlusClick(opt.item);
                        close();
                    }}
                />
            )}
        >
            <Button size={"compact"} shape={"round"}>
                <Plus size={16}/>
            </Button>
        </StatefulPopover>
    );
}