import { getAccounts } from "../../services/api";
import { useStateUpdater } from "../useStateUpdater";
import { setRecoil } from "../../providers/RecoilAccessProvider";
import { memberState } from "../../states/membersState";
import { Uid } from "../../types/core";

export function useMembersUpdater() {
    const stateUpdater = useStateUpdater();

    const updateMembers = async () => {
        const { status, accounts } = await getAccounts();
        if (status) {
            stateUpdater.updateMembers(accounts);
        }
    }

    const updateSingleMember = async (uid: Uid) => {
        const { status, accounts } = await getAccounts([uid]);
        if (status) {
            for (const account of accounts) {
                setRecoil(memberState(account.uid), account);
            }
        }
    }

    return { updateMembers, updateSingleMember };
}