import { atom } from "recoil";
import { DetailedLocation, LonLat } from "../types/core";

export type DrawMode = {
    type: "zone" | "route" | "place" | "job" | "team" | "attachment" | "start" | "end" | "company";
    action: "new" | "edit";
    location?: DetailedLocation;
    radius?: number;
    coordinates?: LonLat[];
}

export function isLocationDrawMode(drawMode: DrawMode) {
    return drawMode?.type === "job"
        || drawMode?.type === "place"
        || drawMode?.type === "team"
        || drawMode?.type === "start"
        || drawMode?.type === "end"
        || drawMode?.type === "attachment"
        || drawMode?.type === "company";
}

export const drawModeState = atom<DrawMode>({
    key: 'map.draw.mode',
    default: undefined,
});
export const locationDraggingState = atom({
    key: 'map.draw.location.dragging',
    default: false,
});