import { forwardRef, ReactNode } from "react";
import { StyleObject } from "styletron-react";
import { useTheme } from "../../hooks/useTheme";

type Props = {
    children: ReactNode,
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
    style?: StyleObject,
}

export const Clickable = forwardRef<HTMLDivElement, Props>(({ children, onClick = undefined, style }: Props, ref) => {
    const { css } = useTheme();

    return (
        <div
            ref={ref}
            className={css({
                cursor: 'pointer',
                ...style
            })}
            onClick={onClick ? (event) => {
                event.stopPropagation();
                event.preventDefault();
                onClick(event);
            } : undefined}
        >
            {children}
        </div>
    );
});