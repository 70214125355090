import { LabelMedium } from 'baseui/typography';
import { Job } from '../../types/job';
import { SectionedPopover } from '../shared/SectionedPopover';
import { JobTooltipInfo } from './JobTooltipInfo';
import { getColorFromProgress } from "../../utils/jobUtils";
import { Button } from "baseui/button";
import { useSetRecoilState } from "recoil";
import { editingJobIdState } from "../../states/appState";
import { EditIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { PlaceBadge } from "../badges/PlaceBadge";
import { memo } from "react";

type Props = {
    job: Job;
    children: React.ReactNode;
    header?: string;
};

export const JobTooltip = memo(({ job, children, header }: Props) => {
    const { theme } = useTheme();
    const editJob = useSetRecoilState(editingJobIdState);
    const width = "300px";

    return (
        <SectionedPopover headerColor={getColorFromProgress(job)}
                          header={
                              <HFlex alignCenter style={{ maxWidth: width }}>
                                  <LabelMedium margin={0} color={"white"}>
                                      {header ? header : job.destinationName}
                                  </LabelMedium>
                                  <SpacerExpand/>
                                  {job.placeUid && <PlaceBadge uid={job.placeUid}/>}
                                  <Button size={"mini"} onClick={() => editJob(job.id)} kind={"secondary"}>
                                      <EditIcon size={14} color={theme.colors.contentPrimary}/>
                                  </Button>
                              </HFlex>
                          }
                          content={
                              <JobTooltipInfo job={job} width={width}/>
                          }
                          placement={"auto"}
        >
            {children}
        </SectionedPopover>
    );
});
