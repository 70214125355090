import React from "react";
import { useRecoilValue } from "recoil";
import { sequenceState } from "../../../states/sequencesState";
import { Sequence } from "../../../types/sequence";
import SequenceItem from "./SequenceItem";

type Props = {
    data: {
        ids: string[];
        onEdit: (sequence: Sequence) => void;
        onDelete: (sequence: Sequence) => void;
    };
    index: number;
    style: React.CSSProperties;
};

function SequenceItemVirtualized({ data, index, style }: Props) {
    const uid = data.ids[index];
    const { onDelete, onEdit } = data;
    const sequence = useRecoilValue(sequenceState(uid));

    if (!sequence) {
        return null;
    }

    return (
        <div style={style}>
            <SequenceItem
                sequence={sequence}
                onEditSequence={onEdit}
                onDeleteSequence={onDelete} />
        </div>
    );
}

export default React.memo(SequenceItemVirtualized);
