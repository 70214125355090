import { Avatar } from 'baseui/avatar';
import { Button } from 'baseui/button';
import { useState } from 'react';
import { useSetRecoilState } from "recoil";
import { memberState } from "../../states/membersState";
import { companyState } from '../../states/accountState';
import { Company } from '../../types/company';
import { Member } from '../../types/member';
import useAccount from '../../hooks/useAccount';
import { ImageUploader } from '../ui/ImageUploader';
import { updateProfileImage } from "../../services/upload";
import { editCompanyState } from '../../states/editCompanyState';
import { editMemberState } from '../../states/editMemberState';
import { useTheme } from "../../hooks/useTheme";
import { useStrings } from "../../hooks/useStrings";
import { VFlex } from "../containers/VFlex";
import { setProfileImageInitials } from "../../services/api";
import { Popconfirm } from "antd";
import { Input } from "baseui/input";
import { useToast } from "../../hooks/useToast";
import useDirector from "../../hooks/useDirector";
import { Uid } from "../../types/core";

type Props = {
    uid: Uid;
    name: string;
    portrait: string;
    color?: string;
    isCompany?: boolean;
    isPlace?: boolean;
};

export function ProfileImage({ uid, name, portrait, color, isCompany = false, isPlace = false }: Props) {
    const { theme, css } = useTheme();
    const { strings } = useStrings();
    const [isEditingImage, setIsEditingImage] = useState(false);
    const setCompany = useSetRecoilState(companyState);
    const setMember = useSetRecoilState(memberState(uid));
    const setCompanyFields = useSetRecoilState(editCompanyState);
    const setMemberFields = useSetRecoilState(editMemberState(uid));
    const { setIcon, uid: accountUid, company } = useAccount();
    const [initialsText, setInitialsText] = useState("");
    const [uploadingImage, setUploadingImage] = useState(false);
    const toast = useToast();
    const director = useDirector();

    const uploadProfileImage = async (file: File) => {
        setUploadingImage(true);
        const { status, account } = await updateProfileImage({ file: file, uid: uid });
        if (status && account) {
            if (isCompany) {
                setCompany(account as Company);
                setCompanyFields((prev) => ({ ...prev, portrait: account.portrait }));
            } else if (isPlace) {
                toast.showInfo(strings.Places.PlaceImageUpdate);
            } else {
                setMember(account as Member);
                setMemberFields((prev) => ({ ...prev, portrait: account.portrait }))
            }
            if (accountUid === account.uid) {
                setIcon(account.portrait);
            }
            if (company?.uid === uid) {
                await director.performPoll();
            }
        }
        setUploadingImage(false);
        setIsEditingImage(false);
    };

    const onCreateWithTextClick = async () => {
        if (initialsText.trim().length === 0) {
            toast.showInfo(strings.General.Cancelled);
            return;
        }
        await setProfileImageInitials(uid, initialsText);
        setIsEditingImage(false);
        setInitialsText("");
        if (company?.uid === uid) {
            await director.performPoll();
        }
        toast.showSuccess(strings.General.Done);
    };

    if (isEditingImage) {
        return (
            <VFlex spacing>
                <ImageUploader onValid={uploadProfileImage} isAvatar isLoading={uploadingImage}/>
                <Popconfirm
                    title={strings.General.EnterInitials}
                    onConfirm={onCreateWithTextClick}
                    description={
                        <Input
                            value={initialsText}
                            onChange={(e) => setInitialsText(e.target.value)}
                            placeholder={strings.General.Max6Chars}
                            type="text"
                            clearable
                            size="mini"
                            maxLength={6}
                        />
                    }>
                    <Button size={"mini"} kind={"tertiary"}>
                        {strings.General.OrCreateWithText}
                    </Button>
                </Popconfirm>
            </VFlex>
        )

    }

    return (
        <div className={css({
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.sizing.scale300,
            width: 'fit-content'
        })}>
            <Avatar name={name} size="scale1600" src={portrait} overrides={{
                Root: {
                    style: {
                        backgroundColor: color
                    }
                }
            }}/>
            <Button onClick={() => setIsEditingImage(true)} kind={'tertiary'} size={'mini'}>
                {strings.General.Change}
            </Button>
        </div>
    );
}
