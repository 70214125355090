import { useRecoilValue } from "recoil";
import { speedHistogramDataState } from "../../states/dataState";
import { VFlex } from "../containers/VFlex";
import { Sparklines, SparklinesLine, SparklinesReferenceLine, SparklinesSpots } from "react-sparklines";
import { useUnit } from "../../hooks/useUnit";
import { COLOR_MOVING } from "../../constants/colors";
import { LabelXSmall } from "baseui/typography";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import React, { memo } from "react";
import { tracksLayerState } from "../../states/geojsonState";
import { retrieveTracksFromGeojson } from "../../utils/geojsonUtils";
import { dayState, pageState } from "../../states/appState";
import { PAGE } from "../../constants/pages";
import { dayOfToday } from "../../utils/time";
import { Uid } from "../../types/core";

export const SpeedHistogram = memo(({ uid }: { uid: Uid }) => {
    const day = useRecoilValue(dayState);
    const page = useRecoilValue(pageState);
    const speedHistogramData = useRecoilValue(speedHistogramDataState(uid));
    const unit = useUnit();
    const fullDayLayer = useRecoilValue(tracksLayerState({ uid: uid, day: dayOfToday() }));
    const trackEntries = retrieveTracksFromGeojson(fullDayLayer);
    const { distanceToString } = useUnit();

    if (!trackEntries) {
        return null;
    }

    const km = trackEntries.map(t => t.entry.distance).reduce((sum, current) => sum + current, 0);

    if (speedHistogramData.length < 2 && km === 0) {
        return null;
    }

    const isLive = page === PAGE.LIVE || day === dayOfToday();

    return (
        <VFlex alignCenter style={{ width: "80px", height: "30px" }}>
            <SpacerExpand/>
            <HFlex spacing={"4px"}>
                <SpacerExpand/>
                <LabelXSmall style={{ right: "8px", color: COLOR_MOVING }}>
                    {distanceToString(km)}
                </LabelXSmall>
                {isLive && speedHistogramData.length > 0 &&
                    <LabelXSmall style={{ right: "8px", color: COLOR_MOVING }}>
                        {unit.speedToString(speedHistogramData[speedHistogramData.length - 1])}
                    </LabelXSmall>
                }
            </HFlex>
            {isLive &&
                <Sparklines data={speedHistogramData} margin={8}>
                    <SparklinesLine style={{ strokeWidth: 3, stroke: COLOR_MOVING, fill: "none" }}/>
                    <SparklinesSpots size={4}
                                     style={{ stroke: COLOR_MOVING, strokeWidth: 3, fill: "white" }}/>
                    <SparklinesReferenceLine type="custom" value={60}/>
                </Sparklines>
            }
            <SpacerExpand/>
        </VFlex>
    );
});