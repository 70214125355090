import { SpacerExpand } from "../containers/Spacer";
import { Button } from "baseui/button";
import { QuickAddMember } from "./QuickAddMember";
import { HFlex } from "../containers/HFlex";
import { useRecoilState, useSetRecoilState } from "recoil";
import { importTypeState } from "../../states/appState";
import { useStrings } from "../../hooks/useStrings";
import { useOnboardRefs } from "../../hooks/onboard/useOnboardRefs";
import { activeTeamTabState } from "../../states/viewState";
import React from "react";
import useAccount from "../../hooks/useAccount";
import { TeamsIcon, UsersOutlineIcon } from "../ui/svg";
import { SwapTab } from "../ui/SwapTab";
import { useTheme } from "../../hooks/useTheme";

export function TeamTitleBar() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const setImportType = useSetRecoilState(importTypeState);
    const { importMembersRef } = useOnboardRefs();
    const [activeKey, setActiveKey] = useRecoilState(activeTeamTabState);
    const { isSelfAdminOrCompany } = useAccount();

    const onImportMembers = () => {
        setImportType("members");
    }

    return (
        <HFlex alignCenter spacing>
            <SwapTab activeKey={activeKey}
                     onChange={(activeKey) => setActiveKey(activeKey as "members" | "team")}
                     tab1={{
                         key: "members",
                         label: <UsersOutlineIcon color={theme.colors.contentPrimary} size={14}/>,
                         description: strings.Members.AllMembers,
                     }}
                     tab2={{
                         key: "team",
                         label: <TeamsIcon color={theme.colors.contentPrimary} size={14}/>,
                         description: strings.General.Teams,
                     }}
            />
            <SpacerExpand/>
            <Button ref={importMembersRef}
                    kind={"secondary"}
                    size={"mini"}
                    onClick={onImportMembers}
                    disabled={!isSelfAdminOrCompany}
            >
                {strings.TeamManagement.ImportMembers}
            </Button>
            <QuickAddMember/>
        </HFlex>
    );
}