import { atom, selector } from 'recoil';
import { DEFAULT_MAP, HEAT_MAP_OBJ, MAP_TYPES_OBJ } from '../constants/mapConstants';
import { atomWithPersistence, localStorageEffect } from '../utils/persistence';
import { ViewState } from 'react-map-gl';
import { placesSelectedUidsState } from "./placeState";
import { pageState } from "./appState";
import { PAGE } from "../constants/pages";
import { drawModeState } from "./drawModeState";
import { Latitude, LatLng, Longitude, Uid } from "../types/core";

export const mapTypeState = atomWithPersistence('map.type', DEFAULT_MAP);

export const mapTypeObjectState = selector({
    key: "map.type.show",
    get: ({ get }) => {
        const mapType = get(mapTypeState);
        const page = get(pageState);
        if (page === PAGE.HEATMAP) {
            return HEAT_MAP_OBJ;
        } else {
            return MAP_TYPES_OBJ[mapType];
        }
    }
});

export const mapViewState = atom({
    key: 'map.view',
    default: {
        longitude: -122.4,
        latitude: 37.8,
        zoom: 14,
    } as ViewState,
    effects: [localStorageEffect],
});

export const mapBearingState = atom({
    key: 'map.bearing',
    default: 0,
});

export const MAP_ICON_DEFAULT_SIZE = 28;

export const mapIconSizeState = atom({
    key: 'MapIconSize',
    default: MAP_ICON_DEFAULT_SIZE,
});

export const user3DEnabledState = atomWithPersistence('map.enable3d', false);

export const is3DEnabledState = selector({
    key: "map.is3DEnabled",
    get: ({ get }) => {
        const user3DEnabled = get(user3DEnabledState);
        const selectedPlaces = get(placesSelectedUidsState);
        const drawMode = get(drawModeState);
        const page = get(pageState);
        return user3DEnabled && selectedPlaces.length === 0 && !drawMode && page !== PAGE.PLACES;
    }
})

export const showPlacesLayerState = atomWithPersistence("map.layer.places.show", true);

export const showZonesLayerState = atomWithPersistence("map.layer.zones.show", true);

export const showTrafficLayerState = atomWithPersistence("map.layer.traffic.show", true);
export const showJobsLayerState = atomWithPersistence("map.layer.jobs.show", true);
export const showRoutesLayerState = atomWithPersistence("map.layer.routes.show", true);
export const showWaypointsLayerState = atomWithPersistence("map.layer.waypoints.show", true);

export const mapInteractiveLayersState = atom({
    key: "map.interactivelayers",
    default: [] as string[],
})

export const mapToolState = atom<"selection" | "point" | "route" | undefined>({
    key: "map.tool",
    default: undefined,
});

export const pulseMarkerState = atom<LatLng | undefined>({
    key: "map.marker.pulse",
    default: undefined,
})

export const h3CellResolutionState = atom<number>({
    key: "map.h3cell.resolution",
    default: 10,
})

export const mapMarkerUpdateEventState = atom<{ uid: Uid, lat: Latitude, lng: Longitude }>({
    key: "map.marker.event",
    default: undefined
})