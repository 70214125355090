import { Column } from "jspreadsheet-ce";
import { getColumnConfig } from "../../utils/spreadsheets";
import { JobImportAdvancedFields, JobImportDefaultFields } from "../../constants/jobs";
import { useImportOptions } from "../useImportOptions";
import { UseImportConfig } from "../../types/imports";

export function useJobsImportConfig() {
    const {
        memberOptions,
        jobTypeOptions,
        priorityOptions,
        teamOptions,
        zoneOptions,
        autoAssignOptions,
        carryOverOptions,
    } = useImportOptions();

    const title = JobImportDefaultFields[JobImportDefaultFields.Title];
    const placeName = JobImportDefaultFields[JobImportDefaultFields["Place Name"]];
    const address = JobImportDefaultFields[JobImportDefaultFields.Address];
    const city = JobImportDefaultFields[JobImportDefaultFields.City];
    const state = JobImportDefaultFields[JobImportDefaultFields.State];
    const country = JobImportDefaultFields[JobImportDefaultFields.Country];
    const type = JobImportDefaultFields[JobImportDefaultFields.Type];
    const assignee = JobImportDefaultFields[JobImportDefaultFields.Assignee];
    const team = JobImportDefaultFields[JobImportDefaultFields.Team];
    const date = JobImportDefaultFields[JobImportDefaultFields.Date];
    const order = JobImportDefaultFields[JobImportDefaultFields.Order];
    const priority = JobImportDefaultFields[JobImportDefaultFields.Priority];
    const contactName = JobImportDefaultFields[JobImportDefaultFields["Contact Name"]];
    const contactPhone = JobImportDefaultFields[JobImportDefaultFields["Contact Phone"]];
    const timeWindow = JobImportDefaultFields[JobImportDefaultFields["Time Window"]];
    const onSiteMinutes = JobImportDefaultFields[JobImportDefaultFields["On Site (minutes"]];
    const notes = JobImportDefaultFields[JobImportDefaultFields.Notes];
    const autoAssign = JobImportDefaultFields[JobImportDefaultFields["Auto-assign"]];
    const carryOver = JobImportDefaultFields[JobImportDefaultFields["Carry Over"]];
    const emailOnCompletion = JobImportDefaultFields[JobImportDefaultFields["Email On Completion"]];
    const emailOnIssue = JobImportDefaultFields[JobImportDefaultFields["Email On Issue"]];
    // Advanced fields
    const latitude = JobImportAdvancedFields[JobImportAdvancedFields.Latitude];
    const longitude = JobImportAdvancedFields[JobImportAdvancedFields.Longitude];
    const attachment = JobImportAdvancedFields[JobImportAdvancedFields.Attachment];
    const contactEmail = JobImportAdvancedFields[JobImportAdvancedFields["Contact Email"]];
    const sortNumber = JobImportAdvancedFields[JobImportAdvancedFields["Sort Number"]];
    const pickUp = JobImportAdvancedFields[JobImportAdvancedFields["Pick-up"]];
    const dropOff = JobImportAdvancedFields[JobImportAdvancedFields["Drop-off"]];
    const linkedForm = JobImportAdvancedFields[JobImportAdvancedFields["Linked Form"]];
    const linkedZone = JobImportAdvancedFields[JobImportAdvancedFields["Linked Zone"]];

    const defaultColumnsConfig = {
        [title]: getColumnConfig(title, { width: 220 }),
        [placeName]: getColumnConfig(placeName),
        [address]: getColumnConfig(address),
        [city]: getColumnConfig(city),
        [state]: getColumnConfig(state),
        [country]: getColumnConfig(country),
        [type]: getColumnConfig(type, { type: "autocomplete", source: jobTypeOptions }),
        [assignee]: getColumnConfig(assignee, { type: "autocomplete", source: memberOptions }),
        [team]: getColumnConfig(team, { type: "autocomplete", source: teamOptions }),
        [date]: getColumnConfig(date),
        [order]: getColumnConfig(order),
        [priority]: getColumnConfig(priority, { type: "autocomplete", source: priorityOptions }),
        [contactName]: getColumnConfig(contactName),
        [contactPhone]: getColumnConfig(contactPhone),
        [timeWindow]: getColumnConfig(timeWindow),
        [onSiteMinutes]: getColumnConfig(onSiteMinutes),
        [notes]: getColumnConfig(notes, { width: 180 }),
        [autoAssign]: getColumnConfig(autoAssign, { type: "autocomplete", source: autoAssignOptions }),
        [carryOver]: getColumnConfig(carryOver, { type: "autocomplete", source: carryOverOptions }),
        [emailOnCompletion]: getColumnConfig(emailOnCompletion, { width: 180 }),
        [emailOnIssue]: getColumnConfig(emailOnIssue, { width: 180 }),
    };

    const advancedColumnsConfig = {
        [latitude]: getColumnConfig(latitude),
        [longitude]: getColumnConfig(longitude),
        [attachment]: getColumnConfig(attachment),
        [contactEmail]: getColumnConfig(contactEmail),
        [sortNumber]: getColumnConfig(sortNumber),
        [pickUp]: getColumnConfig(pickUp),
        [dropOff]: getColumnConfig(dropOff),
        [linkedForm]: getColumnConfig(linkedForm),
        [linkedZone]: getColumnConfig(linkedZone, { type: "autocomplete", source: zoneOptions }),
    };

    const defaultColumns = Object.values(defaultColumnsConfig);

    const getAdvancedColumnsOptions = (columns: Column[]) => {
        const columnFields = columns.map((column) => column.title);
        const advancedFields = Object.keys(advancedColumnsConfig);
        return advancedFields.filter((field) => !columnFields.includes(field));
    };

    return {
        defaultColumnsConfig,
        defaultColumns,
        getAdvancedColumnsOptions,
        advancedColumnsConfig
    } as UseImportConfig;
}