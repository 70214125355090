import { NotificationCircle } from 'baseui/badge';
import { StyleObject } from "styletron-react";

type Props = {
    content: React.ReactNode | number;
    color: string;
    width?: number;
    height?: number;
    style?: StyleObject | undefined;
};

export function StyledNotificationCircle({
                                             content,
                                             color,
                                             width = 20,
                                             height = 20,
                                             style = undefined,
                                         }: Props) {
    return (
        <NotificationCircle
            content={content}
            overrides={{
                Badge: {
                    style: {
                        width: `${width}px`,
                        height: `${height}px`,
                        backgroundColor: color,
                        ...style,
                    },
                },
            }}
        />
    );
}
