import { Button } from "baseui/button";
import { StyledCell, StyledRow } from "baseui/table";
import { LabelXSmall } from "baseui/typography";
import { useBoard } from "../../hooks/useBoard";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { useTheme } from "../../hooks/useTheme";
import { useTracksSelection } from "../../hooks/tracks/useTracksSelection";
import { useUnit } from "../../hooks/useUnit";
import { TrackOrStopEntry } from "../../types/track";
import { MemberBadge } from "../badges/MemberBadge";
import { HFlex } from "../containers/HFlex";
import { CheckboxCheckedIcon, CheckboxUncheckedIcon, LocateIcon } from "../ui/svg";
import React, { useRef } from "react";
import { useSetRecoilState } from "recoil";
import { editingJobIdState, editingPlaceIdState } from "../../states/appState";
import { useStrings } from "../../hooks/useStrings";
import { SpacerExpand } from "../containers/Spacer";
import { useCamera } from "../../hooks/useCamera";
import { useHover } from "usehooks-ts";
import { LocationCell } from "./LocationCell";
import { LocationRowDuration } from "./LocationRowDuration";
import { VFlex } from "../containers/VFlex";
import { useTrackColumns } from "../../hooks/tracks/useTrackColumns";
import {
    TRACKS_ACTIONS_COLUMN,
    TRACKS_APPROVAL_COLUMN,
    TRACKS_DATE_COLUMN,
    TRACKS_DISTANCE_COLUMN,
    TRACKS_DURATION_COLUMN,
    TRACKS_LOCATION_COLUMN,
    TRACKS_NAME_COLUMN,
    TRACKS_OPTIONS_COLUMN,
    TRACKS_SELECT_COLUMN,
    TRACKS_TRIP_PURPOSE_COLUMN
} from "../../constants/tracks";
import { TrackApprovalBar } from "./TrackApprovalBar";
import { TimeBadge } from "../badges/TimeBadge";
import { DateCell } from "./DateCell";
import { Tag } from "antd";
import { NoWrapText } from "../containers/NoWrapText";

type Props = {
    data: {
        onTrackInfo: (track?: TrackOrStopEntry) => void;
        entries: TrackOrStopEntry[]
    };
    index: number;
    style: React.CSSProperties;
}

export function LocationRow({ data, index, style }: Props) {
    const { strings } = useStrings();
    const track = data.entries[index];
    const { theme } = useTheme();
    const unit = useUnit();
    const { isSelected, tableMultiSelect, toggleSelected } = useTracksSelection();
    const { gridStyle, fixedColumnStyle } = useStyleOverrides();
    const multipleMembersSelected = useBoard().memberUids.length > 1;
    const setEditingJob = useSetRecoilState(editingJobIdState);
    const setEditingPlace = useSetRecoilState(editingPlaceIdState);
    const camera = useCamera();
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const { columnsWidth, columnsConfiguration, enabledColumns } = useTrackColumns();
    const { speedToString } = useUnit();

    if (!track) {
        return null;
    }

    const isStop = track.kind === "stop";
    const isTrack = track.kind === "track";
    const isWaypoint = track.kind == "waypoint";

    const onLocateClick = () => {
        camera.zoomWithTempMarker({ lat: track.startLat, lng: track.startLng }, track.title);
    };

    const onJobClick = () => {
        setEditingJob(track.job)
    };

    const onPlaceClick = () => {
        setEditingPlace(track.place)
    }

    const ellipsisStyle: any = {
        fontSize: "10px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        lineHeight: "13px",
        textOverflow: "ellipsis"
    };


    const onRowClick = () => {
        if (isWaypoint) {
            onLocateClick();
        }
    }

    return (
        <StyledRow ref={hoverRef}
                   role="row"
                   style={{ ...style, ...gridStyle(isHover || isSelected(track.uid)) }}
                   onClick={onRowClick}
                   onDoubleClick={() => {isTrack && data.onTrackInfo(track)}}
        >
            <StyledCell $style={{
                ...(isWaypoint ? fixedColumnStyle(0) : fixedColumnStyle(columnsWidth[TRACKS_SELECT_COLUMN])),
                justifyContent: "center"
            }}>
                {!isWaypoint && <span
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        if (event.shiftKey && tableMultiSelect(track, data.entries)) {
                            return;
                        }
                        toggleSelected(track.uid);
                    }}
                >
                    <Button
                        size="mini"
                        kind="tertiary"
                    >
                        {isSelected(track.uid)
                            ? <CheckboxCheckedIcon size={18} color={theme.colors.contentPrimary}/>
                            : <CheckboxUncheckedIcon size={18} color={theme.colors.contentPrimary}/>
                        }
                    </Button>
                </span>}
            </StyledCell>
            {multipleMembersSelected && !isWaypoint && (
                <StyledCell $style={columnsConfiguration[TRACKS_NAME_COLUMN].styles}>
                    <MemberBadge uid={track.account} ellipsed edit/>
                </StyledCell>
            )}
            {isWaypoint && (<>
                <StyledCell $style={fixedColumnStyle(120)}>
                    <TimeBadge startMillis={track.startTs} withDate/>
                </StyledCell>
                <StyledCell $style={fixedColumnStyle(90)}>
                    <Tag color={(track.color === "#FFFFFF" ? "default" : track.color) || "default"}>
                        {speedToString(track.waypoint?.speed)}
                    </Tag>
                </StyledCell>
                {multipleMembersSelected &&
                    <StyledCell $style={fixedColumnStyle(120, true)}>
                        <LabelXSmall>{track.title}</LabelXSmall>
                    </StyledCell>
                }
            </>)}
            {!isWaypoint && <>
                {enabledColumns.map((column) => {
                    return (
                        <StyledCell $style={columnsConfiguration[column].styles} key={column}>
                            {column === TRACKS_DATE_COLUMN && <DateCell track={track}/>}
                            {column === TRACKS_LOCATION_COLUMN && <LocationCell entry={track}/>}
                            {column === TRACKS_DURATION_COLUMN && <LocationRowDuration entry={track}/>}
                            {column === TRACKS_DISTANCE_COLUMN && (
                                track.distance > 0 && unit.distanceToString(track.distance)
                            )}
                            {column === TRACKS_TRIP_PURPOSE_COLUMN && (
                                <VFlex>
                                    {track.purpose &&
                                        <LabelXSmall style={ellipsisStyle}>
                                            {track.purpose}
                                        </LabelXSmall>
                                    }
                                    {track.comment &&
                                        <LabelXSmall style={ellipsisStyle}>
                                            {track.comment}
                                        </LabelXSmall>
                                    }
                                </VFlex>
                            )}
                            {column == TRACKS_APPROVAL_COLUMN && isTrack && <TrackApprovalBar track={track}/>}
                            {column === TRACKS_ACTIONS_COLUMN && (
                                <>
                                    <HFlex alignCenter>
                                        <Button size={"mini"}
                                                kind={"tertiary"}
                                                onClick={() => onLocateClick()}>
                                            <LocateIcon size={12} color={theme.colors.contentPrimary}/>
                                        </Button>
                                        {isTrack &&
                                            <Button
                                                size="mini"
                                                kind="secondary"
                                                onClick={() => data.onTrackInfo(track)}
                                            >
                                                <NoWrapText>{strings.General.Open}</NoWrapText>
                                            </Button>
                                        }
                                        {isStop && track.at === "place" &&
                                            <Button size={"mini"}
                                                    kind={"secondary"}
                                                    onClick={onPlaceClick}>
                                                <NoWrapText>{strings.General.StopAtPlace}</NoWrapText>
                                            </Button>
                                        }
                                        {isStop && track.at === "job" &&
                                            <Button size={"mini"}
                                                    kind={"secondary"}
                                                    onClick={onJobClick}>
                                                <NoWrapText>{strings.General.StopAtJob}</NoWrapText>
                                            </Button>
                                        }
                                        {isStop && track.at === "between_tracks" &&
                                            <Tag>{strings.General.StopBetweenTracks}</Tag>
                                        }
                                    </HFlex>
                                    <SpacerExpand/>
                                </>
                            )}
                        </StyledCell>
                    )
                })}
                <StyledCell $style={{ ...fixedColumnStyle(columnsWidth[TRACKS_OPTIONS_COLUMN]) }}/>
            </>
            }
        </StyledRow>
    );
}