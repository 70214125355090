import { Button } from 'baseui/button';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getLabels, getTitle, isDone, isHighPriority, isLowPriority, Job } from '../../types/job';
import { getColorFromProgress, imagesFromJob, numberOrLetter } from '../../utils/jobUtils';
import { HFlex } from '../containers/HFlex';
import { Spacer } from '../containers/Spacer';
import {
    BookmarkFillIcon,
    CheckboxCheckedIcon,
    CheckboxUncheckedIcon,
    ClockMiniIcon,
    DragHandleIcon,
    HighPriorityIcon,
    InfoIcon,
    LocateIcon,
    LowPriorityIcon,
    PinFilledIcon,
    PinIcon,
} from '../ui/svg';
import React, { useRef } from "react";
import { useSingleJobSelection } from "../../hooks/jobs/useSingleJobSelection";
import { useHover } from "usehooks-ts";
import { useCamera } from "../../hooks/useCamera";
import { jobIdsPinnedState, jobsByMemberDayState, jobState } from "../../states/jobsState";
import { useAllJobsSelection } from "../../hooks/jobs/useAllJobsSelection";
import { useTheme } from "../../hooks/useTheme";
import { Avatar } from 'antd';
import { hasTimeWindow } from "../../utils/timeWindow";
import { useTime } from "../../hooks/useTime";
import { createTimeStringFromInt } from "../../utils/time";
import { NoWrapText } from "../containers/NoWrapText";
import { JobTooltip } from "../job/JobTooltip";
import { VFlex } from "../containers/VFlex";
import { LabelXSmall } from "baseui/typography";
import { EllipsedText } from "../ui/EllipsedText";
import { JobLabelBadge } from "../badges/JobLabelBadge";
import { PlaceBadge } from "../badges/PlaceBadge";
import { ZonesBadges } from "../badges/ZonesBadges";
import { JobTypeBadge } from "../badges/JobTypeBadge";
import { TeamBadge } from "../badges/TeamBadge";
import { useZones } from "../../hooks/zones/useZones";
import { editingJobIdState } from "../../states/appState";
import { Clickable } from "../containers/Clickable";
import { COLOR_JOB_NEEDLE } from "../../constants/colors";
import { EtaBadge } from "../badges/EtaBadge";
import { EMPTY_DAY, EMPTY_UID } from "../../types/core";

type Props = {
    id: string;
    spacing?: boolean;
    onSelected?: (job: Job) => void;
    color?: string;
    showCheckmark?: boolean;
    showHandle?: boolean;
    pinnable?: boolean;
};

function JobItem({
                     id,
                     spacing = true,
                     onSelected,
                     color,
                     showCheckmark = true,
                     showHandle = true,
                     pinnable = true
                 }: Props) {
    const job = useRecoilValue(jobState(id));
    const { theme, css } = useTheme();
    const { isSelected, toggleSelected } = useSingleJobSelection(job?.id || "");
    const [pinned, setPinned] = useRecoilState(jobIdsPinnedState);
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const camera = useCamera();
    const jobs = useRecoilValue(jobsByMemberDayState({ uid: job?.receiverUid || EMPTY_UID, day: job?.day || EMPTY_DAY }));
    const { multiSelect } = useAllJobsSelection();
    const selection = useAllJobsSelection();
    const time = useTime();
    const { getZonesFor } = useZones();
    const setEditingJob = useSetRecoilState(editingJobIdState);

    if (!job) {
        return null;
    }

    const colorPrimary = "#FFFFFF";
    const colorSecondary = "#F4F4F4";
    const uploads = imagesFromJob(job);
    const progressColor = color || getColorFromProgress(job);
    const styles = {
        borderRadius: "28px",
        borderWidth: "1px",
        borderColor: COLOR_JOB_NEEDLE,
        borderStyle: "solid",
        marginTop: spacing ? "3px" : "0px",
        marginBottom: spacing ? "3px" : "0px",
        backgroundColor: progressColor,
        overflow: "hidden",
        paddingRight: theme.sizing.scale300,
        paddingLeft: theme.sizing.scale300,
        minHeight: "40px",
    }

    const isPinned = pinned.includes(job.id);

    const onPinClick = () => {
        setPinned(isPinned
            ? [...pinned.filter(id => id !== job.id)]
            : [...pinned, job.id]
        )
    }

    const onLocateClick = () => {
        camera.zoomTo({ lat: job.destinationLat, lng: job.destinationLng });
    }

    const checkmark = (showCheckmark &&
        <div onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            if (event.shiftKey && multiSelect(job, jobs)) {
                return
            }
            toggleSelected();
        }}
             className={css({
                 cursor: "pointer",
                 width: "18px",
                 height: "18px",
                 flexShrink: 0,
             })}>
            {(isHover || (selection.selectionCount > 0))
                && !job.isTemplate
                && !onSelected
                && (isSelected
                        ? <CheckboxCheckedIcon size={18} color={theme.colors.contentPrimary}/>
                        : <CheckboxUncheckedIcon size={18} color={theme.colors.contentPrimary}/>
                )}
        </div>
    );


    const zonesWithin = getZonesFor(job.destinationLat, job.destinationLng, 3);

    const destinationText = <EllipsedText
        inline
        breakAll
        style={{
            fontSize: "11px",
        }}
    >
        {job.destinationText}
    </EllipsedText>;

    const badgeStyle = { fontSize: "9px", lineHeight: "12px" };
    const labels = getLabels(job);

    const onEditClick = () => {
        if (onSelected) {
            onSelected(job);
        } else {
            setEditingJob(job.id);
        }
    }

    return (
        <div ref={hoverRef}
             className={css({ position: "relative", marginTop: "1px" })}
             onClick={onEditClick}
        >
            {job.orderId > 0 &&
                <div className={css({
                    fontSize: "8px",
                    position: "absolute",
                    right: "32px",
                    top: "100%",
                    transform: "translate(0, -100%)",
                    lineHeight: "16px",
                    pointerEvents: "none",
                    color: colorSecondary,
                })}>
                    {"#" + job.orderId}
                </div>
            }
            {hasTimeWindow(job.windowStart, job.windowEnd) &&
                <div className={css({
                    fontSize: "8px",
                    position: "absolute",
                    right: "32px",
                    top: 0,
                    lineHeight: "16px",
                    pointerEvents: "none",
                    color: colorPrimary
                })}>
                    <NoWrapText>
                        <ClockMiniIcon size={7} color={colorPrimary}/>
                        {createTimeStringFromInt(time.isAmPm(), job.windowStart)}
                        -
                        {createTimeStringFromInt(time.isAmPm(), job.windowEnd)}
                    </NoWrapText>
                </div>
            }
            <HFlex alignCenter style={{
                columnGap: theme.sizing.scale300,
                paddingRight: theme.sizing.scale300,
                boxSizing: "border-box",
            }}>
                {checkmark}
                {!checkmark && <Spacer width={"0px"}/>}
                <HFlex spacing alignCenter style={{
                    justifyContent: "flex-start",
                    boxSizing: "border-box",
                    ...styles,
                }}>
                    {job.isTemplate && <BookmarkFillIcon size={16} color={colorSecondary}/>}
                    {showHandle && !job.isTemplate && !onSelected &&
                        <DragHandleIcon size={16} color={colorSecondary}/>
                    }

                    <JobTooltip job={job}>
                        <HFlex alignCenter spacing={"6px"}>
                            <LabelXSmall color={colorPrimary}>{numberOrLetter(job)}</LabelXSmall>
                            <InfoIcon size={10} color={colorPrimary}/>
                        </HFlex>
                    </JobTooltip>
                    <VFlex style={{ flex: 1 }}>
                        <EllipsedText
                            inline
                            breakAll
                            style={{
                                fontWeight: "bold",
                                fontSize: "11px",
                                lineHeight: "12px",
                                color: colorPrimary,
                            }}
                        >
                            {getTitle(job)}
                        </EllipsedText>
                        {(job.placeUid || zonesWithin.length > 0 || job.type || job.teamId || job.labels || job.tripId) ?
                            <HFlex alignCenter>
                                {labels && labels.map(l => <JobLabelBadge label={l}/>)}
                                {labels && <Spacer width={"4px"}/>}
                                {job.tripId && !isDone(job) && <EtaBadge tripId={job.tripId}/>}
                                {job.placeUid && <PlaceBadge uid={job.placeUid} style={badgeStyle}/>}
                                <ZonesBadges uids={zonesWithin.map(z => z.uid)} max={3} badgeStyle={badgeStyle}/>
                                {job.type > 0 && <JobTypeBadge type={job.type} style={badgeStyle}/>}
                                {job.teamId > 0 && <TeamBadge id={job.teamId} style={badgeStyle}/>}
                                {job.type === 0 && job.teamId === 0 && (
                                    <LabelXSmall color={colorSecondary}>{destinationText}</LabelXSmall>
                                )}
                            </HFlex>
                            :
                            job.destinationText ? <LabelXSmall color={colorSecondary} style={{
                                fontSize: "11px",
                                lineHeight: "12px",
                                color: colorSecondary,
                            }}>{destinationText}</LabelXSmall> : undefined
                        }
                    </VFlex>

                    {uploads.length > 0 &&
                        <Avatar.Group maxCount={2}>
                            {uploads.map(src => <Avatar key={src} src={src} size="small" style={{
                                borderWidth: "1px",
                                borderLeftStyle: "solid",
                                borderRightStyle: "solid",
                                borderTopStyle: "solid",
                                borderBottomStyle: "solid",
                                borderColor: theme.colors.contentTertiary
                            }}/>)}
                        </Avatar.Group>
                    }
                    <HFlex style={{ width: "20px", flexShrink: 0, justifyContent: "center" }} alignCenter>
                        {!isHover &&
                            <>
                                {isHighPriority(job) && <HighPriorityIcon size={14}/>}
                                {isLowPriority(job) && <LowPriorityIcon size={14}/>}
                            </>
                        }
                        {isHover &&
                            <JobTooltip job={job}>
                                <Button
                                    size="mini"
                                    kind="tertiary"
                                    shape="round"
                                    overrides={{
                                        BaseButton: {
                                            style: {
                                                ":hover": {
                                                    backgroundColor: "#00000033",
                                                }
                                            }
                                        }
                                    }}
                                    onClick={(ev) => {
                                        ev.stopPropagation();
                                        onLocateClick();
                                    }}>
                                    <LocateIcon
                                        size={12}
                                        color={colorPrimary}/>
                                </Button>
                            </JobTooltip>
                        }
                    </HFlex>
                </HFlex>
                {pinnable && isPinned &&
                    <Clickable onClick={onPinClick}>
                        <PinFilledIcon color={theme.colors.contentPrimary} size={12}/>
                    </Clickable>
                }
                {pinnable && !isPinned && isHover &&
                    <Clickable onClick={onPinClick}>
                        <PinIcon color={theme.colors.contentPrimary} size={12}/>
                    </Clickable>
                }
                {pinnable && !isPinned && !isHover && <Spacer width="12px"/>}
            </HFlex>
        </div>
    );
}

export default React.memo(JobItem)