import { Section } from "../../ui/Section";
import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { editingJobIdState } from "../../../states/appState";
import { editJobState } from "../../../states/editJobState";
import { useStrings } from "../../../hooks/useStrings";
import { useTheme } from "../../../hooks/useTheme";
import { jobCompactVisualizationState } from "../../../states/optionsState";
import { Job } from "../../../types/job";
import { MessageBubble } from "../../salesai/MessageBubble";
import { Badge, Popconfirm } from "antd";
import { TimeBadge } from "../../badges/TimeBadge";
import { Button } from "baseui/button";
import { PlusIcon, RemoveIcon } from "../../ui/svg";
import { HFlex } from "../../containers/HFlex";
import useAccount from "../../../hooks/useAccount";
import { Spacer, SpacerExpand } from "../../containers/Spacer";
import { VFlex } from "../../containers/VFlex";
import { MemberBadge } from "../../badges/MemberBadge";
import { Textarea } from "baseui/textarea";
import { Comment, Timestamp } from "../../../types/core";

export function CommentsSection() {
    const jobId = useRecoilValue(editingJobIdState);
    const [fields, setFields] = useRecoilState(editJobState(jobId || ''));
    const { strings } = useStrings();
    const { theme } = useTheme();
    const compact = useRecoilValue(jobCompactVisualizationState);
    const [addingComment, setAddingComment] = useState(false);
    const [input, setInput] = useState("");
    const { uid } = useAccount();

    if (!fields) {
        return null;
    }

    let comments: Comment[];
    try {
        comments = JSON.parse(fields.comments || "[]") as Comment[];
    } catch (error) {
        console.error("Failed to parse comments:", error);
        comments = [];
    }

    const onSendClick = () => {
        const newComment: Comment = {
            ts: Date.now() as Timestamp,
            text: input,
            uid,
        };
        const stringifiedComments = JSON.stringify([...comments, newComment]);
        setFields((prev) => ({ ...prev, comments: stringifiedComments } as Job));
        setInput("");
        setAddingComment(false);
    };

    const onDeleteClick = (comment: Comment) => {
        const stringifiedComments = JSON.stringify([...comments.filter(c => c !== comment)]);
        setFields((prev) => ({ ...prev, comments: stringifiedComments } as Job));
    };

    return (
        <Section title={strings.Job.Comments}
                 expandable={compact}
                 collapsedNode={comments.length > 0 && <Badge count={comments.length} color={"blue"}/>}
        >
            {comments.map((comment, index) => (
                <MessageBubble key={index} style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                }}>
                    <VFlex spacing>
                        <HFlex spacing>
                            <div style={{ whiteSpace: "pre-wrap" }}>{comment.text}</div>
                            <SpacerExpand/>
                            {comment.uid === uid && (
                                <Popconfirm title={strings.General.ConfirmToProceed}
                                            onConfirm={() => onDeleteClick(comment)}
                                            cancelText={strings.General.Cancel}
                                            okType="danger"
                                            okText={strings.General.Delete}>
                                    <Button
                                        kind={"tertiary"}
                                    >
                                        <RemoveIcon color={theme.colors.contentPrimary} size={14}/>
                                    </Button>
                                </Popconfirm>
                            )}
                        </HFlex>
                        <HFlex spacing alignCenter>
                            <MemberBadge uid={comment.uid}/>
                            <SpacerExpand/>
                            <TimeBadge withDate startMillis={comment.ts}/>
                        </HFlex>
                    </VFlex>
                </MessageBubble>
            ))}
            {!addingComment &&
                <Button kind={"secondary"}
                        size={"mini"}
                        onClick={() => setAddingComment(true)}>
                    <PlusIcon size={12} color={theme.colors.contentPrimary}/>
                    <Spacer/>
                    {strings.Job.AddComment}
                </Button>
            }
            {addingComment &&
                <HFlex spacing style={{ marginBottom: "8px" }}>
                    <Textarea
                        placeholder={strings.Job.EnterComment}
                        value={input}
                        onChange={(event) => setInput(event.target.value)}
                        rows={2}
                    />
                    <Button size={"mini"}
                            disabled={input.length === 0}
                            onClick={onSendClick}>
                        {strings.General.OK}
                    </Button>
                </HFlex>
            }
        </Section>
    );
}