import useAccount from "../hooks/useAccount";
import { Button } from "baseui/button";
import { useSetRecoilState } from "recoil";
import { companyTabFilterState, editingCompanyIdState, editingMemberIdState } from "../states/appState";
import { VFlex } from "../components/containers/VFlex";
import useDirector from "../hooks/useDirector";
import { CompanyIcon, EditUserIcon } from "../components/ui/svg";
import { useTheme } from "../hooks/useTheme";
import { Spacer, SpacerExpand } from "../components/containers/Spacer";
import { useStrings } from "../hooks/useStrings";
import { InviteCodeSection } from "../components/team/InviteCodeSection";
import { HFlex } from "../components/containers/HFlex";
import { ParagraphMedium } from "baseui/typography";
import { useOnboardRefs } from "../hooks/onboard/useOnboardRefs";
import { StoreButtons } from "../components/shared/AppButtons";
import {
    getBrowserDistanceFormat,
    getBrowserLanguage,
    getBrowserTimeFormat,
    getBrowserTimezone
} from "../utils/browser";
import { MismatchAlert } from "../components/settings/MismatchAlert";

export function SettingsView() {
    const { strings } = useStrings();
    const { theme, css } = useTheme();
    const director = useDirector();
    const account = useAccount();
    const setEditingMember = useSetRecoilState(editingMemberIdState);
    const setEditingCompany = useSetRecoilState(editingCompanyIdState);
    const setCompanyTabFilter = useSetRecoilState(companyTabFilterState);
    const { downloadAppRef } = useOnboardRefs();

    const company = account.company;

    return (
        <div className={css({
            overflowY: "auto",
            paddingLeft: "32px",
            paddingRight: "32px",
        })}>
            <VFlex style={{ rowGap: "8px", height: "auto" }}>
                {account.company?.uid !== account.uid &&
                    <Button onClick={() => setEditingMember(account.uid)}>
                        <EditUserIcon color={theme.colors.contentInversePrimary} size={16}/>
                        <Spacer/> {strings.General.MySettings}
                    </Button>
                }
                {company && account.isSelfAdminOrCompany &&
                    <Button onClick={() => setEditingCompany(company.uid)}>
                        <CompanyIcon color={theme.colors.contentInversePrimary} size={16}/>
                        <Spacer/> {strings.General.CompanySettings}
                    </Button>
                }
                {company && account.isSelfAdminOrCompany &&
                    <Button onClick={() => {
                        setCompanyTabFilter("billing");
                        setEditingCompany(company.uid);
                    }}>
                        {strings.General.Billing}
                    </Button>
                }
                {
                    <Button kind={"tertiary"} onClick={() => director.logout()}>
                        {strings.General.SignOut}
                    </Button>
                }

                {!account.timezoneMatchesBrowser && <MismatchAlert
                        title={strings.Settings.TimezoneMismatchDesc}
                        keepText={strings.Settings.TimezoneMismatchKeepUser + " " + account.timezone}
                        updateText={strings.Settings.TimezoneMismatchUseBrowser}
                        browserValue={getBrowserTimezone()}
                        field={"timezone"}/>}

                {!account.languageMatchesBrowser && <MismatchAlert
                    title={strings.Settings.LanguageMismatchDesc}
                    keepText={strings.Settings.LanguageMismatchKeepUser + " " + account.language}
                    updateText={strings.Settings.LanguageMismatchUseBrowser}
                    browserValue={getBrowserLanguage()}
                    field={"language"}/>}

                {!account.timeFormatMatchesBrowser && <MismatchAlert
                    title={strings.Settings.TimeFormatMismatchDesc}
                    keepText={strings.Settings.TimeFormatMismatchKeepUser + " " + account.timeFormat}
                    updateText={strings.Settings.TimeFormatMismatchUseBrowser}
                    browserValue={getBrowserTimeFormat()}
                    field={"unit_time"}/>}

                {!account.distanceFormatMatchesBrowser && <MismatchAlert
                    title={strings.Settings.DistanceFormatMismatchDesc}
                    keepText={strings.Settings.DistanceFormatMismatchKeepUser + " " + account.distanceFormat}
                    updateText={strings.Settings.DistanceFormatMismatchUseBrowser}
                    browserValue={getBrowserDistanceFormat()}
                    field={"unit_distance"}/>}

                <Spacer height={"48px"}/>

                <ParagraphMedium>{strings.General.DownloadAppDescription}</ParagraphMedium>

                <div ref={downloadAppRef}>
                    <HFlex spacing>
                        <SpacerExpand/>
                        <StoreButtons/>
                        <SpacerExpand/>
                    </HFlex>
                </div>

                <Spacer height={"48px"}/>

                <InviteCodeSection/>

                <Spacer height={"48px"}/>
            </VFlex>
        </div>
    );
}