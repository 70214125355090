import { SelectedDays } from "../types/days";

export const MAX_MINUTES = 24 * 60;

export enum DAYS {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday
}

export const DAY_STRING_KEYS = { // TODO Manuel, avoid strings as keys for localization
    monday: {
        short: 'General.WeekdayMo',
        full: 'General.Monday',
    },
    tuesday: {
        short: 'General.WeekdayTu',
        full: 'General.Tuesday',
    },
    wednesday: {
        short: 'General.WeekdayWe',
        full: 'General.Wednesday',
    },
    thursday: {
        short: 'General.WeekdayTh',
        full: 'General.Thursday',
    },
    friday: {
        short: 'General.WeekdayFr',
        full: 'General.Friday',
    },
    saturday: {
        short: 'General.WeekdaySa',
        full: 'General.Saturday',
    },
    sunday: {
        short: 'General.WeekdaySu',
        full: 'General.Sunday',
    },
};

export const SELECTED_DAYS_NONE = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
} as SelectedDays;