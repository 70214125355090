import useAccount from "../../hooks/useAccount";
import { ConfirmModal } from "./ConfirmModal";
import { useStrings } from "../../hooks/useStrings";
import { useCompanyActivation } from "../../hooks/company/useCompanyActivation";
import useDirector from "../../hooks/useDirector";
import { analyticsEvent } from "../../utils/analytics";

export function ReactivationModal() {
    const account = useAccount();
    const { strings } = useStrings();
    const activation = useCompanyActivation();
    const director = useDirector();

    const onReactivateClick = async () => {
        analyticsEvent("reactivation_reactivate");
        await activation.activate();
    }

    const onLogout = () => {
        analyticsEvent("reactivation_logout");
        director.logout();
    }

    if (account.uid && account.company && account.company.activated_ts === 0) {
        return (
            <ConfirmModal
                title={strings.Billing.Inactive}
                description={strings.Billing.ReactivateDesc}
                isOpen={true}
                cancelText={strings.General.SignOut}
                onCancel={onLogout}
                confirmText={strings.Billing.Reactivate}
                onConfirm={onReactivateClick}
            />
        );
    } else {
        return null;
    }
}