import { SIZE as SELECT_SIZE } from "baseui/input/constants";
import { OnChangeParams, TYPE } from "baseui/select";
import { useRecoilValue } from "recoil";
import { useStrings } from "../../hooks/useStrings";
import { teamOptionsState } from "../../states/optionsState";
import { StyledSelect } from "../ui/StyledSelect";

export function JobTeamSelect({
                                  teamId = 0,
                                  onTeamChange
                              }: { teamId?: number; onTeamChange: (params: OnChangeParams) => unknown }) {
    const { strings } = useStrings();
    const teamOptions = useRecoilValue(teamOptionsState);
    const value = teamId > 0 ? [{ label: '', id: teamId }] : []

    return (
        <StyledSelect
            closeOnSelect
            size={SELECT_SIZE.compact}
            clearable={true}
            placeholder={strings.General.Team}
            options={teamOptions}
            value={value}
            openOnClick
            type={TYPE.select}
            onChange={onTeamChange}
        />
    );
}