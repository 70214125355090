import { useStyletron } from 'baseui';

type Props = {
    children: React.ReactNode;
};

export default function SkeletonBody({ children }: Props) {
    const [css] = useStyletron();

    const bodyStyles = css({
        background: 'grey',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        width: "100%",  
    });

    return <div className={bodyStyles}>{children}</div>;
}
