import { ChevronDown, ChevronUp } from "baseui/icon";
import { SORT_DIRECTION, SortableHeadCell, StyledSortableLabel } from "baseui/table";
import { StyleObject } from "styletron-react";
import { tableHeaderStyle } from "../../constants/ui";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { useTheme } from "../../hooks/useTheme";
import { HFlex } from "../containers/HFlex";
import { MouseEvent } from "react";

export function StyledSortableHeadCell({
                                           size,
                                           styles,
                                           alignment = "start",
                                           ...props

                                       }: React.ComponentProps<typeof SortableHeadCell> & { styles?: StyleObject; size?: number; alignment?: "start" | "center", onResize?: (width: number) => void; initialWidth?: number; minWidth?: number; }) {
    const { theme } = useTheme();
    const { tableHeaderLabelStyle } = useStyleOverrides();

    const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
        const startX = event.clientX;
        const startWidth = props.initialWidth ?? 100;

        const handleMouseMove = (moveEvent: MouseEvent) => {
            const newWidth = startWidth + (moveEvent.clientX - startX);
            props.onResize && props.onResize(Math.max(newWidth, props.minWidth ?? 100));
        };

        const handleMouseUp = () => {
            window.removeEventListener("mousemove", handleMouseMove as any);
            window.removeEventListener("mouseup", handleMouseUp);
        };

        window.addEventListener("mousemove", handleMouseMove as any);
        window.addEventListener("mouseup", handleMouseUp);
    };

    return (
        <SortableHeadCell
            {...props}
            fillClickTarget
            overrides={{
                HeadCell: {
                    style: {
                        ...tableHeaderStyle,
                        flex: size ?? 0,
                        ...styles,
                    }
                },
                SortableLabel: {
                    component: () => (
                        <HFlex alignCenter style={{
                            justifyContent: alignment === "start" ? "space-between" : "center",
                        }}>
                            <StyledSortableLabel style={tableHeaderLabelStyle}>
                                {props.title}
                            </StyledSortableLabel>
                            <HFlex alignCenter style={{ flex: 0 }}>
                                {props.direction && (
                                    <span style={{ lineHeight: 0 }}>
                                        {props.direction === SORT_DIRECTION.ASC && (<ChevronUp color={theme.colors.contentTertiary} />)}
                                        {props.direction === SORT_DIRECTION.DESC && (<ChevronDown color={theme.colors.contentTertiary} />)}
                                    </span>
                                )}
                                {props.onResize && (
                                    <div
                                        style={{
                                            color: theme.customColors.contentLight,
                                            cursor: props.initialWidth === props.minWidth ? "e-resize" : "col-resize",
                                            padding: "0 5px 0 5px",
                                            marginRight: "-16px"
                                        }}
                                        onMouseDown={(e) => handleMouseDown(e)}
                                    >
                                        ⋮
                                    </div>
                                )}
                            </HFlex>
                        </HFlex>
                    )
                },
            }}
        />
    );
}
