import { memo } from "react";
import { Marker } from "react-map-gl";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useEffectOnce, useTimeout } from "usehooks-ts";
import { tempLocationState } from "../../../states/tempLocationState";
import { FixedMarker } from "baseui/map-marker";
import { HFlex } from "../../containers/HFlex";
import { ActionButton } from "../../buttons/ActionButton";
import { useStrings } from "../../../hooks/useStrings";
import { useNewJob } from "../../../hooks/jobs/useNewJob";
import { useNewPlace } from "../../../hooks/places/useNewPlace";
import { DetailedLocation } from "../../../types/core";
import { AddJobIcon, PlaceOutlineIcon } from "../../ui/svg";
import { useTheme } from "../../../hooks/useTheme";
import { Spacer } from "../../containers/Spacer";
import { useGeocode } from "../../../hooks/useGeocode";

function TempMarker() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const { geolocate } = useGeocode();
    const [location, setLocation] = useRecoilState(tempLocationState);
    const resetLocation = useResetRecoilState(tempLocationState);
    const { createNewJob } = useNewJob();
    const { createNewPlace } = useNewPlace();

    const asDetailedLocation = location as DetailedLocation;
    const text = asDetailedLocation.name
        ? asDetailedLocation.name
        : asDetailedLocation.address ? asDetailedLocation.address : "...";

    useEffectOnce(() => {
        if (location && !location?.name) {
            geolocate(location.lat, location.lng)
                .then(newLocation => {
                    if (location && newLocation) {
                        setLocation(newLocation);
                    }
                })
        }
    });

    useTimeout(() => {
        resetLocation();
    }, 6000);

    if (!location) {
        return null;
    }

    return (
        <Marker
            longitude={location.lng ?? 0}
            latitude={location.lat ?? 0}
            anchor="bottom"
            draggable={false}
        >
            <FixedMarker label={text} endEnhancer={() =>
                <HFlex>
                    <ActionButton tooltip={strings.Job.NewJob}
                                  size={"mini"}
                                  kind={"secondary"}
                                  shape={"circle"}
                                  onClick={() => createNewJob()}
                    >
                        <AddJobIcon size={14} color={theme.colors.contentPrimary}/>
                    </ActionButton>
                    <Spacer width={"4px"}/>
                    <ActionButton tooltip={strings.Places.NewPlace}
                                  size={"mini"}
                                  kind={"secondary"}
                                  shape={"circle"}
                                  onClick={() => {
                                      createNewPlace(asDetailedLocation)
                                  }}
                    >
                        <PlaceOutlineIcon size={14} color={theme.colors.contentPrimary}/>
                    </ActionButton>
                </HFlex>}
            />
        </Marker>
    );
}

export default memo(TempMarker);