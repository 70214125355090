import { StyledNotificationCircle } from "../ui/StyledNotificationCircle";
import { BULLET_SIZE } from "../../constants/timeline";
import { useTheme } from "../../hooks/useTheme";
import { COLOR_STEELGRAY } from "../../constants/colors";

type Props = {
    content: string;
    size?: number;
}

export function Bullet({ content, size = BULLET_SIZE }: Props) {
    const { theme } = useTheme();

    return (
        <StyledNotificationCircle
            color={theme.colors.backgroundAlwaysDark}
            content={<span>{content}</span>}
            width={size}
            height={size}
            style={{
                borderRadius: "100%",
                borderBottomWidth: "1px",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderTopWidth: "1px",
                borderLeftStyle: "solid",
                borderRightStyle: "solid",
                borderTopStyle: "solid",
                borderBottomStyle: "solid",
                borderColor: COLOR_STEELGRAY
            }}
        />
    )
}