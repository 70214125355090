import React, { ReactNode } from "react";
import { Card } from "baseui/card";
import { VFlex } from "../containers/VFlex";
import { useTheme } from "../../hooks/useTheme";
import { useRecoilValue } from "recoil";
import { boardColumnWidthState, boardMemberUidsState, boardVisibleSequencesState } from "../../states/boardState";
import { dispatchTreeActiveCardState } from "../../states/viewState";
import { COLOR_TRANSLUCENT_LIGHT } from "../../constants/colors";

type Props = {
    color: string;
    title: ReactNode;
    sticky?: ReactNode;
    width?: number;
    borderless?: boolean,
    children: ReactNode;
}

export function ColumnCard({
                               color,
                               title,
                               sticky,
                               width,
                               children,
                               borderless = false,
                           }: Props) {
    const { css } = useTheme();
    const columnWidth = useRecoilValue(boardColumnWidthState);
    const countMembers = useRecoilValue(boardMemberUidsState).length;
    const countSequences = useRecoilValue(boardVisibleSequencesState).length;
    const dispatchCard = useRecoilValue(dispatchTreeActiveCardState);
    const count = countMembers + countSequences + (!!dispatchCard ? 1 : 0);

    const margins = borderless ? {
        marginLeft: "4px",
        marginRight: 0,
        marginTop: "4px",
        marginBottom: 0,
    } : {};

    return (
        <Card
            title={title}
            overrides={
                {
                    Root: {
                        style: {
                            backgroundColor: count === 1 ? COLOR_TRANSLUCENT_LIGHT : color + 32,
                            minWidth: count === 1 ? "100%" : (width || columnWidth) + "px",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            flex: 0,
                            height: "100%",
                        },
                    },
                    Title: {
                        style: {
                            marginBottom: "4px"
                        }
                    },
                    Body: {
                        style: {
                            display: "flex",
                            flex: 1,
                            marginBottom: 0,
                            overflow: "hidden",
                        }
                    },
                    Contents: {
                        style: {
                            overflow: "hidden",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            ...margins,
                        }
                    }
                }
            }
        >
            <VFlex style={{
                width: "100%",
            }}>
                {sticky}
                <div className={css({
                    overflow: "auto",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                })}>
                    {children}
                </div>
            </VFlex>
        </Card>
    );
}