import { useRecoilValue } from "recoil";
import { memberState } from "../../states/membersState";
import { Tag } from "antd";
import { useMemberInfo } from "../../hooks/members/useMemberInfo";
import { tinyTag } from "../../constants/ui";
import { memo } from "react";
import { Uid } from "../../types/core";

type Props = {
    uid: Uid;
    showWorker: boolean;
    tiny?: boolean;
    shortName?: boolean;
}

export const RoleBadge = memo(({ uid, showWorker = true, tiny = false, shortName = false }: Props) => {
    const member = useRecoilValue(memberState(uid));
    const role = member && member.role || "";
    const info = useMemberInfo();
    if (!member) {
        return null;
    }
    const roleText = info.getRole(role, shortName);
    if (!showWorker && role === "worker") {
        return null;
    }
    const color = role === "admin" ? "volcano" : role === "operator" ? "blue" : undefined;
    return (
        <Tag style={tiny ? tinyTag : undefined} color={color}>{roleText}</Tag>
    );
});