import { Button } from "baseui/button";
import { StyledHead, StyledHeadCell } from "baseui/table";
import { tableHeaderStyle } from "../../constants/ui";
import { useStrings } from "../../hooks/useStrings";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { useZoneSelection } from "../../hooks/zones/useZoneSelection";
import { useZonesSort } from "../../hooks/zones/useZonesSort";
import { StyledSortableHeadCell } from "../ui/StyledSortableHeadCell";
import { TristateCheckbox } from "../ui/TristateCheckbox";
import { ZoneSort } from "../../states/zoneState";

export function ZoneHeaderRow() {
    const { strings } = useStrings();
    const { allSelected, toggleAllSelected, selectionCount } = useZoneSelection();
    const { handleSort, getDirection } = useZonesSort();
    const { zonesColumnStyle } = useStyleOverrides();

    return (
        <StyledHead role="row">
            <StyledHeadCell $style={{ ...zonesColumnStyle[0], ...tableHeaderStyle, justifyContent: "center" }}>
                <Button
                    size="mini"
                    kind="tertiary"
                    onClick={(event) => toggleAllSelected()}
                >
                    <TristateCheckbox allSelected={allSelected} anySelected={selectionCount > 0}/>
                </Button>
            </StyledHeadCell>
            <StyledSortableHeadCell title={strings.General.Color} direction={getDirection(ZoneSort.COLOR)} styles={zonesColumnStyle[1]}
                                    onSort={() => handleSort(ZoneSort.COLOR)} alignment="center"/>
            <StyledSortableHeadCell title={strings.General.Name} direction={getDirection(ZoneSort.NAME)} styles={zonesColumnStyle[2]}
                                    onSort={() => handleSort(ZoneSort.NAME)}/>
            <StyledSortableHeadCell title={strings.General.Created} direction={getDirection(ZoneSort.CREATED)} styles={zonesColumnStyle[3]}
                                    onSort={() => handleSort(ZoneSort.CREATED)}/>
        </StyledHead>
    )
}