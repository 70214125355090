import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
    actionsIsOpenState,
    cardSizesState,
    cardSizesValidState,
    contentWrapperState,
    mapPaddingState,
    panelsDefaultState,
    visibleCardsState
} from '../../states/skeletonState';
import { useElementWidth } from '../../hooks/useElementWidth';
import {
    ImperativePanelGroupHandle,
    ImperativePanelHandle,
    Panel,
    PanelGroup,
    PanelResizeHandle
} from "react-resizable-panels";
import { hideAllPanelsState, pageState, sidebarPinnedState, sidebarWidthState } from "../../states/appState";
import { mapToolState } from "../../states/mapState";
import { useUpdateEffect } from "usehooks-ts";
import { SIDEBAR_COLLAPSED_WIDTH } from '../../constants/app';
import { useProSidebar } from 'react-pro-sidebar';
import { PAGE } from "../../constants/pages";
import { SkeletonMediumCard } from "./cards/SkeletonMediumCard";
import { useTheme } from "../../hooks/useTheme";
import { SkeletonSmallCard } from "./cards/SkeletonSmallCard";

function SkeletonContentWrapper() {
    const { css, isDarkTheme } = useTheme();
    const setMapPadding = useSetRecoilState(mapPaddingState);
    const contentWrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
    const { elementWidth } = useElementWidth(contentWrapperRef);
    const mediumCardRef = useRef() as MutableRefObject<HTMLDivElement>;
    const smallCardRef = useRef() as MutableRefObject<HTMLDivElement>;
    const wrapper = useRecoilValue(contentWrapperState);
    const actionsIsOpen = useRecoilValue(actionsIsOpenState);
    const { smallIsVisible, mediumIsVisible } = useRecoilValue(visibleCardsState);
    const page = useRecoilValue(pageState);
    const panelGroupRef = useRef<ImperativePanelGroupHandle>(null);
    const smallPanelRef = useRef<ImperativePanelHandle>(null);
    const mediumPanelRef = useRef<ImperativePanelHandle>(null);
    const [cardSizes, setCardSizes] = useRecoilState(cardSizesState(page));
    const { defaultMediumCardSize, defaultSmallCardSize } = useRecoilValue(panelsDefaultState(page));
    const cardSizesValid = useRecoilValue(cardSizesValidState(page));
    const [isHover1, setIsHover1] = useState(false);
    const [isHover2, setIsHover2] = useState(false);
    const [isDraggingResize, setIsDraggingResize] = useState(false);
    const tool = useRecoilValue(mapToolState);

    useUpdateEffect(() => {
        const sizes = [];
        if (smallIsVisible) {
            sizes.push(cardSizesValid ? cardSizes.smallCardSize : defaultSmallCardSize);
        }
        if (mediumIsVisible) {
            sizes.push(cardSizesValid && cardSizes.mediumCardSize > 0
                ? cardSizes.mediumCardSize
                : defaultMediumCardSize);
        }
        sizes.push(100 - sizes.reduce((a, b) => a + b, 0));
        panelGroupRef.current?.setLayout(sizes);
    }, [page, smallIsVisible, mediumIsVisible])

    const hideAllPanels = useRecoilValue(hideAllPanelsState);
    const sidebarWidth = useRecoilValue(sidebarWidthState);
    const sidebarPinned = useRecoilValue(sidebarPinnedState);
    const { broken } = useProSidebar();

    const topBarHeight = 52;

    const contentWrapperStyles = css({
        position: 'absolute',
        left: (sidebarPinned || broken ? sidebarWidth : SIDEBAR_COLLAPSED_WIDTH) + "px",
        top: '0%',
        right: 'auto',
        bottom: '0%',
        display: tool ? "none" : 'flex',
        height: `calc(100% - ${broken ? topBarHeight : 0}px)`,
        marginTop: !broken ? '0px' : topBarHeight + "px",
        marginBottom: 'auto',
        marginLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        visibility: actionsIsOpen ? 'hidden' : undefined,
        width: "100%",
        transition: "left 0.3s ease, margin 0.3s ease",
    });

    useEffect(() => {
        if (cardSizes.mapCardSize > 0 && elementWidth) {
            const mapPadding = elementWidth - (elementWidth * (cardSizes.mapCardSize / 100)) + (sidebarPinned ? sidebarWidth : 0);
            setMapPadding(mapPadding)
        }
    }, [cardSizes.mapCardSize, elementWidth, sidebarWidth, sidebarPinned]);

    const divColor = isDarkTheme ? "#0006" : "#FFF6";
    const divHoverColor = isDarkTheme ? "#000C" : "#FFFC";

    return (
        <div
            ref={contentWrapperRef}
            className={`${contentWrapperStyles} ${wrapper}`}
            style={{
                pointerEvents: "none",
                display: hideAllPanels ? "none" : undefined,
                alignItems: "start"
            }}
        >
            <PanelGroup
                direction="horizontal"
                onLayout={(sizes: number[]) => {
                    // Set card sizes when not only map is visible.
                    if (sizes[0] >= 0 && sizes[1] >= 0) {
                        // Set small card size to 0 if not visible.
                        if (!smallIsVisible) {
                            setCardSizes({
                                smallCardSize: 0,
                                mediumCardSize: sizes[0],
                                mapCardSize: sizes[1],
                            });
                        } else {
                            setCardSizes({
                                smallCardSize: sizes[0],
                                mediumCardSize: sizes[2] ? sizes[1] : 0,
                                mapCardSize: sizes[2] || sizes[1],
                            });
                        }
                    }
                }}
                ref={panelGroupRef}
                style={{
                    overflow: "visible",
                    height: page === PAGE.LIVE && !cardSizes.smallHeightExpanded ? "400px" : "100%",
                    transition: "height 0.1s ease",
                }}
            >
                {smallIsVisible && <>
                    <Panel
                        defaultSize={cardSizesValid && cardSizes.smallCardSize ? cardSizes.smallCardSize : defaultSmallCardSize}
                        minSize={0}
                        maxSize={50}
                        order={1}
                        style={{
                            pointerEvents: "all",
                            overflow: "hidden",
                            transition: mediumIsVisible ? undefined : 'flex 0.1s ease',
                        }}
                        collapsible={!isDraggingResize}
                        ref={smallPanelRef}
                    >
                        <SkeletonSmallCard ref={smallCardRef} border={!mediumIsVisible}/>
                    </Panel>
                    <div onMouseEnter={() => setIsHover1(true)}
                         onMouseLeave={() => setIsHover1(false)}
                         style={{
                             backgroundColor: isHover1 ? divHoverColor : (mediumIsVisible ? divColor : "transparent"),
                             backdropFilter: mediumIsVisible ? "blur(50px)" : "none",
                             height: "100%",
                         }}>
                        <PanelResizeHandle style={{
                            pointerEvents: "all",
                            height: "100%",
                        }} onDragging={setIsDraggingResize}>
                            <div style={{ width: "8px" }}/>
                        </PanelResizeHandle>
                    </div>
                </>}
                {mediumIsVisible && (
                    <>
                        <Panel
                            defaultSize={cardSizesValid && cardSizes.mediumCardSize
                                ? cardSizes.mediumCardSize
                                : defaultMediumCardSize}
                            minSize={0}
                            maxSize={90}
                            order={2}
                            style={{
                                pointerEvents: "all",
                                overflow: "hidden",
                                transition: 'flex 0.1s ease',
                            }}
                            collapsible={!isDraggingResize}
                            ref={mediumPanelRef}
                        >
                            <SkeletonMediumCard ref={mediumCardRef}/>
                        </Panel>
                        <div onMouseEnter={() => setIsHover2(true)}
                             onMouseLeave={() => setIsHover2(false)}
                             style={{
                                 backgroundColor: isHover2 ? divHoverColor : "transparent",
                                 height: "100%"
                             }}>
                            <PanelResizeHandle style={{ pointerEvents: "all", height: "100%" }}
                                               onDragging={setIsDraggingResize}
                                               disabled={false}
                            >
                                <div style={{ width: "8px" }}/>
                            </PanelResizeHandle>
                        </div>
                    </>
                )}
                <Panel
                    minSize={10}
                    maxSize={100}
                    order={3}
                />
            </PanelGroup>
        </div>
    );
}

export default React.memo(SkeletonContentWrapper);
