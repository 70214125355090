import { Account, Latitude, Longitude, Timestamp, Uid, WorkingHours } from './core';
import { HOUR, MINUTE } from "../utils/time";
import { CSV_EXTENSION, EXCEL_EXTENSION } from '../constants/app';
import { TRUNCATE_LENGTH_DEFAULT, truncateText } from '../utils/strings';

export const MOVING_STATE_ONTHEWAY = "ontheway";
export const MOVING_STATE_IDLING = "idling";
export const MOVING_STATE_HALTED = "halted";
export const MOVING_STATE_OFFDUTY = "offduty";

export const DEVICE_MODE_UNDEFINED = 0;
export const DEVICE_MODE_FOREGROUNDED = 1;
export const DEVICE_MODE_BACKGROUNDED = 2;
export const DEVICE_MODE_SUSPENDED = 3;

export interface Member extends Account {
    id: Uid;
    battery_level: number;
    battery_plugged: boolean;
    unit_date: string;
    device: Device;
    google_calendar: boolean;
    unit_distance: string;
    role: Role;
    route_end_lat: Latitude;
    route_end_lng: Longitude;
    route_start_lat: Latitude;
    route_start_lng: Longitude;
    route_start_time: number;
    states: States;
    status_label: string;
    teams: number[];
    unit_time: string;
    timestamps: Timestamps;
    travel_mode: string;
    username: string;
    vehicle_capacity: number;
    working_hours: WorkingHours;
    twofactor_auth_enabled: boolean;
    color: string;
    skills?: string[];
}

export type Role = "worker" | "operator" | "admin";

export interface MemberListItem extends Member {
    selected: boolean;
}

export interface Device {
    model: string;
    manufacturer: "Apple" | "Android" | "";
    os: string;
    app_launches: number;
    app_version: number;
    app_version_name: string;
    id: string;
    mode: number;
    diagnostics_path: string;
    name?: string;
}

export interface Timestamps {
    signal: Timestamp;
    created: Timestamp;
    login: Timestamp;
    tracking: Timestamp;
}

export interface States {
    moving: string;
    tracking_status: boolean;
    location_services: boolean;
    location_permission: boolean;
    login_status: boolean;
    battery_save: boolean;
    off_trackrecording: boolean;
    off_gps_stationary: boolean;
}

export type ImportMemberFileFormat = typeof CSV_EXTENSION | typeof EXCEL_EXTENSION;

export function isAdmin(role: string) {
    return role === 'admin';
}

export function isOperator(role: string) {
    return role === 'operator';
}

export function isWorker(role: string) {
    return role === 'worker';
}

export function isAdminOrOperator(member: Member) {
    return isAdmin(member.role) || isOperator(member.role);
}

export function isIdling(member: Member) {
    return member.states && member.states.moving === MOVING_STATE_IDLING
}

export function isOnTheWay(member: Member) {
    return member.location && member.location.speed > 0 && hasRecentGps(member, 10 * MINUTE)
}

export function hasRecentGps(member: Member, millis: number = 18 * HOUR): boolean {
    return member.location && Date.now() - member.location.ts < millis;
}

export function isDeviceResponsive(member: Member) {
    return Math.max(member.timestamps.signal, member.location.ts) >= Date.now() - MINUTE * 30;
}

export function isOffDuty(member: Member) {
    return member.states.moving === MOVING_STATE_OFFDUTY;
}

export function isLoggedIn(member: Member) {
    return member.states.login_status;
}

export function isTrackingOn(member: Member) {
    return member.states.tracking_status;
}

export function hasValidDevice(member?: Member) {
    return member?.device && member?.device.id;
}

export function hasDeviceAnalysisFeature(member?: Member): boolean {
    if (member && hasValidDevice(member)) {
        return member.device.manufacturer === "Apple" && member.device.app_version >= 274
            || member.device.manufacturer !== "Apple" && member.device.app_version >= 245;
    }
    return false;
}

export const getTruncatedMemberName = (member: Member) => {
    return member.name && member.name.length > TRUNCATE_LENGTH_DEFAULT ? truncateText(member.name) : member.name;
}