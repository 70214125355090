import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import {
    ALL_JOBS_ADDRESS_COLUMN,
    ALL_JOBS_ALL_COLUMNS,
    ALL_JOBS_ASSIGNED_DATE_COLUMN,
    ALL_JOBS_ASSIGNEE_COLUMN,
    ALL_JOBS_CARRY_OVER,
    ALL_JOBS_CREATED_DATE_COLUMN,
    ALL_JOBS_DEFAULT_COLUMNS_WIDTH,
    ALL_JOBS_DROPOFF_COLUMN,
    ALL_JOBS_LABELS,
    ALL_JOBS_LAST_SAVED_COLUMN,
    ALL_JOBS_NOTES_COLUMN,
    ALL_JOBS_ONSITE_COLUMN,
    ALL_JOBS_OPTIONS_COLUMN,
    ALL_JOBS_ORDER_NUMBER_COLUMN,
    ALL_JOBS_PICKUP_COLUMN,
    ALL_JOBS_PRIORITY_COLUMN,
    ALL_JOBS_PROGRESS_STATUS_COLUMN,
    ALL_JOBS_SELECT_COLUMN,
    ALL_JOBS_TEAM_COLUMN,
    ALL_JOBS_TIME_WINDOW_COLUMN,
    ALL_JOBS_TITLE_COLUMN,
    ALL_JOBS_WORK_TYPE,
    ALL_JOBS_ZONES
} from '../../constants/jobs';
import { allJobsColumnsState, allJobsColumsWidthState, JobSort } from '../../states/allJobsState';
import { ColumnsConfiguration } from '../../types/core';
import useAccount from '../useAccount';
import { useStrings } from '../useStrings';
import { useStyleOverrides } from '../useStyleOverrides';
import { useAllJobsSort } from './useAllJobsSort';

export function useAllJobsColumns() {
    const { strings } = useStrings();
    const { handleSort, getDirection } = useAllJobsSort();
    const { fixedColumnStyle } = useStyleOverrides();
    const { company } = useAccount();
    const customFields = useMemo(() => company?.job_custom_fields ? company?.job_custom_fields : [], [company?.job_custom_fields]);
    const allColumns = useMemo(() => Array.from(new Set([...ALL_JOBS_ALL_COLUMNS, ...customFields])), [customFields]);
    const [enabledColumns, setEnabledColumns] = useRecoilState(allJobsColumnsState);
    const disabledColumns = useMemo(
        () => allColumns.filter((column) => !enabledColumns.includes(column)),
        [enabledColumns, allColumns]
    );
    const columns = [...enabledColumns, ...disabledColumns];

    const [columnsWidth, setColumnsWidth] = useRecoilState(allJobsColumsWidthState);

    const columnsConfiguration: ColumnsConfiguration = useMemo(() => {
        const config = {
            [ALL_JOBS_TITLE_COLUMN]: {
                title: strings.General.Title,
                direction: getDirection(JobSort.TITLE),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_TITLE_COLUMN]),
                onSort: () => handleSort(JobSort.TITLE),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_TITLE_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_TITLE_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_TITLE_COLUMN],
            },
            [ALL_JOBS_ASSIGNEE_COLUMN]: {
                title: strings.General.Assignee,
                direction: getDirection(JobSort.ASSIGNEE),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_ASSIGNEE_COLUMN]),
                onSort: () => handleSort(JobSort.ASSIGNEE),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_ASSIGNEE_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_ASSIGNEE_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_ASSIGNEE_COLUMN],
            },
            [ALL_JOBS_PROGRESS_STATUS_COLUMN]: {
                title: strings.General.ProgressStatus,
                direction: getDirection(JobSort.PROGRESS_STATUS),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_PROGRESS_STATUS_COLUMN]),
                onSort: () => handleSort(JobSort.PROGRESS_STATUS),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_PROGRESS_STATUS_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_PROGRESS_STATUS_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_PROGRESS_STATUS_COLUMN],
            },
            [ALL_JOBS_ASSIGNED_DATE_COLUMN]: {
                title: strings.General.AssignedDate,
                direction: getDirection(JobSort.ASSIGNED_DATE),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_ASSIGNED_DATE_COLUMN]),
                onSort: () => handleSort(JobSort.ASSIGNED_DATE),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_ASSIGNED_DATE_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_ASSIGNED_DATE_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_ASSIGNED_DATE_COLUMN],
            },
            [ALL_JOBS_PRIORITY_COLUMN]: {
                title: strings.General.Priority,
                direction: getDirection(JobSort.PRIORITY),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_PRIORITY_COLUMN]),
                onSort: () => handleSort(JobSort.PRIORITY),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_PRIORITY_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_PRIORITY_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_PRIORITY_COLUMN],
            },
            [ALL_JOBS_ADDRESS_COLUMN]: {
                title: strings.General.Address,
                direction: getDirection(JobSort.ADDRESS),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_ADDRESS_COLUMN]),
                onSort: () => handleSort(JobSort.ADDRESS),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_ADDRESS_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_ADDRESS_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_ADDRESS_COLUMN],
            },
            [ALL_JOBS_TEAM_COLUMN]: {
                title: strings.General.Team,
                direction: getDirection(JobSort.TEAM),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_TEAM_COLUMN]),
                onSort: () => handleSort(JobSort.TEAM),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_TEAM_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_TEAM_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_TEAM_COLUMN],
            },
            [ALL_JOBS_CREATED_DATE_COLUMN]: {
                title: strings.General.CreatedDate,
                direction: getDirection(JobSort.CREATED),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_CREATED_DATE_COLUMN]),
                onSort: () => handleSort(JobSort.CREATED),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_CREATED_DATE_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_CREATED_DATE_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_CREATED_DATE_COLUMN],
            },
            [ALL_JOBS_LAST_SAVED_COLUMN]: {
                title: strings.General.LastSaved,
                direction: getDirection(JobSort.SAVED),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_LAST_SAVED_COLUMN]),
                onSort: () => handleSort(JobSort.SAVED),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_LAST_SAVED_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_LAST_SAVED_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_LAST_SAVED_COLUMN],
            },
            [ALL_JOBS_ORDER_NUMBER_COLUMN]: {
                title: strings.Job.OrderId,
                direction: getDirection(JobSort.ORDER),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_ORDER_NUMBER_COLUMN]),
                onSort: () => handleSort(JobSort.ORDER),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_ORDER_NUMBER_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_ORDER_NUMBER_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_ORDER_NUMBER_COLUMN],
            },
            [ALL_JOBS_TIME_WINDOW_COLUMN]: {
                title: strings.General.TimeWindow,
                direction: getDirection(JobSort.TIME_WINDOW),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_TIME_WINDOW_COLUMN]),
                onSort: () => handleSort(JobSort.TIME_WINDOW),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_TIME_WINDOW_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_TIME_WINDOW_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_TIME_WINDOW_COLUMN],
            },
            [ALL_JOBS_NOTES_COLUMN]: {
                title: strings.Job.Notes,
                direction: getDirection(JobSort.NOTES),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_NOTES_COLUMN]),
                onSort: () => handleSort(JobSort.NOTES),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_NOTES_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_NOTES_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_NOTES_COLUMN],
            },
            [ALL_JOBS_ZONES]: {
                title: strings.General.Zones,
                direction: getDirection(JobSort.ZONES),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_ZONES]),
                onSort: () => handleSort(JobSort.ZONES),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_ZONES]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_ZONES],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_ZONES],
            },
            [ALL_JOBS_WORK_TYPE]: {
                title: strings.Job.Type,
                direction: getDirection(JobSort.WORK_TYPE),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_WORK_TYPE]),
                onSort: () => handleSort(JobSort.WORK_TYPE),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_WORK_TYPE]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_WORK_TYPE],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_WORK_TYPE],
            },
            [ALL_JOBS_CARRY_OVER]: {
                title: strings.Job.CarryOver,
                direction: getDirection(JobSort.CARRY_OVER),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_CARRY_OVER]),
                onSort: () => handleSort(JobSort.CARRY_OVER),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_CARRY_OVER]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_CARRY_OVER],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_CARRY_OVER],
            },
            [ALL_JOBS_ONSITE_COLUMN]: {
                title: strings.Job.OnSiteTime,
                direction: getDirection(JobSort.ONSITE),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_ONSITE_COLUMN]),
                onSort: () => handleSort(JobSort.ONSITE),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_ONSITE_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_ONSITE_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_ONSITE_COLUMN],
            },
            [ALL_JOBS_PICKUP_COLUMN]: {
                title: strings.Job.Pickup,
                direction: getDirection(JobSort.PICKUP),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_PICKUP_COLUMN]),
                onSort: () => handleSort(JobSort.PICKUP),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_PICKUP_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_PICKUP_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_PICKUP_COLUMN],
            },
            [ALL_JOBS_DROPOFF_COLUMN]: {
                title: strings.Job.Dropoff,
                direction: getDirection(JobSort.DROPOFF),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_DROPOFF_COLUMN]),
                onSort: () => handleSort(JobSort.DROPOFF),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_DROPOFF_COLUMN]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_DROPOFF_COLUMN],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_DROPOFF_COLUMN],
            },
            [ALL_JOBS_LABELS]: {
                title: strings.Job.Labels,
                direction: getDirection(JobSort.LABELS),
                styles: fixedColumnStyle(columnsWidth[ALL_JOBS_LABELS]),
                onSort: () => handleSort(JobSort.LABELS),
                onResize: (width: number) => {
                    setColumnsWidth((prev) => ({
                        ...prev,
                        [ALL_JOBS_LABELS]: width,
                    }));
                },
                initialWidth: columnsWidth[ALL_JOBS_LABELS],
                minWidth: ALL_JOBS_DEFAULT_COLUMNS_WIDTH[ALL_JOBS_LABELS],
            },
        }

        const customFieldsConfigEntries = customFields.map((customField) => [customField, {
            title: customField,
            direction: getDirection(JobSort.CUSTOM, customField),
            styles: fixedColumnStyle(columnsWidth[customField] ?? 100),
            onSort: () => handleSort(JobSort.CUSTOM, customField),
            onResize: (width: number) => {
                setColumnsWidth((prev) => ({
                    ...prev,
                    [customField]: width,
                }));
            },
            initialWidth: columnsWidth[customField],
            minWidth: 100,
        }]);
        const customFieldsConfig = Object.fromEntries(customFieldsConfigEntries);

        return {
            ...config,
            ...customFieldsConfig
        }
    }, [columnsWidth, customFields, getDirection, handleSort]);

    const tableWidth = useMemo(() => {
        return columnsWidth[ALL_JOBS_SELECT_COLUMN] +
               columnsWidth[ALL_JOBS_TITLE_COLUMN] +
               columnsWidth[ALL_JOBS_OPTIONS_COLUMN] +
               Object.entries(columnsWidth)
                   .filter(([key]) => enabledColumns.includes(key))
                   .map(([_, width]) => width)
                   .reduce((accumulator, current) => accumulator + current, 0);
    }, [columnsWidth, enabledColumns])
        

    return {
        columns,
        columnsConfiguration,
        columnsWidth,
        customFields,
        disabledColumns,
        enabledColumns,
        tableWidth,
        reorderColumns: setEnabledColumns,
    };
}
