import { Latitude, Longitude, Timestamp, Uid } from "./core";

export interface Trip {
    id: string;
    accountUid: Uid;
    companyUid: Uid;
    targetLocation: string;
    targetName: string;
    targetLat: Latitude;
    targetLng: Longitude;
    targetRadius: number;
    originalLat: Latitude;
    originalLng: Longitude;
    originalDuration: number;
    originalDistance: number;
    originalEncoded: string;
    currentLat: Latitude;
    currentLng: Longitude;
    lastUpdateTs: Timestamp;
    targetUid: Uid;
    jobId: string;
    currentDirections: string;
    originalDirections: string;
    createdTs: Timestamp;
    etaTs: Timestamp;
    distance: number;
    duration: number;
    encoded: string;
    scheduledTs: Timestamp;
    originalEta: Timestamp;
    customEta: Timestamp;
    progressStatus: TripProgressStatus;
    activeStatus: TripActiveStatus;
}

export enum TripProgressStatus {
    SCHEDULED = 0,
    IN_PROGRESS = 1,
    AUTO_FINISHED = 2,
    MANUAL_FINISHED = 3,
    AUTO_CANCELED = 4,
    MANUAL_CANCELED = 5
}

export enum TripActiveStatus {
    ACTIVE = 0,
    ARCHIVED = 1,
    DELETED = 2
}