import {
    ColumnConfiguration,
    RecurringReportConfig,
    ReportConfig,
    ReportFormat,
    UploadedReport
} from './../types/reports';
import { atom, selector } from "recoil";
import { Member } from "../types/member";
import { Place } from "../types/place";
import { SelectedDays } from "../types/days";
import { SELECTED_DAYS_NONE } from "../constants/days";
import { dayOfToday } from "../utils/time";
import { Day } from "../types/core";

export const setupRecurringReportState = atom<boolean>({
    key: "report.setuprecurring",
    default: false,
})

export const selectedReportConfigState = atom<ReportConfig | undefined>({
    key: "report.selected.config",
    default: undefined,
});

export const selectedReportNotifyState = atom<Member[]>({
    key: "report.selected.notify",
    default: [],
});

export const selectedReportNameState = atom<string>({
    key: "report.selected.name",
    default: "",
});

export const selectedReportDayWeekState = atom<"day" | "week" | "twoweeks">({
    key: "report.recurring.dayweek",
    default: "week",
});

export const selectedReportStartDayState = atom<Day>({
    key: "report.recurring.startday",
    default: dayOfToday(),
});

export const selectedReportWeekdaysState = atom<SelectedDays>({
    key: "report.recurring.weekdays",
    default: SELECTED_DAYS_NONE,
});

export const selectedReportMembersState = atom<Member[]>({
    key: "report.selected.members",
    default: [],
});

export const selectedReportPlacesState = atom<Place[]>({
    key: "report.selected.places",
    default: [],
});

export const selectedReportDatesState = atom<(Date | null | undefined)[]>({
    key: "report.selected.dates",
    default: [],
});

export const selectedReportFormatState = atom<ReportFormat | undefined>({
    key: "report.selected.format",
    default: undefined,
});

export const selectedReportPayloadState = atom<string | undefined>({
    key: "report.selected.payload",
    default: undefined,
});

export const selectedReportColumnsState = atom<ColumnConfiguration[] | undefined>({
    key: "report.selected.columns",
    default: selector({
        key: "report.selected.columns.initializer",
        get: ({ get }) => {
            const reportConfig = get(selectedReportConfigState);
            if (!reportConfig) {
                return;
            }

            return reportConfig.availableColumns?.filter(it => !it.isDefaultHidden)
                .map((column) => ({
                    key: column.key,
                    rename: undefined,
                    isCheck: column.isCheck,
                }));
        }
    }),
});

export const uploadedReportsState = atom<UploadedReport[]>({
    key: "report.uploads",
    default: [],
});

export const recurringReportsState = atom<RecurringReportConfig[]>({
    key: "report.recurring",
    default: [],
});

export const selectedRecurringReportState = atom<RecurringReportConfig | undefined>({
    key: "report.recurring.selected",
    default: undefined,
});