import { OnChangeParams } from "baseui/select";
import { useRecoilValue } from "recoil";
import { allZonesState } from "../../states/zoneState";
import { LinkedZone } from "../../types/linked";
import { StyledSelect } from "../ui/StyledSelect";

type Props = {
    linkedZones: LinkedZone[];
    onChangeLinkedZones: (linkedZones: LinkedZone[]) => void;
    placeholder?: string;
}

export function LinkedZonesSelect({ linkedZones, onChangeLinkedZones, placeholder }: Props) {
    const zones = useRecoilValue(allZonesState);
    const options = zones.map((zone) => ({ title: zone.name, id: zone.uid }));

    const onLinkZone = (params: OnChangeParams) => {
        if (params.type === 'remove') {
            onChangeLinkedZones(linkedZones.filter(lz => lz.id.toString() !== params.option?.id));
        } else if (params.type === 'select') {
            const linkedZone = {
                title: params.option?.title as string,
                id: BigInt(params.option?.id as string),
            } as LinkedZone;
            onChangeLinkedZones([...linkedZones, linkedZone]);
        } else if (params.type === 'clear') {
            onChangeLinkedZones([]);
        }
    }

    const value = linkedZones ? linkedZones.map(lz => {
        return { id: lz.id.toString(), title: lz.title }
    }) : [];

    return (
        <StyledSelect
            closeOnSelect
            size="compact"
            options={options}
            value={value}
            openOnClick
            labelKey='title'
            type="select"
            multi
            onChange={onLinkZone}
            placeholder={placeholder}
        />
    );
}