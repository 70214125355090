import { Job } from "./job";
import { Timestamp, Uid } from "./core";

export interface Sequence {
    id: string;
    name: string;
    assignToUid: Uid;
    assignWeekDays: number;
    assignCalendarDays: string;
    owners: string;
    lastSaved: Timestamp;
    status: number;
}

export interface ReadableSequence extends Sequence {
    jobs: Job[];
}

export interface SavableSequence extends Sequence {
    jobs?: string[];
}

export function createNewEmptySequence(name: string): SavableSequence {
    return {
        name,
        assignToUid: "",
        assignWeekDays: 0,
        assignCalendarDays: "",
        owners: "",
        lastSaved: Date.now(),
        status: 0,
        jobs: [] as string[],
    } as SavableSequence;
}