import useWebSocket from "react-use-websocket";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getConversation } from "../services/api";
import { uidState } from "../states/accountState";
import { memberState } from "../states/membersState";
import { getRecoil, setRecoil } from "./RecoilAccessProvider";
import { Job } from "../types/job";
import { useStateUpdater } from "../hooks/useStateUpdater";
import { hasValidDevice, Member } from "../types/member";
import useAccount from "../hooks/useAccount";
import { useMembersUpdater } from "../hooks/members/useMembersUpdater";
import { jobsUpdateEventState } from "../states/jobsState";
import { useConversations } from "../hooks/useConversations";
import { Message } from "../types/message";
import { useToast } from "../hooks/useToast";
import { useStrings } from "../hooks/useStrings";
import { conversationState, newMessageEventState } from "../states/messagesState";
import { useState } from "react";
import { MINUTE, SECOND } from "../utils/time";
import useDirector from "../hooks/useDirector";
import { Activity } from "../types/notification";
import { MemberItemSimplified } from "../components/member/MemberItemSimplified";
import { activitiesState, routeSimulationRunningForState } from "../states/appState";
import { speedHistogramDataState } from "../states/dataState";
import { formSubmissionEventState } from "../states/viewState";
import { usePlacesLayerUpdater } from "../hooks/places/usePlacesLayerUpdater";
import { useInterval } from "usehooks-ts";
import { mapMarkerUpdateEventState } from "../states/mapState";
import { useDayRouteUpdater } from "../hooks/useDayRouteUpdater";
import { tripEventState } from "../states/tripsState";
import { Day, Latitude, Longitude, Timestamp, Uid } from "../types/core";
import { JSONbig } from "../constants/app";

export default function WebsocketConnector() {
    const { format, strings } = useStrings();
    const uid = useRecoilValue(uidState);
    const connect = uid.length > 0;
    const { access } = useAccount();
    const beatUrl = 'wss://ws.hellotracks.com/!/pulse/?category=beat&platform=web&uid=' + uid + '&access=' + access;
    const appUrl = 'wss://ws.hellotracks.com/!/pulse/?category=app&platform=web&uid=' + uid + '&access=' + access;
    const stateUpdater = useStateUpdater();
    const { updateMembers, updateSingleMember } = useMembersUpdater();
    const setJobsUpdateEvent = useSetRecoilState(jobsUpdateEventState);
    const setNewMessageEvent = useSetRecoilState(newMessageEventState);
    const setFormSubmissionEvent = useSetRecoilState(formSubmissionEventState);
    const conversations = useConversations();
    const toast = useToast();
    const director = useDirector();
    const [throttling, setThrottling] = useState<number>(0);
    const placesLayerUpdater = usePlacesLayerUpdater();
    const setActivities = useSetRecoilState(activitiesState);
    const uidToSingleUpdateTs = new Map<string, number>();
    const dayRouteUpdater = useDayRouteUpdater();
    const setTripEvent = useSetRecoilState(tripEventState);

    const { sendMessage: sendMessageToBeat } = useWebSocket(beatUrl,
        {
            onMessage: (event: WebSocketEventMap['message']) => {
                const data = event.data as string ?? "";
                if (data === "pong") {
                    return;
                }
                if (data.startsWith("beat jobsupdate")) {
                    updateJobsOnBeat(data);
                } else if (data.startsWith("beat accountupdate")) {
                    updateAccountsOnBeat(data);
                } else if (data.startsWith("beat newmessage")) {
                    updateMessages(data);
                } else if (data.startsWith("beat readmessage")) {
                    updateConversation(data);
                } else if (data.startsWith("beat notification")) {
                    updateNotification(data);
                } else if (data.startsWith("beat simulator")) {
                    updateSimulator(data);
                } else if (data.startsWith("beat event")) {
                    updateEvent(data);
                } else if (data.startsWith("beat who")) {
                    updateLocationOnBeat(data);
                }
            },
            shouldReconnect: (closeEvent) => true,
            reconnectAttempts: 1000,
            reconnectInterval: 3000,
        },
        connect
    );

    const { sendMessage: sendMessageToApp } = useWebSocket(appUrl,
        {
            onMessage: (event: WebSocketEventMap['message']) => {
                const data = event.data as string ?? "{}";
                if (data === "pong") {
                    return;
                }
                const json = JSON.parse(data);
                if (json.command === "placesupdate") {
                    placesLayerUpdater.updateAll();
                } else if (json.command === "membersupdate") {
                    updateMembers();
                } else if (json.command === "jobsupdate") {
                    setJobsUpdateEvent(Date.now);
                } else if (json.command === "dayrouteupdate") {
                    if (json.replyTo) {
                        const payload: string = json.replyTo.split(":");
                        const uid: Uid = payload[0] as Uid;
                        const day: Day = parseInt(payload[0]) as Day;
                        if (uid && day) {
                            dayRouteUpdater.update(uid, day);
                        }
                    }
                } else if (json.command === "tripupdate") {
                    setTripEvent(Date.now());
                } else {
                    console.log("unhandled command: " + json.command);
                }
            },
            shouldReconnect: (closeEvent) => true,
            reconnectAttempts: 10,
            reconnectInterval: 3000,
        },
        connect
    );

    useInterval(() => sendPing(), 10_000);

    function sendPing() {
        sendMessageToBeat("ping");
        sendMessageToApp("ping");
    }

    function updateJobsOnBeat(data: string) {
        let idx = data.indexOf("jobs=");
        let jobs = JSONbig.parse(data.substring(idx + 5)) as Job[];
        stateUpdater.updateJobs(jobs);
    }

    function updateAccountsOnBeat(data: string) {
        let idx = data.indexOf("account=");
        let account = JSON.parse(data.substring(idx + "account=".length)) as Member;
        stateUpdater.updateAccount(account);
    }

    function updateLocationOnBeat(data: string) {
        const parts = data.split(" ");
        if (parts.length < 8) {
            console.warn("ignoring beat update: " + data);
            return;
        }
        const uid: Uid = parts[1].substring("who=".length) as Uid;
        const lat: Latitude = parseFloat(parts[2].substring("lat=".length));
        const lng: Longitude = parseFloat(parts[3].substring("lng=".length));
        const ts: Timestamp = parseInt(parts[4].substring("ts=".length)) as Timestamp;
        const head = parseInt(parts[5].substring("head=".length));
        const accuracy = parseInt(parts[6].substring("acc=".length));
        const speed = parseInt(parts[7].substring("spd=".length));
        const member = getRecoil(memberState(uid));
        if (member?.uid && !hasValidDevice(member)) {
            if (!throttling || Date.now() - throttling > MINUTE) {
                director.performPoll().then(() => {});
                setThrottling(Date.now());
            }
        }

        if (member && member.location && member.location.ts < ts) {
            let location = {
                ...member.location,
                lat: lat,
                lng: lng,
                ts: ts,
                head: head,
                accuracy: accuracy,
                speed: speed,
            };
            let newMember = {
                ...member,
                location: location
            };
            setRecoil(memberState(newMember.id), newMember);

            const current = getRecoil(speedHistogramDataState(uid));
            setRecoil(speedHistogramDataState(uid), [...current.slice(Math.max(current.length - 99, 0)), speed]);
            setRecoil(mapMarkerUpdateEventState, {uid, lat, lng});
        }

        const lastSingleUpdate = uidToSingleUpdateTs.get(uid);
        if (!lastSingleUpdate || lastSingleUpdate + 30 * SECOND < Date.now()) {
            uidToSingleUpdateTs.set(uid, Date.now());
            updateSingleMember(uid);
        }
    }

    function updateMessages(data: string) {
        conversations.loadAll();
        const message = JSON.parse(data.substring("beat newmessage ".length)) as Message;
        const member = getRecoil(memberState(message.uid));
        toast.showNotification(format(strings.Messages.MessageFromX, member?.name || ""), message.msg);
        setNewMessageEvent(message);
    }

    function updateConversation(data: string) {
        const idx = data.indexOf("uid=");
        const uid = data.substring(idx + "uid=".length);
        getConversation({ account: uid }).then(({ status, messages }) => {
            if (status) {
                setRecoil(conversationState(uid), messages);
                conversations.loadAll();
            }
        });
    }

    function updateNotification(data: string) {
        const notification = JSON.parse(data.substring("beat notification ".length)) as Activity;
        toast.showNotification(<MemberItemSimplified uid={notification.uid}/>, notification.message, true);
        setActivities(old => [notification, ...old]);
    }

    function updateSimulator(data: string) {
        const parts = data.split(" ");
        if (parts.length < 4) {
            console.warn("ignoring beat simulator: " + data);
            return;
        }
        const createdTs = parts[2].substring("createdTs=".length);
        const uid = parts[3].substring("uid=".length);
        const event = parts[4].substring("event=".length);
        setRecoil(routeSimulationRunningForState(uid), event === "started");
        if (event === "started") {
            toast.showSuccess(strings.Routing.RouteSimulationStarted);
        } else {
            toast.showSuccess(strings.Routing.RouteSimulationFinished);
        }
    }

    function updateEvent(data: string) {
        if (data.includes("formsubmission")) {
            setFormSubmissionEvent(Date.now());
        }
    }

    return null;
}