import React, { useState } from 'react';
import { Group } from '@visx/group';
import { GradientDarkgreenGreen } from '@visx/gradient';
import { Pie } from "@visx/shape";
import { AnimatedPie } from "./AnimatedPie";
import { randomNumber } from "../../../utils/app";

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };

type Props = {
    width: number;
    height: number;
    margin?: typeof defaultMargin;
    animate?: boolean;
    data: Datum[];
};

export type Datum = {
    label: string;
    id: number;
    count: number;
    percent: number;
}

export function Donut({
                          data,
                          width,
                          height,
                          margin = defaultMargin,
                          animate = true,
                      }: Props) {
    const [selectedDatum, setSelectedDatum] = useState<Datum>();

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const radius = Math.min(innerWidth, innerHeight) / 2;
    const centerY = innerHeight / 2;
    const centerX = innerWidth / 2;
    const donutThickness = 80;

    const id = randomNumber() + "";

    return (
        <svg width={width} height={height}>
            <GradientDarkgreenGreen id={id}/>
            <rect rx={8} width={width} height={height} fill={`url(#${id})`}/>
            <Group top={centerY + margin.top} left={centerX + margin.left}>
                <Pie
                    data={selectedDatum ? [selectedDatum] : data}
                    pieValue={item => item.count}
                    outerRadius={radius}
                    innerRadius={radius - donutThickness}
                    cornerRadius={3}
                    padAngle={0.005}
                >
                    {(pie) => (
                        <AnimatedPie<Datum>
                            {...pie}
                            animate={animate}
                            getKey={(arc) => arc.data.label + " (" + arc.data.percent + "%)"}
                            onClickDatum={({ data: datum }) =>
                                animate &&
                                setSelectedDatum(selectedDatum && selectedDatum === datum ? undefined : datum)
                            }
                            getColor={(arc) => "rgba(255,255,255,0.6)"}
                        />
                    )}
                </Pie>
            </Group>
        </svg>
    );
}


