import { StyledCell, StyledRow } from "baseui/table";
import { LabelSmall } from "baseui/typography";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { EllipsedText } from "../ui/EllipsedText";
import { useRecoilValue } from "recoil";
import { memberState } from "../../states/membersState";
import React, { useRef } from "react";
import { useHover } from "usehooks-ts";
import MemberAvatar from "../member/MemberAvatar";
import { HFlex } from "../containers/HFlex";
import { Activity } from "../../types/notification";
import { TimeBadge } from "../badges/TimeBadge";

type Props = {
    data: {
        activities: Activity[]
    };
    index: number;
    style: React.CSSProperties;
}

export function ActivityLogRow({ data, index, style }: Props) {
    const activity = data.activities[index];
    const { gridStyle, activityLogColumnStyle } = useStyleOverrides();
    const member = useRecoilValue(memberState(activity.uid));
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);

    if (!member) {
        return null;
    }

    return (
        <StyledRow ref={hoverRef} role="row" style={{ ...style, ...gridStyle(isHover) }}>
            <StyledCell $style={activityLogColumnStyle[0]}>
                <HFlex spacing alignCenter>
                    <MemberAvatar uid={member.uid} forMap={false}/>
                    <LabelSmall style={{ textAlign: "start", whiteSpace: "nowrap" }}>
                        <EllipsedText inline breakAll>{member.name || member.username}</EllipsedText>
                    </LabelSmall>
                </HFlex>
            </StyledCell>
            <StyledCell $style={activityLogColumnStyle[1]}>
                <TimeBadge startMillis={activity.ts} withDate/>
            </StyledCell>
            <StyledCell $style={activityLogColumnStyle[2]}>
                <EllipsedText inline breakAll>{activity.message}</EllipsedText>
            </StyledCell>
        </StyledRow>
    );
}