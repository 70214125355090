import { Tag, Tooltip } from "antd";
import { tinyTag } from "../../constants/ui";
import React, { memo, ReactNode } from "react";
import { useTime } from "../../hooks/useTime";
import { VFlex } from "../containers/VFlex";
import { useStrings } from "../../hooks/useStrings";

type Props = {
    startMillis: number;
    endMillis?: number;
    withDate?: boolean;
    color?: string;
    style?: any;
    icon?: ReactNode;
    tooltipTitle?: string;
}

export const TimeBadge = memo(({
                                   startMillis,
                                   endMillis,
                                   withDate = false,
                                   color,
                                   icon,
                                   style,
                                   tooltipTitle
                               }: Props) => {
    const time = useTime();
    const { strings } = useStrings();
    const startText = (startMillis > 0 ? time.formatTimeOnly(startMillis) : "?")
    const endText = endMillis && (endMillis > 0 ? time.formatTimeOnly(endMillis) : "?");
    const dateText = withDate ? time.formatDateOnly(startMillis) + " " : ""
    const timeText = dateText + startText + (endText ? "-" + endText : "");
    const startTooltip = startMillis ? time.formatExactTime(startMillis) : strings.General.None;
    const endTooltip = endMillis ? time.formatExactTime(endMillis) : undefined;
    const tooltip = (
        <VFlex style={{ fontSize: '11px' }}>
            {tooltipTitle &&
                <>
                    <strong>{tooltipTitle}</strong>
                    <br/>
                    {endTooltip}
                </>
            }
            {startTooltip}
            {endTooltip &&
                <>
                    <br/>{endTooltip}
                </>
            }
        </VFlex>
    );
    return (
        <Tooltip title={tooltip}>
            <Tag color={color || "orange"} style={style || tinyTag}>
                {icon}
                {timeText}
            </Tag>
        </Tooltip>
    )
});