export interface CookieData {
    [key: string]: string;
}

export const getCookie = (name: string): string | undefined => {
    const cookies: CookieData = document.cookie
        .split(';')
        .reduce((acc: CookieData, cookie: string) => {
            const [name, value] = cookie.trim().split('=');
            return { ...acc, [name]: value };
        }, {});

    return cookies[name];
};

export const setCookie = (name: string, value: string, days: number) => {
    let expires: string = "";
    if (days) {
        const date: Date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/; domain=hellotracks.com";
}

export const HT_LOGIN_TS = "ht.login.ts";