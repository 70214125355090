import { memo } from "react";
import { Draggable } from "react-beautiful-dnd";
import JobItem from "../JobItem";
import { useRecoilValue } from "recoil";
import { sequenceJobsDispatchState } from "../../../states/sequencesState";

type Props = {
    id: string;
};

export function SequenceJobList({ id }: Props) {
    const jobs = useRecoilValue(sequenceJobsDispatchState(id));

    return (
        <>
            {jobs.map((job, index) => (
                <Draggable key={job.id} draggableId={job.id} index={index} isDragDisabled={false}>
                    {(provided) => (
                        <div
                            key={index}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                        >
                            <JobItem id={job.id} spacing pinnable={false}/>
                        </div>
                    )}
                </Draggable>
            ))}
        </>
    );
}

export default memo(SequenceJobList);
