import { Button } from "baseui/button";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { useBoard } from "../../hooks/useBoard";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { useTracksLayerUpdater } from "../../hooks/tracks/useTracksLayerUpdater";
import { useTracksSelection } from "../../hooks/tracks/useTracksSelection";
import { deleteTracks } from "../../services/api";
import { downloadTracksAsGpx } from "../../services/download";
import { selectedTrackEntriesState, trackOrStopEntriesState, tracksDatesState } from "../../states/tracksState";
import { dateToDayBrowserTimezone } from "../../utils/time";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { ConfirmModal } from "../ui/ConfirmModal";
import { TrashIcon } from "../ui/svg";
import { PasswordConfirmation } from "../shared/PasswordConfirmation";


export function TracksBulkRow() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [passwordConfirmed, setPasswordConfirmed] = useState(false);
    const { memberUids } = useBoard();
    const trackEntries = useRecoilValue(trackOrStopEntriesState);
    const selectedTrackEntries = useRecoilValue(selectedTrackEntriesState);
    const { updateMemberTracks } = useTracksLayerUpdater();
    const tracksDates = useRecoilValue(tracksDatesState);
    const { clearSelection } = useTracksSelection();

    const downloadGpx = async () => {
        setDownloading(true);
        const requests: Promise<void>[] = [];
        if (selectedTrackEntries.length > 0) { // Download a gpx file for every user with its own selected tracks
            memberUids.forEach((uid) => {
                const memberTracks = selectedTrackEntries.filter((t) => t.account === uid);
                if (memberTracks.length > 0) {
                    requests.push(downloadTracksAsGpx(uid, memberTracks));
                }
            });
        } else { // Download a gpx file for every user with its own tracks
            memberUids.forEach((uid) => {
                const memberTracks = trackEntries.filter((t) => t.account === uid);
                if (memberTracks.length > 0) {
                    requests.push(downloadTracksAsGpx(uid, memberTracks));
                }
            });
        }
        await Promise.allSettled(requests);
        setDownloading(false);
    }

    const removeTracks = async () => {
        const requests: Promise<{ status: boolean }>[] = [];
        if (selectedTrackEntries.length > 0) { // Delete for every user its own selected tracks
            memberUids.forEach((uid) => {
                const memberTracks = selectedTrackEntries.filter((t) => t.account === uid);
                if (memberTracks.length > 0) {
                    requests.push(deleteTracks(uid, memberTracks));
                }
            });
        }
        await Promise.allSettled(requests);
        if (tracksDates && !!tracksDates[0] && !!tracksDates[1]) {
            const updateRequests: Promise<void>[] = memberUids.map((uid) => updateMemberTracks(uid, dateToDayBrowserTimezone(tracksDates[0]!), dateToDayBrowserTimezone(tracksDates[1]!)));
            await Promise.allSettled(updateRequests);
        }
        setShowDeleteConfirm(false);
        clearSelection();
    }

    if (trackEntries.length === 0) {
        return null;
    }
    
    return (
        <HFlex spacing alignCenter>
            <Button
                kind="tertiary"
                size="compact"
                onClick={() => passwordConfirmed ? setShowDeleteConfirm(true) : setShowPasswordConfirm(true)}
                disabled={!selectedTrackEntries.length}
                startEnhancer={<TrashIcon size={14} color={theme.colors.contentTertiary}/>}
            >
                {strings.General.DeleteTracks}
            </Button>
            <SpacerExpand />
            <Button size="mini"
                    kind={'primary'}
                    shape={'pill'}
                    isLoading={downloading}
                    disabled={selectedTrackEntries.length === 0}
                    onClick={downloadGpx}>
                {strings.General.DownloadAsGPXFile}
            </Button>
            <ConfirmModal
                isOpen={showDeleteConfirm}
                description={strings.General.ReallyDeleteSelectedTracks}
                onConfirm={removeTracks}
                onCancel={() => setShowDeleteConfirm(false)}
                danger
                cancelText={strings.General.Cancel}
                confirmText={strings.General.Delete}
            />
            <PasswordConfirmation
                open={showPasswordConfirm}
                onClose={() => setShowPasswordConfirm(false)}
                onPasswordConfirmed={(confirmed) => {
                    setPasswordConfirmed(confirmed);
                    if (confirmed) {
                        setShowPasswordConfirm(false);
                        setShowDeleteConfirm(true);
                    }
                }}
            />
        </HFlex>
    )
}