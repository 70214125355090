import { ReactNode } from "react";
import { useStrings } from "./useStrings";
import { PAGE } from "../constants/pages";
import {
    ActivityLogIcon,
    AppointmentIcon,
    FormIcon,
    HeatmapIcon,
    HelpIcon,
    IntegrationsIcon,
    JourneyIcon,
    MobileChatIcon,
    PlaceOutlineIcon,
    ReportIcon,
    SettingsIcon,
    StatisticsIcon,
    TimelineIcon,
    TimesheetIcon,
    UploadsIcon,
    UsersOutlineIcon,
    WarningSignIcon,
    WorldIcon
} from "../components/ui/svg";
import { PageType } from "../types/core";
import { useTheme } from "./useTheme";
import { COLOR_WHITE } from "../constants/colors";

export type PageMenuItem = {
    page: PageType;
    title: string;
    icon: ReactNode;
    tooltip: ReactNode;
    activeIcon: ReactNode;
}

export function useMenu() {
    const { strings } = useStrings();
    const { isDarkTheme } = useTheme();

    const iconColor = (itemPage: string): string => {
        return isDarkTheme ? COLOR_WHITE : "rgba(60, 60, 67, .6)";
    }

    const iconSize = 18;
    const style = {
        backgroundColor: isDarkTheme ? "rgba(255, 255, 255, .08)" : "rgba(0, 0, 0, .04)",
        padding: "6px",
        borderRadius: "9px",
    };

    const live = {
        page: PAGE.LIVE,
        title: strings.Map.LiveMap,
        icon: <WorldIcon size={iconSize} color={iconColor(PAGE.LIVE)} style={style}/>,
        activeIcon: <WorldIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#009AB4"}} />,
        tooltip: strings.Menu.LiveMapDesc,
    } as PageMenuItem;

    const dispatch = {
        page: PAGE.DISPATCH,
        title: strings.General.Dispatch,
        icon: <AppointmentIcon size={iconSize} color={iconColor(PAGE.DISPATCH)} style={style}/>,
        activeIcon: <AppointmentIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#C74632" }}/>,
        tooltip: strings.Menu.DispatchDesc,
    } as PageMenuItem;

    const timeline = {
        page: PAGE.TIMELINE,
        title: strings.General.Timeline,
        icon: <TimelineIcon size={iconSize} color={iconColor(PAGE.TIMELINE)} style={style}/>,
        activeIcon: <TimelineIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#41784A" }}/>,
        tooltip: strings.Menu.TimelineDesc,
    } as PageMenuItem;

    const settings = {
        page: PAGE.SETTINGS,
        title: strings.General.Settings,
        icon: <SettingsIcon size={iconSize} color={iconColor(PAGE.SETTINGS)} style={style}/>,
        activeIcon: <SettingsIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#837968" }}/>,
        tooltip: strings.Menu.SettingsDesc,
    } as PageMenuItem;

    const team = {
        page: PAGE.TEAM,
        title: strings.General.Team,
        icon: <UsersOutlineIcon size={iconSize} color={iconColor(PAGE.TEAM)} style={style}/>,
        activeIcon: <UsersOutlineIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#AE6B68" }}/>,
        tooltip: strings.Menu.TeamDesc,
    } as PageMenuItem;

    const places = {
        page: PAGE.PLACES,
        title: strings.General.PlacesAndZones,
        icon: <PlaceOutlineIcon size={iconSize} color={iconColor(PAGE.PLACES)} style={style}/>,
        activeIcon: <PlaceOutlineIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#7E4045" }}/>,
        tooltip: strings.Menu.PlacesDesc,
    } as PageMenuItem;

    const alerts = {
        page: PAGE.ALERTS,
        title: strings.Alerts.Alerts,
        icon: <WarningSignIcon size={iconSize} color={iconColor(PAGE.ALERTS)} style={style}/>,
        activeIcon: <WarningSignIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#BF683B" }}/>,
        tooltip: strings.Menu.AlertsDesc,
    } as PageMenuItem;

    const dashboard = {
        page: PAGE.STATS,
        title: strings.Stats.Statistics,
        icon: <StatisticsIcon size={iconSize} color={iconColor(PAGE.STATS)} style={style}/>,
        activeIcon: <StatisticsIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#417865" }}/>,
        tooltip: strings.Menu.DashboardDesc,
    } as PageMenuItem;

    const reports = {
        page: PAGE.REPORTS,
        title: strings.General.Reports,
        icon: <ReportIcon size={iconSize} color={iconColor(PAGE.REPORTS)} style={style}/>,
        activeIcon: <ReportIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#577866" }}/>,
        tooltip: strings.Menu.ReportsDesc,
    } as PageMenuItem;

    const timesheet = {
        page: PAGE.TIMESHEET,
        title: strings.General.Timesheet,
        icon: <TimesheetIcon size={iconSize} color={iconColor(PAGE.TIMESHEET)} style={style}/>,
        activeIcon: <TimesheetIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#7E896F" }}/>,
        tooltip: strings.Menu.TimesheetDesc,
    } as PageMenuItem;

    const forms = {
        page: PAGE.FORMS,
        title: strings.Forms.Forms,
        icon: <FormIcon size={iconSize} color={iconColor(PAGE.FORMS)} style={style}/>,
        activeIcon: <FormIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#B76A52" }}/>,
        tooltip: strings.Menu.FormsAndDataInputDesc,
    } as PageMenuItem;

    const messages = {
        page: PAGE.MESSAGES,
        title: strings.General.Messages,
        icon: <MobileChatIcon size={iconSize} color={iconColor(PAGE.MESSAGES)} style={style}/>,
        activeIcon: <MobileChatIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#847E73" }}/>,
        tooltip: strings.Menu.MessagesDesc,
    } as PageMenuItem;

    const help = {
        page: PAGE.HELP,
        title: strings.Help.Title,
        icon: <HelpIcon size={iconSize} color={iconColor(PAGE.HELP)} style={style}/>,
        activeIcon: <HelpIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#555555" }}/>,
        tooltip: strings.Menu.HelpCenterDesc,
    } as PageMenuItem;

    const integrations = {
        page: PAGE.INTEGRATIONS,
        title: strings.General.Integrations,
        icon: <IntegrationsIcon size={iconSize} color={iconColor(PAGE.INTEGRATIONS)} style={style}/>,
        activeIcon: <IntegrationsIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#6D6964" }}/>,
        tooltip: strings.Menu.IntegrationsDesc,
    } as PageMenuItem;

    const heatmap = {
        page: PAGE.HEATMAP,
        title: strings.Map.Heatmap,
        icon: <HeatmapIcon size={iconSize} color={iconColor(PAGE.HEATMAP)} style={style}/>,
        activeIcon: <HeatmapIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#A77030" }}/>,
        tooltip: strings.Menu.HeatmapDesc,
    } as PageMenuItem;

    const uploads = {
        page: PAGE.UPLOADS,
        title: strings.General.Uploads,
        icon: <UploadsIcon size={iconSize} color={iconColor(PAGE.UPLOADS)} style={style}/>,
        activeIcon: <UploadsIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#5C7566" }}/>,
        tooltip: strings.Menu.UploadsDesc,
    } as PageMenuItem;

    const location = {
        page: PAGE.LOCATION,
        title: strings.General.LocationHistory,
        icon: <JourneyIcon size={iconSize} color={iconColor(PAGE.LOCATION)} style={style}/>,
        activeIcon: <JourneyIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#E4D00A" }}/>,
        tooltip: strings.Menu.TracksDesc,
    } as PageMenuItem;

    const activitylog = {
        page: PAGE.ACTIVITYLOG,
        title: strings.Activities.ActivityLog,
        icon: <ActivityLogIcon size={iconSize} color={iconColor(PAGE.ACTIVITYLOG)} style={style}/>,
        activeIcon: <ActivityLogIcon size={iconSize} color={COLOR_WHITE} style={{ ...style, backgroundColor: "#FFBF00" }}/>,
        tooltip: strings.Activities.ActivityLogDesc,
    } as PageMenuItem;

    return {
        live,
        dispatch,
        timeline,
        team,
        settings,
        forms,
        places,
        alerts,
        dashboard,
        reports,
        timesheet,
        help,
        messages,
        integrations,
        heatmap,
        activitylog,
        uploads,
        location,
    };
}