import { Select } from "baseui/select";
import { useTheme } from "../../hooks/useTheme";

export function StyledSelect({ overrides, ...props }: React.ComponentProps<typeof Select>) {
    const { theme } = useTheme();

    return (
        <Select
            {...props}
            overrides={{
                ...overrides,
                OptionContent: {
                    style: ({ $isHighlighted }) => ({
                        color: $isHighlighted ? theme.colors.contentPrimary : theme.colors.contentSecondary,
                    }),
                },
            }}
        />
    );
}
