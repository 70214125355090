import React from 'react';
import ReactDOM from 'react-dom/client';
import HellotracksApp from './HellotracksApp';
import './index.css';
import './styles/skeleton-layers-card.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import './styles/mapbox.css';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import RecoilAccessProvider from './providers/RecoilAccessProvider';
import { BrowserRouter } from 'react-router-dom';

const mainContainer = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(mainContainer);

root.render(
    <RecoilRoot>
        <RecoilAccessProvider/>
        <BrowserRouter>
            <HellotracksApp/>
        </BrowserRouter>
    </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
