import { Spin } from "antd";
import { LightTheme } from "baseui";
import { HeadingLarge } from "baseui/typography";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import { useJobAttachments } from "../../hooks/jobs/useJobAttachments";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { useTime } from "../../hooks/useTime";
import { hasSignature, isCheckedIn, isCheckedOut, isDone, Job } from "../../types/job";
import { Centered } from "../containers/Centered";
import { HFlex } from "../containers/HFlex";
import { SectionedWrapper } from "../containers/SectionedWrapper";
import { SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { AttachmentList } from "../shared/AttachmentList";
import { EmptyState } from "../ui/EmptyState";
import { LabeledField } from "../ui/LabeledField";
import { Section } from "../ui/Section";
import { retrieveSingleJob } from "../../services/singlejob";
import { JobSignature } from "./JobSignature";
import { PublicViewFooter } from "../shared/PublicViewFooter";
import { ShortFormResponseSection } from "./ShortFormResponseSection";
import { EMPTY_UID, Uid } from "../../types/core";

export function PrintableJob() {
    const { theme } = useTheme();
    const { companyId, jobId } = useParams();
    const { strings } = useStrings();
    const { formatDay, formatDateTime } = useTime();
    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [job, setJob] = useState<Job>();
    const { staffAttachments } = useJobAttachments(job || ({} as Job));
    const [providerName, setProviderName] = useState("");

    useEffectOnce(() => {
        const loadJob = async () => {
            const { status, job, companyName } = await retrieveSingleJob(companyId as Uid || EMPTY_UID, jobId || "");
            if (status && job) {
                setJob(job);
                setProviderName(companyName);
            } else {
                setNotFound(true);
            }
            setIsLoading(false);
        };

        loadJob();
    });

    return (
        <HFlex
            style={{
                display: "flex",
                background: LightTheme.colors.backgroundPrimary,
                overflowY: "auto",
                justifyContent: "center",
                height: "100%",
                "@media print": {
                    overflow: "visible",
                },
            }}
        >
            {isLoading && (
                <Centered>
                    <EmptyState title={strings.General.Loading}>
                        <Spin/>
                    </EmptyState>
                </Centered>
            )}
            {notFound && (
                <Centered>
                    <EmptyState title={strings.General.JobNotFound}/>
                </Centered>
            )}
            {job && (
                <VFlex
                    style={{
                        flex: 1,
                        maxWidth: "400px",
                        height: "fit-content",
                        minHeight: "100%",
                        "@media print": { maxWidth: "100%" },
                    }}
                >
                    {job.destinationName && <HeadingLarge>{job.destinationName}</HeadingLarge>}
                    <SectionedWrapper style={{ height: "auto", rowGap: theme.sizing.scale200 }}>
                        <Section>
                            {job.orderId > 0 && (
                                <LabeledField label={strings.Job.OrderId} content={job.orderId} direction="horizontal"/>
                            )}
                            <LabeledField
                                label={strings.General.Date}
                                content={formatDay(job.day)}
                                direction="horizontal"
                            />
                            {job.textDispatcher && (
                                <LabeledField
                                    label={strings.Job.Notes}
                                    content={job.textDispatcher}
                                    direction="horizontal"
                                />
                            )}
                        </Section>
                        {(job.contactName || job.contactPhone || job.contactEmail) && (
                            <Section title={strings.Job.Contact}>
                                {job.contactName && (
                                    <LabeledField
                                        label={strings.Job.ContactName}
                                        content={job.contactName}
                                        direction="horizontal"
                                    />
                                )}
                                {job.contactPhone && (
                                    <LabeledField
                                        label={strings.Job.ContactPhone}
                                        content={job.contactPhone}
                                        direction="horizontal"
                                    />
                                )}
                                {job.contactEmail && (
                                    <LabeledField
                                        label={strings.Job.Email}
                                        content={job.contactEmail}
                                        direction="horizontal"
                                    />
                                )}
                            </Section>
                        )}
                        {job.destinationText &&
                            <Section title={strings.General.Location}>
                                <LabeledField
                                    label={strings.Job.Address}
                                    content={job.destinationText}
                                    direction="horizontal"
                                />
                            </Section>
                        }
                        {(job.customFields.some(j => j.val)) && (
                            <Section title={strings.General.Info}>
                                {job.customFields.map((customField, index) => {
                                    if (customField.val) {
                                        return (
                                            <LabeledField
                                                key={index}
                                                label={customField.key}
                                                content={customField.val}
                                                direction="horizontal"
                                            />
                                        );
                                    }
                                    return null;
                                })}
                            </Section>
                        )}
                        <ShortFormResponseSection job={job} allowEdit={false}/>
                        {(isDone(job) || isCheckedIn(job) || isCheckedOut(job)) && (
                            <Section title={strings.Job.StatusHistory}>
                                {!!job.tsDoneSuccess && (
                                    <LabeledField
                                        label={strings.JobStatuses.Done}
                                        content={formatDateTime(job.tsDoneSuccess)}
                                        direction="horizontal"
                                    />
                                )}
                                {!!job.tsDoneFailed && (
                                    <LabeledField
                                        label={strings.JobStatuses.Issue}
                                        content={formatDateTime(job.tsDoneFailed)}
                                        direction="horizontal"
                                    />
                                )}
                                {!!job.tsCheckIn && (
                                    <LabeledField
                                        label={strings.General.CheckIn}
                                        content={formatDateTime(job.tsCheckIn)}
                                        direction="horizontal"
                                    />
                                )}
                                {!!job.tsCheckOut && (
                                    <LabeledField
                                        label={strings.General.CheckOut}
                                        content={formatDateTime(job.tsCheckOut)}
                                        direction="horizontal"
                                    />
                                )}
                            </Section>
                        )}
                        {!!staffAttachments.length && (
                            <Section title={strings.Job.UploadedImages}>
                                <AttachmentList attachments={staffAttachments}/>
                            </Section>
                        )}
                        {hasSignature(job, companyId) && (
                            <JobSignature job={job} printable/>
                        )}
                    </SectionedWrapper>
                    <SpacerExpand/>
                    <PublicViewFooter name={providerName}/>
                </VFlex>
            )}
        </HFlex>
    );
}