import { atomWithIndexedPersistence } from "../utils/persistence";
import { atom, selector, selectorFamily } from "recoil";
import { windowSizeState } from "./skeletonState";
import { clamp } from "../utils/math";
import { Uid } from "../types/core";

export const boardMemberUidsState = atomWithIndexedPersistence("board.member.uids", [] as Uid[]);
export const boardShowAllUnassignedState = atom({
    key: 'board.showallunassigned',
    default: false,
});

export const boardVisibleSequencesState = atom({
    key: "board.visible.squences",
    default: [] as string[],
});

export const boardColumnWidthState = selector({
    key: "board.column.width",
    get: ({ get }) => {
        const windowSize = get(windowSizeState)
        const count = get(boardMemberUidsState).length;

        const fraction = count === 1 ? 4 : 6;
        return clamp(windowSize.innerWidth / fraction, [300, 550]);
    }
});

export const boardMemberIsPresentState = selectorFamily<boolean, Uid>({
    key: "board.member.present",
    get: (uid) => ({ get }) => {
        const memberUids = get(boardMemberUidsState);
        return memberUids.includes(uid);
    },
});