import { OpenAiIcon } from "../ui/svg";
import { ActionButton } from "../buttons/ActionButton";
import { useTheme } from "../../hooks/useTheme";
import { useSetRecoilState } from "recoil";
import { assistantOpenState } from "../../states/assistantState";
import { useStrings } from "../../hooks/useStrings";

export function AssistantButton({ opacity = 1 }: {opacity?: number}) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const setModalOpen = useSetRecoilState(assistantOpenState);

    return (
        <ActionButton
            tooltip={strings.Assistant.Tooltip}
            size={"compact"}
            kind={"secondary"}
            onClick={() => setModalOpen(true)}
            opacity={opacity}
        >
            <OpenAiIcon size={14} color={theme.colors.contentPrimary}/>
        </ActionButton>
    );
}