import { useRecoilState } from "recoil";
import { jobsSearchState } from "../../states/jobsState";
import { Filter } from "baseui/icon";
import { Input } from "baseui/input";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";

export function JobSearch() {
    const { theme, isDarkTheme } = useTheme();
    const { strings } = useStrings();
    const [jobsSearch, setJobsSearch] = useRecoilState(jobsSearchState);

    return (
        <Input
            value={jobsSearch}
            onChange={({ target: { value } }) => {
                setJobsSearch(value);
            }}
            type="text"
            placeholder={strings.Job.FilterByTitleAddressOrder}
            clearable
            size={"mini"}
            startEnhancer={<Filter color={theme.colors.contentSecondary}/>}
            overrides={{
                Root: {
                    style: {
                        borderRadius: "20px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        backgroundColor: isDarkTheme ? "#00000055" : "#FFFFFF33"
                    }
                },
                Input: {
                    style: {
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        backgroundColor: "transparent",
                        '::placeholder': {
                            color: theme.colors.contentSecondary
                        }
                    }
                },
                InputContainer: {
                    style: {
                        backgroundColor: "transparent",
                    }
                },
                StartEnhancer: {
                    style: {
                        backgroundColor: "transparent"
                    }
                }
            }}
        />
    )
}