import { FormControl } from 'baseui/form-control';
import { OnChangeParams, SIZE as SELECT_SIZE, TYPE } from 'baseui/select';
import { useRecoilState, useRecoilValue } from 'recoil';
import { editingMemberIdState } from '../../../states/appState';
import { editMemberState, editMemberValidState } from '../../../states/editMemberState';
import { FlexGridLayout } from '../../containers/FlexGridLayout';
import { FormInput } from '../../ui/FormInput';
import { Section } from '../../ui/Section';
import { ProfileImage } from '../../shared/ProfileImage';
import { SectionedWrapper } from '../../containers/SectionedWrapper';
import { Member, Role } from "../../../types/member";
import { memberState } from '../../../states/membersState';
import { ADMIN_ROLE, OPERATOR_ROLE, WORKER_ROLE } from '../../../constants/app';
import { ContactFields } from '../../shared/ContactFields';
import { CredentialFields } from '../../shared/CredentialFields';
import { editAccount } from '../../../services/api';
import { useStrings } from '../../../hooks/useStrings';
import { VFlex } from '../../containers/VFlex';
import { HFlex } from "../../containers/HFlex";
import useAccount from "../../../hooks/useAccount";
import { StyledSelect } from '../../ui/StyledSelect';
import { TeamsSelect } from '../../pickers/TeamsSelect';
import { teamsState } from '../../../states/teamsState';
import { Team } from '../../../types/core';
import { IntegrationsSections } from "./IntegrationsSections";
import { ColorPicker } from "../../pickers/ColorPicker";

export function ProfileTab() {
    const editingMember = useRecoilValue(editingMemberIdState);
    const [member, setMember] = useRecoilState(memberState(editingMember));
    const [memberFields, setMemberFields] = useRecoilState(editMemberState(editingMember));
    const { strings } = useStrings()

    const validMemberFields = useRecoilValue(editMemberValidState(editingMember));

    const account = useAccount();
    const teams = useRecoilValue(teamsState);
    const selectedTeams = teams.filter((t) => memberFields.teams.includes(t.id));

    if (!member) {
        return null;
    }

    const onChangeInput = (key: string, value: string | number) => {
        setMemberFields((prev) => ({
            ...prev,
            phone_verified: key === "phone" ? false : prev.phone_verified,
            [key]: value,
        }));
    };

    const onRoleChange = ({ option }: OnChangeParams) => {
        setMemberFields((prev) => ({ ...prev, role: option?.id as Role }));
    };

    const onTeamsChange = (teams: Team[]) => {
        setMemberFields((prev) => ({
            ...prev,
            teams: teams.map((t) => t.id),
        }));
    }

    const handleNewPhone = async () => {
        const { status, account: updatedCompany } = await editAccount({ uid: member.uid, phone: memberFields.phone });
        if (status) {
            setMember(updatedCompany as Member);
        }
        return { status };
    }

    const handlePhoneVerified = async () => {
        const { status, account: updatedMember } = await editAccount({ uid: member.uid, phone_verified: true });
        if (status) {
            setMember(updatedMember as Member);
            setMemberFields((prev) => ({ ...prev, phone_verified: true }));
        }
        return { status };
    }

    return (
        <SectionedWrapper>
            <Section>
                <HFlex spacing={"48px"} style={{ alignItems: "start" }}>
                    <ProfileImage
                        uid={memberFields.uid}
                        name={memberFields.name}
                        portrait={memberFields.portrait}
                        color={memberFields.color}
                    />
                    <VFlex style={{ flex: 1, height: "auto" }}>
                        <FlexGridLayout baseWidth={200}>
                            <FormInput
                                label={strings.General.Name}
                                value={memberFields.name}
                                onChange={onChangeInput}
                                field="name"
                            />
                            <FormControl label={strings.General.Role}>
                                <StyledSelect
                                    closeOnSelect
                                    size={SELECT_SIZE.compact}
                                    clearable={false}
                                    placeholder
                                    options={[
                                        { label: strings.General.Admin, id: ADMIN_ROLE },
                                        { label: strings.General.Operator, id: OPERATOR_ROLE },
                                        { label: strings.ProfileModal.Worker, id: WORKER_ROLE },
                                    ]}
                                    value={[{ id: memberFields.role || WORKER_ROLE }]}
                                    openOnClick
                                    type={TYPE.select}
                                    onChange={onRoleChange}
                                    disabled={!account.isSelfAdminOrCompany || account.isSelfAccount(member.uid)}
                                />
                            </FormControl>
                        </FlexGridLayout>
                        <FormControl label={strings.General.Teams}>
                            <TeamsSelect
                                placeholder={`${strings.General.AssignToTeams}...`}
                                selectedTeams={selectedTeams}
                                onSelect={(teams) => onTeamsChange(teams as Team[])}
                            />
                        </FormControl>
                        <FormControl label={strings.General.Color}>
                            <ColorPicker onChange={(value) => onChangeInput("color", value)} selectedColor={memberFields.color}/>
                        </FormControl>
                    </VFlex>
                </HFlex>
            </Section>

            <Section title={strings.General.Credentials}>
                <CredentialFields member={member}
                                  emailIsVerified={memberFields.email_verified}
                                  phoneIsVerified={memberFields.phone_verified}
                />
            </Section>

            <Section title={strings.General.Contact}>
                <ContactFields
                    uid={memberFields.uid}
                    emailValue={memberFields.email}
                    emailIsVerified={memberFields.email_verified}
                    emailIsValid={validMemberFields['email']}
                    phoneValue={memberFields.phone}
                    phoneIsVerified={memberFields.phone_verified}
                    onFieldChange={onChangeInput}
                    onPhoneVerified={handlePhoneVerified}
                    onNewPhone={handleNewPhone}
                />
            </Section>
            <IntegrationsSections/>
        </SectionedWrapper>
    );
}
