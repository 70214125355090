import { useEffectOnce } from 'usehooks-ts';

export function useEscTrigger(action: () => void) {
    useEffectOnce(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                action();
            }
        }
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    });

    return null;
}
