import { loadSequences } from "../../services/api";
import { Job } from "../../types/job";
import { setRecoil } from "../../providers/RecoilAccessProvider";
import { sequenceIdsState, sequenceState } from "../../states/sequencesState";
import { useStateUpdater } from "../useStateUpdater";

export function useSequenceUpdater() {
    const stateUpdater = useStateUpdater();

    const updateSequences = (callback?: Function) => {
        loadSequences().then(({ status, sequences }) => {
            if (status) {
                const jobs = [] as Job[];
                sequences.forEach(seq => {
                    seq.jobs.forEach(j => jobs.push(j));
                    setRecoil(sequenceState(seq.id), seq);
                });
                stateUpdater.updateJobs(jobs);
                setRecoil(sequenceIdsState, sequences.map(s => s.id));
                if (callback) {
                    callback(sequences);
                }
            }
        });
    };

    return { updateSequences };
}