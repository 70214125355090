import { Card, StyledBody } from "baseui/card";
import { useRecoilValue } from "recoil";
import { memberSearchState } from "../../states/membersState";
import { useRef, useState } from "react";
import { HFlex } from "../containers/HFlex";
import { Button } from "baseui/button";
import { ChevronDown, ChevronRight } from "baseui/icon";
import { LabelLarge } from "baseui/typography";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { Badge } from "baseui/badge";
import { VFlex } from "../containers/VFlex";
import { TrashIcon } from "../ui/svg";
import { FormInput } from "../ui/FormInput";
import { ClipboardButton } from "../buttons/ClipboardButton";
import { ItemButton } from "../buttons/ItemButton";
import { stringsState } from "../../states/localeState";
import { validInviteCodeState } from "../../states/inviteCodeState";
import { HTTPS_DOMAIN } from "../../services/api";
import { useInviteCode } from "../../hooks/useInviteCode";
import { QRCode } from "antd";
import { useTheme } from "../../hooks/useTheme";

export function InviteCodeSection() {
    const isFiltering = useRecoilValue(memberSearchState).length > 0;
    const [expanded, setExpanded] = useState(false);
    const hoverRef = useRef(null);
    const { theme } = useTheme();
    const strings = useRecoilValue(stringsState);
    const inviteCodeData = useRecoilValue(validInviteCodeState);
    const { newInviteCode, deleteInviteCode } = useInviteCode();
    const [loading, setLoading] = useState(false);
    const memberSearch = useRecoilValue(memberSearchState);

    if (memberSearch.length > 0) {
        return null;
    }

    const onNewInviteCodeClick = async () => {
        setLoading(true);
        await newInviteCode();
        setLoading(false);
    }

    const inviteCodeLink = inviteCodeData && `${HTTPS_DOMAIN}/get/app/?${inviteCodeData.inviteCode}`;
    const expirationDifference = inviteCodeData && inviteCodeData.expirationTs - new Date().getTime();
    const daysToExpire = inviteCodeData && inviteCodeData && (expirationDifference! / (1000 * 60 * 60 * 24)).toFixed()

    const onDeleteClick = async () => {
        setLoading(true);
        inviteCodeData && await deleteInviteCode(inviteCodeData.inviteCode);
        setLoading(false);
    };

    const inviteCodeWithDash = inviteCodeData?.inviteCode && inviteCodeData?.inviteCode.length === 6
        ? inviteCodeData.inviteCode.substring(0, 3) + "-" + inviteCodeData.inviteCode.substring(3, 6)
        : inviteCodeData?.inviteCode;

    const title = (
        <div ref={hoverRef}>
            <HFlex alignCenter>
                <Button
                    size="compact"
                    kind="tertiary"
                    onClick={() => setExpanded(!expanded)}
                    shape="square"
                >
                    {expanded ? <ChevronDown size={18}/> : <ChevronRight size={18}/>}
                </Button>
                <LabelLarge>
                    {inviteCodeData ? strings.TeamManagement.InviteCode : strings.TeamManagement.NoActiveInviteCode}
                </LabelLarge>
                <Spacer/>
                {inviteCodeData && <Badge content={inviteCodeWithDash}/>}
                <SpacerExpand/>
                {inviteCodeData && expanded &&
                    <>
                        <ItemButton tooltip={strings.General.Delete} onClick={onDeleteClick}>
                            <TrashIcon size={16} color={theme.colors.contentTertiary}/>
                        </ItemButton>
                    </>
                }
            </HFlex>
        </div>
    );

    return (
        <Card title={title}>
            {(expanded || isFiltering) &&
                <StyledBody>
                    <VFlex alignCenter>
                        {strings.TeamManagement.ManageInviteCodesDesc}
                        <Spacer/>
                        {inviteCodeLink && daysToExpire &&
                            <>
                                <QRCode value={inviteCodeLink}/>
                                <Spacer/>
                                <FormInput
                                    label={""}
                                    value={inviteCodeLink}
                                    endEnhancer={<ClipboardButton content={inviteCodeLink}/>}
                                    field="readonly"
                                    caption={strings.formatString(strings.TeamManagement.ThisInviteCodesExpiresInXDays, daysToExpire)}
                                />
                            </>
                        }
                        {!inviteCodeData && <Button size={"compact"} isLoading={loading} onClick={onNewInviteCodeClick}>
                            {strings.TeamManagement.CreateNewInviteCode}
                        </Button>}
                    </VFlex>
                </StyledBody>
            }
        </Card>
    );
}