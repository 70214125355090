import { useRecoilValue } from "recoil";
import { memberState } from "../../states/membersState";
import { Tag, Tooltip } from "antd";
import { pageState } from "../../states/appState";
import { PAGE } from "../../constants/pages";
import { isDeviceResponsive, isIdling } from "../../types/member";
import { useStrings } from "../../hooks/useStrings";
import { SignalIcon } from "../ui/svg";
import { COLOR_DANGER } from "../../constants/colors";
import { RoleBadge } from "../badges/RoleBadge";
import { tinyTag } from "../../constants/ui";
import { LastLocationBadge } from "../badges/LastLocationBadge";
import { HFlex } from "../containers/HFlex";
import { VFlex } from "../containers/VFlex";
import { MemberInfoBadge } from "../badges/MemberInfoBadge";
import { memo } from "react";
import { Uid } from "../../types/core";

type Props = {
    uid: Uid;
    lastLocation: boolean;
    vertical?: boolean;
}

export const MemberInfo = memo(({ uid, lastLocation, vertical = false }: Props) => {
    const member = useRecoilValue(memberState(uid));
    const page = useRecoilValue(pageState);
    const { strings } = useStrings();

    if (!member) {
        return null;
    }

    const content = (
        <>
            <RoleBadge uid={uid} showWorker={false} tiny/>
            <MemberInfoBadge uid={uid}/>
            {lastLocation && <LastLocationBadge memberUid={member.uid} tiny/>}
            {member.status_label && <Tag style={tinyTag} color={"gold"}>{member.status_label}</Tag>}
            {page === PAGE.TEAM && isIdling(member) && !isDeviceResponsive(member) &&
                <Tooltip title={strings.General.DeviceNotResponding}>
                    <Tag style={tinyTag}>
                        <SignalIcon size={10} color={COLOR_DANGER}/>
                    </Tag>
                </Tooltip>
            }
        </>
    );

    if (vertical) {
        return (
            <VFlex spacing={"4px"} style={{ alignItems: "start" }}>
                {content}
            </VFlex>
        );
    } else {
        return (
            <HFlex spacing={"4px"}>
                {content}
            </HFlex>
        );
    }
});