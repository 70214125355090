import { memo, ReactNode, useState } from 'react';
import { Button } from 'baseui/button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { useRecoilValue } from 'recoil';
import { stringsState } from '../../states/localeState';
import { useTheme } from "../../hooks/useTheme";

type Props = {
    isOpen: boolean;
    description: ReactNode;
    title?: ReactNode;
    confirmText?: ReactNode;
    cancelText?: ReactNode;
    canConfirm?: boolean;
    onConfirm?: () => Promise<void> | void;
    onCancel?: () => void;
    danger?: boolean;
    children?: ReactNode;
};

export const ConfirmModal = memo(({
                                 isOpen,
                                 description,
                                 confirmText,
                                 cancelText,
                                 onConfirm,
                                 onCancel,
                                 title,
                                 canConfirm = true,
                                 danger = false,
                                 children = null
                             }: Props) => {
    const strings = useRecoilValue(stringsState);
    const [loading, setLoading] = useState(false);
    const { theme } = useTheme();

    if (!title) {
        title = strings.General.Confirm;
    }
    if (!confirmText) {
        confirmText = strings.General.Yes;
    }
    if (!cancelText) {
        cancelText = strings.General.No;
    }


    return (
        <Modal closeable={false} isOpen={isOpen} overrides={{
            Dialog: {
                style: {
                    backgroundColor: theme.colors.backgroundSecondary
                }
            }
        }}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                {description}
                {children}
            </ModalBody>
            <ModalFooter>
                {onCancel && (
                    <Button
                        kind="tertiary"
                        size='compact'
                        onClick={onCancel}
                        overrides={{
                            BaseButton: {
                                style: ({ $theme }) => ({
                                    marginRight: $theme.sizing.scale400,
                                }),
                            },
                        }}
                    >
                        {cancelText}
                    </Button>
                )}
                {onConfirm && <Button
                    onClick={async () => {
                        setLoading(true);
                        await onConfirm();
                        setLoading(false);
                    }}
                    size='compact'
                    isLoading={loading}
                    disabled={!canConfirm}
                    overrides={{
                        BaseButton: {
                            style: ({ $theme }) => danger ? ({
                                backgroundColor: $theme.colors.warning
                            }) : undefined,
                        }
                    }}
                >
                    {confirmText}
                </Button>}
            </ModalFooter>
        </Modal>
    );
});
