import { Descriptions, Statistic } from "antd";
import { isAdmin, isIdling, isOnTheWay, isOperator, isWorker, Member } from "../../../types/member";
import { COLOR_IDLING, COLOR_MOVING } from "../../../constants/colors";
import { membersDangerousState } from "../../../states/membersState";
import { useTheme } from "../../../hooks/useTheme";
import { useStrings } from "../../../hooks/useStrings";
import { getRecoil } from "../../../providers/RecoilAccessProvider";
import { useEffectOnce, useInterval } from "usehooks-ts";
import { useState } from "react";

export function LiveMemberStats() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const [members, setMembers] = useState<Member[]>([]);

    useInterval(() => setMembers(getRecoil(membersDangerousState)), 20_000);
    useEffectOnce(() => setMembers(getRecoil(membersDangerousState)));

    return (
        <Descriptions bordered column={3} size="small">
            <Descriptions.Item label={strings.Stats.TotalMembers}>
                <Statistic value={members.length}
                           valueStyle={{ color: theme.colors.contentPrimary, whiteSpace: 'nowrap' }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.General.OnTheWay}>
                <Statistic value={members.filter(m => isOnTheWay(m)).length}
                           valueStyle={{ color: COLOR_MOVING, whiteSpace: 'nowrap' }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.General.Stationary}>
                <Statistic value={members.filter(m => isIdling(m)).length}
                           valueStyle={{ color: COLOR_IDLING, whiteSpace: 'nowrap' }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.General.Admin}>
                <Statistic value={members.filter(m => isAdmin(m.role)).length}
                           valueStyle={{ color: theme.colors.contentTertiary, whiteSpace: 'nowrap' }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.General.Operator}>
                <Statistic value={members.filter(m => isOperator(m.role)).length}
                           valueStyle={{ color: theme.colors.contentTertiary, whiteSpace: 'nowrap' }}
                />
            </Descriptions.Item>
            <Descriptions.Item label={strings.ProfileModal.Worker}>
                <Statistic value={members.filter(m => isWorker(m.role)).length}
                           valueStyle={{ color: theme.colors.contentTertiary, whiteSpace: 'nowrap' }}
                />
            </Descriptions.Item>
        </Descriptions>
    );
}