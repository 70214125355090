import { atom, selectorFamily } from "recoil";
import { Trip } from "../types/trip";

export const tripsState = atom<Trip[]>({
    key: 'trips.all',
    default: [],
});

export const newestTripForAccountState = selectorFamily<Trip | undefined, string>({
    key: 'trips.newest',
    get: (uid) => ({ get }) => {
        const allTrips = get(tripsState);
        const trips = allTrips
            .filter(t => t.accountUid === uid)
            .sort((t1, t2) => t2.createdTs > t1.createdTs ? 1 : -1);
        return trips.length > 0 ? trips[0] : undefined;
    }
});

export const tripState = selectorFamily<Trip | undefined, string>({
    key: 'trips.id',
    get: (tripId) => ({ get }) => {
        return get(tripsState).find(t => t.id === tripId);
    }
});

export const tripEventState = atom({
    key: "trip.event",
    default: 0,
})