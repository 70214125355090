import { VFlex } from "../components/containers/VFlex";
import { TracksBulkRow } from "../components/location/TracksBulkRow";
import { useBoard } from "../hooks/useBoard";
import { useStrings } from "../hooks/useStrings";
import { MembersSelect } from "../components/pickers/MembersSelect";
import { LocationFilterRow } from "../components/location/LocationFilterRow";
import { useRecoilValue } from "recoil";
import React from "react";
import MemberList from "../components/member/MemberList";
import { LocationView } from "../components/location/LocationView";
import { TimelineView } from "./TimelineView";
import { pageState } from "../states/appState";
import { PAGE } from "../constants/pages";


export function HistoryView() {
    const { strings } = useStrings();
    const board = useBoard();
    const page = useRecoilValue(pageState);

    return (
        <VFlex spacing>
            <MembersSelect
                selectedMemberUids={board.memberUids}
                onMemberSelect={(members) => members && board.setMemberUids(members.map(m => m.uid))}
                placeholder={strings.Members.SelectMembers}/>
            {board.memberUids.length === 0 ?
                <MemberList/>
                : (
                    <>
                        {page === PAGE.LOCATION && <LocationFilterRow/>}
                        {page === PAGE.LOCATION && <TracksBulkRow/>}
                        {page === PAGE.LOCATION && <LocationView/>}
                        {page === PAGE.TIMELINE && <TimelineView/>}
                    </>
                )
            }
        </VFlex>
    );
}