import { useState } from 'react';


export function useAbortController() {
    const [controller, setController] = useState(new AbortController());

    const cancelRequest = () => {
        controller.abort();
        setController(new AbortController());
    }

    return { controller, cancelRequest };
}