import { Button } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import { Input } from "baseui/input";
import { HeadingLarge, LabelLarge, ParagraphMedium } from "baseui/typography";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import useAccount from "../../hooks/useAccount";
import { useSignup } from "../../hooks/useSignup";
import { useSignupOptionalSteps } from "../../hooks/useSignupOptionalSteps";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { STATUS_ERROR_PASSWORDMISMATCH, STATUS_ERROR_USERALREADYEXISTS } from "../../services/api";
import { signupStepState } from "../../states/signupState";
import { VFlex } from "../containers/VFlex";
import { AccountStep } from "./AccountStep";
import { StylishFormControl } from "./ui/StylishFormControl";
import { StylishInput } from "./ui/StylishInput";
import { PasswordStrengthBar } from "../ui/PasswordStrengthBar";

export function AccountSetup() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const setStep = useSetRecoilState(signupStepState);
    const account = useAccount();
    const { userIsAvailable, hasEmailError, passwordValid, createAccount, personalize, isLoading } = useSignup();
    const [usernameError, setUsernameError] = useState("");
    const [loginError, setLoginError] = useState("");
    const { optionalSteps } = useSignupOptionalSteps();
    const initialOptionalFieldValues: { [key: string]: string } = Object.assign(
        {},
        ...optionalSteps.map((step) => {
            const fields = { [step.fieldName]: "" };
            if (step.customOption) {
                fields[`other-${step.fieldName}`] = "";
            }
            return fields;
        })
    );
    const [optionalFieldValues, setOptionalFieldValues] = useState(initialOptionalFieldValues);

    const prevStep = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const resetErrors = () => {
        setUsernameError("");
        setLoginError("");
    };

    const highlightColor = "#FFF3";

    const onSkipAllClick = () => {
        setStep(optionalSteps.length + 5);
    }

    const sharedOptionStyles = {
        backgroundColor: "transparent",
        borderTopRightRadius: theme.sizing.scale900,
        borderTopLeftRadius: theme.sizing.scale900,
        borderBottomRightRadius: theme.sizing.scale900,
        borderBottomLeftRadius: theme.sizing.scale900,
        borderTopWidth: "3px",
        borderLeftWidth: "3px",
        borderRightWidth: "3px",
        borderBottomWidth: "3px",
        borderTopStyle: "solid",
        borderLeftStyle: "solid",
        borderRightStyle: "solid",
        borderBottomStyle: "solid",
        transitionProperty: "color border-color",
        transitionTimingFunction: "ease-in-out",
        transitionDuration: "100ms",
        ":hover": {
            backgroundColor: highlightColor,
            borderTopColor: theme.colors.contentPrimary,
            borderLeftColor: theme.colors.contentPrimary,
            borderRightColor: theme.colors.contentPrimary,
            borderBottomColor: theme.colors.contentPrimary,
            color: theme.colors.contentPrimary,
        },
        ":active": {
            active: "transparent",
            backgroundColor: "transparent",
        },
    };

    return (
        <div
            style={{
                display: "flex",
                overflow: "hidden",
                minHeight: "45%",
                width: "100%",
                flexWrap: "nowrap",
            }}
        >
            <AccountStep
                title={strings.Signup.NameTitle}
                action={() => setStep(2)}
                actionText={strings.Signup.Continue}
                step={1}
                actionDisabled={!account.name}
            >
                <StylishFormControl label={strings.Signup.Description}>
                    <StylishInput
                        placeholder={strings.Signup.NamePlaceholder}
                        name="name"
                        value={account.name}
                        onChange={(event) => account.setName(event.target.value)}
                        autoFocus
                    />
                </StylishFormControl>
            </AccountStep>
            <AccountStep
                title={strings.Signup.EmailTitle}
                action={async () => {
                    const { error, available } = await userIsAvailable();
                    if (error) {
                        setUsernameError(strings.Signup.SomethingWentWrong);
                    } else if (!available) {
                        setUsernameError(strings.Signup.EmailAlreadyUsed);
                    } else {
                        resetErrors();
                        setStep(3);
                    }
                }}
                actionText={strings.Signup.Continue}
                back={prevStep}
                step={2}
                actionDisabled={hasEmailError || !account.username}
                isLoading={isLoading}
            >
                <StylishFormControl
                    error={hasEmailError ? strings.General.PleaseEnterAValidEmailAddress : usernameError}
                >
                    <StylishInput
                        placeholder={strings.Signup.EmailPlaceholder}
                        name="username"
                        value={account.username}
                        type="email"
                        onChange={(event) => {
                            account.setUsername(event.target.value);
                            resetErrors();
                        }}
                    />
                </StylishFormControl>
            </AccountStep>
            <AccountStep
                title={strings.Signup.PasswordTitle}
                action={async () => {
                    const { errorCode } = await createAccount();
                    if (errorCode && errorCode === STATUS_ERROR_USERALREADYEXISTS) {
                        setLoginError(strings.General.UserIsAlreadyRegistered);
                    } else if (errorCode && errorCode === STATUS_ERROR_PASSWORDMISMATCH) {
                        setLoginError(strings.General.PasswordTooCommon);
                    } else if (errorCode) {
                        setLoginError(strings.General.SomethingWentWrong);
                    } else {
                        setStep(4);
                    }
                }}
                actionText={strings.Signup.CreateAccount}
                back={prevStep}
                step={3}
                actionDisabled={!account.password || !passwordValid.valid}
                isLoading={isLoading}
                footer={
                    <ParagraphMedium
                        color={theme.colors.contentSecondary}
                        dangerouslySetInnerHTML={{ __html: strings.Signup.Terms }}
                    />
                }
            >
                <StylishFormControl error={loginError}>
                    <VFlex>
                        <StylishInput
                            placeholder={strings.General.YourPassword}
                            name="password"
                            value={account.password}
                            type="password"
                            onChange={(event) => {
                                account.setPassword(event.target.value);
                                resetErrors();
                            }}
                        />
                        <PasswordStrengthBar password={account.password}/>
                    </VFlex>
                </StylishFormControl>
            </AccountStep>
            <AccountStep
                title={strings.Signup.WelcomeDescription1}
                action={() => setStep(5)}
                actionText={strings.Signup.Continue}
                step={4}
                actionDisabled={false}
                content={
                    <>
                        <LabelLarge
                            color={theme.colors.contentSecondary}
                            style={{
                                fontSize: theme.typography.HeadingXSmall.fontSize,
                                lineHeight: theme.typography.HeadingXSmall.lineHeight,
                            }}
                        >
                            {strings.Signup.WelcomeTitle}
                        </LabelLarge>
                        <LabelLarge
                            color={theme.colors.contentSecondary}
                            style={{
                                fontSize: theme.typography.HeadingXSmall.fontSize,
                                lineHeight: theme.typography.HeadingXSmall.lineHeight,
                            }}
                        >
                            {strings.Signup.WelcomeDescription2}
                        </LabelLarge>
                    </>
                }
            />
            {optionalSteps.map((step, index) => (
                <AccountStep
                    key={index}
                    title={step.title}
                    action={() => setStep(index + 6)}
                    actionText={strings.Signup.Continue}
                    step={index + 5}
                    actionDisabled={false}
                    skipAll={onSkipAllClick}
                >
                    <VFlex spacing style={{ height: "auto" }}>
                        <ButtonGroup
                            mode="radio"
                            selected={step.options.findIndex(
                                (option) => option.value === optionalFieldValues[step.fieldName]
                            )}
                            onClick={(_event, index) => {
                                setOptionalFieldValues((prevValues) => {
                                    const newValues = {
                                        ...prevValues,
                                        [step.fieldName]: step.options[index].value,
                                    };
                                    if (step.customOption) {
                                        newValues[`other-${step.fieldName}`] = "";
                                    }
                                    return newValues;
                                });
                            }}
                            overrides={{
                                Root: {
                                    style: {
                                        flexDirection: "column",
                                        rowGap: theme.sizing.scale300,
                                    },
                                },
                            }}
                        >
                            {step.options.map((option) => (
                                <Button key={option.value} shape="pill" overrides={{
                                    BaseButton: {
                                        style: ({ $isSelected }) => ({
                                            ...sharedOptionStyles,
                                            justifyContent: "flex-start",
                                            backgroundColor: $isSelected ? "#FFF3" : "transparent",
                                            borderTopColor: $isSelected ? theme.colors.contentPrimary : highlightColor,
                                            borderLeftColor: $isSelected ? theme.colors.contentPrimary : highlightColor,
                                            borderRightColor: $isSelected ? theme.colors.contentPrimary : highlightColor,
                                            borderBottomColor: $isSelected ? theme.colors.contentPrimary : highlightColor,
                                            color: $isSelected ? theme.colors.contentPrimary : theme.colors.contentTertiary,
                                        })
                                    }
                                }}>{option.description}</Button>
                            ))}
                        </ButtonGroup>
                        {step.customOption && (
                            <Input
                                value={optionalFieldValues[`other-${step.fieldName}`]}
                                placeholder={strings.Signup.Other}
                                onChange={(event) => {
                                    setOptionalFieldValues((prevValues) => ({
                                        ...prevValues,
                                        [step.fieldName]: "",
                                        [`other-${step.fieldName}`]: event.target.value,
                                    }));
                                }}
                                overrides={{
                                    Root: {
                                        style: ({ $isSelected, $isFocused }) => {
                                            const hasValue = !!optionalFieldValues[`other-${step.fieldName}`];
                                            return {
                                                ...sharedOptionStyles,
                                                height: "52px",
                                                borderTopColor: $isSelected || $isFocused || hasValue ? theme.colors.contentPrimary : theme.colors.contentTertiary,
                                                borderLeftColor: $isSelected || $isFocused || hasValue ? theme.colors.contentPrimary : theme.colors.contentTertiary,
                                                borderRightColor: $isSelected || $isFocused || hasValue ? theme.colors.contentPrimary : theme.colors.contentTertiary,
                                                borderBottomColor: $isSelected || $isFocused || hasValue ? theme.colors.contentPrimary : theme.colors.contentTertiary,
                                                backgroundColor: $isSelected || $isFocused || hasValue ? highlightColor : "transparent",
                                                color: $isSelected || $isFocused || hasValue ? theme.colors.contentPrimary : theme.colors.contentTertiary,
                                            }
                                        }
                                    },
                                    InputContainer: {
                                        style: {
                                            backgroundColor: "transparent",
                                        }
                                    }
                                }}
                            />
                        )}
                    </VFlex>
                </AccountStep>
            ))}
            <AccountStep
                action={() => {
                    const data: { [key: string]: string } = {};
                    optionalSteps.forEach((step) => {
                        data[step.fieldName] = optionalFieldValues[step.fieldName];
                        if (step.customOption) {
                            data[step.fieldName] += optionalFieldValues[`other-${step.fieldName}`];
                        }
                    });
                    personalize(data);
                }}
                actionText={strings.Signup.ToDashboard}
                step={optionalSteps.length + 5}
                actionDisabled={false}
                isLoading={isLoading}
                content={
                    <>
                        <HeadingLarge margin={0}>{strings.Signup.DoneTitle}</HeadingLarge>
                        <LabelLarge marginBottom={theme.sizing.scale600} color={theme.colors.contentSecondary}>
                            {strings.Signup.DoneDescription}
                        </LabelLarge>
                    </>
                }
            />
        </div>
    );
}
