import { SIZE as SELECT_SIZE } from "baseui/input/constants";
import { OnChangeParams, TYPE } from "baseui/select";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { editingJobTypesState } from "../../states/appState";
import { jobTypeOptionsState } from "../../states/optionsState";
import { ActionButton } from "../buttons/ActionButton";
import { HFlex } from "../containers/HFlex";
import { JobTypesManagerModal } from "../job/modal/JobTypesManagerModal";
import { StyledSelect } from "../ui/StyledSelect";
import { EditIcon } from "../ui/svg";

type Props = {
    type?: number;
    isLoading?: boolean;
    canDelete?: boolean;
    onTypeChange: (params: OnChangeParams) => unknown | undefined,
}

export function JobTypeSelect({ type = 0, isLoading = false, canDelete = false, onTypeChange }: Props) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const setEditingJobTypes = useSetRecoilState(editingJobTypesState);
    const value = type >= 0 ? [{ label: '', id: type }] : [];
    const jobTypeOptions = useRecoilValue(jobTypeOptionsState);

    return (
        <HFlex spacing={theme.sizing.scale200}>
            <StyledSelect
                closeOnSelect
                size={SELECT_SIZE.compact}
                clearable={false}
                creatable={true}
                placeholder={strings.General.Type}
                options={jobTypeOptions}
                value={value}
                openOnClick
                type={TYPE.select}
                onChange={onTypeChange}
                isLoading={isLoading}
            />
            {canDelete && (
                <>
                    <ActionButton
                        tooltip={strings.Job.EditJobTypes}
                        onClick={() => setEditingJobTypes(true)}
                        kind="secondary"
                        size="compact"
                    >
                        <EditIcon size={12} color={theme.colors.contentPrimary}/>
                    </ActionButton>
                    <JobTypesManagerModal/>
                </>
            )}
        </HFlex>
    );
}