import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useUpdateEffect } from "usehooks-ts";
import { useCamera } from "../../hooks/useCamera";
import { useStrings } from "../../hooks/useStrings";
import { useZoneSelection } from "../../hooks/zones/useZoneSelection";
import { editZoneState } from "../../states/zoneState";
import { ZonesBadges } from "../badges/ZonesBadges";
import { HFlex } from "../containers/HFlex";
import { VFlex } from "../containers/VFlex";
import { RemoveIcon } from "../ui/svg";
import { ZonesBulkEdit } from "./ZonesBulkEdit";
import { useDrawMode } from "../../hooks/useDrawMode";

export function ZonesEditRow() {
    const { strings, format } = useStrings();
    const { selectionCount, selectedUids, clearSelection, selectedZone } = useZoneSelection();
    const [bulkEditExpanded, setExpandedBulkEdit] = useState(false);
    const [, theme] = useStyletron();
    const camera = useCamera();
    const editZone = useSetRecoilState(editZoneState);
    const { enterDrawMode } = useDrawMode();

    useUpdateEffect(() => {
        if (selectionCount === 0) {
            setExpandedBulkEdit(false);
        }
    }, [selectionCount]);

    const onEditRegion = () => {
        if (!selectedZone) {
            return;
        }
        clearSelection();
        camera.fitToLngLats(selectedZone.points)
        editZone(selectedZone);
        enterDrawMode({
            type: "zone",
            action: "edit",
            coordinates: selectedZone.points
        })
    };

    return (
        <VFlex style={{ rowGap: theme.sizing.scale300, height: "auto" }}>
            <HFlex spacing>
                <ZonesBadges uids={selectedUids} max={4} closable>
                    {selectedUids.length > 0 && (
                        <Button size="mini"
                                kind={"tertiary"}
                                shape={"pill"}
                                onClick={() => clearSelection()}>
                            <RemoveIcon size={14} color={theme.colors.contentTertiary}/>
                        </Button>
                    )}
                </ZonesBadges>
                {!bulkEditExpanded &&
                    <>
                        {selectedZone && (
                            <Button
                                size="mini"
                                kind="primary"
                                shape="pill"
                                onClick={onEditRegion}
                                style={{
                                    whiteSpace: "nowrap",
                                    paddingRight: theme.sizing.scale600,
                                    paddingLeft: theme.sizing.scale600
                                }}
                            >
                                {strings.General.EditRegion}
                            </Button>
                        )}
                        <Button size="mini" kind={'primary'} shape={'pill'} style={{ width: "180px" }}
                                onClick={() => setExpandedBulkEdit(true)}>
                            {format(strings.Zones.EditXZones, selectionCount)}
                        </Button>
                    </>
                }
            </HFlex>
            {bulkEditExpanded && <ZonesBulkEdit onFinish={() => setExpandedBulkEdit(false)}/>}
        </VFlex>
    );
}