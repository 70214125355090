import { editZoneHasModifiedState, editZoneState } from './../states/editZoneState';
import {
    companyTabFilterState,
    editingPlaceIdState,
    editingZoneIdState,
    memberRoutingOnlyState
} from './../states/appState';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
    editingCompanyIdState,
    editingJobIdState,
    editingMemberIdState,
    jobActiveTabState,
    memberActiveTabState,
    modalContentState
} from "../states/appState";
import { editCompanyHasModifiedState, editCompanyState } from "../states/editCompanyState";
import { editJobHasModifiedState, editJobState } from "../states/editJobState";
import { editMemberHasModifiedState, editMemberProfileImageState, editMemberState } from "../states/editMemberState";
import { editPlaceHasModifiedState, editPlaceState } from '../states/editPlaceState';
import { useDrawMode } from "./useDrawMode";
import { EMPTY_UID } from "../types/core";


export function useModal() {
    const modalContent = useRecoilValue(modalContentState);
    const [editingMember, setEditingMember] = useRecoilState(editingMemberIdState);
    const [editingJob, setEditingJob] = useRecoilState(editingJobIdState);
    const resetMemberActiveTab = useResetRecoilState(memberActiveTabState);
    const resetFieldsForMemberEdit = useResetRecoilState(editMemberState(editingMember));
    const resetJobActiveTab = useResetRecoilState(jobActiveTabState);
    const resetFieldsForJobEdit = useResetRecoilState(editJobState(editingJob || ''));
    const resetMemberProfileImage = useResetRecoilState(editMemberProfileImageState);
    const setEditingCompany = useSetRecoilState(editingCompanyIdState);
    const resetFieldsForCompanyEdit = useResetRecoilState(editCompanyState);
    const jobHasChanges = useRecoilValue(editJobHasModifiedState);
    const companyHasChanges = useRecoilValue(editCompanyHasModifiedState);
    const memberHasChanges = useRecoilValue(editMemberHasModifiedState);
    const resetRoutingOnly = useResetRecoilState(memberRoutingOnlyState);
    const resetCompanyTabFilter = useResetRecoilState(companyTabFilterState);
    const placeHasChanges = useRecoilValue(editPlaceHasModifiedState);
    const [editingPlace, setEditingPlace] = useRecoilState(editingPlaceIdState);
    const resetFieldsForPlaceEdit = useResetRecoilState(editPlaceState(editingPlace || ''));
    const editingZone = useRecoilValue(editingZoneIdState);
    const resetEditingZone = useResetRecoilState(editingZoneIdState);
    const resetFieldsForZoneEdit = useResetRecoilState(editZoneState(editingZone || ''));
    const zoneHasChanges = useRecoilValue(editZoneHasModifiedState);
    const { reset } = useDrawMode();

    const getCloseIsSafely = () => {
        if (modalContent === 'member') {
            return !memberHasChanges;
        }
        if (modalContent === 'job') {
            return !jobHasChanges;
        }
        if (modalContent === 'company') {
            return !companyHasChanges;
        }
        if (modalContent === 'place') {
            return !placeHasChanges;
        }
        if (modalContent === 'zone') {
            return !zoneHasChanges;
        }
        return true;
    }

    const resetFields = () => {
        if (modalContent === 'member') {
            setEditingMember(EMPTY_UID);
            resetMemberActiveTab();
            resetFieldsForMemberEdit();
            resetMemberProfileImage();
            resetRoutingOnly();
        }
        if (modalContent === 'job') {
            setEditingJob(undefined);
            resetJobActiveTab();
            resetFieldsForJobEdit();
        }
        if (modalContent === 'company') {
            setEditingCompany('');
            resetFieldsForCompanyEdit();
            resetCompanyTabFilter();
        }
        if (modalContent === 'place') {
            setEditingPlace(undefined);
            resetFieldsForPlaceEdit();
        }
        if (modalContent === 'zone') {
            resetEditingZone();
            resetFieldsForZoneEdit()
        }
    }

    const resetDraw = () => {
        reset();
    }

    const closeModal = () => {
        if (editingJob === "" || editingPlace === "") {
            resetDraw();
        }
        resetFields();
    }

    return {
        closeModal,
        cancelModal: resetFields,
        getCloseIsSafely
    }
}