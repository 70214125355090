import React, { memo } from "react";
import { MenuContainer } from "../menu/MenuContainer";
import { MenuButton } from "../menu/MenuButton";
import { useStrings } from "../../hooks/useStrings";
import { Divider } from "../menu/Divider";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { memberState } from "../../states/membersState";
import { columnTitleReorderDialogState } from "../../states/viewState";
import { Uid } from "../../types/core";

type Props = {
    uid: Uid;
    close: () => void;
    setShowNewSequence: (show: boolean) => void;
    setShowSequencesModal: (show: boolean) => void;
};

function ColumnTitleMenu({ uid, close, setShowNewSequence, setShowSequencesModal }: Props) {
    const { strings } = useStrings();
    const member = useRecoilValue(memberState(uid));
    const setShowReorderDialog = useSetRecoilState(columnTitleReorderDialogState);

    if (!member) {
        return null;
    }

    const onReorderOrderNumberClick = () => setShowReorderDialog("orderId");

    const onReorderPriorityClick = () => setShowReorderDialog("priority");

    return (
        <MenuContainer maxWidth={220}>
            <MenuButton
                title={strings.Sequences.LoadSequence}
                onClick={() => {
                    setShowSequencesModal(true)
                    close();
                }}
            />
            <MenuButton
                title={strings.Sequences.SaveAsSequence}
                onClick={() => {
                    setShowNewSequence(true)
                    close();
                }}
            />
            <Divider/>
            <MenuButton
                title={strings.Reorder.RouteReorderOrderNumber}
                onClick={() => {
                    onReorderOrderNumberClick()
                    close();
                }}
            />
            <MenuButton
                title={strings.Reorder.RouteReorderPriority}
                onClick={() => {
                    onReorderPriorityClick()
                    close();
                }}
            />
        </MenuContainer>
    )
}

export default memo(ColumnTitleMenu);