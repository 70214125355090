import { useEffect, useState } from 'react';

export function useBodyWidth() {
    const [bodyWidth, setBodyWidth] = useState(0);

    useEffect(() => {
        const body = document.body;
        const onResize = ([
                              {
                                  contentRect: { width },
                              },
                          ]: { contentRect: { width: number } }[]) => {
            setBodyWidth(width);
        };

        let resizeObserver: ResizeObserver;

        if (body) {
            resizeObserver = new ResizeObserver(onResize);
            resizeObserver.observe(body);
        }

        return () => {
            if (body) {
                resizeObserver?.unobserve(body);
            }
        };
    }, []);

    return { bodyWidth };
}
