import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button } from "baseui/button";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { useSetRecoilState } from "recoil";
import { showPaymentMethodState } from "../../states/paymentState";
import { useStrings } from "../../hooks/useStrings";
import useDirector from "../../hooks/useDirector";
import { useToast } from "../../hooks/useToast";
import { useState } from "react";

export function AddPaymentMethodForm() {
    const { strings } = useStrings();
    const stripe = useStripe();
    const elements = useElements();
    const setShowPayment = useSetRecoilState(showPaymentMethodState);
    const director = useDirector();
    const toast = useToast();
    const [loading, setLoading] = useState(false);

    const onCancelClick = () => {
        setShowPayment(false);
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setLoading(true);
        const result = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: 'https://live.hellotracks.com',
            },
            redirect: "if_required",
        });
        if (result.error) {
            toast.showWarning(result.error.message);
        } else {
            await director.performBilling();
            toast.showSuccess(strings.General.Success);
            setShowPayment(false);
        }
        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement/>
            <Spacer height={"24px"}/>
            <HFlex>
                <Button kind={"tertiary"} onClick={onCancelClick}>{strings.General.Cancel}</Button>
                <SpacerExpand/>
                <Button disabled={!stripe} onClick={handleSubmit} isLoading={loading}>{strings.General.OK}</Button>
            </HFlex>
            <Spacer/>
        </form>
    )
}