import { useTheme } from "../../hooks/useTheme";
import "./deviceStyles.css";

type Props = {
    children: React.ReactNode;
    width?: string;
    height?: string;
    minHeight?: string;
    backgroundColor?: string;
    contentSpacing?: string;
}
export function DeviceFrame({ children, width = "360px", height = "780px", minHeight, backgroundColor, contentSpacing }: Props) {
    const { css, theme } = useTheme();
    
    if (!backgroundColor) {
        backgroundColor = theme.colors.backgroundPrimary;
    }

    const deviceContentClass = css({
        width: "100%",
        height: "100%",
        overflowY: "auto",
        paddingTop: theme.sizing.scale1000,
        paddingBottom: theme.sizing.scale1000,
        paddingLeft: theme.sizing.scale900,
        paddingRight: theme.sizing.scale900,
        display: "flex",
        flexDirection: "column",
        gap: contentSpacing || theme.sizing.scale600,
        boxSizing: "border-box",
        "::-webkit-scrollbar": {
            display: "none",
        },
    });

    return (
        <div className={`device`} style={{
            width,
            height,
            backgroundColor,
            minHeight,
        }}>
            <div className={deviceContentClass}>
                {children}
            </div>
            <span className="notch"></span>
            <i>Speaker</i>
            <b>Camera</b>
        </div>
    );
}
