import { OnChangeParams } from "baseui/select";
import { useRecoilValue } from "recoil";
import { useStrings } from "../../hooks/useStrings";
import { formTemplatesSortedState } from "../../states/formsState";
import { LinkedForm } from "../../types/linked";
import { StyledSelect } from "../ui/StyledSelect";

type Props = {
    linkedForms: LinkedForm[] | "";
    onChangeLinkedForms: (linkedForms: LinkedForm[] | "") => void;
    placeholder?: string;
    style?: any,
    multi?: boolean;
    hideUnlinked?: boolean;
}

export function LinkedFormsSelect({
                                      linkedForms,
                                      onChangeLinkedForms,
                                      placeholder,
                                      style,
                                      multi = true,
                                      hideUnlinked = true
                                  }: Props) {
    const { strings } = useStrings();
    const forms = useRecoilValue(formTemplatesSortedState);
    const unlinkedOption = { title: strings.General.Unlinked, id: '' };
    const options = forms.map((form) => ({ title: form.content.title, id: form.id }));

    const onLinkForm = (params: OnChangeParams) => {
        if (params.type === 'remove') {
            onChangeLinkedForms(linkedForms ? linkedForms.filter(lf => lf.id !== params.option?.id) : []);
        } else if (params.type === 'select' && params.option?.id === "") {
            onChangeLinkedForms("");
        } else if (params.type === 'select') {
            const linkedForm = {
                title: params.option?.title as string,
                id: params.option?.id as string,
            } as LinkedForm;
            onChangeLinkedForms(multi && linkedForms ? [...linkedForms, linkedForm] : [linkedForm]);
        } else if (params.type === 'clear') {
            onChangeLinkedForms([]);
        }
    }

    const option = (linkedForm: LinkedForm) => {
        return {
            id: linkedForm.id,
            title: linkedForm.title
        }
    }

    const value = linkedForms
        ? linkedForms.map(lf => option(lf))
        : linkedForms === ""
            ? [unlinkedOption]
            : [];

    const selectOptions = hideUnlinked ? options : [unlinkedOption, ...options]

    return (
        <StyledSelect
            closeOnSelect
            size="compact"
            options={selectOptions}
            value={value}
            openOnClick
            labelKey='title'
            type="select"
            multi={multi}
            onChange={onLinkForm}
            placeholder={placeholder}
            overrides={{
                Root: { style }
            }}
        />
    );
}