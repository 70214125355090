import useAccount from "./useAccount";
import { useRecoilValue } from "recoil";
import { windowIsMobileState } from "../states/skeletonState";
import { format } from "date-fns";
import {
    createTimeStringFromInt,
    dateToDayBrowserTimezone,
    DAY,
    dayToDateBrowserTimezone,
    HOUR,
    MINUTE,
    SECOND
} from "../utils/time";
import { timezoneState } from "../states/accountState";
import { Day, Timestamp } from "../types/core";

export function useTime() {
    const YEAR_2065 = 3000000000000;

    const { isAmPm } = useAccount();
    const isMobile = useRecoilValue(windowIsMobileState);
    const timezone = useRecoilValue(timezoneState);

    const getShortDateFormatPattern = (browserTimezone: boolean = false) => {
        const formatter = new Intl.DateTimeFormat(navigator.language, {
            ...(!browserTimezone && timezone && { timeZone: timezone }),
            month: 'short',
            day: '2-digit',
        });
        return formatter;
    }

    const getLongDateFormatPattern = (browserTimezone: boolean = false) => {
        const formatter = new Intl.DateTimeFormat(navigator.language, {
            ...(!browserTimezone && timezone && { timeZone: timezone }),
            year: isMobile ? '2-digit' : 'numeric',
            month: 'short',
            day: '2-digit',
        });
        return formatter;
    }

    const dateFormat = getShortDateFormatPattern();
    const longFormDateFormat = getLongDateFormatPattern();
    const dateFormatBrowserTimezone = getShortDateFormatPattern(true);
    const longFormDateFormatBrowserTimezone = getLongDateFormatPattern(true);

    const formatIsoDate = (date: Date) => {
        return format(date, "yyyy-MM-dd");
    }

    const shouldIncludeYear = (millis: number) => {
        return Date.now() - millis > DAY * 300;
    };

    const formatExactTime = (millis: number) => {
        if (!millis || millis > YEAR_2065) {
            return "";
        }
        const date = new Date(millis);
        const formattedDate = new Intl.DateTimeFormat(window.navigator.language, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            ...(timezone && { timeZone: timezone })
        }).format(date);
        return formattedDate;
    };

    const formatSecondsToTime = (seconds: number) => {
        let hours = Math.floor(seconds / 3600);
        let mins = Math.floor((seconds - (hours * 3600)) / 60);
        hours %= 24;
        let timeAsInt = hours * 100 + mins;
        return createTimeStringFromInt(isAmPm(), timeAsInt);
    };

    const formatDuration = (millis: number | undefined, showSeconds?: boolean, zeroValue = "") => {
        if (!millis) {
            return zeroValue;
        }
        const hours = Math.floor(millis / HOUR);
        const remainingMillisAfterHours = millis - hours * HOUR;
        const minutes = Math.floor(remainingMillisAfterHours / MINUTE);
        const remainingMillisAfterMinutes = remainingMillisAfterHours - minutes * MINUTE;
        const seconds = Math.floor(remainingMillisAfterMinutes / SECOND);

        let sb = '';
        if (hours > 0) {
            sb += hours + 'h ';
        }
        if (minutes > 0) {
            sb += minutes + 'm';
        }
        if (hours === 0 && (minutes === 0 || showSeconds)) {
            if (hours > 0 || minutes > 0) {
                sb += ' ';
            }
            sb += seconds + 's';
        }
        return sb.trim();
    };

    const formatDateOnly = (millis: number, browserTimezone: boolean = false) => {
        if (!millis || millis > YEAR_2065) {
            return "";
        }
        const date = new Date(millis);
        if (shouldIncludeYear(millis)) {
            return browserTimezone
                ? longFormDateFormatBrowserTimezone.format(date)
                : longFormDateFormat.format(date);
        } else {
            return browserTimezone
                ? dateFormatBrowserTimezone.format(date)
                : dateFormat.format(date);
        }
    };

    const formatLongDate = (millis: number) => {
        if (!millis) {
            return "";
        }
        const date = new Date(millis);
        return longFormDateFormat.format(date);
    };

    const formatTimeOnly = (millis: number) => {
        const string = new Date(millis).toLocaleTimeString(navigator.language, {
            ...(timezone && { timeZone: timezone }),
            hour12: isAmPm(),
            hour: '2-digit',
            minute: '2-digit',
        });
        return sanitizeTime(string);
    };

    const formatTimeOrDateTime = (millis: number) => {
        if (Date.now() - millis < 24 * HOUR) {
            return formatTimeOnly(millis);
        } else {
            return formatDateTime(millis);
        }
    };

    const isSameDay = (millis: number, day: Day) => {
        const date = new Date(millis);
        return day === dateToDayBrowserTimezone(date);
    };

    const formatTimeOnlyIfSameDay = (millis: number, day: Day) => {
        const date = new Date(millis);
        const sameDay = day === dateToDayBrowserTimezone(date);
        return sameDay ? formatTimeOnly(millis) : formatDateTime(millis);
    };

    const formatDateTime = (millis: number) => {
        if (!millis) {
            return "";
        }
        const showYear = true;
        const showDate = true;
        const formattedDate = new Date(millis).toLocaleTimeString(navigator.language, {
            ...(timezone && { timeZone: timezone }),
            hour12: isAmPm(),
            hour: '2-digit',
            minute: '2-digit',
            day: showDate ? "numeric" : undefined,
            month: showDate ? "numeric" : undefined,
            year: showYear ? "numeric" : undefined,
        });
        return sanitizeTime(formattedDate);
    };

    const sanitizeTime = (time: string) => {
        if (isAmPm() && time.includes("00:")) {
            return time.replace("00:", "12:")
        } else if (!isAmPm() && time.includes("24:")) {
            return time.replace("24:", "00:")
        }
        return time;
    }

    const formatDay = (day: Day) => {
        return day ? formatDateOnly(dayToDateBrowserTimezone(day).getTime(), true) : "";
    }

    const timePlaceholder = () => {
        return isAmPm() ? "h:mm AM" : "HH:mm";
    }

    const timeMinusHours = (ts: Timestamp, hours: number) => {
        return ts - hours * HOUR;
    };

    return {
        formatIsoDate,
        formatDateOnly,
        formatDateTime,
        formatTimeOnly,
        formatTimeOnlyIfSameDay,
        formatSecondsToTime,
        formatDuration,
        formatTimeOrDateTime,
        isAmPm,
        dateFormat: "MMM dd",
        longFormDateFormat: "MMM dd yyyy",
        formatLongDate,
        formatDay,
        timePlaceholder,
        timeMinusHours,
        formatExactTime,
        isSameDay,
    }
}