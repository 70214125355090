import { API_ENDPOINT, prepareRequest } from "../services/api";
import { LatLng } from "../types/core";
import { useTheme } from "./useTheme";

export interface MapImageRequest {
    width: number;
    height: number;
    uid?: string;
    trackId?: string;
    job?: string;
    lat?: number;
    lng?: number;
    dark?: boolean;
}

export function useStaticMaps() {
    const { isDarkTheme } = useTheme();

    const urlForTrack = (request: MapImageRequest) => {
        const json = prepareRequest(request);
        return API_ENDPOINT + "/mapimage/?json=" + encodeURIComponent(JSON.stringify(json));
    }

    const urlForLatLng = (latLng: LatLng, width = 500, height = 500, marker = true) => {
        const json = prepareRequest({ ...latLng, marker, dark: isDarkTheme, width, height });
        return API_ENDPOINT + "/mapimage/?json=" + encodeURIComponent(JSON.stringify(json));
    }

    return { urlForTrack, urlForLatLng };
}