import { Tile, TileGroup } from "baseui/tile";
import React from "react";
import { useRecoilState } from "recoil";
import { constraintsState } from "../../../states/appState";

export function ConstraintsTiles({ withEventDistribution = false }: { withEventDistribution?: boolean }) {
    const [constraints, setConstraints] = useRecoilState(constraintsState);

    return (
        <TileGroup
            kind={"multiSelectBatch"}
            selected={constraints}
            onClick={(_event, index) => {
                if (!constraints.includes(index)) {
                    const newValue = [...constraints, index];
                    setConstraints(newValue);
                } else {
                    const newValue = [...constraints.filter((value) => value !== index)];
                    setConstraints(newValue);
                }
            }}
        >
            <Tile tileKind={"selection"} label="Working hours"/>
            <Tile tileKind={"selection"} label="Job time windows"/>
            <Tile tileKind={"selection"} label="Required skills"/>
            <Tile tileKind={"selection"} label="Capacity"/>
            {withEventDistribution && <Tile tileKind={"selection"} label="Even distribution"/>}
        </TileGroup>
    );
}