import { Image } from "antd";
import { ParagraphSmall } from "baseui/typography";
import useAccount from "../../hooks/useAccount";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { useTime } from "../../hooks/useTime";
import { getSignatureUrl, Job } from "../../types/job";
import { FlexGridLayout } from "../containers/FlexGridLayout";
import { FormInput } from "../ui/FormInput";
import { LabeledField } from "../ui/LabeledField";
import { Section } from "../ui/Section";

type Props = {
    job: Job;
    printable?: boolean;
};

export function JobSignature({ job, printable = false }: Props) {
    const { company } = useAccount();
    const { theme, css } = useTheme();
    const { strings } = useStrings();
    const { formatDateOnly } = useTime();
    const signatureUrl = getSignatureUrl(job, company?.uid);

    return (
        <Section title={strings.General.Signature}>
            {!signatureUrl && (
                <ParagraphSmall color={theme.colors.contentTertiary}>{strings.General.NoSignature}</ParagraphSmall>
            )}
            {signatureUrl && (
                <>
                    {signatureUrl.endsWith("pdf") ?
                        <object
                            data={signatureUrl}
                            type="application/pdf"
                            className={css({
                                maxWidth: "450px",
                                maxHeight: "450px",
                                height: "450px",
                                objectFit: "contain",
                            })}
                        >
                            <p>This browser does not support inline PDFs. Please download the PDF to view it: <a
                                href={signatureUrl}>Download PDF</a></p>
                        </object> : <Image
                            src={signatureUrl}
                            alt={strings.General.Signature}
                            className={css({
                                width: "100%",
                                maxHeight: "150px",
                                objectFit: "contain",
                            })}
                        />
                    }
                    {!printable && (
                        <FlexGridLayout>
                            <FormInput
                                label={strings.General.Date}
                                field="readonly"
                                value={formatDateOnly(job.tsSignature)}
                            />
                            <FormInput label={strings.General.Name} field="readonly" value={job.signatureName}/>
                        </FlexGridLayout>
                    )}
                    {printable && (
                        <>
                            <LabeledField
                                label={strings.General.Date}
                                content={formatDateOnly(job.tsSignature)}
                                direction="horizontal"
                            />
                            <LabeledField
                                label={strings.General.Name}
                                content={job.signatureName}
                                direction="horizontal"
                            />
                        </>
                    )}
                </>
            )}
        </Section>
    );
}
