import { getDiffFields } from './../utils/objects';
import { atom, selector } from 'recoil';
import { Company } from '../types/company';
import { companyState } from './accountState';

const editCompanyInitializer = selector<Company>({
    key: 'edit.company.initializer',
    get: ({ get }) => {
        const company = get(companyState);
        return {
            ...company,
        } as Company;
    },
});

export const editCompanyState = atom<Company>({
    key: 'edit.company',
    default: editCompanyInitializer,
});

export const editCompanyValidState = selector({
    key: 'edit.company.valid',
    get: ({ get }) => {
        const fields = get(editCompanyState);
        const validFields = Object.fromEntries(Object.keys(fields).map((key) => [key, true]));

        return validFields;
    },
});

export const editCompanyErrorState = selector({
    key: 'edit.company.error',
    get: ({ get }) => {
        const validFields = get(editCompanyValidState);
        return Object.values(validFields).some((valid) => !valid);
    },
});

export const editCompanyModifiedState = selector({
    key: 'edit.company.modified',
    get: ({ get }) => {
        const company = get(companyState);
        if (!company) {
            return {};
        }
        const companyFields = get(editCompanyState);
        return getDiffFields(company, companyFields);
    },
});

export const editCompanyHasModifiedState = selector({
    key: 'edit.company.modified.count',
    get: ({ get }) => {
        return !!Object.keys(get(editCompanyModifiedState)).length;
    },
});