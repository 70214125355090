import { flushSync } from "react-dom";
import { TourProps } from "antd";
import { useSetRecoilState } from "recoil";
import { onboardStatusState } from "../../states/onboardState";
import { companyTabFilterState, editingCompanyIdState } from "../../states/appState";
import useAccount from "../useAccount";
import { useOnboardStep } from "./useOnboardStep";
import { useOnboardRefs } from "./useOnboardRefs";
import { useStrings } from '../useStrings';
import { ONBOARD_TRACKING_SETTINGS, TOUR_DELAY } from "../../constants/onboard";

export function useOnboardTrackingSettings() {
    const { strings } = useStrings();
    const setCompanyTabFilter = useSetRecoilState(companyTabFilterState);
    const { trackingSettingsRef, companySettingsRef, trackingRef, trackingWorkingHoursRef, deleteTracksRef } =
        useOnboardRefs();
    const account = useAccount();
    const setEditingCompany = useSetRecoilState(editingCompanyIdState);
    const {
        step,
        nextStep,
        prevStep,
        closeStep,
        openStep,
        isOpen,
        canTrigger
    } = useOnboardStep(ONBOARD_TRACKING_SETTINGS);
    const setOnboardStatus = useSetRecoilState(onboardStatusState);

    const trackingSettingsSteps: TourProps["steps"] = [
        {
            title: strings.Onboard.CompanySettingsTitle,
            description: strings.Onboard.CompanySettingsDescription,
            target: companySettingsRef.current as HTMLElement,
            placement: "bottom",
            onNext: nextStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.TrackingSettingsTitle,
            description: strings.Onboard.TrackingSettingsDescription,
            target: trackingSettingsRef.current as HTMLElement,
            placement: "bottom",
            onNext: nextStep,
            onPrev: prevStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.TrackingTitle,
            description: strings.Onboard.TrackingDescription,
            target: trackingRef.current as HTMLElement,
            placement: "bottom",
            onNext: nextStep,
            onPrev: prevStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.TrackingWorkingHoursTitle,
            description: strings.Onboard.TrackingWorkingHoursDescription,
            target: trackingWorkingHoursRef.current as HTMLElement,
            placement: "bottom",
            onNext: nextStep,
            onPrev: prevStep,
            onClose: closeStep,
        },
        {
            title: strings.Onboard.ManageTracksTitle,
            description: strings.Onboard.ManageTracksDescription,
            target: deleteTracksRef.current as HTMLElement,
            placement: "bottom",
            onFinish: () => {
                finishOnboardTrackingSettings();
            },
            onPrev: prevStep,
            onClose: closeStep,
        },
    ];

    const initOnboardTrackingSettings = () => {
        flushSync(() => {
            setCompanyTabFilter("settings");
            setEditingCompany(account.company?.uid || "");
        });
        setTimeout(openStep, TOUR_DELAY);
    };

    const finishOnboardTrackingSettings = () => {
        closeStep();
        setOnboardStatus((prev) => ({ ...prev, trackingSettings: true }));
    };

    const voidFn = () => {};

    return {
        trackingSettingsSteps,
        initOnboardTrackingSettings,
        onboardTrackingSettingsIsOpen: isOpen,
        trackingSettingsStep: step,
        trackingSettingsPrevStep: canTrigger ? prevStep : voidFn,
        trackingSettingsNextStep: canTrigger ? nextStep : voidFn,
    };
}
