import { useState } from "react";
import { useRecoilValue } from "recoil";
import { editPlaceErrorState, editPlaceHasModifiedState, editPlaceModifiedState } from "../../states/editPlaceState";
import { placeState } from "../../states/placeState";
import { createPlace, editPlace } from "../../services/api";
import { editingPlaceIdState } from "../../states/appState";
import { useModal } from "../useModal";
import { useStrings } from "../useStrings";
import { useToast } from "../useToast";
import useAccount from "../useAccount";
import useDirector from "../useDirector";

export function usePlaceForms() {
    const { strings } = useStrings();
    const editingPlace = useRecoilValue(editingPlaceIdState);
    const place = useRecoilValue(placeState(editingPlace || ""));
    const [isLoading, setIsLoading] = useState(false);
    const { closeModal } = useModal();
    const placeHasAnyError = useRecoilValue(editPlaceErrorState(editingPlace || ""));
    const isNewPlace = !editingPlace;
    const placeModifiedFields = useRecoilValue(editPlaceModifiedState);
    const placeHasModifiedFields = useRecoilValue(editPlaceHasModifiedState);
    const toast = useToast();
    const { company } = useAccount();
    const director = useDirector();

    const canSubmit = !placeHasAnyError;

    const onSubmit = async () => {
        setIsLoading(true);
        if (placeHasModifiedFields) {
            if (editingPlace) {
                await editPlace({ uid: place?.uid, ...placeModifiedFields });
            } else {
                await createPlace({ ...placeModifiedFields });
            }
        }
        if (company?.uid === place?.uid) {
            await director.performPoll();
        }
        setIsLoading(false);
        toast.showSuccess(strings.General.Saved);
        closeModal();
    };

    return {
        canSubmit,
        isLoading,
        isNewPlace,
        onSubmit,
        hasChanges: placeHasModifiedFields,
    };
}
