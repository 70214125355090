import { SortConfig } from "../types/jobFilters";
import { CSV_EXTENSION, EXCEL_EXTENSION } from "./app";

export const MAX_JOBS = 10_000;

export const DEFAULT_SORT_CONFIG = {
    field: "",
    limit: MAX_JOBS,
    skip: 0,
    ascending: false,
    status: 0,
} as SortConfig;

export enum JobImportDefaultFields {
    "ID",
    "Title",
    "Place Name",
    "Address",
    "City",
    "State",
    "Country",
    "Type",
    "Assignee",
    "Team",
    "Date",
    "Order",
    "Priority",
    "Contact Name",
    "Contact Phone",
    "Time Window",
    "On Site (minutes",
    "Notes",
    "Auto-assign",
    "Carry Over",
    "Email On Completion",
    "Email On Issue",
}

export enum JobImportAdvancedFields {
    "Latitude",
    "Longitude",
    "Attachment",
    "Contact Email",
    "Sort Number",
    "Pick-up",
    "Drop-off",
    "Linked Form",
    "Linked Zone",
}

export const IMPORT_JOB_FILE_FORMATS = [CSV_EXTENSION, EXCEL_EXTENSION];

export const ALL_JOBS_SELECT_COLUMN = "select";
export const ALL_JOBS_TITLE_COLUMN = "title";
export const ALL_JOBS_ASSIGNEE_COLUMN = "assignee";
export const ALL_JOBS_PROGRESS_STATUS_COLUMN = "progressStatus";
export const ALL_JOBS_ASSIGNED_DATE_COLUMN = "assignedDate";
export const ALL_JOBS_PRIORITY_COLUMN = "priority";
export const ALL_JOBS_ADDRESS_COLUMN = "address";
export const ALL_JOBS_TEAM_COLUMN = "team";
export const ALL_JOBS_CREATED_DATE_COLUMN = "createdDate";
export const ALL_JOBS_LAST_SAVED_COLUMN = "lastSaved";
export const ALL_JOBS_ORDER_NUMBER_COLUMN = "ordenNumber";
export const ALL_JOBS_ONSITE_COLUMN = "onsite";
export const ALL_JOBS_PICKUP_COLUMN = "pickup";
export const ALL_JOBS_DROPOFF_COLUMN = "dropoff";
export const ALL_JOBS_NOTES_COLUMN = "notes";
export const ALL_JOBS_OPTIONS_COLUMN = "options";
export const ALL_JOBS_TIME_WINDOW_COLUMN = "timeWindow";
export const ALL_JOBS_ZONES = "zones";
export const ALL_JOBS_WORK_TYPE = "workType";
export const ALL_JOBS_CARRY_OVER = "carryOver";
export const ALL_JOBS_LABELS = "labels";

export const ALL_JOBS_ALL_COLUMNS = [
    ALL_JOBS_ASSIGNEE_COLUMN,
    ALL_JOBS_PROGRESS_STATUS_COLUMN,
    ALL_JOBS_ASSIGNED_DATE_COLUMN,
    ALL_JOBS_PRIORITY_COLUMN,
    ALL_JOBS_ADDRESS_COLUMN,
    ALL_JOBS_TEAM_COLUMN,
    ALL_JOBS_CREATED_DATE_COLUMN,
    ALL_JOBS_LAST_SAVED_COLUMN,
    ALL_JOBS_TIME_WINDOW_COLUMN,
    ALL_JOBS_ORDER_NUMBER_COLUMN,
    ALL_JOBS_NOTES_COLUMN,
    ALL_JOBS_ZONES,
    ALL_JOBS_WORK_TYPE,
    ALL_JOBS_CARRY_OVER,
    ALL_JOBS_ONSITE_COLUMN,
    ALL_JOBS_PICKUP_COLUMN,
    ALL_JOBS_DROPOFF_COLUMN,
    ALL_JOBS_LABELS,
];

export const ALL_JOBS_DEFAULT_COLUMNS = ALL_JOBS_ALL_COLUMNS.filter(col => {
    return col !== ALL_JOBS_PICKUP_COLUMN
        && col !== ALL_JOBS_DROPOFF_COLUMN
        && col !== ALL_JOBS_ZONES
        && col != ALL_JOBS_LABELS
});

export const ALL_JOBS_DEFAULT_COLUMNS_WIDTH = {
    [ALL_JOBS_SELECT_COLUMN]: 50,
    [ALL_JOBS_TITLE_COLUMN]: 250,
    [ALL_JOBS_ASSIGNEE_COLUMN]: 150,
    [ALL_JOBS_PROGRESS_STATUS_COLUMN]: 100,
    [ALL_JOBS_ASSIGNED_DATE_COLUMN]: 130,
    [ALL_JOBS_PRIORITY_COLUMN]: 90,
    [ALL_JOBS_ADDRESS_COLUMN]: 240,
    [ALL_JOBS_TEAM_COLUMN]: 140,
    [ALL_JOBS_CREATED_DATE_COLUMN]: 170,
    [ALL_JOBS_LAST_SAVED_COLUMN]: 170,
    [ALL_JOBS_ORDER_NUMBER_COLUMN]: 140,
    [ALL_JOBS_NOTES_COLUMN]: 250,
    [ALL_JOBS_OPTIONS_COLUMN]: 35,
    [ALL_JOBS_TIME_WINDOW_COLUMN]: 110,
    [ALL_JOBS_ZONES]: 150,
    [ALL_JOBS_WORK_TYPE]: 100,
    [ALL_JOBS_CARRY_OVER]: 120,
    [ALL_JOBS_ONSITE_COLUMN]: 70,
    [ALL_JOBS_PICKUP_COLUMN]: 70,
    [ALL_JOBS_DROPOFF_COLUMN]: 70,
    [ALL_JOBS_LABELS]: 70,
}

export const MAX_VISIBLE_JOBS = 1000;

export const MAX_JOB_ATTACHMENTS = 16;