import { COLOR_ARRAY } from "../constants/colors";
import { Member } from "./member";
import { Latitude, Longitude } from "./core";

export interface Cluster {
    jobs: string[],
    center_lat: Latitude;
    center_lng: Longitude;
    id: number;
    name: string;
    assignee?: Member;
    assigned?: boolean;
}

export const getClusterColor = (cluster: Cluster): string => {
    if (!cluster?.id) {
        return "#000";
    }
    return COLOR_ARRAY[cluster.id % COLOR_ARRAY.length];
}