import { Button } from "baseui/button";
import { Plus } from "baseui/icon";
import { OnChangeParams } from "baseui/select";
import { HeadingXSmall, LabelSmall } from "baseui/typography";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { useEffectOnce } from "usehooks-ts";
import { ALERT_ACTIONS, ALERT_INTERVALS, alertActionsOptions } from "../../constants/alerts";
import { useStrings } from "../../hooks/useStrings";
import { useStyleOverrides } from "../../hooks/useStyleOverrides";
import { useText } from "../../hooks/useText";
import { useTheme } from "../../hooks/useTheme";
import { useWeekDaysEncoder } from "../../hooks/useWeekDaysEncoder";
import { saveAlertConfig } from "../../services/api";
import { alertConfigsState, selectedAlertConfigState } from "../../states/alertsState";
import { editAlertErrorState, editAlertHasModifiedState, editAlertState } from "../../states/editAlertState";
import { pickerMembersState } from "../../states/membersPickerState";
import { placesPickerState } from "../../states/placesPickerState";
import { teamsState } from "../../states/teamsState";
import { AlertActionOption } from "../../types/alerts";
import { HFlex } from "../containers/HFlex";
import { ResponsiveFieldWrapper } from "../containers/ResponsiveFieldWrapper";
import { SectionedWrapper } from "../containers/SectionedWrapper";
import { SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { MembersPickerModalContent } from "../member/modal/MembersPickerModalContent";
import { LinkedZonesSelect } from "../pickers/LinkedZonesSelect";
import { TeamSelect } from "../pickers/TeamSelect";
import { PlacesPickerModalContent } from "../places/modal/PlacesPickerModalContent";
import { WeekdaysModal } from "../shared/WeekdaysModal";
import { ConfirmModal } from "../ui/ConfirmModal";
import { CreatableTagsInput } from "../ui/CreatableTagsInput";
import { FormInput } from "../ui/FormInput";
import { Section } from "../ui/Section";
import { StyledModal } from "../ui/StyledModal";
import { TrashIcon } from "../ui/svg";
import { AlertTitleModal } from "./modal/AlertTitleModal";
import { getLinkedZones, getLinkedZonesString } from "../../types/linked";
import { StyledSelect } from "../ui/StyledSelect";
import { AllDayTimePicker } from "../pickers/AllDayTimePicker";
import { EMPTY_UID, Uid } from "../../types/core";

export function AlertConfig() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const { getMembersText, getPlacesText } = useText();
    const { weekDaysText } = useWeekDaysEncoder();
    const { cancelButtonStyles } = useStyleOverrides();
    const alertConfig = useRecoilValue(selectedAlertConfigState);
    const resetAlertConfig = useResetRecoilState(selectedAlertConfigState);
    const alertHasAnyError = useRecoilValue(editAlertErrorState);
    const alertHasModifiedFields = useRecoilValue(editAlertHasModifiedState);
    const resetAlertConfigFields = useResetRecoilState(editAlertState);
    const setSelectedMembers = useSetRecoilState(pickerMembersState);
    const setSelectedPlaces = useSetRecoilState(placesPickerState);
    const setAlertConfigs = useSetRecoilState(alertConfigsState);
    const teams = useRecoilValue(teamsState);
    const [isLoading, setIsLoading] = useState(false);
    const [showWeekdays, setShowWeekdays] = useState(false);
    const [pickingPlaces, setPickingPlaces] = useState(false);
    const [pickingMembers, setPickingMembers] = useState(false);
    const [showCloneModal, setShowCloneModal] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [placesType, setPlacesType] = useState<"if" | "and">("if");
    const [alertConfigFields, setAlertConfigFields] = useRecoilState(editAlertState);
    const [action1, setAction1] = useState<AlertActionOption>(
        alertActionsOptions.find((alert) => alert.id === alertConfigFields?.action1) || alertActionsOptions[0]
    );
    const canSubmit = !alertHasAnyError;
    const isNewAlert = alertConfigFields?.id === "0";

    useEffectOnce(() => {
        return () => resetAlertConfigFields();
    });

    useEffect(() => {
        if (alertConfig) {
            resetAlertConfigFields();
            setAction1(
                alertActionsOptions.find((alert) => alert.id === alertConfigFields?.action1) || alertActionsOptions[0]
            );
        }
    }, [alertConfig]);

    const hasAndCondition = alertConfigFields && alertConfigFields.action2 !== 0;

    useEffect(() => {
        const action = alertActionsOptions.find((alert) => alert.id === alertConfigFields?.action1);
        if (action) {
            setAction1(action);
            resetActionRelatedFields({ ...action, hasAndCondition: hasAndCondition || false });
        }
    }, [alertConfigFields?.action1, hasAndCondition])

    const onChangeInput = (key: string, value: string | number) => {
        setAlertConfigFields((prev) => (prev ? { ...prev, [key]: value } : undefined));
    };

    const handleWeekdaysChange = (weekdays: number) => {
        setAlertConfigFields((prev) => (prev ? { ...prev, weekdays } : undefined));
        closeWeekdays();
    };

    const closeWeekdays = () => {
        setShowWeekdays(false);
    };

    const onWindowTimeChange = (time: number, target: string) => {
        setAlertConfigFields((prev) => (prev ? { ...prev, [target]: time } : undefined));
    };

    const onActionChange = ({ option }: OnChangeParams, target: "action1" | "action2") => {
        if (option) {
            onChangeInput(target, option.id || ALERT_ACTIONS.CHECKS_IN);
        }
    };

    const closePlacesPickerModal = () => {
        setPickingPlaces(false);
    };

    const closeMembersPickerModal = () => {
        setPickingMembers(false);
    };

    const handleOpenPlacesPicker = (type: "if" | "and") => {
        setPlacesType(type);
        const placesUids: Uid[] = [];
        if (type === "if") {
            placesUids.push(...(alertConfigFields?.place1Uids.split(",") as Uid[] || []));
        } else if (type === "and") {
            placesUids.push(...(alertConfigFields?.place2Uids.split(",") as Uid[] || []));
        }
        setSelectedPlaces((prev) => prev.map((place) => ({ ...place, selected: placesUids.includes(place.uid) })));
        setPickingPlaces(true);
    };

    const handleOpenMembersPicker = () => {
        const memberUids: Uid[] = alertConfigFields?.memberUids.split(",") as Uid[] || [];
        setSelectedMembers((prev) => prev.map((member) => ({ ...member, selected: memberUids.includes(member.uid) })));
        setPickingMembers(true);
    };

    const onClearPlaces = () => {
        if (placesType === "if") {
            onChangeInput("place1Uids", "");
        } else if (placesType === "and") {
            onChangeInput("place2Uids", "");
        }
        setPickingPlaces(false);
    };

    const onClearMembers = () => {
        onChangeInput('memberUids', "");
        setPickingMembers(false);
    };

    const saveAlert = async () => {
        if (!alertConfigFields) {
            return;
        }
        setIsLoading(true);
        const { status, alertconfigs } = await saveAlertConfig(alertConfigFields);
        if (status) {
            setAlertConfigs(alertconfigs);
            resetAlertConfig();
        }
        setIsLoading(false);
    };

    const deleteAlert = async () => {
        if (!alertConfigFields) {
            return;
        }
        const { status, alertconfigs } = await saveAlertConfig({ ...alertConfigFields, status: 2 });
        if (status) {
            setAlertConfigs(alertconfigs);
            resetAlertConfig();
        }
        setShowDeleteConfirm(false);
    };

    const cloneAlert = async (title: string) => {
        if (!alertConfigFields) {
            return;
        }
        const { status, alertconfigs } = await saveAlertConfig({ ...alertConfigFields, title, id: "0" });
        if (status) {
            setAlertConfigs(alertconfigs);
            resetAlertConfig();
        }
        setShowCloneModal(false);
    };

    const addAndCondition = () => {
        setAlertConfigFields((prev) =>
            prev ? {
                ...prev,
                action2: ALERT_ACTIONS.CHECKS_OUT,
                place2Uids: EMPTY_UID,
                interval: ALERT_INTERVALS.oneHour
            } : undefined
        );
    };

    const removeAndCondition = () => {
        setAlertConfigFields((prev) => (prev ? {
            ...prev,
            action2: ALERT_ACTIONS.NONE,
            place2Uids: EMPTY_UID,
            interval: ALERT_INTERVALS.none
        } : undefined));
    };

    const resetActionRelatedFields = ({
                                          hasDuration,
                                          hasPlace,
                                          hasZone,
                                          hasAndFunction,
                                          hasAndCondition
                                      }: AlertActionOption & { hasAndCondition: boolean }) => {
        setAlertConfigFields((prev) =>
            prev
                ? {
                    ...prev,
                    interval: hasDuration || hasAndCondition ? prev.interval ? prev.interval : ALERT_INTERVALS.oneHour : ALERT_INTERVALS.none,
                    place1Uids: hasPlace ? prev.place1Uids : EMPTY_UID,
                    zones: hasZone ? prev.zones : EMPTY_UID,
                    action2: hasAndFunction ? prev.action2 : ALERT_ACTIONS.NONE,
                    place2Uids: hasAndFunction ? prev.place2Uids : EMPTY_UID,
                }
                : undefined
        );
    };

    const phoneLabels = alertConfigFields && alertConfigFields.phone ? alertConfigFields.phone.split(",") : [];
    const emailLabels = alertConfigFields && alertConfigFields.email ? alertConfigFields.email.split(",") : [];
    const daysText = alertConfigFields?.weekdays ? weekDaysText(alertConfigFields.weekdays) : undefined;
    const selectedTeam = alertConfigFields ? teams.find((team) => team.id === +alertConfigFields.teamIds) : undefined;

    if (!alertConfigFields) {
        return null;
    }

    const sectionTitle = (title: string) => (
        <HeadingXSmall marginBottom={theme.sizing.scale300} marginTop={theme.sizing.scale400}>
            {title}
        </HeadingXSmall>
    );

    return (
        <div style={{ overflowY: "auto", height: "80vh" }}>
            <VFlex style={{ justifyContent: "space-between" }}>
                <SectionedWrapper style={{ overflowY: "auto" }}>
                    <Section>
                        <FormInput
                            field="title"
                            label={strings.General.Title}
                            value={alertConfigFields.title}
                            onChange={onChangeInput}
                            required
                        />
                    </Section>
                    <Section title={sectionTitle(strings.Alerts.AlertSendTo)}>
                        <CreatableTagsInput
                            label={strings.General.Phone}
                            tags={phoneLabels}
                            caption={strings.Alerts.AlertPhoneInputPrompt}
                            onTagsChange={(tags) =>
                                setAlertConfigFields((prev) => (prev ? { ...prev, phone: tags.join(",") } : undefined))
                            }
                        />
                        <CreatableTagsInput
                            label={strings.General.Email}
                            tags={emailLabels}
                            onTagsChange={(tags) =>
                                setAlertConfigFields((prev) => (prev ? { ...prev, email: tags.join(",") } : undefined))
                            }
                        />
                    </Section>
                    <Section title={sectionTitle(strings.Alerts.AlertActiveOn)}>
                        <VFlex style={{ rowGap: theme.sizing.scale600 }}>
                            <ResponsiveFieldWrapper>
                                <LabelSmall>{strings.Alerts.AlertWeekdays}</LabelSmall>
                                <Button
                                    size={"mini"}
                                    shape={"pill"}
                                    kind={"secondary"}
                                    onClick={() => setShowWeekdays(true)}
                                >
                                    {daysText ? daysText : strings.Alerts.AlertScheduleDaysOfWeek}
                                </Button>
                            </ResponsiveFieldWrapper>
                            <ResponsiveFieldWrapper maxWidth={260}>
                                <LabelSmall>{strings.General.Between}</LabelSmall>
                                <AllDayTimePicker
                                    start={alertConfigFields.timeStart || 0}
                                    end={alertConfigFields.timeEnd || 2400}
                                    onTimeChange={(time, target) =>
                                        onWindowTimeChange(time, target === "end" ? "timeEnd" : "timeStart")}
                                />

                            </ResponsiveFieldWrapper>
                        </VFlex>
                    </Section>
                    <Section title={sectionTitle(strings.Alerts.AlertIfCondition)}>
                        <VFlex style={{ rowGap: theme.sizing.scale600 }}>
                            <ResponsiveFieldWrapper>
                                <LabelSmall>{strings.General.Members}</LabelSmall>
                                <Button
                                    size={"mini"}
                                    shape={"pill"}
                                    kind={"secondary"}
                                    onClick={handleOpenMembersPicker}
                                >
                                    {getMembersText(alertConfigFields.memberUids, strings.Members.SelectMembers)}
                                </Button>
                            </ResponsiveFieldWrapper>
                            <ResponsiveFieldWrapper maxWidth={300}>
                                <LabelSmall whiteSpace={"nowrap"}>{strings.Alerts.AlertOrMemberOf}</LabelSmall>
                                <TeamSelect
                                    onSelect={(team) => onChangeInput("teamIds", team?.id || "")}
                                    selectedTeam={selectedTeam}
                                />
                            </ResponsiveFieldWrapper>
                            <ResponsiveFieldWrapper maxWidth={320}>
                                <LabelSmall>{strings.General.Action}</LabelSmall>
                                <StyledSelect
                                    options={alertActionsOptions}
                                    onChange={(params) => onActionChange(params, "action1")}
                                    size="compact"
                                    value={[{ id: alertConfigFields.action1 }]}
                                    getOptionLabel={({ option }) => strings.getString(option.labelKey)}
                                    getValueLabel={({ option }) => strings.getString(option.labelKey)}
                                    clearable={false}
                                />
                            </ResponsiveFieldWrapper>
                            {action1.hasPlace && (
                                <ResponsiveFieldWrapper>
                                    <LabelSmall>{strings.Alerts.AlertAtPlace}</LabelSmall>
                                    <Button
                                        size={"mini"}
                                        shape={"pill"}
                                        kind={"secondary"}
                                        onClick={() => handleOpenPlacesPicker("if")}
                                    >
                                        {getPlacesText(alertConfigFields.place1Uids, strings.Alerts.AlertAnywhere)}
                                    </Button>
                                </ResponsiveFieldWrapper>
                            )}
                            {action1.hasDuration && (
                                <ResponsiveFieldWrapper maxWidth={150}>
                                    <LabelSmall>{strings.General.Duration}</LabelSmall>
                                    <StyledSelect
                                        options={[
                                            { id: ALERT_INTERVALS.tenMinutes, label: "10m" },
                                            { id: ALERT_INTERVALS.thirtyMinutes, label: "30m" },
                                            { id: ALERT_INTERVALS.oneHour, label: "1h" },
                                            { id: ALERT_INTERVALS.twoHours, label: "2h" },
                                            { id: ALERT_INTERVALS.threeHours, label: "3h" },
                                        ]}
                                        onChange={({ option }) => onChangeInput("interval", option?.id || "")}
                                        size="compact"
                                        value={[{ id: alertConfigFields.interval }]}
                                        clearable={false}
                                    />
                                </ResponsiveFieldWrapper>
                            )}
                        </VFlex>
                    </Section>
                    {action1?.hasZone && (
                        <Section title={sectionTitle(strings.Job.LinkedZones)}>
                            <LinkedZonesSelect
                                onChangeLinkedZones={(linkedZones) =>
                                    onChangeInput("zones", getLinkedZonesString(linkedZones))}
                                linkedZones={getLinkedZones(alertConfigFields.zones)}
                            />
                        </Section>
                    )}
                    {action1?.hasAndFunction && (
                        <Section
                            title={
                                <HFlex spacing alignCenter>
                                    {sectionTitle(strings.Alerts.AlertAndAddContition)}
                                    {hasAndCondition && (
                                        <Button
                                            size="compact"
                                            kind="secondary"
                                            shape="circle"
                                            onClick={removeAndCondition}
                                        >
                                            <TrashIcon size={14} color={theme.colors.contentPrimary}/>
                                        </Button>
                                    )}
                                    {!hasAndCondition && (
                                        <Button
                                            size="compact"
                                            kind="secondary"
                                            shape="circle"
                                            onClick={addAndCondition}
                                        >
                                            <Plus/>
                                        </Button>
                                    )}
                                </HFlex>
                            }
                        >
                            {hasAndCondition && (
                                <VFlex style={{ rowGap: theme.sizing.scale600 }}>
                                    <ResponsiveFieldWrapper maxWidth={320}>
                                        <LabelSmall>{strings.General.Action}</LabelSmall>
                                        <StyledSelect
                                            options={alertActionsOptions.slice(0, 4)}
                                            onChange={(params) => onActionChange(params, "action2")}
                                            size="compact"
                                            value={[{ id: alertConfigFields.action2 }]}
                                            getOptionLabel={({ option }) => strings.getString(option.labelKey)}
                                            getValueLabel={({ option }) =>
                                                option.labelKey ? strings.getString(option.labelKey) : undefined
                                            }
                                            clearable={false}
                                        />
                                    </ResponsiveFieldWrapper>
                                    <ResponsiveFieldWrapper>
                                        <LabelSmall>{strings.Alerts.AlertAtPlace}</LabelSmall>
                                        <Button
                                            size={"mini"}
                                            shape={"pill"}
                                            kind={"secondary"}
                                            onClick={() => handleOpenPlacesPicker("and")}
                                        >
                                            {getPlacesText(alertConfigFields.place2Uids, strings.Alerts.AlertAnywhere)}
                                        </Button>
                                    </ResponsiveFieldWrapper>
                                    <ResponsiveFieldWrapper maxWidth={150}>
                                        <LabelSmall>{strings.Alerts.AlertWithin}</LabelSmall>
                                        <StyledSelect
                                            options={[
                                                { id: ALERT_INTERVALS.tenMinutes, label: "10m" },
                                                { id: ALERT_INTERVALS.thirtyMinutes, label: "30m" },
                                                { id: ALERT_INTERVALS.oneHour, label: "1h" },
                                                { id: ALERT_INTERVALS.twoHours, label: "2h" },
                                                { id: ALERT_INTERVALS.threeHours, label: "3h" },
                                            ]}
                                            onChange={({ option }) => onChangeInput("interval", option?.id || "")}
                                            size="compact"
                                            value={[{ id: alertConfigFields.interval }]}
                                            clearable={false}
                                        />
                                    </ResponsiveFieldWrapper>
                                </VFlex>
                            )}
                        </Section>
                    )}
                </SectionedWrapper>
                <HFlex style={{ marginTop: theme.sizing.scale500 }}>
                    {!isNewAlert && (
                        <>
                            <Button size="compact" kind="tertiary" onClick={() => setShowDeleteConfirm(true)}>
                                {strings.General.Delete}
                            </Button>
                            <Button size="compact" kind="tertiary" onClick={() => setShowCloneModal(true)}>
                                {strings.Clone.Clone}
                            </Button>
                        </>
                    )}
                    <SpacerExpand/>
                    {!isNewAlert && (
                        <Button
                            size="compact"
                            kind="tertiary"
                            onClick={resetAlertConfigFields}
                            overrides={cancelButtonStyles}
                            disabled={!alertHasModifiedFields}
                        >
                            {strings.General.Cancel}
                        </Button>
                    )}
                    <Button size="compact" disabled={!canSubmit || !alertHasModifiedFields} onClick={saveAlert}
                            isLoading={isLoading}>
                        {strings.General.Save}
                    </Button>
                </HFlex>
            </VFlex>
            <AlertTitleModal isOpen={showCloneModal} onAccept={cloneAlert} onCancel={() => setShowCloneModal(false)}/>
            <WeekdaysModal
                isOpen={showWeekdays}
                onAccept={handleWeekdaysChange}
                onCancel={closeWeekdays}
                weekDays={alertConfigFields.weekdays}
            />
            {createPortal(
                <ConfirmModal
                    title={strings.General.Delete}
                    description={strings.Alerts.AlertDeleteQuest}
                    isOpen={showDeleteConfirm}
                    onConfirm={deleteAlert}
                    onCancel={() => setShowDeleteConfirm(false)}
                    cancelText={strings.General.No}
                    confirmText={strings.General.Yes}
                />,
                document.body
            )}
            {createPortal(
                <StyledModal onClose={closePlacesPickerModal} isOpen={pickingPlaces}>
                    <PlacesPickerModalContent
                        closeModal={closePlacesPickerModal}
                        onAccept={async (places) => {
                            const placeUids = places.map((place) => place.uid).join(",");
                            if (placesType === "if") {
                                onChangeInput("place1Uids", placeUids);
                            } else if (placesType === "and") {
                                onChangeInput("place2Uids", placeUids);
                            }
                        }}
                        extraActions={
                            <Button onClick={onClearPlaces} size="compact" kind="tertiary">
                                {strings.General.None}
                            </Button>
                        }
                    />
                </StyledModal>,
                document.body
            )}
            {createPortal(
                <StyledModal onClose={closeMembersPickerModal} isOpen={pickingMembers}>
                    <MembersPickerModalContent
                        closeModal={closeMembersPickerModal}
                        onAccept={async (members) => {
                            const memberUids = members.map((member) => member.uid).join(",");
                            onChangeInput("memberUids", memberUids);
                        }}
                        extraActions={
                            <Button onClick={onClearMembers} size="compact" kind="tertiary">
                                {strings.General.None}
                            </Button>
                        }
                    />
                </StyledModal>,
                document.body
            )}
        </div>
    );
}