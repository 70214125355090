import { VFlex } from "../containers/VFlex";
import { ReactNode } from "react";
import { useTheme } from "../../hooks/useTheme";

export function MenuContainer({ style, children, maxWidth = 160 }: { style?: any, maxWidth?: number, children: ReactNode }) {
    const { theme } = useTheme();

    return (
        <VFlex style={{
            backgroundColor: theme.colors.backgroundSecondary,
            borderRadius: "8px",
            maxWidth: maxWidth + "px",
            ...style,
        }}>
            {children}
        </VFlex>
    );
}