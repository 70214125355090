import { useRecoilValue } from "recoil";
import { mapTypeObjectState } from "../states/mapState";
import { themedUseStyletron } from "../styles/theme";

export function useTheme() {
    const [css, theme] = themedUseStyletron();
    const mapTypeObject = useRecoilValue(mapTypeObjectState);
    const isDarkTheme = mapTypeObject.isDark;

    return { css, theme, isDarkTheme };
}