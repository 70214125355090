import { getRecoil, setRecoil } from "../providers/RecoilAccessProvider";
import {
    dayRouteState,
    heatmapLayerState,
    placesLayerState,
    waypointsLayerState,
    zonesLayerState
} from "../states/geojsonState";
import { tokenState, uidState } from "../states/accountState";
import { RecoilState } from "recoil";
import { API_ENDPOINT } from "./api";
import { HOUR } from "../utils/time";
import { Day, Uid } from "../types/core";

export const GEOJSON_BASE_URL = API_ENDPOINT + "/geojson/";

export function fetchGeoJson(endpoint: string, query: string): Promise<Response | void> {
    const bearer = getRecoil(tokenState);
    const uid = getRecoil(uidState);

    const headers = {
        'Authorization': `Bearer ${bearer}`,
        'HT-UID' : uid,
    };

    const url = `${GEOJSON_BASE_URL}${endpoint}?${query}`;
    return fetch(url, { headers })
        .catch(exc => {
            console.log("exception in fetchGeoJson", endpoint, query);
        });
}

async function updateLayer(fetchGeojson: Promise<Response | void>, state: RecoilState<(GeoJSON.FeatureCollection | undefined)>) {
    const result = await fetchGeojson;
    if (result) {
        const json = await result.json();
        setRecoil(state, json);
        return json;
    }
}

export async function updatePlacesLayer(max: number, skip: number, filter = "") {
    const result = await fetchGeoJson("places", "max=" + max + "&skip=" + skip + "&placeUids=" + filter);
    if (result?.ok) {
        const json = await result.json();
        const responseLayer = json as GeoJSON.FeatureCollection;
        if (!responseLayer.features) {
            console.warn("incorrect geojson", responseLayer);
            return;
        }
        const featureMap = new Map();
        const existingLayer = getRecoil(placesLayerState);
        if (existingLayer) {
            for (const feature of existingLayer.features) {
                if (feature?.properties?.uid) {
                    featureMap.set(feature.properties.uid, feature);
                }
            }
        }
        for (const feature of responseLayer.features) {
            if (feature?.properties?.uid) {
                featureMap.set(feature.properties.uid, feature);
            }
        }
        const newLayer = {
            type: "FeatureCollection",
            features: Array.from(featureMap.values()),
        } as GeoJSON.FeatureCollection;
        setRecoil(placesLayerState, newLayer);
        return { status: true, aborted: json.aborted, hasMore: json.hasMore, count: json.count };
    }
    return { status: false };
}

export function updateZonesLayer() {
    updateLayer(fetchGeoJson("zones", ""), zonesLayerState);
}

export async function updateDayRouteLayer(uid: Uid, day: Day) {
    const json = await updateLayer(
        fetchGeoJson("dayroute", "member=" + uid + "&day=" + day + "&force=true"),
        dayRouteState({ uid, day })
    );
    return json?.meta?.jobsCount === 0 || json?.meta?.routeInfoOk;
}

export async function updateWaypointsLayer(uid: Uid) {
    await updateLayer(
        fetchGeoJson("waypoints", "member=" + uid + "&from=" + (Date.now() - HOUR * 12) + "&until=" + Date.now() + "&html=false"),
        waypointsLayerState({ uid })
    );
}

export function updateHeatmapLayer(data: { members: string[], fromDay: Day, untilDay: Day }) {
    if (data.members.length > 0) {
        data.members.forEach(uid => {
            updateLayer(
                fetchGeoJson("heatmap", "member=" + uid + "&fromDay=" + data.fromDay + "&untilDay=" + data.untilDay),
                heatmapLayerState({
                    member: uid,
                    fromDay: data.fromDay,
                    untilDay: data.untilDay,
                })
            );
        });
    } else {
        updateLayer(
            fetchGeoJson("heatmap", "fromDay=" + data.fromDay + "&untilDay=" + data.untilDay),
            heatmapLayerState({ member: undefined, fromDay: data.fromDay, untilDay: data.untilDay })
        );
    }
}
