import { VFlex } from "../containers/VFlex";
import React, { useState } from "react";
import { useTime } from "../../hooks/useTime";
import { HFlex } from "../containers/HFlex";
import { useTheme } from "../../hooks/useTheme";
import { useRecoilState } from "recoil";
import { Message } from "../../types/message";
import { selectedConversationState } from "../../states/messagesState";
import { LabelXSmall } from "baseui/typography";
import { ClickableListItem } from "../ui/ClickableListItem";
import { Badge } from "antd";
import { EllipsedText } from "../ui/EllipsedText";
import { NoWrapText } from "../containers/NoWrapText";
import { MemberBadge } from "../badges/MemberBadge";
import { DAY } from "../../utils/time";
import { SpacerExpand } from "../containers/Spacer";

export function InboxMessage({ message }: { message: Message }) {
    const { theme } = useTheme();
    const time = useTime();
    const [selectedConversation, setSelectedConversation] = useRecoilState(selectedConversationState);
    const isSelected = selectedConversation === message.uid;
    const [isHovering, setHovering] = useState(false);

    const onEditClick = () => setSelectedConversation(message.uid);

    const color = isHovering
        ? theme.colors.contentPrimary
        : theme.colors.contentTertiary;

    const enhancers = () =>
        <LabelXSmall color={color}>
            <NoWrapText>
                {message.ts < Date.now() - DAY
                    ? time.formatDateOnly(message.ts)
                    : time.formatTimeOnly(message.ts)}
            </NoWrapText>
        </LabelXSmall>;

    return (
        <ClickableListItem
            isSelected={isSelected}
            onClick={onEditClick}
            onHover={enhancers}
            offHover={enhancers}
            onHoverChange={val => setHovering(val)}>
            <VFlex style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <SpacerExpand/>
                <HFlex spacing>
                    {message.unread && <Badge status={"processing"}/>}
                    <MemberBadge uid={message.uid} fallbackName={message.name}/>
                </HFlex>
                <LabelXSmall color={color}
                             style={{ marginLeft: "8px", fontSize: "11px"}}>
                    <EllipsedText>{message.msg}</EllipsedText>
                </LabelXSmall>
                <SpacerExpand/>
            </VFlex>
        </ClickableListItem>
    );
}