import { StyleObject } from "styletron-react";
import { useTheme } from "../../hooks/useTheme";
import { useRecoilValue } from "recoil";
import { windowIsMobileState } from "../../states/skeletonState";
import { forwardRef } from "react";

type Props = {
    children: React.ReactNode;
    spacing?: string | true;
    style?: StyleObject;
    expand?: boolean;
    onClick?: () => void;
    alignCenter?: boolean;
    padding?: true | number;
};

export const VFlex = forwardRef<HTMLDivElement, Props>(({ children, spacing, style, expand = false, onClick = undefined, alignCenter, padding = 0 }, ref) => {
    const { css, theme } = useTheme();
    const isMobile = useRecoilValue(windowIsMobileState);
    const defaultSpacing = "0px";
    const actualSpacing = spacing === undefined
        ? defaultSpacing
        : spacing === "" || spacing === true
            ? isMobile ? theme.sizing.scale100 : theme.sizing.scale300
            : spacing;
    const expanding = expand ? { flexGrow: 1 } : {};
    const clickable = onClick ? { cursor: "pointer" } : {};
    const shouldAlignItemsCenter = alignCenter ? { alignItems: "center" } : {};
    const paddingPx = padding === true ? "8px" : padding + "px";

    return (
        <div
            ref={ref}
            onClick={onClick}
            className={css({
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                gap: actualSpacing,
                paddingTop: paddingPx,
                paddingBottom: paddingPx,
                paddingLeft: paddingPx,
                paddingRight: paddingPx,
                ...clickable,
                ...style,
                ...expanding,
                ...shouldAlignItemsCenter,
            })}>
            {children}
        </div>
    );
});