import { useTheme } from "./useTheme";

export function useGap() {
    const { theme } = useTheme();

    const getNumberFromSizing = (text: string): number => Number(text.replace("px", ""));

    return Object.fromEntries(
        Object.entries(theme.sizing).map(([key, value]) => [key.replace('scale', 'gap'), getNumberFromSizing(value)])
    ) as { [key: `gap${number}`]: number };
}
