import { useRecoilValue, useSetRecoilState } from "recoil";
import { editingMemberIdState, memberRoutingOnlyState } from "../../../states/appState";
import { Button } from "baseui/button";
import { ConfigurationToolsIcon } from "../../ui/svg";
import { Tooltip } from "antd";
import React from "react";
import { useStrings } from "../../../hooks/useStrings";
import { useTheme } from "../../../hooks/useTheme";
import { Uid } from "../../../types/core";

export function RoutingSettingsButton({memberUid, buttonOverrides} : {memberUid: Uid, buttonOverrides: any}) {
    const editingMemberId = useRecoilValue(editingMemberIdState);
    const setEditingMember = useSetRecoilState(editingMemberIdState);
    const setRoutingOnly = useSetRecoilState(memberRoutingOnlyState);
    const { strings } = useStrings();
    const { theme } = useTheme();

    const onRoutingSettings = () => {
        setRoutingOnly(true);
        setEditingMember(memberUid);
    };

    if (!!editingMemberId) {
        return null;
    }

    return (
        <Tooltip title={strings.Routing.RoutingSettings}>
            <Button size={"mini"}
                    kind={"tertiary"}
                    onClick={onRoutingSettings}
                    overrides={buttonOverrides}
            >
                <ConfigurationToolsIcon size={14} color={theme.colors.contentPrimary}/>
            </Button>
        </Tooltip>
    );
}