import { Delete } from "baseui/icon";
import { LabelSmall } from "baseui/typography";
import { Fragment, useRef } from "react";
import { useHover } from "usehooks-ts";
import { useForms } from "../../hooks/useForms";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { Conditional, FormItemType } from "../../types/form";
import { HoverButton } from "../buttons/HoverButton";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { FormBetweenItems } from "./FormBetweenItems";
import { FormItemCard } from "./FormItemCard";

export type FormConditionalCardProps = {
    formConditionalItem: Conditional;
    canEdit?: boolean;
    jobInputForm?: boolean;
    onRemoveConditional: () => void;
    onAddConditionalItem: (type: FormItemType) => void;
    onRemoveConditionalItem: (conditionalItemIndex: number) => void;
    onSetConditionalItemName: (conditionalItemIndex: number, name: string) => void;
    onSetConditionalItemOptions: (conditionalItemIndex: number, options: string[]) => void;
    onSetConditionalItemRequired: (conditionalItemIndex: number, required: boolean) => void;
    onSetConditionalItemType: (conditionalItemIndex: number, type: FormItemType) => void;
}

export function FormConditionalCard(props: FormConditionalCardProps) {
    const {
        formConditionalItem,
        canEdit = true,
        jobInputForm = false,
        onRemoveConditional,
        onSetConditionalItemName,
        onSetConditionalItemOptions,
        onSetConditionalItemRequired,
        onSetConditionalItemType,
        onAddConditionalItem,
        onRemoveConditionalItem
    } = props;
    const { strings } = useStrings();
    const { isDarkTheme, theme } = useTheme();
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const items = formConditionalItem.content;
    const forms = useForms()
    const options = jobInputForm ? forms.jobOptions : forms.allOptions;

    return (
        <div ref={hoverRef} style={{
            backgroundColor: isDarkTheme ? "#434343" : "#f0f0f0",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "8px",
            paddingRight: "8px",
        }}>
            <VFlex>
                <HFlex spacing alignCenter style={{ minHeight: theme.sizing.scale800 }}>
                    <LabelSmall>
                        {strings.General.VisibleWhenEqualsTo} "{formConditionalItem.equalsTo}"
                    </LabelSmall>
                    {isHover && canEdit && (
                        <>
                            <SpacerExpand/>
                            <HoverButton tooltip={strings.General.Delete} onClick={() => onRemoveConditional()}>
                                <Delete size={12}/>
                            </HoverButton>
                        </>
                    )}
                </HFlex>
                <Spacer/>
                {items.map((item, conditionalItemIndex) => (
                    <Fragment key={conditionalItemIndex}>
                        <FormItemCard formItem={item}
                                      onSetName={(name) => onSetConditionalItemName(conditionalItemIndex, name)}
                                      onSetType={(type) => onSetConditionalItemType(conditionalItemIndex, type)}
                                      onSetOptions={(options) => onSetConditionalItemOptions(conditionalItemIndex, options)}
                                      onSetRequired={(required) => onSetConditionalItemRequired(conditionalItemIndex, required)}
                                      onRemove={() => onRemoveConditionalItem(conditionalItemIndex)}
                                      canEdit={canEdit}
                                      jobInputForm={jobInputForm}
                        />
                        {conditionalItemIndex < (items.length - 1) && <Spacer/>}
                    </Fragment>
                ))}
                <FormBetweenItems items={options}
                                  onPlusClick={(option) => onAddConditionalItem(option.id)}/>
            </VFlex>
        </div>
    );
}