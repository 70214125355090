import React from "react";
import { Tag } from "antd";
import { useJobTypeLabels } from "../../hooks/jobs/useJobTypeLabels";

type Props = {
    type: number;
    style?: any
}

export function JobTypeBadge({ type , style }: Props) {
    const jobTypes = useJobTypeLabels();

    return (
        <Tag style={style} color={"volcano"}>
            {jobTypes.getLabelForJobType(type)}
        </Tag>
    );
}