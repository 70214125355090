import { Checkbox } from "baseui/checkbox";
import { Button } from "baseui/button";
import { useState } from "react";
import { Input } from "baseui/input";
import { useStrings } from "../../hooks/useStrings";
import { useEditCompany } from "../../hooks/company/useEditCompany";
import { VFlex } from "../containers/VFlex";
import { Radio, RadioGroup } from "baseui/radio";
import { LabelSmall } from "baseui/typography";
import { IdentityProvider, SsoConfig } from "../../types/core";
import useAccount from "../../hooks/useAccount";
import { StyledLink } from "baseui/link";
import { HFlex } from "../containers/HFlex";
import { useTheme } from "../../hooks/useTheme";
import { ClipboardButton } from "../buttons/ClipboardButton";
import { Banner } from "baseui/banner";


export function SsoConfiguration({ config }: { config?: SsoConfig }) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const [identityProvider, setIdentityProvider] = useState<IdentityProvider>(config?.identity_provider || "okta");
    const [tenantId, setTenantId] = useState(config?.tenant_id || "");
    const [clientId, setClientId] = useState(config?.client_id || "");
    const [clientSecret, setClientSecret] = useState(config?.client_secret || "");
    const [redirectUrl, setRedirectUrl] = useState(config?.redirect_url || "");
    const [enter, setEnter] = useState(false);
    const [saving, setSaving] = useState(false);
    const edit = useEditCompany();
    const { company } = useAccount();

    const onCancel = () => {
        setEnter(false);
    };

    const onSave = async () => {
        setSaving(true);
        const hasConfig = (clientId + clientSecret + redirectUrl).length > 0;
        const config = {
            identity_provider: identityProvider,
            tenant_id: identityProvider === "azure_ad" ? tenantId : "",
            client_id: clientId,
            client_secret: clientSecret,
            redirect_url: identityProvider === "azure_ad"
                ? "https://login.microsoftonline.com/" + tenantId
                : redirectUrl,
        } as SsoConfig;
        await edit.editField("sso_config", hasConfig ? JSON.stringify(config) : "");
        setSaving(false);
        setEnter(false);
    };

    const isConfigured = !!config;

    const loginUrl = `https://auth.hellotracks.com/v1/redirect?provider=${identityProvider}&user=${company?.uid}&platform=web`;

    return (
        <VFlex spacing>
            {isConfigured && (
                <Checkbox checked disabled>
                    {strings.Sso.ConfigurationHiddenDesc}
                </Checkbox>
            )}
            {!enter &&
                <VFlex spacing>
                    <Button size={"compact"} shape={"pill"} kind={"primary"} onClick={() => setEnter(true)}>
                        {isConfigured ? strings.Sso.ChangeSsoConfiguration : strings.Sso.SetupSsoConfiguration}
                    </Button>
                    {isConfigured &&
                        <Banner title={strings.Sso.Notes}>
                            {strings.Sso.NotesContent}
                            <HFlex alignCenter spacing>
                                <StyledLink
                                    animateUnderline
                                    target="_blank"
                                    href={loginUrl}
                                >{strings.Sso.LinkToLoginPage}</StyledLink>
                                <ClipboardButton content={loginUrl}/>
                            </HFlex>
                        </Banner>
                    }
                </VFlex>
            }
            {enter &&
                <>
                    <VFlex spacing>
                        <LabelSmall>{strings.Sso.SelectIdentityProvider}:</LabelSmall>
                        <RadioGroup value={identityProvider}
                                    align={"vertical"}
                                    onChange={e => setIdentityProvider(e.target.value as IdentityProvider)}
                        >
                            <Radio value="okta">Okta</Radio>
                            <Radio value="azure_ad">Azure AD</Radio>
                            <Radio value="basic_oidc">Standard OIDC</Radio>
                        </RadioGroup>
                    </VFlex>
                    {identityProvider === "azure_ad" &&
                        <Input value={tenantId}
                               onChange={(val) => setTenantId(val.target.value)} size={"mini"}
                               placeholder={strings.Sso.EnterTenantID}/>
                    }
                    <Input value={clientId} onChange={(val) => setClientId(val.target.value)} size={"mini"}
                           placeholder={strings.Sso.EnterClientID}/>
                    <Input value={clientSecret} onChange={(val) => setClientSecret(val.target.value)} size={"mini"}
                           placeholder={strings.Sso.EnterClientSecret}/>
                    {identityProvider !== "azure_ad" &&
                        <Input value={redirectUrl}
                               onChange={(val) => setRedirectUrl(val.target.value)} size={"mini"}
                               placeholder={strings.Sso.EnterRedirectUrl}/>
                    }
                    <HFlex spacing>
                        <Button size={"compact"} shape={"pill"} kind={"primary"} onClick={onSave} isLoading={saving}>
                            {strings.General.Save}
                        </Button>
                        <Button size={"compact"} shape={"pill"} kind={"tertiary"} onClick={onCancel} isLoading={saving}>
                            {strings.General.Cancel}
                        </Button>
                    </HFlex>
                </>
            }
        </VFlex>
    );
}