import { FormItem } from "../types/form";

export function getConditionalIndex(item: FormItem) {
    return item.conditionals?.findIndex((conditional) => conditional.equalsTo == item.value)
}

export function getMatchingConditional(item: FormItem) {
    const hasConditionals = !!item.conditionals?.length;
    const conditionalIndex = getConditionalIndex(item);
    const matchingConditional = hasConditionals && conditionalIndex !== undefined && conditionalIndex >= 0 ? item.conditionals![conditionalIndex] : undefined;
    
    return matchingConditional;
}

export const preventMinus = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.code === "Minus") {
        event.preventDefault();
    }
}