import { useEffect } from "react";

const revocable = () => {
    const originalRemoveChild = HTMLElement.prototype.removeChild;

    HTMLElement.prototype.removeChild = function<T extends Node>(node: T): T {
        if (this.contains(node)) {
            return originalRemoveChild.call(this, node) as T;
        }
        return node;
    };

    return () => {
        HTMLElement.prototype.removeChild = originalRemoveChild;
    };
};

export const useRemoveChildInterceptor = () => {
    let revoke: (() => void) | undefined;

    useEffect(() => {
        revoke = revocable();

        return () => {
            revoke?.();
        };
    }, []);

    return;
}