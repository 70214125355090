import { Segment, SegmentedControl } from "baseui/segmented-control";
import { ReactNode } from "react";
import { COLOR_TRANSLUCENT } from "../../constants/colors";

type Tab = { key: string, label: ReactNode; description: string; }

type Props = {
    activeKey: string;
    onChange: (activeKey: string) => void;
    tab1: Tab;
    tab2: Tab;
    tab3?: Tab;
}

export function SwapTab({ activeKey, onChange, tab1, tab2, tab3 }: Props) {
    const tabWidth = 90;
    const segmentTotalWidth = tabWidth + tabWidth + (tab3 ? tabWidth : 0);

    return (
        <SegmentedControl
            activeKey={activeKey}
            onChange={({ activeKey }) => {
                onChange(activeKey as string);
            }}
            overrides={{
                Root: {
                    style: {
                        flexShrink: 0,
                        backgroundColor: COLOR_TRANSLUCENT,
                        width: `${segmentTotalWidth}px`,
                        boxSizing: "content-box",
                        paddingTop: "2px",
                        paddingBottom: "1px",
                        paddingLeft: "1px",
                        paddingRight: "1px",
                    }
                },
                SegmentList: {
                    style: {
                        backgroundColor: "transparent",
                    }
                },
                Active: {
                    style: {
                        width: tabWidth + "px",
                        transitionProperty: "all",
                        transitionDuration: "200ms",
                        transitionTimingFunction: "cubic-bezier(0.86, 0, 0.07, 1)",
                    }
                },
            }}
        >
            <Segment key={tab1.key}
                     label={tab1.label}
                     description={tab1.description}
                     overrides={{
                         Segment: {
                             style: {
                                 width: tabWidth + "px",
                                 paddingInline: "0px",
                             }
                         },
                         Description: {
                             style: {
                                 fontWeight: "bold",
                                 fontSize: tab1.description.length > 12 ? "10px" : "12px",
                             }
                         }
                     }}
            />
            <Segment key={tab2.key}
                     label={tab2.label}
                     description={tab2.description}
                     overrides={{
                         Segment: {
                             style: {
                                 width: tabWidth + "px",
                                 paddingInline: "0px",
                             }
                         },
                         Description: {
                             style: {
                                 fontWeight: "bold",
                                 fontSize: tab2.description.length > 12 ? "10px" : "12px",
                             }
                         }
                     }}
            />
            {tab3 && (
                <Segment key={tab3.key}
                         label={tab3.label}
                         description={tab3.description}
                         overrides={{
                             Segment: {
                                 style: {
                                     width: tabWidth + "px",
                                     paddingInline: "0px",
                                 }
                             },
                             Description: {
                                 style: {
                                     fontWeight: "bold",
                                 }
                             }
                         }}
                />
            )}
        </SegmentedControl>
    )
}