import { WEB_UPLOAD_BUCKET_ID } from "../../constants/app";
import { MAX_JOB_ATTACHMENTS } from "../../constants/jobs";
import { Job } from "../../types/job";
import { isValidImage } from "../../utils/app";

export function useJobAttachments(job: Job | undefined) {
    const sortByUrlIndex = (url1: string, url2: string) => {
        const n1 = url1.match(/\d+/g);
        const n2 = url2.match(/\d+/g);
        if (n1 && n2) {
            return +n1[0] - +n2[0]
        }
        return 0;
    };

    const getAttachmentKeys = () => {
        if (!job) {
            return [];
        }
        const urlKeys = Object.keys(job)
            .filter((key: string) => key.startsWith('url_'))
            .sort(sortByUrlIndex);
        return urlKeys;
    }

    const getNewAttachments = (url: string, key?: string) => {
        const attachments = getAllAttachments();
        if (key) {
            const index = attachments.findIndex((attachment) => attachment.key === key);
            if (index !== -1) {
                attachments.splice(index, 1);
            }
        }
        const attachmentValues = attachments.map(attachment => attachment.val);
        if (!key) {
            attachmentValues.push(url);
        }
        const urlIndexes = Array.from({ length: MAX_JOB_ATTACHMENTS }, (_, index) => index + 1);
        const newAttachments = urlIndexes.map((index, arrayIndex) => ({
            [`url_${index}`]: attachmentValues[arrayIndex] ?? "",
        }));
        return newAttachments;
    }

    const getAllAttachments = (): { key: string; val: string }[] => {
        if (!job) {
            return [];
        }
        const attachmentKeys = getAttachmentKeys();
        const attachmentsEntries = Object.entries(job).filter(([key, val]) => attachmentKeys.includes(key) && val !== '');
        const attachments: { key: string; val: string }[] = attachmentsEntries.map(([key, val]) => ({
            key,
            val,
        }))
        .sort(({ key: url1 }, { key: url2 }) => sortByUrlIndex(url1, url2));

        return attachments;
    }

    const getJobAttachments = (): { key: string; val: string }[] => {
        const attachments = getAllAttachments();
        const jobAttachments = attachments.filter(
            (attachment) => !isValidImage(attachment.val) || attachment.val.includes(WEB_UPLOAD_BUCKET_ID)
        );
        return jobAttachments;
    }

    const getStaffAttachments = (): { key: string; val: string }[] => {
        const attachments = getAllAttachments();
        const staffAttachments = attachments.filter(
            (attachment) => isValidImage(attachment.val) && !attachment.val.includes(WEB_UPLOAD_BUCKET_ID)
        );
        return staffAttachments;
    }

    const getClearedAttachments = () => {
        const newJob: { [key: string]: any } = { ...job };
        Object.keys(newJob).forEach((key) => {
            if (key.startsWith("url_")) {
                delete newJob[key];
            }
        });
        return newJob as Job;
    };

    return {
        getNewAttachments,
        jobAttachments: getJobAttachments(),
        staffAttachments: getStaffAttachments(),
        canAddAttachment: getAllAttachments().length < 16,
        clearedAttachments: getClearedAttachments(),
    }
}