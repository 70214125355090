import { Button } from "baseui/button";
import { useTheme } from "../../../hooks/useTheme";


export function StylishBackButton({ ...props }: React.ComponentProps<typeof Button>) {
    const { theme } = useTheme();

    return (
        <Button
            {...props}
            overrides={{
                BaseButton: {
                    style: {
                        width: "min-content",
                        marginRight: "auto",
                        marginLeft: "auto",
                        color: theme.colors.contentSecondary,
                        transitionProperty: "color",
                        transitionTimingFunction: "ease-in-out",
                        transitionDuration: "300ms",
                        ":hover": {
                            backgroundColor: "transparent",
                            color: theme.colors.contentPrimary,
                        },
                        ":active": {
                            backgroundColor: "transparent",
                        }
                    }
                }
            }}
        />
    )
}