import { useRecoilValue } from "recoil";
import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { EmptyState } from "../ui/EmptyState";
import { AllJobsIcon } from "../ui/svg";
import { allJobsFiltersState, allJobsState } from "../../states/allJobsState";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { allJobsActiveTabState, requestingState } from "../../states/viewState";
import { Alert, Spin } from "antd";
import React from "react";
import { jobsForUnassignedState } from "../../states/jobsState";
import { JobsGrid } from "./JobsGrid";
import { ColumnOptions } from "../shared/ColumnOptions";
import { useAllJobsColumns } from "../../hooks/jobs/useAllJobsColumns";

export function AllJobsGrid({ unassigned = false }: { unassigned?: boolean }) {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const jobs = useRecoilValue(unassigned ? jobsForUnassignedState : allJobsState);
    const allJobsTableFilters = useRecoilValue(allJobsFiltersState);
    const requesting = useRecoilValue(requestingState({ req: "alljobs" })) && !unassigned;
    const activeTab = useRecoilValue(allJobsActiveTabState);
    const { columnsConfiguration, disabledColumns, enabledColumns, reorderColumns, columns } = useAllJobsColumns();

    const loading = jobs.length === 0 && requesting;

    if (loading) {
        return (
            <VFlex>
                <SpacerExpand/>
                <HFlex>
                    <SpacerExpand/>
                    <Spin/>
                    <SpacerExpand/>
                </HFlex>
                <SpacerExpand/>
            </VFlex>
        );
    }

    if (!unassigned && jobs.length === 0 && allJobsTableFilters.length === 0) {
        return (
            <EmptyState title={strings.General.Empty} subtitle={strings.General.ReduceFilters}>
                <AllJobsIcon size={64} color={theme.colors.primary400}/>
            </EmptyState>
        );
    }

    return (
        <VFlex
            spacing
            style={{
                flex: 1,
                position: "relative"
            }}
        >
            <ColumnOptions
                columns={columns}
                enabledColumns={enabledColumns}
                configuration={columnsConfiguration}
                disabledColumns={disabledColumns}
                onReorder={reorderColumns}
            />
            <JobsGrid jobs={jobs}/>
            {!unassigned && activeTab === "archived" &&
                <Alert message={strings.Job.ArchivedJobsDesc} showIcon closable type={"warning"}/>}
        </VFlex>
    );
}
