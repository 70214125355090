import { OnChangeParams } from "baseui/select";
import { useRecoilValue } from "recoil";
import { useStrings } from "../../hooks/useStrings";
import { teamsState } from "../../states/teamsState";
import { Team } from "../../types/core";
import { StyledSelect } from "../ui/StyledSelect";

type Props = {
    placeholder: string;
    onSelect: (teams: Team[] | "") => void;
    size?: "mini" | "compact";
    selectedTeams?: Team[] | "";
    hideUnassigned?: boolean;
};

export function TeamsSelect({
                                placeholder,
                                onSelect,
                                size = "compact",
                                selectedTeams = [],
                                hideUnassigned = true
                            }: Props) {
    const { strings } = useStrings();
    const teams = useRecoilValue(teamsState);
    const unassignedOption = { label: strings.JobStatuses.Unassigned, id: '' }
    const option = (team: Team) => {
        return {
            id: team.id,
            label: team.name,
            team: team,
        }
    }
    const options = teams.map(t => option(t));
    const value = selectedTeams
        ? selectedTeams.map(t => option(t))
        : selectedTeams === ""
            ? [unassignedOption]
            : [];
    const handleTeamChange = (params: OnChangeParams) => {
        if (params.type === 'clear') {
            onSelect([]);
        } else if (params.type === 'remove') {
            const newTeams = selectedTeams ? selectedTeams.filter((t) => t.id !== params.option?.id) : [];
            onSelect(newTeams);
        } else if (params.type === 'select' && params.option?.team) {
            onSelect(selectedTeams ? [...selectedTeams, params.option.team] : [params.option.team]);
        } else if (params.type === 'select' && params.option?.id === "") {
            onSelect("");
        }
    }
    const selectOptions = hideUnassigned ? options : [unassignedOption, ...options];

    return (
        <StyledSelect
            options={selectOptions}
            onChange={handleTeamChange}
            placeholder={placeholder}
            size={size}
            value={value}
            multi
        />
    );
}