import { Tag } from "antd";
import { FilterFilledIcon } from "../ui/svg";
import { useTheme } from "../../hooks/useTheme";

type Props = {
    name: string;
    onCloseClick: () => void;
}

export function FilterBadge({ name, onCloseClick }: Props) {
    const badgeStyle = { fontSize: "11px", lineHeight: "14px" };
    const { theme} = useTheme();

    return (
        <Tag style={badgeStyle}
             onClose={onCloseClick}
             icon={<FilterFilledIcon size={8} color={theme.customColors.accent} style={{ marginRight: "4px"}}/>}
             closable
        >
            {name}
        </Tag>
    );
}