import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { getSignatureUrl, Job } from "../../types/job";
import { VFlex } from "../containers/VFlex";
import { ShortFormResponseSection } from "./ShortFormResponseSection";
import { Section } from "../ui/Section";
import { LabelXSmall, ParagraphSmall } from "baseui/typography";
import { AttachmentList } from "../shared/AttachmentList";
import { JobSignature } from "./JobSignature";
import { useJobAttachments } from "../../hooks/jobs/useJobAttachments";
import useAccount from "../../hooks/useAccount";
import { Labeled } from "../containers/Labeled";
import { HFlex } from "../containers/HFlex";
import { TimeBadge } from "../badges/TimeBadge";
import { Divider } from "antd";
import { getProgressStatus } from "../../utils/jobUtils";
import { StatusBadge } from "../badges/StatusBadge";
import React from "react";
import { useRecoilValue } from "recoil";
import { jobTypeOptionsState } from "../../states/optionsState";

type Props = {
    job: Job;
    width: string;
};

export function JobTooltipInfo({ job, width }: Props) {
    const { theme } = useTheme();
    const { strings, format } = useStrings();
    const { staffAttachments } = useJobAttachments(job);
    const { company } = useAccount();
    const signatureUrl = getSignatureUrl(job, company?.uid);
    const jobTypeOptions = useRecoilValue(jobTypeOptionsState);
    const jobTypeLabel = jobTypeOptions.find(o => o.id === job.type);

    const labelWidth = 110;

    return (
        <VFlex style={{ width: width, height: "130px", overflowY: "auto", paddingRight: "8px", paddingTop: "8px" }}>

            {job.receiverUid &&
                <Labeled label={format(strings.General.AssignedTo, "")} labelWidth={labelWidth}>
                    <HFlex spacing alignCenter style={{ marginBottom: "2px" }}>
                        <LabelXSmall>{job.assigneeName}</LabelXSmall>
                    </HFlex>
                </Labeled>}
            {job.tsAssigned > 0 &&
                <Labeled label={format(strings.General.AssignedByX, "")} labelWidth={labelWidth}>
                    <HFlex spacing alignCenter style={{ marginBottom: "2px" }}>
                        <LabelXSmall>{job.dispatcherName}</LabelXSmall>
                        <TimeBadge startMillis={job.tsAssigned}/>
                    </HFlex>
                </Labeled>}
            {jobTypeLabel?.label && <Labeled label={strings.General.Type} labelWidth={labelWidth}>
                <LabelXSmall>{jobTypeLabel.label}</LabelXSmall>
            </Labeled>}
            <Labeled label={strings.General.Status} labelWidth={labelWidth}>
                <StatusBadge progress={getProgressStatus(job)}/>
            </Labeled>
            {job.textReceiver && <Labeled label={strings.General.Message} labelWidth={labelWidth}>
                <LabelXSmall>{job.textReceiver}</LabelXSmall>
            </Labeled>}
            {job.textDispatcher && <Labeled label={strings.Job.Notes} labelWidth={labelWidth}>
                <LabelXSmall>{job.textDispatcher}</LabelXSmall>
            </Labeled>}
            <Divider/>
            <ShortFormResponseSection job={job} allowEdit={false} showTitle={false}/>
            {staffAttachments.length > 0 &&
                <Section title={strings.Job.UploadedImages}>
                    {!staffAttachments.length && (
                        <ParagraphSmall
                            color={theme.colors.contentTertiary}>{strings.General.NoUploadedImages}</ParagraphSmall>
                    )}
                    {!!staffAttachments.length && (
                        <AttachmentList attachments={staffAttachments}/>
                    )}
                </Section>
            }
            {signatureUrl && <JobSignature job={job}/>}
        </VFlex>
    );
}