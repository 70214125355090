import React from "react";
import { useRecoilValue } from "recoil";
import { memberIsVisibleState, memberState } from "../../states/membersState";
import MemberItem from "./MemberItem";
import { boardMemberIsPresentState } from "../../states/boardState";
import { Uid } from "../../types/core";

type Props = {
    data: Uid[];
    index: number;
    style: React.CSSProperties;
};

function MemberItemVirtualized({ data, index, style }: Props) {
    const uid = data[index];
    const isPresent = useRecoilValue(boardMemberIsPresentState(uid));
    const member = useRecoilValue(memberState(uid));
    const isVisible = useRecoilValue(memberIsVisibleState(uid));

    if (!member || !isVisible) {
        return null;
    }

    return (
        <div style={style}>
            <MemberItem
                uid={member.id}
                key={member.id}
                isSelected={isPresent}
            />
        </div>
    );
}

export default React.memo(MemberItemVirtualized);
