import { HFlex } from "../containers/HFlex";
import { useTheme } from "../../hooks/useTheme";
import { ReactNode } from "react";

export function Board({ children }: { children: ReactNode }) {
    const { theme } = useTheme();

    return (
        <HFlex style={{
            gap: theme.sizing.scale300,
            flexShrink: 1,
            paddingBottom: "8px",
            overflowY: "hidden",
            height: "100%",
            userSelect: "none",
        }}>
            {children}
        </HFlex>
    );
}