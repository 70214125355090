import { useEffect } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useCamera } from "./useCamera";
import circle from "@turf/circle";
import { DetailedLocation, LonLat } from "../types/core";
import { DrawMode, drawModeState } from "../states/drawModeState";

export function useDrawMode() {
    const camera = useCamera();
    const [drawMode, setDrawMode] = useRecoilState(drawModeState);
    const resetDrawMode = useResetRecoilState(drawModeState);
    const location = drawMode?.location;

    useEffect(() => {
        if (location && drawMode.radius && location.lat && location.lng) {
            const polygon = circle([location.lng, location.lat], drawMode.radius * 2, {
                steps: 3,
                units: "meters"
            });
            camera.fitToLngLats(polygon.geometry.coordinates[0] as LonLat[]);
        }
    }, [location]);

    const updateLocation = (location: DetailedLocation | undefined) => {
        setDrawMode({ ...drawMode, location })
    }

    const updateRadius = (radius?: number) => {
        setDrawMode({ ...drawMode, radius });
    }

    const updateCoordinates = (coordinates?: LonLat[]) => {
        setDrawMode({ ...drawMode, coordinates })
    }

    const enterDrawMode = (mode: DrawMode) => {
        setDrawMode(mode);
    }

    const reset = () => {
        resetDrawMode();
    }

    return { drawMode, reset, updateLocation, updateRadius, updateCoordinates, enterDrawMode };
}