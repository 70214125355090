import { TrackEntry } from "../types/track";

export function matchesAnyTrackEntry(entries: TrackEntry[], timestampInSeconds: number | undefined): boolean {
    if (!timestampInSeconds) {
        return false;
    }
    const ts = timestampInSeconds * 1000;
    return entries.some(e => ts >= e.startTs && ts <= e.endTs);
}

export function interpolateSpeeds(speeds: number[], timestamps: number[], steps: number): number[] {
    if (speeds.length !== timestamps.length || speeds.length === 0) {
        return [];
    }
    const output: number[] = [];
    const stepDuration = Math.floor((timestamps[timestamps.length - 1] - timestamps[0]) / steps);
    for (let i = 0; i < steps; i++) {
        const targetTimestamp = timestamps[0] + (i * stepDuration);
        let j = 0;
        while (j < timestamps.length && timestamps[j] < targetTimestamp) {
            j++;
        }
        if (j == 0) {
            output.push(speeds[0]);
        } else if (j >= timestamps.length) {
            output.push(speeds[speeds.length - 1]);
        } else if (timestamps[j] === targetTimestamp) {
            output.push(speeds[j]);
        } else {
            const weight = (targetTimestamp - timestamps[j - 1]) / (timestamps[j] - timestamps[j - 1]);
            output.push(speeds[j - 1] * (1 - weight) + speeds[j] * weight);
        }
    }
    return output;
}