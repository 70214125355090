import { useState } from 'react';
import { useRecoilState } from "recoil";
import { audioTranscriptionTextState } from "../states/audioState";
import { uploadAudioTranscription } from "../services/upload";

export const useAudioTranscription = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [text, setText] = useRecoilState(audioTranscriptionTextState);

    const fetchTranscriptions = async (audioBlob: Blob) => {
        setIsLoading(true);

        const file = new File([audioBlob], "audio");
        const response = await uploadAudioTranscription({ file: file, extension: ".wav" })

        const text = response.text || "";
        setText(text);
        setIsLoading(false);
        return text;
    };

    return { fetchTranscriptions, isLoading, text };
};