import { LabelSmall } from "baseui/typography";
import { useRecoilValue } from "recoil";
import { StyleObject } from "styletron-react";
import { placeState } from '../../states/placeState';
import { HFlex } from '../containers/HFlex';
import { Uid } from "../../types/core";

type Props = {
    uid: Uid;
    style?: StyleObject,
};

export function PlaceItemSimplified({ uid, style }: Props) {
    const place = useRecoilValue(placeState(uid));

    return (
        <HFlex spacing alignCenter style={{ ...style }}>
            <LabelSmall margin={0}>{place?.name}</LabelSmall>
        </HFlex>
    );
}
