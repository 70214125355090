import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { fieldsForTeamEditState } from '../../states/editTeamState';
import { editingTeamIdState } from "../../states/appState";
import { DetailedLocation } from '../../types/core';
import { useDrawMode } from "../useDrawMode";
import useAccount from "../useAccount";
import { useTeamForms } from "./useTeamForms";

export function useEditTeamLocation() {
    const [editingTeam, setEditingTeam] = useRecoilState(editingTeamIdState);
    const resetEditingTeam = useResetRecoilState(editingTeamIdState);
    const teamFields = useRecoilValue(fieldsForTeamEditState(editingTeam));
    const { drawMode, enterDrawMode, reset } = useDrawMode();
    const { company } = useAccount();
    const { submitTeam } = useTeamForms(editingTeam);

    const location = drawMode?.location;

    useEffect(() => {
        if (editingTeam > 0) {
            enterDrawMode({
                type: "team",
                action: "edit",
                location: {
                    lat: teamFields?.route?.start_lat || location?.lat || company?.location.lat || 0,
                    lng: teamFields?.route?.start_lng || location?.lng || company?.location.lng || 0,
                } as DetailedLocation
            });
        }
    }, [editingTeam, teamFields.route])

    const editTeam = async () => {
        if (location) {
            const team = {
                ...teamFields,
                route: {
                    start_lat: location.lat,
                    start_lng: location.lng,
                }
            };
            await submitTeam(team);
        }
    };

    const cancelEditTeam = () => {
        resetEditingTeam();
        reset();
    }

    return {
        initEditTeam: setEditingTeam,
        editTeam,
        cancelEditTeam,
    };
}