import { SIZE as SELECT_SIZE } from "baseui/input/constants";
import { TYPE } from "baseui/select";
import { FormItemType } from "../../types/form";
import { useForms } from "../../hooks/useForms";
import { StyledSelect } from "../ui/StyledSelect";


type Props = {
    value: FormItemType;
    onChange: (value: FormItemType) => void;
    jobInputForm?: boolean;
}

export function FormItemSelect({ value, onChange, jobInputForm = false }: Props) {
    const forms = useForms();

    const options = jobInputForm ? forms.jobOptions : forms.allOptions;
    const selected = options.find(o => o.id === value);

    return (
        <StyledSelect
            closeOnSelect
            size={SELECT_SIZE.compact}
            clearable={false}
            placeholder=""
            options={options}
            value={selected && [selected]}
            openOnClick
            type={TYPE.select}
            onChange={(params) => onChange(params.option?.id as FormItemType)}
            overrides={{
                Root: {
                    style: {
                        width: "200px"
                    }
                }
            }}
        />
    );
}