import { requestingState } from '../../states/viewState';
import { cloneJobs, CloneJobsRequest, getJobTemplates, saveJobAsTemplate } from "../../services/api";
import { useStateUpdater } from "../useStateUpdater";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { templateIdsState } from "../../states/templatesState";
import { Job } from "../../types/job";
import { editingJobIdState, selectedDayState } from "../../states/appState";

export function useJobTemplatesUpdater() {
    const setTemplateIds = useSetRecoilState(templateIdsState);
    const stateUpdater = useStateUpdater();
    const setEditingJob = useSetRecoilState(editingJobIdState);
    const setRequesting = useSetRecoilState(requestingState({ req: "jobtemplates" }));
    const day = useRecoilValue(selectedDayState);

    const saveAsTemplate = async (job: Job) => {
        const { status, id } = await saveJobAsTemplate({ id: job.id });
        if (status) {
            await updateJobTemplates();
        }
        return { id };
    };

    const updateJobTemplates = async () => {
        setRequesting(true);
        const { status, jobs } = await getJobTemplates();
        if (status) {
            stateUpdater.updateJobs(jobs);
            setTemplateIds(jobs.map(j => j.id));
        }
        setRequesting(false);
    };

    const createFromTemplate = async (template: Job, openJob: boolean = true) => {
        const data: CloneJobsRequest = {
            withAssignee: true,
            withDate: true,
            day: day,
            suffix: '',
            jobs: [],
            templates: [template.id],
        }
        const { status, jobs } = await cloneJobs(data);
        if (status) {
            stateUpdater.updateJobs(jobs);
            if (openJob) {
                setEditingJob(jobs[0].id);
            }
            return jobs[0];
        }
        return undefined;
    }

    return { saveAsTemplate, updateJobTemplates, createFromTemplate };
}

