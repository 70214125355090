import { randomString } from "../utils/app";
import { WEB_UPLOAD_BUCKET_ID } from "../constants/app";
import { AccountResponse, API_ENDPOINT, Endpoint, getAuthObj, STATUS_OK } from "./api";
import { ImportJobFileFormat } from "../types/job";
import { ImportMemberFileFormat } from "../types/member";
import { ImportZoneFileFormat } from "../types/zone";
import { ImportPlaceFileFormat } from "../types/place";
import { Uid } from "../types/core";

export interface UploadProfileImageRequest {
    file: File,
    uid: Uid,
}

export const updateProfileImage = async ({ file, uid }: UploadProfileImageRequest): Promise<AccountResponse> => {
    const data = new FormData();
    data.append('file', file);
    const authObjWithAccount = { ...getAuthObj(), account: uid };
    data.append('auth', JSON.stringify(authObjWithAccount));
    const response = await fetch(API_ENDPOINT + "/" + Endpoint.uploadprofileimage, {
        method: "post",
        body: data,
    });
    const json = await response.json();
    return {
        status: json.status === STATUS_OK,
        account: json.account,
    }
}

export interface UploadFileRequest {
    file: File,
    extension: string;
    controller?: AbortController;
}

export interface UploadJobImportRequest extends UploadFileRequest {
    extension: ImportJobFileFormat;
}

export interface UploadMemberImportRequest extends UploadFileRequest {
    extension: ImportMemberFileFormat;
}

export interface UploadZoneImportRequest extends UploadFileRequest {
    extension: ImportZoneFileFormat;
}

export interface UploadPlaceImportRequest extends UploadFileRequest {
    extension: ImportPlaceFileFormat;
}

export interface UploadFileResponse {
    status: boolean;
    url?: string;
    text?: string;
    result?: {
        "errorKey": string;
        "data": string;
        "success": boolean;
        "errorMessage": string;
        "index": number;
    }[];
}

export const uploadJobAttachment = async (req: UploadFileRequest): Promise<UploadFileResponse> => {
    return upload(req, Endpoint.uploadjobattachment);
}

export const uploadJobImport = async (req: UploadJobImportRequest): Promise<UploadFileResponse> => {
    return upload(req, Endpoint.uploadjobimport);
}

export const uploadPlaceImport = async (req: UploadPlaceImportRequest): Promise<UploadFileResponse> => {
    return upload(req, Endpoint.uploadplaceimport);
}

export const uploadZoneImport = async (req: UploadZoneImportRequest): Promise<UploadFileResponse> => {
    return upload(req, Endpoint.uploadzoneimport);
}

export const uploadMemberImport = async (req: UploadMemberImportRequest): Promise<UploadFileResponse> => {
    return upload(req, Endpoint.uploadmemberimport);
}

export const uploadAppLog = async (req: UploadFileRequest): Promise<UploadFileResponse> => {
    return upload(req, Endpoint.uploadapplog);
}

export const uploadAudioTranscription = async (req: UploadFileRequest): Promise<UploadFileResponse> => {
    return upload(req, Endpoint.uploadaudiotranscription);
}

const upload = async (req: UploadFileRequest, endpoint: Endpoint): Promise<UploadFileResponse> => {
    const data = new FormData();
    data.append('file', req.file);
    const fileId = randomString() + req.extension;
    const authObjWithAccount = { ...getAuthObj(), bucketId: WEB_UPLOAD_BUCKET_ID, fileId: fileId };
    data.append('auth', JSON.stringify(authObjWithAccount));
    try {
        const response = await fetch(API_ENDPOINT + "/" + endpoint, {
            method: "post",
            body: data,
            signal: req.controller?.signal
        });
        const json = await response.json();
        return {
            status: json.status === STATUS_OK,
            url: json.url,
            text: json.text,
            result: json.result,
        }
    } catch {
        return {
            status: false,
        }
    }
}