import { hasLocationAttached, Message } from "../../types/message";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { useTheme } from "../../hooks/useTheme";
import { VFlex } from "../containers/VFlex";
import { useRecoilValue } from "recoil";
import { memberNamesState, memberState } from "../../states/membersState";
import { useStrings } from "../../hooks/useStrings";
import { DoubleCheckIcon, RemoveIcon, SingleCheckIcon } from "../ui/svg";
import { StaticMap } from "../staticmap/StaticMap";
import React, { memo, useRef, useState } from "react";
import { StyledLink } from "baseui/link";
import Linkify from "react-linkify";
import { useHover } from "usehooks-ts";
import { Popconfirm } from "antd";
import { Button } from "baseui/button";
import { deleteMessage } from "../../services/api";
import { useConversation } from "../../hooks/useConversation";
import { TimeBadge } from "../badges/TimeBadge";
import { useNavigator } from "../../hooks/useNavigator";
import { useBoard } from "../../hooks/useBoard";
import { MemberBadge } from "../badges/MemberBadge";
import { Uid } from "../../types/core";


export const ChatMessage = memo(function ({ message }: { message: Message }) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const other = useRecoilValue(memberState(message.uid));
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);
    const { load } = useConversation(message.uid);
    const { navigateToPage } = useNavigator();
    const memberNames = useRecoilValue(memberNamesState);
    const board = useBoard();

    const liveDomain = "https://live.hellotracks.com/";
    const isLiveLink = (link: string) => link.startsWith(liveDomain);

    const replaceUsernamesInText = (text: string): { adaptedText: string; attachedUids: Uid[] } => {
        let attachedUids: Uid[] = [];
        const regex = /<[^|]+\|([^>]+)>/g;
        const adaptedText = text.replace(regex, (match, username) => {
            const member = memberNames.find(m => m.username === username);
            if (member && member.uid) {
                attachedUids.push(member.uid);
                return member.uid + "@email.com";
            }
            return username;
        });

        return { adaptedText, attachedUids };
    };

    const { adaptedText, attachedUids } = replaceUsernamesInText(message.msg);

    const onConfirmDelete = async () => {
        setLoading(true);
        if (message.id) {
            const { status } = await deleteMessage({ ids: [message.id] });
            if (status) {
                load();
            }
        }
        setLoading(false);
    }

    function onLinkClick(link: string) {
        if (attachedUids) {
            board.setMemberUids(attachedUids);
        }
        navigateToPage(link.substring(liveDomain.length))
    }

    return (
        <div ref={hoverRef}>
            <HFlex>
                {!message.in && <SpacerExpand/>}
                <VFlex style={{
                    position: "relative",
                    background: message.in ? (other?.color || "#FFFFFF") + "55" : theme.colors.primaryB,
                    color: theme.colors.contentPrimary,
                    marginTop: "8px",
                    marginBottom: "8px",
                    borderTopLeftRadius: !message.in ? "8px" : "0px",
                    borderTopRightRadius: message.in ? "8px" : "0px",
                    borderBottomLeftRadius: !message.in ? "8px" : "0px",
                    borderBottomRightRadius: message.in ? "8px" : "0px",
                    padding: "4px",
                }}>
                    {(isHover || opened) &&
                        <Popconfirm
                            title={strings.Messages.DeleteMessage}
                            okText={strings.General.Delete}
                            okType={"danger"}
                            onConfirm={onConfirmDelete}
                            onOpenChange={open => setOpened(open)}
                            okButtonProps={{ loading: loading }}
                        >
                            <Button kind={"tertiary"}
                                    size={"mini"}
                                    style={{ position: "absolute", top: 0, right: 0 }}
                            >
                                <RemoveIcon size={12} color={theme.colors.contentPrimary}/>
                            </Button>
                        </Popconfirm>
                    }
                    <Spacer height={"4px"}/>
                    <Linkify
                        componentDecorator={(href, txt, key) =>
                            <span>
                                {txt.includes("@")
                                    ? <MemberBadge uid={txt.substring(0, txt.indexOf("@")) as Uid} edit/>
                                    : <StyledLink
                                        animateUnderline
                                        target={"_blank"}
                                        onClick={isLiveLink(href) ? () => onLinkClick(href) : undefined}
                                        rel="noopener"
                                        href={isLiveLink(href) ? undefined : href}
                                        key={key}
                                    >
                                        {strings.General.OpenLink}
                                    </StyledLink>
                                }
                            </span>
                        }>
                        {adaptedText}
                    </Linkify>
                    {hasLocationAttached(message) &&
                        <StaticMap location={{ lat: message.lat, lng: message.lng }} viewMarker/>
                    }
                    <HFlex alignCenter style={{ marginTop: "8px" }}>
                        <SpacerExpand/>
                        <TimeBadge startMillis={message.ts} withDate style={{ marginRight: "0px" }}/>
                        {!message.in &&
                            <>
                                <Spacer/>
                                {message.read === 3
                                    ? <DoubleCheckIcon color={theme.customColors.accent} size={14}/>
                                    : <SingleCheckIcon color={theme.colors.contentPrimary} size={14}/>
                                }
                            </>
                        }
                    </HFlex>
                </VFlex>
            </HFlex>
        </div>
    );
});