import { useTheme } from "../../hooks/useTheme";
import { StyledModal } from "../ui/StyledModal";
import { ModalBody, ModalHeader } from "baseui/modal";
import React, { useState } from "react";
import { HFlex } from "../containers/HFlex";
import { LabelMedium } from "baseui/typography";
import { Spacer } from "../containers/Spacer";
import { Divider } from "antd";
import { VFlex } from "../containers/VFlex";
import { useRecoilValue } from "recoil";
import JobItem from "./JobItem";
import { Input } from "baseui/input";
import { SIZE } from "baseui/button-group";
import { Search } from "baseui/icon";
import { useStrings } from "../../hooks/useStrings";
import { Job } from "../../types/job";
import { jobsState } from "../../states/jobsState";
import { useToast } from "../../hooks/useToast";
import FixedJob from "../map/markers/FixedJob";

type Props = {
    onJobSelected: (job: Job) => void;
    onClose: () => void;
    day?: number;
    uid?: string;
}

export function SelectJobModal({ onJobSelected, onClose, day = 0, uid = "" }: Props) {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const jobs = useRecoilValue(jobsState);
    const [search, setSearch] = useState("");
    const toast = useToast();

    const lowercaseSearch = search.toLowerCase();
    const filtered = jobs
        .filter(t => (!day || t.day === day) && (!uid || t.receiverUid === uid))
        .filter(t => search.length === 0
            || t.destinationName.toLowerCase().includes(lowercaseSearch)
            || t.destinationText.toLowerCase().includes(lowercaseSearch)
        );

    const onSelected = async (job: Job) => {
        await onJobSelected(job);
        toast.showSuccess(strings.General.Assigned);
        onClose();
    };

    return (
        <StyledModal
            isOpen={true}
            onClose={onClose}
            customSize={750}
        >
            <ModalHeader>
                <HFlex spacing alignCenter>
                    <FixedJob icon={() => <Search/>} size={16} color={theme.colors.contentPrimary} needle={"short"}/>
                    <LabelMedium>{strings.Job.SearchJobAndAssign}</LabelMedium>
                </HFlex>
            </ModalHeader>
            <ModalBody>
                <Input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    placeholder={strings.Map.Filter}
                    clearable
                    size={SIZE.mini}
                    startEnhancer={<Search/>}
                />
                <Spacer/>
                <Divider orientation="left"/>
                <VFlex spacing={"16px"} style={{ overflow: "auto", height: "50vh" }}>
                    {filtered.length < 200 && filtered.map(t => <JobItem key={t.id} id={t.id}
                                                                         onSelected={onSelected}/>)}
                </VFlex>
            </ModalBody>
        </StyledModal>
    )
}