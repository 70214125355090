import { useRecoilValue, useSetRecoilState } from "recoil";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { EmptyState } from "../ui/EmptyState";
import { JourneyIcon } from "../ui/svg";
import { trackOrStopEntriesState, tracksDatesState } from "../../states/tracksState";
import { useTracksSelection } from "../../hooks/tracks/useTracksSelection";
import { useEffectOnce } from "usehooks-ts";
import React, { useState } from "react";
import { useBoard } from "../../hooks/useBoard";
import { VFlex } from "../containers/VFlex";
import { HFlex } from "../containers/HFlex";
import { MileageHistory } from "../histogram/MileageHistory";
import { Collapse } from "antd";
import { dayToDateBrowserTimezone } from "../../utils/time";
import { useTrackColumns } from "../../hooks/tracks/useTrackColumns";
import { ColumnOptions } from "../shared/ColumnOptions";
import { LocationTable } from "./LocationTable";
import { TrackTimeSlider } from "../pickers/TrackTimeSlider";

const { Panel } = Collapse;

export function LocationView() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const entries = useRecoilValue(trackOrStopEntriesState);
    const { clearSelection } = useTracksSelection();
    const board = useBoard();
    const [showMileage, setShowMileage] = useState(false);
    const setDates = useSetRecoilState(tracksDatesState);
    const { columns, enabledColumns, disabledColumns, reorderColumns, columnsConfiguration } = useTrackColumns();
    const isEmpty = !entries || entries.length === 0;
    const tracksSelection = useTracksSelection();

    useEffectOnce(() => { return () => clearSelection()});

    const mileageHistory = board.memberUids.length === 1 &&
        (
            <HFlex>
                <Collapse bordered={false}
                          defaultActiveKey={showMileage ? ['1'] : []}
                          onChange={(key) => setShowMileage(key.length > 0)}
                          style={{ backgroundColor: "transparent" }}
                >
                    <Panel key={"1"} header={strings.General.MileageHistory} style={{ width: "400px" }}>
                        <MileageHistory
                            uid={board.memberUids[0]}
                            showInfo={false}
                            style={{ width: "300px", marginLeft: 0 }}
                            onDaySelected={(day) => {
                                setDates([dayToDateBrowserTimezone(day), dayToDateBrowserTimezone(day)])
                            }}
                        />
                    </Panel>
                </Collapse>
            </HFlex>
        );

    if (isEmpty) {
        return (
            <VFlex spacing>
                <EmptyState subtitle={strings.EmptyStates.MemberTracksEmptyTitle}>
                    <JourneyIcon size={64} color={theme.colors.contentTertiary}/>
                </EmptyState>
                {mileageHistory}
            </VFlex>
        );
    }


    return (
        <VFlex>
            <VFlex style={{ flex: 1, position: "relative" }}>
                <ColumnOptions
                    columns={columns}
                    enabledColumns={enabledColumns}
                    configuration={columnsConfiguration}
                    disabledColumns={disabledColumns}
                    onReorder={reorderColumns}
                />
                <div style={{
                    flex: 1,
                    paddingBottom: "4px",
                    overflowY: "hidden",
                    overflowX: "auto",
                }}>
                    <LocationTable entries={entries}/>
                </div>
            </VFlex>
            {tracksSelection.selectionCount === 0 && mileageHistory}
            {tracksSelection.selectionCount === 1 && <TrackTimeSlider/>}
        </VFlex>
    );
}