import { Button } from "baseui/button";
import { StyledDivider } from "baseui/divider";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { StyledLink } from "baseui/link";
import { Modal, ModalBody, ROLE, SIZE } from "baseui/modal";
import { PinCode } from "baseui/pin-code";
import { HeadingXLarge, HeadingXSmall, LabelSmall, LabelXSmall, ParagraphMedium } from "baseui/typography";
import { ChangeEvent, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { HFlex } from "../components/containers/HFlex";
import { Spacer, SpacerExpand } from "../components/containers/Spacer";
import { VFlex } from "../components/containers/VFlex";
import useAccount from "../hooks/useAccount";
import useDirector from "../hooks/useDirector";
import { useStrings } from "../hooks/useStrings";
import { useTheme } from "../hooks/useTheme";
import {
    sendResetPasswordLinkTo,
    STATUS_ERROR_NOPERMISSION,
    STATUS_ERROR_NOTREADY,
    STATUS_ERROR_PASSWORDMISMATCH,
    STATUS_ERROR_THROTTLING,
    STATUS_ERROR_USERUNKNOWN,
} from "../services/api";
import { loggedInState, passwordState, usernameState } from "../states/accountState";
import { Alert } from "antd";
import { COLOR_LOGIN_BACKGROUND } from "../constants/colors";
import { useRedirect } from "../hooks/useRedirect";
import { useNavigator } from "../hooks/useNavigator";

export default function LoginView() {
    const { strings, format } = useStrings();
    const account = useAccount();
    const [username, setUsername] = useRecoilState(usernameState);
    const [password, setPassword] = useRecoilState(passwordState);
    const isLoggedIn = useRecoilValue(loggedInState);
    const { performLogin } = useDirector();
    const { theme, css } = useTheme();
    const [errorMessage, setErrorMessage] = useState("");
    const [show2FA, setShow2FA] = useState<any>();
    const [code, setCode] = useState<string[]>(["", "", "", ""]);
    const [loading, setLoading] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const { navigateToPage } = useNavigator();
    useRedirect();

    useEffect(() => setUsername(account.username), [account.username]);

    const onUsernameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUsername(value);
    };

    const onPasswordChange = ({ target: { value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPassword(value);
    };

    const codeString = show2FA ? code[0] + code[1] + code[2] + code[3] : "";

    const onLoginClick = async () => {
        account.setToken("");
        setLoading(true);
        const result = await performLogin(true, codeString);
        setErrorMessage("");
        if (result.error) {
            switch (result.error) {
                case STATUS_ERROR_USERUNKNOWN:
                    setErrorMessage(strings.Login.UserUnknown);
                    break;
                case STATUS_ERROR_PASSWORDMISMATCH:
                    setErrorMessage(show2FA
                        ? strings.General.IncorrectCode
                        : strings.Login.InvalidUsernameOrPassword);
                    break;
                case STATUS_ERROR_NOPERMISSION:
                    setErrorMessage(strings.Login.NoPermissionEnterWeb);
                    break;
                case STATUS_ERROR_THROTTLING:
                    setErrorMessage(strings.Login.Throttling);
                    break;
                case STATUS_ERROR_NOTREADY:
                    setErrorMessage(strings.Login.Busy);
                    break;
                default:
                    setErrorMessage(strings.Login.SomethingWentWrong);
            }
        } else if (result.data.verify) {
            setShow2FA(result.data.verify);
        }
        setLoading(false);
    };

    const onForgotPassword = () => {
        setForgotPassword(true);
        resetFields();
    };

    const onBackToLogin = () => {
        setForgotPassword(false);
        resetFields();
    };

    const onPasswordReset = () => {
        sendResetPasswordLinkTo(username).then(result => {
            if (result.status) {
                const text = format(strings.Login.ForgotPasswordLinkToX, result.email + " " + result.phone);
                setErrorMessage(text as string);
            } else {
                setErrorMessage(strings.Login.ForgotPasswordTryAgain);
            }
        });
        setForgotPassword(false);
        resetFields();
    };

    const resetFields = () => {
        setUsername("");
        setPassword("");
        setErrorMessage("");
    };

    const isLoginDisabled = (show2FA && codeString.length !== 4) || (!show2FA && (!password || !username));

    const onSsoClick = () => {
        navigateToPage("sso", true);
    }

    return (
        <Modal
            isOpen={!isLoggedIn}
            closeable={false}
            animate
            autoFocus
            size={SIZE.default}
            role={ROLE.dialog}
            overrides={{
                Dialog: {
                    style: {
                        backgroundColor: theme.colors.backgroundTertiary + "F0",
                        padding: theme.sizing.scale1400,
                    },
                },
                DialogContainer: {
                    style: {
                        backgroundColor: COLOR_LOGIN_BACKGROUND,
                        backgroundImage: "url(/images/login-background.svg)",
                        backgroundPosition: "50%",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    },
                },
            }}
        >
            <ModalBody $style={{
                marginTop: 0,
                marginRight: 0,
                marginBottom: 0,
                marginLeft: 0,
            }}>
                <VFlex alignCenter style={{ marginBottom: theme.sizing.scale1000 }}>
                    {!forgotPassword && (
                        <>
                            <HeadingXLarge margin={0}>{strings.General.WelcomeBack}</HeadingXLarge>
                            <HeadingXSmall margin={0}>{strings.General.LogIntoHellotracks}</HeadingXSmall>
                        </>
                    )}
                    {forgotPassword &&
                        <HeadingXLarge margin={0}>{strings.General.RequestPasswordResetLink}</HeadingXLarge>}
                </VFlex>
                {!show2FA && (
                    <>
                        <FormControl label={strings.General.UsernameOrEmail}>
                            <Input
                                name="username"
                                placeholder="user@company.com"
                                value={username}
                                type="email"
                                onChange={(e) => onUsernameChange(e)}
                                clearOnEscape
                            />
                        </FormControl>
                        {!forgotPassword && (
                            <FormControl
                                label={strings.General.Password}
                                caption={
                                    <HFlex style={{ justifyContent: "flex-end" }}>
                                        <StyledLink className={css({
                                            cursor: "pointer",
                                        })} onClick={onForgotPassword}>
                                            {strings.General.ForgotPassword}
                                        </StyledLink>
                                    </HFlex>
                                }
                            >
                                <Input
                                    type="password"
                                    name="password"
                                    placeholder={strings.General.YourPassword}
                                    value={password}
                                    onChange={(e) => onPasswordChange(e)}
                                    clearOnEscape
                                />
                            </FormControl>
                        )}
                    </>
                )}
                {show2FA && (
                    <>
                        <LabelSmall>{strings.ProfileModal.EnterVerificationCode}</LabelSmall>
                        <Spacer/>
                        <PinCode autoFocus={true} values={code} onChange={({ values }) => setCode(values)}/>
                        <Spacer/>
                        {show2FA.email && (
                            <LabelXSmall>
                                {format(strings.ProfileModal.EmailVerificationCodeSentToX, show2FA.email)}
                            </LabelXSmall>
                        )}
                        {show2FA.phone && (
                            <LabelXSmall>
                                {format(strings.ProfileModal.PhoneVerificationCodeSentToX, show2FA.phone)}
                            </LabelXSmall>
                        )}
                    </>
                )}
                {errorMessage && (
                    <Alert showIcon type={"warning"} description={errorMessage}/>
                )}
                <VFlex style={{ marginTop: theme.sizing.scale1000 }}>
                    {!forgotPassword && (
                        <Button
                            onClick={onLoginClick}
                            disabled={isLoginDisabled}
                            isLoading={loading}
                        >
                            {show2FA ? strings.General.Confirm : strings.General.Login}
                        </Button>
                    )}
                    {forgotPassword && (
                        <Button
                            onClick={onPasswordReset}
                            disabled={!username}
                            isLoading={loading}
                        >
                            {strings.General.RequestPasswordReset}
                        </Button>
                    )}
                </VFlex>
                {!show2FA && (
                    <>
                        <StyledDivider
                            $size="cell"
                            className={css({
                                marginTop: theme.sizing.scale900,
                                marginBottom: theme.sizing.scale800,
                            })}
                        />
                        {!forgotPassword && (
                            <HFlex>
                                <SpacerExpand/>
                                <Button
                                    onClick={onSsoClick}
                                    size={"mini"}
                                    kind={"tertiary"}
                                >
                                    {strings.General.SingleSignOn}
                                </Button>
                                <SpacerExpand/>
                            </HFlex>
                        )}
                        <HFlex style={{ justifyContent: "center" }}>
                            {!forgotPassword && (
                                <ParagraphMedium margin={0}>
                                    {strings.General.DontHaveAnAccountYet}{" "}
                                    <StyledLink
                                        className={css({ cursor: "pointer" })}
                                        href="/signup"
                                        onClick={(event: any) => {
                                            event.preventDefault();
                                            navigateToPage("signup", true);
                                        }}
                                    >
                                        {strings.General.Signup}
                                    </StyledLink>
                                </ParagraphMedium>
                            )}
                            {forgotPassword && (
                                <ParagraphMedium margin={0}>
                                    {strings.General.BackTo}{" "}
                                    <StyledLink
                                        className={css({ cursor: "pointer" })}
                                        onClick={onBackToLogin}
                                    >
                                        {strings.General.Login}
                                    </StyledLink>
                                </ParagraphMedium>
                            )}
                        </HFlex>
                    </>
                )}
            </ModalBody>
        </Modal>
    );
}
