import { useRecoilValue } from "recoil";
import { jobTypeOptionsState } from "../../states/optionsState";


export function useJobTypeLabels() {
    const jobTypeOptions = useRecoilValue(jobTypeOptionsState);
    const getLabelForJobType = (id: number) => jobTypeOptions.find(t => t.id === id)?.label || "";

    return {
        getLabelForJobType,
    }
}