import { Input } from "baseui/input";
import { useTheme } from "../../../hooks/useTheme";


export function StylishInput({ ...props }: React.ComponentProps<typeof Input>) {
    const { theme } = useTheme();
    return (
        <Input
            {...props}
            overrides={{
                Root: {
                    style: ({ $isFocused }) => ({
                        backgroundColor: "transparent",
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopColor: "transparent",
                        borderRightColor: "transparent",
                        borderLeftColor: "transparent",
                        borderBottomStyle: "dashed",
                        borderBottomColor: $isFocused ? theme.colors.contentPrimary : theme.colors.contentSecondary,
                        paddingLeft: props.type === "password" ? "50px" : 0
                    })
                },
                InputContainer: {
                    style: {
                        backgroundColor: "transparent",
                    }
                },
                Input: {
                    style: {
                        textAlign: "center",
                        "::placeholder": {
                            color: theme.colors.contentSecondary,
                        }
                    }
                }
            }}
        />
    )
}