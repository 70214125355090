import { Button } from "baseui/button";
import { useTheme } from "../../../hooks/useTheme";


export function StylishButton({ ...props }: React.ComponentProps<typeof Button>) {
    const { theme } = useTheme();

    return (
        <Button
            {...props}
            overrides={{
                Root: { style: { width: "100%" } },
                BaseButton: {
                    style: {
                        ":disabled": {
                            backgroundColor: "rgba(255, 255, 255, 0.4)",
                            color: theme.colors.contentInverseSecondary,
                        }
                    }
                }
            }}
        />
    );
}