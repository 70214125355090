import { LightTheme } from "baseui";
import { EmptyState } from "../components/ui/EmptyState";
import { Centered } from "../components/containers/Centered";
import { Spin } from "antd";
import { HFlex } from "../components/containers/HFlex";
import { useStrings } from "../hooks/useStrings";
import useAccount from "../hooks/useAccount";
import useDirector from "../hooks/useDirector";
import { useNavigator } from "../hooks/useNavigator";
import { PAGE } from "../constants/pages";
import { useEffect, useState } from "react";
import { Button } from "baseui/button";
import { useRecoilValue } from "recoil";
import { loggedInState } from "../states/accountState";
import { useLocation } from "react-router-dom";
import { Uid } from "../types/core";

export function AuthenticatedView() {
    const account = useAccount();
    const { strings } = useStrings();
    const { performLogin, logout } = useDirector();
    const { navigateToPage } = useNavigator();
    const [showLoginButton, setShowLoginButton] = useState(false);
    const isLoggedIn = useRecoilValue(loggedInState);
    const [autoLoggedIn, setAutoLoggedIn] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (isLoggedIn) {
            navigateToPage(PAGE.LIVE);
            return;
        }

        const queryParams = new URLSearchParams(location.search);
        const bearer = queryParams.get('bearer');
        const uid = queryParams.get('uid') as Uid;
        const usr = queryParams.get('usr');
        const autoLogin = async () => {
            if (usr && uid && bearer && !autoLoggedIn) {
                account.setUsername(usr);
                account.setUid(uid);
                account.setToken(bearer);
                const result = await performLogin(true);
                if (!result.error) {
                    navigateToPage(PAGE.LIVE);
                    return;
                }
                setAutoLoggedIn(true);
            }
            setShowLoginButton(true);
        }

        autoLogin();
    }, [isLoggedIn]);

    const loginButton = (
        <Button onClick={() => {
            navigateToPage("login");
            logout();
        }}>
            {strings.General.Login}
        </Button>
    );

    return (
        <HFlex
            style={{
                display: "flex",
                background: LightTheme.colors.backgroundPrimary,
                overflowY: "auto",
                justifyContent: "center",
                height: "100%",
            }}
        >
            <Centered>
                {showLoginButton
                    ? loginButton
                    : <EmptyState title={strings.General.Loading}>
                        <Spin/>
                    </EmptyState>
                }
            </Centered>
        </HFlex>
    );
}
