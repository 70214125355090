import { Button } from "baseui/button";
import { Card, StyledAction, StyledBody } from "baseui/card";
import { memo, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useHover } from "usehooks-ts";
import { useTeamForms } from "../../hooks/members/useTeamForms";
import { useTeamManagement } from "../../hooks/members/useTeamManagement";
import useAccount from "../../hooks/useAccount";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import {
    memberNamesFilteredState,
    memberSearchState,
    memberState,
    updateTeamMembersState
} from "../../states/membersState";
import { teamsExpandedState } from "../../states/teamsState";
import { Team } from "../../types/core";
import { Member } from "../../types/member";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { ConfirmModal } from "../ui/ConfirmModal";
import { PlusIcon } from "../ui/svg";
import TeamMembers from "./TeamMembers";
import { TeamRouting } from "./TeamRouting";
import { EditingTeamName, TeamSectionName, TeamSectionTitle, TeamSectionTitleHover } from "./TeamSectionTitle";
import { TeamWorkingHours } from "./TeamWorkingHours";
import { getRecoil } from "../../providers/RecoilAccessProvider";

type Props = {
    team: Team;
    onAddMember: (team: { id: number; name: string; members: Member[] }) => void;
    onRemoveMember: (team: { id: number; name: string; members: Member[] }, member: Member) => void;
}

export const TeamSection = memo(({ team, onAddMember, onRemoveMember }: Props) => {
    const { theme } = useTheme();
    const { strings, format } = useStrings();
    const isFiltering = useRecoilValue(memberSearchState).length > 0;
    const memberNamesFiltered = useRecoilValue(memberNamesFilteredState);
    const updateTeamMembers = useRecoilValue(updateTeamMembersState);

    const members = useMemo(() => {
        return memberNamesFiltered.map(m => getRecoil(memberState(m.uid))).filter(m => !!m)
    }, [memberNamesFiltered, updateTeamMembers]) as Member[];

    const teamOnly: Member[] = useMemo(() => {
        return members
            .filter(m => m?.teams.includes(team.id));
    }, [members]) as Member[];

    const [expanded, setExpanded] = useRecoilState(teamsExpandedState(team.id));
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    const teamForms = useTeamForms(team.id);
    const [isEditingWorkingHours, setIsEditingWorkingHours] = useState(false);
    const [isEditingRouting, setIsEditingRouting] = useState(false);
    const [isEditingName, setIsEditingName] = useState(false);
    const { onSubmit, isLoading, canSubmit } = useTeamForms(team.id);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const { deleteTeam } = useTeamManagement();
    const { isSelfAdminOrCompany, uid } = useAccount();

    const isSelfInTeam = teamOnly.map(member => member.uid).includes(uid);
    const canEdit = isSelfAdminOrCompany || isSelfInTeam;
    const selectedTeam = { id: team.id, name: team.name, members: teamOnly };

    const handleDeleteTeam = async () => {
        if (!selectedTeam) {
            return;
        }
        await deleteTeam(team.id, teamOnly);
        await teamForms.onSubmit();
        setOpenDeleteModal(false);
    };

    const handleEditWorkingHours = (edit: boolean) => {
        setIsEditingWorkingHours(edit);
        if (edit) {
            setExpanded(true);
        }
    }

    const handleEditRouting = (edit: boolean) => {
        setIsEditingRouting(edit);
        if (edit) {
            setExpanded(true);
        }
    }

    const handleUpdateTeamName = async () => {
        await onSubmit();
        setIsEditingName(false);
    }

    const showRouting = expanded && isEditingRouting && !isEditingWorkingHours;
    const showWorkingHours = expanded && isEditingWorkingHours && !isEditingRouting;
    const showDetails = (expanded || isFiltering) && !isEditingWorkingHours && !isEditingRouting;
    const showHoverContent = isHover && !isEditingWorkingHours && !isEditingRouting;
    const showMoreButton = !isHover && !isEditingWorkingHours && !isEditingRouting;

    if (isFiltering && teamOnly.length === 0) {
        return null;
    }

    return (
        <div key={team.id}>
            <Card 
                title={
                    <TeamSectionTitle
                        ref={hoverRef}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        isEditingWorkingHours={isEditingWorkingHours}
                        isEditingRouting={isEditingRouting}
                    >
                        {isEditingName && (
                            <EditingTeamName
                                name={teamForms.teamFields.name}
                                isLoading={isLoading}
                                canSubmit={canSubmit}
                                onNameChange={(value) => {
                                    teamForms.setTeamFields((prev) => ({
                                        ...prev,
                                        name: value
                                    }))
                                }}
                                onUpdateTeamName={handleUpdateTeamName}
                            />
                        )}
                        {!isEditingName && (
                            <TeamSectionName
                                canEdit={canEdit}
                                membersLength={teamOnly.length}
                                onEditName={() => {
                                    setIsEditingName(true);
                                }}
                                showHoverContent={showHoverContent}
                                teamName={team.name}
                                showMoreButton={showMoreButton}
                                hoverContent={
                                    showHoverContent && <TeamSectionTitleHover
                                    onEditRouting={() => {
                                        handleEditRouting(true);
                                    }}
                                    onEditWorkingHours={() => {
                                        handleEditWorkingHours(true);
                                    }}
                                    onDelete={() => {
                                        setOpenDeleteModal(true);
                                        teamForms.setTeamFields((prev) => ({ ...prev, name: "" }));
                                    }}
                                />
                                }
                            />
                        )}
                    </TeamSectionTitle>
                }
                overrides={{
                    Root: {
                        style: {
                            backgroundColor: showRouting || showWorkingHours
                                ? theme.colors.backgroundSecondary
                                : "transparent",
                        }
                    }
                }}
            >
                {showRouting && (
                    <TeamRouting teamId={team.id} close={() => handleEditRouting(false)}/>
                )}
                {showWorkingHours && (
                    <TeamWorkingHours teamId={team.id} close={() => handleEditWorkingHours(false)}/>
                )}
                {showDetails &&
                    <>
                        <StyledBody>
                            <TeamMembers
                                members={teamOnly}
                                canEdit={canEdit}
                                onRemoveMember={(member) => onRemoveMember(selectedTeam, member)}
                            />
                        </StyledBody>
                        {canEdit && <StyledAction>
                            <HFlex>
                                <SpacerExpand/>
                                <Button kind={"tertiary"}
                                        size={"mini"}
                                        onClick={() => onAddMember(selectedTeam)}
                                >
                                    <PlusIcon size={16} color={theme.colors.contentPrimary}/>
                                    <Spacer/>
                                    {format(strings.TeamManagement.AddMemberToX, team.name)}
                                </Button>
                                <SpacerExpand/>
                            </HFlex>
                        </StyledAction>}
                    </>}
            </Card>
            <ConfirmModal
                isOpen={openDeleteModal}
                description={team.name}
                title={strings.TeamManagement.DeleteTeamDesc}
                onCancel={() => {
                    setOpenDeleteModal(false);
                    teamForms.resetTeamFields();
                }}
                onConfirm={handleDeleteTeam}
                cancelText={strings.General.Cancel}
                confirmText={strings.General.Delete}
                danger
            />
        </div>
    );
});