import { useRecoilValue } from "recoil";
import { teamsState } from "../../states/teamsState";
import { useStrings } from "../useStrings";

export function useTeams() {
    const teams = useRecoilValue(teamsState);
    const { strings } = useStrings();

    const getTeamName = (id: number): string => {
        return teams.find(team => team.id === id)?.name || strings.General.None;
    };

    return { getTeamName };
}