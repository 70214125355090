import { LabelSmall } from "baseui/typography";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { Spacer } from "../containers/Spacer";
import { HFlex } from "../containers/HFlex";
import { useRecoilState } from "recoil";
import { tracksAndStopsConfigState } from "../../states/viewState";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";

export function LocationFilterRow() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const [config, setConfig] = useRecoilState(tracksAndStopsConfigState);

    const checkedStyle = {
        backgroundColor: theme.customColors.accent,
        color: "white",
    };

    const uncheckedStyle = {
        color: theme.colors.contentPrimary,
    };

    return (
        <HFlex alignCenter>
            <LabelSmall style={{ marginRight: "4px" }}>{strings.General.Tracks + ": "}</LabelSmall>
            <CheckableTag
                style={config.showTracks ? checkedStyle : uncheckedStyle}
                checked={config.showTracks}
                onChange={checked => setConfig({
                    ...config,
                    showTracks: checked
                })}
            >
                {strings.General.ShowAll}
            </CheckableTag>
            <Spacer width={"32px"}/>
            <LabelSmall style={{ marginRight: "4px" }}>{strings.General.Stops + ": "}</LabelSmall>
            <CheckableTag
                style={config.showStopsAtPlaces && config.showStopsAtJobs && config.showStopsOther ? checkedStyle : uncheckedStyle}
                checked={config.showStopsAtPlaces && config.showStopsAtJobs && config.showStopsOther}
                onChange={checked => setConfig({
                    ...config,
                    showStopsAtPlaces: checked,
                    showStopsAtJobs: checked,
                    showStopsOther: checked
                })}
            >
                {strings.General.ShowAll}
            </CheckableTag>
            <CheckableTag
                style={config.showStopsAtPlaces ? checkedStyle : uncheckedStyle}
                checked={config.showStopsAtPlaces}
                onChange={checked => setConfig({
                    ...config,
                    showStopsAtPlaces: checked
                })}
            >
                {strings.General.StopAtPlace}
            </CheckableTag>
            <CheckableTag
                style={config.showStopsAtJobs ? checkedStyle : uncheckedStyle}
                checked={config.showStopsAtJobs}
                onChange={checked => setConfig({
                    ...config,
                    showStopsAtJobs: checked
                })}
            >
                {strings.General.StopAtJob}
            </CheckableTag>
            <CheckableTag
                style={config.showStopsOther ? checkedStyle : uncheckedStyle}
                checked={config.showStopsOther}
                onChange={checked => setConfig({
                    ...config,
                    showStopsOther: checked
                })}
            >
                {strings.General.StopBetweenTracks}
            </CheckableTag>
            <Spacer width={"32px"}/>
            <LabelSmall style={{ marginRight: "4px" }}>{strings.Map.Waypoints + ": "}</LabelSmall>
            <CheckableTag
                style={config.showWaypoints ? checkedStyle : uncheckedStyle}
                checked={config.showWaypoints}
                onChange={checked => setConfig({
                    ...config,
                    showWaypoints: checked
                })}
            >
                {strings.General.ShowAll}
            </CheckableTag>
        </HFlex>
    )
}