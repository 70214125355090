import { EMPTY_UID, InviteCodeData } from './../types/core';
import { atomWithIndexedPersistence, atomWithPersistence } from "../utils/persistence";
import { Company } from '../types/company';
import { Billing } from "../types/billing";
import { atom, selector } from "recoil";
import { pageState } from "./appState";
import { DAY, HOUR } from "../utils/time";
import { Timestamps } from "../types/member";

export const passwordState = atom({ key: "account.password", default: "" });

export const hardLoginTsState = atomWithPersistence("account.hardLoginTs", 0);
export const usernameState = atomWithPersistence("account.username", "");
export const loggedInState = atomWithPersistence("account.loggedIn", false);
export const loginTsState = atomWithPersistence("account.loginTs", 0);
export const uidState = atomWithPersistence("account.uid", EMPTY_UID);
export const iconState = atomWithPersistence("account.icon", "");
export const nameState = atomWithPersistence("account.name", "");
export const roleState = atomWithPersistence("account.role", "");
export const timeFormatState = atomWithPersistence("account.timeFormat", "24");
export const distanceFormatState = atomWithPersistence("account.distanceFormat", "SI");
export const languageState = atomWithPersistence("account.language", "");
export const timezoneState = atomWithPersistence("account.timezone", "");
export const companyState = atomWithPersistence<Company | undefined>("company", undefined);
export const inviteCodesState = atomWithIndexedPersistence<InviteCodeData[]>("invite.codes", []);
export const billingState = atomWithPersistence<Billing | undefined>("account.billing", undefined);
export const timestampsState = atomWithPersistence<Timestamps | undefined>("account.timestamps", undefined);

export const tokenState = atomWithPersistence("account.token", "");
export const accessState = atomWithPersistence("account.access", "");
export const accountKeyState = atomWithPersistence("account.key", "");
export const companyKeyState = atomWithPersistence("company.key", "");
export const businessState = atomWithPersistence("account.isBusiness", false);
export const companyUsernameState = atomWithPersistence("company.username", "");
export const slackTeamIdState = atomWithPersistence("slack.teamid", "");
export const unblockTsState = atomWithPersistence("unblock.ts", 0);
export const paymentMethodReminderTsState = atomWithPersistence("paymentmethod.reminder.ts", 0);

export const accountBlockedState = selector<boolean>({
    key: "account.blocked",
    get: ({ get }) => {
        const billing = get(billingState);
        const customer = billing?.customerState?.state;
        const unblocked = Date.now() < get(unblockTsState);
        const blocked = customer === "no_card" && !!billing?.subscription;
        if (blocked) {
            get(pageState); // this will reevaluate to blocked state on page changes
        }
        return blocked && !unblocked;
    }
});

export const showPaymentMethodReminderState = selector<boolean>({
    key: "paymentmethod.reminder.show",
    get: ({ get }) => {
        const billing = get(billingState);
        const customer = billing?.customerState?.state;
        const reminderTs = get(paymentMethodReminderTsState);
        if (customer === "trialing"
            && billing?.subscription?.trialEnd
            && billing?.subscription.trialEnd * 1000 < Date.now() + 14 * DAY
            && reminderTs < Date.now() - 48 * HOUR
        ) {
            return true;
        }
        return false;
    }
});

// TODO not being used
export const accountDelinquentState = selector<boolean>({
    key: "account.delinquent",
    get: ({ get }) => {
        const state = get(billingState)?.customerState.state;
        return state === "no_card" || state === "delinquent";
    }
});