import { Button, KIND, SHAPE, SIZE } from 'baseui/button';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { actionsIsOpenState } from '../../states/skeletonState';
import { CloseIcon } from '../ui/svg';
import { StyledCard } from '../ui/StyledCard';
import { ActionPanelContent } from "../../views/ActionPanelContent";
import { quickRouteCoordinatesState } from "../../states/quickRouteState";
import { ActionPanelHeader } from "../../views/ActionPanelHeader";
import { editingJobIdState } from "../../states/appState";
import { actionPanelWidthState } from '../../states/actionPanelState';
import { useTheme } from "../../hooks/useTheme";

export default function SkeletonActionsWrapper() {
    const { css, theme } = useTheme();
    const actionsIsOpen = useRecoilValue(actionsIsOpenState);
    const resetQuickRouteCoordinates = useResetRecoilState(quickRouteCoordinatesState);
    const resetEditJob = useResetRecoilState(editingJobIdState);
    const actionPanelWidth = useRecoilValue(actionPanelWidthState);

    const onActionsClose = () => {
        resetQuickRouteCoordinates();
        resetEditJob();
    };

    const cardWrapperStyles = css({
        position: 'absolute',
        top: '5px',
        bottom: '5px',
        left: actionsIsOpen ? '16px' : `-${actionPanelWidth}px`,
        margin: 'auto',
        transition: 'left 0.6s cubic-bezier(0.5, 1.5, 0.5, 1), width 0.4s',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 32px)',
        width: `${actionPanelWidth}px`,
        flexGrow: 1,
    });

    return (
        <div className={cardWrapperStyles}>
            <StyledCard
                style={{ backgroundColor: theme.colors.backgroundSecondary }}
                title={<ActionPanelHeader/>}
                actions={
                    <Button
                        size={SIZE.mini}
                        shape={SHAPE.circle}
                        onClick={onActionsClose}
                        kind={KIND.tertiary}
                    >
                        <CloseIcon color={theme.colors.backgroundInversePrimary}/>
                    </Button>
                }
            >
                <ActionPanelContent/>
            </StyledCard>
        </div>
    );
}
