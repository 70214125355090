import { useRecoilValue } from "recoil";
import { dayState } from "../../states/appState";
import { requestingState, timesheetByDayState } from "../../states/viewState";
import { UnorderedList } from "../containers/UnorderedList";
import { TimesheetLine } from "./TimesheetLine";
import { VFlex } from "../containers/VFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { EmptyState } from "../ui/EmptyState";
import { OnSiteReportIcon } from "../ui/svg";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import { Centered } from "../containers/Centered";
import { Spin } from "antd";
import { useState } from "react";
import { Member } from "../../types/member";
import { useTimesheet } from "../../hooks/useTimesheet";
import { HFlex } from "../containers/HFlex";
import { MemberSelect } from "../pickers/MemberSelect";
import { Button } from "baseui/button";
import { Plus } from "baseui/icon";
import { StyledTimePicker } from "../pickers/StyledTimePicker";
import { LabelSmall } from "baseui/typography";
import { PinConfirmation } from "../shared/PinConfirmation";

export function TimesheetTable() {
    const { strings } = useStrings();
    const { theme } = useTheme();
    const day = useRecoilValue(dayState);
    const timesheetEntries = useRecoilValue(timesheetByDayState(day));
    const requesting = useRecoilValue(requestingState({ req: "timesheet" }));
    const [showPinConfirm, setShowPinConfirm] = useState(false);
    const [pinConfirmed, setPinConfirmed] = useState(false);
    const [newEntryIn, setNewEntryIn] = useState<Date | null>(null);
    const [newEntryOut, setNewEntryOut] = useState<Date | null>(null);
    const [selectedMember, setSelectedMember] = useState<Member>();
    const timesheet = useTimesheet();
    const [showNewEntry, setShowNewEntry] = useState(false);

    const cleanNewTimesheet = () => {
        setNewEntryIn(null);
        setNewEntryOut(null);
        setSelectedMember(undefined);
        setShowNewEntry(false);
    }

    const onNewTimesheet = async () => {
        if (!selectedMember) {
            return;
        }
        setShowNewEntry(false);
        await timesheet.newEntry(selectedMember.uid, newEntryIn, newEntryOut);
        return cleanNewTimesheet();
    };


    if (requesting && timesheetEntries.length === 0) {
        return (
            <Centered>
                <Spin/>
            </Centered>
        );
    }

    const newEntryLayout = (
        <VFlex spacing>
            <Spacer height={"24px"}/>
            <LabelSmall>{strings.General.NewEntry}</LabelSmall>
            <MemberSelect placeholder={strings.General.Assignee}
                          onMemberSelect={(member?: Member) => setSelectedMember(member)}
                          selectedMember={selectedMember} hideUnassigned/>
            <HFlex alignCenter style={{ width: "min-content" }}>
                <StyledTimePicker
                    value={newEntryIn}
                    onChange={setNewEntryIn}
                />
                {"—"}
                <StyledTimePicker
                    value={newEntryOut}
                    onChange={setNewEntryOut}
                />
            </HFlex>
            <HFlex>
                <Button size={"mini"} kind={"tertiary"} onClick={cleanNewTimesheet}>
                    {strings.General.Cancel}
                </Button>
                <SpacerExpand/>
                <Button size={"mini"} onClick={onNewTimesheet}>
                    {strings.General.Save}
                </Button>
            </HFlex>
            <Spacer height={"24px"}/>
        </VFlex>
    );

    const newEntryButton = (
        <>
            <HFlex alignCenter style={{ minHeight: "64px", flex: "none" }}>
                <SpacerExpand/>
                {pinConfirmed && showNewEntry && newEntryLayout}
                {pinConfirmed && !showNewEntry && (
                    <Button size="compact" shape="circle" onClick={() => setShowNewEntry(true)}>
                        <Plus size={16} color={theme.colors.contentInversePrimary}/>
                    </Button>
                )}
                {!pinConfirmed && (
                    <Button size="mini" onClick={() => {
                        setShowPinConfirm(true);
                        setShowNewEntry(true);
                    }}>
                        <Plus size={16} color={theme.colors.contentInversePrimary}/>
                        {strings.Timesheet.NewEntry}
                    </Button>
                )}
                <SpacerExpand/>
            </HFlex>
            <PinConfirmation
                open={showPinConfirm}
                onClose={() => setShowPinConfirm(false)}
                onConfirmed={setPinConfirmed}
            />
        </>
    );

    if (timesheetEntries.length === 0) {
        return (
            <VFlex>
                <Spacer height="40px"/>
                <EmptyState title={strings.EmptyStates.TimesheetEmptyTitle}
                            subtitle={strings.EmptyStates.TimesheetEmptyDescription}
                            cta={newEntryButton}>
                    <OnSiteReportIcon size={72} color={theme.colors.contentPrimary}></OnSiteReportIcon>
                </EmptyState>
            </VFlex>
        );
    }

    return (
        <VFlex>
            <UnorderedList>
                {timesheetEntries.map((e, index) =>
                    <TimesheetLine entry={e}
                                   key={index}
                                   canModify={pinConfirmed}
                                   onRequestModify={() => setShowPinConfirm(true)}
                    />)
                }
            </UnorderedList>
            {newEntryButton}
        </VFlex>
    );
}