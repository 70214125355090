import { VFlex } from "../containers/VFlex";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { QuickRouteJobItem } from "./QuickRouteJobItem";
import { Button } from "baseui/button";
import { LabelLarge } from "baseui/typography";
import { Member } from "../../types/member";
import { MemberSelect } from "../pickers/MemberSelect";
import { useEffect, useState } from "react";
import { createJobs } from "../../services/api";
import { useStateUpdater } from "../../hooks/useStateUpdater";
import { Job } from "../../types/job";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { quickRouteCoordinatesState, quickRouteDataState } from "../../states/quickRouteState";
import { useBoard } from "../../hooks/useBoard";
import { dayState } from "../../states/appState";
import { PAGE } from "../../constants/pages";
import { useStrings } from "../../hooks/useStrings";
import { creatingJobForUidState } from "../../states/jobsState";
import { memberState } from "../../states/membersState";
import { useNavigator } from "../../hooks/useNavigator";
import { EMPTY_UID } from "../../types/core";
import { useCompanyDefaults } from "../../hooks/company/useCompanyDefaults";

export function QuickRoutePanel() {
    const { strings } = useStrings();
    const resetQuickRouteCoordinates = useResetRecoilState(quickRouteCoordinatesState);
    const coordinates = useRecoilValue(quickRouteCoordinatesState);
    const [assignee, setAssignee] = useState<Member | undefined>();
    const updater = useStateUpdater();
    const board = useBoard();
    const { navigateToPage } = useNavigator();
    const day = useRecoilValue(dayState);
    const creatingJobForUid = useRecoilValue(creatingJobForUidState);
    const initialAssignee = useRecoilValue(memberState(creatingJobForUid?.uid || EMPTY_UID));
    const { getDefaultAttachments, getDefaultCustomFields } = useCompanyDefaults();

    useEffect(() => {
        if (initialAssignee) {
            setAssignee(initialAssignee);
        }
    }, [initialAssignee]);

    const onCreateRoute = async () => {
        const jobsData = coordinates.map((lonLat, index) => {
            const item = getRecoil(quickRouteDataState(lonLat));
            return {
                destinationName: item.title,
                destinationLat: item.lonLat[1],
                destinationLng: item.lonLat[0],
                number: index + 1,
                day: day,
                destinationText: item.address?.formatted,
                receiverUid: assignee ? assignee.uid : "",
                customFields: getDefaultCustomFields(),
                ...getDefaultAttachments(),
            } as Job
        });
        const { status, jobs } = await createJobs(jobsData);
        if (status) {
            updater.updateJobs(jobs);
            resetQuickRouteCoordinates();
            if (assignee) {
                board.setMemberUids([assignee.uid]);
                navigateToPage(PAGE.DISPATCH);
            }
        }
    };

    return (
        <VFlex spacing style={{ paddingLeft: "32px", paddingRight: "32px", overflowY: "auto" }}>
            <LabelLarge style={{
                flexShrink: 0,
                whiteSpace: "nowrap"
            }}>{coordinates.length} {strings.General.Stops}</LabelLarge>
            {coordinates.map((lonLat, index) =>
                <QuickRouteJobItem key={index} index={index} lonLat={lonLat}/>)
            }
            <MemberSelect
                onMemberSelect={(member: Member) => setAssignee(member)}
                placeholder={strings.General.SelectAssignee}
                selectedMember={assignee}
                avatar={false}
                hideUnassigned
            />
            <HFlex>
                <SpacerExpand/>
                <Button size={"compact"} onClick={onCreateRoute} disabled={!assignee}>
                    {strings.General.CreateJobRoute}
                </Button>
            </HFlex>
            <Spacer/>
        </VFlex>
    );
}