import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { LabelMedium } from "baseui/typography";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffectOnce } from "usehooks-ts";
import { useReports } from "../../hooks/useReports";
import { useStrings } from "../../hooks/useStrings";
import { useTheme } from "../../hooks/useTheme";
import {
    selectedRecurringReportState,
    selectedReportConfigState,
    selectedReportDayWeekState,
    selectedReportFormatState
} from "../../states/reportState";
import { HFlex } from "../containers/HFlex";
import { Spacer, SpacerExpand } from "../containers/Spacer";
import { VFlex } from "../containers/VFlex";
import { CheckCircledIcon } from "../ui/svg";
import { ReportDayWeekStep } from "./ReportDayWeekStep";
import { ReportFilterStep } from "./ReportFilterStep";
import { ReportFormatStep } from "./ReportFormatStep";
import { ReportNameStep } from "./ReportNameStep";
import { ReportNotifyStep } from "./ReportNotifyStep";
import { ReportWeekdaysStep } from "./ReportWeekdaysStep";
import { ReportColumnsStep } from "./ReportColumnsStep";

export function RecurringReport() {
    const { theme } = useTheme();
    const { strings } = useStrings();
    const [reportConfig, setReportConfig] = useRecoilState(selectedReportConfigState);
    const [selectedRecurringReport, setSelectedRecurringReport] = useRecoilState(selectedRecurringReportState);
    const format = useRecoilValue(selectedReportFormatState);
    const [isLoading, setIsLoading] = useState(false);
    const { saveEditRecurringConfig, cleanup } = useReports();
    const dayWeek = useRecoilValue(selectedReportDayWeekState);

    useEffectOnce(() => {
        return cleanup;
    });

    const handleCancel = () => {
        setReportConfig(undefined);
        setSelectedRecurringReport(undefined);
    };

    if (!reportConfig || !selectedRecurringReport) {
        return null;
    }

    const saveRecurringReport = async () => {
        if (!format) {
            return;
        }
        setIsLoading(true);
        await saveEditRecurringConfig();
        setIsLoading(false);
        setReportConfig(undefined);
        setSelectedRecurringReport(undefined);
    };

    return (
        <VFlex style={{ marginLeft: "16px", marginTop: "8", marginRight: "16px", marginBottom: "8px", }}>
            <VFlex spacing={theme.sizing.scale850} style={{ overflowY: "auto" }}>
                <Block>
                    <LabelMedium marginBottom={theme.sizing.scale300}>{strings.General.Filter}</LabelMedium>
                    <ReportFilterStep onNext={() => {}}/>
                </Block>
                <Block>
                    <LabelMedium marginBottom={theme.sizing.scale300}>
                        {strings.Reports.SelectTimeFrame}
                    </LabelMedium>
                    <ReportDayWeekStep/>
                </Block>
                <Block>
                    <LabelMedium marginBottom={theme.sizing.scale300}>
                        {dayWeek === "twoweeks"
                            ? strings.Reports.SelectDayToCreateFirstReport
                            : strings.Reports.SelectDayToCreate}
                    </LabelMedium>
                    <ReportWeekdaysStep/>
                </Block>
                <Block>
                    <LabelMedium marginBottom={theme.sizing.scale300}>{strings.Reports.Columns}</LabelMedium>
                    <ReportColumnsStep/>
                </Block>
                <Block>
                    <LabelMedium marginBottom={theme.sizing.scale300}>{strings.General.Format}</LabelMedium>
                    <ReportFormatStep/>
                </Block>
                <Block>
                    <LabelMedium
                        marginBottom={theme.sizing.scale300}>{strings.Reports.NotifyPeopleOnCreation}</LabelMedium>
                    <ReportNotifyStep/>
                </Block>
                <Block>
                    <LabelMedium marginBottom={theme.sizing.scale300}>{strings.General.Title}</LabelMedium>
                    <ReportNameStep/>
                </Block>
            </VFlex>
            <HFlex spacing alignCenter style={{
                marginTop: "8px",
                marginRight: "8px",
                marginBottom: "8px"
            }}>
                <SpacerExpand/>
                <Button kind="tertiary" size="compact" onClick={handleCancel}>
                    {strings.General.Cancel}
                </Button>
                <Button
                    kind="primary"
                    size="compact"
                    onClick={saveRecurringReport}
                    disabled={!format}
                    isLoading={isLoading}
                >
                    <CheckCircledIcon color={theme.colors.contentInversePrimary} size={16}/>
                    <Spacer/> {strings.General.Save}
                </Button>
            </HFlex>
        </VFlex>
    );
}
