import { useRecoilState, useSetRecoilState } from "recoil";
import {
    placesLayerMoreCounterState,
    placesLayerRetryState,
    placesLayerSkipCounterState
} from "../../states/geojsonState";
import { updatePlacesLayer } from "../../services/geojson";
import { requestingState } from "../../states/viewState";

export function usePlacesLayerUpdater() {
    const maxPerRequest = 5000;
    const setRequesting = useSetRecoilState(requestingState({ req: "places" }));
    const [retry, setRetry] = useRecoilState(placesLayerRetryState);
    const setMoreCounter = useSetRecoilState(placesLayerMoreCounterState);
    const setSkipCounter = useSetRecoilState(placesLayerSkipCounterState);

    const updateLayer = async (skip: number) => {
        setRequesting(true);
        const json = await updatePlacesLayer(maxPerRequest, skip);
        setRequesting(false);
        if (json && json.aborted) {
            setRetry(retry <= 0 ? 10 : retry - 1);
        }
        if (json && json.hasMore) {
            setMoreCounter(old => old + 1);
            setSkipCounter(old => old + json.count);
        } else if (json) {
            setRetry(0);
        }
    }

    const updateAll = () => {
        updateLayer(0);
    };

    const updateFiltered = async (uids: string) => {
        setRequesting(true);
        const json = await updatePlacesLayer(maxPerRequest, 0, uids);
        setRequesting(false);
    }

    return { updateAll, updateFiltered, updateLayer };
}