import { FormControl } from 'baseui/form-control';
import { useRecoilState } from 'recoil';
import { editCompanyState } from '../../../states/editCompanyState';
import { SectionedWrapper } from '../../containers/SectionedWrapper';
import { Section } from '../../ui/Section';
import { FormInput } from '../../ui/FormInput';
import { Textarea } from 'baseui/textarea';
import { useState } from 'react';
import { StandardFormModal } from '../../forms/StandardFormModal';
import { LabelSmall, LabelXSmall, ParagraphMedium, ParagraphXSmall } from 'baseui/typography';
import { Button } from 'baseui/button';
import { VFlex } from '../../containers/VFlex';
import { HFlex } from '../../containers/HFlex';
import { Input } from 'baseui/input';
import { ReasonList } from '../../shared/ReasonList';
import { removeAtIndex } from '../../../utils/arrays';
import { useTheme } from '../../../hooks/useTheme';
import { Checkbox } from "baseui/checkbox";
import useAccount from "../../../hooks/useAccount";
import { Spacer } from "../../containers/Spacer";
import { Radio, RadioGroup } from "baseui/radio";
import { ALLOW_REASSIGN_REJECT_JOB, ALLOWED_REJECT_JOB, DISALLOWED_REJECT_JOB } from "../../../constants/company";
import { useStyleOverrides } from "../../../hooks/useStyleOverrides";
import { useStrings } from "../../../hooks/useStrings";
import { AnyField } from "../../../types/fields";
import { InputNumber } from "antd";
import { Tile, TileGroup } from "baseui/tile";
import { DoneDiv } from "../../ui/DoneDiv";

export function JobSettingsTab() {
    const { strings, format } = useStrings();
    const { theme } = useTheme();
    const [companyFields, setCompanyFields] = useRecoilState(editCompanyState);
    const [showStandardForm, setShowStandardForm] = useState(false);
    const [newReason, setNewReason] = useState("");
    const { isSelfAdminOrCompany } = useAccount();
    const { formControlSpacing } = useStyleOverrides();

    const onChangeInput = (key: string, value: string | number) => {
        setCompanyFields((prev) => ({ ...prev, [key]: value }));
    };

    const handleNewReason = () => {
        setCompanyFields((prev) => ({ ...prev, dispatch_reasons: [...prev.dispatch_reasons, newReason] }))
        setNewReason("");
    }

    const handleRemoveReason = (index: number) => {
        setCompanyFields((prev) => ({
            ...prev, dispatch_reasons: removeAtIndex(prev.dispatch_reasons, index)
        }));
    }

    const onChangeToggle = (key: string, value: boolean) => {
        setCompanyFields((prev) => ({ ...prev, [key]: value }));
    };

    const getRejectJobValue = () => {
        if (companyFields.workers_reject_job && companyFields.workers_reject_job_reassign) {
            return ALLOW_REASSIGN_REJECT_JOB;
        }
        if (companyFields.workers_reject_job) {
            return ALLOWED_REJECT_JOB;
        }
        return DISALLOWED_REJECT_JOB;
    };

    const onRejectJobChange = (value: string) => {
        switch (value) {
            case DISALLOWED_REJECT_JOB:
                setCompanyFields((prev) => ({
                    ...prev,
                    workers_reject_job: false,
                    workers_reject_job_reassign: false,
                }));
                break;
            case ALLOWED_REJECT_JOB:
                setCompanyFields((prev) => ({ ...prev, workers_reject_job: true, workers_reject_job_reassign: false }));
                break;
            case ALLOW_REASSIGN_REJECT_JOB:
                setCompanyFields((prev) => ({ ...prev, workers_reject_job: true, workers_reject_job_reassign: true }));
                break;
        }
    };

    return (
        <SectionedWrapper>
            <Section
                title={format(strings.Dispatch.WorkerChangesStatusOfJobToX, strings.JobStatuses.Done)}
                noMarginTop>
                <ParagraphMedium color={theme.colors.contentTertiary}>
                    <HFlex spacing={"4px"}>
                        {format(strings.Dispatch.WorkerChangesStatusOfJobToX, strings.JobStatuses.Done)}
                        <DoneDiv/>
                    </HFlex>
                </ParagraphMedium>
                <VFlex spacing>
                    <LabelSmall>{strings.Dispatch.InputFormDescription}</LabelSmall>
                    <Button
                        size={"mini"}
                        kind={"primary"}
                        onClick={() => setShowStandardForm(true)}
                        overrides={{
                            Root: {
                                style: {
                                    width: "fit-content",
                                }
                            }
                        }}
                    >
                        {strings.Dispatch.CustomizeInputForm}
                    </Button>
                </VFlex>
            </Section>
            {showStandardForm && <StandardFormModal onClose={(saved) => setShowStandardForm(false)}/>}
            <Section title={
                format(strings.Dispatch.WorkerChangesStatusOfJobToX, strings.JobStatuses.Issue)
            }>
                <ParagraphMedium color={theme.colors.contentTertiary}>
                    {strings.Dispatch.RecurringReasonsTitle}
                    <span style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: "4px" }}>
                        <DoneDiv issue/>
                    </span>
                </ParagraphMedium>
                <ReasonList reasons={companyFields.dispatch_reasons} onRemove={handleRemoveReason}/>
                <HFlex spacing>
                    <Input
                        startEnhancer={strings.General.Reason}
                        value={newReason}
                        onChange={(event) => setNewReason(event.target.value)}
                        size="compact"
                        placeholder={strings.Dispatch.RecurringReasonsExamples}
                    />
                    <Button onClick={handleNewReason} disabled={!newReason} size="compact">
                        {strings.General.Add}
                    </Button>
                </HFlex>
                <ParagraphXSmall marginBottom={0}
                                 marginTop={theme.sizing.scale300}
                                 color={theme.colors.contentSecondary}
                >
                    {strings.Dispatch.RecurringReasonsDescription}
                </ParagraphXSmall>
            </Section>
            <Section title={strings.Job.CarryOver}>
                <ParagraphMedium color={theme.colors.contentTertiary}>
                    {strings.Job.ChooseCarryOver}:
                </ParagraphMedium>
                <TileGroup
                    kind={"singleSelect"}
                    selected={companyFields.job_carry_over_when_failed ? 1 : 0}
                    onClick={(_event, index) => onChangeToggle('job_carry_over_when_failed', index > 0)}
                >
                    <Tile
                        tileKind={"selection"}
                        label={strings.Job.CarryOverIfNotDoneOrIssue}
                        leadingContent={() => (
                            <HFlex alignCenter spacing={"4px"}>
                                <LabelXSmall $style={{ fontSize: "8px" }}>not</LabelXSmall>
                                <DoneDiv/>
                                <DoneDiv issue/>
                            </HFlex>
                        )}
                        headerAlignment={"center"}
                        bodyAlignment={"right"}
                    >
                        <LabelXSmall color={theme.colors.contentTertiary} $style={{ marginTop: "2px" }}>
                            {strings.Job.JobFinishedIfMarkedAsEither}
                        </LabelXSmall>
                    </Tile>
                    <Tile
                        tileKind={"selection"}
                        label={strings.Job.CarryOverIfNotDone}
                        leadingContent={() => (
                            <HFlex alignCenter spacing={"4px"}>
                                <LabelXSmall $style={{ fontSize: "8px" }}>not</LabelXSmall>
                                <DoneDiv/>
                            </HFlex>
                        )}
                        headerAlignment={"center"}
                        bodyAlignment={"right"}
                    >
                        <LabelXSmall color={theme.colors.contentTertiary} $style={{ marginTop: "2px" }}>
                            {strings.Job.JobFinishedOnlyIfDone}
                        </LabelXSmall>
                    </Tile>
                </TileGroup>
            </Section>
            <Section title={strings.Dispatch.JobCompletion}>
                <FormInput
                    label={strings.Dispatch.EmailSubject}
                    value={companyFields.send_job_completion_subject}
                    placeholder={strings.Dispatch.EmailSubjectDescription}
                    field="send_job_completion_subject"
                    onChange={onChangeInput}
                />
                <FormControl label={strings.Dispatch.EmailBody}>
                    <Textarea
                        placeholder={strings.Dispatch.EmailBodyDescription}
                        value={companyFields.send_job_completion_body}
                        name={"send_job_completion_body"}
                        onChange={({ target: { value, name } }) => onChangeInput(name as AnyField, value)}
                    />
                </FormControl>
            </Section>
            <Section title={strings.Company.JobSettingsForWorkers}>
                <Checkbox
                    checked={companyFields.workers_view_job_calendar}
                    onChange={(event) => onChangeToggle('workers_view_job_calendar', event.target.checked)}
                    disabled={!isSelfAdminOrCompany}
                >
                    {strings.Company.PermissionWorkersMayAccessTheJobCalendar}
                </Checkbox>
                <Checkbox
                    checked={companyFields.workers_edit_job}
                    onChange={(event) => onChangeToggle('workers_edit_job', event.target.checked)}
                    disabled={!isSelfAdminOrCompany}
                >
                    {strings.Company.PermissionWorkersMayReassignDateOfJob}
                </Checkbox>
                <Checkbox
                    checked={companyFields.workers_relocate_job}
                    onChange={(event) => onChangeToggle('workers_relocate_job', event.target.checked)}
                    disabled={!isSelfAdminOrCompany}
                >
                    {strings.Company.PermissionWorkersMayRelocateJob}
                </Checkbox>
                <Checkbox
                    checked={companyFields.workers_optimize_route}
                    onChange={(event) => onChangeToggle('workers_optimize_route', event.target.checked)}
                    disabled={!isSelfAdminOrCompany}
                >
                    {strings.Company.WorkersMayOptimizeRoute}
                </Checkbox>
                <FormControl label={strings.Company.RejectingAJob} overrides={formControlSpacing}>
                    <RadioGroup
                        value={getRejectJobValue()}
                        onChange={(event) => onRejectJobChange(event.target.value)}
                        align="vertical"
                        disabled={!isSelfAdminOrCompany}
                    >
                        <Radio value={DISALLOWED_REJECT_JOB}>{strings.Company.RejectingDisallowed}</Radio>
                        <Radio value={ALLOWED_REJECT_JOB}>{strings.Company.RejectingAllowed}</Radio>
                        <Radio value={ALLOW_REASSIGN_REJECT_JOB}>{strings.Company.RejectingAllowWithReassign}</Radio>
                    </RadioGroup>
                </FormControl>
            </Section>
            <Section title={strings.Company.OvernightJobs}>
                <FormControl>
                    <Checkbox
                        checked={companyFields.overnight_jobs}
                        onChange={(event) => onChangeToggle('overnight_jobs', event.target.checked)}
                        disabled={!isSelfAdminOrCompany}
                    >
                        {strings.Company.OvernightJobsDesc}
                    </Checkbox>
                </FormControl>
            </Section>
            <Section title={strings.Company.JobData}>
                <FormControl
                    caption={strings.Company.AutoArchiveJobsDesc}>
                    <HFlex spacing alignCenter>
                        <Checkbox
                            checked={companyFields.auto_archive_jobs_days > 0}
                            onChange={(event) => onChangeInput("auto_archive_jobs_days", event.target.checked ? 30 : 0)}
                            disabled={!isSelfAdminOrCompany}
                        />
                        <LabelSmall>{strings.Company.AutoArchiveJobs}</LabelSmall>
                        <InputNumber size={"small"}
                                     style={{ width: "64px" }}
                                     value={companyFields.auto_archive_jobs_days}
                                     onChange={(value) => onChangeInput("auto_archive_jobs_days", value ? value : 0)}
                                     disabled={!isSelfAdminOrCompany}
                        />
                        <LabelSmall>{format(strings.General.XDays, "")}</LabelSmall>
                    </HFlex>
                </FormControl>

                <FormControl
                    caption={strings.Company.AutoDeleteJobsDesc}>
                    <HFlex spacing alignCenter>
                        <Checkbox
                            checked={companyFields.auto_delete_jobs_days > 0}
                            onChange={(event) => onChangeInput("auto_delete_jobs_days", event.target.checked ? 180 : 0)}
                            disabled={!isSelfAdminOrCompany}
                        />
                        <LabelSmall>{strings.Company.AutoDeleteJobs}</LabelSmall>
                        <InputNumber size={"small"}
                                     style={{ width: "64px" }}
                                     value={companyFields.auto_delete_jobs_days}
                                     onChange={(value) => onChangeInput("auto_delete_jobs_days", value ? value : 0)}
                                     disabled={!isSelfAdminOrCompany}
                        />
                        <LabelSmall>{format(strings.General.XDays, "")}</LabelSmall>
                    </HFlex>
                </FormControl>
            </Section>
            <Spacer height={"48px"}/>
        </SectionedWrapper>
    );
}