import { LabelLarge } from "baseui/typography";
import { Card, StyledBody } from "baseui/card";
import { useRecoilValue } from "recoil";
import {
    memberNamesFilteredState,
    memberSearchState,
    memberState,
    updateTeamMembersState
} from "../../states/membersState";
import { HFlex } from "../containers/HFlex";
import { Spacer } from "../containers/Spacer";
import { ChevronDown, ChevronRight } from "baseui/icon";
import { Button } from "baseui/button";
import React, { useMemo, useState } from "react";
import { useStrings } from "../../hooks/useStrings";
import { Tag } from "antd";
import TeamMembers from "./TeamMembers";
import { getRecoil } from "../../providers/RecoilAccessProvider";
import { Member } from "../../types/member";

export function NoTeamSection() {
    const { strings } = useStrings();
    const isFiltering = useRecoilValue(memberSearchState).length > 0;
    const memberNamesFiltered = useRecoilValue(memberNamesFilteredState);
    const updateTeamMembers = useRecoilValue(updateTeamMembersState);

    const noTeamOnly = useMemo(() => {
        return memberNamesFiltered
            .map(m => getRecoil(memberState(m.uid)))
            .filter(m => m?.teams?.length === 0)
    }, [memberNamesFiltered, updateTeamMembers]) as Member[];

    const [expanded, setExpanded] = useState(false);
    if (noTeamOnly.length === 0) {
        return null;
    }

    const title = (
        <HFlex alignCenter>
            <Button
                size="compact"
                kind="tertiary"
                onClick={() => setExpanded(!expanded)}
                shape="square"
            >
                {expanded ? <ChevronDown size={18}/> : <ChevronRight size={18}/>}
            </Button>
            <LabelLarge>{strings.TeamManagement.NoTeamAssigned}</LabelLarge>
            <Spacer/>
            {noTeamOnly.length === 1 &&<Tag>{noTeamOnly.length} {strings.General.Member}</Tag>}
            {noTeamOnly.length > 1 &&<Tag>{noTeamOnly.length} {strings.General.Members}</Tag>}
        </HFlex>
    );
    return (
        <Card title={title}>
            {(expanded || isFiltering) &&
                <StyledBody>
                    <TeamMembers
                        members={noTeamOnly}
                    />
                </StyledBody>
            }
        </Card>
    );
}