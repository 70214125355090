import { LOADING, READY } from "../constants/directorStatuses";
import { Centered } from "../components/containers/Centered";
import { EmptyState } from "../components/ui/EmptyState";
import { Spin } from "antd";
import MainView from "./MainView";
import useDirector from "../hooks/useDirector";
import { useTheme } from "../hooks/useTheme";
import { HELLOTRACKS } from "../constants/app";
import { useRedirect } from "../hooks/useRedirect";

export function DirectorView() {
    const { director } = useDirector();
    const { theme } = useTheme();
    useRedirect();

    return (
        <>
            {director === LOADING &&
                <Centered style={{ backgroundColor: theme.colors.backgroundSecondary }}>
                    <EmptyState title={HELLOTRACKS}>
                        <Spin/>
                    </EmptyState>
                </Centered>}
            {director === READY && <MainView/>}
        </>
    );
}