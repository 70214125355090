import { HFlex } from "../containers/HFlex";
import { VFlex } from "../containers/VFlex";
import { Bullet } from "./Bullet";
import { TimelineEntry } from "../../types/timeline";
import { Event } from "./Event";
import { BULLET_SIZE } from "../../constants/timeline";
import { useTheme } from "../../hooks/useTheme";
import { Line } from "./Line";
import { Button } from "baseui/button";
import { EnlargeVerticalIcon } from "../ui/svg";
import { useState } from "react";
import { useStrings } from "../../hooks/useStrings";

type Props = {
    showStartBullet?: boolean;
    showEndBullet?: boolean;
    events: TimelineEntry[];
}

export function StopSection({ events, showStartBullet, showEndBullet }: Props) {
    const { strings, format } = useStrings();
    const { theme } = useTheme();
    const defaultEventsShown = 4;
    const [showEvents, setShowEvents] = useState(events.length <= defaultEventsShown);

    return (
        <HFlex style={{ minHeight: "50px", gap: theme.sizing.scale400 }}>
            <VFlex style={{ height: "auto", flexShrink: 0, alignItems: "center", width: BULLET_SIZE + 2 + "px" }}>
                {showStartBullet && <Bullet content={""} size={10}/>}
                <Line/>
                {showEndBullet && <Bullet content={""} size={10}/>}
            </VFlex>
            <VFlex style={{ justifyContent: "center", height: "auto" }}>
                {showEvents && events.map((child, index) =>
                    <Event key={index} entry={child}/>
                )}
                {!showEvents && events.length > defaultEventsShown &&
                    <Button size={"mini"}
                            kind={"secondary"}
                            onClick={() => setShowEvents(true)}>
                        <EnlargeVerticalIcon size={12} color={theme.colors.contentPrimary}/>
                        {format(strings.Timeline.ShowAllXEvents, events.length)}
                    </Button>
                }
            </VFlex>
        </HFlex>
    );
}