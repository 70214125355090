import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import * as React from "react";
import { AnyField } from "../../types/fields";
import { FieldType } from './FieldType';
import { useTheme } from "../../hooks/useTheme";

type Props = {
    label: string | React.ReactNode;
    inputSize?: 'mini' | 'default' | 'compact' | 'large';
    value: string | number;
    onChange?: (key: AnyField, value: string | number) => void;
    disabled?: boolean;
    field: AnyField | "readonly";
    startEnhancer?: React.ReactNode;
    endEnhancer?: React.ReactNode;
    type?: string;
    caption?: React.ReactNode | ((props: {}) => React.ReactNode) | undefined | null;
    min?: number;
    error?: string;
    required?: boolean;
    placeholder?: string;
    readonly?: boolean;
    autoComplete?: string;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export function FormInput({
                              label,
                              inputSize = 'compact',
                              value,
                              onChange = () => {
                              },
                              disabled = false,
                              field,
                              startEnhancer,
                              endEnhancer,
                              type = 'text',
                              caption,
                              min,
                              error,
                              required,
                              placeholder,
                              readonly = false,
                              autoComplete,
                              onKeyDown
                          }: Props) {
    const { css, theme } = useTheme();
    const isReadOnly = field === "readonly" || readonly;

    return (
        <FormControl
            label={label &&
                <div className={css({ display: 'flex', alignItems: 'center' })}>
                    {label}
                    {required !== undefined && <FieldType required={required}/>}
                </div>
            }
            caption={caption}
            overrides={{
                ControlContainer: { style: { marginBottom: theme.sizing.scale300 } },
            }}
            error={error}
        >
            <Input
                size={inputSize}
                value={value}
                onChange={({ target: { value, name } }) => onChange(name as AnyField, value)}
                readOnly={isReadOnly}
                disabled={disabled}
                name={field}
                startEnhancer={startEnhancer}
                endEnhancer={endEnhancer}
                type={type}
                min={min}
                placeholder={placeholder}
                autoComplete={autoComplete}
                onKeyDown={onKeyDown}
            />
        </FormControl>
    );
}