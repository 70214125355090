import { useRecoilValue } from "recoil";
import { actionPanelModeState } from "../states/actionPanelState";
import { QuickRoutePanel } from "../components/quickroute/QuickRoutePanel";

export function ActionPanelContent() {
    const actionPanelMode = useRecoilValue(actionPanelModeState);

    if (actionPanelMode === "quickroute") {
        return <QuickRoutePanel/>;
    }
    return null;
}