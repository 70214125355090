import { HFlex } from "../containers/HFlex";
import { SpacerExpand } from "../containers/Spacer";
import { Button } from "baseui/button";
import { Alert } from "antd";
import { MemberField } from "../../types/fields";
import { useRecoilState } from "recoil";
import { ignoreSettingState } from "../../states/appState";
import { editAccount } from "../../services/api";
import useAccount from "../../hooks/useAccount";
import useDirector from "../../hooks/useDirector";
import { useStrings } from "../../hooks/useStrings";
import { useState } from "react";
import { useMembersUpdater } from "../../hooks/members/useMembersUpdater";

type Props = {
    title: string;
    keepText: string;
    updateText: string;
    field: MemberField;
    browserValue: string;
}

export function MismatchAlert({ title, field, keepText, updateText, browserValue }: Props) {
    const { strings } = useStrings();
    const account = useAccount();
    const director = useDirector();
    const [ignore, setIgnore] = useRecoilState(ignoreSettingState(field));
    const [loading, setLoading] = useState(false);
    const { updateMembers } = useMembersUpdater();

    if (ignore) {
        return null;
    }

    const browserText =
        browserValue === "SI" ? strings.General.UnitKilometer
            : browserValue === "US" ? strings.General.UnitMiles
                : browserValue === "12" ? "AM/PM"
                    : browserValue === "24" ? "24H"
                        : browserValue;

    return (
        <Alert
            type={"warning"}
            showIcon
            message={title}
            description={
                <HFlex spacing>
                    <SpacerExpand/>
                    <Button kind={"secondary"} size={"mini"} onClick={() => setIgnore(true)}>{keepText}</Button>
                    <Button kind={"primary"} size={"mini"} isLoading={loading} onClick={async () => {
                        setLoading(true);
                        await editAccount({
                            uid: account.uid,
                            [field]: browserValue,
                        });
                        await director.performPoll();
                        updateMembers();
                        setLoading(false);
                    }
                    }>
                        {updateText} {browserText}
                    </Button>
                </HFlex>
            }
        />
    )
}