import { useRecoilValue } from "recoil";
import { dayState, selectedTimeFrameState } from "../states/appState";
import { dayToMillisBrowserTimezone, MINUTE } from "../utils/time";
import { MAX_MINUTES } from "../constants/days";
import { hoveredTrackState } from "../states/tracksState";

export function useTimeframeFilter() {
    const day = useRecoilValue(dayState);
    const timeFrame = useRecoilValue(selectedTimeFrameState);
    const dayMillis = dayToMillisBrowserTimezone(day);
    const hoveredTrack = useRecoilValue(hoveredTrackState);
    const minTs = hoveredTrack
        ? hoveredTrack.startTs / 1000
        : (dayMillis + timeFrame[0] * MINUTE) / 1000;
    const maxTs = hoveredTrack
        ? hoveredTrack.endTs / 1000
        : (dayMillis + timeFrame[1] * MINUTE) / 1000;
    const timeframeFilter = (timeFrame[0] > 0 || timeFrame[1] < MAX_MINUTES || hoveredTrack)
        ? ["all", ["<", minTs, ["get", "ts"]], [">", maxTs, ["get", "ts"]]]
        : undefined;
    return { timeframeFilter };
}