import { useRecoilState, useRecoilValue } from "recoil";
import { availableTeamIdState, fieldsForTeamEditState } from "../../../states/editTeamState";
import { stringsState } from "../../../states/localeState";
import { MemberListPicker } from "../../pickers/MemberListPicker";
import { FormInput } from "../../ui/FormInput";


export function NewTeam() {
    const strings = useRecoilValue(stringsState);
    const newTeamId = useRecoilValue(availableTeamIdState);
    const [teamFields, setTeamFields] = useRecoilState(fieldsForTeamEditState(newTeamId));

    const onChangeInput = (key: string, value: string | number) => {
        setTeamFields((prev) => ({ ...prev, [key]: value }));
    }

    return (
        <>
            <FormInput
                label={strings.TeamManagement.TeamName}
                value={teamFields.name ? teamFields.name : ''}
                onChange={onChangeInput}
                field="name"
                required
            />
            <MemberListPicker/>
        </>
    );
}