import React from "react";
import { useJobInfo } from "../../hooks/jobs/useJobInfo";
import { getProgressColor } from "../../utils/jobUtils";
import { Tag } from "antd";

type Props = {
    progress: number;
}

export function StatusBadge({ progress }: Props) {
    const jobInfo = useJobInfo();

    return (
        <Tag color={getProgressColor(progress)}>
            {jobInfo.getStatusTextFromProgress(progress)}
        </Tag>
    );
}
